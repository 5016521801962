import { useCallback, useEffect, useState } from 'react';

import { getUserRoleSelector } from 'app/store/app.selectors';
import { useSelector } from 'react-redux';
import { SingleValue } from 'react-select';
import {
  getCurrencyOptions,
  transformEmployeeSalaryCurrency,
} from 'utils/currencies';

import {
  Dropdown,
  Input,
  OptionType,
  Split,
} from '@omnipresentgroup/design-system';

type CurrencyValue = {
  currency?: string;
  amount: string | number | null;
};

export type EmployeeCurrencyInputProps = {
  name: string;
  value: CurrencyValue;
  country: string;
  onChange: (name: string, value: CurrencyValue) => void;
  errorMessage?: any;
  disabled?: boolean;
};

export const EmployeeCurrencyInput = ({
  name,
  value,
  country,
  onChange,
  errorMessage,
  disabled = false,
}: EmployeeCurrencyInputProps) => {
  const defaultCurrency = transformEmployeeSalaryCurrency({
    countryName: country,
    salaryCurrency: value.currency,
  });

  const [currency, setCurrency] = useState(defaultCurrency);

  const userRole = useSelector(getUserRoleSelector);

  const currencyOptions = getCurrencyOptions({
    name,
    country,
    userRole,
  }).options;

  useEffect(() => {
    const countryCurrency = transformEmployeeSalaryCurrency({
      countryName: country,
    });
    setCurrency(countryCurrency || value.currency);
  }, [country]);

  useEffect(() => {
    const isCurrencyUpdated = currency !== value.currency;
    if (isCurrencyUpdated) {
      onChange(name, {
        currency,
        amount: value.amount,
      });
    }
  }, [onChange, value.currency, currency]);

  const handleDropdownChange = (e: SingleValue<OptionType>) =>
    setCurrency((e?.value as string) || '');

  const handleInputChange = useCallback(
    ({ target: { value: newValue, type } }) => {
      const amount = type === 'number' ? parseFloat(newValue) : newValue;

      onChange(name, {
        currency,
        amount,
      });
    },
    [name, onChange, currency],
  );

  const selectedValue = currency ? { label: currency, value: currency } : null;
  const amountInputValue = value.amount ?? 0;

  return (
    <Split gap="8" fraction="1/3" data-testid="employee-currency-input">
      <Dropdown
        onChange={(e) => handleDropdownChange(e as SingleValue<OptionType>)}
        value={selectedValue}
        options={currencyOptions}
        isDisabled={disabled}
        placeholder="Currency"
        errorMessage={errorMessage?.currency?.message}
        id={`${name}-currency-dropdown`}
      />

      <Input
        type="number"
        id={`${name}-amount-input`}
        data-testid={`${name}-amount-input`}
        name={`${name}Amount`}
        value={amountInputValue}
        disabled={disabled}
        onChange={handleInputChange}
        errorMessage={errorMessage?.amount?.message}
        placeholder="Amount"
      />
    </Split>
  );
};
