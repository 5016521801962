import { ChangeEventHandler, useEffect, useRef, useState } from 'react';

import { ALLOWED_FILE_TYPES, AllowedFileType } from 'appConstants';
import { useUploadMutation } from 'utils/hooks/useDocumentUpload';

import {
  UploadDropZone,
  UploadIcon,
  UploadLabel,
} from './FileUploadInput.styles';
import { ProgressState } from './ProgressBar';
import { UploadInProgress, UploadInProgressProps } from './UploadInProgress';

export type FileInState = {
  id: string;
  fileName: string;
  progress: number;
  state: ProgressState;
  url?: string;
};

export type FileUploadInputProps = Partial<UploadInProgressProps> & {
  id: string;
  updateFile: (id: string, updates: Partial<FileInState>) => void;
  uploadFile: ({ name }: File, id: string, numberOfRows: number) => void;
  fileTypes?: AllowedFileType[];
  directory?: string;
  fileName: string;
};

export const FileUploadInput = ({
  uploadFile,
  updateFile,
  id: fileUploadId,
  fileTypes,
  directory,
  ...props
}: FileUploadInputProps) => {
  const allowedTypes = fileTypes || ALLOWED_FILE_TYPES;

  const { isLoading, isSuccess, isError, data, handleFileUpload } =
    useUploadMutation(allowedTypes, directory, (progress: number) => {
      updateFile(fileUploadId, {
        progress,
      });
    });

  const [hasDragOver, setHasDragOver] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isLoading) {
      updateFile(fileUploadId, {
        state: 'pending',
      });
    } else if (isSuccess) {
      updateFile(fileUploadId, {
        state: 'success',
        url: data,
      });
    } else if (isError) {
      updateFile(fileUploadId, {
        state: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, isError, data, fileUploadId]);

  const onFileInputChange: ChangeEventHandler<HTMLInputElement> = async ({
    target,
  }) => {
    const { files } = target;
    if (!files) {
      return;
    }
    // eslint-disable-next-line no-loops/no-loops
    for (const file of files) {
      if (file && !allowedTypes.includes(file.type) && inputRef.current) {
        inputRef.current.files = null;
        setHasDragOver(false);
        return;
      }
      handleFileUpload(target);
      const text = await file.text();
      uploadFile(file, fileUploadId, text.split(/\r\n|\r|\n/).length);
    }
  };

  return (
    <>
      {props.fileName && (
        <UploadInProgress
          {...(props as UploadInProgressProps)}
          clear={() => {
            props.clear?.();
            if (inputRef?.current?.value) {
              inputRef.current.value = '';
            }
          }}
        />
      )}
      {!props.fileName && (
        <UploadDropZone
          hasDragOver={hasDragOver}
          onDragLeave={(e) => {
            e.preventDefault();
            setHasDragOver(false);
          }}
          onDragEnter={(e) => e.preventDefault()}
          onDragOver={(e) => {
            e.preventDefault();
            setHasDragOver(true);
          }}
          onDrop={(e) => {
            e.preventDefault();
            // We can't create a FileList object so we use a DataTransfer object which can
            const dt = e.dataTransfer;
            const files = dt.files;
            if (inputRef && inputRef.current) {
              inputRef.current.files = files;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onFileInputChange({ target: dt });
          }}
        >
          <p>
            <UploadIcon />
            Drop files here to upload or{' '}
            <UploadLabel htmlFor={`input-${fileUploadId}`}>
              choose file
            </UploadLabel>
          </p>
        </UploadDropZone>
      )}

      <input
        ref={inputRef}
        type="file"
        accept={allowedTypes.join('|')}
        id={`input-${fileUploadId}`}
        name={`input-${fileUploadId}`}
        style={{ display: 'none' }}
        onChange={onFileInputChange}
      />
    </>
  );
};
