import { asyncActions, makeActionKeys } from 'utils/redux-utils';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_USER = 'SET_USER';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_DOCUMENT_CREATED = 'CLEAR_DOCUMENT_CREATED';
export const CLEAR_DOCUMENTS = 'CLEAR_DOCUMENTS';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const CLEAR_PLATFORM_INVITATION_STATUS =
  'CLEAR_PLATFORM_INVITATION_STATUS';

export const asyncActionKeys = makeActionKeys([
  ...asyncActions('GET', 'USER'),
  ...asyncActions('UPLOAD', 'DOCUMENT'),
  ...asyncActions('CREATE', 'DOCUMENT'),
  ...asyncActions('GET', 'DOCUMENTS'),
  ...asyncActions('GET', 'MANAGERS_EMPLOYEES'),
  ...asyncActions('GET', 'EMPLOYEE'),
  ...asyncActions('UPDATE', 'EMPLOYEE'),
  ...asyncActions('UPDATE', 'USER'),
  ...asyncActions('VERIFY', 'EMAIL_CHANGE'),
  ...asyncActions('CREATE', 'EMAIL_CHANGE'),
  ...asyncActions('CONFIRM', 'EMAIL_CHANGE'),
  ...asyncActions('SEND', 'PLATFORM_INVITATION'),
]);
