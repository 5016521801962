import { getAdminUsers } from 'common/api/users.api';
import { TEN_MINUTES } from 'common/constants';
import { User } from 'common/types';
import { useQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { useSelectUserRoles } from '../../../hooks';
import { sortAlphabetically } from '../../../sort.util';
import { usersKeys } from '../keys';

export const useAdminUsersQuery = () => {
  const { isOPAdmin } = useSelectUserRoles();

  return useQuery<User[], Error>(
    usersKeys.allAdmins,
    () => getAdminUsers().then(getDataFromResponse),
    {
      enabled: isOPAdmin,
      select: (data) => {
        return sortAlphabetically('displayName', data);
      },
      staleTime: TEN_MINUTES,
    },
  );
};
