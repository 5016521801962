import styled, { css } from 'styled-components';

type TextAreaProps = {
  error?: string;
} & Partial<HTMLFormElement>;

const errorInput = css`
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-negative']}`};
`;

export const StyledTextArea = styled.textarea<TextAreaProps>`
  padding: ${({ theme: { space } }) => space[16]};
  background-color: ${({ theme: { colors } }) =>
    colors['field-background-default']};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  font-family: ${({ theme: { fonts } }) => fonts.default};
  border-radius: ${({ theme: { radii } }) => radii.s};
  color: ${({ theme: { colors } }) => colors['text-default']};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-strong']}`};
  transition: all 300ms ease-in-out;

  ${(props) => (props.error ? errorInput : null)};

  :hover {
    border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  }

  :focus {
    border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-inverse']}`};
    transition: all 300ms ease-in-out;
  }

  :disabled {
    resize: none;
    background: ${({ theme: { colors } }) => colors['background-disabled']};
    border-color: ${({ theme: { colors } }) => colors['border-disabled']};
  }
`;
