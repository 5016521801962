import type { IsoDate, IsoDateTime } from '@omnipresentgroup/design-system';

export const employmentType = ['EOR', 'PEO', 'Virtual Employer'];
export type EmploymentType = (typeof employmentType)[number];

export const EMPLOYMENT_STATUS = {
  CREATED: 'EMPLOYMENT_STATUS_CREATED',
  ONBOARDED: 'EMPLOYMENT_STATUS_ONBOARDED',
  ACTIVE: 'EMPLOYMENT_STATUS_ACTIVE',
  INACTIVE: 'EMPLOYMENT_STATUS_INACTIVE',
} as const;

export const EMPLOYMENT_STATUS_MAP: { [key: string]: string } = {
  [EMPLOYMENT_STATUS.CREATED]: 'Onboarding',
  [EMPLOYMENT_STATUS.ONBOARDED]: 'Onboarded',
  [EMPLOYMENT_STATUS.ACTIVE]: 'Active',
  [EMPLOYMENT_STATUS.INACTIVE]: 'Inactive',
} as const;

export const EMPLOYMENT_STATUSES = Object.values(EMPLOYMENT_STATUS);

export type EmploymentStatus = (typeof EMPLOYMENT_STATUSES)[number];

export type PostEmploymentModel = Pick<
  EmploymentModel,
  | 'userId'
  | 'type'
  | 'companyId'
  | 'lspId'
  | 'startDate'
  | 'terminationDate'
  | 'lastPayrollDate'
  | 'firstPayrollDate'
>;

export type PatchEmploymentModel = Partial<
  PostEmploymentModel & Pick<EmploymentModel, 'status'>
>;

export type EmploymentModel = {
  id: string;
  userId: string;
  type: EmploymentType;
  companyId?: string;
  lspId?: string;
  lspName: string;
  status: EmploymentStatus;
  startDate?: IsoDate;
  terminationDate?: IsoDate;
  firstPayrollDate?: IsoDate;
  lastPayrollDate?: IsoDate;
  createdAt: IsoDateTime;
  lastUpdatedAt: IsoDateTime;
  activeAt?: IsoDateTime;
  inactiveAt?: IsoDateTime;
};
