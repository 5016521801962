import { ReactComponent as SuccessIcon } from 'assets/icon/success_mail.svg';
import { StyledBoldCopy } from 'common/components/atoms/Text/Copy.styles';

import { Typography } from '@omnipresentgroup/design-system';

import { StyledConvertSeatToEmployeeSuccessContainer } from './ConvertSeatToEmployeeSuccess.styles';

const ConvertSeatToEmployeeSuccess = () => (
  <StyledConvertSeatToEmployeeSuccessContainer className="smallStack">
    <SuccessIcon />
    <StyledBoldCopy>Success!</StyledBoldCopy>
    <Typography as="p" hideParagraphSpacing size="16">
      Your new employee has been successfully created.
    </Typography>
    <Typography as="p" hideParagraphSpacing size="16">
      We will now start the onboarding process with them.
    </Typography>
    <Typography as="p" hideParagraphSpacing size="16">
      If you have any questions in the meantime please let us know!
    </Typography>
  </StyledConvertSeatToEmployeeSuccessContainer>
);

export default ConvertSeatToEmployeeSuccess;
