import { useParams } from 'react-router-dom';

import {
  convertSeatToEmployeeRequest,
  saveSeatEmployeeProfile,
} from 'common/api/seats.api';
import { SeatEmployeeProfile } from 'common/types';
import { useMutation, useQueryClient } from 'react-query';

import { seatsKeys } from '../keys';

type PageParams = Record<'seatId' | 'companyId', string>;

export const useConvertSeatToEmployeeMutation = () => {
  const queryClient = useQueryClient();
  const { seatId, companyId } = useParams<PageParams>();

  return useMutation(
    ({
      _isMultiDocFlow,
      employeePayload,
      integrationDetails,
    }: Record<any, any>) => {
      const employee = {
        ...employeePayload,
        ...integrationDetails,
      };
      return convertSeatToEmployeeRequest(seatId, companyId, employee);
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(seatsKeys.default);
      },
    },
  );
};

export const useSaveSeatEmployeeProfileMutation = () => {
  const queryClient = useQueryClient();
  const { seatId } = useParams<PageParams>();

  return useMutation(
    (seatEmployeeProfile: SeatEmployeeProfile) =>
      saveSeatEmployeeProfile(seatId, seatEmployeeProfile),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(seatsKeys.default);
      },
    },
  );
};
