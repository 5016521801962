import { useEffect, useMemo, useState } from 'react';

import { CS_PARTY_ID } from 'common/pages/SeatToEmployeePage/config/generateContractDetailsStage';
import { Field } from 'common/types';
import { arePreconditionsMet } from 'utils/preconditions';

/* @todo davidgarvie
Let's find better type definitions here.
I'm not sure what the definition of a section is
*/
type DynamicKeys = Record<string, string>;

export function useContractValues(
  sectionsToEdit: any,
  formValues: DynamicKeys,
) {
  const [contractValues, setContractValues] = useState({});

  const contractFields = useMemo(
    () =>
      sectionsToEdit
        .filter(
          (section: any) =>
            section.name === 'contract-details' ||
            section.name === `contract-details-${CS_PARTY_ID}`,
        )
        ?.reduce(
          (acc: any[], section: any) => [...acc, ...(section?.details || {})],
          [],
        ),
    [sectionsToEdit],
  );

  useEffect(() => {
    if (!contractFields) {
      return;
    }
    const updatedValues = contractFields.reduce(
      (values: DynamicKeys, field: Field) => {
        const value = formValues[field.name];
        const conditionsMet =
          !field.condition || arePreconditionsMet(field.condition, formValues);
        if (!value || !formValues[field.name] || !conditionsMet) {
          return values;
        }
        return { ...values, [field.name]: formValues[field.name] };
      },
      {},
    );
    setContractValues(updatedValues);
  }, [contractFields, formValues]);

  return contractValues;
}
