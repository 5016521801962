import { AxiosError } from 'axios';
import { Expense } from 'common/types/expenses';
import { updateEmployeeExpenseRequest } from 'omniplatform/employee/api/expenses.api';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// FIXME: this should be void cause it is a 204 but types break
// @ts-ignore
type ApproveExpenseApiResponse = any;
type ApproveExpensePayload = { approved: true };
export const useApproveExpenseMutation = (expenseId: string) => {
  return useMutation<
    ApproveExpenseApiResponse,
    AxiosError,
    ApproveExpensePayload
  >((requestData) => updateEmployeeExpenseRequest(requestData, expenseId));
};

export const useLegacyApproveExpenseMutation = (expenseId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      updateEmployeeExpenseRequest(
        {
          approved: true,
        },
        expenseId,
      ),
    {
      onError: (error) => {
        dispatch({ type: 'UPDATE_EXPENSE_ERROR', payload: error });
      },
      onSuccess: () => {
        queryClient.setQueryData<Expense>(`expense-${expenseId}`, (expense) => {
          return {
            ...(expense as Expense),
            status: 'APPROVED',
          };
        });
      },
    },
  );
};
