/* eslint-disable max-lines-per-function */
import { genderTypeOptions, HOLIDAY_TYPES } from 'appConstants';
import {
  ADVANCED_PAYMENT_DEPOSIT,
  depositTypeOptions,
  ZERO_DEPOSIT_INSURANCE,
} from 'common/helpers/depositTypeOptions.const';
import { PAYROLL_FIELDS } from 'common/helpers/payrollFormFieldLabels.const';
import {
  employeeHasSupplementaryBenefitsOptions,
  employeeTypeOptions,
} from 'common/pages/AddEditEmployeeSeatPage/addCompanyEmployeeSeatFormConfig';
import {
  BENEFIT_COUNTRY_SELECTION_LABEL,
  FIELD_TYPE,
  UserRoleTypeEnum,
} from 'common/types';
import { getLastEffectiveSalary } from 'utils/compensation';
import {
  billingCurrencyFormOptions,
  formatCurrency,
  transformEmployeeSalaryCurrency,
} from 'utils/currencies';
import {
  isClientBenefitsPreferenceEnabled,
  isUpsellProposalGenerationEnabled,
  shouldShowHasSupplementaryBenefits,
} from 'utils/featureFlags';
import { toTitleCase } from 'utils/string-utils';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';

export const transformSimplifiedEmployee = (simplifiedEmployee) => {
  const { startDate, annualBaseSalary, currency } = simplifiedEmployee;
  return {
    ...simplifiedEmployee,
    startDate: formatISODate(startDate, DateFormats.Date, true),
    annualBaseSalary: formatCurrency(annualBaseSalary, currency),
  };
};

export const isNotOfferedSupplementaryBenefits = (benefitsClientSelection) =>
  benefitsClientSelection === 'NO';

const convertContractResponsesToStrings = (contractQuestionnaire) => {
  if (!contractQuestionnaire?.body) {
    return;
  }
  const convertedResponses = Object.keys(contractQuestionnaire.body).reduce(
    (accum, question) => {
      const { value } = accum[question];
      if (Array.isArray(value)) {
        return {
          ...accum,
          [question]: {
            ...accum[question],
            value: value.join(', '),
          },
        };
      }
      if (typeof value === 'number') {
        return {
          ...accum,
          [question]: {
            ...accum[question],
            value: `${value}`,
          },
        };
      }
      return accum;
    },
    contractQuestionnaire.body,
  );
  return convertedResponses;
};

const holidayTypesMapper = {
  [HOLIDAY_TYPES.EXCLUDING_PUBLIC_HOLIDAYS]: 'Excluding public holidays',
  [HOLIDAY_TYPES.INCLUDING_PUBLIC_HOLIDAYS]: 'Including public holidays',
  [HOLIDAY_TYPES.UNLIMITED]: 'Unlimited',
};

// Need to handle unlimited holiday here
const getHolidayDisplayString = (leave) => {
  if (leave[0]?.type === HOLIDAY_TYPES.UNLIMITED) {
    return holidayTypesMapper[HOLIDAY_TYPES.UNLIMITED];
  }
  if (leave.holidayAllowance === 0) {
    return '0';
  }
  const holidayDays = `${leave.holidayAllowance || leave[0].value}`;
  const holidayType = leave.length ? holidayTypesMapper[leave[0].type] : '';
  return holidayType ? `${holidayDays} ${holidayType}` : `${holidayDays}`;
};

export const transformEmployeeForDetailsPage = ({
  id,
  managerId,
  companyName,
  companySettings,
  createdAt,
  userId,
  lspId,
  currentEmploymentId,
  onboardingStatus: { status },
  personalDetails: {
    firstName,
    lastName,
    gender,
    pronouns,
    dateOfBirth,
    citizenship,
    privateAddress,
    socialInsuranceNumber,
    bankingDetails,
  },
  country: { name: countryName },
  contactDetails: { workEmail, phoneNumber, personalEmail },
  employmentDetails: {
    jobTitle,
    startDate,
    firstPayDate,
    jobDescription,
    locationOfWork,
    hoursOfWork,
    annualBaseSalary,
    salaryHistory,
    additionalCompensation,
    leave,
    probationaryPeriod: {
      length: probationLength,
      timeUnit: probationTimeUnit,
    },
    rightToWorkCheckCompleted,
    hasSupplementaryBenefits,
  },
  employeeType,
  contracts,
  contractQuestionnaire,
  employmentInformation,
  needsRequiredFieldsFilled,
  requireTypeFormFilled,
  adminNotes,
  administrativeInfo: { department } = {},
  temporaryPassword,
  sentInvitationAt,
  seenPrivacyPolicy,
  localOnboardingDetails,
  externalId,
  payrollDetails,
  benefitsClientSelection,
}) => {
  const { amount: salaryAmount, currency: salaryCurrency } =
    getLastEffectiveSalary({ annualBaseSalary, salaryHistory });
  const depositTypeField = isUpsellProposalGenerationEnabled()
    ? {
        name: 'depositType',
        label: 'Deposit type',
        value: payrollDetails?.depositType,
        type: 'dropdown',
        options: depositTypeOptions,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        managerEditable: false,
        employeeEditable: false,
        payrollEditable: true,
        adminEditable: true,
        benefitsSpecialistEditable: true,
      }
    : {
        name: 'hasDepositInsurance',
        label: 'Does client pay deposit insurance for this employee?',
        detailLabel: 'Deposit insurance',
        value: payrollDetails?.hasDepositInsurance,
        displayValue: payrollDetails?.hasDepositInsurance ? 'Yes' : 'No',
        type: 'checkbox-boolean',
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        payrollEditable: true,
        adminEditable: false,
        benefitsSpecialistEditable: false,
      };

  return {
    id,
    name: `${firstName} ${lastName}`,
    companyName,
    companySettings,
    createdAt,
    userId,
    jobTitle,
    managerId,
    onboardingStatus: status,
    country: countryName,
    employmentInformation,
    needsRequiredFieldsFilled,
    requireTypeFormFilled,
    currentEmploymentId,
    lspId,
    externalId,
    contracts,
    contractQuestionnaire: {
      ...contractQuestionnaire,
      body: convertContractResponsesToStrings(contractQuestionnaire),
    },
    employeeType,
    personalDetails: [
      {
        name: 'firstName',
        label: 'First name',
        value: firstName,
        type: FIELD_TYPE.STRING,
        employeeEditable: true,
        managerEditable: true,
        required: true,
      },
      {
        name: 'lastName',
        label: 'Last name',
        value: lastName,
        type: FIELD_TYPE.STRING,
        managerEditable: true,
        employeeEditable: true,
        required: true,
      },
      {
        name: 'gender',
        label: 'Legal gender',
        value: gender || '',
        type: FIELD_TYPE.GENDER,
        placeholder: 'Choose a gender...',
        options: genderTypeOptions,
        employeeEditable: true,
        managerEditable: true,
        required: true,
      },
      {
        name: 'pronouns',
        label: 'Pronouns',
        value: pronouns,
        type: FIELD_TYPE.STRING,
        managerEditable: true,
        employeeEditable: true,
      },
      {
        name: 'dateOfBirth',
        label: 'Date of birth',
        value: dateOfBirth,
        type: FIELD_TYPE.DATE,
        employeeEditable: true,
        required: true,
      },
      {
        name: 'citizenship',
        label: 'Citizenship',
        value: citizenship,
        type: FIELD_TYPE.COUNTRY,
        employeeEditable: true,
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        value: phoneNumber,
        type: FIELD_TYPE.STRING,
        employeeEditable: true,
        required: true,
      },
      {
        name: 'personalEmail',
        label: 'Personal email',
        value: personalEmail,
        type: FIELD_TYPE.STRING,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST} ${UserRoleTypeEnum.MANAGER}`,
      },
      {
        name: 'address',
        label: 'Home address',
        value: privateAddress,
        type: FIELD_TYPE.ADDRESS,
        employeeEditable: true,
        required: true,
      },
    ],
    bankingDetails: [
      {
        name: 'iban',
        label: 'IBAN',
        value: bankingDetails?.iban || '',
        type: FIELD_TYPE.STRING,
        employeeEditable: true,
        adminEditable: false,
        managerEditable: false,
        required: true,
      },
      {
        name: 'accountNumber',
        label: 'Account number',
        value: bankingDetails?.accountNumber,
        type: FIELD_TYPE.STRING,
        employeeEditable: true,
        adminEditable: false,
        managerEditable: false,
      },
      {
        name: 'bicSwift',
        label: 'SWIFT/BIC',
        value: bankingDetails?.bicSwift || '',
        link: 'https://wise.com/gb/swift-codes/banks',
        type: FIELD_TYPE.STRING,
        employeeEditable: true,
        adminEditable: false,
        managerEditable: false,
      },
      {
        name: 'routingNumber',
        label: 'Routing number / Sort code',
        value: bankingDetails?.routingNumber || '',
        type: FIELD_TYPE.STRING,
        employeeEditable: true,
        adminEditable: false,
        managerEditable: false,
      },
      {
        name: 'branchName',
        label: 'Branch name',
        value: bankingDetails?.branchName || '',
        type: FIELD_TYPE.STRING,
        employeeEditable: true,
        adminEditable: false,
        managerEditable: false,
        required: true,
      },
    ],
    socialInsuranceNumber: [
      {
        name: 'socialInsuranceNumber',
        label: 'Social insurance number (or equivalent)',
        labelBold: true,
        value: socialInsuranceNumber.number || '',
        type: FIELD_TYPE.STRING,
        required: true,
        employeeEditable: true,
        adminEditable: false,
        managerEditable: false,
      },
    ],
    roleDetails: [
      {
        name: 'jobTitle',
        label: 'Job title',
        value: jobTitle,
        type: FIELD_TYPE.STRING,
        managerEditable: true,
        required: true,
      },
      {
        name: 'jobDescription',
        label: 'Job description',
        value: jobDescription,
        type: FIELD_TYPE.TEXT,
        managerEditable: true,
        required: true,
        placeholder: 'Please provide a detailed job description',
      },
      {
        name: 'annualBaseSalary',
        label: 'Annual base salary',
        type: FIELD_TYPE.EMPLOYEE_CURRENCY,
        value: {
          currency: transformEmployeeSalaryCurrency({
            countryName,
            salaryCurrency: annualBaseSalary.currency,
          }),
          amount: annualBaseSalary.amount,
        },
        managerEditable: true,
        employeeDisplayValue: formatCurrency(salaryAmount, salaryCurrency),
        displayValue: formatCurrency(
          annualBaseSalary.amount,
          annualBaseSalary.currency,
        ),
        required: true,
      },
      {
        name: 'additionalCompensation',
        label: 'Any additional financial compensation?',
        value: additionalCompensation,
        type: FIELD_TYPE.CONDITIONAL_INPUT,
        detailLabel: 'Additional compensation',
        managerEditable: false,
      },
    ],
    employmentDetails: [
      {
        name: 'countryOfWork',
        label: 'Country of work',
        value: countryName,
        type: FIELD_TYPE.STRING,
        readOnly: true,
        managerEditable: true,
        employeeEditable: true,
      },
      {
        name: 'lspId',
        placeholder: 'Select local service provider',
        label: 'Payroll/Service provider',
        type: FIELD_TYPE.DROPDOWN,
        value: lspId,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
      },
      {
        name: 'onboardingStatus',
        label: 'Status',
        value: status,
        type: FIELD_TYPE.ONBOARDING_STATUS,
        required: true,
        hideFromDetailsView: true,
      },
      {
        name: 'workEmail',
        label: 'Work email',
        value: workEmail,
        type: FIELD_TYPE.STRING,
        managerEditable: true,
        employeeEditable: true,
      },
      ...(Object.keys(contractQuestionnaire?.body || {}).length
        ? [
            {
              name: 'firstPayDate',
              label: 'First pay date',
              value: firstPayDate,
              displayValue: formatISODate(
                firstPayDate,
                DateFormats.Date,
                true,
                '-',
              ),
              type: FIELD_TYPE.DATE,
            },
          ]
        : [
            {
              name: 'startDate',
              label: 'Start date',
              value: startDate,
              displayValue: formatISODate(
                startDate,
                DateFormats.Date,
                true,
                '-',
              ),
              type: FIELD_TYPE.DATE,
              managerEditable: true,
              required: true,
            },
            {
              name: 'firstPayDate',
              label: 'First pay date',
              value: firstPayDate,
              displayValue: formatISODate(
                firstPayDate,
                DateFormats.Date,
                true,
                '-',
              ),
              type: FIELD_TYPE.DATE,
            },
            {
              name: 'hoursOfWork',
              label: 'Hours of work',
              value: hoursOfWork,
              type: FIELD_TYPE.NUMBER,
              managerEditable: true,
              required: true,
            },
            {
              name: 'holidayAllowance',
              label: 'Entitled paid time off',
              value:
                leave.holidayAllowance || leave.holidayAllowance === 0
                  ? { value: leave.holidayAllowance }
                  : leave[0],
              displayValue: getHolidayDisplayString(leave),
              type: FIELD_TYPE.HOLIDAY_ALLOWANCE,
              managerEditable: true,
              contextualInfo:
                'We will check this against the minimum requirements in this country and inform you of any discrepancies.',
              required: true,
            },
            {
              name: 'probationaryPeriod',
              label: `Probationary period (${probationTimeUnit.toLowerCase()})`,
              value: probationLength,
              type: FIELD_TYPE.NUMBER,
              managerEditable: true,
              required: true,
            },
          ]),
      {
        name: 'locationOfWork',
        label: 'Location of work',
        value: locationOfWork,
        type: FIELD_TYPE.LOCATION_OF_WORK,
        managerEditable: true,
        employeeEditable: true,
        noLabel: true,
        required: true,
      },
      {
        name: 'employeeType',
        label: 'Employee type',
        value: employeeType,
        type: FIELD_TYPE.DROPDOWN,
        managerEditable: false,
        employeeEditable: false,
        options: employeeTypeOptions,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        required: true,
      },
      {
        name: 'rightToWorkCheckCompleted',
        label: `Is this person eligible to work in ${countryName}?`,
        options: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        value: [
          {
            label: 'Yes',
            value: true,
            selected: rightToWorkCheckCompleted,
          },
          {
            label: 'No',
            value: false,
            selected: !rightToWorkCheckCompleted,
          },
        ],
        type: FIELD_TYPE.CHECKBOX,
        displayValue: rightToWorkCheckCompleted ? 'Yes' : 'No',
        managerEditable: true,
        employeeEditable: false,
        showFor: 'admin benefits-specialist manager',
      },
      {
        name: 'hasSupplementaryBenefits',
        label: 'Enrolled in supplementary benefits?',
        value: hasSupplementaryBenefits
          ? toTitleCase(hasSupplementaryBenefits)
          : 'Unknown',
        type: shouldShowHasSupplementaryBenefits() ? 'dropdown' : 'hidden',
        hideFromDetailsView: !shouldShowHasSupplementaryBenefits(),
        required: true,
        disabled: isClientBenefitsPreferenceEnabled()
          ? isNotOfferedSupplementaryBenefits(benefitsClientSelection)
          : false,
        options: employeeHasSupplementaryBenefitsOptions,
        managerEditable: false,
        employeeEditable: false,
        adminEditable: false,
        benefitsSpecialistEditable: true,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
      },
      {
        name: 'isOfferedSupplementaryBenefits',
        label: 'Is offered supplementary benefits?',
        value: benefitsClientSelection
          ? BENEFIT_COUNTRY_SELECTION_LABEL[benefitsClientSelection]
          : 'Unknown',
        type: isClientBenefitsPreferenceEnabled()
          ? FIELD_TYPE.STRING
          : FIELD_TYPE.HIDDEN,
        hideFromDetailsView: !isClientBenefitsPreferenceEnabled(),
        readOnly: true,
        managerEditable: false,
        employeeEditable: false,
        benefitsSpecialistEditable: true,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
      },
    ],
    administrativeInformation: [
      {
        name: 'adminNotes',
        label: 'Notes',
        value: adminNotes,
        type: FIELD_TYPE.TEXT,
      },
      {
        name: 'department',
        label: 'Department',
        value: department,
        type: FIELD_TYPE.DROPDOWN,
        options: [],
      },
      {
        name: 'sentInvitationAt',
        label: 'Platform invitation',
        value: seenPrivacyPolicy ? 'ACCEPTED' : sentInvitationAt && 'SENT',
        type: 'invitation',
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
      },
      {
        name: 'temporaryPassword',
        label: 'Temporary password',
        value: temporaryPassword,
        type: FIELD_TYPE.TEXT,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
      },
      {
        name: 'externalId',
        label: 'External ID',
        value: externalId,
        type: FIELD_TYPE.STRING,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
      },
      {
        name: 'requireTypeFormFilled',
        label:
          'Prompt employee to resubmit Local Onboarding Details (TypeForm) on next login?',
        type: FIELD_TYPE.CHECKBOX,
        options: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        value: [
          {
            label: 'Yes',
            value: true,
            selected: requireTypeFormFilled,
          },
          {
            label: 'No',
            value: false,
            selected: !requireTypeFormFilled,
          },
        ],
        displayValue: requireTypeFormFilled ? 'Yes' : 'No',
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
      },
    ],
    localOnboardingDetails: (localOnboardingDetails ?? [])
      .filter(({ type }) => type !== 'file')
      .map(({ value, name, type, label }) => {
        let displayValue = value;
        let fieldType = 'string';
        let transformedValue = value;

        if (Array.isArray(value)) {
          displayValue = value.join(', ');
          transformedValue = value.join(', ');
        } else if (typeof value === 'boolean') {
          displayValue = value ? 'Yes' : 'No';
          transformedValue = [
            {
              label: 'Yes',
              value: true,
              selected: value,
            },
            {
              label: 'No',
              value: false,
              selected: !value,
            },
          ];
          fieldType = 'checkbox';
        } else if (type === 'date') {
          transformedValue = value.redacted ? value : value.substr(0, 10);
          displayValue = value.redacted
            ? value
            : formatISODate(value, DateFormats.Date, true, '-');
          fieldType = 'date';
        } else if (type === 'number') {
          fieldType = 'number';
        }

        return {
          label,
          type: fieldType,
          name: `local-onboarding-details-${name}`,
          value: transformedValue,
          displayValue,
          managerEditable: false,
          employeeEditable: true,
          required: true,
        };
      }),
    payrollDetails: [
      {
        name: PAYROLL_FIELDS.payroll_id.name,
        label: PAYROLL_FIELDS.payroll_id.label,
        value: payrollDetails?.payrollId,
        type: FIELD_TYPE.STRING,
        payrollEditable: false,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        adminEditable: false,
        benefitsSpecialistEditable: false,
      },
      {
        name: PAYROLL_FIELDS.billing_currency.name,
        label: PAYROLL_FIELDS.billing_currency.label,
        value: payrollDetails?.billingCurrency,
        type: FIELD_TYPE.DROPDOWN,
        options: billingCurrencyFormOptions,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        payrollEditable: true,
        adminEditable: true,
        benefitsSpecialistEditable: true,
      },
      {
        name: PAYROLL_FIELDS.management_fee_currency.name,
        label: PAYROLL_FIELDS.management_fee_currency.label,
        value: payrollDetails?.managementFee?.currency || '',
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        type: FIELD_TYPE.HIDDEN,
      },
      {
        name: PAYROLL_FIELDS.management_fee_amount.name,
        label: PAYROLL_FIELDS.management_fee_amount.label,
        value: {
          amount: parseFloat(payrollDetails?.managementFee?.amount) || 0,
          currency:
            payrollDetails?.managementFee?.currency ||
            payrollDetails?.billingCurrency,
        },
        type: FIELD_TYPE.FEE_CURRENCY,
        displayValue: payrollDetails?.managementFee?.amount || '0',
        disabled: true,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        payrollEditable: true,
        adminEditable: true,
        benefitsSpecialistEditable: true,
      },
      {
        name: PAYROLL_FIELDS.managementFeeDiscount.sales_discount_percentage
          .name,
        label:
          PAYROLL_FIELDS.managementFeeDiscount.sales_discount_percentage.label,
        value: payrollDetails?.managementFeeDiscount?.percentage || 0,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        displayValue: payrollDetails?.managementFeeDiscount?.percentage || '-',
        type: FIELD_TYPE.NUMBER,
        disabled: true,
      },
      {
        name: PAYROLL_FIELDS.managementFeeDiscount.sales_discount_period_start
          .name,
        label:
          PAYROLL_FIELDS.managementFeeDiscount.sales_discount_period_start
            .label,
        value: formatISODate(firstPayDate, 'yyyy-MM'),
        placeholder: 'Month/Year',
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        displayValue: firstPayDate
          ? formatISODate(firstPayDate, 'LLL yyyy')
          : '-',
        type: FIELD_TYPE.DATE,
        disabled: true,
      },
      {
        name: PAYROLL_FIELDS.managementFeeDiscount.sales_discount_billing_cycles
          .name,
        label:
          PAYROLL_FIELDS.managementFeeDiscount.sales_discount_billing_cycles
            .label,
        value: payrollDetails?.managementFeeDiscount?.billingCycles || 0,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        displayValue:
          payrollDetails?.managementFeeDiscount?.billingCycles || '-',
        type: FIELD_TYPE.NUMBER,
        disabled: true,
      },
      {
        name: PAYROLL_FIELDS.managementFeeWaiver.waiver_percentage.name,
        label: PAYROLL_FIELDS.managementFeeWaiver.waiver_percentage.label,
        placeholder: 'Enter percentage',
        value: payrollDetails?.managementFeeWaiver?.percentage || 0,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        displayValue: payrollDetails?.managementFeeWaiver?.percentage || '0',
        payrollEditable: true,
        adminEditable: true,
        benefitsSpecialistEditable: true,
        type: FIELD_TYPE.NUMBER,
      },
      {
        name: PAYROLL_FIELDS.managementFeeWaiver.waiver_period_start.name,
        label: PAYROLL_FIELDS.managementFeeWaiver.waiver_period_start.label,
        value:
          formatISODate(
            payrollDetails?.managementFeeWaiver?.periodStart,
            'yyyy-MM',
          ) || '',
        placeholder: 'Month/Year',
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        displayValue: payrollDetails?.managementFeeWaiver?.periodStart
          ? formatISODate(
              payrollDetails?.managementFeeWaiver?.periodStart,
              'LLL yyyy',
            )
          : '-',
        payrollEditable: true,
        adminEditable: true,
        benefitsSpecialistEditable: true,
        type: FIELD_TYPE.DATE,
      },
      {
        name: PAYROLL_FIELDS.managementFeeWaiver.waiver_billing_cycles.name,
        label: PAYROLL_FIELDS.managementFeeWaiver.waiver_billing_cycles.label,
        value: payrollDetails?.managementFeeWaiver?.billingCycles || 0,
        placeholder: 'Enter number of billing cycles',
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        displayValue: payrollDetails?.managementFeeWaiver?.billingCycles || '-',
        payrollEditable: true,
        adminEditable: true,
        benefitsSpecialistEditable: true,
        type: FIELD_TYPE.NUMBER,
        maxlength: '2',
      },
      {
        ...depositTypeField,
      },
      {
        name: PAYROLL_FIELDS.zero_deposit_fee_currency.name,
        label: PAYROLL_FIELDS.zero_deposit_fee_currency.label,
        value: payrollDetails?.zeroDepositFee?.currency || '',
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        type: FIELD_TYPE.HIDDEN,
      },
      {
        name: PAYROLL_FIELDS.zero_deposit_fee_amount.name,
        label: PAYROLL_FIELDS.zero_deposit_fee_amount.label,
        value: {
          currency:
            payrollDetails?.zeroDepositFee?.currency ||
            payrollDetails?.billingCurrency,
          amount: parseFloat(payrollDetails?.zeroDepositFee?.amount) || 0,
        },
        displayValue: payrollDetails?.zeroDepositFee?.amount || '0',
        type:
          payrollDetails?.depositType === ZERO_DEPOSIT_INSURANCE
            ? FIELD_TYPE.FEE_CURRENCY
            : FIELD_TYPE.HIDDEN,
        disabled: true,

        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        payrollEditable: true,
        adminEditable: true,
        benefitsSpecialistEditable: true,
      },
      {
        name: PAYROLL_FIELDS.setup_fee_paid.name,
        label: PAYROLL_FIELDS.setup_fee_paid.label,
        options: [
          {
            label: 'Yes',
            value: false,
          },
          {
            label: 'No',
            value: true,
          },
        ],
        value: [
          {
            label: 'Yes',
            value: false,
            selected: true,
          },
          {
            label: 'No',
            value: true,
          },
        ],
        hideFromDetailsView: true,
        type: FIELD_TYPE.CHECKBOX,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        payrollEditable: true,
        adminEditable: true,
        benefitsSpecialistEditable: true,
      },
      {
        name: PAYROLL_FIELDS.setup_fee_currency.name,
        label: PAYROLL_FIELDS.setup_fee_currency.label,
        value: payrollDetails?.setupFee?.currency || '',
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        type: FIELD_TYPE.HIDDEN,
      },
      {
        name: PAYROLL_FIELDS.setup_fee_amount.name,
        label: PAYROLL_FIELDS.setup_fee_amount.label,
        value: {
          currency: payrollDetails?.setupFee?.currency || '',
          amount: parseFloat(payrollDetails?.setupFee?.amount) || 0,
        },
        displayValue: payrollDetails?.setupFee?.amount || '0',
        type: FIELD_TYPE.FEE_CURRENCY,
        contextualInfo: !payrollDetails?.setup_fee_paid?.value?.value
          ? 'This is the currency that the set up fee was paid in'
          : 'The currency is the same as the billing currency above',
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        payrollEditable: true,
        adminEditable: true,
        benefitsSpecialistEditable: true,
      },
      {
        name: PAYROLL_FIELDS.setup_fee_structure.name,
        label: PAYROLL_FIELDS.setup_fee_structure.label,
        value: payrollDetails?.setupFeeStructure,
        type: FIELD_TYPE.STRING,
        readOnly: true,
        payrollEditable: true,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        adminEditable: true,
        benefitsSpecialistEditable: true,
      },
      {
        name: PAYROLL_FIELDS.advanced_payment_deposit_percentage.name,
        label: PAYROLL_FIELDS.advanced_payment_deposit_percentage.label,
        value: payrollDetails?.advancedPaymentDepositPercentage || 0,
        type:
          payrollDetails?.depositType === ADVANCED_PAYMENT_DEPOSIT
            ? FIELD_TYPE.NUMBER
            : FIELD_TYPE.HIDDEN,
        showFor: `${UserRoleTypeEnum.ADMIN} ${UserRoleTypeEnum.BENEFITS_SPECIALIST}`,
        payrollEditable: true,
        adminEditable: true,
        benefitsSpecialistEditable: true,
      },
    ],
  };
};

export const transformUpdateEmployeePayload = (payload) => {
  let transformedPayload = {
    ...payload,
    locationOfWorkAddress: payload.locationOfWork?.address,
    locationOfWorkType: payload.locationOfWork?.type,
    holidayAllowance: payload.holidayAllowance?.value,
    holidayAllowanceType: payload.holidayAllowance?.type,
  };

  delete transformedPayload.locationOfWork;
  delete transformedPayload.accountNumber;
  delete transformedPayload.bicSwift;
  delete transformedPayload.routingNumber;
  delete transformedPayload.localName;
  delete transformedPayload.number;
  delete transformedPayload.countryOfIssue;
  delete transformedPayload.branchName;
  delete transformedPayload.iban;

  if (
    payload.accountNumber ||
    payload.bicSwift ||
    payload.routingNumber ||
    payload.branchName ||
    payload.iban
  ) {
    transformedPayload = {
      ...transformedPayload,
      bankingDetails: {
        accountNumber: payload.accountNumber,
        bicSwift: payload.bicSwift,
        routingNumber: payload.routingNumber,
        branchName: payload.branchName,
        iban: payload.iban,
      },
    };
  }

  if (payload.socialInsuranceNumber) {
    transformedPayload = {
      ...transformedPayload,
      socialInsuranceNumber: {
        number: payload.socialInsuranceNumber,
      },
    };
  }

  return transformedPayload;
};

export const transformEmployeeFromEmailInvitation = ({
  userEmployee,
  currentSelectedEmployee,
}) => {
  return {
    ...currentSelectedEmployee,
    administrativeInformation:
      currentSelectedEmployee.administrativeInformation.map((section) => {
        const shouldUpdateInvitationStatus =
          section.name === 'sentInvitationAt';

        if (shouldUpdateInvitationStatus) {
          return userEmployee.administrativeInformation.find(
            ({ name }) => name === 'sentInvitationAt',
          );
        }

        return section;
      }),
  };
};
