import {
  Seat,
  SeatApiRequest,
  SOWStatusEnum,
  TransformedSeat,
} from 'common/types';
import { reduceFormCheckboxValue } from 'utils/transformer-utils/input.transformer';

export const transformInputForAPI = (
  data: Record<string, any>,
  companyId?: string,
): SeatApiRequest => {
  const {
    sowSigned,
    sowStatus,
    id,
    annualBaseSalary,
    managementFeeAmount,
    setupFeeAmount,
    setupFeeStructure,
    employeeType,
    depositType,
    employerCosts,
    fxFee,
    zeroDepositFee,
    depositPercent,
    otherDepositPercent,
    ...rest
  } = data;
  return {
    ...rest,
    ...(companyId ? { companyId } : {}),
    ...(sowStatus ? { sowStatus } : {}),
    ...(annualBaseSalary ||
    managementFeeAmount ||
    setupFeeAmount ||
    managementFeeAmount === 0 ||
    setupFeeAmount === 0 ||
    depositType ||
    employeeType ||
    zeroDepositFee ||
    depositPercent ||
    otherDepositPercent
      ? {
          employeeProfile: {
            ...(annualBaseSalary
              ? {
                  annualBaseSalaryAmount: annualBaseSalary.amount,
                  annualBaseSalaryCurrency: annualBaseSalary.currency,
                }
              : {}),
            ...(managementFeeAmount || managementFeeAmount === 0
              ? {
                  managementFeeAmount,
                }
              : {}),
            ...(setupFeeAmount || setupFeeAmount === 0
              ? {
                  setupFeeAmount,
                }
              : {}),
            ...(setupFeeStructure ? { setupFeeStructure } : {}),
            ...(employeeType ? { employeeType } : {}),
            ...(depositType ? { depositType } : {}),
            ...(employerCosts ? { employerCosts } : {}),
            ...(fxFee ? { fxFee } : {}),
            ...(depositType === 'Zero Deposit Insurance' ||
            (!depositType && zeroDepositFee)
              ? { zeroDepositFee }
              : {}),
            ...(depositType === 'Advanced Payment Deposit (APD)' ||
            (!depositType && (depositPercent || otherDepositPercent))
              ? {
                  advancedPaymentDepositPercentage:
                    depositPercent && depositPercent !== 'Other'
                      ? depositPercent
                      : otherDepositPercent,
                }
              : {}),
          },
        }
      : {}),
    ...(data['sowSigned'] !== undefined && !sowStatus
      ? {
          sowStatus: reduceFormCheckboxValue(sowSigned)
            ? SOWStatusEnum.SOW_ACCEPTED
            : SOWStatusEnum.SOW_AWAITING_PROPOSAL,
        }
      : {}),
  } as SeatApiRequest;
};

export const formatNameOrDefaultToTBC = (
  firstName: string,
  lastName: string,
) => {
  const name = `${firstName} ${lastName}`.trim();

  return !!name.length ? name : 'TBC';
};

export const transformSeatApiResponseToSeat = ({
  firstName = '',
  lastName = '',
  desiredStartDate,
  ...seatApiResponse
}: Seat): TransformedSeat => ({
  ...seatApiResponse,
  name: formatNameOrDefaultToTBC(firstName, lastName),
  desiredStartDate: desiredStartDate ?? '',
});
