import { submitBulkCompensationVariantPayment } from 'common/api/variantPayments.api';
import { SubmitBulkVariantPaymentRequest } from 'common/types/variantPayment';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useSubmitBulkVariantPaymentMutation = () => {
  return useMutation(
    ({ companyId, type, csvFileUrl }: SubmitBulkVariantPaymentRequest) =>
      submitBulkCompensationVariantPayment(companyId, type, csvFileUrl).then(
        getDataFromResponse,
      ),
  );
};
