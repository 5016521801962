import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import styled, { createGlobalStyle } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

import '@omnipresentgroup/design-system/dist/styles.css';
import '@omnipresentgroup/design-tokens/dist/css/fonts.css';
import 'react-loading-skeleton/dist/skeleton.css';

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: ${({ theme: { fonts } }) => fonts.default};
    height: 100%;
    overflow: auto;
    font-size:  ${({ theme: { fontSizes } }) => fontSizes[2]};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: ${({ theme: { colors } }) => colors['link-default']};
  }

  h1, h2 {
    font-family: ${({ theme: { fonts } }) => fonts.default};
    color: ${({ theme: { colors } }) => colors['text-default']};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  }

  body {
    margin: 0;
    height: 100%;
    overflow: auto;
    background: ${({ theme: { colors } }) => colors['background-page']};
  }

  h2 {
    margin: 0;
  }

  h3 {
    color: ${({ theme: { colors } }) => colors['text-default']};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  }

  .stickyHeaderTable {
    thead {
      position:sticky;
      top:0;
      background-color: ${({ theme: { colors } }) =>
        colors['background-primary']};;
    }
  }

  input {
    border: ${({ theme: { borderStyles } }) => borderStyles.none};
    background-image:none;
    background-color:transparent;
    box-shadow: none;
    appearance: none;
    outline: none;
    font-family: ${({ theme: { fonts } }) => fonts.default};
  }

  .radioButton{
    appearance: auto;
    accent-color:  ${({ theme: { colors } }) => colors['gray-500']};
  }

  .smallerStack > * + * {
    margin-top: ${({ theme: { space } }) => space[4]};
  }

  .smallStack > * + * {
    margin-top: ${({ theme: { space } }) => space[8]};
  }

  .stack > * + * {
    margin-top: ${({ theme: { space } }) => space[16]};
  }

  .largerStack > * + * {
    margin-top: ${({ theme: { space } }) => space[16]};
  }

  .bigStack > * + * {
    margin-top: ${({ theme: { space } }) => space[32]};
  }

  .massiveStack > * + * {
    margin-top: ${({ theme: { space } }) => space[64]};
  }

  .alignSelfCenter {
    align-self: center;
  }

  .intercom-lightweight-app {
    z-index: 2 !important;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { space } }) => space[16]};

  padding-bottom: 50px;
`;

export const ProfilePageContent = styled(PageContent)`
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    padding: 0;
  }
`;

export const PageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
`;

export const PrimerModal = styled(ModalWrapper)`
  @media (max-width: ${tokens.breakpoint.laptop}) {
    > div {
      height: 80vh;

      > :last-child {
        padding-bottom: ${({ theme: { space } }) => space[32]};
      }
    }
  }
`;
