import { useContext, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Loading } from 'common/components';
import {
  GrossToNetManualView,
  PAYROLL_CYCLE_LABELS,
  PayrollCycle,
} from 'common/types';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { PayrollInstanceDetailPageContext } from 'omniplatform/admin/pages/PayrollInstanceDetailPage/PayrollInstanceDetailPageContext';
import { lspBillsDetailPage } from 'paths';
import { getCountryByCountryCode } from 'utils/countries';
import { useLspsQuery } from 'utils/queries';
import { useGrossToNetManualViewQuery } from 'utils/queries/payroll/useGrossToNetManualViewQuery';

import {
  Box,
  ContentState,
  Stack,
  Table,
  Typography,
} from '@omnipresentgroup/design-system';

type EnhancedGrossToNetManualView = GrossToNetManualView & {
  lspName: string;
  countryName: string;
};

const columnHelper = createColumnHelper<EnhancedGrossToNetManualView>();

const TableCell = ({
  onClick = () => {},
  value,
  bold = false,
}: {
  onClick?: () => void;
  value: string;
  bold?: boolean;
}) => {
  return (
    <Typography
      as="span"
      size="14"
      weight={bold ? 'medium' : 'regular'}
      onClick={onClick}
    >
      {value}
    </Typography>
  );
};

export const LspBillsTab = () => {
  const history = useHistory();
  const { payrollInstanceId } = useParams<{ payrollInstanceId: string }>();
  const { payrollPeriod } = useContext(PayrollInstanceDetailPageContext);

  const {
    data: grossToNetManualViewData = [],
    isLoading: grossToNetManualViewLoading,
  } = useGrossToNetManualViewQuery(payrollPeriod);

  const { data: lspData = [], isLoading: lspDataLoading } = useLspsQuery();

  const enhancedGrossToNetManualViewData: EnhancedGrossToNetManualView[] =
    grossToNetManualViewData
      .map((grossToNetManualView) => {
        const fullLsp = lspData.find(
          (lsp) => lsp.id === grossToNetManualView.lspId,
        );
        return {
          ...grossToNetManualView,
          lspName: fullLsp?.name || 'LSP not found',
          countryName:
            getCountryByCountryCode(grossToNetManualView.country)?.label ||
            grossToNetManualView.country,
        };
      })
      .sort((a, b) => {
        const compare1 = a.countryName.localeCompare(b.countryName);
        if (compare1 !== 0) {
          return compare1;
        }

        const compare2 = a.lspName.localeCompare(b.lspName);
        if (compare2 !== 0) {
          return compare2;
        }

        return Number(a.payrollCycle) - Number(b.payrollCycle);
      });

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('countryName', {
        cell: (info) => {
          return (
            <TableCell
              value={startCase(info.getValue())}
              onClick={() =>
                history.push(
                  lspBillsDetailPage(payrollInstanceId, {
                    country: info.row.original.country,
                    lspId: info.row.original.lspId,
                    payrollCycle: info.row.original.payrollCycle,
                    payrollPeriod,
                  }),
                )
              }
              bold
            />
          );
        },
        header: 'Country',
      }),
      columnHelper.accessor('lspName', {
        cell: (info) => <TableCell value={info.getValue()} />,
        header: 'Provider',
      }),
      columnHelper.accessor('payrollCycle', {
        cell: (info) => {
          const payrollCycle = info.getValue();

          return (
            <TableCell
              value={
                payrollCycle === PayrollCycle.OFF_CYCLE
                  ? PAYROLL_CYCLE_LABELS[payrollCycle]
                  : payrollCycle
              }
            />
          );
        },
        header: 'Payroll cycle',
      }),
    ];
  }, [history, payrollInstanceId, payrollPeriod]);

  const table = useReactTable({
    columns: columns,
    data: enhancedGrossToNetManualViewData,
    getCoreRowModel: getCoreRowModel(),
  });

  if (grossToNetManualViewLoading || lspDataLoading) {
    return <Loading />;
  }

  if (isEmpty(enhancedGrossToNetManualViewData)) {
    return (
      <ContentState
        variant="empty"
        description="The race to be the first to upload an LSP invoice this month (haha...)"
        id="empty-state"
        title="Did you know you won the race?"
      />
    );
  }

  return (
    <Box bg="primary" radius="m" border="subtle">
      <Stack>
        <Box>
          <Table
            table={table}
            wrapperProps={{ style: { border: 'none' } }}
            showHeader={false}
          />
        </Box>
      </Stack>
    </Box>
  );
};
