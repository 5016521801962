import { getUserSelector } from 'app/store/app.selectors';
import { getEmployer, getEmployers } from 'common/api/employers.api';
import { Employer, EmployerApiResponse } from 'common/types';
import { useQuery, UseQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';
import { getCountryByCountryName } from 'utils/countries';
import { isAdmin } from 'utils/user';

import { getDataFromResponse } from '../../../react-query-utils';
import { sortAlphabetically } from '../../../sort.util';
import { employersKeys } from '../keys';

type UseEmployersParams = UseEmployerQueryParams & UseEmployersFilterParams;

export type EmployerFilterOptions = {
  showCountryCode?: boolean;
  isOmniEntity?: boolean;
  isActive?: boolean;
  countryName?: string;
};

type UseEmployersFilterParams = {
  appendCountryCodeToName?: boolean;
  countryCode?: string;
  isOmniEntity?: boolean;
  lspId?: string;
};

type UseEmployerQueryParams = {
  isActive?: boolean;
  key?: any;
};

export const useEmployersQuery = ({
  isActive,
  key,
  appendCountryCodeToName,
  countryCode,
  isOmniEntity,
  lspId,
}: UseEmployersParams = {}) => {
  const user = useSelector(getUserSelector);
  const hasPermissionToSeeEmployers = isAdmin(user);

  return useQuery<EmployerApiResponse[], Error>(
    key || employersKeys.default,
    () => getEmployers({ isActive, lspId }).then(getDataFromResponse),
    {
      enabled: hasPermissionToSeeEmployers,
      select: (data) => {
        let sorted = sortAlphabetically('name', data);
        if (countryCode) {
          sorted = sorted.filter(
            (employer) => employer.countryCode === countryCode,
          );
        }
        if (isOmniEntity !== undefined) {
          sorted = sorted.filter(
            (employer) => employer.isOmniEntity === isOmniEntity,
          );
        }

        if (appendCountryCodeToName) {
          return sorted.map((employer: Employer) => ({
            ...employer,
            name: `${employer.name} (${employer.countryCode})`,
          }));
        }
        return sorted;
      },
    },
  );
};

export const useEmployerQuery = (
  id: string,
  {
    queryOptions,
  }: {
    queryOptions?: Pick<UseQueryOptions, 'staleTime' | 'cacheTime' | 'enabled'>;
  } = {},
) => {
  const user = useSelector(getUserSelector);
  const hasPermissionToSeeEmployers = isAdmin(user);
  return useQuery<EmployerApiResponse, Error>(
    employersKeys.single(id),
    () => getEmployer(id).then(getDataFromResponse),
    {
      enabled: hasPermissionToSeeEmployers,
      staleTime: queryOptions?.staleTime || Infinity,
      ...(queryOptions || {}),
    },
  );
};

export const useEmployersFilterQuery = ({
  isActive,
  showCountryCode,
  countryName,
  isOmniEntity,
}: EmployerFilterOptions) => {
  return useEmployersQuery({
    isActive,
    key: employersKeys.active,
    appendCountryCodeToName: !!showCountryCode || false,
    countryCode: countryName && getCountryByCountryName(countryName)?.value,
    isOmniEntity,
  });
};
