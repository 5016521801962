import styled from 'styled-components';

import { Button } from '@omnipresentgroup/design-system';
import tokens from '@omnipresentgroup/design-tokens';

export const StyledStagedForm = styled.div<{
  noMargin?: boolean;
  formWidth?: string;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ formWidth }) =>
    formWidth && formWidth.length ? formWidth : '100%'};
  margin-left: ${({ theme: { space }, noMargin }) =>
    noMargin ? 0 : space[32]};
  max-width: 600px;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-left: 0;
    width: 100%;
  }
`;

export const StyledStagedFormWrapper = styled.div<{
  borderless?: boolean;
  padding?: string;
}>`
  padding: ${({ theme: { space }, padding }) =>
    `${padding && padding.length ? padding : space[16]}`};
  background: ${({ theme: { colors } }) => colors['background-primary']};
  border: ${({ theme: { borderWidths, colors, borderStyles }, borderless }) =>
    `${
      borderless
        ? ``
        : `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`
    }`};
  border-radius: ${({ theme: { radii } }) => radii.m};

  @media (min-width: ${tokens.breakpoint.laptop}) {
    padding: ${({ theme: { space }, padding }) =>
      `${padding && padding.length ? padding : space[32]}`};
  }
`;

export const StyledStagedFormHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    width: 100%;
    margin-top: ${({ theme: { space } }) => space[4]};
  }
`;

export const StyledStagedFormRequiredFields = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: ${({ theme: { colors } }) => `${colors['text-subtle']}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
  margin-top: ${({ theme: { space } }) => space[16]};
  margin-bottom: ${({ theme: { space } }) => space[16]};
`;

export const StyledCTAContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${({ theme: { space } }) => space[16]};
`;

export const StyledFormContainer = styled.div`
  width: 100%;
`;

export const StyledStagedFormPage = styled.div``;

export const StyledRightAlignButton = styled(Button)`
  margin-left: auto;
`;

export const StyledSpacerHr = styled.div`
  padding-right: ${({ theme: { space } }) => space[16]};
`;
