import { HTTPStatusCodesEnum } from 'common/constants';
import {
  EmploymentModel,
  PatchEmploymentModel,
  PostEmploymentModel,
} from 'common/types/employments';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { PathParams, rest } from 'msw';

export const employmentsHandlers = [
  rest.get<EmploymentModel[]>(
    composeEndpoint('/employments'),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockEmployments)),
  ),
  rest.get<EmploymentModel>(
    composeEndpoint('/employments/:employmentId'),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockEmployment)),
  ),
  rest.post<PostEmploymentModel, PathParams, EmploymentModel>(
    composeEndpoint('/employments'),
    (req, res, ctx) => res(ctx.status(201), ctx.json(mockEmployment)),
  ),
  rest.patch<PatchEmploymentModel>(
    composeEndpoint('/employments/:employmentId'),
    (req, res, ctx) => res(ctx.status(204)),
  ),
];

const mockEmployment: EmploymentModel = {
  id: '1',
  userId: '1',
  type: 'EOR',
  companyId: '1',
  lspId: 'LSP-21-001',
  lspName: 'test-lsp-name',
  status: 'EMPLOYMENT_STATUS_CREATED',
  startDate: '2020-01-01',
  terminationDate: '2020-01-01',
  firstPayrollDate: '2020-01-01',
  lastPayrollDate: '2020-01-01',
  createdAt: '2020-01-01',
  lastUpdatedAt: '2020-01-01',
};

const mockEmployment1: EmploymentModel = {
  id: '2',
  userId: '2',
  type: 'EOR',
  companyId: '2',
  lspId: 'LSP-21-001',
  lspName: 'test-lsp-name',
  status: 'EMPLOYMENT_STATUS_ACTIVE',
  startDate: '2020-01-01',
  terminationDate: '2020-01-01',
  firstPayrollDate: '2020-01-01',
  lastPayrollDate: '2020-01-01',
  createdAt: '2020-01-01',
  lastUpdatedAt: '2020-01-01',
  activeAt: '2021-02-02T22:22:22.222Z',
};
const mockEmployments: EmploymentModel[] = [mockEmployment, mockEmployment1];
