import { useEffect, useState } from 'react';

import { FIVE_SECONDS } from 'common/constants';
import { TimeOffLog } from 'common/types';
import { useDeleteTimeOffMutation } from 'utils/queries';

import Button from '../../atoms/Button/Button';
import * as Styled from './TimeOffAction.styles';

type TimeOffActionProps = TimeOffLog;

export const TimeOffAction = ({ id }: TimeOffActionProps) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const { mutate: deleteTimeOffMutation } = useDeleteTimeOffMutation();

  const { deleteLabel, handleDeleteClick } = isDeleting
    ? {
        deleteLabel: 'Confirm Delete',
        handleDeleteClick: () => deleteTimeOffMutation(id),
      }
    : {
        deleteLabel: 'Delete',
        handleDeleteClick: () => setIsDeleting(true),
      };

  useEffect(() => {
    let labelTimeout: ReturnType<typeof setTimeout>;

    if (isDeleting) {
      labelTimeout = setTimeout(() => setIsDeleting(false), FIVE_SECONDS);
    }

    return () => clearTimeout(labelTimeout);
  }, [isDeleting]);

  return (
    <Styled.DeleteContainer>
      <Button variant="link" palette="primary" onClick={handleDeleteClick}>
        {deleteLabel}
      </Button>
    </Styled.DeleteContainer>
  );
};
