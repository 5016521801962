import { storeByRole } from 'app/store/store';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

function getStoreByRoleType<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

type UserRole = keyof typeof storeByRole;
const reducerByRole = (userRole: UserRole) =>
  getStoreByRoleType(storeByRole, userRole).reducer;

const adminRootState = reducerByRole('admin');
const managerRootState = reducerByRole('manager');
const employeeRootState = reducerByRole('employee');

export type AdminRootState = ReturnType<typeof adminRootState>;
export type ManagerRootState = ReturnType<typeof managerRootState>;
export type EmployeeRootState = ReturnType<typeof employeeRootState>;

export const useAdminSelector: TypedUseSelectorHook<AdminRootState> =
  useSelector;
export const useManagerSelector: TypedUseSelectorHook<ManagerRootState> =
  useSelector;
export const useEmployeeSelector: TypedUseSelectorHook<EmployeeRootState> =
  useSelector;
