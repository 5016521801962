import { client } from 'api';
import {
  CreateVariantPaymentRequest,
  GetVariantPaymentsResponse,
  QueryParams,
} from 'common/types/variantPayment';

export const createVariantPayment = (payload: CreateVariantPaymentRequest) => {
  return client.post(`/variant-payments`, payload);
};

export const validateBulkCompensationVariantPayment = (
  companyId: string,
  type: string,
  url: string,
) =>
  client.post('/variant-payments/bulk-validation', {
    companyId,
    type,
    url,
  });

export const submitBulkCompensationVariantPayment = (
  companyId: string,
  type: string,
  url: string,
) =>
  client.post('/variant-payments/bulk', {
    companyId,
    type,
    url,
  });

export const getVariantPayments = (companyId: string, params?: QueryParams) =>
  client.get<GetVariantPaymentsResponse>(
    `/variant-payments/company/${companyId}`,
    { params },
  );
