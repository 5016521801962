import { BenefitsClientPreferencesResponse } from 'common/types/benefits';

export const mockBenefitsClientPreferencesResponses: Record<
  string,
  BenefitsClientPreferencesResponse
> = {
  NL: {
    id: 'NL-001',
    countryCode: 'NL',
    selectionStatus: 'NO',
    clientId: 'CL-NL-01',
  },
  UK: {
    id: 'UK-002',
    countryCode: 'GB',
    selectionStatus: 'YES',
    clientId: 'CL-UK-02',
  },
  AU: {
    id: 'UK-003',
    countryCode: 'GB',
    selectionStatus: 'REMIND_LATER',
    clientId: 'CL-AU-03',
  },
  DE: {
    id: 'DE-004',
    countryCode: 'DE',
    selectionStatus: 'YES',
    clientId: 'CL-DE-04',
  },
  GB: {
    // this is catering for failed tests in EditContract.test.tsx for countryCode 'GB'
    id: 'UK-002-2',
    countryCode: 'GB',
    selectionStatus: 'YES',
    clientId: 'CL-UK-02',
  },
  PK: {
    id: 'DE-004',
    countryCode: 'DE',
    selectionStatus: 'YES',
    clientId: 'CL-DE-04',
  },
};

export const mockCompanyBenefitsPreferencesResponses: Array<BenefitsClientPreferencesResponse> =
  [
    {
      id: 'AT-001',
      countryCode: 'AT',
      selectionStatus: 'YES',
      clientId: 'OuanZbYPCFsSQaQpJ96gn',
    },
    {
      id: 'UK-002',
      countryCode: 'GB',
      selectionStatus: 'REMIND_LATER',
      clientId: 'OuanZbYPCFsSQaQpJ96gn',
    },
    {
      id: 'PK-003',
      countryCode: 'PK',
      selectionStatus: 'YES',
      clientId: 'CL-PK-03',
    },
  ];
