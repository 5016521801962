import { useEffect, useRef, useState } from 'react';

import Button from 'common/components/atoms/Button';
import EditableInput from 'common/components/molecules/EditableInput/EditableInput';
import {
  ActionButtons,
  FilterContainer,
  FilterElement,
  FilterPopupTitle,
  FilterText,
} from 'common/components/molecules/SeatTabFilter/SeatTabFilter.styles';
import { FIELD_TYPE } from 'common/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Filter } from 'react-feather';
import { useOnClickOutside } from 'utils/hooks';

import { InitialInvoiceStatusType, SimpleInvoiceType } from '../../types';
import {
  FilterButton,
  FilterDropDownContainer,
  FilterHeading,
  FilterIconContainer,
  FilterPopupContainer,
  FilterTitle,
  StatusWrapper,
} from './MonitoringFilterDropDown.styles';

export type FilterItem<T> = {
  id: T;
  label: string;
};

export type Props = {
  filters: InvoicesMonitoringFilterType;
  onFilterUpdate: (filters: InvoicesMonitoringFilterType) => void;
};

const STATUS_FILTER: FilterItem<InitialInvoiceStatusType>[] = [
  { id: InitialInvoiceStatusType.SUCCESS, label: 'Processed' },
  { id: InitialInvoiceStatusType.FAILED, label: 'Failed' },
  { id: InitialInvoiceStatusType.SUBMITTED, label: 'Submitted' },
];

export type InvoicesMonitoringFilterType = {
  statuses: InitialInvoiceStatusType[];
  invoiceTypes: SimpleInvoiceType[];
  fromDate?: string;
  toDate?: string;
  currency?: string;
  employeeCountry?: string;
  clientOrEmployeeNameQuery?: string;
};

export const INITIAL_FILTER_STATE = {
  statuses: [],
  invoiceTypes: [],
};

export const MonitoringFilterDropDown = ({
  filters,
  onFilterUpdate,
}: Props) => {
  const { enableNetSuiteInitialInvoiceIntegration } = useFlags();

  const TYPE_FILTER: FilterItem<SimpleInvoiceType>[] = [
    {
      id: SimpleInvoiceType.SETUP_DEPOSIT_FEE,
      label: 'Setup Fee and Deposit',
    },
    {
      id: SimpleInvoiceType.INITIAL_SALARY_RETAINER,
      label: 'Salary pre-payment',
    },
    ...(enableNetSuiteInitialInvoiceIntegration
      ? [
          {
            id: SimpleInvoiceType.SETUP_FEE_ONLY,
            label: 'Setup Fee',
          },
          {
            id: SimpleInvoiceType.DEPOSIT_ONLY,
            label: 'Deposit',
          },
        ]
      : []),
  ];

  const elementRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [currentFilter, updateCurrentFilter] =
    useState<InvoicesMonitoringFilterType>(filters);

  const toggle = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  useOnClickOutside(elementRef, () => {
    updateCurrentFilter(filters);
    setExpanded(false);
  });

  useEffect(() => {
    updateCurrentFilter(filters);
  }, [filters]);

  return (
    <FilterDropDownContainer
      ref={elementRef}
      data-testid="monitoring-filter-container"
    >
      <FilterButton onClick={toggle} data-testid="filter-button">
        <FilterIconContainer>
          <Filter />
        </FilterIconContainer>
        <FilterText>
          Filter (
          {
            Object.entries(filters).filter(
              ([key, value]) =>
                [
                  'statuses',
                  'invoiceTypes',
                  'currency',
                  'employeeCountry',
                  'fromDate',
                  'toDate',
                ].includes(key) && value?.length,
            ).length
          }
          )
        </FilterText>
      </FilterButton>
      {expanded && (
        <FilterPopupContainer data-testid="filter-popup-container">
          <FilterPopupTitle>Filter table by</FilterPopupTitle>
          <FilterContainer>
            <FilterHeading>
              <FilterTitle>Status</FilterTitle>
              <FilterTitle
                clearLink
                onClick={() => {
                  updateCurrentFilter({ ...currentFilter, statuses: [] });
                }}
              >
                Clear
              </FilterTitle>
            </FilterHeading>
            <FilterElement>
              {STATUS_FILTER.map((status, index) => (
                <StatusWrapper key={index}>
                  <EditableInput
                    name={status.id}
                    noLabel
                    type={FIELD_TYPE.CHECKBOX_BOOLEAN}
                    value={currentFilter.statuses.indexOf(status.id) > -1}
                    onChange={(name: InitialInvoiceStatusType, value: any) => {
                      const filterStatus = [...currentFilter.statuses];

                      if (value) {
                        filterStatus.push(name);
                      } else {
                        filterStatus.splice(filterStatus.indexOf(name), 1);
                      }

                      updateCurrentFilter({
                        ...currentFilter,
                        statuses: filterStatus,
                      });
                    }}
                  />
                  <FilterTitle>{status.label}</FilterTitle>
                </StatusWrapper>
              ))}
            </FilterElement>

            <FilterHeading>
              <FilterTitle>Type</FilterTitle>
              <FilterTitle
                clearLink
                onClick={() => {
                  updateCurrentFilter({ ...currentFilter, invoiceTypes: [] });
                }}
              >
                Clear
              </FilterTitle>
            </FilterHeading>
            <FilterElement>
              {TYPE_FILTER.map((type, index) => (
                <StatusWrapper key={index}>
                  <EditableInput
                    name={type.id}
                    noLabel
                    type={FIELD_TYPE.CHECKBOX_BOOLEAN}
                    value={currentFilter.invoiceTypes.indexOf(type.id) > -1}
                    onChange={(name: SimpleInvoiceType, value: any) => {
                      const filterType = [...currentFilter.invoiceTypes];

                      if (value) {
                        filterType.push(name);
                      } else {
                        filterType.splice(filterType.indexOf(name), 1);
                      }

                      updateCurrentFilter({
                        ...currentFilter,
                        invoiceTypes: filterType,
                      });
                    }}
                  />
                  <FilterTitle>{type.label}</FilterTitle>
                </StatusWrapper>
              ))}
            </FilterElement>

            <FilterHeading>
              <FilterTitle>Currency</FilterTitle>
            </FilterHeading>
            <FilterElement>
              <EditableInput
                name="currency"
                noLabel
                value={currentFilter?.currency}
                type={FIELD_TYPE.CURRENCY}
                onChange={(_: any, val: any) => {
                  updateCurrentFilter({
                    ...currentFilter,
                    ...(val ? { currency: val } : {}),
                  });
                }}
                key="currency"
              />
            </FilterElement>

            <FilterHeading>
              <FilterTitle>Employee Country</FilterTitle>
            </FilterHeading>
            <FilterElement>
              <EditableInput
                name="employeeCountry"
                noLabel
                value={currentFilter?.employeeCountry}
                type={FIELD_TYPE.COUNTRY}
                onChange={(_: any, val: any) => {
                  updateCurrentFilter({
                    ...currentFilter,
                    ...(val ? { employeeCountry: val } : {}),
                  });
                }}
                key="employeeCountry"
              />
            </FilterElement>

            <FilterHeading>
              <FilterTitle>From</FilterTitle>
            </FilterHeading>
            <FilterElement>
              <EditableInput
                name="fromDate"
                noLabel
                value={currentFilter.fromDate}
                type={FIELD_TYPE.DATE}
                onChange={(_: any, val: any) => {
                  updateCurrentFilter({
                    ...currentFilter,
                    ...(val ? { fromDate: val } : {}),
                  });
                }}
                placeholder="Please select"
                key="fromdatepicker"
              />
            </FilterElement>

            <FilterHeading>
              <FilterTitle>To</FilterTitle>
            </FilterHeading>
            <FilterElement>
              <EditableInput
                name="toDate"
                noLabel
                value={currentFilter.toDate}
                type={FIELD_TYPE.DATE}
                onChange={(_: any, val: any) => {
                  updateCurrentFilter({
                    ...currentFilter,
                    ...(val ? { toDate: val } : {}),
                  });
                }}
                key="todatepicker"
              />
            </FilterElement>

            <ActionButtons>
              <Button
                style={{ marginRight: 16 }}
                variant="outlined"
                palette="secondary"
                onClick={() => {
                  setExpanded(false);
                  onFilterUpdate(INITIAL_FILTER_STATE);
                }}
              >
                Clear
              </Button>
              <Button
                palette="primary"
                onClick={() => {
                  setExpanded(false);
                  onFilterUpdate(currentFilter);
                }}
              >
                Apply filters
              </Button>
            </ActionButtons>
          </FilterContainer>
        </FilterPopupContainer>
      )}
    </FilterDropDownContainer>
  );
};
