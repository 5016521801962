import { payrollServiceClient } from 'api';
import { AxiosResponse } from 'axios';

export type BillingEntityEntry = {
  billingEntityId: string;
  companyId: string;
  directDebitSetupUrl: string;
  isDirectDebit: boolean;
  billingEntitiesInfo: {
    enrolledCurrencies: string;
    formFlag: boolean;
    isDirectDebit: boolean;
    isMultiBillingEntity: boolean;
  };
};

export const PATH = `billing-entities`;
export type BillingEntitiesAPIResponse = AxiosResponse<BillingEntityEntry[]>;

export const getBillingEntitiesRequest = (companyId: string) =>
  payrollServiceClient.get<BillingEntitiesAPIResponse>(`/${PATH}`, {
    params: { companyId },
  });
