import { employerCostsResponse } from '__mocks__/calculator/employerCosts';
import { HTTPStatusCodesEnum } from 'common/constants';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

export const employerCostsHandlers = [
  rest.get(composeEndpoint('/calculator/employer-costs'), (req, res, ctx) =>
    res(
      ctx.status(HTTPStatusCodesEnum.OK),
      ctx.json({ ...employerCostsResponse }),
    ),
  ),
];
