import { AxiosError } from 'axios';
import { editUser } from 'common/api/users.api';
import { AdditionalAdminRoleType, UserRole } from 'common/types';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

type EditAdminUserFormSubmissionResult = {
  currentIdAdminRole?: string;
  additionalAdminRoles?: AdditionalAdminRoleType[];
};
type EditAdminUserRequestPayload = {
  roles: UserRole[];
};

export const useEditAdminUserMutation = (userId: string) => {
  return useMutation<void, AxiosError, EditAdminUserFormSubmissionResult>(
    ({ currentIdAdminRole, additionalAdminRoles }) => {
      const editAdminUserRequestPayload = {
        roles: [],
      } as EditAdminUserRequestPayload;

      if (currentIdAdminRole) {
        editAdminUserRequestPayload.roles.push({
          type: 'admin',
          id: currentIdAdminRole,
        });
      } else {
        editAdminUserRequestPayload.roles.push({
          type: 'admin',
        });
      }

      if (additionalAdminRoles) {
        additionalAdminRoles.forEach((role) =>
          editAdminUserRequestPayload.roles.push({
            type: role,
          }),
        );
      }

      return editUser(userId, editAdminUserRequestPayload).then(
        getDataFromResponse,
      );
    },
  );
};
