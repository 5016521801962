import { queryClient } from 'app/queryClient';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { createTemplateMapping, PATH } from '../api';
import {
  CreateTemplateMappingAPIResponse,
  CreateTemplateMappingPayload,
} from '../types';

export const useCreateTemplateMappingMutation = () =>
  useMutation<
    CreateTemplateMappingAPIResponse,
    AxiosError,
    CreateTemplateMappingPayload
  >((data: CreateTemplateMappingPayload) => createTemplateMapping(data), {
    onSuccess: () => queryClient.invalidateQueries(PATH),
  });
