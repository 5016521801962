import { Asset, Entry, EntryCollection } from 'contentful';

const isAsset = (object: any): boolean => object?.sys?.type === 'Asset';
const isEntry = (object: any): boolean => object?.sys?.type === 'Entry';
const transformContentAsset = (asset: Asset) => asset.fields;

// TODO checkout client.parseEntries - https://contentful.github.io/contentful.js/contentful/8.4.2/ContentfulClientAPI.html#.parseEntries
export const transformContentfulEntry = (entry: Entry<any>) => {
  const { fields, sys: { id } = {}, metadata: { tags } = {} } = entry;

  const tagsIdList = tags?.map(({ sys }) => sys.id) ?? [];

  return Object.keys(fields).reduce(
    (accumulator, field) => {
      const fieldValue = fields[field];

      if (isAsset(fieldValue)) {
        (accumulator as any)[field] = transformContentAsset(fieldValue);
      } else if (isEntry(fieldValue)) {
        (accumulator as any)[field] = transformContentfulEntry(fieldValue);
      } else {
        (accumulator as any)[field] = fieldValue;
      }

      return accumulator;
    },
    { id, tags: tagsIdList },
  );
};

export const transformContentfulCollection = <T>(
  collection: EntryCollection<T>,
) => collection.items.map((entry) => transformContentfulEntry(entry));
