import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Amount } from 'common/types';
import {
  GetVariantPaymentsResponseItem,
  VariantPaymentFrequencyEnum,
  VariantPaymentTypesEnum,
} from 'common/types/variantPayment';
import { DateTime } from 'luxon';
import Skeleton from 'react-loading-skeleton';
import { toSentenceCase } from 'utils/string-utils';

import {
  DateFormats,
  formatISODate,
  Icon,
  Inline,
  Tooltip,
} from '@omnipresentgroup/design-system';

// const getIntentForPaymentStatus = (
//   type: VariantPaymentStatusEnum,
// ): TagIntent => {
//   switch (type) {
//     case VariantPaymentStatusEnum.requested:
//       return 'info';
//     case VariantPaymentStatusEnum.deleted:
//       return 'error';
//     case VariantPaymentStatusEnum.ended:
//       return 'default';
//     default:
//       return 'default';
//   }
// };

export enum ColumnHeaders {
  employeeName = 'Employee name',
  reason = '',
  type = 'Type',
  amount = 'Gross amount',
  effectiveDate = 'Starting month',
  requestedAt = 'Request date',
  frequency = 'Frequency',
  endDate = 'Ending month',
}

const columnHelper = createColumnHelper<GetVariantPaymentsResponseItem>();

export const Columns: ColumnDef<GetVariantPaymentsResponseItem, any>[] = [
  columnHelper.accessor('employeeName', {
    cell: (info) => info?.getValue(),
    header: () => ColumnHeaders.employeeName,
    size: 200,
  }),
  columnHelper.accessor('reason', {
    cell: (info) =>
      info?.getValue() ? (
        <Inline justify="center">
          <Tooltip
            id={`reason-tooltip-${info.row.id}`}
            description={info.getValue()}
          >
            <Icon size="16" icon="InfoCircle" title="" />
          </Tooltip>
        </Inline>
      ) : (
        <></>
      ),
    header: () => ColumnHeaders.reason,
    size: 16,
  }),
  columnHelper.accessor('type', {
    cell: (info) => {
      const type =
        VariantPaymentTypesEnum[
          info?.getValue() as keyof typeof VariantPaymentTypesEnum
        ];
      const allowanceType = info?.row.original.allowanceType ?? '';
      return toSentenceCase(
        type !== VariantPaymentTypesEnum.allowance ? type : allowanceType,
      );
    },
    header: () => ColumnHeaders.type,
    size: 150,
  }),
  columnHelper.accessor('amount', {
    cell: (info) => {
      const value: Amount = info?.getValue();
      return `${value?.currency?.toUpperCase()} ${value?.amount?.toFixed(2)}`;
    },
    header: () => ColumnHeaders.amount,
    size: 120,
  }),
  columnHelper.accessor('frequency', {
    cell: (info) => {
      return info?.getValue()
        ? toSentenceCase(info?.getValue() as string)
        : null;
    },
    header: () => ColumnHeaders.frequency,
    size: 100,
  }),
  columnHelper.accessor('effectiveDate', {
    cell: (info) =>
      info?.getValue()
        ? DateTime.fromISO(info?.getValue() as string).toFormat('MMM yyyy')
        : null,
    header: () => ColumnHeaders.effectiveDate,
    size: 120,
  }),
  columnHelper.accessor('endDate', {
    cell: (info) => {
      const recurring =
        info?.row.original.frequency &&
        info.row.original.frequency !== VariantPaymentFrequencyEnum.ONE_OFF;
      return info?.getValue()
        ? DateTime.fromISO(info?.getValue() as string).toFormat('MMM yyyy')
        : recurring
          ? 'Contract end'
          : null;
    },
    header: () => ColumnHeaders.endDate,
    size: 120,
  }),
  columnHelper.accessor('requestedAt', {
    cell: (info) =>
      info?.getValue()
        ? formatISODate(info?.getValue() as string, DateFormats.Date)
        : null,
    header: () => ColumnHeaders.requestedAt,
    size: 100,
  }),
];

export const LoadingColumns: ColumnDef<any, any>[] = [
  columnHelper.display({
    id: 'employeeName',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.employeeName,
    size: 200,
  }),
  columnHelper.display({
    id: 'reason',
    cell: () => (
      <Inline justify="center">
        <Skeleton width={16} height={16} circle />
      </Inline>
    ),
    maxSize: 20,
    header: () => ColumnHeaders.reason,
    size: 16,
  }),
  columnHelper.display({
    id: 'type',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.type,
    size: 150,
  }),
  columnHelper.display({
    id: 'amount',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.amount,
    size: 100,
  }),
  columnHelper.display({
    id: 'frequency',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.frequency,
    size: 10,
  }),
  columnHelper.display({
    id: 'effectiveDate',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.effectiveDate,
    size: 120,
  }),
  columnHelper.display({
    id: 'endDate',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.endDate,
    size: 120,
  }),
  columnHelper.display({
    id: 'requestedAt',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.requestedAt,
    size: 120,
  }),
];
