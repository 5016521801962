import { client } from 'api';
import {
  ContractAmendmentFormApiRequest,
  ContractAmendmentUpdateBody,
} from 'common/types';

export const createContractAmendmentRequest = (
  contractAmendmentRequest: ContractAmendmentFormApiRequest,
) => {
  return client.post('/amendments', contractAmendmentRequest);
};

export const getContractAmendments = async (
  documentId?: string,
  employeeId?: string,
) => {
  return client.get(`/amendments`, { params: { documentId, employeeId } });
};

export const dispatchAmendmentRequest = (
  amendmentId: string,
  body: ContractAmendmentUpdateBody,
) => {
  return client.post(`/amendments/${amendmentId}/dispatch`, body);
};
