import { contractorsClient } from 'api';
import { HTTPStatusCodesEnum, ONE } from 'common/constants';
import {
  ContractorInvoiceStateEnum,
  PermittedInvoiceCurrenciesEnum,
  type ContractorInvoiceAPI,
  type ContractorInvoiceDetailsAPI,
  type GetContractorInvoiceResponseAPI,
  type GetContractorInvoicesResponseAPI,
} from 'common/types/contractorInvoices';
import { composeEndpoint } from 'mocks/utils';
import { rest } from 'msw';

import { DEFAULT_COMPANY } from './companies.msw';

const generateDescription = (description?: string) =>
  description ??
  `Hey Mark,
  Attached is the invoice for the last detailed description of the invoice can go here.`;

export const DEFAULT_CONTRACTOR_INVOICE: ContractorInvoiceAPI = {
  id: '9e0fefc5-d7b1-4adc-b1ec-8a714c8f78c4',
  state: ContractorInvoiceStateEnum.OPEN,
  contractor: {
    id: '6dcf906e-5794-4bf4-8a71-7d2eabc02e89',
    name: 'Angus Campbell',
  },
  client: {
    id: DEFAULT_COMPANY.id,
    name: DEFAULT_COMPANY.name,
  },
  amount: 5000,
  currency: PermittedInvoiceCurrenciesEnum.USD,
  due_date: '2024-02-06',
  description: generateDescription(),
  raised_at: '2024-02-06T10:55:05.285Z',
  paid_at: '',
  overdue_by: '',
};

export const DEFAULT_CONTRACTOR_INVOICE_DETAILS: ContractorInvoiceDetailsAPI = {
  ...DEFAULT_CONTRACTOR_INVOICE,
  invoice_ref: 'INV-2142141',
};

export const generateInvoice = (
  override: Partial<ContractorInvoiceAPI> = {},
) => ({
  ...DEFAULT_CONTRACTOR_INVOICE,
  ...override,
});

const OPEN_INVOICE = generateInvoice({
  id: 'd0bf48c6-3172-484c-89e0-4f053a08c3a5',
  amount: 1500,
  state: ContractorInvoiceStateEnum.OPEN,
});

const PAID_INVOICE = generateInvoice({
  id: 'c6534a2f-b31c-4b1a-aa43-a22a2168cf33',
  amount: 150,
  state: ContractorInvoiceStateEnum.PAID,
  paid_at: '2024-02-06T10:55:05.285Z',
});

const OVERDUE_INVOICE = generateInvoice({
  id: 'd0bf48c6-3172-484c-89e0-4f053a07c3a1',
  amount: 1500,
  state: ContractorInvoiceStateEnum.OPEN,
  overdue_by: '15',
});

const CANCELLED_INVOICE = generateInvoice({
  id: '00a15683-f0cc-456a-ab59-32e1c6399ce1',
  amount: 1500,
  state: ContractorInvoiceStateEnum.CANCELLED,
});

const IN_PROGRESS_INVOICE = generateInvoice({
  id: '293cbdc8-40e6-49d0-b02e-31ebae41eb65',
  amount: 1500,
  state: ContractorInvoiceStateEnum.IN_PROGRESS,
  paid_at: '2024-02-06T10:55:05.285Z',
});

const PENDING_INVOICE = generateInvoice({
  id: '5cd86307-2f1c-4101-be45-a656833f391d',
  amount: 1500,
  state: ContractorInvoiceStateEnum.PENDING,
});

export const DEFAULT_CONTRACTORS_INVOICES = [
  OPEN_INVOICE,
  PAID_INVOICE,
  OVERDUE_INVOICE,
  CANCELLED_INVOICE,
  IN_PROGRESS_INVOICE,
  PENDING_INVOICE,
];

export const contractorInvoicesHandlers = [
  rest.get<GetContractorInvoicesResponseAPI>(
    composeEndpoint(`/invoices`, contractorsClient),
    (_, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          items: DEFAULT_CONTRACTORS_INVOICES,
          total: DEFAULT_CONTRACTORS_INVOICES.length,
          page: ONE,
          size: 20,
          // eslint-disable-next-line no-magic-numbers
          pages: Math.ceil(DEFAULT_CONTRACTORS_INVOICES.length / 20),
        }),
      );
    },
  ),
  rest.get<GetContractorInvoiceResponseAPI>(
    composeEndpoint(`/invoices/:id`, contractorsClient),
    (_, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(DEFAULT_CONTRACTOR_INVOICE_DETAILS),
      ),
  ),
];
