import { useCallback, useEffect, useState } from 'react';

import { PayrollJustificationAPIResponse } from 'common/types';
import {
  useDeletePayrollJustificationMutation,
  usePayrollEmployeeJustificationsQuery,
  usePayrollJustificationMutation,
} from 'utils/queries';

type PayrollJustificationsHookProps = {
  grossToNetId: string;
  employeeId?: string;
  readOnly: boolean;
};

export const usePayrollEmployeeJustifications = ({
  grossToNetId,
  employeeId,
  readOnly,
}: PayrollJustificationsHookProps) => {
  const [note, setNote] = useState('');

  const { mutate: savePayrollJustification } =
    usePayrollJustificationMutation();

  const { mutate: deletePayrollJustification } =
    useDeletePayrollJustificationMutation();

  const {
    data: justification = <PayrollJustificationAPIResponse>{},
    isLoading: isLoadingJustifications,
    isSuccess: isSuccessJustificationsQuery,
    refetch: refetchEmployeeJustification,
  } = usePayrollEmployeeJustificationsQuery({
    grossToNetId,
    employeeId,
  });

  useEffect(() => {
    // default the note if the employee changed
    // set the note in the value from server
    if (!isLoadingJustifications && isSuccessJustificationsQuery) {
      setNote(justification.note || '');
    }
  }, [isSuccessJustificationsQuery, isLoadingJustifications, justification]);

  const handleChangeNote = ({ target }: any) => {
    setNote(target.value);
  };

  const handleUpdateNote = useCallback(
    ({ target }: any) => {
      if (!employeeId || readOnly) {
        return;
      }

      const textToSave = target.value.trim();

      if (justification.id && !textToSave) {
        deletePayrollJustification(justification, {
          onSuccess: () => refetchEmployeeJustification(),
        });

        return;
      }

      if (textToSave && justification.note !== textToSave) {
        savePayrollJustification(
          {
            grossToNetId,
            employeeId,
            note: textToSave,
          },
          { onSuccess: () => refetchEmployeeJustification() },
        );
      }
    },
    [
      employeeId,
      readOnly,
      justification.id,
      justification.note,
      deletePayrollJustification,
      refetchEmployeeJustification,
      savePayrollJustification,
      grossToNetId,
    ],
  );

  return {
    note,
    handleChangeNote,
    handleUpdateNote,
    isLoadingJustifications,
  };
};
