import { ReactNode } from 'react';

import { TimeOffAction } from 'common/components/molecules/TimeOffAction/TimeOffAction';
import { TimeOffType } from 'common/components/molecules/TimeOffType/TimeOffType';
import { CompanyTimeOffLog, TimeOffLog } from 'common/types';

import { Tag, TagIntent } from '@omnipresentgroup/design-system';

import { TimeOffDate } from '../../molecules/TimeOffDate';

export type TableComposition = {
  headerTitle: string;
  fieldOnObject?: keyof TimeOffLog | keyof CompanyTimeOffLog;
  customCell?: ReactNode;
};

const employeeNameRow: TableComposition = {
  headerTitle: 'Name',
  fieldOnObject: 'employeeName',
};

const DatesCustomCell = ({ startDate, endDate }: TimeOffLog) => (
  <TimeOffDate startDate={startDate} endDate={endDate} />
);

const TypeCustomCell = (timeoff: TimeOffLog) => <TimeOffType {...timeoff} />;

const StatusCustomCell = (timeOff: TimeOffLog) => {
  const statusMap: Record<string, { intent: TagIntent }> = {
    deleted: { intent: 'error' },
    taken: { intent: 'default' },
    scheduled: { intent: 'success' },
  };

  const status = timeOff.deletedAt
    ? 'deleted'
    : timeOff.isTaken
      ? 'taken'
      : 'scheduled';

  return (
    <Tag id={timeOff.id} intent={statusMap[status].intent}>
      {status}
    </Tag>
  );
};

const baseTimeOffTable: TableComposition[] = [
  {
    headerTitle: 'Dates',
    customCell: DatesCustomCell,
  },
  {
    headerTitle: 'Type',
    fieldOnObject: 'leaveType',
    customCell: TypeCustomCell,
  },
  {
    headerTitle: 'Status',
    customCell: StatusCustomCell,
  },
  {
    headerTitle: 'Note',
    fieldOnObject: 'employeeNote',
  },
];

export const scheduledTimeOffTable = (
  hasNameField = false,
  isEmployee = false,
): TableComposition[] => {
  if (hasNameField) {
    return [employeeNameRow, ...baseTimeOffTable];
  }

  if (isEmployee) {
    const ActionsCustomCell = (timeoff: TimeOffLog) => (
      <TimeOffAction {...timeoff} />
    );

    return [
      ...baseTimeOffTable,
      {
        headerTitle: 'Actions',
        customCell: ActionsCustomCell,
      },
    ];
  }

  return baseTimeOffTable;
};

export const takenTimeOffTable = (hasNameField = false): TableComposition[] => {
  if (hasNameField) {
    return [employeeNameRow, ...baseTimeOffTable];
  }

  return baseTimeOffTable;
};
