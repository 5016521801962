import {
  CONTRACT_AMENDMENT_REVIEW_CLAUSE_LABELS,
  CONTRACT_AMENDMENT_REVIEW_TYPE_LABELS,
  ContractAmendmentReviewClause,
  ContractAmendmentReviewType,
  FIELD_TYPE,
  FormConfig,
} from 'common/types';
import Joi from 'joi';

const reviewTypeOptions = Object.keys(
  CONTRACT_AMENDMENT_REVIEW_TYPE_LABELS,
).map((value) => ({
  label:
    CONTRACT_AMENDMENT_REVIEW_TYPE_LABELS[value as ContractAmendmentReviewType],
  value,
}));

const reviewClauseOptions = Object.keys(
  CONTRACT_AMENDMENT_REVIEW_CLAUSE_LABELS,
).map((value) => ({
  label:
    CONTRACT_AMENDMENT_REVIEW_CLAUSE_LABELS[
      value as ContractAmendmentReviewClause
    ],
  value,
}));

export const getContractAmendmentFormConfig = (): FormConfig => ({
  formTitle: '',
  formCopy: '',
  formFields: [
    {
      name: 'reviewType',
      label: 'Review type',
      type: FIELD_TYPE.DROPDOWN,
      options: reviewTypeOptions,
      value: null,
      placeholder: 'please select a review type',
      required: true,
      error: null,
      disabled: false,
    },
    {
      name: 'reviewClause',
      label: 'Clause to review',
      type: FIELD_TYPE.DROPDOWN,
      options: reviewClauseOptions,
      value: null,
      placeholder: 'please select a clause to review',
      required: true,
      error: null,
      disabled: false,
    },
    {
      name: 'reviewClauseNumber',
      label: 'Clause number',
      type: FIELD_TYPE.STRING,
      value: '',
      error: null,
      required: false,
    },
    {
      name: 'details',
      label:
        'Please provide a detailed description of the edit you would like to make to the contract',
      type: FIELD_TYPE.TEXT,
      value: '',
      error: null,
      required: true,
    },
  ],
  schema: Joi.object({
    reviewType: Joi.any()
      .required()
      .valid(...Object.values(CONTRACT_AMENDMENT_REVIEW_TYPE_LABELS))
      .messages({
        'any.only': `Please select review type among ${Object.values(
          CONTRACT_AMENDMENT_REVIEW_TYPE_LABELS,
        ).join(', ')}`,
      }),
    reviewClause: Joi.any()
      .required()
      .valid(...Object.values(CONTRACT_AMENDMENT_REVIEW_CLAUSE_LABELS))
      .messages({
        'any.only': `Please select review clause from the above options`,
      }),
    reviewClauseNumber: Joi.string().optional().allow(''),
    details: Joi.string().messages({
      'string.empty': 'Please provide more info',
    }),
  }),
});
