import { queryClient } from 'app/queryClient';
import { updateExpensesNotificationsScope } from 'common/api/userAssociations.api';
import { useMutation } from 'react-query';
import { companiesKeys } from 'utils/queries/companies/keys';

export const useUpdateUserScopeExpensesMutation = ({
  companyId,
}: {
  companyId: string;
}) => {
  return useMutation(
    ({
      hasExpensesScope,
      userId,
    }: {
      hasExpensesScope: boolean;
      userId: string;
    }) => updateExpensesNotificationsScope(userId, hasExpensesScope),
    {
      onSuccess: () => {
        queryClient.refetchQueries(companiesKeys.managers(companyId));
      },
    },
  );
};
