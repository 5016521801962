import {
  getPayrollJustifications,
  getPayrollJustificationsByGTNIDs,
} from 'common/api/payrollTaxonomy.api';
import { PayrollJustificationAPIResponse } from 'common/types';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';

import { notifyError } from '@omnipresentgroup/design-system';

import { getDataFromResponse } from '../../../react-query-utils';
import { payrollKeys } from '../keys';

export const usePayrollJustificationsQuery = (params: {
  grossToNetId: string;
  employeeId?: string;
}) => {
  return useQuery<PayrollJustificationAPIResponse[], Error>(
    payrollKeys.payReportJustifications(params),
    () => getPayrollJustifications(params).then(getDataFromResponse),
    {
      keepPreviousData: true,
      onError: () => {
        notifyError({
          title: 'Error',
          description: 'Failed to read justifications from server.',
        });
      },
      enabled: !!params.grossToNetId,
    },
  );
};

export const usePayrollEmployeeJustificationsQuery = (params: {
  grossToNetId: string;
  employeeId?: string;
}) => {
  return useQuery<PayrollJustificationAPIResponse, Error>(
    payrollKeys.payReportJustifications(params),
    () =>
      getPayrollJustifications(params)
        .then(getDataFromResponse)
        .then((data) => data.length && data[0]),
    {
      onError: () => {
        notifyError({
          title: 'Error',
          description: 'Failed to read justifications from server.',
        });
      },
      enabled: !!params.employeeId && !!params.grossToNetId,
    },
  );
};

export const usePayrollJustificationsByGTNIDsQuery = (
  grossToNetIds: string[],
) => {
  return useQuery<PayrollJustificationAPIResponse[], Error>(
    payrollKeys.payReportJustificationsByGTNWithIds({
      grossToNetIds,
    }),
    () =>
      getPayrollJustificationsByGTNIDs(grossToNetIds).then(getDataFromResponse),
    {
      keepPreviousData: true,
      onError: () => {
        notifyError({
          title: 'Error',
          description: 'Failed to read justifications from server.',
        });
      },
      enabled: !isEmpty(grossToNetIds),
    },
  );
};
