import { getUserSelector } from 'app/store/app.selectors';
import { transformUser } from 'app/store/app.transformers';
import { getLSPUserRequest } from 'common/api/companies.api';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getDataFromResponse } from 'utils/react-query-utils';
import { isAdmin } from 'utils/user';

import { lspsKeys } from '../keys';

export const useLspUserQuery = (userId: string) => {
  const user = useSelector(getUserSelector);
  const hasPermissionToSeeLSPUsers = isAdmin(user);
  const { isLoading, isError, isSuccess, data, error } = useQuery<any, Error>(
    lspsKeys.lspUser(userId),
    () => getLSPUserRequest(userId).then(getDataFromResponse),
    {
      enabled: hasPermissionToSeeLSPUsers,
    },
  );

  return {
    isError,
    isLoading,
    isSuccess,
    data: data && transformUser(data),
    error,
  };
};
