import { useState } from 'react';

import { Box, Inline } from '@omnipresentgroup/design-system';

import 'react-circular-progressbar/dist/styles.css';

import { X } from 'react-feather';
import { useSelectUserRoles } from 'utils/hooks/redux-selectors/useSelectUserRoles';

import {
  StyledCountryFlag,
  StyledOnboardingTitle,
} from '../OnboardingProgressTracker/OnboardingProgressTracker.style';
import { OnboardingStage } from '../OnboardingProgressTracker/types';
import { EditableDrawerForm } from './EditableDrawerForm/EditableDrawerForm';
import { EmployerFieldMissingModal } from './EmployerFieldMissingModal';
import {
  BackGroundDivDrawer,
  CloseIconContainer,
  DrawerContainer,
  LoadingSpinner,
  StyledDivider,
  StyledEmployeeName,
  StyledEmployeeStarDate,
} from './OnboardingProgressDrawer.style';
import { getPercentComplete } from './OnboardingProgressDrawerUtil';
import { PercentageComponent } from './PercentageProgressCircular/PercentageProgressCircular';
import { ReadOnlyDrawer } from './ReadOnlyDrawer';

export type Props = {
  stages: OnboardingStage[];
  setOpenDrawer: (value: boolean) => void;
  territoryFlag?: string;
  startDate: string;
  employeeName: string;
  employeeId: string;
  lspId?: string;
  completedSteps: string[];
};

export const OnboardingProgressDrawer = ({
  stages = [],
  setOpenDrawer,
  territoryFlag,
  startDate,
  employeeName,
  employeeId,
  lspId,
  completedSteps,
}: Props) => {
  const [isUpdatingDrawer, setIsUpdatingDrawer] = useState<boolean>(false);

  const closeCss = {
    right: '$4',
    cursor: 'pointer',
  };

  const [showLspModal, setShowLspModal] = useState(false);

  const { isAdmin } = useSelectUserRoles();
  const percentage = getPercentComplete(stages, completedSteps);

  return (
    <>
      <BackGroundDivDrawer
        data-testid="outside-drawer"
        onClick={() => setOpenDrawer(false)}
      />
      {showLspModal && (
        <EmployerFieldMissingModal
          employeeName={employeeName}
          employeeId={employeeId}
          stateChanger={setShowLspModal}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      <DrawerContainer>
        <Inline ml="16" mt="16" gap="0">
          <Inline>
            {territoryFlag && (
              <StyledCountryFlag size="small" className="emoji">
                {territoryFlag}
              </StyledCountryFlag>
            )}
            <StyledOnboardingTitle>Onboarding progress</StyledOnboardingTitle>
          </Inline>
          <Box pos="absolute" css={closeCss}>
            <CloseIconContainer>
              <X
                data-testid="close-drawer"
                onClick={() => setOpenDrawer(false)}
                size={16}
              />
            </CloseIconContainer>
          </Box>
        </Inline>

        <StyledDivider />
        <Inline justify="space-between">
          <Box>
            <StyledEmployeeName>{employeeName}</StyledEmployeeName>
            <StyledEmployeeStarDate>
              {`Start date: ${startDate}`}
            </StyledEmployeeStarDate>
          </Box>
          <Box mt="16" ml="32" mr="8">
            {isUpdatingDrawer ? (
              <LoadingSpinner role="alert" />
            ) : (
              <PercentageComponent
                size={48}
                percentage={percentage}
                text={`${percentage}%`}
              />
            )}
          </Box>
        </Inline>
        <StyledDivider />
        <Box mt="32" data-testid="onboarding-progress-drawer">
          {isAdmin ? (
            <EditableDrawerForm
              stages={stages}
              completedSteps={completedSteps}
              employeeName={employeeName}
              employeeId={employeeId}
              lspId={lspId}
              setShowLspModal={setShowLspModal}
              setIsUpdatingDrawer={setIsUpdatingDrawer}
            />
          ) : (
            <ReadOnlyDrawer stages={stages} completedSteps={completedSteps} />
          )}
        </Box>
      </DrawerContainer>
    </>
  );
};
