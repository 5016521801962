import { getSelectedEmployeeSelector } from 'common/store/selectors/employees.selector';
import { UserRoleType, UserRoleTypeEnum } from 'common/types';
import {
  APPROVED,
  ARCHIVED,
  Contract,
  ContractStatus,
  DRAFT,
  PENDING_INTERNAL_REVIEW,
  PENDING_REVIEW,
  REVIEW_DONE,
  SIGNED_BOTH_PARTIES,
  SIGNED_EMPLOYEE,
} from 'common/types/contract';
import { useSelector } from 'react-redux';

import { useUserRoles } from '../redux-selectors';

export function useVisibleContracts(): Contract[] {
  const userRoles = useUserRoles();
  const employee = useSelector(getSelectedEmployeeSelector);
  const visibleContracts: Contract[] = getVisibleContracts(
    employee?.contracts,
    isRightToWorkCheckCompleted(employee),
    userRoles,
  );
  return visibleContracts;
}

const getContractsVisibility = (
  userRoles: UserRoleType[],
): ContractStatus[] => {
  switch (true) {
    case userRoles.includes(UserRoleTypeEnum.OMNI_HR):
    case userRoles.includes(UserRoleTypeEnum.ADMIN):
    case userRoles.includes(UserRoleTypeEnum.CS):
      return [
        PENDING_REVIEW,
        REVIEW_DONE,
        DRAFT,
        PENDING_INTERNAL_REVIEW,
        APPROVED,
        SIGNED_EMPLOYEE,
        SIGNED_BOTH_PARTIES,
        ARCHIVED,
      ];
    case userRoles.includes(UserRoleTypeEnum.MANAGER):
      return [
        PENDING_REVIEW,
        DRAFT,
        PENDING_INTERNAL_REVIEW,
        APPROVED,
        SIGNED_EMPLOYEE,
        SIGNED_BOTH_PARTIES,
      ];
    case userRoles.includes(UserRoleTypeEnum.EMPLOYEE):
      return [APPROVED, SIGNED_EMPLOYEE, SIGNED_BOTH_PARTIES];
    default:
      return [SIGNED_BOTH_PARTIES];
  }
};

export const getVisibleContracts = (
  contracts: Contract[] = [],
  rtwCheck: boolean | undefined,
  userRoles: UserRoleType[],
): Contract[] => {
  const visibleStatus = getContractsVisibility(userRoles);

  let visibleContracts = contracts
    .map((contract) => {
      if (visibleStatus.includes(contract.status)) {
        return contract;
      }
      return false;
    })
    .filter(Boolean) as Contract[];

  // Right To Work Check rule
  if (userRoles.includes(UserRoleTypeEnum.MANAGER) && rtwCheck === false) {
    visibleContracts = visibleContracts.filter(
      (contract) => contract?.status !== DRAFT,
    );
  }
  return visibleContracts;
};

export function isRightToWorkCheckCompleted(
  employee: any,
): boolean | undefined {
  const rtwDetails = employee?.employmentDetails?.find(
    (detail: { name: string }) => detail.name === 'rightToWorkCheckCompleted',
  );

  const hasRightToWork = (option: { selected?: boolean }) => option?.selected;

  return rtwDetails?.value?.find(hasRightToWork)?.value;
}
