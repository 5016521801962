import { ActionCell } from 'common/components/molecules/ActionCell/ActionCell';
import { StyledTitleCell } from 'common/components/organisms/TableWithTitle/TableWithTitle.styles';
import { ManagerActions } from 'common/types';
import styled from 'styled-components';

const StyledCustomButton = styled.button`
  padding: 0;
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  outline: none;
  background-color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: left;
  cursor: pointer;
`;

const ManagerCustomCell = (manager, onColumnItemClick) => {
  const { name } = manager;
  return (
    <StyledCustomButton onClick={() => onColumnItemClick('manager', manager)}>
      <StyledTitleCell>{name}</StyledTitleCell>
    </StyledCustomButton>
  );
};

const ActionsCustomCell = (currentUser, onColumnItemClick) => (
  <StyledCustomButton>
    <ActionCell
      actions={ManagerActions}
      onActionClick={(action) => onColumnItemClick(action, currentUser)}
    />
  </StyledCustomButton>
);

export const managerTableHeaders = [
  {
    customCell: ManagerCustomCell,
    fieldOnObject: 'name',
    headerTitle: 'Manager',
    id: 'manager',
  },
  {
    fieldOnObject: 'jobTitle',
    headerTitle: 'Job Title',
    id: 'jobTitle',
  },
  {
    fieldOnObject: 'department',
    headerTitle: 'Department',
    id: 'department',
  },
  {
    fieldOnObject: 'email',
    headerTitle: 'Email',
    id: 'email',
  },
  {
    fieldOnObject: 'role',
    headerTitle: 'Role',
    id: 'role',
  },
  {
    align: 'center',
    customCell: ActionsCustomCell,
    headerTitle: 'Actions',
    id: 'action',
  },
];
