import { useState } from 'react';

import { joiResolver } from '@hookform/resolvers/joi';
import { deleteCompanyManagerRequest } from 'common/api/companies.api';
import { FormInput } from 'common/components/molecules/FormInput';
import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { Button } from '@omnipresentgroup/design-system';

import { DeleteManagerErrorModal } from './DeleteManagerErrorModal';
import {
  StyledButtonContainer,
  StyledCloseButton,
  StyledCloseButtonContainer,
  StyledManagerDetailsContainer,
  StyledManagerHeaderContainer,
  StyledManagerInfoContainer,
  StyledModalContainer,
  StyledNameContainer,
  StyledNoteTextContainer,
  StyledRoleContainer,
  StyledSelectContainer,
  StyledTitle,
} from './DeleteManagerModal.styles';
import { DeleteManagerModalProps } from './DeleteManagerModal.types';
import { schema } from './deleteManagerModalSchema';
import { DeleteManagerSuccessModal } from './DeleteManagerSuccessModal';

export const DeleteManagerModal = ({
  isOpen,
  companyId,
  managerDetails,
  closeFn,
  successFn,
  hidden,
}: DeleteManagerModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    register,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    mode: 'all',
    resolver: joiResolver(schema),
  });

  const { mutate: deleteManager } = useMutation(
    (managerId: string) => deleteCompanyManagerRequest(companyId, managerId),
    {
      onSuccess: () => {
        setIsLoading(false);
        setIsSuccess(true);
        closeFn();
      },
      onError: () => {
        setIsLoading(false);
        closeFn();
        setIsError(true);
        clearFields();
      },
    },
  );

  const clearFields = () => {
    reset({
      deleteVerificationField: '',
    });
  };

  const handleOnClose = () => {
    closeFn && closeFn();
    clearFields();
  };

  return hidden ? (
    <></>
  ) : (
    <>
      {isSuccess && (
        <DeleteManagerSuccessModal
          name={managerDetails.name}
          closeFn={() => {
            successFn();
            setIsSuccess(!isSuccess);
          }}
        />
      )}
      {isError && (
        <DeleteManagerErrorModal closeFn={() => setIsError(!isError)} />
      )}
      {isOpen && !isSuccess && !isError && (
        <ModalWrapper fixedWidth="600" data-testid="admin-manager-delete-modal">
          <StyledCloseButtonContainer>
            <StyledCloseButton
              aria-label="closeModalButton"
              onClick={handleOnClose}
              role="button"
            />
          </StyledCloseButtonContainer>

          <StyledModalContainer>
            <StyledTitle>Delete manager profile?*</StyledTitle>

            <StyledManagerInfoContainer>
              <StyledNameContainer>
                <StyledManagerHeaderContainer>
                  <div>Name</div>
                </StyledManagerHeaderContainer>
                <StyledManagerDetailsContainer>
                  <div>{managerDetails.name}</div>
                </StyledManagerDetailsContainer>
              </StyledNameContainer>

              <StyledRoleContainer>
                <StyledManagerHeaderContainer>
                  <div>Role</div>
                </StyledManagerHeaderContainer>
                <StyledManagerDetailsContainer>
                  <div>{managerDetails.role}</div>
                </StyledManagerDetailsContainer>
              </StyledRoleContainer>
            </StyledManagerInfoContainer>

            <StyledSelectContainer>
              <FormInput
                as="text"
                data-testid="deleteVerificationField"
                error={errors['deleteVerificationField']?.message}
                id="deleteVerificationField"
                label={'To verify, type "DELETE" below'}
                required
                {...register('deleteVerificationField')}
              />
            </StyledSelectContainer>

            <StyledNoteTextContainer>
              * Please note, once deleted, the profile will no longer be
              displayed in the list of managers. This is an irreversible action.
            </StyledNoteTextContainer>

            <StyledButtonContainer>
              <Button variant="secondary" onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setIsLoading(true);
                  deleteManager(managerDetails.id);
                }}
                loading={isLoading}
                disabled={
                  isLoading || watch('deleteVerificationField') !== 'DELETE'
                }
              >
                Delete
              </Button>
            </StyledButtonContainer>
          </StyledModalContainer>
        </ModalWrapper>
      )}
    </>
  );
};
