import { territoriesResponse } from '__mocks__/contentful/contentful.territoriesResponse.mock';
import { HTTPStatusCodesEnum } from 'common/constants';
import { rest } from 'msw';

const CONTENTFUL_BASE_URL =
  'https://cdn.contentful.com/spaces/:spaceId/environments/:environmentId';

export const contentfulHandlers = [
  rest.get(`${CONTENTFUL_BASE_URL}/entries`, (req, res, ctx) => {
    const contentType = req.url.searchParams.get('content_type');

    if (contentType === 'territory') {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(territoriesResponse),
      );
    }
  }),
];
