import { HTTPStatusCodesEnum } from 'common/constants';
import {
  PostResignationModel,
  ResignationModel,
  ResignationStatus,
} from 'common/types/resignations';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

export const resignationHandlers = [
  rest.post<PostResignationModel>(
    composeEndpoint('/resignations'),
    (req, res, ctx) => res(ctx.status(201), ctx.json(mockResignation)),
  ),

  rest.get<ResignationModel[]>(
    composeEndpoint('/resignations'),
    (req, res, ctx) => {
      const query = req.url.searchParams;
      const employeeId = query.get('employeeId');

      if (employeeId) {
        return res(
          ctx.status(HTTPStatusCodesEnum.OK),
          ctx.json(mockResignations),
        );
      }
    },
  ),

  rest.get<ResignationModel[]>(
    composeEndpoint('/resignations'),
    (req, res, ctx) => {
      const query = req.url.searchParams;
      const companyId = query.get('companyId');

      if (companyId) {
        res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockResignations));
      }
    },
  ),
];

export const mockResignation: ResignationModel = {
  id: '4a8a6721-6204-434d-9a53-4efc1b469ccb',
  companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
  employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
  employmentId: 'abc2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
  employeeLastWorkingDay: '2022-11-30',
  isManagerAware: true,
  employeeScheduledLeaves: ['sickLeave', 'parentalLeave'],
  employeeNumberOfVacationsDays: 4,
  resignationReason: 'flexibleWorkOptions',
  otherReason: 'Test other reason',
  createdBy: 'auth0|6348088d0913a31d51a3a763',
  createdAt: '2022-11-11T09:30:00.114Z',
  status: ResignationStatus.REQUESTED,
};

export const mockResignations: ResignationModel[] = [mockResignation];
