import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { joiResolver } from '@hookform/resolvers/joi';
import { PageContent } from 'app/App.styles';
import { Loading } from 'common/components';
import { FourOhFour } from 'common/pages/404/FourOhFour';
import { getEmployeeAction } from 'common/store/actions/employee.actions';
import { getSelectedEmployeeSelector } from 'common/store/selectors/employees.selector';
import { FormData, User } from 'common/types';
import { EMPLOYEES_PATH, getEmployeeTerminationRequestsPath } from 'paths';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSelectUserRoles } from 'utils/hooks';
import { useCompanyManagersQuery } from 'utils/queries';
import { useDocumentDownloadQuery } from 'utils/queries/document/useDocumentQuery/useDocumentDownloadQuery';
import { useTerminationRequestQuery } from 'utils/queries/employees/terminationRequests/useTerminationRequestQuery';
import { useUserQuery } from 'utils/queries/users';

import {
  Box,
  Button,
  DateFormats,
  DateInput,
  Dropdown,
  formatISODate,
  Inline,
  Input,
  Link,
  Notification,
  Split,
  Stack,
  TextArea,
  Typography,
  type IsoDateTime,
} from '@omnipresentgroup/design-system';

import {
  questions,
  terminationFormValidationSchema,
} from './terminationForm.helper';
import { TerminationFormExplainerSection } from './TerminationFormExplainerSection/TerminationFormExplainerSection';

export const ViewTerminationRequestPage = () => {
  const history = useHistory();

  const { control } = useForm<FormData>({
    resolver: joiResolver(terminationFormValidationSchema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const { isManager, isCSAdmin } = useSelectUserRoles();

  const { companyId, employeeId, terminationRequestId } = useParams<{
    companyId: string;
    employeeId: string;
    terminationRequestId: string;
  }>();

  const {
    data: terminationRequestData,
    isLoading: isLoadingGetTerminationRequest,
    error: getTerminationRequestError,
  } = useTerminationRequestQuery(terminationRequestId, {
    enabled: Boolean(terminationRequestId),
  });

  const {
    data: companyManagersData = [],
    isLoading: isLoadingGetCompanyManagers,
  } = useCompanyManagersQuery({
    companyId: companyId,
  });

  const documentIds = terminationRequestData?.answers[
    'terminationSupportDocumentation'
  ]?.inputAnswers as string[];

  const documentId: string = documentIds && documentIds[0];

  const {
    data: documentDownloadData,
    isLoading: isLoadingGenerateDocumentViewLink,
  } = useDocumentDownloadQuery(documentId, 'inline');

  const terminationCreatedBy = terminationRequestData?.createdBy ?? '';

  const { data: userData, isLoading: isLoadingUserData } =
    useUserQuery(terminationCreatedBy);

  const documentUrl = documentDownloadData ? documentDownloadData?.url : '';

  const managersEmails = companyManagersData.map((manager: User) => ({
    label: manager.email,
    value: manager.userId,
  }));

  const savedAnswers = terminationRequestData?.answers[
    'employeeNonHolidayLeaves'
  ]
    ? (terminationRequestData?.answers['employeeNonHolidayLeaves']
        ?.selectedAnswerKeys as string[])
    : [];

  const employeeNonHolidayLeavesSavedOptions = () => {
    const selectedOptions: any = [];

    questions.employeeNonHolidayLeaves.answers.forEach((defaultAnswer) => {
      savedAnswers.some((savedAnswer) => {
        if (defaultAnswer.value === savedAnswer) {
          selectedOptions.push(defaultAnswer);
        }
      });
    });
    return selectedOptions;
  };

  const dispatch = useDispatch();

  const employee = useSelector(getSelectedEmployeeSelector);

  useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeAction(employeeId));
    }
  }, [dispatch, employeeId]);

  if (
    isLoadingGetTerminationRequest ||
    isLoadingGetCompanyManagers ||
    isLoadingGenerateDocumentViewLink ||
    isLoadingUserData
  ) {
    return <Loading />;
  }

  if (getTerminationRequestError) {
    return <FourOhFour />;
  }

  const getPathLink = () => {
    isManager
      ? history.push(`${EMPLOYEES_PATH}#termination-requests`)
      : history.push(getEmployeeTerminationRequestsPath(companyId));
  };

  return (
    <PageContent>
      <Stack gap="32">
        <Typography as="h2" hideParagraphSpacing size="24" weight="medium">
          View Termination Request For: {employee?.name}
        </Typography>
        {isCSAdmin && (
          <Box>
            {`Termination request submitted by ${userData?.firstName} ${userData?.lastName} (${userData?.email}) on ${formatISODate(
              terminationRequestData?.createdAt as IsoDateTime,
              DateFormats.Date,
              true,
              '-',
            )}`}
          </Box>
        )}
        <Box>
          <Button
            variant="secondary"
            icon="ChevronLeft"
            onClick={() => getPathLink()}
          >
            Back to termination requests list
          </Button>
        </Box>
        <Notification
          id="termination-request-info"
          title="Note"
          description="After you submit the form below, Omnipresent will contact you (the manager). We will NOT notify the employee of the termination until all parties agree to do so."
        />
        <TerminationFormExplainerSection />
        <Stack gap="32" bg="primary" depth="m" p="32">
          <Split gap="64" fraction="1/2">
            <Input
              id="employeeName"
              label="Employee Name"
              readOnly
              value={employee?.name || ''}
            />
          </Split>
          <Split gap="64" fraction="1/2">
            <Input
              id="employeeCountry"
              label="Country where employee holds his employment contract"
              readOnly
              value={employee?.country || ''}
            />
          </Split>
          <Split gap="64" fraction="1/2">
            <Controller
              name="isEmployeeOPManager"
              control={control}
              defaultValue={
                questions.defaultAnswers.answers[
                  terminationRequestData?.employeeManagerId ? 0 : 1
                ]
              }
              render={({ field }) => (
                <Dropdown
                  id="isEmployeeOPManager"
                  label="Does the employee have manager permissions or super admin access on the OmniPlatform?"
                  isReadOnly
                  {...field}
                />
              )}
            />
            <Stack justify="center">
              <Typography as="label" size="14" weight="medium">
                Why do I need to answer this?
              </Typography>
              <Typography as="span" size="14">
                {`If the employee has `}
                <Link
                  to="https://intercom.help/omnipresent/en/articles/6662336-omniplatform-manager-permissions"
                  target="_blank"
                  rel="noreferrer"
                  size="14"
                >
                  manager permissions
                </Link>
                {` or super admin access on OmniPlatform, we must hide this
                termination request from them. If you answer yes, you will be
                asked to select their manager / super admin login email address.`}
              </Typography>
            </Stack>
          </Split>
          <Split gap="64" fraction="1/2">
            {terminationRequestData?.employeeManagerId && (
              <Controller
                name="employeeOPManagerProfile"
                control={control}
                defaultValue={managersEmails.find(
                  (email) =>
                    email.value === terminationRequestData?.employeeManagerId,
                )}
                render={({ field }) => (
                  <Dropdown
                    id="employeeOPManagerProfile"
                    label="If yes, which manager or super admin login belongs to the employee?"
                    isReadOnly
                    {...field}
                  />
                )}
              />
            )}
          </Split>
          <Split gap="64" fraction="1/2">
            <Controller
              name="isEmployeeAwareOfTermination"
              control={control}
              defaultValue={questions.defaultAnswers.answers.find(
                (answer) =>
                  answer.value ===
                  terminationRequestData?.answers[
                    'isEmployeeAwareOfTermination'
                  ]?.selectedAnswerKeys,
              )}
              render={({ field }) => (
                <Dropdown
                  id="isEmployeeAwareOfTermination"
                  label="Is the employee aware of the termination?"
                  isReadOnly
                  {...field}
                />
              )}
            />
          </Split>
          <Split gap="64" fraction="1/2">
            <Controller
              name="terminationReason"
              control={control}
              defaultValue={questions.terminationReason.answers.find(
                (answer) =>
                  answer.value ===
                  terminationRequestData?.answers['terminationReason']
                    ?.selectedAnswerKeys,
              )}
              render={({ field }) => (
                <Dropdown
                  id="terminationReason"
                  label="What is the reason for the termination?"
                  isReadOnly
                  {...field}
                />
              )}
            />
            <Stack justify="center">
              <Typography as="label" size="14" weight="medium">
                Reasons for termination explained
              </Typography>
              <Typography as="span" size="14">
                <Typography as="span" size="14" weight="medium">
                  {`Poor performance: `}
                </Typography>
                Employee shows incompetence in performing job duties as expected
                and shows no improvement even after feedback and guidance.
              </Typography>
              <Typography as="span" size="14">
                <Typography as="span" size="14" weight="medium">
                  {`Business reason: `}
                </Typography>
                Economic, technical and organizational reasons.
              </Typography>
              <Typography as="span" size="14">
                <Typography as="span" size="14" weight="medium">
                  {`Did not pass probation: `}
                </Typography>
                Performance issues identified during the period of employee
                probation as mentioned in the employment contract.
              </Typography>
              <Typography as="span" size="14">
                <Typography as="span" size="14" weight="medium">
                  {`Misconduct: `}
                </Typography>
                A willful breach of obligations by an employee or a violation of
                ethics and code of conduct.
              </Typography>
              <Typography as="span" size="14">
                <Typography as="span" size="14" weight="medium">
                  {`Changing EOR provider: `}
                </Typography>
                Employees are being moved to an alternative EOR provider and
                therefore need to be terminated.
              </Typography>
              <Typography as="span" size="14">
                <Typography as="span" size="14" weight="medium">
                  {`Moving to own entity: `}
                </Typography>
                Employees are being moved to an entity that belongs to the
                client.
              </Typography>
              <Typography as="span" size="14">
                <Typography as="span" size="14" weight="medium">
                  {`Other: `}
                </Typography>
                Anything else that cannot be merged with the other categories.
              </Typography>
            </Stack>
          </Split>
          <Split gap="64" fraction="1/2">
            {terminationRequestData?.answers['terminationReason']
              ?.selectedAnswerKeys === 'other' && (
              <Controller
                name="detailsOfTerminationReason"
                control={control}
                defaultValue={
                  terminationRequestData?.answers['detailsOfTerminationReason']
                    ?.inputAnswers as string
                }
                render={({ field }) => (
                  <TextArea
                    id="detailsOfTerminationReason"
                    label="Please provide details"
                    readOnly
                    {...field}
                  />
                )}
              />
            )}
          </Split>
          <Split gap="64" fraction="1/2">
            <Controller
              name="hasDisputesWithEmployee"
              control={control}
              defaultValue={questions.defaultAnswers.answers.find(
                (answer) =>
                  answer.value ===
                  terminationRequestData?.answers['hasDisputesWithEmployee']
                    ?.selectedAnswerKeys,
              )}
              render={({ field }) => (
                <Dropdown
                  id="hasDisputesWithEmployee"
                  label="Do you have any current disputes with the employee?"
                  isReadOnly
                  {...field}
                />
              )}
            />
            <Stack justify="center">
              <Typography as="label" size="14" weight="medium">
                Current disputes
              </Typography>
              <Typography as="span" size="14">
                Terminating an employee who has an existing grievance, ongoing
                disciplinary case, unresolved allegations is risky. This can be
                seen as an act of retaliation against the employee and maybe
                considered unlawful in court.
              </Typography>
            </Stack>
          </Split>
          <Split gap="64" fraction="1/2">
            {terminationRequestData?.answers['hasDisputesWithEmployee']
              ?.selectedAnswerKeys === 'yes' && (
              <Controller
                name="detailsOfDisputesWithEmployee"
                control={control}
                defaultValue={
                  terminationRequestData?.answers[
                    'detailsOfDisputesWithEmployee'
                  ]?.inputAnswers as string
                }
                render={({ field }) => (
                  <TextArea
                    id="detailsOfDisputesWithEmployee"
                    label="Please provide details"
                    readOnly
                    {...field}
                  />
                )}
              />
            )}
          </Split>
          <Split gap="64" fraction="1/2">
            <Controller
              name="lastWorkingDayPreference"
              control={control}
              defaultValue={
                terminationRequestData?.answers['lastWorkingDayPreference']
                  ?.inputAnswers as string
              }
              render={({ field }) => (
                <DateInput
                  id="lastWorkingDayPreference"
                  label="What is your preference for the employee's last working day?"
                  readOnly
                  {...field}
                />
              )}
            />
            <Stack justify="center">
              <Typography as="label" size="14" weight="medium">
                Please select a date at least 30 days from the date of
                submission.
              </Typography>
              <Typography as="span" size="14">
                {`Our terms require that 'all terminations must be carried out in accordance 
                  with local law.' Rushed terminations put a high degree of risk on both you 
                  and Omnipresent. Because of this risk and to comply with our obligation to 
                  terminate employees in a compliant way, we require 30 days notice before 
                  termination. We always strive to work faster where possible. However, 
                  compliant terminations are complicated and cannot be rushed.`}
              </Typography>
            </Stack>
          </Split>
          <Split gap="64" fraction="1/2">
            <Controller
              name="lastEmploymentDatePreference"
              control={control}
              defaultValue={
                terminationRequestData?.answers['lastEmploymentDatePreference']
                  ?.inputAnswers as string
              }
              render={({ field }) => (
                <DateInput
                  id="lastEmploymentDatePreference"
                  label="On what day do you prefer the employee to stop getting paid?"
                  readOnly
                  {...field}
                />
              )}
            />
            <Stack justify="center">
              <Typography as="label" size="14" weight="medium">
                Please select a date at least 30 days from the date of
                submission.
              </Typography>
              <Typography as="span" size="14">
                {`We will confirm the effective date of termination on the basis
                of statutory and/or contractual requirements applicable in the
                employee's jurisdiction.`}
              </Typography>
              <Typography as="span" size="14">
                Please note that this is also subject to any mandatory internal
                processes.
              </Typography>
            </Stack>
          </Split>
          <Split gap="64" fraction="1/2">
            <Controller
              name="employeeNonHolidayLeaves"
              control={control}
              defaultValue={employeeNonHolidayLeavesSavedOptions()}
              render={({ field }) => (
                <Dropdown
                  id="employeeNonHolidayLeaves"
                  label="Is the employee currently or scheduled to any time off other than vacation leave?"
                  isMulti
                  isReadOnly
                  {...field}
                />
              )}
            />
          </Split>
          <Split gap="64" fraction="1/2">
            <Input
              id="numberOfVacationDaysBeforeLastEmploymentDate"
              type="number"
              label="How many vacation days has the employee taken or planned to take before the date he/she stops getting paid?"
              defaultValue={
                terminationRequestData?.answers[
                  'numberOfVacationDaysBeforeLastEmploymentDate'
                ]?.inputAnswers as string
              }
              readOnly
            />
          </Split>
          <Split gap="64" fraction="1/2">
            <Input
              id="numberOfDaysOwed"
              type="number"
              label="How many vacation days are owed to this employee (if any)?"
              defaultValue={
                terminationRequestData?.answers['numberOfDaysOwed']
                  ?.inputAnswers as string
              }
              readOnly
            />
            <Stack justify="center">
              <Typography as="label" size="14" weight="medium">
                Vacation days owed
              </Typography>
              <Typography as="span" size="14">
                To calculate this, please refer back to the employment contract
                for this employee.
              </Typography>
            </Stack>
          </Split>
          <Box>
            <Typography as="label" size="14" weight="medium">
              How much overtime is owed to this employee (if any)?
            </Typography>
            <Split gap="64" fraction="1/2">
              <Inline stretch="all">
                <Box maxW={135}>
                  <Controller
                    name="overtimeUnits"
                    control={control}
                    defaultValue={questions.overtimeUnits.answers.find(
                      (answer) =>
                        answer.value ===
                        terminationRequestData?.answers['overtimeOwed']
                          ?.selectedAnswerKeys,
                    )}
                    render={({ field }) => (
                      <Dropdown
                        id="overtimeUnits"
                        label=""
                        placeholder=""
                        isReadOnly
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Input
                    id="overtimeOwed"
                    type="number"
                    label=""
                    defaultValue={
                      terminationRequestData?.answers['overtimeOwed']
                        ?.inputAnswers as string
                    }
                    readOnly
                  />
                </Box>
              </Inline>
            </Split>
          </Box>
          <Split gap="64" fraction="1/2">
            <Stack>
              <Typography as="label" size="14" weight="medium">
                If you have documentation to support the termination, please
                upload it here
              </Typography>
              {documentIds && (
                <Box>
                  <Link
                    to={documentUrl}
                    target="_blank"
                    rel="noreferrer"
                    size="14"
                  >
                    View document
                  </Link>
                </Box>
              )}
            </Stack>
            <Stack justify="start">
              <Typography as="label" size="14" weight="medium">
                Examples of supporting documentation
              </Typography>
              <Typography as="span" size="14">
                Performance review, feedback, relevant email correspondence, PDF
                documents, data from IT, evidence screenshots (as applicable).
              </Typography>
            </Stack>
          </Split>
        </Stack>
      </Stack>
    </PageContent>
  );
};
