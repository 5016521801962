import Joi from 'joi';

export const userSchema = Joi.object().keys({
  displayName: Joi.string().messages({
    'string.empty': 'Please enter a name',
  }),
  firstName: Joi.string().messages({
    'string.empty': 'Please enter a first name',
  }),
  lastName: Joi.string().messages({
    'string.empty': 'Please enter a last name',
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      'string.base': 'Please enter a valid email address',
      'string.empty': 'Please enter an email address',
    }),
});
