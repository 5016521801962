import appHistory from 'app/appHistory';
import { ADD_EMPLOYEE_CHECKLIST_PAGE_PATH } from 'paths';

import { Button, Stack, Typography } from '@omnipresentgroup/design-system';

export type NoEmployeesProps = {
  className?: string;
  addEmployeePath?: string;
};

export const NoEmployees = ({
  className,
  addEmployeePath = ADD_EMPLOYEE_CHECKLIST_PAGE_PATH,
}: NoEmployeesProps) => {
  return (
    <>
      <Stack
        className={className}
        data-testid="no-employees-container"
        mt="64"
        align="self-start"
        p="16"
      >
        <Typography as="h4" hideParagraphSpacing size="24" weight="medium">
          Your company profile is now complete.
        </Typography>
        <Typography
          as="p"
          hideParagraphSpacing
          size="16"
          data-testid="no-employees-content"
        >
          The next step is to create your first employee!
        </Typography>
        <Typography as="span" size="16">
          You&apos;ll provide some of the initial information we need in order
        </Typography>
        <Typography as="span" size="16">
          to start the employment process on our end.
        </Typography>
        <Button
          onClick={() => {
            appHistory.push(addEmployeePath);
          }}
          data-testId="no-employees-button"
          size="medium"
        >
          Create first hire
        </Button>
      </Stack>
    </>
  );
};
