import { Seat } from 'common/types';

import { ADD_SEAT, asyncActionKeys, UPDATE_SEAT } from './redux.constants';

export const getCompanySeatsAction = (companyId: string) => {
  return {
    type: asyncActionKeys.GET_SEATS_START,
    payload: companyId,
  };
};

export const addSeatAction = (seatToAdd: Seat) => {
  return {
    type: ADD_SEAT,
    payload: seatToAdd,
  };
};

export const updateSeatAction = (seatId: string, updatedSeat: any) => {
  return {
    type: UPDATE_SEAT,
    payload: { id: seatId, ...updatedSeat },
  };
};
