import { Dictionary } from 'common/types/utilities';
import { COMPANIES_LIST_PAGE } from 'paths';
import { getNextPayrollCutOffDate } from 'utils/getNextPayrollCutOffDate';

import { Link, Notification } from '@omnipresentgroup/design-system';

export const LEARN_MORE_LINK =
  'https://help.omnipresent.com/en/articles/6763263-bulk-compensation-changes';

export const CUT_OFF_DETAILS_LINK =
  'https://help.omnipresent.com/en/articles/7041274-payroll-cut-off-date';

export const compensationHeadings: Dictionary<string> = {
  salary: 'Salary Change',
  bonus: 'Bonus',
  commission: 'Commission',
};

export const compensationTextValues: Dictionary<string[]> = {
  bonus: ['bonus', 'bonuses'],
  commission: ['commission', 'commissions'],
  salary: ['salary change', 'salary changes'],
};

export const selectedEmployeesTextValues: Dictionary<string[]> = {
  employee: ['employee', 'employees'],
};

export const getPluralizedText = (
  uploadCount: number,
  optionsTextValues: Dictionary<string[]>,
  selectedOption: string,
) => {
  if (!selectedOption) {
    return;
  }
  if (uploadCount === 1) {
    return optionsTextValues[selectedOption][0];
  }
  return optionsTextValues[selectedOption][1];
};

const MANAGER_BACK_LINKS: Dictionary<string> = {
  upload: '/bulk-compensation',
  verify: '/bulk-compensation/upload',
};

const getCSBackLinks = (companyId: string): Dictionary<string> => ({
  upload: `${COMPANIES_LIST_PAGE}/${companyId}#bulk-compensation`,
  verify: `${COMPANIES_LIST_PAGE}/${companyId}/bulk-compensation/upload`,
});

export const getBackLinkPath = (
  isManager: boolean,
  stage: string,
  companyId: string,
) => {
  const CS_BACK_LINKS = getCSBackLinks(companyId);
  if (isManager) {
    return MANAGER_BACK_LINKS[stage];
  }
  return CS_BACK_LINKS[stage];
};

export const getNextPagePath = (
  isManager: boolean,
  nextPageComponent: string,
  companyId?: string,
) => {
  if (isManager) {
    return `/bulk-compensation/${nextPageComponent}`;
  }
  return `${COMPANIES_LIST_PAGE}/${companyId}/bulk-compensation/${nextPageComponent}`;
};

export const getBulkCompensationWarning = () => {
  return (
    <Notification
      id="bulk-compensation-warning"
      intent="warning"
      title={`The next cut off date to submit compensation requests is ${getNextPayrollCutOffDate()}`}
      description="Requests requiring contract addendums to be prepared have additional processing time and will impact the compensations effective start date"
      link={
        <Link
          size="16"
          icon="ExternalLink"
          to={CUT_OFF_DETAILS_LINK}
          target="_blank"
        >
          View more details here
        </Link>
      }
    />
  );
};
