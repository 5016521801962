import { Department } from 'appConstants';

import { Address } from './address';
import { Field } from './form';
import { Integration } from './integration';

export type AllowedProduct = 'EOR' | 'Contractor';

export type Manager = {
  role: 'Manager';
  email: string;
  name: string;
  id: string;
  jobTitle?: string;
  department?: Department;
};

export type CompanyId = string;

type CompanyAddress = Pick<
  Address,
  'addressLine1' | 'addressLine2' | 'city' | 'postalCode' | 'country'
> & { state?: string };

export type CompanySettings = {
  disableContractAutomation?: boolean;
};

export type CompanyIntegrations = Record<'hris', Integration[]>;
export enum CompanyStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type Company = {
  noOfEmployees: number;
  invoicingEmail: string;
  updatedBy: string;
  updatedAt: string;
  adminNotes: string;
  id: CompanyId;
  name: string;
  externalId: string;
  identifiers?: {
    vatNumber: string;
    companyNumber: string;
  };
  primaryContact?: {
    name: string;
    phoneNumber?: string;
    email?: string;
  };
  status: CompanyStatusEnum;
  needsManagerReview?: boolean;
  countryOfRegistration: string;
  onboardingStage: string;
  managers: Manager[];
  registeredAddress: CompanyAddress;
  createdAt: string;
  createdBy: string;
  createdManagers: string[];
  settings: CompanySettings;
  integrations?: CompanyIntegrations;
  isVATExempt: boolean;
  fxConversionPercent: string;
  benefitsPercentage?: number;
  latePayment?: {
    amount: number;
    interestMargin: number;
  };
  salaryPrepayment?: {
    terms: number;
    multiplier: number;
  };
  newBusinessOpportunity?: {
    id: string;
    name: string;
  };
  enabledProducts: AllowedProduct[];
};

export type CompanyAvailableProducts = Record<AllowedProduct, boolean>;

export type SelectedCompany = {
  id: string;
  companyName: string;
  onboardingStage: string;
  countryOfRegistration: string;
  managers: Manager[];
  needsManagerReview: boolean;
  numberOfEmployees: number;
  contactName: string;
  contactPhoneNumber: string;
  companyDetails: Field[];
  administrativeInformation: Field[];
  settings: {
    disableContractAutomation?: boolean;
  };
  integrations: CompanyIntegrations;
  isVATExempt: boolean;
  fxConversionPercent: string;
  latePayment?: {
    amount: number;
    interestMargin: number;
  };
};

export type CreatedNetsuiteCustomerApiResponse = {
  netsuiteId: string;
};

export type TransformCompanyFromApi = {
  id: string;
  companyName: string;
  onboardingStage: string;
  countryOfRegistration: string;
  managers: Manager[];
  needsManagerReview: boolean;
  numberOfEmployees: number;
  vatNumber: string;
  companyNumber: string;
  contactName: string;
  contactPhoneNumber: string;
  settings: CompanySettings;
  integrations?: CompanyIntegrations;
  isVATExempt: boolean;
  fxConversionPercent: boolean;
  salaryPrepayment?: {
    terms: number;
    multiplier: number;
  };
  companyDetails: {
    name: string;
    label: string;
    value: string;
    type: string;
    editable?: boolean;
    required?: boolean;
    readOnly?: boolean;
    contextualInfo?: string;
    disabled?: boolean;
  }[];
  administrativeInformation: Field[];
  enabledProducts: AllowedProduct[];
};
