import { createContext, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { TransformedUser } from 'app/store/app.transformers';
import { IS_TEST_ENV } from 'common/constants';
import { FourOhFour } from 'common/pages/404/FourOhFour';
import { User } from 'common/types';
import {
  baseAdminRoutes,
  benefitsSpecialistRoutes,
  CSAdminRoutes,
  financeRoutes,
  OPAdminRoutes,
  payrollRoutes,
} from 'omniplatform/admin/routes';
import EmployeeRoutes from 'omniplatform/employee/routes';
import { LspRoutes } from 'omniplatform/lsp/routes';
import ManagerRoutes from 'omniplatform/manager/routes';
import { lazyWithRetry } from 'utils/lazyWithRetry';

import { Spinner } from '@omnipresentgroup/design-system';

import { SentryRoute, SentryRouteProps } from '../sentry';

const ManagerRoutesLazy = lazyWithRetry(
  () => import('omniplatform/manager/routes'),
);
const EmployeeRoutesLazy = lazyWithRetry(
  () => import('omniplatform/employee/routes'),
);
const LspRoutesLazy = lazyWithRetry(() => import('omniplatform/lsp/routes'));
// Users with these roles should only have a single role
/** @todo Combine with admin routes and use the same logic. Just need to check there aren't any weird users with multiple roles that aren't admins */
const routesByNonAdminTypeRole: Record<string, unknown> = IS_TEST_ENV
  ? {
      manager: <ManagerRoutes />,
      employee: <EmployeeRoutes />,
      lsp: <LspRoutes />,
    }
  : {
      manager: (
        <Suspense fallback={<Spinner size="40" />}>
          <ManagerRoutesLazy />
        </Suspense>
      ),
      employee: (
        <Suspense fallback={<Spinner size="40" />}>
          <EmployeeRoutesLazy />
        </Suspense>
      ),
      lsp: (
        <Suspense fallback={<Spinner size="40" />}>
          <LspRoutesLazy />
        </Suspense>
      ),
    };

// Admin users can possibly have mutliple roles
const routesByAdminTypeRoles: Record<string, unknown> = {
  admin: baseAdminRoutes,
  payroll: payrollRoutes,
  finance: financeRoutes,
  'benefits-specialist': benefitsSpecialistRoutes,
  'viewer:clients-sensitive-data': CSAdminRoutes,
  'viewer:omnipresent-sensitive-data': OPAdminRoutes,
};

const getNonAdminRoutes = (user: User | TransformedUser) => {
  if (user.role && 'type' in user.role) {
    const { role } = user;

    return routesByNonAdminTypeRole[role.type] as JSX.Element;
  }
  return null;
};

export const UserContext = createContext({});

export const AppRouter = ({ user }: { user: User | TransformedUser }) => {
  const { roles } = user;

  const nonAdminRoutes = getNonAdminRoutes(user);

  if (nonAdminRoutes) {
    return nonAdminRoutes;
  }

  const adminTypeRolesRoutes: SentryRouteProps[] = roles.reduce<
    SentryRouteProps[]
  >((acc, { type }) => {
    const routes = routesByAdminTypeRoles[type];
    if (routes && Array.isArray(routes)) {
      return [...acc, ...routes];
    }
    return acc;
  }, []);

  return (
    <Switch>
      {adminTypeRolesRoutes.map((route) => (
        <SentryRoute
          key={route.path}
          exact={route.exact}
          path={route.path}
          component={route.component}
        />
      ))}
      <SentryRoute path="/404" component={FourOhFour} />
      <Redirect to="/404" />
    </Switch>
  );
};
