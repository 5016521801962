export const NONE = 'None';
export const NO_DEPOSIT = 'No Deposit';
export const ZERO_DEPOSIT_INSURANCE = 'Zero Deposit Insurance';
export const ADVANCED_PAYMENT_DEPOSIT = 'Advanced Payment Deposit (APD)';

export const depositTypeOptions = [
  NONE,
  NO_DEPOSIT,
  ZERO_DEPOSIT_INSURANCE,
  ADVANCED_PAYMENT_DEPOSIT,
].map((option) => ({ label: option, value: option }));
