import styled from 'styled-components';

export const TableFilterActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: ${({ theme: { space } }) => space[16]} 0;
  height: ${({ theme: { space } }) => space[32]};
`;
