import styled, { css } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

import TableWithTitle from '../TableWithTitle/TableWithTitle';

export const TabContainer = styled.div`
  display: flex;
  flex-direction: ${(props: any) =>
    props.direction ? props.direction : 'column'};
  align-items: flex-start;
  width: 100%;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    width: 100%;
  }
`;

type TimeOffTableProps = {
  $withActions?: boolean;
  $withName?: boolean;
};

const tableColumnsModifiers = (hasNameColumn?: boolean) => ({
  withActions: css`
    &:nth-last-child(2) {
      width: 100%;
      max-width: 53.5%;

      @media (max-width: ${tokens.breakpoint.laptop}) {
        width: 100px;
      }

      @media (max-width: ${tokens.breakpoint.tablet}) {
        width: 250px;
      }
    }

    &:last-child {
      width: 132px;
    }
  `,
  withoutActions: css`
    &:last-child {
      max-width: ${hasNameColumn ? 48 : 67}%;
    }
  `,
});

export const TimeOffTable = styled(TableWithTitle)<TimeOffTableProps>(
  ({ $withActions: hasActionsColumn, $withName: hasNameColumn }) => css`
    width: 100%;

    table {
      table-layout: fixed;

      th {
        ${hasNameColumn &&
        css`
          &:first-child {
            width: 120px;
          }
        `};

        /* stylelint-disable no-duplicate-selectors */

        &:nth-child(${hasNameColumn ? 2 : 1}) {
          width: 224px;
        }

        &:nth-child(${hasNameColumn ? 3 : 2}) {
          width: 100px;
        }

        /* stylelint-enable no-duplicate-selectors */

        ${tableColumnsModifiers(hasNameColumn)[
          hasActionsColumn ? 'withActions' : 'withoutActions'
        ]};
      }

      tr {
        :hover {
          background: initial;
          box-shadow: initial;
        }
      }
    }
  `,
);
