import { getUserSelector } from 'app/store/app.selectors';
import { getManagersEmployeesRequest } from 'common/api/employees.api';
import { TEN_MINUTES } from 'common/constants';
import { SimplifiedEmployeesApiResponse } from 'common/types';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { createQueryKeys, getDataFromResponse } from 'utils/react-query-utils';
import { sortObjectsByKey } from 'utils/sortObjectsByKey';
import { isAdmin } from 'utils/user';

import { isRedacted } from '@omnipresentgroup/design-system';

const queryKeys = createQueryKeys(
  'compensation-change-employees',
  (createKey) => ({
    admin: (userId: string) => createKey(userId, 'admin'),
    manager: (userId: string) => createKey(userId, 'manager'),
  }),
);

export const useEmployeesForCompChangeQuery = ({
  companyId,
}: {
  companyId: string;
}) => {
  const user = useSelector(getUserSelector);
  const isUserAdmin = isAdmin(user);

  return useQuery<
    SimplifiedEmployeesApiResponse,
    Error,
    {
      id: string;
      value: string;
      label: string;
    }[]
  >(
    isUserAdmin ? queryKeys.admin(user.userId) : queryKeys.manager(user.userId),
    () => getManagersEmployeesRequest(companyId).then(getDataFromResponse),
    {
      enabled: !!companyId,
      select: (data) =>
        sortObjectsByKey({
          key: 'label',
          list: data.employees.map((employee) => ({
            id: employee.id,
            value: employee.id,
            label: employee.name,
            description: isRedacted(employee.email)
              ? 'Redacted'
              : employee.email,
          })),
        }),
      staleTime: TEN_MINUTES,
    },
  );
};
