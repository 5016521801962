import { useHistory } from 'react-router-dom';

import { ExpenseStatus } from 'common/components/molecules/ExpenseStatus/ExpenseStatus';
import { ExpenseFromAPI } from 'common/types';
import { DateTime } from 'luxon';
import { EXPENSE_LIST_PAGE } from 'paths';

import {
  Card,
  DateFormats,
  formatISODate,
  Inline,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

export const ExpenseMobileCard = ({ expense }: { expense: ExpenseFromAPI }) => {
  const history = useHistory();
  return (
    <Card
      p="16"
      css={{ cursor: 'pointer' }}
      key={expense.id}
      onClick={() => history.push(`${EXPENSE_LIST_PAGE}/${expense.id}`)}
    >
      <Stack gap="12">
        <Inline>
          <Typography as="span" size="20" weight="medium">
            {expense.title}
          </Typography>
        </Inline>
        <Inline>
          <Typography as="span" size="14">
            {expense.employeeName}
          </Typography>
        </Inline>
        <Inline justify="space-between">
          <Typography as="span" size="14">
            {expense.amount.currency}
          </Typography>
          <Typography as="span" size="14">
            {expense.amount.currency} {expense.amount.amount}
          </Typography>
        </Inline>
        <Inline justify="space-between">
          <Typography size="14" as="span">
            {DateTime.fromISO(expense.dateSpent).toLocaleString({
              weekday: 'long',
            })}
            , {formatISODate(expense.dateSpent, DateFormats.Date)}
          </Typography>
          <Typography size="14" as="span">
            {expense.estimatedPayrollMonth
              ? `${formatISODate(expense.estimatedPayrollMonth, 'MMM yyyy')}
            / ${expense.approverName}`
              : ''}
          </Typography>
        </Inline>
        <Inline justify="flex-end">
          <ExpenseStatus status={expense.status} />
        </Inline>
      </Stack>
    </Card>
  );
};
