import { Redacted } from 'common/types/redacted';

import { type IsoDate } from '@omnipresentgroup/design-system';

import { DepositType } from '.';
import { CompanySettings } from './company';
import { Contract } from './contract';
import { AvvokaQuestion } from './contractTemplate';
import { Field } from './form';
import { SetupFeeStructureEnum } from './seat';

export type EmployeeHasSupplementaryBenefitsType = 'yes' | 'no' | 'unknown';
export enum OnboardingStatusValues {
  INPROGRESS = 'In progress',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export type Employee = {
  onboardingStatus: { status: OnboardingStatusValues };
  id: string;
  name: string;
  externalId: string;
  userId: string;
  lspId: string;
  adminNotes: string;
  createdAt: string;
  needsRequiredFieldsFilled: boolean;
  currentEmploymentId?: string;
  requireTypeFormFilled: boolean;
  localOnboardingDetails: Array<EmployeeLocalOnboardingDetails>;
  companyName: string;
  metadata?: {
    contracts?: Array<Contract>;
  };
  payrollDetails: PayrollDetailsFields;
  managerId: string;
  country: string; // FIXME: should be as below because this is API return not the employee from the redux store
  // country: {
  //   id: string;
  //   currency: string;
  //   name: string;
  // };
  // FIXME: this type has no contractQuestionnaire et. ali types
  employeeType: EmployeeType;
  // socialInsuranceNumber: string;
  personalDetails?: {
    firstName?: string;
    lastName?: string;
    gender?: string;
    dateOfBirth?: string;
    bankingDetails?: {
      accountNumber?: string;
      bicSwift?: string;
      routingNumber?: string;
      socialInsuranceNumber?: string;
      iban?: string;
    };
    privateAddress?: {
      addressLine1: string;
      city: string;
      postalCode: string;
    };
    socialInsuranceNumber?: {
      number: string;
    };
  };
  employmentDetails: {
    startDate: IsoDate;
    annualBaseSalary: {
      amount: Redacted | number;
      currency: string;
    };
    hoursOfWork: number;
    jobDescription: string;
    jobTitle: string;
    hasSupplementaryBenefits: EmployeeHasSupplementaryBenefitsType;
    locationOfWork?: {
      address?: {
        addressLine1: string;
        city: string;
        postalCode: string;
      };
    };
  };
  contactDetails?: {
    personalEmail: string;
    phoneNumber: string;
    workEmail: string;
  };
};

type PayrollDetailsFields = {
  billingCurrency?: string;
  managementFee?: { amount: string; currency: string };
  setupFee?: { amount: string; currency: string };
  setupFeeStructure?: SetupFeeStructureEnum;
  hasDepositInsurance?: boolean;
  depositType?: DepositType;
  managementFeeWaiver?: {
    percentage: number;
    periodStart: Date;
    billingCycles: number;
  };
};

export type SensitiveField = {
  redacted: boolean;
  missing: boolean;
};

export type SimplifiedEmployee = {
  id: string;
  userId: string;
  managerId: string;
  lspId: string;
  name: string;
  companyName?: string;
  country: string;
  currency: string;
  annualBaseSalary: number | SensitiveField;
  jobTitle: string;
  startDate: string;
  onboardingStatus: string;
  hasSupplementaryBenefits: EmployeeHasSupplementaryBenefitsType;
  needsRequiredFieldsFilled: boolean;
  requireTypeFormFilled: boolean;
  totalStages?: number;
  latestInProgressStageId?: number;
  email?: Redacted | string;
};

export type SimplifiedEmployeesApiResponse = {
  employees: SimplifiedEmployee[];
};

export type PaginatedEmployeesApiResponse = {
  employees: EmployeeForPaginationView[];
  nextPageKey: string;
};

export type EmployeeLocalOnboardingDetails =
  | DetailKnownField
  | DetailUnknownField;

export type DetailKnownField = {
  name: string;
  type: LODFieldType;
  value: any;
};

export type DetailUnknownField = {
  type: LODFieldType;
  value: any;
  label?: string;
  originalRef?: string;
  labelBold?: boolean;
  title?: string;
  ref?: string;
  answerType?: TypeformAnswerType;
  fieldType?: string;
};

export type LODFieldType =
  | 'text'
  | 'date'
  | 'boolean'
  | 'file'
  | 'choice'
  | 'choices'
  | 'email'
  | 'url'
  | 'number'
  | 'payment'
  | 'checkbox';

export type TypeformAnswerType =
  | 'choice'
  | 'choices'
  | 'date'
  | 'email'
  | 'url'
  | 'file_url'
  | 'number'
  | 'boolean'
  | 'text'
  | 'payment';

export type CurrentStageByEmployee = {
  [employeeId: string]: {
    totalStages?: number;
    currentStageId?: number;
  };
};

export const employeeTypeOptions = [
  { value: 'EOR', label: 'EOR' },
  { value: 'PEO', label: 'PEO' },
  { value: 'Virtual Employer', label: 'Virtual Employer' },
];
const employeeTypeList = ['EOR', 'PEO', 'Virtual Employer'] as const;

export type EmployeeType = (typeof employeeTypeList)[number];
export type EmployeeForPaginationView = {
  id: string;
  userId: string;
  name: string;
  country: string;
  currency: string;
  jobTitle: string;
  startDate: string;
  onboardingStatus: string;
  managerId: string;
  company: { id?: string; name?: string };
  lsp: { id?: string; name?: string };
  annualBaseSalary: {
    redacted: boolean;
    missing: boolean;
  };
};

export type EmployeeForDetailView = Omit<
  Employee,
  | 'adminNotes'
  | 'createdAt'
  | 'metadata'
  | 'employmentDetails'
  | 'bankingDetails'
  | 'socialInsuranceNumber'
  | 'payrollDetails'
  | 'onboardingStatus'
> & {
  administrativeInformation: Field[];
  companyName: string;
  jobTitle: string;
  onboardingStatus: string;
  hasSupplementaryBenefits: EmployeeHasSupplementaryBenefitsType;
  lspId: string;
  companySettings: CompanySettings;
  contractQuestionnaire?: {
    templateId?: string;
    body: Record<string, AvvokaQuestion>;
  };
  contracts?: Array<Contract>;
  employmentDetails: Field[];
  bankingDetails: Field[];
  socialInsuranceNumber: Field[];
  payrollDetails: Field[];
  personalDetails: Field[];
  roleDetails: Field[];
  bankDetails: Field[];
  employmentInformation?: string;
};
export enum EmployeeActionEnum {
  ARCHIVE = 'Archive',
}

export enum ManagerEmployeeEnum {
  REQUEST_TERMINATION = 'Request termination',
}

export const EmployeeActions = Object.values(EmployeeActionEnum);
export const ManagerEmployeeActions = Object.values(ManagerEmployeeEnum);

export type Amount = {
  amount: number;
  currency: string;
};

export type ManagerEmployee = {
  country: {
    id: string;
    currency: string;
    name: string;
  };
  employmentDetails: {
    jobTitle: string;
  };
  name: string;
  userId: string;
};

export type ManagerEmployeesApiResponse = {
  employees: ManagerEmployee[];
};

type Step = {
  id: string;
  name: string;
};

type OnboardingStageApi = {
  id: number;
  name: string;
  steps: Step[];
};

export type EmployeeOnboardingProgressApiResponse = {
  completedSteps: string[];
  onboardingStages: OnboardingStageApi[];
};

export type EmployeeOnboardingProgressApiPatchResponse =
  EmployeeOnboardingProgressApiResponse & {
    employeeId: string;
    onboardingFlowId: string;
  };

export type DownloadBulkCompensationCSVTemplateRequest = {
  companyId: string;
  employeesIds: string[];
  compensationType: string;
};

export type ValidateBulkSalaryChangeRequest = {
  companyId: string;
  type: string;
  csvFileUrl: string;
};

export type SubmitBulkSalaryChangeRequest = {
  companyId: string;
  type: string;
  csvFileUrl: string;
};

export type PaginatedPayload = {
  employeeName?: string;
  companyId?: string;
  employeeId?: string;
  country?: string;
  personalEmail?: string;
  lspId?: string;
  payrollId?: string;
  externalId?: string;
  onboardingStatus?: string;
};
