import { createQueryKeys } from 'utils/react-query-utils';

export const employeesKeys = createQueryKeys('employees', (createKey) => ({
  allemployees: createKey('employees'),
  singleEmployee: (employeeId: string) =>
    createKey(employeeId, 'single-employee'),
  managerEmployees: (managerId: string) =>
    createKey(managerId, 'manager-employees'),
  paginatedEmployees: (
    employeeName?: string,
    companyId?: string,
    country?: string,
    personalEmail?: string,
    employeeId?: string,
    payrollId?: string,
    externalId?: string,
    lpsId?: string,
    onboardingStatus?: string,
  ) =>
    createKey(
      employeeName,
      companyId,
      country,
      personalEmail,
      employeeId,
      payrollId,
      externalId,
      lpsId,
      onboardingStatus,
      'paginated-employees',
    ),
}));
