import Button from 'common/components/atoms/Button';
import { ModalWrapper } from 'common/components/molecules/ModalWrapper';

import {
  StyledButtonContainer,
  StyledContentContainer,
  StyledHeader,
  StyledModalContainer,
} from './TooltipModal.styles';

export type TooltipModalProps = {
  title: string;
  children: React.ReactNode;
  clickHandler: () => void;
};

export const TooltipModal = ({
  title,
  children,
  clickHandler,
}: TooltipModalProps) => {
  return (
    <ModalWrapper fixedWidth="500">
      <StyledModalContainer>
        <StyledContentContainer>
          <div>
            <StyledHeader>{title}</StyledHeader>
            {children}
          </div>
        </StyledContentContainer>
        <StyledButtonContainer>
          <Button palette="primary" onClick={clickHandler}>
            Okay
          </Button>
        </StyledButtonContainer>
      </StyledModalContainer>
    </ModalWrapper>
  );
};
