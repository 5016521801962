import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { Tabs } from 'common/components/organisms/Tabs/Tabs';

import { getTabs } from './ProviderPageTabs';

export const ProviderListPage = () => {
  const location = useLocation();
  const [currentHash, setCurrentHash] = useState(location.hash || null);

  useEffect(() => {
    setCurrentHash(location.hash);
  }, [location]);

  const tabs = getTabs();

  return (
    <PageContent data-testid="providers-list-page" className="bigStack">
      <Tabs
        tabsToDisplay={tabs}
        currentTab={
          currentHash
            ? tabs.filter((tab) => tab.href === currentHash)[0]
            : tabs[0]
        }
      />
    </PageContent>
  );
};
