import { CompensationType } from 'common/types/compensation';

// Manager
export const ADD_EMPLOYEE_PAGE_PATH = '/employees/add-employee';
export const ADD_EMPLOYEE_CHECKLIST_PAGE_PATH = '/employees/employee-checklist';
export const EMPLOYEES_PATH = '/employees';
export const CONTRACTORS_PATH = '/contractors';
export const CONTRACTOR_INVOICES_PATH = '/contractor-invoices';
export const getEmployeeViewPath = (employeeId: string) =>
  `/employees/${employeeId}#details`;
export const COMPANY_PROFILE_PAGE = '/profile';
export const MANAGER_SEATS_LIST_PATH = `/employees#pending-employees`;
export const MANAGER_INTEGRATIONS_PATH = `/integrations`;
export const MANAGER_INVOICE_LIST_PATH = `/invoices`;
export const MANAGER_BALANCES_PATH = `/balances`;
export const MANAGER_PAYROLL_LIST_PATH = `/payroll-reports`;
export const MANAGER_ATLAS_PATH = `/atlas`;
export const MANAGER_ATLAS_COMPARE_PATH = `/atlas/compare`;
export const MANAGER_DASHBOARD_PATH = `/dashboard`;
export const MANAGER_PERMISSIONS_PATH = `/permissions`;
export const BULK_COMPENSATION_PATH = `/bulk-compensation`;
export const COMPENSATION_PATH = `/compensation`;
export const ADD_COMPENSATION_PATH = `${COMPENSATION_PATH}/add`;
export const ADD_COMPENSATION_SALARY_PATH = `${ADD_COMPENSATION_PATH}/${CompensationType.SALARY_CHANGE}`;
export const ADD_COMPENSATION_ONE_TIME_VARIABLE_PAY_PATH = `${ADD_COMPENSATION_PATH}/${CompensationType.ONE_TIME_VARIABLE_PAY}`;
export const ADD_COMPENSATION_RECURRING_VARIABLE_PAY_PATH = `${ADD_COMPENSATION_PATH}/${CompensationType.RECURRING_VARIABLE_PAY}`;
export const SALARY_HISTORY = `salary-history`;
export const VARIANT_PAYMENTS = `variant-payments`;
export const ADD_HIRE_PATH = (companyId = ':companyId') =>
  `/companies/${companyId}/add-hire`;
export const ADD_CONTRACTOR_PATH = (companyId = ':companyId') =>
  `/companies/${companyId}/add-contractor`;
export const CONTRACTUAL_COMPENSATION_PATH = (employeeId = ':employeeId') =>
  `/employees/${employeeId}/contractual-compensation`;

// Employee
export const EMPLOYEE_PROFILE_PAGE = '/profile';
export const EMPLOYEE_EXPENSE_LIST_PAGE = '/expenses';
export const ADD_EMPLOYEE_EXPENSE_PAGE = '/expenses/add-expense';
export const ADD_DOCUMENT_PAGE = '/documents/add-document';
export const MY_EMPLOYMENT_PAGE = '/my-employment';
export const EMPLOYMENT_HISTORY = '/history';
export const TIMESHEETS_PAGE = '/timesheets';

// resignations
export const CREATE_RESIGNATION = (employeeId = ':employeeId') =>
  `/employee/${employeeId}/resignation/add`;
export const RESIGNATION_SUMMARY = (employeeId = ':employeeId') =>
  `/employee/${employeeId}/resignation/summary`;
export const getEmployeeResignationRequestsPath = (companyId: string) =>
  `/company/?company=${companyId}#resignation-requests`;

// LSP (Local Service Provider)
export const LSP_PROFILE_PAGE = '/profile';
export const LSP_EMPLOYEES_PAGE = '/employees';
export const LSP_EXPENSES_PAGE = '/expenses';
export const ADD_LSP_PAGE_PATH = '/local-service-providers/add';
export const EDIT_LSP_PAGE_PATH = '/local-service-providers/:lspId/edit';
export const getEmployeeProfilePage = (employeeId: string) =>
  `/employees/${employeeId}`;

// Admin
export const COMPANIES_LIST_PAGE = '/companies';
export const getCompanyProfilePage = (companyId: string) =>
  `${COMPANIES_LIST_PAGE}/${companyId}`;
export const getEmployeeListPath = (companyId: string) =>
  `/companies/${companyId}#employees`;
export const getAddEmployeeChecklistPath = (companyId: string) =>
  `/companies/${companyId}/employee-checklist`;
export const getEmployeeSeatListPath = (companyId: string) =>
  `/companies/${companyId}#pending-employees`;
export const getEmployeeTerminationRequestsPath = (companyId: string) =>
  `/companies/${companyId}#termination-requests`;
export const getSeatPath = (companyId: string, seatId: string) =>
  `${COMPANIES_LIST_PAGE}/${companyId}/pending-employee/${seatId}`;
export const getEditSeatPath = (companyId: string, seatId: string) =>
  `${COMPANIES_LIST_PAGE}/${companyId}/pending-employee/${seatId}/edit`;
export const LSP_LIST_PAGE = '/local-service-providers';
export const getLspProfilePage = (companyId: string) =>
  `${LSP_LIST_PAGE}/${companyId}`;
export const ALL_EMPLOYEES_PAGE = '/employees';
export const ADD_EMPLOYMENT_PAGE = (employeeId = ':employeeId') =>
  `/employees/${employeeId}/employments/add`;
export const EDIT_EMPLOYMENT_PAGE = (
  employeeId = ':employeeId',
  employmentId = ':employmentId',
) => `/employees/${employeeId}/employments/${employmentId}/edit`;
export const BACK_OFFICE_PAGE = '/back-office';

export const BO_CONTRACTUAL_COMPENSATION_PATH = (employeeId?: string) =>
  `/employees/contractual-compensation${
    employeeId ? `?employeeId=${employeeId}` : ''
  }`;

// Providers
export const PROVIDERS_PAGE = '/providers';
export const PROVIDERS_EMPLOYER_ADD = `${PROVIDERS_PAGE}/employer/add`;
export const getEditEmployerPath = (id: string) =>
  `${PROVIDERS_PAGE}/employer/${id}/edit`;
export const PROVIDERS_PP_ADD = `${PROVIDERS_PAGE}/payroll-provider/add`;
export const getEditPayrollProviderPath = (id: string) =>
  `${PROVIDERS_PAGE}/payroll-provider/${id}/edit`;

// Payroll
export const PAYROLL_PAGE = '/payroll';
export const PAYROLL_INSTANCE_DETAIL_PAGE = (payrollInstanceId: string) =>
  `/payroll/payroll-instances/${payrollInstanceId}`;
export const payReportPreviewPage = (
  payrollInstanceId: string,
  dataFrameId: string,
) =>
  `/payroll/payroll-instances/${payrollInstanceId}/pay-report-preview/${dataFrameId}`;
export const lspBillsDetailPage = (
  payrollInstanceId: string,
  query?: Record<string, string>,
) => {
  const queryString = new URLSearchParams(query).toString();
  return `/payroll/payroll-instances/${payrollInstanceId}/lspBillsDetail${
    queryString ? `?${queryString}` : ''
  }`;
};

// Payroll
export const FINANCE_PAGE = '/finance';

// Payments
export const PAYMENTS_PAGE = '/payments';
export const PAYMENTS_LOGIN_CALLBACK =
  '/payments/login-callback/:authorisationCode';

// Shared
export const HOME_PAGE_PATH = '/';
export const DOCUMENT_LIST_PAGE = '/documents';
export const VERIFY_EMAIL_PAGE = '/profile/verify-email';
export const ACCOUNT_DETAILS_PAGE = '/account-details';
export const TIME_OFF_PAGE = '/time-off';
export const EXPENSE_LIST_PAGE = '/expenses';

// User management
export const ADMIN_LIST_PAGE = '/admins';
export const ADD_ADMIN_PAGE = '/admins/add-admin';

// Benefits
export const BENEFITS_PAGE = '/benefits';

export const getConvertSeatChecklistPagePath = (
  companyId: string,
  seatId: string,
) => {
  return `/companies/${companyId}/pending-employees/${seatId}/employee-checklist`;
};

export const getSeatConversionFormPagePath = (
  companyId: string,
  seatId: string,
  country?: string,
) => {
  return `/companies/${companyId}/pending-employees/${seatId}/complete-profile${
    country ? `?country=${country}` : ''
  }`;
};

export const getAddEmployeeSeatPath = (companyId: string, country?: string) =>
  `/companies/${companyId}/add-pending-employee${
    country ? `?country=${country}` : ''
  }`;
export const getEditEmployeeSeatPath = (companyId: string, seatId: string) =>
  `/companies/${companyId}/pending-employee/${seatId}/edit`;
export const getEmployeeSeatPath = (companyId: string, seatId: string) =>
  `/companies/${companyId}/pending-employee/${seatId}`;
export const getEmployeeBulkUploadPath = (companyId: string) =>
  `/companies/${companyId}/employees-upload`;

export const getContractPath = (
  isEmployee: boolean,
  documentId: string,
  employeeId: string,
) => {
  const contractPath = `contracts/${documentId}`;
  if (isEmployee) {
    return contractPath;
  }
  return `${EMPLOYEES_PATH}/${employeeId}/${contractPath}`;
};

// termination request form page
export const CREATE_TERMINATION_REQUEST = (
  companyId = ':companyId',
  employeeId = ':employeeId',
) => `/companies/${companyId}/employee/${employeeId}/termination-requests/add`;

// view termination request page
export const VIEW_TERMINATION_REQUEST = (
  companyId = ':companyId',
  employeeId = ':employeeId',
  terminationRequestId = ':terminationRequestId',
) =>
  `/companies/${companyId}/employee/${employeeId}/termination-requests/${terminationRequestId}/view`;
