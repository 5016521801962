import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledFormPage = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme: { radii } }) => radii.s};

  label {
    padding-left: 0;
  }

  @media (max-width: ${tokens.breakpoint.tablet}) {
    box-shadow: none;
    width: 100%;

    button {
      align-self: center;
      width: 100%;
      max-width: 100%;
    }
  }
`;
