import { ReactComponent as CheckIcon } from 'assets/icon/CheckIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icon/Minus.svg';
import styled from 'styled-components';

const checkboxSize = ({ theme: { space } }) => space[16];
const checkboxBorderWidth = ({ theme: { borderWidths } }) => borderWidths.s;
const checkboxBorderStyle = ({ theme: { borderStyles } }) => borderStyles.solid;
const checkboxBorderRadius = ({ theme: { radii } }) => radii.s;
const checkboxIconColor = ({ theme: { colors } }) => colors['icon-on-color'];
const checkboxBorderColor = ({ theme: { colors } }) =>
  colors['control-border-default'];
const checkboxDisabledColor = ({ theme: { colors } }) =>
  colors['control-background-interactive-disabled'];
const checkboxCheckedColor = ({ theme: { colors } }) =>
  colors['control-background-interactive'];
const checkboxOnHoverBorderColor = ({ theme: { colors } }) =>
  colors['control-background-interactive'];
const checkboxOnFocusBorderColor = ({ theme: { colors } }) =>
  colors['control-background-interactive'];
const size = (props) => {
  return props.size ? props.size : checkboxSize;
};

export const StyledIcon = styled(CheckIcon)`
  position: absolute;
  pointer-events: none;
  display: none;
`;

export const StyledMinusIcon = styled(MinusIcon)`
  position: absolute;
  pointer-events: none;
  display: none;
`;

export const StyledCheckboxWrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  opacity: 0;
  height: calc(${size} + calc(${checkboxBorderWidth} * 2));
  width: calc(${size} + calc(${checkboxBorderWidth} * 2));
`;

export const StyledCheckbox = styled.div`
  height: ${size};
  width: ${size};
  color: ${checkboxIconColor};
  border-radius: ${checkboxBorderRadius};
  border: ${checkboxBorderWidth} ${checkboxBorderStyle} ${checkboxBorderColor};
  display: flex;
  align-items: center;
  justify-content: center;

  ${/* sc-selector */ StyledInput}:disabled ~ & {
    border-color: ${checkboxDisabledColor};
  }

  ${/* sc-selector */ StyledInput}:checked ~ & {
    background-color: ${checkboxCheckedColor};
    border-color: ${checkboxCheckedColor};

    ${StyledIcon} {
      height: calc(${size} - calc(${checkboxBorderWidth} * 2));
      display: block;
    }
  }

  ${/* sc-selector */ StyledInput}:indeterminate ~ & {
    background-color: ${checkboxCheckedColor};
    border-color: ${checkboxCheckedColor};

    ${StyledMinusIcon} {
      display: block;
    }
  }

  ${/* sc-selector */ StyledInput}:checked:disabled ~ & {
    background-color: ${checkboxDisabledColor};
    border-color: ${checkboxDisabledColor};
  }

  ${/* sc-selector */ StyledInput}:hover:not(:checked):not(:disabled) ~ & {
    border-color: ${checkboxOnHoverBorderColor};
  }

  ${/* sc-selector */ StyledInput}:focus:not(:checked) ~ & {
    border-color: ${checkboxOnFocusBorderColor};
  }
`;
