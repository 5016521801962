import styled, { css } from 'styled-components';

import { Inline, omniConfig, Stack } from '@omnipresentgroup/design-system';

type CompensationTypesCardsProps = {
  $isSelectedCompensation?: string;
};

export const CompensationTypesCards = styled(
  Inline,
)<CompensationTypesCardsProps>`
  ${(props) => {
    const isBonusSelected = props?.$isSelectedCompensation === 'bonus';
    const isCommissionSelected =
      props?.$isSelectedCompensation === 'commission';
    const isSalarySelected = props?.$isSelectedCompensation === 'salary';
    let cardIndex = 0;
    if (isBonusSelected) {
      cardIndex = 1;
    }
    if (isCommissionSelected) {
      cardIndex = 2;
    }
    if (isSalarySelected) {
      cardIndex = 3;
    }
    return css`
      > div:nth-child(${cardIndex}) {
        border-color: ${omniConfig.theme.colors[
          'button-border-secondary-active'
        ]};
      }
    `;
  }}
`;

export const CompensationOptionStack = styled(Stack)`
  cursor: pointer;
  border-radius: ${omniConfig.theme.radii.m};
  border-width: ${omniConfig.theme.borderWidths.s};
  border-style: ${omniConfig.theme.borderStyles.solid};
  border-color: ${omniConfig.theme.colors['border-strong']};
  text-align: center;
`;
