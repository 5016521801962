import { Link } from 'react-router-dom';

import { ExpenseFromAPI } from 'common/types/expenses';

import DownloadDocumentButton from '../../../components/molecules/DownloadDocumentButton/DownloadDocumentButton';
import { ExpenseStatus } from '../../../components/molecules/ExpenseStatus/ExpenseStatus';
import { StyledTitleCell } from '../../../components/organisms/TableWithTitle/TableWithTitle.styles';

type TitleCellProps = {
  title: string;
  id: string;
};

// eslint-disable-next-line react/prop-types
const TitleCustomCell = (props: TitleCellProps) => (
  <StyledTitleCell>
    <Link to={`/expenses/${props.id}?referrer=employee`}>{props.title}</Link>
  </StyledTitleCell>
);

const ExpenseCustomCell = (expense: ExpenseFromAPI) => {
  const { documentId } = expense;
  return <>{documentId && <DownloadDocumentButton {...expense} />}</>;
};

const StatusCustomCell = (expense: ExpenseFromAPI) => (
  <ExpenseStatus {...expense} />
);

export const employeeExpenseTableHeaders = [
  {
    id: 'title',
    headerTitle: 'Spent at',
    customCell: TitleCustomCell,
    maxWidth: true,
  },
  {
    id: 'amount',
    headerTitle: 'Amount',
    fieldOnObject: 'amount',
  },
  {
    id: 'date-spent',
    headerTitle: 'Date spent',
    fieldOnObject: 'dateSpent',
  },
  {
    id: 'proof-of-purchase',
    headerTitle: 'Proof of purchase',
    customCell: ExpenseCustomCell,
  },
  {
    id: 'status',
    headerTitle: 'Status',
    customCell: StatusCustomCell,
  },
];
