import styled from 'styled-components';

import { omniConfig } from '@omnipresentgroup/design-system';

const tableBorderRadius = omniConfig.theme.radii.s;
const tableBorderColor = omniConfig.theme.colors['border-subtle'];
const tableBorderStyle = omniConfig.theme.borderStyles.solid;
const tableBorderWidth = '1px'; // TODO: Leaving the hardcoded value for now until new tables are ready from OmniDS

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: visible;
  position: relative;
  border-radius: ${tableBorderRadius};
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
`;

export const StyledTableHeader = styled.th`
  border-bottom: ${tableBorderWidth} ${tableBorderStyle} ${tableBorderColor};
  text-align: ${({ align }) => align || 'left'};
  font-family: ${({ theme: { fonts } }) => fonts.default};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  color: ${({ theme: { colors } }) => colors['text-default']};
  padding: ${({ theme: { space } }) => space[16]};
`;

export const StyledTableCell = styled.td`
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  border-bottom: ${tableBorderWidth} ${tableBorderStyle} ${tableBorderColor};
  font-family: ${({ theme: { fonts } }) => fonts.default};
  margin: 0;
  padding: 0;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  font-weight: ${(props) => (props.bold ? 700 : 400)};
  text-align: ${(props) => props.align};
  max-width: ${(props) => (props.maxWidth ? '70px' : '100%')};

  &:last-child {
    padding: ${({ theme: { space } }) => `${space[4]} ${space[16]}`};
  }

  &:not(:last-child) {
    padding: ${({ theme: { space } }) =>
      `${space[4]} 0 ${space[4]} ${space[16]}`};
  }
`;

export const StyledTableRow = styled.tr`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};
  overflow: visible;
  transition: all 400ms ease-in-out;

  :hover {
    box-shadow: ${({ theme: { shadows } }) => shadows.s};
    background-color: ${({ theme: { colors } }) => colors['gray-100']};
    transition: all 400ms ease-in-out;
  }
`;

export const StyledHeaderRow = styled.tr`
  color: ${({ theme: { colors } }) => colors['text-subtle']};
  background-color: ${({ theme: { colors } }) => colors['gray-100']};
`;

export const StyledTableTitle = styled.h2`
  align-self: flex-start;
  margin-bottom: ${({ theme: { space } }) => `${space[8]}`};
  padding: ${({ theme: { space } }) => `0 ${space[4]}`};
`;

export const StyledTableCopy = styled.p`
  align-self: flex-start;
`;

export const StyledTableCellWrapper = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'left'};
  margin: ${({ theme: { space } }) => `${space[16]} 0`};
`;
