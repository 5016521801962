import appHistory from 'app/appHistory';
import { ChevronRight } from 'react-feather';

import * as S from './PageBreadCrumb.styles';

type Crumb = {
  name: string;
  link: string;
};

type PageBreadCrumbProps = {
  crumbs: Crumb[];
  className?: string;
};

export const PageBreadCrumb = ({ crumbs, className }: PageBreadCrumbProps) => {
  const pageCrumbs = crumbs.map((crumb, key) => {
    if (crumb.link === 'active') {
      return <S.ActiveCrumb key={key}>{crumb.name}</S.ActiveCrumb>;
    } else {
      return (
        <div key={key}>
          <S.Crumb onClick={() => appHistory.push(crumb.link)}>
            {crumb.name}
          </S.Crumb>
          <ChevronRight />
        </div>
      );
    }
  });

  return (
    <S.CrumbWrapper data-testid="crumbWrapper" className={className}>
      {pageCrumbs}
    </S.CrumbWrapper>
  );
};
