import { submitBulkSalaryChange } from 'common/api/employees.api';
import { ValidateBulkSalaryChangeRequest } from 'common/types';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useSubmitBulkSalaryChangeMutation = () => {
  return useMutation(
    async ({
      companyId,
      type,
      csvFileUrl,
    }: ValidateBulkSalaryChangeRequest) => {
      const res = await submitBulkSalaryChange(companyId, type, csvFileUrl);
      return getDataFromResponse(res);
    },
  );
};
