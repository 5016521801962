import { MarkDownEditor } from 'common/components/molecules/MarkDownEditor/MarkDownEditor';

import { Typography } from '@omnipresentgroup/design-system';

import { FieldsWrapper, StyledTab } from './Tab.styles';

type EmploymentFAQTabProps = {
  employmentInformation: string;
  saveEmploymentInformation: (data: string) => void;
};

export const EmploymentFAQTab = ({
  employmentInformation,
  saveEmploymentInformation,
}: EmploymentFAQTabProps) => (
  <StyledTab data-testid="employment-faq-tab-page">
    <FieldsWrapper className="stack">
      <Typography as="span" size="16" weight="bold">
        Please be careful!, whatever you put here will be displayed to this
        employee
      </Typography>

      <MarkDownEditor
        content={employmentInformation}
        saveMarkDownContent={saveEmploymentInformation}
        placeholder="Please paste employment information for client employee here"
      />
    </FieldsWrapper>
  </StyledTab>
);
