import { client } from 'api';
import { AxiosResponse } from 'axios';
import {
  PostTerminationRequestModel,
  TerminationRequestModel,
} from 'common/types/terminations';

export const getTerminationRequest = (
  id: string,
): Promise<AxiosResponse<TerminationRequestModel>> => {
  return client.get<TerminationRequestModel>(`/terminations/requests/${id}`);
};

export const getTerminationRequests = (
  companyId: string,
): Promise<AxiosResponse<TerminationRequestModel[]>> => {
  return client.get<TerminationRequestModel[]>(
    `/terminations/requests?companyId=${companyId}`,
  );
};

export const createTerminationRequest = (
  terminationRequest: PostTerminationRequestModel,
): Promise<AxiosResponse<TerminationRequestModel>> =>
  client.post<TerminationRequestModel>(
    `/terminations/requests`,
    terminationRequest,
  );
