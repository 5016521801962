import { createQueryKeys } from 'utils/react-query-utils';

export const employeerCostsKeys = createQueryKeys(
  'employeerCosts',
  (createKey) => ({
    costs: (
      countryName: string,
      currencyCode: string,
      annualBaseSalary: number,
      hasFxRate: boolean,
    ) => createKey(countryName, currencyCode, annualBaseSalary, hasFxRate),
  }),
);
