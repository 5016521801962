import { StyledSmallCopy } from 'common/components/atoms/Text';
import { ContractStatusDescription } from 'common/types';
import { FileText } from 'react-feather';

import {
  Button,
  Inline,
  Tag,
  TagIntent,
  Typography,
} from '@omnipresentgroup/design-system';

import {
  ActionItem,
  ActionsDropDown,
} from '../ActionsDropDown/ActionsDropDown';
import { StyledEmployeeDocuments } from './EmployeeDocumentDetails.styles';

export interface Props {
  heading: string;
  label?: string | null;
  intent?: TagIntent;
  statusDescription?: ContractStatusDescription | null;
  uploadType?: string | null;
  onClick: any;
  subHeading: string;
  actions?: ActionItem[];
}

export const EmployeeDocumentDetails = ({
  heading,
  label,
  intent,
  statusDescription,
  uploadType,
  onClick,
  subHeading,
  actions,
}: Props) => (
  <StyledEmployeeDocuments>
    <div>
      <FileText size={48} strokeWidth="1px" />
      <div>
        <Typography as="p" hideParagraphSpacing size="16">
          {heading}
        </Typography>
        <StyledSmallCopy>{subHeading}</StyledSmallCopy>
        <Inline gap="8">
          {uploadType && (
            <Tag id="upload_type_badge" intent="default" outlined={true}>
              {uploadType}
            </Tag>
          )}
          {statusDescription && (
            <Tag id="status_badge" intent={intent} outlined={false}>
              {statusDescription}
            </Tag>
          )}
        </Inline>
      </div>
    </div>
    <div>
      {label && (
        <Button onClick={onClick} variant="secondary" size="medium">
          {label}
        </Button>
      )}
      {actions?.length ? <ActionsDropDown actions={actions} /> : null}
    </div>
  </StyledEmployeeDocuments>
);
