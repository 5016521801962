import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Tabs } from 'common/components/organisms/Tabs/Tabs';
import { TimeOffLogsResponseUnion } from 'common/types';
import { EMPLOYEES_PATH } from 'paths';
import { useSelectUserRoles } from 'utils/hooks';

import { scheduledTimeOffTable, takenTimeOffTable } from './constants';
import * as Styled from './TimeOffContent.styles';

type TimeOffContentProps<T extends TimeOffLogsResponseUnion> = {
  data: T;
  handleEmptyState?: () => void;
};

export const TimeOffContent = <T extends TimeOffLogsResponseUnion>({
  data,
  handleEmptyState,
}: TimeOffContentProps<T>) => {
  const { hash, pathname } = useLocation();
  const { isEmployee } = useSelectUserRoles();

  const [currentTabId, setCurrentTabId] = useState<1 | 2>(1);

  const isAdminAccessingEmployeeProfile = pathname.includes(EMPLOYEES_PATH);
  const hasNameField = !isEmployee && !isAdminAccessingEmployeeProfile;

  const handleClickTabEvent = useCallback(
    (tabId: typeof currentTabId) =>
      isAdminAccessingEmployeeProfile
        ? (event: MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            setCurrentTabId(tabId);
          }
        : null,
    [isAdminAccessingEmployeeProfile],
  );

  const tabConfig = useMemo(() => {
    const employeeEmptyStates = {
      scheduled: {
        title: "There's nothing here...",
        description: `Looks like you have no scheduled time offs.\nWould you like to add one?`,
        buttonLabel: 'Add new time off',
        buttonClick: handleEmptyState,
      },
      taken: {
        title: "There's nothing here...",
        description: `Looks like you haven't logged any past time offs.\nWould you like to add one to the record?`,
        buttonLabel: 'Add time off',
        buttonClick: handleEmptyState,
      },
    };

    const managerEmptyState = {
      title: "There's nothing here...",
      description: isAdminAccessingEmployeeProfile
        ? 'The employee has not logged any time off.'
        : 'The employees have not logged any time off.',
    };

    const [SCHEDULED_TAB_ID, TAKEN_TAB_ID] = [1, 2] as const;

    const ScheduledContent = () => (
      <Styled.TabContainer className="stack">
        <Styled.TimeOffTable
          $withActions={isEmployee}
          $withName={hasNameField}
          itemsToDisplay={data.scheduled}
          headers={scheduledTimeOffTable(hasNameField, isEmployee)}
          testId="time-off-scheduled"
          emptyState={
            isEmployee ? employeeEmptyStates.scheduled : managerEmptyState
          }
        />
      </Styled.TabContainer>
    );

    const TakenContent = () => (
      <Styled.TabContainer className="smallStack">
        <Styled.TimeOffTable
          $withName={hasNameField}
          itemsToDisplay={data.taken}
          headers={takenTimeOffTable(hasNameField)}
          testId="time-off-taken"
          emptyState={
            isEmployee ? employeeEmptyStates.taken : managerEmptyState
          }
        />
      </Styled.TabContainer>
    );

    return [
      {
        id: 1,
        title: `Scheduled`,
        href: '#scheduled',
        onClick: handleClickTabEvent(SCHEDULED_TAB_ID),
        Content: ScheduledContent,
      },
      {
        id: 2,
        title: `Taken`,
        href: '#taken',
        onClick: handleClickTabEvent(TAKEN_TAB_ID),
        Content: TakenContent,
      },
    ];
  }, [
    isEmployee,
    data.taken,
    data.scheduled,
    handleEmptyState,
    handleClickTabEvent,
    hasNameField,
    isAdminAccessingEmployeeProfile,
  ]);

  const currentTab =
    tabConfig.find(({ id, href }) =>
      isAdminAccessingEmployeeProfile ? id === currentTabId : href === hash,
    ) || tabConfig[0];

  return <Tabs tabsToDisplay={tabConfig} currentTab={currentTab} />;
};
