import {
  EmployeeOnboardingProgressApiPatchResponse,
  EmployeeOnboardingProgressApiResponse,
} from 'common/types';

const onboardingStages = [
  {
    id: 1,
    name: 'Employee Added',
    steps: [
      {
        id: 'j5QYFrzlPnuxKKvpC2rGk',
        name: 'Employee profile created',
      },
      {
        id: '5krXZAH1GOVwvMQ7KTaRxC',
        name: 'Generating draft contract',
      },
    ],
  },
  {
    id: 2,
    name: 'Contract Review',
    steps: [
      {
        id: '7g7c2V1EATgd9oqZ1LoGF2',
        name: 'Draft contract generated',
      },
      {
        id: '3LOIi0vTAqjs8lsh6Hyu4R',
        name: 'Waiting for manager approval',
      },
    ],
  },
  {
    id: 3,
    name: 'Contract Signing',
    steps: [
      {
        id: '3DbFP9ATbr47DH9HrIdnOh',
        name: 'Manager approved',
      },
      {
        id: '62AhnuyGhk52ZLRZoJL1Tj',
        name: "Waiting for employee's signature",
      },
      {
        id: '7zQ1xugdHiV0VgLoNCXK5m',
        name: 'Employee signed',
      },
      {
        id: '1MNfAmlfMFNYgLjOcurgbM',
        name: 'Waiting for Omnipresent counter signature',
      },
    ],
  },
  {
    id: 4,
    name: 'Finalising',
    steps: [
      {
        id: '6kobx1rL0qojJ5BAjF1E6y',
        name: 'Contract signing complete',
      },
      {
        id: '3lms5N66TcYoCSGbDHm5BT',
        name: 'Finalising onboarding process',
      },
    ],
  },
  {
    id: 5,
    name: 'Onboarded',
    steps: [
      {
        id: '71NP8diiMqRINbRLZNz4Ok',
        name: 'Employee onboarded',
      },
    ],
  },
];

export const mockOngoardingProgressResponse: EmployeeOnboardingProgressApiResponse =
  {
    onboardingStages,
    completedSteps: ['j5QYFrzlPnuxKKvpC2rGk'],
  };

export const mockPatchOnboardingProgressResponse: EmployeeOnboardingProgressApiPatchResponse =
  {
    onboardingStages,
    employeeId: '9a7c7bee-a691-4b3e-90c9-178cb5ef7306',
    completedSteps: [
      'j5QYFrzlPnuxKKvpC2rGk',
      '5krXZAH1GOVwvMQ7KTaRxC',
      '7g7c2V1EATgd9oqZ1LoGF2',
      '3LOIi0vTAqjs8lsh6Hyu4R',
      '3DbFP9ATbr47DH9HrIdnOh',
      '62AhnuyGhk52ZLRZoJL1Tj',
      '7zQ1xugdHiV0VgLoNCXK5m',
      '1MNfAmlfMFNYgLjOcurgbM',
      '6kobx1rL0qojJ5BAjF1E6y',
      '3lms5N66TcYoCSGbDHm5BT',
    ],
    onboardingFlowId: 'global-flow',
  };
