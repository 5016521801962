import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledNoExpensesContainer = styled.div`
  margin-top: ${({ theme: { space } }) => `${space[64]}`};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-top: ${({ theme: { space } }) => `${space[64]}`};
  }
`;
