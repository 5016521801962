import { Modal, TextArea } from '@omnipresentgroup/design-system';

import { usePayrollEmployeeJustifications } from '../../PayReportPreviewPage/usePayrollEmployeeJustifications';

export const LSPBillJustificationModal = ({
  onClose,
  employeeData,
  readOnly,
}: {
  onClose?: () => void;
  employeeData: { gtnId: string; employeeId?: string; name: string };
  readOnly: boolean;
}) => {
  const { note, handleUpdateNote, handleChangeNote, isLoadingJustifications } =
    usePayrollEmployeeJustifications({
      grossToNetId: employeeData.gtnId,
      employeeId: employeeData.employeeId,
      readOnly: readOnly,
    });

  return (
    <Modal open span={6}>
      <Modal.Header
        onClose={onClose}
        title={employeeData.name}
        description="Justifications"
      />
      <Modal.Body>
        <TextArea
          id="payrollJustificationText"
          readOnly={readOnly || isLoadingJustifications}
          minRows={10}
          value={note}
          onChange={handleChangeNote}
          onBlur={handleUpdateNote}
        />
      </Modal.Body>
    </Modal>
  );
};
