import styled from 'styled-components';

export const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-content: stretch space-between;
  height: 'max';
`;

export const StyledHeader = styled.p`
  margin: 0;
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.medium}`};
  padding-top: ${({ theme: { space } }) => `${space[16]}`};
`;

export const StyledTooltipDescription = styled.p`
  background: none;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
`;

export const StyledButtonContainer = styled.div`
  padding-top: ${({ theme: { space } }) => space[8]};
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme: { space } }) => `${space[16]}`};
  margin-right: ${({ theme: { space } }) => `${space[16]}`};
  margin-bottom: ${({ theme: { space } }) => `${space[16]}`};
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${({ theme: { space } }) => `${space[16]}`};
  margin-right: ${({ theme: { space } }) => `${space[16]}`};
  margin-left: ${({ theme: { space } }) => `${space[16]}`};
`;
