import { useCallback, useEffect, useState } from 'react';

import { Toggle } from 'common/components/atoms/Toggle/Toggle';
import { getTenantIdSelector } from 'common/store/selectors/companies.selectors';
import { User } from 'common/types';
import { useSelector } from 'react-redux';
import { useUpdateUserScopeSuperManagerMutation } from 'utils/queries/managerPermissions/useUpdateUserScopeSuperManagerMutation/useUpdateUserScopeSuperManagerMutation';
import { hasSuperManagerScope } from 'utils/user';

import { Inline, Typography } from '@omnipresentgroup/design-system';

export const SuperManagerToggle = ({
  manager,
  isDisabled = false,
}: {
  manager: User;
  isDisabled?: boolean;
}) => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(
    hasSuperManagerScope(manager),
  );

  const companyId = useSelector(getTenantIdSelector);
  const updateUserScopeSuperManagerMutation =
    useUpdateUserScopeSuperManagerMutation({
      companyId,
      userId: manager.userId,
    });

  useEffect(() => {
    setIsSuperAdmin(hasSuperManagerScope(manager));
  }, [manager]);

  const onToggle = useCallback(() => {
    updateUserScopeSuperManagerMutation.mutate(!isSuperAdmin);
    setIsSuperAdmin(!isSuperAdmin);
  }, [isSuperAdmin, updateUserScopeSuperManagerMutation]);

  return (
    <Inline>
      <Typography
        as="label"
        size="16"
        weight="medium"
        htmlFor="superAdminCheckbox"
      >
        Super Admin
      </Typography>
      <Toggle
        name="superAdminCheckbox"
        toggleOn={isSuperAdmin}
        onToggle={onToggle}
        isDisabled={isDisabled && isSuperAdmin}
      />
    </Inline>
  );
};
