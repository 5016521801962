/* eslint-disable no-magic-numbers */
import { EmployeeForDetailView } from 'common/types/employee';
import { Field } from 'common/types/form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  BO_CONTRACTUAL_COMPENSATION_PATH,
  CONTRACTUAL_COMPENSATION_PATH,
} from 'paths';
import { generateContractFields } from 'utils/employees';

import { Section } from './EmployeeInfoTab/EmployeeInfoTab';

const useManagerSections = (employee: EmployeeForDetailView) => {
  const {
    personalDetails,
    employmentDetails,
    bankingDetails,
    socialInsuranceNumber,
    roleDetails,
  } = employee;
  const contractDetails = generateContractFields(employee);

  const { enableNewCompensation } = useFlags();

  return [
    {
      name: 'personal-details',
      title: 'Personal details',
      details: personalDetails,
    },
    {
      name: 'role-details',
      title: 'Role details',
      details: enableNewCompensation
        ? [
            ...roleDetails,
            {
              name: 'contractualCompensation',
              label: 'Contractual compensation',
              value: `${
                process.env.VITE_MANAGER_APP
              }${CONTRACTUAL_COMPENSATION_PATH(employee.id)}`,
              displayValue: 'View',
              type: 'link',
              showFor: 'manager',
            },
          ]
        : roleDetails,
    },
    {
      name: 'employment-details',
      title: 'Employment details',
      details: employmentDetails,
    },
    {
      name: 'bank-details',
      title: 'Bank Details',
      details: bankingDetails || [],
    },
    {
      name: 'social-insurance-number',
      title: '',
      details: socialInsuranceNumber || [],
    },
    contractDetails?.length > 0
      ? {
          name: 'contract-details',
          title: 'Contract details',
          details: contractDetails,
        }
      : null,
  ].filter(Boolean) as Section[];
};

const useAdminSections = (employee: EmployeeForDetailView) => {
  const { administrativeInformation, payrollDetails, localOnboardingDetails } =
    employee;
  const managerSections = useManagerSections(employee);

  const { enableNewCompensationBo } = useFlags();

  return [
    {
      name: 'personal-details',
      title: 'Personal details',
      details: employee.personalDetails,
    },
    {
      name: 'role-details',
      title: 'Role details',
      details: enableNewCompensationBo
        ? [
            ...employee.roleDetails,
            {
              name: 'contractualCompensation',
              label: 'Contractual compensation',
              value: `${
                process.env.VITE_BO_APP
              }${BO_CONTRACTUAL_COMPENSATION_PATH(employee.id)}`,
              displayValue: 'View',
              type: 'link',
              showFor: 'admin',
            },
          ]
        : employee.roleDetails,
    },
    ...managerSections.filter(
      (section) =>
        section.name !== 'role-details' && section.name !== 'personal-details',
    ),
    localOnboardingDetails?.length > 0
      ? {
          name: 'local-onboarding-details',
          title: 'Local onboarding details',
          details: localOnboardingDetails,
        }
      : null,
    {
      name: 'payroll-details',
      title: 'Payroll details (internal)',
      details: payrollDetails,
    },
    {
      name: 'admin-details',
      title: 'Administrative information (internal)',
      details: administrativeInformation
        ? [
            ...administrativeInformation,
            {
              name: 'dataExport',
              label: 'Data export',
              value: '',
              type: 'dataExport',
              showFor: 'admin',
            },
          ]
        : [],
    },
  ].filter(Boolean) as Section[];
};

const useLspSections = (employee: EmployeeForDetailView) => {
  const { localOnboardingDetails } = employee;
  const managerSections = useManagerSections(employee);
  return [
    ...managerSections,
    localOnboardingDetails?.length > 0
      ? {
          name: 'local-onboarding-details',
          title: 'Local onboarding details',
          details: localOnboardingDetails,
        }
      : null,
    {
      name: 'lsp-exports',
      title: 'Exports',
      details: [
        {
          name: 'dataExport',
          label: 'Data export',
          value: '',
          type: 'dataExport',
        },
      ],
    },
  ].filter(Boolean) as Section[];
};

type FieldRoleDetails = Field & {
  employeeDisplayValue?: string;
  managerEditable?: boolean;
};

const setVisibleSalaryForEmployee = (
  roleDetails:
    | FieldRoleDetails[]
    | Array<{
        name: string;
        employeeDisplayValue: string;
        label: string;
        labelBold: boolean;
        managerEditable: boolean;
        required: boolean;
        type: string;
        value: {
          amount: number;
          baseCurrency: string;
        };
      }> = [],
) => {
  return roleDetails.map((detail) => {
    if (detail.name === 'annualBaseSalary') {
      return {
        displayValue: detail.employeeDisplayValue,
        label: detail.label,
        managerEditable: detail.managerEditable,
        name: detail.name,
        required: detail.required,
        type: detail.type,
        value: detail.value,
      };
    }
    return detail;
  });
};

const useEmployeeSections = (employee: EmployeeForDetailView) => {
  const {
    roleDetails,
    employmentDetails,
    personalDetails,
    localOnboardingDetails,
    bankingDetails,
    socialInsuranceNumber,
  } = employee;

  const contractDetails = generateContractFields(employee);

  return [
    {
      name: 'personal-details',
      title: 'Personal details',
      details: personalDetails || [],
    },
    {
      name: 'role-details',
      title: 'Role details',
      details: setVisibleSalaryForEmployee(roleDetails) || [],
    },
    {
      name: 'employment-details',
      title: 'Employment details',
      details: employmentDetails || [],
    },
    {
      name: 'bank-details',
      title: 'Bank Details',
      details: bankingDetails || [],
    },
    {
      name: 'social-insurance-number',
      title: '',
      details: socialInsuranceNumber || [],
    },
    contractDetails?.length > 0
      ? {
          name: 'contract-details',
          title: 'Contract details',
          details: contractDetails,
        }
      : null,
    localOnboardingDetails?.length > 0
      ? {
          name: 'local-onboarding-details',
          title: 'Local onboarding details',
          details: localOnboardingDetails,
        }
      : null,
  ].filter(Boolean) as Section[];
};

type RoleSections = {
  [key: string]: Section[];
};

export const useEmployeeDetailsSectionByRole = (
  employee: EmployeeForDetailView,
  role?: string,
) => {
  const mapRoleSections: RoleSections = {
    employee: useEmployeeSections(employee),
    admin: useAdminSections(employee),
    lsp: useLspSections(employee),
    manager: useManagerSections(employee),
    'viewer:omnipresent-sensitive-data': [],
    'viewer:clients-sensitive-data': [],
    payroll: [],
    'international-expansion': [],
    finance: [],
  };

  return !!role ? mapRoleSections[role] : [];
};
