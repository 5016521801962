import { useHistory } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { ReactComponent as ArrowRight } from 'assets/icon/ArrowRight.svg';
import FloatingActionButton from 'common/components/atoms/FloatingActionButton/FloatingActionButton';
import Loading from 'common/components/atoms/Loading/Loading';
import { StyledBoldCopy } from 'common/components/atoms/Text';
import Card from 'common/components/molecules/Card/Card';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import MobileCardListHeader from 'common/components/molecules/MobileCardListHeader/MobileCardListHeader';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import TableWithTitle from 'common/components/organisms/TableWithTitle/TableWithTitle';
import { LSP_LIST_PAGE } from 'paths';
import { useLspsQuery } from 'utils/queries';

import { Viewport } from '@omnipresentgroup/design-system';

import { StyledLspListMobile } from './LspListPage.styles';
import { cardHeaders, tableHeaders } from './LspListPageTableHeaders';

export const LspListPage = () => {
  const history = useHistory();
  const lspsQuery = useLspsQuery();

  const onLspClick = (lspId: string) =>
    history.push(`${LSP_LIST_PAGE}/${lspId}`);

  return (
    <PageContent data-testid="lsp-list-page" className="bigStack">
      <PageHeader title="Local Service Providers" />
      {lspsQuery.isLoading && <Loading />}

      {lspsQuery.isError && (
        <ErrorBanner errorMessage={lspsQuery.error.message} />
      )}

      {lspsQuery.isSuccess && (
        <>
          <Viewport devices={['laptop', 'desktop', 'highRes']}>
            <TableWithTitle
              testId="lsp-list"
              itemsToDisplay={lspsQuery.data}
              onClick={onLspClick}
              headers={tableHeaders}
            />
          </Viewport>
          <Viewport devices={['phone', 'tablet']}>
            <StyledLspListMobile className="stack">
              <MobileCardListHeader total={lspsQuery.data.length} />
              {lspsQuery.data.map((lsp) => (
                <Card
                  key={lsp.id}
                  fieldsToShow={cardHeaders}
                  onClick={onLspClick}
                  item={lsp}
                  icon={<ArrowRight />}
                  title={<StyledBoldCopy>{lsp.name}</StyledBoldCopy>}
                />
              ))}
              <FloatingActionButton type="add" onClick={null} />
            </StyledLspListMobile>
          </Viewport>
        </>
      )}
    </PageContent>
  );
};
