import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { subject } from '@casl/ability';
import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import { useAppAbility } from 'app/store/ability';
import { getLoadingSelector } from 'app/store/app.selectors';
import FloatingActionButton from 'common/components/atoms/FloatingActionButton/FloatingActionButton';
import Loading from 'common/components/atoms/Loading/Loading';
import DocumentsTab from 'common/components/organisms/DocumentsTab/DocumentsTab';
import { TabProp } from 'common/components/organisms/Tabs/Tabs.types';
import { THIRTY_MINUTES } from 'common/constants';
import {
  clearDocumentsAction,
  deleteDocumentAction,
  getDocumentsAction,
} from 'common/store/actions/documents.actions';
import {
  getCompanyManagersSelector,
  getSelectedCompanySelector,
} from 'common/store/selectors/companies.selectors';
import { getDocumentsSelector } from 'common/store/selectors/document.selectors';
import { HireTypeEnum } from 'common/types/hire';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ResignationRequestsTable } from 'omniplatform/manager/pages/ManagerEmployeePage/Resignations/ResignationRequestsTable/ResignationRequestsTable';
import { TerminationRequestsTable } from 'omniplatform/manager/pages/ManagerEmployeePage/Terminations/TerminationRequestsTable/TerminationRequestsTable';
import {
  ADD_HIRE_PATH,
  COMPANIES_LIST_PAGE,
  getEmployeeBulkUploadPath,
} from 'paths';
import { useDispatch, useSelector } from 'react-redux';
import { getOmnipresentTenantId } from 'utils/companies';
import {
  isEmployeeBulkUploadEnabled,
  isUpsellPendingEmployeeFlowEnabled,
} from 'utils/featureFlags';
import { useSelectUserRoles } from 'utils/hooks';
import { useEmployeesForAdminUsers } from 'utils/hooks/employees/useEmployeesForAdminUsers';
import { useCompanySeatsForAdminUsers } from 'utils/hooks/seats/useCompanySeatsForAdminUsers';
import { useResignationsByCompanyQuery } from 'utils/queries/employees/resignations/useResignationsByCompanyQuery';
import { useTerminationRequestsQuery } from 'utils/queries/employees/terminationRequests/useTerminationRequestsQuery/useTerminationRequestsQuery';

import { Viewport } from '@omnipresentgroup/design-system';

import {
  clearSelectedCompanyAction,
  getCompanyAction,
  getCompanyManagersAction,
} from '../../store/actions/companies.actions';
import { BulkCompensationOptionsPage } from '../CompensationBulkUploadPage/BulkCompensationOptionsPage/BulkCompensationOptionsPage';
import {
  StyledCompanyDetailHeader,
  StyledCompanyDetailPage,
  StyledTabsContainer,
} from './CompanyDetailPage.styles';
import { BenefitsTab } from './Tabs/BenefitsTab';
import CompanyInfoTab from './Tabs/CompanyInfoTab';
import { EmployeesTab } from './Tabs/EmployeesTab/EmployeesTab';
import { GSInviteModalEventSource } from './Tabs/GlobalSetup/InviteModal/InviteModal';
import { GlobalSetupTab } from './Tabs/GlobalSetupTab';
import ManagersTab from './Tabs/ManagersTab';
import { PermissionsTab } from './Tabs/PermissionsTab';
import { SeatsTab } from './Tabs/SeatsTab';

type NewButtonConfig = {
  text: string;
  type: string;
  onClick: () => void;
  disabled: boolean;
  showImportEmployeesButton?: boolean;
  importEmployeesButtonClicked?: () => void;
};

const PENDING_EMPLOYEES = '#pending-employees';
const EMPLOYEES = '#employees';

export const CompanyDetailPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoadingSelector);
  const company = useSelector(getSelectedCompanySelector);
  const companyManagers = useSelector(getCompanyManagersSelector);
  const companyDocuments = useSelector(getDocumentsSelector);
  const { companyId } = useParams<{ companyId: string }>();
  const location = useLocation();

  const [currentHash, setCurrentHash] = useState(location.hash || null);

  const [esInviteModal, setESInviteModal] = useState<GSInviteModalEventSource>(
    GSInviteModalEventSource.None,
  );

  const {
    isCSAdmin,
    isAdmin,
    isOPAdmin: isOmnipresentAdmin,
    isPayrollAdmin,
  } = useSelectUserRoles();
  const ability = useAppAbility();

  const {
    showManagerPermissionsUi,
    showClientBenefits,
    enableVirtualEmployerFlow,
  } = useFlags();

  const isOmnipresentCompany = getOmnipresentTenantId() === companyId;
  const isAdminType = isCSAdmin || isPayrollAdmin || isAdmin;

  const isPermissionsTabEnabled =
    (isOmnipresentCompany && isOmnipresentAdmin) ||
    (!(isOmnipresentCompany || isOmnipresentAdmin) && isAdminType);

  const compensationTabEnabled = isCSAdmin || isOmnipresentAdmin;

  const canAddDocument = ability.can(
    'documents:create',
    subject('Documents', { ownerId: companyId }),
  );

  const canDeleteDocument = (id: string) =>
    ability.can('documents:delete', subject('Documents', { id }));
  const canDownloadDocument = (id: string) =>
    ability.can('documents:download', subject('Documents', { id }));

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyAction(companyId));
    }
    return () => {
      dispatch(clearSelectedCompanyAction());
    };
  }, [companyId, dispatch]);

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyManagersAction(companyId));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    if (companyId) {
      dispatch(getDocumentsAction({ companyId }));
    }
    return () => {
      dispatch(clearDocumentsAction());
    };
  }, [companyId, dispatch]);

  useEffect(() => {
    setCurrentHash(location.hash);
  }, [location]);

  let newButtonConfig: NewButtonConfig = {
    text: 'Edit details',
    type: 'edit',
    onClick: () => appHistory.push(`${COMPANIES_LIST_PAGE}/${companyId}/edit`),
    disabled: false,
  };

  if (currentHash === '#documents') {
    newButtonConfig = {
      text: 'Add document',
      type: 'add',
      onClick: () =>
        appHistory.push(`${COMPANIES_LIST_PAGE}/${companyId}/add-document`),
      disabled: !canAddDocument,
    };
  }
  if (currentHash === EMPLOYEES || currentHash === PENDING_EMPLOYEES) {
    newButtonConfig = {
      text: 'Add employee',
      type: 'add',
      onClick: () => {
        appHistory.push(
          `${ADD_HIRE_PATH(companyId)}?type=${HireTypeEnum.employee}`,
        );
      },
      disabled: false,

      showImportEmployeesButton: isEmployeeBulkUploadEnabled() && isCSAdmin,
      importEmployeesButtonClicked: () => {
        appHistory.push(getEmployeeBulkUploadPath(companyId));
      },
    };
  }

  if (currentHash === '#managers') {
    newButtonConfig = {
      text: 'Add manager',
      type: 'add',
      onClick: () =>
        appHistory.push(`${COMPANIES_LIST_PAGE}/${companyId}/managers/add`),
      disabled: false,
    };
  }

  if (currentHash === '#termination-requests') {
    newButtonConfig = {} as NewButtonConfig;
  }

  if (currentHash === '#resignation-requests') {
    newButtonConfig = {} as NewButtonConfig;
  }

  if (currentHash === '#permissions') {
    newButtonConfig = {} as NewButtonConfig;
  }

  if (currentHash === '#benefits') {
    newButtonConfig = {} as NewButtonConfig;
  }

  if (currentHash === '#virtual-employer') {
    newButtonConfig = {
      text: 'Send Invite',
      type: 'add',
      onClick: () => {
        setESInviteModal(GSInviteModalEventSource.Header);
      },
      disabled: false,
    };
  }

  const {
    data: companySeats = [],
    isFetching: isFetchingSeats,
    refetch: refetchSeats,
  } = useCompanySeatsForAdminUsers(companyId);

  const {
    data: companyEmployees = [],
    isFetching: isFetchingEmployees,
    refetch: refetchEmployees,
  } = useEmployeesForAdminUsers(companyId, { staleTime: THIRTY_MINUTES });

  const companyDetailsSections = [
    {
      name: 'company-details',
      title: 'Company details',
      details: company?.companyDetails,
    },
    {
      name: 'admin-details',
      title: 'Administrative information (internal)',
      details: company?.administrativeInformation,
    },
  ];

  const DetailsContent = () => (
    <CompanyInfoTab companyDetailsSections={companyDetailsSections} />
  );

  const EmployeesContent = () => (
    <EmployeesTab
      employeesToDisplay={companyEmployees}
      companyId={companyId}
      reloadEmployees={() => {
        refetchEmployees();
      }}
    />
  );

  const PendingEmployeesContent = () => (
    <SeatsTab
      seatsToDisplay={companySeats}
      companyId={companyId}
      reloadSeats={() => {
        refetchSeats();
      }}
    />
  );

  const terminationRequestsQuery = useTerminationRequestsQuery(companyId, {
    enabled: Boolean(companyId) && isCSAdmin,
  });

  const { data: terminations = [] } = terminationRequestsQuery;

  const TerminationRequestsContent = () => (
    <TerminationRequestsTable
      employees={companyEmployees}
      terminationRequestsData={terminations}
    />
  );

  const enabledResignationsQuery = Boolean(companyId) && !isOmnipresentCompany;

  const resignationRequestsQuery = useResignationsByCompanyQuery(companyId, {
    enabled: enabledResignationsQuery,
  });
  const { data: resignations = [] } = resignationRequestsQuery;

  const ResignationRequestsContent = () => (
    <ResignationRequestsTable
      employees={companyEmployees}
      resignations={resignations}
    />
  );

  const DocumentsContent = () => (
    <DocumentsTab
      documents={companyDocuments}
      addDocumentPath={`${COMPANIES_LIST_PAGE}/${companyId}/add-document`}
      onDocumentDelete={(documentId: any) =>
        dispatch(deleteDocumentAction({ documentId, companyId }))
      }
      canAddDocument={canAddDocument}
      canDeleteDocument={canDeleteDocument}
      canDownloadDocument={canDownloadDocument}
    />
  );

  const ManagersContent = () => (
    <ManagersTab
      companyId={companyId}
      managersToDisplay={companyManagers}
      companyManagers={company?.managers}
      reloadManagers={() => dispatch(getCompanyManagersAction(companyId))}
    />
  );

  const PermissionsContent = () => <PermissionsTab />;
  const CompensationContent = () => <BulkCompensationOptionsPage />;
  const GlobalSetupContent = () => (
    <GlobalSetupTab
      setInviteEventSource={setESInviteModal}
      inviteEventSource={esInviteModal}
    />
  );

  const BenefitsContent = () => <BenefitsTab />;

  const isLoadingInformation = isFetchingEmployees || isFetchingSeats;

  const tabConfig: TabProp[] = [
    {
      id: 1,
      title: 'Details',
      href: '#details',
      Content: isLoadingInformation ? Loading : DetailsContent,
      isLoading: isLoadingInformation,
    },
    {
      id: 2,
      title: 'Employees',
      href: EMPLOYEES,
      isDisabled:
        isUpsellPendingEmployeeFlowEnabled() && !companyEmployees?.length,
      Content: isLoadingInformation ? Loading : EmployeesContent,
      isLoading: isLoadingInformation,
    },
    {
      id: 3,
      title: 'Pending employees',
      href: PENDING_EMPLOYEES,
      isDisabled: Boolean(companyEmployees?.length && !companySeats?.length),
      Content: isLoadingInformation ? Loading : PendingEmployeesContent,
      isLoading: isLoadingInformation,
    },
    ...(isCSAdmin && terminations.length
      ? [
          {
            id: 4,
            title: 'Termination requests',
            href: '#termination-requests',
            Content: TerminationRequestsContent,
            isLoading: isLoadingInformation,
          },
        ]
      : []),
    ...(resignations.length
      ? [
          {
            id: 5,
            title: 'Resignation requests',
            href: '#resignation-requests',
            Content: ResignationRequestsContent,
          },
        ]
      : []),
    {
      id: 6,
      title: 'Documents',
      href: '#documents',
      Content: DocumentsContent,
      isLoading: isLoadingInformation,
    },
    ...(showClientBenefits
      ? [
          {
            id: 7,
            title: 'Benefits',
            href: '#benefits',
            Content: BenefitsContent,
            isLoading: isLoadingInformation,
          },
        ]
      : []),
    {
      id: 8,
      title: 'Managers',
      href: '#managers',
      Content: ManagersContent,
      isLoading: isLoadingInformation,
    },
    ...(showManagerPermissionsUi && isPermissionsTabEnabled
      ? [
          {
            id: 10,
            title: 'Permissions',
            href: '#permissions',
            Content: PermissionsContent,
            isLoading: isLoadingInformation,
          },
        ]
      : []),
    ...(compensationTabEnabled
      ? [
          {
            id: 11,
            title: 'Compensation',
            href: '#bulk-compensation',
            Content: CompensationContent,
            isLoading: isLoadingInformation,
          },
        ]
      : []),
    ...(isAdmin && enableVirtualEmployerFlow
      ? [
          {
            id: 12,
            title: 'Virtual employer',
            href: '#virtual-employer',
            Content: GlobalSetupContent,
            isLoading: isLoadingInformation,
          },
        ]
      : []),
  ];

  return (
    <PageContent>
      {loading || !company?.companyName ? (
        <Loading />
      ) : (
        <StyledCompanyDetailPage
          data-testid="company-detail-page"
          className="bigStack"
        >
          <StyledCompanyDetailHeader
            backLink={{
              url: COMPANIES_LIST_PAGE,
              label: 'Back to all clients',
            }}
            title={company.companyName}
            copy="Client"
            ctaText={newButtonConfig.text}
            onCTAClick={newButtonConfig.onClick}
            hasDisabledCTA={newButtonConfig.disabled}
            showImportEmployeesButton={
              newButtonConfig.showImportEmployeesButton
            }
            importEmployeesButtonClicked={
              newButtonConfig.importEmployeesButtonClicked
            }
            testId="company-detail-headline"
          />
          <StyledTabsContainer
            tabsToDisplay={tabConfig}
            currentTab={tabConfig.find((tab) => tab.href === currentHash)}
          />
          <Viewport devices={['phone', 'tablet']}>
            <FloatingActionButton
              onClick={newButtonConfig.onClick}
              type={newButtonConfig.type}
            />
          </Viewport>
        </StyledCompanyDetailPage>
      )}
    </PageContent>
  );
};
