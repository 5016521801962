import { AnnualBaseSalary } from 'common/types/salaryHistory';
import { DateTime } from 'luxon';

type SalaryHistory = {
  amount: number;
  currency: string;
  effectiveDate?: string;
  requestedAt: string;
};

interface LastEffectiveSalaryParams {
  annualBaseSalary: AnnualBaseSalary;
  salaryHistory?: SalaryHistory[];
}

const findLastEffectiveSalary = (salary: SalaryHistory) => {
  const { effectiveDate } = salary;
  if (effectiveDate) {
    return DateTime.fromISO(effectiveDate) <= DateTime.now();
  }
  return true;
};

export const getLastEffectiveSalary = ({
  annualBaseSalary,
  salaryHistory,
}: LastEffectiveSalaryParams): AnnualBaseSalary => {
  let effectiveBaseSalary = annualBaseSalary;
  if (salaryHistory) {
    const { amount, currency } = salaryHistory.find(
      findLastEffectiveSalary,
    ) as AnnualBaseSalary;
    effectiveBaseSalary = { amount, currency };
  }
  return effectiveBaseSalary;
};
