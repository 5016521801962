import { Reducer } from 'redux';

import {
  asyncActionKeys,
  CLEAR_EMPLOYEE_CREATED,
  CLEAR_SELECTED_EMPLOYEE,
  UPDATE_COMPENSATION,
  UPDATE_DEPARTMENT,
  UPDATE_EMPLOYEE_PATCH,
} from '../actions/redux.constants';

const initialState = {
  employees: [],
  selectedEmployee: {
    administrativeInformation: [],
  },
  employeeCreated: false,
  dataExportLink: '',
};

export const employeesReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case asyncActionKeys.GET_MANAGERS_EMPLOYEES_END:
      return {
        ...state,
        employees: action.payload,
      };
    case asyncActionKeys.GET_EMPLOYEE_END:
    case UPDATE_COMPENSATION:
    case UPDATE_EMPLOYEE_PATCH:
      return {
        ...state,
        selectedEmployee: action.payload,
      };
    case UPDATE_DEPARTMENT:
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          administrativeInformation: [
            state.selectedEmployee.administrativeInformation[0],
            {
              name: action.payload.name,
              value: action.payload.value,
              label: 'Department',
              type: 'dropdown',
            },
            ...state.selectedEmployee.administrativeInformation.slice(2),
          ],
        },
      };
    case CLEAR_SELECTED_EMPLOYEE:
      return {
        ...state,
        selectedEmployee: {},
      };
    case asyncActionKeys.CREATE_EMPLOYEE_END:
      return {
        ...state,
        employeeCreated: true,
      };
    case CLEAR_EMPLOYEE_CREATED:
      return {
        ...state,
        employeeCreated: false,
      };
    case asyncActionKeys.GET_DATA_EXPORT_END:
      return {
        ...state,
        dataExportLink: action.payload,
      };
    default:
      return state;
  }
};
