import { getUserSelector } from 'app/store/app.selectors';
import { getLSPs } from 'common/api/localServiceProviders.api';
import { TEN_MINUTES } from 'common/constants';
import { transformLSPApiResponseToLSP } from 'common/store/transformers/lsps.transformer';
import { LSP, LSPApiResponse, LSPTypes } from 'common/types';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { sortObjectsByKey } from 'utils/sortObjectsByKey';
import { isAdmin } from 'utils/user';

import { getDataFromResponse } from '../../../react-query-utils';
import { lspsKeys } from '../keys';

type LSPQueryParams = { type?: LSPTypes };
export const useLspsQuery = ({ type }: LSPQueryParams = {}) => {
  const user = useSelector(getUserSelector);
  const hasPermissionToSeeLSPs = isAdmin(user);

  return useQuery<LSPApiResponse[], Error, LSP[]>(
    lspsKeys.default,
    () => getLSPs().then(getDataFromResponse),
    {
      enabled: hasPermissionToSeeLSPs,
      select: (data): LSP[] => {
        let transformedData = sortObjectsByKey({ key: 'name', list: data }).map(
          transformLSPApiResponseToLSP,
        );

        if (type) {
          transformedData = transformedData.filter(
            (item) => item.type === type,
          );
        }

        return transformedData;
      },
      staleTime: TEN_MINUTES,
    },
  );
};
