import { ReactElement } from 'react';

import { Check, Clock } from 'react-feather';

import { Box, Split } from '@omnipresentgroup/design-system';

export type Props = {
  label: string;
  stepId: string;
  isCompleted: boolean;
};

export const OnboardingProgressDrawerStepIcon = ({
  isCompleted,
  stepId,
  label,
}: Props): ReactElement => {
  const isCompletedColor = isCompleted ? 'default' : 'helper';
  return (
    <>
      <Split gap="0" fraction="auto-start">
        <Box color={isCompletedColor}>
          {isCompleted ? (
            <Check data-testid={`step-${stepId}-icon`} size={16} />
          ) : (
            <Clock data-testid={`step-${stepId}-icon`} size={16} />
          )}
        </Box>
        <Box pr="16" ml="8" color={isCompletedColor}>
          {label}
        </Box>
      </Split>
    </>
  );
};
