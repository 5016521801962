import { useParams } from 'react-router-dom';

import { updateEmployeeOnboardingProgressRequest } from 'common/api/employees.api';
import { useMutation, useQueryClient } from 'react-query';

import { onboardingProgressKeys } from '../keys';

type StepModification = {
  id: string;
  status: string;
};
export type StepModifications = {
  StepModifications: StepModification[];
};

export const useOnboardingProgressMutation = () => {
  const queryClient = useQueryClient();
  const { employeeId } = useParams<{ employeeId: string }>();
  const keyToInvalidate = onboardingProgressKeys.default;
  return useMutation(
    ({ updatedSteps }: { updatedSteps: StepModifications }) =>
      updateEmployeeOnboardingProgressRequest(employeeId, updatedSteps),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keyToInvalidate);
      },
    },
  );
};
