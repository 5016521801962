import { reduceInputField } from 'utils/transformer-utils/input.transformer';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';

export const transformDocument = ({
  description,
  title,
  linkToDocument,
  id,
  createdAt,
}) => ({
  description,
  title,
  linkToDocument,
  id,
  createdAt,
  formattedDate: formatISODate(createdAt, DateFormats.Date, true, '-'),
});

const mergeContractMetadata = ({ version, status, type, ...rest }) => ({
  ...(type === 'contract' && {
    metadata: {
      ...(version && { version }),
      ...(status && { status }),
    },
  }),
  ...(type && { type }),
  ...rest,
});

export const transformDocumentToApi = (documentInfo, userId, tenantId) => {
  const transformedFields = documentInfo[0].fields.reduce((result, field) => {
    return reduceInputField(result, field, false);
  }, {});
  const nestedTransformedFields = mergeContractMetadata(transformedFields);
  return {
    type: 'document',
    ...nestedTransformedFields,
    tenantId,
    userId,
  };
};
