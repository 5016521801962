import { StyledKeyFactsEmptyContainer } from 'common/pages/automatedUpsellSection/AutomatedUpsellSection.styles';
import styled from 'styled-components';

import { Box, Button, Split, Stack } from '@omnipresentgroup/design-system';
import tokens from '@omnipresentgroup/design-tokens';

export const StyledManagerBox = styled(Stack)`
  border-right: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  height: 70vh;

  @media (max-width: ${tokens.breakpoint.laptop}) {
    border-right: none;
    border-bottom: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  }
`;

export const StyledEmployeesBox = styled(Stack)`
  height: 75vh;
`;

export const StyledDirectReportsEmptyBox = styled(
  StyledKeyFactsEmptyContainer,
)``;

export const CheckboxWrapper = styled(Stack)`
  flex-grow: 1;
  flex-direction: row;
  align-self: start;
  width: 100%;
  justify-content: space-between;
`;

export const ResponsiveSplit = styled(Split)`
  @media (max-width: ${tokens.breakpoint.laptop}) {
    grid-template-columns: repeat(1, 1fr);
    max-width: 60vw;
  }

  @media (max-width: ${tokens.breakpoint.tablet}) {
    min-width: 90vw;
  }
`;

export const ResponsiveBox = styled(Box)`
  @media (max-width: ${tokens.breakpoint.laptop}) {
    max-width: 60vw;
  }

  @media (max-width: ${tokens.breakpoint.tablet}) {
    min-width: 90vw;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: ${({ theme: { space } }) => space[16]};
  text-transform: uppercase;
`;

export const StyledManagersList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;

  :hover {
    overflow-y: scroll;
    margin-right: ${({ theme: { space } }) => `-${space[16]}`};
  }
`;

export const StyledEmployeesList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 45vh;
`;
