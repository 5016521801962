import { Suspense } from 'react';

import Loading from 'common/components/atoms/Loading/Loading';
import { lazyWithRetry } from 'utils/lazyWithRetry';

import { GlobalSetupProps } from './GlobalSetup/GlobalSetup';

const GlobalSetup = lazyWithRetry(() => import('./GlobalSetup/GlobalSetup'));

export const GlobalSetupTab = (props: GlobalSetupProps) => {
  return (
    <Suspense fallback={<Loading />}>
      <GlobalSetup {...props} />
    </Suspense>
  );
};
