import { appReducer } from 'app/store/reducers/app.reducer';
import { companiesReducer } from 'common/store/reducers/companies.reducer';
import { documentsReducer } from 'common/store/reducers/documents.reducer';
import { documentStorageReducer } from 'common/store/reducers/documentStorage.reducer';
import { employeesReducer } from 'common/store/reducers/employee.reducer';
import { seatsReducer } from 'common/store/reducers/seats.reducer';
import { combineReducers } from 'redux';

import { expensesReducer } from '../../../employee/store/reducers/expenses.reducer';
import { managersEmployeeExpensesReducer } from '../../../manager/store/reducers/managersEmployeeExpenses.reducer';

const adminRootReducer = combineReducers({
  appReducer,
  seatsReducer,
  companiesReducer,
  employeesReducer,
  documentsReducer,
  documentStorageReducer,
  expensesReducer,
  managersEmployeeExpensesReducer,
});

export default adminRootReducer;
