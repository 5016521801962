import { getCompanyAvailableProductsRequest } from 'common/api/companies.api';
import { CompanyAvailableProducts } from 'common/types';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { companiesKeys } from '../keys';

export const useCompanyAvailableProductsQuery = <T = CompanyAvailableProducts>(
  companyId?: string,
  options?: UseQueryOptions<T, Error, T, QueryKey>,
) => {
  return useQuery<T, Error, T, QueryKey>(
    companiesKeys.availableProducts(companyId),
    () =>
      getCompanyAvailableProductsRequest(companyId as string).then(
        getDataFromResponse,
      ) as Promise<T>,
    {
      // enabled below options, otherwise it will be overidden by the options
      ...options,
      enabled: !!companyId && options?.enabled,
    },
  );
};
