import { createQueryKeys } from 'utils/react-query-utils';

export const lspsKeys = createQueryKeys(
  'local-service-providers',
  (createKey) => ({
    single: (lspId: string) => createKey(lspId),
    employees: (lspId: string) => createKey(lspId, 'employees'),
    lspUsers: (lspId: string) => createKey(lspId, 'lspUsers'),
    lspUser: (lspId: string) => createKey(lspId, 'lspUser'),
    onboardingProgress: (lspId: string) =>
      createKey(lspId, 'onboardingProgress'),
    approvedExpenses: (lspId: string, month: string, year: string) =>
      createKey(lspId, 'approvedExpenses', month, year),
    approvedExportExpensesLink: (lspId: string, month: string, year: string) =>
      createKey(lspId, 'approvedExportExpensesLink', month, year),
  }),
);
