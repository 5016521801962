import { mockEmployeesLspView } from '__mocks__/employees.mock';
import { mockLSPsResponse } from '__mocks__/lsps.mock';
import { HTTPStatusCodesEnum } from 'common/constants';
import { LSPApiResponse, SimplifiedEmployee, User } from 'common/types';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';
import mockLSPEmployees from 'omniplatform/admin/__mocks__/lspEmployees.json';
import { mockLspUser } from 'omniplatform/lsp/__mocks__/lspUser.mock';

import { LOCAL_SERVICE_PROVIDERS_BASE_API_ENDPOINT } from './localServiceProviders.api';

export const lspsHandlers = [
  rest.get<LSPApiResponse[]>(
    composeEndpoint(LOCAL_SERVICE_PROVIDERS_BASE_API_ENDPOINT),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockLSPsResponse),
      );
    },
  ),
  rest.get<LSPApiResponse>(
    composeEndpoint(`${LOCAL_SERVICE_PROVIDERS_BASE_API_ENDPOINT}/:lspId`),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockLSPsResponse[0]),
      );
    },
  ),
  rest.get<{ employees: SimplifiedEmployee[] }>(
    composeEndpoint('/employees/lsp/:lspId'),
    (req, res, ctx) => {
      const query = req.url.searchParams;
      const simplified = query.get('simplified');
      const lspEmployeesView: any = mockLSPEmployees;
      if (simplified) {
        return res(
          ctx.status(HTTPStatusCodesEnum.OK),
          ctx.json(lspEmployeesView),
        );
      }
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({ employees: mockEmployeesLspView }),
      );
    },
  ),
  rest.get<{ lspUsers: User[] }, { lspId: string }>(
    composeEndpoint('/local-service-providers/:lspId/users'),
    (req, res, ctx) => {
      if (req.params.lspId === 'lsp-with-no-users') {
        return res(ctx.json({ lspUsers: [] }));
      }

      if (req.params.lspId === 'lsp-which-errors') {
        return res(ctx.status(500));
      }
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({ lspUsers: [mockLspUser] }),
      );
    },
  ),
  rest.get(
    composeEndpoint('/local-service-providers/:lspId/users/:userId'),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          lspId: 'mockLsp',
          userId: 'd1bd64d2-9141-42b3-b9e5-c607d5b4779e',
        }),
      );
    },
  ),
  rest.delete(
    composeEndpoint('/local-service-providers/:lspId/users/:userId'),
    (_req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json({}));
    },
  ),
];
