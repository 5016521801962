import { Fragment, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import { Loading } from 'common/components';
import {
  FileInState,
  FileUploadInput,
} from 'common/components/molecules/FileUploadInput/FileUploadInput';
import { exportCSVFileWithFs } from 'common/helpers/exportData';
import { FourOhThree } from 'common/pages/403/FourOhThree';
import { getTenantIdSelector } from 'common/store/selectors/companies.selectors';
import { AlertCircle, CheckCircle } from 'react-feather';
import { useSelector } from 'react-redux';
import { useSelectUserRoles } from 'utils/hooks';
import { useTrack } from 'utils/mixpanel/tracker';
import { useDownloadBulkCompensationCSVTemplateMutation } from 'utils/queries/employees/useDownloadBulkCompensationCSVTemplateMutation';
import { useValidateBulkSalaryChangeMutation } from 'utils/queries/employees/useValidateBulkSalaryChangeMutation';
import { useValidateBulkVariantPaymentMutation } from 'utils/queries/variantPayments';

import {
  Box,
  Button,
  getISOString,
  Inline,
  omniConfig,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import { CompensationTypes } from '../BulkCompensationOptionsPage/BulkCompensationOptionsPage';
import {
  compensationHeadings,
  getBackLinkPath,
  getBulkCompensationWarning,
  getNextPagePath,
  getPluralizedText,
  selectedEmployeesTextValues,
} from '../utils';
import {
  LoadingContainer,
  TextLink,
} from './CompensationBulkUploadCSVPage.styles';

type LocationState = {
  selectedCompensation: CompensationTypes;
  selectedEmployeesIds: string[];
};

const DEFAULT_FILE_INPUT_STATE: FileInState = {
  id: 'BulkCompensationUploadFile',
  fileName: '',
  progress: 0,
  state: 'pending',
};

export const CompensationBulkUploadCSVPage = () => {
  const track = useTrack();
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const data = location.state as LocationState;
  const { selectedCompensation, selectedEmployeesIds } = data;

  const downloadCSVTemplateMutation =
    useDownloadBulkCompensationCSVTemplateMutation();
  const validateBulkVariantPaymentMutation =
    useValidateBulkVariantPaymentMutation();
  const validateBulkSalaryChangeMutation =
    useValidateBulkSalaryChangeMutation();
  const validateBulkCompensation =
    selectedCompensation === 'salary'
      ? validateBulkSalaryChangeMutation
      : validateBulkVariantPaymentMutation;

  const { isManager } = useSelectUserRoles();
  const { companyId: adminCompanyId } = useParams<{ companyId: string }>();
  const managerCompanyId = useSelector(getTenantIdSelector);
  const companyId = adminCompanyId || managerCompanyId;

  const [fileData, setFileData] = useState<FileInState>(
    DEFAULT_FILE_INPUT_STATE,
  );

  const onUploadFileHandler = (file: File) => {
    setFileData((fileData) => ({
      ...fileData,
      fileName: file.name,
    }));
  };

  const onBackClick = () => {
    const backLink = getBackLinkPath(isManager, 'upload', companyId);
    appHistory.push(backLink);
  };

  const handleFileSubmited = (data: Partial<FileInState>) => {
    track('Upload Bulk Compensation File', { selectedCompensation });
    setFileData((fileData) => ({
      ...fileData,
      state: data.state || fileData.state,
      url: data.url,
      progress: data.progress || fileData?.progress,
    }));
  };

  const onGetTemplateButtonClicked = () => {
    track('Download Bulk Compensation Template', { selectedCompensation });
    setIsLoading(true);
    const compensationType = selectedCompensation.toLocaleLowerCase();
    const employeesIds = selectedEmployeesIds;

    downloadCSVTemplateMutation.mutate(
      { companyId, employeesIds, compensationType },
      {
        onError: (err) => console.error(err),
        onSuccess: (csvTemplate) => {
          exportCSVFileWithFs(
            csvTemplate,
            `bulk-${selectedCompensation.toLowerCase()}-template-${getISOString()}.csv`,
          );
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const onNextClick = () => {
    track('Validate Bulk Compensation Clicked', { selectedCompensation });
    const nextPage = getNextPagePath(isManager, 'upload/verify', companyId);
    if (fileData.url) {
      validateBulkCompensation.mutate(
        {
          companyId,
          type: selectedCompensation,
          csvFileUrl: fileData.url,
        },
        {
          onSuccess: (response) => {
            const validatedData = {
              selectedEmployeesIds,
              selectedCompensation,
              uploadedCSVUrl: fileData.url,
              csvValidationResult: response,
            };
            appHistory.push(nextPage, validatedData);
          },

          onError: (err) => console.error(err),
        },
      );
    }
  };

  return !selectedCompensation ? (
    <FourOhThree />
  ) : (
    <PageContent className="bigStack">
      <Typography as="h3" hideParagraphSpacing size="24" weight="medium">
        {compensationHeadings[selectedCompensation]}
      </Typography>
      <Box mr="64">
        {getBulkCompensationWarning()}
        <Box bg="primary" maxH="90vh" p="32" mt="32" radius="m" depth="s">
          <Typography as="h5" hideParagraphSpacing size="20" weight="medium">
            Fill in the Template
          </Typography>
          <Typography as="span" size="14">
            Use the template provided below to submit multiple{' '}
            {selectedCompensation} requests
          </Typography>

          <Stack my="32">
            <Inline bg="primary">
              {selectedEmployeesIds.length > 0 && (
                <Fragment key=".0">
                  <CheckCircle
                    size="20"
                    color={omniConfig.theme.colors['icon-positive']}
                  />
                  <Typography as="span" size="14" weight="bold">
                    You have added{' '}
                    {`${selectedEmployeesIds.length} ${getPluralizedText(
                      selectedEmployeesIds.length,
                      selectedEmployeesTextValues,
                      'employee',
                    )}`}{' '}
                    to the template.
                  </Typography>
                </Fragment>
              )}
              {selectedEmployeesIds.length === 0 && (
                <Fragment key=".0">
                  <AlertCircle
                    size="20"
                    color={omniConfig.theme.colors['icon-neutral']}
                  />
                  <Typography as="span" size="14" weight="bold">
                    You have not added any employees to the template.
                  </Typography>
                </Fragment>
              )}
            </Inline>
          </Stack>

          <Stack mt="32" gap="0">
            <Typography as="span" size="12" weight="bold">
              How to submit bulk {selectedCompensation} changes?
            </Typography>
            <ol style={{ fontWeight: 'bold' }}>
              <li key="instruction-1">
                <Typography as="span" size="14">
                  {`Download bulk ${selectedCompensation} `}
                  <TextLink
                    data-testid="download-template-button"
                    as="span"
                    onClick={onGetTemplateButtonClicked}
                    $isActive={selectedEmployeesIds.length > 0}
                  >
                    template
                  </TextLink>
                  .
                </Typography>
              </li>
              <li key="instruction-2">
                <Typography as="span" size="14">
                  Fill in the template.
                </Typography>
              </li>
              <li key="instruction-3">
                <Typography as="span" size="14">
                  Upload the filled template below
                </Typography>
              </li>
            </ol>
          </Stack>
          <Stack mr="16" w="50%">
            <FileUploadInput
              id={fileData.id}
              fileName={fileData.fileName}
              progress={fileData?.progress}
              state={fileData?.state}
              url={fileData}
              fileTypes={['text/csv']}
              uploadFile={onUploadFileHandler}
              updateFile={(_, data) => handleFileSubmited(data)}
              clear={() => setFileData(DEFAULT_FILE_INPUT_STATE)}
            />
          </Stack>
          <Inline
            mt="16"
            css={{
              justifyContent: 'space-between',
            }}
          >
            <Button
              icon="ChevronLeft"
              variant="secondary"
              onClick={onBackClick}
            >
              Back
            </Button>
            <Button
              disabled={!fileData.url}
              loading={validateBulkCompensation.isLoading}
              onClick={onNextClick}
            >
              Next
            </Button>
          </Inline>
        </Box>
        {isLoading && (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        )}
      </Box>
    </PageContent>
  );
};
