import { useHistory } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { PAGE_TITLE as CompensationPageTitle } from 'omniplatform/manager/pages/Compensation/CompensationPage/CompensationPage';
import { COMPENSATION_PATH } from 'paths';

import {
  Breadcrumbs,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import { BulkCompensationOptionsPage } from './BulkCompensationOptionsPage/BulkCompensationOptionsPage';

const PageTitle = 'Upload compensation changes in bulk';

export const CompensationBulkUploadPage = () => {
  const history = useHistory();

  return (
    <PageContent style={{ position: 'relative' }}>
      <Stack gap="24">
        <Breadcrumbs
          crumbs={[
            { id: 'home', title: 'Home', onClick: () => history.push('/') },
            {
              id: 'compensation',
              title: CompensationPageTitle,
              onClick: () => history.push(COMPENSATION_PATH),
            },
            { id: 'bulk-compensation', title: PageTitle, active: true },
          ]}
        />
        <Typography as="h2" hideParagraphSpacing size="24" weight="medium">
          {PageTitle}
        </Typography>
        <BulkCompensationOptionsPage />
      </Stack>
    </PageContent>
  );
};
