import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import Button from '../Button/Button';
import { baseButton, defaultPalette } from '../Button/Button.styles';

const baseLinkButton = css`
  ${baseButton}
  ${defaultPalette}

  min-height: 0;
  text-decoration: none;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-inverse']}`};
  color: ${({ theme: { colors } }) => colors['gray-500']};
  background: white;

  :disabled {
    background-color: ${({ theme: { colors } }) => colors['white-base']};
    border-color: ${({ theme: { colors } }) => colors['border-subtle']};
    color: ${({ theme: { colors } }) => colors['gray-200']};
  }

  :active,
  :focus {
    background-color: ${({ theme: { colors } }) => colors['gray-200']};
    border-color: ${({ theme: { colors } }) => colors['gray-400']};
  }

  :hover:not(:disabled) {
    background-color: ${({ theme: { colors } }) => colors['gray-100']};
    border-color: ${({ theme: { colors } }) => colors['border-strong']};
  }

  svg {
    height: ${({ theme: { space } }) => space[16]};
    width: ${({ theme: { space } }) => space[16]};
    color: ${({ theme: { colors } }) => colors['gray-500']};
  }
`;

export const StyledLinkAsButton = styled(Link)`
  ${baseLinkButton}
`;

export const StyledBackLinkButton = styled(Button)`
  ${baseLinkButton}
`;

export const StyledLinkButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
