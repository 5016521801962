import { AxiosError } from 'axios';
import { updateClientTerms } from 'common/api/contractors.api';
import { useMutation, useQueryClient } from 'react-query';

import { notifyError } from '@omnipresentgroup/design-system';

export const useClientTermsMutation = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(() => updateClientTerms(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`contractor-clients`]);
    },
    onError: (error: AxiosError) => {
      notifyError({
        title: 'Error',
        description: `There was a problem while processing the request: ${error?.response?.data?.message}.`,
      });
    },
  });
};
