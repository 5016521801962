import { contractorsClient } from 'api';
import { HTTPStatusCodesEnum } from 'common/constants';
import {
  BillingCurrencyEnum,
  ContractorState,
  ContractState,
  InvoicingCurrencyEnum,
  InvoicingFrequencyEnum,
  type ClientAPI,
  type ContractAPI,
  type GetContractorResponseAPI,
  type GetContractorsCostsResponseAPI,
  type GetCountriesResponseAPI,
} from 'common/types/contractor';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

export const GET_CONTRACTORS_COSTS_RESPONSE: GetContractorsCostsResponseAPI = {
  monthly_platform_fee: '40',
  fx_fee: '1.5',
  vat_rate: '0.2',
  vat_amount: '5.5',
  total_cost: '45.5',
  country: {
    code: 'GB',
    name: 'United Kingdom',
    flag: '🇬🇧',
  },
};

export const GET_COUNTRIES_RESPONSE: GetCountriesResponseAPI = [
  {
    code: 'ES',
    name: 'Spain',
    flag: '🇪🇸',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    flag: '🇬🇧',
  },
];

export const DEFAULT_CONTRACTOR: GetContractorResponseAPI = {
  id: '9e0fefc5-d7b1-4adc-b1ec-8a714c8f78c4',
  first_name: 'Angus',
  last_name: 'Campbell',
  email: `angus.campbell@qatester.com`,
  residence_country: {
    code: 'GB',
    name: 'United Kingdom',
    flag: '🇬🇧',
  },
  state: ContractorState.ACTIVE,
  contracts: [
    {
      id: 'dummy-id-0',
      contractor_id: '9e0fefc5-d7b1-4adc-b1ec-8a714c8f78c4',
      client_id: 'dummy-client-id-0',
      job_role: 'QA tester',
      invoicing_currency: InvoicingCurrencyEnum.EUR,
      expected_invoicing_frequency: InvoicingFrequencyEnum.PER_MONTH,
      billing_currency: BillingCurrencyEnum.EUR,
      start_date: '2023-02-15',
      end_date: '',
      rate: '1000.123',
      state: ContractState.ACTIVE,
    },
  ],
  user_id: 'dummy-user-id-0',
  misclassification_risk_accepted: true,
  misclassification_risk_accepted_at: '2024-03-08',
  misclassification_risk_accepted_by: 'dummy-user-id',
};

export const DEFAULT_CLIENT: ClientAPI = {
  id: 'dummy-client-id-0',
  company_id: 'dummy-company-id-0',
  company_name: 'some company',
};

export const generateContractor = (
  override: Partial<
    Omit<GetContractorResponseAPI, 'contract'> & {
      contract: Partial<ContractAPI>;
    }
  > = {},
) => ({
  ...DEFAULT_CONTRACTOR,
  ...override,
  contracts: [
    {
      ...DEFAULT_CONTRACTOR.contracts[0],
      ...override.contract,
    },
  ],
});

export const DEFAULT_CONTRACTORS = [
  DEFAULT_CONTRACTOR,
  generateContractor({
    id: '86f05c68-7ab3-486b-9403-d251632f3f04',
    first_name: 'Meu',
    last_name: ' Primo',
    contract: {
      contractor_id: '86f05c68-7ab3-486b-9403-d251632f3f04',
      job_role: 'Staff Engineer',
      start_date: '2024-01-02',
    },
  }),
  generateContractor({
    id: 'd00f66dc-d616-4b8b-9a0f-6281d20fee52',
    first_name: 'That Dude',
    last_name: 'Right There',
    contract: {
      contractor_id: 'd00f66dc-d616-4b8b-9a0f-6281d20fee52',
      job_role: 'Staff Engineer',
      start_date: '2024-01-13',
    },
  }),
  generateContractor({
    id: 'fe9e6bbc-6011-4df1-898a-ad063853021e',
    first_name: 'Someone',
    last_name: 'Else',
    contract: {
      contractor_id: 'fe9e6bbc-6011-4df1-898a-ad063853021e',
      job_role: 'Senior Software Engineer',
      start_date: '2024-01-12',
    },
  }),
  generateContractor({
    id: 'fe9e6bbc-6011-4df1-898a-a423853021e',
    first_name: 'Elsa',
    last_name: 'Else',
    contract: {
      contractor_id: 'fe9e6bbc-6011-4df1-898a-a423853021e',
      job_role: 'Software Engineer',
      start_date: '2024-01-12',
      end_date: '2028-01-12',
    },
  }),
];

export const contractorsHandlers = [
  rest.get(
    composeEndpoint('/contractors', contractorsClient),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          items: DEFAULT_CONTRACTORS,
          total: 60,
          page: 1,
          size: 20,
          pages: 3,
        }),
      ),
  ),
  rest.post(
    composeEndpoint('/contractors', contractorsClient),
    (_req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.RESOURCE_CREATED), ctx.json({})),
  ),
  rest.get(
    composeEndpoint('/contractors/costs', contractorsClient),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(GET_CONTRACTORS_COSTS_RESPONSE),
      ),
  ),
  // This needs to be after /contractors/* endpoints due to wildcard selector
  rest.get(
    composeEndpoint('/contractors/:contractorId', contractorsClient),
    (_req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(DEFAULT_CONTRACTOR)),
  ),
  rest.get(
    composeEndpoint('/common/supported-countries', contractorsClient),
    (_req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(GET_COUNTRIES_RESPONSE)),
  ),
  rest.put(
    composeEndpoint('/clients/:clientId/accept', contractorsClient),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({ ...DEFAULT_CLIENT, op_terms_accepted: true }),
      ),
  ),
  // This needs to be after /clients/* endpoints due to wildcard selector
  rest.get(
    composeEndpoint('/clients/:clientId', contractorsClient),
    (_req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(DEFAULT_CLIENT)),
  ),
];
