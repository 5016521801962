import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { joiResolver } from '@hookform/resolvers/joi';
import { sendPlatformInvitationRequest } from 'app/api/user.api';
import { queryClient } from 'app/queryClient';
import { getTransformedUser } from 'app/store/app.selectors';
import { departmentTypeOptions } from 'appConstants';
import { Loading } from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { FormInput } from 'common/components/molecules/FormInput';
import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { X } from 'react-feather';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useForm,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { companiesKeys } from 'utils/queries/companies/keys';
import { useCreateNewManagerMutation } from 'utils/queries/managerPermissions/useCreateNewManagerMutation';

import { Dropdown, Stack, Typography } from '@omnipresentgroup/design-system';

import * as S from './AddManagerForm.styles';
import { schema } from './AddManagerFormSchema';

export const AddManagerForm = () => {
  type ParamTypes = {
    companyId: string;
  };

  const user = useSelector(getTransformedUser);
  const companyId_URL = useParams<ParamTypes>();
  const companyId = companyId_URL.companyId ?? user?.role?.tenantId;

  const createNewManagerMutation = useCreateNewManagerMutation();

  const { register, handleSubmit, formState, reset, control } = useForm({
    mode: 'onChange',
    resolver: joiResolver(schema),
  });
  const [showModal, setShowModal] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');

  const handleAddManagerFormSubmit = useCallback(
    (addManager) => {
      const managerDetails = {
        firstName: addManager.firstName,
        lastName: addManager.lastName,
        email: addManager.email,
        jobTitle: addManager.jobTitle,
        department: addManager.department,
      };
      createNewManagerMutation.mutate(
        {
          companyId,
          managerDetails,
        },
        {
          onSuccess: async (data) => {
            setShowModal(true);
            queryClient.refetchQueries(companiesKeys.managers(companyId));
            try {
              await sendPlatformInvitationRequest(data.id, true);
            } catch (error) {
              //Note: currently no functionality to indicate to user if platform invite is unsuccessful
            }
            reset();
          },
          onError: (error) => {
            if (error instanceof Error && error.message.includes('409')) {
              setSubmitErrorMessage(
                'This email is already associated with a user in the platform',
              );
            }
            setSubmitError(true);
          },
        },
      );
    },
    [createNewManagerMutation, companyId, reset],
  );

  const renderDropdown: ({
    field,
  }: {
    field: ControllerRenderProps<FieldValues, 'department'>;
  }) => React.ReactElement = ({ field }) => (
    <Dropdown
      id="departmentSelectionDropdown"
      label="Department"
      required={true}
      isClearable={false}
      options={departmentTypeOptions}
      value={departmentTypeOptions.find((d) => d.value === field.value)}
      placeholder="Choose a department"
      errorMessage={formState.errors['department']?.message}
      onChange={(newValue) => {
        if (newValue !== null && 'value' in newValue) {
          newValue && field.onChange(newValue.value);
        }
      }}
    />
  );

  return (
    <>
      {showModal && (
        <ModalWrapper fixedWidth="500" fixedHeight="294">
          <S.CloseButtonWrapper
            onClick={() => setShowModal(false)}
            onKeyPress={() => setShowModal(false)}
            role="button"
            tabIndex={0}
          >
            <X />
          </S.CloseButtonWrapper>
          <S.StyledSuccessModalContainer
            onClick={(e: { stopPropagation: () => void }) => {
              e.stopPropagation();
            }}
            data-testid="success-modal"
          >
            <S.StyledSuccessIcon />
            <S.StyledContentContainer>
              <S.StyledHeader>Success!</S.StyledHeader>
            </S.StyledContentContainer>
            <S.StyledContentContainer>
              <S.StyledHeader>
                The invite has been sent and the new manager has been created.
              </S.StyledHeader>
            </S.StyledContentContainer>
          </S.StyledSuccessModalContainer>
        </ModalWrapper>
      )}
      {submitError && <ErrorBanner errorMessage={submitErrorMessage} />}
      {createNewManagerMutation.isLoading ? (
        <S.SpinnerWrapper>
          <Loading />
        </S.SpinnerWrapper>
      ) : (
        <Stack bg="primary">
          <Stack gap="0">
            <S.AddManagerFormTitleContainer>
              <Typography as="span" size="16" weight="medium">
                Add new manager
              </Typography>
              <S.RequiredFields>* required field</S.RequiredFields>
            </S.AddManagerFormTitleContainer>
            <Typography as="span" size="14" color="helper">
              Invite a manager outside of the platform
            </Typography>
          </Stack>
          <S.StyledForm
            data-testid="add-manager-form"
            onSubmit={handleSubmit(handleAddManagerFormSubmit)}
          >
            <FormInput
              as="text"
              id="firstName"
              label="First Name"
              placeholder="Type the first name"
              required
              error={formState.errors['firstName']?.message}
              {...register('firstName')}
            />
            <FormInput
              as="text"
              id="lastName"
              label="Last Name"
              placeholder="Type the last name"
              required
              error={formState.errors['lastName']?.message}
              {...register('lastName')}
            />
            <FormInput
              as="text"
              id="jobTitle"
              label="Job Title"
              placeholder="Type the job title"
              required
              error={formState.errors['jobTitle']?.message}
              {...register('jobTitle')}
            />

            <Controller
              control={control}
              name="department"
              render={renderDropdown}
            />

            <FormInput
              as="text"
              id="email"
              label="Email"
              placeholder="Type the email"
              required
              error={formState.errors['email']?.message}
              {...register('email')}
            />
          </S.StyledForm>
          <S.CtaWrapper>
            <S.CtaButton
              onClick={handleSubmit(handleAddManagerFormSubmit)}
              disabled={!formState.isValid || formState.isSubmitting}
            >
              Add a manager
            </S.CtaButton>
          </S.CtaWrapper>
        </Stack>
      )}
    </>
  );
};
