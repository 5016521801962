export enum AlertNotification {
  EMPTY = 'EMPTY',
  SHOW_WALLET_TOPUP = 'SHOW_WALLET_TOPUP',
  PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS',
  PROFILE_UPDATE_ERROR = 'PROFILE_UPDATED_ERROR',
  INVOICE_REJECTED = 'INVOICE_REJECTED',
  INVOICE_DELETED = 'INVOICE_DELETED',
}

export type AlertNotificationType = keyof typeof AlertNotification;
