import appHistory from 'app/appHistory';

import { Box, Button, ContentState } from '@omnipresentgroup/design-system';

type FourXXErrorProps = {
  message: string;
  error: string;
};

const FourXXError = ({ message, error }: FourXXErrorProps) => (
  <Box bg="primary" p="4" radius="m" border="subtle">
    <ContentState
      id="fourxx-error"
      variant="error"
      title={error}
      description={message}
      callToAction={<Button onClick={() => appHistory.goBack()}>Back</Button>}
    />
  </Box>
);

export default FourXXError;
