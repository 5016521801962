import { HTTPStatusCodesEnum } from 'common/constants';
import {
  Company,
  CompanyAvailableProducts,
  CompanyStatusEnum,
  User,
} from 'common/types';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';
import { mockCompanyManagers } from 'omniplatform/admin/__mocks__/companyManagers.mock';

const netsuiteId = 'mockNetsuiteId28';

export const DEFAULT_COMPANY: Company = {
  countryOfRegistration: 'United Kingdom',
  noOfEmployees: 2,
  onboardingStage: 'Onboarding complete',
  invoicingEmail: 'e2e-manager@omnie2etesting.com',
  updatedBy: '',
  updatedAt: '',
  adminNotes: 'Wants employees to be paid in Dollars',
  needsManagerReview: false,
  id: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
  externalId: 'ETC',
  name: 'E2E test co',
  managers: [],
  registeredAddress: {
    addressLine1: '123 Test apartment',
    city: 'London',
    postalCode: '11 890',
    country: 'United Kingdom',
    addressLine2: 'Test Road',
  },
  createdAt: '2021-01-12T12:07:00.032Z',
  createdBy: '7880bcbe-f20e-4d07-bea8-2a2a1ef1ef5b',
  settings: {},
  createdManagers: [],
  isVATExempt: false,
  fxConversionPercent: '2',
  salaryPrepayment: {
    terms: 2,
    multiplier: 2,
  },
  status: CompanyStatusEnum.ACTIVE,
  enabledProducts: ['EOR', 'Contractor'],
};

export const DEFAULT_COMPANY_ENABLED_PRODUCTS: CompanyAvailableProducts = {
  EOR: true,
  Contractor: true,
};

export const generateCompany = (override: Partial<Company> = {}) => ({
  ...DEFAULT_COMPANY,
  ...override,
});

export const companiesHandlers = [
  rest.get(composeEndpoint('/companies'), (req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.ACCEPTED), ctx.json([DEFAULT_COMPANY])),
  ),
  rest.get(composeEndpoint(`/companies/:companyId`), (_req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.ACCEPTED), ctx.json(DEFAULT_COMPANY)),
  ),
  rest.get(
    composeEndpoint(`/companies/:companyId/available-products`),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(DEFAULT_COMPANY_ENABLED_PRODUCTS),
      ),
  ),
  rest.post(
    composeEndpoint(`/companies/:newCompanyId/netsuite`),
    (_req, res, ctx) => res(ctx.status(201), ctx.json({ netsuiteId })),
  ),
  rest.get<User[], { companyId: string }>(
    composeEndpoint(`/companies/:companyId/managers`),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.ACCEPTED),
        ctx.json(mockCompanyManagers),
      ),
  ),
  rest.delete(
    composeEndpoint(`/companies/:companyId/managers/:managerId`),
    (req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.ACCEPTED), ctx.json({}));
    },
  ),
];
