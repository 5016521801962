import Button from 'common/components/atoms/Button';
import { StyledFullWidthDividerHr } from 'common/components/atoms/Divider/Divider.styles';
import { StyledBoldCopy, StyledContent } from 'common/components/atoms/Text';
import styled from 'styled-components';

export const Divider = styled(StyledFullWidthDividerHr)`
  margin: ${({ theme: { space } }) => `${space[16]}`} 0;
`;

export const EmployeesChecklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background: ${({ theme: { colors } }) => colors['background-primary']};
  padding: ${({ theme: { space } }) => `${space[32]}`};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  border-radius: ${({ theme: { radii } }) => radii.s};
`;

export const CheckListContainer = styled.div`
  margin: 0;
  margin-top: ${({ theme: { space } }) => `${space[16]}`};
`;

export const RightAlignButton = styled(Button)`
  margin-left: auto;
`;

export const NameHeader = styled.h4`
  margin: 0;
  margin-bottom: ${({ theme: { space } }) => space[4]};
  color: ${({ theme: { colors } }) => colors['text-subtle']};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
`;

export const NameContainer = styled.h3`
  margin: 0;
  color: ${({ theme: { colors } }) => colors['text-default']};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  text-transform: capitalize;
`;

export const ButtonContainer = styled.div`
  margin-top: ${({ theme: { space } }) => space[32]};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const EmployeeChecklistSubtitle = styled(StyledBoldCopy)`
  margin-top: ${({ theme: { space } }) => `${space[16]}`};
  color: ${({ theme: { colors } }) => colors['text-default']};
`;

export const EmployeeChecklistExplanations = styled(StyledContent)`
  margin: 0;
`;
