import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledTab = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-self: center;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    padding-right: ${({ theme: { space } }) => space[16]};
  }
`;

export const Container = styled.div``;

export const FieldsWrapper = styled.div`
  background: ${({ theme: { colors } }) => colors['background-primary']};
  padding: ${({ theme: { space } }) => space[64]};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  border-radius: ${({ theme: { radii } }) => radii.s};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    padding: ${({ theme: { space } }) =>
      `${space[8]} ${space[8]} 0 ${space[8]}`};
  }
`;
