import { getTenantIdSelector } from 'common/store/selectors/companies.selectors';
import { getEmployeeExpensesRequest } from 'omniplatform/employee/api/expenses.api';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { createActionObject } from 'utils/redux-utils';

import { transformExpenseList } from '../../../employee/store/transformers/expensesList.transformer';
import {
  getEmployeeExpenseRequest,
  getManagersEmployeesExpensesRequest,
  updateEmployeeExpenseRequest,
} from '../../api/managerEmployeesExpenses.api';
import managerActionKeys from '../actions/reduxConstants';
import { getSelectedExpenseSelector } from '../selectors/managersEmployeeExpenses.selector';
import {
  transformExpenseFromAPI,
  transformUpdateExpenseRequest,
} from '../transformers/employeesExpense.transformer';

function* getEmployeeExpenses({ payload: employeeId }) {
  try {
    let employeesExpenses;

    if (employeeId) {
      employeesExpenses = yield call(getEmployeeExpensesRequest, employeeId);
    } else {
      const tenantId = yield select(getTenantIdSelector);
      employeesExpenses = yield call(
        getManagersEmployeesExpensesRequest,
        tenantId,
      );
    }

    yield put(
      createActionObject(
        managerActionKeys.GET_EMPLOYEE_EXPENSES_END,
        transformExpenseList(employeesExpenses.data),
      ),
    );
  } catch (e) {
    yield put(
      createActionObject(managerActionKeys.GET_EMPLOYEE_EXPENSES_ERROR, e),
    );
  }
}

function* getEmployeeExpense({ payload }) {
  try {
    const { data: expense } = yield call(getEmployeeExpenseRequest, payload);
    yield put(
      createActionObject(
        managerActionKeys.GET_EMPLOYEE_EXPENSE_END,
        transformExpenseFromAPI(expense),
      ),
    );
  } catch (e) {
    yield put(
      createActionObject(managerActionKeys.GET_EMPLOYEE_EXPENSE_ERROR, e),
    );
  }
}

function* updateEmployeeExpense({ payload }) {
  try {
    const { id: expenseId } = yield select(getSelectedExpenseSelector);
    yield call(
      updateEmployeeExpenseRequest,
      expenseId,
      transformUpdateExpenseRequest(payload),
    );
    yield call(getEmployeeExpense, { payload: expenseId });
    yield take(managerActionKeys.GET_EMPLOYEE_EXPENSE_END);
    yield put(
      createActionObject(managerActionKeys.UPDATE_EMPLOYEE_EXPENSE_END),
    );
  } catch (e) {
    yield put(
      createActionObject(managerActionKeys.UPDATE_EMPLOYEE_EXPENSE_ERROR, e),
    );
  }
}

export default [
  takeLatest(
    managerActionKeys.GET_EMPLOYEE_EXPENSES_START,
    getEmployeeExpenses,
  ),
  takeLatest(managerActionKeys.GET_EMPLOYEE_EXPENSE_START, getEmployeeExpense),
  takeLatest(
    managerActionKeys.UPDATE_EMPLOYEE_EXPENSE_START,
    updateEmployeeExpense,
  ),
];
