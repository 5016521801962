import {
  ADVANCED_PAYMENT_DEPOSIT,
  depositTypeOptions,
  NO_DEPOSIT,
  ZERO_DEPOSIT_INSURANCE,
} from 'common/helpers/depositTypeOptions.const';
import {
  EmployeeType,
  Field,
  FIELD_TYPE,
  FormConfig,
  Seat,
  SeatInputData,
  SOWStatusEnum,
} from 'common/types';
import JoiImport from 'joi';
import { DateTime } from 'luxon-business-days';
import {
  billingCurrencies,
  BillingCurrency,
  billingCurrencyFormOptions,
} from 'utils/currencies';
import { transformFormCheckBoxValue } from 'utils/transformer-utils/input.transformer';

import {
  DateFormats,
  formatISODate,
  Link,
  Typography,
} from '@omnipresentgroup/design-system';
import DateExtension from '@omnipresentgroup/joi-date';

const Joi = JoiImport.extend(DateExtension) as JoiImport.Root;

const minOnboardingDate = DateTime.local()
  .startOf('day')
  .plusBusiness({ days: 3 });
const warnOnboardingDate = DateTime.local()
  .startOf('day')
  .plusBusiness({ days: 13 });

const onboardingWarningMessage = (
  <Typography as="span" size="12">
    We will confirm the start date once we have all required documentation. To
    meet start dates that are less than 2 weeks away: employees must act
    quickly, have the right to work in the country, and there must be no
    requests to change the employment contract. Please also see{' '}
    <Link
      size="12"
      to="https://help.omnipresent.com/en/articles/8582679-kolleno-and-making-online-payments"
      target="_blank"
    >
      this article
    </Link>{' '}
    on how to settle invoices quickly.
  </Typography>
);

export const addCompanyEmployeeFormDefaultConfig = (
  seat?: Seat,
  country?: string,
): FormConfig => ({
  formTitle: 'Request new employee',
  formCopy: 'To add an employee to the OmniPlatform please fill in this form.',
  formFields: [
    {
      name: 'countryName',
      label: 'Country of work',
      type: FIELD_TYPE.COUNTRY,
      value: seat?.countryName || country || '',
      error: null,
      required: true,
    },
    {
      name: 'firstName',
      label: 'First name',
      type: FIELD_TYPE.STRING,
      error: null,
      value: seat?.firstName || '',
    },
    {
      name: 'lastName',
      label: 'Last name',
      type: FIELD_TYPE.STRING,
      value: seat?.lastName || '',
      error: null,
    },
    {
      name: 'desiredStartDate',
      label: 'Desired start date',
      type: FIELD_TYPE.DATE,
      value: seat?.desiredStartDate
        ? formatISODate(seat?.desiredStartDate, DateFormats.FixedDate)
        : '',
      contextualInfo:
        seat?.desiredStartDate &&
        DateTime.fromISO(seat.desiredStartDate).startOf('day') <
          warnOnboardingDate
          ? onboardingWarningMessage
          : '',
      error: null,
      required: true,
    },
    {
      name: 'sowSigned',
      label: 'Order form already signed outside platform',
      type: FIELD_TYPE.CHECKBOX,
      value: transformFormCheckBoxValue(
        seat?.sowStatus ? seat.sowStatus === SOWStatusEnum.SOW_ACCEPTED : false,
      ),
      error: null,
      showFor: 'admin',
    },
  ],
  schema: Joi.object({
    firstName: Joi.string().allow('', null),
    lastName: Joi.string().allow('', null),
    countryName: Joi.string()
      .required()
      .messages({ 'string.empty': 'Please enter a country' }),
    desiredStartDate: Joi.date()
      .min('now')
      .format('YYYY-MM-DD')
      .raw()
      .messages({
        'date.empty': 'Please enter a start date',
        'date.format': 'Please enter a valid start date',
        'date.min': 'Please enter date in future',
      }),
    sowSigned: Joi.any(),
  }),
});

export const employeeTypeOptions = [
  { value: 'EOR', label: 'EOR' },
  { value: 'PEO', label: 'PEO' },
  { value: 'Virtual Employer', label: 'Virtual Employer' },
];

export const setupFeeStructureOptions = [
  { value: 'per employee', label: 'per employee' },
  { value: 'per country', label: 'per country' },
];

export const employeeHasSupplementaryBenefitsOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
  { value: 'unknown', label: 'Unknown' },
];
// Valid currencies at the company level
type ValidBillingCurrencies = {
  value: BillingCurrency;
  label: BillingCurrency;
}[];

const isLimitedBillingOptions = (limited: undefined | boolean): boolean =>
  limited ?? true;

const getDepositTypeField = (
  seat?: SeatInputData,
  employeeType?: EmployeeType,
): Field[] => {
  let depositTypeOptionsToUse = depositTypeOptions;

  if (employeeType === 'EOR') {
    depositTypeOptionsToUse = [
      { label: `${NO_DEPOSIT} (only for OP employees)`, value: NO_DEPOSIT },
      { label: ZERO_DEPOSIT_INSURANCE, value: ZERO_DEPOSIT_INSURANCE },
      { label: ADVANCED_PAYMENT_DEPOSIT, value: ADVANCED_PAYMENT_DEPOSIT },
    ];
  } else if (
    employeeType &&
    ['PEO', 'Virtual Employer'].includes(employeeType)
  ) {
    depositTypeOptionsToUse = [{ label: NO_DEPOSIT, value: NO_DEPOSIT }];
  }

  return [
    {
      name: 'depositType',
      label: 'Deposit type',
      type: FIELD_TYPE.DROPDOWN,
      placeholder: 'Select deposit type',
      value: seat?.employeeProfile?.depositType || null,
      options: depositTypeOptionsToUse,
      error: null,
      required: true,
    },
  ];
};

const getAdvancedDepositPercentField = (
  seat?: SeatInputData,
  advancedPaymentDepositPercentage?: string | number,
): Field[] => {
  const percentages = ['10', '15', '20'];
  const isOtherPecentage = Boolean(
    advancedPaymentDepositPercentage &&
      (advancedPaymentDepositPercentage === 'Other' ||
        !percentages.includes(`${advancedPaymentDepositPercentage}`)),
  );

  return [
    {
      name: 'depositPercent',
      label: 'Deposit Percentage',
      type: FIELD_TYPE.DROPDOWN,
      value: isOtherPecentage
        ? 'Other'
        : `${seat?.employeeProfile?.advancedPaymentDepositPercentage}` || null,
      options: [
        ...percentages.map((percentage) => ({
          label: percentage,
          value: percentage,
        })),
        { label: 'Other', value: 'Other' },
      ],
      error: null,
      required: true,
    },
    ...(isOtherPecentage
      ? [
          {
            name: 'otherDepositPercent',
            type: FIELD_TYPE.NUMBER,
            label: 'Agreed Deposit Percentage',
            contextualInfo:
              'This has been agreed and communicated with finance',
            value:
              seat?.employeeProfile?.advancedPaymentDepositPercentage || null,
            error: null,
            required: true,
          },
        ]
      : []),
  ];
};

export const addCompanyEmployeeFormAdminConfig = ({
  seat,
  depositType,
  employeeType,
  depositPercentage,
}: {
  seat?: SeatInputData;
  depositType?: string;
  employeeType?: EmployeeType;
  depositPercentage?: string;
}): FormConfig => {
  const depositTypeToUse = depositType || seat?.employeeProfile?.depositType;
  const employeeTypeToUse = employeeType || seat?.employeeProfile?.employeeType;
  const depositPercentageToUse =
    depositPercentage ||
    seat?.employeeProfile?.advancedPaymentDepositPercentage;

  const isZeroDepositType = depositTypeToUse === 'Zero Deposit Insurance';
  const isAdvancedPaymentDepositType =
    depositTypeToUse === 'Advanced Payment Deposit (APD)';

  return {
    formTitle: 'Request new employee',
    formCopy:
      'To add an employee to the OmniPlatform please fill in this form.',
    formFields: [
      {
        name: 'countryName',
        label: 'Country of work',
        type: FIELD_TYPE.COUNTRY,
        value: seat?.countryName || '',
        error: null,
        required: true,
      },
      {
        name: 'firstName',
        label: 'First name',
        type: FIELD_TYPE.STRING,
        error: null,
        value: seat?.firstName || '',
        required: true,
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: FIELD_TYPE.STRING,
        value: seat?.lastName || '',
        error: null,
        required: true,
      },
      {
        name: 'employeeType',
        label: 'Employee type',
        type: FIELD_TYPE.DROPDOWN,
        placeholder: 'Select employee type',
        value: seat?.employeeProfile?.employeeType || null,
        options: employeeTypeOptions,
        error: null,
        required: true,
      },
      {
        name: 'billingCurrency',
        label: 'Billing currency',
        type: FIELD_TYPE.DROPDOWN,
        placeholder: 'Select billing currency',
        value: seat?.billingCurrency || null,
        options: billingCurrencyFormOptions,
        error: null,
        required: true,
      },
      {
        name: 'managementFeeAmount',
        label: 'Management fee',
        value:
          typeof seat?.employeeProfile?.managementFeeAmount === 'number'
            ? seat?.employeeProfile?.managementFeeAmount
            : seat?.managementFeeAmount || null,
        type: 'number',
        required: true,
      },
      {
        name: 'setupFeeAmount',
        label: 'Setup fee',
        value:
          typeof seat?.employeeProfile?.setupFeeAmount === 'number'
            ? seat?.employeeProfile?.setupFeeAmount
            : seat?.setupFeeAmount || null,
        type: 'number',
        required: true,
      },
      {
        name: 'setupFeeStructure',
        label: 'Setup fee structure',
        type: FIELD_TYPE.DROPDOWN,
        placeholder: 'Select setup fee structure',
        value: seat?.employeeProfile?.setupFeeStructure || null,
        options: setupFeeStructureOptions,
        error: null,
        required: true,
      },
      ...getDepositTypeField(seat, employeeTypeToUse),
      ...(isZeroDepositType
        ? [
            {
              name: 'zeroDepositFee',
              label: 'Deposit Fee',
              type: FIELD_TYPE.NUMBER,
              value: seat?.employeeProfile?.zeroDepositFee || null,
              error: null,
              required: true,
            },
          ]
        : []),
      ...(isAdvancedPaymentDepositType
        ? getAdvancedDepositPercentField(seat, depositPercentageToUse)
        : []),
      {
        name: 'desiredStartDate',
        label: 'Desired start date',
        type: FIELD_TYPE.DATE,
        value: seat?.desiredStartDate
          ? formatISODate(seat?.desiredStartDate, DateFormats.FixedDate)
          : '',
        error: null,
        required: true,
      },
      {
        name: 'sowSigned',
        label: 'Order form already signed outside platform',
        type: FIELD_TYPE.CHECKBOX,
        value: transformFormCheckBoxValue(
          seat?.sowStatus
            ? seat.sowStatus === SOWStatusEnum.SOW_ACCEPTED
            : false,
        ),
        error: null,
        showFor: 'admin',
      },
    ],
    schema: Joi.object({
      firstName: Joi.string()
        .required()
        .messages({ 'string.empty': 'Please provide a first name' }),
      lastName: Joi.string()
        .required()
        .messages({ 'string.empty': 'Please provide a last name' }),
      countryName: Joi.string()
        .required()
        .messages({ 'string.empty': 'Please enter a country' }),
      employeeType: Joi.string()
        .required()
        .messages({ 'string.base': 'Please choose an employee type' }),
      billingCurrency: Joi.string()
        .valid(...billingCurrencies)
        .required()
        .messages({ 'string.empty': 'Please select an billing currency' }),
      managementFeeAmount: Joi.number().min(0).required().messages({
        'number.base': 'Please enter a valid annual management fee amount',
      }),
      setupFeeAmount: Joi.number().min(0).required().messages({
        'number.base': 'Please enter a valid annual setup fee amount',
      }),
      setupFeeStructure: Joi.string()
        .required()
        .messages({ 'string.base': 'Please choose a setup fee structure' }),
      depositType: Joi.string()
        .required()
        .messages({ 'string.base': 'Please choose a deposit type' }),
      desiredStartDate: Joi.date()
        .min(minOnboardingDate.toJSDate())
        .format('YYYY-MM-DD')
        .raw()
        .messages({
          'date.empty': 'Please enter a start date',
          'date.format': 'Please enter a valid start date',
          'date.min':
            'Sorry we are not able to onboard in less then two business days.',
        }),
      sowSigned: Joi.any(),
      zeroDepositFee: isZeroDepositType
        ? Joi.number().min(1).required().messages({
            'any.required': `Please enter a valid number`,
          })
        : Joi.number().min(1).messages({
            'any.required': `Please enter a valid number`,
          }),
      depositPercent: isAdvancedPaymentDepositType
        ? Joi.string()
            .valid('10', '15', '20', 'Other')
            .messages({ 'any.required': 'Please select an option' })
        : Joi.string(),
      otherDepositPercent: Joi.number().min(1).messages({
        'any.required': `Please enter a valid number`,
      }),
    }),
  };
};

export const addCompanyEmployeeFormManagerConfig = (
  seat?: SeatInputData,
  limitBillingOptions?: boolean,
  validBillingCurrencies?: ValidBillingCurrencies,
  startDate?: Date,
): FormConfig => ({
  formTitle: 'Request new employee',
  formCopy: 'To add an employee to the OmniPlatform please fill in this form.',
  formFields: [
    {
      name: 'countryName',
      label: 'Country of work',
      type: FIELD_TYPE.COUNTRY,
      value: seat?.countryName || '',
      error: null,
      required: true,
    },
    {
      name: 'billingCurrency',
      label: 'Billing currency',
      type: FIELD_TYPE.DROPDOWN,
      placeholder: 'Your invoice will be in this currency',
      value: seat?.billingCurrency || null,
      options:
        isLimitedBillingOptions(limitBillingOptions) && validBillingCurrencies
          ? validBillingCurrencies
          : billingCurrencyFormOptions,
      error: null,
      required: true,
    },
    {
      name: 'annualBaseSalary',
      label: 'Annual base salary',
      type: FIELD_TYPE.EMPLOYEE_CURRENCY,
      value: {
        currency:
          seat?.employeeProfile?.annualBaseSalaryCurrency ||
          seat?.annualBaseSalary?.currency ||
          '',
        amount:
          seat?.employeeProfile?.annualBaseSalaryAmount ||
          seat?.annualBaseSalary?.amount ||
          1,
      },
      error: null,
      required: true,
    },
    {
      name: 'firstName',
      label: 'First name',
      type: FIELD_TYPE.STRING,
      error: null,
      value: seat?.firstName || '',
    },
    {
      name: 'lastName',
      label: 'Last name',
      type: FIELD_TYPE.STRING,
      error: null,
      value: seat?.lastName || '',
    },
    {
      name: 'desiredStartDate',
      label: 'Desired start date',
      type: FIELD_TYPE.DATE,
      value: seat?.desiredStartDate
        ? formatISODate(seat?.desiredStartDate, DateFormats.FixedDate)
        : '',
      error: null,
      contextualInfo:
        startDate &&
        DateTime.fromJSDate(startDate).startOf('day') < warnOnboardingDate
          ? onboardingWarningMessage
          : '',
      required: true,
    },
    {
      name: 'sowSigned',
      label: 'Order form already signed outside platform',
      type: FIELD_TYPE.CHECKBOX,
      value: transformFormCheckBoxValue(
        seat?.sowStatus ? seat.sowStatus === SOWStatusEnum.SOW_ACCEPTED : false,
      ),
      error: null,
      showFor: 'admin',
    },
  ],
  schema: Joi.object({
    countryName: Joi.string()
      .required()
      .messages({ 'string.empty': 'Please enter a country' }),
    firstName: Joi.string().allow('', null),
    lastName: Joi.string().allow('', null),
    annualBaseSalary: Joi.object().keys({
      amount: Joi.number().min(1).required().messages({
        'number.base': 'Please enter a valid annual base salary amount',
      }),
      currency: Joi.string().required().messages({
        'string.empty': 'Please select an annual base salary currency',
      }),
    }),
    billingCurrency: Joi.string()
      .valid(...billingCurrencies)
      .required()
      .messages({ 'string.empty': 'Please select an billing currency' }),
    desiredStartDate: Joi.date()
      .min(minOnboardingDate.toJSDate())
      .format('YYYY-MM-DD')
      .raw()
      .messages({
        'date.empty': 'Please enter a start date',
        'date.format': 'Please enter a valid start date',
        'date.min':
          'Sorry we are not able to onboard in less then two business days.',
      }),
    sowSigned: Joi.any(),
  }),
});
