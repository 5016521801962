import { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { X } from 'react-feather';
import { useFocusTrap } from 'utils/hooks/useFocusTrap';

import * as S from './ModalWrapper.styles';

export type ModalWrapperProps = {
  children: React.ReactNode;
  fixedHeight?: string;
  fixedWidth?: string;
  fullScreen?: boolean;
  onClick?: () => void;
  inLineCss?: Record<string, string>;
  onClose?: () => void;
  label?: string;
  showCloseIcon?: boolean;
};
export const ModalWrapper = ({
  children,
  inLineCss,
  onClose,
  label,
  showCloseIcon = false,
  ...rest
}: ModalWrapperProps) => {
  const modalRef = useFocusTrap();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (onClose) {
          onClose();
        }
      }
    };
    if (onClose) {
      document.addEventListener('keydown', handleKeyDown);
    }

    const rootWrapper = document.querySelector('[data-testid=root-wrapper]');
    if (rootWrapper) {
      // hide the main content from screen readers
      rootWrapper.setAttribute('aria-hidden', 'true');
      // prevent the main content from being focusable
      //`inert` isn't supported in Firefox hence the useTrapFocus hook
      rootWrapper.setAttribute('inert', 'true');
    }

    return () => {
      if (onClose) {
        document.removeEventListener('keydown', handleKeyDown);
      }

      if (rootWrapper) {
        rootWrapper.removeAttribute('aria-hidden');
        rootWrapper.removeAttribute('inert');
      }
    };
  }, [onClose]);

  return ReactDOM.createPortal(
    <S.StyledModalRoot
      role="dialog"
      aria-modal={true}
      aria-label={label ?? 'dialog view'}
      ref={modalRef}
      onClick={onClose}
    >
      <S.StyledModalContainer>
        <S.StyledModalContents
          inLineCss={inLineCss}
          onClick={(event) => event.stopPropagation()}
          {...rest}
        >
          {showCloseIcon && (
            <S.CloseIconButton
              onClick={onClose}
              data-testid="mw-close-icon-btn"
            >
              <X />
            </S.CloseIconButton>
          )}
          {children}
        </S.StyledModalContents>
      </S.StyledModalContainer>
    </S.StyledModalRoot>,
    document.body,
  );
};
