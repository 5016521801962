import { getPayrollReportPreviewBulk } from 'common/api/payrollTaxonomy.api';
import { PayrollReportDataCurrency } from 'common/types';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { payrollKeys } from '../keys';

export const usePayReportPreviewBulkQuery = (
  dataFrameIds: string[],
  dataCurrency: PayrollReportDataCurrency = PayrollReportDataCurrency.LOCAL,
  variancePayrollPeriod?: string,
  variancePayrollCycle?: string,
) => {
  return useQuery(
    payrollKeys.payReportPreviewBulk(
      dataFrameIds,
      dataCurrency,
      variancePayrollPeriod,
      variancePayrollCycle,
    ),
    () =>
      getPayrollReportPreviewBulk(
        dataFrameIds,
        dataCurrency,
        variancePayrollPeriod,
        variancePayrollCycle,
      ).then(getDataFromResponse),
    {
      enabled: !isEmpty(dataFrameIds),
      refetchOnWindowFocus: false,
    },
  );
};
