import {
  mockActivePayrollProvidersResponse,
  mockPayrollProvidersResponse,
} from '__mocks__/payrollProviders.mock';
import { HTTPStatusCodesEnum } from 'common/constants';
import {
  PayrollProviderApiResponse,
  PayrollProviderCreateApiRequest,
} from 'common/types';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

import { PAYROLL_PROVIDERS_BASE_API_ENDPOINT } from './payrollProviders.api';

export const payrollProvidersHandlers = [
  rest.get<PayrollProviderApiResponse[]>(
    composeEndpoint(PAYROLL_PROVIDERS_BASE_API_ENDPOINT),
    (_req, res, ctx) => {
      const isActive = _req.url.searchParams.get('isActive');
      if (isActive) {
        return res(
          ctx.status(HTTPStatusCodesEnum.OK),
          ctx.json(mockActivePayrollProvidersResponse),
        );
      }
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockPayrollProvidersResponse),
      );
    },
  ),
  rest.post<PayrollProviderCreateApiRequest>(
    composeEndpoint(PAYROLL_PROVIDERS_BASE_API_ENDPOINT),
    (_req, res, ctx) => {
      return res(ctx.status(201), ctx.json({}));
    },
  ),
  rest.get<PayrollProviderApiResponse>(
    composeEndpoint(`${PAYROLL_PROVIDERS_BASE_API_ENDPOINT}/:id`),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockActivePayrollProvidersResponse[0]),
      );
    },
  ),
  rest.put<PayrollProviderCreateApiRequest>(
    composeEndpoint(`${PAYROLL_PROVIDERS_BASE_API_ENDPOINT}/:id`),
    (_req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK));
    },
  ),
];

export const payrollProviderErrorHandler = rest.get(
  composeEndpoint(PAYROLL_PROVIDERS_BASE_API_ENDPOINT),
  (_req, res, ctx) => {
    return res.once(
      ctx.status(500),
      ctx.json({ error: 'Something went wrong' }),
    );
  },
);
