import { InvoicesLogsQueryOptions } from 'common/types';
import { DateTime } from 'luxon';

import {
  InitialInvoiceStatusType,
  SimpleInvoiceType,
} from '../pages/FinancePage/types';

export const allMockInvoiceLogs = [
  {
    id: 'fa49ab12-bc18-49a2-a549-9dba51a55822',
    employeeId: 'a82f09f2-834a-4bbf-9846-08999b90fe6a',
    employeeName: 'Employee Test 1',
    employeeCountry: 'France',
    companyId: 'jzgO9IZX6aFo',
    companyName: 'client 1',
    invoiceType: 'op:initial-salary-retainer',
    reason: 'Error sending initial client invoice to NetSuite',
    employeeProfileLink: null,
    triggeredBy: 'Omniplatform',
    status: 'FAILED',
    createdAt: '2022-01-02T23:50:26.087Z',
    simpleInvoice: {
      id: '6f892a59-1c1e-4d62-a2d1-816c6cf33e7c',
      employeeId: 'a82f09f2-834a-4bbf-9846-08999b90fe6a',
      totalAmount: null,
      currency: null,
      title:
        'Initial Salary Retainer Invoice for Employee: a82f09f2-834a-4bbf-9846-08999b90fe6a',
      description: null,
      attachmentUrls: [],
      lspId: null,
      type: 'op:initial-salary-retainer',
      error: 'Error sending initial client invoice to NetSuite',
      createdAt: '2022-01-02T23:50:26.087Z',
    },
    company: {
      id: 'jzgO9IZX6aFo',
      name: 'client 1',
      country: 'France',
      externalId: 'RTC',
      isVATExempt: false,
      fxConversionPercent: '2',
    },
  },
  {
    id: '8ddd3567-4997-4b24-b44f-1a0654686a2c',
    employeeId: 'a82f09f2-834a-4bbf-9846-08999b90fe6a',
    employeeName: 'Employee Test 1',
    employeeCountry: 'Spain',
    companyId: 'jzgO9IZX6aFo',
    companyName: 'client 2',
    invoiceType: 'op:initial-salary-retainer',
    reason: '',
    employeeProfileLink: null,
    triggeredBy: 'Omniplatform',
    status: 'SUCCESS',
    createdAt: '2022-09-02T23:50:25.333Z',
    simpleInvoice: {
      id: '0aeffd4a-9224-4bec-97bc-da2803349ca0',
      employeeId: 'a82f09f2-834a-4bbf-9846-08999b90fe6a',
      totalAmount: null,
      currency: null,
      title:
        'Initial Salary Retainer Invoice for Employee: a82f09f2-834a-4bbf-9846-08999b90fe6a',
      description: null,
      attachmentUrls: [],
      lspId: null,
      type: 'op:initial-salary-retainer',
      error: '',
      createdAt: '2022-09-02T23:50:25.333Z',
    },
    company: {
      id: 'jzgO9IZX6aFo',
      name: 'client 2',
      country: 'Spain',
      externalId: 'C2',
      isVATExempt: false,
      fxConversionPercent: '2',
    },
  },
  {
    id: '862de063-1ba9-469d-9328-cd324b23a1c5',
    employeeId: 'a82f09f2-834a-4bbf-9846-08999b90fe6a',
    employeeName: 'Employee Test 1',
    employeeCountry: 'Italy',
    companyId: 'jzgO9IZX6aFo',
    companyName: 'client 3',
    invoiceType: 'op:initial-salary-retainer',
    reason: '',
    employeeProfileLink: null,
    triggeredBy: 'Omniplatform',
    status: 'SUBMITTED',
    createdAt: '2022-09-02T23:50:23.667Z',
    simpleInvoice: {
      id: 'b2b80323-c31f-4c73-b725-534df5ab8948',
      employeeId: 'a82f09f2-834a-4bbf-9846-08999b90fe6a',
      totalAmount: null,
      currency: null,
      title:
        'Initial Salary Retainer Invoice for Employee: a82f09f2-834a-4bbf-9846-08999b90fe6a',
      description: null,
      attachmentUrls: [],
      lspId: null,
      type: 'op:initial-salary-retainer',
      error: '',
      createdAt: '2022-09-02T23:50:23.667Z',
    },
    company: {
      id: 'jzgO9IZX6aFo',
      name: 'client 3',
      country: 'Italy',
      externalId: 'C3',
      isVATExempt: false,
      fxConversionPercent: '2',
    },
  },
];

export const filterMockInvoiceLogs = (
  queryOptions: InvoicesLogsQueryOptions,
) => {
  return {
    items: allMockInvoiceLogs.filter((invoiceLog) => {
      return (
        (!queryOptions.statuses ||
          !(queryOptions.statuses.length > 0) ||
          queryOptions.statuses?.includes(
            invoiceLog.status as InitialInvoiceStatusType,
          )) &&
        (!queryOptions.invoiceTypes ||
          !(queryOptions.invoiceTypes.length > 0) ||
          queryOptions.invoiceTypes?.includes(
            invoiceLog.invoiceType as SimpleInvoiceType,
          )) &&
        (!queryOptions.fromDate ||
          DateTime.fromFormat(queryOptions.fromDate, 'yyyy-MM-dd').startOf(
            'day',
          ) <= DateTime.fromISO(invoiceLog.createdAt)) &&
        (!queryOptions.toDate ||
          DateTime.fromFormat(queryOptions.toDate, 'yyyy-MM-dd').endOf('day') >=
            DateTime.fromISO(invoiceLog.createdAt)) &&
        (!queryOptions.clientOrEmployeeNameQuery ||
          queryOptions.clientOrEmployeeNameQuery === invoiceLog.employeeName ||
          queryOptions.clientOrEmployeeNameQuery === invoiceLog.companyName)
      );
    }),
  };
};
