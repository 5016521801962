import { createPayrollProvider } from 'common/api/payrollProviders.api';
import { PayrollProviderCreateApiRequest } from 'common/types';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { lspsKeys } from '../../lsps/keys';

export const useCreatePayrollProviderMutation = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  return useMutation(
    (payrollProvider: PayrollProviderCreateApiRequest) =>
      createPayrollProvider(payrollProvider),
    {
      onError: (error) => {
        dispatch({ type: 'CREATE_PAYROLL_PROVIDER_ERROR', payload: error });
      },
      onSuccess: () => {
        queryClient.refetchQueries(lspsKeys.default);
      },
    },
  );
};
