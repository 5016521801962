import { Tag } from '@omnipresentgroup/design-system';

type OnboardingStatusProps = {
  status: string;
  totalStages?: number;
  latestInProgressStageId?: number;
};

type Intent = 'success' | 'warning' | 'error' | 'default' | undefined;

const getIntentByStatus = (status: string): Intent => {
  const statusIntent = {
    Active: 'success',
    Inactive: 'default',
    'In progress': 'warning',
  };

  const intent = statusIntent[status as keyof typeof statusIntent];
  return (intent as Intent) || 'default';
};

const getStatusWithProgress = (
  status: string,
  totalStages: number | undefined,
  latestInProgressStageId: number | undefined,
) => {
  const hasStageInProgressData =
    status === 'In progress' && !!totalStages && !!latestInProgressStageId;

  if (hasStageInProgressData) {
    return `${latestInProgressStageId}/${totalStages} ${status}`;
  }
};

export const OnboardingStatus = ({
  status,
  totalStages,
  latestInProgressStageId,
}: OnboardingStatusProps) => {
  const statusWithProgress = getStatusWithProgress(
    status,
    totalStages,
    latestInProgressStageId,
  );

  return (
    <Tag id={status} intent={getIntentByStatus(status)}>
      {statusWithProgress || status}
    </Tag>
  );
};
