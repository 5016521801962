import { Switch } from 'react-router-dom';

import { ErrorBoundaryFallback } from 'app/ErrorBoundaryFallback';
import { ability, AbilityContext } from 'app/store/ability';
import { AlertNotificationProvider } from 'common/contexts/alertNotificationCtx';
import ChangePassword from 'common/pages/ChangePassword/ChangePassword';
import Logout from 'common/pages/Logout/Logout';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';

import { omniConfig } from '@omnipresentgroup/design-system';

import { SentryRoute } from '../sentry';
import { GlobalStyle } from './App.styles';
import { AppContent } from './AppContent';
import HelmetHead from './Helmet';
import { queryClient } from './queryClient';
import { ReduxWithUser } from './ReduxWithUser';

export const App = () => {
  return (
    <HelmetProvider>
      <HelmetHead />
      <AlertNotificationProvider>
        <AbilityContext.Provider value={ability}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={omniConfig.theme}>
              <GlobalStyle />

              <Switch>
                <SentryRoute path="/e4c9a8c48948e0ef6ae40609483f8f4d.txt" />
                <SentryRoute path="/error" component={ErrorBoundaryFallback} />
                <SentryRoute
                  path="/change-pw"
                  component={ChangePassword}
                  doNotTrack
                />
                <SentryRoute component={Logout} path="/logout" />
                <SentryRoute path="/" doNotTrack>
                  <ReduxWithUser>
                    {/* @ts-ignore as the user will be provided*/}
                    <AppContent />
                  </ReduxWithUser>
                </SentryRoute>
              </Switch>
            </ThemeProvider>

            <ReactQueryDevtools
              position="bottom-right"
              toggleButtonProps={{ style: { right: 104, bottom: 8 } }}
            />
          </QueryClientProvider>
        </AbilityContext.Provider>
      </AlertNotificationProvider>
    </HelmetProvider>
  );
};
