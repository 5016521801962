import {
  Employer,
  EMPLOYER_TYPES_LABELS,
  EmployerType,
  FIELD_TYPE,
  FormConfig,
} from 'common/types';
import Joi from 'joi';
import { getCountryByCountryCode } from 'utils/countries';

import { StyledFormSectionTitle } from '../ProviderPage.styles';
import EmployerDropDown from './employerDropdown';
import { EmployerLspSelectionDropdown } from './employerLspSelectionDropdown';
import PayrollProviderDropdown from './payrollProviderDropdown';

const employerTypeOptions = Object.keys(EMPLOYER_TYPES_LABELS).map((value) => ({
  label: EMPLOYER_TYPES_LABELS[value as EmployerType],
  value,
}));

const validateParent: Joi.CustomValidator<Employer | null> = (
  value,
  { state, error },
) => {
  const [{ hasParent }] = state?.ancestors;
  return !value && hasParent ? error('any.custom') : value;
};

const ProviderSectionTitle = () => (
  <StyledFormSectionTitle colour="gray-500">Employer</StyledFormSectionTitle>
);

const ParentSectionTitle = () => (
  <StyledFormSectionTitle colour="gray-500">
    Parent entity
  </StyledFormSectionTitle>
);

const IsActiveSectionTitle = () => (
  <StyledFormSectionTitle colour="gray-500">
    Active employer
  </StyledFormSectionTitle>
);

export const employerFormConfig = (
  employer?: Employer,
  showCountryCode = false,
): FormConfig => ({
  formTitle: '',
  formCopy: '',
  formFields: [
    {
      name: 'name',
      label: 'Employer name',
      type: FIELD_TYPE.STRING,
      value: employer?.name || '',
      error: null,
      required: true,
      before: ProviderSectionTitle,
    },
    {
      name: 'country',
      label: 'Employer country',
      type: FIELD_TYPE.COUNTRY,
      value:
        (employer?.countryCode &&
          getCountryByCountryCode(employer?.countryCode)?.label) ||
        '',
      error: null,
      required: true,
    },
    {
      name: 'type',
      label: 'Entity type',
      type: FIELD_TYPE.DROPDOWN,
      options: employerTypeOptions,
      value: (employer?.type && EMPLOYER_TYPES_LABELS[employer.type]) || '',
      error: null,
      required: true,
    },
    {
      name: 'needGenerateLSP',
      label: 'Auto-generate associated LSP',
      type: FIELD_TYPE.CHECKBOX_BOOLEAN,
      value: !employer,
      hidden: !!employer,
      disabled: !!employer,
      error: null,
      required: false,
    },
    {
      name: 'lsp',
      label: 'Associated LSP',
      type: FIELD_TYPE.COMPONENT,
      component: EmployerLspSelectionDropdown,
      value: employer?.lspId,
      rules: {
        hidden: (_field, fields) => !!fields.needGenerateLSP.value,
        required: (_field, fields) => !fields.needGenerateLSP.value,
      },
      params: {
        employer,
      },
      error: null,
      required: false,
    },
    {
      name: 'isOmniEntity',
      label: 'Is OmniEntity',
      type: FIELD_TYPE.CHECKBOX_BOOLEAN,
      value: employer?.isOmniEntity || false,
      error: null,
      required: false,
    },
    {
      name: 'payrollProvider',
      label: 'Payroll provider',
      type: FIELD_TYPE.COMPONENT,
      component: PayrollProviderDropdown,
      value: {
        label: employer?.payrollProvider?.name,
        value: employer?.payrollProvider?.id,
      },
      error: null,
      required: false,
      rules: {
        hidden: (_field, fields) =>
          !fields.isOmniEntity.value || !fields.country.value,
        disabled: (_field, fields) => !fields.country.value,
      },
    },
    {
      name: 'hasParent',
      label: 'This employer has a parent',
      type: FIELD_TYPE.CHECKBOX_BOOLEAN,
      value: !!employer?.parent,
      error: null,
      required: false,
      before: ParentSectionTitle,
    },
    {
      name: 'parent',
      label: 'Parent legal entity',
      type: FIELD_TYPE.COMPONENT,
      component: EmployerDropDown,
      value: `${employer?.parent?.name} (${employer?.parent?.countryCode})`,
      error: null,
      required: false,
      rules: {
        hidden: (_field, fields) => !fields.hasParent.value,
      },
      params: { showCountryCode: showCountryCode, isActive: true },
    },
    {
      name: 'isActive',
      label: 'Requires payroll provider to be complete',
      type: FIELD_TYPE.TOGGLE,
      value: employer?.isActive || false,
      error: null,
      required: false,
      before: IsActiveSectionTitle,
    },
  ],
  schema: Joi.object().keys({
    name: Joi.string()
      .required()
      .messages({ 'string.empty': 'Please enter the employer name' }),
    country: Joi.string()
      .required()
      .messages({ 'string.empty': 'Please select a country' }),
    type: Joi.string()
      .valid(...Object.values(EMPLOYER_TYPES_LABELS))
      .required()
      .messages({ 'string.empty': 'Please select the type of employer' }),
    isOmniEntity: Joi.boolean().required(),
    needGenerateLSP: Joi.boolean().required(),
    hasParent: Joi.boolean(),
    parent: Joi.custom(validateParent).messages({
      'any.custom': 'Please select a parent entity',
    }),
    payrollProvider: Joi.object().allow(null),
    lsp: Joi.when('needGenerateLSP', {
      is: true,
      then: Joi.string().valid('', null),
      otherwise: Joi.alternatives()
        .try(Joi.object(), Joi.string())
        .required()
        .messages({
          'any.required': 'Please select an LSP',
        }),
    }),
    isActive: Joi.boolean(),
  }),
});
