import { mockMergeHRISEmployeesList } from '__mocks__/mergeHRIS.mock';
import { HTTPStatusCodesEnum } from 'common/constants';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { PathParams, rest } from 'msw';

import { Integration, MergeEmployeesListResponse } from '../types';
import {
  CreateMergeIntegrationInstallPayload,
  CreateMergeIntegrationStartPayload,
  INTEGRATIONS_BASE_API_ENDPOINT,
  MergeIntegrationStartResponse,
} from './integrations.api';

export const integrationsHandlers = [
  rest.post<
    CreateMergeIntegrationStartPayload,
    PathParams,
    MergeIntegrationStartResponse
  >(
    composeEndpoint(`${INTEGRATIONS_BASE_API_ENDPOINT}/merge/start`),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          integrationId: 'fake-integration-id',
          linkToken: 'fake-link-token',
        }),
      );
    },
  ),
  rest.post<CreateMergeIntegrationInstallPayload, PathParams, Integration>(
    composeEndpoint(`${INTEGRATIONS_BASE_API_ENDPOINT}/merge/install`),
    (_req, res, ctx) => {
      return res(
        ctx.status(201),
        ctx.json({
          name: 'BambooHR',
          categories: ['hris'],
          provider: 'merge',
          image:
            'https://merge-api-production.s3.amazonaws.com/media/bamboo_hr_logo.png',
          square_image:
            'https://merge-api-production.s3.amazonaws.com/media/BambooHR_Square_Logo.jpg',
          color: '#73C41D',
          slug: 'bamboohr',
          integrationId: 'fake-integration-id',
        }),
      );
    },
  ),
  rest.delete(
    composeEndpoint(`${INTEGRATIONS_BASE_API_ENDPOINT}/merge/install`),
    (_req, res, ctx) => {
      return res(ctx.status(204));
    },
  ),
  rest.get<MergeEmployeesListResponse, { companyId: string }>(
    composeEndpoint(
      `${INTEGRATIONS_BASE_API_ENDPOINT}/merge/:integrationId/employees`,
    ),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockMergeHRISEmployeesList),
      );
    },
  ),
];
