import appHistory from 'app/appHistory';
import { AxiosError } from 'axios';
import {
  downloadExpenses,
  downloadExpenseZipFile,
} from 'common/api/expense.api';
import { ONE_MINUTE } from 'common/constants';
import { ExpenseFromAPI } from 'common/types/expenses';
import { getManagersEmployeesExpensesRequest } from 'omniplatform/manager/api/managerEmployeesExpenses.api';
import { EXPENSE_LIST_PAGE } from 'paths';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import type { IsoDateTime } from '@omnipresentgroup/design-system';

import {
  expenseCsvKeys,
  expenseIdsKeys,
  expensesKeys,
  expenseZipDownloadKeys,
} from '../keys';

export const useCompanyExpensesQuery = (
  companyId: string,
  {
    queryOptions,
  }: {
    queryOptions?: Pick<UseQueryOptions, 'staleTime' | 'cacheTime' | 'enabled'>;
  } = {},
): UseQueryResult<{ data: ExpenseFromAPI[] }, AxiosError> => {
  return useQuery(
    expensesKeys.multiple(companyId),
    () => getManagersEmployeesExpensesRequest(companyId),
    {
      staleTime: queryOptions?.staleTime || ONE_MINUTE,
      ...(queryOptions || {}),
      enabled: !!companyId,
    },
  );
};

export const useDownloadExpensesToCsvQuery = ({
  expenseIds = [],
  onSuccess = () => {
    // intentionally left blank
  },
  approverId,
}: {
  expenseIds?: string[];
  onSuccess: (data: string) => void;
  approverId: string;
}) => {
  return useQuery(
    expenseCsvKeys.multiple(approverId, expenseIds),
    () =>
      downloadExpenses({
        expenseIds,
        format: 'csv',
        approverId,
      }).then(getDataFromResponse),
    {
      enabled: false,
      onSuccess,
    },
  );
};

export const useDownloadExpensesWithAttachmentsQuery = ({
  expenseIds = [],
  onSuccess = () => {
    // intentionally left blank
  },
  approverId,
}: {
  expenseIds?: string[];
  onSuccess: () => void;
  approverId: string;
}) => {
  return useQuery(
    expenseIdsKeys.multiple(expenseIds || [], approverId),
    () =>
      downloadExpenses({
        expenseIds,
        format: 'csv',
        includeAttachments: true,
        approverId,
      }).then(getDataFromResponse),
    {
      enabled: false,
      onSuccess,
    },
  );
};

export const useDownloadExpensesZipFileQuery = (
  approverId: string,
  timestamp: IsoDateTime,
) => {
  return useQuery(
    expenseZipDownloadKeys.multiple(approverId, timestamp),
    () =>
      downloadExpenseZipFile(approverId, timestamp).then(getDataFromResponse),
    {
      onSuccess: ({ url }) => {
        window.open(url, '_self');
        appHistory.push(`${EXPENSE_LIST_PAGE}`);
      },
    },
  );
};
