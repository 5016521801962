export const additionalAdminRoleNames = [
  'viewer:clients-sensitive-data', // Mostly, Customer Success people
  'viewer:omnipresent-sensitive-data', // Mostly, Omnipresent HR
  'payroll',
  'finance',
  'international-expansion',
  'benefits-specialist',
] as const;

export type AdditionalAdminRoleType = (typeof additionalAdminRoleNames)[number];

export const roleNames = [
  'admin',
  ...additionalAdminRoleNames,
  'manager',
  'employee',
  'lsp',
  'contractor',
] as const;
export type UserRoleType = (typeof roleNames)[number];

export enum UserRoleTypeEnum {
  CS = 'viewer:clients-sensitive-data',
  OMNI_HR = 'viewer:omnipresent-sensitive-data',
  PAYROLL = 'payroll',
  FINANCE = 'finance',
  INTERNATIONAL_EXPANSION = 'international-expansion',
  BENEFITS_SPECIALIST = 'benefits-specialist',
  ADMIN = 'admin',
  MANAGER = 'manager',
  EMPLOYEE = 'employee',
  LSP = 'lsp',
  CONTRACTOR = 'contractor',
}

export type User = {
  userId: string;
  authId: string;
  firstName: string;
  lastName: string;
  displayName: string;
  name: string;
  email: string;
  temporaryPassword: string;
  roles: UserRole[];
  seenWelcomeScreen: boolean;
  seenPrivacyPolicy: string;
  sentInvitationAt: string;
  createdAt: string;
  role?: UserRole;
};

export type UserRole = {
  id?: string;
  tenantId?: string;
  type: UserRoleType;
  scopes?: string[];
};

export type LspUser = Omit<User, 'role'> & {
  id: string;
  role: string;
};

export const userRoleTypeToFriendlyName: Record<UserRoleType, string> = {
  admin: 'Admin',
  manager: 'Client Company Manager',
  lsp: 'LSP User',
  employee: 'Client Employee',
  'viewer:omnipresent-sensitive-data': 'Omnipresent People Team Manager',
  'viewer:clients-sensitive-data': 'Customer Success Manager',
  'benefits-specialist': 'Benefits Specialist',
  payroll: 'Payroll manager',
  'international-expansion': 'International Expansion Lead',
  finance: 'Finance',
  contractor: 'Contractor',
};

export enum LSPUserActionEnum {
  DELETE_MANAGER = 'Delete',
}

export const LSPUserActions = Object.values(LSPUserActionEnum);
