import { appReducer } from 'app/store/reducers/app.reducer';
import { documentsReducer } from 'common/store/reducers/documents.reducer';
import { documentStorageReducer } from 'common/store/reducers/documentStorage.reducer';
import { employeesReducer } from 'common/store/reducers/employee.reducer';
import { combineReducers } from 'redux';

import { expensesReducer } from './expenses.reducer';

const employeeRootReducer = combineReducers({
  appReducer,
  employeesReducer,
  expensesReducer,
  documentStorageReducer,
  documentsReducer,
});

export default employeeRootReducer;
