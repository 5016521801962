import isEmpty from 'lodash/isEmpty';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';

export const getEmployeeStartDate = ({
  employmentDetails,
  contractQuestionnaire,
}: {
  employmentDetails?: any;
  contractQuestionnaire?: any;
}) => {
  const hasEmploymentDetails = !!employmentDetails;
  if (hasEmploymentDetails) {
    const hasStartDateInDetails = !isEmpty(employmentDetails?.startDate);

    if (hasStartDateInDetails) {
      return formatISODate(
        employmentDetails.startDate,
        DateFormats.Date,
        true,
        '-',
      );
    }
  }
  const startDate = { ...contractQuestionnaire?.body.startDate };
  return formatISODate(startDate.value, DateFormats.Date, true, '-');
};
