import paginatedBillingItems from 'omniplatform/admin/__mocks__/paginatedBillingItems.json';
import { v4 as uuidv4 } from 'uuid';

export const getMockedPaginatedBillingItems = () => ({
  nextPageKey: paginatedBillingItems.nextPageKey,
  items: paginatedBillingItems.items.map((item) => ({
    ...item,
    id: uuidv4(),
  })),
});
