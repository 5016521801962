import { AlertTriangle, CheckCircle, X } from 'react-feather';
import styled, { css } from 'styled-components';

export const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-content: stretch space-between;
  height: 'max';
  padding: ${({ theme: { space } }) =>
    `0 ${space[64]} ${space[32]} ${space[64]}`};
`;

export const StyledTitle = styled.h3`
  margin: ${({ theme: { space } }) => `${space[64]} 0 ${space[32]} 0`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  color: ${({ theme: { colors } }) => colors['text-default']};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
`;

export const StyledCloseButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledX = css`
  display: flex;
  position: absolute;
  background: none;
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  box-shadow: none;
  color: ${({ theme: { colors } }) => colors['icon-default']};
  cursor: pointer;
  font-family: ${({ theme: { fonts } }) => fonts.default};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  min-height: ${({ theme: { space } }) => space[16]};
  opacity: 1;
  padding: ${({ theme: { space } }) => `${space[8]}`};
  width: fit-content;
`;

export const StyledCloseButton = styled(X)`
  ${StyledX}
`;

export const StyledManagerInfoContainer = styled.div`
  display: flex;
`;

export const StyledNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
`;

export const StyledRoleContainer = styled(StyledNameContainer)`
  margin-left: ${({ theme: { space } }) => space[32]};
`;

export const StyledManagerHeaderContainer = styled.div`
  margin: ${({ theme: { space } }) =>
    `${space[32]} ${space[0]} ${space[8]} ${space[0]}`};
  color: ${({ theme: { colors } }) => colors['text-subtle']};
`;

export const StyledManagerDetailsContainer = styled.div`
  color: ${({ theme: { colors } }) => colors['text-default']};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
`;

export const StyledButtonContainer = styled.div`
  padding: ${({ theme: { space } }) => space[8]};
  margin: ${({ theme: { space } }) => space[16]};
  display: flex;
  justify-content: space-between;
`;

export const StyledSelectContainer = styled.div`
  margin-top: ${({ theme: { space } }) => space[64]};
`;

export const StyledNoteTextContainer = styled.div`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
  margin-top: ${({ theme: { space } }) => `${space[16]}`};
`;

export const StyledMessageModal = styled.div`
  display: flex;
  min-height: 40vh;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const MessageTextContainer = styled.div`
  margin-bottom: ${({ theme: { space } }) => `${space[64]}`};
`;

export const StyledIcon = css`
  display: flex;
  align-self: center;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[6]};
  padding: ${({ theme: { space } }) => `${space[8]}`};
`;

export const StyledSuccessIcon = styled(CheckCircle)`
  ${StyledIcon}
  color: ${({ theme: { colors } }) => colors['icon-positive']};
  width: 50px;
  height: 50px;
`;

export const StyledErrorIcon = styled(AlertTriangle)`
  ${StyledIcon}
  color: ${({ theme: { colors } }) => colors['icon-negative']};
  padding-bottom: ${({ theme: { space } }) => `${space[64]}`};
  width: 60px;
  height: 60px;
`;
