import { SetStateAction, useState } from 'react';

import appHistory from 'app/appHistory';
import { Loading } from 'common/components';
import { StyledErrorMessage } from 'common/components/atoms/ErrorMessage';
import { DatePicker } from 'common/components/molecules/DatePicker/DatePicker';
import EditableInput from 'common/components/molecules/EditableInput/EditableInput';
import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { getMonthInString } from 'common/helpers/month';
import { PayrollInstanceApiRequest } from 'common/types';
import get from 'lodash/get';
import { PAYROLL_INSTANCE_DETAIL_PAGE } from 'paths';
import { useCreateWorkbookMutation } from 'utils/queries/payroll/useCreateWorkbookMutation';

import { Button, Notification, Spinner } from '@omnipresentgroup/design-system';

import {
  CancelButton,
  CloseButton,
  CloseIcon,
  StyledButtonContainer,
  StyledContainer,
  StyledContentContainer,
  StyledInputContainer,
  StyledMainContentContainer,
  StyledModalContainer,
  StyledTitle,
} from './PayrollInstanceModal.styles';

export type PayrollInstanceModalProps = {
  clickHandler: () => void;
};

export const PayrollInstanceModal = ({
  clickHandler,
}: PayrollInstanceModalProps) => {
  const currentDate = new Date();
  const [selectedDate, updateSelectedDate] = useState(currentDate);
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState(
    `${getMonthInString(
      currentDate.getMonth() + 1,
    )} ${currentDate.getFullYear()} - Workbook`,
  );

  const createPayrollInstanceMutation = useCreateWorkbookMutation();

  const handleCreatePayrollInstance = () => {
    const payload: PayrollInstanceApiRequest = {
      name,
      month: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
    };

    createPayrollInstanceMutation.mutate(payload, {
      onError: (err) => {
        const responseData = get(err, 'response.data');
        setErrorMessage(responseData?.message || '');
      },
      onSuccess: (response) => {
        appHistory.push(PAYROLL_INSTANCE_DETAIL_PAGE(response.data.id));
      },
    });
  };

  const onDateChanged = (changedDate: Date) => {
    updateSelectedDate(changedDate);
  };

  return (
    <StyledContainer data-testid="payroll-instance-modal">
      <ModalWrapper fixedWidth="650">
        <StyledModalContainer>
          <StyledContentContainer>
            <div>
              <StyledTitle>Create Workbook</StyledTitle>
            </div>
            <div>
              <CloseButton
                data-testid="close-modal-button"
                onClick={clickHandler}
              >
                <CloseIcon />
              </CloseButton>
            </div>
          </StyledContentContainer>
          {createPayrollInstanceMutation.isError && errorMessage && (
            <Notification
              id="create-payroll-instance-error"
              intent="error"
              title={errorMessage}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dismissible
            ></Notification>
          )}
          <StyledMainContentContainer>
            <div>
              <p>Month</p>
              <DatePicker
                fallback={<Spinner size="16" />}
                selected={selectedDate}
                onChange={onDateChanged}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                className="month-picker-input"
                name="month-picker-input"
                placeholderText="Select a Month"
              />
            </div>
            <StyledInputContainer data-testid="payroll-instance-name">
              <EditableInput
                name="payroll-instance-name"
                onChange={(_: any, newValue: SetStateAction<string>) => {
                  setName(newValue);
                }}
                type="string"
                label="Workbook Name"
                small={false}
                oneInputPerRow={true}
                value={name}
              />
              {name?.length <= 0 && (
                <StyledErrorMessage data-testid="payroll-instance-name-error">
                  Workbook Name is required
                </StyledErrorMessage>
              )}
            </StyledInputContainer>
          </StyledMainContentContainer>
          <StyledButtonContainer>
            <CancelButton variant="secondary" onClick={clickHandler}>
              Cancel
            </CancelButton>
            <Button
              variant="primary"
              onClick={handleCreatePayrollInstance}
              disabled={createPayrollInstanceMutation.isLoading}
            >
              Confirm
            </Button>
          </StyledButtonContainer>
          {createPayrollInstanceMutation.isLoading && <Loading />}
        </StyledModalContainer>
      </ModalWrapper>
    </StyledContainer>
  );
};
