import { useCallback } from 'react';

import { GET_IS_TEST_ENV, GET_PROD_STAGE, IS_DEV_ENV } from 'common/constants';
import mixpanel, { Dict, OverridedMixpanel } from 'mixpanel-browser';

import { getMixpanelToken } from './utils';

export const initTracker = () => {
  const token = getMixpanelToken();
  if (!!token && GET_PROD_STAGE()) {
    mixpanel.init(token, {
      debug: IS_DEV_ENV,
      ip: false,
      // Mixpanel cookies will only be transmitted over https
      secure_cookie: true,
    });
  }
};

const noop = () => {
  // intentionally left blank
};

const excludeLogsFromTestsTracker = {
  track: noop,
  identify: noop,
  register: noop,
  people: { set_once: noop },
};

const consoleTracker = {
  ...(GET_IS_TEST_ENV()
    ? excludeLogsFromTestsTracker
    : {
        track: (evt, props) => console.log(`[MIXPANEL] Track: ${evt}`, props),
        identify: (unique_id) =>
          console.log(`[MIXPANEL] Identify: ${unique_id}`),
        register: (props) => console.log('[MIXPANEL] Register:', props),
        people: {
          set_once: (props) => console.log('[MIXPANEL] Set once:', props),
        },
      }),
} as OverridedMixpanel;

export const getTracker = () =>
  !!getMixpanelToken && GET_PROD_STAGE() ? mixpanel : consoleTracker;

export const useTrack = () =>
  useCallback<(event: string, args?: Dict) => void>((event, args) => {
    const tracker = getTracker();
    if (!GET_IS_TEST_ENV()) {
      tracker.track(event, args);
    }
  }, []);
