import { ReactComponent as DownloadIcon } from 'assets/icon/Download.svg';
import { ReactComponent as PencilIcon } from 'assets/icon/WhitePencil.svg';
import { ReactComponent as AddIcon } from 'assets/icon/WhitePlus.svg';
import PropTypes from 'prop-types';

import { StyledFloatingActionButton } from './FloatingActionButton.styles';

const typeToIcon = {
  add: <AddIcon data-testid="add-icon" />,
  edit: <PencilIcon data-testid="edit-icon" />,
  download: <DownloadIcon data-testid="download-icon" />,
};

const FloatingActionButton = ({ type, onClick, disabled }) => {
  return (
    <StyledFloatingActionButton onClick={onClick} disabled={disabled}>
      {typeToIcon[type] || <></>}
    </StyledFloatingActionButton>
  );
};

FloatingActionButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

FloatingActionButton.defaultProps = {
  type: 'add',
  onClick: () => {},
  disabled: false,
};

export default FloatingActionButton;
