export interface ManagerDetail {
  editable: boolean;
  required: boolean;
  label: string;
  name: string;
  showFor: string;
  type: string;
  value?: string;
}
export interface Managers {
  administrativeInformation: Record<string, any>[];
  displayName: string;
  email: string;
  name: string;
  role: { type: string; tenantId: string };
  roles: Record<string, any>[];
  id?: string;
  userDetails: ManagerDetail[];
}

export enum ManagerActionEnum {
  DELETE = 'Delete',
}

export const ManagerActions = Object.values(ManagerActionEnum);
