import PropTypes from 'prop-types';
import { onWheelPreventChangeForNumberInput } from 'utils/input.helper';

import {
  StyledAmountInput,
  StyledCurrencyInput,
  StyledCurrencyTimePeriodInputContainer,
  StyledTimePeriodInput,
} from './CurrencyTimePeriodInput.styles';

const CurrencyTimePeriodInput = ({
  name,
  value,
  onChange,
  editable,
  errorMessage,
}) => {
  return (
    <StyledCurrencyTimePeriodInputContainer>
      <StyledCurrencyInput
        data-testid={`${name}-currencyTimePeriod-currency`}
        value={value.currency}
        name={`${name}timePeriodCurrencyCurrency`}
        disabled={!editable}
        editable={editable}
        onChange={({ target: { value: newValue } }) =>
          onChange(name, {
            ...value,
            currency: newValue,
          })
        }
        error={errorMessage}
        placeholder="Currency"
      />
      <StyledAmountInput
        type="number"
        data-testid={`${name}-currencyTimePeriod-amount`}
        value={value.amount || ''}
        name={`${name}timePeriodCurrencyAmount`}
        disabled={!editable}
        editable={editable}
        onChange={({ target: { value: newValue, type } }) =>
          onChange(name, {
            ...value,
            amount: type === 'number' ? parseInt(newValue, 10) : newValue,
          })
        }
        error={errorMessage}
        placeholder="Amount"
        onWheel={onWheelPreventChangeForNumberInput}
      />
      <StyledTimePeriodInput
        type="string"
        data-testid={`${name}-currencyTimePeriod-timePeriod`}
        value={value.timePeriod || ''}
        name={`${name}timePeriodCurrencyTimePeriod`}
        disabled={!editable}
        editable={editable}
        onChange={({ target: { value: newValue } }) =>
          onChange(name, {
            ...value,
            timePeriod: newValue,
          })
        }
        error={errorMessage}
        placeholder="Time period"
      />
    </StyledCurrencyTimePeriodInputContainer>
  );
};

CurrencyTimePeriodInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  errorMessage: PropTypes.string,
};

CurrencyTimePeriodInput.defaultProps = {
  onChange: () => {},
  editable: false,
  errorMessage: null,
};

export default CurrencyTimePeriodInput;
