import { useEffect, useState } from 'react';

import appHistory from 'app/appHistory';
import { EmployeeDocumentDetails } from 'common/components/molecules/EmployeeDocumentDetails/EmployeeDocumentDetails';
import { downloadDocumentAction } from 'common/store/actions/documents.actions';
import { Document, Seat } from 'common/types';
import { EMPLOYEES_PATH } from 'paths';
import { useDispatch } from 'react-redux';
import { toTitleCase } from 'utils/string-utils';

import { DateFormats, formatJSDate } from '@omnipresentgroup/design-system';

export const EmployeeOrderFormCard = ({
  employeeId,
  seat,
  orderFormDocument,
}: {
  employeeId: string;
  seat: Seat;
  orderFormDocument: Document | undefined;
}) => {
  const [hasSignByUser, updateHasSignByUser] = useState<boolean>(false);

  const signedBy = seat.sowSignature?.signedBy ?? '';

  const dispatch = useDispatch();

  const handleLinkClick = () => {
    if (seat.isAutomatedUpsellUsed) {
      return appHistory.push(
        `${EMPLOYEES_PATH}/${employeeId}/${seat.id}/order-form`,
      );
    } else if (orderFormDocument) {
      dispatch(downloadDocumentAction(orderFormDocument.id));
    } else {
      window.open(seat.sowDocUrl, '_self');
    }
  };

  useEffect(() => {
    const isValidSignature =
      signedBy && !signedBy.match(/unknown user|not found/i);
    updateHasSignByUser(!!isValidSignature);
  }, [seat, signedBy]);

  if (!seat.isAutomatedUpsellUsed && !seat.sowDocUrl) {
    return null;
  }

  const signature =
    !seat.isAutomatedUpsellUsed && hasSignByUser && signedBy
      ? `, by ${toTitleCase(signedBy)}`
      : '';

  const signedDate = getSignedDate(seat.sowSignature?.date);

  return (
    <EmployeeDocumentDetails
      heading="Employee order form"
      label={
        seat.isAutomatedUpsellUsed ? 'View order form' : 'Download order form'
      }
      onClick={handleLinkClick}
      subHeading={`Accepted on ${signedDate || ``}${signature}`}
    />
  );
};

export const getSignedDate = (signedDate: string | undefined) =>
  typeof signedDate !== 'undefined'
    ? formatJSDate(new Date(signedDate), DateFormats.Date)
    : signedDate;
