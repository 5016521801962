import {
  mockEmployeesExpenses,
  mockManagerExpenseCsv,
} from '__mocks__/employeesExpenses.mock';
import { ExpenseStatusEnum } from 'common/components/molecules/ExpenseStatus/ExpenseStatus';
import { HTTPStatusCodesEnum } from 'common/constants';
import {
  BulkUpdateExpensesRequest,
  ExpenseFromAPI,
  SimplifiedExpense,
} from 'common/types';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

export const DEFAULT_EXPENSE = mockEmployeesExpenses[0];

export const simplifyExpense = ({
  dateSpent,
  employeeName,
  status,
  category,
  amount,
  approverName,
  id,
  title,
}: ExpenseFromAPI): SimplifiedExpense => ({
  dateSpent,
  employeeName,
  status,
  category,
  amount,
  approverName,
  id,
  title,
});

export const DEFAULT_SIMPLIFIED_EXPENSE = simplifyExpense(DEFAULT_EXPENSE);

export const generateSimpleExpense = (
  override = {} as Partial<ExpenseFromAPI>,
) => ({
  ...DEFAULT_SIMPLIFIED_EXPENSE,
  ...override,
});

export const generateExpense = (override = {} as Partial<ExpenseFromAPI>) => ({
  ...DEFAULT_EXPENSE,
  ...override,
});

export const mockExpenseCreated = {
  message: 'expenses entity created',
  id: 'c5ef29ac-1039-45d5-934c-ef2f7513b4a8',
  url: '/expenses/c5ef29ac-1039-45d5-934c-ef2f7513b4a8',
};

export const PatchExpensesKOResponse = [
  {
    success: false,
    error: {
      name: 'ForbiddenError',
      status: HTTPStatusCodesEnum.FORBIDDEN,
    },
    result: {
      expenseId: mockEmployeesExpenses[5].id,
      approved: true,
    },
  },
];

export const PatchExpensesMixedResponse = [
  {
    success: true,
    result: {
      ...mockEmployeesExpenses[2],
    },
  },
  {
    success: false,
    error: {
      name: 'ForbiddenError',
      status: 403,
    },
    result: {
      expenseId: mockEmployeesExpenses[5].id,
      approved: true,
    },
  },
];

export const expenseHandlers = [
  rest.get(composeEndpoint('/expenses/:expenseId'), (_req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(DEFAULT_EXPENSE)),
  ),
  rest.patch<BulkUpdateExpensesRequest>(
    composeEndpoint('/expenses'),
    async (req, res, ctx) => {
      const body: BulkUpdateExpensesRequest = await req.json();
      const expenseId = body.expenses[0].expenseId;
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json([
          {
            success: true,
            result: {
              ...mockEmployeesExpenses.find(
                (expense) => expense.id === expenseId,
              ),
              status: ExpenseStatusEnum.APPROVED,
            },
          },
        ]),
      );
    },
  ),
  rest.patch(composeEndpoint('/expenses/:expenseId'), (_req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.OK)),
  ),
  rest.delete(composeEndpoint('/expenses/:expenseId'), (_req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.NO_CONTENT)),
  ),
  rest.get(composeEndpoint('/expenses/employee/:expenseId'), (_req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json([DEFAULT_EXPENSE])),
  ),
  rest.get(composeEndpoint('/expenses/approver/:managerId'), (_req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockEmployeesExpenses)),
  ),
  rest.post(composeEndpoint('/expenses/export'), (req, res, ctx) => {
    const body = req.body as Record<string, any>;
    if (body.includeAttachments) {
      return res(ctx.status(HTTPStatusCodesEnum.ACCEPTED), ctx.json({}));
    }
    if (body.exportFormat === 'csv') {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.text(mockManagerExpenseCsv),
      );
    }

    return res(
      ctx.status(HTTPStatusCodesEnum.OK),
      ctx.json(mockEmployeesExpenses),
    );
  }),

  rest.post(composeEndpoint('expenses'), (_req, res, ctx) =>
    res(
      ctx.status(HTTPStatusCodesEnum.RESOURCE_CREATED),
      ctx.json(mockExpenseCreated),
    ),
  ),
];
