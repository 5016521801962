import { getGrossToNetManualView } from 'common/api/payrollTaxonomy.api';
import { useQuery } from 'react-query';
import { payrollKeys } from 'utils/queries/payroll/keys';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useGrossToNetManualViewQuery = (payrollPeriod: string) => {
  return useQuery(payrollKeys.grossToNetManualView(payrollPeriod), async () =>
    getGrossToNetManualView({
      payrollPeriod,
    }).then(getDataFromResponse),
  );
};
