import { useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import EmployeesChecklist from 'common/components/molecules/EmployeeChecklist/EmployeeChecklist';

const EmployeeCheckListPage = () => {
  const { companyId, seatId } = useParams();
  return (
    <PageContent data-testid="employee-checklist-page">
      <EmployeesChecklist companyId={companyId} seatId={seatId} />
    </PageContent>
  );
};

export default EmployeeCheckListPage;
