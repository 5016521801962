import { HTTPStatusCodesEnum } from 'common/constants';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

export const financeHandlers = [
  rest.post(
    composeEndpoint('/payroll/invoice-logs/export'),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({ data: { url: 'mockSimpleInvoiceLogsCSVDownloadLink' } }),
      );
    },
  ),
];
