import { getEmployeeNetsuiteIDRequest } from 'common/api/employees.api';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useClientEmployeeNetsuiteMutation = () => {
  const RETRY_TIMES_THREE = 3;
  return useMutation(
    ({ employeeId }: { employeeId: string }) =>
      getEmployeeNetsuiteIDRequest(employeeId).then(getDataFromResponse),
    {
      retry: RETRY_TIMES_THREE,
    },
  );
};
