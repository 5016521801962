import { useEffect, useState } from 'react';

import { TransformedUser } from 'app/store/app.transformers';
import { getEmployeeSelector } from 'omniplatform/employee/store/selectors/employee.selector';
import { useSelector } from 'react-redux';
import { isEmployee } from 'utils/user';

export const useEmployeeGetStarted = (user: TransformedUser) => {
  const [showEmployeeGetStarted, setShowEmployeeGetStarted] = useState(false);
  const [isPrimerModalOpen, setIsPrimerModalOpen] = useState(true);
  const selectedEmployee = useSelector(getEmployeeSelector);

  useEffect(() => {
    if (isEmployee(user) && selectedEmployee?.requireTypeFormFilled) {
      setShowEmployeeGetStarted(true);
    }
  }, [user, selectedEmployee?.requireTypeFormFilled]);

  return {
    showEmployeeGetStarted,
    isPrimerModalOpen,
    setIsPrimerModalOpen,
    countryOfWork: selectedEmployee?.country,
    company: selectedEmployee?.companyName,
  };
};
