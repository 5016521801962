import {
  EmployeeTimesheetResponse,
  PaginatedCompanyTimesheetsApiResponse,
} from 'common/types';

const mockCompanyEmployeesTimesheets: EmployeeTimesheetResponse[] = [
  {
    employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
    yearMonth: '2022-11',
    totalMonthWorkingHours: '112',
    timesheetData: {
      workingDays: [
        {
          breakStartTime: '13:00',
          startTime: '09:00',
          dayOfWeek: 'Monday',
          endTime: '17:00',
          breakEndTime: '14:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '09:00',
          dayOfWeek: 'Tuesday',
          endTime: '17:00',
          breakEndTime: '14:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '09:00',
          dayOfWeek: 'Wednesday',
          endTime: '17:00',
          breakEndTime: '14:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '09:00',
          dayOfWeek: 'Friday',
          endTime: '17:00',
          breakEndTime: '14:00',
        },
      ],
      overtime: [],
      timeoff: [],
    },
  },
  {
    employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
    yearMonth: '2022-10',
    totalMonthWorkingHours: '40',
    timesheetData: {
      workingDays: [
        {
          breakStartTime: '13:00',
          startTime: '9:00',
          breakEndTime: '14:00',
          dayOfWeek: 'Monday',
          endTime: '17:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '9:00',
          breakEndTime: '14:00',
          dayOfWeek: 'Tuesday',
          endTime: '17:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '9:00',
          breakEndTime: '14:00',
          dayOfWeek: 'Wednesday',
          endTime: '17:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '9:00',
          breakEndTime: '14:00',
          dayOfWeek: 'Friday',
          endTime: '17:00',
        },
      ],
      overtime: [],
      timeoff: [],
    },
    submittedDate: '2022-11-01',
    approvedDate: '2022-11-01',
  },
  {
    employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8b9y3',
    yearMonth: '2022-10',
    totalMonthWorkingHours: '36',
    timesheetData: {
      workingDays: [
        {
          breakStartTime: '13:00',
          startTime: '9:00',
          breakEndTime: '14:00',
          dayOfWeek: 'Monday',
          endTime: '17:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '9:00',
          breakEndTime: '14:00',
          dayOfWeek: 'Tuesday',
          endTime: '17:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '9:00',
          breakEndTime: '14:00',
          dayOfWeek: 'Wednesday',
          endTime: '17:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '9:00',
          breakEndTime: '14:00',
          dayOfWeek: 'Friday',
          endTime: '17:00',
        },
      ],
      overtime: [],
      timeoff: [],
    },
    submittedDate: '2022-11-01',
    approvedDate: '2022-11-01',
  },
];

export const mockPaginatedCompanyTimesheetsApiResponse: PaginatedCompanyTimesheetsApiResponse =
  {
    items: mockCompanyEmployeesTimesheets,
    nextPageKey: 'mockNextPageKey',
  };
