import PropTypes from 'prop-types';

import {
  StyledProgressIndicatorBar,
  StyledProgressIndicatorContainer,
} from './ProgressIndicator.styles';

const ProgressIndicator = ({ totalStages, currentStage }) => {
  const percentageComplete = () => {
    const progress = Math.floor((currentStage / totalStages) * 100);
    if (progress > 100) {
      return 100;
    }
    return progress;
  };

  return (
    <StyledProgressIndicatorContainer>
      <StyledProgressIndicatorBar
        progress={percentageComplete()}
        data-testid="progress-bar"
      />
    </StyledProgressIndicatorContainer>
  );
};

ProgressIndicator.propTypes = {
  totalStages: PropTypes.number.isRequired,
  currentStage: PropTypes.number.isRequired,
};

export default ProgressIndicator;
