import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { subject } from '@casl/ability';
import { sendPlatformInvitationRequest } from 'app/api/user.api';
import { PageContent } from 'app/App.styles';
import { ability } from 'app/store/ability';
import { ErrorBanner, Loading, PageHeader } from 'common/components';
import FloatingActionButton from 'common/components/atoms/FloatingActionButton/FloatingActionButton';
import DetailsCard from 'common/components/organisms/DetailsCard/DetailsCard';
import { User, userRoleTypeToFriendlyName } from 'common/types';
import { ADMIN_LIST_PAGE } from 'paths';
import { useMutation } from 'react-query';
import { useUserQuery } from 'utils/queries/users';

import { Viewport } from '@omnipresentgroup/design-system';

type buttonConfigType = {
  text: string;
  type: string;
  clickPath: string;
  disabled: boolean;
};

const adminUserDetailsSection = (user: User) => [
  {
    name: 'user-details',
    title: 'Details',
    details: [
      {
        label: 'Name',
        value: user.name,
        type: 'string',
      },
      {
        label: 'Email',
        value: user.email,
        type: 'string',
      },
      {
        label: 'Additional admin roles',
        displayValue: user.roles
          .filter((role) => role.type !== 'admin')
          .map((role) => userRoleTypeToFriendlyName[role.type])
          .join(', '),
        type: 'string',
      },
    ],
  },
  {
    name: 'administrative-details',
    title: 'Administrative Details',
    details: [
      {
        label: 'Platform invitation',
        value: user.seenPrivacyPolicy
          ? 'ACCEPTED'
          : user.sentInvitationAt && 'SENT',
        type: 'invitation',
      },
      {
        label: 'Temporary password',
        value: user.temporaryPassword,
        type: 'string',
      },
    ],
  },
];

export const AdminDetailPage = () => {
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const userQuery = useUserQuery(userId);
  const [platformInvitationProgress, setPlatformInvitationProgress] =
    useState('');
  const canEditAdmin = (userId: string) =>
    ability.can('users:edit', subject('Users', { userId }));
  const [buttonConfig, _setButtonConfig] = useState<buttonConfigType>({
    text: 'Edit details',
    type: 'edit',
    clickPath: `${ADMIN_LIST_PAGE}/${userId}/edit`,
    disabled: !canEditAdmin,
  });
  const onCTAClick = () => history.push(buttonConfig.clickPath);

  const backLink = {
    label: 'Back to admin list',
    url: ADMIN_LIST_PAGE,
  };

  const {
    isLoading,
    isError: isPlatformInviteError,
    error: platformInviteError,
    mutate: sendPlatformInviteToAdmin,
    data,
  }: Record<string, any> = useMutation((userId: string) =>
    sendPlatformInvitationRequest(userId),
  );

  useEffect(() => {
    const platformInvitationProgressState = isLoading
      ? 'InProgress'
      : data?.data
        ? 'SENT'
        : '';
    setPlatformInvitationProgress(platformInvitationProgressState);
  }, [isLoading, data, setPlatformInvitationProgress]);

  return (
    <PageContent className="bigStack">
      {userQuery.isLoading && <Loading />}

      {userQuery.isError && (
        <ErrorBanner
          errorMessage={`Failed to load user data, due to error: ${userQuery.error.message}`}
        />
      )}
      {isPlatformInviteError && (
        <ErrorBanner
          errorMessage={`Failed to send out user invitation, due to error: ${platformInviteError.message}`}
        />
      )}

      {userQuery.isSuccess && (
        <>
          <PageHeader
            title={`Admin: ${userQuery.data.name}`}
            ctaText={buttonConfig.text}
            onCTAClick={onCTAClick}
            hasDisabledCTA={buttonConfig.disabled}
            backLink={backLink}
          />
          <Viewport devices={['phone', 'tablet']}>
            <FloatingActionButton
              type={buttonConfig.type}
              onClick={onCTAClick}
              disabled={buttonConfig.disabled}
            />
          </Viewport>
          {adminUserDetailsSection(userQuery.data).map((section) => (
            <DetailsCard
              key={section.title}
              fieldsToDisplay={section.details}
              testId={`${section.name}-card`}
              title={section.title}
              platformInvitationProgress={platformInvitationProgress}
              sendPlatformInvitation={() =>
                sendPlatformInviteToAdmin(userQuery.data.userId)
              }
            />
          ))}
        </>
      )}
    </PageContent>
  );
};
