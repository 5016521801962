import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { PageContent } from 'app/App.styles';
import {
  confirmEmailChangeAction,
  verifyEmailChangeAction,
} from 'app/store/app.actions';
import {
  getEmailChangeVerifiedSelector,
  getLoadingSelector,
} from 'app/store/app.selectors';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@omnipresentgroup/design-system';

import Button from '../../components/atoms/Button/Button';
import { StyledFullWidthBlueDivider } from '../../components/atoms/Divider/Divider.styles';
import { StyledBodyCopy } from '../../components/atoms/Text/Copy.styles';
import {
  StyledConfirmEmailContainer,
  StyledVerifyEmailPage,
} from './VerifyEmailPage.styles';

interface ParamTypes {
  token: string;
}

type confirmEmailType = {
  token: string;
  logout: (options?: LogoutOptions) => void;
};

export const VerifyEmailPage = () => {
  const loading = useSelector(getLoadingSelector);
  const emailChangeVerified = useSelector(getEmailChangeVerifiedSelector);
  const { token } = useParams<ParamTypes>();
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(verifyEmailChangeAction(token));
    }
  }, [dispatch, token]);

  const confirmEmailUpdate = ({ token, logout }: confirmEmailType) => {
    dispatch(confirmEmailChangeAction({ token, logout }));
  };

  return (
    <PageContent>
      {!loading && (
        <StyledVerifyEmailPage>
          {emailChangeVerified && (
            <StyledConfirmEmailContainer
              data-testid="confirm-email-change"
              className="stack"
            >
              <div>
                <Typography
                  as="h4"
                  hideParagraphSpacing
                  size="24"
                  weight="bold"
                >
                  Confirm email change
                </Typography>
                <StyledFullWidthBlueDivider />
              </div>
              <StyledBodyCopy>
                If you are sure you want to change your sign in email please
                click confirm.
              </StyledBodyCopy>
              <StyledBodyCopy>
                Once confirmed you will be logged out and will need to sign back
                in with your new email.
              </StyledBodyCopy>
              <Button
                palette="primary"
                onClick={() => confirmEmailUpdate({ token, logout })}
              >
                Confirm
              </Button>
            </StyledConfirmEmailContainer>
          )}
        </StyledVerifyEmailPage>
      )}
    </PageContent>
  );
};
