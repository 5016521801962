import appHistory from 'app/appHistory';
import { defineRulesForResources } from 'app/store/ability';
import { SET_ERROR_MESSAGE } from 'app/store/app.constants';
import { HTTPStatusCodesEnum } from 'common/constants';
import { call, put, take, takeLatest } from 'redux-saga/effects';
import { createActionObject } from 'utils/redux-utils';

import { sortByMultipleDates } from '@omnipresentgroup/design-system';

import {
  createDocumentRequest,
  deleteDocumentRequest,
  downloadDocumentRequest,
  getDocumentsRequest,
} from '../../api/documents.api';
import { asyncActionKeys } from '../actions/redux.constants';
import {
  transformDocument,
  transformDocumentToApi,
} from '../transformers/documents.transformer';

export function* getDocuments({ payload }) {
  try {
    const { data: documentsData } = yield call(getDocumentsRequest, payload);

    // NOTE: We're temporarily accepting documents either as a raw array or as obj like { items: [], actions }
    const documents = documentsData.items ?? documentsData;
    const { userId, companyId } = payload;

    defineRulesForResources({
      resources: documentsData,
      ownerId: userId || companyId,
      subject: 'Documents',
    });

    const sortedDocuments = sortByMultipleDates(
      documents.map(transformDocument),
      ['createdAt'],
      'desc',
    );
    yield put(
      createActionObject(asyncActionKeys.GET_DOCUMENTS_END, sortedDocuments),
    );
  } catch (e) {
    yield put(createActionObject(asyncActionKeys.GET_DOCUMENTS_ERROR, e));
  }
}

export function* createDocument({ payload }) {
  try {
    yield call(
      createDocumentRequest,
      transformDocumentToApi(
        payload.documentInfo,
        payload.userId,
        payload.tenantId,
      ),
    );
    yield put(createActionObject(asyncActionKeys.CREATE_DOCUMENT_END));
    appHistory.push(payload.returnPath);
  } catch (e) {
    yield put(createActionObject(asyncActionKeys.CREATE_DOCUMENT_ERROR, e));
  }
}

export function* viewDocument({ payload: { documentId, target } }) {
  try {
    const {
      data: { url: documentLink },
      status,
    } = yield call(downloadDocumentRequest, documentId, 'inline');
    if (status === HTTPStatusCodesEnum.ACCEPTED) {
      yield put(
        createActionObject(
          SET_ERROR_MESSAGE,
          'This document is being processed and will be available soon.',
        ),
      );
    } else {
      window.open(documentLink, target ?? '_blank');
    }
    yield put(createActionObject(asyncActionKeys.DOWNLOAD_DOCUMENT_END));
  } catch (e) {
    if (e?.response?.data?.message === 'MALICIOUS_DOCUMENT') {
      yield put(
        createActionObject(
          SET_ERROR_MESSAGE,
          'This document was flagged as malicious and is not available.',
        ),
      );
    }
    yield put(createActionObject(asyncActionKeys.DOWNLOAD_DOCUMENT_ERROR, e));
  }
}

export function* downloadDocument({ payload }) {
  try {
    const {
      data: { url: documentLink },
      status,
    } = yield call(downloadDocumentRequest, payload);
    if (status === HTTPStatusCodesEnum.ACCEPTED) {
      yield put(
        createActionObject(
          SET_ERROR_MESSAGE,
          'This document is being processed and will be available soon.',
        ),
      );
    } else {
      window.open(documentLink, '_self');
    }
    yield put(createActionObject(asyncActionKeys.DOWNLOAD_DOCUMENT_END));
  } catch (e) {
    if (e?.response?.data?.message === 'MALICIOUS_DOCUMENT') {
      yield put(
        createActionObject(
          SET_ERROR_MESSAGE,
          'This document was flagged as malicious and is not available.',
        ),
      );
    }
    yield put(createActionObject(asyncActionKeys.DOWNLOAD_DOCUMENT_ERROR, e));
  }
}

export function* deleteDocument({ payload: { documentId, ...rest } }) {
  try {
    yield call(deleteDocumentRequest, documentId);
    yield call(getDocuments, { payload: { ...rest } });
    yield take(asyncActionKeys.GET_DOCUMENTS_END);
    yield put(createActionObject(asyncActionKeys.DELETE_DOCUMENT_END));
  } catch (e) {
    yield put(createActionObject(asyncActionKeys.DELETE_DOCUMENT_ERROR, e));
  }
}

export default [
  takeLatest(asyncActionKeys.GET_DOCUMENTS_START, getDocuments),
  takeLatest(asyncActionKeys.CREATE_DOCUMENT_START, createDocument),
  takeLatest(asyncActionKeys.VIEW_DOCUMENT_START, viewDocument),
  takeLatest(asyncActionKeys.DOWNLOAD_DOCUMENT_START, downloadDocument),
  takeLatest(asyncActionKeys.DELETE_DOCUMENT_START, deleteDocument),
];
