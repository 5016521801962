import { WheelEvent } from 'react';

import { isActiveDocumentElement } from './dom.helper';

/**
 * This is a quick-win fix for `<input type="number">` fields where on Chrome the value changes on focus through mouse wheel scrolling.
 * See: https://app.shortcut.com/omnipresent/story/4411/input-type-number-value-s-changes-on-scrolling-page for context
 * @param evt
 */
export const onWheelPreventChangeForNumberInput = (
  evt: WheelEvent<HTMLInputElement>,
) => {
  if (isActiveDocumentElement(evt.currentTarget)) {
    // When there is a scroll while being focused, the number changes.
    // Therefore, we blur to prevent that behavior, without preventing the scroll.
    evt.currentTarget.blur();

    evt.stopPropagation();

    // Refocus immediately the input
    setTimeout(() => {
      (evt.target as HTMLInputElement).focus();
    }, 0);
  }
};
