import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { LspProfile } from 'common/components/organisms/LspProfile/LspProfile';
import { TabProp } from 'common/components/organisms/Tabs/Tabs.types';
import { transformLSPApiResponseToLSP } from 'common/store/transformers/lsps.transformer';
import { LSP_LIST_PAGE } from 'paths';
import { useSelectUserRoles } from 'utils/hooks';
import { useDeleteLSPMutation, useLspQuery } from 'utils/queries';

import {
  Button,
  Inline,
  notifyError,
  notifySuccess,
} from '@omnipresentgroup/design-system';

import { StyledTabsContainer } from '../CompanyDetailPage/CompanyDetailPage.styles';
import { LspUpdateModal } from './LspUpdateModal/LspUpdateModal';
import { EmployeesTab } from './Tabs/EmployeesTab';
import LspUsersTab from './Tabs/LspUsersTab';
import { useLSPRelations } from './useLSPRelations';

export const LspDetailPage = () => {
  const location = useLocation();
  const history = useHistory();

  const { isAdmin } = useSelectUserRoles();
  const [currentHash, setCurrentHash] = useState(location.hash || null);

  const { lspId } = useParams<{ lspId: string }>();
  const [openUpdateLSPModal, setOpenUpdateLSPModal] = useState<boolean>(false);

  const lspRelations = useLSPRelations({ lspId });
  const deleteLspMutation = useDeleteLSPMutation({ lspId });
  const lspQuery = useLspQuery(lspId);

  const buttonConfig = useMemo<ButtonConfigType>(() => {
    return currentHash === '#managers'
      ? {
          text: 'Add Manager',
          type: 'add',
          disabled: false,
          onClick: () => history.push(`${LSP_LIST_PAGE}/${lspId}/user/add`),
        }
      : {
          text: 'Edit LSP',
          type: 'edit',
          onClick: () => setOpenUpdateLSPModal(true),
          disabled: false,
        };
  }, [currentHash, history, lspId]);

  const onDeleteButtonClick = useCallback(() => {
    if (!lspRelations.isSuccess || lspRelations.hasConnectedEntities) {
      return;
    }

    deleteLspMutation.mutate(undefined, {
      onError: () => {
        notifyError({
          title: 'Error',
          description: `Failed to delete LSP.`,
        });
      },
      onSuccess: () => {
        notifySuccess({
          title: 'Success',
          description: 'LSP was successfully deleted.',
        });
        appHistory.push(LSP_LIST_PAGE);
      },
    });
  }, [
    deleteLspMutation,
    lspId,
    lspRelations.hasConnectedEntities,
    lspRelations.isSuccess,
  ]);

  useEffect(() => {
    setCurrentHash(location.hash);
  }, [location]);

  const tabConfig = useMemo<TabProp[]>(() => {
    const EmployeesContent = () => <EmployeesTab lspId={lspId} />;
    const DetailsContent = () => <LspProfile lspId={lspId} />;
    const ManagersContent = () => (
      <LspUsersTab lspId={lspId} hasVerificationField={true} />
    );

    return [
      {
        id: 1,
        title: 'Employees',
        href: '#employees',
        Content: EmployeesContent,
      },
      {
        id: 2,
        title: 'Details',
        href: '#details',
        Content: DetailsContent,
      },
      {
        id: 3,
        title: 'Managers',
        href: '#managers',
        Content: ManagersContent,
      },
    ];
  }, [lspId]);

  return (
    <PageContent data-testid="lsp-detail-page" className="bigStack">
      {lspQuery.isLoading && <Loading />}

      {lspQuery.isError && (
        <ErrorBanner errorMessage={lspQuery.error.message} />
      )}

      {lspQuery.isSuccess && (
        <>
          <PageHeader
            backLink={{
              url: LSP_LIST_PAGE,
              label: 'Back to all LSPs',
            }}
            title={lspQuery.data.name}
            copy="Local Service Provider"
            testId="lsp-detail-headline"
            ctaText={isAdmin && buttonConfig?.text}
            onCTAClick={buttonConfig.onClick}
            customCTA={
              <Inline mx="8">
                <Button
                  variant="secondary"
                  icon="Trash"
                  loading={lspRelations.isLoading}
                  disabled={
                    !lspRelations.isSuccess || lspRelations.hasConnectedEntities
                  }
                  onClick={onDeleteButtonClick}
                >
                  Delete LSP
                </Button>
              </Inline>
            }
          />
          <StyledTabsContainer
            tabsToDisplay={tabConfig}
            currentTab={
              currentHash
                ? tabConfig.find((tab: TabProp) => tab.href === currentHash)
                : tabConfig[0]
            }
          />
          <LspUpdateModal
            closeFn={() => setOpenUpdateLSPModal(false)}
            open={lspQuery?.data && openUpdateLSPModal}
            lsp={transformLSPApiResponseToLSP(lspQuery.data)}
          />
        </>
      )}
    </PageContent>
  );
};

type ButtonConfigType = {
  text: string;
  type: string;
  onClick?: () => void;
  disabled: boolean;
};
