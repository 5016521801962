import styled from 'styled-components';

import { omniConfig, Table } from '@omnipresentgroup/design-system';

export const StickyTable = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: inherit;
  }

  thead {
    z-index: 2;
  }

  tr:hover {
    background-color: #eaeafc;
  }

  /**
    when rendering headers with a custom element they are wrapped in a span so align properties don't work
  **/
  th:not(:first-child) > div > span {
    width: 100%;
  }
`;

export const TableWrapper = styled(StickyTable)`
  td:has(> .end-cell) {
    border-right-style: ${omniConfig.theme.borderStyles.solid};
    border-right-width: ${omniConfig.theme.borderWidths.s};
    border-right-color: ${omniConfig.theme.colors['border-subtle']};
  }

  /**
    without this it is showing empty footer from the regular colums, we only want footer from groups
  **/
  tfoot > tr:not(:first-child) {
    display: none;
  }
`;
