import { AxiosError } from 'axios';
import { publishPayReportBulk } from 'common/api/payrollTaxonomy.api';
import { BulkRequest, PayReportBulkApproveApiResponse } from 'common/types';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const usePublishPayReportBulkMutation = () => {
  return useMutation<
    PayReportBulkApproveApiResponse,
    AxiosError<string>,
    BulkRequest
  >((data: BulkRequest) =>
    publishPayReportBulk(data).then(getDataFromResponse),
  );
};
