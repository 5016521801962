import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Contractor } from 'common/types/contractor';
import { DateTime } from 'luxon';
import { toSentenceCase } from 'utils/string-utils';

import {
  formatNumber,
  Inline,
  Skeleton,
  Typography,
} from '@omnipresentgroup/design-system';

import { ContractorStatus } from '../../../components/ContractorStatus';

export enum ColumnHeaders {
  contractorName = 'Contractor',
  role = 'Role',
  country = 'Country',
  startDate = 'Start date',
  endDate = 'End date',
  pay = 'Pay',
  status = 'Status',
}

const columnHelper = createColumnHelper<Contractor>();

export const Columns: ColumnDef<Contractor, any>[] = [
  columnHelper.display({
    id: 'contractorName',
    cell: (info) =>
      `${info.row.original.firstName} ${info.row.original.lastName}`,
    header: () => ColumnHeaders.contractorName,
    size: 200,
    enableGlobalFilter: true,
  }),
  columnHelper.display({
    id: 'jobRole',
    cell: (info) => info.row.original.contracts[0].jobRole,
    header: () => ColumnHeaders.role,
    size: 100,
  }),
  columnHelper.display({
    id: 'country',
    cell: (info) => info.row.original.residenceCountry.name || '',
    header: () => ColumnHeaders.country,
    size: 100,
  }),
  columnHelper.display({
    id: 'startDate',
    cell: (info) => {
      const startDateISO = info.row.original.contracts[0].startDate;
      return startDateISO
        ? DateTime.fromISO(startDateISO).toFormat('MMM dd, yyyy')
        : '';
    },
    sortingFn: 'datetime',
    header: () => ColumnHeaders.startDate,
    size: 100,
  }),
  columnHelper.display({
    id: 'endDate',
    cell: (info) => {
      const endDateISO = info.row.original.contracts[0].endDate;
      return endDateISO
        ? DateTime.fromISO(endDateISO).toFormat('MMM dd, yyyy')
        : '';
    },
    sortingFn: 'datetime',
    header: () => ColumnHeaders.endDate,
    size: 100,
  }),
  columnHelper.display({
    id: 'pay',
    cell: (info) => {
      const amount = info.row.original.contracts[0].rate;
      if (amount) {
        const frequency =
          info.row.original.contracts[0].expectedInvoicingFrequency;
        return (
          <Inline gap="8" css={{ flexWrap: 'nowrap' }}>
            <Typography as="span" size="14" whiteSpace="nowrap">
              {formatNumber({
                input: Number(amount),
                currency: info.row.original.contracts[0].invoicingCurrency,
              })}
            </Typography>
            {frequency && (
              <Typography
                as="span"
                size="14"
                color="subtle"
                whiteSpace="nowrap"
              >
                {toSentenceCase(frequency)}
              </Typography>
            )}
          </Inline>
        );
      }
      return '';
    },
    header: () => ColumnHeaders.pay,
    size: 125,
  }),
  columnHelper.accessor('state', {
    cell: (info) => <ContractorStatus status={info.getValue()} />,
    header: () => ColumnHeaders.status,
    size: 75,
  }),
];

export const LoadingColumns: ColumnDef<any, any>[] = [
  columnHelper.display({
    id: 'contractorName',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.contractorName,
    size: 200,
  }),
  columnHelper.display({
    id: 'jobRole',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.role,
    size: 100,
  }),
  columnHelper.display({
    id: 'country',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.country,
    size: 100,
  }),
  columnHelper.display({
    id: 'startDate',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.startDate,
    size: 100,
  }),
  columnHelper.display({
    id: 'endDate',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.endDate,
    size: 100,
  }),
  columnHelper.display({
    id: 'pay',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.pay,
    size: 125,
  }),
  columnHelper.display({
    id: 'state',
    cell: () => <Skeleton height={24} />,
    header: () => ColumnHeaders.status,
    size: 75,
  }),
];
