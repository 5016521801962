import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledDetailsCardContainer = styled.table`
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
  border-radius: ${({ theme: { radii } }) => radii.m};
  box-shadow: ${({ theme: { shadows } }) => shadows.s};
  width: 100%;
  border-collapse: collapse;

  tr:last-child td:first-child {
    border-bottom-left-radius: ${({ theme: { radii } }) => radii.m};
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: ${({ theme: { radii } }) => radii.m};
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledDetailCardContent = styled.div`
  padding: ${({ theme: { space } }) => space[8]};
`;

export const StyledDetail = styled.tr``;

const borderWidth = '1px'; // TODO: Leaving the hardcoded value for now until new tables are ready from OmniDS

export const StyledDetailLabel = styled.td`
  border: ${({ theme: { colors, borderStyles } }) =>
    `${borderWidth} ${borderStyles.solid} ${colors['border-subtle']}`};
  vertical-align: middle;
  padding: ${({ theme: { space } }) => space[16]};
  width: 30%;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    min-width: 55%;
    max-width: 55%;
    border-left: none;
    border-right: none;
  }
`;

export const StyledDetailsContainer = styled.div`
  width: 100%;
`;
