import { ChangeEvent, forwardRef, useState } from 'react';

import { Checkbox } from 'common/components/atoms/Checkbox';

import { AssociatedEmployee } from '.';

const EmployeeCheckbox = forwardRef(function EmployeeCheckbox(
  { id, ...restProps }: any,
  ref,
) {
  return (
    <>
      <Checkbox id={id} ref={ref} size={20} {...restProps} />
    </>
  );
});

export const CheckboxCell = (
  employee: AssociatedEmployee,
  onColumnItemClick: (
    event: ChangeEvent<HTMLInputElement>,
    employee: AssociatedEmployee[],
  ) => void,
) => {
  const [checked, setChecked] = useState(employee.isAssociated);

  return (
    <EmployeeCheckbox
      id={`cbx_${employee.userId}`}
      checked={checked}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setChecked(!checked);
        onColumnItemClick(event, [employee]);
      }}
    />
  );
};
