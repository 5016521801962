import { AxiosError } from 'axios';
import { bulkUpdateExpenses } from 'common/api/expense.api';
import {
  BulkUpdateExpensesAPIResponse,
  BulkUpdateExpensesRequest,
} from 'common/types';
import { useMutation } from 'react-query';

export const useBulkApproveExpensesMutation = () =>
  useMutation<
    BulkUpdateExpensesAPIResponse[],
    AxiosError,
    BulkUpdateExpensesRequest
  >((fieldsForUpdate: BulkUpdateExpensesRequest) =>
    bulkUpdateExpenses(fieldsForUpdate),
  );
