import { PageContent } from 'app/App.styles';
import { getLoadingSelector } from 'app/store/app.selectors';
import Loading from 'common/components/atoms/Loading/Loading';
import { SafeRichMarkdownEditor } from 'common/components/molecules/MarkDownEditor/SafeRichMarkdownEditor';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { StyledDetailsCardContainer } from 'common/components/organisms/DetailsCard/DetailsCard.styles';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ContentState } from '@omnipresentgroup/design-system';

import { getEmployeeSelector } from '../../store/selectors/employee.selector';

const EditorContainer = styled.div`
  padding: ${({ theme: { space } }) => space[16]};

  input[type='checkbox'] {
    appearance: checkbox;
  }
`;

export const MyEmploymentPage = () => {
  const { name, employmentInformation } = useSelector(getEmployeeSelector);
  const loading = useSelector(getLoadingSelector);
  const showMyEmploymentInformation = !loading && name && employmentInformation;
  const showEmptyState = !loading && !employmentInformation;

  return (
    <PageContent>
      {!showMyEmploymentInformation && <Loading />}

      {showEmptyState && (
        <ContentState
          id="myEmployment-unavailable"
          variant="empty"
          title="Feature unavailable"
        />
      )}

      {showMyEmploymentInformation && (
        <div data-testid="employment-checklist-page" className="bigStack">
          <PageHeader title="My Employment" />
          <StyledDetailsCardContainer>
            <EditorContainer>
              {/* @ts-expect-error Missing props have actually defaults in the library */}
              <SafeRichMarkdownEditor
                id="employment-checklist-content"
                defaultValue={employmentInformation}
                readOnly
                onChange={() => {}}
              />
            </EditorContainer>
          </StyledDetailsCardContainer>
        </div>
      )}
    </PageContent>
  );
};
