import appSaga from 'app/store/sagas/app.saga';
import companiesSaga from 'common/store/sagas/companies.saga';
import documentsSaga from 'common/store/sagas/documents.saga';
import documentUploadSaga from 'common/store/sagas/documentStorage.saga';
import employeesSaga from 'common/store/sagas/employee.saga';
import seatsSaga from 'common/store/sagas/seats.saga';
import { all } from 'redux-saga/effects';

import managersEmployeeExpensesSaga from './managersEmployeeExpenses.saga';

export default function* managerRootSaga() {
  yield all([
    ...employeesSaga,
    ...seatsSaga,
    ...managersEmployeeExpensesSaga,
    ...appSaga,
    ...documentUploadSaga,
    ...documentsSaga,
    ...companiesSaga,
  ]);
}
