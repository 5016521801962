import { useEffect, useState } from 'react';

import {
  Box,
  Inline,
  Notification,
  omniConfig,
  Split,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import { RadioInput } from '../../../components/atoms/RadioInput/RadioInput';
import { BenefitsCountrySelectionState } from '../../../types';
import { useBenefitsSelectionContext } from './BenefitsSelectionContext';
import { SelectionConfirmationPopup } from './SelectionConfirmationPopup';

export type Option = {
  label: string;
  value: BenefitsCountrySelectionState;
  checked?: boolean;
};

const options: Array<Option> = [
  {
    label: 'Yes, setup benefits now',
    value: 'YES',
  },
  {
    label: 'Remind me later',
    value: 'REMIND_LATER',
  },
  {
    label: "No - I don't want benefits",
    value: 'NO',
  },
];

const bgBenefitsInfo = { background: omniConfig.theme.colors['gray-100'] };

export const ConfigureBenefits = () => {
  const { clientPreferences, countryData } = useBenefitsSelectionContext();
  const [confirmationPopupOption, setConfirmationPopupOption] =
    useState<BenefitsCountrySelectionState | null>();

  useEffect(() => {
    countryData.isRequired && clientPreferences.setSelectedOptionToSave('YES');
  }, [clientPreferences, countryData.isRequired]);

  const renderOptions = () => {
    return (
      <Box>
        <RadioInput
          name="benefits-options"
          label="Do you want benefits for this employee?"
          options={options.map((option) => ({
            ...option,
            checked: option.value === clientPreferences.selectedOptionToSave,
          }))}
          onChange={(value) => {
            setConfirmationPopupOption(value as BenefitsCountrySelectionState);
          }}
        />
        {confirmationPopupOption && (
          <SelectionConfirmationPopup
            onConfirm={() => {
              clientPreferences.setSelectedOptionToSave(
                confirmationPopupOption,
              );
            }}
            close={() => setConfirmationPopupOption(null)}
            selectedOption={confirmationPopupOption}
          />
        )}
      </Box>
    );
  };

  return (
    <Stack mt="32">
      <Notification
        intent="info"
        id="benefits-info-alert"
        title="Good to know"
        description={countryData.layoutInfo}
      />
      <Typography as="span" size="16">
        {countryData.layoutDescription}
      </Typography>
      <Box p="16" radius="s" css={bgBenefitsInfo}>
        <Stack>
          <Split fraction="1/2">
            <Typography as="span" size="14" weight="medium" color="helper">
              Offered
            </Typography>
            <Typography as="span" size="14" weight="medium" color="helper">
              Estimated costs
            </Typography>
          </Split>
          <Split color="default" fraction="1/2">
            <ul style={{ margin: 0, paddingLeft: '24px' }}>
              {countryData.layoutOffered?.map((item, idx) => (
                <li key={idx}>
                  <Typography as="span" size="16">
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
            <Box>
              <Inline align="center" gap="4">
                <Typography as="span" size="16" weight="bold">
                  {countryData.layoutCost}
                </Typography>
                <Typography
                  as="span"
                  size="12"
                  weight="medium"
                  color="placeholder"
                >
                  per month
                </Typography>
              </Inline>
            </Box>
          </Split>
          <Typography as="span" size="12">
            *{countryData.layoutAdditional}
          </Typography>
        </Stack>
      </Box>
      {countryData.isRequired ? null : renderOptions()}
    </Stack>
  );
};

ConfigureBenefits.defaultProps = {
  countryLabel: '',
};
ConfigureBenefits.displayName = 'ConfigureBenefits';
