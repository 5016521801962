import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { sendPlatformInvitation } from 'app/store/app.actions';
import {
  getLoadingSelector,
  getPlatformInvitationStatus,
} from 'app/store/app.selectors';
import {
  getCompanyManagerSelector,
  getSelectedCompanySelector,
} from 'common/store/selectors/companies.selectors';
import { Managers } from 'common/types/manager';
import { COMPANIES_LIST_PAGE } from 'paths';
import { connect } from 'react-redux';

import {
  getCompanyAction,
  getCompanyManagerAction,
} from '../../store/actions/companies.actions';
import ManagerDetailPage from '../ManagerDetailPage/ManagerDetailPage';

type Props = {
  getManager: (arg: { companyId: string; managerId: string }) => void;
  loading: boolean;
  manager: Managers;
  sendInvitation: (arg: { userId: string }) => void;
  platformInvitationProgress: string;
  getCompany: (companyId: string) => void;
  companyDetails: any;
};

const CompanyManagerDetailsPage = ({
  getManager,
  manager,
  loading,
  getCompany,
  companyDetails,
}: Props) => {
  const { companyId, managerId } = useParams<{
    companyId: string;
    managerId: string;
  }>();

  const [canDelete, setCanDelete] = useState(true);

  useEffect(() => {
    getManager({ companyId, managerId });
    getCompany(companyId);
  }, [managerId, companyId, getManager, getCompany]);

  useEffect(() => {
    if (companyDetails?.managers?.length) {
      if (companyDetails.managers.length < 2) {
        setCanDelete(false);
      }
    }
  }, [companyDetails, managerId]);

  return (
    <ManagerDetailPage
      manager={manager}
      companyManagers={companyDetails?.managers}
      loading={loading}
      managerId={managerId}
      onEditURL={`${COMPANIES_LIST_PAGE}/${companyId}/managers/${managerId}/edit`}
      canEdit
      companyId={companyId}
      canDelete={canDelete}
    />
  );
};

const mapDispatchToProps = {
  getManager: getCompanyManagerAction,
  getCompany: getCompanyAction,
  sendInvitation: sendPlatformInvitation,
};

const mapStateToProps = (state: Record<string, any>) => ({
  manager: getCompanyManagerSelector(state),
  loading: getLoadingSelector(state),
  platformInvitationProgress: getPlatformInvitationStatus(state),
  companyDetails: getSelectedCompanySelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyManagerDetailsPage);
