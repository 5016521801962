import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { getMonthInString } from 'common/helpers/month';
import {
  LspBillsTab,
  LspBillUploadModal,
} from 'omniplatform/admin/pages/PayrollInstanceDetailPage/Tabs/LspBillsTab';
import { PayrollContextWrapper } from 'omniplatform/admin/pages/PayrollPage/PayrollContextWrapper';
import { PAYROLL_PAGE } from 'paths';
import { useTrack } from 'utils/mixpanel/tracker';

import { Button, DropdownMenu, Inline } from '@omnipresentgroup/design-system';

import { StyledTabsContainer } from './PayrollInstanceDetailPage.styles';
import {
  PayrollInstanceDetailPageContext,
  VisibleModalEnum,
} from './PayrollInstanceDetailPageContext';
import { PayReportsTab } from './Tabs/PayReportsTab';

const payReportsTabUrl = '#payreports';
const lspBillsTabUrl = '#lsp-bills';

const PayReportsTabContent = () => <PayReportsTab />;
const LspBillsTabContent = () => <LspBillsTab />;

const Page = () => {
  const location = useLocation();
  const track = useTrack();

  const { payrollInstance, setVisibleModal, month, year } = useContext(
    PayrollInstanceDetailPageContext,
  );

  const title = `${getMonthInString(month)} ${year}`;

  const [currentHash, setCurrentHash] = useState(
    location.hash || payReportsTabUrl,
  );

  useEffect(() => {
    if (location.hash) {
      setCurrentHash(location.hash);
    }
  }, [location]);

  const tabContainer = useMemo(() => {
    const tabConfig = [
      {
        id: 1,
        title: 'Pay Reports',
        href: payReportsTabUrl,
        Content: PayReportsTabContent,
      },
      {
        id: 2,
        title: 'LSP Bills',
        href: lspBillsTabUrl,
        Content: LspBillsTabContent,
      },
    ];

    return (
      <StyledTabsContainer
        tabsToDisplay={tabConfig}
        currentTab={
          currentHash
            ? tabConfig.find((tab) => tab.href === currentHash)
            : tabConfig[0]
        }
      />
    );
  }, [currentHash]);

  return (
    <PageContent
      data-testid="payroll-instance-detail-page"
      className="bigStack"
    >
      <PageHeader
        backLink={{
          url: PAYROLL_PAGE,
          label: 'Back to all Workbooks',
        }}
        title={title}
        copy={payrollInstance?.name}
        customCTA={
          <Inline justify="flex-end">
            {currentHash === payReportsTabUrl && (
              <>
                <Button
                  onClick={() => {
                    track('Upload button Clicked');
                    setVisibleModal(VisibleModalEnum.gtnUpload);
                  }}
                >
                  Upload
                </Button>
              </>
            )}
            {currentHash === lspBillsTabUrl && (
              <>
                <DropdownMenu
                  options={[
                    {
                      name: 'Regenerate template',
                      onClick: () => {},
                    },
                  ]}
                >
                  <Button
                    onClick={() => {}}
                    icon="DotsVertical"
                    variant="secondary"
                  ></Button>
                </DropdownMenu>
                <LspBillUploadModal />
              </>
            )}
          </Inline>
        }
        testId="payroll-detail"
      />
      {tabContainer}
    </PageContent>
  );
};

export const PayrollInstanceDetailPage = () => {
  return (
    <PayrollContextWrapper>
      <Page />
    </PayrollContextWrapper>
  );
};
