import { getUserSelector } from 'app/store/app.selectors';
import { getLSPEmployees } from 'common/api/localServiceProviders.api';
import { transformSimplifiedEmployee } from 'common/store/transformers/employees.transformer';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getDataFromResponse } from 'utils/react-query-utils';
import { isAdmin, isLSP } from 'utils/user';

import { sortObjectsByKey } from '../../../sortObjectsByKey';
import { lspsKeys } from '../keys';

export const useLSPEmployeesQuery = ({ lspId }: { lspId: string }) => {
  const user = useSelector(getUserSelector);
  const hasPermissionToSeeLSPs = isAdmin(user) || isLSP(user);
  return useQuery(
    lspsKeys.employees(lspId),
    async () => {
      return getLSPEmployees(lspId).then(getDataFromResponse);
    },
    {
      enabled: hasPermissionToSeeLSPs,
      select: (data) => {
        return sortObjectsByKey({
          key: 'name',
          list: data.employees.map(transformSimplifiedEmployee),
        });
      },
    },
  );
};
