import styled from 'styled-components';

export const StyledMonthPicker = styled.div`
  input {
    padding: ${({ theme: { space } }) => space[16]};
    background-color: ${({ theme: { colors } }) =>
      colors['field-background-default']};
    font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
    border-radius: ${({ theme: { radii } }) => radii.s};
    color: ${({ theme: { colors } }) => colors['text-default']};
    border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-strong']}`};
    transition: all 300ms ease-in-out;
  }

  input:hover {
    border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  }

  input:focus {
    border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-inverse']}`};
    transition: all 300ms ease-in-out;
  }

  .react-datepicker__navigation-icon::before,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-color: black;
  }
`;
