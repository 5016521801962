import * as Sentry from '@sentry/react';

const logError = (exception, context) => {
  if (process.env.VITE_STAGE !== 'OFFLINE') {
    Sentry.captureException(exception, context);
  }
};

export default {
  error: logError,
};
