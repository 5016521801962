const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/** The accepted month number range is from 1 to 12 */
export const getMonthInString = (month: number): string | null => {
  if (month < 1 || month > 12) {
    return null;
  }
  return MONTH_NAMES[month - 1];
};
