import { getNextPayrollCutOffDate } from 'utils/getNextPayrollCutOffDate';

import { Notification } from '@omnipresentgroup/design-system';

export const COMPENSATION_NOTICE_TITLE = `The next cut off date to submit compensation requests is ${getNextPayrollCutOffDate()}`;

export const CompensationNotice = () => (
  <Notification
    id="compensation-notice"
    title={COMPENSATION_NOTICE_TITLE}
    intent="warning"
    description="If you’ve made a mistake please reach out to our support team via the chat"
    type="banner"
  />
);
