import { getVariantPayments } from 'common/api/variantPayments.api';
import {
  GetVariantPaymentsResponse,
  GetVariantPaymentsResponseItem,
  QueryParams,
} from 'common/types/variantPayment';
import { useInfiniteQuery, UseQueryOptions } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { variantPaymentsKeys } from '../keys';

export const useGetVariantPaymentsQuery = (
  companyId: string,
  params?: QueryParams,
  options?: UseQueryOptions,
) => {
  return useInfiniteQuery<
    GetVariantPaymentsResponse,
    Error,
    GetVariantPaymentsResponseItem[]
  >(
    variantPaymentsKeys.getVariantPayments({ companyId: companyId }),
    ({ pageParam }) =>
      getVariantPayments(companyId, {
        pageSize: params?.pageSize,
        nextPageKey: pageParam,
      }).then(getDataFromResponse),
    {
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      select: (data) => ({
        pages: data.pages.map((page) => page.variantPayments),
        pageParams: data.pageParams,
      }),
      enabled: options?.enabled,
    },
  );
};
