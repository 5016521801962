import { ReactComponent as CheckIcon } from 'assets/icon/Check.svg';
import styled from 'styled-components';

export const Checked = styled(CheckIcon)`
  width: ${({ theme: { space } }) => space[64]};
  height: ${({ theme: { space } }) => space[64]};

  rect {
    fill: ${({ theme: { colors } }) => colors['icon-positive']};
  }
`;

export const ButtonsRow = styled.div<{
  centered?: boolean;
}>`
  justify-content: ${(props) => (props.centered ? 'center' : 'right')};
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { space } }) => space[4]};
`;
