import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import { ConfirmationModal } from 'common/components/molecules/ConfirmationModal';
import { SuccessModal } from 'common/components/molecules/SuccessModal';
import { THREE_SECONDS } from 'common/constants';
import { FourOhThree } from 'common/pages/403/FourOhThree';
import { getTenantIdSelector } from 'common/store/selectors/companies.selectors';
import { CheckCircle } from 'react-feather';
import { useSelector } from 'react-redux';
import { useSelectUserRoles } from 'utils/hooks';
import { useTrack } from 'utils/mixpanel/tracker';
import { useSubmitBulkSalaryChangeMutation } from 'utils/queries/employees/useSubmitBulkSalaryChangeMutation';
import { useSubmitBulkVariantPaymentMutation } from 'utils/queries/variantPayments';
import { capitaliseFirstLetter } from 'utils/string-utils';

import {
  Box,
  Button,
  Inline,
  Link,
  Notification,
  omniConfig,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import {
  compensationHeadings,
  compensationTextValues,
  getBackLinkPath,
  getPluralizedText,
} from '../utils';

type LocationState = {
  selectedCompensation: string;
  selectedEmployeesIds: string[];
  uploadedCSVUrl: string;
  csvValidationResult: {
    totErrors: number;
    errorsUrl: string;
    totSuccess: number;
    successUrl: string;
  };
};

export const VerifyBulkUploadPage = () => {
  const track = useTrack();
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const location = useLocation();

  const verificationResult = location.state as LocationState;
  const { csvValidationResult, selectedCompensation, uploadedCSVUrl } =
    verificationResult;
  const failedUploadCount = csvValidationResult.totErrors;
  const successUploadCount = csvValidationResult.totSuccess;
  const failedUploadURL = csvValidationResult.errorsUrl;
  const successUploadURL = csvValidationResult.successUrl;

  const submitBulkVariantPayment = useSubmitBulkVariantPaymentMutation();
  const submitBulkSalaryChange = useSubmitBulkSalaryChangeMutation();
  const submitBulkCompensation =
    selectedCompensation === 'salary'
      ? submitBulkSalaryChange
      : submitBulkVariantPayment;

  const { isManager } = useSelectUserRoles();
  const { companyId: adminCompanyId } = useParams<{ companyId: string }>();
  const managerCompanyId = useSelector(getTenantIdSelector);
  const companyId = adminCompanyId || managerCompanyId;

  const onSubmitCompensationClick = () => {
    track('Submit Bulk Compensation Clicked', {
      selectedCompensation,
      failed: failedUploadCount,
      success: successUploadCount,
    });
    submitBulkCompensation.mutate(
      {
        companyId,
        type: selectedCompensation,
        csvFileUrl: uploadedCSVUrl,
      },
      {
        onSuccess: () => {
          setShowSubmitModal(false);
          setShowSuccessModal(true);
          setTimeout(() => {
            const backLink = getBackLinkPath(isManager, 'upload', companyId);
            appHistory.push(backLink);
          }, THREE_SECONDS);
        },
        onError: (err) => console.error(err),
      },
    );
  };

  const setSubmitPage = () => {
    if (successUploadCount) {
      setShowSubmitModal(true);
    } else {
      const backLink = getBackLinkPath(isManager, 'upload', companyId);
      appHistory.push(backLink);
    }
  };

  const onBackClick = () => {
    const backLink = getBackLinkPath(isManager, 'verify', companyId);
    appHistory.push(backLink, verificationResult);
  };

  const pluralizedErrorText = getPluralizedText(
    failedUploadCount,
    compensationTextValues,
    selectedCompensation,
  );
  const pluralizedSuccessText = getPluralizedText(
    successUploadCount,
    compensationTextValues,
    selectedCompensation,
  );
  const submitCompensationModalText =
    failedUploadCount > 0
      ? `${capitaliseFirstLetter(
          compensationTextValues[selectedCompensation][1],
        )} that failed verification will not be submitted. Please fix the errors and resubmit those later.`
      : '\n';

  return !selectedCompensation ? (
    <FourOhThree />
  ) : (
    <PageContent className="bigStack">
      <Typography as="h3" hideParagraphSpacing size="24" weight="medium">
        {compensationHeadings[selectedCompensation]}
      </Typography>
      <Stack
        bg="primary"
        minH={550}
        w="60vw"
        p="32"
        mt="32"
        radius="m"
        depth="s"
      >
        <Stack
          gap="0"
          css={{
            flexGrow: 1,
          }}
        >
          <Typography as="h4" hideParagraphSpacing weight="medium" size="20">
            Verification
          </Typography>
          {!!failedUploadCount && (
            <Box>
              <Typography as="span" size="14">
                {`Any ${pluralizedErrorText} that have failed verification will not be
            included in the submission.`}
              </Typography>
              <Box my="32" px="8">
                <Notification
                  intent="error"
                  id="error-upload"
                  title={`${failedUploadCount} ${pluralizedErrorText} failed verification`}
                  description="Please restart the bulk upload process for the failed records"
                  link={
                    <Link size="16" target="_self" to={failedUploadURL}>
                      Download report
                    </Link>
                  }
                />
              </Box>
              <Typography as="span" size="14" weight="bold">
                {`How to fix ${pluralizedErrorText} that have failed verification?`}
              </Typography>
              <Stack p="16" gap="0">
                <Typography as="span" size="14">
                  1. Download the report above
                </Typography>
                <Typography as="span" size="14">
                  2. Restart the process for the failed records
                </Typography>
              </Stack>
            </Box>
          )}
          {!!successUploadCount && (
            <Box my="16" px="8">
              <Notification
                intent="success"
                id="verified-upload"
                title={`${successUploadCount} ${pluralizedSuccessText}  verified`}
                description="Ready to be submitted"
                link={
                  <Link size="16" target="_self" to={successUploadURL}>
                    Download report
                  </Link>
                }
              />
            </Box>
          )}
        </Stack>
        <hr
          style={{
            width: '100%',
            opacity: 0.5,
          }}
        />

        <Inline pt="16" justify="space-between">
          {!!successUploadCount && (
            <Inline
              align="center"
              gap="8"
              css={{
                flexGrow: 1,
              }}
            >
              <CheckCircle color={omniConfig.theme.colors['green-500']} />
              <Typography as="span" size="14" weight="bold">
                {`${successUploadCount} ${pluralizedSuccessText}  verified`}
              </Typography>
            </Inline>
          )}
          <Button icon="ChevronLeft" variant="secondary" onClick={onBackClick}>
            Back
          </Button>
          <Button onClick={setSubmitPage}>
            {successUploadCount
              ? 'Submit verified compensation'
              : 'Start again'}
          </Button>
          {showSubmitModal && (
            <ConfirmationModal
              title={`Submit the verified ${pluralizedSuccessText}?`}
              message={submitCompensationModalText}
              cancelHandler={() => setShowSubmitModal(false)}
              cancelLabel="Back"
              confirmLabel="Submit"
              confirmHandler={onSubmitCompensationClick}
              loading={submitBulkCompensation.isLoading}
            />
          )}
          {showSuccessModal && (
            <SuccessModal
              text={`${submitBulkCompensation.data.length} ${getPluralizedText(
                submitBulkCompensation.data.length,
                compensationTextValues,
                selectedCompensation,
              )} have been submitted successfully.`}
            />
          )}
        </Inline>
      </Stack>
    </PageContent>
  );
};
