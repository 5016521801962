import { LoadingSpinner } from 'common/components/atoms/LoadingSpinner/LoadingSpinner.styles';
import PropTypes from 'prop-types';

import { Button, Tag } from '@omnipresentgroup/design-system';

import { Wrapper } from './InvitationStatus.styles';

const InvitationStatus = ({
  invitationStatus,
  platformInviteInProgress,
  sendPlatformInvitation,
}) => (
  <Wrapper data-testid="invitation-component">
    {invitationStatus === 'ACCEPTED' && (
      <Tag id="invitation-pill" intent="success">
        ACCEPTED
      </Tag>
    )}
    {invitationStatus === 'SENT' && (
      <Tag id="invitation-pill" intent="success">
        SENT
      </Tag>
    )}
    {platformInviteInProgress !== 'InProgress' &&
      invitationStatus !== 'ACCEPTED' && (
        <Button
          data-testid="invitation-send-button"
          onClick={sendPlatformInvitation}
          variant="secondary"
          size="small"
        >
          {invitationStatus === 'SENT' ? 'Send again' : 'Send invite'}
        </Button>
      )}
    {platformInviteInProgress === 'InProgress' && (
      <LoadingSpinner
        data-testid="invitation-loading-icon"
        style={{ marginLeft: 5 }}
      />
    )}
  </Wrapper>
);

InvitationStatus.propTypes = {
  invitationStatus: PropTypes.string,
  platformInviteInProgress: PropTypes.string.isRequired,
  sendPlatformInvitation: PropTypes.func.isRequired,
};

InvitationStatus.defaultProps = {
  invitationStatus: null,
};

export default InvitationStatus;
