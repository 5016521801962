import { PopupButton } from '@typeform/embed-react';

import { omniConfig } from '@omnipresentgroup/design-system';

import { useSelectUser } from '../../../../utils/hooks';
import { useBenefitsSelectionContext } from './BenefitsSelectionContext';
import { ButtonsRow } from './BenefitsStep.styles';
import { getBenefitsTypeformId } from './getTypeformId';
import { changeTypeformUrl } from './TypeFormPopupUtil';

export const TYPEFORM_MODAL_SIZE = 80;

export const TypeFormPopupButtonDeprecated = ({
  onSubmit,
  text,
  testId,
}: {
  onSubmit: () => void;
  text: string;
  testId: string;
}) => {
  const { companyName, countryData, seat } = useBenefitsSelectionContext();
  const user = useSelectUser();

  const { firstName, lastName, id: seatId } = seat;

  const typeFormId = getBenefitsTypeformId(seatId);

  const hiddenFields = {
    company_name: companyName || 'company name not found',
    employee_name: `${firstName} ${lastName}`,
    manager_name: `${user?.firstName} ${user?.lastName}`,
    manager_email: user?.email,
    external_id: seatId,
  };

  const SetupBenefitsButton = () => (
    <PopupButton
      buttonProps={{ 'data-testid': testId }}
      onSubmit={onSubmit}
      id={typeFormId}
      size={TYPEFORM_MODAL_SIZE}
      hidden={hiddenFields}
      style={{
        border: 0,
        cursor: 'pointer',
        minHeight: omniConfig.theme.space[16],
        borderRadius: omniConfig.theme.radii.s,
        backgroundColor: omniConfig.theme.colors['gray-500'],
        color: omniConfig.theme.colors['white-base'],
        padding: `${omniConfig.theme.space[8]} ${omniConfig.theme.space[16]}`,
        fontFamily: omniConfig.theme.fonts.default,
        fontSize: omniConfig.theme.fontSizes[1],
      }}
      onReady={() => changeTypeformUrl(countryData.typeFormRef || '')}
    >
      {text}
    </PopupButton>
  );

  return (
    <ButtonsRow>
      <SetupBenefitsButton />
    </ButtonsRow>
  );
};
