import { getGrossToNetDetailView } from 'common/api/payrollTaxonomy.api';
import { useQuery } from 'react-query';
import { payrollKeys } from 'utils/queries/payroll/keys';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useGrossToNetDetailViewQuery = (
  country = '',
  lspId = '',
  payrollCycle = '',
  payrollPeriod = '',
) => {
  return useQuery(payrollKeys.grossToNetDetailView(country, lspId), async () =>
    getGrossToNetDetailView({
      country,
      lspId,
      payrollCycle,
      payrollPeriod,
    }).then(getDataFromResponse),
  );
};
