import { payrollServiceClient } from 'api';
import { HTTPStatusCodesEnum } from 'common/constants';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';
import { getMockedPaginatedPayrolllInstances } from 'omniplatform/admin/__mocks__/getMockedPaginatedPayrolllInstances';
import { mockPayrollInstance } from 'omniplatform/admin/__mocks__/payrollInstance.mock';

export const payrollServiceHandlers = [
  rest.post(
    composeEndpoint('/payroll-id/template', payrollServiceClient),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.text(`Internal OP ID,External OP ID,Provider,Country,Employee name,External payroll ID,\n
                f45975cf-3a8a-4fa4-8395-dbe1c498625b,e0cd8d20-2003-40ff-96bb-6c8dc23addca,123456,Guyana,sim jena,\n
                9e8f935e-37b5-4ebd-9cae-568d3dc65bf0,78fb4aa3-ab0b-4aa2-97e2-52d105adf533,123456,Guam,volih zaf,\n`),
      );
    },
  ),
  rest.post(
    composeEndpoint('/payroll-id', payrollServiceClient),
    (_req, res, ctx) => {
      const successResponse = {
        errors: [],
        employeesUpdated: [
          {
            name: 'Alec Antić',
            id: '24889979-f420-4ef6-bcaf-c97a63b768de',
          },
          {
            name: 'Alec Erickson',
            id: '3e2116b2-a42b-4f01-8a17-3080a00b43de',
          },
        ],
      };
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(successResponse));
    },
  ),
  rest.get(
    composeEndpoint('/workbook', payrollServiceClient),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(getMockedPaginatedPayrolllInstances()),
      ),
  ),
  rest.get(
    composeEndpoint('/workbook/:workbookId', payrollServiceClient),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockPayrollInstance)),
  ),
];
