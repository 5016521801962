import { getPaginatedWorkbooks } from 'common/api/payroll.api';
import { transformPayrollInstancesApiResponse } from 'common/store/transformers/payroll.transformer';
import { PayrollInstance, PayrollInstancesApiResponse } from 'common/types';
import { useInfiniteQuery } from 'react-query';
import { payrollKeys } from 'utils/queries/payroll/keys';
import { getDataFromResponse } from 'utils/react-query-utils';

export const usePaginatedWorkbooksInfiniteQuery = (params?: {
  nextPageKey?: number;
  pageSize?: number;
}) =>
  useInfiniteQuery<PayrollInstancesApiResponse, Error, PayrollInstance[]>(
    payrollKeys.payrollInstances(),
    async ({ pageParam }) =>
      getPaginatedWorkbooks({
        nextPageKey: pageParam,
        pageSize: params?.pageSize,
      }).then(getDataFromResponse),
    {
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      select: (data) => ({
        pages: data.pages.map((page) =>
          transformPayrollInstancesApiResponse(page.items),
        ),
        pageParams: data.pageParams,
      }),
    },
  );
