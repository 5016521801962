import { useState } from 'react';

import { HOLIDAY_TYPES } from 'appConstants';
import {
  StyledHolidayAllowanceCopy,
  StyledHolidayAllowanceInput,
  StyledHolidayDaysInput,
  StyledHolidayTypeDropdown,
} from 'common/components/molecules/HolidayAllowanceInput/HolidayAllowanceInput.styles';
import PropTypes from 'prop-types';
import { onWheelPreventChangeForNumberInput } from 'utils/input.helper';

const holidayTypeOptions = {
  name: 'holiday-allowance-type',
  options: [
    {
      value: HOLIDAY_TYPES.EXCLUDING_PUBLIC_HOLIDAYS,
      label: 'Excluding public holidays',
    },
    {
      value: HOLIDAY_TYPES.UNLIMITED,
      label: 'Unlimited time off',
    },
  ],
};

const HolidayAllowanceInput = ({
  value: holidayAllowance,
  onChange,
  name,
  onFocus,
  errorMessage,
}) => {
  const [unlimitedHolidaySelected, setUnlimitedHolidaySelected] = useState(
    holidayAllowance.type === HOLIDAY_TYPES.UNLIMITED,
  );

  return (
    <StyledHolidayAllowanceInput data-testid={`${name}-input`}>
      <StyledHolidayTypeDropdown
        onSelect={(_, selectedValue) => {
          onChange(name, {
            ...holidayAllowance,
            type: holidayTypeOptions.options.find(
              (option) => option.label === selectedValue,
            ).value,
            value:
              selectedValue === 'Unlimited time off'
                ? 0
                : holidayAllowance.value,
          });
          setUnlimitedHolidaySelected(selectedValue === 'Unlimited time off');
        }}
        placeholder="Select type..."
        options={holidayTypeOptions}
        value={holidayTypeOptions.options.find(
          (option) => option.value === holidayAllowance?.type,
        )}
      />
      <StyledHolidayDaysInput
        type="number"
        value={
          holidayAllowance?.value || holidayAllowance?.value === 0
            ? holidayAllowance.value
            : ''
        }
        name={`${name}-days-input`}
        data-testid={`${name}-days-input`}
        disabled={unlimitedHolidaySelected}
        onChange={({ target: { value: newValue, type } }) => {
          onChange(name, {
            ...holidayAllowance,
            value: type === 'number' ? parseInt(newValue, 10) : newValue,
          });
        }}
        error={errorMessage}
        autoComplete="chrome-off"
        onFocus={() => onFocus(name)}
        onWheel={onWheelPreventChangeForNumberInput}
      />
      <StyledHolidayAllowanceCopy disabled={unlimitedHolidaySelected}>
        working days
      </StyledHolidayAllowanceCopy>
    </StyledHolidayAllowanceInput>
  );
};

HolidayAllowanceInput.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  onFocus: PropTypes.func,
  name: PropTypes.string.isRequired,
};

HolidayAllowanceInput.defaultProps = {
  errorMessage: null,
  onFocus: () => {},
};

export default HolidayAllowanceInput;
