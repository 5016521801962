import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getUserSelector } from 'app/store/app.selectors';
import { TableFilterActionWrapper } from 'common/components/atoms/TableFilterAction/TableFilterAction.styled';
import Card from 'common/components/molecules/Card/Card';
import MobileCardListHeader from 'common/components/molecules/MobileCardListHeader/MobileCardListHeader';
import SearchInput from 'common/components/molecules/SearchInput/SearchInput';
import {
  INITIAL_FILTER_STATE,
  SeatTabFilter,
  SeatTableFilterType,
} from 'common/components/molecules/SeatTabFilter/SeatTabFilter';
import { FlexWrapper } from 'common/components/molecules/SeatTabFilter/SeatTabFilter.styles';
import { SOWDocumentModal } from 'common/components/organisms/SOWDocumentModal/SOWDocumentModal';
import TableWithTitle from 'common/components/organisms/TableWithTitle/TableWithTitle';
import { StyledProfilePictureSmall } from 'common/pages/EditEmployeePage/EditEmployeePage.styles';
import {
  SeatActionEnum,
  SeatStatusEnum,
  SOWStatusEnum,
  TransformedSeat,
} from 'common/types';
import { HireTypeEnum } from 'common/types/hire';
import isNil from 'lodash/isNil';
import { StyledShowingText } from 'omniplatform/manager/pages/DocumentListPage/DocumentListPage.styles';
import {
  ADD_HIRE_PATH,
  getConvertSeatChecklistPagePath,
  getEditSeatPath,
  getSeatConversionFormPagePath,
  getSeatPath,
} from 'paths';
import { useSelector } from 'react-redux';
import { getCountryCodeByCountryName } from 'utils/countries';
import { useUpdateEmployeeSeatMutation } from 'utils/queries';
import { isAdmin } from 'utils/user';

import { Viewport } from '@omnipresentgroup/design-system';

import avatarPlaceholderPicture from '../../../../../assets/img/avatar-placeholder.png';
import {
  StyledEmployeeCardTitle,
  StyledManagerEmployeesTableContainer,
} from '../../../../manager/pages/ManagerEmployeePage/ManagerEmployeePage.styles';
import { StyledNoEmployees, StyledTab } from '../CompanyDetailPage.styles';
import { SeatActionModal } from './ArchiveEmployeeModal/SeatActionModal';
import { seatsTableHeaders } from './seatsTableHeaders';

type Props = {
  seatsToDisplay: TransformedSeat[];
  companyId?: string;
  reloadSeats: () => void;
};

const seatHeadersForCard = seatsTableHeaders.filter(
  (header) => header.headerTitle !== 'Name' && header.id !== 'action',
);

export const ARCHIVE_SEAT = 'archive_seat';
export const CHANGE_COUNTRY_SEAT = 'change_country_seat';

export const SeatsTab = ({ seatsToDisplay, companyId, reloadSeats }: Props) => {
  const user = useSelector(getUserSelector);
  const history = useHistory();
  const [filter, updateFilter] =
    useState<SeatTableFilterType>(INITIAL_FILTER_STATE);

  const pendingSeatsToDisplay = seatsToDisplay.filter(
    (seat) => seat.seatStatus !== SeatStatusEnum.ARCHIVED,
  );

  const [seats, setSeats] = useState<TransformedSeat[]>(pendingSeatsToDisplay);
  const [filteredSeats, updateFilteredSeats] = useState<TransformedSeat[]>(
    pendingSeatsToDisplay,
  );

  const seatsHeader = seatsTableHeaders.filter(
    (header) => isAdmin(user) || header.id !== 'action',
  );

  const [showSeatModal, setShowSeatModal] = useState<TransformedSeat | null>();
  const [seatToDisplay, setSeatToDisplay] = useState<TransformedSeat | null>();
  const [visibleModal, setVisibleModal] = useState<string | undefined>(
    undefined,
  );

  const { mutate: updateSeat } = useUpdateEmployeeSeatMutation();

  const handleUpdateSeat = useCallback(
    (seatId: string) => {
      updateSeat(
        { seatToUpdate: { sowStatus: SOWStatusEnum.SOW_ACCEPTED }, seatId },
        {
          onSuccess: () => {
            reloadSeats();
          },
        },
      );
    },
    [reloadSeats, updateSeat],
  );

  const displayArchiveModal: any = (selectedSeat: TransformedSeat) => {
    setVisibleModal(ARCHIVE_SEAT);
    setSeatToDisplay(selectedSeat);
  };

  const displayCountryChangeModal: any = (selectedSeat: TransformedSeat) => {
    setVisibleModal(CHANGE_COUNTRY_SEAT);
    setSeatToDisplay(selectedSeat);
  };

  const handleSeatClick = (column: string, selectedSeat: TransformedSeat) => {
    const hasRequiredUpsellData = Boolean(
      selectedSeat?.countryName &&
        selectedSeat.billingCurrency &&
        selectedSeat.desiredStartDate &&
        selectedSeat.employeeProfile?.employeeType &&
        selectedSeat.employeeProfile.depositType &&
        !isNil(selectedSeat.employeeProfile.managementFeeAmount) &&
        !isNil(selectedSeat.employeeProfile.setupFeeAmount),
    );

    if (column === SeatActionEnum.ARCHIVE) {
      displayArchiveModal(selectedSeat);
    }

    if (column === SeatActionEnum.CHANGE_COUNTRY) {
      displayCountryChangeModal(selectedSeat);
    }

    if (column === SeatActionEnum.ORDER_FORM_ACCEPTED) {
      if (!hasRequiredUpsellData) {
        history.push(getEditSeatPath(selectedSeat.companyId, selectedSeat.id));
      } else {
        handleUpdateSeat(selectedSeat.id);
      }
    }

    if (column === 'name') {
      history.push(getSeatPath(selectedSeat.companyId, selectedSeat.id));
    }

    if (
      column === 'status' &&
      selectedSeat?.sowStatus === SOWStatusEnum.SOW_AWAITING_ACCEPTANCE
    ) {
      setShowSeatModal(selectedSeat);
    }

    if (
      column === 'status' &&
      selectedSeat?.sowStatus === SOWStatusEnum.SOW_ACCEPTED
    ) {
      const pathToNavigate = selectedSeat?.employeeProfile
        ? getSeatConversionFormPagePath(
            selectedSeat.companyId,
            selectedSeat.id,
            getCountryCodeByCountryName(selectedSeat.countryName),
          )
        : getConvertSeatChecklistPagePath(
            selectedSeat.companyId,
            selectedSeat.id,
          );

      history.push(pathToNavigate);
    }
  };

  const archiveSeatModalProps = {
    isOpen: true,
    isOnlyArchiveSeat: true,
    title: 'Archive pending employee?',
    subtitleName: 'Name',
    subtitleRoleOrCountry: 'Country',
    modalAlert:
      'Please note, once archived, the profile will no longer be displayed in the list of employees. This is a reversible action but recovery may take several days.',
    confirmButton: 'Archive',
  };

  const changeCountrySeatModalProps = {
    isOpen: true,
    isOnlyArchiveSeat: false,
    title: 'Change country?',
    subtitleName: 'Name',
    subtitleRoleOrCountry: 'Country',
    modalAlert:
      'The current seat will be archived and a new seat with the updated country will be added. This is a reversible action but recovery may take several days.',
    confirmButton: 'Change country',
  };

  const onFilteredData = (filteredData: TransformedSeat[]) =>
    updateFilteredSeats(filteredData);

  const onFilterUpdate = (updatedFilter: SeatTableFilterType) => {
    setSeats(
      pendingSeatsToDisplay.filter((seat) => {
        return Object.entries(updatedFilter).every(([key, value]) => {
          if (key === 'country' && value) {
            return seat.countryName === value;
          }
          if (key === 'status' && value.length) {
            return value.indexOf(seat.sowStatus) > -1;
          }
          return true;
        });
      }),
    );
    updateFilter(updatedFilter);
  };
  return (
    <StyledManagerEmployeesTableContainer className="smallStack">
      {visibleModal === ARCHIVE_SEAT && (
        <SeatActionModal
          employeeOrSeatDetails={{
            id: seatToDisplay!.id,
            name: seatToDisplay!.name,
            country: seatToDisplay!.countryName,
          }}
          {...archiveSeatModalProps}
          closeFn={() => setVisibleModal(undefined)}
          reloadEmployees={() => reloadSeats()}
        />
      )}
      {visibleModal === CHANGE_COUNTRY_SEAT && (
        <SeatActionModal
          employeeOrSeatDetails={{
            id: seatToDisplay!.id,
            name: seatToDisplay!.name,
            country: seatToDisplay!.countryName,
          }}
          {...changeCountrySeatModalProps}
          closeFn={() => setVisibleModal(undefined)}
          reloadEmployees={() => reloadSeats()}
        />
      )}
      {showSeatModal && (
        <SOWDocumentModal
          seat={showSeatModal}
          onModalClose={(refetch = false) => {
            setShowSeatModal(null);
            if (refetch) {
              reloadSeats();
            }
          }}
        />
      )}

      <StyledTab data-testid="seats-tab" className="smallStack">
        {!pendingSeatsToDisplay.length ? (
          <StyledNoEmployees
            addEmployeePath={
              companyId &&
              `${ADD_HIRE_PATH(companyId)}?type=${HireTypeEnum.employee}`
            }
          />
        ) : (
          <>
            <TableFilterActionWrapper>
              <SearchInput
                searchField="name"
                dataToSearch={seats}
                placeHolderText="Search by name"
                onFilteredData={onFilteredData}
                focus
              />
              <FlexWrapper spacing={10}>
                <div>
                  <StyledShowingText>
                    Showing 1 - {filteredSeats.length} of{' '}
                    {pendingSeatsToDisplay.length}
                  </StyledShowingText>
                </div>
                <SeatTabFilter
                  filters={filter}
                  onFilterUpdate={onFilterUpdate}
                />
              </FlexWrapper>
            </TableFilterActionWrapper>
            <Viewport devices={['laptop', 'desktop', 'highRes']}>
              <TableWithTitle
                onColumnItemClick={handleSeatClick}
                headers={seatsHeader}
                itemsToDisplay={filteredSeats}
                testId="seat-info"
              />
            </Viewport>
            <Viewport devices={['phone', 'tablet']}>
              <>
                <MobileCardListHeader total={filteredSeats.length} />
                {!!filteredSeats?.length &&
                  filteredSeats.map((seat) => (
                    <Card
                      key={seat.id}
                      fieldsToShow={seatHeadersForCard}
                      item={seat}
                      onColumnItemClick={handleSeatClick}
                      title={
                        <StyledEmployeeCardTitle>
                          <StyledProfilePictureSmall
                            src={avatarPlaceholderPicture}
                          />
                          <div
                            role="none"
                            onClick={() =>
                              history.push(getSeatPath(seat.companyId, seat.id))
                            }
                          >
                            {seat.name}
                          </div>
                        </StyledEmployeeCardTitle>
                      }
                    />
                  ))}
              </>
            </Viewport>
          </>
        )}
      </StyledTab>
    </StyledManagerEmployeesTableContainer>
  );
};
