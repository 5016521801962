export const employees = {
  employees: [
    {
      id: 'def2967b-f6fa-48a7-82f0-cf56ecd8b9y3',
      userId: '82e11408-5cd4-4ce1-8d54-73622f4930b5',
      name: 'Simone Testerona',
      country: 'Germany',
      currency: 'EUR',
      annualBaseSalary: '100000',
      testOnly: {
        expectedDisplayAnnualBaseSalary: 'EUR 100.000,00',
      },
      bankingDetails: {
        bicSwift: '1234',
        accountNumber: '1223123123123',
        routingNumber: '98838',
      },
      socialInsuranceNumber: {
        localName: 'Social insurance number',
        number: 'JX309029D',
      },
      jobTitle: 'CTO',
      startDate: '2020-07-27',
      onboardingStatus: 'Active',
    },
    {
      id: 'def2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
      userId: '82e11408-5cd4-4ce1-8d54-73622f4930b5',
      name: 'Roy Testerton',
      country: 'United Kingdom',
      currency: 'GBP',
      annualBaseSalary: '50000',
      testOnly: {
        expectedDisplayAnnualBaseSalary: 'GBP 50.000,00',
      },
      jobTitle: 'Software developer',
      startDate: '2020-07-20',
      onboardingStatus: 'In progress',
    },
  ],
};
