import styled from 'styled-components';

export const DropDownContainer = styled.div`
  position: relative;

  > button {
    background-color: ${({ theme: { colors } }) =>
      colors['background-primary']};
    border: ${({ theme: { borderStyles } }) => borderStyles.none};
    cursor: pointer;
  }

  svg {
    margin: ${({ theme: { space } }) => space[8]} auto;
  }
`;

export const ActionsContainer = styled.div<{ align?: string }>`
  position: absolute;
  width: 196px;
  padding-bottom: ${({ theme: { space } }) => space[16]};
  top: ${({ theme: { space } }) => space[32]};
  right: ${({ theme: { space } }) => space[8]};
  background: ${({ theme: { colors } }) => colors['background-primary']};
  box-shadow: ${({ theme: { shadows } }) => shadows.m};
  border-radius: ${({ theme: { radii } }) => radii.s};
  z-index: 1;

  ${({ align }) => {
    if (align) {
      return `text-align:${align}`;
    }
  }}
`;

export const StyledActionDivider = styled.hr`
  margin: ${({ theme: { space } }) =>
    `0 ${space[16]} ${space[16]} ${space[16]} ${space[16]}`};
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  border-bottom: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
`;

export const ActionsContainerTitle = styled.div`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  padding: ${({ theme: { space } }) => space[16]};
  color: ${({ theme: { colors } }) => colors['gray-300']};
`;

export const ActionItem = styled.div<{
  disabled?: boolean;
  hoverColor?: boolean;
}>`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  padding: ${({ theme: { space } }) => `${space[8]} ${space[16]}`};
  cursor: pointer;
  ${({ hoverColor, disabled, theme: { colors } }) => {
    if (hoverColor && !disabled) {
      return `
      :hover {
        background-color: ${colors['blue-100']};
      }
    `;
    }
  }}
  ${({ disabled, theme: { colors } }) => {
    if (disabled) {
      return ` 
      color: ${colors['gray-300']}
      cursor: not-allowed;        
      `;
    }
  }}
`;
