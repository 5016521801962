import { Button, Modal } from '@omnipresentgroup/design-system';

export const ConfirmDeleteLspBillModal = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Modal open onOpenChange={onClose}>
      <Modal.Header
        title="Delete employee(s)"
        description="Are you sure you wish to delete these employee(s)?"
      />
      <Modal.Footer>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={onConfirm}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );
};
