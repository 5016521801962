import { createQueryKeys } from 'utils/react-query-utils';

export const invoiceReportsKeys = createQueryKeys(
  'invoice-reports',
  (createKey) => ({
    managerInvoiceReports: (managerId: string) =>
      createKey(managerId, 'manager-invoice-reports'),
    balancesOverdue: (managerId: string) =>
      createKey(managerId, 'manager-invoice-reports-balances-overdue'),
  }),
);
