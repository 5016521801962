import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledAddDocumentPage = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme: { space } }) => space[32]};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    flex-direction: column;
  }
`;
