import { useMemo } from 'react';

import { UserRoleType } from 'common/types';
import {
  getUserMainRole,
  isAdmin,
  isCSAdmin,
  isEmployee,
  isIEAdmin,
  isLSP,
  isManager,
  isOPAdmin,
  isPayrollAdmin,
} from 'utils/user';

import { useSelectUser } from './useSelectUser';

type UserRoleVerification = Record<
  | 'isAdmin'
  | 'isManager'
  | 'isEmployee'
  | 'isLSP'
  | 'isIEAdmin'
  | 'isCSAdmin'
  | 'isOPAdmin'
  | 'isPayrollAdmin',
  boolean
>;

type UserRolesData = UserRoleVerification & {
  main: UserRoleType;
};

export const useSelectUserRoles = () => {
  const user = useSelectUser();

  return useMemo<UserRolesData>(
    () => ({
      main: getUserMainRole(user.roles)?.type,
      isAdmin: isAdmin(user),
      isManager: isManager(user),
      isEmployee: isEmployee(user),
      isLSP: isLSP(user),
      isIEAdmin: isIEAdmin(user),
      isCSAdmin: isCSAdmin(user),
      isOPAdmin: isOPAdmin(user),
      isPayrollAdmin: isPayrollAdmin(user),
    }),
    [user],
  );
};

export const useUserRoles = () => {
  const user = useSelectUser();
  return useMemo<UserRoleType[]>(
    () => user.roles.map((role) => role.type),
    [user],
  );
};
