import { AxiosError } from 'axios';
import { workflowTransitionPayReport } from 'common/api/payrollTaxonomy.api';
import { WorkflowTransitionPayReportPayload } from 'common/types';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const usePayReportWorkflowTransitionMutation = () => {
  return useMutation<
    void,
    AxiosError<string>,
    WorkflowTransitionPayReportPayload
  >((payload: WorkflowTransitionPayReportPayload) =>
    workflowTransitionPayReport(payload).then(getDataFromResponse),
  );
};
