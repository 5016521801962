import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: auto;
`;

export const StyledPageHeaderTitle = styled.div`
  display: flex;
  word-break: break-word;
`;

export const StyledTitleStatusContainer = styled.div`
  display: flex;
  align-items: start;

  h3 {
    margin-right: ${({ theme: { space } }) => space[16]};
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledBackLink = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    width: 100%;
  }
`;
