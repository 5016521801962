import styled from 'styled-components';

export const StyledTableWithTitleContainer = styled.div`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
  border-radius: ${({ theme: { radii } }) => radii.s};
  box-shadow: ${({ theme: { shadows }, showShadow }) =>
    `${showShadow ? shadows.s : 'none'}`};
  overflow: visible;
  position: relative;
  margin-bottom: 180px;
`;

export const StyledTitleCell = styled.p`
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.medium}`};
`;

export const ClickableCell = styled.span`
  cursor: pointer;
`;
