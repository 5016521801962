import { useEffect, useRef, useState } from 'react';

import { createWidget } from '@typeform/embed';
import appHistory from 'app/appHistory';
import { getLoadingSelector, getUserSelector } from 'app/store/app.selectors';
import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { updateEmployeePatchAction } from 'common/store/actions/employee.actions';
import { getSelectedEmployeeSelector } from 'common/store/selectors/employees.selector';
import {
  transformEmployeeForDetailsPage,
  transformUpdateEmployeePayload,
} from 'common/store/transformers/employees.transformer';
import { EMPLOYEE_PROFILE_PAGE } from 'paths';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getEmployeeOnboardingTypeFormID } from 'utils/employees';
import { useUpdateEmployeeMutation } from 'utils/queries';
import { employeesKeys } from 'utils/queries/employees/keys';
import { useEmployeeQuery } from 'utils/queries/employees/useEmployeeQuery';

import { Card, Inline } from '@omnipresentgroup/design-system';

import { TypeFormWrapper } from './EmployeeTypeFormPage.styles';

const EmployeeTypeFormPage = ({ loading, updateEmployee }) => {
  const typeformId = getEmployeeOnboardingTypeFormID();
  const [typeFormReady, setTypeFormReady] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [updatesError, setUpdatesError] = useState(false);

  const typeFormElement = useRef();

  const employee = useSelector(getSelectedEmployeeSelector);
  const user = useSelector(getUserSelector);
  const updateEmployeeMutation = useUpdateEmployeeMutation();
  const dispatch = useDispatch();

  const employeeDataQuery = useEmployeeQuery(employee.id, {
    staleTime: Infinity,
  });
  const queryClient = useQueryClient();
  const keyToInvalidate = employeesKeys.singleEmployee(employee.id);

  useEffect(() => {
    if (typeformId && !employeeDataQuery?.isLoading) {
      createWidget(typeformId, {
        container: typeFormElement.current,
        hideHeaders: true,
        transitiveSearchParams: ['first_name', 'last_name', 'email', 'country'],
        hideScrollbars: true,
        onSubmit: (event) => {
          const infoToUpdate = {
            typeformResponseId: event.responseId,
            typeformId,
          };
          setLoadingUpdate(true);
          updateEmployeeMutation.mutate(
            {
              infoToUpdate,
              employeeId: employee.id,
            },
            {
              onSuccess: () => {
                const typeformPayload =
                  transformUpdateEmployeePayload(infoToUpdate);
                const payload = transformEmployeeForDetailsPage({
                  ...employeeDataQuery.data,
                  typeformPayload,
                  requireTypeFormFilled: false,
                });
                dispatch(updateEmployeePatchAction(payload));

                appHistory.push(EMPLOYEE_PROFILE_PAGE);
                queryClient.invalidateQueries(keyToInvalidate);
              },
              onError: () => {
                setLoadingUpdate(false);
                setUpdatesError(true);
              },
            },
          );
        },
        onReady: () => {
          setTypeFormReady(true);
        },
      });
    }
  }, [
    typeFormElement,
    updateEmployee,
    typeformId,
    user,
    employeeDataQuery.data,
    employeeDataQuery?.isLoading,
    updateEmployeeMutation,
    employee.id,
    dispatch,
    queryClient,
    keyToInvalidate,
  ]);

  return (
    <Card p="24" interactive={false}>
      {loadingUpdate && <Loading />}
      {updatesError && <ErrorBanner />}

      {!employeeDataQuery?.isLoading && (
        <Inline justify="center" data-testid="welcome-profile-type-form">
          {loading && !typeFormReady && <Loading />}

          <TypeFormWrapper ref={typeFormElement} />
        </Inline>
      )}
    </Card>
  );
};

EmployeeTypeFormPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  updateEmployee: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: getLoadingSelector(state),
});

const mapDispatchToProps = {
  updateEmployee: updateEmployeePatchAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeTypeFormPage);
