import {
  mockActiveEmployersResponse,
  mockEmployersResponse,
} from '__mocks__/employers.mock';
import { HTTPStatusCodesEnum } from 'common/constants';
import { EmployerApiResponse, EmployerCreateApiRequest } from 'common/types';
import { isNil } from 'lodash';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

import { LEGAL_ENTITIES_BASE_API_ENDPOINT } from './employers.api';

export const employerHandlers = [
  rest.get<EmployerApiResponse[]>(
    composeEndpoint(LEGAL_ENTITIES_BASE_API_ENDPOINT),
    (_req, res, ctx) => {
      const query = _req.url.searchParams;
      const isActive = query.get('isActive');
      const isOmniEntity = query.get('isOmniEntity');

      let employersResponse = mockEmployersResponse;
      if (isActive) {
        employersResponse = mockActiveEmployersResponse;
      }

      if (!isNil(isOmniEntity)) {
        employersResponse = employersResponse.filter(
          (employer) => employer.isOmniEntity === (isOmniEntity === 'true'),
        );
      }

      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(employersResponse),
      );
    },
  ),
  rest.post<EmployerCreateApiRequest>(
    composeEndpoint(LEGAL_ENTITIES_BASE_API_ENDPOINT),
    (_req, res, ctx) => {
      return res(ctx.status(201), ctx.json({}));
    },
  ),
  rest.get<EmployerApiResponse>(
    composeEndpoint(`${LEGAL_ENTITIES_BASE_API_ENDPOINT}/:id`),
    (req, res, ctx) => {
      const employer = mockEmployersResponse.find(
        (item) => item.id === req.params.id,
      );

      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(req.params.id ? employer : mockEmployersResponse[0]),
      );
    },
  ),
  rest.put<EmployerCreateApiRequest>(
    composeEndpoint(`${LEGAL_ENTITIES_BASE_API_ENDPOINT}/:id`),
    (_req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK));
    },
  ),
];

export const employerErrorHandler = rest.get(
  composeEndpoint('/legal-entities'),
  (_req, res, ctx) => {
    return res.once(
      ctx.status(500),
      ctx.json({ error: 'Something went wrong' }),
    );
  },
);
