import { paginatedEmployeesResponse } from '__mocks__/employees.mock';
import { v4 as uuidv4 } from 'uuid';

export const getMockedPaginatedEployees = () => {
  return {
    nextPageKey: paginatedEmployeesResponse.nextPageKey,
    employees: paginatedEmployeesResponse.employees.map((item) => ({
      ...item,
      id: uuidv4(),
    })),
  };
};
