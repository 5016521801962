import styled from 'styled-components';

export const StyledSearchInputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SearchIconContainer = styled.div`
  width: ${({ theme: { space } }) => space[32]};
  margin-right: ${({ theme: { space } }) => space[8]};
`;

export const StyledSearchInput = styled.input`
  height: inherit;
  width: 230px;
  color: ${({ theme: { colors } }) => colors['text-subtle']};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};

  ::placeholder {
    color: ${({ theme: { colors } }) => colors['text-placeholder']};
    opacity: 1;
  }

  :input-placeholder {
    color: ${({ theme: { colors } }) => colors['text-placeholder']};
  }
`;
