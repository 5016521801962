import { useEffect, useRef } from 'react';

let firstFocusableEl: HTMLElement | null = null;
let lastFocusableEl: HTMLElement | null = null;

/**
 * Keep focus within a div element e.g. a modal
 * @returns a ref that can be attached to a div element
 */
export function useFocusTrap() {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let currentEl: HTMLElement | null = null;

    if (ref.current) {
      currentEl = ref.current;
      trapFocus(ref.current);
    }

    return () => {
      removeTrapFocus(currentEl!);
      firstFocusableEl = null;
      lastFocusableEl = null;
    };
  }, [ref]);

  return ref;
}

// Heavily modified from
// https://hidde.blog/using-javascript-to-trap-focus-in-an-element/
function trapFocus(element: HTMLElement) {
  const focusableEls = element.querySelectorAll(
    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])',
  );
  firstFocusableEl = focusableEls[0] as HTMLElement;
  lastFocusableEl = focusableEls[focusableEls.length - 1] as HTMLElement;

  element.addEventListener('keydown', keydownTrapFocus);
}

function removeTrapFocus(element: HTMLElement) {
  element.removeEventListener('keydown', keydownTrapFocus);
}

function keydownTrapFocus(event: KeyboardEvent) {
  if (event.key !== 'Tab') {
    return;
  }

  if (event.shiftKey) {
    /* shift + tab */
    if (document.activeElement === firstFocusableEl) {
      lastFocusableEl?.focus();
      event.preventDefault();
    }
  } /* tab */ else {
    if (document.activeElement === lastFocusableEl) {
      firstFocusableEl?.focus();
      event.preventDefault();
    }
  }
}
