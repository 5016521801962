import { Fragment, useState } from 'react';

import intersection from 'lodash/intersection';
import map from 'lodash/map';
import { Check, ChevronDown, ChevronUp } from 'react-feather';

import { Box, Inline } from '@omnipresentgroup/design-system';

import { OnboardingStage } from '../OnboardingProgressTracker/types';
import {
  StepContainerDrawer,
  StyledCheck,
  StyledCompletedCircleDrawer,
  StyledStageDrawer,
  StyledStageNumberDrawer,
} from './OnboardingProgressDrawer.style';

export type Props = {
  children: React.ReactNode;
  stage: OnboardingStage;
  completedSteps: string[];
  index: number;
  totalStages: number;
};

export const OnboardingProgressDrawerStage = ({
  children,
  stage,
  index,
  totalStages,
  completedSteps,
}: Props) => {
  const [collapse, setCollapse] = useState<boolean>(true);

  const stageStepsId = map(stage?.steps, 'id');
  const stageCompletedSteps = intersection(completedSteps, stageStepsId);
  const isCompleted = stageStepsId.length === stageCompletedSteps.length;
  const hasCompletedSteps = stageCompletedSteps.length >= 1;
  const isPartiallyCompleted = hasCompletedSteps && !isCompleted;

  const openOrCloseSpecificCollapse = () => {
    setCollapse(!collapse);
  };

  const chevronCss = {
    right: '$4',
  };

  return (
    <Fragment key={stage.id}>
      <Inline
        ml="16"
        gap="0"
        css={{ cursor: 'pointer' }}
        onClick={openOrCloseSpecificCollapse}
      >
        <Box>
          <StyledStageNumberDrawer
            isPartialCompleted={isPartiallyCompleted}
            isCompleted={isCompleted}
          >
            {isCompleted ? (
              <StyledCheck data-testid={`completed-stage-${stage.id}`}>
                <Check size={14} />
              </StyledCheck>
            ) : (
              <StyledCompletedCircleDrawer>
                {stage.id}
              </StyledCompletedCircleDrawer>
            )}
          </StyledStageNumberDrawer>
        </Box>
        <StyledStageDrawer>{stage.name}</StyledStageDrawer>
        <Box pos="absolute" css={chevronCss}>
          {collapse ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </Box>
      </Inline>
      <StepContainerDrawer isOpen={collapse} key={index + totalStages}>
        {children}
      </StepContainerDrawer>
    </Fragment>
  );
};
