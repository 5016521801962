import { client } from 'api';
import {
  LSPApiResponse,
  LSPApprovedEmployeeExpenses,
  LSPUpdateApiRequest,
  LSPUserApiResponse,
  SimplifiedEmployee,
  User,
} from 'common/types';

export const LOCAL_SERVICE_PROVIDERS_BASE_API_ENDPOINT =
  '/local-service-providers';

export const getLSPs = () => {
  return client.get<LSPApiResponse[]>(
    LOCAL_SERVICE_PROVIDERS_BASE_API_ENDPOINT,
  );
};

export const getLspDetails = (lspId: string) =>
  client.get<LSPApiResponse>(
    `${LOCAL_SERVICE_PROVIDERS_BASE_API_ENDPOINT}/${lspId}`,
  );

export const getLSPEmployees = (lspId: string) =>
  client.get<{ employees: SimplifiedEmployee[] }>(
    `/employees/lsp/${lspId}?simplified=true`,
  );

export const getLSPUsers = (lspId: string) =>
  client.get<{ users: User[] }>(
    `${LOCAL_SERVICE_PROVIDERS_BASE_API_ENDPOINT}/${lspId}/users`,
  );

export const getLSPApprovedMonthlyExpenses = (
  lspId: string,
  month: number,
  year: number,
) =>
  client.get<LSPApprovedEmployeeExpenses[]>(
    `expenses/lsp/${lspId}?month=${month}&year=${year}`,
  );

export const exportLSPApprovedMonthlyExpenses = (
  lspId: string,
  month: number,
  year: number,
) =>
  client.get<{ url: string }>(
    `expenses/lsp/${lspId}/export?month=${month}&year=${year}`,
  );

export const createLSPRequest = (data: Record<string, any>) => {
  return client.post<LSPApiResponse>(
    `${LOCAL_SERVICE_PROVIDERS_BASE_API_ENDPOINT}`,
    data,
  );
};

export const updateLSPRequest = async (
  lspId: string,
  data: LSPUpdateApiRequest,
) => {
  return client.patch<LSPApiResponse>(
    `${LOCAL_SERVICE_PROVIDERS_BASE_API_ENDPOINT}/${lspId}`,
    data,
  );
};

export const deleteLSPRequest = async (lspId: string) => {
  return client.delete(`${LOCAL_SERVICE_PROVIDERS_BASE_API_ENDPOINT}/${lspId}`);
};

export const createLSPUserRequest = async (
  lspId: string,
  data: Record<string, any>,
) => {
  return client.post<LSPUserApiResponse>(
    `${LOCAL_SERVICE_PROVIDERS_BASE_API_ENDPOINT}/${lspId}/users`,
    data,
  );
};

export const deleteLSPManagerRequest = (lspId: string, userId: string) =>
  client.delete(
    `${LOCAL_SERVICE_PROVIDERS_BASE_API_ENDPOINT}/${lspId}/users/${userId}`,
  );
