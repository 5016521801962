import styled, { css } from 'styled-components';

import { omniConfig } from '@omnipresentgroup/design-system';

import type { Props as ButtonProps } from './Button';

// TODO: Refactor once the chosen styles are agreed on
const buttonBorderWidth = omniConfig.theme.borderWidths.s;
const buttonBorderStyle = omniConfig.theme.borderStyles.solid;
const buttonBorderRadius = omniConfig.theme.radii.s;

export const baseButton = css`
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  cursor: pointer;
  min-height: 40px;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: ${buttonBorderRadius};
  max-width: 640px;
  width: fit-content;
  opacity: 1;
  padding: ${({ theme: { space } }) => `${space[8]} ${space[16]}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  font-family: ${({ theme: { fonts } }) => fonts.default};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};

  :disabled {
    cursor: not-allowed;
  }
`;

export const defaultPalette = css`
  background-color: ${({ theme: { colors } }) => colors['gray-100']};
  color: ${({ theme: { colors } }) => colors['gray-400']};
  border: ${buttonBorderWidth} ${buttonBorderStyle}
    ${({ theme: { colors } }) => colors['border-subtle']};
`;

const primaryOutlined = css`
  border: ${buttonBorderWidth} ${buttonBorderStyle}
    ${({ theme: { colors } }) => colors['gray-500']};
  color: ${({ theme: { colors } }) => colors['gray-500']};
  background: white;

  :hover:not(:disabled) {
    background-color: ${({ theme: { colors } }) => colors['gray-100']};
    border-color: ${({ theme: { colors } }) => colors['border-strong']};
  }

  :disabled {
    background-color: ${({ theme: { colors } }) => colors['white-base']};
    border-color: ${({ theme: { colors } }) => colors['border-subtle']};
    color: ${({ theme: { colors } }) => colors['gray-200']};
  }

  :active:not(:disabled),
  :focus:not(:disabled) {
    background-color: ${({ theme: { colors } }) => colors['gray-200']};
    border-color: ${({ theme: { colors } }) => colors['gray-400']};
  }
`;

const primaryGhost = css`
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  background: none;
  color: ${({ theme: { colors } }) => colors['gray-500']};

  :hover:not(:disabled) {
    background-color: ${({ theme: { colors } }) => colors['gray-200']};
  }
`;

const primaryLink = css`
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  background: transparent;
  color: ${({ theme: { colors } }) => colors['blue-500']};
  padding: ${({ theme: { space } }) => `${space[4]}`};

  :hover:not(:disabled) {
    color: ${({ theme: { colors } }) => colors['blue-300']};
    text-decoration: none;
  }

  :disabled {
    color: ${({ theme: { colors } }) => colors['gray-300']};
  }
`;

const primary = css`
  :hover:not(:disabled) {
    background-color: ${({ theme: { colors } }) => colors['gray-400']};
  }

  :disabled {
    background-color: ${({ theme: { colors } }) => colors['gray-300']};
  }

  :active:not(:disabled),
  :focus:not(:disabled) {
    background: ${({ theme: { colors } }) => colors['gray-400']};
  }
`;

const primaryPalette = css<ButtonProps>`
  background-color: ${({ theme: { colors } }) =>
    colors['button-background-primary-default']};
  color: ${({ theme: { colors } }) => colors['white-base']};

  ${({ variant }) => {
    switch (variant) {
      case 'outlined':
        return primaryOutlined;
      case 'ghost':
        return primaryGhost;
      case 'link':
        return primaryLink;
      default:
        return primary;
    }
  }};
`;

const secondaryOutlined = css`
  border: ${buttonBorderWidth} ${buttonBorderStyle}
    ${({ theme: { colors } }) => colors['gray-500']};
  color: ${({ theme: { colors } }) => colors['gray-500']};
  background: white;

  :hover:not(:disabled) {
    background-color: ${({ theme: { colors } }) => colors['gray-100']};
    border-color: ${({ theme: { colors } }) => colors['border-strong']};
  }

  :disabled {
    background-color: ${({ theme: { colors } }) => colors['white-base']};
    border-color: ${({ theme: { colors } }) => colors['border-subtle']};
    color: ${({ theme: { colors } }) => colors['gray-200']};
  }

  :active:not(:disabled),
  :focus:not(:disabled) {
    background-color: ${({ theme: { colors } }) => colors['gray-200']};
    border-color: ${({ theme: { colors } }) => colors['gray-400']};
  }
`;

const secondaryGhost = css`
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  background: white;
  color: ${({ theme: { colors } }) => colors['gray-500']};

  :hover:not(:disabled) {
    background-color: ${({ theme: { colors } }) => colors['gray-200']};
  }
`;

const secondaryLink = css`
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  background: transparent;
  color: ${({ theme: { colors } }) => colors['blue-500']};
  padding: ${({ theme: { space } }) => `${space[4]}`};

  :hover:not(:disabled) {
    color: ${({ theme: { colors } }) => colors['blue-300']};
    text-decoration: none;
  }

  :disabled {
    color: ${({ theme: { colors } }) => colors['gray-300']};
  }
`;

const secondary = css`
  :hover:not(:disabled) {
    background-color: ${({ theme: { colors } }) => colors['gray-400']};
  }

  :disabled {
    background-color: ${({ theme: { colors } }) => colors['gray-300']};
  }

  :active:not(:disabled),
  :focus:not(:disabled) {
    background: ${({ theme: { colors } }) => colors['gray-400']};
  }
`;

const secondaryPalette = css<ButtonProps>`
  background-color: ${({ theme: { colors } }) => colors['gray-500']};
  color: ${({ theme: { colors } }) => colors['white-base']};

  ${({ variant }) => {
    switch (variant) {
      case 'outlined':
        return secondaryOutlined;
      case 'ghost':
        return secondaryGhost;
      case 'link':
        return secondaryLink;
      default:
        return secondary;
    }
  }};
`;

export const StyledButton = styled.button<ButtonProps>`
  ${baseButton}

  ${({ palette }) => {
    switch (palette) {
      case 'primary':
        return primaryPalette;
      case 'secondary':
        return secondaryPalette;
      default:
        return defaultPalette;
    }
  }};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${({ theme: { space } }) => `${space[4]}`};
  }
`;
