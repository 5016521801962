import { client, employmentTimeClient } from 'api';

import {
  CompanyTimeOffLogsResponse,
  CreateTimeOffLogsPayload,
  EmployeeTimeOffLogsResponse,
  TimeOffLog,
} from '../types';

export const TIME_OFF_LOGS_BASE_API_ENDPOINT = (enableNewTimeOff: boolean) =>
  enableNewTimeOff ? '/timeoff' : '/time-off';

export const composeTimeOffLogsEndpointByScope = (
  scope: 'employee' | 'company',
  id: string,
  enableNewTimeOff: boolean,
) =>
  `${TIME_OFF_LOGS_BASE_API_ENDPOINT(
    enableNewTimeOff,
  )}/by-${scope}-id/${id}/requests`;

export const getEmployeeTimeOffLogsRequest = (
  employeeId: string,
  enableNewTimeOff: boolean,
) => {
  const queryClient = enableNewTimeOff ? employmentTimeClient : client;
  return queryClient.get<EmployeeTimeOffLogsResponse>(
    composeTimeOffLogsEndpointByScope('employee', employeeId, enableNewTimeOff),
  );
};

export const getEmployeeTimeOffLogsByYearMonthRequest = (
  employeeId: string,
  year: number,
  month: number,
  enableNewTimeOff: boolean,
) => {
  const queryClient = enableNewTimeOff ? employmentTimeClient : client;
  return queryClient.get<TimeOffLog[]>(
    `${TIME_OFF_LOGS_BASE_API_ENDPOINT(
      enableNewTimeOff,
    )}/by-employee-id/${employeeId}/requests?year=${year}&month=${month}`,
  );
};

export const createEmployeeTimeOffLogRequest = (
  newTimeOff: CreateTimeOffLogsPayload,
  enableNewTimeOff: boolean,
) => {
  const queryClient = enableNewTimeOff ? employmentTimeClient : client;
  return queryClient.post<TimeOffLog>(
    TIME_OFF_LOGS_BASE_API_ENDPOINT(enableNewTimeOff),
    newTimeOff,
  );
};

export const getCompanyTimeOffLogsRequest = (
  companyId: string,
  enableNewTimeOff: boolean,
) => {
  const queryClient = enableNewTimeOff ? employmentTimeClient : client;
  return queryClient.get<CompanyTimeOffLogsResponse>(
    composeTimeOffLogsEndpointByScope('company', companyId, enableNewTimeOff),
  );
};

export const deleteEmployeeTimeOffLogRequest = (
  timeOffLogid: string,
  enableNewTimeOff: boolean,
) => {
  const queryClient = enableNewTimeOff ? employmentTimeClient : client;
  return queryClient.delete<void>(
    `${TIME_OFF_LOGS_BASE_API_ENDPOINT(
      enableNewTimeOff,
    )}/requests/${timeOffLogid}`,
  );
};
