import PropTypes from 'prop-types';

import { StyledSmallCopy } from '../../atoms/Text/Copy.styles';
import { StyledMobileCardListHeader } from './MobileCardListHeader.styles';

const MobileCardListHeader = ({ total }) => {
  return (
    <StyledMobileCardListHeader>
      <StyledSmallCopy>
        Showing 1 - {total} of {total}
      </StyledSmallCopy>
    </StyledMobileCardListHeader>
  );
};

MobileCardListHeader.propTypes = {
  total: PropTypes.number,
};

MobileCardListHeader.defaultProps = {
  total: null,
};

export default MobileCardListHeader;
