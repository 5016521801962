import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  CreateEmployeeIntegrationDetails,
  MergeEmployeeExpandedFieldsDTO,
} from 'common/types';
import { useSelectCompanySelectedHRISIntegration } from 'utils/hooks';
import {
  HRISIntegrationTransformedData,
  hrisIntegrationTransformer,
} from 'utils/transformer-utils/hrisIntegration.transformer';

import {
  HRISFormIntegrationContextData,
  HRISFormIntegrationProviderProps,
  HRISOption,
} from './hrisFormIntegration.context.types';

const INITIAL_IMPORTED_EMPLOYEE_STATE = {} as MergeEmployeeExpandedFieldsDTO;

const HRISFormIntegrationContext =
  createContext<HRISFormIntegrationContextData | null>(null);
HRISFormIntegrationContext.displayName = 'HRISFormIntegrationContext';

type ContextProps = PropsWithChildren<HRISFormIntegrationProviderProps>;

export const HRISFormIntegrationContextProvider = (props: ContextProps) => {
  const { integrationId } = useSelectCompanySelectedHRISIntegration();

  const [hasFilledForm, setHasFilledForm] = useState(false);
  const [refetchRetries, setRefetchRetries] = useState(0);
  const [selectedOption, setSelectedOption] = useState<HRISOption>(null);
  const [importedEmployee, setImportedEmployee] = useState(
    INITIAL_IMPORTED_EMPLOYEE_STATE,
  );

  const { onFillForm, onClearFilledData, children } = props;

  const isRefetchDisabled = refetchRetries === 2;

  const handleImportEmployeeData = useCallback(() => {
    setHasFilledForm(true);

    const transformedData = hrisIntegrationTransformer(importedEmployee);

    const integrationDetails: CreateEmployeeIntegrationDetails = {
      integrationId,
      externalEmployeeId: importedEmployee.id,
      externalRemoteId: importedEmployee.remote_id,
    };

    onFillForm(integrationDetails, (formStages) => {
      const fieldsToBeUpdated = Object.keys(transformedData) as Array<
        keyof HRISIntegrationTransformedData
      >;

      return formStages.map((stage) => ({
        ...stage,
        fields: stage?.fields?.map((field) => {
          if (field.name === 'country') {
            return field;
          }

          if (field.name === 'annualBaseSalary') {
            return {
              ...field,
              value: {
                currency: transformedData.currency || field.value.currency,
                amount: transformedData.amount || field.value.amount,
              },
            };
          }

          if (field.name === 'locationOfWork') {
            return {
              ...field,
              value: {
                ...field.value,
                address: {
                  ...field.value.address,
                  city: transformedData.city || field.value.address.city,
                },
              },
            };
          }

          const fieldToBeUpdated = fieldsToBeUpdated.find(
            (fieldName) => fieldName === field.name,
          );

          if (!fieldToBeUpdated) {
            return field;
          }

          return {
            ...field,
            value: transformedData[fieldToBeUpdated] || field.value,
          };
        }),
      }));
    });
  }, [onFillForm, importedEmployee, integrationId]);

  const handleResetForm = useCallback(() => {
    setSelectedOption(null);
    setHasFilledForm(false);
    setImportedEmployee(INITIAL_IMPORTED_EMPLOYEE_STATE);
    onClearFilledData();
  }, [onClearFilledData]);

  const contextValue = useMemo<HRISFormIntegrationContextData>(
    () => ({
      hasFilledForm,
      integrationId,
      importedEmployee,
      selectedOption,
      setImportedEmployee,
      setSelectedOption,
      handleImportEmployeeData,
      handleResetForm,
      setRefetchRetries,
      isRefetchDisabled,
    }),
    [
      hasFilledForm,
      integrationId,
      importedEmployee,
      selectedOption,
      handleImportEmployeeData,
      handleResetForm,
      isRefetchDisabled,
    ],
  );

  return (
    <HRISFormIntegrationContext.Provider value={contextValue}>
      {children}
    </HRISFormIntegrationContext.Provider>
  );
};

export const useHrisIntegrationContext = () => {
  const context = useContext(HRISFormIntegrationContext);

  if (!context) {
    throw new Error(
      'useHrisIntegrationContext must be used within a HRISFormIntegrationContextProvider',
    );
  }

  return context;
};
