import { AxiosError } from 'axios';
import { createUserRequest, CreateUserResponse } from 'common/api/users.api';
import { AdditionalAdminRoleType, UserRoleType } from 'common/types';
import { useMutation } from 'react-query';

import { getDataFromResponse } from '../../../react-query-utils';

export type AddAdminUserFormSubmissionResult = {
  firstName: string;
  lastName: string;
  email: string;
  additionalAdminRoles?: AdditionalAdminRoleType[];
};

export const useCreateAdminUserMutation = () => {
  return useMutation<
    CreateUserResponse,
    AxiosError,
    AddAdminUserFormSubmissionResult
  >(({ firstName, lastName, email, additionalAdminRoles }) => {
    const createAdminUserRequestPayload = {
      firstName,
      lastName,
      email,
      roles: [
        {
          type: 'admin' as UserRoleType,
        },
      ],
    };

    if (additionalAdminRoles) {
      additionalAdminRoles.forEach((role) =>
        createAdminUserRequestPayload.roles.push({
          type: role,
        }),
      );
    }

    return createUserRequest(createAdminUserRequestPayload).then(
      getDataFromResponse,
    );
  });
};
