import { transformContentfulCollection } from 'common/store/transformers/contentful.transformers';
import { TerritoryEntry } from 'common/types/contentful';
import { useQuery } from 'react-query';
import { getContentfulClient } from 'utils/contentful/client';

import { contentfulKeys } from '../keys';

export const useContentfulCountriesQuery = () => {
  return useQuery(
    contentfulKeys.territories,
    () =>
      getContentfulClient().getEntries<TerritoryEntry>({
        content_type: 'territory',
        select: 'fields.name,fields.flagImage,metadata,fields.keyFact',
        limit: 300,
        order: 'fields.name',
      }),
    {
      select: (data) =>
        transformContentfulCollection<TerritoryEntry>(data) as TerritoryEntry[],
    },
  );
};
