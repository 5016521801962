import type { AdminUpdateValidStatuses } from 'common/api/globalSetup.msw';
import {
  GlobalSetupErrorStatus as ErrorStatus,
  GlobalSetup,
  GSType,
  GlobalSetupStatus as Status,
} from 'common/types/globalSetup';

import type { OptionType } from '@omnipresentgroup/design-system';

export const mockVeSupportedCountries: OptionType[] = [
  { value: 'DE', label: 'Germany' },
  { value: 'ES', label: 'Spain' },
];

export const mockGlobalSetupDe: GlobalSetup = {
  id: 'DE:adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
  companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
  countryCode: 'de',
  type: GSType.VirtualEmployer,
  involvedManagers: [
    {
      id: 'def2967b-f6fa-48a7-82f0-cf82ecd8b5b3',
      name: 'E2e Manager',
      email: 'e2e-manager@omnie2etesting.com',
    },
  ],
  status: Status.InfoCollected,
  supportingData: {
    uploadedDocuments: [],
    jotformInformationLink: '',
  },
};

export const mockGlobalSetupEs: GlobalSetup = {
  ...mockGlobalSetupDe,
  id: 'ES:adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
  countryCode: 'es',
  status: Status.DocsApproved,
  involvedManagers: [
    ...mockGlobalSetupDe.involvedManagers,
    {
      id: 'def2967b-f6fa-48a7-82f0-cf82ecd8b5b3',
      name: 'Tina Testington',
      email: 't.testington@somecompany.fr',
    },
  ],
};

export const mockGlobalSetups: GlobalSetup[] = [
  mockGlobalSetupDe,
  mockGlobalSetupEs,
];

export const mockVeAdminUpdateStatusesDE: AdminUpdateValidStatuses = [
  [
    { name: Status.InviteSent, isValid: false },
    { name: Status.Started, isValid: false },
    { name: Status.InfoCollected, isValid: false },
    { name: Status.DocsApproved, isValid: true },
    { name: Status.Active, isValid: false },
  ],
  [
    { name: ErrorStatus.InfoCollectedForm, isValid: true },
    { name: ErrorStatus.InfoCollectedDocuments, isValid: true },
  ],
];

export const mockVeAdminUpdateStatusesES: AdminUpdateValidStatuses = [
  [
    { name: Status.InviteSent, isValid: false },
    { name: Status.Started, isValid: false },
    { name: Status.InfoCollected, isValid: false },
    { name: Status.DocsApproved, isValid: false },
    { name: Status.AwaitingPhysicalDocuments, isValid: true },
    { name: Status.PhysicalDocumentsReceived, isValid: false },
    { name: Status.Active, isValid: false },
  ],
  [
    { name: ErrorStatus.InfoCollectedForm, isValid: false },
    { name: ErrorStatus.InfoCollectedDocuments, isValid: false },
    {
      name: ErrorStatus.PhysicalDocuments,
      isValid: false,
    },
  ],
];
