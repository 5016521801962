import { deleteGrossToNetFileBulk } from 'common/api/payrollTaxonomy.api';
import { BulkRequest } from 'common/types';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useDeleteGrossToNetFileBulkMutation = () => {
  return useMutation((data: BulkRequest) =>
    deleteGrossToNetFileBulk(data).then(getDataFromResponse),
  );
};
