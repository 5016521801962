import styled, { css } from 'styled-components';

export const CrumbWrapper = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    gap: 6px;
    align-items: center;
    margin-top: ${space[16]};

    & > div {
      display: inherit;
      align-items: center;
    }

    & svg {
      margin-left: ${space[8]};
      height: ${space[16]};
      color: ${colors['icon-default']};
    }
  `,
);

export const Crumb = styled.span(
  ({ theme: { colors, fontSizes } }) => css`
    font-size: ${fontSizes[0]};
    font-weight: 400;
    color: ${colors['text-default']};
    cursor: pointer;
    z-index: 1;

    &:hover {
      color: ${colors['text-default']};
      text-decoration: underline;
    }
  `,
);

export const ActiveCrumb = styled.span(
  ({ theme: { colors, fontSizes } }) => css`
    font-size: ${fontSizes[0]};
    font-weight: 700;
    color: ${colors['text-default']};
  `,
);
