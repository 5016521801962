import { ChangeEvent, ReactNode, Ref } from 'react';

import { FormCheckbox } from 'common/components/molecules/FormCheckbox';
import TableRow from 'common/components/organisms/Table/TableRow';

import * as S from './List.styles';

export type ListColumn = {
  headerTitle: string;
  fieldOnObject?: string;
  customCell?: ReactNode;
};

export type ListProps<T> = {
  columns: Array<ListColumn>;
  items?: Array<Record<string, unknown>>;
  itemKeyExtractor?: (item: Record<string, unknown>) => string;
  hasCheckboxSelection?: boolean;
  enableSelectAllCheckbox?: boolean;
  customCellCallback?: (
    event: ChangeEvent<HTMLInputElement>,
    model: T[],
  ) => void;
  selectAllRef?: Ref<HTMLInputElement | undefined>;
};

export const List = <T extends unknown>({
  items,
  columns,
  itemKeyExtractor,
  hasCheckboxSelection,
  enableSelectAllCheckbox,
  customCellCallback,
  selectAllRef,
}: ListProps<T>) => {
  return (
    <S.StyledContainer>
      <S.StyledTable>
        <S.StyledTableHeader>
          <S.StyledHeaderRow>
            {columns.map(
              (column: ListColumn) =>
                (column.fieldOnObject && (
                  <S.StyledTableHeaderCell key={column.fieldOnObject}>
                    {column.headerTitle}
                  </S.StyledTableHeaderCell>
                )) ||
                (enableSelectAllCheckbox && customCellCallback && items && (
                  <S.StyledTableHeaderCell key="empty">
                    <FormCheckbox
                      key="checkbox-employees"
                      id="checkbox-employees"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        customCellCallback(event, items as T[])
                      }
                      role="checkbox"
                      name="checkbox-employees"
                      label=""
                      size={20}
                      ref={selectAllRef}
                    />
                  </S.StyledTableHeaderCell>
                )) || <S.StyledTableHeaderCell key="empty" />,
            )}
          </S.StyledHeaderRow>
        </S.StyledTableHeader>
        <S.StyledTableBody hasCheckbox={hasCheckboxSelection}>
          {items &&
            itemKeyExtractor &&
            items.map((dataPoint) => (
              <TableRow
                key={`${itemKeyExtractor(dataPoint)}`}
                objectForRow={dataPoint}
                headers={columns}
                onColumnItemClick={customCellCallback}
              />
            ))}
        </S.StyledTableBody>
      </S.StyledTable>
    </S.StyledContainer>
  );
};
