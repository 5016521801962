import {
  Checkbox,
  Inline,
  Link,
  Typography,
} from '@omnipresentgroup/design-system';
import type { CheckboxProps } from '@omnipresentgroup/design-system/dist/types/components/Checkbox/Checkbox';

const DOCUMENT_LINK = '/ClientTermsContractors.pdf';

export const TermsCheckbox = (props: CheckboxProps) => {
  const { id, ...rest } = props;

  return (
    <Inline gap="4" align="center">
      <Checkbox id={id} {...rest} />
      <Typography as="label" size="14" htmlFor={id}>
        I agree to the{' '}
        <Link size="14" target="_blank" to={DOCUMENT_LINK}>
          Client Terms
        </Link>{' '}
        of Omnipresent.
      </Typography>
    </Inline>
  );
};
