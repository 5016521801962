import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-self: center;
`;

export const Container = styled.div`
  width: 50%;
  margin: 0 auto;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-left: 0;
    width: 100%;
  }
`;

export const AlertContainer = styled.div`
  margin: ${({ theme: { space } }) => space[8]} 0;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-left: 0;
    width: 100%;
  }
`;

export const SectionContainer = styled.div`
  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-left: 0;
    width: 100%;
  }
`;

export const SectionContainerWrapper = styled.div`
  background: ${({ theme: { colors } }) => colors['background-primary']};
  padding: ${({ theme: { space } }) => space[64]};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  border-radius: ${({ theme: { radii } }) => radii.s};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    padding: ${({ theme: { space } }) =>
      `${space[8]} ${space[8]} 0 ${space[8]}`};
  }
`;

export const RequiredFields = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${({ theme: { colors } }) => `${colors['text-subtle']}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
  margin-bottom: ${({ theme: { space } }) => space[4]};
`;
