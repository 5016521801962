import { useEffect, useState } from 'react';

import { getTenantIdSelector } from 'common/store/selectors/companies.selectors';
import { FormData, User } from 'common/types';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTrack } from 'utils/mixpanel/tracker';
import { useCompanyManagersQuery } from 'utils/queries';
import { useGetGlobalSetupQuery } from 'utils/queries/globalSetup/useGetGlobalSetupQuery/useGetGlobalSetupQuery';
import { useVeInviteMutation } from 'utils/queries/virtualEntity';
import { useVeSupportedCountriesQuery } from 'utils/queries/virtualEntity/useVeSupportedCountriesQuery';
import { sortAlphabetically } from 'utils/sort.util';

import {
  Box,
  Button,
  Dropdown,
  Inline,
  Stack,
} from '@omnipresentgroup/design-system';

import { ManagerOption } from './InviteForm.types';

// type InviteData = {
//   managerEmail: string;
//   countryCode: string;
// };

type InviteFormProps = {
  setError: () => void;
  setSuccess: () => void;
};

export const InviteForm = ({ setError, setSuccess }: InviteFormProps) => {
  const [managersOptions, setManagersOptions] = useState<ManagerOption[]>();

  const {
    isLoading: countriesQueryIsLoading,
    isSuccess: countriesQueryIsSuccess,
    isError: countriesQueryIsError,
    data: countriesManagersData,
  } = useVeSupportedCountriesQuery();

  const companyId = useSelector(getTenantIdSelector) as string;
  const {
    isLoading: companyQueryIsLoading,
    isSuccess: companyQueryIsSuccess,
    isError: companyQueryIsError,
    data: companyManagersData,
  } = useCompanyManagersQuery(
    { companyId },
    { select: (data) => sortAlphabetically('name', data as User[]) },
  );

  useEffect(() => {
    if (countriesQueryIsError || companyQueryIsError) {
      setError();
    }
  }, [countriesQueryIsError, companyQueryIsError, setError]);

  useEffect(() => {
    if (companyQueryIsSuccess && companyManagersData) {
      const managers = companyManagersData.map(
        (manager) =>
          ({
            value: {
              userId: manager.userId,
              email: manager.email,
            },
            label: manager.name,
          }) as ManagerOption,
      );
      setManagersOptions(managers);
    }
  }, [companyManagersData, companyQueryIsSuccess]);

  const { refetch } = useGetGlobalSetupQuery(companyId);
  const onSuccess = () => {
    // allow time for the db to update
    setTimeout(refetch, 1000);
    setSuccess();
  };

  const { mutate, isLoading: isSending } = useVeInviteMutation({
    onError: setError,
    onSuccess,
  });

  const tracker = useTrack();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    mutate({
      userId: data.manager.value.userId,
      countryCode: data.countryCode.value,
    });

    tracker('Global Setup Client Manager Invite Sent', {
      entityType: 'virtual employer',
      countryCode: String(data.countryCode.value),
      managerEmail: String(data.manager.value.email),
    });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    reValidateMode: 'onChange',
  });

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="16" w={385}>
        <Controller
          key="countryCode"
          name="countryCode"
          control={control}
          rules={{ required: 'Please select a country' }}
          render={({ field }) => (
            <Dropdown
              id="countryCode"
              label="Country"
              placeholder="Choose a country"
              isDisabled={countriesQueryIsError || isSending}
              isLoading={countriesQueryIsLoading}
              options={
                countriesQueryIsSuccess && countriesManagersData
                  ? [{ options: countriesManagersData }]
                  : []
              }
              errorMessage={errors['countryCode']?.message}
              {...field}
            />
          )}
        />
        <Controller
          key="manager"
          name="manager"
          control={control}
          rules={{ required: 'Please select a client manager' }}
          render={({ field }) => (
            <Dropdown
              id="manager"
              label="Manager to invite"
              placeholder="Choose a manager"
              isDisabled={companyQueryIsError || isSending}
              isLoading={companyQueryIsLoading}
              options={managersOptions ? [{ options: managersOptions }] : []}
              errorMessage={errors['manager']?.message}
              menuPosition="fixed"
              {...field}
            />
          )}
        />
      </Stack>

      <Inline justify="end" mt="32">
        <Button type="submit" loading={isSending}>
          Send invite
        </Button>
      </Inline>
    </Box>
  );
};
