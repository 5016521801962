import { StyledButton, StyledButtonContainer } from './Button.styles';

export interface Props extends React.ComponentPropsWithoutRef<'button'> {
  children: React.ReactNode;
  testId?: string;
  icon?: React.ReactNode;
  palette?: 'primary' | 'secondary';
  variant?: 'outlined' | 'ghost' | 'link';
}

/**
 * @deprecated
 * Use the OmniDS Button component instead
 * https://omnipresentgroup.github.io/OmniDS/?path=/docs/atoms-button--default
 * Please reach out to #web-platform-support for questions about using the OmniDS components
 */
const Button = ({
  children,
  icon,
  type = 'button',
  testId = 'omniplatform-button-testid',
  ...props
}: Props) => (
  <StyledButton data-testid={testId} type={type} {...props}>
    <StyledButtonContainer>
      {icon}
      {children}
    </StyledButtonContainer>
  </StyledButton>
);

export default Button;
