import { useEffect } from 'react';

import Button from 'common/components/atoms/Button';
import styled from 'styled-components';
import { useTrack } from 'utils/mixpanel/tracker';

import { BulkUploadValidationError } from '../types';
import { ValidationAccordion } from '../ValidationAccordion/ValidationAccordion';
import { EmployeeValidationMessage } from './EmployeeValidationMessage';

const Wrapper = styled.div`
  padding: ${({ theme: { space } }) => space[32]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const Separator = styled.div`
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  margin: ${({ theme: { space } }) => `${space[32]} ${space[0]}`};
`;

type ReviewErrorPageProps = {
  totalEmployees: number;
  invalidEmployees: BulkUploadValidationError;
  goToUploadPage: () => void;
  companyId: string;
  bulkUploadId: string;
};

export const ReviewErrorPage = ({
  totalEmployees,
  invalidEmployees,
  goToUploadPage,
  bulkUploadId,
  companyId,
}: ReviewErrorPageProps) => {
  const track = useTrack();
  const validateEmployees = totalEmployees - invalidEmployees.length;

  useEffect(() => {
    track('Import Employee BulkUpload Completed', {
      clientId: companyId,
      bulkUploadId,
      totalUploadEmployees: totalEmployees,
      totalSuccessEmployees: 0,
      totalErrorEmployees: 0,
      totalInvalidEmployees: invalidEmployees,
    });
  }, [bulkUploadId, totalEmployees, invalidEmployees, track, companyId]);

  return (
    <Wrapper>
      <div>
        <EmployeeValidationMessage
          type="success"
          message={`${validateEmployees} employees are good to go!`}
          description={`${validateEmployees} employees have been successfully validated and are ready to be imported to employees list.`}
        />
        <Separator />
        <EmployeeValidationMessage
          type="warning"
          message={`${invalidEmployees.length} employees need your attention`}
          description="Please review the employees below before they can be successfully added."
        />
        <div style={{ marginTop: 24 }}>
          <ValidationAccordion invalidEmployees={invalidEmployees} />
        </div>
      </div>
      <Button
        style={{ marginLeft: 'auto' }}
        palette="primary"
        onClick={() => {
          goToUploadPage();
        }}
      >
        Upload new file
      </Button>
    </Wrapper>
  );
};
