import styled from 'styled-components';

export const StyledModalContainer = styled.div`
  left: 0;
  top: 0;
  margin: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  background: ${({ theme: { colors } }) => colors.overlay};
  justify-content: center;
  align-items: center;
`;

export const StyledModalContents = styled.div<{ fullScreen?: boolean }>`
  display: flex;
  width: 500px;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
  border-radius: ${({ theme: { radii } }) => radii.s};
  padding: ${({ theme: { space } }) => space[16]};

  ${({ fullScreen }) => {
    if (fullScreen) {
      return `
        height: calc(100vh - 80px);
        width: calc(100vw - 60px);
      `;
    }
  }}
`;

export const StyledModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
