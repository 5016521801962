import { HireTypeEnum } from 'common/types/hire';
import { ADD_CONTRACTOR_PATH, getAddEmployeeSeatPath } from 'paths';

type HireTypeDetails = {
  title: string;
  moreInfo?: {
    link: string;
    linkText: string;
  };
  items: { id: string; name: string }[];
  nextPagePath: (companyId: string, country?: string) => string;
};

export const HIRE_TYPE_DETAILS_MAP: Record<HireTypeEnum, HireTypeDetails> = {
  [HireTypeEnum.employee]: {
    title: 'Employees',
    items: [
      { id: '0', name: 'Receive a regular salary from the employer.' },
      {
        id: '1',
        name: 'Are entitled to employee benefits like healthcare, pension, and paid leave.',
      },
      { id: '2', name: 'Works set hours and days as defined by the employer.' },
      {
        id: '3',
        name: 'Have their work tasks, methods, and work environment dictated or provided by the employer.',
      },
      {
        id: '4',
        name: 'Income taxes are withheld by employer, who also pays social security.',
      },
    ],
    nextPagePath: (companyId, country) =>
      getAddEmployeeSeatPath(companyId, country),
  },
  [HireTypeEnum.contractor]: {
    title: 'Contractors',
    moreInfo: {
      link: 'https://www.omnipresent.com/articles/understanding-the-risks-of-hiring-independent-contractors',
      linkText: 'More about contractors',
    },
    items: [
      {
        id: '0',
        name: 'Are paid on a per-project basis or through a specific contract term and invoice companies directly for their services.',
      },
      {
        id: '1',
        name: 'Are ineligible for company-provided benefits like healthcare or pensions.',
      },
      {
        id: '2',
        name: 'Choose their own working hours, and retain control over work methods, approach, and execution details.',
      },
      {
        id: '3',
        name: 'Are responsible for their own taxes, including self-employment and business expenses.',
      },
      {
        id: '4',
        name: "Aren't covered by labour laws for unemployment rights and job protections.",
      },
    ],
    nextPagePath: (companyId) =>
      `${process.env.VITE_MANAGER_APP}${ADD_CONTRACTOR_PATH(companyId)}`,
  },
};
