import { createQueryKeys } from 'utils/react-query-utils';

export const terminationRequestsKeys = createQueryKeys(
  'requests',
  (createKey) => ({
    default: createKey('requests'),
    single: (id?: string) => createKey(id),
    allTerminationRequests: (companyId?: string) => createKey(companyId),
  }),
);
