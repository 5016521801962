import { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import { Inline } from '@omnipresentgroup/design-system';

import DropdownInput from '../DropdownInput/DropdownInput';
import { groupDependentOptions } from '../EditableInput/EditableInput';

const formatGroupLabel = (data) => (
  <Inline color="placeholder" radius="s">
    {data.label}
  </Inline>
);

const DependentDropdownInput = ({
  label,
  options,
  onSelect,
  value,
  onFocus,
  error,
  placeholder,
  isDisabled,
}) => {
  const groupedOptions = useMemo(
    () => ({
      options: groupDependentOptions(options.options),
      name: options.name,
    }),
    [options],
  );
  useEffect(() => {
    if (value && Array.isArray(groupedOptions.options)) {
      const isAvailableOption = groupedOptions.options[0]?.options.some(
        (option) => option.value === value.value,
      );
      if (!isAvailableOption && groupedOptions.name !== undefined) {
        onSelect(groupedOptions.name, '');
      }
    }
  }, [value, groupedOptions, onSelect]);
  return (
    <DropdownInput
      label={label}
      options={groupedOptions}
      onSelect={onSelect}
      value={value}
      onFocus={onFocus}
      error={error}
      placeholder={placeholder}
      isDisabled={isDisabled}
      formatGroupLabel={formatGroupLabel}
    />
  );
};

DependentDropdownInput.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onFocus: PropTypes.func,
  error: PropTypes.string,
  label: PropTypes.string,
};

DependentDropdownInput.defaultProps = {
  placeholder: 'Select...',
  value: null,
  className: '',
  onFocus: () => {},
  error: '',
  isLoading: false,
  isDisabled: false,
};

export default DependentDropdownInput;
