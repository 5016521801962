export type GenericObject = Record<string, unknown>;

export type NullableObjectKeys<T extends GenericObject> = {
  [P in keyof T]: T[P] | null;
};

export type Dictionary<T> = { [key: string]: T };

export type DayOfWeek =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

export enum FileFormat {
  CSV = 'csv',
  PDF = 'pdf',
}
