import { client, clientInvoicesClient, payrollServiceClient } from 'api';
import Big from 'big.js';
import {
  BenefitInvoiceAPIRequest,
  BenefitsUploadResponse,
  BillingItemApiResponse,
  ClientExportApiResponse,
  ClientInvoicesApiResponse,
  EmployeeBillingItemApiResponse,
  ExportDownloadLinkApiResponse,
  ExportInvoiceApiRequest,
  ExportInvoiceDataToCsvApiResponse,
  ImportedBenefitsBillingsByClientsResponse,
  ImportedBenefitsBillingsStatsResponse,
  InvoiceFromSpreadsheet,
  InvoiceId,
  InvoicesLogsListResponse,
  InvoicesLogsQueryOptions,
  PaginatedBillingItemApiResponse,
  PaginatedNetSuiteCustomersApiResponse,
  PayrollInstanceApiRequest,
  PayrollInstanceApiResponse,
  PayrollInstancesApiResponse,
} from 'common/types';
import queryString from 'query-string';
import { removeFalsy } from 'utils/objects';

export const PAYROLL_INVOICES_ROUTE = '/payroll/invoices';

export const getInvoices = (params: {
  'invoice-id-list': string[];
  payrollInstanceId?: string;
  view?: string;
}) => {
  return client.get(PAYROLL_INVOICES_ROUTE, { params });
};

export const deleteInvoice = (invoiceId: InvoiceId) =>
  client.delete(`/payroll/invoices/${invoiceId}`);

export const createBenefitInvoice = (
  benefitInvoice: BenefitInvoiceAPIRequest,
) => client.post(`/payroll/benefits-invoice`, benefitInvoice);

export const getBillingItems = () =>
  client.get<BillingItemApiResponse[]>('/payroll/billing-items');

export const getPaginatedBillingItems = (params: {
  nextPageKey?: string;
  size?: number;
  employeeId?: string;
  companyId?: string;
  lspId?: string;
  minInvoiceDate?: string;
  maxInvoiceDate?: string;
}) =>
  client.get<PaginatedBillingItemApiResponse>(
    '/payroll/billing-items/paginated',
    { params },
  );

export const getWorkbooksDetails = (workbookId: string) =>
  payrollServiceClient.get<PayrollInstanceApiResponse>(
    `/workbook/${workbookId}`,
  );

export const getPaginatedWorkbooks = (params: {
  nextPageKey?: string;
  pageSize?: number;
}) =>
  payrollServiceClient.get<PayrollInstancesApiResponse>('/workbook/', {
    params,
  });

export const validatePayrollInstance = (payrollInstanceId: string) => {
  return clientInvoicesClient.post(
    `/payroll/payroll-instances/${payrollInstanceId}/validate`,
  );
};

export const createWorkbook = (workbook: PayrollInstanceApiRequest) =>
  payrollServiceClient.post('/workbook', removeFalsy(workbook));

export const approveInvoices = (invoiceIds: string[]) => {
  return client.patch('/payroll/invoices/approve', {
    invoiceIds: [...invoiceIds],
  });
};

export const getEmployeeBillingItems = (params: {
  employeeId: string;
  size: number;
}) =>
  client.get<EmployeeBillingItemApiResponse[]>(
    `/payroll/billing-items/employee/${params.employeeId}`,
    {
      params: {
        size: params.size,
      },
    },
  );

export const getClientInvoices = (params: {
  nextPageKey?: string;
  pageSize?: number;
  payrollInstanceId: string;
  ids?: string[];
}) =>
  clientInvoicesClient.get<ClientInvoicesApiResponse>(
    `/payroll/client-invoices`,
    {
      params: params,
    },
  );

export const createLspExportView = (billingItemsIds: string[]) =>
  createExportView(billingItemsIds, 'lsp');

export const createClientExportView = (billingItemsIds: string[]) =>
  createExportView(billingItemsIds, 'client');

export const processInstructions = (billingItemsIds: string[]) =>
  client.post('payments/process', {
    billingItemIds: [...billingItemsIds],
  });

export const exportClientInvoicesToNetsuite = (
  payrollInstanceId: string,
  clientInvoiceIds: string[],
) =>
  clientInvoicesClient.post(
    `/payroll/payroll-instances/${payrollInstanceId}/generate`,
    {},
    {
      params: { clientInvoiceIds: [...clientInvoiceIds] },
      paramsSerializer: (params) => {
        return queryString.stringify(params, { arrayFormat: 'bracket' });
      },
    },
  );

export const downloadLSPPaymentsCSV = async (billingItemsIds: string[]) => {
  const {
    data: { url },
  } = await client.post('payments/export', {
    billingItemIds: [...billingItemsIds],
  });

  window.open(url, '_self');
};

export const exportClientInvoicesToCsv = async (
  payrollInstanceId: string,
  clientInvoiceIds: string[],
) => {
  const {
    data: { url },
  } = await clientInvoicesClient.post(
    `payroll/payroll-instances/${payrollInstanceId}/export`,
    {},
    {
      params: { clientInvoiceIds: [...clientInvoiceIds] },
      paramsSerializer: (params) =>
        queryString.stringify(params, { arrayFormat: 'bracket' }),
    },
  );
  window.open(url, '_self');
};

export const processInvoices = (billingItemsIds: string[]) =>
  client.post('invoices/process', {
    billingItemIds: [...billingItemsIds],
  });

export const createExportView = (
  billingItemsIds: string[],
  exportView: 'client' | 'lsp',
) =>
  client.post<ClientExportApiResponse>('/payroll/billing-items/export', {
    exportView,
    billingItemIdList: [...billingItemsIds],
  });

export const exportInvoiceDataToCsv = async (
  formStateData: ExportInvoiceApiRequest,
) => {
  const {
    data: { url },
  } = await client.post<ExportInvoiceDataToCsvApiResponse>(
    'payroll/invoices/export-to-csv',
    formStateData,
  );

  return { url };
};

export const exportFXRatesDataToCsv = async (
  fromCurrency?: string,
  toCurrency?: string,
) => {
  const {
    data: { url },
  } = await client.get<{ url: string }>(
    '/payroll/fx-exchange-rates/export-to-csv',
    {
      params:
        typeof fromCurrency === 'undefined' || typeof toCurrency === 'undefined'
          ? {}
          : { fromCurrency, toCurrency },
    },
  );
  window.open(url, '_self');
};

export const downloadPayrollExportCSV = async ({
  exportId,
  exportType,
}: {
  exportId: string;
  exportType: 'clientInvoice' | 'lspPayment';
}) => {
  const url =
    (exportType === 'clientInvoice' &&
      `payroll/client-invoice-export/${exportId}/download-link`) ||
    (exportType === 'lspPayment' &&
      `payroll/payment-instruction-export/${exportId}/download-link`);

  const {
    data: { downloadLink },
  } = await client.post<ExportDownloadLinkApiResponse>(url || '');

  window.open(downloadLink, '_self');
};

export const getFxRateForFees = (fromCurrency: string, toCurrency: string) =>
  client.get<Big>('/payroll/invoices/fx-rate', {
    params: { fromCurrency, toCurrency },
  });

export const parseInvoiceSpreadsheet = (filename: string, lspId: string) =>
  client.post<InvoiceFromSpreadsheet>(
    '/payroll/invoices/parse-employees-costs',
    {
      filename,
      lspId,
    },
  );

export const getInvoicesLogs = (
  queryOptions: InvoicesLogsQueryOptions,
  paginationOptions?: { nextPageKey?: string },
) =>
  paginationOptions
    ? client.post<InvoicesLogsListResponse>(
        '/payroll/invoice-logs',
        queryOptions,
        {
          params: paginationOptions,
        },
      )
    : client.post<InvoicesLogsListResponse>(
        '/payroll/invoice-logs',
        queryOptions,
      );

export const parseBenefitsSpreadsheet = (
  fileName: string,
  month: number,
  year: number,
  payrollInstanceId: string,
  countryCode: string,
  allCountries: boolean,
) => {
  return client.post<BenefitsUploadResponse>('/payroll/benefits/import', {
    fileName,
    month,
    year,
    payrollInstanceId,
    countryCode,
    allCountries,
  });
};

export const getImportedBenefitsBillingsStats = (payrollInstanceId: string) => {
  return client.get<ImportedBenefitsBillingsStatsResponse>(
    '/payroll/benefits/import',
    {
      params: { payrollInstanceId },
    },
  );
};

export const getImportedBenefitsBillingsByClient = (
  payrollInstanceId: string,
  clientId: string,
  countryCode: string,
) => {
  return client.get<ImportedBenefitsBillingsByClientsResponse>(
    `/payroll/benefits/import/client/${clientId}`,
    {
      params: { payrollInstanceId, countryCode },
    },
  );
};

export const getNetSuiteCustomersPaginatedRequest = (params: {
  nextPageKey?: string;
  pageSize?: number;
}) =>
  client.get<PaginatedNetSuiteCustomersApiResponse>('/invoices/customers', {
    params,
  });

export const parseWorkbook = (filename: string, payrollInstanceId: string) =>
  client.post('/payroll/workbook/import', {
    filename,
    payrollInstanceId,
  });
