import {
  Condition,
  Field,
  FIELD_TYPE,
  FormQuestionSetConfig,
  FormStage,
  User,
} from 'common/types';
import Joi from 'joi';
import { isCSAdmin, isOPAdmin } from 'utils/user';

const formBaseConfig = {
  formTitle: 'Add document',
  formCopy: 'Upload a document.',
  SuccessScreen: null,
};

const stageBaseConfig = {
  name: 'Document details',
  stage: 1,
};

const baseQuestions: FormQuestionSetConfig = [
  {
    field: {
      name: 'title',
      label: 'Title',
      type: FIELD_TYPE.STRING,
      value: '',
      error: null,
      required: true,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: TODO
    validation: Joi.string()
      .required()
      .messages({ 'string.empty': 'Please enter a title' }),
  },
  {
    field: {
      name: 'description',
      label: 'Description of the document',
      type: FIELD_TYPE.STRING,
      value: '',
      error: null,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: TODO
    validation: Joi.string().allow('', null),
  },
  {
    field: {
      name: 'linkToDocument',
      label: 'Document',
      type: FIELD_TYPE.DOCUMENT,
      value: null,
      error: null,
      required: true,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: TODO
    validation: Joi.any()
      .required()
      .custom((value: string) => {
        if (value === null) {
          throw new Error();
        }
      })
      .messages({ 'any.custom': 'Please upload a document' }),
  },
];

const typeQuestion = {
  field: <Field>{
    name: 'type',
    label: 'Type of the document',
    type: FIELD_TYPE.DROPDOWN,
    options: [
      { value: 'document', label: 'Document' },
      { value: 'contract', label: 'Contract' },
    ],
  },
  validation: Joi.string().allow('document', 'contract'),
};

const versionQuestion = {
  field: <Field>{
    name: 'version',
    label: 'Version of the contract',
    type: FIELD_TYPE.NUMBER,
    condition: <Condition>{
      Equals: [
        {
          Att: 'type',
        },
        {
          String: 'contract',
        },
      ],
    },
  },
  validation: Joi.number()
    .required()
    .messages({
      'any.required': 'Please enter a version number',
    })
    .required(),
};

const statusQuestion = {
  field: {
    name: 'status',
    label: 'Contract status',
    type: FIELD_TYPE.DROPDOWN,
    options: [
      { value: 'draft', label: 'Draft' },
      { value: 'pending-internal-review', label: 'Approved by manager' },
      { value: 'approved', label: 'Internal review completed' },
      { value: 'signed:employee', label: 'Signed by employee only' },
      { value: 'signed:both-parties', label: 'Signed by both parties' },
    ],
    placeholder: 'Choose a status...',
    value: '',
    condition: <Condition>{
      Equals: [
        {
          Att: 'type',
        },
        {
          String: 'contract',
        },
      ],
    },
  },
  validation: Joi.string()
    .allow(
      'draft',
      'approved',
      'pending-internal-review',
      'signed:employee',
      'signed:both-parties',
    )
    .messages({
      'string.empty': 'Please select an option',
    }),
};

const contractUploadQuestions = [typeQuestion, versionQuestion, statusQuestion];

const generateFormStage = (user: User): FormStage => {
  const questionnaireCSAdmin = [
    ...baseQuestions.slice(0, 2),
    ...contractUploadQuestions,
    ...baseQuestions.slice(-1),
  ];

  const questionnaire =
    isCSAdmin(user) || isOPAdmin(user) ? questionnaireCSAdmin : baseQuestions;

  const fields: Field[] = [];
  const validationKeys: any = {};
  Object.values(questionnaire).forEach((question) => {
    fields.push(question.field);
    validationKeys[question.field.name] = question.validation;
  });

  const schema = Joi.object().keys(validationKeys);

  return {
    ...stageBaseConfig,
    fields,
    schema,
  };
};

export const getFormConfig = (user: User) => ({
  ...formBaseConfig,
  stages: [generateFormStage(user)],
});
