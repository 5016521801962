import type { Icon } from 'react-feather';

import { Stack, Typography } from '@omnipresentgroup/design-system';

import * as S from './CardIconsList.styles';

type ItemWithIcon = {
  Icon: Icon;
  label: string;
};

export type Props = {
  header: string;
  items: ItemWithIcon[];
};

export const CardIconsList = ({ header, items }: Props) => (
  <S.CardContainer>
    <Stack align="left">
      <Typography as="label" size="16" weight="medium">
        {header}
      </Typography>
    </Stack>
    <S.ListContainer>
      {items.map(({ Icon, label }) => (
        <S.ListRow key={label}>
          <Icon />
          {label}
        </S.ListRow>
      ))}
    </S.ListContainer>
  </S.CardContainer>
);
