import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledMobileButton = styled.button`
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 48px;
  background-color: ${({ theme: { colors } }) => colors['gray-500']};
  color: ${({ theme: { colors } }) => colors['white-base']};
  padding: ${({ theme: { space } }) => space[4]};
  box-shadow: ${({ theme: { shadows } }) => shadows.s};
  cursor: pointer;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  font-family: ${({ theme: { fonts } }) => fonts.default};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};

  :focus {
    outline: 0;
  }

  :disabled {
    opacity: ${tokens.opacity[2]};
  }
`;
