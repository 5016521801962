import { useCallback, useEffect, useState } from 'react';

import { useMergeLink as useRawMergeLink } from '@mergeapi/react-merge-link';
import {
  createMergeIntegrationStartRequest,
  MergeIntegrationStartResponse,
} from 'common/api/integrations.api';
import {
  createAddHRISIntegrationAction,
  removeHRISIntegrationAction,
} from 'common/store/actions/companies.actions';
import { useDispatch } from 'react-redux';

import { useSelectCompanySelected } from './redux-selectors';

const TOKENS_INITIAL_STATE: MergeIntegrationStartResponse = {
  linkToken: '',
  integrationId: '',
};

export const useMergeLink = () => {
  const dispatch = useDispatch();

  const {
    id: companyId,
    companyName,
    integrations,
  } = useSelectCompanySelected();

  const [tokens, setTokens] = useState(TOKENS_INITIAL_STATE);
  const { linkToken, integrationId } = tokens;

  const onSuccess = useCallback(
    (publicToken: string) => {
      setTokens(TOKENS_INITIAL_STATE);
      dispatch(
        createAddHRISIntegrationAction({
          companyId,
          integrationId,
          publicToken,
        }),
      );
    },
    [companyId, integrationId, dispatch],
  );

  const deleteIntegration = useCallback(
    (id: string) => {
      return () => {
        dispatch(
          removeHRISIntegrationAction({
            companyId,
            integrationId: id,
          }),
        );
      };
    },
    [companyId, dispatch],
  );

  const mergeSetup = useRawMergeLink({ linkToken, onSuccess });

  useEffect(() => {
    async function getLinkToken() {
      const response = await createMergeIntegrationStartRequest({
        companyId,
        companyName,
      });

      setTokens(response.data);
    }

    if (!integrations.hris.length) {
      getLinkToken();
    }
  }, [companyId, companyName, integrations.hris.length]);

  return { ...mergeSetup, deleteIntegration };
};
