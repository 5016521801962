import PropTypes from 'prop-types';

import {
  StyledDateSorter,
  StyledDownArrow,
  StyledUpArrow,
} from './DateSort.styles';

const DateSort = ({ onUpArrowClicked, onDownArrowClicked }) => (
  <StyledDateSorter>
    Date <StyledUpArrow data-testid="asc-icon" onClick={onUpArrowClicked} />{' '}
    <StyledDownArrow data-testid="desc-icon" onClick={onDownArrowClicked} />{' '}
  </StyledDateSorter>
);

DateSort.propTypes = {
  onUpArrowClicked: PropTypes.func.isRequired,
  onDownArrowClicked: PropTypes.func.isRequired,
};

export default DateSort;
