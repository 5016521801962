import { queryClient } from 'app/queryClient';
import { updateUserAssociations } from 'common/api/userAssociations.api';
import { useMutation } from 'react-query';

import { managerPermissionsKeys } from '../keys';

export const useUpdateUserAssociationsMutation = ({
  companyId,
}: {
  companyId: string;
}) => {
  return useMutation(
    ({
      userId,
      associatedUserIds,
    }: {
      userId: string;
      associatedUserIds: string[];
    }) => updateUserAssociations(userId, associatedUserIds),
    {
      onSuccess: (_data, variables) => {
        queryClient.refetchQueries(
          managerPermissionsKeys.getEmployees(companyId, variables.userId),
        );
      },
    },
  );
};
