import { updateCompanyEmployeeSeatRequest } from 'common/api/seats.api';
import { Seat } from 'common/types';
import { useMutation } from 'react-query';

// TODO: Implement in SC-901 subtasks
export const useUpdateEmployeeSeatMutation = () => {
  return useMutation(
    ({
      seatToUpdate,
      seatId,
    }: {
      seatToUpdate: Partial<Seat>;
      seatId: string;
    }) => updateCompanyEmployeeSeatRequest(seatId, seatToUpdate),
  );
};
