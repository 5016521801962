import { Departments } from 'appConstants';
import JoiImport from 'joi';
import ianaDomains from 'utils/ianaDomains.json';

import DateExtension from '@omnipresentgroup/joi-date';

import { AddCompanyManagerSuccess } from './AddCompanyManagerSuccess';

const Joi = JoiImport.extend(DateExtension);

export const addCompanyManagerFormConfig = {
  formTitle: 'Add a manager',
  formCopy: 'Once completed you will be able to invite them to the platform',
  stages: [
    {
      name: 'Manager details',
      stage: 1,
      fields: [
        {
          name: 'firstName',
          label: "Manager's first name",
          type: 'string',
          value: '',
          error: null,
          required: true,
        },
        {
          name: 'lastName',
          label: "Manager's last name",
          type: 'string',
          value: '',
          error: null,
          required: true,
        },
        {
          name: 'jobTitle',
          label: 'Job Title',
          type: 'string',
          value: '',
          error: null,
          required: true,
        },
        {
          name: 'department',
          label: 'Department',
          type: 'departmentType',
          value: '',
          error: null,
          required: true,
        },
        {
          name: 'email',
          label: "Manager's email address",
          type: 'email',
          value: '',
          error: null,
          required: true,
        },
      ],
      schema: Joi.object().keys({
        firstName: Joi.string()
          .required()
          .messages({ 'string.empty': 'Please enter a first name' }),
        lastName: Joi.string()
          .required()
          .messages({ 'string.empty': 'Please enter a last name' }),
        jobTitle: Joi.string()
          .required()
          .messages({ 'string.empty': 'Please enter a job title' }),
        department: Joi.string()
          .required()
          .valid(...Departments)
          .messages({
            'string.empty': 'Please select a department',
            'string.base': 'Please select a department',
          }),
        email: Joi.string()
          .prefs({ convert: false })
          .email({ tlds: { allow: ianaDomains } })
          .trim()
          .messages({
            'string.email': 'Please enter a valid email',
            'string.empty': 'Please enter an email address',
            'string.trim':
              'Email addresses should not contain any spaces at the beginning or end',
          }),
      }),
    },
  ],
  SuccessScreen: AddCompanyManagerSuccess,
};
