import { getVeSupportedCountriesRequest } from 'common/api/globalSetup.api';
import { ONE_MINUTE } from 'common/constants';
import { useQuery, UseQueryOptions } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import type { OptionType } from '@omnipresentgroup/design-system';

export const useVeSupportedCountriesQuery = (
  options?: Pick<UseQueryOptions, 'staleTime' | 'select'>,
) => {
  return useQuery<OptionType[]>(
    've-supported-countries',
    () => getVeSupportedCountriesRequest().then(getDataFromResponse),
    {
      refetchOnWindowFocus: false,
      staleTime: options?.staleTime ?? ONE_MINUTE * 5,
      select: (data: OptionType[]) =>
        (options?.select ? options?.select(data) : data) as OptionType[],
    },
  );
};
