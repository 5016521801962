import { useEffect, useState } from 'react';

import { ErrorBanner } from 'common/components';
import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { X } from 'react-feather';
import { useTrack } from 'utils/mixpanel/tracker';

import {
  Box,
  Button,
  Inline,
  Typography,
} from '@omnipresentgroup/design-system';

import { InviteForm } from '../InviteForm/InviteForm';
import { CloseButton, SuccessIcon } from './InviteModal.styles';

export enum GSInviteModalEventSource {
  Header = 'header',
  EmptyState = 'empty state',
  None = 'none',
}

type InviteModalProps = {
  close: () => void;
  eventSource: GSInviteModalEventSource;
};

const InviteModal = ({ close, eventSource }: InviteModalProps) => {
  const [showSendingError, setShowSendingError] = useState<boolean>(false);
  const [showSuccess, setSuccess] = useState<boolean>(false);

  const tracker = useTrack();

  useEffect(() => {
    tracker('Global Setup Client Manager Invite Modal Shown', { eventSource });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showSendingError && (
        <ErrorBanner onErrorClear={() => setShowSendingError(false)} />
      )}

      <ModalWrapper
        fixedWidth={showSuccess ? '495' : '650'}
        inLineCss={{ position: 'relative' }}
        onClose={close}
      >
        {!showSuccess && (
          <Box m="32" mt="16">
            <CloseButton onClick={close} aria-label="Close">
              <X aria-hidden focusable={false} />
            </CloseButton>
            <Typography as="h4" hideParagraphSpacing size="20" weight="medium">
              Send Invite
            </Typography>
            <Box my="16">
              <Typography as="p" hideParagraphSpacing size="16">
                Use the drop-down menus below to choose a manager to invite to
                the country-specific virtual employer flow.
              </Typography>
            </Box>
            <Typography as="span" size="16">
              Once you click <em>Send Invite</em>, an email will be sent to the
              manager with details on the process and a link to access the
              OmniCreate platform.
            </Typography>

            <Box my="16">
              <InviteForm
                setError={() => setShowSendingError(true)}
                setSuccess={() => setSuccess(true)}
              />
            </Box>
          </Box>
        )}

        {showSuccess && (
          <Box m="32" mt="16">
            <CloseButton onClick={close} aria-label="Close">
              <X aria-hidden focusable={false} />
            </CloseButton>
            <Typography as="h4" hideParagraphSpacing size="20" weight="medium">
              Invite Sent
            </Typography>

            <SuccessIcon />

            <Box mt="16">
              <Typography as="p" hideParagraphSpacing size="16">
                An invite has been successfully sent to the selected manager!
                They&apos;ll receive an email with all the details on what to
                expect and a direct link to access the OmniCreate platform.
              </Typography>
            </Box>
            <Inline justify="end" mt="32">
              <Button onClick={close} variant="secondary">
                Continue
              </Button>
            </Inline>
          </Box>
        )}
      </ModalWrapper>
    </>
  );
};

// Default export is required for lazy loading
export default InviteModal;
