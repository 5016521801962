import {
  CountryTimesheetFormat,
  EmployeeRegularWorkingHoursResponse,
  EmployeeTimesheetResponse,
} from 'common/types';

export const mockCountriesTimesheetFormat: CountryTimesheetFormat[] = [
  {
    id: '5d5e0ebf-15e9-4e67-9c11-5e5945e8bdf5',
    countryName: 'Australia',
    countryCode: 'AU',
    trackingFormatRequirement: 'SIMPLE',
  },
  {
    id: '5cc655f7-c22b-4ac4-86ad-2cc07640291f',
    countryName: 'France',
    countryCode: 'FR',
    trackingFormatRequirement: 'MEDIUM',
  },
  {
    id: 'ae806673-cdea-45aa-b514-ded450d54c8d',
    countryName: 'Germany',
    countryCode: 'DE',
    trackingFormatRequirement: 'MEDIUM',
  },
  {
    id: 'bd512dc1-1a7f-4b35-a0c8-28aaf85949ce',
    countryName: 'United Kingdom',
    countryCode: 'GB',
    trackingFormatRequirement: 'SIMPLE',
  },
];

export const getMockCountryTimesheetFormat = (
  countryName: string,
): CountryTimesheetFormat => {
  return (
    mockCountriesTimesheetFormat.find(
      (mockCountry) => mockCountry.countryName === countryName,
    ) || mockCountriesTimesheetFormat[0]
  );
};

export const mockRegularWorkingHours: EmployeeRegularWorkingHoursResponse = {
  id: 'bcf1957b-f5ea-37b6-73e2-de47bbd7b4c3',
  employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8b9y3',
  workingDays: [
    {
      dayOfWeek: 'Monday',
      startTime: '09:00',
      endTime: '13:00',
      breakStartTime: '14:00',
      breakEndTime: '17:00',
    },
    {
      dayOfWeek: 'Tuesday',
      startTime: '09:00',
      endTime: '13:00',
      breakStartTime: '14:00',
      breakEndTime: '17:00',
    },
    {
      dayOfWeek: 'Wednesday',
      startTime: '09:00',
      endTime: '13:00',
      breakStartTime: '14:00',
      breakEndTime: '17:00',
    },
    {
      dayOfWeek: 'Friday',
      startTime: '09:00',
      endTime: '13:00',
      breakStartTime: '14:00',
      breakEndTime: '17:00',
    },
  ],
  lastSubmittedTimesheetDate: '2022-11-01',
  lastSubmittedTimesheetDocumentId: '',
};

export const mockEmployeeTimesheets: EmployeeTimesheetResponse[] = [
  {
    employeeId: '6db3570b-4da9-48a2-892f-69f41abfc35d',
    yearMonth: '2022-11',
    totalMonthWorkingHours: '112',
    timesheetData: {
      workingDays: [
        {
          breakStartTime: '13:00',
          startTime: '09:00',
          dayOfWeek: 'Monday',
          endTime: '17:00',
          breakEndTime: '14:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '09:00',
          dayOfWeek: 'Tuesday',
          endTime: '17:00',
          breakEndTime: '14:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '09:00',
          dayOfWeek: 'Wednesday',
          endTime: '17:00',
          breakEndTime: '14:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '09:00',
          dayOfWeek: 'Friday',
          endTime: '17:00',
          breakEndTime: '14:00',
        },
      ],
      overtime: [],
      timeoff: [],
    },
  },
  {
    employeeId: '6db3570b-4da9-48a2-892f-69f41abfc35d',
    yearMonth: '2022-10',
    totalMonthWorkingHours: '112',
    timesheetData: {
      workingDays: [
        {
          breakStartTime: '13:00',
          startTime: '9:00',
          breakEndTime: '14:00',
          dayOfWeek: 'Monday',
          endTime: '17:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '9:00',
          breakEndTime: '14:00',
          dayOfWeek: 'Tuesday',
          endTime: '17:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '9:00',
          breakEndTime: '14:00',
          dayOfWeek: 'Wednesday',
          endTime: '17:00',
        },
        {
          breakStartTime: '13:00',
          startTime: '9:00',
          breakEndTime: '14:00',
          dayOfWeek: 'Friday',
          endTime: '17:00',
        },
      ],
      overtime: [],
      timeoff: [],
    },
    submittedDate: '2022-11-01',
    approvedDate: '2022-11-01',
  },
];

export const mockEmployeeSpecificTimesheet: EmployeeTimesheetResponse = {
  employeeId: '6db3570b-4da9-48a2-892f-69f41abfc35d',
  yearMonth: '2022-12',
  totalMonthWorkingHours: '112',
  timesheetData: {
    workingDays: [
      {
        breakStartTime: '13:00',
        startTime: '9:00',
        dayOfWeek: 'Monday',
        endTime: '17:00',
        breakEndTime: '14:00',
      },
      {
        breakStartTime: '13:00',
        startTime: '9:00',
        dayOfWeek: 'Tuesday',
        endTime: '17:00',
        breakEndTime: '14:00',
      },
      {
        breakStartTime: '13:00',
        startTime: '9:00',
        dayOfWeek: 'Wednesday',
        endTime: '17:00',
        breakEndTime: '14:00',
      },
      {
        breakStartTime: '13:00',
        startTime: '9:00',
        dayOfWeek: 'Friday',
        endTime: '17:00',
        breakEndTime: '14:00',
      },
    ],
    overtime: [],
    timeoff: [],
  },
};
