import { client } from 'api';
import {
  ContractStatus,
  ContractTemplateApiResponse,
  ContractTemplateMappingsApiResponse,
  QuestionnaireAPIPayload,
} from 'common/types';

export const getTemplateMappingsRequest = () => {
  return client.get<ContractTemplateMappingsApiResponse>(
    '/contracts/template-mappings',
    {},
  );
};

export const getTemplateRequest = ({
  countryCode,
  companyId,
}: {
  countryCode?: string;
  companyId: string;
}) => {
  // NB: not passing companyId is okay but passing an empty companyId would throw a 400 on API
  const params = companyId ? { countryCode, companyId } : { countryCode };
  return client.get<ContractTemplateApiResponse>('/contracts/templates', {
    params,
  });
};

export const downloadDocumentDocx = (avvokaDocumentId: string) =>
  client.get<{ linkToDocument: string }>(
    `/contracts/documents/${avvokaDocumentId}/download`,
    {
      params: { format: 'docx', temporary: true },
    },
  );

export type UpdateContractQuestionnaireParams = {
  status?: ContractStatus;
  employeeId: string;
  companyId: string;
  questionnaire: QuestionnaireAPIPayload;
};

export const updateContractQuestionnaireRequest = ({
  status,
  employeeId,
  companyId,
  questionnaire,
}: UpdateContractQuestionnaireParams) => {
  return client.patch<{ contractId: string }>('/contracts/', {
    status,
    employeeId,
    companyId,
    ...questionnaire,
  });
};
