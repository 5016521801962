import PropTypes from 'prop-types';
import { getIsRedactedValue } from 'utils/rbac.utils';

import DetailsCardRedactedType from '../../molecules/DetailsCardValue/valuesByType/DetailsCardRedactedType';
import { StyledTableRow } from './Table.styles';
import TableCell from './TableCell';

const TableRow = ({
  objectForRow,
  headers,
  onClick,
  maxWidth,
  onColumnItemClick,
}) => {
  const transformedHeaders = headers.map(({ customCell, ...rest }) => {
    const value = objectForRow[rest.fieldOnObject];

    return {
      ...rest,
      customCell: getIsRedactedValue(value)
        ? () => <DetailsCardRedactedType value={value} />
        : customCell,
    };
  });

  return (
    <StyledTableRow
      clickable={!!onClick}
      data-testid="table-row"
      onClick={() => onClick && onClick(objectForRow)}
    >
      {transformedHeaders.map(
        ({ headerTitle, fieldOnObject, customCell, align, id }) => {
          if (customCell) {
            return (
              <TableCell
                key={`${objectForRow.id}-${headerTitle || id}`}
                align={align}
                maxWidth={maxWidth}
              >
                {customCell(objectForRow, onColumnItemClick)}
              </TableCell>
            );
          }
          return (
            <TableCell
              key={`${objectForRow.id}-${headerTitle || id}`}
              align={align}
              text={objectForRow.displayValue || objectForRow[fieldOnObject]}
              maxWidth={maxWidth}
            />
          );
        },
      )}
    </StyledTableRow>
  );
};

TableRow.propTypes = {
  objectForRow: PropTypes.oneOfType([PropTypes.object]).isRequired,
  headers: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onClick: PropTypes.func,
  maxWidth: PropTypes.bool,
  onColumnItemClick: PropTypes.func,
};

TableRow.defaultProps = {
  onClick: null,
  onColumnItemClick: null,
  maxWidth: false,
};

export default TableRow;
