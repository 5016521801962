import {
  Employer,
  FIELD_TYPE,
  FormConfig,
  PayrollProvider,
} from 'common/types';
import Joi from 'joi';
import { getCountryByCountryCode } from 'utils/countries';

import { Box, Typography } from '@omnipresentgroup/design-system';

import { StyledFormSectionTitle } from '../ProviderPage.styles';

const validateParent: Joi.CustomValidator<Employer | null> = (
  value,
  { state, error },
) => {
  const [{ hasParent }] = state?.ancestors;
  return !value && hasParent ? error('any.custom') : value;
};

const PayrollProviderSectionTitle = () => (
  <StyledFormSectionTitle colour="gray-500">
    Payroll Provider details
  </StyledFormSectionTitle>
);

const PayrollParentSectionTitle = () => (
  <StyledFormSectionTitle colour="gray-500">
    Parent entity
  </StyledFormSectionTitle>
);

const PayrollIsActiveSectionTitle = () => (
  <StyledFormSectionTitle colour="gray-500">
    Active provider
  </StyledFormSectionTitle>
);

const IsActiveSectionDisabledLabel = () => (
  <Box mb="32">
    <Typography
      as="p"
      hideParagraphSpacing
      size="14"
      color="helper"
      weight="regular"
    >
      Can&lsquo;t deactivate payroll provider because it&lsquo;s linked to an
      employer
    </Typography>
  </Box>
);

const payrollProviderHasHistory = (payrollProvider: PayrollProvider) => {
  return payrollProvider?.hasHistory || false;
};

export const payrollProviderFormConfig = (
  payrollProvider?: PayrollProvider,
): FormConfig => ({
  formTitle: '',
  formCopy: '',
  formFields: [
    {
      name: 'name',
      label: 'Provider name',
      type: FIELD_TYPE.STRING,
      value: payrollProvider?.name || '',
      error: null,
      required: true,
      before: PayrollProviderSectionTitle,
    },
    {
      name: 'country',
      label: 'Provider country',
      type: FIELD_TYPE.COUNTRY,
      value: payrollProvider?.countryCode
        ? getCountryByCountryCode(payrollProvider?.countryCode)?.label
        : '',
      error: null,
      required: true,
      rules: {
        disabled: () => payrollProvider?.hasHistory || false,
      },
    },
    {
      name: 'hasParent',
      label: 'This provider has a parent',
      type: FIELD_TYPE.CHECKBOX_BOOLEAN,
      value: payrollProvider?.parent ? true : false,
      error: null,
      required: false,
      before: PayrollParentSectionTitle,
    },
    {
      name: 'parent',
      label: 'Parent payroll provider',
      type: FIELD_TYPE.LPP,
      value: payrollProvider?.parent?.name || null,
      error: null,
      required: false,
      rules: {
        hidden: (_field, fields) => !fields.hasParent.value,
      },
    },
    {
      name: 'isActive',
      label: 'Requires payroll provider to be complete',
      type: FIELD_TYPE.TOGGLE,
      value: payrollProvider?.isActive || false,
      error: null,
      required: false,
      rules: {
        disabled: () =>
          payrollProviderHasHistory(payrollProvider as PayrollProvider),
      },
      before: PayrollIsActiveSectionTitle,
      ...(payrollProviderHasHistory(payrollProvider as PayrollProvider)
        ? { after: IsActiveSectionDisabledLabel }
        : {}),
    },
  ],
  schema: Joi.object().keys({
    name: Joi.string()
      .required()
      .messages({ 'string.empty': 'Please enter the payroll provider name' }),
    country: Joi.string()
      .required()
      .messages({ 'string.empty': 'Please select a country' }),
    hasParent: Joi.boolean(),
    parent: Joi.custom(validateParent).messages({
      'any.custom': 'Please select a parent payroll provider entity',
    }),
    isActive: Joi.boolean(),
  }),
});
