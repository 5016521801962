import { useCallback, useEffect, useMemo, useState } from 'react';

import { LSP } from 'common/types';
import { useUpdateLSPMutation } from 'utils/queries/lsps/useUpdateLSPMutation/useUpdateLSPMutation';

import {
  Box,
  Button,
  Input,
  Modal,
  notifyError,
  notifySuccess,
  Stack,
  ToggleSwitch,
} from '@omnipresentgroup/design-system';

export const LspUpdateModal = ({
  open,
  closeFn,
  lsp,
}: {
  closeFn: () => void;
  open: boolean;
  lsp: LSP;
}) => {
  const { isLoading, mutateAsync: updateLSP } = useUpdateLSPMutation(lsp.id);

  const [name, setName] = useState<string>(lsp.name);
  const [isActive, setActive] = useState<boolean>(lsp.isActive);

  useEffect(() => {
    if (open) {
      setName(lsp.name);
      setActive(lsp.isActive);
    }
  }, [lsp.isActive, lsp.name, open]);

  const isSubmitEnabled = useMemo(() => {
    const hasChanged = lsp.name !== name || lsp.isActive !== isActive;
    const isValid = !!name;

    return hasChanged && isValid;
  }, [isActive, lsp.isActive, lsp.name, name]);

  const handleSubmit = useCallback(async () => {
    await updateLSP(
      { name: name.trim(), isActive },
      {
        onSuccess: () => {
          notifySuccess({
            title: 'Success',
            description: `LSP was updated successfully`,
          });
        },
        onError: () => {
          notifyError({
            title: 'Error',
            description: `There was a problem while updating LSP ${lsp.id}.`,
          });
        },
      },
    );
    closeFn();
  }, [closeFn, isActive, lsp.id, name, updateLSP]);

  return (
    <Modal open={open} onOpenChange={closeFn} span={2}>
      <Modal.Header title={`Update ${lsp?.name}`} />
      <Modal.Body>
        <Box>
          <Stack>
            <Input
              id="name"
              label="Name"
              value={`${name}`}
              onChange={({ target }) => setName(target.value)}
            />
            <ToggleSwitch
              id="isActive"
              label="Active"
              checked={isActive}
              onCheckedChange={setActive}
            />
          </Stack>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeFn} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          loading={isLoading}
          disabled={!isSubmitEnabled}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
