import { getGrossToNetFiles } from 'common/api/payrollTaxonomy.api';
import { GrossToNetFilesApiResponse } from 'common/types';
import { isNil, omitBy } from 'lodash';
import { useQuery } from 'react-query';
import { payrollKeys } from 'utils/queries/payroll/keys';
import { getDataFromResponse } from 'utils/react-query-utils';

export const usePaginatedGrossToNetFilesQuery = (params: {
  payrollPeriod?: string;
  lspId?: string;
  country?: string;
  source?: string;
  payrollCycle?: string;
  processingStatus?: string;
  pageSize?: number;
  pageIndex?: number;
}) => {
  return useQuery<GrossToNetFilesApiResponse>(
    payrollKeys.grossToNetFiles({ ...omitBy(params, isNil) }),
    async () => {
      return getGrossToNetFiles({
        ...params,
      }).then(getDataFromResponse);
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};
