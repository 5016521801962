import styled from 'styled-components';

import { convertToRem } from '@omnipresentgroup/design-system';

type ManagerCardContainerProps = {
  checked: boolean;
  superAdmin: boolean;
};

export const ManagerCardContainer = styled.div<ManagerCardContainerProps>`
  margin-bottom: ${({ theme: { space } }) => space[16]};
  border-radius: ${({ theme: { radii } }) => radii.s};
  padding: ${({ theme: { space } }) => space[8]};
  border: ${({ checked, theme: { borderWidths, borderStyles, colors } }) =>
    checked
      ? `${borderWidths.m} ${borderStyles.solid} ${colors['border-inverse']}`
      : `${borderWidths.m} ${borderStyles.solid} ${colors['border-subtle']}`};
  background: ${({ theme: { colors } }) => colors['background-primary']};
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.m} ${borderStyles.solid} ${colors['border-inverse']}`};
  }
`;

export const ManagerCardContentContainer = styled.div`
  width: 100%;
`;

export const AddManagerCardContentContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const AddManagerCardContentIcon = styled.div`
  width: fit-content;
  display: flex;
  margin-left: ${({ theme: { space } }) => space[16]};
  align-items: center;
`;

export const ManagerCardContentText = styled.div`
  display: grid;
`;

export const ManagerCardSuperAdminContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: ${({ theme: { space } }) => space[4]};
`;

export const ManagerCardSuperAdmin = styled.div`
  width: ${convertToRem(105)};
  height: ${({ theme: { space } }) => space[32]};
  border-radius: ${({ theme: { radii } }) => radii.s};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
`;

export const ManagerCardHeader = styled.h3`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  font-family: ${({ theme: { fonts } }) => fonts.default};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  color: ${({ theme: { colors } }) => colors['text-default']};
  margin: ${({ theme: { space } }) => space[16]};
`;
