import { User } from 'common/types';

export const mockAdminUser: User = {
  userId: '7880bcbe-f20e-4d07-bea8-2a2a1ef1ef5b',
  authId: 'auth0|5f16ee9d8a0ed800133353aa',
  firstName: 'E2e',
  displayName: 'E2e',
  lastName: 'Admin',
  name: 'E2e Admin',
  email: 'e2e-admin@omnie2etesting.com',
  roles: [
    {
      type: 'admin',
      id: '39ffc7d0-9a0f-4cef-b430-f9a89d277b37',
    },
  ],
  seenWelcomeScreen: false,
  seenPrivacyPolicy: '23343434',
  createdAt: '2020-08-20T15:00:00.000Z',
  sentInvitationAt: '2020-08-20T15:00:00.000Z',
  temporaryPassword: 'YeF90sd9cb563e',
};

export const mockCSAdminUser: User = {
  userId: 'cs_user',
  authId: 'auth0|5f16ee9d8a0ed800133353aa',
  firstName: 'E2e',
  displayName: 'E2e',
  lastName: 'Admin',
  name: 'E2e Admin',
  email: 'e2e-admin@omnie2etesting.com',
  roles: [
    {
      type: 'admin',
      id: '39ffc7d0-9a0f-4cef-b430-f9a89d277b37',
    },
    {
      type: 'viewer:clients-sensitive-data',
    },
  ],
  seenWelcomeScreen: false,
  seenPrivacyPolicy: '23343434',
  createdAt: '2020-08-20T15:00:00.000Z',
  sentInvitationAt: '2020-08-20T15:00:00.000Z',
  temporaryPassword: 'YeF90sd9cb563e',
};

export const mockIEAdminUser: User = {
  userId: '4ef20092-c946-446a-9977-f63191bf5e46',
  authId: 'auth0|5f16ee9d8a0ed800133353aa',
  firstName: 'E2e',
  displayName: 'E2e',
  lastName: 'Admin',
  name: 'E2e Admin',
  email: 'e2e-admin@omnie2etesting.com',
  roles: [
    {
      type: 'admin',
      id: '39ffc7d0-9a0f-4cef-b430-f9a89d277b37',
    },
    {
      type: 'international-expansion',
    },
  ],
  seenWelcomeScreen: false,
  seenPrivacyPolicy: '23343434',
  createdAt: '2020-08-20T15:00:00.000Z',
  sentInvitationAt: '2020-08-20T15:00:00.000Z',
  temporaryPassword: 'YeF90sd9cb563e',
};

export const mockPayrollUser: User = {
  userId: 'payroll_user_but_actually_this_would_be_an_autogenerated_hash',
  authId: 'auth0|60f0a9d87a2fb2007096ee29',
  email: 'e2e-payroll@omnie2etesting.com',
  displayName: 'Payroll Admin',
  name: 'Payroll Admin',
  firstName: 'Cooks',
  lastName: 'Books',
  roles: [
    {
      type: 'admin',
    },
    {
      type: 'payroll',
    },
  ],
  seenWelcomeScreen: false,
  seenPrivacyPolicy: '5656565656',
  createdAt: '2020-08-20T15:00:00.000Z',
  sentInvitationAt: '2020-08-20T15:00:00.000Z',
  temporaryPassword: 'YeF90sd9cb563e',
};

export const mockFinanceUser: User = {
  userId: 'finance_user_but_actually_this_would_be_an_autogenerated_hash',
  authId: 'auth0|5f16ee9d8a0ed800133353aa',
  email: 'e2e-finance@omnie2etesting.com',
  firstName: 'E2e',
  lastName: 'Finance',
  displayName: 'Finance',
  name: 'Finance',
  roles: [
    {
      type: 'admin',
    },
    {
      type: 'finance',
    },
  ],
  seenWelcomeScreen: false,
  seenPrivacyPolicy: '5656565656',
  createdAt: '2020-08-20T15:00:00.000Z',
  sentInvitationAt: '2020-08-20T15:00:00.000Z',
  temporaryPassword: 'YeF90sd9cb563e',
};

export const mockOPAdminUser: User = {
  userId: 'omnihr_user',
  authId: 'auth0|609008c1ba2d49006a7a4571',
  firstName: 'Omni',
  lastName: 'HR',
  displayName: 'Omni HR',
  name: 'Omni HR',
  email: 'e2e-omnihr@omnie2etesting.com',
  roles: [
    {
      type: 'admin',
      id: 'omnihr_user_admin',
    },
    {
      type: 'viewer:omnipresent-sensitive-data',
    },
  ],
  seenWelcomeScreen: false,
  seenPrivacyPolicy: '5656565656',
  createdAt: '2020-08-20T15:00:00.000Z',
  sentInvitationAt: '2020-08-20T15:00:00.000Z',
  temporaryPassword: 'YeF90sd9cb563e',
};

export const adminUsers = [
  mockAdminUser,
  mockCSAdminUser,
  mockIEAdminUser,
  mockOPAdminUser,
  mockPayrollUser,
];
