import { joiResolver } from '@hookform/resolvers/joi';
import { StyledBodyCopy } from 'common/components/atoms/Text';
import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import Joi from 'joi';
import { useForm } from 'react-hook-form';

import { Button, Typography } from '@omnipresentgroup/design-system';

import { FormInput } from '../FormInput';
import * as S from './ConfirmationModal.styles';

export type Props = {
  cancelHandler: () => any;
  cancelLabel: string;
  confirmHandler?: () => any;
  confirmLabel?: string;
  loading?: boolean;
  message: string | React.ReactElement;
  title: string;
  testId?: string;
  hasVerificationField?: boolean;
  verificationField?: string;
  label?: string;
  id?: string;
  schema?: Joi.ObjectSchema<any>;
  buttonsAlignment?: 'left' | 'middle' | 'right' | 'space-between';
};

export const ConfirmationModal = ({
  cancelHandler,
  cancelLabel,
  confirmHandler,
  confirmLabel,
  loading,
  message,
  title,
  hasVerificationField = false,
  verificationField,
  label,
  schema,
  testId = 'confirmation-modal',
  buttonsAlignment,
}: Props) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'all',
    resolver: joiResolver(schema!),
  });

  return (
    <ModalWrapper data-testid={testId} fixedWidth="511">
      <S.ModalContainer className="smallStack">
        <Typography as="h4" hideParagraphSpacing size="24" weight="medium">
          {title}
        </Typography>
        <StyledBodyCopy>{message}</StyledBodyCopy>
        {hasVerificationField && (
          <FormInput
            id={verificationField!}
            as="text"
            data-testid={verificationField}
            error={errors[verificationField!]?.message}
            label={label!}
            {...register(verificationField!)}
          />
        )}
        <S.ButtonsContainer alignment={buttonsAlignment}>
          <Button onClick={cancelHandler} variant="secondary">
            {cancelLabel}
          </Button>
          {confirmHandler && confirmLabel && (
            <Button
              onClick={confirmHandler}
              variant="primary"
              disabled={
                loading ||
                (hasVerificationField &&
                  watch(verificationField!) !== confirmLabel)
              }
              loading={loading}
            >
              {confirmLabel}
            </Button>
          )}
        </S.ButtonsContainer>
      </S.ModalContainer>
    </ModalWrapper>
  );
};
