import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import {
  getLoadingSelector,
  getTransformedUser,
} from 'app/store/app.selectors';
import { ACCOUNT_DETAILS_PAGE } from 'paths';
import { useSelector } from 'react-redux';

import { Viewport } from '@omnipresentgroup/design-system';

import FloatingActionButton from '../../components/atoms/FloatingActionButton/FloatingActionButton';
import Loading from '../../components/atoms/Loading/Loading';
import PageHeader from '../../components/molecules/PageHeader/PageHeader';
import DetailsCard from '../../components/organisms/DetailsCard/DetailsCard';
import { StyledAccountDetailsPage } from './AccountDetailsPage.styles';

export const AccountDetailsPage = () => {
  const user = useSelector(getTransformedUser);
  const loading = useSelector(getLoadingSelector);
  const accountDetailsSections = [
    {
      name: 'account-details',
      title: 'Account details',
      details: user?.userDetails,
    },
  ];

  const onEditClicked = () => appHistory.push(`${ACCOUNT_DETAILS_PAGE}/edit`);
  return (
    <PageContent>
      <StyledAccountDetailsPage data-testid="account-details-page">
        {loading || !user ? (
          <Loading />
        ) : (
          <div className="bigStack">
            <PageHeader
              title={user.displayName}
              ctaText="Edit details"
              onCTAClick={onEditClicked}
            />
            {accountDetailsSections.map(
              (section) =>
                section && (
                  <DetailsCard
                    key={section.title}
                    fieldsToDisplay={section.details}
                    title={section.title}
                    testId={`${section.name}-card`}
                    userRole={user.role.type}
                  />
                ),
            )}
          </div>
        )}
        <Viewport devices={['phone', 'tablet']}>
          <FloatingActionButton type="edit" onClick={onEditClicked} />
        </Viewport>
      </StyledAccountDetailsPage>
    </PageContent>
  );
};
