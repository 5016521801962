import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ theme: { space } }) => `${space[16]} ${space[16]}`};
  background: ${({ theme: { colors } }) => colors['blue-100']};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-inverse']}`};
  margin-top: ${({ theme: { space } }) => space[32]};
  border-radius: ${({ theme: { radii } }) => radii.s};
`;

export const IconContainer = styled.div`
  color: ${({ theme: { colors } }) => colors['icon-neutral']};
  padding-right: ${({ theme: { space } }) => space[8]};
`;

export const DescriptionContainer = styled.div``;

export const BannerTitle = styled.div`
  margin-bottom: ${({ theme: { space } }) => space[4]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  color: ${({ theme: { colors } }) => colors['blue-700']};
  word-break: break-word;
  order: 0;
`;

export const BannerDescription = styled.div`
  margin-bottom: ${({ theme: { space } }) => space[4]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  color: ${({ theme: { colors } }) => colors['text-subtle']};
  word-break: break-word;
  order: 1;
`;

export const BannerButton = styled.div`
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[0]};
  color: ${({ theme: { colors } }) => colors['gray-500']};
  cursor: pointer;
  order: 2;
`;
