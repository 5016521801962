import styled from 'styled-components';

export const StyledPayrollPage = styled.div`
  margin-top: ${({ theme: { space } }) => space[32]};
  width: 100%;
`;

export const StyledNestedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-self: center;
  padding-left: ${({ theme: { space } }) => space[64]};
`;

export const StyledCheckboxItem = styled.div`
  padding-bottom: ${({ theme: { space } }) => space[4]};
`;

export const StyledErrorContent = styled.div`
  color: ${({ theme: { colors } }) => colors['red-300']};
`;
