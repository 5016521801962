import styled from 'styled-components';

export const StyledVerifyEmailPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledConfirmEmailContainer = styled.div`
  align-self: center;
  max-width: 500px;
  margin-top: ${({ theme: { space } }) => space[64]};
`;
