import { client } from 'api';
import { ExportInvoiceDataToCsvApiResponse } from 'common/types/finance';

export const exportInvoiceDataToCsv = async (invoiceLogIds: string[]) => {
  const {
    data: { url },
  } = await client.post<ExportInvoiceDataToCsvApiResponse>(
    'payroll/invoice-logs/export-invoice-report',
    {
      invoiceLogIds: [...invoiceLogIds],
    },
  );

  window.open(url, '_self');
  return { url };
};
