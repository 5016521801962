import { StyledBodyCopy } from 'common/components/atoms/Text/Copy.styles';
import PropTypes from 'prop-types';

const DetailsCardAddressType = ({ value }) => {
  const {
    city,
    country,
    postalCode,
    addressLine1,
    addressLine2,
    addressLine3,
    state,
  } = value ?? {};

  return (
    <>
      {addressLine1 && <StyledBodyCopy>{addressLine1}</StyledBodyCopy>}
      {addressLine2 && <StyledBodyCopy>{addressLine2}</StyledBodyCopy>}
      {addressLine3 && <StyledBodyCopy>{addressLine3}</StyledBodyCopy>}
      {city && <StyledBodyCopy>{city}</StyledBodyCopy>}
      {postalCode && <StyledBodyCopy>{postalCode}</StyledBodyCopy>}
      {country && (
        <StyledBodyCopy data-testid="address-country">{country}</StyledBodyCopy>
      )}
      {state && <StyledBodyCopy>{state}</StyledBodyCopy>}
    </>
  );
};

export const detailsCardAddressPropTypes = {
  city: PropTypes.string,
  country: PropTypes.string,
  postalCode: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  addressLine3: PropTypes.string,
  state: PropTypes.string,
};

DetailsCardAddressType.propTypes = {
  value: PropTypes.shape(detailsCardAddressPropTypes).isRequired,
};

export default DetailsCardAddressType;
