import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { ErrorBanner, Loading } from 'common/components';
import { Form } from 'common/components/molecules/Form/Form';
import {
  FormProvider,
  useFormContext,
} from 'common/components/molecules/Form/FormProvider';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { Employer } from 'common/types';
import { PROVIDERS_PAGE } from 'paths';
import { useEmployerQuery } from 'utils/queries/employers/useEmployerQuery/useEmployersQuery';
import { useUpdateEmployerMutation } from 'utils/queries/employers/useUpdateEmployerMutation';

import { EmployerFormData, transformFormDataForAPI } from '../employer';
import { employerFormConfig } from '../employerFormConfig';

const EditEmployerForm = () => {
  const history = useHistory();
  const { mutate, isLoading } = useUpdateEmployerMutation();
  const goToListAction = () => history.push(`${PROVIDERS_PAGE}#employers`);

  const {
    values: { hasParent, isOmniEntity, country },
    onFieldChange,
  } = useFormContext();

  const [prevCountrySelected, setPrevCountrySelected] =
    useState<string>(country);
  const [newCountrySelected, setNewCountrySelected] = useState<string>('');
  const [hasSelectedNewCountry, setHasSelectedNewCountry] =
    useState<boolean>(false);

  const update = useCallback(
    (employerFormData: EmployerFormData) => {
      mutate(transformFormDataForAPI(employerFormData), {
        onSuccess: goToListAction,
      });
    },
    [goToListAction, mutate],
  );

  useEffect(() => {
    if (!hasParent) {
      onFieldChange('parent', null);
    }
    if (!isOmniEntity) {
      onFieldChange('payrollProvider', null);
    }
    if (prevCountrySelected !== country && hasSelectedNewCountry) {
      onFieldChange('payrollProvider', null);
      setNewCountrySelected(country);
      setPrevCountrySelected(newCountrySelected);
    }
    setHasSelectedNewCountry(true);
  }, [
    hasParent,
    isOmniEntity,
    country,
    onFieldChange,
    prevCountrySelected,
    hasSelectedNewCountry,
    newCountrySelected,
  ]);

  return (
    <Form
      isLoading={isLoading}
      onSubmit={update}
      ctaText="Save Edit"
      showCancel={true}
      onCancel={goToListAction}
    />
  );
};

export const EditEmployerPage = () => {
  const { id: employerId } = useParams<{ id: string }>();
  const employerQuery = useEmployerQuery(employerId, {
    queryOptions: { staleTime: 0, cacheTime: 0 },
  });
  const employer = employerQuery?.data as Employer;

  if (employerQuery.isLoading) {
    return <Loading />;
  }

  if (employerQuery.isError) {
    return <ErrorBanner errorMessage={employerQuery.error.message} />;
  }

  return (
    <PageContent data-testid="edit-employer-page" className="bigStack">
      <PageHeader
        title="Edit Employer"
        backLink={{
          url: `${PROVIDERS_PAGE}#employers`,
          label: 'Back to list',
        }}
      />
      {!employerQuery.isLoading && (
        <FormProvider config={employerFormConfig(employer, true)}>
          <EditEmployerForm />
        </FormProvider>
      )}
    </PageContent>
  );
};
