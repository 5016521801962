import { StyledInput } from 'common/components/atoms/Input';
import styled from 'styled-components';

export const StyledTimePeriodInputContainer = styled.div`
  display: flex;
`;

export const StyledLengthInput = styled(StyledInput)`
  flex-grow: 1;
  width: 15%;
  margin-right: ${({ theme: { space } }) => space[8]};
`;

export const StyledTimePeriod = styled(StyledInput)`
  flex-grow: 2;
`;
