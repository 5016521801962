import { ActionCell } from 'common/components/molecules/ActionCell/ActionCell';
import NameTableCell from 'common/components/molecules/NameTableCell/NameTableCell';
import StatementOfWorkStatus from 'common/components/molecules/StatementOfWorkStatus/StatementOfWorkStatus';
import {
  SeatActionEnum,
  SeatActions,
  SOWStatusEnum,
  TransformedSeat,
} from 'common/types';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';

const NameCustomCell = (
  seat: TransformedSeat,
  onColumnItemClick: (column: string, seat: TransformedSeat) => void,
) => {
  const { name } = seat;

  return (
    <span
      role="none"
      style={{ cursor: 'pointer' }}
      onClick={() => onColumnItemClick('name', seat)}
    >
      <NameTableCell name={name} />
    </span>
  );
};

const StatusCustomCell = (
  seat: TransformedSeat,
  onColumnItemClick: (column: string, seat: TransformedSeat) => void,
) => (
  <div
    role="none"
    onClick={() => {
      onColumnItemClick('status', seat);
    }}
  >
    <StatementOfWorkStatus status={seat.sowStatus} testId={seat.id} />
  </div>
);

const ActionsCustomCell = (
  seat: TransformedSeat,
  onColumnItemClick: (column: string, seat: TransformedSeat) => void,
) => (
  <ActionCell
    actions={SeatActions.filter(
      (action) =>
        action !== SeatActionEnum.ORDER_FORM_ACCEPTED ||
        seat.sowStatus !== SOWStatusEnum.SOW_ACCEPTED,
    )}
    onActionClick={(action) => onColumnItemClick(action, seat)}
  />
);

const StartDateCell = ({ desiredStartDate = '' }: TransformedSeat) => (
  <span>{formatISODate(desiredStartDate, DateFormats.Date, true, '-')}</span>
);

export const seatsTableHeaders = [
  {
    id: 'name',
    headerTitle: 'Name',
    customCell: NameCustomCell,
  },
  {
    id: 'country',
    headerTitle: 'Country',
    fieldOnObject: 'countryName',
  },
  {
    id: 'desired-start-date',
    headerTitle: 'Desired Start Date',
    customCell: StartDateCell,
  },
  {
    id: 'status',
    headerTitle: 'Status',
    customCell: StatusCustomCell,
  },
  {
    id: 'action',
    headerTitle: 'Actions',
    customCell: ActionsCustomCell,
  },
];
