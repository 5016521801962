import appSaga from 'app/store/sagas/app.saga';
import documentsSaga from 'common/store/sagas/documents.saga';
import employeesSaga from 'common/store/sagas/employee.saga';
import { all } from 'redux-saga/effects';

import expensesSaga from '../../../employee/store/sagas/expenses.saga';

export default function* lspRootSaga() {
  yield all([...appSaga, ...employeesSaga, ...documentsSaga, ...expensesSaga]);
}
