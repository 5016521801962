import { useState } from 'react';

import { PageContent } from 'app/App.styles';
import {
  startEmailChangeAction,
  updateUserAction,
} from 'app/store/app.actions';
import {
  getLoadingSelector,
  getTransformedUser,
} from 'app/store/app.selectors';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { roleTypeToUserFriendlyRoleTypeName } from 'utils/user';
import { userSchema } from 'utils/validators/user.schema';
import { validateInputs } from 'utils/validators/validator';

import Loading from '../../components/atoms/Loading/Loading';
import { StyledBodyCopy } from '../../components/atoms/Text/Copy.styles';
import { ConfirmationPopup } from '../../components/molecules/ConfirmationPopup/ConfirmationPopup';
import FormSidebar from '../../components/molecules/FormSidebar/FormSidebar';
import EditDetailsPage from '../EditDetailsPage/EditDetailsPage';
import SideBar from '../EditEmployeePage/SideBar';
import { StyledEditAccountDetailsPage } from './EditAccountDetailsPage.styles';

const EditAccountDetailsPage = ({
  loading,
  user,
  updateUser,
  startEmailChange,
}) => {
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [requestedEmailChange, setRequestedEmailChange] = useState('');
  const [editedValues, setEditedValues] = useState({});

  const sections = [
    {
      name: 'user-details',
      title: '',
      details: user?.userDetails
        .filter((detail) => detail.editable)
        .filter((detail) =>
          detail.showFor ? detail.showFor.includes(user.role.type) : true,
        ),
    },
  ];

  const updateUserInfo = (newEditedValues) => {
    if (newEditedValues.email && newEditedValues.email !== user.email) {
      setRequestedEmailChange(newEditedValues.email);
      setShowConfirmationPopup(true);
      setEditedValues(newEditedValues);
      return;
    }
    updateUser(newEditedValues);
  };
  return (
    <PageContent data-testid="edit-account-details-page">
      {loading || !user?.userDetails ? (
        <Loading />
      ) : (
        <StyledEditAccountDetailsPage>
          <EditDetailsPage
            title="Edit your details"
            onSaveEdits={updateUserInfo}
            validator={(fields) => validateInputs(fields, userSchema)}
            sections={sections}
            sideBar={
              <FormSidebar
                child={
                  <SideBar
                    jobTitle={roleTypeToUserFriendlyRoleTypeName(
                      user.role.type,
                    )}
                    name={user.displayName}
                  />
                }
              />
            }
          />
          {showConfirmationPopup && (
            <ConfirmationPopup
              title="Confirm email change"
              onConfirm={() => {
                setShowConfirmationPopup(false);
                updateUser(editedValues);
                startEmailChange(editedValues.email);
              }}
              onCancel={() => setShowConfirmationPopup(false)}
              copy={
                <StyledBodyCopy>
                  This will change the email you use to log into OmniPlatform.
                  <br />
                  <br />
                  Please re-enter your new email address and press confirm. You
                  will then receive an email with a link to verify the new
                  address. <br /> <br />
                  Until you have clicked that link you email address will remain
                  the same.
                </StyledBodyCopy>
              }
              stringToConfirm={requestedEmailChange}
            />
          )}
        </StyledEditAccountDetailsPage>
      )}
    </PageContent>
  );
};

EditAccountDetailsPage.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  loading: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
  startEmailChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: getTransformedUser(state),
  loading: getLoadingSelector(state),
});

const mapDispatchToProps = {
  updateUser: updateUserAction,
  startEmailChange: startEmailChangeAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAccountDetailsPage);
