import { useParams } from 'react-router-dom';

import { AxiosError } from 'axios';
import { updateEmployer } from 'common/api/employers.api';
import { Employer, EmployerCreateApiRequest } from 'common/types';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useUpdateEmployerMutation = () => {
  const { id } = useParams<{ id: string }>();
  return useMutation<Employer, AxiosError, EmployerCreateApiRequest>(
    (employerUpdate) => {
      return updateEmployer(id, employerUpdate).then(getDataFromResponse);
    },
  );
};
