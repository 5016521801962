import { MoreHorizontal } from 'react-feather';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const ActionIcon = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export const StyledDotsIcon = styled(MoreHorizontal)`
  height: ${({ theme: { space } }) => space[32]};
  width: ${({ theme: { space } }) => space[32]};
`;

export const ActionPopupContainer = styled.div<{
  align?: string;
  $noPadding?: boolean;
}>`
  position: absolute;
  width: 196px;
  ${({ $noPadding, theme: { space } }) =>
    !$noPadding && `padding-bottom: ${space[16]};`}
  top: ${({ theme: { space } }) => space[32]};
  right: ${({ theme: { space } }) => space[8]};
  background: ${({ theme: { colors } }) => colors['background-primary']};
  box-shadow: ${({ theme: { shadows } }) => shadows.m};
  border-radius: ${({ theme: { radii } }) => radii.s};
  z-index: 1;

  ${({ align }) => {
    if (align) {
      return `text-align:${align}`;
    }
  }}
`;

export const ActionItemHeading = styled.div`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  padding: ${({ theme: { space } }) => space[16]};
  color: ${({ theme: { colors } }) => colors['gray-300']};
`;

export const StyledActionDiver = styled.hr`
  margin: ${({ theme: { space } }) =>
    `0 ${space[16]} ${space[16]} ${space[16]} ${space[16]}`};
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  border-bottom: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
`;

export const ActionItem = styled.div<{
  disabled?: boolean;
  hoverColor?: boolean;
}>`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  padding: ${({ theme: { space } }) => `${space[8]} ${space[16]}`};
  cursor: pointer;
  ${({ hoverColor, disabled, theme: { colors } }) => {
    if (hoverColor && !disabled) {
      return `
        :hover {
          background-color: ${colors['blue-100']};
        }
      `;
    }
  }}
  ${({ disabled, theme: { colors } }) => {
    if (disabled) {
      return ` 
        color: ${colors['gray-300']}
        cursor: not-allowed;        
        `;
    }
  }}
`;

export const ActionItemWithIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionItemIcon = styled.div`
  display: flex;
  padding: ${({ theme: { space } }) => space[4]};
  padding-right: ${({ theme: { space } }) => space[8]};
`;
