import { ReactNode, SetStateAction, useState } from 'react';

import { StyledErrorMessage } from 'common/components/atoms/ErrorMessage';

import { Button, Typography } from '@omnipresentgroup/design-system';

import { StyledFullWidthBlueDivider } from '../../atoms/Divider/Divider.styles';
import EditableInput from '../EditableInput/EditableInput';
import {
  StyledModalButtons,
  StyledModalContainer,
  StyledModalContents,
} from './ConfirmationPopup.styles';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  copy: ReactNode;
  title: string;
  stringToConfirm?: string;

  confirmationDisabled?: boolean;
}

export const ConfirmationPopup = ({
  onConfirm,
  onCancel,
  copy,
  title,
  stringToConfirm = '',
  confirmationDisabled = false,
}: Props) => {
  const [confirmedInput, setConfirmedInput] = useState('');
  const [showError, setShowError] = useState(false);

  const onConfirmClicked = () =>
    confirmedInput === stringToConfirm ? onConfirm() : setShowError(true);

  return (
    <StyledModalContainer role="dialog" data-testid="confirmation-popup">
      <StyledModalContents className="bigStack">
        <div className="stack">
          <Typography as="h4" hideParagraphSpacing size="24" weight="medium">
            {title}
          </Typography>
          <StyledFullWidthBlueDivider />
        </div>
        {copy}
        {stringToConfirm && (
          <div>
            <EditableInput
              name="confirm"
              onChange={(_: any, newValue: SetStateAction<string>) =>
                setConfirmedInput(newValue)
              }
              onFocus={() => setShowError(false)}
              type="string"
              value={confirmedInput}
            />
            {showError && (
              <StyledErrorMessage>
                Entered text does not match requested change
              </StyledErrorMessage>
            )}
          </div>
        )}
        <StyledModalButtons>
          <Button onClick={onCancel} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={onConfirmClicked}
            variant="primary"
            disabled={confirmationDisabled}
          >
            Confirm
          </Button>
        </StyledModalButtons>
      </StyledModalContents>
    </StyledModalContainer>
  );
};
