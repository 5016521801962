import { AxiosError } from 'axios';
import { getCompanySeatRequest } from 'common/api/seats.api';
import { ONE_MINUTE } from 'common/constants';
import { Seat } from 'common/types';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { seatsKeys } from '../keys';

export const useSingleSeatQuery = (
  seatId: string,
  {
    withSignature,
    queryOptions,
  }: {
    withSignature?: boolean;
    queryOptions?: Pick<UseQueryOptions, 'staleTime' | 'cacheTime' | 'enabled'>;
  } = {},
): UseQueryResult<Seat, AxiosError> => {
  return useQuery(
    seatsKeys.single(seatId),
    () => getCompanySeatRequest(seatId, withSignature),
    {
      staleTime: queryOptions?.staleTime || ONE_MINUTE,
      ...(queryOptions || {}),
    },
  );
};
