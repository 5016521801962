import { validateBulkCompensationVariantPayment } from 'common/api/variantPayments.api';
import { ValidateBulkVariantPaymentRequest } from 'common/types/variantPayment';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useValidateBulkVariantPaymentMutation = () => {
  return useMutation(
    async ({
      companyId,
      type,
      csvFileUrl,
    }: ValidateBulkVariantPaymentRequest) => {
      const res = await validateBulkCompensationVariantPayment(
        companyId,
        type,
        csvFileUrl,
      );
      return getDataFromResponse(res);
    },
  );
};
