import { getUserSelector } from 'app/store/app.selectors';
import Button from 'common/components/atoms/Button';
import { Briefcase, DollarSign, FileText, User } from 'react-feather';
import { useSelector } from 'react-redux';

import { Box, Modal, Stack, Typography } from '@omnipresentgroup/design-system';

import { CardDetails } from './CardDetails';

type Props = {
  setIsPrimerModalOpen: (params: boolean) => void;
  company: string;
  countryOfWork: string;
  open: boolean;
};

const cardDetailsContent = [
  {
    label:
      'We need some details about you to properly kickstart your onboarding.',
    Icon: User,
    header: 'Complete basic profile',
  },
  {
    label:
      'We will request specific documents which needs to be collected to compliantly hire you in countryOfWork',
    Icon: Briefcase,
    header: 'Documents Upload',
  },
  {
    label: 'You will receive an employment contract to review and sign.',
    Icon: FileText,
    header: 'Contract review and signing',
  },
  {
    label:
      'Once all documents and final checks are completed, you will be enrolled in payroll.',
    Icon: DollarSign,
    header: 'Payroll enrollment',
  },
];

export const EmployeeGetStartedModal = ({
  open,
  setIsPrimerModalOpen,
  company,
  countryOfWork,
}: Props) => {
  const user = useSelector(getUserSelector);
  return (
    <Modal open={open} onOpenChange={(openState) => !openState}>
      <Modal.Header
        onClose={() => setIsPrimerModalOpen(false)}
        title={`Hey ${user.firstName}, Welcome to the Omniplatform!`}
      />

      <Modal.Body>
        <Typography as="span" size="16">
          We are working with your new company,
          <Typography as="span" size="16" weight="bold">
            {` ${company} `}
          </Typography>
          to get you onboarded and compliantly employed in
          <Typography
            as="span"
            size="16"
            weight="bold"
          >{` ${countryOfWork}.`}</Typography>
        </Typography>
        <Box mt="16" mb="8" radius="s">
          <Typography as="span" size="16" color="helper" weight="bold">
            What happens next?
          </Typography>
        </Box>
        {cardDetailsContent.map(({ label, Icon, header }) => {
          return (
            <CardDetails
              countryOfWork={countryOfWork}
              key={label}
              label={label}
              Icon={Icon}
              header={header}
            />
          );
        })}
        <Box mt="16" mb="16" radius="s">
          <Typography as="span" size="16" weight="regular">
            Please make sure you fill in the information accurately as this will
            be used to complete your onboarding.
          </Typography>
        </Box>
      </Modal.Body>

      <Modal.Footer>
        <Stack align="flex-end">
          <Button onClick={() => setIsPrimerModalOpen(false)} palette="primary">
            GET STARTED
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};
