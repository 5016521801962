import { useState } from 'react';

import {
  FileInState,
  FileUploadInput,
} from 'common/components/molecules/FileUploadInput/FileUploadInput';
import { useMutation } from 'react-query';

import {
  Box,
  Button,
  Center,
  Inline,
  notifyError,
  Split,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import {
  BulkPayrollIdUploadPayload,
  bulkUpdatePayrollId,
  getBulkPayrollIdTemplate,
} from '../../../../../../common/api/payrollService.api';
import { exportCSVFileWithFs } from '../../../../../../common/helpers/exportData';
import { BulkUploadOutcome } from './BulkUploadOutcome';

const DEFAULT_FILE_INPUT_STATE: FileInState = {
  fileName: '',
  progress: 0,
  state: 'pending',
  id: 'payroll-id-bulk-upload',
};

export const ProviderIdTab = () => {
  const [fileData, setFileData] = useState<FileInState>({
    ...DEFAULT_FILE_INPUT_STATE,
  });
  const [templateIdCsv, setTemplateIdCsv] = useState<File | undefined>(
    undefined,
  );

  const {
    mutate: downloadPayrollIdTemplate,
    isLoading: isDownloading,
    reset: resetDownloadMutation,
  } = useMutation(getBulkPayrollIdTemplate, {
    onSuccess: (response) => {
      exportCSVFileWithFs(response.data, 'missing-payroll-id-template.csv');
    },
    onError: () => {
      notifyError({
        title: 'Error',
        description:
          'Template creation has failed please try again or contact support',
      });
    },
  });

  const {
    mutate: uploadPayrollIdTemplate,
    isLoading: isUploading,
    data: bulkUploadStatuses,
    reset: resetBulkUploadMutation,
  } = useMutation(
    (data: BulkPayrollIdUploadPayload) => bulkUpdatePayrollId(data),
    {
      onError: () => {
        notifyError({
          title: 'Error',
          description:
            'Something has gone wrong please try again or contact support',
        });
      },
    },
  );

  const onUploadFileHandler = (file: File) => {
    setFileData((fileData) => ({
      ...fileData,
      fileName: file.name,
    }));
    setTemplateIdCsv(file);
  };

  const handleFileSubmitted = (data: Partial<FileInState>) => {
    setFileData((fileData) => ({
      ...fileData,
      state: data.state || fileData.state,
      url: data.url,
      progress: data.progress || fileData?.progress,
    }));
  };

  const onFinishButtonClicked = () => {
    templateIdCsv && uploadPayrollIdTemplate({ file: templateIdCsv });
    setFileData(DEFAULT_FILE_INPUT_STATE);
  };

  const resetTab = () => {
    setFileData(DEFAULT_FILE_INPUT_STATE);
    setTemplateIdCsv(undefined);
    resetBulkUploadMutation();
    resetDownloadMutation();
  };

  return (
    <Center centerChildren data-testid="provider-id-tab">
      <Box bg="primary" depth="s" p="32" radius="m" minH="50vh" w="50%">
        {!bulkUploadStatuses && (
          <>
            <Stack gap="24">
              <Split
                fraction="3/4"
                bg="page"
                border="subtle"
                radius="m"
                p="16"
                align="center"
              >
                <Stack gap="0">
                  <Typography as="span" weight="medium" size="14">
                    Download and complete this template
                  </Typography>
                  <Typography as="span" size="14" color="subtle">
                    Include as many Payroll Provider IDs as you wish
                  </Typography>
                </Stack>

                <Button
                  icon="Download"
                  variant="secondary"
                  size="small"
                  onClick={() => downloadPayrollIdTemplate()}
                  disabled={isDownloading}
                  loading={isDownloading}
                >
                  Download
                </Button>
              </Split>
            </Stack>
            <FileUploadInput
              fileName={fileData.fileName}
              id={fileData.id}
              progress={fileData.progress}
              state={fileData.state}
              fileTypes={['text/csv']}
              updateFile={(_, data) => handleFileSubmitted(data)}
              uploadFile={onUploadFileHandler}
              clear={() => setFileData(DEFAULT_FILE_INPUT_STATE)}
            />
            <Inline justify="right">
              <Button
                onClick={onFinishButtonClicked}
                loading={isUploading}
                disabled={isUploading}
              >
                Finish
              </Button>
            </Inline>
          </>
        )}
        {bulkUploadStatuses?.data && (
          <BulkUploadOutcome
            statusData={bulkUploadStatuses.data}
            onDoneClicked={resetTab}
          />
        )}
      </Box>
    </Center>
  );
};
