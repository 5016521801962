import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Document } from '@contentful/rich-text-types';
import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import Big from 'big.js';
import { ErrorBanner, Loading } from 'common/components';
import Button from 'common/components/atoms/Button';
import LinkAsButton from 'common/components/atoms/LinkAsButton/LinkAsButton';
import MobileButton from 'common/components/atoms/MobileButton/MobileButton';
import { CheckboxBoolean } from 'common/components/molecules/CheckboxBoolean/CheckboxBoolean';
import FormHeader from 'common/components/molecules/FormHeader/FormHeader';
import { StyledBackLink } from 'common/components/molecules/FormHeader/FormHeader.styles';
import { Tabs } from 'common/components/organisms/Tabs/Tabs';
import {
  BenefitsCountryFee,
  DepositFeeWithType,
  FilteredCompanyUpsellFees,
  Salary,
} from 'common/types';
import { EmployerCostFee } from 'common/types/employerCost';
import { toPng } from 'html-to-image';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EMPLOYEES_PATH } from 'paths';
import { getCountryCodeByCountryName } from 'utils/countries';
import {
  useCompanyQuery,
  useContentfulCountriesQuery,
  useFxRateQuery,
  useSingleSeatQuery,
} from 'utils/queries';
import { useBenefitsByCountry } from 'utils/queries/benefits';
import { buildFeesDataFromSeatApiResponse } from 'utils/queries/calculator/useEmployerCostsQuery/employerCostsDataTransformationUtil';
import { useEmployeeQuery } from 'utils/queries/employees/useEmployeeQuery';
import { toTitleCase } from 'utils/string-utils';

import { Viewport } from '@omnipresentgroup/design-system';

import { OmnipresentFees } from '../automatedUpsellSection/OmnipresentFees';
import {
  StyledTable,
  StyledTitle,
} from '../automatedUpsellSection/OmnipresentFees.styles';
import { getSignedDate } from '../EmployeeDetailsPage/EmployeeOrderFormCard/EmployeeOrderFormCard';
import {
  StyledAcceptedDetails,
  StyledButtons,
  StyledCameraIcon,
  StyledCheckboxContainer,
  StyledConfirmationSection,
  StyledConfirmEmployeeSeatPage,
  StyledOrderFormContainer,
  StyledReviewContainerWrapper,
  StyledSubmitButtonContainer,
  StyledTableApprovalKey,
  StyledTableApprovalValue,
  StyledTabsContainer,
} from './ConfirmEmployeeSeatPage.styles';
import {
  HowItWorksContent,
  InvoiceScheduleContent,
  KeyFactsContent,
  OnboardingScheduleContent,
} from './ConfirmEmployeeSeatPageTabContent';
import { InvoiceInfoModal } from './InvoiceInfoModal';

type ConfirmEmployeeSeatProps = {
  testId?: string;
  upsellFeeData?: FilteredCompanyUpsellFees;
  depositFeeData?: DepositFeeWithType;
  annualBaseSalaryData?: Salary;
  currencyExchangeRateData?: Big | number;
  backClick: () => void;
  countryName?: string;
  submitClick: () => void;
  showLoading?: boolean;
  showOrderForm?: boolean;
  country?: string;
  employerCostFees?: EmployerCostFee[];
  benefitsCountryFeeData?: BenefitsCountryFee;
};

type ApprovalDetailsProps = {
  name: string;
  value: string;
}[];

export const ConfirmEmployeeSeatPage = ({
  backClick: backClick,
  submitClick: submitClick,
  countryName,
  showOrderForm = true,
  showLoading,
  upsellFeeData,
  depositFeeData,
  annualBaseSalaryData,
  currencyExchangeRateData,
  country,
  employerCostFees,
  benefitsCountryFeeData,
}: ConfirmEmployeeSeatProps) => {
  const { employeeId, seatId } = useParams<{
    employeeId: string;
    seatId: string;
  }>();
  const [keyFacts, setKeyFacts] = useState<Document>();
  const contentfulCountriesQuery = useContentfulCountriesQuery();
  const { enableAddEmployeeInvoicePopup } = useFlags();

  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

  const handleBackClick = () => {
    if (employeeId) {
      appHistory.push(`${EMPLOYEES_PATH}/${employeeId}`);
      return backClick;
    }
    return backClick();
  };

  const [currentTab, setCurrentTab] = useState('#key-facts');
  const [submitdisabled, setSubmitDisabled] = useState(true);
  const [approvalDetails, setApprovalDetails] = useState<ApprovalDetailsProps>(
    [],
  );
  const seatQuery = useSingleSeatQuery(seatId, {
    queryOptions: { enabled: seatId ? true : false },
  });
  const employeeQuery = useEmployeeQuery(employeeId)?.data;
  const [upsellFees, setUpsellFees] = useState(upsellFeeData);
  const [depositFee, setDepositFee] = useState(depositFeeData);
  const [billingCurrency, setBillingCurrency] = useState('');
  const [annualBaseSalary, setAnnualBaseSalary] =
    useState(annualBaseSalaryData);
  const [benefitsCountryFee, setBenefitsCountryFee] = useState(
    benefitsCountryFeeData,
  );

  const currencyExchangeRate = useFxRateQuery(
    seatQuery.data?.employeeProfile?.annualBaseSalaryCurrency || '',
    seatQuery.data?.billingCurrency || '',
    {
      queryOptions: {
        enabled: !currencyExchangeRateData,
      },
    },
  );

  const companyName = useCompanyQuery(seatQuery?.data?.companyId)?.data?.name;
  const employeeName = employeeQuery?.name
    ? `${toTitleCase(employeeQuery.name)}`
    : '';

  const signedBy = seatQuery?.data?.sowSignature?.signedBy;

  const formattedSignedBy =
    signedBy && !signedBy.match(/unknown user|not found/i)
      ? toTitleCase(signedBy)
          .replace(/\([^)]*\)/i, '')
          .trim()
      : '';

  const signedDate = getSignedDate(seatQuery.data?.sowSignature?.date);

  useEffect(() => {
    if (!upsellFeeData && employeeQuery) {
      setBillingCurrency(
        employeeQuery.payrollDetails.billingCurrency
          ? employeeQuery.payrollDetails.billingCurrency
          : '',
      );

      setUpsellFees({
        managementFee: {
          currency: employeeQuery.payrollDetails.managementFee?.currency
            ? employeeQuery.payrollDetails.managementFee?.currency
            : '',
          amount: Number(employeeQuery.payrollDetails?.managementFee?.amount),
        },
        setupFee: {
          currency: employeeQuery.payrollDetails.setupFee?.currency
            ? employeeQuery.payrollDetails.setupFee?.currency
            : '',
          amount: Number(employeeQuery.payrollDetails?.setupFee?.amount),
        },
      });
    }
  }, [employeeQuery, upsellFeeData]);

  useEffect(() => {
    if (companyName && signedDate) {
      const allApprovalData = [
        { name: 'Client', value: companyName },
        { name: 'Employee', value: employeeName },
        { name: 'Approved by', value: formattedSignedBy },
        { name: 'Approved on', value: signedDate },
      ];
      setApprovalDetails(allApprovalData);
    }
  }, [companyName, employeeName, formattedSignedBy, signedDate]);

  const renderKeyFacts = useCallback(() => {
    const territoryData =
      contentfulCountriesQuery?.data?.filter((territory) =>
        territory.name.includes(
          (countryName as string) || (seatQuery?.data?.countryName as string),
        ),
      ) || [];
    setKeyFacts(territoryData?.[0]?.keyFact);
  }, [
    contentfulCountriesQuery?.data,
    countryName,
    seatQuery?.data?.countryName,
  ]);

  useEffect(() => {
    renderKeyFacts();
  }, [renderKeyFacts]);

  useEffect(() => {
    if (!depositFeeData && seatQuery?.data) {
      setDepositFee({
        depositType: seatQuery?.data.employeeProfile?.depositType as string,
        calculatedAmount: {
          amount: Number(
            seatQuery?.data.employeeProfile?.depositFee?.calculatedAmount,
          ),
          currency: seatQuery?.data.employeeProfile
            ?.annualBaseSalaryCurrency as string,
        },
        convertedAmount: {
          amount: Number(
            seatQuery?.data.employeeProfile?.depositFee?.convertedAmount,
          ),
          currency: seatQuery.data?.billingCurrency as string,
        },
      });
    }
  }, [seatQuery?.data, depositFeeData]);

  useEffect(() => {
    if (!annualBaseSalaryData && seatQuery?.data) {
      setAnnualBaseSalary({
        amount: seatQuery.data?.employeeProfile
          ?.annualBaseSalaryAmount as number,
        currency: seatQuery.data?.employeeProfile
          ?.annualBaseSalaryCurrency as string,
      });
    }
  }, [seatQuery.data, annualBaseSalaryData]);

  const countryCode = getCountryCodeByCountryName(
    (countryName as string) || (seatQuery?.data?.countryName as string),
  );

  const benefitsCountryQuery = useBenefitsByCountry(countryCode);

  useEffect(() => {
    if (!benefitsCountryQuery?.data) {
      return;
    }

    if (!benefitsCountryFeeData) {
      setBenefitsCountryFee({
        ...(benefitsCountryQuery.data as BenefitsCountryFee),
      });
    }
  }, [benefitsCountryQuery?.data, benefitsCountryFeeData]);

  const handleSubmitClick = () => {
    if (!enableAddEmployeeInvoicePopup) {
      return submitClick();
    } else if (enableAddEmployeeInvoicePopup) {
      setInvoiceModalOpen(true);
    }
  };

  const closeInvoiceModal = () => {
    setInvoiceModalOpen(false);
  };

  const handleCheckboxToggle = () => {
    setSubmitDisabled(!submitdisabled);
  };

  const handleCameraClick = () => {
    toPng(document.getElementById('review-order-form') as HTMLElement).then(
      function (dataUrl) {
        const imageElement = window.document.createElement('a');
        imageElement.href = dataUrl;
        imageElement.download = `order-form-${companyName}-${employeeName}.png`;
        (document.body || document.documentElement).appendChild(imageElement);
        imageElement.click();
        URL.revokeObjectURL(imageElement.href);
        imageElement.remove();
      },
    );
  };

  const handleClickTabEvent = useCallback(
    (tabHref: typeof currentTab) =>
      (event: MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setCurrentTab(tabHref);
      },
    [],
  );

  const [KEY_FACTS_TAB, HOW_IT_WORKS_TAB, INVOICE_TAB, ONBOARDING_TAB] = [
    '#key-facts',
    '#how-it-works',
    '#invoice-schedule',
    '#onboarding-schedule',
  ] as const;

  const tabConfig = [
    {
      id: 1,
      title: 'Key facts',
      href: KEY_FACTS_TAB,
      Content: () =>
        KeyFactsContent(
          countryName || (seatQuery?.data?.countryName as string),
          keyFacts as Document,
        ),
      onClick: handleClickTabEvent(KEY_FACTS_TAB),
    },
    {
      id: 2,
      title: 'How it works',
      href: HOW_IT_WORKS_TAB,
      Content: HowItWorksContent,
      onClick: handleClickTabEvent(HOW_IT_WORKS_TAB),
    },
    {
      id: 3,
      title: 'Invoice schedule',
      href: INVOICE_TAB,
      Content: InvoiceScheduleContent,
      onClick: handleClickTabEvent(INVOICE_TAB),
    },
    {
      id: 4,
      title: 'Onboarding schedule',
      href: ONBOARDING_TAB,
      Content: OnboardingScheduleContent,
      onClick: handleClickTabEvent(ONBOARDING_TAB),
    },
  ];

  const selectedTab = tabConfig.filter((tab) => tab.href === currentTab)[0];

  const employerCostsFromSeat = seatQuery?.data?.employeeProfile?.employerCosts;
  const fxFeeFromSeat = seatQuery?.data?.employeeProfile?.fxFee;
  const employerCostFeesProp = showOrderForm
    ? buildFeesDataFromSeatApiResponse(employerCostsFromSeat, fxFeeFromSeat)
    : employerCostFees;

  if (showLoading) {
    return <Loading />;
  }
  if (seatId && seatQuery.isLoading) {
    return <Loading />;
  }
  if (currencyExchangeRate.isLoading) {
    return <Loading />;
  }

  if (seatId && seatQuery.isError && seatQuery.error instanceof Error) {
    return <ErrorBanner errorMessage={seatQuery.error.message} />;
  }

  if (seatId && !seatQuery.isSuccess) {
    return <></>;
  }

  return (
    <PageContent>
      <StyledConfirmEmployeeSeatPage
        data-testid="confirm-employee-seat-page"
        className="bigStack"
      >
        <FormHeader
          title={showOrderForm ? 'Order Form' : 'Review employee request'}
          currentStage={1}
          stages={1}
        />
        <StyledButtons>
          <StyledBackLink data-testid="page-header-backlink">
            <LinkAsButton onClick={handleBackClick}>
              {showOrderForm ? 'Back to employee' : 'Back to add an employee'}
            </LinkAsButton>
          </StyledBackLink>
          {showOrderForm && (
            <StyledCameraIcon
              data-testid="screenshot-icon"
              onClick={handleCameraClick}
              cursor="pointer"
            />
          )}
        </StyledButtons>

        <StyledReviewContainerWrapper
          data-testid="review-order-form"
          id="review-order-form"
        >
          <StyledTabsContainer>
            <Tabs tabsToDisplay={tabConfig} currentTab={selectedTab} />
          </StyledTabsContainer>

          <StyledConfirmationSection>
            <OmnipresentFees
              upsellFee={upsellFees as FilteredCompanyUpsellFees}
              depositFee={depositFee as DepositFeeWithType}
              annualBaseSalary={annualBaseSalary as Salary}
              currencyExchangeRate={
                (currencyExchangeRateData ||
                  currencyExchangeRate?.data?.data) as Big
              }
              country={country || seatQuery?.data?.countryName || ''}
              billingCurrency={billingCurrency}
              employerCostFees={employerCostFeesProp}
              benefitsCountryFee={benefitsCountryFee}
            />

            {!showOrderForm ? (
              <>
                <StyledCheckboxContainer>
                  <CheckboxBoolean
                    name="Review Proposal Checkbox"
                    title="Terms and conditions"
                    label=""
                    onChange={handleCheckboxToggle}
                    initialValue={!submitdisabled}
                  />
                  I have read and agreed to the terms and conditions in this
                  proposal
                </StyledCheckboxContainer>
                <Viewport devices={['laptop', 'desktop', 'highRes']}>
                  <StyledSubmitButtonContainer>
                    <Button
                      onClick={handleSubmitClick}
                      testId="confirm-employee-seat-complete-order"
                      palette="primary"
                      disabled={submitdisabled}
                    >
                      Add Employee
                    </Button>
                  </StyledSubmitButtonContainer>
                </Viewport>
              </>
            ) : (
              ''
            )}
            <StyledOrderFormContainer>
              {seatQuery.data?.sowSignature?.date && (
                <StyledAcceptedDetails>
                  <StyledTitle>Approval Details</StyledTitle>
                  <StyledTable>
                    <tbody>
                      {approvalDetails.map((approvalItem) =>
                        approvalItem.value ? (
                          <tr key={approvalItem.name}>
                            <StyledTableApprovalKey>
                              {approvalItem.name}
                            </StyledTableApprovalKey>
                            <StyledTableApprovalValue>
                              {approvalItem.value}
                            </StyledTableApprovalValue>
                          </tr>
                        ) : (
                          []
                        ),
                      )}
                    </tbody>
                  </StyledTable>
                </StyledAcceptedDetails>
              )}
            </StyledOrderFormContainer>
          </StyledConfirmationSection>
        </StyledReviewContainerWrapper>
        <InvoiceInfoModal
          isModalOpen={invoiceModalOpen}
          onAgreeClick={submitClick}
          closeModal={closeInvoiceModal}
        />
      </StyledConfirmEmployeeSeatPage>
      <Viewport devices={['phone', 'tablet']}>
        <MobileButton
          type="submit"
          onClick={handleSubmitClick}
          disabled={submitdisabled}
        >
          Add Employee
        </MobileButton>
      </Viewport>
    </PageContent>
  );
};
