import { getCompanyManagersRequest } from 'common/api/companies.api';
import { ONE_MINUTE } from 'common/constants';
import { User } from 'common/types';
import { useQuery, UseQueryOptions } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { companiesKeys } from '../keys';

export const useCompanyManagersQuery = (
  {
    companyId,
  }: {
    companyId: string;
  },
  options?: Pick<UseQueryOptions, 'staleTime' | 'select'>,
) => {
  return useQuery<User[]>(
    companiesKeys.managers(companyId),
    () => getCompanyManagersRequest(companyId).then(getDataFromResponse),
    {
      refetchOnWindowFocus: false,
      staleTime: options?.staleTime || ONE_MINUTE,
      enabled: !!companyId,
      select: (data: User[]) =>
        (options?.select ? options?.select(data) : data) as User[],
    },
  );
};
