import { useState } from 'react';

import Big from 'big.js';
import { TooltipModal } from 'common/components/organisms/TooltipModal';
import {
  BenefitsCountryFee,
  DepositFeeWithType,
  FilteredCompanyUpsellFees,
  Salary,
} from 'common/types';
import { EmployerCostFee } from 'common/types/employerCost';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { HelpCircle } from 'react-feather';
import { getCountryFlagByCountryName } from 'utils/countries';
import { formatCurrency } from 'utils/currencies';

import { getDescriptionByFeeName } from './FeesTooltipDescription';
import {
  HelpCircleIconContainer,
  StyledHeader,
  StyledHeaderSuffix,
  StyledHeadsUp,
  StyledNotice,
  StyledNoticeSubtitle,
  StyledOmniPresentFeesWrapper,
  StyledTable,
  StyledTableAmount,
  StyledTableData,
  StyledTableRow,
  StyledTitle,
} from './OmnipresentFees.styles';
import {
  Fee,
  getDepositFee,
  getMonthlyTotalAmount,
  getOneTimeTotalAmount,
} from './OmnipresentFeesUtil';

const MONTHS_IN_A_YEAR = 12;
const DEFAULT_VALUE_FOR_CONVERSION_RATE = 1;

type OmnipresentFees = {
  upsellFee: FilteredCompanyUpsellFees;
  depositFee: DepositFeeWithType;
  annualBaseSalary: Salary;
  currencyExchangeRate: Big | number;
  country: string;
  billingCurrency: string;
  employerCostFees?: EmployerCostFee[];
  benefitsCountryFee?: BenefitsCountryFee;
};

type TableRowProps = {
  description: string;
  fee?: Fee;
  secondFee?: Fee;
  isTotal?: boolean;
  hasTooltip?: boolean;
  isSubitem?: boolean;
  hasTBC?: boolean;
};

export const OmnipresentFees: React.FC<OmnipresentFees> = ({
  upsellFee,
  depositFee,
  annualBaseSalary,
  country,
  currencyExchangeRate,
  billingCurrency,
  employerCostFees,
  benefitsCountryFee,
}: OmnipresentFees) => {
  const [toolTipModalFee, setTooltipModalFee] = useState('');
  const { showSupplementaryBenefitsCost } = useFlags();

  const TableRow = ({
    description,
    fee: { amount, currency } = { amount: 0, currency: '' },
    secondFee,
    isTotal,
    hasTooltip,
    isSubitem,
    hasTBC,
  }: TableRowProps) => {
    return (
      <StyledTableRow>
        <StyledTableData
          variant={isTotal ? 'total' : isSubitem ? 'alternative' : ''}
          isSubitem={!!isSubitem}
        >
          {description}
          {hasTooltip && (
            <HelpCircleIconContainer>
              <HelpCircle
                data-testid={`${description.replace(/\s/g, '-')}-tooltip-icon`}
                size={16}
                onClick={() => setTooltipModalFee(description)}
              ></HelpCircle>
            </HelpCircleIconContainer>
          )}
        </StyledTableData>
        <StyledTableAmount
          isSubitem={!!isSubitem}
          variant="alternative"
          hasSecondFee={!!secondFee}
        >
          {secondFee &&
            secondFee?.currency !== currency &&
            formatCurrency(secondFee.amount, secondFee.currency)}
        </StyledTableAmount>
        <StyledTableAmount
          isSubitem={!!isSubitem}
          variant={isTotal ? 'total' : isSubitem ? 'alternative' : ''}
          hasSecondFee={!!secondFee}
        >
          {hasTBC ? 'Needs setup' : formatCurrency(amount, currency)}
        </StyledTableAmount>
      </StyledTableRow>
    );
  };

  const shouldRender = upsellFee?.managementFee;

  const monthlySalaryFee: Fee = {
    amount: annualBaseSalary?.amount / MONTHS_IN_A_YEAR,
    currency: annualBaseSalary?.currency,
  };

  const monthlySalaryFeeInBillingCurrency: Fee = {
    amount:
      monthlySalaryFee.amount *
      Big(currencyExchangeRate || DEFAULT_VALUE_FOR_CONVERSION_RATE).toNumber(),
    currency: billingCurrency || upsellFee?.managementFee?.currency || '',
  };

  const isZeroDepositInsurance =
    depositFee?.depositType === 'Zero Deposit Insurance';

  const isAdvancedPaymentDeposit =
    depositFee?.depositType === 'Advanced Payment Deposit (APD)';

  const advancedPaymentDepositFee: Fee = getDepositFee(
    depositFee,
    upsellFee,
    billingCurrency,
    currencyExchangeRate,
  );

  const zeroDepositInsuranceFee: Fee = getDepositFee(
    depositFee,
    upsellFee,
    billingCurrency,
  );

  const monthlyTotalAmount: number = getMonthlyTotalAmount(
    depositFee,
    upsellFee,
    billingCurrency,
    monthlySalaryFeeInBillingCurrency,
    employerCostFees,
  );

  const oneTimeTotalAmount: number = getOneTimeTotalAmount(
    depositFee,
    upsellFee,
    currencyExchangeRate,
    billingCurrency,
  );

  const onClickTooltipGoBack = () => setTooltipModalFee('');

  const employerCostFeesWithValue = employerCostFees?.filter(
    (cost) => (cost?.fee?.amount || 0) > 0,
  );

  return (
    <>
      {!!toolTipModalFee && (
        <TooltipModal
          title={toolTipModalFee}
          clickHandler={onClickTooltipGoBack}
        >
          {getDescriptionByFeeName(toolTipModalFee)}
        </TooltipModal>
      )}
      {shouldRender && (
        <StyledOmniPresentFeesWrapper>
          <StyledTitle>Ongoing monthly employee costs and fees</StyledTitle>
          <StyledHeader>
            {`${getCountryFlagByCountryName(country)} ${country}`}
            {monthlySalaryFee.amount > 0 && (
              <StyledHeaderSuffix>
                {` (${formatCurrency(
                  annualBaseSalary?.amount,
                  annualBaseSalary?.currency,
                )} Annual Base Salary)`}
              </StyledHeaderSuffix>
            )}
          </StyledHeader>
          <StyledTable>
            <tbody>
              {monthlySalaryFee.amount > 0 && (
                <TableRow
                  description="Base Salary"
                  fee={monthlySalaryFeeInBillingCurrency}
                  secondFee={monthlySalaryFee}
                />
              )}
              <TableRow
                description="Management fee"
                fee={upsellFee?.managementFee}
                hasTooltip={true}
              />
              {employerCostFeesWithValue &&
                employerCostFeesWithValue?.map((employerCost) => (
                  <TableRow
                    key={employerCost.description}
                    description={employerCost?.description || ''}
                    fee={employerCost.fee}
                    isSubitem={employerCost.isSubitem}
                  />
                ))}
              {showSupplementaryBenefitsCost &&
                benefitsCountryFee?.isOffered && (
                  <TableRow
                    description="Supplementary benefit cost estimate"
                    fee={benefitsCountryFee?.supplementaryBenefitsFee}
                    hasTBC={
                      !benefitsCountryFee?.supplementaryBenefitsFee
                        ? true
                        : false
                    }
                    hasTooltip={true}
                  />
                )}
              {isZeroDepositInsurance && (
                <TableRow
                  description="Zero deposit insurance"
                  fee={zeroDepositInsuranceFee}
                  hasTooltip={true}
                />
              )}
              <StyledTableRow>
                <StyledNoticeSubtitle>
                  All fees above are exclusive of VAT.
                </StyledNoticeSubtitle>
              </StyledTableRow>
              <TableRow
                description="Estimated Total"
                fee={{
                  amount: monthlyTotalAmount,
                  currency: upsellFee?.managementFee?.currency || '',
                }}
                isTotal={true}
              />
            </tbody>
          </StyledTable>
          <StyledHeadsUp>
            Actual figure may be above or below the quoted amount
          </StyledHeadsUp>
          <StyledTitle>One-time fees and deposits</StyledTitle>
          {isAdvancedPaymentDeposit && (
            <StyledNotice>
              The APD will be refunded to you, and any severance deposit will be
              paid to the employee, upon termination of the employment.
            </StyledNotice>
          )}
          <StyledTable>
            <tbody>
              <TableRow
                description="Set-up fee"
                fee={upsellFee?.setupFee}
                hasTooltip={true}
              />
              {isAdvancedPaymentDeposit && (
                <TableRow
                  description="Advanced payment deposit (APD)"
                  fee={advancedPaymentDepositFee}
                  hasTooltip={true}
                />
              )}
              <StyledTableRow>
                <StyledNoticeSubtitle>
                  All fees above are exclusive of VAT.
                </StyledNoticeSubtitle>
              </StyledTableRow>
              <TableRow
                description="Estimated Total"
                fee={{
                  amount: oneTimeTotalAmount,
                  currency: upsellFee?.managementFee?.currency || '',
                }}
                isTotal={true}
              />
            </tbody>
          </StyledTable>
          <StyledHeadsUp>
            Actual figure may be above or below the quoted amount
          </StyledHeadsUp>
        </StyledOmniPresentFeesWrapper>
      )}
    </>
  );
};
