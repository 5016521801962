import { downloadBulkCompensationTemplate } from 'common/api/employees.api';
import { DownloadBulkCompensationCSVTemplateRequest } from 'common/types';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useDownloadBulkCompensationCSVTemplateMutation = () => {
  return useMutation(
    ({
      companyId,
      employeesIds,
      compensationType,
    }: DownloadBulkCompensationCSVTemplateRequest) =>
      downloadBulkCompensationTemplate(
        companyId,
        employeesIds,
        compensationType,
      ).then(getDataFromResponse),
  );
};
