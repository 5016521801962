import { Balance } from 'common/types/invoiceReports';

export const mockBalances: Balance[] = [
  {
    companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    currency: 'USD',
    daysOverdue: 17,
    accountBalance: -4595034.5,
    payrollBalanceUnpaid: -4406218,
    depositBalanceUnpaid: -677379.9,
    prepaymentBalanceUnpaid: -620409.5,
    creditBalanceUnapplied: 508057.16,
    payrollBalanceUnapplied: 2121.02,
    depositBalanceUnapplied: 252035.81,
    prepaymentBalanceUnapplied: 253900.31,
    cashBalanceUnapplied: 600915.94,
    depositBalancePaid: 478966.25,
    prepaymentBalancePaid: 400189.88,
    depositAndPrepaymentBalanceHeld: -1802725.5,
    isPausedUpsell: false,
    lastSyncedAt: '2023-05-12T16:53:16.000Z',
  },
  {
    companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    currency: 'CAD',
    daysOverdue: 217,
    accountBalance: -1238053.1,
    payrollBalanceUnpaid: -1238053.1,
    depositBalanceUnpaid: 0,
    prepaymentBalanceUnpaid: 0,
    creditBalanceUnapplied: 0,
    payrollBalanceUnapplied: 0,
    depositBalanceUnapplied: 0,
    prepaymentBalanceUnapplied: 0,
    cashBalanceUnapplied: 0,
    depositBalancePaid: 0,
    prepaymentBalancePaid: 199766.52,
    depositAndPrepaymentBalanceHeld: 0,
    isPausedUpsell: true,
    lastSyncedAt: '2023-05-12T16:53:16.000Z',
  },
  {
    companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    currency: 'SEK',
    daysOverdue: 3,
    accountBalance: -803.57,
    payrollBalanceUnpaid: -803.57,
    depositBalanceUnpaid: 0,
    prepaymentBalanceUnpaid: 0,
    creditBalanceUnapplied: 0,
    payrollBalanceUnapplied: 0,
    depositBalanceUnapplied: 0,
    prepaymentBalanceUnapplied: 0,
    cashBalanceUnapplied: 0,
    depositBalancePaid: -2.2737368e-13,
    prepaymentBalancePaid: -9.094947e-13,
    depositAndPrepaymentBalanceHeld: 0,
    isPausedUpsell: true,
    lastSyncedAt: '2023-05-12T16:53:16.000Z',
  },
  {
    companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    currency: 'GBP',
    daysOverdue: 7,
    accountBalance: -13885.95,
    payrollBalanceUnpaid: -13885.95,
    depositBalanceUnpaid: 0,
    prepaymentBalanceUnpaid: 0,
    creditBalanceUnapplied: 0,
    payrollBalanceUnapplied: 0,
    depositBalanceUnapplied: 0,
    prepaymentBalanceUnapplied: 2.543743e-12,
    cashBalanceUnapplied: 0,
    depositBalancePaid: 0.08,
    prepaymentBalancePaid: -37809.16,
    depositAndPrepaymentBalanceHeld: 2.543743e-12,
    isPausedUpsell: true,
    lastSyncedAt: '2023-05-12T16:53:16.000Z',
  },
  {
    companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    currency: 'EUR',
    daysOverdue: null,
    accountBalance: 0,
    payrollBalanceUnpaid: 0,
    depositBalanceUnpaid: 0,
    prepaymentBalanceUnpaid: 0,
    creditBalanceUnapplied: 0,
    payrollBalanceUnapplied: 0,
    depositBalanceUnapplied: 0,
    prepaymentBalanceUnapplied: 0,
    cashBalanceUnapplied: 0,
    depositBalancePaid: 32780,
    prepaymentBalancePaid: 20526.93,
    depositAndPrepaymentBalanceHeld: -53306.93,
    isPausedUpsell: false,
    lastSyncedAt: '2023-05-12T16:53:16.000Z',
  },
];
