export const isUpsellPendingEmployeeFlowEnabled = () =>
  process.env.VITE_UPSELL_PENDING_EMPLOYEE_FLOW === 'true';

export const isLocalServiceProviderAdminViewsEnabled = () =>
  process.env.VITE_LOCAL_SERVICE_PROVIDER_ADMIN_VIEWS === 'true';

export const isLocalServiceProviderCreateUpdateEnabled = () =>
  process.env.VITE_LOCAL_SERVICE_PROVIDER_CREATE_UPDATE === 'true';

export const isHRISIntegrationEnabled = () =>
  process.env.VITE_HRIS_INTEGRATION === 'true';

export const isUpsellProposalGenerationEnabled = () =>
  process.env.VITE_UPSELL_PROPOSAL_GENERATION === 'true';

export const isPayrollLocalServiceProviderPaymentEnabled = () =>
  process.env.VITE_PAYROLL_LSP_PAYMENT === 'true';

export const isLocalServiceProviderPaymentsHistoryEnabled = () =>
  process.env.VITE_PAYROLL_LSP_PAYMENTS_HISTORY === 'true';

/** @todo Remove this feature flag after payroll monthly export feature is completed */
export const isPayrollExportMonthlyDataEnabled = () =>
  process.env.VITE_PAYROLL_EXPORT_MONTHLY_DATA === 'true';

export const isAdminManagementEnabled = () =>
  process.env.VITE_ADMIN_MANAGEMENT === 'true';

export const isPayrollImportInvoiceFromSpreadsheetEnabled = () =>
  process.env.VITE_PAYROLL_IMPORT_INVOICE_FROM_SPREADSHEET === 'true';

export const isEmployeeBulkUploadEnabled = () =>
  process.env.VITE_EMPLOYEES_BULK_UPLOAD_ENABLED === 'true';

export const enableEditContractResponses = () =>
  process.env.VITE_EDIT_CONTRACT_RESPONSES === 'true';

export const isProcessInvoicesEnabled = () =>
  process.env.VITE_PROCESS_INVOICES_ENABLED === 'true';

export const isLSPRedesignEnabled = () =>
  process.env.VITE_LSP_PAGE_REDESIGN === 'true';

export const shouldShowHasSupplementaryBenefits = () =>
  process.env.VITE_VIEW_HAS_SUPPLEMENTARY_BENEFITS === 'true';

export const isClientBenefitsPreferenceEnabled = () =>
  process.env.VITE_ENABLE_CLIENT_BENEFITS_PREFERENCE === 'true';

export const isBenefitsWorkspaceEnabled = () =>
  process.env.VITE_BENEFITS_WORKSPACE_ENABLED === 'true';
