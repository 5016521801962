import {
  downloadPayrollReportGrossToNet,
  getPayrollReportsPeriodList,
} from 'common/api/financialDocuments.api';
import { THIRTY_MINUTES } from 'common/constants';
import { useMutation, useQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { notifyError } from '@omnipresentgroup/design-system';

import { payrollKeys } from '../keys';

export const useDownloadPayrollReportGrossToNetMutation = () => {
  return useMutation(
    (options: { companyId: string; payrollPeriod: string }) =>
      downloadPayrollReportGrossToNet(options).then(getDataFromResponse),
    {
      retry: 0,
      onError: () => {
        notifyError({
          title: 'Error',
          description: 'Failed to download file from server.',
        });
      },
    },
  );
};

export const usePayrollReportPeriodListQuery = (companyId: string) => {
  return useQuery(
    payrollKeys.payrollReportPeriodsList(companyId),

    () =>
      getPayrollReportsPeriodList(companyId)
        .then(getDataFromResponse)
        .then(({ payrollReports }) =>
          [...payrollReports].sort(
            (a: string, b: string) => Number(b) - Number(a),
          ),
        ),
    {
      staleTime: THIRTY_MINUTES,
      onError: () => {
        notifyError({
          title: 'Error',
          description: 'Failed to read payroll reports from server.',
        });
      },
      enabled: !!companyId,
    },
  );
};
