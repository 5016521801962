import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import DetailsCard from 'common/components/organisms/DetailsCard/DetailsCard';
import { transformLspType } from 'common/store/transformers/lsps.transformer';
import { LSPApiResponse } from 'common/types';
import { useSelectUserRoles } from 'utils/hooks';
import { useLspQuery } from 'utils/queries';

import PageHeader from '../../molecules/PageHeader/PageHeader';

const lspDetailsSections = (lsp: LSPApiResponse) => [
  {
    name: 'lsp-details',
    title: 'Details',
    details: [
      {
        label: 'Name',
        value: lsp.name,
        type: 'string',
      },
      {
        label: 'Type',
        value: lsp.type,
        displayValue: transformLspType(lsp.type),
        type: 'string',
      },
      {
        label: 'Operating countries',
        value: lsp.countries,
        displayValue:
          lsp.countries && lsp.countries.length > 0
            ? [...lsp.countries].sort().join(', ')
            : '',
        type: 'string',
      },
      {
        label: 'Number of employees',
        value: lsp.noOfEmployees,
        displayValue: lsp.noOfEmployees,
        type: 'number',
      },
      {
        label: 'Additional information',
        value: lsp.profileUrl,
        displayValue: lsp.profileUrl,
        showFor: 'admin',
        type: 'link',
      },
      {
        label: 'Additional Contact email',
        value: lsp.contactEmails,
        displayValue: lsp.contactEmails?.join(', '),
        showFor: 'admin',
        type: 'link',
      },
      {
        label: 'LSP Id',
        value: lsp.id,
        type: 'string',
      },
    ],
  },
];

type Props = {
  lspId: string;
  displayLspPageHeader?: boolean;
};

export const LspProfile = ({ lspId, displayLspPageHeader = false }: Props) => {
  const { main: userRole } = useSelectUserRoles();
  const lspQuery = useLspQuery(lspId);

  return (
    <div className="stack" data-testid="lsp-profile">
      {lspQuery.isLoading && <Loading />}

      {lspQuery.isError && (
        <ErrorBanner errorMessage={lspQuery.error.message} />
      )}

      {lspQuery.isSuccess && displayLspPageHeader && (
        <PageHeader title={lspQuery.data.name} />
      )}

      {lspQuery.isSuccess &&
        lspDetailsSections(lspQuery.data).map(
          (section) =>
            section && (
              <DetailsCard
                key={section.title}
                fieldsToDisplay={section.details}
                title={section.title}
                testId={`${section.name}-card`}
                userRole={userRole}
              />
            ),
        )}
    </div>
  );
};
