import { Fragment, useCallback } from 'react';

import {
  FormValues,
  useFormContext,
} from 'common/components/molecules/Form/FormProvider';
import FormHeader from 'common/components/molecules/FormHeader/FormHeader';
import { renderSingleField } from 'common/components/molecules/FormPage/FormPage';
import { StyledFormPage } from 'common/components/molecules/FormPage/FormPage.styles';
import FormSidebar from 'common/components/molecules/FormSidebar/FormSidebar';
import FormSubmitButton from 'common/components/molecules/FormSubmitButton/FormSubmitButton';
import {
  StyledFormContainer,
  StyledStagedForm,
  StyledStagedFormPage,
  StyledStagedFormWrapper,
} from 'common/components/organisms/StagedForm/StagedForm.styles';
import { StyledEditPageRequiredFields } from 'common/pages/EditDetailsPage/EditDetailsPage.styles';

import { Viewport } from '@omnipresentgroup/design-system';

type FormProps = {
  showCancel?: boolean;
  ctaText?: string;
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
  isLoading?: boolean;
  showRequiredText?: boolean;
  reverseButtonOrder?: boolean;
  formWidth?: string;
  borderless?: boolean;
  padding?: string;
};

export const Form = ({
  ctaText,
  onSubmit,
  onCancel,
  showCancel = false,
  isLoading = false,
  showRequiredText = true,
  reverseButtonOrder = false,
  formWidth = '',
  borderless = false,
  padding = '',
}: FormProps) => {
  const {
    fields,
    values,
    onFieldChange,
    formTitle,
    clearFieldErrors,
    validate,
    isSubmitDisabled,
  } = useFormContext();

  const onCTAClick = useCallback(() => {
    const errors = validate();
    if (Object.keys(errors).length) {
      return;
    }

    onSubmit(values);
  }, [onSubmit, validate, values]);

  return (
    <>
      <Viewport devices={['laptop', 'desktop', 'highRes']}>
        {formTitle && <FormSidebar title={formTitle} />}
      </Viewport>
      <Viewport devices={['phone', 'tablet']}>
        <FormHeader title={formTitle} currentStage={1} stages={[]} />
      </Viewport>
      <StyledStagedForm noMargin={!formTitle} formWidth={formWidth}>
        <StyledStagedFormWrapper borderless={borderless} padding={padding}>
          <StyledFormContainer>
            <StyledStagedFormPage>
              {showRequiredText && (
                <StyledEditPageRequiredFields>
                  * required field
                </StyledEditPageRequiredFields>
              )}
              <StyledFormPage>
                {fields.map((field) => (
                  <Fragment key={field.name}>
                    {!field.hidden &&
                      renderSingleField({
                        field,
                        onFieldFocus: clearFieldErrors,
                        onFormChanged: onFieldChange,
                      })}
                  </Fragment>
                ))}
                <FormSubmitButton
                  disabled={isSubmitDisabled || isLoading}
                  isLoading={isLoading}
                  ctaText={ctaText}
                  onCTAClick={onCTAClick}
                  showCancel={showCancel}
                  onCancelClick={onCancel}
                  reverseOrder={reverseButtonOrder}
                />
              </StyledFormPage>
            </StyledStagedFormPage>
          </StyledFormContainer>
        </StyledStagedFormWrapper>
      </StyledStagedForm>
    </>
  );
};
