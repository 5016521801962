import { ACCESS_TOKEN } from '../../../appConstants';
import {
  asyncActionKeys,
  CLEAR_ERROR,
  CLEAR_PLATFORM_INVITATION_STATUS,
  SET_ACCESS_TOKEN,
  SET_ERROR_MESSAGE,
  SET_USER_ROLE,
} from '../app.constants';
import { transformUser } from '../app.transformers';

const initialState = {
  loading: true,
  error: null,
  errorMessage: null,
  accessToken: localStorage.getItem(ACCESS_TOKEN),
  user: {},
  userRole: '',
  emailChangeVerified: false,
  platformInvitationStatus: '',
};

export const appReducer = (state = initialState, action) => {
  switch (true) {
    case CLEAR_ERROR === action.type:
      return {
        ...state,
        error: null,
        errorMessage: null,
      };
    case SET_ERROR_MESSAGE === action.type:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case asyncActionKeys.GET_USER_END === action.type:
      return {
        ...state,
        loading: false,
        user: action.payload,
        transformedUser: transformUser(action.payload),
      };
    case asyncActionKeys.VERIFY_EMAIL_CHANGE_END === action.type:
      return {
        ...state,
        emailChangeVerified: action.payload,
        loading: false,
      };
    case asyncActionKeys.SEND_PLATFORM_INVITATION_START === action.type:
      return {
        ...state,
        platformInvitationStatus: 'InProgress',
      };
    case asyncActionKeys.SEND_PLATFORM_INVITATION_END === action.type:
      return {
        ...state,
        platformInvitationStatus: 'SENT',
      };
    case asyncActionKeys.SEND_PLATFORM_INVITATION_ERROR === action.type:
      return {
        ...state,
        platformInvitationStatus: 'ERROR',
      };
    case CLEAR_PLATFORM_INVITATION_STATUS === action.type:
      return {
        ...state,
        platformInvitationStatus: '',
      };
    case /^.+_START$/.test(action.type):
      // We handle upload document loading state in documentStorage.reducer
      if (
        action.type === 'UPLOAD_DOCUMENT_START' ||
        action.type === 'DOWNLOAD_DOCUMENT_START' ||
        action.type === 'SEND_PLATFORM_INVITATION_START' ||
        action.type === 'GET_DATA_EXPORT_START' ||
        action.type === 'UPDATE_EMPLOYEE_EMPLOYMENT_FAQ_START'
      ) {
        return {
          ...state,
          error: null,
        };
      }
      return {
        ...state,
        loading: true,
        error: null,
      };
    case /^.+_END$/.test(action.type):
      return {
        ...state,
        loading: false,
      };
    case /^.+_ERROR$/.test(action.type):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_ACCESS_TOKEN === action.type:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_USER_ROLE === action.type:
      return {
        ...state,
        userRole: action.payload,
      };
    default:
      return state;
  }
};
