import { useCallback, useMemo } from 'react';

import Button from 'common/components/atoms/Button';
import { useHrisIntegrationContext } from 'common/contexts';
import { DropdownOption } from 'common/types';
import Select from 'react-select';
import { composePossessiveNoun } from 'utils/composePossessiveNoun';
import { useHRISEmployeesListQuery } from 'utils/queries';

import { Typography } from '@omnipresentgroup/design-system';

import { customDropdownStyles } from '../../DropdownInput/DropdownInput';
import {
  formatOptionLabel,
  loadingMessage,
  noOptionsMessage,
} from './constants';
import * as Styled from './HRISIntegrationInput.styles';

export const HRISIntegrationInput = () => {
  const hrisEmployeesQuery = useHRISEmployeesListQuery();

  const {
    hasFilledForm,
    isRefetchDisabled,
    importedEmployee,
    selectedOption,
    setImportedEmployee,
    setSelectedOption,
    handleResetForm,
    handleImportEmployeeData,
    setRefetchRetries,
  } = useHrisIntegrationContext();

  const selectOptions = useMemo(
    () =>
      hrisEmployeesQuery.data?.map<DropdownOption>((employee) => {
        const firstName = employee.first_name || '';
        const lastName = employee.last_name || '';
        const email = employee.work_email || employee.personal_email || '';

        return {
          value: employee.id || '',
          label: `${firstName} ${lastName} ${email && `(${email})`}`,
        };
      }),
    [hrisEmployeesQuery.data],
  );

  const handleSelect = useCallback(
    (option: DropdownOption | null) => {
      setSelectedOption(option);

      const employeeData = hrisEmployeesQuery.data?.find(
        (employee) => employee.id === option?.value,
      );

      if (employeeData) {
        setImportedEmployee(employeeData);
      }
    },
    [hrisEmployeesQuery.data, setSelectedOption, setImportedEmployee],
  );

  const refetchEmployeesList = () => {
    setRefetchRetries((prevState) => prevState + 1);
    hrisEmployeesQuery.refetch();
  };

  const onClick = hasFilledForm ? handleResetForm : handleImportEmployeeData;

  return (
    <Styled.Container>
      <Styled.InputLabel htmlFor="hris-integration-input">
        Import employee data from your HRIS
      </Styled.InputLabel>

      <Styled.IntegrationWrapper>
        <Styled.InputContainer data-testid="hris-integration-input-container">
          <Select
            name="hris-integration"
            inputId="hris-integration-input"
            closeMenuOnSelect
            blurInputOnSelect
            backspaceRemovesValue
            escapeClearsValue
            isSearchable
            isClearable
            isDisabled={hasFilledForm}
            isLoading={hrisEmployeesQuery.isFetching}
            onChange={handleSelect}
            value={selectedOption}
            placeholder="Employee name or email"
            styles={customDropdownStyles}
            options={selectOptions}
            noOptionsMessage={noOptionsMessage}
            formatOptionLabel={formatOptionLabel}
            loadingMessage={loadingMessage}
            components={{ DropdownIndicator: null, ClearIndicator: undefined }}
          />
        </Styled.InputContainer>

        <Button
          palette={importedEmployee.id ? 'primary' : undefined}
          variant={hasFilledForm ? 'outlined' : undefined}
          disabled={!importedEmployee.id}
          onClick={onClick}
        >
          {hasFilledForm ? 'Clear prefilled data' : 'Import employee data'}
        </Button>
      </Styled.IntegrationWrapper>

      {hrisEmployeesQuery.isSuccess && !hrisEmployeesQuery.data.length && (
        <Styled.CopyContainer>
          <Typography as="p" hideParagraphSpacing size="16" color="helper">
            Can&apos;t find your employee?
            <Button
              palette="primary"
              variant="link"
              onClick={refetchEmployeesList}
              disabled={isRefetchDisabled}
            >
              {isRefetchDisabled ? 'Try again later' : 'Click here to refresh'}
            </Button>
          </Typography>

          <Typography as="p" hideParagraphSpacing size="16" color="helper">
            Please note that it might take up to 24hours for employee data to be
            available
          </Typography>
        </Styled.CopyContainer>
      )}

      {hasFilledForm && (
        <Styled.CopyContainer>
          <Typography as="p" hideParagraphSpacing size="16" color="helper">
            {`The form has been prefilled with ${composePossessiveNoun(
              importedEmployee.first_name!,
            )} information from your HRIS`}
          </Typography>
        </Styled.CopyContainer>
      )}
    </Styled.Container>
  );
};
