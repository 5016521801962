import { sendVeInviteRequest } from 'common/api/globalSetup.api';
import { useMutation } from 'react-query';

type UseVeInviteMutation = {
  onError?: (error: any) => void;
  onSuccess?: (error: any) => void;
};

export const useVeInviteMutation = ({
  onError,
  onSuccess,
}: UseVeInviteMutation = {}) => {
  return useMutation(sendVeInviteRequest, { onError, onSuccess });
};
