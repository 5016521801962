import { mockManagerEmployeesApiResponse } from '__mocks__/employees.mock';
import { HTTPStatusCodesEnum } from 'common/constants';
import { ManagerEmployeesApiResponse } from 'common/types';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

export const managerPermissionsHandlers = [
  rest.get<ManagerEmployeesApiResponse, { managerUserId: string }>(
    composeEndpoint('/employees/company/:companyId/manager/:managerUserId'),
    (req, res, ctx) => {
      if (req.params.managerUserId === 'manager-with-no-employees') {
        return res(ctx.json({ employees: [] }));
      }
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockManagerEmployeesApiResponse),
      );
    },
  ),
];
