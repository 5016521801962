import { HOLIDAY_TYPES } from 'appConstants';
import { employeeHasSupplementaryBenefitsOptions } from 'common/pages/AddEditEmployeeSeatPage/addCompanyEmployeeSeatFormConfig';
import { FormStage, Seat, UserRoleType } from 'common/types';
import JoiImport from 'joi';
import { shouldShowHasSupplementaryBenefits } from 'utils/featureFlags';
import ianaDomains from 'utils/ianaDomains.json';
import {
  addressSchema,
  optionalAddressSchema,
} from 'utils/validators/address.schema';

import DateExtension from '@omnipresentgroup/joi-date';

const Joi = JoiImport.extend(DateExtension) as JoiImport.Root;

type Params = {
  seat: Seat;
  isPrivateAddressRequired: boolean;
  userRole: UserRoleType;
  enableNewCompensation: boolean;
};

export const getStaticStages = ({
  seat,
  isPrivateAddressRequired,
  userRole,
  enableNewCompensation,
}: Partial<Params> = {}): FormStage[] => [
  {
    name: "Employee's details",
    stage: 1,
    fields: [
      {
        name: 'country',
        label: 'Country of work',
        type: 'string',
        value: seat?.countryName,
        error: null,
        required: true,
        readOnly: true,
      },
      {
        name: 'firstName',
        label: 'First name',
        type: 'string',
        value: seat?.employeeProfile?.firstName || seat?.firstName,
        error: null,
        required: true,
      },
      {
        name: 'lastName',
        label: 'Last name',
        type: 'string',
        value: seat?.employeeProfile?.lastName || seat?.lastName,
        error: null,
        required: true,
      },
      {
        name: 'pronouns',
        label: 'Pronouns',
        type: 'string',
        value: seat?.employeeProfile?.pronouns || '',
        error: null,
        required: false,
      },
      {
        name: 'citizenship',
        label: 'Citizenship',
        type: 'country',
        value: seat?.employeeProfile?.citizenship || '',
        error: null,
        required: true,
      },
      {
        name: 'personalEmail',
        label: "Employee's personal email",
        contextualInfo: "This will be used for the employee's account login",
        type: 'email',
        value: seat?.employeeProfile?.personalEmail || '',
        error: null,
        required: true,
      },
      {
        name: 'privateAddress',
        label: "Employee's personal address",
        type: 'address',
        value: {
          city: seat?.employeeProfile?.privateAddress?.city || '',
          addressLine1:
            seat?.employeeProfile?.privateAddress?.addressLine1 || '',
          addressLine2:
            seat?.employeeProfile?.privateAddress?.addressLine2 || '',
          addressLine3:
            seat?.employeeProfile?.privateAddress?.addressLine3 || '',
          postalCode: seat?.employeeProfile?.privateAddress?.postalCode || '',
          country: seat?.countryName,
        },
        error: null,
        required: isPrivateAddressRequired,
      },
      {
        name: 'rightToWorkCheckCompleted',
        label: `Is this person eligible to work in ${
          seat?.countryName ? seat.countryName : 'this country'
        } and does not require sponsorship for this position?`,

        contextualInfo: `This is usually only the case if the person is a national citizen, has obtained the appropriate visa or residence permit that allows them to work in ${
          seat?.countryName ? seat.countryName : 'this country'
        } and they do not require further sponsorship. If no is selected, you can still continue to complete the profile and we’ll get in touch about the next steps.`,
        type: 'checkbox',
        value: [
          {
            label: 'Yes',
            value: true,
            selected: !!seat?.employeeProfile?.rightToWorkCheckCompleted,
          },
          {
            label: 'No',
            value: false,
            selected: !seat?.employeeProfile?.rightToWorkCheckCompleted,
          },
        ],
        error: null,
        required: true,
      },
    ],
    schema: Joi.object().keys({
      country: Joi.string()
        .required()
        .messages({ 'string.empty': 'Please enter a country' }),
      citizenship: Joi.string()
        .required()
        .messages({ 'string.empty': 'Please enter a country' }),
      firstName: Joi.string()
        .required()
        .messages({ 'string.empty': 'Please enter a first name' }),
      lastName: Joi.string()
        .required()
        .messages({ 'string.empty': 'Please enter a last name' }),
      pronouns: Joi.string().allow(null, ''),
      personalEmail: Joi.string()
        .prefs({ convert: false })
        .email({ tlds: { allow: ianaDomains } })
        .trim()
        .messages({
          'string.trim':
            'Email addresses should not contain any spaces at the beginning or the end',
          'string.email': 'Please enter a valid email address',
          'string.empty': 'Please enter an email address',
        }),
      privateAddress: isPrivateAddressRequired
        ? addressSchema
        : optionalAddressSchema,
      rightToWorkCheckCompleted: Joi.boolean().required(),
    }),
  },
  {
    name: 'Role details',
    stage: 2,
    fields: [
      {
        name: 'jobTitle',
        label: 'Job title',
        type: 'string',
        value: seat?.employeeProfile?.jobTitle || '',
        error: null,
        required: true,
      },
      {
        name: 'jobDescription',
        label: 'Job description',
        type: 'text',
        value: seat?.employeeProfile?.jobDescription || '',
        error: null,
        required: true,
        placeholder: 'Please provide a detailed job description',
      },
      {
        name: 'annualBaseSalary',
        label: 'Annual base salary',
        type: 'employeeCurrency',
        value: {
          currency: seat?.employeeProfile?.annualBaseSalaryCurrency || '',
          amount: seat?.employeeProfile?.annualBaseSalaryAmount || null,
        },
        error: null,
        required: true,
      },
      {
        name: 'additionalCompensation',
        label: 'Any additional financial compensation?',
        type: 'conditionalInput',
        value: seat?.employeeProfile?.additionalCompensation || '',
        error: null,
        hidden: enableNewCompensation,
      },
      {
        name: 'locationOfWork',
        label: 'Location of work',
        type: 'locationOfWork',
        value: {
          type: seat?.employeeProfile?.locationOfWorkType || 'Home office',
          address: {
            city: seat?.employeeProfile?.locationOfWorkAddress?.city || '',
            hideAddressLines: true,
            hidePostalCode: true,
          },
        },
        error: null,
        noLabel: true,
        required: true,
      },
      {
        name: 'hasSupplementaryBenefits',
        label: 'Enrolled in supplementary benefits?',
        type:
          shouldShowHasSupplementaryBenefits() && userRole === 'admin'
            ? 'dropdown'
            : 'hidden',
        options: employeeHasSupplementaryBenefitsOptions,
        value: 'unknown',
        error: null,
        required: true,
      },
    ],
    schema: Joi.object().keys({
      hasSupplementaryBenefits: Joi.string()
        .required()
        .messages({ 'string.empty': 'Please choose a value' }),
      jobTitle: Joi.string()
        .required()
        .messages({ 'string.empty': 'Please enter a job title' }),
      jobDescription: Joi.string()
        .required()
        .messages({ 'string.empty': 'Please enter a job description' }),
      locationOfWork: Joi.object()
        .keys({
          type: Joi.string().required(),
          address: addressSchema,
        })
        .required(),
      annualBaseSalary: Joi.object().keys({
        amount: Joi.number().required().messages({
          'number.base': 'Please enter a valid annual base salary amount',
        }),
        currency: Joi.string().allow('', null),
      }),
      additionalCompensation: Joi.string().allow(''),
    }),
  },
];
