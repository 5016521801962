import { ModalWrapper } from 'common/components/molecules/ModalWrapper';

import {
  Button,
  Inline,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import { ResignationRequestDetailCard } from './ResignationRequestDetailCard';

type ResignationConfirmationModalProps = {
  closeFn: () => void;
  onSubmit: () => void;
};

export const ResignationConfirmationModal = ({
  closeFn,
  onSubmit,
}: ResignationConfirmationModalProps) => {
  const handleOnClickButton = () => {
    closeFn && closeFn();
  };

  const handleOnSubmitButton = () => {
    onSubmit && onSubmit();
  };

  return (
    <ModalWrapper onClose={handleOnClickButton} showCloseIcon fixedWidth="672">
      <Stack gap="24" p="24">
        <Stack gap="0">
          <Typography as="span" size="20" weight="medium">
            Initiate resignation
          </Typography>
          <Typography as="span" size="16" color="subtle">
            Are you sure you want to submit your resignation request?
          </Typography>
        </Stack>
        <ResignationRequestDetailCard />
        <Inline justify="right">
          <Button
            onClick={() => {
              handleOnSubmitButton();
              handleOnClickButton();
            }}
          >
            Submit request
          </Button>
        </Inline>
      </Stack>
    </ModalWrapper>
  );
};
