import { createQueryKeys } from 'utils/react-query-utils';

export const companiesKeys = createQueryKeys('companies', (createKey) => ({
  upsellFees: (companyId: string) => createKey(companyId, 'upsell-fees'),
  singleCompany: (companyId?: string) => createKey(companyId, 'single-company'),
  opportunity: (companyId: string, opportunityId: string) =>
    createKey(companyId, 'opportunity', opportunityId),
  managers: (companyId: string) => createKey(companyId, 'managers'),
  employeesOnboarding: (companyId: string) =>
    createKey(companyId, 'employees-onboarding'),
  availableProducts: (companyId?: string) =>
    createKey(companyId, 'available-products'),
}));
