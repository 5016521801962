import { HTTPStatusCodesEnum } from 'common/constants';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

import {
  Condition,
  EntryDefinition,
  EntryDefinitionType,
  Option,
} from '../types';

const defaultTemplate = {
  id: 38863,
  entryDefinitions: [
    {
      attribute: 'fullName',
      type: 'text',
      position: 0,
      party_id: 'Party A',
      default_value: '',
      question: 'Provide your full name',
      question_plain_text: 'Provide your full name',
      hint: null,
      required: false,
      color: null,
      condition: null,
      options: [],
    },
  ],
};

/*
  One of each:
    1 text, 
    1 date
    1 number
    1 select_list
    1 radio_button
    1 text (conditioned by the radio_button)
 */

export const defaultEntryDefinitions = [
  {
    attribute: 'fullLegalName',
    type: 'text',
    position: 20,
    party_id: 'Party A',
    default_value: null,
    question: 'Full Legal Name',
    question_plain_text: 'Full Legal Name',
    hint: null,
    required: false,
    color: null,
    condition: null,
    options: [],
  },
  {
    attribute: 'startDate',
    type: 'date',
    position: 2,
    party_id: 'Party A',
    default_value: null,
    question: 'Please provide the employment starting date.',
    question_plain_text: 'Please provide the employment starting date.',
    hint: 'Please pick a date.',
    required: true,
    color: null,
    condition: null,
    options: [],
  },
  {
    attribute: 'workingHoursStartTime',
    type: 'number',
    position: 7,
    party_id: 'Party A',
    default_value: '9:00',
    question: 'Please provide the average daily starting time.',
    question_plain_text: 'Please provide the average daily starting time.',
    hint: 'Please enter the starting hour in the following format - 9:00 for 9 am/ 6:00 for 6pm.',
    required: true,
    color: null,
    condition: null,
    options: [],
  },
  {
    attribute: 'noOfHolidays',
    type: 'select_list',
    position: 12,
    party_id: 'Party A',
    default_value: '',
    question:
      'Please provide the number of days for annual leave excluding public holidays.',
    question_plain_text:
      'Please provide the number of days for annual leave excluding public holidays.',
    hint: 'Statutory minimum holiday entitlement is 20 days excluding public holidays. Best practice is granting 25 - 28 excluding public holidays.',
    required: true,
    color: null,
    condition: null,
    options: [
      { label: '20', value: '20' },
      { label: '21', value: '21' },
      { label: '22', value: '22' },
      { label: '23', value: '23' },
      { label: '24', value: '24' },
      { label: '25', value: '25' },
      { label: '26', value: '26' },
      { label: '27', value: '27' },
      { label: '28', value: '28' },
      { label: '29', value: '29' },
      { label: '30', value: '30' },
    ],
  },
  {
    attribute: 'restrictiveCovenantsYesNo',
    type: 'radio_button',
    position: 19,
    party_id: 'Party A',
    default_value: null,
    question:
      'Should the employment contract include Restrictive Covenant Clauses (Non-compete, Non-solicit)? ',
    question_plain_text:
      'Should the employment contract include Restrictive Covenant Clauses (Non-compete, Non-solicit)?',
    hint: null,
    required: true,
    color: null,
    condition: null,
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
  },
  {
    attribute: 'yourIndustry',
    type: 'text',
    position: 20,
    party_id: 'Party A',
    default_value: null,
    question: 'What industry are you working in?',
    question_plain_text: 'What industry are you working in?',
    hint: null,
    required: true,
    color: null,
    condition: {
      ast: {
        Equals: [{ Att: 'restrictiveCovenantsYesNo' }, { String: 'Yes' }],
      },
      showUnanswered: false,
    },
    options: [],
  },
];

export const generateEntryDefinition = ({
  type = 'text',
  attribute = 'test field',
  party_id = 'Party A',
  condition = null,
  options = [],
  ...rest
}: Partial<EntryDefinition>): EntryDefinition => ({
  attribute,
  type,
  position: 0,
  party_id,
  default_value: '',
  question: `Provide your ${attribute}`,
  question_plain_text: `Provide your ${attribute}`,
  hint: null,
  required: false,
  condition,
  color: null,
  options,
  ...rest,
});

export const generateConditionalEntryDefinition = (): EntryDefinition[] => [
  {
    attribute: 'parentQuestionYesNo',
    color: null,
    condition: null,
    default_value: '',
    hint: null,
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
    party_id: 'Party A',
    position: 19,
    question: 'Will you answer yes? ',
    question_plain_text:
      'If you answer "Yes" an additional question will be asked',
    required: true,
    type: 'radio_button',
  },
  {
    attribute: 'additionalQuestion',
    color: null,
    condition: {
      ast: {
        Equals: [{ Att: 'parentQuestionYesNo' }, { String: 'Yes' }],
      },
    },
    default_value: '',
    hint: null,
    options: [],
    party_id: 'Party A',
    position: 20,
    question: 'Will you provide an answer to the additional question as well?',
    question_plain_text: 'You answered "Yes" earlier there\'s no way back',
    required: true,
    type: 'text',
  },
];

export const generateCheckboxField = ({
  type = 'checkbox',
  name = 'test checkbox field',
  condition = null,
  options = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ],
  ...rest
}: {
  type: EntryDefinitionType;
  options?: Option[];
  name: string;
  condition?: {
    ast: Condition;
  } | null;
}): EntryDefinition => ({
  attribute: name,
  type,
  position: 0,
  party_id: 'Party A',
  default_value: '',
  question: `Provide your ${name}`,
  question_plain_text: `Provide your ${name}`,
  hint: null,
  required: false,
  condition,
  color: null,
  options,
  ...rest,
});

export const generateTemplate = (override = {}) => ({
  ...defaultTemplate,
  ...override,
});

export const contractHandlers = [
  rest.get(composeEndpoint('/contracts/templates'), (req, res, ctx) => {
    const countryCode = req.url.searchParams.get('countryCode');

    if (countryCode === 'kp') {
      return res(ctx.json([generateTemplate()]));
    }

    if (countryCode === 'fr') {
      return res(ctx.status(400, 'bad request'));
    }

    return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json([]));
  }),
  rest.get(
    composeEndpoint(`/contracts/documents/:avvokaDocumentId/download`),
    (req, res, ctx) => {
      return res(ctx.status(201), ctx.json({ linkToDocument: 'http://test' }));
    },
  ),
];
