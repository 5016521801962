import { useHistory } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { getUserTenantId } from 'app/store/app.selectors';
import { Loading } from 'common/components';
import { ADD_COMPENSATION_PATH, BULK_COMPENSATION_PATH } from 'paths';
import { useSelector } from 'react-redux';

import {
  Breadcrumbs,
  Button,
  Inline,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import { CompensationRequestList } from '../components/CompensationRequestList/CompensationRequestList';

export const PAGE_TITLE = 'Additional pay';

export const CompensationPage = () => {
  const history = useHistory();
  const userTenantId = useSelector(getUserTenantId);

  return (
    <PageContent style={{ position: 'relative' }}>
      <Stack>
        <Stack gap="16">
          <Breadcrumbs
            crumbs={[
              { id: 'home', title: 'Home', onClick: () => history.push('/') },
              { id: 'compensation', title: PAGE_TITLE, active: true },
            ]}
          />
          <Stack gap="32">
            <Stack gap="16">
              <Stack gap="32">
                <Inline
                  justify="space-between"
                  overflow="auto"
                  align="center"
                  minH={40}
                >
                  <Typography
                    as="h2"
                    hideParagraphSpacing
                    size="24"
                    weight="medium"
                  >
                    {PAGE_TITLE}
                  </Typography>
                  <Inline>
                    <Button
                      id="compensation-bulk-upload"
                      variant="secondary"
                      onClick={() => history.push(BULK_COMPENSATION_PATH)}
                    >
                      Upload bulk changes
                    </Button>
                    <Button
                      id="compensation-submit-change"
                      onClick={() => history.push(ADD_COMPENSATION_PATH)}
                    >
                      Add
                    </Button>
                  </Inline>
                </Inline>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {!userTenantId ? (
          <Loading />
        ) : (
          <CompensationRequestList companyId={userTenantId} />
        )}
      </Stack>
    </PageContent>
  );
};
