import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledFormSidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 212px;
  gap: ${({ theme: { space } }) => space[16]};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    display: none;
  }
`;

export const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme: { colors } }) => colors['background-inverse']};
`;
