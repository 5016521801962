import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Stack } from '@omnipresentgroup/design-system';

import TabTitle from '../../molecules/TabTitle/TabTitle';
import { StyledTabsContainer, StyledTabsTitlesContainer } from './Tabs.styles';
import { TabsProp } from './Tabs.types';

export const Tabs = ({
  tabsToDisplay,
  currentTab,
  className,
  children,
}: TabsProp) => {
  const history = useHistory();

  const isValidCurrentTab = currentTab && !currentTab.isDisabled;
  const tabFallback = tabsToDisplay[0];
  const currentTabWithFallback = isValidCurrentTab ? currentTab : tabFallback;

  useEffect(() => {
    if (isValidCurrentTab || currentTab?.isLoading) {
      return;
    }

    const pathWithTabFallback = `${history.location.pathname}${tabFallback?.href}`;

    history.push(pathWithTabFallback);
  }, [history, isValidCurrentTab, tabFallback?.href]);
  return (
    <StyledTabsContainer data-testid="tabs-container" className={className}>
      <StyledTabsTitlesContainer>
        {tabsToDisplay.map(
          ({ id, href, title, isDisabled, onClick, isLoading = false }) => (
            <TabTitle
              key={id}
              tabHref={href}
              tabTitle={title}
              isDisabled={isDisabled}
              isLoading={isLoading}
              currentTab={id === currentTabWithFallback?.id}
              onClick={onClick}
            />
          ),
        )}
      </StyledTabsTitlesContainer>
      {children && (
        <Stack pt="16" pb="16">
          {children}
        </Stack>
      )}
      <div>
        {currentTabWithFallback?.Content && <currentTabWithFallback.Content />}
      </div>
    </StyledTabsContainer>
  );
};
