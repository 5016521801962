import { SyntheticEvent, useState } from 'react';

import {
  StyledChevronContainer,
  StyledChevronDown,
  StyledChevronUp,
  StyledDescription,
  StyledDetails,
  StyledDetailsDiv,
  StyledDetailsTitle,
  StyledSubtitle,
  StyledSummary,
} from './Collapsible.styles';

export type CollapsibleProps = {
  id: string;
  title: string | React.ReactNode;
  subtitle?: string;
  children: React.ReactNode;
  noMaxHeigh?: boolean;
  titleAction?: React.ReactNode;
  openContent?: boolean;
  noOverflow?: boolean;
};

export const Collapsible = ({
  title,
  subtitle,
  children,
  id,
  noMaxHeigh,
  titleAction,
  openContent,
  noOverflow,
}: CollapsibleProps) => {
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(
    openContent || false,
  );
  const handleToggleClick = (event: SyntheticEvent<HTMLElement, Event>) => {
    const target = event.target as HTMLDetailsElement;
    setIsDetailsExpanded(target.hasAttribute('open'));
  };
  return (
    <StyledDetails
      data-testid={id}
      onToggle={(event) => handleToggleClick(event)}
      open={isDetailsExpanded}
    >
      <StyledSummary>
        <StyledDetailsDiv>
          <StyledDetailsTitle>{title}</StyledDetailsTitle>
          {titleAction}
          <StyledChevronContainer>
            {!isDetailsExpanded ? <StyledChevronDown /> : <StyledChevronUp />}
          </StyledChevronContainer>
        </StyledDetailsDiv>

        {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      </StyledSummary>

      <StyledDescription noOverflow={noOverflow} noMaxHeigh={noMaxHeigh}>
        {children}
      </StyledDescription>
    </StyledDetails>
  );
};
Collapsible.displayName = 'Collapsible';
