import { Box, Stack, Typography } from '@omnipresentgroup/design-system';

import { getCountryByCountryCode } from '../../../../utils/countries';
import { BenefitsCountrySelectionState } from '../../../types';
import { useBenefitsSelectionContext } from './BenefitsSelectionContext';
import { Checked } from './BenefitsStep.styles';

export const TITLE: Record<
  BenefitsCountrySelectionState,
  (country?: string) => string
> = {
  NO: (country) =>
    `You've previously elected to have no benefits in ${country}`,
  YES: (country) => `You've already set up benefits in ${country}`,
  REMIND_LATER: () => `NA`,
};

export const DESCRIPTION: Record<BenefitsCountrySelectionState, string> = {
  NO: 'This will also be applied to this employee. If you wish to change your selection please reach out to your account manager or contact support.',
  YES: 'The same benefits will be applied to this employee.',
  REMIND_LATER: 'NA',
};

export const BenefitsStepDone = () => {
  const { clientPreferences, countryCode } = useBenefitsSelectionContext();

  if (!clientPreferences.populated) {
    return null;
  }

  const country = getCountryByCountryCode(countryCode)?.label;

  return (
    <Stack gap="16">
      <Box mt="32">
        <Checked />
      </Box>
      <Typography as="span" size="16" weight="bold">
        {clientPreferences.value && TITLE[clientPreferences.value](country)}
      </Typography>
      <Typography as="span" size="14">
        {clientPreferences.value && DESCRIPTION[clientPreferences.value]}
      </Typography>
    </Stack>
  );
};

BenefitsStepDone.displayName = 'BenefitsStepDone';
