import { useMemo } from 'react';

import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  OnChangeFn,
  PaginationOptions,
  useReactTable,
} from '@tanstack/react-table';
import Big from 'big.js';
import { FIVE } from 'common/constants';
import {
  DataProcessingError,
  GrossToNetFile,
  GrossToNetProcessingStatus,
} from 'common/types';
import { isEmpty } from 'lodash';
import { Info } from 'luxon';
import { VisibleModalEnum } from 'omniplatform/admin/pages/PayrollInstanceDetailPage/PayrollInstanceDetailPageContext';

import {
  Button,
  DropdownMenu,
  Icon,
  Link,
  Skeleton,
  Tooltip,
} from '@omnipresentgroup/design-system';

import {
  formatValueToFixed,
  PayReportCustomCell,
} from '../../PayReportPreviewPage/PayReportPreviewTable';
import { PayReportViewFilter } from '../../PayReportPreviewPage/PayReportViewFilter';
import { calculateVariancePercentage } from '../../PayReportPreviewPage/prepareDataForTreeTable';
import {
  TableCell,
  TagTableCell,
} from '../../PayrollInstanceDetailPage/Tabs/PayReportsTab/PayReportsTab';
import { canBeDeleted, RowData } from '../PayrollInstanceLspBillDetailPage';
import { StickyLastColumnTable } from '../PayrollInstanceLspBillDetailPage.styles';

enum ColumnHeaders {
  employee = 'Employee',
  status = 'Status',
  lspBill = 'LSP bill',
}

const WeightMap: Record<string, number> = {
  salary: 1,
  allowance: 2,
  'variable pay': 3,
  expenses: 4,
  'pension contribution': 5,
  'benefit contribution': 6,
  'social costs': 7,
  total: 99999, // make sure it's in the last position
};

const columnHelper = createColumnHelper<RowData>();

const getBaseColumns = (
  dataProcessingErrors: DataProcessingError[],
  onEmployeeClick: (data: RowData) => void,
) => [
  columnHelper.accessor('name', {
    cell: (info) => {
      return (
        <Link
          size="14"
          css={{ whiteSpace: 'nowrap' }}
          onClick={() => {
            onEmployeeClick(info.row.original);
          }}
        >
          {info.getValue()}
        </Link>
      );
    },
    header: () => (
      <PayReportCustomCell
        fontWeight="bold"
        align="center"
        value={ColumnHeaders.employee}
      />
    ),
  }),
  ...(!isEmpty(dataProcessingErrors)
    ? [
        columnHelper.display({
          id: 'error-display',
          cell: (info) => {
            const { gtnId, status } = info.row.original;

            const errorMessage = dataProcessingErrors
              ?.reduce<string[]>((acc, processingError) => {
                const errorMatchesGtn = processingError.dataFrameId === gtnId;

                return errorMatchesGtn
                  ? [...acc, processingError.message]
                  : acc;
              }, [])
              .join('\n');

            return status === GrossToNetProcessingStatus.ERROR ? (
              <Tooltip
                description={errorMessage}
                id={`${gtnId}-error=tooltip`}
                delayDuration={200}
              >
                <Icon icon="AlertTriangle" size="16" />
              </Tooltip>
            ) : (
              <></>
            );
          },
        }),
      ]
    : []),
  columnHelper.accessor((row) => row.hasJustification || '', {
    id: 'hasJustification',
    header: '',
    cell: (info) =>
      info.getValue() ? <Icon icon="BrandLine" size="16" /> : null,
  }),
  columnHelper.accessor('status', {
    cell: (info) => <TagTableCell value={info.getValue()} />,
    header: () => (
      <PayReportCustomCell
        align="center"
        fontWeight="bold"
        value={ColumnHeaders.status}
      />
    ),
  }),
  columnHelper.accessor('lspData.lspBillNumber', {
    cell: (info) => <PayReportCustomCell value={info.getValue()} />,
    header: () => (
      <PayReportCustomCell
        align="center"
        fontWeight="bold"
        value={ColumnHeaders.lspBill}
      />
    ),
  }),
];

const getMenuColumn = (
  onRowDelete: (info: CellContext<RowData, string>) => () => void,
  onViewStatusHistory: (info: CellContext<RowData, string>) => () => void,
  onViewLspData: (info: CellContext<RowData, string>) => () => void,
) =>
  columnHelper.accessor('gtnId', {
    cell: (info) => {
      const options = [
        {
          name: 'View history',
          icon: 'Clock',
          onClick: onViewStatusHistory(info),
        },

        {
          name: 'Delete',
          icon: 'Trash',
          disabled: !canBeDeleted(info.row.original.status),
          onClick: onRowDelete(info),
        },
      ];

      if (
        info.row.original.lspData ||
        info.row.original.status !== GrossToNetProcessingStatus.PUBLISHED
      ) {
        options.unshift({
          name: info.row.original.lspData
            ? `View${
                info.row.original.status !==
                GrossToNetProcessingStatus.PUBLISHED
                  ? ' or edit'
                  : ''
              } LSP data`
            : 'Add LSP data',
          icon: 'Edit',
          onClick: onViewLspData(info),
        });
      }

      return (
        <DropdownMenu onOpenChange={function noRefCheck() {}} options={options}>
          <Button icon="Dots" variant="tertiary" size="small" />
        </DropdownMenu>
      );
    },
    header: '',
  });

const getColumns = (
  uniqueOmniplatformColumnNames: string[],
  dataProcessingErrors: DataProcessingError[],
  onRowDelete: (info: CellContext<RowData, string>) => () => void,
  onEmployeeClick: (data: RowData) => void,
  onViewStatusHistory: (info: CellContext<RowData, string>) => () => void,
  onViewLspData: (info: CellContext<RowData, string>) => () => void,
  varianceConfig?: { varianceMonth: string; varianceCycle: string },
) => {
  const omniplatformColumns = uniqueOmniplatformColumnNames
    .sort((a, b) => {
      const endSortingWeight = uniqueOmniplatformColumnNames.length + 1;

      const weightA = WeightMap[a.toLowerCase()] || endSortingWeight;
      const weightB = WeightMap[b.toLowerCase()] || endSortingWeight;

      return weightA - weightB;
    })
    .map((payrollGlobal) => {
      if (!varianceConfig) {
        return columnHelper.accessor(`totals.${payrollGlobal}`, {
          cell: (info) => (
            <PayReportCustomCell
              align="right"
              value={formatValueToFixed(info.getValue()) || '-'}
            />
          ),
          header: () => (
            <PayReportCustomCell
              fontWeight="bold"
              align="right"
              value={payrollGlobal}
              width="10em"
            />
          ),
        });
      }

      return columnHelper.group({
        header: () => (
          <PayReportCustomCell
            value={payrollGlobal}
            fontWeight="bold"
            align="center"
          />
        ),
        id: payrollGlobal,
        columns: [
          columnHelper.accessor(`totals.${payrollGlobal}`, {
            cell: (info) => (
              <PayReportCustomCell value={info.getValue() || '-'} />
            ),
            header: () => <TableCell value="This cycle" />,
          }),
          columnHelper.accessor(
            (row) => row.variancesPreviousTotals?.[payrollGlobal] || '-',
            {
              id: `previous.${payrollGlobal}`,
              cell: (info) => (
                <PayReportCustomCell value={info.getValue() || '-'} />
              ),
              header: () => (
                <PayReportCustomCell
                  value={`${
                    Info.months()[Number(varianceConfig.varianceMonth) - 1]
                  }: Cycle ${varianceConfig.varianceCycle}`}
                />
              ),
            },
          ),
          columnHelper.accessor(
            (row) => {
              if (!row.variancesPreviousTotals?.[payrollGlobal]) {
                return '-';
              }

              const variance = Big(row.totals[payrollGlobal] || 0)
                .minus(row.variancesPreviousTotals[payrollGlobal])
                .toFixed(2);

              return variance;
            },
            {
              id: `variance.${payrollGlobal}`,
              cell: (info) => (
                <PayReportCustomCell value={info.getValue() || '-'} />
              ),
              header: () => <TableCell value="Variance" />,
            },
          ),
          columnHelper.accessor(
            (row) => {
              if (!row.variancesPreviousTotals?.[payrollGlobal]) {
                return '-';
              }

              const percentage = calculateVariancePercentage(
                Big(row.totals[payrollGlobal] || 0).toNumber(),
                Big(
                  row.variancesPreviousTotals?.[payrollGlobal] || 0,
                ).toNumber(),
              );

              return `${percentage.toFixed(2)} %`;
            },
            {
              id: `percentage.${payrollGlobal}`,
              cell: (info) => (
                <PayReportCustomCell endCell value={info.getValue()} />
              ),
              header: () => (
                <PayReportCustomCell align="center" endCell value="%" />
              ),
            },
          ),
        ],
      });
    });

  return [
    ...getBaseColumns(dataProcessingErrors, onEmployeeClick),
    ...omniplatformColumns,
    getMenuColumn(onRowDelete, onViewStatusHistory, onViewLspData),
  ];
};

export const LspBillDetailTable = ({
  tableData,
  dataProcessingErrors,
  rowSelection,
  setRowSelection,
  setVisibleModal,
  paginationProps,
  setPagination,
  isLoading,
  payReportViewFilter,
  onEmployeeClick,
  onViewStatusHistory,
  onViewLspData,
}: {
  tableData: RowData[];
  dataProcessingErrors: DataProcessingError[];
  rowSelection: Record<number, boolean>;
  setRowSelection: OnChangeFn<Record<number, boolean>>;
  setVisibleModal: (modalName: VisibleModalEnum) => void;
  paginationProps: PaginationOptions;
  setPagination: React.Dispatch<
    React.SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  >;
  isLoading: boolean;
  payReportViewFilter: PayReportViewFilter;
  onEmployeeClick: (data: RowData) => void;
  onViewStatusHistory: ({
    gtnId,
    fileName,
  }: {
    gtnId: string;
    fileName: string;
  }) => void;
  onViewLspData: (
    gtnId: string,
    canEdit: boolean,
    lspData: GrossToNetFile['lspData'] | null,
  ) => void;
}) => {
  const uniqueOmniplatformColumnNames: string[] = useMemo(() => {
    return Array.from(
      tableData.reduce<Set<string>>((acc, entry) => {
        Object.keys(entry.totals).forEach((totalEntry) => acc.add(totalEntry));
        return acc;
      }, new Set<string>()),
    );
  }, [tableData]);

  const columns = useMemo(
    () =>
      getColumns(
        uniqueOmniplatformColumnNames,
        dataProcessingErrors,
        (info) => () => {
          setRowSelection({ [info.row.index]: true });
          setVisibleModal(VisibleModalEnum.gtnDelete);
        },
        onEmployeeClick,
        (info) => () => {
          onViewStatusHistory({
            gtnId: info.row.original.gtnId,
            fileName: info.row.original.name,
          });
        },
        (info) => () => {
          onViewLspData(
            info.row.original.gtnId,
            info.row.original.status !== GrossToNetProcessingStatus.PUBLISHED,
            info.row.original.lspData || null,
          );
        },
        ...(payReportViewFilter.showVariance
          ? [
              {
                varianceMonth: payReportViewFilter.month,
                varianceCycle: payReportViewFilter.payCycle,
              },
            ]
          : []),
      ),
    [
      uniqueOmniplatformColumnNames,
      dataProcessingErrors,
      payReportViewFilter.showVariance,
      payReportViewFilter.month,
      payReportViewFilter.payCycle,
      setRowSelection,
      setVisibleModal,
    ],
  );

  const table = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: true,
    state: {
      rowSelection,
    },
    onRowSelectionChange: (...args) => {
      console.log('onRowSelectionChange args', args);
      setRowSelection(...args);
    },
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  const LoadingColumns: ColumnDef<any, any>[] = [
    columnHelper.display({
      id: 'employeeName',
      cell: () => <Skeleton height={16} />,
      header: () => ColumnHeaders.employee,
      size: 200,
    }),
    columnHelper.display({
      id: 'status',
      cell: () => <Skeleton height={16} />,
      maxSize: 20,
      header: () => ColumnHeaders.status,
      size: 16,
    }),
    columnHelper.display({
      id: 'total',
      cell: () => <Skeleton height={16} />,
      maxSize: 20,
      header: () => 'Total',
      size: 16,
    }),
  ];

  const loadingTable = useReactTable({
    // Core Config
    data: Array.from({ length: FIVE }, (_value, index) => index),
    columns: LoadingColumns,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  return (
    <StickyLastColumnTable
      table={isLoading ? loadingTable : table}
      wrapperProps={{
        maxH: '60vh',
        overflow: 'visible',
        style: { border: 'none' },
      }}
      showHeader={false}
      useRowSelection={tableData.length !== 0}
      usePagination
      paginationProps={paginationProps}
      emptyStateProps={{
        id: 'empty-state-pay-reports-table',
        title: 'No LSP bill entries available.',
        description: `You haven't uploaded any LSP bill entries.`,
      }}
    />
  );
};
