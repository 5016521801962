import styled, { css } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

import { StyledBodyCopy } from '../../atoms/Text/Copy.styles';

const warningBanner = css`
  background: ${({ theme: { colors } }) => colors['red-100']};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.m} ${borderStyles.solid} ${colors['red-300']}`};
`;

const infoBanner = css`
  background: ${({ theme: { colors } }) => colors['orange-100']};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.m} ${borderStyles.solid} ${colors['orange-300']}`};
`;

export const StyledInfoBannerContainer = styled.div`
  display: flex;
  max-width: ${({ maxWidth }) => maxWidth};
  flex-direction: column;
  border-radius: ${({ theme: { radii } }) => radii.s};
  padding: ${({ theme: { space } }) => space[4]};
  margin: ${({ theme: { space }, margin }) =>
    margin ? margin : `0 ${space[64]} 0 0`};
  ${(props) => (props.type === 'warning' ? warningBanner : infoBanner)};

  svg {
    margin-right: ${({ theme: { space } }) => space[8]};
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
  }

  @media (max-width: ${tokens.breakpoint.tablet}) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: ${({ theme: { space } }) => space[32]};
  }
`;

export const StyledInfoCopy = styled(StyledBodyCopy)`
  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-left: 0;
  }
`;
