import { HTTPStatusCodesEnum } from 'common/constants';
import { composeEndpoint } from 'mocks/utils';
import { rest } from 'msw';
import { mockBalances } from 'omniplatform/manager/__mocks__/managerInvoiceBalances.mock';

import { financialDocsClient } from '../../api';
import { INVOICE_REPORTS_API_PATH } from './financialDocuments.api';

export const invoiceReportHandlers = [
  rest.get(
    composeEndpoint(
      `${INVOICE_REPORTS_API_PATH}/companies/:companyId/count/`,
      financialDocsClient,
    ),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json({ count: '3' })),
  ),
  rest.get(
    composeEndpoint(
      `${INVOICE_REPORTS_API_PATH}/companies/:companyId/balances/`,
      financialDocsClient,
    ),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockBalances)),
  ),
  rest.get(
    composeEndpoint(
      `${INVOICE_REPORTS_API_PATH}/companies/:companyId/balances/status`,
      financialDocsClient,
    ),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json({ overdue: true })),
  ),
];
