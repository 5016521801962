export const isValidFileType = (file, acceptAttribute) => {
  return acceptAttribute.some((acceptToken) => {
    const isExtension = acceptToken[0] === '.';

    if (isExtension) {
      return file.name.endsWith(acceptToken);
    }

    const isWildcard = acceptAttribute.includes(acceptToken);

    if (isWildcard) {
      return file.type.startsWith(
        acceptToken.substr(0, acceptToken.length - 1),
      );
    }

    return file.type === acceptToken;
  });
};

export const isValidFileSize = (file, maximumSizeBytes) => {
  if (file.size > maximumSizeBytes) {
    return false;
  } else {
    return true;
  }
};
