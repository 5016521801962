import { StyledInput } from 'common/components/atoms/Input';
import styled from 'styled-components';

export const StyledCurrencyTimePeriodInputContainer = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    width: 100%;
  }
`;

export const StyledTimePeriodInput = styled(StyledInput)`
  flex: 0 1 30%;
`;

export const StyledCurrencyInput = styled(StyledInput)`
  flex: 0 1 15%;
`;

export const StyledAmountInput = styled(StyledInput)`
  flex: 0 1 35%;
`;
