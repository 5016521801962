import { TimeOffLogsResponseUnion } from 'common/types';

import { sortByMultipleDates } from '@omnipresentgroup/design-system';

export const sortTimeOffLogsByDate = <T extends TimeOffLogsResponseUnion>({
  taken,
  scheduled,
}: T) =>
  ({
    taken: sortByMultipleDates(taken, ['startDate', 'endDate']),
    scheduled: sortByMultipleDates(scheduled, ['startDate', 'endDate']),
  }) as T;
