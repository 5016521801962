import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { queryClient } from 'app/queryClient';
import { ConfirmationModal } from 'common/components/molecules/ConfirmationModal';
import { FIVE, ONE } from 'common/constants';
import {
  getGtnProcessingDisplayStatus,
  getIntentByStatus,
  GrossToNetFile,
  GrossToNetProcessingStatus,
  GrossToNetSource,
  GrossToNetUploadStatus,
  LSP,
  PAYROLL_CYCLE_LABELS,
  PayrollCycle,
} from 'common/types';
import { includes, isEmpty } from 'lodash';
import { LspDataModal } from 'omniplatform/admin/pages/PayReportPreviewPage/components/LspDataModal';
import { StatusHistoryModal } from 'omniplatform/admin/pages/PayReportPreviewPage/components/StatusHistoryModal';
import { formatValueToFixed } from 'omniplatform/admin/pages/PayReportPreviewPage/PayReportPreviewTable';
import {
  PayReportsUploadModal,
  PayReportsUploadModalProps,
} from 'omniplatform/admin/pages/PayrollInstanceDetailPage/components/PayReportsUploadModal';
import { ViewErrorDetailsModal } from 'omniplatform/admin/pages/PayrollInstanceDetailPage/components/ViewErrorDetailsModal';
import { payReportPreviewPage } from 'paths';
import Skeleton from 'react-loading-skeleton';
import countries, { getCountryByCountryCode } from 'utils/countries';
import { useTrack } from 'utils/mixpanel/tracker';
import { useLspsQuery } from 'utils/queries';
import { payrollKeys } from 'utils/queries/payroll/keys';
import { useDeleteGrossToNetFileMutation } from 'utils/queries/payroll/useDeleteGrossToNetFileMutation';
import { usePaginatedGrossToNetFilesQuery } from 'utils/queries/payroll/useGrossToNetFilesQuery';

import {
  Box,
  Checkbox,
  Dropdown,
  DropdownButton,
  DropdownMenuItemType,
  Inline,
  Link,
  Notification,
  notifyError,
  notifySuccess,
  OptionType,
  SingleValue,
  Stack,
  Table,
  Tag,
  Typography,
} from '@omnipresentgroup/design-system';
import { PaginationProps } from '@omnipresentgroup/design-system/dist/types/components/Pagination/Pagination';

import {
  PayrollInstanceDetailPageContext,
  VisibleModalEnum,
} from '../../PayrollInstanceDetailPageContext';

const ActionCell = ({
  file,
  onDelete,
  onViewHistory,
  onViewLspData,
}: {
  file: GrossToNetFileParsed;
  onDelete: () => void;
  onViewHistory: () => void;
  onViewLspData: () => void;
}) => {
  const options: DropdownMenuItemType[] = [
    {
      name: 'View history',
      icon: 'Clock',
      onClick: onViewHistory,
    },
  ];

  if (
    file.lspData ||
    file.originalStatus !== GrossToNetProcessingStatus.PUBLISHED
  ) {
    options.unshift({
      name: file.lspData
        ? `View${
            file.originalStatus !== GrossToNetProcessingStatus.PUBLISHED
              ? ' or edit'
              : ''
          } LSP data`
        : 'Add LSP data',
      icon: 'Edit',
      onClick: onViewLspData,
    });
  }

  if (
    file.originalStatus !== GrossToNetProcessingStatus.APPROVED &&
    file.originalStatus !== GrossToNetProcessingStatus.PUBLISHED
  ) {
    options.unshift({
      name: 'Delete',
      icon: 'Trash',
      onClick: onDelete,
    });
  }

  return (
    <DropdownButton
      data-testid="edit-gtn-button"
      icon="Dots"
      options={options}
    />
  );
};

export const TableCell = ({
  onClick = () => {},
  value,
  bold = false,
}: {
  onClick?: () => void;
  value: string | JSX.Element;
  bold?: boolean;
}) => {
  return (
    <Typography
      as="span"
      size="14"
      weight={bold ? 'medium' : 'regular'}
      onClick={onClick}
      whiteSpace="nowrap"
    >
      {value}
    </Typography>
  );
};

export const TagTableCell = ({
  value,
}: {
  value: GrossToNetProcessingStatus;
}) => {
  return (
    <Tag id={`${value}-table-tag`} intent={getIntentByStatus(value)}>
      {getGtnProcessingDisplayStatus(value)}
    </Tag>
  );
};

const columnHelper = createColumnHelper<GrossToNetFileParsed>();

type GrossToNetFileParsed = {
  id: string;
  fileName: string;
  type: string;
  country: string;
  status: JSX.Element;
  originalStatus: GrossToNetProcessingStatus;
  payrollCycle: string;
  lsp: boolean;
  lspData?: GrossToNetFile['lspData'];
  provider: string;
  total: string;
};

function removeFileExtension(fileName: string): string {
  return fileName.split('.').slice(0, -1).toString();
}

const PROCESSING_STATUSES = [
  GrossToNetProcessingStatus.DRAFT,
  GrossToNetProcessingStatus.TRANSFORMED,
  GrossToNetProcessingStatus.INGESTED,
  GrossToNetProcessingStatus.TAXONOMIZED,
  GrossToNetProcessingStatus.ENRICHED,
];

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE_INDEX = 0;

enum ColumnHeaders {
  providerName = 'Provider name',
  file = 'File',
  country = 'Country',
  payrollCycle = 'Payroll cycle',
  lsp = 'LSP',
  status = 'Status',
}

const dropdownCountriesOptions = countries.map((country) => ({
  label: `${country.emoji} ${country.label}`,
  value: country.value,
}));

const dropdownPayrollCycleOptions = Object.values(PayrollCycle).map((s) => ({
  label: PAYROLL_CYCLE_LABELS[s],
  value: s,
}));

const dropdownStatusOptions = [
  GrossToNetProcessingStatus.DRAFT,
  GrossToNetProcessingStatus.INGESTED,
  GrossToNetProcessingStatus.DUPLICATES_CHECKED,
  GrossToNetProcessingStatus.COPIED,
  GrossToNetProcessingStatus.PRE_APPROVAL_QUERY,
  GrossToNetProcessingStatus.SPECIALIST_APPROVED,
  GrossToNetProcessingStatus.PRE_PUBLISH_QUERY,
  GrossToNetProcessingStatus.PUBLISHED,
  GrossToNetProcessingStatus.ERROR,
].map((status) => ({
  label: getGtnProcessingDisplayStatus(status),
  value: status,
}));

const FiltersHeader = ({
  countryFilter,
  setCountryFilter,
  isLoadingLsps,
  dropdownLspOptions,
  lspFilter,
  setLspFilter,
  cycleFilter,
  setCycleFilter,
  statusFilter,
  setStatusFilter,
  setPagination,
}: {
  countryFilter: SingleValue<OptionType>;
  setCountryFilter: React.Dispatch<
    React.SetStateAction<SingleValue<OptionType>>
  >;
  isLoadingLsps: boolean;
  dropdownLspOptions: { label: string; value: string }[];
  lspFilter: SingleValue<OptionType>;
  setLspFilter: React.Dispatch<React.SetStateAction<SingleValue<OptionType>>>;
  cycleFilter: SingleValue<OptionType>;
  setCycleFilter: React.Dispatch<React.SetStateAction<SingleValue<OptionType>>>;
  statusFilter: SingleValue<OptionType>;
  setStatusFilter: React.Dispatch<
    React.SetStateAction<SingleValue<OptionType>>
  >;
  setPagination: React.Dispatch<
    React.SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  >;
}) => {
  const setAndResetPaginationFn =
    (setFn: React.Dispatch<React.SetStateAction<SingleValue<OptionType>>>) =>
    (value: SingleValue<OptionType>) => {
      setPagination((p) => ({
        ...p,
        pageIndex: 0,
      }));
      setFn(value);
    };
  return (
    <Inline
      pt="24"
      pl="24"
      pr="24"
      align="flex-end"
      stretch="all"
      stackAt={800}
    >
      <Dropdown
        id="country-filter"
        label="Country"
        isClearable
        isSearchable
        isMulti={false}
        placeholder="Filter by country"
        options={dropdownCountriesOptions}
        value={countryFilter}
        onChange={setAndResetPaginationFn(setCountryFilter)}
      />
      <Dropdown
        id="lsp-filter"
        label="Provider Name"
        isLoading={isLoadingLsps}
        isClearable
        isSearchable
        isMulti={false}
        placeholder="Filter by provider"
        options={dropdownLspOptions}
        value={lspFilter}
        onChange={setAndResetPaginationFn(setLspFilter)}
      />
      <Box minW={140} maxW={160}>
        <Dropdown
          id="cycle-filter"
          label="Payroll Cycle"
          placeholder="Filter by cycle"
          isClearable
          isSearchable
          isMulti={false}
          options={dropdownPayrollCycleOptions}
          value={cycleFilter}
          onChange={setAndResetPaginationFn(setCycleFilter)}
        ></Dropdown>
      </Box>
      <Box minW={250} maxW={260}>
        <Dropdown
          id="status-filter"
          label="Status"
          placeholder="Filter by status"
          isClearable
          isSearchable
          isMulti={false}
          options={dropdownStatusOptions}
          value={statusFilter}
          onChange={setAndResetPaginationFn(setStatusFilter)}
        ></Dropdown>
      </Box>
    </Inline>
  );
};

const parseGTNFiles = (grossToNetFiles: GrossToNetFile[], lspList: LSP[]) => {
  return grossToNetFiles.map<GrossToNetFileParsed>((grossToNetFile) => {
    const displayStatus = getGtnProcessingDisplayStatus(
      grossToNetFile.processingStatus || 'UNAVAILABLE',
    );
    const intent = getIntentByStatus(
      grossToNetFile.processingStatus || 'UNAVAILABLE',
    );
    const lsp = lspList.find(
      (lspEntry) => lspEntry.id === grossToNetFile.lspId,
    );

    return {
      id: grossToNetFile.id,
      fileName: removeFileExtension(grossToNetFile.fileName),
      type: grossToNetFile.fileType,
      country:
        getCountryByCountryCode(grossToNetFile.country ?? '')?.label ||
        grossToNetFile.country,
      status: (
        <Tag id="status-tag" intent={intent}>
          {displayStatus}
        </Tag>
      ),
      originalStatus: grossToNetFile.processingStatus,
      payrollCycle:
        grossToNetFile.payrollCycle === PayrollCycle.OFF_CYCLE
          ? PAYROLL_CYCLE_LABELS[grossToNetFile.payrollCycle]
          : grossToNetFile.payrollCycle,
      lsp: grossToNetFile.lspData !== null,
      provider: lsp?.name || 'LSP not found',
      total: !includes(PROCESSING_STATUSES, grossToNetFile.processingStatus)
        ? formatValueToFixed(grossToNetFile.total)
        : '-',
      lspData: grossToNetFile.lspData,
    };
  });
};

export const PayReportsTab = () => {
  const history = useHistory();
  const track = useTrack();

  const { visibleModal, setVisibleModal, payrollPeriod } = useContext(
    PayrollInstanceDetailPageContext,
  );

  const { payrollInstanceId } = useParams<{ payrollInstanceId: string }>();

  const [viewErrorDetailFile, setViewErrorDetailFile] =
    useState<GrossToNetFile | null>(null);

  const [fileToDelete, setFileToDelete] = useState<GrossToNetFileParsed | null>(
    null,
  );
  const [viewHistoryModalFile, setViewHistoryModalFile] =
    useState<GrossToNetFileParsed | null>(null);

  const [viewLspData, setViewLspData] = useState<{
    gtnId: string;
    canEdit: boolean;
    lspData: GrossToNetFile['lspData'] | null;
  } | null>(null);

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: Number(DEFAULT_PAGE_INDEX),
    pageSize: Number(DEFAULT_PAGE_SIZE),
  });

  const [countryFilter, setCountryFilter] =
    useState<SingleValue<OptionType>>(null);
  const [lspFilter, setLspFilter] = useState<SingleValue<OptionType>>(null);
  const [cycleFilter, setCycleFilter] = useState<SingleValue<OptionType>>(null);
  const [statusFilter, setStatusFilter] =
    useState<SingleValue<OptionType>>(null);

  const grossToNetQueryProps = useMemo(() => {
    return {
      payrollPeriod,
      source: GrossToNetSource.NEEYAMO,
      pageSize,
      pageIndex,
      ...(countryFilter ? { country: countryFilter.value.toString() } : {}),
      ...(lspFilter ? { lspId: lspFilter.value.toString() } : {}),
      ...(cycleFilter ? { payrollCycle: cycleFilter.value.toString() } : {}),
      ...(statusFilter
        ? { processingStatus: statusFilter.value.toString() }
        : {}),
    };
  }, [
    payrollPeriod,
    pageSize,
    pageIndex,
    countryFilter,
    lspFilter,
    cycleFilter,
    statusFilter,
  ]);

  const {
    data,
    isLoading: isLoadingGtns,
    refetch: refetchGtns,
  } = usePaginatedGrossToNetFilesQuery(grossToNetQueryProps);

  const grossToNetFiles = useMemo(() => data?.items || [], [data]);
  const paginationProps = useMemo(() => {
    return {
      pageSize,
      pageIndex,
      canNextPage: data?.items.length === data?.pageSize,
      canPreviousPage: pageIndex > 0,
      pageCount: data?.totalCount
        ? Math.ceil(Math.max(data.totalCount / pageSize, 1))
        : 0,
      pageSizeOptions: [20, 50, 100],
    };
  }, [pageSize, pageIndex, data]);

  const { data: lspList = [], isLoading: isLoadingLsps } = useLspsQuery();

  const dropdownLspOptions = useMemo(
    () =>
      lspList.map((lsp) => ({
        label: lsp.name,
        value: lsp.id,
      })),
    [lspList],
  );

  useEffect(() => {
    refetchGtns();
  }, [grossToNetQueryProps, refetchGtns]);

  const invalidate = () => {
    queryClient.invalidateQueries(
      payrollKeys.grossToNetFiles(grossToNetQueryProps),
    );
  };

  const { mutate: deleteGrossToNetFile, isLoading: isDeletingGrossToNet } =
    useDeleteGrossToNetFileMutation();

  const uploadModalProps: PayReportsUploadModalProps = {
    cancelHandler: () => {
      setVisibleModal(undefined);
      invalidate();
    },
    onSuccess: (uploadResults) => {
      setVisibleModal(undefined);

      const errors = uploadResults.filter(
        (file) => file.uploadStatus === GrossToNetUploadStatus.FAILED,
      );
      if (isEmpty(errors)) {
        notifySuccess({
          title: `File${
            uploadResults.length > ONE ? 's' : ''
          } successfully uploaded`,
        });
      } else {
        notifyError({
          title: 'Some files had errors while uploading:',
          description: `${errors.map((file) => file.fileName).join(', ')}`,
        });
      }
      invalidate();
    },
    onError: () => {
      setVisibleModal(undefined);
      notifyError({
        title: 'Error while uploading files',
      });
      invalidate();
    },
    payrollPeriod,
    modalProps: {
      span: 10,
      open: visibleModal === VisibleModalEnum.gtnUpload,
      onOpenChange: () => {
        setVisibleModal(undefined);
      },
    },
  };

  const confirmDeleteModalProps = {
    cancelLabel: 'Cancel',
    cancelHandler: () => setVisibleModal(undefined),
    confirmHandler: () => {
      if (fileToDelete) {
        fileToDelete?.originalStatus === GrossToNetProcessingStatus.APPROVED
          ? track('Delete approved pay report file clicked')
          : track('Delete pay report file clicked');

        deleteGrossToNetFile(fileToDelete.id, {
          onSuccess: () => {
            notifySuccess({
              title: `${fileToDelete?.fileName} successfully deleted`,
            });
            invalidate();
          },
          onError: () => {
            notifyError({
              title: `Something went wrong while deleting file ${fileToDelete?.fileName}`,
            });
          },
          onSettled: () => {
            setVisibleModal(undefined);
            setFileToDelete(null);
          },
        });
      }
    },
    confirmLabel: 'Delete',
    loading: isDeletingGrossToNet,
    title: `Delete ${fileToDelete?.fileName}`,
    message: `Are you sure you wish to delete the ${fileToDelete?.fileName} pay report file?\nThis process can't be undone.`,
    testId: 'confirm-delete-pay-report-modal',
  };

  const grossToNetFilesParsed = useMemo(
    () => parseGTNFiles(grossToNetFiles, lspList),
    [grossToNetFiles, lspList],
  );

  const columns = [
    columnHelper.accessor('provider', {
      cell: (info) => {
        return (
          <TableCell
            value={
              <Link
                size="14"
                onClick={() =>
                  history.push(
                    payReportPreviewPage(
                      payrollInstanceId,
                      info.row.original.id,
                    ),
                  )
                }
              >
                {info.getValue()}
              </Link>
            }
          ></TableCell>
        );
      },
      header: () => <TableCell value={ColumnHeaders.providerName} />,
    }),
    columnHelper.accessor('fileName', {
      cell: (info) => {
        return (
          <TableCell
            value={info.getValue()}
            onClick={() =>
              history.push(
                payReportPreviewPage(payrollInstanceId, info.row.original.id),
              )
            }
          />
        );
      },
      header: () => <TableCell value={ColumnHeaders.file} />,
    }),
    columnHelper.accessor('country', {
      cell: (info) => {
        return <TableCell value={info.getValue()} />;
      },
      header: () => <TableCell value={ColumnHeaders.country} />,
    }),
    columnHelper.accessor('payrollCycle', {
      cell: (info) => {
        return <TableCell value={info.getValue()} />;
      },
      header: () => <TableCell value={ColumnHeaders.payrollCycle} />,
    }),
    columnHelper.accessor('lsp', {
      cell: (info) => {
        return <Checkbox defaultChecked={info.getValue()} readOnly />;
      },
      header: () => <TableCell value={ColumnHeaders.lsp} />,
    }),
    columnHelper.accessor('status', {
      cell: (info) => {
        return <TableCell value={info.getValue()} />;
      },
      header: () => <TableCell value={ColumnHeaders.status} />,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => {
        return (
          <ActionCell
            file={info.row.original}
            onDelete={() => {
              setVisibleModal(VisibleModalEnum.gtnDelete);
              setFileToDelete(info.row.original);
            }}
            onViewHistory={() => {
              setVisibleModal(VisibleModalEnum.gtnHistory);
              setViewHistoryModalFile(info.row.original);
            }}
            onViewLspData={() => {
              setViewLspData({
                gtnId: info.row.original.id,
                canEdit:
                  info.row.original.originalStatus !==
                  GrossToNetProcessingStatus.PUBLISHED,
                lspData: info.row.original.lspData || null,
              });
              setVisibleModal(VisibleModalEnum.gtnLspData);
            }}
          />
        );
      },
    }),
  ];

  const isLoading = isLoadingGtns || isLoadingLsps;

  return (
    <>
      {!isLoading && (
        <NotificationBanner
          grossToNetFiles={grossToNetFiles}
          onErrorDetailClick={(grossToNetFile: GrossToNetFile) =>
            setViewErrorDetailFile(grossToNetFile)
          }
          onRefreshClick={() => invalidate()}
        />
      )}
      <Stack bg="primary" radius="m" border="subtle">
        <FiltersHeader
          countryFilter={countryFilter}
          setCountryFilter={setCountryFilter}
          isLoadingLsps={isLoadingLsps}
          dropdownLspOptions={dropdownLspOptions}
          lspFilter={lspFilter}
          setLspFilter={setLspFilter}
          cycleFilter={cycleFilter}
          setCycleFilter={setCycleFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          setPagination={setPagination}
        />
        <PayReportsTable
          isLoading={isLoading}
          grossToNetFilesParsed={grossToNetFilesParsed}
          paginationProps={paginationProps}
          setPagination={setPagination}
          columns={columns}
        />
      </Stack>
      {viewErrorDetailFile && (
        <ViewErrorDetailsModal
          doneHandler={() => setViewErrorDetailFile(null)}
          cancelHandler={() => setViewErrorDetailFile(null)}
          grossToNetFile={viewErrorDetailFile}
        />
      )}
      {visibleModal === VisibleModalEnum.gtnUpload && (
        <PayReportsUploadModal {...uploadModalProps} />
      )}
      {visibleModal === VisibleModalEnum.gtnDelete && fileToDelete && (
        <ConfirmationModal {...confirmDeleteModalProps} />
      )}
      {viewHistoryModalFile && (
        <StatusHistoryModal
          gtnInfo={{
            gtnId: viewHistoryModalFile.id,
            fileName: viewHistoryModalFile.fileName,
          }}
        />
      )}
      {visibleModal === VisibleModalEnum.gtnLspData && viewLspData && (
        <LspDataModal
          gtnId={viewLspData.gtnId}
          lspData={viewLspData.lspData}
          canEdit={viewLspData.canEdit}
          onLspDataUpdated={() => {
            invalidate();
          }}
        />
      )}
    </>
  );
};

const PayReportsTable = ({
  isLoading,
  paginationProps,
  setPagination,
  grossToNetFilesParsed,
  columns,
}: {
  grossToNetFilesParsed: GrossToNetFileParsed[];
  isLoading: boolean;
  paginationProps: PaginationProps;
  setPagination: React.Dispatch<
    React.SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  >;
  columns: ColumnDef<any, any>[];
}) => {
  const table = useReactTable({
    data: grossToNetFilesParsed || [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  const LoadingColumns: ColumnDef<any, any>[] = [
    columnHelper.display({
      id: 'providerName',
      cell: () => <Skeleton height={16} />,
      header: () => ColumnHeaders.providerName,
      size: 200,
    }),
    columnHelper.display({
      id: 'file',
      cell: () => <Skeleton height={16} />,
      header: () => ColumnHeaders.file,
      size: 200,
    }),
    columnHelper.display({
      id: 'country',
      cell: () => <Skeleton height={16} />,
      header: () => ColumnHeaders.country,
      size: 200,
    }),
    columnHelper.display({
      id: 'payrollCycle',
      cell: () => <Skeleton height={16} />,
      header: () => ColumnHeaders.payrollCycle,
      size: 200,
    }),
    columnHelper.display({
      id: 'status',
      cell: () => <Skeleton height={16} />,
      header: () => ColumnHeaders.status,
      size: 200,
    }),
  ];

  const loadingTable = useReactTable({
    // Core Config
    data: Array.from({ length: FIVE }, (_value, index) => index),
    columns: LoadingColumns,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  return (
    <Table
      table={isLoading ? loadingTable : table}
      emptyStateProps={{
        id: 'empty-state-pay-reports-table',
        title: 'No pay reports available.',
        description: `You haven't uploaded any pay reports.`,
      }}
      wrapperProps={{ style: { border: 'none' } }}
      usePagination
      paginationProps={paginationProps}
    />
  );
};

const NotificationBanner = ({
  grossToNetFiles,
  onErrorDetailClick,
  onRefreshClick,
}: {
  grossToNetFiles: GrossToNetFile[];
  onErrorDetailClick: (grossToNetFile: GrossToNetFile) => void;
  onRefreshClick: () => void;
}) => {
  const { errorGrossToNetFiles, processingGrossToNetFiles } = useMemo(
    () =>
      grossToNetFiles.reduce<{
        errorGrossToNetFiles: GrossToNetFile[];
        processingGrossToNetFiles: GrossToNetFile[];
      }>(
        (acc, grossToNetFile) => {
          const { processingStatus } = grossToNetFile;
          if (processingStatus === GrossToNetProcessingStatus.ERROR) {
            acc.errorGrossToNetFiles.push(grossToNetFile);
          } else if (PROCESSING_STATUSES.includes(processingStatus)) {
            acc.processingGrossToNetFiles.push(grossToNetFile);
          }
          return acc;
        },
        {
          errorGrossToNetFiles: [],
          processingGrossToNetFiles: [],
        },
      ),
    [grossToNetFiles],
  );

  const hasErrors = !isEmpty(errorGrossToNetFiles);
  const isProcessing = !isEmpty(processingGrossToNetFiles);

  if (!hasErrors && !isProcessing) {
    return <></>;
  }

  return (
    <Stack data-test-id="payreports-tab-alerts" mb="16">
      {hasErrors && (
        <Notification
          id="payreports-tab-error"
          intent="error"
          dismissible
          description={
            <Stack>
              {errorGrossToNetFiles.map((grossToNetFile) => (
                <Inline key={grossToNetFile.id}>
                  <span>{removeFileExtension(grossToNetFile.fileName)}</span>
                  <Link
                    size="14"
                    onClick={() => onErrorDetailClick(grossToNetFile)}
                  >
                    view details
                  </Link>
                </Inline>
              ))}
            </Stack>
          }
          title="Some files could not be processed"
        />
      )}
      {isProcessing && (
        <Notification
          id="payreports-tab-info"
          intent="info"
          dismissible
          description={
            "This will take a few moments. Hit refresh to see if it's finished."
          }
          title="Your files are currently being processed"
          link={
            <Link size="14" onClick={onRefreshClick}>
              Refresh
            </Link>
          }
        />
      )}
    </Stack>
  );
};
