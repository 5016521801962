import { useCallback, useEffect, useRef, useState } from 'react';

import { Document } from '@contentful/rich-text-types';
import { SUPPORT_EMAIL } from 'appConstants';
import Big from 'big.js';
import { Loading } from 'common/components';
import { StyledBoldCopy } from 'common/components/atoms/Text';
import { ONE_SECOND } from 'common/constants';
import { StyledFormHeaderSubString } from 'common/pages/EditDetailsPage/EditDetailsPage.styles';
import {
  BenefitsCountryFee,
  DepositFeeWithType,
  FilteredCompanyUpsellFees,
  Salary,
} from 'common/types';
import { EmployerCostFee } from 'common/types/employerCost';
import { useContentfulCountriesQuery } from 'utils/queries';

import { Link, omniConfig, Viewport } from '@omnipresentgroup/design-system';

import { KeyFactsContent } from '../ConfirmEmployeeSeatPage/ConfirmEmployeeSeatPageTabContent';
import {
  PrimaryTextContainer,
  StyledKeyFactsEmptyContainer,
  StyledKeyFactsWrapper,
  StyledKeyFactsWrapperContent,
} from './AutomatedUpsellSection.styles';
import { OmnipresentFees } from './OmnipresentFees';

type AutomatedUpsellSection = {
  countryName: string;
  disabledFields: (value: boolean) => void;
  upsellFeeData?: FilteredCompanyUpsellFees;
  depositFeeData: DepositFeeWithType;
  annualBaseSalaryData: Salary;
  currencyExchangeRateData: Big | number;
  enableAutomatedUpsellForManager?: boolean;
  isLoadingCosts?: boolean;
  keyFactsExist?: (value: boolean) => void;
  billingCurrency: string;
  employerCostFees?: EmployerCostFee[];
  benefitsCountryFeeData?: BenefitsCountryFee;
};

export const AutomatedUpsellSection: React.FC<AutomatedUpsellSection> = ({
  countryName,
  disabledFields,
  upsellFeeData,
  enableAutomatedUpsellForManager,
  keyFactsExist,
  depositFeeData,
  annualBaseSalaryData,
  currencyExchangeRateData,
  billingCurrency,
  employerCostFees,
  isLoadingCosts,
  benefitsCountryFeeData,
}: AutomatedUpsellSection) => {
  const [hasKeyFacts, setHasKeyFacts] = useState(false);
  const [allKeyFacts, setKeyFacts] = useState<Document>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubjectToGlobalSanctions, setIsSubjectToGlobalSanctions] =
    useState(false);
  const isMounted = useRef(false);
  const contentfulCountriesQuery = useContentfulCountriesQuery();

  const hasRequiredInputs = Boolean(
    countryName &&
      annualBaseSalaryData.amount &&
      annualBaseSalaryData.currency &&
      billingCurrency,
  );

  function resetStateValues() {
    setIsSubjectToGlobalSanctions(false);
    setHasKeyFacts(false);
  }
  const renderKeyFacts = useCallback(() => {
    resetStateValues();
    if (countryName) {
      const territoryData =
        contentfulCountriesQuery.data?.filter((territory) =>
          territory.name.includes(countryName),
        ) || [];
      const country = territoryData[0];
      const isCountryWithGlobalRestrictions = /global/i.test(
        country?.tags.join(', '),
      );

      if (isCountryWithGlobalRestrictions) {
        setIsSubjectToGlobalSanctions(isCountryWithGlobalRestrictions);
      } else if (!territoryData || !country?.keyFact) {
        setHasKeyFacts(false);
      } else {
        setHasKeyFacts(true);
        setKeyFacts(country.keyFact);
      }
    }
  }, [countryName, contentfulCountriesQuery.data]);

  useEffect(() => {
    renderKeyFacts();
  }, [renderKeyFacts]);

  useEffect(() => {
    let timeoutHandler: any;
    isMounted.current = true;
    if (isMounted.current) {
      setIsLoading(true);
      timeoutHandler = setTimeout(() => setIsLoading(false), ONE_SECOND);
    }
    return () => {
      isMounted.current = false;
      clearTimeout(timeoutHandler);
    };
  }, [hasKeyFacts, upsellFeeData, setHasKeyFacts, hasRequiredInputs]);

  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      keyFactsExist?.(hasKeyFacts);
    }
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasKeyFacts, setHasKeyFacts]);

  useEffect(() => {
    disabledFields(isSubjectToGlobalSanctions);
  }, [disabledFields, isSubjectToGlobalSanctions]);

  const displayBasicFormMessageComponent = () => {
    return (
      <StyledKeyFactsEmptyContainer>
        <img
          style={{
            display: 'block',
            paddingBottom: omniConfig.theme.space[16],
          }}
          width="20%"
          src="/logo192.png"
          alt="Omnipresent"
          data-test-id="omnipresent-png-logo"
        />
        <StyledFormHeaderSubString>
          Fill in employee details to continue
        </StyledFormHeaderSubString>
      </StyledKeyFactsEmptyContainer>
    );
  };

  const displayKeyFactsComponent = () => {
    if (hasKeyFacts) {
      return (
        <StyledKeyFactsWrapperContent>
          {KeyFactsContent(countryName, allKeyFacts)}
        </StyledKeyFactsWrapperContent>
      );
    }
    if (isSubjectToGlobalSanctions) {
      return displayGlobalSanctionsComponent();
    }
    return displayKeyfactsComingSoonComponent();
  };

  const displayGlobalSanctionsComponent = () => {
    return (
      <StyledKeyFactsEmptyContainer>
        <PrimaryTextContainer>
          Global Sanction Restrictions
        </PrimaryTextContainer>
        <StyledFormHeaderSubString>
          At this time we are not able to hire in <strong>{countryName}</strong>
        </StyledFormHeaderSubString>
        <Link size="16" to={SUPPORT_EMAIL}>
          contact support
        </Link>
      </StyledKeyFactsEmptyContainer>
    );
  };

  const displayKeyfactsComingSoonComponent = () => {
    return (
      <StyledKeyFactsEmptyContainer>
        <img
          style={{
            display: hasKeyFacts ? 'none' : 'block',
            paddingBottom: omniConfig.theme.space[16],
          }}
          width="10%"
          src="/logo192.png"
          alt="Omnipresent"
          data-test-id="omnipresent-png-logo"
        />
        <PrimaryTextContainer>Key facts coming soon!</PrimaryTextContainer>
        <StyledFormHeaderSubString>
          Request an order form to get more information about hiring in
        </StyledFormHeaderSubString>
        <StyledBoldCopy>{countryName}</StyledBoldCopy>
      </StyledKeyFactsEmptyContainer>
    );
  };

  const displayKeyfactsOnMobileComponent = () => {
    if (hasKeyFacts) {
      return (
        <StyledKeyFactsWrapperContent>
          {KeyFactsContent(countryName, allKeyFacts)}
        </StyledKeyFactsWrapperContent>
      );
    }
  };

  const isAutomatedFlow =
    enableAutomatedUpsellForManager &&
    upsellFeeData?.managementFee &&
    hasKeyFacts;

  return (
    <>
      <Viewport devices={['laptop', 'desktop', 'highRes']}>
        <StyledKeyFactsWrapper>
          {(hasRequiredInputs && isLoading) || isLoadingCosts ? (
            <Loading />
          ) : (
            <>
              {!hasRequiredInputs ? (
                isSubjectToGlobalSanctions ? (
                  displayGlobalSanctionsComponent()
                ) : (
                  displayBasicFormMessageComponent()
                )
              ) : (
                <>
                  {isAutomatedFlow ? (
                    <OmnipresentFees
                      upsellFee={upsellFeeData}
                      depositFee={depositFeeData}
                      country={countryName}
                      annualBaseSalary={annualBaseSalaryData}
                      currencyExchangeRate={currencyExchangeRateData}
                      billingCurrency={billingCurrency}
                      employerCostFees={employerCostFees}
                      benefitsCountryFee={benefitsCountryFeeData}
                    />
                  ) : (
                    displayKeyFactsComponent()
                  )}
                </>
              )}
            </>
          )}
        </StyledKeyFactsWrapper>
      </Viewport>
      <Viewport devices={['phone', 'tablet']}>
        <>
          {!hasRequiredInputs ? (
            displayBasicFormMessageComponent()
          ) : (
            <>
              {isAutomatedFlow ? (
                <OmnipresentFees
                  upsellFee={upsellFeeData}
                  depositFee={depositFeeData}
                  country={countryName}
                  annualBaseSalary={annualBaseSalaryData}
                  currencyExchangeRate={currencyExchangeRateData}
                  billingCurrency={billingCurrency}
                  benefitsCountryFee={benefitsCountryFeeData}
                />
              ) : (
                displayKeyfactsOnMobileComponent()
              )}
            </>
          )}
        </>
      </Viewport>
    </>
  );
};
