import { joiResolver } from '@hookform/resolvers/joi';
import { AxiosError } from 'axios';
import { archiveEmployeeById } from 'common/api/employees.api';
import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { ArchiveReasonsEnum } from 'common/types';
import { X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  Button,
  Dropdown,
  Inline,
  Input,
  notifyError,
  notifySuccess,
  Split,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import { ArchiveEmployeeModalProps } from './ArchiveEmployeeModal.types';
import { schema } from './archiveEmployeeModalSchema';

const options = [
  {
    label: 'Dropout',
    value: ArchiveReasonsEnum.DROP_OUT,
  },
  {
    label: 'Correction',
    value: ArchiveReasonsEnum.CORRECTION,
  },
];

export const ArchiveEmployeeModal = ({
  isOpen,
  employeeOrSeatDetails,
  closeFn,
  reloadEmployees,
}: ArchiveEmployeeModalProps) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    mode: 'all',
    resolver: joiResolver(schema),
  });

  const clearFields = () => {
    reset({
      reasonForArchiving: '',
      archiveVerificationField: '',
    });
  };

  const { mutate: archiveEmployee, isLoading } = useMutation(
    (employeeId: string) => archiveEmployeeById(employeeId),
    {
      onSuccess: () => {
        notifySuccess({
          title: 'Success!',
          description: (
            <Typography as="span" size="16">
              <Typography as="span" size="16" weight="medium">
                {employeeOrSeatDetails.name}
              </Typography>{' '}
              has been archived.
            </Typography>
          ),
        });
        reloadEmployees();
      },
      onError: (error: AxiosError) => {
        notifyError({
          title: 'Error',
          description: `There was a problem while archiving employee ${employeeOrSeatDetails.id}: ${error?.response?.data?.message}.`,
        });
      },
    },
  );

  const handleOnClickButton = () => {
    closeFn && closeFn();
    clearFields();
  };

  return (
    <>
      {isOpen && (
        <ModalWrapper
          fixedWidth="550"
          data-testid="admin-employee-archive-modal"
        >
          <Stack p="32" pt="16" gap="16">
            <Inline justify="end">
              <X
                style={{ cursor: 'pointer' }}
                onClick={handleOnClickButton}
                data-testid="closeModalButton"
              />
            </Inline>
            <Typography as="h5" hideParagraphSpacing size="20" weight="medium">
              Archive employee profile? *
            </Typography>
            <Split mt="16">
              <Stack>
                <Typography as="span" size="16" color="disabled">
                  Name
                </Typography>
                <Typography as="span" size="16" weight="medium">
                  {employeeOrSeatDetails.name}
                </Typography>
              </Stack>
              <Stack>
                <Typography as="span" size="16" color="disabled">
                  Role
                </Typography>
                <Typography as="span" size="16" weight="medium">
                  {employeeOrSeatDetails.role}
                </Typography>
              </Stack>
            </Split>
            <Controller
              name="reasonForArchiving"
              control={control}
              render={({ field }) => (
                <Dropdown
                  id="reasonForArchiving"
                  label="Specify the reason for archiving the profile"
                  required
                  placeholder="Select a reason"
                  isSearchable={false}
                  options={options}
                  errorMessage={
                    errors['reasonForArchiving']?.message ||
                    errors['reasonForArchiving']?.value?.message
                  }
                  {...field}
                />
              )}
            />
            <Input
              id="archiveVerificationField"
              type="text"
              label={'To verify, type "ARCHIVE" below'}
              required
              errorMessage={errors['archiveVerificationField']?.message}
              {...register('archiveVerificationField')}
            />
            <Typography as="span" size="12">
              * Please note, once archived, the profile will no longer be
              displayed in the list of active employees. This is a reversible
              action but recovery may take several days.
            </Typography>
            <Inline justify="space-between">
              <Button variant="secondary" onClick={handleOnClickButton}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  archiveEmployee(employeeOrSeatDetails.id);
                }}
                loading={isLoading}
                disabled={
                  isLoading ||
                  watch('archiveVerificationField') !== 'ARCHIVE' ||
                  !watch('reasonForArchiving')
                }
              >
                Archive
              </Button>
            </Inline>
          </Stack>
        </ModalWrapper>
      )}
    </>
  );
};
