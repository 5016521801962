import EditableInput from 'common/components/molecules/EditableInput/EditableInput';
import { useFormContext } from 'common/components/molecules/Form/FormProvider';
import { FIELD_TYPE } from 'common/types';

import { EmployerFormData } from './employer';

const PayrollProviderDropdown = ({
  name,
  label,
  required,
  onChange,
  onFocus,
  error,
  params,
  value,
}: any) => {
  const {
    values: { country },
  } = useFormContext<EmployerFormData>();

  const payrollProviderFiler = {
    ...params,
    countryName: country,
  };

  return (
    <EditableInput
      name={name}
      label={label}
      value={value}
      required={required}
      type={FIELD_TYPE.LPP}
      onFocus={onFocus}
      onChange={onChange}
      key={name}
      errorMessage={error}
      params={payrollProviderFiler}
    />
  );
};

export default PayrollProviderDropdown;
