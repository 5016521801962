import EditableInput from 'common/components/molecules/EditableInput/EditableInput';
import { useFormContext } from 'common/components/molecules/Form/FormProvider';
import { FIELD_TYPE } from 'common/types';
import { EmployerFilterOptions } from 'utils/queries/employers/useEmployerQuery/useEmployersQuery';

import { EmployerFormData } from './employer';

type EmployerDropdownInput = {
  name: string;
  label: string;
  onFocus: () => void;
  error?: string;
  params?: EmployerFilterOptions;
  value?: string | number | boolean | Record<string, unknown> | null;
  onChange?: () => void;
  required?: boolean;
};

const EmployerDropDown = ({
  name,
  label,
  required,
  onChange,
  onFocus,
  error,
  params,
  value,
}: EmployerDropdownInput) => {
  const {
    values: { isOmniEntity },
  } = useFormContext<EmployerFormData>();

  const employerFilter = {
    countryName: params?.countryName,
    isOmniEntity: isOmniEntity || isOmniEntity === false ? isOmniEntity : null,
    isActive: !!params?.isActive,
    showCountryCode: !!params?.showCountryCode,
  } as EmployerFilterOptions;

  return (
    <EditableInput
      name={name}
      label={label}
      value={value}
      required={required}
      type={FIELD_TYPE.EMPLOYER}
      onFocus={onFocus}
      onChange={onChange}
      key={name}
      errorMessage={error}
      params={employerFilter}
    />
  );
};

export default EmployerDropDown;
