import { useState } from 'react';

import { joiResolver } from '@hookform/resolvers/joi';
import { AxiosError } from 'axios';
import { archiveSeatById } from 'common/api/seats.api';
import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { ArchiveReasonsEnum, TransformedSeat } from 'common/types';
import { X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import countries from 'utils/countries';

import {
  Button,
  Dropdown,
  Inline,
  Input,
  Notification,
  notifyError,
  notifySuccess,
  OptionType,
  Split,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import { ModalEmployeeOrSeatDetails } from './ArchiveEmployeeModal.types';
import { schema } from './archiveEmployeeModalSchema';

const options = [
  {
    label: 'Dropout',
    value: ArchiveReasonsEnum.DROP_OUT,
  },
  {
    label: 'Correction',
    value: ArchiveReasonsEnum.CORRECTION,
  },
];

export type SeatActionModalProps = {
  title: string;
  isOpen: boolean;
  modalAlert?: string;
  subtitleName: string;
  subtitleRoleOrCountry: string;
  isOnlyArchiveSeat?: boolean;
  currentSeat?: TransformedSeat | null;
  employeeOrSeatDetails: ModalEmployeeOrSeatDetails;
  confirmButton: string;
  closeFn: () => void;
  reloadEmployees: () => void;
};

export const SeatActionModal = ({
  isOpen,
  employeeOrSeatDetails,
  modalAlert,
  isOnlyArchiveSeat,
  confirmButton,
  title,
  closeFn,
  reloadEmployees,
}: SeatActionModalProps) => {
  const [country, setCountry] = useState<OptionType['value']>('');

  const {
    register,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    mode: 'all',
    resolver: joiResolver(schema),
  });

  const clearFields = () => {
    reset({
      reasonForArchiving: '',
      archiveVerificationField: '',
    });
  };
  const { mutate: handleArchiveMutation, isLoading } = useMutation(
    (employeeOrSeatDetails: ModalEmployeeOrSeatDetails) =>
      isOnlyArchiveSeat
        ? archiveSeatById(employeeOrSeatDetails.id, {
            archiveReason: watch('reasonForArchiving').value,
          })
        : archiveSeatById(employeeOrSeatDetails.id, {
            archiveReason: ArchiveReasonsEnum.CHANGE_COUNTRY,
            countryName: country,
          }),
    {
      onSuccess: () => {
        notifySuccess({
          title: 'Success!',
          description: isOnlyArchiveSeat ? (
            <Typography as="span" size="16">
              <Typography as="span" size="16" weight="medium">
                {employeeOrSeatDetails.name}
              </Typography>{' '}
              has been archived.
            </Typography>
          ) : (
            <Typography as="span" size="16">
              Country has been changed
            </Typography>
          ),
        });
        reloadEmployees();
      },
      onError: (error: AxiosError) => {
        notifyError({
          title: 'Error',
          description: `There was a problem while archiving seat ${employeeOrSeatDetails.id}: ${error?.response?.data?.message}.`,
        });
      },
    },
  );

  const handleOnClickButton = () => {
    closeFn && closeFn();
    clearFields();
  };

  return (
    <>
      {isOpen && (
        <ModalWrapper
          fixedWidth="600"
          data-testid="admin-employee-archive-modal"
        >
          <Stack p="32" pt="16" gap="16">
            <Inline justify="end">
              <X
                style={{ cursor: 'pointer' }}
                onClick={handleOnClickButton}
                data-testid="closeModalButton"
              />
            </Inline>
            <Typography as="h5" hideParagraphSpacing size="20" weight="medium">
              {title}
            </Typography>
            <Notification
              intent="info"
              id="salesforce-alert"
              title="Salesforce Notification "
              description={modalAlert}
            />
            <Split mt="16">
              <Stack>
                <Typography as="span" size="16" color="disabled">
                  Name
                </Typography>
                <Typography as="span" size="16" weight="medium">
                  {employeeOrSeatDetails.name}
                </Typography>
              </Stack>
              <Stack>
                <Typography as="span" size="16" color="disabled">
                  Country
                </Typography>
                <Typography as="span" size="16" weight="medium">
                  {employeeOrSeatDetails.country}
                </Typography>
              </Stack>
            </Split>
            {isOnlyArchiveSeat ? (
              <Controller
                name="reasonForArchiving"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    id="reasonForArchiving"
                    label="Specify the reason for archiving the profile"
                    required
                    placeholder="Select the answer"
                    isSearchable={false}
                    options={options}
                    errorMessage={
                      errors['reasonForArchiving']?.message ||
                      errors['reasonForArchiving']?.value?.message
                    }
                    {...field}
                  />
                )}
              />
            ) : (
              <Controller
                control={control}
                name="countryToChange"
                render={({ field }) => (
                  <Dropdown
                    id="seatChangeCountry"
                    label="Please select new country"
                    placeholder="Select a country"
                    required
                    isSearchable
                    isMulti={false}
                    options={countries.map((country) => ({
                      label: `${country.emoji} ${country.label}`,
                      value: country.label,
                    }))}
                    errorMessage={
                      errors['reasonForArchiving']?.message ||
                      errors['reasonForArchiving']?.value?.message
                    }
                    {...field}
                    onChange={(field) => {
                      if (field?.value) {
                        setCountry(field.value as string);
                      }
                    }}
                  />
                )}
              />
            )}
            {isOnlyArchiveSeat ? (
              <Input
                type="text"
                data-testid="archiveVerificationField"
                errorMessage={errors['archiveVerificationField']?.message}
                label={'To verify, type "ARCHIVE" below'}
                id="archiveVerificationField"
                required
                {...register('archiveVerificationField')}
              />
            ) : (
              <Input
                type="text"
                data-testid="countryChangeVerificationField"
                id="countryChangeVerificationField"
                label={'To verify, type "CHANGE COUNTRY" below'}
                errorMessage={errors['countryChangeVerificationField']?.message}
                {...register('countryChangeVerificationField')}
              />
            )}
            <Inline justify="space-between">
              <Button variant="secondary" onClick={handleOnClickButton}>
                Cancel
              </Button>
              <Button
                data-testid="archive_button"
                variant="primary"
                onClick={() => {
                  handleArchiveMutation(employeeOrSeatDetails);
                }}
                loading={isLoading}
                disabled={
                  isLoading || isOnlyArchiveSeat
                    ? watch('archiveVerificationField') !== 'ARCHIVE' ||
                      !watch('reasonForArchiving')
                    : watch('countryChangeVerificationField') !==
                      'CHANGE COUNTRY'
                }
              >
                {confirmButton}
              </Button>
            </Inline>
          </Stack>
        </ModalWrapper>
      )}
    </>
  );
};
