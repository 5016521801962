import { EmployeeLocalOnboardingDetails, Field } from 'common/types';
import { depositTypes } from 'common/types/seat';
import JoiImport from 'joi';
import { billingCurrencies } from 'utils/currencies';
import { allowedHasSupplementaryBenefitsValues } from 'utils/hasSupplementaryBenefits';

import DateExtension from '@omnipresentgroup/joi-date';

import { isUpsellProposalGenerationEnabled } from '../featureFlags';
import ianaDomains from '../ianaDomains.json';
import { addressSchema } from './address.schema';

const Joi = JoiImport.extend(DateExtension);

const sixteenYearsCutOffDate = new Date(
  Date.now() - 1000 * 60 * 60 * 24 * 365 * 16,
);

const genderOptions = ['Male', 'Female', 'X'];
const GENDER_MESSAGE = 'Please select a gender';
const DATE_FORMAT = 'YYYY-MM-DD';
const emailSchema = Joi.string()
  .prefs({ convert: false })
  .email({ tlds: { allow: ianaDomains } })
  .trim()
  .messages({
    'string.trim':
      'Email addresses should not contain any spaces at the beginning or the end',
    'string.empty': 'Please enter an email address',
    'string.email': 'Please enter a valid email address',
  });

const depositTypeSchemaKey = isUpsellProposalGenerationEnabled()
  ? {
      depositType: Joi.string().valid(...depositTypes),
    }
  : {
      hasDepositInsurance: Joi.boolean(),
    };

const schemaKeys = {
  firstName: Joi.string().messages({
    'string.empty': 'Please enter first name',
  }),
  rightToWorkCheckCompleted: Joi.boolean(),
  lastName: Joi.string().messages({ 'string.empty': 'Please enter last name' }),
  gender: Joi.string()
    .valid(...genderOptions)
    .messages({
      'any.only': GENDER_MESSAGE,
      'any.required': GENDER_MESSAGE,
      'string.empty': GENDER_MESSAGE,
    }),
  pronouns: Joi.string().allow('', null),
  personalEmail: emailSchema,
  workEmail: emailSchema.allow('', null),
  phoneNumber: Joi.string()
    .regex(/^[/+0-9]+$/)
    .min(5)
    .max(15)
    .messages({
      'string.pattern.base': 'Please enter a valid phone number',
      'string.empty': 'Please enter a phone number',
      'string.max': 'Please enter a shorter phone number',
      'string.min': 'Please enter a longer phone number',
    }),
  jobTitle: Joi.string().messages({
    'string.empty': 'Please enter a job title',
  }),
  jobDescription: Joi.string().messages({
    'string.empty': 'Please enter a job description',
  }),
  locationOfWork: Joi.object()
    .keys({
      type: Joi.string()
        .required()
        .messages({ 'string.empty': 'Please enter a location of work' }),
      address: addressSchema,
    })
    .messages({}),
  annualBaseSalary: Joi.object()
    .keys({
      amount: Joi.number().allow('', null).required(),
      currency: Joi.string().allow('', null).required(),
    })
    .messages({
      'object.base': 'Please enter a valid annual base salary',
    }),
  bonus: Joi.object()
    .keys({
      amount: Joi.number().allow(null, 0),
      currency: Joi.string().allow('', null),
      timePeriod: Joi.string().allow('', null),
    })
    .messages({
      'object.base': 'Please enter a valid annual base salary',
    }),
  address: addressSchema,
  startDate: Joi.date().format(DATE_FORMAT).messages({
    'date.empty': 'Please enter a start date',
    'date.min': 'Please enter a date in the future',
  }),
  firstPayDate: Joi.alternatives([
    Joi.date().format(DATE_FORMAT).messages({
      'date.format': 'Please enter a date in the correct format',
    }),
    Joi.string().valid(''),
  ]),
  hoursOfWork: Joi.number().messages({
    'number.base': 'Please enter hours of work',
  }),
  probationaryPeriod: Joi.number().messages({
    'number.empty': 'Please enter a probationary period',
  }),
  holidayAllowance: Joi.object()
    .keys({
      value: Joi.number().required(),
      type: Joi.string().required(),
    })
    .messages({
      'object.base': 'Please enter holiday entitlement',
    }),
  dateOfBirth: Joi.date()
    .max(sixteenYearsCutOffDate)
    .format(DATE_FORMAT)
    .messages({
      'date.format': 'Please enter a date in the correct format',
      'date.max': 'Please enter a valid age, minimum age should be 16',
      'date.empty': 'Please enter a date',
    }),
  citizenship: Joi.string().messages({
    'string.empty': 'Please enter your citizenship',
  }),

  onboardingStatus: Joi.string().messages({
    'string.empty': 'Please choose a status',
  }),
  additionalCompensation: Joi.string().allow('', null),
  bicSwift: Joi.string().allow('', null).max(20),
  routingNumber: Joi.string().allow('', null),
  localName: Joi.string().allow('', null),
  countryOfIssue: Joi.string().allow('', null),
  socialInsuranceNumber: Joi.string().messages({
    'string.empty': 'Please enter your Social insurance number',
  }),
  iban: Joi.string().allow(''),
  accountNumber: Joi.when('iban', {
    is: '',
    then: Joi.string().messages({
      'string.empty': 'Please enter Account number or Iban',
    }),
    otherwise: Joi.string().allow(''),
  }),
  branchName: Joi.string(),
  number: Joi.string().allow('', null).max(20),
  adminNotes: Joi.string().allow('', null),
  department: Joi.number(),
  localServiceProvider: Joi.string().allow('', null),
  lspId: Joi.string().allow('', null),
  employeeType: Joi.string().messages({
    'string.empty': 'Please choose an employee type',
  }),

  billingCurrency: Joi.string().valid(...billingCurrencies),
  hasSupplementaryBenefits: Joi.string().valid(
    ...allowedHasSupplementaryBenefitsValues,
  ),
  advancedPaymentDepositPercentage: Joi.number().allow('', null),
  zeroDepositFeeAmount: Joi.number().allow('', null),
  managementFeeAmount: Joi.number(),
  managementFeeCurrency: Joi.string().valid(...billingCurrencies),
  setupFeeAmount: Joi.number(),
  needsSetupFeeUpdated: Joi.boolean(),
  requireTypeFormFilled: Joi.boolean(),
  managementFeeWaiver: Joi.object()
    .keys({
      percentage: Joi.number().min(0).max(100),
      periodStart: Joi.date().format('YYYY-MM').allow('', null),
      billingCycles: Joi.number()
        .max(12)
        .message({ 'number.max': 'Up to 12 billing cycles allowed' }),
    })
    .when(Joi.object({ percentage: Joi.not(null) }).unknown(), {
      then: Joi.object({
        periodStart: Joi.required(),
        billingCycles: Joi.required().not(0),
      }),
    })
    .when(Joi.object({ periodStart: Joi.not(null) }).unknown(), {
      then: Joi.object({
        billingCycles: Joi.required().not(0),
        percentage: Joi.required().not(0),
      }),
    })
    .when(Joi.object({ billingCycles: Joi.not(null) }).unknown(), {
      then: Joi.object({
        periodStart: Joi.required(),
        percentage: Joi.required().not(0),
      }),
    }),
  managementFeeDiscount: Joi.object().keys({
    percentage: Joi.number().allow('', null),
    billingCycles: Joi.number().allow('', null),
  }),

  ...depositTypeSchemaKey,
};
export const employeeSchema = Joi.object().keys(schemaKeys);

export const getLocalOnboardingSchema = (
  localOnboardingDetails: Array<EmployeeLocalOnboardingDetails>,
) => {
  if (!localOnboardingDetails?.length) {
    return employeeSchema;
  }
  const schema = localOnboardingDetails.reduce((acc, field) => {
    let joiSchema = Joi.string().messages({
      'string.empty': 'This field is required',
    });
    switch (field.type) {
      case 'email': {
        joiSchema = Joi.string()
          .prefs({ convert: false })
          .email({ tlds: { allow: ianaDomains } })
          .trim()
          .messages({
            'string.trim':
              'Email addresses should not contain any spaces at the beginning or the end',
            'string.empty': 'Please enter an email address',
            'string.email': 'Please enter a valid email address',
          });
        break;
      }
      case 'date': {
        joiSchema = Joi.date().format(DATE_FORMAT).messages({
          'date.empty': 'Please enter a date',
        });
        break;
      }
      case 'number': {
        joiSchema = Joi.number().messages({
          'number.base': 'Please enter a number',
        });
        break;
      }
      case 'checkbox': {
        joiSchema = Joi.any();
        break;
      }
      default:
        break;
    }
    return { ...acc, [(field as Field).name]: joiSchema };
  }, {});
  return Joi.object().keys({ ...schema, ...schemaKeys });
};
