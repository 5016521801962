import { PayrollProviderCreateApiRequest } from 'common/types';
import { getCountryCodeByCountryName } from 'utils/countries';

export type PayrollProviderFormData = {
  name: string;
  country: string;
  profileUrl?: string;
  contactEmails?: string;
  hasParent: boolean;
  parent?: {
    value: string;
    label: string;
  };
  isActive: boolean;
};

export const transformFormDataForAPI = (
  payrollProviderFormData: PayrollProviderFormData,
): PayrollProviderCreateApiRequest => {
  const contactEmails = payrollProviderFormData.contactEmails?.trim();
  return {
    name: payrollProviderFormData.name,
    countryCode:
      getCountryCodeByCountryName(payrollProviderFormData.country) || '',
    parentId: payrollProviderFormData.parent?.value,
    profileUrl: payrollProviderFormData.profileUrl,
    contactEmails: contactEmails
      ? contactEmails?.split(',').map((email) => email.trim())
      : undefined,
    isActive: payrollProviderFormData.isActive,
  };
};
