import { useState } from 'react';

import SearchInput from 'common/components/molecules/SearchInput/SearchInput';
import { User } from 'common/types';
import { hasSuperManagerScope } from 'utils/user';

import { Box } from '@omnipresentgroup/design-system';

import { AddManagerCard } from '../AddManager/AddManagerCard/AddManagerCard';
import { ManagerCard } from '../ManagerCard/ManagerCard';
import { StyledManagersList } from '../ManagerPermissionsTab/ManagerPermissionsTab.styles';

export type ManagerListSearchProps = {
  managers: User[];
  selectedManager?: User;
  onClickManager: (managerUserId: User) => void;
  onClickAddManager: () => void;
  addManagerForm?: boolean;
};

export const ManagerListSearch = ({
  managers,
  selectedManager,
  onClickManager,
  onClickAddManager,
  addManagerForm = false,
}: ManagerListSearchProps) => {
  const [filteredManagers, setFilteredManagers] = useState(managers);

  const onFilteredData = (filteredData: any[]) => {
    setFilteredManagers(filteredData);
  };

  const isManagerSelected = (manager: User) =>
    !addManagerForm && manager.userId === selectedManager?.userId;

  return (
    <>
      <Box mb="4" pl="8">
        <SearchInput
          searchField="name"
          dataToSearch={managers}
          placeHolderText="Search"
          onFilteredData={onFilteredData}
        />
      </Box>
      <StyledManagersList>
        <AddManagerCard
          cardHeader="Add a new manager"
          headerId="addManagerHeaderID"
          role="radio"
          selected={addManagerForm}
          onClick={onClickAddManager}
        />
        {!!filteredManagers.length &&
          filteredManagers.map((manager) => (
            <ManagerCard
              item={manager}
              key={manager.userId}
              cardHeader={manager.name}
              selected={isManagerSelected(manager)}
              superAdmin={hasSuperManagerScope(manager)}
              headerId={manager.userId}
              role="radio"
              onClick={() => {
                onClickManager(manager);
              }}
            />
          ))}
      </StyledManagersList>
    </>
  );
};
