import styled from 'styled-components';

export const Wrapper = styled.div`
  input[type='checkbox'] {
    appearance: checkbox;
  }
`;

export const ToolBar = styled.div`
  margin-bottom: ${({ theme: { space } }) => space[8]};
`;

export const Container = styled.div`
  padding: ${({ theme: { space } }) => space[16]};
`;
