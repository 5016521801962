import { Seat, SeatStatusEnum, SOWStatusEnum } from 'common/types';

export const mockSeatsResponse: Array<Seat> = [
  {
    id: 'test',
    companyId: 'company',
    firstName: 'Faizan',
    lastName: 'Haider',
    countryName: 'Pakistan',
    seatStatus: SeatStatusEnum.PENDING,
    desiredStartDate: '2020-10-18',
    sowStatus: SOWStatusEnum.SOW_AWAITING_PROPOSAL,
    employeeProfile: {
      pronouns: 'test pronoun',
    },
  },
];

export const mockSeatsBenefitsResponse: Array<Seat> = [
  {
    id: 'test',
    companyId: 'company',
    firstName: 'Harry',
    lastName: 'Potter',
    countryName: 'United Kingdom',
    seatStatus: SeatStatusEnum.PENDING,
    desiredStartDate: '2020-10-18',
    sowStatus: SOWStatusEnum.SOW_AWAITING_PROPOSAL,
    employeeProfile: {
      pronouns: 'test pronoun',
    },
  },
];

export const mockSeatsUpsellResponse: Array<Seat> = [
  {
    id: 'test',
    companyId: 'company',
    firstName: 'Faizan',
    lastName: 'Haider',
    seatStatus: SeatStatusEnum.PENDING,
    countryName: 'Pakistan',
    desiredStartDate: '2022-10-18',
    sowStatus: SOWStatusEnum.SOW_AWAITING_PROPOSAL,
    employeeProfile: {
      pronouns: 'test pronoun',
      annualBaseSalaryAmount: 10000,
      annualBaseSalaryCurrency: 'CAD',
      managementFeeAmount: 800,
      managementFeeCurrency: 'CAD',
      setupFeeAmount: 476,
      setupFeeCurrency: 'CAD',
    },
  },
  {
    sowStatus: 'sow:accepted',
    isAutomatedUpsellUsed: true,
    id: 'ETC-22-3247745',
    companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    firstName: 'Roy',
    lastName: 'Bane',
    countryName: 'United Kingdom',
    seatStatus: SeatStatusEnum.PENDING,
    desiredStartDate: '2022-03-12T00:00:00.000-05:00',
    createdAt: '2022-02-07T19:09:04.697Z',
    sowSignature: {
      signedBy: 'Test (admin@example.com)',
      time: '16:31 UTC',
      date: '03 November 2021',
    },
  },
];

export const mockSeatsUpsellZeroFeesResponse: Array<Seat> = [
  {
    id: 'test',
    companyId: 'company',
    firstName: 'Faizan',
    lastName: 'Haider',
    countryName: 'Pakistan',
    desiredStartDate: '2022-10-18',
    sowStatus: SOWStatusEnum.SOW_AWAITING_PROPOSAL,
    seatStatus: SeatStatusEnum.PENDING,
    billingCurrency: 'USD',
    employeeProfile: {
      pronouns: 'test pronoun',
      annualBaseSalaryAmount: 10000,
      annualBaseSalaryCurrency: 'CAD',
      managementFeeAmount: 0,
      managementFeeCurrency: 'CAD',
      setupFeeAmount: 0,
      setupFeeCurrency: 'CAD',
      employeeType: 'EOR',
    },
  },
];
