import { HTTPStatusCodesEnum } from 'common/constants';
import {
  GetVariantPaymentsResponse,
  VariantPayment,
} from 'common/types/variantPayment';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';
import { getMockVariantPayment } from 'omniplatform/manager/__mocks__/variantPayments';

export const mockBulkVariantPaymentValidationResult = {
  totSuccess: 2,
  successUrl: 'successfile.csv',
  totErrors: 0,
  errorsUrl: '',
};

export const mockGetVariantPaymentsResponse: GetVariantPaymentsResponse = {
  variantPayments: [
    {
      amount: {
        currency: 'USD',
        amount: 1000.0,
      },
      employeeId: 'some-id-1',
      employeeName: 'Test Name A',
      effectiveDate: '2022-09-01T00:00:00.000',
      requestedAt: '2022-09-02T00:00:00.000',
      type: 'bonus',
      reason: 'Bonus',
      status: 'requested',
      id: '',
      frequency: 'one_off',
    },
    {
      amount: {
        currency: 'USD',
        amount: 2000.0,
      },
      employeeId: 'some-id-2',
      employeeName: 'Test Name B',
      effectiveDate: '2022-09-03T00:00:00.000',
      requestedAt: '2022-09-04T00:00:00.000',
      type: 'bonus',
      reason: 'Bonus',
      status: 'requested',
      id: '',
      frequency: 'one_off',
    },
    {
      amount: {
        currency: 'USD',
        amount: 2000.0,
      },
      employeeId: 'some-id-3',
      employeeName: 'Test Name C',
      effectiveDate: '2022-09-03T00:00:00.000',
      requestedAt: '2022-09-04T00:00:00.000',
      type: 'commission',
      reason: 'Commission Reason',
      status: 'requested',
      id: '',
      frequency: 'one_off',
    },
    {
      amount: {
        currency: 'USD',
        amount: 2000.0,
      },
      employeeId: 'some-id-4',
      employeeName: 'Test Name D',
      effectiveDate: '2022-09-03T00:00:00.000',
      requestedAt: '2022-09-04T00:00:00.000',
      type: 'commission',
      reason: 'Commission Reason',
      status: 'requested',
      id: '',
      frequency: 'one_off',
    },
    {
      amount: {
        currency: 'USD',
        amount: 2000.0,
      },
      employeeId: 'some-id-5',
      employeeName: 'Test Name E',
      effectiveDate: '2022-09-03T00:00:00.000',
      requestedAt: '2022-09-04T00:00:00.000',
      type: 'commission',
      reason: 'Commission Reason',
      status: 'requested',
      id: '',
      frequency: 'one_off',
    },
  ],
  nextPageKey: 'mock-key',
};

export const mockBulkVariantPaymentSubmitResult: VariantPayment[] = [
  getMockVariantPayment(),
];

export const variantPaymentsHandlers = [
  rest.post(composeEndpoint('/variant-payments'), (_req, res, ctx) =>
    res(
      ctx.status(HTTPStatusCodesEnum.RESOURCE_CREATED),
      ctx.json(getMockVariantPayment()),
    ),
  ),
  rest.post<{ type: string }>(
    composeEndpoint('/variant-payments/bulk-validation'),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.RESOURCE_CREATED),
        ctx.json(mockBulkVariantPaymentValidationResult),
      ),
  ),
  rest.post<VariantPayment[]>(
    composeEndpoint('/variant-payments/bulk'),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.RESOURCE_CREATED),
        ctx.json(mockBulkVariantPaymentSubmitResult),
      ),
  ),
  rest.get<GetVariantPaymentsResponse>(
    composeEndpoint(`/variant-payments/company/:companyId`),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockGetVariantPaymentsResponse),
      );
    },
  ),
];
