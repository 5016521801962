import { generateEmployeeFields } from 'common/pages/SeatToEmployeePage/config/generateContractDetailsStage.ts';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import queryString from 'query-string';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';

export const showEmployeeReviewForm = (employee) => {
  return !!employee?.needsRequiredFieldsFilled;
};

export const showEmployeeTypeForm = (employee) => {
  return (
    employee?.requireTypeFormFilled && !employee?.needsRequiredFieldsFilled
  );
};

export const getEmployeeName = (employee) => {
  const { firstName = '', lastName = '' } = (
    employee?.personalDetails || []
  ).reduce(
    (acc, field) => ({
      ...acc,
      ...{ [field.name]: field.value },
    }),
    {},
  );

  return { fullName: `${firstName} ${lastName}`, firstName, lastName };
};

export const getEmployeeTypeFormURL = (employee) => {
  const { country } = employee;
  const { firstName, lastName, personalEmail } = isArray(
    employee.personalDetails,
  )
    ? employee.personalDetails.reduce((acc, field) => {
        return {
          ...acc,
          ...{ [field.name]: field.value },
        };
      }, {})
    : employee.personalDetails;

  const params = {
    first_name: firstName,
    last_name: lastName,
    email: personalEmail,
    country: isString(country) ? country : country?.name,
  };

  return `/typeform?${queryString.stringify(params)}`;
};

export const getEmployeeOnboardingTypeFormID = () =>
  process.env.VITE_EMPLOYEE_ONBOARDING_TYPEFORM_ID;

export const getEmployeeStartDateField = ({
  employmentDetails,
  contractQuestionnaire,
}) => {
  const startDateFromEmploymentDetails = employmentDetails?.find(
    (field) => field.name === 'startDate',
  );

  if (startDateFromEmploymentDetails) {
    return startDateFromEmploymentDetails;
  }

  const contractStartDate = { ...contractQuestionnaire?.body.startDate };
  contractStartDate.displayValue = formatISODate(
    contractStartDate.value,
    DateFormats.Date,
    true,
    '-',
  );
  contractStartDate.label = 'Start date';
  return contractStartDate;
};

export function generateContractFields(employee) {
  const contractResponses = employee?.contractQuestionnaire?.body;
  if (isEmpty(contractResponses)) {
    return [];
  }
  const entryDefinitions = Object.entries(contractResponses)
    .map(([key, value]) => ({
      attribute: key,
      question_plain_text: value.title,
      ...value,
    }))
    .sort((a, b) => a.position - b.position);
  return generateEmployeeFields(employee, entryDefinitions, false).fields;
}
