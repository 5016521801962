import { HOLIDAY_TYPES } from 'appConstants';
import { FormQuestionSetConfig, Seat } from 'common/types';
import JoiImport from 'joi';
import ianaDomains from 'utils/ianaDomains.json';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';
import DateExtension from '@omnipresentgroup/joi-date';

const Joi = JoiImport.extend(DateExtension) as JoiImport.Root;

export const thirdStageBaseQuestions = (seat?: Seat): FormQuestionSetConfig => [
  {
    field: {
      name: 'startDate',
      label: 'Start date',
      type: 'date',
      value: formatISODate(
        (seat?.employeeProfile?.startDate || seat?.desiredStartDate)!,
        DateFormats.FixedDate,
      ),
      error: null,
      required: true,
    },
    validation: Joi.date().format('YYYY-MM-DD').messages({
      'date.empty': 'Please enter a start date',
      'date.format': 'Please enter a valid start date',
    }),
  },
  {
    field: {
      name: 'workEmail',
      label: "Employee's work email",
      type: 'email',
      value: seat?.employeeProfile?.workEmail || '',
      error: null,
    },
    validation: Joi.string()
      .prefs({ convert: false })
      .email({ tlds: { allow: ianaDomains } })
      .trim()
      .messages({
        'string.trim':
          'Email addresses should not contain any spaces at the beginning or the end',
        'string.email': 'Please enter a valid email address',
        'string.empty': 'Please enter an email address',
      })
      .allow('', null),
  },
  {
    field: {
      name: 'hoursOfWork',
      label: 'Hours of work per week',
      type: 'number',
      value: seat?.employeeProfile?.hoursOfWork || null,
      error: null,
      required: true,
    },
    validation: Joi.number()
      .required()
      .messages({ 'number.base': 'Please enter hours of work' }),
  },
  {
    field: {
      name: 'probationaryPeriodLength',
      label: 'Probationary period (Months)',
      type: 'number',
      value: seat?.employeeProfile?.probationaryPeriodLength || null,
      error: null,
      required: true,
    },
    validation: Joi.number()
      .required()
      .messages({ 'number.base': 'Please enter a probationary period' }),
  },
  {
    field: {
      name: 'holidayEntitlement',
      label: 'Entitled paid time off',
      type: 'holidayAllowance',
      value: {
        value: seat?.employeeProfile?.holidayEntitlementDays || null,
        type:
          seat?.employeeProfile?.holidayEntitlementType ||
          HOLIDAY_TYPES.INCLUDING_PUBLIC_HOLIDAYS,
      },
      error: null,
      contextualInfo:
        'We will check this against the minimum requirements in this country and inform you of any discrepancies.',
      required: true,
    },
    validation: Joi.object().keys({
      value: Joi.number().required().messages({
        'number.base': 'Please enter a holiday allowance',
      }),
      type: Joi.string().required(),
    }),
  },
];
