import { client } from 'api';

import { User, UserRoleType } from '../types';

export type CreateUserRequestPayload = {
  firstName: string;
  lastName: string;
  email: string;
  roles: {
    type: UserRoleType;
    tenantId?: string;
    id?: string;
  }[];
};

export type CreateUserResponse = {
  id: string;
  message: string;
  url: string;
};

export type EditUserPayload = {
  roles: {
    type: UserRoleType;
    tenantId?: string;
    id?: string;
  }[];
};

export const getAdminUsers = () => client.get<User[]>('/users?role=admin');
export const getUser = (userId: string) => client.get<User>(`/users/${userId}`);
export const createUserRequest = (
  createUserRequestPayload: CreateUserRequestPayload,
) => client.post<CreateUserResponse>('/users', createUserRequestPayload);
export const deleteUser = (userId: string) =>
  client.delete<void>(`/users/${userId}`);
export const editUser = (
  userId: string,
  EditUserRequestPayload: EditUserPayload,
) => client.patch<void>(`/users/${userId}`, EditUserRequestPayload);
