import { CompanyUpsellFees } from 'common/types';
import { isNil } from 'lodash';
import { BillingCurrency } from 'utils/currencies';

const isManualFlow = (upsellFees: CompanyUpsellFees | undefined) =>
  !upsellFees ||
  !upsellFees.enableAutomatedProposals ||
  !upsellFees.depositType;

export const getFeeByCurrency = (
  fees: any[] | undefined,
  billingCurrency: string,
) =>
  fees?.find(
    (fee: { currency: string; amount: null | number }) =>
      fee.currency === billingCurrency && fee.amount !== null,
  );

export const getAutomatedUpsellsData = ({
  billingCurrency,
  upsellFees,
}: {
  billingCurrency: BillingCurrency;
  upsellFees: CompanyUpsellFees;
}) => {
  if (upsellFees && !isManualFlow(upsellFees)) {
    const foundManagementFeeCurrency = getFeeByCurrency(
      upsellFees?.managementFee,
      billingCurrency,
    );
    const foundSetupFeeCurrency = getFeeByCurrency(
      upsellFees?.setupFee,
      billingCurrency,
    );
    const foundZeroDepositInsuranceFeeCurrency = getFeeByCurrency(
      upsellFees?.zeroDepositInsuranceFee,
      billingCurrency,
    );

    const areInvalidUpsellsFees = () =>
      !foundManagementFeeCurrency ||
      !foundSetupFeeCurrency ||
      (upsellFees?.depositType === 'Zero Deposit Insurance' &&
        isNil(foundZeroDepositInsuranceFeeCurrency)) ||
      (upsellFees?.depositType === 'Advanced Payment Deposit (APD)' &&
        isNil(upsellFees?.advancedPaymentDepositPercent));

    if (!areInvalidUpsellsFees()) {
      return {
        depositType: upsellFees?.depositType,
        advancedPaymentDepositPercent:
          upsellFees?.advancedPaymentDepositPercent,
        managementFee: foundManagementFeeCurrency,
        setupFee: foundSetupFeeCurrency,
        zeroDepositInsuranceFee: foundZeroDepositInsuranceFeeCurrency,
      };
    }
  }
};
