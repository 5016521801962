import { PAYROLL_FIELDS } from 'common/helpers/payrollFormFieldLabels.const';
import { Section } from 'common/pages/EmployeeDetailsPage/Tabs/EmployeeInfoTab/EmployeeInfoTab';

import { FIELD_TYPE } from '../common/types';

export const togglePayrollDepositFields = (
  sectionsToEdit: Section[],
  depositTypeFieldValue: string,
): any => {
  const payrollSection = sectionsToEdit.find(
    (section: Section) => section.name === PAYROLL_FIELDS.section.name,
  );

  const advancedPaymentDepositField = payrollSection?.details?.find(
    (detail: any) =>
      detail.name === PAYROLL_FIELDS.advanced_payment_deposit_percentage.name,
  );

  const zeroDepositFeeField = payrollSection?.details?.find(
    (detail: any) =>
      detail.name === PAYROLL_FIELDS.zero_deposit_fee_amount.name,
  );

  const displayField = (field: any, type: string) => {
    // toggles type from hidden to number as user changes deposit type dropdown
    field.type = type;
  };

  switch (depositTypeFieldValue) {
    case 'Zero Deposit Insurance':
      displayField(zeroDepositFeeField, FIELD_TYPE.FEE_CURRENCY);
      displayField(advancedPaymentDepositField, FIELD_TYPE.HIDDEN);
      break;
    case 'Advanced Payment Deposit (APD)':
      displayField(zeroDepositFeeField, FIELD_TYPE.HIDDEN);
      displayField(advancedPaymentDepositField, FIELD_TYPE.NUMBER);
      break;
    default:
      displayField(zeroDepositFeeField, FIELD_TYPE.HIDDEN);
      displayField(advancedPaymentDepositField, FIELD_TYPE.HIDDEN);
  }
};
