import countries from './countries';
import {
  currencyCodesMinorUnitsExceptions,
  defaultCountryCurrency,
} from './countryCurrencyMapper';
import { getIsRedactedValue } from './rbac.utils';

/**
 * Given a country that has multiple currencies defined in `countries-list`
 * we only return the main one. i.e. USD,USN,USS -> USD
 *
 * @param {string} currency
 * @param {string} countryName
 */
const getMainCurrency = (currency?: string, countryName?: string) => {
  if (!currency) {
    return '';
  }

  if (countryName && defaultCountryCurrency[countryName]) {
    return defaultCountryCurrency[countryName];
  }

  return currency.split(',')[0];
};

export const CURRENCY_CODE_OPTIONS = [
  ...new Set(
    countries.map((item) => getMainCurrency(item.currency, item.label)),
  ),
]
  .map((currency) => ({
    value: currency,
    label: currency,
  }))
  .filter((currency) => currency.value);

// TODO: populate this from `billingCurrencies` in BE
export const billingCurrencies = ['USD', 'EUR', 'GBP', 'CAD', 'SEK'];
export const billingCurrencyFormOptions = billingCurrencies.map((currency) => ({
  value: currency,
  label: currency,
}));
export type BillingCurrency = (typeof billingCurrencies)[number];

export const formatCurrencyWithSymbol = (
  amount: string | number,
  currency: string,
) => {
  return formatCurrency(amount, currency, { currencyDisplay: 'symbol' });
};

export const formatCurrency = (
  amount: string | number,
  currency?: string,
  options?: Intl.NumberFormatOptions,
) => {
  if (getIsRedactedValue(amount)) {
    return amount;
  }

  const mainCurrency = getMainCurrency(currency);
  const shouldFormatCurrency = !!mainCurrency;
  if (!shouldFormatCurrency) {
    return Number(amount).toLocaleString();
  }
  return Number(amount)
    .toLocaleString(navigator.language, {
      currencyDisplay: 'code',
      ...options,
      style: 'currency',
      currency: mainCurrency,
    })
    .replace(/\s/g, ' '); // replace any &nbsp; (char code 160) with a regular space (char code 20)
};

export const formatCurrencyFinance = (
  amount: number,
  currency: string,
  flipped = false,
) => {
  const formattedAmountElements: string[] = formatCurrency(amount, currency)
    .toLocaleString()
    .replace('-', '')
    .split(' ');
  const isNegative = Number(amount) < 0;

  const [currencySymbol, formattedAmount] = formattedAmountElements;

  if (isNegative) {
    return flipped
      ? `(${formattedAmount}) ${currencySymbol}`
      : `${currencySymbol} (${formattedAmount})`;
  }

  return flipped
    ? `${formattedAmount} ${currencySymbol}`
    : `${currencySymbol} ${formattedAmount}`;
};

export const formatCurrencyFlipped = (amount: number, currency: string) => {
  const formattedAmount = formatCurrency(amount, currency);
  const elements: string[] = formattedAmount.toString().split(' ');
  const flippedElements = elements.reverse();
  return flippedElements.join(' ');
};

export const roundCurrencyAmount = (amount: number) => {
  return (Math.round(amount + Number.EPSILON) * 100) / 100;
};

export const getCountryCurrency = (countryName: string) =>
  getMainCurrency(
    countries.find((country) => country.label === countryName)?.currency,
    countryName,
  );

type EmployeeSalaryCurrencyInputType = {
  countryName: string;
  salaryCurrency?: string;
};

export const transformEmployeeSalaryCurrency = ({
  countryName,
  salaryCurrency,
}: EmployeeSalaryCurrencyInputType): string | undefined => {
  return salaryCurrency || getCountryCurrency(countryName);
};

type CurrencyOptionsInputType = {
  name: string;
  country: string;
  userRole: string;
};

// interface CurrencyOptionsOutputType {
//   name: string;
//   options: Array<{
//     label: string | undefined;
//     value: string | undefined;
//   }>;
// }

export const getCurrencyOptions = ({
  name,
  country,
  userRole,
}: CurrencyOptionsInputType) => {
  const userIsAdmin = userRole === 'admin';
  const userIsEmployee = userRole === 'employee';

  const shouldShowOptions = userIsAdmin || userIsEmployee;
  const currency = getCountryCurrency(country);
  return {
    name: `${name}-currency`,
    options: shouldShowOptions
      ? [...CURRENCY_CODE_OPTIONS]
      : [
          {
            label: currency,
            value: currency,
          },
        ],
  };
};

export const getCurrencyMinorUnits = (currency: string): number => {
  const TWO_DECIMALS = 2;
  const exceptions = currencyCodesMinorUnitsExceptions[currency];
  return exceptions !== undefined ? exceptions : TWO_DECIMALS;
};

export const getCurrencyInputStep = (currency?: string): string => {
  if (!currency) {
    return '0.01';
  }

  const steps: { [key: number]: string } = {
    0: '1',
    1: '0.1',
    2: '0.01',
    3: '0.001',
  } as const;
  return steps[getCurrencyMinorUnits(currency)];
};
