import { Employee } from 'common/types/employee';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { generateContractFields } from 'utils/employees';
import { useToggle } from 'utils/hooks';

import * as S from './ContractQuestionnaire.styles';

interface Props {
  employee: Employee;
}

export const ContractQuestionnaire: React.FunctionComponent<Props> = ({
  employee,
}: Props) => {
  const contractDetails = generateContractFields(employee);
  const section = {
    name: 'contract-responses',
    title: 'Contract responses',
    details: contractDetails,
  };

  const [isVisible, toggle] = useToggle(true);

  return (
    <>
      {!!contractDetails && contractDetails.length > 0 && (
        <S.CQWrapper>
          {isVisible && (
            <S.CQTable
              className={section.name}
              key={section.title}
              testId={`${section.name}-card`}
              fieldsToDisplay={section.details}
              showContextualInfo
              head={
                <S.CQTableHead>
                  <tr>
                    <S.CQTableHeadCell className="title" colSpan={2}>
                      <div className="header">
                        <div
                          title="close-contract-responses"
                          className="icon text"
                        >
                          <ChevronLeft onClick={toggle} />
                        </div>
                        <span>Contract responses</span>
                      </div>
                    </S.CQTableHeadCell>
                  </tr>
                  <tr>
                    <S.CQTableHeadCell>Question</S.CQTableHeadCell>
                    <S.CQTableHeadCell>Response</S.CQTableHeadCell>
                  </tr>
                </S.CQTableHead>
              }
            />
          )}
          {!isVisible && (
            <div title="open-contract-responses" className="icon">
              <ChevronRight onClick={toggle} />
            </div>
          )}
        </S.CQWrapper>
      )}
    </>
  );
};
