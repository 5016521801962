import { mockLSPsResponse } from '__mocks__/lsps.mock';
import {
  PaymentEntity,
  PaymentVendor,
  PaymentVendorsResponse,
} from 'common/types';
import omit from 'lodash/omit';

import { PaymentVendorEnhanced } from '../pages/PayrollPage/Tabs/VendorMappingTab/types';

const mockCurrencies = [
  {
    id: '7782404e-fbc3-4951-a8bc-35e25e4dcef8',
    currency: 'EUR',
    netsuiteId: '1',
  },
];

export const mockPaymentVendor1: PaymentVendor = {
  id: '70145a8d-c464-40d0-ad91-47395974bb2c',
  netsuiteName: 'lorenzo-final',
  netsuiteId: 'test-netsuite-id',
  countryCode: 'test-country-code',
  currencies: mockCurrencies,
  lsps: [
    {
      id: mockLSPsResponse[0].id,
      currencies: mockCurrencies,
    },
  ],
};

export const mockPaymentVendor2: PaymentVendor = {
  id: '70145a8d-c464-40d0-ad91-473959742222',
  netsuiteName: 'lorenzo-final2',
  netsuiteId: 'test-netsuite-id2',
  countryCode: 'test-country-code2',
  currencies: mockCurrencies,
  lsps: [],
};

export const mockPaymentVendorEnhanced: PaymentVendorEnhanced = {
  ...mockPaymentVendor1,
  lsps: [
    {
      id: mockLSPsResponse[0].id,
      full: mockLSPsResponse[0],
      currencies: mockPaymentVendor1.currencies,
    },
  ],
};

export const mockPaymentVendorResponse1: PaymentVendorsResponse = {
  items: [mockPaymentVendor1],
  nextPageKey: 'mockNextPageKey',
};

export const mockPaymentVendorResponse2: PaymentVendorsResponse = {
  items: [mockPaymentVendor2],
};

export const mockPaymentEntity: PaymentEntity = {
  id: 'kgkaYEY8Omc-',
  currencies: mockCurrencies,
  vendor: omit(mockPaymentVendor1, 'lsps'),
};
