import {
  createContractAmendmentRequest,
  dispatchAmendmentRequest,
} from 'common/api/contractAmendment.api';
import {
  ContractAmendmentFormApiRequest,
  ContractAmendmentUpdateBody,
} from 'common/types';
import { useMutation } from 'react-query';

const AMENDMENTS = 'amendments';
const UPDATE = 'update';

export const useCreateContractAmendmentMutation = (
  setShowContractAmendmentRequestSuccessModal: (_: boolean) => void,
) => {
  return useMutation(
    (contractAmendmentForm: ContractAmendmentFormApiRequest) =>
      createContractAmendmentRequest(contractAmendmentForm),
    {
      mutationKey: [AMENDMENTS],
      onSuccess: () => {
        setShowContractAmendmentRequestSuccessModal(true);
      },
    },
  );
};

export const useDispatchContractAmendmentMutation = () => {
  return useMutation(
    ({
      body,
      amendmentId,
    }: {
      body: ContractAmendmentUpdateBody;
      amendmentId: string;
    }) => dispatchAmendmentRequest(amendmentId, body),
    { mutationKey: [AMENDMENTS, UPDATE] },
  );
};
