import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const StyledLink = styled(Link)`
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  color: ${({ theme: { colors } }) => colors['text-default']};
  cursor: pointer;
  align-self: center;
  text-decoration: none;

  :hover {
    text-decoration: underline;
    text-decoration-thickness: ${({ theme: { borderWidths } }) =>
      borderWidths.m};
  }
`;
