export const IS_DEV_ENV = process.env.NODE_ENV === 'development';
export const IS_PROD_ENV = process.env.NODE_ENV === 'production';

export const IS_TEST_ENV = process.env.NODE_ENV === 'test';

export const IS_E2E = !!(window as any).Cypress;

export const GET_PROD_STAGE = () => process.env.VITE_STAGE === 'PROD';

export const GET_DEV_STAGE = () => process.env.VITE_STAGE === 'DEV';

export const GET_IS_TEST_ENV = () => process.env.NODE_ENV === 'test';
