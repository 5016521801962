import { useContext, useMemo } from 'react';

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FIVE } from 'common/constants';
import {
  getGtnProcessingDisplayStatus,
  getIntentByStatus,
  GrossToNetProcessingStatus,
  User,
} from 'common/types';
import {
  PayrollInstanceDetailPageContext,
  VisibleModalEnum,
} from 'omniplatform/admin/pages/PayrollInstanceDetailPage/PayrollInstanceDetailPageContext';
import { useGrossToNetQuery } from 'utils/queries/payroll/useGrossToNetQuery';
import { useUsersQuery } from 'utils/queries/users/useUsersQuery/useUsersQuery';

import {
  DateFormats,
  formatISODate,
  IsoDateTime,
  Modal,
  Skeleton,
  Table,
  Tag,
} from '@omnipresentgroup/design-system';

type StatusHistoryItem = {
  date: IsoDateTime;
  status: GrossToNetProcessingStatus;
  user: string;
};

const DUMMY_STATUS_HISTORY: StatusHistoryItem = {
  date: '',
  status: GrossToNetProcessingStatus.DRAFT,
  user: '',
};

const columnHelper = createColumnHelper<StatusHistoryItem>();

enum ColumnHeaders {
  DATE = 'Date',
  STATUS = 'Status',
  USER = 'User',
}

const tableColumns = [
  columnHelper.accessor('date', {
    header: () => ColumnHeaders.DATE,
    cell: (info) => formatISODate(info.getValue(), DateFormats.DateTime),
  }),
  columnHelper.accessor('status', {
    header: () => ColumnHeaders.STATUS,
    cell: (info) => (
      <Tag
        size="small"
        intent={getIntentByStatus(info.getValue())}
        id="status-tag"
      >
        {getGtnProcessingDisplayStatus(info.getValue())}
      </Tag>
    ),
  }),
  columnHelper.accessor('user', {
    header: () => ColumnHeaders.USER,
    cell: (info) => info.getValue(),
  }),
];

const loadingColumns = [
  columnHelper.display({
    id: 'date',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.DATE,
    size: 200,
  }),
  columnHelper.display({
    id: 'status',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.STATUS,
    size: 200,
  }),
  columnHelper.display({
    id: 'user',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.USER,
    size: 200,
  }),
];

const StatusHistoryModalBody = ({ gtnId }: { gtnId: string }) => {
  const { data: gtnData, isLoading } = useGrossToNetQuery(gtnId);

  const queriesResult = useUsersQuery(
    (gtnData?.grossToNetStatusChangeLogs || [])
      .map((log) => log.updatedBy)
      .concat(gtnData?.uploadedBy || []),
  );

  const isLoadingUsers = queriesResult.some((result) => result.isLoading);

  const tableData = useMemo(() => {
    if (isLoading || isLoadingUsers || !gtnData) {
      return [];
    }

    const userData = queriesResult.reduce((acc, { isSuccess, data: user }) => {
      if (isSuccess && user) {
        acc.set(user.authId, user);
      }
      return acc;
    }, new Map<string, User>());

    return (gtnData?.grossToNetStatusChangeLogs || [])
      .map(({ updatedAt: date, status, updatedBy }) => ({
        date,
        status,
        user:
          userData?.get(updatedBy)?.name ||
          userData?.get(updatedBy)?.displayName ||
          updatedBy,
      }))
      .concat([
        {
          date: gtnData.createdAt,
          status: GrossToNetProcessingStatus.DRAFT,
          user:
            userData?.get(gtnData.uploadedBy)?.name ||
            userData?.get(gtnData.uploadedBy)?.displayName ||
            gtnData.uploadedBy,
        },
      ]);
  }, [isLoading, isLoadingUsers, gtnData, queriesResult]);

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const loadingTable = useReactTable({
    data: Array.from({ length: FIVE }, () => DUMMY_STATUS_HISTORY),
    columns: loadingColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table
      table={isLoading || isLoadingUsers ? loadingTable : table}
      showHeader={false}
    />
  );
};

export const StatusHistoryModal = ({
  gtnInfo: { gtnId, fileName },
}: {
  gtnInfo: { gtnId: string; fileName: string };
}) => {
  const { visibleModal, setVisibleModal } = useContext(
    PayrollInstanceDetailPageContext,
  );

  return (
    <Modal
      {...{
        span: 10,
        open: visibleModal === VisibleModalEnum.gtnHistory,
        onOpenChange: (open) =>
          setVisibleModal(open ? VisibleModalEnum.gtnHistory : undefined),
      }}
    >
      <Modal.Header title={`Status history for: ${fileName}`} />
      <Modal.Body>
        <StatusHistoryModalBody gtnId={gtnId} />
      </Modal.Body>
    </Modal>
  );
};
