import { ModalWrapper } from 'common/components/molecules/ModalWrapper';

import {
  MessageTextContainer,
  StyledCloseButton,
  StyledCloseButtonContainer,
  StyledMessageModal,
  StyledSuccessIcon,
} from './DeleteManagerModal.styles';
import { SuccessModalProps } from './DeleteManagerModal.types';

export const DeleteManagerSuccessModal = ({
  name,
  closeFn,
}: SuccessModalProps) => {
  return (
    <ModalWrapper fixedWidth="550" data-testid="success-modal">
      <StyledCloseButtonContainer>
        <StyledCloseButton onClick={closeFn} role="button" />
      </StyledCloseButtonContainer>

      <StyledMessageModal>
        <StyledSuccessIcon />

        <MessageTextContainer>
          <strong>Success!</strong>
        </MessageTextContainer>
        <div>
          <strong>{name}</strong> manager profile has been deleted.
        </div>
      </StyledMessageModal>
    </ModalWrapper>
  );
};
