import { ReactElement, ReactNode } from 'react';

import Loading from 'common/components/atoms/Loading/Loading';
import { StyledBottomContainer } from 'common/components/organisms/InfiniteScrollTable/InfiniteScrollTable.styles';
import TableWithTitle from 'common/components/organisms/TableWithTitle/TableWithTitle';
import { TableHeader } from 'common/types';
import { useInView } from 'react-intersection-observer';

interface EmptyState {
  title: string;
  description: string;
  buttonLabel: string;
  buttonClick: () => void;
}

interface InfiniteScrollTableProps<
  ItemID = any,
  ItemType = any,
  TableRow = any,
> {
  className?: string;
  itemsToDisplay: Array<ItemType>;
  title?: string;
  headers: Array<TableHeader<TableRow>>;
  onClick?: (itemId: ItemID) => void;
  onColumnItemClick?: (...params: any) => void;
  testId?: string;
  showButton: boolean;
  CTAText?: string;
  onCTAClicked?: () => void;
  maxWidth?: boolean;
  emptyState?: EmptyState;
  tableTopComponent?: ReactNode;
  isInitialLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  onBottomReached: () => void;
  noMoreItemsText?: string;
  getId: (item: ItemType) => ItemID;
  showHeader?: boolean;
}

export const InfiniteScrollTable = <ItemID, ItemType, TableRow>({
  isInitialLoading,
  isFetchingNextPage,
  hasNextPage = false,
  onBottomReached,
  noMoreItemsText = "You've reached the end of the list.",
  ...rest
}: InfiniteScrollTableProps<ItemID, ItemType, TableRow>): ReactElement => {
  const [ref, inView] = useInView();

  if (inView && !isInitialLoading && !isFetchingNextPage && hasNextPage) {
    onBottomReached();
  }

  return (
    <>
      <TableWithTitle {...rest} />
      {isFetchingNextPage && (
        <StyledBottomContainer>
          <Loading />
        </StyledBottomContainer>
      )}
      {!hasNextPage && (
        <StyledBottomContainer>
          <span>{noMoreItemsText}</span>
        </StyledBottomContainer>
      )}
      <div ref={ref} data-testid="bottom-trigger" />
    </>
  );
};
