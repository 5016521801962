import { payrollServiceClient } from 'api';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

import { BillingEntityEntry, PATH } from './billingEntities.api';

export const DEFAULT_BILLING_ENTITY: BillingEntityEntry = {
  billingEntityId: '12345',
  companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
  directDebitSetupUrl: '**********',
  isDirectDebit: true,
  billingEntitiesInfo: {
    enrolledCurrencies: 'EUR,GBP,USD',
    formFlag: false,
    isDirectDebit: true,
    isMultiBillingEntity: false,
  },
};

export const generateBillingEntity = (
  override: Partial<BillingEntityEntry> = {},
) => ({
  ...DEFAULT_BILLING_ENTITY,
  ...override,
});

export const DEFAULT_BILLING_ENTITIES_RESPONSE: BillingEntityEntry[] = [
  DEFAULT_BILLING_ENTITY,
];

export const billingEntitiesHandlers = [
  rest.get(composeEndpoint(PATH, payrollServiceClient), (req, res, ctx) =>
    res(ctx.json(DEFAULT_BILLING_ENTITIES_RESPONSE)),
  ),
];
