import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { Form } from 'common/components/molecules/Form/Form';
import {
  FormProvider,
  useFormContext,
} from 'common/components/molecules/Form/FormProvider';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { PROVIDERS_PAGE } from 'paths';
import { useCreateEmployerMutation } from 'utils/queries/employers/useCreateEmployerMutation';

import { EmployerFormData, transformFormDataForAPI } from '../employer';
import { employerFormConfig } from '../employerFormConfig';

const AddEmployerForm = () => {
  const history = useHistory();
  const { mutate, isLoading } = useCreateEmployerMutation();

  const goToListAction = () => history.push(`${PROVIDERS_PAGE}#employers`);
  const {
    values: { hasParent, isOmniEntity, country },
    onFieldChange,
  } = useFormContext();

  const [prevCountrySelected, setPrevCountrySelected] = useState<string>('');
  const [newCountrySelected, setNewCountrySelected] = useState<string>('');

  const submit = useCallback(
    (employerFormData: EmployerFormData) => {
      mutate(transformFormDataForAPI(employerFormData), {
        onSuccess: goToListAction,
      });
    },
    [goToListAction, mutate],
  );

  useEffect(() => {
    if (!hasParent) {
      onFieldChange('parent', null);
    }
    if (!isOmniEntity) {
      onFieldChange('payrollProvider', null);
    }

    setPrevCountrySelected(newCountrySelected);
    setNewCountrySelected(country);

    if (prevCountrySelected !== country) {
      onFieldChange('payrollProvider', null);
    }
  }, [
    hasParent,
    isOmniEntity,
    country,
    onFieldChange,
    newCountrySelected,
    prevCountrySelected,
  ]);

  return (
    <Form
      isLoading={isLoading}
      onSubmit={submit}
      ctaText="Submit"
      showCancel={true}
      onCancel={goToListAction}
    />
  );
};

export const AddEmployerPage = () => {
  return (
    <PageContent data-testid="add-employer-page" className="bigStack">
      <PageHeader
        title="Add employer"
        backLink={{ url: `${PROVIDERS_PAGE}#employers`, label: 'Back to list' }}
      />
      <FormProvider config={employerFormConfig(undefined, true)}>
        <AddEmployerForm />
      </FormProvider>
    </PageContent>
  );
};
