export type EmployerType =
  | 'employer-of-record'
  | 'consulting-entity'
  | 'virtual-employer'
  | 'pending';
export const EMPLOYER_TYPES_LABELS: Record<EmployerType, string> = {
  'employer-of-record': 'Employer of record',
  'consulting-entity': 'Consulting entity',
  'virtual-employer': 'Virtual employer',
  pending: 'Pending',
};
export const EMPLOYER_TYPES_LABELS_TRANSFORMED: Record<string, EmployerType> = {
  'Employer of record': 'employer-of-record',
  'Consulting entity': 'consulting-entity',
  'Virtual employer': 'virtual-employer',
  Pending: 'pending',
};

export type Employer = {
  id: string;
  lspId: string;
  name: string;
  type: EmployerType;
  countryCode: string;
  parent?: Employer;
  isOmniEntity: boolean;
  payrollProvider?: PayrollProvider;
  profileUrl?: string;
  contactEmails?: string;
  isActive?: boolean;
  activeSince?: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type EmployerApiResponse = Employer;
export type EmployerCreateApiRequest = {
  name: string;
  type: EmployerType;
  countryCode: string;
  parentId?: string;
  isOmniEntity: boolean;
  payrollProviderId?: string;
  lspId?: string;
  profileUrl?: string;
  contactEmails?: Array<string>;
  isActive: boolean;
};

export type PayrollProvider = {
  id: string;
  lspId: string;
  countryCode: string;
  name: string;
  parent?: PayrollProvider;
  profileUrl?: string;
  contactEmails?: string;
  hasHistory: boolean;
  isActive?: boolean;
  activeSince?: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type PayrollProviderCreateApiRequest = {
  name: string;
  countryCode: string;
  parentId?: string;
  profileUrl?: string;
  contactEmails?: Array<string>;
  isActive: boolean;
};

export type PayrollProviderApiResponse = PayrollProvider;
