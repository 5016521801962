import { SimplifiedEmployee } from 'common/types';
import {
  ResignationModel,
  ResignationRequest,
  ResignationStatus,
} from 'common/types/resignations';
import { getCountryFlagByCountryName } from 'utils/countries';

import { ResignationRequestsTableProps } from './ResignationRequestsTable';

export const getResignationRequestRows = ({
  employees,
  resignations,
}: ResignationRequestsTableProps): ResignationRequest[] => {
  const resignationRequests: ResignationRequest[] = [];

  employees.forEach((employee: SimplifiedEmployee) => {
    resignations.some((resignation: ResignationModel) => {
      if (employee.id === resignation.employeeId) {
        resignationRequests.push({
          id: resignation.id,
          employeeId: employee.id,
          name: employee.name,
          jobTitle: employee.jobTitle,
          country: `${getCountryFlagByCountryName(employee.country)} ${
            employee.country
          }`,
          status: resignation.status || ResignationStatus.REVIEWED,
        });
      }
    });
  });
  return resignationRequests;
};
