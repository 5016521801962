import { useMemo } from 'react';

import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  PaginationState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import Big from 'big.js';
import { EmployeeReportData, EmployeeTotals } from 'common/types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { Info } from 'luxon';
import { TableWrapper } from 'omniplatform/admin/pages/PayReportPreviewPage/PayReportPreviewTable.styles';
import { PayReportViewFilter } from 'omniplatform/admin/pages/PayReportPreviewPage/PayReportViewFilter';
import { calculateVariancePercentage } from 'omniplatform/admin/pages/PayReportPreviewPage/prepareDataForTreeTable';

import { Box, Icon, Link } from '@omnipresentgroup/design-system';

const DECIMAL_PLACES = 2;
const PAGE_SIZE_OPTIONS = [50, 100];

const TOTAL_COLUMNS_WHITELIST = [
  'allowance',
  'erContribution',
  'expenses',
  'salary',
  'variablePay',
] as const;

const calculateTotalSum = (breakdown: object): string => {
  const totalSum = TOTAL_COLUMNS_WHITELIST.reduce(
    (acc, totalKey) =>
      acc.add(Big((breakdown as Record<string, string>)[totalKey] || '0')),
    Big(0),
  ).toFixed(DECIMAL_PLACES);

  return totalSum;
};

export const formatValueToFixed = (value: string | number) => {
  if (isNil(value)) {
    return '';
  }

  try {
    Big(value);
  } catch {
    //ignore when it's not a numerical value
    return String(value);
  }

  return Number(value).toLocaleString('en-UK', {
    minimumFractionDigits: DECIMAL_PLACES,
    maximumFractionDigits: DECIMAL_PLACES,
  });
};

export const formatValueForTableDisplay = (value: string) => {
  const cleanedValue = value.replace(/[\,,\%,\-]/g, '');

  try {
    Big(cleanedValue);
  } catch {
    //ignore when it's still not a numerical value
    return value;
  }

  return Big(cleanedValue).toNumber() < 0 ? `(${value})` : value;
};

const columnHelper = createColumnHelper<EmployeeReportData>();

export const PayReportCustomCell = ({
  value,
  align = 'right',
  width = 'auto',
  fontWeight = 'normal',
  endCell = false,
}: {
  value: string;
  align?: string;
  width?: string;
  fontWeight?: string;
  endCell?: boolean;
}) => (
  <Box
    css={{
      textAlign: align,
      whiteSpace: 'nowrap',
      width,
      fontWeight,
    }}
    className={endCell ? 'end-cell' : ''}
  >
    {!value ? value : formatValueForTableDisplay(formatValueToFixed(value))}
  </Box>
);

const CustomEmployeeName = ({
  name,
  onClickHandler,
}: {
  name: string;
  onClickHandler: () => void;
}) => (
  <Link size="14" css={{ whiteSpace: 'nowrap' }} onClick={onClickHandler}>
    {name}
  </Link>
);

const JustificationNoteCustomCell = ({
  hasJustificationNote,
}: {
  hasJustificationNote: boolean;
}) => (hasJustificationNote ? <Icon icon="BrandLine" size="16" /> : null);

const getFooterTotal = (
  reportDataEmployees: EmployeeReportData[],
  identifier: keyof EmployeeTotals,
) => {
  const total = reportDataEmployees
    .map((employee) => employee.totals[identifier])
    .reduce((acc, val) => acc.plus(val), Big(0))
    .toString();

  return total;
};

const getTotalForVariance = (
  reportDataEmployees: EmployeeReportData[],
  getValue: (employee: EmployeeReportData) => string,
) => {
  return reportDataEmployees
    .reduce((acc, employee) => {
      if (!employee.variances) {
        return acc;
      }

      return acc.plus(getValue(employee));
    }, Big(0))
    .toString();
};

const getColumns = (
  reportDataEmployees: EmployeeReportData[],
  handleEmployeeNameClick: (id: string) => void,
  allValuesTotal: string,
) => {
  const columns: ColumnDef<EmployeeReportData, string>[] = [
    columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
      id: 'employee',
      header: () => 'Employee',
      cell: (info) => (
        <CustomEmployeeName
          name={info.getValue()}
          onClickHandler={() => handleEmployeeNameClick(info.row.id)}
        />
      ),
      footer: () => (
        <PayReportCustomCell fontWeight="bold" align="left" value="Total" />
      ),
    }),
    columnHelper.accessor((row) => `${row.hasJustificationNote || ''}`, {
      id: 'hasJustificationNote',
      header: '',
      cell: (info) => (
        <JustificationNoteCustomCell hasJustificationNote={!!info.getValue()} />
      ),
    }),
    columnHelper.accessor((row) => formatValueToFixed(row.totals.salary), {
      id: 'salary',
      cell: (info) => (
        <PayReportCustomCell
          value={formatValueForTableDisplay(
            formatValueToFixed(info.getValue()),
          )}
        />
      ),
      header: () => (
        <PayReportCustomCell width="9em" fontWeight="bold" value="Salary" />
      ),
      footer: () => (
        <PayReportCustomCell
          fontWeight="bold"
          value={formatValueForTableDisplay(
            formatValueToFixed(getFooterTotal(reportDataEmployees, 'salary')),
          )}
        />
      ),
    }),
    columnHelper.accessor((row) => formatValueToFixed(row.totals.expenses), {
      id: 'expenses',
      cell: (info) => (
        <PayReportCustomCell
          value={formatValueForTableDisplay(
            formatValueToFixed(info.getValue()),
          )}
        />
      ),
      header: () => (
        <PayReportCustomCell width="9em" fontWeight="bold" value="Expenses" />
      ),
      footer: () => (
        <PayReportCustomCell
          fontWeight="bold"
          value={formatValueForTableDisplay(
            formatValueToFixed(getFooterTotal(reportDataEmployees, 'expenses')),
          )}
        />
      ),
    }),
    columnHelper.accessor((row) => formatValueToFixed(row.totals.allowance), {
      id: 'allowance',
      cell: (info) => (
        <PayReportCustomCell
          value={formatValueForTableDisplay(
            formatValueToFixed(info.getValue()),
          )}
        />
      ),
      header: () => (
        <PayReportCustomCell width="9em" fontWeight="bold" value="Allowances" />
      ),
      footer: () => (
        <PayReportCustomCell
          fontWeight="bold"
          value={formatValueForTableDisplay(
            formatValueToFixed(
              getFooterTotal(reportDataEmployees, 'allowance'),
            ),
          )}
        />
      ),
    }),
    columnHelper.accessor((row) => formatValueToFixed(row.totals.variablePay), {
      id: 'variablePay',
      cell: (info) => (
        <PayReportCustomCell
          value={formatValueForTableDisplay(
            formatValueToFixed(info.getValue()),
          )}
        />
      ),
      header: () => (
        <PayReportCustomCell
          width="9em"
          fontWeight="bold"
          value="Variable Pay"
        />
      ),
      footer: () => (
        <PayReportCustomCell
          fontWeight="bold"
          value={formatValueForTableDisplay(
            formatValueToFixed(
              getFooterTotal(reportDataEmployees, 'variablePay'),
            ),
          )}
        />
      ),
    }),
    columnHelper.accessor(
      (row) => {
        return formatValueToFixed(row.totals.eeDeductions);
      },
      {
        id: 'eeDeduction',
        cell: (info) => (
          <PayReportCustomCell
            value={formatValueForTableDisplay(
              formatValueToFixed(info.getValue()),
            )}
          />
        ),
        header: () => (
          <PayReportCustomCell
            width="9em"
            fontWeight="bold"
            value="EE Deductions"
          />
        ),
        footer: () => (
          <PayReportCustomCell
            fontWeight="bold"
            value={formatValueForTableDisplay(
              formatValueToFixed(
                getFooterTotal(reportDataEmployees, 'eeDeductions'),
              ),
            )}
          />
        ),
      },
    ),
    columnHelper.accessor(
      (row) => {
        return formatValueToFixed(row.totals.erContribution);
      },
      {
        id: 'erContribution',
        cell: (info) => (
          <PayReportCustomCell
            value={formatValueForTableDisplay(
              formatValueToFixed(info.getValue()),
            )}
          />
        ),
        header: () => (
          <PayReportCustomCell
            width="9em"
            fontWeight="bold"
            value="ER Contribution"
          />
        ),
        footer: () => (
          <PayReportCustomCell
            fontWeight="bold"
            value={formatValueForTableDisplay(
              formatValueToFixed(
                getFooterTotal(reportDataEmployees, 'erContribution'),
              ),
            )}
          />
        ),
      },
    ),
    columnHelper.accessor(
      (row) => formatValueToFixed(calculateTotalSum(row.totals)),
      {
        id: 'total',
        cell: (info) => (
          <PayReportCustomCell
            value={formatValueForTableDisplay(
              formatValueToFixed(info.getValue()),
            )}
          />
        ),
        header: () => (
          <PayReportCustomCell width="9em" fontWeight="bold" value="Total" />
        ),
        footer: () => (
          <PayReportCustomCell
            fontWeight="bold"
            value={formatValueToFixed(allValuesTotal)}
          />
        ),
      },
    ),
  ];

  return columns;
};

const createVarianceGroupsColumns = (
  reportDataEmployees: EmployeeReportData[],
  name: string,
  identifier: keyof EmployeeTotals,
  varianceMonth: string,
  varianceCycle: string,
) => {
  const total = getFooterTotal(reportDataEmployees, identifier);
  const totalForVariance = getTotalForVariance(
    reportDataEmployees,
    (employee) => employee.totals[identifier] || '0',
  );

  const conceptTotal = reportDataEmployees
    .map((employee) => employee.variances?.previous[identifier] || '0')
    .reduce((acc, val) => acc.plus(Big(val)), Big(0))
    .toString();

  const columns = createVarianceColumns(
    (row) => row.totals[identifier],
    (row) => row.variances?.previous[identifier] || '',
    (row) => row.variances?.variance[identifier] || '',
    (row) => row.variances?.variancePercentage[identifier] || '',
    total,
    conceptTotal,
    totalForVariance,
    identifier,
    varianceMonth,
    varianceCycle,
  );

  return columnHelper.group({
    header: () => (
      <PayReportCustomCell fontWeight="bold" value={name} align="center" />
    ),
    id: name,
    columns,
  });
};

function createVarianceColumns(
  getRecordValue: (row: EmployeeReportData) => string,
  getVariancePreviousValue: (row: EmployeeReportData) => string,
  getVariance: (row: EmployeeReportData) => string,
  getVariancePercentage: (row: EmployeeReportData) => string,
  total: string,
  conceptTotal: string,
  totalForVariance: string,
  identifier: string,
  varianceMonth: string,
  varianceCycle: string,
): ColumnDef<EmployeeReportData, string>[] {
  return [
    // Current value
    columnHelper.accessor((row) => getRecordValue(row), {
      id: `${identifier}.current`,
      header: () => <PayReportCustomCell value="This cycle" />,
      footer: () => (
        <PayReportCustomCell
          fontWeight="bold"
          value={formatValueForTableDisplay(formatValueToFixed(total))}
        />
      ),
      cell: (info) => {
        return (
          <PayReportCustomCell
            value={formatValueForTableDisplay(
              formatValueToFixed(info.getValue()),
            )}
          />
        );
      },
    }),
    // Previous value
    columnHelper.accessor((row) => getVariancePreviousValue(row) || '-', {
      id: `${identifier}.cycle`,
      header: () => (
        <PayReportCustomCell
          value={`${
            Info.months()[Number(varianceMonth) - 1]
          }: Cycle ${varianceCycle}`}
        />
      ),
      footer: () => {
        // only show dash when we had no data to begin with
        if (Big(conceptTotal).toNumber() === 0) {
          return <PayReportCustomCell fontWeight="bold" value="-" />;
        }
        return (
          <PayReportCustomCell
            fontWeight="bold"
            value={formatValueForTableDisplay(formatValueToFixed(conceptTotal))}
          />
        );
      },
      cell: (info) => (
        <PayReportCustomCell
          value={formatValueForTableDisplay(
            formatValueToFixed(info.getValue()),
          )}
        />
      ),
    }),
    // Variance
    columnHelper.accessor((row) => getVariance(row) || '-', {
      id: `${identifier}.variance`,
      header: () => <PayReportCustomCell value="Variance" />,
      footer: () => {
        const varianceDiff = Big(totalForVariance)
          .minus(conceptTotal)
          .toFixed(DECIMAL_PLACES)
          .toString();

        return <PayReportCustomCell fontWeight="bold" value={varianceDiff} />;
      },
      cell: (info) => (
        <PayReportCustomCell
          value={formatValueForTableDisplay(
            formatValueToFixed(info.getValue()),
          )}
        />
      ),
    }),
    // Variance percentage
    columnHelper.accessor(
      (row) => {
        const variancePercentage = getVariancePercentage(row);
        return variancePercentage ? `${variancePercentage} %` : '-';
      },
      {
        id: `${identifier}.percentage`,
        header: () => <PayReportCustomCell align="right" value="%" />,
        footer: () => {
          const result = calculateVariancePercentage(
            Big(totalForVariance).toNumber(),
            Big(conceptTotal).toNumber(),
          );

          return (
            <PayReportCustomCell
              endCell
              fontWeight="bold"
              value={`${result.toFixed(DECIMAL_PLACES)} %`}
            />
          );
        },
        cell: (info) => (
          <PayReportCustomCell
            endCell
            value={formatValueForTableDisplay(
              formatValueToFixed(info.getValue()),
            )}
          />
        ),
      },
    ),
  ];
}

const getVarianceColumns = (
  reportDataEmployees: EmployeeReportData[],
  handleEmployeeNameClick: (id: string) => void,
  varianceMonth: string,
  varianceCycle: string,
): ColumnDef<EmployeeReportData, string>[] => {
  return [
    columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
      id: 'employee',
      header: () => <PayReportCustomCell fontWeight="bold" value="Employee" />,
      footer: () => (
        <PayReportCustomCell fontWeight="bold" align="left" value="Total" />
      ),
      cell: (info) => (
        <CustomEmployeeName
          name={info.getValue()}
          onClickHandler={() => handleEmployeeNameClick(info.row.id)}
        />
      ),
    }),
    columnHelper.accessor((row) => `${row.hasJustificationNote || ''}`, {
      id: 'hasJustificationNote',
      header: '',
      cell: (info) => (
        <JustificationNoteCustomCell hasJustificationNote={!!info.getValue()} />
      ),
    }),
    createVarianceGroupsColumns(
      reportDataEmployees,
      'Salary',
      'salary',
      varianceMonth,
      varianceCycle,
    ),
    createVarianceGroupsColumns(
      reportDataEmployees,
      'Expenses',
      'expenses',
      varianceMonth,
      varianceCycle,
    ),
    createVarianceGroupsColumns(
      reportDataEmployees,
      'Allowances',
      'allowance',
      varianceMonth,
      varianceCycle,
    ),
    createVarianceGroupsColumns(
      reportDataEmployees,
      'Variable Pay',
      'variablePay',
      varianceMonth,
      varianceCycle,
    ),
    createVarianceGroupsColumns(
      reportDataEmployees,
      'EE deductions',
      'eeDeductions',
      varianceMonth,
      varianceCycle,
    ),
    createVarianceGroupsColumns(
      reportDataEmployees,
      'ER Contribution',
      'erContribution',
      varianceMonth,
      varianceCycle,
    ),
  ];
};

type PayReportPreviewTableProps = {
  handleEmployeeNameClick: (id: string) => void;
  reportDataEmployees: EmployeeReportData[];
  payReportViewFilter: PayReportViewFilter;
  totalPageCount: number;
  pageIndex: number;
  pageSize: number;
  setPagination: (updaterOrValue: Updater<PaginationState>) => void;
  setPayReportViewFilter: (payReportViewFilter: PayReportViewFilter) => void;
};

export const PayReportPreviewTable = ({
  handleEmployeeNameClick,
  reportDataEmployees,
  payReportViewFilter,
  totalPageCount,
  pageIndex,
  pageSize,
  setPagination,
}: PayReportPreviewTableProps) => {
  const hasPayReportData = !isEmpty(reportDataEmployees);

  const allValuesTotal = useMemo(() => {
    return reportDataEmployees
      .reduce((acc, employeeData) => {
        return acc.add(calculateTotalSum(employeeData.totals));
      }, Big(0))
      .toString();
  }, [reportDataEmployees]);

  const table = useReactTable({
    data: reportDataEmployees,
    columns: payReportViewFilter?.showVariance
      ? getVarianceColumns(
          reportDataEmployees,
          handleEmployeeNameClick,
          payReportViewFilter.month,
          payReportViewFilter.payCycle,
        )
      : getColumns(
          reportDataEmployees,
          handleEmployeeNameClick,
          allValuesTotal,
        ),
    pageCount: totalPageCount,
    initialState: { pagination: { pageIndex, pageSize } },
    state: { pagination: { pageIndex, pageSize } },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    onPaginationChange: setPagination,
  });

  return (
    <TableWrapper
      data-testid="pay-report-preview-list-table"
      table={table}
      usePagination
      paginationProps={{
        canNextPage: pageIndex !== totalPageCount - 1 && hasPayReportData,
        canPreviousPage: pageIndex !== 0 && hasPayReportData,
        pageIndex,
        pageSize,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
      }}
      showFooter
      showHeader={false}
      wrapperProps={{
        maxH: '60vh',
        overflow: 'visible',
        style: { border: 'none' },
      }}
    />
  );
};
