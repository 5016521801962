import { useHistory } from 'react-router-dom';

import { getUserSelector } from 'app/store/app.selectors';
import {
  getSeatSOWDocument,
  updateCompanyEmployeeSeatRequest,
} from 'common/api/seats.api';
import Button from 'common/components/atoms/Button/Button';
import { StyledFullWidthBlueDivider } from 'common/components/atoms/Divider/Divider.styles';
import Loading from 'common/components/atoms/Loading/Loading';
import {
  StyledModalButtons,
  StyledModalContainer,
  StyledModalContents,
} from 'common/components/molecules/ConfirmationPopup/ConfirmationPopup.styles';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { Seat, SOWStatusEnum, TransformedSeat } from 'common/types';
import { getConvertSeatChecklistPagePath } from 'paths';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useDocumentUpload } from 'utils/hooks/useDocumentUpload';
import { isAdmin } from 'utils/user';

import { Typography } from '@omnipresentgroup/design-system';

type SOWDocumentModalProps = {
  seat: TransformedSeat;
  onModalClose: (reloadSeats?: boolean) => void;
};

export const SOWDocumentModal = ({
  seat: { id = '', name, companyId },
  onModalClose,
}: SOWDocumentModalProps) => {
  const user = useSelector(getUserSelector);
  const history = useHistory();

  const {
    uploadDocument,
    isLoading: isDocumentUploading,
    isError: isDocumentUploadError,
    isSuccess: isDocumentUploadSuccess,
    error: uploadError,
    reset: uploadDocReset,
    data: uploadedDocUrl,
  } = useDocumentUpload();

  const {
    isLoading,
    isError,
    isSuccess,
    error,
    reset,
    mutate: updateEmployeeSeat,
  } = useMutation((seatToUpdate: Partial<Seat>) =>
    updateCompanyEmployeeSeatRequest(seatToUpdate.id as string, seatToUpdate),
  );

  const {
    isLoading: isDocLoading,
    isError: isDocError,
    isSuccess: isDocLoadingSuccess,
    data: sowDocument,
    error: docError,
  } = useQuery(`seat-${id}-sow-document`, () => getSeatSOWDocument(id));

  const handleCTAClick = () => {
    if (!isAdmin(user)) {
      updateEmployeeSeat({
        id,
        sowStatus: SOWStatusEnum.SOW_ACCEPTED,
      });
    } else {
      uploadDocReset();
      uploadDocument('sowUrl');
    }
  };

  if (isLoading || isDocumentUploading || isDocLoading) {
    return <Loading />;
  }

  if (isError || isDocumentUploadError || isDocError) {
    return (
      <ErrorBanner
        onErrorClear={() => reset()}
        errorMessage={
          (error instanceof Error && error.message) ||
          (uploadError instanceof Error && uploadError.message) ||
          (docError instanceof Error && docError.message) ||
          ''
        }
      />
    );
  }

  if (isDocumentUploadSuccess) {
    updateEmployeeSeat({
      id,
      sowStatus: SOWStatusEnum.SOW_AWAITING_ACCEPTANCE,
      sowDocUrl: uploadedDocUrl,
    });
    uploadDocReset();
  }

  if (isSuccess) {
    if (isAdmin(user)) {
      onModalClose(true);
    } else {
      history.push(getConvertSeatChecklistPagePath(companyId, id));
    }
  }

  if (isDocLoadingSuccess) {
    return (
      <StyledModalContainer data-testid="sow-modal">
        <StyledModalContents fullScreen className="stack">
          <Typography as="h4" hideParagraphSpacing size="24" weight="bold">
            Order form ({name})
          </Typography>
          <StyledFullWidthBlueDivider />
          <div style={{ flex: '1 1 auto' }}>
            <iframe
              src={sowDocument?.data.url}
              title="Order form"
              height="100%"
              width="100%"
            ></iframe>
          </div>
          <StyledModalButtons>
            <Button
              variant="outlined"
              palette="secondary"
              onClick={() => onModalClose()}
            >
              Back
            </Button>
            <Button
              data-testid="modal-confirm-button"
              palette="primary"
              onClick={handleCTAClick}
            >
              {isAdmin(user)
                ? 'Upload new order form'
                : 'Accept and add employee'}
            </Button>
          </StyledModalButtons>
        </StyledModalContents>
      </StyledModalContainer>
    );
  }

  return <></>;
};
