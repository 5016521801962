import { Check, X } from 'react-feather';

import {
  Box,
  Inline,
  Link,
  omniConfig,
  Split,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

export const TerminationFormExplainerSection = () => {
  return (
    <Stack p="32" gap="32" depth="m" bg="primary">
      <Box>
        <Typography as="h2" hideParagraphSpacing weight="medium" size="24">
          Before the termination
        </Typography>
        <Typography as="p" hideParagraphSpacing size="16">
          {`What you should and shouldn't do before submitting a termination
        request.`}
        </Typography>
      </Box>
      <Split fraction="1/2" gap="32">
        <Stack
          p="16"
          border="subtle"
          borderBottom
          borderLeft
          borderRight
          borderTop
          radius="m"
        >
          <Inline align="center" gap="4">
            <Check
              size={omniConfig.theme.sizes[16]}
              color={omniConfig.theme.colors['green-500']}
              style={{ marginLeft: '5' }}
            />
            <Typography as="h2" hideParagraphSpacing weight="medium" size="20">
              Do
            </Typography>
          </Inline>
          <ul style={{ margin: '0', paddingLeft: '8px' }}>
            <Stack p="16">
              <li>
                <Typography as="span" size="16">
                  DO inform Omnipresent as soon as you decide you wish to
                  terminate someone or if the employee informs you that they
                  wish to resign.
                </Typography>
              </li>
              <li>
                <Typography as="span" size="16">
                  DO expect Omnipresent to be an active part of the process
                  Omnipresent must ensure that offboarding is done compliantly.
                </Typography>
              </li>
              <li>
                <Typography as="span" size="16">
                  {`DO keep a Record (Documentation): if you don't have a
                  record of something happening, it's easier to argue that
                  it didn't happen. Documentation will be the key if we
                  ever have to defend the decision to terminate an employee
                  before Court.`}
                </Typography>
              </li>
              <li>
                <Typography as="span" size="16">
                  DO make a list of all company belongings that should be
                  returned upon termination, such as laptop, monitor, printer,
                  etc.
                </Typography>
              </li>
              <li>
                <Typography as="span" size="16">
                  DO inform Omnipresent if you suspect that an involuntary
                  termination may trigger a lawsuit.
                </Typography>
              </li>
              <li>
                <Typography as="span" size="16">
                  DO expect that a terminated employee will ask to review the
                  grounds of the termination, and any termination package
                  offered, with their legal counsel.
                </Typography>
              </li>
            </Stack>
          </ul>
        </Stack>
        <Stack
          p="16"
          border="subtle"
          borderBottom
          borderLeft
          borderRight
          borderTop
          radius="m"
        >
          <Inline align="center" gap="4">
            <X
              size={omniConfig.theme.sizes[16]}
              color={omniConfig.theme.colors['red-500']}
              style={{ marginLeft: '5' }}
            />
            <Typography
              as="h2"
              hideParagraphSpacing
              weight="medium"
              size="20"
            >{`Don't`}</Typography>
          </Inline>
          <ul style={{ margin: '0', paddingLeft: '8px' }}>
            <Stack p="16">
              <li>
                <Typography as="span" size="16">
                  DON’T take any action towards the employee you wish to
                  terminate before having consulted with Omnipresent.
                  Omnipresent as the legal employer must ensure that the
                  offboarding process is done compliantly.
                </Typography>
              </li>
              <li>
                <Typography as="span" size="16">
                  DON’T have a meeting with the employee to discuss termination
                  before notifying Omnipresent, avoid emails, texts, and
                  messages that may trigger the employee to suspect about the
                  termination.
                </Typography>
              </li>
              <li>
                <Typography as="span" size="16">
                  DON’T think that the employment rules you are familiar with
                  apply everywhere. Unfortunately, each jurisdiction in which we
                  operate has its specificity, especially when it comes to
                  termination. It is our job at Omnipresent to guide you through
                  the right steps to take, compliantly according to each
                  jurisdiction.
                </Typography>
              </li>
            </Stack>
          </ul>
        </Stack>
      </Split>
      <Inline gap="4" align="center" justify="center">
        <Typography as="span" size="16">
          Learn more about offboarding with Omnipresent
        </Typography>
        <Link
          to="https://www.omnipresent.com/articles/offboarding-dos-and-donts"
          target="_blank"
          rel="noreferrer"
          size="16"
        >
          here
        </Link>
      </Inline>
    </Stack>
  );
};
