import { HTTPStatusCodesEnum } from 'common/constants';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

export const mockAssociatedUsers = [
  {
    id: 'dee5dacf-9ef5-49d6-b961-3e739d8e2be6',
    userId: '5b5b8adf-3f69-464d-8c8c-26160828f64c',
    associatedUserId: 'b5f6a27c-96f7-4d74-9a20-c88ed5097e8a',
  },
  {
    id: 'f00c179c-4d0a-45ba-ac37-13b754a883ef',
    userId: '5b5b8adf-3f69-464d-8c8c-26160828f64c',
    associatedUserId: '2962c3b6-2698-4f2f-837c-24d47c413d17',
  },
];

export const userAssociationsHandlers = [
  rest.put<string[], any>(
    composeEndpoint('/user-associations/:userId'),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockAssociatedUsers),
      );
    },
  ),
  rest.patch<string[], any>(
    composeEndpoint('/user-scopes/users/:userId/super-manager'),
    (_req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json([]));
    },
  ),
  rest.patch(
    composeEndpoint('/user-scopes/users/:userId/expenses-notifications'),
    (_req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json([]));
    },
  ),
  rest.patch(
    composeEndpoint('/user-scopes/users/:userId/finance'),
    (_req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json([]));
    },
  ),
  rest.patch(
    composeEndpoint('/user-scopes/users/:userId/payroll'),
    (_req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json([]));
    },
  ),
];
