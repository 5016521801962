import { lazy } from 'react';

const appName = 'OmniPlatform';

/**
 * See: https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
 */
export const lazyWithRetry: typeof lazy = (componentImport) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore ignore non promised return in catch
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(`${appName}-page-has-been-force-refreshed`) ||
        'false',
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(
        `${appName}-page-has-been-force-refreshed`,
        'false',
      );

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(
          `${appName}-page-has-been-force-refreshed`,
          'true',
        );
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
