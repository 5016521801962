import PropTypes from 'prop-types';

import { StyledBodyCopy, StyledSmallCopy } from '../../atoms/Text/Copy.styles';
import {
  StyledFormProgressContainer,
  StyledProgressIndicator,
  StyledProgressIndicatorContainer,
  StyledStageCopyContainer,
  StyledStagesCopyContainer,
} from './FormProgress.styles';

const FormProgress = ({ stages, currentStage }) => {
  const percentageComplete = () => {
    const progress = Math.floor((currentStage / stages.length) * 100);
    if (progress > 100) {
      return 100;
    }
    return progress;
  };

  return (
    <StyledFormProgressContainer stages={stages.length}>
      <StyledProgressIndicatorContainer data-testid="form-progress-indicator">
        <StyledProgressIndicator
          data-testid="progress-indicator-bar"
          progress={percentageComplete()}
        />
      </StyledProgressIndicatorContainer>
      <StyledStagesCopyContainer>
        {stages.map(({ name, description, stage }) => (
          <StyledStageCopyContainer key={name}>
            <StyledBodyCopy>Step {stage}:</StyledBodyCopy>
            <StyledSmallCopy>{name}</StyledSmallCopy>
            {description && <StyledSmallCopy>{description}</StyledSmallCopy>}
          </StyledStageCopyContainer>
        ))}
        <StyledBodyCopy>Complete</StyledBodyCopy>
      </StyledStagesCopyContainer>
    </StyledFormProgressContainer>
  );
};

FormProgress.propTypes = {
  stages: PropTypes.oneOfType([PropTypes.array]).isRequired,
  currentStage: PropTypes.number.isRequired,
};

export default FormProgress;
