import { BulkUploadPayrollIdResponse } from 'common/api/payrollService.api';

import {
  Button,
  Icon,
  Inline,
  List,
  Separator,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

const ALL_SUCCEEDED = 'ALL_SUCCEEDED';
const ALL_FAILED = 'ALL_FAILED';
const SOME_SUCCEEDED = 'SOME_SUCCEEDED';

export const BulkUploadOutcome = ({
  statusData,
  onDoneClicked,
}: {
  statusData: BulkUploadPayrollIdResponse;
  onDoneClicked: () => void;
}) => {
  let outcome = '';
  if (statusData.errors.length === 0) {
    outcome = ALL_SUCCEEDED;
  }
  if (statusData.employeesUpdated.length === 0) {
    outcome = ALL_FAILED;
  }
  if (
    statusData.errors.length !== 0 &&
    statusData.employeesUpdated.length !== 0
  ) {
    outcome = SOME_SUCCEEDED;
  }

  const updatedEmployeesNames = statusData.employeesUpdated.map(
    (employee) => employee.name,
  );
  const errors = statusData.errors;

  const outcomeConfig = {
    [ALL_SUCCEEDED]: {
      Icon: <Icon icon="CircleCheck" color="positive" size="32" />,
      title: 'All Provider IDs were successfully updated',
      context: null,
    },
    [ALL_FAILED]: {
      Icon: <Icon icon="AlertCircle" color="negative" size="32" />,
      title: 'No Provider IDs could be updated',
      context:
        'Check you’ve entered the correct ID, and upload the template again',
    },
    [SOME_SUCCEEDED]: {
      Icon: <Icon icon="AlertTriangle" color="warning" size="32" />,
      title: 'Some Provider IDs could not be updated',
      context:
        'Check you’ve entered the correct ID, and upload the template again',
    },
  }[outcome];

  return (
    <>
      {outcomeConfig && (
        <Stack gap="16">
          <Stack align="center" gap="4">
            {outcomeConfig?.Icon}
            <Typography as="h3" size="20" weight="medium">
              {outcomeConfig?.title}
            </Typography>
            {outcomeConfig?.context && (
              <Typography as="span" size="14" color="subtle">
                {outcomeConfig?.context}
              </Typography>
            )}
          </Stack>
          {errors && (
            <List
              css={{ listStyleType: 'disc' }}
              m="0"
              pl="24"
              gap="4"
              items={errors}
              keyExtractor={({ employee }) => employee.id}
              render={({ employee, error }) => (
                <Stack gap="0">
                  <Typography as="span" weight="medium" size="14">
                    {employee.name}
                  </Typography>
                  <Typography as="span" size="14">
                    {error}
                  </Typography>
                </Stack>
              )}
            />
          )}
          {!!updatedEmployeesNames.length && (
            <Stack>
              {outcome === SOME_SUCCEEDED && (
                <>
                  <Separator />
                  <Inline gap="8">
                    <Icon icon="CircleCheck" color="positive" size="24" />
                    <Typography as="span" size="14" weight="medium">
                      The provider IDs of the following were successfully
                      updated
                    </Typography>
                  </Inline>
                </>
              )}
              <List
                css={{ listStyleType: 'disc' }}
                m="0"
                pl="24"
                gap="0"
                items={updatedEmployeesNames}
                keyExtractor={(employee) => employee}
                render={(employee) => (
                  <Typography as="span" size="14">
                    {employee}
                  </Typography>
                )}
              />
            </Stack>
          )}
          <Inline justify="right">
            <Button onClick={onDoneClicked}>Done</Button>
          </Inline>
        </Stack>
      )}
    </>
  );
};
