import { getUserSelector } from 'app/store/app.selectors';
import {
  getPayrollProvider,
  getPayrollProviders,
} from 'common/api/payrollProviders.api';
import { PayrollProviderApiResponse } from 'common/types';
import { useQuery, UseQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';
import { getCountryByCountryName } from 'utils/countries';
import { isAdmin } from 'utils/user';

import { getDataFromResponse } from '../../../react-query-utils';
import { sortAlphabetically } from '../../../sort.util';
import { payrollProviderKeys } from '../keys';

type UsePayrollProviderParams = UsePayrollProviderQueryParams &
  UsePayrollProviderFilterParams;

type UsePayrollProviderFilterParams = {
  countryCode?: string;
};

type UsePayrollProviderQueryParams = {
  isActive?: boolean;
  lspId?: string;
  key?: any;
};

export const usePayrollProvidersQuery = ({
  isActive,
  lspId,
  key,
  countryCode,
}: UsePayrollProviderParams = {}) => {
  const user = useSelector(getUserSelector);
  const hasPermissionToSeePayrollProviders = isAdmin(user);

  return useQuery<PayrollProviderApiResponse[], Error>(
    key || payrollProviderKeys.default,
    () => getPayrollProviders({ isActive, lspId }).then(getDataFromResponse),
    {
      enabled: hasPermissionToSeePayrollProviders,
      select: (data) => {
        const sorted = sortAlphabetically('name', data);
        if (countryCode) {
          return sorted.filter((x) => x.countryCode === countryCode);
        }
        return sorted;
      },
    },
  );
};

export const usePayrollProviderQuery = (
  id: string,
  {
    queryOptions,
  }: {
    queryOptions?: Pick<UseQueryOptions, 'staleTime' | 'cacheTime' | 'enabled'>;
  } = {},
) => {
  const user = useSelector(getUserSelector);
  const hasPermissionToSeePayrollProviders = isAdmin(user);
  return useQuery<PayrollProviderApiResponse, Error>(
    payrollProviderKeys.single(id),
    () => getPayrollProvider(id).then(getDataFromResponse),
    {
      enabled: hasPermissionToSeePayrollProviders,
      staleTime: queryOptions?.staleTime || Infinity,
      ...(queryOptions || {}),
    },
  );
};

export const useActivePayrollProvidersFilterQuery = ({
  countryName,
}: {
  countryName: string;
}) => {
  return usePayrollProvidersQuery({
    isActive: true,
    key: payrollProviderKeys.active,
    countryCode: countryName && getCountryByCountryName(countryName)?.value,
  });
};
