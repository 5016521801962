import { Reducer } from 'redux';

import {
  asyncActionKeys,
  CLEAR_DOCUMENT_CREATED,
  SET_FILE_INFO,
} from '../actions/redux.constants';

const initialState = {
  documentLink: null,
  documentUploaded: false,
  uploading: false,
  documentFile: null,
  documentType: null,
};

export const documentStorageReducer: Reducer = (
  state = initialState,
  action,
) => {
  switch (true) {
    case asyncActionKeys.UPLOAD_DOCUMENT_END === action.type:
      return {
        ...state,
        documentLink: action.payload,
        documentUploaded: true,
        uploading: false,
      };
    case asyncActionKeys.UPLOAD_DOCUMENT_START === action.type:
      return {
        ...state,
        uploading: true,
        documentUploaded: false,
      };
    case CLEAR_DOCUMENT_CREATED === action.type:
      return {
        ...state,
        documentUploaded: false,
        uploading: false,
        documentLink: null,
        documentFile: null,
        documentType: null,
      };
    case SET_FILE_INFO === action.type:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
