import { createQueryKeys } from 'utils/react-query-utils';

export const timeOffLogsKeys = createQueryKeys(
  'time-off-logs',
  (createKey) => ({
    singleEmployeeByYearMonth: (
      employeeId: string,
      year: number,
      month: number,
    ) => createKey(employeeId, year, month),
    singleEmployee: (employeeId: string) => createKey(employeeId),
    singleCompany: (companyId: string) => createKey(companyId),
  }),
);
