import { BulkRequest, GrossToNetProcessingStatus } from 'common/types/payroll';
import { isEmpty } from 'lodash';
import { useTrack } from 'utils/mixpanel/tracker';
import { useApprovePayReportBulkMutation } from 'utils/queries/payroll/useApprovePayReportBulkMutation';
import { useDeleteGrossToNetFileBulkMutation } from 'utils/queries/payroll/useDeleteGrossToNetFileBulkMutation';
import { useGrossToNetExportMutation } from 'utils/queries/payroll/useGrossToNetExportMutation';
import { usePayReportWorkflowTransitionBulkMutation } from 'utils/queries/payroll/usePayReportWorkflowTransitionBulkMutation';
import { usePublishPayReportBulkMutation } from 'utils/queries/payroll/usePublishPayReportBulkMutation';

import { notifyError, notifySuccess } from '@omnipresentgroup/design-system';

import { NEW_STATUSES_CONFIG } from '../../PayReportPreviewPage/PayReportPreviewPage';
import { RowData } from '../PayrollInstanceLspBillDetailPage';

export type ActionHookDepencies = {
  setRowSelection: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  selectedGrossToNets: {
    gtnId: string;
    status: GrossToNetProcessingStatus;
  }[];
  gtnsInfo:
    | {
        country: string;
        lspId: string;
        payrollCycle: string;
        payrollPeriod: string;
      }
    | undefined;
  reloadGtnFiles: () => void;
  tableData: RowData[];
};

const createBulkRequest = (
  selectedGrossToNets: { gtnId: string; status: GrossToNetProcessingStatus }[],
  gtnsInfo?: {
    country: string;
    lspId: string;
    payrollCycle: string;
    payrollPeriod: string;
  },
): BulkRequest => {
  if (!isEmpty(selectedGrossToNets)) {
    return {
      type: 'dataFrameIds',
      dataFrameIds: selectedGrossToNets.map(({ gtnId }) => gtnId),
    };
  } else if (gtnsInfo) {
    return {
      type: 'lsp',
      ...gtnsInfo,
    };
  } else {
    return {
      type: 'dataFrameIds',
      dataFrameIds: [],
    };
  }
};

const manageBulkResponse = (
  successStatus: string,
  results: [string, string][],
  tableData: RowData[],
) => {
  const data = results.reduce<string[]>((acc, response) => {
    const [gtnId, status] = response;
    if (status === successStatus) {
      return acc;
    }
    const rowData = tableData.find((row) => row.gtnId === gtnId);

    return [...acc, rowData?.name || 'unknown'];
  }, []);
  return data.join(', ');
};

export const useExport = ({
  setRowSelection,
  selectedGrossToNets,
  gtnsInfo,
}: ActionHookDepencies) => {
  const { mutate: exportGrossToNets, isLoading: isExportingGrossToNets } =
    useGrossToNetExportMutation();

  const onExportCsvClick = () => {
    exportGrossToNets(createBulkRequest(selectedGrossToNets, gtnsInfo), {
      onSuccess: (response) => {
        const a = document.createElement('a');
        a.href = `data:application/zip;base64,${response.data}`;
        a.download = 'export.zip';
        a.click();
        notifySuccess({
          title: 'Success',
          description: 'Exported employees.',
        });
        setRowSelection({});
      },
      onError: (err) => {
        console.error(err);
        notifyError({
          title: 'Error',
          description: 'Failed to export employees.',
        });
      },
    });
  };

  return { isExportingGrossToNets, onExportCsvClick };
};

export const useDelete = ({
  setRowSelection,
  selectedGrossToNets,
  gtnsInfo,
  reloadGtnFiles,
  tableData,
}: ActionHookDepencies) => {
  const track = useTrack();
  const { mutate: deleteGrossToNets, isLoading: isDeletingGrossToNets } =
    useDeleteGrossToNetFileBulkMutation();

  const onDeletePayReportsClick = () => {
    track('Delete pay report file clicked');
    deleteGrossToNets(createBulkRequest(selectedGrossToNets, gtnsInfo), {
      onSuccess: (response) => {
        const failedToDelete = manageBulkResponse(
          'DELETED',
          Object.entries(response),
          tableData,
        );
        setRowSelection({});
        reloadGtnFiles();
        isEmpty(failedToDelete)
          ? notifySuccess({
              title: 'Success',
              description: 'Deleted employees.',
            })
          : notifyError({
              title: 'Error',
              description: `Failed to delete ${failedToDelete}.`,
            });
      },
      onError: (err) => {
        console.error(err);
        notifyError({
          title: 'Error',
          description: 'Failed to delete employees.',
        });
      },
    });
  };

  return { isDeletingGrossToNets, onDeletePayReportsClick };
};

export const useApprove = ({
  setRowSelection,
  selectedGrossToNets,
  gtnsInfo,
  reloadGtnFiles,
  tableData,
}: ActionHookDepencies) => {
  const track = useTrack();
  const { mutate: approvePayReports, isLoading: isApprovingPayReports } =
    useApprovePayReportBulkMutation();

  const onApprovePayReportsClick = () => {
    track('Approve Pay Report file button clicked');
    approvePayReports(createBulkRequest(selectedGrossToNets, gtnsInfo), {
      onSuccess: (response) => {
        const failedApprovals = manageBulkResponse(
          'SUCCESS',
          Object.entries(response),
          tableData,
        );
        setRowSelection({});
        reloadGtnFiles();
        isEmpty(failedApprovals)
          ? notifySuccess({
              title: 'Success',
              description:
                'Pay report status has been changed successfully for employees.',
            })
          : notifyError({
              title: 'Error',
              description: `Something went wrong starting verification for: ${failedApprovals}.`,
            });
      },
      onError: (err) => {
        console.error(err);
        notifyError({
          title: 'Error',
          description:
            'Something went wrong starting verification for employees.',
        });
      },
    });
  };

  return { isApprovingPayReports, onApprovePayReportsClick };
};

export const usePostApprovalTransiton = ({
  setRowSelection,
  selectedGrossToNets,
  gtnsInfo,
  reloadGtnFiles,
  tableData,
}: ActionHookDepencies) => {
  const track = useTrack();
  const { mutate: workflowTransitionBulk, isLoading: isWaitingTransition } =
    usePayReportWorkflowTransitionBulkMutation();

  const onPostApprovalTransitonClick = (
    newStatus: GrossToNetProcessingStatus,
  ) => {
    track(
      `${
        newStatus && NEW_STATUSES_CONFIG[newStatus]?.action.label
      } pay report file clicked`,
    );
    workflowTransitionBulk(
      { ...createBulkRequest(selectedGrossToNets, gtnsInfo), newStatus },
      {
        onSuccess: (response) => {
          const failedTransitions = manageBulkResponse(
            'SUCCESS',
            Object.entries(response),
            tableData,
          );
          setRowSelection({});
          reloadGtnFiles();
          isEmpty(failedTransitions)
            ? notifySuccess({
                title: 'Success',
                description: 'All employees status successfully changed.',
              })
            : notifyError({
                title: 'Error',
                description: `Failed to change statuses: ${failedTransitions}.`,
              });
        },
        onError: (err) => {
          console.error(err);
          notifyError({
            title: 'Error',
            description: 'Failed to change status for employees.',
          });
        },
      },
    );
  };

  return { isWaitingTransition, onPostApprovalTransitonClick };
};

export const usePublish = ({
  setRowSelection,
  selectedGrossToNets,
  gtnsInfo,
  reloadGtnFiles,
  tableData,
}: ActionHookDepencies) => {
  const track = useTrack();
  const { mutate: publishPayReports, isLoading: isPublishingPayReports } =
    usePublishPayReportBulkMutation();

  const onPublishPayReportsClick = () => {
    track('Publish pay report file clicked');
    publishPayReports(createBulkRequest(selectedGrossToNets, gtnsInfo), {
      onSuccess: (response) => {
        const failedPublish = manageBulkResponse(
          'SUCCESS',
          Object.entries(response),
          tableData,
        );
        setRowSelection({});
        reloadGtnFiles();
        isEmpty(failedPublish)
          ? notifySuccess({
              title: 'Success',
              description: 'Published employees.',
            })
          : notifyError({
              title: 'Error',
              description: `Failed to publish employees: ${failedPublish}.`,
            });
      },
      onError: (err) => {
        console.error(err);
        notifyError({
          title: 'Error',
          description: 'Failed to publish employees.',
        });
      },
    });
  };

  return { isPublishingPayReports, onPublishPayReportsClick };
};
