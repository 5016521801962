import { reduceInputField } from 'utils/transformer-utils/input.transformer';

export const transformCreateEmployeePayload = (stagedFormData) => {
  return stagedFormData.reduce((accumulated, stage) => {
    const stageObject = stage.fields.reduce(reduceInputField, {});
    return {
      ...accumulated,
      ...stageObject,
    };
  }, {});
};
