import { createQueryKeys } from 'utils/react-query-utils';

export const timesheetsKeys = createQueryKeys('timesheets', (createKey) => ({
  getCountryFormat: (countryName: string) =>
    createKey(countryName, 'getCountryFormat'),
  getEmployeeIsAllowed: (employeeId: string) =>
    createKey(employeeId, 'getEmployeeIsAllowed'),
  getEmployeeActiveTimesheetSystem: (employeeId: string) =>
    createKey(employeeId, 'getEmployeeActiveTimesheetSystem'),
  getRegularWorkingHours: (employeeId: string) =>
    createKey(employeeId, 'getRegularWorkingHours'),
  getTimesheets: (employeeId: string) => createKey(employeeId, 'getTimesheets'),
  getTimesheetByYearMonth: (employeeId: string, year: string, month: string) =>
    createKey(employeeId, year, month, 'getTimesheetByYearMonth'),
  getCompanyTimesheetsPaginated: (companyId: string) =>
    createKey(companyId, 'getCompanyTimesheetsPaginated'),
}));
