import styled from 'styled-components';

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CSVExportButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme: { space } }) => space[16]};
`;
