export const getCompaniesSelector = (state) => state.companiesReducer.companies;
export const getSelectedCompanySelector = (state) =>
  state.companiesReducer.selectedCompany;
export const getSelectedCompanyIntegrationsSelector = (state) =>
  state.companiesReducer.selectedCompany.integrations;
export const getSelectedCompanyHRISIntegrationsSelector = (state) =>
  state.companiesReducer.selectedCompany.integrations.hris;
export const getIsCompanyCreatedSelector = (state) =>
  state.companiesReducer.companyCreated;
export const getCompanyManagersSelector = (state) =>
  state.companiesReducer.companyManagers;
export const getCompanyManagerSelector = (state) =>
  state.companiesReducer.companyManager;
export const getTenantIdSelector = (state) =>
  state.companiesReducer?.selectedCompany?.id;
export const getNewCompanyIdSelector = (state) =>
  state.companiesReducer.newCompanyId;
export const getSelectedCompanyForAppSelector = (state) =>
  state.companiesReducer?.selectedCompany;
export const getCreatedCompanyManagerUserIdSelector = (state) =>
  state.companiesReducer?.createdCompanyManagerUserId;
export const getNewCompanyAdministrativeInformation = (
  state,
  informationName,
) =>
  state.companiesReducer?.selectedCompany?.administrativeInformation?.find(
    (item) => item.name === informationName,
  ).value;
