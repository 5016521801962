import PropTypes from 'prop-types';

import { StyledTableCell } from './Table.styles';

const TableCell = ({ text, children, bold, align, maxWidth }) => (
  <StyledTableCell
    bold={bold}
    align={align}
    maxWidth={maxWidth}
    data-testid="table-cell"
  >
    {text || children}
  </StyledTableCell>
);

TableCell.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  children: PropTypes.node,
  bold: PropTypes.bool,
  align: PropTypes.string,
  maxWidth: PropTypes.bool,
};

TableCell.defaultProps = {
  text: null,
  children: null,
  bold: false,
  align: 'left',
  maxWidth: false,
};

export default TableCell;
