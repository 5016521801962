import actionKeys from './reduxConstants';

export const getEmployeeExpensesAction = (payload) => ({
  type: actionKeys.GET_EXPENSES_START,
  payload,
});

export const getEmployeeExpenseAction = (payload) => ({
  type: actionKeys.GET_EXPENSE_START,
  payload,
});

export const updateEmployeeExpenseAction = (payload) => ({
  type: actionKeys.UPDATE_EXPENSE_START,
  payload,
});

export const clearSelectedExpenseAction = () => ({
  type: actionKeys.CLEAR_SELECTED_EXPENSE,
});

export const setExpenseToPaidAction = (payload) => ({
  type: actionKeys.SET_PAID_EXPENSE_START,
  payload,
});
