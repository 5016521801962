import InvitationStatus from 'common/components/molecules/InvitationStatus/InvitationStatus';
import PropTypes from 'prop-types';

const DetailsCardInvitationType = ({
  value,
  sendPlatformInvitation,
  platformInvitationProgress,
}) => (
  <InvitationStatus
    invitationStatus={value || platformInvitationProgress}
    platformInviteInProgress={platformInvitationProgress}
    sendPlatformInvitation={sendPlatformInvitation}
  />
);

DetailsCardInvitationType.propTypes = {
  value: PropTypes.string,
  platformInvitationProgress: PropTypes.string.isRequired,
  sendPlatformInvitation: PropTypes.func.isRequired,
};

DetailsCardInvitationType.defaultProps = {
  value: null,
};

export default DetailsCardInvitationType;
