import styled from 'styled-components';

export const StyledAdminHomePage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const StyledCompanyListMobile = styled.div``;
