import { HTTPStatusCodesEnum } from 'common/constants';
import { rest } from 'msw';

const LOOM_BASE_URL = 'https://www.loom.com';

export const loomVideoEmbedHandler = [
  rest.get(`${LOOM_BASE_URL}/v1/oembed`, (req, res, ctx) => {
    return res(
      ctx.status(HTTPStatusCodesEnum.OK),
      ctx.json({
        type: 'video',
        version: '1.0',
        html: '<div class="lo-emb-vid" style="position: relative; padding-bottom: 75%; height: 0;"><iframe src="https://www.loom.com/embed/0281766fa2d04bb788eaf19e65135184" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>',
        height: 960,
        width: 1280,
        provider_name: 'Loom',
        provider_url: 'https://www.loom.com',
        thumbnail_height: 960,
        thumbnail_width: 1280,
        thumbnail_url:
          'https://cdn.loom.com/sessions/thumbnails/0281766fa2d04bb788eaf19e65135184-00001.jpg',
        duration: 21,
        title: 'Check out loom.com',
        description: 'Testing',
      }),
    );
  }),
];
