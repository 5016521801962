type SortOrder = 'asc' | 'desc';

interface SortOptions<T> {
  key: keyof T;
  list: T[];
  order?: SortOrder;
}

/**
 * Sorts an array of objects based on the specified object key, in either ascending or descending order.
 *
 * @template T - The type of the objects within the array to be sorted (in 99.9% of the case the type is inferred).
 *
 * @param {SortOptions<T>} options - An object containing the sorting parameters.
 * @param {keyof T} options.key - The key in the objects to sort by.
 * @param {T[]} options.list - The array of objects to be sorted.
 * @param {SortOrder} [options.order='asc'] - The sorting order, 'asc' for ascending and 'desc' for descending.
 *
 * @returns {T[]} The sorted array of objects.
 *
 * @example
 * sortObjectsByKey({
 *   key: 'name',
 *   list: [{ name: 'Paul' }, { name: 'Jamie' }],
 *   order: 'asc',
 * });
 *
 * // Returns [{ name: 'Jamie' }, { name: 'Paul' }]
 */
export const sortObjectsByKey = <T>({
  key,
  list,
  order = 'asc',
}: SortOptions<T>): T[] => {
  return list.sort((itemOne, itemTwo) => {
    const val1 = String(itemOne[key] ?? '').toLowerCase();
    const val2 = String(itemTwo[key] ?? '').toLowerCase();

    return order === 'desc'
      ? val2.localeCompare(val1)
      : val1.localeCompare(val2);
  });
};
