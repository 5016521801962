import {
  getHRISEmployeesList,
  GetHRISEmployeesListPayload,
} from 'common/api/integrations.api';
import { TEN_MINUTES } from 'common/constants';
import { useQuery } from 'react-query';
import {
  useSelectCompanySelected,
  useSelectCompanySelectedHRISIntegration,
} from 'utils/hooks';
import { getDataFromResponse } from 'utils/react-query-utils';

import { integrationsKeys } from '../keys';

export const useHRISEmployeesListQuery = () => {
  const { id: companyId } = useSelectCompanySelected();
  const hrisIntegration = useSelectCompanySelectedHRISIntegration();

  const payload: GetHRISEmployeesListPayload = {
    companyId,
    provider: hrisIntegration.provider,
    integrationId: hrisIntegration.integrationId,
  };

  return useQuery(
    integrationsKeys.hris.employeeList(payload),
    () => getHRISEmployeesList(payload).then(getDataFromResponse),
    {
      enabled: !!hrisIntegration.integrationId,
      staleTime: TEN_MINUTES,
      select: (data) => data.results,
    },
  );
};
