import { ManagerPermissionsTab } from 'omniplatform/manager/pages/ManagerPermissions/components';

import { StyledTab } from '../CompanyDetailPage.styles';

export const PermissionsTab = () => {
  return (
    <StyledTab data-testid="permissions-tab" className="smallStack">
      <ManagerPermissionsTab />
    </StyledTab>
  );
};
