import { useEffect, useMemo, useRef, useState } from 'react';

import { exportInvoiceDataToCsv } from 'common/api/finance.api';
import { ErrorBanner, Loading } from 'common/components';
import { TableFilterActionWrapper } from 'common/components/atoms/TableFilterAction/TableFilterAction.styled';
import { FormCheckbox } from 'common/components/molecules/FormCheckbox';
import {
  SearchIconContainer,
  StyledSearchInput,
} from 'common/components/molecules/SearchInput/SearchInput.styles';
import StyledTableContainer from 'common/components/molecules/StyledTableContainer/StyledTableContainer.styles';
import { InfiniteScrollTable } from 'common/components/organisms/InfiniteScrollTable/InfiniteScrollTable';
import { StyledTitleCell } from 'common/components/organisms/TableWithTitle/TableWithTitle.styles';
import { ONE_SECOND } from 'common/constants';
import { TableHeader } from 'common/types';
import { Search } from 'react-feather';
import { formatCurrency } from 'utils/currencies';
import { useDebounce } from 'utils/hooks';
import { useInvoiceLogsQuery } from 'utils/queries/payroll/useInvoiceLogsQuery/useInvoiceLogsQuery';

import {
  Button,
  DateFormats,
  formatISODate,
} from '@omnipresentgroup/design-system';

import {
  INITIAL_FILTER_STATE,
  InvoicesMonitoringFilterType,
  MonitoringFilterDropDown,
} from '../../components/MonitoringFilterDropDown/MonitoringFilterDropDown';
import { ProcessingStatusBadge } from '../../components/ProcessingStatusBadge';
import { InvoiceLog, SimpleInvoiceType } from '../../types';
import { ActionCell } from './ActionCell/ActionCell';
import { ActionWrapper } from './ActionCell/ActionCell.styles';
import {
  CSVExportButtonContainer,
  StyledDiv,
} from './FinanceMonitoringTab.style';
import { InvoiceLogId } from './types';

const ClientCell = (data: InvoiceLog) => (
  <StyledTitleCell>{data.client}</StyledTitleCell>
);

const StatusCell = (data: InvoiceLog) => (
  <ProcessingStatusBadge id={`processing-${data.id}`} status={data.status} />
);

const DateCell = (data: InvoiceLog) => (
  <span>{formatISODate(data.date, DateFormats.Date)}</span>
);

const AmountCell = (data: InvoiceLog) => (
  <span>
    {data.amount
      ? formatCurrency(data.amount.totalAmount, data.amount.currency)
      : '-'}
  </span>
);

const invoiceTypeLabel = (invoiceType: SimpleInvoiceType) => {
  switch (invoiceType) {
    case SimpleInvoiceType.INITIAL_SALARY_RETAINER:
      return 'Salary pre-payment';
    case SimpleInvoiceType.SETUP_DEPOSIT_FEE:
      return 'Setup Fee and Deposit';
    case SimpleInvoiceType.SETUP_FEE_ONLY:
      return 'Setup Fee';
    case SimpleInvoiceType.DEPOSIT_ONLY:
      return 'Deposit';

    default:
      return '-';
  }
};

const TypeCell = (data: InvoiceLog) => (
  <span>{invoiceTypeLabel(data.type)}</span>
);

const Table = () => {
  const [filter, setFilter] =
    useState<InvoicesMonitoringFilterType>(INITIAL_FILTER_STATE);

  const [clientOrEmployeeName, setClientOrEmployeeName] = useState<string>();
  const clientOrEmployeeNameDebounce = useDebounce(
    clientOrEmployeeName,
    ONE_SECOND,
  );
  const paginatedQuery = useInvoiceLogsQuery({
    ...filter,
    clientOrEmployeeNameQuery: clientOrEmployeeNameDebounce,
    resolveSimpleInvoice: true,
  });
  const [csvLoading, setCsvLoading] = useState(false);
  const [entitiesToExport, setEntitiesToExport] = useState<Set<InvoiceLogId>>(
    new Set(),
  );

  const selectAllCheckBoxRef = useRef<HTMLInputElement>(null);

  const items = useMemo(
    () =>
      paginatedQuery.data?.pages.reduce<InvoiceLog[]>(
        (accumulator, current) => {
          current.forEach((item) => {
            accumulator.push(item);
          });
          return accumulator;
        },
        [],
      ) || [],
    [paginatedQuery.data?.pages],
  );

  const addEntityToExport = (invoiceLogExport: InvoiceLogId) => {
    setEntitiesToExport((prevEntitiesToExport) => {
      const newEntitiesToExport = new Set(prevEntitiesToExport);
      newEntitiesToExport.add(invoiceLogExport);
      return newEntitiesToExport;
    });
  };

  const removeEntityToExport = (invoiceLogExport: InvoiceLogId) => {
    setEntitiesToExport((prevEntitiesToExport) => {
      const newEntitiesToExport = new Set(prevEntitiesToExport);
      newEntitiesToExport.delete(invoiceLogExport);
      return newEntitiesToExport;
    });
  };

  const allSelected =
    items.length > 0 && items.length === entitiesToExport.size;

  const paginatedTableHeaders: TableHeader<InvoiceLog>[] = [
    {
      headerTitle: 'Client',
      customCell: ClientCell,
      align: 'left',
      fieldOnObject: 'client',
    },
    {
      headerTitle: 'Employee',
      align: 'left',
      fieldOnObject: 'employee',
    },
    {
      headerTitle: 'Type',
      align: 'left',
      customCell: TypeCell,
    },
    {
      headerTitle: 'Date',
      align: 'left',
      fieldOnObject: 'date',
      customCell: DateCell,
    },
    {
      headerTitle: 'Status',
      align: 'left',
      fieldOnObject: 'status',
      customCell: StatusCell,
    },
    {
      headerTitle: 'Amount',
      align: 'left',
      fieldOnObject: 'amount',
      customCell: AmountCell,
    },
  ];

  const someSelected = !allSelected && entitiesToExport.size > 0;

  useEffect(() => {
    selectAllCheckBoxRef.current &&
      (selectAllCheckBoxRef.current.indeterminate = someSelected);
  }, [someSelected]);

  const paginatedTableHeadersWithCheckBox = [
    {
      // eslint-disable-next-line react/display-name
      headerTitle: (
        <ActionWrapper>
          <FormCheckbox
            ref={selectAllCheckBoxRef}
            id="checkbox-select-all"
            name="checkbox-select-all"
            label=""
            checked={allSelected}
            indeterminate={someSelected}
            onChange={(state: React.ChangeEvent<HTMLInputElement>) => {
              state.target.checked
                ? setEntitiesToExport(new Set(items.map(({ id }) => id)))
                : setEntitiesToExport(new Set());
            }}
          />
        </ActionWrapper>
      ),
      align: 'left',
      customCell: ActionCell(
        addEntityToExport,
        removeEntityToExport,
        entitiesToExport,
      ),
      fieldOnObject: 'id',
    },
    ...paginatedTableHeaders,
  ] as TableHeader<InvoiceLog>[];

  const { isError, isFetchingNextPage, isLoading, hasNextPage } =
    paginatedQuery;

  if (isError) {
    return <ErrorBanner />;
  }

  const fetchNextPage = () => {
    paginatedQuery.fetchNextPage();
  };

  const handleOnChange = (event: any) => {
    event.preventDefault();
    const search = event.target.value;

    setClientOrEmployeeName(search);
    setEntitiesToExport(new Set());
  };

  return (
    <StyledTableContainer>
      <CSVExportButtonContainer>
        <Button
          variant="primary"
          loading={csvLoading}
          disabled={entitiesToExport.size === 0}
          onClick={() => {
            setCsvLoading(true);
            exportInvoiceDataToCsv([...entitiesToExport])
              .then(() => {
                setCsvLoading(false);
              })
              .catch((err) => {
                setCsvLoading(false);
                console.error('Error exporting simple invoice logs CSV', err);
              });
          }}
        >
          Export Invoice Report
        </Button>
      </CSVExportButtonContainer>
      <StyledDiv>
        <TableFilterActionWrapper data-testid="search-input-container">
          <SearchIconContainer>
            <Search />
          </SearchIconContainer>
          <StyledSearchInput
            type="text"
            name="searchInputField"
            placeholder="Search by employee or client"
            onChange={handleOnChange}
          />
        </TableFilterActionWrapper>
        <MonitoringFilterDropDown
          onFilterUpdate={(f) => {
            setFilter(f);
            setEntitiesToExport(new Set());
          }}
          filters={filter}
        />
      </StyledDiv>
      {paginatedQuery.isLoading ? (
        <Loading />
      ) : (
        <InfiniteScrollTable
          showButton={false}
          headers={paginatedTableHeadersWithCheckBox}
          itemsToDisplay={items || []}
          testId="initial-invoices-list"
          isInitialLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          onBottomReached={fetchNextPage}
          getId={(invoiceLog) => invoiceLog.id}
          showHeader
        />
      )}
    </StyledTableContainer>
  );
};

export const FinanceMonitoringTab = () => <Table />;
