import styled from 'styled-components';

export const ModalContainer = styled.div`
  margin: ${({ theme: { space } }) => space[32]};

  & h4 {
    margin-bottom: ${({ theme: { space } }) => space[16]};
  }
`;

const align = {
  left: 'flex-start',
  middle: 'center',
  right: 'flex-end',
  space: 'space-between',
};

export const UploadProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 538px;
  height: 60px;
  background: #f5fcf5;
  border-radius: 8px;
  margin-bottom: ${({ theme: { space } }) => space[16]};

  & > div {
    padding: ${({ theme: { space } }) => space[16]};
  }
`;

export const UploadWrapper = styled.div`
  margin-top: 24px;
`;

export const UploadProgressTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;

  span:first-child {
    font-weight: 500;
    font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
    line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
    color: ${({ theme: { colors } }) => colors['gray-500']};
  }

  span:last-child {
    font-weight: 500;
    font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
    line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
    color: ${({ theme: { colors } }) => colors['gray-300']};
  }
`;

export const TitleWrappper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg:hover {
    cursor: pointer;
  }
`;

export const UploadProgressBar = styled.div<{
  alignment?: 'left' | 'middle' | 'right' | 'space';
}>`
  width: 460px;
  height: 4px;

  & > div {
    background: #3cc13b;
    border-radius: 4px;
    height: 4px;
  }
`;

export const FilesContainer = styled.div`
  margin-top: ${({ theme: { space } }) => space[32]};
  margin-bottom: ${({ theme: { space } }) => space[32]};

  input {
    display: none;
  }

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    padding: ${({ theme: { space } }) => space[4]};
    gap: ${({ theme: { space } }) => space[16]};
    width: auto;
    max-width: 552px;
    height: 60px;
    background: ${({ theme: { colors } }) => colors['white-base']};
    border: 1px dashed #dfe1eb;
    border-radius: ${({ theme: { space } }) => space[8]};

    span {
      text-decoration: underline;
      margin-left: -11px;
      text-underline-offset: 4px;
      text-decoration-thickness: 1.3px;
      border: none;
    }
  }
`;

export const ErrorTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: #a30015;

  svg {
    width: 18px;
  }
`;

export const ProcessingError = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #515151;
`;

export const ErrorIntroductionText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left: 27px;
  margin-top: 30px;
`;

export const PayReportsData = styled.div`
  ul {
    margin-left: 20px;
    margin-top: 10px;

    li {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #060707;
    }
  }
`;

export const ButtonsContainer = styled.div<{
  alignment?: 'left' | 'middle' | 'right' | 'space';
}>`
  display: flex;
  margin-top: ${({ theme: { space } }) => space[32]};
  justify-content: ${({ alignment }) => align[alignment || 'right']};
`;
