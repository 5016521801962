import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BenefitsStepDoneDeprecated } from './BenefitsStepDoneDeprecated';
import { ConfigureBenefitsDeprecated } from './ConfigureBenefitsDeprecated';

type CountryLabel = string | undefined;

export const BenefitsSelectionStepDeprecated = ({
  countryLabel,
}: {
  countryLabel: CountryLabel;
}) => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  const { seatId } = useParams<{ seatId: string }>();
  const benefitFormItem = localStorage.getItem(`benefitsFormId-${seatId}`);
  const isTyperformFilledOut = benefitFormItem === 'true';

  return (
    <>
      {isTyperformFilledOut ? (
        <BenefitsStepDoneDeprecated />
      ) : (
        <ConfigureBenefitsDeprecated countryLabel={countryLabel} />
      )}
    </>
  );
};
