import {
  Address,
  EmployeeRequestPayload,
  EntryDefinition,
  Field,
  FIELD_TYPE,
  FormQuestionSetConfig,
  FormStage,
  Seat,
  UserRoleType,
  UserRoleTypeEnum,
} from 'common/types';
import Joi from 'joi';
import { arePreconditionsMet } from 'utils/preconditions';

import {
  DateFormats,
  formatISODate,
  type IsoDate,
} from '@omnipresentgroup/design-system';

import { getStaticStages } from './staticFormStages';
import { thirdStageBaseQuestions } from './thirdStageBaseQuestions';

const AVVOKA_AUTOFILL_ATTRIBUTES = [
  'fullLegalName',
  'employeeAddress',
  'salaryAmount',
];

export const getAvvokaFieldMapping = (field: EntryDefinition) => {
  switch (true) {
    case field.attribute.startsWith('jobDescription'):
      return FIELD_TYPE.TEXT;
    default:
    case field.type === 'text':
      return FIELD_TYPE.STRING;
    case field.type === 'select_list':
    case field.type === 'db_list':
      return FIELD_TYPE.DROPDOWN;
    case field.type === 'number':
      return FIELD_TYPE.NUMBER;
    case field.type === 'date':
      return FIELD_TYPE.DATE;
    case field.type === 'radio_button':
      return FIELD_TYPE.CHECKBOX;
    case field.type === 'dependent_list':
      return FIELD_TYPE.DEPENDENT_DROPDOWN;
    case field.type === 'checkbox':
      return FIELD_TYPE.CHECKBOX_BOOLEAN;
    case field.type === 'multi_select':
      return FIELD_TYPE.MULTI_SELECT;
  }
};

const convertAddressIntoString = (address: Address): string => {
  if (!address) {
    return '';
  }
  return `${address.addressLine1.trim()}, ${
    address.addressLine2 ? `${address.addressLine2.trim()}, ` : ''
  }${address.addressLine3 ? `${address.addressLine3.trim()}, ` : ''}${
    address.postalCode
  }, ${address.city}, ${address.country}`;
};

const shouldIncludeAvvokaQuestion = (
  updateEmployeePayLoad: Record<string, unknown>,
  entryDefinition?: EntryDefinition,
) => {
  if (entryDefinition?.hidden) {
    return false;
  }

  if (entryDefinition?.type === FIELD_TYPE.CHECKBOX) {
    return false;
  }

  if (entryDefinition?.condition) {
    const conditionsMet = arePreconditionsMet(
      entryDefinition?.condition.ast,
      updateEmployeePayLoad,
    );
    return conditionsMet;
  }

  return true;
};

export const transformEmployeePayLoad = (
  employeePayload: Record<string, unknown>,
  templateId?: string,
  entryDefinitions?: EntryDefinition[],
): EmployeeRequestPayload => {
  if (entryDefinitions && entryDefinitions.length && templateId) {
    const staticFormStages = getStaticStages();
    const staticQuestionFieldNames = staticFormStages.reduce((acc, stage) => {
      return acc.concat(stage.fields.map((field) => field.name));
    }, [] as string[]);
    const avvokaFieldNames = entryDefinitions.map((field) => field.attribute);
    const updateEmployeePayLoad = {
      ...employeePayload,
      ...(employeePayload.firstName && employeePayload.lastName
        ? {
            fullLegalName: `${employeePayload.firstName} ${employeePayload.lastName}`,
          }
        : {}),
      ...(employeePayload.annualBaseSalaryAmount
        ? { salaryAmount: employeePayload.annualBaseSalaryAmount }
        : {}),
      ...(employeePayload.privateAddress || employeePayload.address
        ? {
            employeeAddress: convertAddressIntoString(
              (employeePayload.privateAddress as Address) ||
                (employeePayload.address as Address),
            ),
          }
        : {}),
    };

    return Object.entries(updateEmployeePayLoad).reduce(
      (acc, [key, value]) => {
        if (avvokaFieldNames.includes(key)) {
          const entryDefinition = entryDefinitions.find(
            (definition) => definition.attribute === key,
          );

          if (
            shouldIncludeAvvokaQuestion(updateEmployeePayLoad, entryDefinition)
          ) {
            acc.contractQuestionnaire.body = {
              ...acc.contractQuestionnaire.body,
              [key]: {
                title: entryDefinition?.question_plain_text || '',
                type: entryDefinition?.type,
                value: value,
                hint: entryDefinition?.hint || '',
                position: entryDefinition?.position,
              },
            };
          }
        }
        if (
          (avvokaFieldNames.includes(key) &&
            !staticQuestionFieldNames.includes(key)) ||
          AVVOKA_AUTOFILL_ATTRIBUTES.includes(key)
        ) {
          /* happens if staticFormStages includes a "field" that unrolls into multiple
            fields, e.g. a field with field.type === 'address' (this gives rise to 5 HTML
            fields, each with a different name than specified in the staticFormStages
            config). This only happens with fields from getStaticStages(), and does not
            happen with Avvoka fields.
          */
          return acc;
        }
        return { ...acc, [key]: value };
      },
      {
        contractQuestionnaire: {
          templateId,
          body: {},
        },
      },
    );
  }
  return employeePayload;
};

export const removeDuplicateQuestionsFromAvvoka = (
  staticFormStages: FormStage[],
  avvokaQuestions: FormQuestionSetConfig,
): FormQuestionSetConfig => {
  const staticQuestionFieldNames = staticFormStages.reduce((acc, stage) => {
    return acc.concat(stage.fields.map((field) => field.name));
  }, [] as string[]);

  return avvokaQuestions.filter(
    (question) =>
      staticQuestionFieldNames.indexOf(question.field.name) < 0 &&
      AVVOKA_AUTOFILL_ATTRIBUTES.indexOf(question.field.name) < 0,
  );
};

const formatValueForInputComponent = (
  entryDefinition: EntryDefinition,
  defaultValue?: unknown,
  editable?: boolean,
) => {
  if (!editable) {
    return entryDefinition.type === 'date'
      ? formatISODate(defaultValue as IsoDate, DateFormats.Date, true)
      : defaultValue;
  }
  if (entryDefinition.type === 'radio_button') {
    return entryDefinition.options.map((option) => ({
      ...option,
      selected: defaultValue
        ? defaultValue === option.value
        : entryDefinition.default_value === option.value,
    }));
  }
  if (
    ['select_list'].includes(entryDefinition.type) &&
    entryDefinition.default_value
  ) {
    return entryDefinition.options.find((option) =>
      defaultValue
        ? defaultValue === option.value
        : option.value === entryDefinition.default_value,
    )?.value;
  }
  if (entryDefinition.type === 'checkbox') {
    return !!defaultValue;
  }

  return defaultValue || entryDefinition.default_value;
};

const getValidationObject = (
  entryDefinition: EntryDefinition,
  updateMode?: boolean,
) => {
  const presenceMode = updateMode ? 'optional' : 'required';
  const CHECKBOX_VALIDATION_MESSAGE = 'Please check the box';
  if (!entryDefinition.required) {
    switch (entryDefinition.type) {
      case 'number':
        return Joi.number().allow('', null).messages({
          'number.base': 'Please enter a valid number',
        });
      case 'text':
        return Joi.string().allow('', null).messages({
          'string.base': 'Please enter a value',
        });
      case 'select_list':
        return Joi.string()
          .allow('', null)
          .valid(...entryDefinition.options.map((option) => option.value))
          .messages({ 'string.base': 'Please select value' });
      case 'date':
        return Joi.date().allow('', null).raw().messages({
          'date.base': 'Please enter a valid date',
        });
      case 'radio_button':
        return Joi.string()
          .valid(...entryDefinition.options.map((option) => option.value))
          .messages({
            'string.valid': 'Please select a valid option',
          });
      case 'checkbox':
        const message = entryDefinition.hint || CHECKBOX_VALIDATION_MESSAGE;
        return Joi.boolean().presence(presenceMode).messages({
          'boolean.base': message,
          'any.invalid': message,
        });
      default:
        return Joi.any();
    }
  }

  switch (entryDefinition.type) {
    case 'number':
      return Joi.number().presence(presenceMode).messages({
        'number.base': 'Please enter a number',
      });
    case 'text':
      return Joi.string().presence(presenceMode).messages({
        'string.base': 'Please enter a value',
      });
    case 'dependent_list':
    case 'select_list':
      return Joi.string()
        .valid(...entryDefinition.options.map((option) => option.value))
        .presence(presenceMode)
        .messages({ 'string.base': 'Please select value' });
    case 'date':
      return Joi.date().presence(presenceMode).raw().messages({
        'date.base': 'Please enter a date',
        'date.empty': 'Please enter a date',
        'date.format': 'Please enter a valid date',
      });
    case 'radio_button':
      return Joi.string()
        .valid(...entryDefinition.options.map((option) => option.value))
        .presence(presenceMode)
        .messages({
          'string.base': 'Please select a value',
          'any.required': 'Please select a value',
        });
    case 'checkbox':
      const message = entryDefinition.hint || CHECKBOX_VALIDATION_MESSAGE;
      return Joi.boolean().invalid(false).presence(presenceMode).messages({
        'boolean.base': message,
        'any.invalid': message,
      });
    default:
      return Joi.any();
  }
};

const convertEntryDefinitionsToQuestions = ({
  entryDefinitions,
  seat,
  companyName,
  employee,
  editable,
}: {
  entryDefinitions?: EntryDefinition[];
  seat?: Seat;
  companyName?: string;
  editable?: boolean;
  employee?: any;
}): FormQuestionSetConfig => {
  const questions: FormQuestionSetConfig = [];

  if (entryDefinitions) {
    const contractQuestions =
      seat?.employeeProfile?.contractQuestionnaire?.body ||
      employee?.contractQuestionnaire?.body ||
      {};

    const filteredEntryDefinitions = entryDefinitions.filter(
      (entryDefinition) =>
        !(employee && entryDefinition.type === FIELD_TYPE.CHECKBOX),
    );
    filteredEntryDefinitions.forEach((entryDefinition) => {
      let preFillValue = contractQuestions[entryDefinition.attribute]?.value;

      if (
        entryDefinition.attribute === 'startDate' &&
        seat &&
        !contractQuestions[entryDefinition.attribute]?.value
      ) {
        preFillValue = formatISODate(
          seat?.desiredStartDate,
          DateFormats.FixedDate,
        );
      }

      if (
        entryDefinition.attribute === 'companyName' &&
        seat &&
        !contractQuestions[entryDefinition.attribute]?.value
      ) {
        preFillValue = companyName;
      }

      questions.push({
        field: {
          name: entryDefinition.attribute,
          label: entryDefinition.question_plain_text,
          type: !editable
            ? FIELD_TYPE.STRING
            : getAvvokaFieldMapping(entryDefinition),
          value: formatValueForInputComponent(
            entryDefinition,
            preFillValue,
            editable,
          ),
          error: null,
          required: entryDefinition.required,
          party_id: entryDefinition.party_id,
          contextualInfo: entryDefinition.hint || '',
          options: entryDefinition.options,
          condition: entryDefinition.condition?.ast,
          readOnly: !editable,
          dependentAttribute: entryDefinition.dependent_attribute,
          listName: entryDefinition.list_name,
          hidden: entryDefinition.hidden,
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: TODO
        validation:
          editable && getValidationObject(entryDefinition, Boolean(employee)),
      });
    });
  }

  return questions;
};

const castFieldToStringType = (field: Field): Field => {
  if (field.type === 'string' && typeof field.value !== 'string') {
    return {
      ...field,
      value: `${field.value}`,
    };
  }
  return field;
};

export const generateEmployeeFields = (
  employee: any,
  entryDefinitions: EntryDefinition[],
  editable: boolean,
  removeDuplicateQuestions = true,
) => {
  let questionnaire: FormQuestionSetConfig = [];
  if (!!entryDefinitions?.length) {
    const staticFormStages = getStaticStages();
    const avvokaQuestions = convertEntryDefinitionsToQuestions({
      entryDefinitions,
      employee,
      editable,
    });
    questionnaire = removeDuplicateQuestions
      ? removeDuplicateQuestionsFromAvvoka(staticFormStages, avvokaQuestions)
      : avvokaQuestions;
  }

  const validationKeys: any = {};
  Object.values(questionnaire).forEach((question) => {
    validationKeys[question.field.name] = question.validation;
  });

  return {
    fields: Object.values(questionnaire).map((question) =>
      castFieldToStringType(question.field),
    ),
    schema: validationKeys,
  };
};

export const CS_PARTY_ID = 'CustomerSuccess';
export const MANAGER_PARTY_ID = 'ClientManager';
export type TemplateParty = typeof CS_PARTY_ID | typeof MANAGER_PARTY_ID;

export const generateContractDetailsStage = (
  seat: Seat,
  userRole: UserRoleType,
  entryDefinitions?: EntryDefinition[],
  companyName?: string,
): FormStage => {
  let questionnaire: FormQuestionSetConfig;

  const partyIdSet = entryDefinitions
    ? new Set(entryDefinitions.map((entry) => entry.party_id))
    : new Set<string>();
  const isMultiParty = partyIdSet.size > 1;

  const getPermissionMapping = (
    userRole: UserRoleType,
  ): (TemplateParty | string)[] => {
    switch (userRole) {
      case UserRoleTypeEnum.ADMIN:
      case UserRoleTypeEnum.CS:
        return [CS_PARTY_ID, MANAGER_PARTY_ID];
      case UserRoleTypeEnum.MANAGER:
        return [MANAGER_PARTY_ID];
      default:
        return [];
    }
  };

  const entryDefinitionsFilteredByParty =
    entryDefinitions &&
    (isMultiParty
      ? entryDefinitions.filter((entry) =>
          getPermissionMapping(userRole).includes(entry.party_id),
        )
      : entryDefinitions);

  if (!entryDefinitions || entryDefinitions.length === 0) {
    questionnaire = thirdStageBaseQuestions(seat);
  } else {
    const staticFormStages = getStaticStages();
    questionnaire = removeDuplicateQuestionsFromAvvoka(
      staticFormStages,
      convertEntryDefinitionsToQuestions({
        entryDefinitions: entryDefinitionsFilteredByParty,
        seat,
        companyName,
        editable: true,
      }),
    );
  }

  const validationKeys: any = {};
  Object.values(questionnaire).forEach((question) => {
    validationKeys[question.field.name] = question.validation;
  });

  return {
    name: `${seat.countryName} contract options`,
    stage: 3,
    notifications: `Use our customisable and localised templates to align your employee contracts while ensuring a legally compliant agreement between Omnipresent and the employee.`,
    fields: Object.values(questionnaire).map((question) => question.field),
    schema: Joi.object().keys(validationKeys),
  };
};
