import type { Icon } from 'react-feather';

import {
  Box,
  Inline,
  omniConfig,
  Typography,
} from '@omnipresentgroup/design-system';

type Props = {
  header: string;
  Icon: Icon;
  label: string;
  countryOfWork: string;
};

const bgCardCss = { background: omniConfig.theme.colors['gray-100'] };

const COUNTRY_OF_WORK_PLACEHOLDER = 'countryOfWork';
export const CardDetails = ({ Icon, header, label, countryOfWork }: Props) => (
  <Box p="16" radius="s" css={bgCardCss}>
    <Inline align="center" gap="8" css={{ color: '$pink-500' }}>
      <Icon size="16" />
      <Typography as="h1" hideParagraphSpacing size="16" weight="medium">
        {header}
      </Typography>
    </Inline>
    <Box>
      {label.includes(COUNTRY_OF_WORK_PLACEHOLDER) ? (
        <Typography as="label" size="16">
          {label.replace(COUNTRY_OF_WORK_PLACEHOLDER, countryOfWork)}
        </Typography>
      ) : (
        <Typography as="label" size="16">
          {label}
        </Typography>
      )}
    </Box>
  </Box>
);
