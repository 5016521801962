import { getUserSelector } from 'app/store/app.selectors';
import { UseQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';
import { useCompanyEmployeesQuery } from 'utils/queries/employees/useCompanyEmployeesQuery';
import {
  hasSuperManagerScope,
  isAdmin,
  isCSAdmin,
  isOPAdmin,
  isPayrollAdmin,
} from 'utils/user';

export const useEmployeesForAdminUsers = (
  companyId: string,
  options?: Pick<UseQueryOptions, 'staleTime' | 'cacheTime' | 'enabled'>,
) => {
  const user = useSelector(getUserSelector);
  const canFetchEmployees =
    isCSAdmin(user) ||
    isOPAdmin(user) ||
    isAdmin(user) ||
    isPayrollAdmin(user) ||
    hasSuperManagerScope(user);
  return useCompanyEmployeesQuery(companyId, {
    enabled: canFetchEmployees,
    ...options,
  });
};
