import { UploadCloud, XCircle } from 'react-feather';
import styled from 'styled-components';

import { omniConfig } from '@omnipresentgroup/design-system';

import { ProgressState } from './ProgressBar';

const containerColorStates = {
  pending: omniConfig.theme.colors['gray-100'],
  error: omniConfig.theme.colors['red-100'],
  success: omniConfig.theme.colors['green-100'],
};

const stateToColor = {
  pending: omniConfig.theme.colors['green-300'],
  success: omniConfig.theme.colors['green-500'],
  error: omniConfig.theme.colors['red-500'],
};

export const UploadDropZone = styled.div<{ hasDragOver: boolean }>`
  border: ${({ hasDragOver, theme: { colors, borderWidths } }) =>
    hasDragOver
      ? `${borderWidths.m} dashed ${colors['pink-500']}`
      : `${borderWidths.s} dashed ${colors['border-subtle']}`};
  padding: ${({ theme: { space } }) => space[16]};
  border-radius: ${({ theme: { radii } }) => radii.m};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ theme: { space } }) => `${space[16]} 0`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  color: ${({ theme: { colors } }) => colors['text-subtle']};
  min-height: 4.5rem;
  box-sizing: border-box;
  ${({ hasDragOver, theme: { colors } }) =>
    hasDragOver && `background-color: ${colors['red-100']};`}
  transition: border 0.1s linear, background-color 0.2s linear;

  p {
    margin: 0;
    vertical-align: middle;
  }
`;

export const UploadLabel = styled.label`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const UploadIcon = styled(UploadCloud)`
  color: ${({ theme: { colors } }) => colors['icon-default']};
  padding-right: ${({ theme: { space } }) => space[16]};
  vertical-align: middle;
`;

export const UploadBlockContainer = styled.div<{ state: ProgressState }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ state }) => containerColorStates[state]};
  border-radius: ${({ theme: { radii } }) => radii.m};
  padding: ${({ theme: { space } }) => space[16]};
  box-sizing: border-box;
  margin: ${({ theme: { space } }) => `${space[16]} 0`};
  min-height: 4.5rem;
`;

export const UploadTitleContainer = styled.div`
  width: 80%;
`;

export const UploadBlockTitle = styled.p`
  margin: 0;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};

  span {
    padding-left: ${({ theme: { space } }) => space[8]};
    color: ${({ theme: { colors } }) => colors['text-subtle']};
    font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
  }
`;

export const CloseIcon = styled(XCircle)`
  color: ${({ theme: { colors } }) => colors['icon-default']};
  transition: color 0.5s;
  cursor: pointer;

  &:hover {
    color: ${({ theme: { colors } }) => colors['gray-400']};
  }
`;

export const CloseButton = styled.button`
  appearance: none;
  background: transparent;
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  padding: 0;
`;

export const Container = styled.div<{ state: ProgressState }>`
  width: 100%;
  height: ${({ theme: { space } }) => space[4]};
  margin: ${({ theme: { space } }) => `${space[4]} 0`};
  background-color: ${({ theme: { colors } }) => colors['gray-200']};
  border-radius: ${({ theme: { radii } }) => radii.s};
  overflow: hidden;
`;

export const Bar = styled.div<{ progress: number; state: ProgressState }>`
  width: ${({ progress }) => progress + '%'};
  background-color: ${({ state }) => stateToColor[state]};
  height: 100%;
  border-radius: ${({ progress, theme: { radii } }) =>
    progress < 100 ? `${radii.s} 0 0 ${radii.s}` : radii.s};
  transition: ${({ state }) => (state === 'error' ? '' : 'width 0.2s linear')};
`;
