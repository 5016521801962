import { getEmployeeRequest } from 'common/api/employees.api';
import { ONE_MINUTE } from 'common/constants';
import { Employee } from 'common/types';
import { useQuery, UseQueryOptions } from 'react-query';
import { useSelectUserRoles } from 'utils/hooks';
import { employeesKeys } from 'utils/queries/employees/keys';
import { getDataFromResponse } from 'utils/react-query-utils';

// FIXME: the type used here is not Employee API Response it's the selectedEmployee type
export const useEmployeeQuery = (
  employeeId = '',
  options?: Pick<UseQueryOptions, 'staleTime'>,
  fetchForLsp = false,
) => {
  const { isAdmin, isManager, isEmployee } = useSelectUserRoles();
  const hasPermissionToGetEmployee =
    isAdmin || isManager || isEmployee || fetchForLsp;

  return useQuery<Employee, Error>(
    employeesKeys.singleEmployee(employeeId),
    () => getEmployeeRequest(employeeId).then(getDataFromResponse),
    {
      enabled: Boolean(employeeId && hasPermissionToGetEmployee),
      staleTime: options?.staleTime || ONE_MINUTE,
    },
  );
};
