import {
  CreateMergeIntegrationInstallPayload,
  DeleteMergeIntegrationPayload,
} from 'common/api/integrations.api';

import { asyncActionKeys } from './redux.constants';

export const createAddHRISIntegrationAction = (
  payload: CreateMergeIntegrationInstallPayload,
) => ({
  type: asyncActionKeys.CREATE_HRIS_INTEGRATION_TO_SELECTED_COMPANY_START,
  payload,
});

export const removeHRISIntegrationAction = (
  payload: DeleteMergeIntegrationPayload,
) => ({
  type: asyncActionKeys.REMOVE_HRIS_INTEGRATION_FROM_SELECTED_COMPANY_START,
  payload,
});
