import { useTrack } from 'utils/mixpanel/tracker';

import { Box, Button, ContentState } from '@omnipresentgroup/design-system';

export const ErrorBoundaryFallback = ({
  resetError,
}: {
  resetError: () => void;
}) => {
  const track = useTrack();

  return (
    <Box bg="primary" p="4" radius="m" border="subtle">
      <ContentState
        id="error-boundary-state"
        variant="error"
        title="Uh-oh, something's gone wrong"
        description="Try reloading the page below, and if this fails get in touch with our support team."
        callToAction={
          <Button
            onClick={() => {
              track('Retry Clicked');
              resetError();
            }}
          >
            Retry
          </Button>
        }
      />
    </Box>
  );
};
