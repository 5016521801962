import { appReducer } from 'app/store/reducers/app.reducer';
import { companiesReducer } from 'common/store/reducers/companies.reducer';
import { documentsReducer } from 'common/store/reducers/documents.reducer';
import { documentStorageReducer } from 'common/store/reducers/documentStorage.reducer';
import { employeesReducer } from 'common/store/reducers/employee.reducer';
import { seatsReducer } from 'common/store/reducers/seats.reducer';
import { combineReducers } from 'redux';

import { managersEmployeeExpensesReducer } from './managersEmployeeExpenses.reducer';

const managerRootReducer = combineReducers({
  appReducer,
  employeesReducer,
  seatsReducer,
  managersEmployeeExpensesReducer,
  documentStorageReducer,
  documentsReducer,
  companiesReducer,
});

export default managerRootReducer;
