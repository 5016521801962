import { getUserSelector } from 'app/store/app.selectors';
import { UseQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';
import { useCompanySeatsQuery } from 'utils/queries/seats/useCompanySeatsQuery';
import { isAdmin, isCSAdmin, isOPAdmin, isPayrollAdmin } from 'utils/user';

export const useCompanySeatsForAdminUsers = (
  companyId: string,
  options?: Pick<UseQueryOptions, 'staleTime' | 'cacheTime' | 'enabled'>,
) => {
  const user = useSelector(getUserSelector);
  const canFetchSeats =
    isCSAdmin(user) || isOPAdmin(user) || isPayrollAdmin(user) || isAdmin(user);
  return useCompanySeatsQuery(companyId, {
    enabled: canFetchSeats,
    ...options,
  });
};
