import { useMemo, useState } from 'react';

import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import StyledTableContainer from 'common/components/molecules/StyledTableContainer/StyledTableContainer.styles';
import { InfiniteScrollTable } from 'common/components/organisms/InfiniteScrollTable/InfiniteScrollTable';
import { PayrollInstance } from 'common/types';
import { usePaginatedWorkbooksInfiniteQuery } from 'utils/queries/payroll/useWorkbooksQuery/useWorkbooksQuery';

import { StyledPageHeader } from '../../../PayrollInstanceDetailPage/PayrollInstancesTab.styles';
import { PayrollInstanceModal } from '../../components/PayrollInstanceModal/PayrollInstanceModal';
import { paginatedTableHeaders } from './PayrollInstancesTabTableHeaders';

export const PAYROLL_INSTANCES_PAGE_SIZE = 100;

export const PayrollInstancesTab = () => {
  const [isModalVisibile, setIsModalVisible] = useState(false);

  const onCTAClicked = () => {
    setIsModalVisible(!isModalVisibile);
  };

  const paginatedPayrollInstancesQuery = usePaginatedWorkbooksInfiniteQuery({
    pageSize: PAYROLL_INSTANCES_PAGE_SIZE,
  });

  const items = useMemo(
    () =>
      paginatedPayrollInstancesQuery.data?.pages.reduce<PayrollInstance[]>(
        (accumulator, current) => {
          current.forEach((item) => {
            accumulator.push(item);
          });
          return accumulator;
        },
        [],
      ),
    [paginatedPayrollInstancesQuery.data?.pages],
  );

  const { isError, error, isFetchingNextPage, isLoading, hasNextPage } =
    paginatedPayrollInstancesQuery;

  if (isError) {
    return <ErrorBanner errorMessage={error?.message} />;
  }
  if (isLoading) {
    return <Loading />;
  }

  const fetchNextPage = () => {
    paginatedPayrollInstancesQuery.fetchNextPage();
  };

  return (
    <StyledTableContainer>
      <StyledPageHeader
        title=""
        testId="create-new-payroll-instance"
        ctaText="New Workbook"
        onCTAClick={onCTAClicked}
      />

      {isModalVisibile && <PayrollInstanceModal clickHandler={onCTAClicked} />}

      <InfiniteScrollTable
        showButton={false}
        CTAText="Add new workbook"
        headers={paginatedTableHeaders}
        itemsToDisplay={items || []}
        testId="payroll-instances-list"
        isInitialLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        onBottomReached={fetchNextPage}
        getId={(item) => item.id}
        showHeader={false}
      />
    </StyledTableContainer>
  );
};
