import PropTypes from 'prop-types';
import { getIsRedactedValue } from 'utils/rbac.utils';

import { StyledSmallCopy } from '../../atoms/Text/Copy.styles';
import DetailsCardRedactedType from '../DetailsCardValue/valuesByType/DetailsCardRedactedType';
import {
  StyledCardContainer,
  StyledFieldContainer,
  StyledFieldsContainer,
  StyledFullWidthFieldContainer,
  StyledHeaderContainer,
} from './Card.styles';

const Card = ({
  title,
  icon,
  item,
  fieldsToShow,
  onClick,
  onColumnItemClick,
}) => (
  <StyledCardContainer
    onClick={() => onClick && onClick(item.id)}
    data-testid="card-container"
    key={item.id}
  >
    <StyledHeaderContainer>
      {title}
      {icon}
    </StyledHeaderContainer>
    <StyledFieldsContainer>
      {fieldsToShow.map((field) => {
        const value = item[field?.fieldOnObject];
        const key = field?.headerTitle;

        if (getIsRedactedValue(value)) {
          return <DetailsCardRedactedType key={key} />;
        }

        return field.customCell && key === 'Due Date' ? (
          <StyledFieldContainer key={key}>
            {field.customCell(item, onColumnItemClick)}
          </StyledFieldContainer>
        ) : field.customCell ? (
          <StyledFullWidthFieldContainer key={key}>
            {field.customCell(item, onColumnItemClick)}
          </StyledFullWidthFieldContainer>
        ) : field.Cell && field.fieldOnObject === 'status' ? (
          <StyledFullWidthFieldContainer key={key}>
            {field.Cell(value)}
          </StyledFullWidthFieldContainer>
        ) : (
          <StyledFieldContainer key={key}>
            <StyledSmallCopy>
              {field.Cell ? field.Cell(value) : value}
            </StyledSmallCopy>
          </StyledFieldContainer>
        );
      })}
    </StyledFieldsContainer>
  </StyledCardContainer>
);
Card.propTypes = {
  title: PropTypes.node,
  icon: PropTypes.node,
  item: PropTypes.oneOfType([PropTypes.object]),
  fieldsToShow: PropTypes.oneOfType([PropTypes.array]),
  onClick: PropTypes.func,
  onColumnItemClick: PropTypes.func,
};

Card.defaultProps = {
  title: null,
  item: {},
  icon: null,
  fieldsToShow: [],
  onClick: null,
  onColumnItemClick: null,
};

export default Card;
