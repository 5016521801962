import { User } from 'common/types';

export const mockLspUser: User = {
  userId: 'd1bd64d2-9141-42b3-b9e5-c607d5b4779e',
  authId: 'auth0|60ed904a08b14500716a9870',
  firstName: 'Tina',
  lastName: 'Testington',
  displayName: 'Tina',
  name: 'Tina Testington',
  email: 'e2e-lsp@omnie2etesting.com',
  temporaryPassword: 'AApRc60E[@l6A4',
  roles: [
    {
      type: 'lsp',
      tenantId: 'kgkaYEY8Omc-',
    },
  ],
  seenWelcomeScreen: false,
  seenPrivacyPolicy: '3434343434',
  createdAt: '2021-07-13T13:08:26.467Z',
  sentInvitationAt: '2021-07-13T13:08:26.467Z',
};
