import styled from 'styled-components';

export const StyledOmniPresentFeesWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  align-self: baseline;
  margin-bottom: ${({ theme: { space } }) => `${space[16]}`};
`;

export const StyledTable = styled.table`
  margin-top: ${({ theme: { space } }) => space[16]};
  border-collapse: collapse;
  width: 100%;
`;

export const StyledTitle = styled.p`
  margin: 0;
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.bold}`};
  color: ${({ theme: { colors } }) => `${colors['text-default']}`};
`;

export const StyledHeader = styled.p`
  margin: 0;
  padding-top: ${({ theme: { space } }) => `${space[16]}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.medium}`};
`;

export const StyledHeaderSuffix = styled(StyledHeader)`
  display: inline;
  color: ${({ theme: { colors } }) => `${colors['text-subtle']}`};
`;

export const StyledHeadsUp = styled.p`
  margin: 0;
  text-align: right;
  padding-bottom: ${({ theme: { space } }) => space[64]};
  padding-top: ${({ theme: { space } }) => `${space[16]}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.regular}`};
`;

export const StyledNotice = styled.p`
  margin: 0;
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.regular}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  color: ${({ theme: { colors } }) => `${colors['text-subtle']}`};
  padding-top: ${({ theme: { space } }) => `${space[4]}`};
`;

export const StyledNoticeSubtitle = styled(StyledNotice)`
  width: 150%;
  padding-top: ${({ theme: { space } }) => `${space[16]}`};
  padding-bottom: ${({ theme: { space } }) => `${space[16]}`};
  padding-left: ${({ theme: { space } }) => `${space[4]}`};
`;

export const StyledTableData = styled.td<{
  variant?: string;
  isSubitem?: boolean;
}>`
  text-align: left;
  width: 45%;
  margin: 0;
  border-bottom: ${({ isSubitem, theme: { borderStyles, colors } }) =>
    isSubitem
      ? borderStyles.none
      : `1px ${borderStyles.solid} ${colors['border-subtle']}`};
  padding: ${({ theme: { space } }) => `${space[4]}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.regular}`};
  color: ${(props) => {
    if (props.variant === 'alternative') {
      return ({ theme: { colors } }) => colors['text-subtle'];
    }
    return props.variant === 'total'
      ? ({ theme: { colors } }) => colors['text-on-color']
      : ({ theme: { colors } }) => colors['text-default'];
  }};
  padding-left: ${(props) => {
    return props.isSubitem
      ? ({ theme: { space } }) => space[32]
      : ({ theme: { space } }) => space[4];
  }};
`;

export const StyledTableAmount = styled(StyledTableData)<{
  hasSecondFee?: boolean;
}>`
  margin-right: 0;
  text-align: right;
  color: ${(props) => {
    if (props.variant === 'alternative') {
      return ({ theme: { colors } }) => colors['text-subtle'];
    }
    return props.variant === 'total'
      ? ({ theme: { colors } }) => colors['text-on-color']
      : ({ theme: { colors } }) => colors['text-default'];
  }};
  width: ${(props) => {
    if (props.variant === 'alternative') {
      return props.hasSecondFee ? '25%' : '10%';
    }
    return '30%';
  }};
`;

export const StyledTableRow = styled.tr`
  &:last-child {
    background-color: ${({ theme: { colors } }) =>
      `${colors['background-inverse']}`};
    color: ${({ theme: { colors } }) => `${colors['text-on-color']}`};
  }
`;

export const HelpCircleIconContainer = styled.div`
  display: inline;
  cursor: pointer;
  margin-left: ${({ theme: { space } }) => space[4]};
  color: ${({ theme: { colors } }) => `${colors['text-subtle']}`};
`;

export const StyledTooltipDescription = styled.p`
  background: none;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
`;
