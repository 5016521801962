import { ComponentProps } from 'react';

import {
  CloseButton,
  CloseIcon,
  UploadBlockContainer,
  UploadBlockTitle,
  UploadTitleContainer,
} from './FileUploadInput.styles';
import { ProgressBar, ProgressState } from './ProgressBar';

export type UploadInProgressProps = {
  fileName: string;
  progress: number;
  state: ProgressState;
  clear: () => void;
} & ComponentProps<typeof UploadBlockContainer>;

export const UploadInProgress = ({
  fileName,
  progress,
  state,
  clear,
  ...props
}: UploadInProgressProps) => {
  let progressText = `${progress}%`;

  switch (state) {
    case 'error':
      progressText = 'Upload failed';
      break;

    case 'success':
      progressText = 'Upload successful!';
      break;
  }

  return (
    <UploadBlockContainer state={state} {...props}>
      <UploadTitleContainer>
        <UploadBlockTitle>
          {fileName} <span>{progressText}</span>
        </UploadBlockTitle>
        <ProgressBar
          progress={progress}
          state={state}
          label={`Uploading ${fileName}`}
        />
      </UploadTitleContainer>
      <CloseButton
        type="button"
        aria-label={`Clear ${fileName}`}
        onClick={clear}
      >
        <CloseIcon />
      </CloseButton>
    </UploadBlockContainer>
  );
};
