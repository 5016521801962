import { useMemo, useState } from 'react';

import { sendGrossNetPayrollComplete } from 'common/api/payrollTaxonomy.api';
import {
  GrossToNetFileUpload,
  LegalEntityOption,
  LSPData,
  PAYROLL_CYCLE_LABELS,
  PayrollCycle,
} from 'common/types';
import { CountryCode } from 'countries-and-timezones';
import { countries } from 'countries-list';
import { isEmpty } from 'lodash';
import { useTrack } from 'utils/mixpanel/tracker';
import { useEmployersQuery } from 'utils/queries/employers/useEmployerQuery';
import { getDataFromResponse } from 'utils/react-query-utils';

import {
  Button,
  Checkbox,
  Dropdown,
  FileDropInput,
  Inline,
  Modal,
  ModalProps,
  RadioButtonGroup,
  Spinner,
  Stack,
} from '@omnipresentgroup/design-system';
import { CheckboxProps } from '@omnipresentgroup/design-system/dist/types/components/Checkbox/Checkbox';

import {
  getDefaultLSPData,
  LSPDataFields,
} from '../LSPDataFields/LSPDataFields';

export type PayReportsUploadModalProps = {
  cancelHandler: () => void;
  onSuccess: (_uploadResults: GrossToNetFileUpload[]) => void;
  onError: () => void;
  payrollPeriod: string;
  modalProps: Omit<ModalProps, 'children'>;
};

const uploadFiles = (
  files: File[],
  {
    payrollPeriod,
    payrollCycle,
    lspData,
    provider,
  }: {
    payrollPeriod: string;
    payrollCycle: string;
    provider: LegalEntityOption;
    lspData?: LSPData;
  },
) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('fileContent', file);
  });
  formData.append('source', 'NEEYAMO');
  formData.append('payrollPeriod', payrollPeriod);
  formData.append('payrollCycle', payrollCycle);
  formData.append('fileType', 'PAY_REGISTER');

  if (lspData) {
    formData.append('lspData', JSON.stringify(lspData));
  }

  formData.append('country', provider.country);
  formData.append('legalEntityId', provider.value);
  formData.append('lspId', provider.lspId);

  return sendGrossNetPayrollComplete({
    formData: formData,
  }).then(getDataFromResponse);
};

const PayReportsUploadModalBody = ({
  selectedProvider,
  setSelectedProvider,
  hasLSPData,
  setHasLSPData,
  lspData,
  setLspData,
  payrollCycle,
  setPayrollCycle,
  setFilesToUpload,
}: {
  selectedProvider: LegalEntityOption | null;
  setSelectedProvider: React.Dispatch<
    React.SetStateAction<LegalEntityOption | null>
  >;
  hasLSPData: CheckboxProps['checked'];
  setHasLSPData: React.Dispatch<React.SetStateAction<CheckboxProps['checked']>>;
  lspData: LSPData;
  setLspData: React.Dispatch<React.SetStateAction<LSPData>>;
  payrollCycle: PayrollCycle;
  setPayrollCycle: React.Dispatch<React.SetStateAction<PayrollCycle>>;
  setFilesToUpload: React.Dispatch<React.SetStateAction<File[]>>;
}) => {
  const track = useTrack();

  const { data: employersList = [], isLoading: isLoadingEmployers } =
    useEmployersQuery();
  const { providerOptions, providersMap } = useMemo(() => {
    const optionsMap = new Map<string, LegalEntityOption>();
    const options = employersList.map((entity) => {
      const label = entity.countryCode
        ? `${entity.name} - ${
            countries?.[entity.countryCode.toUpperCase() as CountryCode]
              ?.name || entity.countryCode
          }`
        : entity.name;

      optionsMap.set(entity.id, {
        value: entity.id,
        label,
        country: entity.countryCode,
        lspId: entity.lspId,
      });

      return { value: entity.id, label };
    });
    return { providerOptions: options, providersMap: optionsMap };
  }, [employersList]);

  const payrollCycleRadioOptions = Object.values(PayrollCycle).map((s) => ({
    id: `radio-btn-payroll-cycle-${s}`,
    label: PAYROLL_CYCLE_LABELS[s],
    checked: payrollCycle === s,
    value: s,
    name: 'radio-btn-default',
  }));

  return (
    <Stack gap="24">
      <Dropdown
        id="provider-dropdown"
        label="Provider"
        onChange={(e) => {
          track('Omnientity type Selected');
          setSelectedProvider(
            (e && providersMap.get(e.value.toString())) || null,
          );
        }}
        value={selectedProvider}
        options={providerOptions}
        isDisabled={false}
        isMulti={false}
        isLoading={isLoadingEmployers}
        isClearable
        isSearchable
        placeholder="Choose a provider"
      />
      <Checkbox
        label="Add optional LSP billing details"
        checked={hasLSPData}
        onCheckedChange={setHasLSPData}
      />
      {hasLSPData && (
        <LSPDataFields lspData={lspData} setLspData={setLspData} />
      )}

      <RadioButtonGroup
        id="radio-btn-group-payroll-cycle"
        label="Pay cycle"
        onChange={(e) => {
          const id = e.target.id;
          const selectedOption = payrollCycleRadioOptions.find(
            (option) => option.id === id,
          );

          if (!selectedOption) {
            return;
          }
          setPayrollCycle(selectedOption.value);
        }}
        options={payrollCycleRadioOptions}
      />
      <FileDropInput
        id="file-drop-input"
        label="Attach files"
        dropzoneOptions={{
          onDrop: (acceptedFiles) => {
            setFilesToUpload(acceptedFiles);
          },
          accept: {
            'text/csv': ['.csv'],
          },
          multiple: true,
        }}
      />
    </Stack>
  );
};

export const PayReportsUploadModal = ({
  cancelHandler,
  onSuccess,
  onError,
  payrollPeriod,
  modalProps,
}: PayReportsUploadModalProps) => {
  const track = useTrack();

  const [selectedProvider, setSelectedProvider] =
    useState<LegalEntityOption | null>(null);

  const [hasLSPData, setHasLSPData] = useState<CheckboxProps['checked']>(false);
  const defaultLSPData = useMemo(() => getDefaultLSPData(), []);
  const [lspData, setLspData] = useState<LSPData>(defaultLSPData);

  const [payrollCycle, setPayrollCycle] = useState<PayrollCycle>(
    PayrollCycle.ONE,
  );

  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

  const [isUploading, setIsUploading] = useState(false);

  return (
    <Modal {...modalProps}>
      <Modal.Header title="Upload Pay Report files" />
      <Modal.Body>
        {isUploading ? (
          <Inline justify="center">
            <Spinner size="40" />
          </Inline>
        ) : (
          <PayReportsUploadModalBody
            {...{
              selectedProvider,
              setSelectedProvider,
              hasLSPData,
              setHasLSPData,
              lspData,
              setLspData,
              payrollCycle,
              setPayrollCycle,
              setFilesToUpload,
            }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Inline justify="space-between">
          <Button
            disabled={isUploading}
            onClick={() => {
              track('Upload Pay Report Files modal cancelled');
              cancelHandler();
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!selectedProvider) {
                return;
              }
              setIsUploading(true);
              uploadFiles(filesToUpload, {
                payrollPeriod,
                payrollCycle,
                provider: selectedProvider,
                ...(hasLSPData ? { lspData } : {}),
              })
                .then((uploadResults) => {
                  setIsUploading(true);
                  track('Upload Pay Report Files successfully uploaded');
                  onSuccess(uploadResults);
                })
                .catch((err) => {
                  console.error(err);
                  setIsUploading(false);
                  onError();
                });
            }}
            variant="primary"
            disabled={
              !selectedProvider ||
              isEmpty(filesToUpload) ||
              (hasLSPData && !Object.values(lspData).every(Boolean))
            }
            loading={isUploading}
          >
            Upload
          </Button>
        </Inline>
      </Modal.Footer>
    </Modal>
  );
};
