import styled from 'styled-components';

export const StyledDropdownInput = styled.div`
  .custom-option-container {
    display: flex;
    align-items: baseline;
  }

  .padded {
    padding: ${({ theme: { space } }) => `${space[8]} 0`};
  }

  .emoji {
    margin-right: ${({ theme: { space } }) => `${space[4]}`};
  }

  svg {
    color: ${({ theme: { colors } }) => colors['icon-default']};
  }

  .custom-option-text {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
    color: ${({ theme: { colors } }) => colors['text-default']};
  }

  [class$='-control'] {
    :hover {
      border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
        `${borderWidths.s} ${borderStyles.solid} ${colors['border-inverse']}`};
    }

    :focus {
      outline: none;
    }
    ${({ error, theme: { colors } }) => {
      if (error && Object.keys(error).length) {
        return `border-color: ${colors['border-negative']}`;
      }

      return ``;
    }}

    padding: ${({ theme: { space } }) => `${space[8]} 0`};
  }
`;

export const StyledDropdownPlaceholderOption = styled.option`
  color: ${({ theme: { colors } }) => colors['text-default']};
`;

export const StyledDropdownOption = styled.option`
  background-color: ${({ theme: { colors } }) =>
    colors['field-background-default']};
`;
