import {
  EMPLOYER_TYPES_LABELS_TRANSFORMED,
  EmployerCreateApiRequest,
} from 'common/types';
import { getCountryCodeByCountryName } from 'utils/countries';

export type EmployerFormData = {
  name: string;
  type: string; // 'Consulting entity',
  country: string; //'Antigua and Barbuda',
  profileUrl?: string;
  contactEmails?: string;
  hasParent: boolean;
  parent?: {
    value: string; //'3aee2ebd-58ec-42cc-b53e-2928a16a5425',
    label: string; //'Ankunding Inc';
  };
  isActive: boolean;
  isOmniEntity: boolean;
  payrollProvider?: {
    value: string; //'0743ad9a-be45-4cd2-be95-f0af542ccbdb',
    label: string; //'Bednar, Mayer and Zemlak'
  };
  lsp?: {
    value: string;
    label: string;
  };
};

export const transformFormDataForAPI = (
  employerFormData: EmployerFormData,
): EmployerCreateApiRequest => {
  const contactEmails = employerFormData.contactEmails?.trim();

  return {
    name: employerFormData.name,
    type: EMPLOYER_TYPES_LABELS_TRANSFORMED[employerFormData.type],
    countryCode: getCountryCodeByCountryName(employerFormData.country) || '',
    parentId: employerFormData.parent?.value,
    isOmniEntity: employerFormData.isOmniEntity,
    payrollProviderId: employerFormData.payrollProvider?.value,
    lspId: employerFormData.lsp?.value,
    profileUrl: employerFormData.profileUrl,
    contactEmails: contactEmails
      ? contactEmails?.split(',').map((email) => email.trim())
      : undefined,
    isActive: employerFormData.isActive,
  };
};
