import { asyncActions, makeActionKeys } from 'utils/redux-utils';

const managerActionKeys = makeActionKeys([
  'CLEAR_SELECTED_EMPLOYEE',
  'CLEAR_EMPLOYEE_CREATED',
  ...asyncActions('GET', 'MANAGERS_EMPLOYEES'),
  ...asyncActions('GET', 'EMPLOYEE'),
  ...asyncActions('UPDATE', 'EMPLOYEE'),
  ...asyncActions('CREATE', 'EMPLOYEE'),
  ...asyncActions('GET', 'EMPLOYEE_EXPENSES'),
  ...asyncActions('GET', 'EMPLOYEE_EXPENSE'),
  ...asyncActions('UPDATE', 'EMPLOYEE_EXPENSE'),
]);

export default managerActionKeys;
