import {
  CompanyTimeOffLogsResponse,
  CreateTimeOffLogsPayload,
  EmployeeTimeOffLogsResponse,
} from 'common/types';

export const mockEmployeeTimeOffLogsResponse: EmployeeTimeOffLogsResponse = {
  taken: [
    {
      id: '111b2b905-e866-60c8-8be2-efe53827a0aa',
      employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
      companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
      employeeNote: 'Trip to Iowa. Miss those cornfields!',
      leaveType: 'op:leave:holiday',
      startDate: '2019-04-11',
      endDate: '2019-04-16',
      createdAt: '2019-03-07T20:05:02',
      createdBy: 'some-id',
      updatedAt: '2019-03-07T20:05:10',
      updatedBy: 'some-id',
      isTaken: true,
    },
  ],
  scheduled: [
    {
      id: '91b2b905-e866-40c8-8be2-efe53827a0aa',
      employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
      companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
      employeeNote: 'Medical appointments during the whole day',
      leaveType: 'op:leave:sick',
      startDate: '2019-05-10',
      endDate: '2019-05-10',
      createdAt: '2019-03-07T20:05:02',
      createdBy: 'some-id',
      updatedAt: '2019-03-07T20:05:10',
      updatedBy: 'some-id',
      isTaken: false,
    },
  ],
};

export const mockCompanyTimeOffLogsResponse: CompanyTimeOffLogsResponse = {
  taken: [
    ...mockEmployeeTimeOffLogsResponse.taken.map((timeOffLog) => ({
      ...timeOffLog,
      employeeName: 'Roy Testerton',
    })),
    {
      id: '101b2b905-e866-70c8-8be2-efe53827a0aa',
      employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8h8t5',
      employeeName: 'Mock Testerman',
      companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
      leaveType: 'op:leave:compassionate',
      startDate: '2019-05-11',
      endDate: '2019-05-13',
      createdAt: '2019-05-07T20:05:02',
      createdBy: 'some-id',
      updatedAt: '2019-05-07T20:05:10',
      updatedBy: 'some-id',
      isTaken: true,
    },
  ],
  scheduled: [
    ...mockEmployeeTimeOffLogsResponse.scheduled.map((timeOffLog) => ({
      ...timeOffLog,
      employeeName: 'Roy Testerton',
    })),
    {
      id: '121b2b905-e866-50c8-8be2-efe53827a0aa',
      employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8h8t5',
      employeeName: 'Mock Testerman',
      companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
      leaveType: 'op:leave:compassionate',
      startDate: '2019-06-10',
      endDate: '2019-07-13',
      createdAt: '2019-04-07T20:05:02',
      createdBy: 'some-id',
      updatedAt: '2019-04-07T20:05:10',
      updatedBy: 'some-id',
      isTaken: false,
    },
  ],
};

export const mockEmployeeTimeOffLogRequest: CreateTimeOffLogsPayload = {
  leaveType: 'op:leave:compassionate',
  startDate: '2019-06-10',
  endDate: '2019-07-13',
  hasConfirmedWithManager: true,
};
