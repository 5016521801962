import { X } from 'react-feather';
import styled from 'styled-components';

import { Button } from '@omnipresentgroup/design-system';

type ModalContainerProps = {
  height?: 'max';
};

export const StyledContainer = styled.div``;

export const StyledModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  flex-direction: column;
  place-content: stretch flex-start;
  height: ${(props) => (props.height === 'max' ? '80vh' : '')};
  margin: ${({ theme: { space } }) => space[16]};
`;

export const StyledTitle = styled.h3`
  margin: ${({ theme: { space } }) => space[4]};
  padding: ${({ theme: { space } }) => space[4]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  color: ${({ theme: { colors } }) => colors['text-default']};
`;

export const StyledDescription = styled.p`
  margin: ${({ theme: { space } }) => space[4]};
  padding: ${({ theme: { space } }) => space[4]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  color: ${({ theme: { colors } }) => colors['gray-300']};
  background: none;
`;

export const StyledCloseButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${({ theme: { space } }) => space[4]};
`;

export const StyledMainContentContainer = styled.div`
  border-top: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  display: block;
  justify-content: space-between;
  margin: ${({ theme: { space } }) => space[16]};
`;

export const StyledInputContainer = styled.div`
  margin-top: ${({ theme: { space } }) => space[16]};
`;

export const StyledButtonContainer = styled.div`
  padding-top: ${({ theme: { space } }) => space[16]};
  margin: ${({ theme: { space } }) => space[4]};
  display: flex;
  justify-content: flex-end;
`;

export const CloseIcon = styled(X)`
  color: ${({ theme: { colors } }) => colors['icon-default']};
  transition: color 0.5s;
  cursor: pointer;

  &:hover {
    color: ${({ theme: { colors } }) => colors['gray-400']};
  }
`;
export const CancelButton = styled(Button)`
  color: ${({ theme: { colors } }) => colors['gray-500']};
  margin-right: ${({ theme: { space } }) => space[32]};
  cursor: pointer;
`;

export const CloseButton = styled.button`
  appearance: none;
  background: transparent;
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  padding: 0;
`;
