import { HTTPStatusCodesEnum } from 'common/constants';
import { rest } from 'msw';

import { financialDocsClient } from '../../api';
import { composeEndpoint } from '../../mocks/utils';
import { PayrollReportsPeriodListApiResponse } from '../types';
import {
  DownloadTransactionBalancesRequest,
  INVOICE_REPORTS_API_PATH,
  PAYROLL_REPORTS_API_PATH,
} from './financialDocuments.api';

type PathParamsPayrollReportsPeriodList = { companyId: string };
type PathParamsDonwloadPayrollReportGrossToNet = {
  companyId: string;
  payrollPeriod: string;
};

export const financialDocumentsHandlers = [
  rest.get<
    undefined,
    PathParamsPayrollReportsPeriodList,
    PayrollReportsPeriodListApiResponse
  >(
    composeEndpoint(
      `${PAYROLL_REPORTS_API_PATH}/companies/:companyId/gross-to-net/`,
      financialDocsClient,
    ),
    (req, res, ctx) => {
      const companyId = req.params.companyId;
      if (!companyId) {
        return res(ctx.status(HTTPStatusCodesEnum.BAD_REQUEST));
      }

      const response = {
        payrollReports: ['202311', '202309', '202308', '202310'],
      };

      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),
  rest.get<undefined, PathParamsDonwloadPayrollReportGrossToNet, string>(
    composeEndpoint(
      `${PAYROLL_REPORTS_API_PATH}/companies/:companyId/gross-to-net/:payrollPeriod`,
      financialDocsClient,
    ),
    (req, res, ctx) => {
      const companyId = req.params.companyId;
      const payrollPeriod = req.params.payrollPeriod;

      if (!companyId || !payrollPeriod) {
        return res(ctx.status(HTTPStatusCodesEnum.BAD_REQUEST));
      }

      const response = 'some,data,in,csv,format';

      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),
  rest.get<
    Omit<DownloadTransactionBalancesRequest, 'companyId'>,
    { companyId: string },
    string
  >(
    composeEndpoint(
      `${INVOICE_REPORTS_API_PATH}/companies/:companyId/transaction-balances/download`,
      financialDocsClient,
    ),
    (req, res, ctx) => {
      const companyId = req.params.companyId;

      if (!companyId) {
        return res(ctx.status(HTTPStatusCodesEnum.BAD_REQUEST));
      }

      const response = 'some,data,in,csv,format';

      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),
  rest.get(
    composeEndpoint(
      `${INVOICE_REPORTS_API_PATH}/companies/:companyId/count/`,
      financialDocsClient,
    ),
    (req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json({ count: 4 }));
    },
  ),
];
