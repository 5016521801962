import { getUserSelector } from 'app/store/app.selectors';
import { getLSPUsers } from 'common/api/localServiceProviders.api';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getDataFromResponse } from 'utils/react-query-utils';
import { isAdmin } from 'utils/user';

import { lspsKeys } from '../keys';

export const useLspUsersQuery = (lspId: string) => {
  const user = useSelector(getUserSelector);
  const hasPermissionToSeeLSPUsers = isAdmin(user);
  const { isLoading, isError, isSuccess, data, error } = useQuery<any, Error>(
    lspsKeys.lspUsers(lspId),
    () => getLSPUsers(lspId).then(getDataFromResponse),
    {
      enabled: hasPermissionToSeeLSPUsers,
    },
  );
  return {
    isError,
    isLoading,
    isSuccess,
    data,
    error,
  };
};
