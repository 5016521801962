import axios from 'axios';
import { HTTPStatusCodesEnum } from 'common/constants';

export function is401UnauthorizedError(error: any): boolean {
  return (
    axios.isAxiosError(error) &&
    error.response?.status === HTTPStatusCodesEnum.UNAUTHORIZED
  );
}

export function is409ConflictError(error: any): boolean {
  return (
    axios.isAxiosError(error) &&
    error.response?.status === HTTPStatusCodesEnum.CONFLICT
  );
}
