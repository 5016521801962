export type BenefitsCountry = {
  id: string;
  countryCode: string;
  showBenefitsSelection: boolean;
  isRequired: boolean;
  isOffered: boolean;
  layoutAdditional: string;
  layoutCost: string;
  layoutDescription: string;
  layoutInfo: string;
  layoutOffered: string[];
  typeFormRef: string;
  createdAt?: Date;
  updatedAt?: Date;
};
export type BenefitsCountryApiResponse = BenefitsCountry;

export type BenefitsCountrySelectionState = 'YES' | 'NO' | 'REMIND_LATER';
export type BenefitsClientPreferences = {
  id: string;
  countryCode: string;
  clientId: string;
  selectionStatus?: BenefitsCountrySelectionState;
};
export type BenefitsClientPreferencesResponse = BenefitsClientPreferences;

export type Benefit = {
  id: string;
  name: string;
  countryCode: string;
  employerId: string;
};

export type BenefitsClientEnrollmentResponse = {
  clientId: string;
  benefits: Array<{
    benefit: Benefit;
    enrollmentAmount: number;
  }>;
};

export type BenefitsClientPreferencesNew = Omit<
  BenefitsClientPreferences,
  'id'
>;

export type BenefitsClientPreferencesUpdate = { id: string } & Partial<
  Omit<BenefitsClientPreferences, 'id'>
>;

export const BENEFIT_COUNTRY_SELECTION_LABEL: Record<
  BenefitsCountrySelectionState,
  string
> = {
  YES: 'Yes',
  NO: 'No',
  REMIND_LATER: 'Remind Later',
};

export type BenefitReportResponse = {
  url: string;
};
