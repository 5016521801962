import { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { getUserAction, setAccessTokenAction } from 'app/store/app.actions';
import { getTransformedUser } from 'app/store/app.selectors';
import { TransformedUser } from 'app/store/app.transformers';
import { useDispatch, useSelector } from 'react-redux';

export const useUser = () => {
  const dispatch = useDispatch();
  const { user: userFromAuth0, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector<any, TransformedUser>(getTransformedUser);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(setAccessTokenAction(accessToken));
        dispatch(getUserAction(userFromAuth0));
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    getAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, user };
};
