import { getTerminationRequests } from 'common/api/terminations.api';
import { ONE_MINUTE } from 'common/constants';
import { TerminationRequestModel } from 'common/types/terminations';
import { useQuery } from 'react-query';

import { getDataFromResponse } from '../../../../react-query-utils';
import { terminationRequestsKeys } from '../keys';

export const useTerminationRequestsQuery = (
  companyId: string,
  { enabled }: { enabled: boolean },
) => {
  return useQuery<TerminationRequestModel[], Error>(
    terminationRequestsKeys.allTerminationRequests(companyId),
    () => getTerminationRequests(companyId).then(getDataFromResponse),
    {
      enabled,
      staleTime: ONE_MINUTE,
    },
  );
};
