import { client } from 'api';
import type { Company, CompanyAvailableProducts } from 'common/types';

export const getCompaniesRequest = () => client.get<Company[]>('/companies');

export const getCompanyRequest = (companyId: string) =>
  client.get<Company>(`/companies/${companyId}`);

export const getCompanyAvailableProductsRequest = (companyId: string) =>
  client.get<CompanyAvailableProducts>(
    `/companies/${companyId}/available-products`,
  );

export const createCompanyRequest = (
  companyToCreate: Record<string, unknown>,
) => client.post('/companies', companyToCreate);

export const updateCompanyRequest = (
  updatedDetails: Record<string, unknown>,
  companyId: string,
) => client.patch(`/companies/${companyId}`, updatedDetails);

export const getCompanyManagersRequest = (companyId: string) =>
  client.get(`/companies/${companyId}/managers`);

export const getCompanyManagerRequest = (managerId: string) =>
  client.get(`/users/${managerId}`);

export const getLSPUserRequest = (userId: string) =>
  client.get(`/users/${userId}`);

export const updateCompanyManagerRequest = (
  managerId: string,
  updatedDetails: Record<string, unknown>,
) => client.patch(`/users/${managerId}`, updatedDetails);

export const createCompanyManagerRequest = (
  companyId: string,
  managerDetails: Record<string, unknown>,
) => client.post(`/companies/${companyId}/managers`, managerDetails);

export const deleteCompanyManagerRequest = (
  companyId: string,
  managerId: string,
) => client.delete(`/companies/${companyId}/managers/${managerId}`);

export const updateManagerRequest = (
  companyId: string,
  managerId: string,
  managerDetails: Record<string, unknown>,
) =>
  client.patch(`/companies/${companyId}/managers/${managerId}`, managerDetails);
