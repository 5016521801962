import { Fragment } from 'react';

import { FIELD_TYPE } from 'common/types';
import PropTypes from 'prop-types';

import EditableInput, {
  getDependentFieldOptions,
} from '../EditableInput/EditableInput';
import { StyledFormPage } from './FormPage.styles';

const addFieldHooks = (field, { after: After, before: Before, props }) => (
  <Fragment key={field.name}>
    {Before && <Before {...props} />}
    {field}
    {After && <After {...props} />}
  </Fragment>
);

export const renderSingleField = ({
  field,
  onFormChanged,
  onFieldFocus,
  country = null,
  small = false,
  pageEditable = true,
  formValues = {},
}) => {
  if (field.type === FIELD_TYPE.COMPONENT) {
    const { component: Component, ...props } = field;
    return addFieldHooks(
      <Component onChange={onFormChanged} key={field.name} {...props} />,
      {
        after: field.after,
        before: field.before,
        props: {
          ...props,
          onChange: onFormChanged,
        },
      },
    );
  }

  return addFieldHooks(
    <EditableInput
      name={field.name}
      label={field.label}
      value={field.value}
      onChange={onFormChanged}
      editable={pageEditable && !field.readOnly}
      disabled={field.disabled}
      key={field.name}
      errorMessage={field.error}
      type={field.type}
      onFocus={onFieldFocus}
      noLabel={field.noLabel}
      contextualInfo={field.contextualInfo}
      country={country}
      required={field.required}
      placeholder={field.placeholder}
      options={
        field.type === FIELD_TYPE.DEPENDENT_DROPDOWN
          ? getDependentFieldOptions(field, formValues)
          : field.options
      }
      params={field.params}
      labelBold={field.labelBold}
      small={small}
    />,
    {
      after: field.after,
      before: field.before,
      props: {
        ...field,
        onChange: onFormChanged,
      },
    },
  );
};

const FormPage = ({
  formFields,
  onFormChanged,
  onFieldFocus,
  country,
  editable: pageEditable,
  formValues = {},
}) => {
  return (
    <StyledFormPage>
      {formFields.map((field) => (
        <Fragment key={field.name}>
          {renderSingleField({
            field,
            onFormChanged,
            onFieldFocus,
            country,
            pageEditable,
            formValues,
          })}
        </Fragment>
      ))}
    </StyledFormPage>
  );
};

FormPage.propTypes = {
  formFields: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onFormChanged: PropTypes.func.isRequired,
  onFieldFocus: PropTypes.func,
  country: PropTypes.string,
  editable: PropTypes.bool,
  formValues: PropTypes.object,
};

FormPage.defaultProps = {
  editable: true,
  onFieldFocus: () => {},
  country: '',
  formValues: {},
};

export default FormPage;
