import { FC, Suspense, SuspenseProps } from 'react';

import { StyledMonthPicker } from 'common/components/molecules/DatePicker/DatePicker.styles';
import { ReactDatePickerProps } from 'react-datepicker';
import { lazyWithRetry } from 'utils/lazyWithRetry';

import { DateFormats } from '@omnipresentgroup/design-system';

const ReactDatePicker = lazyWithRetry(() => import('react-datepicker'));

type DatePickerProps = ReactDatePickerProps & Pick<SuspenseProps, 'fallback'>;

export const DatePicker: FC<DatePickerProps> = ({
  fallback,
  ...rest
}: DatePickerProps) => (
  <Suspense fallback={fallback}>
    <StyledMonthPicker>
      <ReactDatePicker dateFormat={DateFormats.Date} {...rest} />
    </StyledMonthPicker>
  </Suspense>
);
