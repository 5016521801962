export enum StepChangeStatus {
  Checked = 'checked',
  Unchecked = 'unchecked',
}

export const getCompletedStepsDefaultValues = (completedSteps: string[]) => {
  return completedSteps.reduce(
    (transformedStepIds: Record<string, boolean>, stepId: string) => {
      transformedStepIds[stepId] = true;
      return transformedStepIds;
    },
    {},
  );
};

export type StepModification =
  | {
      id: string;
      status: string;
    }
  | undefined;

export const getStepModifications = ({
  completedSteps,
  formValues,
}: {
  completedSteps: string[];
  formValues: { [stepId: string]: boolean };
}) => {
  const reducedModifications: StepModification[] = Object.keys(
    formValues,
  ).reduce((stepModifications: StepModification[], stepId) => {
    const stepStatus = {
      id: stepId,
      status: formValues[stepId]
        ? StepChangeStatus.Checked
        : StepChangeStatus.Unchecked,
    };
    const isExistingCheckedStep =
      stepStatus.status === StepChangeStatus.Checked &&
      completedSteps.includes(stepId);
    const isExistingUncheckedStep =
      stepStatus.status === StepChangeStatus.Unchecked &&
      !completedSteps.includes(stepId);

    if (isExistingCheckedStep || isExistingUncheckedStep) {
      return stepModifications;
    }
    stepModifications.push(stepStatus);

    return stepModifications.filter(
      (stepStatus: StepModification) => stepStatus !== undefined,
    );
  }, []);

  return reducedModifications;
};
