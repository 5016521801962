import { FormCheckbox } from 'common/components/molecules/FormCheckbox';
import PropTypes from 'prop-types';

const CheckboxInput = ({
  onChange,
  name,
  options,
  className,
  ...restProps
}) => {
  const onOptionSelected = (selectedValue) => {
    onChange(
      name,
      options.map((option) => {
        return option.value === selectedValue
          ? { ...option, selected: true }
          : { ...option, selected: false };
      }),
    );
  };

  return (
    <div
      className={`stack ${className}`}
      data-testid={`${name}-checkbox-input`}
      id={name}
    >
      {options.map((option, i) => (
        <FormCheckbox
          key={option.label}
          name={name}
          id={`checkbox-${name}-${i}`}
          onChange={() => onOptionSelected(option.value)}
          checked={Boolean(option.selected)}
          label={option.label}
          {...restProps}
        />
      ))}
    </div>
  );
};

CheckboxInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

CheckboxInput.defaultProps = {
  className: '',
};

export default CheckboxInput;
