import { useEffect, useRef } from 'react';

import { ALLOWED_FILE_TYPES } from 'appConstants';

import { useUploadMutation } from './useUploadMutation';

type UseDocumentUploadParams = {
  testId?: string;
};

export const useDocumentUpload = (params?: UseDocumentUploadParams) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    isLoading,
    isError,
    isSuccess,
    error,
    reset,
    handleFileUpload: saveDocument,
    data,
  } = useUploadMutation();

  const handleFileUpload = (e: Event) => {
    saveDocument(e.target as HTMLInputElement);
  };

  const createFileInputElement = (name: string) => {
    const inputElement = document.createElement<'input'>('input');
    inputElement.setAttribute('type', 'file');
    inputElement.setAttribute('name', name);
    inputElement.setAttribute('accept', ALLOWED_FILE_TYPES.join('|'));

    params?.testId && inputElement.setAttribute('data-testid', params.testId);

    inputElement.style.display = 'none';
    inputElement.onchange = handleFileUpload;

    document.body.appendChild(inputElement);
    inputRef.current = inputElement;
    return inputElement;
  };

  const uploadDocument = (type: string) => {
    createFileInputElement(type);
    inputRef.current?.click();
  };

  useEffect(() => {
    if (inputRef.current && (isError || isSuccess)) {
      document.body.removeChild(inputRef.current);
      inputRef.current.remove();
    }
  }, [isError, isSuccess]);

  return { uploadDocument, isLoading, isError, isSuccess, error, reset, data };
};
