import { ContractorState } from 'common/types/contractor';
import { toSentenceCase } from 'utils/string-utils';

import { Tag, TagIntent } from '@omnipresentgroup/design-system';

type ContractorStatusProps = {
  status: ContractorState;
};

const getIntentByStatus = (status: ContractorState): TagIntent => {
  switch (status) {
    case ContractorState.ACTIVE:
      return 'success';
    case ContractorState.ONBOARDING:
    case ContractorState.KYC_PENDING:
      return 'warning';
    case ContractorState.KYC_FAILED:
    case ContractorState.DUPLICATE_EMAIL:
      return 'error';
    default:
      return 'default';
  }
};

export const ContractorStatus = ({ status }: ContractorStatusProps) => {
  return (
    <Tag id={status} intent={getIntentByStatus(status)}>
      {toSentenceCase(status)}
    </Tag>
  );
};
