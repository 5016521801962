import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledFormHeader = styled.div`
  width: 100%;
  padding: ${({ theme: { space } }) => `${space[4]} ${space[8]}`};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    padding: 0;
  }
`;

export const StyledBackLink = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    width: 100%;
  }
`;
