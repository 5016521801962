import styled from 'styled-components';

export const TypeformPageContent = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0;
`;

export const TypeFormWrapper = styled.div`
  height: calc(100vh - 125px);
  max-height: 600px;

  .tf-v1-widget {
    width: 100%;
    height: 100%;

    iframe {
      width: 100vh !important;
      height: 100% !important;
      border: ${({ theme: { borderStyles } }) => borderStyles.none};
    }
  }
`;
