import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { getLoadingSelector } from 'app/store/app.selectors';
import { departmentTypeOptions } from 'appConstants';
import Loading from 'common/components/atoms/Loading/Loading';
import FormSidebar from 'common/components/molecules/FormSidebar/FormSidebar';
import { StyledEditAccountDetailsPage } from 'common/pages/EditAccountDetailsPage/EditAccountDetailsPage.styles';
import { EditDetailsPage } from 'common/pages/EditDetailsPage/EditDetailsPage';
import SideBar from 'common/pages/EditEmployeePage/SideBar';
import {
  getCompanyManagerSelector,
  getSelectedCompanySelector,
} from 'common/store/selectors/companies.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { capitaliseFirstLetter } from 'utils/string-utils';
import { managerSchema } from 'utils/validators/manager.schema';
import { validateInputs } from 'utils/validators/validator';

import {
  getCompanyManagerAction,
  updateCompanyManagerAction,
} from '../../store/actions/companies.actions';

export const EditManagerPage = () => {
  const manager = useSelector(getCompanyManagerSelector);
  const company = useSelector(getSelectedCompanySelector);
  const loading = useSelector(getLoadingSelector);
  const dispatch = useDispatch();

  const {
    companyId: pathCompanyId,
    managerId: pathManagerId,
    lspId,
    userId,
  } = useParams();
  const companyId = pathCompanyId || lspId;
  const managerId = pathManagerId || userId;
  const selectedManager =
    company?.managers?.find((manager) => manager.id === managerId) || null;
  const sections = [
    {
      name: 'manager-details',
      title: '',
      details: [
        {
          editable: true,
          required: true,
          label: 'Job Title',
          name: 'jobTitle',
          showFor: 'admin',
          type: 'string',
          value: selectedManager?.jobTitle || '-',
        },
        {
          editable: true,
          required: true,
          label: 'Department',
          name: 'department',
          showFor: 'admin',
          type: 'dropdown',
          options: departmentTypeOptions,
          placeholder: 'Choose a department type...',
          value: selectedManager?.department || '-',
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(getCompanyManagerAction({ companyId, managerId }));
  }, [companyId, managerId, dispatch]);

  return (
    <PageContent data-testid="edit-manager-page">
      {loading || !manager?.userDetails ? (
        <Loading />
      ) : (
        <StyledEditAccountDetailsPage>
          <EditDetailsPage
            title="Edit manager details"
            onSaveEdits={(updatedDetails) =>
              dispatch(
                updateCompanyManagerAction({
                  companyId,
                  managerId,
                  updatedDetails,
                }),
              )
            }
            validator={(fields) => validateInputs(fields, managerSchema)}
            sections={sections}
            sideBar={
              <FormSidebar
                child={
                  <SideBar
                    jobTitle={capitaliseFirstLetter(manager.role.type)}
                    name={manager.name}
                  />
                }
              />
            }
          />
        </StyledEditAccountDetailsPage>
      )}
    </PageContent>
  );
};
