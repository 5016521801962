import styled from 'styled-components';

export const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-content: stretch space-between;
  height: 'max';
  padding: ${({ theme: { space } }) => `${space[32]}`};
`;

export const Step = styled.div`
  background: rgb(255 67 137 / 10%);
  border-radius: ${({ theme: { radii } }) => `${radii.full}`};
  height: ${({ theme: { space } }) => `${space[32]}`};
  min-width: ${({ theme: { space } }) => `${space[32]}`};
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${({ theme: { colors } }) => `${colors['pink-500']}`};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
`;
