import { useEffect, useState } from 'react';

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event: BeforeUnloadEvent) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

export const usePageExit = (val: boolean) => {
  const [showExitPrompt, setShowExitPrompt] = useState<boolean>(val);

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  return { showExitPrompt, setShowExitPrompt };
};
