import { EmployerApiResponse } from 'common/types';

export const mockEmployersResponse: Array<EmployerApiResponse> = [
  {
    id: 'LE-21-001',
    name: 'Emerald inc.',
    countryCode: 'NL',
    type: 'employer-of-record',
    profileUrl: 'htt://test-le.com',
    lspId: 'LSP-21-001',
    isActive: true,
    contactEmails: 'foo@bar.com',
    isOmniEntity: true,
    payrollProvider: {
      id: 'PP-0023-01',
      lspId: 'LSP-0005',
      countryCode: 'NL',
      name: 'Green LLC',
      hasHistory: true,
    },
  },
  {
    id: 'LE-21-002',
    name: 'Parakar Spain',
    countryCode: 'ES',
    type: 'consulting-entity',
    profileUrl: 'htt://test.com',
    parent: {
      id: 'LE-21-001',
      name: 'Emerald inc.',
      countryCode: 'NL',
      type: 'employer-of-record',
      profileUrl: 'htt://test-le.com',
      lspId: 'LSP-0011',
      isActive: true,
      contactEmails: 'foo@bar.com',
      isOmniEntity: true,
    },
    lspId: 'LSP-0012',
    isActive: true,
    contactEmails: 'foo@bar.com',
    isOmniEntity: false,
  },
  {
    id: 'LE-21-003',
    name: 'Jonson inc.',
    countryCode: 'DE',
    type: 'consulting-entity',
    profileUrl: 'htt://jonson.com',
    lspId: 'LSP-0013',
    isActive: false,
    contactEmails: 'foo@bar.com',
    isOmniEntity: false,
  },
  {
    id: 'LE-21-004',
    name: 'Emerald Payroll inc.',
    countryCode: 'DE',
    type: 'employer-of-record',
    profileUrl: 'htt://test-le.com',
    lspId: 'LSP-0014',
    isActive: true,
    contactEmails: 'foo@bar.com',
    isOmniEntity: true,
  },
];

export const mockActiveEmployersResponse: Array<EmployerApiResponse> = [
  {
    id: 'LE-21-001',
    name: 'Emerald inc.',
    countryCode: 'NL',
    type: 'employer-of-record',
    profileUrl: 'htt://test-le.com',
    lspId: 'LSP-0011',
    isActive: true,
    contactEmails: 'foo@bar.com',
    isOmniEntity: true,
    payrollProvider: {
      id: 'PP-0023-01',
      lspId: 'LSP-0005',
      countryCode: 'NL',
      name: 'Green LLC',
      hasHistory: true,
    },
  },
  {
    id: 'LE-21-002',
    name: 'Parakar Spain',
    countryCode: 'ES',
    type: 'consulting-entity',
    profileUrl: 'htt://test.com',
    parent: {
      id: 'LE-21-001',
      name: 'Emerald inc.',
      countryCode: 'NL',
      type: 'employer-of-record',
      profileUrl: 'htt://test-le.com',
      lspId: 'LSP-0011',
      isActive: true,
      contactEmails: 'foo@bar.com',
      isOmniEntity: true,
    },
    lspId: 'LSP-0012',
    isActive: true,
    contactEmails: 'foo@bar.com',
    isOmniEntity: false,
  },
  {
    id: 'LE-21-003',
    name: 'Jonson inc.',
    countryCode: 'DE',
    type: 'consulting-entity',
    profileUrl: 'htt://jonson.com',
    lspId: 'LSP-0013',
    isActive: true,
    contactEmails: 'foo@bar.com',
    isOmniEntity: false,
  },
  {
    id: 'LE-21-004',
    name: 'Emerald Payroll inc.',
    countryCode: 'DE',
    type: 'employer-of-record',
    profileUrl: 'htt://test-le.com',
    lspId: 'LSP-0011',
    isActive: true,
    contactEmails: 'foo@bar.com',
    isOmniEntity: true,
  },
];
