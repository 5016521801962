import { client } from '../../api';
import {
  ContractStatus,
  Employee,
  PaginatedEmployeesApiResponse,
  SimplifiedEmployeesApiResponse,
} from '../types';

//TODO: replace any with missing types
export const getAllEmployeesRequest = () =>
  client.get<SimplifiedEmployeesApiResponse>(`/employees?simplified=true`);

export const getAllEmployeesPaginatedRequest = (params: {
  nextPageKey?: string;
  size?: number;
  employeeName?: string;
  employeeId?: string;
  country?: string;
  personalEmail?: string;
  companyId?: string;
  lspId?: string;
  payrollId?: string;
  externalId?: string;
  onboardingStatus?: string;
}) =>
  client.get<PaginatedEmployeesApiResponse>('/employees/paginated', { params });

export const getManagersEmployeesRequest = (managerId: string) =>
  client.get<SimplifiedEmployeesApiResponse>(
    `/employees/manager/${managerId}?simplified=true`,
  );

export const getEmployeeRequest = (employeeId: string) =>
  client.get(`/employees/${employeeId}`);

export const updateEmployeeRequest = (employeeId: string, infoToUpdate: any) =>
  client.patch(`/employees/${employeeId}`, infoToUpdate);

export const updateCompensation = (employeeId: string, infoToUpdate: any) =>
  client.patch(`/employees/${employeeId}/compensation`, infoToUpdate);

export const addEmployeeRequest = (employeeToAddBody: any, managerId: string) =>
  client.post('/employees', {
    ...employeeToAddBody,
    managerId,
  });

export const updateTypeformEmployeeOnboardingRequest = (
  employeeId: string,
  typeformCredentials: any,
) =>
  client.put(
    `/typeform/employee-onboarding/${employeeId}`,
    typeformCredentials,
  );

export const getEmployeeDataExportRequest = (employeeId: string) =>
  client.post(`/employees/${employeeId}/data-export`, {});

export const getEmployeeNetsuiteIDRequest = (employeeId: string) =>
  client.post(`/employees/${employeeId}/netsuite/external-employee`, {});

export const getEmployeeOnboardingProgressRequest = (employeeId: string) =>
  client.get(`/onboarding-progress/employee/${employeeId}`);

export const updateEmployeeOnboardingProgressRequest = (
  employeeId: string,
  infoToUpdate: any,
) => client.patch(`/onboarding-progress/employee/${employeeId}`, infoToUpdate);

export const archiveEmployeeById = (employeeId: string) =>
  client.post<Employee>(`/employees/${employeeId}/archive`);

export const archiveEmployeeContractRequest = (
  employeeId: string,
  contractId: string,
) =>
  client.post<Employee>(
    `/employees/${employeeId}/contracts/${contractId}/archive`,
  );

export const updateEmployeeContractStatus = (
  employeeId: string,
  contractId: string,
  status: ContractStatus,
) =>
  client.patch<Employee>(`/employees/${employeeId}/contracts/${contractId}`, {
    status,
  });

export const getDepartmentsRequest = () =>
  client.get(`/compensation/reference-data/employee-departments`);

export const getEmployeesUploadStatus = (bulkUploadId: string) =>
  client.get(`/employees/bulk-upload/${bulkUploadId}`);

export const downloadBulkCompensationTemplate = (
  companyId: string,
  employeesIds: string[],
  compensationType: string,
) =>
  client.post('/employees/compensation/bulk-template', {
    companyId,
    employeesIds,
    compensationType,
  });

export const validateBulkSalaryChange = (
  companyId: string,
  type: string,
  url: string,
) =>
  client.post('/employees/compensation/bulk-validation', {
    companyId,
    type,
    url,
  });

export const submitBulkSalaryChange = (
  companyId: string,
  type: string,
  url: string,
) =>
  client.post('/employees/compensation/bulk', {
    companyId,
    type,
    url,
  });
