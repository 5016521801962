import { UserRoleType } from 'common/types';

import { getIntercomAppId } from './helper';

type IntercomUpdateCompanyObject = {
  id: string;
  name: string;
  company_externalID?: string;
};

type PermissionsTier = 'superManager' | 'regularManager';
type IntercomActions =
  | 'shutdown'
  | 'hide'
  | 'show'
  | 'showMessages'
  | 'showNewMessage';

export type IntercomUpdateObject = {
  name?: string;
  email?: string;
  user_id?: string;
  user_type: UserRoleType;
  employee_status?: string;
  country_of_work?: string;
  employee_type?: string;
  op_created_at_timestamp?: string;
  employee_start_date?: string;
  employee_externalID?: string;
  lsp_id?: string;
  op_profile?: string;
  company?: IntercomUpdateCompanyObject;
  permissionsTier?: PermissionsTier;
};

export const intercomBoot = () => {
  if (!!window.Intercom && !!getIntercomAppId()) {
    window.Intercom('boot', {
      app_id: getIntercomAppId(),
      //Website visitor so may not have any user related info
    });
  }
};

export const intercomUpdate = (intercomUpdateObject: IntercomUpdateObject) => {
  if (!!window.Intercom) {
    window.Intercom('update', intercomUpdateObject);
  }
};

export const performIntercomAction = (action: IntercomActions) => {
  if (!!window.Intercom) {
    window.Intercom(action);
  }
};

export const intercomShutdown = () => performIntercomAction('shutdown');
export const intercomHide = () => performIntercomAction('hide');
export const intercomShow = () => performIntercomAction('show');
export const intercomShowMessages = () => performIntercomAction('showMessages');
export const intercomShowNewMessage = () =>
  performIntercomAction('showNewMessage');
