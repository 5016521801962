import Button from 'common/components/atoms/Button';
import styled from 'styled-components';

export const StyledStatementUploadContainer = styled.div`
  .button-container {
    display: flex;
    margin-top: ${({ theme: { space } }) => space[64]};
    flex-direction: row;
  }

  .button-container > div {
    margin-right: 150px;
  }
`;

export const StyledModalHeader = styled.h3`
  margin: 0;
  color: ${({ theme: { colors } }) => colors['text-default']};
`;

export const StyledBoldSeatName = styled.strong`
  text-transform: capitalize;
`;

export const StyledRightAlignButton = styled(Button)`
  margin-left: auto;
`;
