import { queryClient } from 'app/queryClient';
import { updateUserScopeSuperManager } from 'common/api/userAssociations.api';
import { useMutation } from 'react-query';
import { companiesKeys } from 'utils/queries/companies/keys';

import { managerPermissionsKeys } from '../keys';

export const useUpdateUserScopeSuperManagerMutation = ({
  companyId,
  userId,
}: {
  companyId: string;
  userId: string;
}) => {
  return useMutation(
    (hasSuperManagerScope: boolean) =>
      updateUserScopeSuperManager(userId, hasSuperManagerScope),
    {
      onSuccess: () => {
        queryClient.refetchQueries(
          managerPermissionsKeys.getEmployees(companyId, userId),
        );
        queryClient.refetchQueries(companiesKeys.managers(companyId));
      },
    },
  );
};
