import { call, put, takeLatest } from 'redux-saga/effects';
import { createActionObject } from 'utils/redux-utils';
import { sortAlphabetically } from 'utils/sort.util';

import { getCompanySeatsRequest } from '../../api/seats.api';
import { asyncActionKeys } from '../actions/redux.constants';
import { transformSeatApiResponseToSeat } from '../transformers/seats.transformer';

export function* getSeats({ payload: companyId }: { payload: string }): any {
  try {
    const { data: seats } = yield call(getCompanySeatsRequest, companyId);

    return yield put(
      createActionObject(
        asyncActionKeys.GET_SEATS_END,
        sortAlphabetically('name', seats.map(transformSeatApiResponseToSeat)),
      ),
    );
  } catch (error) {
    return yield put(
      createActionObject(asyncActionKeys.GET_SEATS_ERROR, error),
    );
  }
}

export default [takeLatest(asyncActionKeys.GET_SEATS_START, getSeats)];
