import { PageHeader } from 'common/components';
import styled from 'styled-components';

export const StyledFinancePage = styled.div`
  margin-top: ${({ theme: { space } }) => space[64]};
  width: 100%;
`;

export const StyledPageHeader = styled(PageHeader)`
  padding-bottom: ${({ theme: { space } }) => space[32]};
`;
