import { client } from '../../api';

type EmployerCostsParameters = {
  currencyCode: string;
  countryCode: string;
  annualBaseSalary: number;
  hasFxRate: boolean;
};

export const getEmployerCostsRequest = ({
  currencyCode,
  countryCode,
  annualBaseSalary,
  hasFxRate,
}: EmployerCostsParameters) => {
  const fxPricing = hasFxRate ? 'standard' : 'none';
  return client.get(
    `calculator/employer-costs?currencyCode=${currencyCode}&countryCode=${countryCode}&annualSalary=${annualBaseSalary}&managementPricing=none&fxPricing=${fxPricing}`,
  );
};
