import { client } from '../../../api';

export const getEmployeeExpensesRequest = (employeeId: string) =>
  client.get(`expenses/employee/${employeeId}`);

export const getEmployeeExpenseRequest = (expenseId: string) =>
  client.get(`expenses/${expenseId}`);

export const updateEmployeeExpenseRequest = (
  expenseFieldsToUpdate: unknown,
  expenseId: string,
) => client.patch(`expenses/${expenseId}`, expenseFieldsToUpdate);

export const deleteEmployeeExpenseRequest = (expenseId: string) =>
  client.delete(`expenses/${expenseId}`);

export const addEmployeeExpenseRequest = (expenseToCreate: unknown) =>
  client.post(`expenses`, expenseToCreate);
