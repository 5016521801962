import { Step } from '../OnboardingProgressTracker/types';
import { StyledStepDrawer } from './OnboardingProgressDrawer.style';
import { OnboardingProgressDrawerStepIcon } from './OnboardingProgressDrawerStepIcon';

export type Props = {
  stageId: number;
  isOpen: boolean;
  step: Step;
  completedSteps: string[];
};

export const OnboardingProgressDrawerStep = ({
  step,
  isOpen,
  stageId,
  completedSteps,
}: Props) => {
  const { id, name } = step;
  const isCompleted = completedSteps?.includes(id);

  return (
    <StyledStepDrawer
      isOpen={isOpen}
      isCompleted={isCompleted}
      key={id}
      stageId={stageId}
    >
      <OnboardingProgressDrawerStepIcon
        isCompleted={isCompleted}
        stepId={id}
        label={name}
      />
    </StyledStepDrawer>
  );
};
