import styled from 'styled-components';

import { convertToRem } from '@omnipresentgroup/design-system';

export const Wrapper = styled.div`
  position: relative;
`;

export const FilterButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: ${({ theme: { radii } }) => radii.s};
  background-color: ${({ theme: { colors } }) => colors['white-base']};
  height: ${({ theme: { space } }) => space[16]};
  padding: ${({ theme: { space } }) => `${space[8]} ${space[32]}`};
`;

export const FilterIconContainer = styled.div`
  margin-right: ${({ theme: { space } }) => space[8]};
  height: ${({ theme: { space } }) => space[16]};
  width: ${({ theme: { space } }) => space[16]};

  > svg {
    height: ${({ theme: { space } }) => space[16]};
    width: ${({ theme: { space } }) => space[16]};
  }
`;

export const FilterText = styled.div`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
`;

export const FilterPopupWrapper = styled.div`
  position: absolute;
  top: 52px;
  right: 0;
  z-index: 1;
  width: 317px;
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
  box-shadow: ${({ theme: { shadows } }) => shadows.m};
  border-radius: ${({ theme: { radii } }) => radii.m};
  padding: ${({ theme: { space } }) => `${space[32]} ${space[16]}`};
`;

export const FilterPopupTitle = styled.div`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  padding-bottom: ${({ theme: { space } }) => space[16]};
  border-bottom: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
`;

export const FilterContainer = styled.div`
  padding-top: ${({ theme: { space } }) => space[16]};
`;

export const FilterHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FilterTitle = styled.div<{ clearLink?: boolean }>`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};

  ${({ clearLink, theme: { colors } }) => {
    if (clearLink) {
      return `
        color: ${colors['red-500']};
        cursor: pointer;
      `;
    }
  }}
`;

export const FilterElement = styled.div`
  padding: ${({ theme: { space } }) => space[16]} 0;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme: { space } }) => space[16]};

  > * {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
    flex-basis: auto;
    margin: 0;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const FlexWrapper = styled.div<{
  spacing?: number;
  direction?: 'row' | 'column';
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: center;
  margin: ${({ spacing, direction }) => {
    const margin = spacing ? convertToRem(spacing / 2) : 0;
    if (direction === 'column') {
      return `-${margin} 0`;
    }
    return `0 -${margin}`;
  }};

  > * {
    margin: ${({ spacing, direction }) => {
      const margin = spacing ? convertToRem(spacing / 2) : 0;
      if (direction === 'row') {
        return `${margin} 0`;
      }
      return `0 ${margin}`;
    }};
  }
`;
