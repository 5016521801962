import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { CheckCircle } from 'react-feather';

import {
  Center,
  omniConfig,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

export interface SuccessModalProps {
  text: string;
}

export const SuccessModal = ({ text }: SuccessModalProps) => (
  <ModalWrapper fixedWidth="400" fixedHeight="180">
    <Center axis="both">
      <Stack
        data-testid="success-modal"
        align="center"
        gap="16"
        css={{ textAlign: 'center' }}
      >
        <CheckCircle
          size="48"
          color={omniConfig.theme.colors['icon-positive']}
        />
        <Typography as="label" size="16" weight="medium">
          {text}
        </Typography>
      </Stack>
    </Center>
  </ModalWrapper>
);
