import { FileInState } from 'common/components/molecules/FileUploadInput/FileUploadInput';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';

type FileUploadInputState = FileInState & {
  numberOfEmployees: number;
};

export type State = {
  [index: string]: FileUploadInputState;
};

type UpdateAction = {
  name: 'UpdateAction';
  payload: {
    id: string;
    values: Partial<FileUploadInputState>;
  };
};

type CreateAction = {
  name: 'CreateAction';
  payload: Omit<FileUploadInputState, 'id'>;
};

type ClearAction = {
  name: 'ClearAction';
  payload: FileUploadInputState['id'];
};

type Action = CreateAction | UpdateAction | ClearAction;

export const emptyFile: Omit<FileUploadInputState, 'id'> = {
  fileName: '',
  progress: 0,
  state: 'pending',
  numberOfEmployees: 0,
};

export const bulkUploadReducer = (state: State, action: Action) => {
  switch (action.name) {
    case 'CreateAction':
      const id = uuidv4();
      return {
        ...state,
        [id]: {
          ...action.payload,
          id,
        },
      };

    case 'UpdateAction':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.values,
        },
      };

    case 'ClearAction':
      const copy = { ...state };
      delete copy[action.payload];
      if (isEmpty(copy)) {
        const id = uuidv4();
        return {
          [id]: {
            id,
            ...emptyFile,
          },
        };
      }
      return copy;

    default:
      return state;
  }
};
