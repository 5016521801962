import { useCallback, useEffect, useRef } from 'react';

export enum MimeType {
  CSV = 'text/csv',
  PDF = 'application/pdf',
}

export const useFileDownloadDialog = () => {
  const downloadAnchorElement = useRef(document.createElement('a'));

  useEffect(() => {
    downloadAnchorElement.current.setAttribute('hidden', 'true');
  }, []);

  const showFileDownloadDialog = useCallback(
    (fileType: MimeType, fileName: string, data: any) => {
      const blob = new Blob([data], { type: fileType });

      downloadAnchorElement.current.setAttribute(
        'href',
        URL.createObjectURL(blob),
      );
      downloadAnchorElement.current.setAttribute(
        'download',
        `${fileName}.${fileType.split('/')[1]}`,
      );

      downloadAnchorElement.current.click();

      downloadAnchorElement.current.removeAttribute('href');
      downloadAnchorElement.current.removeAttribute('download');
    },
    [],
  );

  return { showFileDownloadDialog };
};
