import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { PageHeader } from 'common/components';
import { Tabs } from 'common/components/organisms/Tabs/Tabs';

import { StyledFinancePage } from './FinancePage.styles';
import { FinanceMonitoringTab } from './Tabs';

const tabs = [
  {
    id: 1,
    title: 'Invoice Monitoring',
    href: '#monitoring',
    Content: FinanceMonitoringTab,
  },
];

export const FinancePage = () => {
  const location = useLocation();
  const [currentHash, setCurrentHash] = useState(location.hash || null);

  useEffect(() => {
    setCurrentHash(location.hash);
  }, [location]);

  return (
    <PageContent>
      <PageHeader title="Finance: Invoice Monitoring" testId="finance-header" />
      <StyledFinancePage className="bigStack" data-testid="finance-page">
        <Tabs
          tabsToDisplay={tabs}
          currentTab={
            currentHash
              ? tabs.filter((tab) => tab.href === currentHash)[0]
              : tabs[0]
          }
        />
      </StyledFinancePage>
    </PageContent>
  );
};
