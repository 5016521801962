import {
  mockSeatsBenefitsResponse,
  mockSeatsResponse,
} from '__mocks__/seats.mock';
import { HTTPStatusCodesEnum } from 'common/constants';
import { CompanyUpsellFees, Seat, SeatApiRequest } from 'common/types';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { PathParams, rest } from 'msw';

import { SEATS_BASE_API_ENDPOINT } from './seats.api';

const employeeSeat = mockSeatsResponse[0];
const employeeSeatUk = mockSeatsBenefitsResponse[0];

export const seatsHandlers = [
  rest.get<Seat[]>(
    composeEndpoint(`${SEATS_BASE_API_ENDPOINT}`),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockSeatsResponse),
      );
    },
  ),
  rest.post<Seat>(
    composeEndpoint(`${SEATS_BASE_API_ENDPOINT}`),
    (_req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(employeeSeat));
    },
  ),
  rest.get<Seat>(
    composeEndpoint(`${SEATS_BASE_API_ENDPOINT}/:seatId`),
    (_req, res, ctx) => {
      if (_req.params.seatId === 'seat-from-kp') {
        return res(ctx.json({ ...employeeSeat, countryName: 'North Korea' }));
      }
      if (_req.params.seatId === 'seat-from-fr') {
        return res(ctx.json({ ...employeeSeat, countryName: 'France' }));
      }
      if (_req.params.seatId === 'seat-from-uk') {
        return res(ctx.json(employeeSeatUk));
      }
      if (_req.params.seatId === 'bad-seat-request') {
        return res(ctx.status(400));
      }
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(employeeSeat));
    },
  ),
  rest.patch<SeatApiRequest, PathParams, Seat>(
    composeEndpoint(`${SEATS_BASE_API_ENDPOINT}/:seatId`),
    (req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          ...employeeSeat,
          ...req.body,
        }),
      );
    },
  ),
  rest.get<CompanyUpsellFees>(
    composeEndpoint(`${SEATS_BASE_API_ENDPOINT}/fees/company/:companyId`),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          enableAutomatedProposals: true,
          managementFee: [{ currency: 'CAD', amount: 650 }],
          setupFee: [
            { currency: 'GBP', amount: 100 },
            { currency: 'CAD', amount: 850 },
          ],
          depositType: 'Advanced Payment Deposit (APD)',
          advancedPaymentDepositPercent: 10,
          zeroDepositInsuranceFee: [{ amount: 80, currency: 'GBP' }],
        }),
      );
    },
  ),
  rest.post<Seat>(
    composeEndpoint(`${SEATS_BASE_API_ENDPOINT}/:seatid/convert`),
    (_req, res, ctx) => {
      return res(ctx.status(201), ctx.json(employeeSeat));
    },
  ),
  rest.patch<SeatApiRequest, PathParams, Seat>(
    composeEndpoint(`${SEATS_BASE_API_ENDPOINT}/:seatId/employee-profile`),
    (req, res, ctx) => res(ctx.status(HTTPStatusCodesEnum.OK)),
  ),
  rest.get<Seat[]>(
    composeEndpoint(`${SEATS_BASE_API_ENDPOINT}/company/:companyId`),
    (_req, res, ctx) => res(ctx.json([employeeSeat])),
  ),
  rest.get<SeatApiRequest, { url: string }>(
    composeEndpoint(`${SEATS_BASE_API_ENDPOINT}/:seatId/sow-document`),
    (req, res, ctx) => res(ctx.json({ url: 'https://sow.doc.url/1' })),
  ),
  rest.post<SeatApiRequest, PathParams, Seat>(
    composeEndpoint(`${SEATS_BASE_API_ENDPOINT}/:seatId/archive`),
    (req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          ...employeeSeat,
          ...req.body,
        }),
      );
    },
  ),
];
