import { LSPApiResponse, LSPTypesEnum } from 'common/types';

export const mockLSPsResponse: Array<LSPApiResponse> = [
  {
    id: 'LSP-21-001',
    name: 'Emerald inc.',
    countries: ['Brazil', 'United Kingdom'],
    type: LSPTypesEnum.EMPLOYER_OF_RECORD,
    profileUrl: 'htt://test',
    noOfEmployees: 0,
    contactEmails: ['foo@bar.com'],
  },
  {
    id: 'PP-21-001',
    name: 'Emerald Payroll inc.',
    countries: [
      'Australia',
      'Cambodia',
      'China',
      'Denmark',
      'Hong Kong',
      'India',
      'Indonesia',
      'Japan',
      'Malaysia',
      'Mongolia',
      'New Zealand',
      'Singapore',
      'Taiwan',
      'Thailand',
      'Vietnam',
    ],
    type: LSPTypesEnum.PAYROLL_PROVIDER,
    noOfEmployees: 10,
    inactive: true,
  },
  {
    id: 'LSP-0012',
    name: 'Parakar',
    type: LSPTypesEnum.EMPLOYER_OF_RECORD,
    noOfEmployees: 11,
  },
  {
    id: 'LSP-0013',
    name: 'Employ Global',
    type: LSPTypesEnum.EMPLOYER_OF_RECORD,
    noOfEmployees: 12,
  },
  {
    id: 'LSP-0014',
    name: 'Hire Global',
    type: LSPTypesEnum.EMPLOYER_OF_RECORD,
    noOfEmployees: 13,
  },
  {
    id: 'LSP-0015',
    name: 'OP',
    type: LSPTypesEnum.EMPLOYER_OF_RECORD,
    noOfEmployees: 14,
    inactive: true,
  },
];
