import { BenefitsClientEnrollmentResponse } from 'common/types/benefits';

export const mockBenefitsClientEnrollmentResponse = (
  clientId: string,
): BenefitsClientEnrollmentResponse => {
  return {
    clientId,
    benefits: [
      {
        benefit: {
          id: '111',
          name: 'Silver',
          countryCode: 'SE',
          employerId: '444',
        },
        enrollmentAmount: 5,
      },
      {
        benefit: {
          id: '111',
          employerId: '222',
          countryCode: 'CA',
          name: 'Manulife',
        },
        enrollmentAmount: 15,
      },
      {
        benefit: {
          id: '111',
          employerId: '222',
          countryCode: 'DE',
          name: 'Gold',
        },
        enrollmentAmount: 3,
      },
    ],
  };
};
