import { AxiosError } from 'axios';
import { approvePayReport } from 'common/api/payrollTaxonomy.api';
import {
  ApprovePayReportPayload,
  CopyApprovedTaxonomicDataApiResponse,
} from 'common/types';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useApprovePayReportMutation = () => {
  return useMutation<
    CopyApprovedTaxonomicDataApiResponse,
    AxiosError<CopyApprovedTaxonomicDataApiResponse>,
    ApprovePayReportPayload
  >(({ dataFrameId }: ApprovePayReportPayload) =>
    approvePayReport({ dataFrameId }).then(getDataFromResponse),
  );
};
