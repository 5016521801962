import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { HTTPStatusCodesEnum } from 'common/constants';
import { mapCountryNameToCountryCode } from 'utils/mapCountryNameToCountryCode';
import { useAvvokaTemplateQuery, useSingleSeatQuery } from 'utils/queries';

import { BenefitsSelectionProvider } from './Benefits/BenefitsSelectionContext';
import SeatToEmployeeConversionForm from './SeatToEmployeeConversionForm';

type PageParams = Record<'seatId' | 'companyId', string>;

export const SeatToEmployeeConversion = () => {
  const { seatId, companyId } = useParams<PageParams>();
  const seatQuery = useSingleSeatQuery(seatId, {
    queryOptions: { staleTime: Infinity },
  });

  const countryCode = useMemo(
    () => mapCountryNameToCountryCode(seatQuery.data?.countryName) || '',
    [seatQuery.data?.countryName],
  );

  const templateQuery = useAvvokaTemplateQuery({
    enabled: seatQuery.isSuccess,
    countryCode,
    companyId,
  });

  const isAvvokaUnavailable =
    templateQuery.error?.response?.status === HTTPStatusCodesEnum.BAD_GATEWAY;

  if (seatQuery.isLoading || templateQuery.isLoading) {
    return <Loading />;
  }

  const isError =
    seatQuery.isError || (templateQuery.isError && !isAvvokaUnavailable);

  if (isError) {
    const errorMessage =
      seatQuery.error?.message || templateQuery.error?.message;
    return <ErrorBanner errorMessage={errorMessage} />;
  }

  if (seatQuery.isSuccess && (templateQuery.isSuccess || isAvvokaUnavailable)) {
    return (
      <BenefitsSelectionProvider
        companyId={companyId}
        countryCode={countryCode}
        seat={seatQuery.data}
      >
        <SeatToEmployeeConversionForm
          seat={seatQuery.data}
          contractTemplate={templateQuery.data}
          companyId={companyId}
          countryCode={countryCode}
        />
      </BenefitsSelectionProvider>
    );
  }
  return null;
};
