import { useEffect } from 'react';

import { SUPPORT_EMAIL } from 'appConstants';

import { notifyError } from '@omnipresentgroup/design-system';

type Props = {
  onErrorClear?: () => void;
  errorMessage?: string;
};

const ErrorBanner = ({ errorMessage, onErrorClear }: Props) => {
  useEffect(() => {
    notifyError(
      {
        title:
          errorMessage ||
          'Something went wrong, please contact our support team',
      },
      { onClose: onErrorClear },
    );
  }, []);

  return <></>;
};

export default ErrorBanner;
