import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { User } from '@sentry/react';
import { ReactComponent as ArrowRight } from 'assets/icon/ArrowRight.svg';
import { deleteLSPManagerRequest } from 'common/api/localServiceProviders.api';
import Loading from 'common/components/atoms/Loading/Loading';
import { ConfirmationModal } from 'common/components/molecules/ConfirmationModal';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { transformLspUsers } from 'common/store/transformers/lsps.transformer';
import { LspUser, LSPUserActionEnum } from 'common/types';
import { StyledShowingText } from 'omniplatform/manager/pages/DocumentListPage/DocumentListPage.styles';
import {
  StyledEmployeeCardTitle,
  StyledManagerEmployeesTableContainer,
} from 'omniplatform/manager/pages/ManagerEmployeePage/ManagerEmployeePage.styles';
import { LSP_LIST_PAGE } from 'paths';
import { useMutation } from 'react-query';
import { useLspUsersQuery } from 'utils/queries';

import { Viewport } from '@omnipresentgroup/design-system';

import { StyledBoldCopy } from '../../atoms/Text';
import Card from '../../molecules/Card/Card';
import MobileCardListHeader from '../../molecules/MobileCardListHeader/MobileCardListHeader';
import TableWithTitle from '../TableWithTitle/TableWithTitle';
import { LSPSchema } from './deleteLSPManagerModalSchema';
import { userTableHeaders } from './LspUsersTableHeaders';

type Props = {
  lspId: string;
  displayLspPageHeader?: boolean;
  hasVerificationField: boolean;
};
const emptyStateContent = {
  title: 'No Managers',
  description: 'This Service Provider has no managers',
};

export const modals = {
  DELETE_LSP_MANAGER_CONFIRM: 'delete-lsp-manager-confirm',
  DELETE_LSP_MANAGER_SUCCESS: 'delete-lsp-manager-success',
} as const;

type ModalKeys = keyof typeof modals;
type ModalValues = (typeof modals)[ModalKeys];

export const LspUsersTable = ({ lspId, hasVerificationField }: Props) => {
  const history = useHistory();
  const lspUserQuery = useLspUsersQuery(lspId);
  const [visibleModal, setVisibleModal] = useState<ModalValues | void>();
  const [managerToRemove, setManagerToRemove] = useState<User | void>();

  const deleteLSPManagerMutation = useMutation((userId: string) =>
    deleteLSPManagerRequest(lspId, userId),
  );
  const approveDeleteConfirmationModalProps = {
    title: 'Delete manager profile?',
    message: `Are you sure you want to delete this manager:
    
      ${managerToRemove?.firstName} ${managerToRemove?.lastName}
      
      This user will no longer able to access the OmniPlatform.`,
    hasVerificationField: hasVerificationField,
    verificationField: 'deleteManagerVerificationField',
    label: 'To delete, type "DELETE" below',
    id: 'deleteManagerVerificationField',
    schema: LSPSchema,
    cancelLabel: 'CANCEL',
    cancelHandler: () => setVisibleModal(),
    confirmLabel: 'DELETE',
    confirmHandler: () => {
      if (managerToRemove) {
        deleteLSPManagerMutation.mutate(managerToRemove.userId, {
          onSuccess: () => setVisibleModal(modals.DELETE_LSP_MANAGER_SUCCESS),
          onError: (err) => console.error(err),
        });
      }
    },
    loading: deleteLSPManagerMutation.isLoading,
  };

  const approveDeleteSuccessModalProps = {
    title: 'Manager deleted',
    message: 'The manager has been successfully deleted.',
    cancelHandler: () => {
      setManagerToRemove();
      setVisibleModal();
    },
    cancelLabel: 'CLOSE',
  };

  const handleSeatClick = (column: string, manager: User) => {
    if (column === LSPUserActionEnum.DELETE_MANAGER) {
      setManagerToRemove(manager);
      setVisibleModal(modals.DELETE_LSP_MANAGER_CONFIRM);
    }

    if (column === 'name') {
      history.push(`${LSP_LIST_PAGE}/${lspId}/users/${manager.id}`);
    }
  };

  if (lspUserQuery.isLoading) {
    return <Loading />;
  }

  if (lspUserQuery.isError) {
    return <ErrorBanner errorMessage={lspUserQuery?.error?.message} />;
  }

  if (lspUserQuery.isSuccess) {
    const users: LspUser[] = transformLspUsers(lspUserQuery.data.lspUsers);

    return (
      <div data-testid="lsp-users-component">
        <StyledManagerEmployeesTableContainer className="smallStack">
          <Viewport devices={['laptop', 'desktop', 'highRes']}>
            <>
              <StyledShowingText>
                Showing 1 - {users.length} of {users.length}
              </StyledShowingText>
              <TableWithTitle
                testId="lsp-users"
                itemsToDisplay={users}
                onColumnItemClick={handleSeatClick}
                headers={userTableHeaders}
                getId={(user: { userId: any }) => user.userId}
                emptyState={emptyStateContent}
              />
            </>
          </Viewport>
          <Viewport devices={['phone', 'tablet']}>
            <>
              <MobileCardListHeader total={users.length} />
              {!!users?.length &&
                users.map((user) => (
                  <Card
                    key={user.id}
                    fieldsToShow={userTableHeaders}
                    onColumnItemClick={handleSeatClick}
                    item={user}
                    icon={<ArrowRight />}
                    title={
                      <StyledEmployeeCardTitle>
                        <StyledBoldCopy>{user.name}</StyledBoldCopy>
                      </StyledEmployeeCardTitle>
                    }
                  />
                ))}
            </>
          </Viewport>
          {visibleModal === modals.DELETE_LSP_MANAGER_CONFIRM && (
            <ConfirmationModal {...approveDeleteConfirmationModalProps} />
          )}
          {visibleModal === modals.DELETE_LSP_MANAGER_SUCCESS && (
            <ConfirmationModal {...approveDeleteSuccessModalProps} />
          )}
        </StyledManagerEmployeesTableContainer>
      </div>
    );
  }
  return <></>;
};
