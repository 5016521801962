/* eslint-disable no-magic-numbers */
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { getTransformedUser } from 'app/store/app.selectors';
import { Loading } from 'common/components';
import { ClientIdTypeEnum } from 'common/types/contractor';
import { HireTypeEnum } from 'common/types/hire';
import { useSelector } from 'react-redux';
import { useTrack } from 'utils/mixpanel/tracker';
import { useCompanyAvailableProductsQuery } from 'utils/queries';
import {
  useClientQuery,
  useClientTermsMutation,
} from 'utils/queries/contractor';
import { hasSuperManagerScope, isAdmin, isManager } from 'utils/user';

import {
  Box,
  Button,
  Card,
  Inline,
  Link,
  List,
  notifyError,
  Spinner,
  Stack,
  Tag,
  Typography,
  useViewport,
  Viewport,
} from '@omnipresentgroup/design-system';
import type { CheckboxProps } from '@omnipresentgroup/design-system/dist/types/components/Checkbox/Checkbox';

import { HireCard } from './components/HireCard/HireCard';
import { TermsCheckbox } from './components/TermsCheckbox/TermsCheckbox';
import { HIRE_TYPE_DETAILS_MAP } from './hireTypeDetailsConfig';

type PageParams = {
  companyId: string;
};

export const PAGE_TITLE = 'Add hire';
export const SELECTION_TITLE = 'Choose your type of hire';

const HireCardLayout = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  return (
    <>
      <Viewport devices={['phone']}>
        <Stack>{children}</Stack>
      </Viewport>
      <Viewport devices={['highRes', 'desktop', 'laptop', 'tablet']}>
        <Inline>{children}</Inline>
      </Viewport>
    </>
  );
};

const NotAvailableTag = () => (
  <Tag intent="default" id="add-hire-not-available-tag">
    Not available
  </Tag>
);

function useSearchQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const AddHirePage = () => {
  const history = useHistory();
  const viewport = useViewport();
  const query = useSearchQuery();
  const track = useTrack();

  const { companyId } = useParams<PageParams>();

  const user = useSelector(getTransformedUser);

  const isRegularManager = isManager(user) && !hasSuperManagerScope(user);

  const country = query.get('country') || undefined;

  const querySelection =
    Object.values(HireTypeEnum)[
      Object.keys(HireTypeEnum).indexOf(query.get('type') || '')
    ];

  const [selectedCard, setSelectedCard] = useState<HireTypeEnum | null>(
    querySelection,
  );

  const {
    data: clientData,
    isError: isErrorClient,
    isLoading: isLoadingClient,
  } = useClientQuery(companyId, ClientIdTypeEnum.OP_ID, {
    enabled: selectedCard === HireTypeEnum.contractor,
  });

  const {
    mutate: updateClient,
    isLoading: isUpdatingClient,
    isSuccess: isSuccessUpdateClient,
  } = useClientTermsMutation(clientData?.id || '');

  const {
    data: companyAvailableProducts,
    isLoading: isLoadingCompanyAvailableProducts,
    isError: isErrorCompanyAvailableProducts,
  } = useCompanyAvailableProductsQuery(companyId);

  const isContractorsAllowed = useMemo(
    () => !isAdmin(user) && !!companyAvailableProducts?.Contractor,
    [companyAvailableProducts?.Contractor, user],
  );

  const isEORAllowed = useMemo(
    () => !!companyAvailableProducts?.EOR,
    [companyAvailableProducts?.EOR],
  );

  const isContractorOnly = useMemo(
    () =>
      !!companyAvailableProducts?.Contractor && !companyAvailableProducts?.EOR,
    [companyAvailableProducts?.Contractor, companyAvailableProducts?.EOR],
  );

  const isEOROnly = useMemo(
    () =>
      !!companyAvailableProducts?.EOR && !companyAvailableProducts?.Contractor,
    [companyAvailableProducts?.Contractor, companyAvailableProducts?.EOR],
  );

  const [termsAgreededCheck, setTermsAgreededCheck] =
    useState<CheckboxProps['checked']>(false);

  const goToNextPage = useCallback(() => {
    selectedCard &&
      (window.location.href = HIRE_TYPE_DETAILS_MAP[selectedCard].nextPagePath(
        companyId,
        country,
      ));
  }, [companyId, country, selectedCard]);

  // Move directly to employee flow for regular managers
  useEffect(() => {
    isRegularManager &&
      history.push(
        HIRE_TYPE_DETAILS_MAP.employee.nextPagePath(companyId, country),
      );
  }, [companyId, country, history, isRegularManager]);

  // Update selection based on url and company data
  useEffect(() => {
    if (companyAvailableProducts) {
      switch (querySelection) {
        case HireTypeEnum.contractor:
          if (isContractorsAllowed === false) {
            setSelectedCard(null);
          }
          break;
        case HireTypeEnum.employee:
          if (isEORAllowed === false) {
            setSelectedCard(null);
          }
          break;
        default:
          // no valid query selection -> pre-select based on company data
          isEOROnly && setSelectedCard(HireTypeEnum.employee);
          isContractorOnly && setSelectedCard(HireTypeEnum.contractor);
          break;
      }
    }
  }, [
    isContractorOnly,
    isContractorsAllowed,
    isEORAllowed,
    isEOROnly,
    querySelection,
    companyAvailableProducts,
  ]);

  useEffect(() => {
    setTermsAgreededCheck(clientData?.opTermsAccepted);
  }, [clientData?.opTermsAccepted]);

  // Move to the next page if client update was successfull
  useEffect(() => {
    isSuccessUpdateClient && goToNextPage();
  }, [goToNextPage, isSuccessUpdateClient]);

  // Handle card selection
  const handleClick = (value: HireTypeEnum) => {
    const params = new URLSearchParams({
      type: value.toLowerCase(),
    });
    if (country) {
      params.append('country', country);
    }
    history.replace({
      search: params.toString(),
    });
    track('Add Hire Type Clicked', {
      clientId: companyId,
      userId: user?.userId,
      hireType: value,
    });
    setSelectedCard(value);
  };

  // Handle error response
  useEffect(() => {
    isErrorClient &&
      notifyError({
        title: 'Error',
        description:
          'Something went wrong while getting client information. Please contact support.',
      });
  }, [isErrorClient]);

  useEffect(() => {
    isErrorCompanyAvailableProducts &&
      notifyError({
        title: 'Error',
        description:
          'Something went wrong while getting company available products. Please contact support.',
      });
  }, [isErrorCompanyAvailableProducts]);

  const HireCardTag = ({ type }: { type: HireTypeEnum }) => {
    switch (type) {
      case HireTypeEnum.contractor:
        if (!isContractorsAllowed) {
          return <NotAvailableTag />;
        }
        return (
          <Tag intent="success" id="add-hire-new-tag">
            New
          </Tag>
        );
      case HireTypeEnum.employee:
        return !isEORAllowed ? <NotAvailableTag /> : <></>;
      default:
        return <></>;
    }
  };

  if (isRegularManager || isLoadingCompanyAvailableProducts) {
    return <Loading />;
  }

  return (
    <PageContent>
      <Stack gap="32">
        <Inline
          justify="space-between"
          overflow="auto"
          align="center"
          minH={40}
        >
          <Typography as="h2" hideParagraphSpacing size="24" weight="medium">
            {PAGE_TITLE}
          </Typography>
        </Inline>
        <Inline justify="center">
          <Card maxW={600} p={viewport.isPhone ? '24' : '32'} w="stretch">
            <Stack gap="32">
              <Typography
                as="h3"
                size="20"
                weight="medium"
                hideParagraphSpacing
              >
                {SELECTION_TITLE}
              </Typography>
              <HireCardLayout>
                <HireCard
                  type={HireTypeEnum.employee}
                  selected={selectedCard === HireTypeEnum.employee}
                  onClick={() => handleClick(HireTypeEnum.employee)}
                  tag={<HireCardTag type={HireTypeEnum.employee} />}
                  disabled={!isEORAllowed}
                />
                <HireCard
                  type={HireTypeEnum.contractor}
                  selected={selectedCard === HireTypeEnum.contractor}
                  onClick={() => handleClick(HireTypeEnum.contractor)}
                  disabled={!isContractorsAllowed}
                  tag={<HireCardTag type={HireTypeEnum.contractor} />}
                />
              </HireCardLayout>
              {selectedCard && (
                <>
                  <Stack gap="8">
                    <Inline w="stretch" justify="space-between" align="center">
                      <Typography
                        as="h4"
                        size="16"
                        weight="medium"
                        hideParagraphSpacing
                      >
                        {HIRE_TYPE_DETAILS_MAP[selectedCard].title}
                      </Typography>
                      {HIRE_TYPE_DETAILS_MAP[selectedCard].moreInfo && (
                        <Box
                          onClick={() => {
                            track('Add Hire Type More Info Clicked', {
                              clientId: companyId,
                              userId: user?.userId,
                              hireType: selectedCard,
                            });
                          }}
                        >
                          <Link
                            icon="ExternalLink"
                            size="14"
                            to={
                              HIRE_TYPE_DETAILS_MAP[selectedCard].moreInfo
                                ?.link || ''
                            }
                            target="_blank"
                          >
                            {HIRE_TYPE_DETAILS_MAP[selectedCard].moreInfo
                              ?.linkText || ''}
                          </Link>
                        </Box>
                      )}
                    </Inline>
                    <List
                      css={{ listStyleType: 'disc' }}
                      pl="24"
                      gap="4"
                      items={HIRE_TYPE_DETAILS_MAP[selectedCard].items}
                      keyExtractor="id"
                      render={(item) => (
                        <Typography as="span" size="14">
                          {item.name}
                        </Typography>
                      )}
                    />
                  </Stack>
                  {selectedCard === HireTypeEnum.contractor && (
                    <>
                      <Stack gap="16">
                        {isLoadingClient ? (
                          <Inline justify="center">
                            <Spinner size="40" />
                          </Inline>
                        ) : (
                          !clientData?.opTermsAccepted && (
                            <TermsCheckbox
                              id="terms-checkbox"
                              checked={termsAgreededCheck}
                              onCheckedChange={setTermsAgreededCheck}
                            />
                          )
                        )}
                      </Stack>
                    </>
                  )}
                  <Inline justify="flex-end">
                    <Button
                      onClick={() => {
                        // mixpanel tracker
                        track('Add Hire Type Next Clicked', {
                          clientId: companyId,
                          userId: user?.userId,
                          hireType: selectedCard,
                        });
                        clientData?.opTermsAccepted !== termsAgreededCheck
                          ? // update terms and conditions if not already
                            updateClient()
                          : // else navigate directly
                            goToNextPage();
                      }}
                      disabled={
                        selectedCard === HireTypeEnum.contractor &&
                        !termsAgreededCheck
                      }
                      loading={isUpdatingClient}
                    >
                      Next
                    </Button>
                  </Inline>
                </>
              )}
            </Stack>
          </Card>
        </Inline>
      </Stack>
    </PageContent>
  );
};
