import { getUser } from 'common/api/users.api';
import { TEN_MINUTES } from 'common/constants';
import { User } from 'common/types';
import { useQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { useSelectUserRoles } from '../../../hooks';
import { usersKeys } from '../keys';

export const useUserQuery = (userId: string) => {
  const { isAdmin } = useSelectUserRoles();

  return useQuery<User, Error>(
    usersKeys.singleUser(userId),
    () => getUser(userId).then(getDataFromResponse),
    {
      enabled: isAdmin,
      staleTime: TEN_MINUTES,
    },
  );
};
