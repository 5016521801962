import styled from 'styled-components';

export const StyledEmployeeDetailsPage = styled.div`
  margin-bottom: ${({ theme: { space } }) => `${space[32]}`};
  display: flex;
  flex-direction: column;
  place-self: center flex-start;
  justify-content: center;
  width: 100%;
  overflow: visible;
`;

export const StyledProfilePicture = styled.img`
  width: ${({ theme: { space } }) => space[64]};
  height: ${({ theme: { space } }) => space[64]};
  border-radius: ${({ theme: { radii } }) => radii.full};
  margin-right: ${({ theme: { space } }) => space[8]};
`;
