import { forwardRef, ReactNode } from 'react';

import { onWheelPreventChangeForNumberInput } from 'utils/input.helper';

import { StyledContextualInfo } from '../../atoms/ContextualInfo';
import { StyledErrorMessage } from '../../atoms/ErrorMessage';
import { StyledInput } from '../../atoms/Input';
import { StyledLabel } from '../../atoms/Label';
import * as S from './FormInput.styles';

export type FormInputType = 'date' | 'number' | 'text';

export type FormInputProps = {
  as: FormInputType;
  contextualInfo?: ReactNode;
  error?: string;
  id: string;
  label: string;
  required?: boolean;
} & Partial<HTMLInputElement>;

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    { as, contextualInfo, error, id, label, required, ...rest }: FormInputProps,
    forwardedRef,
  ) => (
    <S.FieldContainer error={error}>
      <StyledLabel htmlFor={id} required={required}>
        {label}
      </StyledLabel>
      {contextualInfo && (
        <StyledContextualInfo id={`${id}-context`}>
          {contextualInfo}
        </StyledContextualInfo>
      )}
      <StyledInput
        aria-describedby={contextualInfo ? `${id}-context` : ''}
        aria-label={label}
        error={error}
        id={id}
        ref={forwardedRef}
        type={as}
        onWheel={onWheelPreventChangeForNumberInput}
        {...rest}
      />
      {error && (
        <StyledErrorMessage aria-label={`${id}-alert`} role="alert">
          {error}
        </StyledErrorMessage>
      )}
    </S.FieldContainer>
  ),
);

FormInput.displayName = 'FormInput';
