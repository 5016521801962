import styled from 'styled-components';

import { convertToRem } from '@omnipresentgroup/design-system';

export const FilterDropDownContainer = styled.div`
  position: relative;
`;

export const FilterButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: right;
  margin: ${({ theme: { space } }) => space[8]} 0;
  cursor: pointer;
  border-radius: ${({ theme: { radii } }) => radii.s};
  background-color: ${({ theme: { colors } }) => colors['white-base']};
  height: ${({ theme: { space } }) => space[16]};
  width: ${convertToRem(114)};
  padding: ${({ theme: { space } }) => `${space[8]} ${space[32]}`};
`;

export const FilterIconContainer = styled.div`
  margin-right: ${({ theme: { space } }) => space[8]};
  height: ${({ theme: { space } }) => space[16]};
  width: ${({ theme: { space } }) => space[16]};

  > svg {
    height: ${({ theme: { space } }) => space[16]};
    width: ${({ theme: { space } }) => space[16]};
  }
`;

export const FilterPopupContainer = styled.div`
  position: absolute;
  top: 59px;
  right: 0;
  z-index: 1;
  width: ${convertToRem(398)};
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
  box-shadow: ${({ theme: { shadows } }) => shadows.m};
  border-radius: ${({ theme: { radii } }) => radii.m};
  padding: ${({ theme: { space } }) => `${space[32]} ${space[16]}`};
`;

export const FilterContainerTitle = styled.span`
  color: ${({ theme: { colors } }) => colors['gray-300']};
  display: inline-block;
`;

export const FilterTitle = styled.div<{ clearLink?: boolean }>`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};

  ${({ clearLink, theme: { colors } }) => {
    if (clearLink) {
      return `
        color: ${colors['red-500']};
        cursor: pointer;
      `;
    }
  }}
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme: { space } }) => space[16]};

  > * {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
    flex-basis: auto;
    margin: 0;
  }
`;

export const FilterHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
