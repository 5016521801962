import styled from 'styled-components';

export const StyledEmployeeDocuments = styled.section`
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme: { space } }) => space[8]};
  border-radius: ${({ theme: { space } }) => space[4]};
  box-shadow: ${({ theme: { shadows } }) => shadows.s};

  svg {
    margin-right: ${({ theme: { space } }) => space[4]};
  }

  p {
    margin-bottom: ${({ theme: { space } }) => space[4]};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  }

  p:first-of-type {
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  }

  > div {
    display: flex;
    justify-content: column;
    align-items: center;
  }
`;
