import { client } from 'api';
import { AxiosResponse } from 'axios';
import {
  PostResignationModel,
  ResignationModel,
} from 'common/types/resignations';

export const createResignation = (
  resignation: PostResignationModel,
): Promise<AxiosResponse<ResignationModel>> =>
  client.post<ResignationModel>('/resignations', resignation);

export const getResignations = (
  employeeId: string,
): Promise<AxiosResponse<ResignationModel[]>> => {
  return client.get<ResignationModel[]>(
    `/resignations?employeeId=${employeeId}`,
  );
};

export const getResignationsByCompanyId = (
  companyId: string,
): Promise<AxiosResponse<ResignationModel[]>> => {
  return client.get<ResignationModel[]>(`/resignations?companyId=${companyId}`);
};
