import styled from 'styled-components';

export const StyledConvertSeatToEmployeeSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { space } }) => `${space[16]}`};
  align-self: center;

  button {
    align-self: center;
  }
`;
