import { Resource } from 'common/components/molecules/Resource/Resource';
import { MANAGER_PERMISSIONS_PATH } from 'paths';

import { Icon, Stack, Typography } from '@omnipresentgroup/design-system';

export type ResourceItem = {
  title: string;
  icon: React.ReactElement;
  url: string;
};

const resources: ResourceItem[] = [
  {
    title: 'OmniCalculator',
    url: 'https://omnipresent.com/global-employment-cost-calculator?calcgate=unga',
    icon: <Icon size="24" icon="Divide" />,
  },
  {
    title: 'OmniAcademy',
    url: 'https://www.omnipresent.com/academy',
    icon: <Icon size="24" icon="Award" />,
  },
  {
    title: 'FAQs',
    url: 'https://omnipresent.com/faq',
    icon: <Icon size="24" icon="HelpCircle" />,
  },
];

const superManagerResources: ResourceItem[] = [
  {
    title: 'Permissions',
    url: MANAGER_PERMISSIONS_PATH,
    icon: <Icon size="24" icon="Settings" />,
  },
];

export const Resources = ({
  showSuperManagerResources,
}: {
  showSuperManagerResources: boolean;
}) => (
  <Stack>
    <Typography as="h2" hideParagraphSpacing weight="medium" size="24">
      Resources
    </Typography>
    {resources.map((resource) => (
      <Resource key={resource.title} {...resource} />
    ))}
    {showSuperManagerResources &&
      superManagerResources.map((superManagerResource) => (
        <Resource key={superManagerResource.title} {...superManagerResource} />
      ))}
  </Stack>
);
