import { formatCurrency } from 'utils/currencies';

import {
  DateFormats,
  formatISODate,
  sortByMultipleDates,
} from '@omnipresentgroup/design-system';

export const transformExpenseForList = ({
  title,
  description,
  id,
  dateSpent,
  amount: { amount, currency },
  status,
  statusHistory,
  paid,
  employeeName,
  employeeId,
  documentId,
  createdAt,
}) => {
  return {
    title,
    description,
    id,
    dateSpent: formatISODate(dateSpent, DateFormats.Date, true, '-'),
    amount: formatCurrency(amount, currency),
    currency,
    status,
    documentId,
    statusHistory,
    paid,
    employeeName,
    employeeId,
    createdAt,
  };
};

export const transformExpenseList = (expenses) => {
  const outstandingExpenses = sortByMultipleDates(
    expenses.filter(({ paid }) => !paid).map(transformExpenseForList),
    ['createdAt'],
    'desc',
  );
  const paidExpenses = expenses
    .filter(({ paid }) => paid)
    .map(transformExpenseForList);
  return {
    outstanding: outstandingExpenses,
    paid: paidExpenses,
  };
};
