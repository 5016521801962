import { StyledTooltipDescription } from './OmnipresentFees.styles';

const setupFeeDescription = () => (
  <StyledTooltipDescription>
    The set-up fee is the cost for:
    <ul>
      <li>Registering employee with local tax authorities</li>
      <li>Registering employee with local social services authority</li>
      <li>Setting up local payroll service</li>
      <li>Providing local Employment Contract</li>
    </ul>
  </StyledTooltipDescription>
);

const advancePaymentDepositDescription = () => (
  <StyledTooltipDescription>
    This is fully refundable and covers salary payments to your employee and
    other employer payments in case of Client insolvency or non-payment. If all
    invoices are fully paid this will be returned in full on termination.
  </StyledTooltipDescription>
);

const managementFeeDescription = () => (
  <StyledTooltipDescription>
    Omnipresent service fee per month per employee per country.
  </StyledTooltipDescription>
);

const zeroDepositInsuranceFeeDescription = () => (
  <StyledTooltipDescription>
    Monthly Insurance fee per employee (Non-refundable) in lieu of Deposit
    payment.
  </StyledTooltipDescription>
);

const supplementaryBenefitFeeDescription = () => (
  <StyledTooltipDescription>
    The costs for supplementary benefits vary depending on your specific choice
    of benefits, as well as potentially other attributes like employee age.
    These costs will be detailed later during the setup of benefits for this
    employee.
  </StyledTooltipDescription>
);

const FeesDescription: { [key: string]: JSX.Element } = {
  'Management fee': managementFeeDescription(),
  'Zero deposit insurance': zeroDepositInsuranceFeeDescription(),
  'Advanced payment deposit (APD)': advancePaymentDepositDescription(),
  'Set-up fee': setupFeeDescription(),
  'Supplementary benefit cost estimate': supplementaryBenefitFeeDescription(),
};

export const getDescriptionByFeeName = (feeName: string) =>
  FeesDescription[feeName];
