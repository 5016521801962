import { archiveEmployeeContractRequest } from 'common/api/employees.api';
import { transformEmployeeForDetailsPage } from 'common/store/transformers/employees.transformer';
import { Employee } from 'common/types';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

export const useArchiveContractMutation = () => {
  const dispatch = useDispatch();
  return useMutation(
    ({ employeeId, documentId }: { employeeId: string; documentId: string }) =>
      archiveEmployeeContractRequest(employeeId, documentId),
    {
      onSuccess: ({ data = {} as Employee }: { data: Employee }) => {
        dispatch({
          type: 'GET_EMPLOYEE_END',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          payload: transformEmployeeForDetailsPage(data),
        });
      },
      onError: (err: { message: string }) => {
        alert(err.message);
      },
    },
  );
};
