import { SalaryHistoryEntry } from 'common/types/salaryHistory';
import { composeEndpoint } from 'mocks/utils';
import { rest } from 'msw';
import { SALARY_HISTORY } from 'paths';

import { GetSalaryHistoryResponse } from './salaryHistory.api';

export const salaryHistoryHandlers = [
  rest.get<GetSalaryHistoryResponse>(
    composeEndpoint(`${SALARY_HISTORY}/company/:companyId`),
    (_req, res, ctx) => res(ctx.json(DEFAULT_GET_SALARY_HISTORY_RESPONSE)),
  ),
];

export const generateSalaryHistory = (
  override: Partial<SalaryHistoryEntry> = {},
) => ({
  ...DEFAULT_SALARY_HISTORY,
  ...override,
});

export const DEFAULT_SALARY_HISTORY = {
  employeeId: '9a666a45-e49b-4dbd-88c2-7bcbf1d1d0f5',
  employeeName: 'Lat Via',
  newSalary: {
    amount: 1000,
    currency: 'EUR',
  },
  status: 'requested',
  requestedAt: '2022-11-08T11:35:29.302Z',
};

export const DEFAULT_GET_SALARY_HISTORY_RESPONSE = {
  salaryHistory: [
    DEFAULT_SALARY_HISTORY,
    generateSalaryHistory({
      effectiveDate: '2023-05-01',
      previousSalary: {
        amount: 1000,
        currency: 'EUR',
      },
      newSalary: {
        amount: 2000,
        currency: 'EUR',
      },
      reason: 'Promotion',
      requestedAt: '2023-04-28T14:50:35.202Z',
    }),
  ],
};
