import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { OnboardingStatusValues } from 'common/types';
import { SingleValue } from 'react-select';
import countries from 'utils/countries';
import { removeAccents } from 'utils/string-utils';

import {
  Box,
  Dropdown,
  Inline,
  Input,
  OptionType,
} from '@omnipresentgroup/design-system';

type Props<DataToSearchType> = {
  employeesToFilter: DataToSearchType[];
  setEmployeesToDisplay: (filteredData: DataToSearchType[]) => void;
};

type QueryObject = {
  name?: string;
  jobTitle?: string;
  country?: string;
  onboardingStatus?: string;
};

const SEARCH_BY_OPTIONS = [
  { label: 'Search by Name', value: 'name' },
  { label: 'Search by Role', value: 'jobTitle' },
];

export const EmployeesSearchAndFilter = <DataToSearchType,>({
  employeesToFilter,
  setEmployeesToDisplay,
}: Props<DataToSearchType>) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const [country, setCountry] = useState<string>(
    urlParams.get('country') || '',
  );

  const [onboardingStatus, setOnboardingStatus] = useState<string>(
    urlParams.get('onboardingStatus') || '',
  );

  const [searchBy, setSearchBy] = useState<string>(
    urlParams.get('searchBy') || 'name',
  );

  const [searchValue, setSearchValue] = useState<string>(
    urlParams.get('searchValue') || '',
  );

  const [filters, setFilters] = useState<QueryObject>({
    country,
    onboardingStatus,
    [searchBy]: searchValue,
  });

  const handleSearchByChange = (event: SingleValue<OptionType>) => {
    setSearchBy((event?.value as string) || '');
    setSearchValue('');
  };

  const statusesDropdown = Object.values(OnboardingStatusValues).map(
    (status) => ({
      label: status,
      value: status,
    }),
  );

  useEffect(() => {
    setFilters({ country, onboardingStatus, [searchBy]: searchValue });
  }, [country, onboardingStatus, searchBy, searchValue]);

  useEffect(() => {
    const filtered = employeesToFilter.filter((employee: any) => {
      return Object.entries(filters).every(([key, value]) => {
        if (key === 'name' && value) {
          return removeAccents(employee.name).toLowerCase().includes(value);
        }
        if (key === 'jobTitle' && value) {
          return employee.jobTitle.toLowerCase().includes(value);
        }
        if (key === 'country' && value) {
          return employee.country === value;
        }
        if (key === 'onboardingStatus' && value) {
          return employee.onboardingStatus === value;
        }
        return true;
      });
    });
    setEmployeesToDisplay(filtered);
  }, [employeesToFilter, filters, setEmployeesToDisplay]);

  return (
    <Inline align="center" stretch="all" mt="16">
      <Input
        id="searchInputField"
        type="search"
        value={searchValue}
        placeholder="Search"
        onChange={(event) => {
          setSearchValue(event.target.value);
        }}
        handleClearSearch={() => {
          setSearchValue('');
        }}
      />
      <Box maxW={250}>
        <Dropdown
          id="searchBy"
          aria-label="search-by-dropdown"
          isMulti={false}
          isClearable={false}
          isSearchable={false}
          defaultValue={SEARCH_BY_OPTIONS.find(
            ({ value }) => value === searchBy,
          )}
          options={SEARCH_BY_OPTIONS}
          onChange={handleSearchByChange}
        />
      </Box>
      <Box w={210}>
        <Dropdown
          id="countries"
          aria-label="countries-dropdown"
          isClearable
          isSearchable
          isMulti={false}
          placeholder="Select a country..."
          value={countries.find(({ label }) => label === country)}
          options={countries.map((country) => ({
            label: `${country.emoji} ${country.label}`,
            value: country.label,
          }))}
          onChange={(event) => {
            setCountry((event?.value as string) || '');
          }}
        />
      </Box>
      <Box w={210}>
        <Dropdown
          id="onboardingStatuses"
          aria-label="status-dropdown"
          isClearable
          isSearchable
          isMulti={false}
          placeholder="Select a status..."
          value={statusesDropdown.find(
            ({ label }) => label === onboardingStatus,
          )}
          options={statusesDropdown}
          onChange={(event) => {
            setOnboardingStatus((event?.value as string) || '');
          }}
        />
      </Box>
    </Inline>
  );
};
