import { useState } from 'react';

import { PageHeader } from 'common/components';
import StyledTableContainer from 'common/components/molecules/StyledTableContainer/StyledTableContainer.styles';
import { useFxRatesQuery } from 'utils/queries/payroll/useFxRatesQuery';

const Table = () => {
  const [fromCurrency, _] = useState();
  const [toCurrency, __] = useState();
  const { refetch } = useFxRatesQuery(fromCurrency, toCurrency);

  return (
    <StyledTableContainer>
      <PageHeader
        title="Note: Rates are updated at 09:15 UTC once per day."
        ctaText="Download FX Rates"
        onCTAClick={() => {
          refetch();
        }}
        testId="fx-rates-page-header"
      />
    </StyledTableContainer>
  );
};

export const FXRatesTab = () => <Table />;
