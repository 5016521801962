export const PAYROLL_FIELDS = {
  section: {
    name: 'payroll-details',
  },
  payroll_id: {
    name: 'payrollId',
    label: 'Payroll ID',
  },
  advanced_payment_deposit_percentage: {
    name: 'advancedPaymentDepositPercentage',
    label: 'Advanced payment deposit percentage',
  },
  zero_deposit_fee_amount: {
    name: 'zeroDepositFeeAmount',
    label: 'Zero deposit fee',
  },
  zero_deposit_fee_currency: {
    name: 'zeroDepositFeeCurrency',
    label: 'Zero deposit fee currency',
  },

  setup_fee_amount: {
    name: 'setupFeeAmount',
    label: 'Setup fee',
  },
  setup_fee_currency: {
    name: 'setupFeeCurrency',
    label: 'Setup fee currency',
  },
  setup_fee_structure: {
    name: 'setupFeeStructure',
    label: 'Setup fee structure',
  },
  management_fee_amount: {
    name: 'managementFeeAmount',
    label: 'Management fee',
  },
  management_fee_currency: {
    name: 'managementFeeCurrency',
    label: 'Management fee currency',
  },
  billing_currency: {
    name: 'billingCurrency',
    label: 'Billing currency',
  },
  setup_fee_paid: {
    name: 'needsSetupFeeUpdated',
    label: 'Has the set up fee been paid ?',
  },
  managementFeeWaiver: {
    waiver_percentage: {
      name: 'percentage',
      label: 'Waiver percentage %',
    },
    waiver_period_start: {
      name: 'periodStart',
      label: 'Waiver period start',
    },
    waiver_billing_cycles: {
      name: 'billingCycles',
      label: 'Waiver billing cycles',
    },
  },
  managementFeeDiscount: {
    sales_discount_percentage: {
      name: 'discountPercentage',
      label: 'Sales discount percentage %',
    },
    sales_discount_period_start: {
      name: 'discountPeriodStart',
      label: 'Sales discount period start',
    },
    sales_discount_billing_cycles: {
      name: 'discountBillingCycles',
      label: 'Sales discount billing cycles',
    },
  },
};
