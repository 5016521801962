import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { Check, X } from 'react-feather';

import { Box, Center, Inline } from '@omnipresentgroup/design-system';

import * as S from './EmployeeDetailsNotification.styles';

type EmployeeDetailsNotificationModalProps = {
  isModalOpen: boolean;
  closeFn: () => void;
};

export const EmployeeDetailsNotificationModal = ({
  isModalOpen,
  closeFn,
}: EmployeeDetailsNotificationModalProps) => {
  const handleOnClickButton = () => {
    closeFn && closeFn();
  };

  const displayTextBasedOnDate = () => {
    const currentDate = new Date().getDate();

    return (
      <Box p="16" mt="16">
        {currentDate >= 6 && currentDate <= 31 ? (
          <Center centerText>
            Your changes have been saved.
            <br />
            Please note that the changes made to your bank account or address
            will only take effect in&nbsp;
            <b>next month&apos;s payroll processing.</b>
          </Center>
        ) : (
          <Center centerChildren>Your changes have been saved.</Center>
        )}
      </Box>
    );
  };

  return (
    <>
      {isModalOpen && (
        <ModalWrapper
          fixedWidth="500"
          data-testid="employee-details-notification-modal"
        >
          <Box p="32" pt="16">
            <Inline justify="end">
              <X
                style={{ cursor: 'pointer' }}
                onClick={handleOnClickButton}
                data-testid="closeModalButton"
              />
            </Inline>
            <Center centerChildren>
              <S.StyledCheckIcon>
                <Check />
              </S.StyledCheckIcon>
            </Center>
            {displayTextBasedOnDate()}
          </Box>
        </ModalWrapper>
      )}
    </>
  );
};
