/* eslint import/no-cycle: 0 */
import {
  departmentTypeOptions,
  documentTypeOptions,
  genderTypeOptions,
} from 'appConstants';
import { StyledContextualInfo } from 'common/components/atoms/ContextualInfo';
import { StyledErrorMessage } from 'common/components/atoms/ErrorMessage';
import { StyledInput } from 'common/components/atoms/Input';
import { StyledTextArea } from 'common/components/atoms/TextArea';
import FetchingDropdownInput from 'common/components/molecules/FetchingDropdownInput/FetchingDropdownInput';
import HolidayAllowanceInput from 'common/components/molecules/HolidayAllowanceInput/HolidayAllowanceInput';
import { PAYROLL_FIELDS } from 'common/helpers/payrollFormFieldLabels.const';
import { FIELD_TYPE } from 'common/types';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import { Info } from 'react-feather';
import Select from 'react-select';
import Tooltip from 'rich-markdown-editor/dist/components/Tooltip';
import { companyOnboardingStages } from 'utils/companyOnboardingStages';
import countries from 'utils/countries';
import { CURRENCY_CODE_OPTIONS } from 'utils/currencies';
import { findSoftOptionMatch } from 'utils/findSoftOptionMatch';
import { onWheelPreventChangeForNumberInput } from 'utils/input.helper';
import {
  useEmployeesQuery,
  useLSPEmployeesQuery,
  useLspsQuery,
} from 'utils/queries';
import { useEmployerLSPSelectionQuery } from 'utils/queries/employers/useEmployerLSPSelectionQuery';
import { useEmployersFilterQuery } from 'utils/queries/employers/useEmployerQuery';
import { useActivePayrollProvidersFilterQuery } from 'utils/queries/payrollProviders/usePayrollProvidersQuery/usePayrollProvidersQuery';

import { Inline, Link, Typography } from '@omnipresentgroup/design-system';

import { Toggle } from '../../atoms/Toggle/Toggle';
import { Address } from '../Address/Address';
import { CheckboxBoolean } from '../CheckboxBoolean/CheckboxBoolean';
import CheckboxInput from '../CheckboxInput/CheckboxInput';
import ConditionalInput from '../ConditionalInput/ConditionalInput';
import CurrencyTimePeriodInput from '../CurrencyTimePeriodInput/CurrencyTimePeriodInput';
import DependentDropdownInput from '../DependentDropdown/DependentDropdownInput';
import DropdownInput from '../DropdownInput/DropdownInput';
import { EmployeeCurrencyInput } from '../EmployeeCurrencyInput/EmployeeCurrencyInput';
import { FeeCurrencyInput } from '../FeeCurrencyInput/FeeCurrencyInput';
import LocationOfWorkInput from '../LocationOfWorkInput/LocationOfWorkInput';
import TimePeriodInput from '../TimePeriodInput/TimePeriodInput';
import UploadDocument from '../UploadDocument/UploadDocument';
import {
  StyledDetailsFieldContainer,
  StyledLabelMultipleLine,
} from './EditableInput.styles';

export const groupDependentOptions = (dependentOptions) => {
  const groupedByKey = groupBy(dependentOptions, (item) => item.key);
  const groupedOptionsWithLabel = Object.entries(groupedByKey).map(
    ([label, options]) => ({
      label,
      options,
    }),
  );
  return groupedOptionsWithLabel;
};

export const getDependentFieldOptions = (
  field,
  formValues,
  grouped = false,
) => {
  let options = (field?.options || []).filter((option) => {
    return option.key === formValues?.[field?.dependentAttribute];
  });
  if (grouped) {
    options = groupDependentOptions(options);
  }
  return options;
};

const onboardingStages = [
  {
    label: 'In progress',
    value: 'In progress',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];

const leaveTypes = [
  {
    label: 'Sick',
    value: 'op:leave:sick',
  },
  {
    label: 'Compassionate',
    value: 'op:leave:compassionate',
  },
  {
    label: 'Holiday',
    value: 'op:leave:holiday',
  },
  {
    label: 'Other',
    value: 'op:leave:other',
  },
];

const fetchingDropdownTypesMap = {
  [FIELD_TYPE.LSP]: {
    dbErrorMessage: 'Cannot load the list of LSPs',
    query: useLspsQuery,
  },
  [FIELD_TYPE.EMPLOYER_LSP_SELECTION]: {
    dbErrorMessage: 'Cannot load the list of LSPs',
    query: useEmployerLSPSelectionQuery,
  },
  [FIELD_TYPE.LPP]: {
    dbErrorMessage: 'Cannot load the list of payroll providers',
    query: useActivePayrollProvidersFilterQuery,
  },
  [FIELD_TYPE.EMPLOYER]: {
    dbErrorMessage: 'Cannot load the list of legal entities',
    query: useEmployersFilterQuery,
  },
  [FIELD_TYPE.EMPLOYEE_BY_LSP]: {
    dbErrorMessage: 'Cannot load the list of LSP employees',
    query: useLSPEmployeesQuery,
  },
  [FIELD_TYPE.EMPLOYEE]: {
    dbErrorMessage: 'Cannot load the list of employees',
    query: useEmployeesQuery,
  },
};

const getInput = ({
  inputType,
  name,
  link,
  editable,
  disabled,
  onChange,
  errorMessage,
  value,
  label,
  onFocus,
  country,
  required,
  options,
  placeholder,
  params,
}) => {
  if (fetchingDropdownTypesMap[inputType]) {
    return (
      <FetchingDropdownInput
        label={label}
        name={name}
        onSelect={onChange}
        onFocus={onFocus}
        error={errorMessage}
        placeholder={placeholder}
        value={value}
        params={params}
        disabled={disabled}
        {...fetchingDropdownTypesMap[inputType]}
      />
    );
  }

  switch (inputType) {
    case 'currencyTimePeriod':
      return (
        <CurrencyTimePeriodInput
          name={name}
          value={value}
          errorMessage={errorMessage}
          onChange={onChange}
          editable={editable}
        />
      );
    case 'timePeriod':
      return (
        <TimePeriodInput
          name={name}
          value={value}
          errorMessage={errorMessage}
          onChange={onChange}
          editable={editable}
        />
      );
    case 'employeeCurrency':
      return (
        <EmployeeCurrencyInput
          name={name}
          value={value}
          country={country}
          errorMessage={errorMessage}
          disabled={disabled}
          onChange={onChange}
          editable={editable}
          onFocus={onFocus}
        />
      );
    case 'feeCurrency':
      return (
        <FeeCurrencyInput
          name={name}
          value={value}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
        />
      );
    case 'document':
    case 'receipt':
      return (
        <UploadDocument
          onDocumentUploaded={onChange}
          name={name}
          value={value}
          type={inputType}
          editable={editable}
          onFocus={onFocus}
        />
      );
    case 'dropdown':
      const softMatchingOption = findSoftOptionMatch(options, value);
      return (
        <DropdownInput
          label={label}
          options={{
            name,
            options,
          }}
          onSelect={onChange}
          value={softMatchingOption || value}
          onFocus={onFocus}
          error={errorMessage}
          placeholder={placeholder}
          isDisabled={disabled}
        />
      );
    case 'dependent-dropdown':
      const softMatchingOptionDependent = findSoftOptionMatch(options, value);
      return (
        <DependentDropdownInput
          label={label}
          options={{
            name,
            options,
          }}
          onSelect={onChange}
          value={softMatchingOptionDependent || value}
          onFocus={onFocus}
          error={errorMessage}
          placeholder={placeholder}
          isDisabled={disabled}
        />
      );
    case 'multi-select': {
      // that's unfortunately the only way to make this work given userland code
      const values = options
        .map((option) => (value.includes(option.value) ? option : null))
        .filter(Boolean);
      return (
        <Select
          inputId={name}
          name={name}
          aria-labelledby={name}
          options={options}
          onChange={(multiSelectValues, event) => {
            onChange(
              event.name,
              multiSelectValues.map((v) => v.value),
              event,
            );
          }}
          value={values}
          isMulti
        />
      );
    }
    case 'address':
      return (
        <Address
          editable={editable}
          disabled={disabled}
          errorObject={errorMessage || {}}
          onChange={onChange}
          addressObject={value}
          title={label}
          name={name}
          onFocus={onFocus}
          required={required}
        />
      );
    case 'locationOfWork':
      return (
        <LocationOfWorkInput
          onChange={onChange}
          value={value}
          name={name}
          error={errorMessage}
          onFocus={onFocus}
          homeAddress={options[0]?.homeAddress}
          required={required}
        />
      );
    // TODO: this component does not have a label it makes it un-redeable by screen-readers and test library
    // Found a label with the text of: Start date, however no form control was found associated to that label.
    // Make sure you're using the "for" attribute or "aria-labelledby" attribute correctly.
    case 'date':
      return (
        <StyledInput
          onChange={({ target: { value: newValue } }) =>
            onChange(name, newValue)
          }
          onFocus={() => onFocus(name)}
          value={value}
          data-testid={`${name}-date-input`}
          name={name}
          id={name}
          disabled={disabled}
          error={errorMessage}
          min="1910-01-01"
          placeholder="yyyy-mm-dd"
          type={
            name !==
              PAYROLL_FIELDS.managementFeeWaiver.waiver_period_start.name &&
            name !==
              PAYROLL_FIELDS.managementFeeDiscount.sales_discount_period_start
                .name
              ? 'date'
              : 'month'
          }
        />
      );

    case 'conditionalInput':
      return <ConditionalInput onChange={onChange} value={value} name={name} />;
    case 'checkbox':
      return <CheckboxInput onChange={onChange} options={value} name={name} />;

    case 'checkbox-boolean':
      return (
        <CheckboxBoolean
          name={name}
          title={label}
          onChange={onChange}
          initialValue={value}
        />
      );
    case 'toggle':
      return (
        <Toggle
          onToggle={onChange}
          toggleOn={!!value}
          name={name}
          isDisabled={disabled}
        />
      );
    case 'holidayAllowance':
      return (
        <HolidayAllowanceInput
          onChange={onChange}
          name={name}
          value={value}
          errorMessage={errorMessage}
          onFocus={onFocus}
        />
      );
    case 'number':
      return (
        <StyledInput
          id={name}
          type="number"
          onFocus={() => onFocus(name)}
          data-testid={`${name}-input`}
          value={value}
          name={name}
          min="0"
          disabled={disabled || !editable}
          editable={editable}
          onChange={({ target: { value: newValue } }) =>
            onChange(name, parseFloat(newValue))
          }
          error={errorMessage}
          autoComplete="chrome-off"
          onWheel={onWheelPreventChangeForNumberInput}
          placeholder={placeholder}
        />
      );
    case 'text':
      return (
        <StyledTextArea
          id={name}
          onFocus={() => onFocus(name)}
          data-testid={`${name}-text`}
          wrap="hard"
          value={value}
          name={name}
          rows="3"
          disabled={disabled || !editable}
          editable={editable}
          onChange={({ target: { value: newValue } }) =>
            onChange(name, newValue)
          }
          error={errorMessage}
          autoComplete="off"
          placeholder={placeholder}
        />
      );
    case 'string':
    default:
      return (
        <StyledInput
          id={name}
          type="text"
          onFocus={() => onFocus(name)}
          data-testid={`${name}-input`}
          value={value}
          name={name}
          link={link}
          disabled={disabled || !editable}
          editable={editable}
          onChange={({ target: { value: newValue } }) =>
            onChange(name, newValue)
          }
          error={errorMessage}
          autoComplete="off"
          placeholder={placeholder}
        />
      );
  }
};

const dropdownTypesMap = {
  [FIELD_TYPE.CURRENCY]: {
    options: CURRENCY_CODE_OPTIONS,
    placeholder: 'Choose a currency...',
  },
  gender: {
    options: genderTypeOptions,
    placeholder: 'Choose a gender...',
  },
  onboardingStage: {
    options: companyOnboardingStages,
    placeholder: 'Select onboarding stage...',
  },
  documentType: {
    options: documentTypeOptions,
    placeholder: 'Choose a document type...',
  },
  onboardingStatus: {
    options: onboardingStages,
    placeholder: 'Select status...',
  },
  country: {
    options: countries,
    placeholder: 'Choose a country...',
  },
  leaveType: {
    options: leaveTypes,
    placeholder: 'Choose a leave type...',
  },
  departmentType: {
    options: departmentTypeOptions,
    placeholder: 'Choose a department type...',
  },
};

const normalizeInputType = ({ type, value, options, placeholder }) => {
  const dropdownType = dropdownTypesMap[type];
  if (dropdownType) {
    return {
      inputType: 'dropdown',
      inputValue: dropdownType.options.find((option) => option.label === value),
      inputOptions: dropdownType.options,
      inputPlaceholder: dropdownType.placeholder,
    };
  }

  // default value to empty string or UI throws error on empty value
  return {
    inputType: type,
    inputValue: value || value === 0 ? value : '',
    inputOptions: options,
    inputPlaceholder: placeholder,
  };
};

export const renderInput = ({ type, value, options, placeholder, ...rest }) => {
  const { inputType, inputValue, inputOptions, inputPlaceholder } =
    normalizeInputType({
      type,
      value,
      options,
      placeholder,
    });

  return getInput({
    inputType,
    value: inputValue,
    options: inputOptions,
    placeholder: inputPlaceholder,
    ...rest,
  });
};

const wrapTextIntoNewLines = (text) => {
  const textSplitIntoNewLines = text.split('\n');
  if (textSplitIntoNewLines.length === 1) {
    return text;
  }
  return textSplitIntoNewLines.map((x) => <span key={x}>{x}</span>);
};

const EditableInput = ({
  label,
  labelBold,
  name,
  value,
  editable,
  disabled,
  onChange,
  small,
  errorMessage,
  type,
  oneInputPerRow,
  onFocus,
  noLabel,
  contextualInfo,
  country,
  required,
  options,
  link,
  placeholder,
  params,
}) => {
  if (type === FIELD_TYPE.HIDDEN) {
    return null;
  }

  return (
    <StyledDetailsFieldContainer
      small={small}
      error={errorMessage}
      oneInputPerRow={oneInputPerRow}
    >
      {label && type !== 'address' && !noLabel && !labelBold && (
        <Inline align="center">
          <StyledLabelMultipleLine htmlFor={name} required={required}>
            {wrapTextIntoNewLines(label)}{' '}
            {name === 'bicSwift' && (
              <Inline gap="4">
                <Typography as="span" size="16" style={{ marginTop: 0 }}>
                  Find your bank swift code
                </Typography>
                <Link size="16" to={link} target="_blank" rel="noreferrer">
                  here
                </Link>
              </Inline>
            )}
          </StyledLabelMultipleLine>
          {name === PAYROLL_FIELDS.setup_fee_paid.name && (
            <Tooltip tooltip="If the setup fee has been paid, keep the original currency to preserve the data accuracy.">
              <Info
                style={{ height: '16px', width: '16px' }}
                data-testid="more-info-icon"
              />
            </Tooltip>
          )}
        </Inline>
      )}
      {label && labelBold && (
        <StyledLabelMultipleLine htmlFor={name} required={required}>
          <b>{wrapTextIntoNewLines(label)}</b>
        </StyledLabelMultipleLine>
      )}
      {contextualInfo && (
        <StyledContextualInfo>{contextualInfo}</StyledContextualInfo>
      )}
      {renderInput({
        type,
        errorMessage,
        required,
        labelBold,
        label,
        name,
        value,
        options,
        placeholder,
        editable,
        disabled,
        onChange,
        onFocus,
        country,
        params,
      })}
      {errorMessage && typeof errorMessage === 'string' && (
        <StyledErrorMessage role="alert">{errorMessage}</StyledErrorMessage>
      )}
    </StyledDetailsFieldContainer>
  );
};

EditableInput.propTypes = {
  label: PropTypes.string,
  labelBold: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]),
  options: PropTypes.array,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.oneOfType([PropTypes.func]),
  small: PropTypes.bool,
  errorMessage: PropTypes.string,
  type: PropTypes.string,
  oneInputPerRow: PropTypes.bool,
  onFocus: PropTypes.func,
  noLabel: PropTypes.bool,
  contextualInfo: PropTypes.string,
  country: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  params: PropTypes.object,
  link: PropTypes.string,
};

EditableInput.defaultProps = {
  value: null,
  label: null,
  editable: true,
  disabled: false,
  onChange: () => {},
  small: false,
  errorMessage: null,
  type: null,
  oneInputPerRow: false,
  onFocus: () => {},
  noLabel: false,
  contextualInfo: '',
  country: '',
  required: false,
  options: [],
  placeholder: '',
  params: {},
  link: '',
};

export default EditableInput;
