import styled from 'styled-components';

export const ModalContainer = styled.div`
  margin: ${({ theme: { space } }) => space[32]};
`;

const align = {
  left: 'flex-start',
  middle: 'center',
  right: 'flex-end',
  'space-between': 'space-between',
};

export const ButtonsContainer = styled.div<{
  alignment?: 'left' | 'middle' | 'right' | 'space-between';
}>`
  display: flex;
  margin-top: ${({ theme: { space } }) => space[32]};
  justify-content: ${({ alignment }) => align[alignment || 'right']};

  & button {
    margin-left: ${({ theme: { space }, alignment }) => {
      if (alignment !== 'space-between') {
        return space[16];
      }
    }};
  }
`;
