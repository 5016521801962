import Big from 'big.js';
import { Concept } from 'common/types/payroll';
import groupBy from 'lodash/groupBy';

export enum BreakdownItemType {
  INFORMATIONAL = 'Informational',
  VALUE = 'Value',
}

export type BreakdownItem = {
  id: string;
  style?: StyleEnum;
  name?: string;
  amount?: string;
  previous?: string;
  variance?: string;
  percentage?: string;
  type: BreakdownItemType;
};

enum ApplicationType {
  INCOME = 'Income',
  CONTRIBUTION = 'Contribution',
  DEDUCTION = 'Deduction',
}

export enum GroupEnum {
  APPLICATION_TYPE = `applicationType`,
  OMNIPLATFORM = 'omniplatform',
  PAYROLL_GLOBAL = 'payrollGlobal',
  PAYROLL_GLOBAL_SUB = 'payrollGlobalSubCategory',
}

export enum StyleEnum {
  APPLICATION_TYPE = `applicationType`,
  OMNIPLATFORM = 'omniplatform',
  PAYROLL_GLOBAL = 'payrollGlobal',
  PAYROLL_GLOBAL_SUB = 'payrollGlobalSubCategory',
  TOTAL_SUB = 'subTotal',
  TOTAL = 'total',
}

export type GroupType = `${GroupEnum}`;

const SALARY_DEDUCTION_TO_GROSS = 'Salary Deduction to Gross';

const PERCENT_MULTIPLIER = 100;

const getTotalGrossSalary = (data: Concept[]) => {
  return data
    .filter((item) => item.applicationType === ApplicationType.INCOME)
    .reduce((acc, item) => acc + parseFloat(item.value), 0);
};

const getTotalDeductions = (data: Concept[]) => {
  return data
    .filter(
      (item) =>
        item.applicationType === ApplicationType.DEDUCTION &&
        item.payrollGlobal !== SALARY_DEDUCTION_TO_GROSS,
    )
    .reduce((acc, item) => acc + parseFloat(item.value), 0);
};

const getTotalNetPay = (data: Concept[]) => {
  return Big(getTaxableGross(data)).minus(getTotalDeductions(data));
};

const getTotalContribution = (data: Concept[]) => {
  return data
    .filter((item) => item.applicationType === ApplicationType.CONTRIBUTION)
    .reduce((acc, item) => acc + parseFloat(item.value), 0);
};

const getTotalCost = (data: Concept[]) => {
  return Big(getTotalGrossSalary(data)).add(getTotalContribution(data));
};

const getTaxableGross = (data: Concept[]) => {
  const grossSalary = getTotalGrossSalary(data);

  const grossSalaryDeductions = data
    .filter(
      (item) =>
        item.applicationType === ApplicationType.DEDUCTION &&
        item.payrollGlobal === SALARY_DEDUCTION_TO_GROSS,
    )
    .reduce((acc, item) => acc + parseFloat(item.value), 0);

  return Big(grossSalary).minus(grossSalaryDeductions).toNumber();
};

const getTotals = (data: Concept[]) => {
  const totalGrossPay = getTotalGrossSalary(data);
  const totalDeduction = getTotalDeductions(data);
  const totalNetPay = getTotalNetPay(data);
  const totalEmployerContribution = getTotalContribution(data);
  const taxableGross = getTaxableGross(data);

  return {
    totalGrossPay,
    totalDeduction,
    totalNetPay,
    totalEmployerContribution,
    taxableGross,
  };
};

export const groupConceptData = (items: Concept[]): BreakdownItem[] => {
  const {
    totalGrossPay,
    totalDeduction,
    totalNetPay,
    totalEmployerContribution,
    taxableGross,
  } = getTotals(items);

  const groupedByApplicationType = groupBy(items, GroupEnum.APPLICATION_TYPE);

  const breakdownItems = Object.entries(groupedByApplicationType)
    .sort((a, b) => (a[0] > b[0] ? -1 : 1))
    .reduce<BreakdownItem[]>((acc, applicationTypeEntry) => {
      const [applicationType, applicationTypeEntries] = applicationTypeEntry;

      acc.push({
        id: applicationType,
        name: `${applicationType}`,
        style: StyleEnum.APPLICATION_TYPE,
        type: BreakdownItemType.INFORMATIONAL,
      });

      if (applicationType === ApplicationType.DEDUCTION) {
        const salaryDeductions = groupBy(
          applicationTypeEntries.filter(
            (entry) => entry.payrollGlobal === SALARY_DEDUCTION_TO_GROSS,
          ),
          GroupEnum.OMNIPLATFORM,
        );
        Object.entries(salaryDeductions).forEach(createOmniplatformItems);
        acc.push({
          id: `totalTaxableGross`,
          name: `Taxable Gross`,
          style: StyleEnum.TOTAL_SUB,
          amount: taxableGross.toString(),
          type: BreakdownItemType.VALUE,
        });

        const employeeDeductions = groupBy(
          applicationTypeEntries.filter(
            (entry) => entry.payrollGlobal !== SALARY_DEDUCTION_TO_GROSS,
          ),
          GroupEnum.OMNIPLATFORM,
        );

        Object.entries(employeeDeductions).forEach(createOmniplatformItems);
      } else {
        const groupedConceptsByOmniplatform = groupBy(
          applicationTypeEntries,
          GroupEnum.OMNIPLATFORM,
        );
        Object.entries(groupedConceptsByOmniplatform).forEach(
          createOmniplatformItems,
        );
      }

      // add calculated fields at certain locations
      switch (applicationType) {
        case ApplicationType.INCOME: {
          acc.push({
            id: 'totalGross',
            name: 'Total Gross',
            style: StyleEnum.TOTAL_SUB,
            amount: totalGrossPay.toString(),
            type: BreakdownItemType.VALUE,
          });
          break;
        }
        case ApplicationType.DEDUCTION: {
          acc.push({
            id: 'totalDeductions',
            name: 'Total Deductions',
            style: StyleEnum.TOTAL_SUB,
            amount: totalDeduction.toString(),
            type: BreakdownItemType.VALUE,
          });
          acc.push({
            id: 'totalNetPay',
            name: 'Net Pay',
            style: StyleEnum.TOTAL_SUB,
            amount: totalNetPay.toString(),
            type: BreakdownItemType.VALUE,
          });

          break;
        }
        case ApplicationType.CONTRIBUTION: {
          acc.push({
            id: 'totalEmployerContribution',
            name: 'Total Employer Contribution',
            style: StyleEnum.TOTAL_SUB,
            amount: totalEmployerContribution.toString(),
            type: BreakdownItemType.VALUE,
          });
          break;
        }
      }

      function createOmniplatformItems(omniplatformEntry: [string, Concept[]]) {
        const [omniplatform, omniplatformEntries] = omniplatformEntry;

        const amount = omniplatformEntries
          .reduce(
            (opeAcc, item) =>
              opeAcc + parseFloat(item.value.replaceAll(',', '')),
            0,
          )
          .toString();

        acc.push({
          id: `${applicationType}.${omniplatform}`,
          name: `${omniplatform}`,
          style: StyleEnum.OMNIPLATFORM,
          amount,
          type: BreakdownItemType.VALUE,
        });

        const groupedConceptsByPayrollGlobal = groupBy(
          omniplatformEntries,
          GroupEnum.PAYROLL_GLOBAL,
        );

        Object.entries(groupedConceptsByPayrollGlobal).forEach(
          (payrollGlobalGroup) => {
            const [payrollGlobal, payrollGlobalEntries] = payrollGlobalGroup;

            acc.push({
              id: `${applicationType}.${omniplatform}.${payrollGlobal}`,
              name: `${payrollGlobal}`,
              style: StyleEnum.PAYROLL_GLOBAL,
              amount: payrollGlobalEntries
                .reduce(
                  (pgeAcc, item) =>
                    pgeAcc + parseFloat(item.value.replaceAll(',', '')),
                  0,
                )
                .toString(),
              type: BreakdownItemType.VALUE,
            });

            payrollGlobalEntries.forEach((payrollGlobalEntry) => {
              const { payrollGlobalSubCategory, value } = payrollGlobalEntry;
              const id = `${applicationType}.${omniplatform}.${payrollGlobal}.${payrollGlobalSubCategory}`;
              const existingSubCategoryEntryIndex = acc.findIndex(
                (breakdownItem) => breakdownItem.id === id,
              );

              if (existingSubCategoryEntryIndex !== -1) {
                const existingEntry = acc[existingSubCategoryEntryIndex];
                existingEntry.amount = Big(
                  existingEntry.amount?.replaceAll(',', '') || 0,
                )
                  .add(value.replaceAll(',', ''))
                  .toString();
              } else {
                acc.push({
                  id,
                  name: `${payrollGlobalSubCategory}`,
                  style: StyleEnum.PAYROLL_GLOBAL_SUB,
                  amount: value,
                  type: BreakdownItemType.VALUE,
                });
              }
            });
          },
        );
      }
      return acc;
    }, []);

  // push the total row at the bottom
  breakdownItems.push({
    id: 'totalCost',
    name: 'Total Cost',
    style: StyleEnum.TOTAL,
    amount: getTotalCost(items).toString(),
    type: BreakdownItemType.VALUE,
  });

  return breakdownItems;
};

export function mergeNestedBreakdownItemsWithVariance(
  originals: BreakdownItem[],
  variances: BreakdownItem[],
): BreakdownItem[] {
  return originals.map((child) => {
    let varianceFields = {};

    const variance = variances.find((v) => v.id === child.id);

    if (child.amount) {
      const currentValue = child?.amount?.replaceAll(',', '');
      const previousValue = variance?.amount?.replaceAll(',', '');
      const varianceValue = previousValue
        ? Big(currentValue).minus(previousValue).toNumber()
        : undefined;

      varianceFields = {
        previous: previousValue,
        variance: varianceValue,
        percentage: previousValue
          ? calculateVariancePercentage(
              Big(currentValue).toNumber(),
              Big(previousValue).toNumber(),
            )
          : undefined,
      };
    }

    return {
      ...child,
      ...varianceFields,
    };
  });
}

export function calculateVariancePercentage(
  currentValue: number,
  previousValue: number,
): number {
  if (Big(currentValue).eq(0) && Big(previousValue).eq(0)) {
    return 0;
  }

  if (Big(previousValue).toNumber() === 0) {
    return 100;
  }

  if (Big(currentValue).toNumber() === 0) {
    return -100;
  }

  return Big(currentValue).div(previousValue).mul(100).minus(100).toNumber();
}
