import { getGrossToNet } from 'common/api/payrollTaxonomy.api';
import { GrossToNetFile } from 'common/types';
import { useQuery } from 'react-query';
import { payrollKeys } from 'utils/queries/payroll/keys';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useGrossToNetQuery = (gtnId: string) =>
  useQuery<GrossToNetFile>(payrollKeys.grossToNet(gtnId), () =>
    getGrossToNet(gtnId).then(getDataFromResponse),
  );
