import {
  Context,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Field,
  FIELD_TYPE,
  FieldRuleHandler,
  FieldRulePropName,
  FieldRules,
  FormConfig,
  FormData,
  FormFields,
  FormFieldsObject,
} from 'common/types';
import { validateInputs } from 'utils/validators/validator';

export type FormValues = any;
export type FormErrors = Record<string, string>;

export type FormContextType<FormValues = FormData> = {
  fields: FormFields;
  fieldsObject: FormFieldsObject;
  values: FormValues;
  formTitle: string;
  onFieldChange: (name: string, value: any) => void;
  alterFieldProp: (fieldName: string, propName: string, value: any) => void;
  clearFieldErrors: (name: string) => void;
  validate: () => FormErrors;
  onAfterError?: (values: any) => void;
  isSubmitDisabled: boolean;
  setIsSubmitDisabled: (disabled: boolean) => void;
};

export const emptyFn = () => {
  return;
};

export const FormContext = createContext<FormContextType<FormData>>({
  fields: [],
  fieldsObject: {},
  values: {},
  formTitle: '',
  onFieldChange: emptyFn,
  clearFieldErrors: emptyFn,
  alterFieldProp: emptyFn,
  validate: () => ({}),
  onAfterError: emptyFn,
  isSubmitDisabled: false,
  setIsSubmitDisabled: emptyFn,
});

export function useFormContext<FormValues extends Record<string, any> = any>() {
  return useContext<FormContextType<FormData<FormValues>>>(
    FormContext as Context<FormContextType<FormData<FormValues>>>,
  );
}

type FormProviderProps = {
  children: ReactNode | ReactNode[];
  config: FormConfig;
  clearFields?: boolean;
  onAfterError?: (values: any) => void;
};

export const transformConfigFields = (
  fields: FormFields,
  clearFields?: boolean | undefined,
): FormData =>
  fields.reduce((acc, { name, value, type }) => {
    if (clearFields) {
      acc[name] = type === FIELD_TYPE.DROPDOWN ? null : '';
    } else {
      acc[name] = value;
    }
    return acc;
  }, {} as any);

export const FormProvider = ({
  children,
  config: { formFields, formTitle, schema },
  clearFields,
  onAfterError,
}: FormProviderProps) => {
  const [fieldsProps, setFieldsProps] = useState(formFields);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [values, setValues] = useState<FormData>(
    transformConfigFields(fieldsProps, false),
  );

  const [errors, setErrors] = useState<Record<string, any>>({});

  const alterFieldProp = useCallback(
    (fieldName, propName, value) => {
      setFieldsProps((prevFieldProps) => {
        const newFieldProps = prevFieldProps.map((field) => {
          if (field.name === fieldName) {
            return { ...field, [propName]: value };
          }
          return field;
        });
        return newFieldProps;
      });
    },
    [fieldsProps],
  );

  const onFieldChange = useCallback((name, value) => {
    setValues((fields) => ({ ...fields, [name]: value }));
  }, []);

  const validate = useCallback(() => {
    const validated = validateInputs(values, schema) || {};
    setErrors(validated);
    return validated;
  }, [values, schema]);

  const clearFieldErrors = useCallback(
    (name: string) => {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    },
    [setErrors],
  );

  const evalPropRules = useCallback(
    (field: Field, fields: FormFieldsObject) => {
      if (!field.rules) {
        return {};
      }

      const rules = Object.keys(field.rules) as unknown as FieldRulePropName[];
      return rules.reduce(
        (acc, ruleName: FieldRulePropName) => {
          const rules = field.rules as FieldRules;
          const rule = rules[ruleName] as FieldRuleHandler;
          acc[ruleName] = rule(field, fields);
          return acc;
        },
        {} as Record<FieldRulePropName, boolean>,
      );
    },
    [],
  );

  const fieldsObject = useMemo(() => {
    const fieldsObject = fieldsProps.reduce<FormFieldsObject>(
      (acc, formField) => {
        acc[formField.name] = {
          ...formField,
          value: values[formField.name],
          error: errors[formField.name],
        };
        return acc;
      },
      {},
    );

    return fieldsProps.reduce<FormFieldsObject>((acc, formField) => {
      acc[formField.name] = {
        ...fieldsObject[formField.name],
        ...evalPropRules(formField, fieldsObject),
      };
      return acc;
    }, {});
  }, [errors, evalPropRules, fieldsProps, values]);

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstFieldInError = document?.getElementById(`${errorKeys[0]}`);
      firstFieldInError?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }, [errors]);

  useEffect(() => {
    if (clearFields) {
      setValues(transformConfigFields(fieldsProps, clearFields));
    }
  }, [fieldsProps, clearFields]);

  const context = useMemo(() => {
    return {
      fields: Object.values(fieldsObject),
      fieldsObject,
      values,
      formTitle,
      onFieldChange,
      clearFieldErrors,
      validate,
      alterFieldProp,
      onAfterError,
      isSubmitDisabled,
      setIsSubmitDisabled,
    } as FormContextType;
  }, [
    fieldsObject,
    values,
    formTitle,
    onFieldChange,
    clearFieldErrors,
    validate,
    alterFieldProp,
    onAfterError,
    isSubmitDisabled,
    setIsSubmitDisabled,
  ]);

  return (
    <FormContext.Provider value={context}>{children}</FormContext.Provider>
  );
};
