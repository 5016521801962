import DetailsCard from 'common/components/organisms/DetailsCard/DetailsCard';
import PropTypes from 'prop-types';

import { StyledCompanyDetailsTab } from '../CompanyDetailPage.styles';

const CompanyInfoTab = ({ companyDetailsSections }) => {
  return (
    <StyledCompanyDetailsTab className="stack">
      {companyDetailsSections.map(
        (section) =>
          section && (
            <DetailsCard
              key={section.title}
              fieldsToDisplay={section.details}
              title={section.title}
              testId={`${section.name}-card`}
            />
          ),
      )}
    </StyledCompanyDetailsTab>
  );
};

CompanyInfoTab.propTypes = {
  companyDetailsSections: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default CompanyInfoTab;
