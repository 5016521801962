import PropTypes from 'prop-types';

import PageHeader from '../../molecules/PageHeader/PageHeader';
import TableEmptyState from '../../molecules/TableEmptyState';
import { StyledTable } from '../Table/Table.styles';
import TableHeader from '../Table/TableHeader';
import TableRow from '../Table/TableRow';
import { StyledTableWithTitleContainer } from './TableWithTitle.styles';

const TableWithTitle = ({
  className,
  itemsToDisplay,
  title,
  headers,
  onClick,
  testId,
  showButton,
  CTAText,
  onCTAClicked,
  maxWidth,
  emptyState,
  onColumnItemClick,
  getId,
  tableTopComponent,
  showHeader,
  showShadow,
}) => (
  <div data-testid={`${testId}-table`} className={`bigStack ${className}`}>
    {title && (
      <PageHeader
        title={title}
        ctaText={showButton && CTAText}
        onCTAClick={showButton && onCTAClicked}
        testId={`${testId}-table-header`}
      />
    )}
    {tableTopComponent}
    {itemsToDisplay.length ? (
      <StyledTableWithTitleContainer showShadow={showShadow}>
        <StyledTable>
          {showHeader && <TableHeader headers={headers} />}
          <tbody>
            {itemsToDisplay.map((item, index) => (
              <TableRow
                onColumnItemClick={onColumnItemClick}
                onClick={onClick && (() => onClick(item.id))}
                key={getId ? getId(item) : index}
                objectForRow={item}
                headers={headers}
                maxWidth={maxWidth}
              />
            ))}
          </tbody>
        </StyledTable>
      </StyledTableWithTitleContainer>
    ) : (
      <TableEmptyState {...emptyState} />
    )}
  </div>
);

TableWithTitle.propTypes = {
  className: PropTypes.string,
  itemsToDisplay: PropTypes.oneOfType([PropTypes.array]).isRequired,
  title: PropTypes.string,
  headers: PropTypes.oneOfType([PropTypes.array]).isRequired,
  showButton: PropTypes.bool,
  onClick: PropTypes.func,
  onColumnItemClick: PropTypes.func,
  testId: PropTypes.string,
  CTAText: PropTypes.string,
  onCTAClicked: PropTypes.func,
  maxWidth: PropTypes.bool,
  emptyState: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonClick: PropTypes.func,
  }),
  getId: PropTypes.func,
  tableTopComponent: PropTypes.node,
  showHeader: PropTypes.bool,
  showShadow: PropTypes.bool,
};

TableWithTitle.defaultProps = {
  className: '',
  onClick: null,
  onColumnItemClick: null,
  testId: null,
  showButton: false,
  CTAText: 'Add new',
  onCTAClicked: () => {},
  maxWidth: false,
  title: null,
  emptyState: {},
  getId: (item) => item.id,
  tableTopComponent: null,
  showHeader: true,
  showShadow: true,
};

export default TableWithTitle;
