import {
  asyncActionKeys,
  CLEAR_DOCUMENT_CREATED,
  CLEAR_DOCUMENTS,
} from './redux.constants';

export const getDocumentsAction = (payload) => ({
  type: asyncActionKeys.GET_DOCUMENTS_START,
  payload,
});

export const createDocumentAction = (payload) => ({
  type: asyncActionKeys.CREATE_DOCUMENT_START,
  payload,
});

export const clearDocumentCreatedAction = () => ({
  type: CLEAR_DOCUMENT_CREATED,
});

export const clearDocumentsAction = () => ({
  type: CLEAR_DOCUMENTS,
});

export const downloadDocumentAction = (payload) => ({
  type: asyncActionKeys.DOWNLOAD_DOCUMENT_START,
  payload,
});

export const viewDocumentAction = (payload) => ({
  type: asyncActionKeys.VIEW_DOCUMENT_START,
  payload,
});

export const deleteDocumentAction = (payload) => ({
  type: asyncActionKeys.DELETE_DOCUMENT_START,
  payload,
});
