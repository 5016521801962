import { queryClient } from 'app/queryClient';
import {
  deletePayrollJustification,
  savePayrollJustification,
} from 'common/api/payrollTaxonomy.api';
import {
  PayrollJustificationAPIPayload,
  PayrollJustificationAPIResponse,
} from 'common/types';
import { useMutation } from 'react-query';

import { notifyError } from '@omnipresentgroup/design-system';

import { payrollKeys } from '../keys';

export const usePayrollJustificationMutation = () => {
  return useMutation(
    (payload: PayrollJustificationAPIPayload) =>
      savePayrollJustification(payload),
    {
      onError: () => {
        notifyError({
          title: 'Error',
          description: 'Failed to save justification.',
        });
      },
      onSuccess: (data, payload) => {
        queryClient.invalidateQueries(
          payrollKeys.payReportJustifications({
            grossToNetId: payload.grossToNetId,
          }),
        );
        queryClient.invalidateQueries(
          payrollKeys.payReportJustifications({
            grossToNetId: payload.grossToNetId,
            employeeId: payload.employeeId,
          }),
        );
        queryClient.invalidateQueries(
          payrollKeys.payReportJustificationsByGTN(),
        );
      },
    },
  );
};

export const useDeletePayrollJustificationMutation = () => {
  return useMutation(
    (justification: PayrollJustificationAPIResponse) =>
      deletePayrollJustification(justification.id),
    {
      onError: () => {
        notifyError({
          title: 'Error',
          description: 'Failed to delete justification.',
        });
      },
      onSuccess: (data, justification) => {
        queryClient.invalidateQueries(
          payrollKeys.payReportJustifications({
            grossToNetId: justification.grossToNetId,
          }),
        );
        queryClient.invalidateQueries(
          payrollKeys.payReportJustifications({
            grossToNetId: justification.grossToNetId,
            employeeId: justification.employeeId,
          }),
        );
        queryClient.invalidateQueries(
          payrollKeys.payReportJustificationsByGTN(),
        );
      },
    },
  );
};
