import { getEmployment } from 'common/api/employments.api';
import { THIRTY_SECONDS } from 'common/constants';
import { EmploymentModel } from 'common/types/employments';
import { useQuery } from 'react-query';

import { useSelectUserRoles } from '../../../hooks';
import { getDataFromResponse } from '../../../react-query-utils';
import { employmentKeys } from '../keys';

export const useEmploymentQuery = (employmentId: string) => {
  const { isAdmin } = useSelectUserRoles();
  const hasPermissionToSeeEmployment = isAdmin;

  return useQuery<EmploymentModel, Error>(
    employmentKeys.single(employmentId),
    () => getEmployment(employmentId).then(getDataFromResponse),
    {
      enabled: hasPermissionToSeeEmployment,
      staleTime: THIRTY_SECONDS,
    },
  );
};
