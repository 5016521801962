import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledTabsContainer = styled.div`
  min-height: 500px;
  width: 100%;
  align-self: center;
  align-items: center;

  .stack:has(.currentTabDivider) {
    color: ${({ theme: { colors } }) =>
      colors['control-background-interactive']};

    .currentTabDivider {
      background-color: ${({ theme: { colors } }) =>
        colors['control-background-interactive']};
    }
  }
`;

export const StyledTabBackLinkContainer = styled.div`
  margin-right: ${({ theme: { space } }) => space[32]};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    display: none;
  }
`;

export const StyledTabsTitlesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${({ theme: { space } }) => `${space[16]}`};
  white-space: nowrap;
  overflow: auto;

  &::after {
    content: '';
    padding-right: ${({ theme: { space } }) => `${space[16]}`};
  }
`;
