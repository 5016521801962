import { ChangeEvent, forwardRef, useEffect, useState } from 'react';

import { Checkbox } from 'common/components/atoms/Checkbox';

import { ListEmployeeData } from '.';

const EmployeeCheckbox = forwardRef(function EmployeeCheckbox(
  { id, ...restProps }: any,
  ref,
) {
  return <Checkbox id={id} ref={ref} size={20} {...restProps} />;
});

export const CheckboxCell = (
  employee: ListEmployeeData,
  onColumnItemClick: (
    event: ChangeEvent<HTMLInputElement>,
    employeeList: ListEmployeeData[],
  ) => void,
) => {
  const [checked, setChecked] = useState(employee.isSelected);

  useEffect(() => {
    setChecked(employee.isSelected);
  }, [employee.isSelected]);

  return (
    <EmployeeCheckbox
      id={`cbx_${employee.id}`}
      checked={checked}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setChecked(!checked);
        onColumnItemClick(event, [employee]);
      }}
    />
  );
};
