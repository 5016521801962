import styled from 'styled-components';

export const StyledUploadDocument = styled.div`
  input {
    display: none;
  }
`;

export const StyledUploadDocumentIcons = styled.div`
  display: flex;
  flex-direction: row;

  svg {
    width: ${({ theme: { space } }) => space[32]};
    height: ${({ theme: { space } }) => space[32]};
  }

  button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledUploadedDocumentContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
    color: ${({ theme: { colors } }) => colors['text-subtle']};
  }
`;

export const StyledLoadingContainer = styled.div`
  display: flex;

  div {
    align-items: end;
    width: ${({ theme: { space } }) => space[32]};
    height: ${({ theme: { space } }) => space[32]};
  }
`;
