import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledFormPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    width: 100%;
    margin: 0;
  }
`;

export const StyledFormPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme: { space } }) => space[64]};
  margin-top: ${({ theme: { space } }) => space[32]};
`;

export const StyledFormPageContent = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  gap: ${({ theme: { space } }) => space[32]};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    flex-direction: column;
  }
`;
