import { useParams } from 'react-router-dom';

import { createLSPUserRequest } from 'common/api/localServiceProviders.api';
import { DuplicateEmailErrorMessage } from 'common/components/molecules/ErrorBanner/ErrorMessages';
import { transformConfigFields } from 'common/components/molecules/Form/FormProvider';
import { HTTPStatusCodesEnum } from 'common/constants/statusCode';
import { LSP_LIST_PAGE } from 'paths';
import { useMutation } from 'react-query';

import { AddManagerFormPage } from '../AddManagerFormPage/AddManagerFormPage';
import { addLSPManagerFormConfig } from './addLSPManagerFormConfig';

export const AddLSPUserPage = () => {
  const { lspId } = useParams<{ lspId: string }>();

  const {
    isLoading,
    isSuccess,
    data,
    isError,
    error,
    mutate: createLSPUser,
  }: Record<string, any> = useMutation((newLSPUser: NewLSPUser) =>
    createLSPUserRequest(
      lspId,
      transformConfigFields(newLSPUser.managerDetails[0]?.fields),
    ),
  );

  const errorMessage =
    isError &&
    error?.response?.status === HTTPStatusCodesEnum.CONFLICT &&
    DuplicateEmailErrorMessage;

  return (
    <AddManagerFormPage
      formConfig={addLSPManagerFormConfig}
      isLoading={isLoading}
      addManager={createLSPUser}
      isSuccess={isSuccess}
      companyId={lspId}
      successScreenPayload={data?.data}
      onCompleteUrl={`${LSP_LIST_PAGE}/${lspId}#managers`}
      isError={isError}
      errorMessage={errorMessage || error?.message}
    />
  );
};

interface NewLSPUser {
  companyid: string;
  managerDetails: LSPUserDetails[];
}
interface LSPUserDetails {
  fields: any[];
  name: string;
  schema: Record<string, any>[];
  stage: number;
}
