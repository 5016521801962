import {
  MergeEmployeesListResponse,
  MergeEmployment,
  MergeWorkLocation,
} from 'common/types';

export const mockMergeHRISEmployeesList: MergeEmployeesListResponse = {
  next: null,
  previous: null,
  results: [
    {
      id: 'a29d8e91-e988-4e76-82b2-efd2714b51ab',
      remote_id: '116',
      employee_number: null,
      company: null,
      first_name: 'boris',
      last_name: 'johbson',
      display_full_name: 'boris johbson',
      work_email: 'boris.johbson@omnipresent.com',
      personal_email: 'boris.johbson@gmail.com',
      mobile_phone_number: null,
      employments: [
        {
          job_title: 'Software Engineer',
          pay_currency: 'USD',
          pay_period: 'YEAR',
          pay_frequency: 'MONTHLY',
          pay_rate: '100000.00',
        } as MergeEmployment,
      ],
      home_location: null,
      work_location: {
        city: 'Denver',
        state: 'Colorado',
        country: 'United States',
      } as MergeWorkLocation,
      manager: null,
      team: null,
      ssn: null,
      gender: null,
      ethnicity: null,
      marital_status: null,
      date_of_birth: null,
      hire_date: null,
      start_date: '2021-08-09T00:00:00.000Z',
      employment_status: 'ACTIVE',
      termination_date: null,
      avatar: null,
      remote_data: null,
    },
    {
      id: '39390721-8b52-4fa3-a9b4-31f3a57508d0',
      remote_id: '115',
      employee_number: '123',
      company: null,
      first_name: 'Bob',
      last_name: 'Murphy',
      display_full_name: 'Bob Murphy',
      work_email: 'bob.murphy@gmail.com',
      personal_email: null,
      mobile_phone_number: null,
      employments: [],
      home_location: null,
      work_location: null,
      manager: null,
      team: null,
      ssn: null,
      gender: null,
      ethnicity: null,
      marital_status: null,
      date_of_birth: null,
      hire_date: null,
      start_date: null,
      employment_status: 'ACTIVE',
      termination_date: null,
      avatar: null,
      remote_data: null,
    },
  ],
};
