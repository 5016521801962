import EmployeeCheckListPage from 'common/components/organisms/EmployeeCheckListPage/EmployeeCheckListPage';
import { AddEmployeeSeatPage } from 'common/pages/AddEditEmployeeSeatPage/AddEmployeeSeatPage';
import { EditEmployeeSeatPage } from 'common/pages/AddEditEmployeeSeatPage/EditEmployeeSeatPage';
import { AddHirePage } from 'common/pages/AddHirePage/AddHirePage';
import { AddLSPUserPage } from 'common/pages/AddLSPUser/AddLSPUserPage';
import { ConfirmEmployeeSeatPage } from 'common/pages/ConfirmEmployeeSeatPage/ConfirmEmployeeSeatPage';
import { EditContract } from 'common/pages/Contracts/Edit';
import { EditCompanyPage } from 'common/pages/EditCompanyPage/EditCompanyPage';
import { EditEmployeePage } from 'common/pages/EditEmployeePage/EditEmployeePage';
import { EmploymentContractPage } from 'common/pages/EmployeeContractPage/EmployeeContractPage';
import { AddEmploymentPage } from 'common/pages/EmployeeDetailsPage/EmploymentHistoryFormPage/AddEmploymentPage';
import { EditEmploymentPage } from 'common/pages/EmployeeDetailsPage/EmploymentHistoryFormPage/EditEmploymentPage';
import EmployeeExpenseDetailPage from 'common/pages/EmployeeExpenseDetailPage/EmployeeExpenseDetailPage';
import { SeatStatementOfWorkPage } from 'common/pages/SeatStatementOfWorkPage/SeatStatementOfWorkPage';
import { SeatToEmployeeConversion } from 'common/pages/SeatToEmployeePage/SeatToEmployeeConversion';
import { commonRoutes } from 'common/routes';
import { AddCompanyManagerPage } from 'omniplatform/admin/pages/AddCompanyManagerPage/AddCompanyManagerPage';
import { PayrollInstanceLspBillDetailPage } from 'omniplatform/admin/pages/PayrollInstanceLspBillDetailPage/PayrollInstanceLspBillDetailPage';
import { TerminationRequestForm } from 'omniplatform/manager/pages/ManagerEmployeePage/Terminations/TerminationForm/TerminationRequestForm';
import { ViewTerminationRequestPage } from 'omniplatform/manager/pages/ManagerEmployeePage/Terminations/TerminationForm/ViewTerminationRequestPage';
import {
  ADD_ADMIN_PAGE,
  ADD_EMPLOYMENT_PAGE,
  ADD_HIRE_PATH,
  ADMIN_LIST_PAGE,
  ALL_EMPLOYEES_PAGE,
  BACK_OFFICE_PAGE,
  COMPANIES_LIST_PAGE,
  CREATE_TERMINATION_REQUEST,
  EDIT_EMPLOYMENT_PAGE,
  EMPLOYEES_PATH,
  EXPENSE_LIST_PAGE,
  FINANCE_PAGE,
  HOME_PAGE_PATH,
  LSP_LIST_PAGE,
  lspBillsDetailPage,
  payReportPreviewPage,
  PAYROLL_INSTANCE_DETAIL_PAGE,
  PAYROLL_PAGE,
  PROVIDERS_EMPLOYER_ADD,
  PROVIDERS_PAGE,
  PROVIDERS_PP_ADD,
  VIEW_TERMINATION_REQUEST,
} from 'paths';

import { AddAdminPage } from './pages/AddAdminPage/AddAdminPage';
import AddCompanyDocumentPage from './pages/AddCompanyDocumentPage/AddCompanyDocumentPage';
import AddEmployeeDocumentPage from './pages/AddEmployeeDocumentPage/AddEmployeeDocumentPage';
import { AdminDetailPage } from './pages/AdminDetailPage/AdminDetailPage';
import { AdminListPage } from './pages/AdminListPage/AdminListPage';
import { AllEmployeesPaginatedPage } from './pages/AllEmployeesPage/AllEmployeesPaginatedPage';
import {
  ContractAutomationMappingList,
  CreateTemplateMapping,
} from './pages/BackOffice/TemplatesMapping';
import { CompanyDetailPage } from './pages/CompanyDetailPage/CompanyDetailPage';
import CompanyEmployeeDetailPage from './pages/CompanyEmployeeDetailPage/CompanyEmployeeDetailPage';
import { CompanyListPage } from './pages/CompanyListPage/CompanyListPage';
import CompanyManagerDetailPage from './pages/CompanyManagerDetailsPage/CompanyManagerDetailPage';
import { CompensationBulkUploadCSVPage } from './pages/CompensationBulkUploadPage/CompensationBulkUploadCSVPage/CompensationBulkUploadCSVPage';
import { CompensationBulkUploadPage } from './pages/CompensationBulkUploadPage/CompensationBulkUploadPage';
import { VerifyBulkUploadPage } from './pages/CompensationBulkUploadPage/VerifyBulkUploadPage/VerifyBulkUploadPage';
import { EditAdminPage } from './pages/EditAdminPage/EditAdminPage';
import { EditManagerPage } from './pages/EditManagerPage/EditManagerPage';
import { BulkUploadStatusPage } from './pages/EmployeesBulkUploadPage/BulkUploadStatusPage';
import { EmployeesBulkUploadPage } from './pages/EmployeesBulkUploadPage/EmployeesBulkUploadPage';
import { FinancePage } from './pages/FinancePage/FinancePage';
import { LspDetailPage } from './pages/LspDetailPage/LspDetailPage';
import { LspListPage } from './pages/LspListPage/LspListPage';
import LspUserDetailsPage from './pages/LspUserDetailsPage/LspUserDetailPage';
import { PayReportPreviewPage } from './pages/PayReportPreviewPage/PayReportPreviewPage';
import { PayrollInstanceDetailPage } from './pages/PayrollInstanceDetailPage/PayrollInstanceDetailPage';
import { PayrollPage } from './pages/PayrollPage';
import { AddEmployerPage } from './pages/ProviderEditPage/Employers/Add/AddEmployerPage';
import { EditEmployerPage } from './pages/ProviderEditPage/Employers/Edit/EditEmployerPage';
import { AddPayrollProviderPage } from './pages/ProviderEditPage/PayrollProvider/Add/AddPayrollProviderPage';
import { EditPayrollProviderPage } from './pages/ProviderEditPage/PayrollProvider/Edit/EditPayrollProviderPage';
import { ProviderListPage } from './pages/ProviderListPage/ProviderListPage';

export const baseAdminRoutes = [
  ...commonRoutes,
  {
    path: [HOME_PAGE_PATH, COMPANIES_LIST_PAGE],
    component: CompanyListPage,
    exact: true,
  },
  {
    path: `${EMPLOYEES_PATH}/:employeeId/add-document`,
    component: AddEmployeeDocumentPage,
    exact: true,
  },
  {
    path: `${EMPLOYEES_PATH}/:employeeId/:seatId/order-form`,
    component: ConfirmEmployeeSeatPage,
    exact: true,
  },
  {
    path: `${EMPLOYEES_PATH}/:employeeId/edit`,
    component: EditEmployeePage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/managers/:managerId/edit`,
    component: EditManagerPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/managers/add`,
    component: AddCompanyManagerPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/managers/:managerId`,
    component: CompanyManagerDetailPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/edit`,
    component: EditCompanyPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId`,
    component: CompanyDetailPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/employee-checklist`,
    component: EmployeeCheckListPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/pending-employee/:seatId/edit`,
    component: EditEmployeeSeatPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/add-pending-employee`,
    component: AddEmployeeSeatPage,
    exact: true,
  },
  {
    path: `${EMPLOYEES_PATH}/:employeeId`,
    component: CompanyEmployeeDetailPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/add-document`,
    component: AddCompanyDocumentPage,
    exact: true,
  },
  {
    path: `/companies/:companyId/pending-employees/:seatId/complete-profile`,
    component: SeatToEmployeeConversion,
    exact: true,
  },
  {
    path: `/companies/:companyId/pending-employees/:seatId/employee-checklist`,
    component: EmployeeCheckListPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/pending-employee/:seatId`,
    component: SeatStatementOfWorkPage,
    exact: true,
  },
  {
    path: PROVIDERS_PAGE,
    component: ProviderListPage,
    exact: true,
  },
  {
    path: PROVIDERS_EMPLOYER_ADD,
    component: AddEmployerPage,
    exact: true,
  },
  {
    path: PROVIDERS_PP_ADD,
    component: AddPayrollProviderPage,
    exact: true,
  },
  {
    path: `${PROVIDERS_PAGE}/employer/:id/edit`,
    component: EditEmployerPage,
    exact: true,
  },
  {
    path: `${PROVIDERS_PAGE}/payroll-provider/:id/edit`,
    component: EditPayrollProviderPage,
    exact: true,
  },
  {
    path: LSP_LIST_PAGE,
    component: LspListPage,
    exact: true,
  },
  {
    path: `${LSP_LIST_PAGE}/:lspId`,
    component: LspDetailPage,
    exact: true,
  },
  {
    path: `${LSP_LIST_PAGE}/:lspId/users/:userId`,
    component: LspUserDetailsPage,
    exact: true,
  },
  {
    path: `${LSP_LIST_PAGE}/:lspId/user/add`,
    component: AddLSPUserPage,
    exact: true,
  },
  {
    path: ALL_EMPLOYEES_PAGE,
    component: AllEmployeesPaginatedPage,
    exact: true,
  },
  {
    path: `${BACK_OFFICE_PAGE}/template-mappings`,
    component: ContractAutomationMappingList,
    exact: true,
  },
  {
    path: `${BACK_OFFICE_PAGE}/template-mappings/create`,
    component: CreateTemplateMapping,
    exact: true,
  },
  {
    path: `${EXPENSE_LIST_PAGE}/:expenseId`,
    exact: true,
    component: EmployeeExpenseDetailPage,
  },
  {
    path: ADD_EMPLOYMENT_PAGE(':employeeId'),
    exact: true,
    component: AddEmploymentPage,
  },
  {
    path: EDIT_EMPLOYMENT_PAGE(':employeeId', ':employmentId'),
    exact: true,
    component: EditEmploymentPage,
  },
  {
    path: CREATE_TERMINATION_REQUEST(':companyId', ':employeeId'),
    exact: true,
    component: TerminationRequestForm,
  },
  {
    path: VIEW_TERMINATION_REQUEST(
      ':companyId',
      ':employeeId',
      ':terminationRequestId',
    ),
    exact: true,
    component: ViewTerminationRequestPage,
  },
  {
    path: ADD_HIRE_PATH(':companyId'),
    component: AddHirePage,
    exact: true,
  },
];

export const payrollRoutes = [
  {
    path: PAYROLL_PAGE,
    component: PayrollPage,
    exact: true,
  },
  {
    path: PAYROLL_INSTANCE_DETAIL_PAGE(':payrollInstanceId'),
    component: PayrollInstanceDetailPage,
    exact: true,
  },
  {
    path: payReportPreviewPage(':payrollInstanceId', ':dataFrameId'),
    component: PayReportPreviewPage,
    exact: true,
  },
  {
    path: lspBillsDetailPage(':payrollInstanceId'),
    component: PayrollInstanceLspBillDetailPage,
    exact: true,
  },
];

export const financeRoutes = [
  ...payrollRoutes,
  { path: FINANCE_PAGE, component: FinancePage, exact: true },
];

export const CSAdminRoutes = [
  {
    path: `${EMPLOYEES_PATH}/:employeeId/contracts/:documentId`,
    component: EmploymentContractPage,
    exact: true,
  },
  {
    path: `${EMPLOYEES_PATH}/:employeeId/contracts/:documentId/edit`,
    component: EditContract,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/employees-upload`,
    component: EmployeesBulkUploadPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/employees-upload/:bulkUploadId`,
    component: BulkUploadStatusPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/bulk-compensation`,
    component: CompensationBulkUploadPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/bulk-compensation/upload`,
    component: CompensationBulkUploadCSVPage,
    exact: true,
  },
  {
    path: `${COMPANIES_LIST_PAGE}/:companyId/bulk-compensation/upload/verify`,
    component: VerifyBulkUploadPage,
    exact: true,
  },
];

export const OPAdminRoutes = [
  ...CSAdminRoutes,
  {
    path: ADMIN_LIST_PAGE,
    component: AdminListPage,
    exact: true,
  },
  {
    path: ADD_ADMIN_PAGE,
    component: AddAdminPage,
    exact: true,
  },
  {
    path: `${ADMIN_LIST_PAGE}/:userId`,
    component: AdminDetailPage,
    exact: true,
  },
  {
    path: `${ADMIN_LIST_PAGE}/:userId/edit`,
    component: EditAdminPage,
    exact: true,
  },
];

export const benefitsSpecialistRoutes = [
  {
    path: PAYROLL_PAGE,
    component: PayrollPage,
    exact: true,
  },
  {
    path: PAYROLL_INSTANCE_DETAIL_PAGE(':payrollInstanceId'),
    component: PayrollInstanceDetailPage,
    exact: true,
  },
];
