import styled, { css } from 'styled-components';

type InputProps = {
  error?: string;
  disabled?: boolean;
} & Partial<HTMLFormElement>;

const errorInput = css`
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-negative']}`};
`;

export const StyledInput = styled.input<InputProps>`
  padding: ${({ theme: { space } }) => space[16]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  border-radius: ${({ theme: { radii } }) => radii.s};
  color: ${({ theme: { colors } }) => colors['text-default']};
  transition: all 300ms ease-in-out;
  background-color: ${(props) =>
    ({ theme: { colors } }) =>
      props.disabled ? colors['gray-100'] : colors['field-background-default']};
  border: ${(props) =>
    ({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${
        props.disabled ? colors['border-subtle'] : colors['border-strong']
      }`};

  ${(props) => (props.error ? errorInput : null)};

  :hover {
    border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  }

  :focus {
    border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-inverse']}`};
    transition: all 300ms ease-in-out;
    background: ${({ theme: { colors } }) =>
      colors['field-background-default']};
  }

  & :disabled {
    background: ${({ theme: { colors } }) =>
      colors['field-background-disabled']};
    border-color: ${({ theme: { colors } }) => colors['border-disabled']};
  }

  & :read-only {
    background: ${({ theme: { colors } }) =>
      colors['field-background-disabled']};
    border-color: ${({ theme: { colors } }) => colors['border-disabled']};
  }
`;
