import { StyledBoldCopy } from 'common/components/atoms/Text';
import styled from 'styled-components';

export const StyledMainContainer = styled.div`
  display: flex;
  text-align: center;
  height: auto;
`;

export const StyledSection = styled.section`
  box-shadow: ${({ theme: { shadows } }) => shadows.m};
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
  display: flex;
  flex-direction: column;
  align-items: safe center;
  padding: ${({ theme: { space } }) => space[32]};
  border-radius: ${({ theme: { radii } }) => radii.s};
  box-sizing: border-box;
  overflow: auto;

  svg {
    vertical-align: middle;
    margin-right: ${({ theme: { space } }) => space[4]};
  }

  p {
    margin-bottom: ${({ theme: { space } }) => space[4]};
  }

  button {
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
    text-transform: unset;
  }
`;

export const StyledStageContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const StyledOnboardingTitle = styled(StyledBoldCopy)`
  display: flex;
  align-items: baseline;
  margin-top: 10;
`;

export const StyledOnboardingText = styled.span`
  margin-left: ${({ theme: { space } }) => space[4]};
`;

export const StyledCountryFlag = styled.span<{
  size: string;
}>`
  font-size: ${(props) => {
    return props.size === 'small'
      ? ({ theme: { fontSizes } }) => fontSizes[2]
      : ({ theme: { fontSizes } }) => fontSizes[6];
  }};
`;

export const StyledDrawerClick = styled.p`
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors['text-default']};
`;

export const StyledStageNumber = styled.div<{
  isCompleted?: boolean;
  isPartiallyCompleted?: boolean;
}>`
  border-radius: ${({ theme: { radii } }) => radii.full};
  border-style: ${({ theme: { borderStyles } }) => borderStyles.solid};
  border-color: ${(props) => {
    return props.isPartiallyCompleted || props.isCompleted
      ? ({ theme: { colors } }) => colors['border-inverse']
      : ({ theme: { colors } }) => colors['border-subtle'];
  }};
  width: ${({ theme: { space } }) => space[16]};
  height: ${({ theme: { space } }) => space[16]};
  padding: ${({ theme: { space } }) => space[4]};
  display: inline-block;
  color: ${(props) => {
    return props.isCompleted
      ? ({ theme: { colors } }) => colors['text-on-color']
      : ({ theme: { colors } }) => colors['text-default'];
  }};
  background-color: ${(props) => {
    if (props.isCompleted) {
      return ({ theme: { colors } }) => colors['background-inverse'];
    }
    return props.isPartiallyCompleted
      ? ({ theme: { colors } }) => colors['background-primary']
      : ({ theme: { colors } }) => colors['gray-200'];
  }};
  align-self: center;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
`;

export const StyledStage = styled.p`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-style: normal;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  color: ${({ theme: { colors } }) => colors['text-default']};
  text-transform: uppercase;
  min-width: 200px;
  width: auto;
`;

export const StyledCheck = styled.div`
  color: ${({ theme: { colors } }) => colors['text-on-color']};
  > svg {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    vertical-align: middle;
  }
`;

export const StyledCompletedCircle = styled.div`
  margin-right: 0;
`;

export const StepBar = styled.div<{
  isCompleted: boolean;
  isFirstStage?: boolean;
  isLastStage?: boolean;
  percentComplete: number;
}>`
  height: 2px;
  width: 100%;
  background-color: ${(props) => {
    if (props.isFirstStage || props.isLastStage) {
      return ({ theme: { colors } }) => colors['background-primary'];
    }
    return props.isCompleted && props.percentComplete === 100
      ? ({ theme: { colors } }) => colors['background-inverse']
      : ({ theme: { colors } }) => colors['gray-200'];
  }};
`;

export const StyledStepBarContainer = styled.div`
  width: 100%;
  padding-top: ${({ theme: { space } }) => space[4]};
  padding-bottom: ${({ theme: { space } }) => space[4]};
`;

export const StyledCircleContainer = styled.div`
  padding-left: ${({ theme: { space } }) => space[8]};
  padding-right: ${({ theme: { space } }) => space[8]};
`;

export const StyledProgressStep = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin-left: 88%;
  width: 100%;
`;

export const StyledCircle = styled.div<{
  percentComplete: number;
  isCompletedStage: boolean;
}>`
  height: ${({ theme: { space } }) => space[8]};
  width: ${({ theme: { space } }) => space[8]};
  background-color: ${(props) => {
    if (props.isCompletedStage && props.percentComplete === 100) {
      return ({ theme: { colors } }) => colors['icon-default'];
    }
    return ({ theme: { colors } }) => colors['icon-disabled'];
  }};
  border-radius: ${({ theme: { radii } }) => radii.full};
`;
