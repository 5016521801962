import EditableInput from 'common/components/molecules/EditableInput/EditableInput';
import { useFormContext } from 'common/components/molecules/Form/FormProvider';
import { Employer, FIELD_TYPE } from 'common/types';
import { getCountryCodeByCountryName } from 'utils/countries';

import { EmployerFormData } from './employer';

type LspSelectionDropdownInput = {
  params: {
    employer?: Employer;
  };
  name: string;
  label: string;
  onFocus: () => void;
  error?: string;
  value?: string | number | boolean | Record<string, unknown> | null;
  onChange?: () => void;
  required?: boolean;
};

const EmployerLspSelectionDropdown = ({
  name,
  label,
  required,
  onChange,
  onFocus,
  error,
  value,
  params: { employer },
}: LspSelectionDropdownInput) => {
  const {
    values: { country },
  } = useFormContext<EmployerFormData>();

  const countryCode = getCountryCodeByCountryName(country);

  return (
    <EditableInput
      name={name}
      label={label}
      value={value}
      required={required}
      type={FIELD_TYPE.EMPLOYER_LSP_SELECTION}
      onFocus={onFocus}
      onChange={onChange}
      key={name}
      errorMessage={error}
      params={{
        employerCountryCode: countryCode,
        employerLspId: employer?.lspId,
      }}
    />
  );
};

export { EmployerLspSelectionDropdown };
