import styled, { css } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

import { StyledSmallCopy } from '../../atoms/Text/Copy.styles';
import { StyledCardContainer } from '../Card/Card.styles';

export const StyledDocumentCard = styled(StyledCardContainer)`
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  cursor: auto;
`;

export const StyledTitleDescription = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: ${({ theme: { space } }) => space[8]};
  justify-content: space-between;
  word-break: break-word;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    flex-direction: column;
  }
`;

export const StyledUploadDate = styled(StyledSmallCopy)`
  align-self: center;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-top: ${({ theme: { space } }) => space[8]};
    align-self: flex-end;
  }
`;

export const StyledDownloadSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};

  a {
    padding: ${({ theme: { space } }) => space[8]};
    color: black;
  }
`;

const deleteSectionDisabled = css`
  svg {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const StyledDeleteSection = styled.button`
  border: ${({ theme: { borderStyles } }) => borderStyles.none};
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  ${(props) => props.disabled && deleteSectionDisabled};

  svg {
    padding: ${({ theme: { space } }) => space[4]};
    cursor: pointer;
  }
`;

export const StyledRightSection = styled.div`
  display: flex;
`;
