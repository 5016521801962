import { createContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { joiResolver } from '@hookform/resolvers/joi';
import { PageContent } from 'app/App.styles';
import { queryClient } from 'app/queryClient';
import { AxiosError, AxiosResponse } from 'axios';
import { createResignation } from 'common/api/resignations.api';
import { Loading } from 'common/components';
import { PageBreadCrumb } from 'common/components/molecules/PageBreadCrumb/PageBreadCrumb';
import { FormData } from 'common/types';
import { PostResignationModel } from 'common/types/resignations';
import { EMPLOYMENT_HISTORY } from 'paths';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { resignationsKeys } from 'utils/queries/employees/resignations/keys';
import { useEmployeeQuery } from 'utils/queries/employees/useEmployeeQuery';

import {
  Button,
  DateFormats,
  DateInput,
  Dropdown,
  formatJSDate,
  Inline,
  Input,
  Link,
  Notification,
  notifyError,
  RadioButtonGroup,
  Stack,
  TextArea,
  Typography,
} from '@omnipresentgroup/design-system';

import { ResignationCancelModal } from './modals/ResignationCancelModal';
import { ResignationConfirmationModal } from './modals/ResignationConfirmationModal';
import { ResignationSuccessModal } from './modals/ResignationSuccessModal';
import {
  questions,
  resignationFormValidationSchema,
  THREE_DAYS_FROM_NOW,
} from './resignationForm.helper';

export type EmployeeResignationRequest = {
  employeeLastWorkingDay: Date;
  isManagerAware: { value: boolean; label: string };
  employeeScheduledLeaves: { value: string; label: string };
  employeeNumberOfVacationsDays?: string;
  resignationReason: { value: string; label: string };
  otherReason: string;
};

export const ResignationRequestContext =
  createContext<EmployeeResignationRequest | null>(null);

export const ResignationFormPage = () => {
  const {
    getValues,
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty: formIsDirty },
    watch,
  } = useForm<FormData>({
    resolver: joiResolver(resignationFormValidationSchema),
    shouldFocusError: true,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      isManagerAware: { label: 'No', value: 'true' },
    },
  });

  const [shouldShowResignationModal, setShouldShowResignationModal] =
    useState(false);

  const [shouldOpenSuccessModal, setShouldOpenSuccessModal] = useState(false);

  const [shouldOpenCancelModal, setShouldOpenCancelModal] = useState(false);

  const { employeeId } = useParams<{ employeeId: string }>();

  const resignationReason = watch('resignationReason', false);

  const { data: employeeData, isLoading: isGetEmployeeLoading } =
    useEmployeeQuery(employeeId, undefined, true);

  const companyName = employeeData?.companyName ?? '';

  const history = useHistory();

  const handleFormSubmit = handleSubmit((data: Record<string, any>) => {
    const resignationPayload: PostResignationModel = {
      companyId: employeeData?.managerId ?? '',
      employeeId: employeeId,
      employmentId: employeeData?.currentEmploymentId ?? '',
      employeeLastWorkingDay: formatJSDate(
        data?.employeeLastWorkingDay,
        DateFormats.FixedDate,
        true,
      ),
      isManagerAware: Boolean(data?.isManagerAware?.value),
      employeeScheduledLeaves: [data?.employeeScheduledLeaves.value],
      employeeNumberOfVacationsDays: Number(
        data?.employeeNumberOfVacationsDays,
      ),
      resignationReason: data?.resignationReason?.value,
      otherReason: data?.otherReason,
    };

    onSubmit(resignationPayload);
  });

  const handleCancel = () => {
    if (!formIsDirty) {
      history.push(EMPLOYMENT_HISTORY);
    }
    setShouldOpenCancelModal(true);
  };

  const {
    isLoading,
    isError,
    error,
    mutate: onSubmit,
  } = useMutation<
    AxiosResponse<PostResignationModel>,
    AxiosError,
    PostResignationModel
  >(
    (newResignation: PostResignationModel) => createResignation(newResignation),
    {
      onSuccess: async () => {
        setShouldOpenSuccessModal(true);
        await queryClient.invalidateQueries(
          resignationsKeys.allResignations(employeeId),
        );
      },
    },
  );

  useEffect(() => {
    if (isError) {
      notifyError({
        title: 'Error',
        description: `Something went wrong while submitting your resignation. More details:  ${error?.message}`,
      });
    }
  }, [error?.message, isError]);

  if (isGetEmployeeLoading) {
    return <Loading />;
  }
  const pageCrumbs = [
    { name: 'Employment history', link: EMPLOYMENT_HISTORY },
    { name: 'Initiate resignation', link: 'active' },
  ];

  return (
    <>
      <ResignationRequestContext.Provider
        value={getValues() as EmployeeResignationRequest}
      >
        {shouldShowResignationModal && (
          <ResignationConfirmationModal
            closeFn={() => {
              setShouldShowResignationModal(!shouldShowResignationModal);
            }}
            onSubmit={handleFormSubmit}
          />
        )}
        {shouldOpenSuccessModal && (
          <ResignationSuccessModal companyName={companyName} />
        )}
      </ResignationRequestContext.Provider>

      {shouldOpenCancelModal && (
        <ResignationCancelModal
          closeFn={() => {
            setShouldOpenCancelModal(!shouldOpenCancelModal);
          }}
          onSubmit={() => {
            history.push(EMPLOYMENT_HISTORY);
          }}
        />
      )}
      <PageContent>
        {isLoading && <Loading />}
        <Stack gap="16">
          <PageBreadCrumb crumbs={pageCrumbs} className="pageCrumbs" />

          <Stack gap="0">
            <Typography size="24" as="h1" weight="medium" hideParagraphSpacing>
              Initiate resignation
            </Typography>
            <Link
              size="14"
              target="_blank"
              color="default"
              icon="ExternalLink"
              type="button"
              to="https://help.omnipresent.com/en/articles/6768568-how-to-resign-in-the-omniplatform"
            >
              Learn how to resign via the OmniPlatform
            </Link>
          </Stack>
          <Stack
            gap="24"
            bg="primary"
            p="32"
            radius="s"
            w={600}
            data-testid="resignation-request-form-page"
            border="subtle"
          >
            <Typography size="20" as="h2" weight="medium" hideParagraphSpacing>
              Submit request
            </Typography>
            <Notification
              id="alert-id"
              intent="warning"
              link={{
                text: 'Contact us',
                to: 'mailto:help@omnipresent.com',
              }}
              title="If you have any doubts about the process, contact us prior to initiating the resignation form."
            />
            <Controller
              name="employeeLastWorkingDay"
              control={control}
              render={({ field }) => (
                <DateInput
                  helperText="Select a date that is at least 72 hours from today"
                  id="employeeLastWorkingDay"
                  minDate={THREE_DAYS_FROM_NOW}
                  placeholderText={DateFormats.FixedDate.toLocaleLowerCase()}
                  label="What is your intended last working day?"
                  errorMessage={errors['employeeLastWorkingDay']?.message}
                  {...field}
                  useUTC
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                />
              )}
            />
            <Controller
              name="isManagerAware"
              control={control}
              render={({ field }) => (
                <RadioButtonGroup
                  display="vertical"
                  id="isManagerAware"
                  labelElement={
                    <Stack gap="0">
                      <Typography as="span" size="14" weight="medium">
                        Has your resignation been communicated to your manager
                        yet?
                      </Typography>
                      <Typography
                        as="span"
                        size="14"
                        weight="regular"
                        color="helper"
                      >
                        When you submit this form, your manager will
                        automatically be informed of your intent to resign
                      </Typography>
                    </Stack>
                  }
                  options={questions.isManagerAware.answers}
                  errorMessage={errors['isManagerAware']?.message}
                  {...field}
                  onChange={(value) => {
                    const { checked, id } = value.target;
                    const currentOption = questions.isManagerAware.answers.find(
                      (option) => option.id === id,
                    );
                    const isManagerAwareOption = {
                      label: currentOption?.label,
                      value: checked.toString(),
                    };
                    field.onChange(isManagerAwareOption);
                  }}
                />
              )}
            />
            <Controller
              name="employeeScheduledLeaves"
              control={control}
              render={({ field }) => (
                <Dropdown
                  id="employeeScheduledLeaves"
                  label="Are you currently on time off or are scheduled to take time off?"
                  placeholder="Choose an option"
                  isSearchable={false}
                  options={questions.employeeScheduledLeaves.answers}
                  errorMessage={errors['employeeScheduledLeaves']?.message}
                  {...field}
                />
              )}
            />
            <Input
              id="employeeNumberOfVacationsDays"
              type="number"
              placeholder="Enter the number of days"
              label="How many days of vacation have you taken?"
              errorMessage={errors['employeeNumberOfVacationsDays']?.message}
              {...register('employeeNumberOfVacationsDays')}
            />
            <Controller
              name="resignationReason"
              control={control}
              render={({ field }) => (
                <Dropdown
                  id="resignationReason"
                  label="Please share the reason for your resignation"
                  placeholder="Choose an option"
                  isSearchable={false}
                  options={questions.resignationReasons.answers}
                  errorMessage={errors['resignationReason']?.message}
                  helperText="This information is not shared with your manager or employer"
                  {...field}
                  isMulti={false}
                />
              )}
            />

            {resignationReason?.value === 'other' && (
              <Controller
                name="otherReason"
                control={control}
                render={({ field }) => (
                  <TextArea
                    helperText="Inform us of any concerns or feedback regarding your employment. This will not be shared with your manager or employer. "
                    id="otherReason"
                    label="Is there anything else you would like to share with us?"
                    placeholder="Other reason"
                    errorMessage={errors['otherReason']?.message}
                    {...field}
                  />
                )}
              />
            )}
            <Typography size="14" as="p">
              Upon submission of this form, we will begin your offboarding and a
              member of our team will get in touch on the next steps. This
              includes preparation of any documentation and other details.
            </Typography>
            <Inline justify="space-between">
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                onClick={() => setShouldShowResignationModal(true)}
                disabled={!isValid}
              >
                Submit request
              </Button>
            </Inline>
          </Stack>
        </Stack>
      </PageContent>
    </>
  );
};
