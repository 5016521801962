import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { updateTemplateMapping } from '../api';
import type {
  UpdateTemplateMappingAPIResponse,
  UpdateTemplateMappingPayload,
} from '../types';

export const useUpdateTemplateMappingMutation = () =>
  useMutation<
    UpdateTemplateMappingAPIResponse,
    AxiosError,
    { templateId: string } & UpdateTemplateMappingPayload
  >(
    ({
      templateId,
      disabled,
    }: { templateId: string } & UpdateTemplateMappingPayload) =>
      updateTemplateMapping({ templateId, disabled }),
  );
