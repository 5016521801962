import { Auth0Provider } from '@auth0/auth0-react';
import history from 'app/appHistory';
import PropTypes from 'prop-types';

const Auth0ProviderWithHistoryPropTypes = {
  children: PropTypes.node.isRequired,
};

export const Auth0ProviderWithHistory = ({ children }) => {
  const onRedirectCallback = (appState) => {
    history.push(
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname,
    );
  };

  const providerConfig = {
    domain: process.env.VITE_AUTH0_DOMAIN,
    clientId: process.env.VITE_AUTH0_CLIENT_ID,
    redirectUri: window.location.origin,
    onRedirectCallback,
    audience: process.env.VITE_AUTH0_AUDIENCE,
  };

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};

Auth0ProviderWithHistory.propTypes = Auth0ProviderWithHistoryPropTypes;
