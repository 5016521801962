import { StyledBodyCopy } from 'common/components/atoms/Text';
import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledKeyFactsWrapper = styled.div`
  height: auto;
  width: 100%;
  border-left: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  padding-left: ${({ theme: { space } }) => space[64]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    border-left: none;
    padding: ${({ theme: { space } }) =>
      `${space[32]} ${space[0]} ${space[32]} ${space[0]}`};
    border-top: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  }
`;

export const StyledKeyFactsEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 2;

  a {
    margin-top: ${({ theme: { space } }) => space[16]};
  }
`;

export const ScrollableTextContainer = styled.div`
  overflow-y: auto;
  max-height: auto;
  height: 100%;
  align-self: baseline;
`;

export const StyleSectionRendererContainer = styled.div`
  margin-top: ${({ theme: { space } }) => space[16]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};

  > * {
    display: block;
    margin-top: ${({ theme: { space } }) => space[8]};
    margin-bottom: ${({ theme: { space } }) => space[8]};
    white-space: pre-wrap;
  }
`;

export const PrimaryTextContainer = styled(StyledBodyCopy)`
  margin-bottom: ${({ theme: { space } }) => space[4]};
  color: ${({ theme: { colors } }) => colors['text-default']};
`;

export const StyledTitle = styled.p`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.bold}`};
  color: ${({ theme: { colors } }) => `${colors['text-default']}`};
  margin: 0;
`;

export const StyledKeyFactsWrapperContent = styled.div`
  flex-grow: 1;
  width: 100%;
  align-self: baseline;
  margin-bottom: ${({ theme: { space } }) => `${space[16]}`};
`;
