import { getUserSelector } from 'app/store/app.selectors';
import { getCompaniesRequest } from 'common/api/companies.api';
import { Company } from 'common/types';
import { useQuery, UseQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';
import { getDataFromResponse } from 'utils/react-query-utils';
import { isAdmin } from 'utils/user';

import { sortAlphabetically } from '../../../sort.util';
import { companiesKeys } from '../keys';

export const useCompaniesQuery = (
  options?: Pick<UseQueryOptions, 'staleTime' | 'refetchOnWindowFocus'>,
) => {
  const { staleTime, refetchOnWindowFocus } = options || {};
  const user = useSelector(getUserSelector);
  const hasPermissionToGetAllCompanies = isAdmin(user);
  return useQuery<Company[], Error>(
    companiesKeys.default,
    () => getCompaniesRequest().then(getDataFromResponse),
    {
      staleTime,
      refetchOnWindowFocus: Boolean(refetchOnWindowFocus),
      enabled: hasPermissionToGetAllCompanies,
      select: (data) => {
        return sortAlphabetically('name', data);
      },
    },
  );
};
