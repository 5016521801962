import styled from 'styled-components';

export const IconButton = styled.div`
  width: ${({ theme: { space } }) => space[32]};
  height: ${({ theme: { space } }) => space[32]};
  margin-left: ${({ theme: { space } }) => space[32]};
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
