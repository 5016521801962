import { useEffect, useState } from 'react';

import { FormCheckbox } from 'common/components/molecules/FormCheckbox';
import { ONE_SECOND } from 'common/constants';
import flatten from 'lodash/flatten';
import { useForm } from 'react-hook-form';
import {
  StepModifications,
  useOnboardingProgressMutation,
} from 'utils/queries/employeeOnboardingProgress/useOnboardingProgressMutation';

import { OnboardingStage, Step } from '../../OnboardingProgressTracker/types';
import { OnboardingProgressDrawerStage } from '../OnboardingProgressDrawerStage';
import {
  getCompletedStepsDefaultValues,
  getStepModifications,
  StepChangeStatus,
  StepModification,
} from './EditableDrawerFormUtil';

const shouldShowLspModal = (
  stages: OnboardingStage[],
  completedSteps: string[],
  stepModifications: StepModification[],
  providerId?: string,
) => {
  const hasEmployerAssigned = !!providerId;
  if (hasEmployerAssigned) {
    return false;
  }

  const totalSteps = flatten(stages.map((stage) => stage.steps)).length;
  const checkedSteps = stepModifications.filter(
    (step) => step?.status === StepChangeStatus.Checked,
  );
  const uncheckedSteps = stepModifications.filter(
    (step) => step?.status === StepChangeStatus.Unchecked,
  );
  const totalStepsAfterUpdate =
    completedSteps.length + checkedSteps.length - uncheckedSteps.length;

  return totalStepsAfterUpdate === totalSteps;
};

export const EditableDrawerForm = ({
  stages,
  completedSteps,
  setIsUpdatingDrawer,
  lspId,
  setShowLspModal,
}: {
  stages: OnboardingStage[];
  completedSteps: string[];
  setShowLspModal: (value: boolean) => void;
  lspId?: string;
  employeeName?: string;
  employeeId?: string;
  setIsUpdatingDrawer: (value: boolean) => void;
}) => {
  const [showProviderModal, setShowModal] = useState(false);

  const defaultValues = getCompletedStepsDefaultValues(completedSteps);

  const { register, watch } = useForm({
    defaultValues,
  });

  const watchAllFields = watch();
  const updateProgressTrackerMutation = useOnboardingProgressMutation();
  const stepModifications = getStepModifications({
    completedSteps,
    formValues: watchAllFields,
  });

  const updateCompletedSteps = () => {
    const shouldShowModal = shouldShowLspModal(
      stages,
      completedSteps,
      stepModifications,
      lspId,
    );
    setShowLspModal(shouldShowModal);
    setShowModal(shouldShowModal);
    if (!shouldShowModal) {
      setIsUpdatingDrawer(true);
      updateProgressTrackerMutation.mutate(
        {
          updatedSteps: {
            stepModifications: stepModifications,
          } as unknown as StepModifications,
        },
        {
          onError: (err) => console.error(err),
          onSettled: () => setIsUpdatingDrawer(false),
        },
      );
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const intervalId = setTimeout(() => {
      try {
        if (stepModifications.length && !showProviderModal) {
          updateCompletedSteps();
        }
      } catch {}
    }, ONE_SECOND);

    return () => clearTimeout(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepModifications.length]);

  return (
    <form id="EditableDrawerForm">
      {stages.map((stage: OnboardingStage, index: number) => (
        <OnboardingProgressDrawerStage
          key={stage.id}
          stage={stage}
          index={index}
          totalStages={stages.length}
          completedSteps={completedSteps}
        >
          {stage.steps.map((step: Step) => {
            return (
              <FormCheckbox
                size={16}
                key={step.id}
                label={step.name}
                id={step.id}
                {...register(step.id)}
              />
            );
          })}
        </OnboardingProgressDrawerStage>
      ))}
    </form>
  );
};
