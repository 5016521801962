import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getPasswordChangeLink } from 'common/api/auth.api';
import Loading from 'common/components/atoms/Loading/Loading';

const ChangePassword = () => {
  const location = useLocation();

  useEffect(() => {
    const goToChangePasswordPage = async () => {
      const token = new URLSearchParams(location.search).get('token');
      const {
        data: { url: passwordChangePage },
      } = await getPasswordChangeLink(token);
      window.location.assign(passwordChangePage);
    };

    goToChangePasswordPage();
  }, [location.search]);
  return <Loading />;
};

export default ChangePassword;
