import { AppRouter } from 'app/AppRouter';
import {
  useEmployeeGetStarted,
  useErrorBanner,
  useIntercom,
  useLaunchDarkly,
  useMixpanelTracking,
  usePlanhatIntegration,
  useProfileInputScreen,
} from 'app/hooks';
import { ErrorBanner } from 'common/components';
import { EmployeeGetStartedModal } from 'common/pages/EmployeeGetStartedPage/EmployeeGetStartedModal';

import { TransformedUser } from './store/app.transformers';

type Props = {
  // Here, it's a certainty that User is fetched and loaded
  user: TransformedUser;
};

export const AppContent = ({ user }: Props) => {
  const { showErrorBanner, errorMessage, clearError } = useErrorBanner();
  const {
    showEmployeeGetStarted,
    isPrimerModalOpen,
    ...employeeGetStartedProps
  } = useEmployeeGetStarted(user);

  useLaunchDarkly(user);
  useIntercom(user);
  useMixpanelTracking(user);
  usePlanhatIntegration(user);
  // As it introduces side effects with redirection and all, maybe it should not be hidden in the hook
  useProfileInputScreen(user);

  return (
    <>
      <EmployeeGetStartedModal
        open={showEmployeeGetStarted && isPrimerModalOpen}
        {...employeeGetStartedProps}
      />
      {showErrorBanner && (
        <ErrorBanner onErrorClear={clearError} errorMessage={errorMessage} />
      )}
      <AppRouter user={user} />
    </>
  );
};
