import { getInvoicesLogs } from 'common/api/payroll.api';
import { transformInvoicesLogsAPIResponse } from 'common/store/transformers/payroll.transformer';
import { InvoicesLogsQueryOptions } from 'common/types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useInfiniteQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { payrollKeys } from '../keys';

export const useInvoiceLogsQuery = (queryOptions: InvoicesLogsQueryOptions) => {
  const clientOrEmployeeName = get(
    queryOptions,
    'clientOrEmployeeNameQuery',
    undefined,
  );
  return useInfiniteQuery(
    payrollKeys.invoiceLogs(queryOptions),
    ({ pageParam }) =>
      getInvoicesLogs(
        {
          ...queryOptions,
          clientOrEmployeeNameQuery: isEmpty(clientOrEmployeeName)
            ? undefined
            : clientOrEmployeeName,
        },
        {
          nextPageKey: pageParam,
        },
      ).then(getDataFromResponse),
    {
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      select: (data) => ({
        pageParams: data.pageParams,
        pages: data.pages.map((p) => transformInvoicesLogsAPIResponse(p.items)),
      }),
    },
  );
};
