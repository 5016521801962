import JoiImport from 'joi';

import DateExtension from '@omnipresentgroup/joi-date';

const Joi = JoiImport.extend(DateExtension);

export const expenseSchema = Joi.object().keys({
  title: Joi.string().messages({
    'string.empty': 'Please enter a title',
  }),
  description: Joi.string().messages({
    'string.empty': 'Please enter a description',
  }),
  category: Joi.string().messages({
    'string.empty': 'Please enter a category',
  }),
  amount: Joi.object()
    .keys({
      amount: Joi.number().required(),
      currency: Joi.string().required(),
    })
    .messages({
      'object.base': 'Please enter a valid annual base salary',
    }),
  dateSpent: Joi.date().format('YYYY-MM-DD').messages({
    'date.empty': 'Please enter a start date',
    'date.min': 'Please enter a date in the future',
  }),
  documentId: Joi.any(),
});
