import {
  InitialInvoiceStatusType,
  SimpleInvoice,
  SimpleInvoiceType,
} from 'omniplatform/admin/pages/FinancePage/types';

import { TagIntent, type IsoDateTime } from '@omnipresentgroup/design-system';

import { CompanyId, DepositType } from '.';

export type ID = {
  id: string;
  name: string;
  country: string;
};

// changed some fields from mandatory to optionals
// because the API response doesn't return all mandatory data
// TODO: verify with BE what generates the issue
type Employee = ID & {
  payrollDetails?: {
    managementFee?: { amount?: string; currency?: string };
    setupFee?: { amount?: string; currency?: string };
    billingCurrency?: string;
    hasDepositInsurance?: boolean;
    depositType?: DepositType;
  };
  employeeType?: string;
};

export type LspCompanyID = ID & { externalId: string };

export type InvoiceId = string;
export type EmployeeId = string;

export type Export = {
  id: string;
  dateTimeExported?: IsoDateTime;
};

export type EmployeeBillingItemApiResponse = {
  id: string;
  employeeId: EmployeeId;
  companyId: CompanyId;
  invoiceNumber: string;
  invoiceId: InvoiceId;
  invoiceDate: string;
  currency: string;
  createdAt: IsoDateTime;
  lspId: LspId;
  clientInvoiceExportId?: string;
  paymentInstructionExportId?: string;
  totalAmount: string;
  salaryAmount?: string;
  lspFeeAmount?: string;
  expenseAmount?: string;
  variableAmount?: string;
  socialCostsAmount?: string;
  visaFeesAmount?: string;
  pensionContributionAmount?: string;
  onOffAmount?: string;
  depositAmount?: string;
  benefitAmount?: string;
  allowanceAmount?: string;
  otherFees?: string;
  nonStatutoryPensionContributionAmount?: string;
  nonStatutoryBenefitAmount?: string;
};

export enum EmployeeCostsLabels {
  totalAmount = 'Employee total',
  salaryAmount = 'Salary',
  lspFeeAmount = 'LSP Management Fee',
  expenseAmount = 'Expenses',
  variableAmount = 'Variable Pay',
  socialCostsAmount = 'Social Costs',
  visaFeesAmount = 'Visa Fees',
  pensionContributionAmount = 'Pension Contribution',
  onOffAmount = 'LSP On/Offboarding Fee',
  depositAmount = 'LSP Deposit',
  benefitAmount = 'Benefit Contribution',
  allowanceAmount = 'Allowance',
  otherFees = 'Other / Admin Fees',
  nonStatutoryPensionContributionAmount = 'Non Statutory Pension Contribution',
  nonStatutoryBenefitAmount = 'Non Statutory Benefit Contribution',
}

export type EmployeeCostsName = keyof EmployeeCosts;
export type EmployeeCost = {
  amount: number;
  name: EmployeeCostsName;
  label: string;
};

export type EmployeeCosts = {
  totalAmount: EmployeeCost;
  salaryAmount?: EmployeeCost;
  lspFeeAmount?: EmployeeCost;
  expenseAmount?: EmployeeCost;
  variableAmount?: EmployeeCost;
  socialCostsAmount?: EmployeeCost;
  visaFeesAmount?: EmployeeCost;
  pensionContributionAmount?: EmployeeCost;
  onOffAmount?: EmployeeCost;
  depositAmount?: EmployeeCost;
  benefitAmount?: EmployeeCost;
  allowanceAmount?: EmployeeCost;
  otherFees?: EmployeeCost;
  nonStatutoryPensionContributionAmount?: EmployeeCost;
  nonStatutoryBenefitAmount?: EmployeeCost;
};

export type EmployeeBillingItem = {
  id: string;
  employeeId: EmployeeId;
  companyId: CompanyId;
  invoiceNumber: string;
  invoiceId: InvoiceId;
  invoiceDate: string;
  currency: string;
  createdAt: IsoDateTime;
  lspId: LspId;
  clientInvoiceExportId?: string;
  paymentInstructionExportId?: string;
  costs: EmployeeCosts;
};

export type BillingItemApiResponse = {
  clientCsvUrl: string;
  clientInvoice?: Export;
  clientInvoiceExportId?: string;
  company: ID;
  createdAt: IsoDateTime;
  createdByAuthId: string;
  // Change this to a currency code union type
  currency: string;
  employee: Employee;
  id: string;
  invoiceDate: string;
  invoiceId: InvoiceId;
  invoiceNumber: string;
  lsp: ID;
  lspCsvUrl: string;
  lspPayment?: Export;
  paymentInstructionExportId?: string;
  totalAmount: string;
  variableAmount?: string;
  allowanceAmount?: string;
  benefitAmount?: string;
  depositAmount?: string;
  expenseAmount?: string;
  lspFeeAmount?: string;
  nonStatutoryBenefitAmount?: string;
  nonStatutoryNonTaxableBenefitAmount?: string;
  nonStatutoryNonTaxablePensionContributionAmount?: string;
  nonStatutoryPensionContributionAmount?: string;
  onOffAmount?: string;
  otherFees?: string;
  pensionContributionAmount?: string;
  salaryAmount?: string;
  socialCostsAmount?: string;
  visaFeesAmount?: string;
  isBenefit: boolean;
};

export type PaginatedBillingItemApiResponse = {
  items: BillingItemApiResponse[];
  nextPageKey: string | undefined;
};

type Company = ID & {
  externalId: string;
  isVATExempt: boolean;
};

type BillingItemMandatoryFields = {
  company: Company;
  createdAt: string;
  createdByAuthId: string;
  currency: string;
  employee: Employee;
  id: string;
  invoiceDate: string;
  invoiceId: string;
  lsp: ID;
  totalAmount: string;
};

type BillingItemOptionalFields = {
  allowanceAmount?: string;
  benefitAmount?: string;
  clientCsvUrl?: string;
  clientInvoiceExportId?: string;
  depositAmount?: string;
  expenseAmount?: string;
  invoiceNumber?: string;
  lspCsvUrl?: string;
  lspFeeAmount?: string;
  nonStatutoryBenefitAmount?: string;
  nonStatutoryNonTaxableBenefitAmount?: string;
  nonStatutoryNonTaxablePensionContributionAmount?: string;
  nonStatutoryPensionContributionAmount?: string;
  onOffAmount?: string;
  otherFees?: string;
  paymentInstructionExportId?: string;
  paymentProcessedAt?: IsoDateTime;
  paymentStatus?: string;
  pensionContributionAmount?: string;
  salaryAmount?: string;
  socialCostsAmount?: string;
  visaFeesAmount?: string;
  updatedAt?: string;
  updatedByAuthId?: string;
  variableAmount?: string;
};

// TODO: rename this type after check if we can have only one type for BillingItem
export type SingleBillingItem = BillingItemMandatoryFields &
  BillingItemOptionalFields;

export type InvoiceFormBillingItem = {
  company: {
    id: string;
    name: string;
  };
  employee: {
    id: string;
    name: string;
  };
  totalAmount: string;
  allowanceAmount?: string;
  benefitAmount?: string;
  clientCsvUrl?: string;
  clientInvoiceExportId?: string;
  depositAmount?: string;
  expenseAmount?: string;
  invoiceNumber?: string;
  lspFeeAmount?: string;
  nonStatutoryBenefitAmount?: string;
  nonStatutoryNonTaxableBenefitAmount?: string;
  nonStatutoryNonTaxablePensionContributionAmount?: string;
  nonStatutoryPensionContributionAmount?: string;
  onOffAmount?: string;
  otherFees?: string;
  paymentStatus?: string;
  pensionContributionAmount?: string;
  salaryAmount?: string;
  socialCostsAmount?: string;
  visaFeesAmount?: string;
  variableAmount?: string;
};

export type LspBillingItemApiResponse = {
  clientInvoiceExportId: string;
  company: LspCompanyID;
  createdAt: IsoDateTime;
  createdByAuthId: string;
  currency: string;
  depositAmount: string;
  employee: Employee;
  expenseAmount: string;
  gstAmount: string;
  healthAmount: string;
  id: string;
  incTaxAmount: string;
  invoiceDate: string;
  invoiceId: InvoiceId;
  invoiceNumber: string;
  lsp: ID;
  lspFeeAmount: string;
  onOffAmount: string;
  paymentInstructionExportId: string;
  pensionContributionAmount: string;
  salaryAmount: string;
  socialCostsAmount: string;
  visaFeesAmount: string;
  stLoanAmount: string;
  totalAmount: string;
  variableAmount: string;
  allowanceAmount?: string;
  benefitAmount?: string;
  nonStatutoryBenefitAmount?: string;
  nonStatutoryNonTaxableBenefitAmount?: string;
  nonStatutoryNonTaxablePensionContributionAmount?: string;
  nonStatutoryPensionContributionAmount?: string;
  otherFees?: string;
};

export type BillingItem = {
  id: string;
  invoiceId: InvoiceId;
  invoiceNumber: string;
  invoiceDate: string;
  amount: string | number;
  lspName: string;
  lspCountryName: string;
  companyName: string;
  employeeName: string;
  currency: string;
  employeeDetails: {
    billingCurrency?: string;
    managementFee?: string;
  };
  clientCsvUrl: string;
  lspCsvUrl: string;
  lspPayment: Export | undefined;
  clientInvoice: Export | undefined;
  createdAt: IsoDateTime;
  billingBits: { type: string; amount: string | number }[];
  isBenefit?: boolean;
};

export type BillingItemApiRequest = {
  employeeId: EmployeeId;
  companyId: CompanyId;
  salaryAmount?: string;
  socialCostsAmount?: string;
  visaFeesAmount?: string;
  lspFeeAmount?: string;
  expenseAmount?: string;
  variableAmount?: string;
  allowanceAmount?: string;
  otherFees?: string;
  pensionContributionAmount?: string;
  nonStatutoryPensionContributionAmount?: string;
  benefitAmount?: string;
  nonStatutoryBenefitAmount?: string;
  onOffAmount?: string;
  depositAmount?: string;
  totalAmount?: string;
};

export type ExportInvoiceApiRequest = {
  lspId: string;
  invoiceDate: string;
  billingItems: BillingItemApiRequest[];
};

export enum ClientInvoiceStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  WITH_ERRORS = 'WITH_ERRORS',
  VALIDATING = 'VALIDATING',
  VALIDATED = 'VALIDATED',
  GENERATING = 'GENERATING',
  GENERATED = 'GENERATED',
  WITH_PENDING_INVOICES = 'WITH_PENDING_INVOICES',
}

export enum ClientInvoiceEmployeeStatus {
  VALIDATING = 'VALIDATING',
  WITH_ERRORS = 'WITH_ERRORS',
  VALIDATED = 'VALIDATED',
  GENERATING = 'GENERATING',
  GENERATED = 'GENERATED',
}

export enum ClientInvoiceEmployeeBillingBitType {
  LSP_FEE = 'LSP_FEE',
  SALARY = 'SALARY',
  EXPENSE = 'EXPENSE',
  VARIABLE = 'VARIABLE',
  SOCIAL_COSTS = 'SOCIAL_COSTS',
  VISA_FEES = 'VISA_FEES',
  PENSION_CONTRIBUTION = 'PENSION_CONTRIBUTION',
  ON_OFF = 'ON_OFF',
  OTHER_FEES = 'OTHER_FEES',
  DEPOSIT = 'DEPOSIT',
  BENEFIT = 'BENEFIT',
  ALLOWANCE = 'ALLOWANCE',
  NON_STATUTORY_PENSION_CONTRIBUTION = 'NON_STATUTORY_PENSION_CONTRIBUTION',
  NON_STATUTORY_BENEFIT = 'NON_STATUTORY_BENEFIT',
  NON_STATUTORY_NON_TAXABLE_PENSION_CONTRIBUTION = 'NON_STATUTORY_NON_TAXABLE_PENSION_CONTRIBUTION',
  NON_STATUTORY_NON_TAXABLE_BENEFIT = 'NON_STATUTORY_NON_TAXABLE_BENEFIT',
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
  FX_FEE = 'FX_FEE',
  ZERO_DEPOSIT_INSURANCE_FEE = 'ZERO_DEPOSIT_INSURANCE_FEE',
  NON_STATUTORY_PENSION_CONTRIBUTION_FEE = 'NON_STATUTORY_PENSION_CONTRIBUTION_FEE',
  NON_STATUTORY_BENEFIT_FEE = 'NON_STATUTORY_BENEFIT_FEE',
}

export enum ClientInvoiceEmployeeBillingBitTaxType {
  NO_VAT = 'NO_VAT',
  TWENTY_PERCENT_VAT_ON_INCOME = '20% (VAT on Income)',
}

export type ClientInvoicesApiResponse = {
  items: ClientInvoiceApiResponse[];
  nextPageKey: string | undefined;
};

export type ClientInvoiceCompany = Company & {
  name: string;
  country: string;
  fxConversionPercent: string;
  invoiceIds: InvoiceId[];
};

export type Companies = Record<CompanyId, ClientInvoiceCompany>;

export type ClientInvoices = Record<ClientInvoiceID, ClientInvoice>;

export type ClientInvoiceEmployee = {
  id: string;
  clientInvoiceId: string;
  payElementId: string;
  employeeId: string;
  employee?: Omit<Employee, 'country'>;
  status: ClientInvoiceEmployeeStatus;
  errorMessage: string;
  createdAt: IsoDateTime;
  reference: string | null;
  clientInvoiceEmployeeBillingBits: ClientInvoiceEmployeeBillingBit[];
  amount: string;
  payElements?: PayElement[];
  payElementCurrency?: string;
  fxRate?: string;
};

export type ClientInvoiceEmployeeBillingBit = {
  id: string;
  clientInvoiceEmployeeId: string;
  amount: string;
  description: string | null;
  type: ClientInvoiceEmployeeBillingBitType;
  clientCode: string | null;
  taxType: ClientInvoiceEmployeeBillingBitTaxType;
  createdAt: IsoDateTime;
};

export type ClientInvoiceApiResponse = {
  id: string;
  companyId: string;
  payrollInstanceId: string;
  invoiceNumber: string | null;
  status: ClientInvoiceStatus;
  billingCurrency: string;
  date: IsoDateTime;
  dueDate: IsoDateTime;
  exportedTo: string | null;
  exportId: string | null;
  company: Omit<ClientInvoiceCompany, 'invoiceIds'>;
  clientInvoiceEmployees: ClientInvoiceEmployee[];
  exportedAt: IsoDateTime | null;
  exportedBy: string | null;
  createdAt: IsoDateTime;
};

export type ClientInvoice = ClientInvoiceApiResponse & {
  clientInvoiceEmployeeIds: string[];
};

export type PayrollClientInvoice = {
  itemId: string;
  currency: string;
  billingItemsIds: string[];
  billingItems: BillingItem[];
};

export type Client = ID & {
  invoiceIds: InvoiceId[];
};

export type ClientInvoicesExportData = {
  companies: Companies;
  invoices: ClientInvoices;
};

type ClientID = string;
type ClientInvoiceID = string;

export type Clients = Record<ClientID, Client>;

export type PayrollClientInvoices = Record<
  ClientInvoiceID,
  PayrollClientInvoice
>;
export type ClientsExportData = {
  clients: Clients;
  invoices: PayrollClientInvoices;
};
export type ClientExportApiResponse = {
  exportId: string;
  url: string;
};
export type ExportDownloadLinkApiResponse = {
  downloadLink: string;
};

export type ExportInvoiceDataToCsvApiResponse = {
  url: string;
};

export type LspId = string;
export type LspList = Record<LspId, ID>;
export type CurrencyId = string;

export type BenefitInvoiceAPIRequest = {
  billingCurrency: string;
  employeeId: string;
  nonStatutoryNonTaxableBenefitAmount?: number;
  nonStatutoryNonTaxablePensionContributionAmount?: number;
};

type BillingItemFromSpreadsheet = {
  employee: {
    id: string;
    name: string;
  };
  company: {
    id: string;
    name: string;
  };
  total: number;
  salary?: number;
  socialCosts?: number;
  visaFees?: number;
  lspFee?: number;
  expense?: number;
  variable?: number;
  allowance?: number;
  otherFees?: number;
  pensionContribution?: number;
  nonStatutoryPensionContribution?: number;
  benefit?: number;
  nonStatutoryBenefit?: number;
  onOff?: number;
  deposit?: number;
};

export type InvoiceFromSpreadsheet = {
  invoiceTotal: number;
  costs: BillingItemFromSpreadsheet[];
  errors?: Array<string>;
};

export type BenefitsUploadCountry = {
  country: string;
  totalEmployees: number;
};

export type ImportedBenefitsBillingsStatsResponse =
  Array<BenefitsUploadCountry>;

export type BenefitsBillingItem = {
  employeeExternalId: string;
  employeeName: string;
  employeeCost: number;
};

export type ImportedBenefitsBillingsByClientsResponse =
  Array<BenefitsBillingItem>;

export type BenefitsUploadResponse = {
  totalRecords: number;
  countries: Array<BenefitsUploadCountry>;
  errors?: Array<string>;
};

export enum PayrollInstanceStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  VALIDATING = 'VALIDATING',
  WITH_ERRORS = 'WITH_ERRORS',
  WITH_PENDING_INVOICES = 'WITH_PENDING_INVOICES',
  VALIDATED = 'VALIDATED',
  GENERATED = 'GENERATED',
  GENERATING = 'GENERATING',
}
export type PayrollInstanceStatusType =
  | 'In Progress'
  | 'Validating'
  | 'With Errors'
  | 'With Pending Invoices'
  | 'Validated'
  | 'Generated'
  | 'Generating';

export type PayrollInstance = {
  id: string;
  name: string;
  month: number;
  year: number;
  status: PayrollInstanceStatus;
  createdAt: IsoDateTime;
};
export type PayElement = {
  id: string;
  source: string;
  externalId: string;
  employeeId: string;
  companyId: string;
  lspId: string | null;
  payrollInstanceId: string;
  currency: string;
  createdAt: IsoDateTime;
  payElementsBillingBits: PayElementsBillingBit[];
  amount: string;
};

export type PayElementsBillingBit = {
  id: string;
  payElementId: string;
  type: string;
  amount: string;
  createdAt: IsoDateTime;
};

export type PayrollInstanceApiResponse = {
  id: string;
  name: string;
  month: number;
  year: number;
  status: PayrollInstanceStatus;
  createdAt: IsoDateTime;
  payElements?: PayElement[];
};

export type PayrollInstanceApiRequest = {
  name: string;
  month: number;
  year: number;
};

export type PayrollInstancesApiResponse = {
  items: PayrollInstance[];
  nextPageKey: string | undefined;
};

export type PaymentVendor = {
  id: string;
  netsuiteName: string;
  netsuiteId: string;
  countryCode: string;
  lsps: { id: string; currencies: PaymentVendorCurrency[] }[];
  currencies: PaymentVendorCurrency[];
};

export type PaymentVendorCurrency = {
  id: string;
  currency: string;
  netsuiteId: string;
};

export type PaymentVendorUpdate = Omit<Partial<PaymentVendor>, 'lsps'> & {
  lsps?: {
    id: string;
    currencies?: {
      id: string;
    }[];
  }[];
};

export type PaymentVendorsResponse = {
  items: PaymentVendor[];
  nextPageKey?: string;
};

export type PaymentVendorUpdateResponse = Partial<PaymentVendorUpdate>;

export type PaymentEntity = {
  id: string;
  vendor: PaymentEntityVendor;
  currencies: PaymentVendorCurrency[];
} | null;

export type PaymentEntityVendor = Omit<PaymentVendor, 'lsps'>;

export type PaymentEntityResponse = PaymentEntity[];

export type PaymentVendorList = Record<string, PaymentEntityVendor>;

export type InvoicesLogsQueryOptions = {
  invoiceTypes?: SimpleInvoiceType[];
  statuses?: InitialInvoiceStatusType[];
  fromDate?: string;
  toDate?: string;
  currency?: string;
  employeeCountry?: string;
  resolveSimpleInvoice?: boolean;
  resolveCompany?: boolean;
  clientOrEmployeeNameQuery?: string;
};

export type SimpleInvoiceLog = {
  id: string;
  employeeId: string;
  employeeName: string;
  companyName: string;
  invoiceType: SimpleInvoiceType;
  simpleInvoice?: SimpleInvoice;
  reason: string | null;
  employeeProfileLink: string | null;
  status: InitialInvoiceStatusType;
  createdAt: string;
};

export type InvoicesLogsListResponse = {
  items: SimpleInvoiceLog[];
  nextPageKey?: string;
};

export type NSCustomersForPaginationView = {
  id: string;
  entityId: string;
  entityType: string;
  netSuiteId: string;
  netSuiteType: string;
  company?: {
    name: string;
    externalId: string;
  };
};

export type PaginatedNetSuiteCustomersApiResponse = {
  customers: NSCustomersForPaginationView[];
  nextPageKey: string;
};

export enum PayrollCycle {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  OFF_CYCLE = 'OFF_CYCLE',
}

export const PAYROLL_CYCLE_LABELS: Record<PayrollCycle, string> = {
  [PayrollCycle.ONE]: 'Cycle 1',
  [PayrollCycle.TWO]: 'Cycle 2',
  [PayrollCycle.THREE]: 'Cycle 3',
  [PayrollCycle.FOUR]: 'Cycle 4',
  [PayrollCycle.OFF_CYCLE]: 'Off Cycle',
};

export enum BillType {
  BILL = 'Bill',
  BILL_CREDIT = 'Bill Credit',
}

export enum GrossToNetProcessingStatus {
  ERROR = 'ERROR',
  DRAFT = 'DRAFT',
  INGESTED = 'INGESTED',
  TAXONOMIZED = 'TAXONOMIZED',
  ENRICHED = 'ENRICHED',
  DUPLICATES_CHECKED = 'DUPLICATES_CHECKED',
  APPROVED = 'APPROVED',
  COPIED = 'COPIED',
  PRE_APPROVAL_QUERY = 'PRE_APPROVAL_QUERY',
  SPECIALIST_APPROVED = 'SPECIALIST_APPROVED',
  PRE_PUBLISH_QUERY = 'PRE_PUBLISH_QUERY',
  PUBLISHED = 'PUBLISHED',
  TRANSFORMED = 'TRANSFORMED',
  DISCARDED = 'DISCARDED',
}

export enum GrossToNetUploadStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum PayrollReportDataCurrency {
  LOCAL = 'Local',
  BILLED = 'Billed',
}

export enum GrossToNetSource {
  NEEYAMO = 'NEEYAMO',
  SNOWFLAKE = 'SNOWFLAKE',
  MANUAL = 'MANUAL',
}

export type GrossToNetFileLSPData = {
  lspBillingCurrency: string;
  lspExchangeRate: number;
  lspBillNumber: string;
  lspBillUrl: string;
  invoiceDate: Date | null;
  invoiceDueDate: Date | null;
  invoiceAmount: number | null;
  memo: string | null;
  postingPeriod: string | null;
  billType: string | null;
};

export type GrossToNetFile = {
  id: string;
  uploadedBy: string;
  fileName: string;
  cloudStorageFilePath: string;
  country: string;
  legalEntityId: string;
  lspData: GrossToNetFileLSPData | null;
  lspId: string;
  payrollPeriod: string;
  payrollCycle: string;
  fileType: string;
  source: GrossToNetSource;
  processingStatus: GrossToNetProcessingStatus;
  createdAt: string;
  updatedAt: string;
  total: string;
  grossToNetStatusChangeLogs?: Array<{
    id: string;
    status: GrossToNetProcessingStatus;
    updatedAt: IsoDateTime;
    updatedBy: string;
  }>;
};

export type GrossToNetFileUpload = GrossToNetFile & {
  uploadStatus: GrossToNetUploadStatus;
  errorMessage?: string;
};

export type GrossToNetFilesApiResponse = {
  items: GrossToNetFile[];
  pageSize: number;
  pageIndex: number;
  totalCount: number;
};

export type GrossToNetManualView = {
  country: string;
  payrollCycle: PayrollCycle;
  lspId: string;
};

export type GrossToNetDetailView = {
  grandTotalInBillingCurrency: string;
  uploadedRecords: string;
  uploadedEmployees: string;
  approvedRecords: string;
  publishedRecords: string;
};

export type DataProcessingError = {
  key: string;
  rawTableId: string;
  failedOn: string;
  errorType: string;
  message: string;
  dataFrameId: string;
  timeStamp: string;
};

export type DataProcessingErrorApiResponse = DataProcessingError[];

export type UpdateGrossToNetFile = {
  id: string;
  country: string;
  legalEntityId: string;
  lspId: string;
  lspData?: {
    lspExchangeRate: number;
    lspBillingCurrency: string;
    lspBillNumber: string;
  };
};

export type UpdateGrossToNetPayload = {
  files: UpdateGrossToNetFile[];
};

export type LSPData = {
  lspBillingCurrency: string;
  lspExchangeRate: number;
  lspBillNumber: string;
  lspBillUrl: string;
  invoiceDate: Date;
  invoiceDueDate: Date;
  invoiceAmount: number;
  memo: string;
  postingPeriod: string;
  billType: string;
};

export type LegalEntityOption = {
  label: string;
  value: string;
  lspId: string;
  country: string;
  lspData?: LSPData;
};

export type FileUpdateSelectedOptions = {
  id: string;
  value: LegalEntityOption;
};

export type Concept = {
  value: string;
  inputType: string;
  assignee: string;
  taxability: string;
  applicationType: string;
  statutory: string;
  omniplatform: string;
  payrollGlobal: string;
  payrollGlobalSubCategory: string;
  variances?: {
    previous: string;
    variance: string;
    variancePercentage: string;
  };
};

export type EmployeeTotals = {
  salary: string;
  // employee deductions
  eeDeductions: string;
  // employer contribution
  erContribution: string;
  expenses: string;
  allowance: string;
  variablePay: string;
};

export type EmployeeReportData = {
  firstName?: string;
  lastName?: string;
  employeeId: string;
  hasJustificationNote?: boolean;
  concepts: Concept[];
  totals: EmployeeTotals;
  variances?: {
    previous: EmployeeTotals;
    variance: EmployeeTotals;
    variancePercentage: EmployeeTotals;
  };
};

export type PayReportSummary = {
  type: 'dataFrame';
  legalEntityId: string;
  lspId: string;
  payrollPeriod: string;
  payrollCycle: string;
  gtnId: string;
  fileName: string;
  uploadedBy: string;
  employeeCount: number;
  reportTotalSum: string;
  country: string;
  currency: string;
  status: GrossToNetProcessingStatus;
};

export type PayReportSummaryLegalEntity = {
  type: 'legalEntity';
  legalEntityId: string;
  payrollPeriod: string;
  payrollCycle: string;
  employeeCount: number;
  reportTotalSum: string;
  country: string;
  currency: string;
  status: GrossToNetProcessingStatus;
};

export type EmployeeData = {
  items: EmployeeReportData[];
  pageIndex: number;
  pageSize: number;
  pageTotalCount: number;
};

export type PayrollReportPreviewApiResponse = {
  reportSummary: PayReportSummary;
  employees: EmployeeData;
};

export type ApprovePayReportPayload = {
  dataFrameId: string;
};
export type PublishPayReportPayload = {
  dataFrameId: string;
};

export type WorkflowTransitionPayReportPayload = {
  dataFrameId: string;
  newStatus: GrossToNetProcessingStatus;
};

export type LSPBulkRequest = {
  lspId: string;
  country: string;
  payrollPeriod: string;
  payrollCycle: string;
};

export type BulkRequest =
  | {
      type: 'dataFrameIds';
      dataFrameIds: string[];
    }
  | ({
      type: 'lsp';
    } & LSPBulkRequest);

export type CopyApprovedTaxonomicDataApiResponse = {
  success: boolean;
  message: string;
};

const PROCESSING_STATUS_LABELS: Record<GrossToNetProcessingStatus, string> = {
  ERROR: 'Error',
  DRAFT: 'Uploaded',
  INGESTED: 'Processing',
  TAXONOMIZED: 'Taxonomised',
  ENRICHED: 'Enriched',
  DUPLICATES_CHECKED: 'Ready for Verification',
  APPROVED: 'Preparing data for Verification',
  COPIED: 'Verification in Progress',
  PRE_APPROVAL_QUERY: 'Pre-Approval Query',
  SPECIALIST_APPROVED: 'Approved',
  PRE_PUBLISH_QUERY: 'Pre-Publish Query',
  PUBLISHED: 'Published',
  TRANSFORMED: 'Transformed',
  DISCARDED: 'Discarded',
};

export const getGtnProcessingDisplayStatus = (
  status: GrossToNetProcessingStatus,
) => {
  const displayStatus = PROCESSING_STATUS_LABELS[status];
  return displayStatus || 'Status Unavailable';
};

export const getIntentByStatus = (
  status: GrossToNetProcessingStatus | undefined,
): TagIntent => {
  const statusIntent: Record<GrossToNetProcessingStatus, TagIntent> = {
    ERROR: 'error',
    DRAFT: 'default',
    INGESTED: 'default',
    TAXONOMIZED: 'default',
    ENRICHED: 'default',
    DUPLICATES_CHECKED: 'warning',
    APPROVED: 'info',
    COPIED: 'warning',
    PRE_APPROVAL_QUERY: 'error',
    SPECIALIST_APPROVED: 'success',
    PRE_PUBLISH_QUERY: 'error',
    PUBLISHED: 'info',
    TRANSFORMED: 'default',
    DISCARDED: 'error',
  };

  const intent = status && statusIntent[status];
  return intent || 'warning';
};

export type GrossToNetExportApiResponse = {
  data: string;
};

export type PayReportBulkApproveApiResponse = Record<
  string,
  'SUCCESS' | string
>;

export type GrossToNetBulkDeleteApiResponse = Record<
  string,
  'DELETED' | string
>;

export type PayrollJustificationAPIPayload = {
  grossToNetId: string;
  employeeId: string;
  note: string;
};

export type PayrollJustificationAPIQuery = {
  id?: string;
  grossToNetId?: string;
  employeeId?: string;
  lineItemId?: string;
  createdBy?: string;
  archivedBy?: string;
};

export type PayrollJustificationAPIResponse = {
  id: string;
  grossToNetId: string;
  employeeId: string;
  lineItemId?: string;
  note: string;
  createdBy: string;
  archivedBy?: string;
  validUntil?: string;
  validFrom?: string;
};

export type PayrollPeriod = string;
export type PayrollReportsPeriodListApiResponse = {
  payrollReports: PayrollPeriod[];
};
