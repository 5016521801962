import { Box, Stack, Typography } from '@omnipresentgroup/design-system';

import { Checked } from './BenefitsStep.styles';

export const BenefitsStepDoneDeprecated = () => (
  <Stack gap="0">
    <Box mt="32" mb="16" radius="s">
      <Checked />
    </Box>
    <Box>
      <Typography as="span" size="16">
        Benefits selected successfully!
      </Typography>
    </Box>
    <Box>
      <Typography as="span" size="14">
        Your benefits selection for this employee has been saved
      </Typography>
    </Box>
  </Stack>
);

BenefitsStepDoneDeprecated.displayName = 'BenefitsStepDone';
