import { TransformedUser } from 'app/store/app.transformers';
import { User, UserRole, UserRoleType } from 'common/types';

import { capitaliseFirstLetter } from './string-utils';

type UserType = TransformedUser | User;

const rankFrom = (role: UserRole) => {
  const ranks = {
    'benefits-specialist': 3,
    'international-expansion': 3,
    admin: 3,
    manager: 2,
    lsp: 2,
    employee: 1,
  };

  return ranks[role?.type as keyof typeof ranks] || 0;
};

export const getUserMainRole = (roles: UserRole[]) => {
  return [roles]
    .flat()
    .reduce(
      (bestSoFar, role) =>
        rankFrom(role) > rankFrom(bestSoFar) ? role : bestSoFar,
      roles?.[0],
    );
};

export const roleTypeToUserFriendlyRoleTypeName = (roleType: UserRoleType) => {
  if (roleType === 'lsp') {
    return 'Service Provider';
  } else {
    return capitaliseFirstLetter(roleType);
  }
};

const isRole = (user: UserType, role: UserRoleType) => {
  if (user?.roles) {
    return user?.roles.some(({ type }) => type === role);
  }
  return user?.role?.type === role;
};

export const isManager = (user: UserType) => isRole(user, 'manager');

export const isEmployee = (user: UserType) => isRole(user, 'employee');

export const isIEAdmin = (user: UserType) =>
  isRole(user, 'international-expansion');
export const isBenefitsAdmin = (user: UserType) =>
  isRole(user, 'benefits-specialist');

export const isAdmin = (user: UserType) => {
  if (typeof user === 'string') {
    return !!(user === 'admin');
  }

  return isRole(user, 'admin');
};

const hasRole = (user: UserType, role: UserRoleType) => {
  return user.roles.some(({ type }) => type === role);
};

export const isPayrollAdmin = (user: UserType) => {
  if (!user) {
    return false;
  }

  if (!isAdmin(user)) {
    return false;
  }

  return hasRole(user, 'payroll');
};

export const isLSP = (user: UserType) => isRole(user, 'lsp');

export const isCSAdmin = (user: UserType) =>
  isRole(user, 'viewer:clients-sensitive-data');

export const isOPAdmin = (user: UserType) =>
  isRole(user, 'viewer:omnipresent-sensitive-data');

export const isFinanceAdmin = (user: UserType) => isRole(user, 'finance');

const getScopes = (user: UserType) => {
  if (!user.roles) {
    return [];
  }

  const allScopes = user.roles.reduce((scopes, role) => {
    return role.scopes ? scopes.concat(role.scopes) : scopes;
  }, [] as string[]);

  return [...new Set(allScopes)];
};

const hasScope = (user: UserType, searchScope?: string) =>
  getScopes(user).some((scope) => scope === '*' || scope === searchScope);

export const hasSuperManagerScope = (user: UserType) =>
  hasScope(user, 'employees:*');

export const hasExpensesNotificationsScope = (user: UserType) =>
  hasScope(user, 'expenses:notifications');

export const hasFinanceScope = (user: UserType) => hasScope(user, 'finance:*');

export const hasPayrollScope = (user: UserType) => hasScope(user, 'payroll:*');

export const roleManagement = (roles?: UserRole[]) => {
  const allScopes =
    roles?.reduce((acc, role) => {
      return role.scopes ? acc.concat(role.scopes) : acc;
    }, [] as string[]) || [];

  const uniqueScopes = [...new Set(allScopes)];

  const isSuperManager = uniqueScopes.includes('employees:*');

  return { allScopes, isSuperManager };
};
