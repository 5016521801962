import {
  ManagerEmployeesApiResponse,
  PaginatedEmployeesApiResponse,
  SimplifiedEmployee,
} from 'common/types';
import { AssociatedEmployee } from 'omniplatform/manager/pages/ManagerPermissions/components/EmployeesList';

export const mockEmployeesLspView: SimplifiedEmployee[] = [
  {
    id: 'def2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
    userId: '82e11408-5cd4-4ce1-8d54-73622f4930b5',
    name: 'Roy Testerton',
    country: 'United Kingdom',
    currency: 'GBP',
    annualBaseSalary: 12345,
    jobTitle: 'Software developer',
    startDate: '2020-07-20',
    onboardingStatus: 'Active',
    needsRequiredFieldsFilled: false,
    requireTypeFormFilled: false,
    managerId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    lspId: 'LSP-21-001',
    hasSupplementaryBenefits: 'yes',
  },
  {
    id: 'def2967b-f6fa-48a7-82f0-cf56ecd8b9y3',
    userId: '82e11408-5cd4-4ce1-8d54-73622f4937t2',
    name: 'Simone Testerona',
    country: 'Germany',
    currency: 'EUR',
    annualBaseSalary: 50000,
    jobTitle: 'CTO',
    startDate: '2020-07-27',
    onboardingStatus: 'In Progress',
    needsRequiredFieldsFilled: false,
    requireTypeFormFilled: false,
    managerId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    hasSupplementaryBenefits: 'yes',
    lspId: 'PP-21-001',
  },
  {
    id: 'def2967b-f6fa-48a7-82f0-cf56ecd8b9y3',
    userId: '82e11408-5cd4-4ce1-8d54-73622f4937t2',
    name: 'Tester Testerona',
    country: 'Germany',
    currency: 'EUR',
    annualBaseSalary: 50000,
    jobTitle: 'CTO',
    startDate: '2020-07-27',
    onboardingStatus: 'Active',
    needsRequiredFieldsFilled: false,
    requireTypeFormFilled: false,
    managerId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    hasSupplementaryBenefits: 'yes',
    lspId: 'PP-21-001',
  },
];

export const mockEmployeesAdminView: SimplifiedEmployee[] =
  mockEmployeesLspView.map((employee) => ({
    ...employee,
    annualBaseSalary: { redacted: true, missing: false },
  }));

export const paginatedEmployeesResponse: PaginatedEmployeesApiResponse = {
  employees: [
    {
      id: 'def2967b-f6fa-48a7-82f0-cf56ecd8b9y3',
      userId: '82e11408-5cd4-4ce1-8d54-73622f4937t2',
      name: 'Simone Testerona',
      country: 'Canada',
      currency: 'CAD',
      annualBaseSalary: {
        redacted: true,
        missing: false,
      },
      jobTitle: 'CTO',
      startDate: '2020-07-27',
      onboardingStatus: 'In progress',
      managerId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
      company: {
        id: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
        name: 'E2E test co',
      },
      lsp: {
        id: 'kgkaYEY8Omc-',
        name: 'MaplePay24',
      },
    },
    {
      id: 'def2967b-f6fa-48a7-82f0-cf56ecd8h8t5',
      userId: '82e11408-5cd4-4ce1-8d54-73622f4937t2',
      name: 'Egbert Simpson',
      country: 'Canada',
      currency: 'CAD',
      annualBaseSalary: {
        redacted: true,
        missing: false,
      },
      jobTitle: 'Cleaner',
      startDate: '2020-04-22',
      onboardingStatus: 'Pending',
      managerId: 'OuanZbYPCFsSQaQpJ96gn',
      company: {
        id: 'OuanZbYPCFsSQaQpJ96gn',
        name: "Chris' test co",
      },
      lsp: {
        id: 'kgkaYEY8Omc-',
        name: 'MaplePay24',
      },
    },
    {
      id: 'def2967b-f6fa-48a7-82f0-cf56ecd8h878',
      userId: '82e11408-5cd4-4ce1-8d54-73622f493709',
      name: 'John Doe',
      country: 'Canada',
      currency: 'CAD',
      annualBaseSalary: {
        redacted: true,
        missing: false,
      },
      jobTitle: 'Cleaner',
      startDate: '2020-05-22',
      onboardingStatus: 'Pending',
      managerId: 'OuanZbYPCFsSQaQpJ96gn',
      company: {
        id: 'OuanZbYPCFsSQaQpJ96gn',
        name: "Chris' test co",
      },
      lsp: {
        id: 'kgkaYEY8Omc-',
        name: 'OmniPay',
      },
    },
  ],
  nextPageKey: 'eyJpZCI6ImRlZjI5NjdiLWY2ZmEtNDhhNy04MmYwLWNmNTZlY2Q4aDh0NSJ9',
};

export const mockAssociatedEmployeeItems: AssociatedEmployee[] = [
  {
    userId: 'dssdcsdcsc',
    name: 'Test name',
    jobTitle: 'Tester',
    country: 'Spain',
    isAssociated: false,
  },
  {
    userId: 'dssdcfvdfvsdcsc',
    name: 'Other name',
    jobTitle: 'Something',
    country: 'Vietnam',
    isAssociated: false,
  },
];

export const mockManagerEmployeesApiResponse: ManagerEmployeesApiResponse = {
  employees: [
    {
      userId: 'user-1',
      name: 'Felipe Ensayador',
      employmentDetails: {
        jobTitle: 'Ensayador',
      },
      country: {
        id: 'c-1',
        currency: 'EUR',
        name: 'Spain',
      },
    },
    {
      userId: 'user-2',
      name: 'Philippe Testeur',
      employmentDetails: {
        jobTitle: 'Testeur',
      },
      country: {
        id: 'c-2',
        currency: 'EUR',
        name: 'France',
      },
    },
  ],
};
