import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { getUserSelector } from 'app/store/app.selectors';
import Big from 'big.js';
import {
  createCompanyEmployeeSeatRequest,
  getCompanySeatRequest,
} from 'common/api/seats.api';
import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { AddEditFormPage } from 'common/pages/AddEditFormPage/AddEditFormPage';
import { addSeatAction } from 'common/store/actions/seats.actions';
import { transformSeatApiResponseToSeat } from 'common/store/transformers/seats.transformer';
import {
  BenefitsCountryFee,
  CompanyUpsellFees,
  DepositFeeWithType,
  DropdownOption,
  FilteredCompanyUpsellFees,
  Salary,
  SOWStatusEnum,
} from 'common/types';
import {
  EmployerCostApiResponse,
  EmployerCostFee,
  EmployerCosts,
} from 'common/types/employerCost';
import {
  COMPANIES_LIST_PAGE,
  getEmployeeSeatListPath,
  MANAGER_ATLAS_COMPARE_PATH,
  MANAGER_ATLAS_PATH,
  MANAGER_SEATS_LIST_PATH,
} from 'paths';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCountryByCountryCode,
  getCountryCodeByCountryName,
  isCountryEligibleForAutomatedUpsells,
} from 'utils/countries';
import { isUpsellProposalGenerationEnabled } from 'utils/featureFlags';
import { useDebounce } from 'utils/hooks';
import { useEmployeesForManagerUser } from 'utils/hooks/employees/useEmployeesForManagerUser';
import { useDocumentUpload } from 'utils/hooks/useDocumentUpload';
import { useCompanyUpsellFeesQuery, useFxRateQuery } from 'utils/queries';
import { useBenefitsByCountry } from 'utils/queries/benefits';
import { useEmployerCostsQuery } from 'utils/queries/calculator/useEmployerCostsQuery';
import {
  buildEmployerCostsFeesModelFromApiResponse,
  buildFeesDataFromEmployerCostApiResponse,
} from 'utils/queries/calculator/useEmployerCostsQuery/employerCostsDataTransformationUtil';
import { reduceFormCheckboxValue } from 'utils/transformer-utils/input.transformer';
import { isAdmin, isManager } from 'utils/user';

import { ConfirmEmployeeSeatPage } from '../ConfirmEmployeeSeatPage/ConfirmEmployeeSeatPage';
import {
  addCompanyEmployeeFormAdminConfig,
  addCompanyEmployeeFormDefaultConfig,
  addCompanyEmployeeFormManagerConfig,
} from './addCompanyEmployeeSeatFormConfig';
import {
  getAutomatedUpsellsData,
  getFeeByCurrency,
} from './enableAutomatedUpsells';

const UPLOAD_SOW_CTA = 'Upload order form';
const ANNUAL_SALARY_AMOUNT_DELAY = 500;

export const AddEmployeeSeatPage: React.FC = () => {
  const history = useHistory();
  const { companyId } = useParams<{ companyId: string }>();
  const { search } = useLocation<{ search?: string }>();
  const [seatInputData, setSeatInputData] = useState<Record<string, any>>({});
  const [confirmEmployee, setConfirmEmployee] = useState<boolean>(false);
  const [showAutomatedProposal, updateShowAutomatedProposal] =
    useState<boolean>(false);
  const [keyfactsExist, setKeyfactsExist] = useState<boolean>(false);
  const [enableAutomatedUpsellForManager, setEnableAutomatedUpsellForManager] =
    useState<boolean | undefined>(false);
  const [enableAutomatedUpsellForCountry, setEnableAutomatedUpsellForCountry] =
    useState<boolean>(true);
  const [filteredUpsellData, setFilteredUpsellData] =
    useState<FilteredCompanyUpsellFees>();
  const [depositFeeData, setDepositFeeData] = useState<DepositFeeWithType>({});
  const [depositType, setDepositType] = useState<string>('');
  const [billingCurrencyData, setBillingCurrencyData] = useState<string>('');
  const [annualBaseSalaryData, setAnnualBaseSalaryData] = useState<Salary>({
    currency: '',
    amount: 0,
  });
  const [employerCostFees, setEmployerCostFees] = useState<EmployerCostFee[]>(
    [],
  );
  const [employeeType, setEmployeeType] = useState();
  const [depositPercentage, setDepositPercentage] = useState();

  const [employerCostFeesToBeSaved, setEmployerCostFeesToBeSaved] =
    useState<EmployerCosts>({});

  const [countryName, setCountryName] = useState<string>('');
  const urlCountryCode = search.replace(/\\?.*=\\*/g, '');
  const urlCountry = getCountryByCountryCode(urlCountryCode);

  const [benefitsCountryFee, setBenefitsCountryFee] =
    useState<BenefitsCountryFee>();

  const dispatch = useDispatch();

  const upsellFeeQuery = useCompanyUpsellFeesQuery(companyId);

  const user = useSelector(getUserSelector);
  const [submitCTA, setSubmitCTA] = useState<string>('');
  const isAdminUser = isAdmin(user);

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);

  function getValidBillingCurrencies(
    companyBillingCurrencies:
      | { currency: string; amount: number | null }[]
      | undefined,
  ) {
    if (companyBillingCurrencies === undefined) {
      return undefined;
    }
    const uniqueCurrencies = companyBillingCurrencies.reduce(
      (acc: Set<DropdownOption>, { currency, amount }) => {
        if (currency && amount) {
          acc.add({ value: currency, label: currency });
        }
        return acc;
      },
      new Set<DropdownOption>(),
    );
    return Array.from(uniqueCurrencies);
  }

  const getCompanyEmployeeFormConfig = () => {
    if (enableAutomatedUpsellForManager) {
      const limitBillingOptions =
        upsellFeeQuery?.data?.enableAutomatedProposals;
      const validBillingCurrencies = getValidBillingCurrencies(
        upsellFeeQuery?.data?.managementFee,
      );
      return addCompanyEmployeeFormManagerConfig(
        urlCountry ? { countryName: urlCountry?.label } : seatInputData,
        limitBillingOptions,
        validBillingCurrencies,
        startDate,
      );
    }
    if (isAdminUser) {
      return addCompanyEmployeeFormAdminConfig({
        seat: urlCountry ? { countryName: urlCountry?.label } : {},
        depositType,
        employeeType,
        depositPercentage,
      });
    }
    return addCompanyEmployeeFormDefaultConfig(
      undefined,
      urlCountry ? urlCountry.label : undefined,
    );
  };

  const { data } = useEmployeesForManagerUser(companyId);

  useEffect(() => {
    if (urlCountry) {
      setCountryName(urlCountry.label);
    }
  }, [urlCountry]);

  useEffect(() => {
    if (!isCountryEligibleForAutomatedUpsells(countryName)) {
      const hasEmployeesInSelectedCountry =
        data?.filter((employee) => employee.country === countryName).length ===
        0;
      if (hasEmployeesInSelectedCountry) {
        setEnableAutomatedUpsellForCountry(false);
      }
    }
  }, [data, companyId, countryName]);

  const hasFxRate = billingCurrencyData !== annualBaseSalaryData.currency;

  const currencyExchangeRate = useFxRateQuery(
    annualBaseSalaryData.currency,
    billingCurrencyData,
    {
      queryOptions: {
        enabled:
          Boolean(annualBaseSalaryData?.currency && billingCurrencyData) &&
          keyfactsExist,
      },
    },
  );

  const annualBaseSalaryAmount = hasFxRate
    ? annualBaseSalaryData?.amount *
      Big(currencyExchangeRate?.data?.data || 1).toNumber()
    : annualBaseSalaryData?.amount;

  const bouncedSalaryAmount = useDebounce(
    annualBaseSalaryAmount,
    ANNUAL_SALARY_AMOUNT_DELAY,
  );

  const employerCostsQuery = useEmployerCostsQuery(
    countryName,
    billingCurrencyData,
    bouncedSalaryAmount,
    hasFxRate,
    {
      queryOptions: {
        enabled:
          !!countryName &&
          !!billingCurrencyData &&
          !!bouncedSalaryAmount &&
          keyfactsExist &&
          !currencyExchangeRate.isLoading,
      },
    },
  );

  const { formTitle, formFields, schema } = getCompanyEmployeeFormConfig();

  const getBackLabelAndUrl = () => {
    const locationState: any = history.location?.state;
    let backUrl = MANAGER_SEATS_LIST_PATH;
    let backLabel = 'Back to pending employees';
    if (isAdminUser) {
      backUrl = getEmployeeSeatListPath(companyId);
    } else {
      if (locationState?.from?.pathname) {
        switch (locationState.from.pathname) {
          case MANAGER_ATLAS_COMPARE_PATH:
            backUrl = `${MANAGER_ATLAS_COMPARE_PATH}${locationState.from.search}`;
            backLabel = 'Back to OmniAtlas';
            break;
          case MANAGER_ATLAS_PATH:
            backUrl = `${MANAGER_ATLAS_PATH}${locationState.from.search}`;
            backLabel = 'Back to OmniAtlas';
            break;
          default:
            break;
        }
      }
    }
    return { backUrl, backLabel };
  };
  const { backUrl, backLabel } = getBackLabelAndUrl();

  const {
    uploadDocument,
    isLoading: isDocumentUploading,
    isError: isDocumentUploadError,
    isSuccess: isDocumentUploadSuccess,
    error: uploadError,
    reset: uploadDocReset,
    data: uploadedDocUrl,
  } = useDocumentUpload();

  const {
    isLoading,
    isError,
    isSuccess,
    error: seatCreationError,
    reset,
    mutate: createSeat,
    data: createdSeatResponse,
  } = useMutation((newSeat: Record<string, any>) =>
    createCompanyEmployeeSeatRequest(companyId, newSeat),
  );

  if (isDocumentUploadSuccess) {
    createSeat({
      ...seatInputData,
      sowDocUrl: uploadedDocUrl,
      sowStatus: SOWStatusEnum.SOW_AWAITING_ACCEPTANCE,
    });
    uploadDocReset();
  }

  const calculateDepositFee = useCallback(
    (data: CompanyUpsellFees, billingCurrency: string) => {
      if (data) {
        if (data.depositType === 'Zero Deposit Insurance') {
          const depositFee = getFeeByCurrency(
            data.zeroDepositInsuranceFee,
            billingCurrency,
          );
          return depositFee?.amount || 0;
        }
        if (data.depositType === 'Advanced Payment Deposit (APD)') {
          if (
            annualBaseSalaryData?.amount &&
            data.advancedPaymentDepositPercent
          ) {
            return (
              annualBaseSalaryData?.amount *
              (data.advancedPaymentDepositPercent / 100)
            );
          }
        }
      }
      return 0;
    },
    [annualBaseSalaryData],
  );

  const handleFormChange = (name: string, value: any) => {
    if (name === 'countryName') {
      setCountryName(value);
    }

    if (name === 'annualBaseSalary') {
      setAnnualBaseSalaryData({
        amount: value.amount,
        currency: value.currency,
      });
    }

    if (name === 'billingCurrency') {
      setBillingCurrencyData(value);
      const automatedUpsellsData = getAutomatedUpsellsData({
        billingCurrency: value,
        upsellFees: upsellFeeQuery?.data,
      });
      setFilteredUpsellData(automatedUpsellsData);
      updateShowAutomatedProposal(Boolean(automatedUpsellsData));
    }

    if (name === 'desiredStartDate') {
      setStartDate(new Date(value));
    }

    if (!isAdminUser) {
      return;
    }

    if (name === 'sowSigned') {
      setSubmitCTA(
        reduceFormCheckboxValue(value) ? 'Add employee' : UPLOAD_SOW_CTA,
      );
    }

    if (name === 'depositType') {
      setDepositType(value);
    }
    if (name === 'employeeType') {
      setEmployeeType(value);
    }
    if (name === 'depositPercent') {
      setDepositPercentage(value);
    }
  };

  const handleChangeForKeyfacts = (hasKeyfacts: boolean) => {
    setKeyfactsExist(hasKeyfacts);
  };

  const handleFormSubmit = (data: any) => {
    if (submitCTA === UPLOAD_SOW_CTA) {
      setSeatInputData(data);
      uploadDocReset();
      uploadDocument('sowUrl');
      return;
    }

    const isAutomatedFlow =
      enableAutomatedUpsellForManager &&
      showAutomatedProposal &&
      keyfactsExist &&
      enableAutomatedUpsellForCountry;
    if (isAutomatedFlow) {
      setSeatInputData({
        ...data,
        sowStatus: SOWStatusEnum.SOW_ACCEPTED,
      });
      setConfirmEmployee(true);
      return;
    }
    createSeat(data);
  };

  const getSuccessPageUrlByUserType = () => {
    const baseURL = `${COMPANIES_LIST_PAGE}/${companyId}`;

    const isAutomateFlowForManagerUser =
      enableAutomatedUpsellForManager &&
      showAutomatedProposal &&
      keyfactsExist &&
      enableAutomatedUpsellForCountry;

    if (isAutomateFlowForManagerUser) {
      return `${baseURL}/pending-employees/${createdSeatResponse?.data.id}/employee-checklist`;
    }
    return `${baseURL}/pending-employee/${createdSeatResponse?.data.id}?showModal=true`;
  };

  if (isSuccess && createdSeatResponse?.data) {
    (async () => {
      const fetchedSeatData = await getCompanySeatRequest(
        createdSeatResponse.data.id,
      );
      reset();
      dispatch(addSeatAction(transformSeatApiResponseToSeat(fetchedSeatData)));
      history.push(getSuccessPageUrlByUserType());
    })();
  }

  const getEmployerCostFees = useCallback(
    (
      employerCostsResponse: EmployerCostApiResponse,
      billingCurrencyData: string,
    ) =>
      buildFeesDataFromEmployerCostApiResponse(
        employerCostsResponse,
        billingCurrencyData,
      ),
    [],
  );
  const getEmployerCostsToBeSaved = useCallback(
    (employerCostsResponse: any, billingCurrencyData: string) =>
      buildEmployerCostsFeesModelFromApiResponse(
        employerCostsResponse.data,
        billingCurrencyData,
      ),
    [],
  );

  useEffect(() => {
    if (employerCostsQuery.isSuccess) {
      setEmployerCostFees(
        getEmployerCostFees(employerCostsQuery.data, billingCurrencyData),
      );
      setEmployerCostFeesToBeSaved(
        getEmployerCostsToBeSaved(employerCostsQuery, billingCurrencyData),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerCostsQuery.data]);

  useEffect(() => {
    const isNewManagerUpsellsEnabled =
      isManager(user) &&
      isUpsellProposalGenerationEnabled() &&
      upsellFeeQuery.data?.enableAutomatedProposals;

    setEnableAutomatedUpsellForManager(isNewManagerUpsellsEnabled);

    const getCTAText = (isNewManagerUpsellsEnabled: any) => {
      if (isNewManagerUpsellsEnabled) {
        return 'Continue';
      }
      return isAdminUser ? UPLOAD_SOW_CTA : 'Request order form';
    };

    setSubmitCTA(getCTAText(isNewManagerUpsellsEnabled));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsellFeeQuery.data]);

  useEffect(() => {
    setDepositFeeData({
      depositType: upsellFeeQuery.data?.depositType,
      calculatedAmount: {
        amount: calculateDepositFee(upsellFeeQuery?.data, billingCurrencyData),
        currency: billingCurrencyData || '',
      },
    });
  }, [
    upsellFeeQuery.data,
    calculateDepositFee,
    billingCurrencyData,
    annualBaseSalaryData,
  ]);

  const countryCode = getCountryCodeByCountryName(countryName);

  const benefitsCountryQuery = useBenefitsByCountry(countryCode);

  useEffect(() => {
    if (!benefitsCountryQuery?.data) {
      return;
    }

    setBenefitsCountryFee({
      ...(benefitsCountryQuery.data as BenefitsCountryFee),
    });
  }, [benefitsCountryQuery?.data]);

  if (upsellFeeQuery.isLoading) {
    return <Loading />;
  }

  return (
    <>
      {upsellFeeQuery.isError && <ErrorBanner />}
      {isDocumentUploadError && uploadError instanceof Error && (
        <ErrorBanner errorMessage={uploadError.message} />
      )}
      {confirmEmployee ? (
        <>
          {isError && seatCreationError instanceof Error && (
            <ErrorBanner errorMessage={seatCreationError.message} />
          )}
          <ConfirmEmployeeSeatPage
            countryName={countryName}
            upsellFeeData={filteredUpsellData}
            submitClick={() => {
              createSeat({
                ...seatInputData,
                ...employerCostFeesToBeSaved,
              });
            }}
            backClick={() => {
              setConfirmEmployee(false);
            }}
            showOrderForm={false}
            showLoading={isLoading}
            depositFeeData={depositFeeData}
            annualBaseSalaryData={annualBaseSalaryData}
            currencyExchangeRateData={currencyExchangeRate.data?.data || 1}
            country={countryName}
            employerCostFees={employerCostFees}
            benefitsCountryFeeData={benefitsCountryFee}
          />
        </>
      ) : (
        <AddEditFormPage
          backLink={{
            label: backLabel,
            url: backUrl,
          }}
          employerCostFees={employerCostFees}
          submitCTA={submitCTA}
          formHeader="Add employee details"
          formHeaderSubstring="To get started, please complete the form below to get you the detailed monthly employer cost for your new employee."
          formTitle={formTitle}
          formFields={formFields}
          schema={schema}
          keyFacts={true}
          enableAutomatedUpsellForManager={enableAutomatedUpsellForManager}
          onSubmit={handleFormSubmit}
          onFieldChange={handleFormChange}
          upsellFeeData={filteredUpsellData}
          depositFeeData={depositFeeData}
          annualBaseSalaryData={annualBaseSalaryData}
          currencyExchangeRateData={currencyExchangeRate.data?.data || 1}
          billingCurrency={billingCurrencyData}
          benefitsCountryFeeData={benefitsCountryFee}
          country={countryName}
          showLoading={isLoading || isDocumentUploading}
          isLoadingCosts={employerCostsQuery.isLoading}
          onErrorBannerReset={() => {
            reset();
          }}
          keyFactsExist={handleChangeForKeyfacts}
          errorBannerMessage={
            isError && seatCreationError instanceof Error
              ? seatCreationError.message
              : ''
          }
        />
      )}
    </>
  );
};
