/* eslint-disable no-magic-numbers */
import { useState } from 'react';

import { updateUserAction } from 'app/store/app.actions';
import { useDispatch } from 'react-redux';

import {
  Button,
  Checkbox,
  getISOString,
  Inline,
  Link,
  Modal,
  ModalProps,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

export const MODAL_TITLE = 'Privacy Policy';

export type Props = {
  modalProps: Omit<ModalProps, 'children'>;
  handleContinue: (event?: React.SyntheticEvent) => void;
};

export const PrivacyPolicyModal = (props: Props) => {
  const { modalProps, handleContinue } = props;
  const [checkBoxSelected, setCheckBoxSelected] = useState(false);
  const dispatch = useDispatch();

  return (
    <Modal {...modalProps} span={1}>
      <Modal.Body>
        <Stack gap="24">
          <Typography as="h4" size="20" weight="medium">
            {MODAL_TITLE}
          </Typography>
          <Typography as="span" size="16">
            I agree to the collection of my personal information as set out in
            the{' '}
            <Link
              size="16"
              to="https://omnipresent-assets.s3.eu-west-2.amazonaws.com/Omnipresent+-+GDPR+Privacy+Policy.docx.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
            , which I have read and understand.
          </Typography>
          <Checkbox
            label="I agree"
            checked={checkBoxSelected}
            name="privacy-policy-checkbox"
            id="privacy-policy-checkbox-id"
            onCheckedChange={() => setCheckBoxSelected(!checkBoxSelected)}
          />
        </Stack>
      </Modal.Body>

      <Modal.Footer>
        <Inline justify="end">
          <Button
            variant="primary"
            disabled={!checkBoxSelected}
            onClick={() => {
              if (checkBoxSelected) {
                dispatch(
                  updateUserAction({
                    seenPrivacyPolicy: getISOString(),
                  }),
                );
                handleContinue();
              }
            }}
          >
            Continue
          </Button>
        </Inline>
      </Modal.Footer>
    </Modal>
  );
};
