import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TWO } from 'common/constants';
import { getSelectedCompanySelector } from 'common/store/selectors/companies.selectors';
import { PayrollPeriod } from 'common/types';
import { DateTime } from 'luxon';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { MimeType, useFileDownloadDialog } from 'utils/hooks';
import { useTrack } from 'utils/mixpanel/tracker';
import { usePayrollReportPeriodListQuery } from 'utils/queries';
import { useDownloadPayrollReportGrossToNetMutation } from 'utils/queries/payroll/usePayrollReportGTNQuery/usePayrollReportGTNQuery';

import {
  Button,
  dismissToast,
  Inline,
  notifyWarning,
  Table,
  Typography,
} from '@omnipresentgroup/design-system';

import { getTransformedUser } from '../../../../app/store/app.selectors';

type GTNFilesData = {
  month: PayrollPeriod;
  action: () => void;
};
const columnHelper = createColumnHelper<GTNFilesData>();

export enum ColumnHeaders {
  'month' = 'Month',
  'action' = '',
}

const TableColumns = [
  columnHelper.accessor('month', {
    id: 'month',
    cell: (info) =>
      DateTime.fromISO(`${info.getValue()}`).toFormat('MMMM yyyy'),
    header: () => ColumnHeaders.month,
  }),
  columnHelper.accessor('action', {
    id: 'action',
    cell: (info) => (
      <Inline justify="end">
        <Button onClick={info.getValue()} variant="secondary" size="small">
          Download CSV
        </Button>
      </Inline>
    ),
    header: () => ColumnHeaders.action,
  }),
];

const LoadingColumns: ColumnDef<any, any>[] = [
  columnHelper.display({
    id: 'month',
    cell: () => <Skeleton height={16} width={50} />,
    header: () => ColumnHeaders.month,
  }),
  columnHelper.display({
    id: 'action',
    cell: () => (
      <Inline justify="end">
        <Skeleton height={12} width={50} />
      </Inline>
    ),
    header: () => ColumnHeaders.action,
  }),
];

export const GrossToNetFilesTable = () => {
  const { id: companyId }: { id: string } = useSelector(
    getSelectedCompanySelector,
  );

  const { data: payrollPeriods = [], isLoading } =
    usePayrollReportPeriodListQuery(companyId);

  const { mutateAsync: downloadPayrollReportGrossToNet } =
    useDownloadPayrollReportGrossToNetMutation();
  const { showFileDownloadDialog } = useFileDownloadDialog();

  const track = useTrack();
  const user = useSelector(getTransformedUser);

  const tableData: GTNFilesData[] = payrollPeriods.map(
    (payrollPeriod: PayrollPeriod) => ({
      month: payrollPeriod,
      action: async () => {
        track('Payroll Reports Download CSV Clicked', {
          companyId,
          userId: user?.userId,
        });
        const toastId = notifyWarning(
          {
            title: `Download will start soon`,
            description: (
              <Typography as="span" size="14">
                This can take a moment,&nbsp;do not leave this page
              </Typography>
            ),
          },
          {
            autoClose: false,
            progress: 100,
            hideProgressBar: false,
          },
        );

        try {
          const data = await downloadPayrollReportGrossToNet({
            companyId,
            payrollPeriod,
          });
          dismissToast(toastId);

          showFileDownloadDialog(MimeType.CSV, payrollPeriod, data);
        } catch (e) {
          dismissToast(toastId);
        }
      },
    }),
  );

  const GTNFileTable = () => {
    const loadingTable = useReactTable({
      data: Array.from({ length: TWO }, (_value, index) => index),
      columns: LoadingColumns,
      getCoreRowModel: getCoreRowModel(),
    });

    const table = useReactTable({
      data: tableData,
      columns: TableColumns,
      getCoreRowModel: getCoreRowModel(),
    });

    return (
      <Table showHeader={false} table={isLoading ? loadingTable : table} />
    );
  };

  return <GTNFileTable />;
};
