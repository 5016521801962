import { contractorsClient } from 'api';
import {
  ClientIdTypeEnum,
  CreateContractorRequestAPI,
  GetClientResponseAPI,
  GetContractorResponseAPI,
  GetContractorsCostsRequestAPI,
  GetContractorsCostsResponseAPI,
  GetContractorsResponseAPI,
  GetCountriesResponseAPI,
} from 'common/types/contractor';

export const getContractor = (id: string) =>
  contractorsClient.get<GetContractorResponseAPI>(`contractors/${id}`);

export const getContractors = (params?: { page: number; size: number }) =>
  contractorsClient.get<GetContractorsResponseAPI>(`/contractors`, { params });

export const createContractor = (payload: CreateContractorRequestAPI) => {
  return contractorsClient.post(`/contractors`, payload);
};

export const getContractorsCosts = (params: GetContractorsCostsRequestAPI) => {
  return contractorsClient.get<GetContractorsCostsResponseAPI>(
    `/contractors/costs`,
    {
      params: params,
    },
  );
};

export const getCountries = () =>
  contractorsClient.get<GetCountriesResponseAPI>(`/common/supported-countries`);

export const getClient = (
  id: string,
  idType: ClientIdTypeEnum = ClientIdTypeEnum.INTERNAL_ID,
) =>
  contractorsClient.get<GetClientResponseAPI>(`clients/${id}`, {
    params: {
      id_type: idType,
    },
  });

export const updateClientTerms = (id: string) =>
  contractorsClient.put<GetClientResponseAPI>(`clients/${id}/terms`);
