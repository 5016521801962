import { useEffect } from 'react';

import { useBenefitsSelectionContext } from './BenefitsSelectionContext';
import { BenefitsStepDone } from './BenefitsStepDone';
import { ConfigureBenefits } from './ConfigureBenefits';

type CountryLabel = string | undefined;

export const BenefitsSelectionStep = ({
  countryLabel,
}: {
  countryLabel: CountryLabel;
}) => {
  useEffect(() => {
    return () => {
      //(sc-15228) workaround for scroll when closing typeform
      document.body.style.overflow = 'auto';
    };
  }, []);
  const { clientPreferences } = useBenefitsSelectionContext();

  return (
    <>
      {!clientPreferences.populated ||
      clientPreferences.value === 'REMIND_LATER' ? (
        <ConfigureBenefits countryLabel={countryLabel} />
      ) : (
        <BenefitsStepDone />
      )}
    </>
  );
};
