import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { Form } from 'common/components/molecules/Form/Form';
import {
  FormProvider,
  useFormContext,
} from 'common/components/molecules/Form/FormProvider';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { PROVIDERS_PAGE } from 'paths';
import { useCreatePayrollProviderMutation } from 'utils/queries/payrollProviders/useCreatePayrollProviderMutation';

import {
  PayrollProviderFormData,
  transformFormDataForAPI,
} from '../payrollProvider';
import { payrollProviderFormConfig } from '../payrollProviderFormConfig';

const AddPayrollProviderForm = () => {
  const history = useHistory();
  const { mutate, isLoading } = useCreatePayrollProviderMutation();

  const goToListAction = () =>
    history.push(`${PROVIDERS_PAGE}#payroll-providers`);
  const {
    values: { hasParent },
    onFieldChange,
  } = useFormContext();

  const submit = useCallback(
    (payrollProviderFormData: PayrollProviderFormData) => {
      mutate(transformFormDataForAPI(payrollProviderFormData), {
        onSuccess: goToListAction,
      });
    },
    [goToListAction, mutate],
  );

  useEffect(() => {
    if (!hasParent) {
      onFieldChange('parent', null);
    }
  }, [hasParent, onFieldChange]);

  return (
    <Form
      isLoading={isLoading}
      onSubmit={submit}
      ctaText="Submit"
      showCancel={true}
      onCancel={goToListAction}
    />
  );
};

export const AddPayrollProviderPage = () => {
  return (
    <PageContent data-testid="add-payroll-provider-page" className="bigStack">
      <PageHeader
        title="Add payroll provider"
        backLink={{
          url: `${PROVIDERS_PAGE}#payroll-providers`,
          label: 'Back to list',
        }}
      />
      <FormProvider config={payrollProviderFormConfig()}>
        <AddPayrollProviderForm />
      </FormProvider>
    </PageContent>
  );
};
