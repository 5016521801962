import * as Sentry from '@sentry/react';
import get from 'lodash/get';
import adminRootReducer from 'omniplatform/admin/store/reducers/adminRoot.reducer';
import adminRootSaga from 'omniplatform/admin/store/sagas/adminRoot.saga';
import employeeRootReducer from 'omniplatform/employee/store/reducers/employeeRoot.reducer';
import employeeRootSaga from 'omniplatform/employee/store/sagas/employeeRoot.saga';
import lspRootReducer from 'omniplatform/lsp/store/reducers/lspRoot.reducer';
import lspRootSaga from 'omniplatform/lsp/store/sagas/lspRoot.saga';
import managerRootReducer from 'omniplatform/manager/store/reducers/managerRoot.reducer';
import managerRootSaga from 'omniplatform/manager/store/sagas/managerRoot.saga';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { notifyError } from '@omnipresentgroup/design-system';

import { setAuthHeader } from '../../api';
import { ACCESS_TOKEN } from '../../appConstants';
import { getAccessTokenSelector } from './app.selectors';
import composeEnhancers from './enhancers';
import errorMiddleware from './middleware/error.middleware';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    return {
      ...action,
      // Do not log the payload in the breadcrumb
      payload: null,
    };
  },
  // Do not attach the state to an event
  stateTransformer: () => null,
});

export const storeByRole = {
  manager: {
    reducer: managerRootReducer,
    saga: managerRootSaga,
  },
  employee: {
    reducer: employeeRootReducer,
    saga: employeeRootSaga,
  },
  admin: {
    reducer: adminRootReducer,
    saga: adminRootSaga,
  },
  lsp: {
    reducer: lspRootReducer,
    saga: lspRootSaga,
  },
};

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const enhancers = composeEnhancers(
  applyMiddleware(sagaMiddleware, errorMiddleware),
  sentryReduxEnhancer,
);

// eslint-disable-next-line import/no-mutable-exports
let store;

export const getStore = (userRole, state = {}) => {
  const tempStore = get(storeByRole, userRole);
  if (!tempStore) {
    notifyError({
      title: `Missing store configuration for userRole: ${userRole}`,
    });
    return null;
  }
  const rootReducer = tempStore?.reducer;
  const rootSaga = tempStore?.saga;
  store = createStore(rootReducer, { ...initialState, ...state }, enhancers);
  store.subscribe(() => {
    const token = getAccessTokenSelector(store.getState());
    localStorage.setItem(ACCESS_TOKEN, token);
    setAuthHeader(token);
  });
  sagaMiddleware.run(rootSaga);
  return store;
};

export default store;
