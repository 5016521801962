import { Address as AddressType } from 'common/types';

import { Stack, Typography } from '@omnipresentgroup/design-system';

import EditableInput from '../EditableInput/EditableInput';
import * as S from './Address.styles';

const getAddressFieldLabel: Record<keyof AddressType, string> = {
  addressLine1: 'Address line one',
  addressLine2: 'Address line two',
  addressLine3: 'Address line three',
  city: 'City/Town',
  postalCode: 'Postal or ZIP code',
  country: 'Country',
};

type AddressProps = {
  addressObject: AddressType;
  onChange?: (name: string, addressObject: AddressType) => void;
  title?: string;
  name?: string;
  onFocus?: (name: string) => void;
  showCountry?: boolean;
  hideAddressLines?: boolean;
  hidePostalCode?: boolean;
  errorObject?: Partial<AddressType>;
  required?: boolean;
  disabled?: boolean;
};

export const Address = ({
  addressObject,
  onChange = () => {},
  title = '',
  name = 'address',
  disabled,
  onFocus = () => {},
  showCountry = false,
  hideAddressLines = false,
  hidePostalCode = false,
  errorObject = {},
  required = false,
}: AddressProps) => {
  const addressFields: Array<string> = [
    ...(hideAddressLines
      ? []
      : ['addressLine1', 'addressLine2', 'addressLine3']),
    'city',
    ...(hidePostalCode ? [] : ['postalCode']),
    ...(showCountry ? ['country'] : []),
  ];

  const onAddressFieldChange = (
    fieldName: keyof AddressType,
    value: string,
  ) => {
    const addressObjectToUpdate = { ...addressObject };
    addressObjectToUpdate[fieldName] = value;
    onChange(name, addressObjectToUpdate);
  };

  return (
    <S.AddressContainer data-testid="address-input">
      {title && (
        <Stack align="flex-start">
          <Typography as="label" size="16" weight="medium">
            {title}
          </Typography>
        </Stack>
      )}

      {addressFields.map((key: string) => {
        const fieldName = key as keyof AddressType;
        return (
          <EditableInput
            small
            name={key}
            errorMessage={errorObject[fieldName]}
            key={key}
            disabled={disabled}
            onChange={onAddressFieldChange}
            value={addressObject[fieldName] || ''}
            label={getAddressFieldLabel[fieldName]}
            type={key === 'country' ? 'country' : 'string'}
            onFocus={() => onFocus(name)}
            required={
              !['addressLine2', 'addressLine3'].includes(key) && required
            }
          />
        );
      })}
    </S.AddressContainer>
  );
};
