import { ElementType, ReactNode } from 'react';

import Joi from 'joi';

import { Condition } from './conditions';

export enum FIELD_TYPE {
  COMPONENT = 'component',
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  TEXT = 'text',
  HIDDEN = 'hidden',
  CURRENCY = 'currency',
  DROPDOWN = 'dropdown',
  DEPENDENT_DROPDOWN = 'dependent-dropdown',
  MULTI_SELECT = 'multi-select',
  CHECKBOX = 'checkbox',
  CHECKBOX_BOOLEAN = 'checkbox-boolean',
  TOGGLE = 'toggle',
  HOLIDAY_ALLOWANCE = 'holidayAllowance',
  ADDRESS = 'address',
  DOCUMENT = 'document',
  RECEIPT = 'receipt',
  LEAVE_TYPE = 'leaveType',
  LSP = 'lsp',
  EMPLOYER_LSP_SELECTION = 'employer-lsp-selection',
  LPP = 'payroll-provider',
  EMPLOYER = 'employer',
  EMPLOYEE_BY_LSP = 'employeeByLsp',
  EMPLOYEE = 'employee',
  LOCATION_OF_WORK = 'locationOfWork',
  CURRENCY_TIME_PERIOD = 'currencyTimePeriod',
  TIME_PERIOD = 'timePeriod',
  EMPLOYEE_CURRENCY = 'employeeCurrency',
  CONDITIONAL_INPUT = 'conditionalInput',
  ONBOARDING_STAGE = 'onboardingStage',
  ONBOARDING_STATUS = 'onboardingStatus',
  GENDER = 'gender',
  COUNTRY = 'country',
  EMAIL = 'email',
  FEE_CURRENCY = 'feeCurrency',
}

export type FormValidationError = {
  [key: string]: string | Record<string, string>;
  address: Record<string, string>;
};

export type FieldRenderFunc = {
  field: Field;
  onFieldFocus?: (name: string) => void;
  onFormChanged?: (name: string, value: any) => void;
  key?: string;
};

export type FieldRuleHandler = (
  field: Field,
  fields: FormFieldsObject,
) => boolean;

export type FieldRules = Partial<
  Record<
    'required' | 'readOnly' | 'disabled' | 'editable' | 'hidden',
    FieldRuleHandler
  >
>;
export type FieldRulePropName = keyof FieldRules;

export type Field = {
  name: string;
  label: string;
  labelBold?: boolean;
  type: `${FIELD_TYPE}`;
  dependentAttribute?: string; // for dependent_list in avvoka
  listName?: string; // for dependent_list and db_list in avvoka
  party_id?: string; // for avvoka contracts
  value: any;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  editable?: boolean;
  hidden?: boolean;
  error?: null | string | Record<string, string>;
  noLabel?: boolean;
  detailLabel?: string;
  contextualInfo?: string | JSX.Element;
  displayValue?: string | number;
  placeholder?: string;
  component?: ReactNode;
  showFor?: 'admin' | 'manager' | 'employee';
  hideFromDetailsView?: boolean;
  params?: any;
  onChange?: () => void;
  options?: Array<{ label: string; value: string }>;
  condition?: Condition;
  rules?: FieldRules;
  after?: ReactNode;
  before?: ReactNode;
  link?: string;
};

export type FieldName = string;

export type FormFields = Field[];
export type FormFieldsObject = Record<FieldName, Field>;

export type FormQuestionConfig = {
  field: Field;
  validation: Joi.SchemaLike;
};

export type FormQuestionSetConfig = FormQuestionConfig[];

export type BaseStage = {
  name: string;
  stage: number;
};

export type FormStage = BaseStage & {
  fields: FormFields;
  schema?: Joi.AnySchema;
  pageComponent?: ElementType;
  ctaText?: string;
  ctaComponent?: ElementType;
  notifications?: string;
  benefitSelectionFormId?: string;
};

export type BaseFormConfig = {
  formTitle: string;
  formCopy: string | null;
};

export type FormConfig = BaseFormConfig & {
  formFields: FormFields;
  schema?: Joi.AnySchema;
};

export type StagedFormConfig = BaseFormConfig & {
  stages: FormStage[];
  SuccessScreen?: ElementType;
};

export type FormData<FormDataValues = Record<string, any>> = FormDataValues & {
  [key: string]: any;
};

export type TableHeader<RowProps> = {
  headerTitle: string;
  fieldOnObject?: keyof RowProps;
  customCell?: ElementType<RowProps>;
  align?: string;
};

export type DropdownOption = { label: string; value: string };

export type OnSelect = (
  name: string,
  label: string,
  event: {
    value: string;
    label: string;
  },
) => void;
