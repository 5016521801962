import { client } from 'api';
import { transformInputForAPI } from 'common/store/transformers/seats.transformer';
import {
  CompanyUpsellFees,
  Employee,
  Seat,
  SeatEmployeeProfile,
  SOWStatusEnum,
} from 'common/types';
import { getDataFromResponse } from 'utils/react-query-utils';

export const SEATS_BASE_API_ENDPOINT = '/seats';

export const getCompanySeatsRequest = (companyId: string) =>
  client.get<Seat[]>(`${SEATS_BASE_API_ENDPOINT}/company/${companyId}`);

export const createCompanyEmployeeSeatRequest = (
  companyId: string,
  data: Record<string, any>,
) => {
  const seatToCreate = transformInputForAPI(data, companyId);
  return client.post<Seat>(`${SEATS_BASE_API_ENDPOINT}`, seatToCreate);
};

export const getCompanySeatRequest = (seatId: string, withSignature = false) =>
  client
    .get<Seat>(`${SEATS_BASE_API_ENDPOINT}/${seatId}`, {
      params: {
        ...(withSignature && { signedBy: true }),
      },
    })
    .then(getDataFromResponse);

export const updateCompanyEmployeeSeatRequest = async (
  seatId: string,
  data: Record<string, any>,
) => {
  const seatToUpdate = transformInputForAPI(data);
  return client.patch<Seat>(
    `${SEATS_BASE_API_ENDPOINT}/${seatId}`,
    seatToUpdate,
  );
};

export const convertSeatToEmployeeRequest = async (
  seatId: string,
  tenantId: string,
  employee: Record<string, any>,
) => {
  return client.post<Employee>(`seats/${seatId}/convert`, {
    ...employee,
    managerId: tenantId,
  });
};

export const updateSOWStatusRequest = (
  seatId: string,
  sowStatus: SOWStatusEnum,
) =>
  client.patch<Seat>(`${SEATS_BASE_API_ENDPOINT}/${seatId}`, {
    sowStatus,
  });

export const getSeatSOWDocument = (seatId: string) =>
  client.get<{ url: string }>(
    `${SEATS_BASE_API_ENDPOINT}/${seatId}/sow-document`,
  );

export const saveSeatEmployeeProfile = async (
  seatId: string,
  seatProfile: SeatEmployeeProfile,
) => {
  return client.patch<SeatEmployeeProfile>(`seats/${seatId}/employee-profile`, {
    ...seatProfile,
  });
};

export const getCompanyUpsellFees = (companyId: string) =>
  client
    .get<CompanyUpsellFees>(
      `${SEATS_BASE_API_ENDPOINT}/fees/company/${companyId}`,
    )
    .then(getDataFromResponse);

export const archiveSeatById = async (
  seatId: string,
  data: Record<string, any>,
) => {
  const seatToUpdate = transformInputForAPI(data);
  return client.post<Seat>(
    `${SEATS_BASE_API_ENDPOINT}/${seatId}/archive`,
    seatToUpdate,
  );
};
