import FourXXError from 'common/components/molecules/4XXError/FourXXError';

type FourOhThreeProps = {
  message?: string;
};

export const FourOhThree = ({
  message = 'Access Denied/Forbidden',
}: FourOhThreeProps) => (
  <FourXXError message={message} error="403"></FourXXError>
);
