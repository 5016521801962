import { AlertCircle, AlertTriangle, CheckCircle } from 'react-feather';
import styled from 'styled-components';

const Title = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 600px;

  font-weight: 600;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
`;

const Description = styled.div`
  font-weight: 400;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  max-width: 600px;
  letter-spacing: -0.011em;
`;

const SuccessIcon = styled(CheckCircle)`
  color: ${({ theme: { colors } }) => colors['icon-positive']};
  margin-right: ${({ theme: { space } }) => space[16]};
`;

const WarningIcon = styled(AlertTriangle)`
  color: ${({ theme: { colors } }) => colors['icon-warning']};
  margin-right: ${({ theme: { space } }) => space[16]};
`;

const ErrorIcon = styled(AlertCircle)`
  color: ${({ theme: { colors } }) => colors['icon-negative']};
  margin-right: ${({ theme: { space } }) => space[16]};
`;

type EmployeeValidationMessageProps = {
  type: 'success' | 'warning' | 'error';
  message: string;
  description: string;
};

export const EmployeeValidationMessage = ({
  type,
  message,
  description,
}: EmployeeValidationMessageProps) => {
  return (
    <div>
      <Title>
        {type === 'success' && <SuccessIcon />}
        {type === 'warning' && <WarningIcon />}
        {type === 'error' && <ErrorIcon />}
        {message}
      </Title>
      <Description>{description}</Description>
    </div>
  );
};
