import { client } from '../../api';

export const getDocumentsRequest = ({
  userId,
  companyId,
}: {
  userId?: string;
  companyId?: string;
}) =>
  userId
    ? client.get(`documents`, { params: { userId, documentType: 'document' } })
    : client.get(`documents`, {
        params: { companyId, documentType: 'document' },
      });

export const createDocumentRequest = (documentToCreate: any) =>
  client.post(`documents`, documentToCreate);

export const downloadDocumentRequest = (
  documentId: string,
  disposition?: string,
) => {
  return client.get(
    `documents/${documentId}/download${
      disposition ? `?disposition=${disposition}` : ''
    }`,
  );
};

export const getDocumentByUserIdRequest = (
  userId: string,
  documentType: string,
) => {
  return client.get(`documents/?userId=${userId}&documentType=${documentType}`);
};

export const deleteDocumentRequest = (documentToDeleteId: string) =>
  client.delete(`documents/${documentToDeleteId}`);
