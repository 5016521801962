import { useMemo } from 'react';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';

import * as Styled from './TimeOffDate.styles';

type TimeOffDateProps = {
  [Range in `${'start' | 'end'}Date`]: string;
};

export const TimeOffDate = ({ startDate, endDate }: TimeOffDateProps) => {
  const isNotSameDay = startDate !== endDate;

  const { start, end } = useMemo(
    () => ({
      start: formatISODate(startDate, DateFormats.WeekDate),
      end: formatISODate(endDate, DateFormats.WeekDate),
    }),
    [startDate, endDate],
  );

  const [startWeekday, startFormattedDate] = start.split(/,(.+)/);
  const [endWeekday, endFormattedDate] = end.split(/,(.+)/);

  return (
    <Styled.CellContainer>
      <Styled.CustomDate>
        <span>{`${startWeekday},`}</span>
        <time>{startFormattedDate}</time>
      </Styled.CustomDate>

      {isNotSameDay && (
        <>
          <span>-</span>
          <Styled.CustomDate>
            <span>{`${endWeekday},`}</span>
            <time>{endFormattedDate}</time>
          </Styled.CustomDate>
        </>
      )}
    </Styled.CellContainer>
  );
};
