export type VECountryCode = 'es' | 'de';
// TODO: add ES countries when they are ready
export type GSCountryCode = VECountryCode;

export enum GlobalSetupStatus {
  InviteSent = 'invite-sent',
  Started = 'started',
  InfoCollected = 'info-collected',
  DocsApproved = 'docs-approved',
  AwaitingPhysicalDocuments = 'awaiting-physical-docs',
  PhysicalDocumentsSent = 'physical-docs-sent',
  PhysicalDocumentsReceived = 'physical-docs-received',
  Active = 'active',
}

export enum GlobalSetupErrorStatus {
  InfoCollectedForm = 'error:info-collected:form',
  InfoCollectedDocuments = 'error:info-collected:documents',
  PhysicalDocuments = 'error:physical-docs',
}

export enum GSType {
  VirtualEmployer = 'virtual-employer',
  LegalEntity = 'legal-entity',
}

export type GlobalSetup = {
  id: string;
  companyId: string;
  countryCode: GSCountryCode;
  type: GSType;
  status: GlobalSetupStatus | GlobalSetupErrorStatus;
  involvedManagers: {
    id: string;
    name: string;
    email: string;
  }[];
  supportingData: {
    uploadedDocuments: {
      id: string;
      type: string;
      error: string;
    }[];
    jotformInformationLink: string;
  };
};
