import { NoEmployees } from 'common/components/molecules/NoEmployees/NoEmployees';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { Tabs } from 'common/components/organisms/Tabs/Tabs';
import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledCompanyDetailPage = styled.div`
  display: flex;
  flex-direction: column;
  place-self: center flex-start;
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;

export const StyledCompanyHeadline = styled.div`
  display: flex;
  padding: ${({ theme: { space } }) => `${space[16]} 0 ${space[16]}`};
  justify-content: space-between;
`;

export const StyledCompanyDetailHeader = styled(PageHeader)``;

export const StyledTab = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCompanyDetailsTab = styled.div``;

export const StyledNoEmployees = styled(NoEmployees)`
  align-self: center;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    align-self: flex-start;
    margin: ${({ theme: { space } }) => `0 ${space[8]}`};
  }
`;

export const StyledTabsContainer = styled(Tabs)``;
