import { useParams } from 'react-router-dom';

import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { LSP_LIST_PAGE } from 'paths';
import { useSelectUserRoles } from 'utils/hooks';
import { useLspUserQuery } from 'utils/queries';

import ManagerDetailPage from '../ManagerDetailPage/ManagerDetailPage';

const LspUserDetailsPage = () => {
  const { userId, lspId } = useParams<{ lspId: string; userId: string }>();
  const { isIEAdmin } = useSelectUserRoles();
  const lspUserQuery = useLspUserQuery(userId);

  if (lspUserQuery.isError) {
    return <ErrorBanner errorMessage={lspUserQuery?.error?.message} />;
  }

  return (
    <ManagerDetailPage
      manager={lspUserQuery.data}
      loading={lspUserQuery.isLoading}
      managerId={userId}
      onEditURL={`${LSP_LIST_PAGE}/${lspId}/users/${userId}/edit`}
      canEdit={!!isIEAdmin}
      companyId={lspId}
    />
  );
};
export default LspUserDetailsPage;
