import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

import NoDocuments from '../../molecules/NoDocuments/NoDocuments';

export const StyledDocumentsTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledNoDocuments = styled(NoDocuments)`
  align-self: center;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    align-self: start;
  }
`;
