import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { joiResolver } from '@hookform/resolvers/joi';
import { PageContent } from 'app/App.styles';
import { ErrorBanner, Loading, PageHeader } from 'common/components';
import { StyledLabel } from 'common/components/atoms/Label';
import MobileButton from 'common/components/atoms/MobileButton/MobileButton';
import { StyledDropdownInput } from 'common/components/molecules/DropdownInput/DropdownInput.styles';
import { StyledDetailsFieldContainer } from 'common/components/molecules/EditableInput/EditableInput.styles';
import { FormInput } from 'common/components/molecules/FormInput';
import FormSubmitButton from 'common/components/molecules/FormSubmitButton/FormSubmitButton';
import {
  additionalAdminRoleNames,
  FormData,
  userRoleTypeToFriendlyName,
} from 'common/types';
import Joi from 'joi';
import { ADMIN_LIST_PAGE } from 'paths';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import Select from 'react-select';
import { usersKeys } from 'utils/queries/users/keys';
import { useCreateAdminUserMutation } from 'utils/queries/users/useCreateAdminUserMutation';
import { AddAdminUserFormSubmissionResult } from 'utils/queries/users/useCreateAdminUserMutation/useCreateAdminUserMutation';

import { Viewport } from '@omnipresentgroup/design-system';

import * as S from './AddAdminPage.styles';

export const AddAdminPage = () => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const addAdminSchema = Joi.object({
    firstName: Joi.string()
      .required()
      .messages({ 'string.empty': 'Please enter a first name' }),
    lastName: Joi.string()
      .required()
      .messages({ 'string.empty': 'Please enter a last name' }),
    email: Joi.string()
      .required()
      .pattern(/^.+@omnipresent\.com$/)
      .messages({
        'string.pattern.base':
          'Please enter a valid @omnipresent.com email address',
        'string.empty':
          'Please enter the corresponding @omnipresent.com email address',
      }),
    additionalAdminRoles: Joi.array().allow(null),
  });

  const {
    isSuccess,
    isLoading,
    reset: onErrorBannerReset,
    isError,
    error,
    data,
    mutate: onSubmit,
  } = useCreateAdminUserMutation();

  useEffect(() => {
    if (isSuccess && data) {
      const newlyCreateduserId = data.id;
      queryClient.invalidateQueries(usersKeys.allAdmins);
      history.push(`${ADMIN_LIST_PAGE}/${newlyCreateduserId}`);
    }
  }, [history, queryClient, isSuccess, data]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: joiResolver(addAdminSchema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const onSubmitClick = handleSubmit((data: Record<string, any>) => {
    let payload = data;
    if (payload['additionalAdminRoles']) {
      payload = {
        ...payload,
        additionalAdminRoles: data['additionalAdminRoles'].map(
          (payload: { type: string; value: string }) => payload.value,
        ),
      };
    }
    onSubmit(payload as AddAdminUserFormSubmissionResult);
  });

  const FORM_ID = 'add-admin';
  const ctaText = 'Add';
  const backLink = {
    label: 'Back to admin list',
    url: ADMIN_LIST_PAGE,
  };

  return (
    <PageContent>
      {isLoading && <Loading />}
      {isError && (
        <ErrorBanner
          onErrorClear={() => onErrorBannerReset && onErrorBannerReset()}
          errorMessage={error?.message}
        />
      )}
      <S.StyledEditPageMain className="bigStack">
        <PageHeader title="Add an admin" backLink={backLink} />
        <S.StyledSectionContainer>
          <S.StyledSectionContainerWrapper>
            <S.StyledSectionFormContainerWrapper>
              <S.StyledSectionFormContainer>
                <S.StyledFormHeaderSubString>
                  Once completed, you can invite the new admin user to the
                  platform via their user profile
                </S.StyledFormHeaderSubString>
                <form id={FORM_ID}>
                  <FormInput
                    as="text"
                    error={errors['firstName']?.message}
                    id="firstName"
                    label={"Admin's first name"}
                    required
                    {...register('firstName')}
                  />
                  <FormInput
                    as="text"
                    error={errors.lastName?.message}
                    id="lastName"
                    label={"Admin's last name"}
                    required
                    {...register('lastName')}
                  />
                  <FormInput
                    as="text"
                    error={errors.email?.message}
                    id="email"
                    label={"Omnipresent Admin's email address"}
                    required
                    type="email"
                    placeholder="@omnipresent.com"
                    {...register('email')}
                  />
                  <StyledDetailsFieldContainer
                    error={errors.additionalAdminRoles?.message}
                  >
                    <StyledLabel
                      id="additionalAdminRoles"
                      htmlFor="additionalAdminRoles"
                    >
                      Additional admin roles
                    </StyledLabel>
                    <StyledDropdownInput>
                      <Controller
                        key="additionalAdminRoles"
                        name="additionalAdminRoles"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            aria-labelledby="additionalAdminRoles"
                            options={[
                              ...additionalAdminRoleNames.map((role) => ({
                                value: role,
                                label: userRoleTypeToFriendlyName[role],
                              })),
                            ]}
                            isLoading={isLoading}
                            isMulti
                          />
                        )}
                      />
                    </StyledDropdownInput>
                  </StyledDetailsFieldContainer>
                  <FormSubmitButton
                    ctaText={ctaText}
                    onCTAClick={onSubmitClick}
                    showCancel
                    disabled={isLoading}
                  />
                </form>
              </S.StyledSectionFormContainer>
            </S.StyledSectionFormContainerWrapper>
          </S.StyledSectionContainerWrapper>
        </S.StyledSectionContainer>
      </S.StyledEditPageMain>
      <Viewport devices={['phone', 'tablet']}>
        <MobileButton onClick={onSubmitClick} disabled={isLoading}>
          {ctaText}
        </MobileButton>
      </Viewport>
    </PageContent>
  );
};
