/* eslint-disable no-shadow */

export enum ContractorState {
  ONBOARDING = 'ONBOARDING',
  KYC_PENDING = 'KYC_PENDING',
  KYC_FAILED = 'KYC_FAILED',
  ACTIVE = 'ACTIVE',
  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
}

export enum ContractState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum InvoicingFrequencyEnum {
  PER_HOUR = 'PER_HOUR',
  PER_DAY = 'PER_DAY',
  PER_WEEK = 'PER_WEEK',
  PER_MONTH = 'PER_MONTH',
  PER_DELIVERABLE = 'PER_DELIVERABLE',
}

export enum InvoicingCurrencyEnum {
  GBP = 'GBP',
  EUR = 'EUR',
  USD = 'USD',
}

export enum BillingCurrencyEnum {
  GBP = 'GBP',
  EUR = 'EUR',
  USD = 'USD',
}

export type Country = {
  code: string;
  name: string;
  flag: string;
};

export enum ClientIdTypeEnum {
  OP_ID = 'op_id',
  INTERNAL_ID = 'internal_id',
}

/**
 * API
 */

export type ContractAPI = {
  id: string;
  contractor_id: string;
  client_id: string;
  job_role: string;
  invoicing_currency: InvoicingCurrencyEnum;
  billing_currency: BillingCurrencyEnum;
  expected_invoicing_frequency?: InvoicingFrequencyEnum;
  rate?: string;
  start_date?: string;
  end_date?: string;
  state: ContractState;
};

export type ContractorAPI = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  residence_country: Country;
  state: ContractorState;
  contracts: ContractAPI[];
  user_id: string;
  misclassification_risk_accepted: boolean;
  misclassification_risk_accepted_at: string;
  misclassification_risk_accepted_by: string;
};

export type ClientAPI = {
  id: string;
  company_id: string;
  company_name: string;
  op_terms_accepted?: boolean;
  op_terms_accepted_at?: string;
  op_terms_accepted_by?: string;
};

export type GetContractorResponseAPI = ContractorAPI;

export type GetContractorsResponseAPI = {
  items: ContractorAPI[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

export type CreateContractorRequestAPI = {
  first_name: string;
  last_name: string;
  email: string;
  job_role: string;
  residence_country: string;
  invoicing_currency: InvoicingCurrencyEnum;
  billing_currency: BillingCurrencyEnum;
  expected_invoicing_frequency?: InvoicingFrequencyEnum;
  invoice_amount?: string;
  start_date?: string;
  end_date?: string;
  misclassification_risk_accepted: boolean;
};

export type GetContractorsCostsRequestAPI = {
  billing_currency: BillingCurrencyEnum;
  invoicing_currency: InvoicingCurrencyEnum;
};

export type GetContractorsCostsResponseAPI = {
  monthly_platform_fee: string;
  total_cost: string;
  fx_fee?: string;
  vat_rate?: string;
  vat_amount?: string;
  country: Country;
};

export type GetCountriesResponseAPI = Country[];

export type GetClientResponseAPI = ClientAPI;

/**
 * Model
 */

export type Contract = {
  id: string;
  contractorId: string;
  clientId: string;
  jobRole: string;
  invoicingCurrency: InvoicingCurrencyEnum;
  billingCurrency: BillingCurrencyEnum;
  expectedInvoicingFrequency?: InvoicingFrequencyEnum;
  rate?: string;
  startDate?: string;
  endDate?: string;
  state: ContractState;
};

export type Contractor = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  residenceCountry: Country;
  state: ContractorState;
  contracts: Contract[];
  userId: string;
  misclassificationRiskAccepted: boolean;
  misclassificationRiskAcceptedAt: string;
  misclassificationRiskAcceptedBy: string;
};

export type Client = {
  id: string;
  companyId: string;
  companyName: string;
  opTermsAccepted?: boolean;
  opTermsAcceptedAt?: string;
  opTermsAcceptedBy?: string;
};

export type GetContractorResponse = Contractor;

export type GetContractorsResponse = {
  items: Contractor[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

export type CreateContractorRequest = {
  firstName: string;
  lastName: string;
  email: string;
  jobRole: string;
  residenceCountry: string;
  invoicingCurrency: InvoicingCurrencyEnum;
  billingCurrency: BillingCurrencyEnum;
  expectedInvoicingFrequency?: InvoicingFrequencyEnum;
  invoiceAmount?: string;
  startDate?: string;
  endDate?: string;
  misclassificationRiskAccepted: boolean;
};

export type GetContractorsCostsRequest = {
  billingCurrency: BillingCurrencyEnum;
  invoicingCurrency: InvoicingCurrencyEnum;
};

export type GetContractorsCostsResponse = {
  monthlyPlatformFee: string;
  totalCost: string;
  fxFee?: string;
  vatRate?: string;
  vatAmount?: string;
  country: Country;
};

export type GetCountriesResponse = Country[];

export type GetClientResponse = Client;

/**
 * Mappers
 */

export const transformContractFromAPI = ({
  id,
  contractor_id,
  client_id,
  job_role,
  invoicing_currency,
  billing_currency,
  expected_invoicing_frequency,
  rate,
  start_date,
  end_date,
  state,
}: ContractAPI): Contract => ({
  id,
  contractorId: contractor_id,
  clientId: client_id,
  jobRole: job_role,
  invoicingCurrency: invoicing_currency,
  billingCurrency: billing_currency,
  expectedInvoicingFrequency: expected_invoicing_frequency,
  rate,
  startDate: start_date,
  endDate: end_date,
  state,
});

export const transformContractorFromAPI = ({
  id,
  first_name,
  last_name,
  email,
  residence_country,
  state,
  contracts,
  user_id,
  misclassification_risk_accepted,
  misclassification_risk_accepted_at,
  misclassification_risk_accepted_by,
}: ContractorAPI): Contractor => ({
  id,
  firstName: first_name,
  lastName: last_name,
  email,
  residenceCountry: { ...residence_country },
  state,
  contracts: contracts.map((contract) => transformContractFromAPI(contract)),
  userId: user_id,
  misclassificationRiskAccepted: misclassification_risk_accepted,
  misclassificationRiskAcceptedAt: misclassification_risk_accepted_at,
  misclassificationRiskAcceptedBy: misclassification_risk_accepted_by,
});

export const transformGetContractorsResponseFromAPI = ({
  items,
  total,
  page,
  size,
  pages,
}: GetContractorsResponseAPI): GetContractorsResponse => ({
  items: items.map((item) => transformContractorFromAPI(item)),
  total,
  page,
  size,
  pages,
});

export const transformClientFromAPI = ({
  id,
  company_id,
  company_name,
  op_terms_accepted,
  op_terms_accepted_at,
  op_terms_accepted_by,
}: ClientAPI): Client => ({
  id,
  companyId: company_id,
  companyName: company_name,
  opTermsAccepted: op_terms_accepted,
  opTermsAcceptedAt: op_terms_accepted_at,
  opTermsAcceptedBy: op_terms_accepted_by,
});
