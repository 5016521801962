import { Check } from 'react-feather';
import styled from 'styled-components';

export const CloseButton = styled.button`
  position: absolute;
  top: ${({ theme: { space } }) => space[8]};
  right: ${({ theme: { space } }) => space[8]};
  background: none;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme: { space } }) => space[8]};
  transition: background 0.2s ease-in-out;

  &:hover {
    background: ${({ theme: { colors } }) => colors['gray-100']};
  }
`;

export const SuccessIcon = styled(Check)`
  display: block;
  color: ${({ theme: { colors } }) => colors['white-base']};
  background-color: ${({ theme: { colors } }) => colors['icon-positive']};
  margin-block: ${({ theme: { space } }) => space[32]};
  width: ${({ theme: { fontSizes } }) => fontSizes[4]};
  height: ${({ theme: { fontSizes } }) => fontSizes[4]};
  padding: ${({ theme: { space } }) => space[4]};
  border-radius: 100%;
`;
