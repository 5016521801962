import { getDepartmentsRequest } from 'common/api/employees.api';
import { useQuery, UseQueryOptions } from 'react-query';
import { Department } from 'utils/departments';
import { getDataFromResponse } from 'utils/react-query-utils';

type DepartmentResponse = Department[];

export const useDepartmentQuery = (
  options?: Pick<UseQueryOptions, 'staleTime'>,
) => {
  return useQuery<DepartmentResponse, Error>(
    'referenceDataDepartments',
    () => getDepartmentsRequest().then(getDataFromResponse),
    { staleTime: options?.staleTime || Infinity },
  );
};
