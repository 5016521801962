import { getUserSelector } from 'app/store/app.selectors';
import { listCutoffReports } from 'common/api/cutoffReports.api';
import {
  ListCutoffReportsApiResponse,
  ListCutoffReportsParams,
} from 'common/types';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { isPayrollAdmin } from 'utils/user';

import { notifyError } from '@omnipresentgroup/design-system';

import { getDataFromResponse } from '../../../react-query-utils';
import { payrollKeys } from '../keys';

export const useListCutoffReportsQuery = (params: ListCutoffReportsParams) => {
  const user = useSelector(getUserSelector);

  return useQuery<ListCutoffReportsApiResponse, Error>(
    payrollKeys.cutoffReportList(params),
    () =>
      listCutoffReports(params)
        .then(getDataFromResponse)
        .catch((error) => {
          throw error?.response?.data;
        }),
    {
      enabled: isPayrollAdmin(user),
      onError: () => {
        notifyError({
          title: 'Error',
          description: 'Failed to get cutoff reports.',
        });
      },
    },
  );
};
