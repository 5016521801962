import { FormCheckbox } from 'common/components/molecules/FormCheckbox';

import { InvoiceLogId, SimpleInvoiceLog } from '../types';
import { ActionWrapper } from './ActionCell.styles';

export const ActionCell = (
  addEntityToExport: (invoiceLogId: InvoiceLogId) => void,
  removeEntityToExport: (invoiceLogId: InvoiceLogId) => void,
  checkedIds: Set<string>,
) => {
  // eslint-disable-next-line react/display-name
  return (simpleInvoiceLog: SimpleInvoiceLog) => {
    const { id } = simpleInvoiceLog;
    const onChange = (state: React.ChangeEvent<HTMLInputElement>) => {
      state.target.checked ? addEntityToExport(id) : removeEntityToExport(id);
    };

    return (
      <ActionWrapper>
        <FormCheckbox
          id={`checkbox-${id}`}
          name="Action provider Checkbox"
          label=""
          onChange={onChange}
          checked={checkedIds.has(id)}
          test-id={`action-checkbox-${id}`}
        />
      </ActionWrapper>
    );
  };
};
