import { getEmployments } from 'common/api/employments.api';
import { TEN_MINUTES } from 'common/constants';
import { EmploymentModel } from 'common/types/employments';
import { useQuery } from 'react-query';
import { employmentKeys } from 'utils/queries/employments/keys';

import { getDataFromResponse } from '../../../react-query-utils';

export const useEmploymentHistoryQuery = (
  userId: string,
  staleTime: number,
  displayKey?: string,
) => {
  return useQuery<EmploymentModel[], Error>(
    employmentKeys.singleUser(userId),
    () =>
      getEmployments({ userId, display: displayKey }).then(getDataFromResponse),
    {
      enabled: Boolean(userId),
      staleTime,
    },
  );
};

export const useLspEmploymentsQuery = ({ lspId }: { lspId: string }) => {
  return useQuery<EmploymentModel[], Error>(
    employmentKeys.lspEmployments(lspId),
    () => getEmployments({ lspId }).then(getDataFromResponse),
    {
      enabled: Boolean(lspId),
      staleTime: TEN_MINUTES,
    },
  );
};
