import styled from 'styled-components';

type LabelProps = {
  size?: string;
} & Partial<HTMLFormElement>;

export const StyledLabel = styled.label<LabelProps>`
  font-size: ${(props) =>
    props.size ? props.size : ({ theme: { fontSizes } }) => fontSizes[2]};
`;
