import { CountryName } from 'common/types';

export enum LSPTypesEnum {
  EMPLOYER_OF_RECORD = 'lsp:employer-of-record',
  PAYROLL_PROVIDER = 'lsp:payroll-provider',
}

export type LSPTypes = `${LSPTypesEnum}`;

export type LSPApprovedEmployeeExpenses = {
  amount: {
    amount: number;
    currency: string;
  };
  id: string;
  employeeId: string;
  employeeName: string;
  title: string;
  documentId: string;
  approvedDate: string;
};

export type LSPUserApiResponse = {
  id: string;
  message: string;
  url: string;
};

export type LSPApiResponse = {
  id: string;
  name: string;
  type: LSPTypes;
  countries?: CountryName[];
  profileUrl?: string;
  noOfEmployees: number;
  contactEmails?: string[];
  inactive?: boolean;
};

export type LSP = LSPApiResponse & {
  displayCountries: string;
  isActive: boolean;
  displayTypes: string;
};

export type LSPUpdateApiRequest = Partial<
  Pick<LSPApiResponse, 'name' | 'inactive'>
>;
