import { Collapsible } from 'common/components/atoms/Collapsible/Collapsible';
import { AlertCircle, Clipboard } from 'react-feather';
import styled from 'styled-components';
import { copyToClipboard } from 'utils/copyToClipboard';

import { EmployeeUploadErrors, EmployeeUploadStatusTypes } from '../types';

const IconContainer = styled.div`
  width: 24px;
  margin-right: ${({ theme: { space } }) => space[16]};
`;

const ClipBoarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: ${({ theme: { space } }) => `${space[0]} ${space[16]}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  color: ${({ theme: { colors } }) => colors['text-default']};
  cursor: pointer;
`;

const AccordionSummaryTitle = styled.div`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const ClipboardIcon = styled(Clipboard)`
  color: ${({ theme: { colors } }) => colors['icon-default']};
  margin-right: ${({ theme: { space } }) => space[8]};
`;

const AlertIcon = styled(AlertCircle)`
  color: ${({ theme: { colors } }) => colors['icon-negative']};
`;

const ErrorContainer = styled.div`
  padding: ${({ theme: { space } }) => `${space[32]} ${space[0]}`};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  border-left: 0;
  border-right: 0;
`;

const EmployeeName = styled.span`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
`;

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  margin-bottom: ${({ theme: { space } }) => space[16]};
`;

const StatusMessageMapping: Record<EmployeeUploadStatusTypes, string> = {
  pending: 'Seat can not be created',
  'seat:created': 'Seat can not be converted',
  'seat:converted': 'Employee status can not be set to active',
  'onboarding:updated': 'Typeform can not be disabled for the employee',
  'typeform:disabled': '',
};

type ErrorAccordionProps = {
  errors: EmployeeUploadErrors;
};

export const ErrorAccordion = ({ errors }: ErrorAccordionProps) => {
  const copyInvalidEmployeesToClipboard = (
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.preventDefault();
    const emails = errors.map((employee) => employee.email).join(',');
    copyToClipboard(emails);
  };
  return (
    <>
      <Collapsible
        noMaxHeigh
        id="validationAccordion"
        title={
          <AccordionSummaryTitle>
            <div>{errors.length} has not been imported</div>
            <ClipBoarContainer onClick={copyInvalidEmployeesToClipboard}>
              <ClipboardIcon size={12} />
              <div>Copy all to clipboard</div>
            </ClipBoarContainer>
          </AccordionSummaryTitle>
        }
      >
        <div>
          {errors.map((employee, index) => {
            return (
              <ErrorContainer key={index}>
                <div style={{ marginBottom: 22, fontSize: 16 }}>
                  <EmployeeName>{employee.name}</EmployeeName>{' '}
                  <>{employee.email}</>
                </div>
                {StatusMessageMapping[employee.migrationStatus] && (
                  <ErrorMessage>
                    <IconContainer>
                      <AlertIcon size={24} />
                    </IconContainer>
                    <div>{StatusMessageMapping[employee.migrationStatus]}</div>
                  </ErrorMessage>
                )}

                <ErrorMessage>
                  <IconContainer>
                    <AlertIcon size={24} />
                  </IconContainer>
                  <div>{employee.error}</div>
                </ErrorMessage>
              </ErrorContainer>
            );
          })}
        </div>
      </Collapsible>
    </>
  );
};
