import styled from 'styled-components';

import { StyledProfilePicture } from '../EmployeeDetailsPage/EmployeeDetailsPage.styles';

export const StyledEditEmployeePage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledProfilePictureSmall = styled(StyledProfilePicture)`
  width: 40px;
  height: 40px;
`;
