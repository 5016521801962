import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledModal = styled(ModalWrapper)`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  @media (min-width: ${tokens.breakpoint.tablet}) {
    height: 85%;
    position: relative;
    width: 50%;
    overflow-y: auto;
  }
`;
