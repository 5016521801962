import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { ErrorBanner, Loading } from 'common/components';
import { Form } from 'common/components/molecules/Form/Form';
import {
  FormProvider,
  useFormContext,
} from 'common/components/molecules/Form/FormProvider';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { PayrollProvider } from 'common/types';
import { PROVIDERS_PAGE } from 'paths';
import { usePayrollProviderQuery } from 'utils/queries/payrollProviders/usePayrollProvidersQuery/usePayrollProvidersQuery';
import { useUpdatePayrollProviderMutation } from 'utils/queries/payrollProviders/useUpdatePayrollProviderMutation';

import {
  PayrollProviderFormData,
  transformFormDataForAPI,
} from '../payrollProvider';
import { payrollProviderFormConfig } from '../payrollProviderFormConfig';

const EditPayrollProviderForm = () => {
  const history = useHistory();
  const { mutate, isLoading } = useUpdatePayrollProviderMutation();
  const goToListAction = () =>
    history.push(`${PROVIDERS_PAGE}#payroll-providers`);

  const {
    values: { hasParent },
    onFieldChange,
  } = useFormContext();

  const update = useCallback(
    (payrollProviderFormData: PayrollProviderFormData) => {
      mutate(transformFormDataForAPI(payrollProviderFormData), {
        onSuccess: goToListAction,
      });
    },
    [goToListAction, mutate],
  );

  useEffect(() => {
    if (!hasParent) {
      onFieldChange('parent', null);
    }
  }, [hasParent, onFieldChange]);

  return (
    <Form
      isLoading={isLoading}
      onSubmit={update}
      ctaText="Save Edit"
      showCancel={true}
      onCancel={goToListAction}
    />
  );
};

export const EditPayrollProviderPage = () => {
  const { id: payrollProviderId } = useParams<{ id: string }>();
  const payrollProviderQuery = usePayrollProviderQuery(payrollProviderId, {
    queryOptions: { staleTime: 0, cacheTime: 0 },
  });
  const payrollProvider = payrollProviderQuery?.data as PayrollProvider;

  if (payrollProviderQuery.isLoading) {
    return <Loading />;
  }

  if (payrollProviderQuery.isError) {
    return <ErrorBanner errorMessage={payrollProviderQuery.error.message} />;
  }

  return (
    <PageContent data-testid="edit-payroll-provider-page" className="bigStack">
      <PageHeader
        title="Edit payroll provider"
        backLink={{
          url: `${PROVIDERS_PAGE}#payroll-providers`,
          label: 'Back to list',
        }}
      />
      {!payrollProviderQuery.isLoading && (
        <FormProvider config={payrollProviderFormConfig(payrollProvider)}>
          <EditPayrollProviderForm />
        </FormProvider>
      )}
    </PageContent>
  );
};
