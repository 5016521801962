export const getDocumentUploadingSelector = (state) =>
  state.documentStorageReducer.uploading;
export const getUploadedDocumentLocation = (state) =>
  state.documentStorageReducer.documentLink;
export const getDocumentUploadedSelector = (state) =>
  state.documentStorageReducer.documentUploaded;
export const getDocumentFileInfoSelector = (state) => ({
  file: state.documentStorageReducer.documentFile,
  documentType: state.documentStorageReducer.documentType,
});
