import { SowStatus } from 'common/types';
import styled from 'styled-components';

import { Tag } from '@omnipresentgroup/design-system';

import Button from '../../atoms/Button';

const SOWStatusActionItem = styled(Button)<{
  palette?: 'primary' | 'secondary';
}>`
  text-transform: none;
  padding: ${({ theme: { space } }) => `${space[4]} ${space[4]}`};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  min-height: auto;
`;

interface Props {
  status: SowStatus;
  testId?: string;
  onClick?: () => void;
}

const StatementOfWorkStatus = ({ status, testId, onClick }: Props) => {
  const handleOnClick = () => {
    if (onClick) {
      return onClick();
    }
  };
  switch (status) {
    case 'sow:awaiting-proposal':
      return (
        <Tag id="awaiting-proposal" data-testid={`${testId}-sow-status-pill`}>
          Awaiting order form
        </Tag>
      );
    case 'sow:awaiting-acceptance':
      return (
        <SOWStatusActionItem
          variant="link"
          palette="secondary"
          data-testid={`${testId}-sow-status-pill`}
        >
          View order form
        </SOWStatusActionItem>
      );
    case 'sow:accepted':
      return (
        <SOWStatusActionItem
          variant="outlined"
          palette="secondary"
          data-testid={`${testId}-sow-status-pill`}
          onClick={() => handleOnClick()}
        >
          Complete profile
        </SOWStatusActionItem>
      );
    default:
      return null;
  }
};

export default StatementOfWorkStatus;
