import { downloadDocumentAction } from 'common/store/actions/documents.actions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@omnipresentgroup/design-system';

const DetailsCardReceiptType = ({ value, downloadDocument }) => {
  return value ? (
    <Button onClick={() => downloadDocument(value)}>Download</Button>
  ) : (
    <Button disabled>No Document Uploaded</Button>
  );
};

DetailsCardReceiptType.propTypes = {
  value: PropTypes.string.isRequired,
  downloadDocument: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  downloadDocument: downloadDocumentAction,
};

export default connect(null, mapDispatchToProps)(DetailsCardReceiptType);
