import { ReactComponent as IntegrationsIcon } from 'assets/icon/Integrations.svg';
import Button from 'common/components/atoms/Button';
import Loading from 'common/components/atoms/Loading/Loading';
import { useMergeLink, useSelectGlobalLoading } from 'utils/hooks';

import { Box, Typography } from '@omnipresentgroup/design-system';

import * as Styled from './HRISIntegrationSection.styles';

export const HRISIntegrationSection = () => {
  const isGlobalLoading = useSelectGlobalLoading();
  const { open: openMergeModal } = useMergeLink();

  return (
    <Styled.Container>
      {isGlobalLoading ? (
        <Loading />
      ) : (
        <>
          <Styled.Title>Want to import your employee data?</Styled.Title>

          <Box mt="4" mr="0" mb="16">
            <Typography as="p" hideParagraphSpacing size="14" color="helper">
              Add the integration with your Human Resources Information System
            </Typography>
          </Box>

          <Button
            palette="primary"
            variant="outlined"
            onClick={openMergeModal}
            icon={<IntegrationsIcon width="20" title="Add HRIS Integration" />}
          >
            Connect HRIS
          </Button>
        </>
      )}
    </Styled.Container>
  );
};
