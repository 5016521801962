import { ReactNode } from 'react';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from 'common/components';
import { Provider } from 'react-redux';
import { getUserMainRole } from 'utils/user';

import { AUTH0_ROLES } from '../appConstants';
import { Root } from './Root';
import { getStore } from './store/store';

type Props = {
  children: ReactNode;
};

const ReduxWithUser = ({ children }: Props) => {
  const { user: userFromAuth0, isAuthenticated } = useAuth0();
  const userRoles = userFromAuth0 && userFromAuth0[AUTH0_ROLES];
  const userRole = getUserMainRole(userRoles).type || '';

  if (isAuthenticated && userRole) {
    if (userRole === 'contractor') {
      window.location.assign(process.env.VITE_CONTRACTOR_APP);
    } else {
      const store = getStore(userRole);

      if (store) {
        return (
          <Provider store={store}>
            <Root userRole={userRole}>{children}</Root>
          </Provider>
        );
      }
    }
  }

  // Render a loading spinner when the authentication state is loading
  // or the store is not yet available.
  return <Loading />;
};

const AuthenticatedReduxWithUser = withAuthenticationRequired(ReduxWithUser, {
  onRedirecting: Loading,
});

export { AuthenticatedReduxWithUser as ReduxWithUser };
