import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

export const documentsHandlers = [
  rest.get(composeEndpoint('/documents'), (_req, res, ctx) => {
    return res(ctx.json(generateDocuments()));
  }),
  rest.get(
    composeEndpoint('/documents/:documentId/download'),
    (_req, res, ctx) => {
      return res(
        ctx.json({
          url: 'https://document-storage-bucket-multi-dev.s3.eu-west-2.amazonaws.com/document/123',
        }),
      );
    },
  ),
  rest.post(composeEndpoint('/documents'), (_req, res, ctx) => {
    return res(ctx.json(generateDocuments()));
  }),
];

const defaultDocuments = {
  items: [
    {
      description: 'Right to work document',
      id: 'f606862a-1a48-4d24-8070-90f30263b763',
      userId: '82e11408-5cd4-4ce1-8d54-73622f4930b5',
      linkToDocument:
        'https://document-storage-bucket-dev.s3.eu-west-2.amazonaws.com/receipt/e490badc-63af-4577-9060-271714ba3681-dummy-payslip.jpg',
      createdAt: '2020-09-04T15:41:17.293Z',
      type: 'document',
      title: 'Right to work',
      actions: ['documents:download', 'documents:delete'],
    },
    {
      description: 'Form to complete to apply for healthcare',
      id: 'f606862a-1a48-4d24-8070-90f30263b805',
      userId: '82e11408-5cd4-4ce1-8d54-73622f4930b5',
      linkToDocument:
        'https://document-storage-bucket-dev.s3.eu-west-2.amazonaws.com/receipt/e490badc-63af-4577-9060-271714ba3681-dummy-payslip.jpg',
      createdAt: '2020-08-04T15:41:17.281Z',
      type: 'document',
      title: 'Health care form',
      actions: ['documents:download', 'documents:delete'],
    },
    {
      description: 'Employment contract document',
      id: 'f606862a-1a48-4d24-8070-90f30263b642',
      userId: '82e11408-5cd4-4ce1-8d54-73622f4930b5',
      linkToDocument:
        'https://document-storage-bucket-dev.s3.eu-west-2.amazonaws.com/receipt/e490badc-63af-4577-9060-271714ba3681-dummy-payslip.jpg',
      createdAt: '2020-07-04T15:41:17.294Z',
      type: 'document',
      title: 'Employment contract',
      actions: ['documents:download', 'documents:delete'],
    },
  ],
  actions: ['documents:list', 'documents:create'],
};

export const generateDocuments = (override = {}) => ({
  ...defaultDocuments,
  override,
});
