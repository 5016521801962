import { getUserSelector } from 'app/store/app.selectors';
import { getAllEmployeesRequest } from 'common/api/employees.api';
import { ONE_MINUTE } from 'common/constants';
import {
  SimplifiedEmployee,
  SimplifiedEmployeesApiResponse,
} from 'common/types';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getDataFromResponse } from 'utils/react-query-utils';
import { isAdmin } from 'utils/user';

import { sortAlphabetically } from '../../../sort.util';
import { employeesKeys } from '../keys';

export const useEmployeesQuery = () => {
  const user = useSelector(getUserSelector);
  const hasPermissionToGetAllEmployees = isAdmin(user);
  return useQuery<SimplifiedEmployeesApiResponse, Error, SimplifiedEmployee[]>(
    employeesKeys.allemployees,
    () => getAllEmployeesRequest().then(getDataFromResponse),
    {
      enabled: hasPermissionToGetAllEmployees,
      select: (data) => sortAlphabetically('name', data.employees),
      staleTime: ONE_MINUTE,
    },
  );
};
