import { client } from 'api';
import { AxiosInstance } from 'axios';

export const composeEndpoint = (
  endpoint: string,
  clientApi?: AxiosInstance,
) => {
  const formatEndpoint = (href: string) => href.replace(/^(\/)|(\/)$/g, '');

  const formattedEndpoint = formatEndpoint(endpoint);

  return clientApi
    ? `${clientApi.defaults.baseURL}/${formattedEndpoint}`
    : `${client.defaults.baseURL}/${formattedEndpoint}`;
};
