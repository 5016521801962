import { useMemo, useState } from 'react';

import appHistory from 'app//appHistory';
import { getUserSelector } from 'app/store/app.selectors';
import { StyledBoldCopy } from 'common/components/atoms/Text/Copy.styles';
import Card from 'common/components/molecules/Card/Card';
import MobileCardListHeader from 'common/components/molecules/MobileCardListHeader/MobileCardListHeader';
import TableWithTitle from 'common/components/organisms/TableWithTitle/TableWithTitle';
import { ManagerActionEnum } from 'common/types';
import { COMPANIES_LIST_PAGE } from 'paths';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isCSAdmin, isOPAdmin } from 'utils/user';

import { Viewport } from '@omnipresentgroup/design-system';

import { ReactComponent as ArrowRight } from '../../../../../assets/icon/ArrowRight.svg';
import { StyledEmployeeCardTitle } from '../../../../manager/pages/ManagerEmployeePage/ManagerEmployeePage.styles';
import { StyledTab } from '../CompanyDetailPage.styles';
import { DeleteManagerModal } from './DeleteManagerModal/DeleteManagerModal';
import { managerTableHeaders } from './managerTableHeaders';

const ManagersTab = ({
  managersToDisplay,
  companyId,
  companyManagers,
  reloadManagers,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedManager, setSelectedManager] = useState();

  const user = useSelector(getUserSelector);

  const onManagerClicked = (column, manager) => {
    if (column === ManagerActionEnum.DELETE) {
      setSelectedManager(manager);
      setIsDeleteModalOpen(true);
    }

    if (column === 'manager') {
      appHistory.push(
        `${COMPANIES_LIST_PAGE}/${companyId}/managers/${manager.id}`,
      );
    }
  };

  const onCTAClicked = () =>
    appHistory.push(`${COMPANIES_LIST_PAGE}/${companyId}/add-employee`);

  const managerTabHeaders = managerTableHeaders.filter((header) => {
    if (managersToDisplay.length < 2) {
      return header.id !== 'action';
    }
    return header;
  });

  const tableHeaders = managerTabHeaders.filter(
    (header) => isCSAdmin(user) || isOPAdmin(user) || header.id !== 'action',
  );

  const headersForCard = managerTabHeaders.filter(
    (header) => header.id !== 'manager' && header.id !== 'action',
  );

  const mergedManagers = managersToDisplay.map((managerOne) => ({
    ...managerOne,
    ...companyManagers.find((managerTwo) => managerTwo.id === managerOne.id),
  }));

  const custManagersToDisplay = useMemo(() => {
    return mergedManagers.map(({ ...manager }) => ({
      ...manager,
    }));
  }, [mergedManagers]);

  return (
    <>
      <DeleteManagerModal
        isOpen={isDeleteModalOpen}
        companyId={companyId}
        managerDetails={{
          id: selectedManager?.id,
          name: selectedManager?.name,
          role: selectedManager?.role,
        }}
        closeFn={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        successFn={() => reloadManagers()}
        hidden={managersToDisplay.length < 2}
      />
      <StyledTab data-testid="managers-tab" className="smallStack">
        <Viewport devices={['laptop', 'desktop', 'highRes']}>
          <TableWithTitle
            itemsToDisplay={custManagersToDisplay}
            headers={tableHeaders}
            onCTAClicked={onCTAClicked}
            CTAText="Add manager"
            showButton
            testId="employees"
            onColumnItemClick={onManagerClicked}
          />
        </Viewport>
        <Viewport devices={['phone', 'tablet']}>
          <>
            <MobileCardListHeader total={managersToDisplay.length} />
            {!!managersToDisplay?.length &&
              managersToDisplay.map((manager) => (
                <Card
                  key={manager.id}
                  fieldsToShow={headersForCard}
                  item={manager}
                  icon={<ArrowRight />}
                  title={
                    <StyledEmployeeCardTitle>
                      <StyledBoldCopy>{manager.name}</StyledBoldCopy>
                    </StyledEmployeeCardTitle>
                  }
                  onClick={() => {
                    appHistory.push(
                      `${COMPANIES_LIST_PAGE}/${companyId}/managers/${manager.id}`,
                    );
                  }}
                />
              ))}
          </>
        </Viewport>
      </StyledTab>
    </>
  );
};

ManagersTab.propTypes = {
  managersToDisplay: PropTypes.oneOfType([PropTypes.array]).isRequired,
  companyId: PropTypes.string.isRequired,
  companyManagers: PropTypes.oneOfType([PropTypes.array]).isRequired,
  reloadManagers: PropTypes.func.isRequired,
};

export default ManagersTab;
