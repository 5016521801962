import {
  APPROVED,
  ARCHIVED,
  DRAFT,
  PENDING_REVIEW,
  REVIEW_DONE,
  SIGNED_EMPLOYEE,
} from 'common/types';
import { useSelectUserRoles } from 'utils/hooks/redux-selectors';

export function useContractLabel(status: string) {
  const userRole = useSelectUserRoles();
  switch (true) {
    case status === DRAFT:
      return 'Review contract';
    case userRole.isManager && status === PENDING_REVIEW:
      return null;
    case userRole.isEmployee && status === APPROVED:
      return 'Sign contract';
    case userRole.isCSAdmin && status === PENDING_REVIEW:
      return 'Complete missing information';
    case userRole.isCSAdmin && status === REVIEW_DONE:
      return 'Review and send to manager';
    case userRole.isCSAdmin && status === SIGNED_EMPLOYEE:
    case userRole.isOPAdmin && status === SIGNED_EMPLOYEE:
      return 'Countersign contract';
    case userRole.isCSAdmin && status === ARCHIVED:
      return 'View archived contract';
    default:
      return 'View contract';
  }
}
