import { StyledLabel } from 'common/components/atoms/Label';
import styled, { css } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

const smallInput = css`
  margin-bottom: ${({ theme: { space } }) => space[4]};
`;

const largeInput = css`
  margin-bottom: ${({ theme: { space } }) => space[16]};
`;

const error = css`
  margin-bottom: 0;
`;

export const StyledDetailsFieldContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex-basis: ${(props) => (props.oneInputPerRow ? '60%' : '50%')};
  ${(props) => (props.small ? smallInput : largeInput)}
  ${(props) => (props.error ? error : null)}

  @media (max-width: ${tokens.breakpoint.tablet}) {
    flex-basis: 100%;
  }

  input:disabled {
    cursor: not-allowed;
  }
`;

export const StyledLabelMultipleLine = styled(StyledLabel)`
  span {
    display: block;
    margin-top: ${({ theme: { space } }) => space[16]};
  }

  span:last-of-type {
    display: inline-block;
  }
`;
