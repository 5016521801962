import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
`;

export const DropdownContainer = styled.div`
  margin: ${({ theme: { space } }) => space[4]};
  flex: 1;
`;

export const EmptyVendorContainer = styled.div`
  width: 75%;
`;

export const CurrencyContainer = styled.div`
  margin: ${({ theme: { space } }) => space[4]};
`;

export const CurrencyList = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin: ${({ theme: { space } }) => space[4]};
  }
`;
