import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { ErrorBanner, Loading, PageHeader } from 'common/components';
import { StyledLabel } from 'common/components/atoms/Label';
import MobileButton from 'common/components/atoms/MobileButton/MobileButton';
import { StyledDropdownInput } from 'common/components/molecules/DropdownInput/DropdownInput.styles';
import { StyledDetailsFieldContainer } from 'common/components/molecules/EditableInput/EditableInput.styles';
import FormSubmitButton from 'common/components/molecules/FormSubmitButton/FormSubmitButton';
import {
  additionalAdminRoleNames,
  userRoleTypeToFriendlyName,
} from 'common/types';
import { ADMIN_LIST_PAGE } from 'paths';
import { useQueryClient } from 'react-query';
import Select, { MultiValue } from 'react-select';
import { useUserQuery } from 'utils/queries/users';
import { usersKeys } from 'utils/queries/users/keys';
import { useEditAdminUserMutation } from 'utils/queries/users/useEditAdminUserMutation';

import { Viewport } from '@omnipresentgroup/design-system';

import {
  StyledEditPageMain,
  StyledSectionContainer,
  StyledSectionContainerWrapper,
  StyledSectionFormContainer,
  StyledSectionFormContainerWrapper,
} from '../AddAdminPage/AddAdminPage.styles';

type FormValues = {
  additionalAdminRoles: Array<{ label: string; value: string }> | [];
};

export const EditAdminPage = () => {
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const {
    refetch,
    data: user,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useUserQuery(userId);

  const queryClient = useQueryClient();

  const mutation = useEditAdminUserMutation(userId);

  const options: Array<{ label: string; value: string }> = [
    ...additionalAdminRoleNames.map((role) => ({
      value: role,
      label: userRoleTypeToFriendlyName[role],
    })),
  ];

  const currentRoles: string[] | undefined = user?.roles.map(
    (role) => role.type,
  );

  const existingRoles = options.filter(
    (option) => currentRoles?.includes(option.value),
  );

  const currentIdAdminRole: string | null = isSuccess
    ? user?.roles.filter((role) => role.type === 'admin')?.[0]?.id || null
    : null;

  const [formValues, setFormValues] = useState<FormValues>({
    additionalAdminRoles: [],
  });

  useEffect(() => {
    setFormValues({ additionalAdminRoles: existingRoles });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (mutation.isSuccess) {
      refetch();
      queryClient.invalidateQueries(usersKeys.allAdmins);
      history.push(`${ADMIN_LIST_PAGE}/${userId}`);
    }
  }, [history, mutation, queryClient, refetch, userId]);

  const onChange = (
    newValue: MultiValue<{
      label: string;
      value: string;
    }>,
  ) => {
    const newRoles = newValue.filter((role) => role.value !== 'admin');
    setFormValues(() => ({
      additionalAdminRoles: [...newRoles],
    }));
  };

  const onSubmit = async (evt: any) => {
    evt.preventDefault();
    let data = {} as any;
    if (currentIdAdminRole) {
      data = { currentIdAdminRole };
    }
    if (formValues['additionalAdminRoles']) {
      data = {
        ...data,
        additionalAdminRoles: formValues['additionalAdminRoles'].map(
          (data) => data.value,
        ),
      };
    }
    await mutation.mutateAsync(data, { onError: (err) => console.error(err) });
  };

  const FORM_ID = 'edit-admin';
  const ctaText = 'Save edits';
  const backLink = {
    label: 'Back to admin list',
    url: ADMIN_LIST_PAGE,
  };

  return (
    <PageContent data-testid="edit-admin-page">
      {(isLoading || mutation.isLoading) && <Loading />}
      {isError && <ErrorBanner errorMessage={error?.message} />}
      {mutation.isError && (
        <ErrorBanner errorMessage={mutation.error?.toJSON()?.toString()} />
      )}
      {isSuccess && (
        <StyledEditPageMain className="bigStack">
          <PageHeader
            title={`Edit an admin: ${user?.name}`}
            backLink={backLink}
          />
          <StyledSectionContainer data-testid="edit-page-form">
            <StyledSectionContainerWrapper>
              <StyledSectionFormContainerWrapper>
                <StyledSectionFormContainer>
                  <form id={FORM_ID}>
                    <StyledDetailsFieldContainer>
                      <StyledLabel
                        required
                        id="additionalAdminRoles"
                        htmlFor="additionalAdminRoles"
                      >
                        Additional admin roles
                      </StyledLabel>
                      <StyledDropdownInput data-testid="additionalAdminRoles-dropdown-input">
                        <Select
                          inputId="additionalAdminRoles"
                          aria-labelledby="additionalAdminRoles"
                          options={options}
                          onChange={onChange}
                          value={formValues.additionalAdminRoles}
                          isLoading={isLoading}
                          isMulti
                        />
                      </StyledDropdownInput>
                    </StyledDetailsFieldContainer>
                    <FormSubmitButton
                      ctaText={ctaText}
                      onCTAClick={onSubmit}
                      showCancel
                      disabled={mutation.isLoading}
                    />
                  </form>
                </StyledSectionFormContainer>
              </StyledSectionFormContainerWrapper>
            </StyledSectionContainerWrapper>
          </StyledSectionContainer>
        </StyledEditPageMain>
      )}
      {isSuccess && (
        <Viewport devices={['phone', 'tablet']}>
          <MobileButton onClick={onSubmit} disabled={mutation.isLoading}>
            {ctaText}
          </MobileButton>
        </Viewport>
      )}
    </PageContent>
  );
};
