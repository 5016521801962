import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import { App } from 'app/App';
import history from 'app/appHistory';
import { IS_DEV_ENV } from 'common/constants';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { getClientSideID } from 'launchDarkly/config';
import { Auth0ProviderWithHistory } from 'providers';
import { initTracker } from 'utils/mixpanel/tracker';

import { ToastProvider } from '@omnipresentgroup/design-system';

import { initializeMSW } from './mocks/browser';
import { sentryInit } from './sentry';
import { intercomBoot } from './utils/intercom/utils';

sentryInit(history);
initTracker();
intercomBoot();

const isMockServerEnabled = process.env.VITE_MSW_ENABLED === 'true';

async function prepare() {
  if (IS_DEV_ENV && isMockServerEnabled) {
    return initializeMSW();
  }
}

prepare().then(async () => {
  const LDProvider = await asyncWithLDProvider({
    options: { disableSyncEventPost: true, allAttributesPrivate: true },
    clientSideID: getClientSideID(),
  });

  ReactDOM.render(
    <StrictMode>
      <Router history={history}>
        <ToastProvider />
        <Auth0ProviderWithHistory>
          <LDProvider>
            <App />
          </LDProvider>
        </Auth0ProviderWithHistory>
      </Router>
    </StrictMode>,
    document.getElementById('root'),
  );
});
