import { DropdownOption } from 'common/types';
import { Props } from 'react-select';

import * as Styled from './HRISIntegrationInput.styles';

type SelectProps = Props<DropdownOption>;

export const noOptionsMessage: SelectProps['noOptionsMessage'] = () =>
  'No employees found';

export const loadingMessage: SelectProps['loadingMessage'] = () =>
  'Loading employees from HRIS';

export const formatOptionLabel: SelectProps['formatOptionLabel'] = (option) => {
  const [name, email] = option.label.split(/\s+(?=\()/);

  return (
    <Styled.DropdownOptionWrapper className="custom-option-container">
      <strong>{name}</strong>

      {email && <span>{email}</span>}
    </Styled.DropdownOptionWrapper>
  );
};
