import { client } from '../../../api';

export const getManagersEmployeesExpensesRequest = (managerId) =>
  client.get(`expenses/approver/${managerId}`, {
    params: { view: 'simplified' },
  });

export const getEmployeeExpenseRequest = (expenseId) =>
  client.get(`expenses/${expenseId}`);

export const updateEmployeeExpenseRequest = (
  expenseId,
  expenseFieldsToUpdate,
) => client.patch(`expenses/${expenseId}`, expenseFieldsToUpdate);
