import styled from 'styled-components';

export const StyledNameTableCell = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme: { space } }) => `${space[4]} ${space[4]}`};
`;

export const StyledName = styled.p`
  margin: ${({ theme: { space } }) => `${space[4]} ${space[4]}`};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
`;

export const StyledJobTitle = styled.p`
  margin: ${({ theme: { space } }) => `${space[4]} ${space[4]}`};
  color: ${({ theme: { colors } }) => colors['text-subtle']};
`;

export const StyledProfilePicture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: ${({ theme: { radii } }) => radii.full};
`;
