import { useEffect, useState } from 'react';

import { joiResolver } from '@hookform/resolvers/joi';
import { PageContent } from 'app/App.styles';
import { getUserSelector } from 'app/store/app.selectors';
import Big from 'big.js';
import Loading from 'common/components/atoms/Loading/Loading';
import MobileButton from 'common/components/atoms/MobileButton/MobileButton';
import EditableInput, {
  getDependentFieldOptions,
} from 'common/components/molecules/EditableInput/EditableInput';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import FormSubmitButton from 'common/components/molecules/FormSubmitButton/FormSubmitButton';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import {
  StyledEditDetailsPage,
  StyledEditPageMain,
  StyledFormHeader,
  StyledFormHeaderSubString,
  StyledSectionContainer,
  StyledSectionContainerWrapper,
  StyledSectionFormContainer,
  StyledSectionFormContainerWrapper,
} from 'common/pages/EditDetailsPage/EditDetailsPage.styles';
import {
  BenefitsCountryFee,
  DepositFeeWithType,
  Field,
  FIELD_TYPE,
  FilteredCompanyUpsellFees,
  FormData,
  FormFields,
  Salary,
} from 'common/types';
import { EmployerCostFee } from 'common/types/employerCost';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getIsRedactedValue } from 'utils/rbac.utils';
import { isAdmin } from 'utils/user';

import { Viewport } from '@omnipresentgroup/design-system';

import { AutomatedUpsellSection } from '../automatedUpsellSection/AutomatedUpsellSection';

type AddEditFormPageProps = {
  formTitle: string;
  formHeader?: string;
  formHeaderSubstring?: string;
  formFields: FormFields;
  schema: any;
  keyFacts?: boolean;
  backLink: {
    url: string;
    label: string;
  };
  submitCTA: string;
  onSubmit: (data: any) => void;
  onFieldChange?: (
    name: string,
    value: string,
    formFields: Record<string, any>,
    selectedOption: Record<string, any>,
  ) => void;
  showLoading?: boolean;
  errorBannerMessage?: string;
  onErrorBannerReset?: () => void;
  updateMode?: boolean;
  isSubmitDisabled?: boolean;
  upsellFeeData?: FilteredCompanyUpsellFees;
  depositFeeData?: DepositFeeWithType;
  annualBaseSalaryData?: Salary;
  currencyExchangeRateData?: Big | number;
  enableAutomatedUpsellForManager?: boolean;
  isLoadingCosts?: boolean;
  billingCurrency?: string;
  country?: string;
  employerCostFees?: EmployerCostFee[];
  benefitsCountryFeeData?: BenefitsCountryFee;
  keyFactsExist?: (value: boolean) => void;
};

export const AddEditFormPage: React.FC<AddEditFormPageProps> = ({
  formTitle,
  formHeader,
  formHeaderSubstring,
  formFields,
  backLink,
  isSubmitDisabled,
  submitCTA,
  onSubmit,
  showLoading,
  errorBannerMessage,
  onErrorBannerReset,
  updateMode,
  schema,
  keyFacts,
  upsellFeeData,
  depositFeeData,
  annualBaseSalaryData,
  currencyExchangeRateData,
  enableAutomatedUpsellForManager,
  onFieldChange,
  keyFactsExist,
  billingCurrency,
  country,
  employerCostFees,
  benefitsCountryFeeData,
  isLoadingCosts,
}: AddEditFormPageProps) => {
  const user = useSelector(getUserSelector);

  const [countryName, setCountryName] = useState('');
  const [disabledInputFields, setDisableInputFields] = useState(false);

  const disableInputFields = (
    value: boolean | ((prevState: boolean) => boolean),
  ) => {
    setDisableInputFields(value);
  };

  const filteredFields = formFields.filter((field) => {
    if (getIsRedactedValue(field.value)) {
      return false;
    }
    if (field.showFor === 'admin') {
      return isAdmin(user);
    }
    return true;
  });

  const formValues = filteredFields.reduce(
    (acc, { name, value }) => ({
      ...acc,
      [name]: value,
    }),
    {},
  ) as Record<string, any>;

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<FormData>({
    defaultValues: formValues,
    resolver: joiResolver(schema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstFieldInError = document?.getElementById(`${errorKeys[0]}`);
      firstFieldInError?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }, [errors]);

  const onSubmitClick = handleSubmit((data: Record<string, any>) => {
    if (Object.keys(errors).length === 0) {
      let dataRecord = data;
      if (updateMode) {
        dataRecord = {};
        Object.entries(dirtyFields).forEach(([key, value]: [string, any]) => {
          if (value) {
            dataRecord = { ...dataRecord, [key]: data[key] };
          }
        });
      }
      onSubmit(dataRecord);
    }
  });

  if (showLoading) {
    return <Loading />;
  }

  return (
    <>
      {errorBannerMessage && (
        <ErrorBanner
          onErrorClear={() => onErrorBannerReset && onErrorBannerReset()}
          errorMessage={errorBannerMessage}
        />
      )}
      <PageContent>
        <StyledEditDetailsPage>
          <StyledEditPageMain className="bigStack">
            <PageHeader title={formTitle} backLink={backLink} />
            <StyledSectionContainer
              data-testid="edit-page-form"
              style={{
                width: keyFacts ? '' : '50%',
              }}
            >
              <StyledSectionContainerWrapper>
                <StyledSectionFormContainerWrapper>
                  <StyledSectionFormContainer>
                    {formHeader && (
                      <StyledFormHeader>{formHeader}</StyledFormHeader>
                    )}
                    {formHeaderSubstring && (
                      <StyledFormHeaderSubString>
                        {formHeaderSubstring}
                      </StyledFormHeaderSubString>
                    )}
                    <form onSubmit={onSubmitClick}>
                      {filteredFields.map((field: Field) => {
                        const formField = { ...field };
                        const {
                          name,
                          type,
                          label,
                          noLabel,
                          contextualInfo,
                          readOnly,
                          required,
                          options,
                          placeholder,
                        } = field;
                        return (
                          <Controller
                            key={name}
                            control={control}
                            defaultValue={formValues?.[name]}
                            name={name}
                            render={({
                              field: {
                                onChange,
                                value: controlValue,
                                name: controlName,
                              },
                            }) => (
                              <EditableInput
                                name={controlName}
                                noLabel={noLabel}
                                type={type}
                                value={controlValue}
                                placeholder={placeholder}
                                label={label}
                                country={countryName}
                                editable={!readOnly}
                                disabled={
                                  name === 'country' || name === 'countryName'
                                    ? false
                                    : disabledInputFields
                                }
                                contextualInfo={contextualInfo}
                                errorMessage={
                                  errors[name]?.message || errors[name]
                                }
                                onChange={(
                                  _: any,
                                  value: string,
                                  selectedOption: Record<string, any>,
                                ) => {
                                  onFieldChange &&
                                    onFieldChange(
                                      name,
                                      value,
                                      formValues,
                                      selectedOption,
                                    );

                                  if (type === FIELD_TYPE.COUNTRY) {
                                    setCountryName(value);
                                  }

                                  if (type === FIELD_TYPE.DROPDOWN) {
                                    onChange(selectedOption.value);
                                  } else {
                                    onChange(value);
                                  }
                                }}
                                required={required}
                                options={
                                  type === FIELD_TYPE.DEPENDENT_DROPDOWN
                                    ? getDependentFieldOptions(
                                        formField,
                                        formValues,
                                      )
                                    : options
                                }
                              />
                            )}
                          />
                        );
                      })}
                    </form>
                    <FormSubmitButton
                      ctaText={submitCTA}
                      onCTAClick={onSubmitClick}
                      showCancel
                      disabled={isSubmitDisabled || disabledInputFields}
                    />
                  </StyledSectionFormContainer>
                  {keyFacts && (
                    <AutomatedUpsellSection
                      countryName={country || ''}
                      disabledFields={disableInputFields}
                      upsellFeeData={upsellFeeData}
                      depositFeeData={depositFeeData || {}}
                      annualBaseSalaryData={
                        annualBaseSalaryData || { amount: 0, currency: '' }
                      }
                      currencyExchangeRateData={currencyExchangeRateData || 0}
                      enableAutomatedUpsellForManager={
                        enableAutomatedUpsellForManager
                      }
                      keyFactsExist={keyFactsExist}
                      billingCurrency={billingCurrency || ''}
                      employerCostFees={employerCostFees}
                      isLoadingCosts={isLoadingCosts}
                      benefitsCountryFeeData={benefitsCountryFeeData}
                    />
                  )}
                </StyledSectionFormContainerWrapper>
              </StyledSectionContainerWrapper>
            </StyledSectionContainer>
          </StyledEditPageMain>
        </StyledEditDetailsPage>
        <Viewport devices={['phone', 'tablet']}>
          <MobileButton
            type="submit"
            onClick={onSubmitClick}
            disabled={disabledInputFields}
          >
            {submitCTA}
          </MobileButton>
        </Viewport>
      </PageContent>
    </>
  );
};
