import { AxiosError } from 'axios';
import { Field } from 'common/types';
import { Expense } from 'common/types/expenses';
import { updateEmployeeExpenseRequest } from 'omniplatform/manager/api/managerEmployeesExpenses.api';
import { transformUpdateExpenseRequest } from 'omniplatform/manager/store/transformers/employeesExpense.transformer';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

export enum PayloadKeysEnum {
  REQUESTED_CHANGE = 'requestedChange',
  REJECTION_REASON = 'rejectionReason',
}

// FIXME: this should be void cause it is a 204 but types break
// @ts-ignore
export type UpdateExpenseApiResponse = any;
export type UpdateExpensePayload =
  | { [PayloadKeysEnum.REJECTION_REASON]: string }
  | { [PayloadKeysEnum.REQUESTED_CHANGE]: string };

export const useUpdateExpenseMutation = (expenseId: string) => {
  return useMutation<
    UpdateExpenseApiResponse,
    AxiosError,
    UpdateExpensePayload
  >((requestData) => updateEmployeeExpenseRequest(expenseId, requestData));
};

export const useLegacyUpdateExpenseMutation = (expenseId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, Field, unknown>(
    (requestData) =>
      updateEmployeeExpenseRequest(
        expenseId,
        transformUpdateExpenseRequest(requestData),
      ),
    {
      onError: (error) => {
        dispatch({ type: 'UPDATE_EXPENSE_ERROR', payload: error });
      },
      onSuccess: (data, field) => {
        queryClient.setQueryData<Expense>(`expense-${expenseId}`, (expense) => {
          switch (field.name) {
            case 'requestedChange':
              return {
                ...(expense as Expense),
                status: 'INFO_NEEDED',
                requestedChange: field,
              };
            case 'rejectionReason':
              return {
                ...(expense as Expense),
                status: 'REJECTED',
                rejectionReason: field,
                requestedChange: {
                  ...(expense as Expense).requestedChange,
                  value: '',
                },
              };
          }
          return expense as Expense;
        });
        queryClient.invalidateQueries(`expense-${expenseId}`);
      },
    },
  );
};
