import { deleteEmployeeExpenseRequest } from 'omniplatform/employee/api/expenses.api';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

export const useDeleteExpenseMutation = (expenseId: string) => {
  const dispatch = useDispatch();

  return useMutation(() => deleteEmployeeExpenseRequest(expenseId), {
    onSuccess: () => {
      dispatch({ type: 'DELETE_EXPENSE_END', payload: expenseId });
    },
    onError: (error) => {
      dispatch({ type: 'DELETE_EXPENSE_ERROR', payload: error });
    },
  });
};
