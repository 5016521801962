import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { SimplifiedEmployee } from 'common/types';
import { ResignationModel } from 'common/types/resignations';

import { Table } from '@omnipresentgroup/design-system';

import { getResignationRequestRows } from './ResignationReqiestsTableUtil';
import { resignationTableHeaders } from './ResignationRequestsTableHeaders';

export type ResignationRequestsTableProps = {
  employees: SimplifiedEmployee[];
  resignations: ResignationModel[];
};

export const ResignationRequestsTable = ({
  employees,
  resignations,
}: ResignationRequestsTableProps) => {
  const table = useReactTable({
    data: getResignationRequestRows({ employees, resignations }),
    getCoreRowModel: getCoreRowModel(),
    columns: resignationTableHeaders,
  });

  return (
    <Table
      data-testid="resignation-request-table"
      showHeader={false}
      table={table}
    />
  );
};
