import { forwardRef, ReactNode } from 'react';

import { StyledContextualInfo } from 'common/components/atoms/ContextualInfo';
import { StyledErrorMessage } from 'common/components/atoms/ErrorMessage';
import { StyledLabel } from 'common/components/atoms/Label';
import Select, { GroupBase, Props } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';

import * as S from './FormSelect.styles';

export type FormSelectProps = Props & {
  contextualInfo?: ReactNode;
  error?: string;
  id: string;
  label: string;
  required?: boolean;
  async?: boolean;
  selectKey?: string;
} & AsyncProps<unknown, boolean, GroupBase<unknown>>;

export const FormSelect = forwardRef<HTMLInputElement, FormSelectProps>(
  (
    {
      contextualInfo,
      id,
      error,
      label,
      required,
      async,
      selectKey,
      ...rest
    }: FormSelectProps,
    forwardedRef,
  ) => {
    const SelectComponent = async ? AsyncSelect : Select;
    return (
      <S.FieldContainer error={error} ref={forwardedRef} data-testid={id}>
        <StyledLabel htmlFor={id} required={required}>
          {label}
        </StyledLabel>
        {contextualInfo && (
          <StyledContextualInfo id={`${id}-context`}>
            {contextualInfo}
          </StyledContextualInfo>
        )}
        <SelectComponent
          aria-describedby={contextualInfo ? `${id}-context` : ''}
          inputId={id}
          aria-label={label}
          name={`${id}-select`}
          key={selectKey}
          {...rest}
        />
        {error && (
          <StyledErrorMessage aria-label={`${id}-alert`} role="alert">
            {error}
          </StyledErrorMessage>
        )}
      </S.FieldContainer>
    );
  },
);

FormSelect.displayName = 'FormSelect';
