import { ReactElement } from 'react';

import { ContractAmendment } from 'common/types/contract';
import { useContractAmendmentQuery } from 'utils/queries/contract/useContractAmendmentQuery';

import {
  Box,
  Button,
  Spinner,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import { AmendmentCard } from './ContractAmendmentCard';

type ContractAmendmentsHistoryProps = {
  closeListAndOpenForm: () => void;
  setAmendmentToDispatch: (_: string) => void;
  contractDocumentId: string;
};

export const ContractAmendmentsHistory = ({
  closeListAndOpenForm,
  setAmendmentToDispatch,
  contractDocumentId,
}: ContractAmendmentsHistoryProps): ReactElement => {
  const {
    data: contractAmendments,
    isLoading,
    isSuccess,
  } = useContractAmendmentQuery(contractDocumentId);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spinner size="40" />
      </div>
    );
  } else if (isSuccess && !contractAmendments?.length) {
    return (
      <Stack align="center" mx="32" my="64">
        <Typography as="h5" hideParagraphSpacing size="24">
          No request history
        </Typography>
        <Box m="16">
          All contract edit request you make and their status will be visible
          here.
        </Box>
        <Button variant="secondary" onClick={closeListAndOpenForm}>
          Request contract edit
        </Button>
      </Stack>
    );
  } else if (isSuccess && contractAmendments?.length > 0) {
    return (
      <Stack bg="primary" color="default" depth="s" gap="16" p="16" radius="s">
        {contractAmendments
          ?.sort(sortByDate)
          .map((contractAmendment: ContractAmendment) => {
            return (
              <AmendmentCard
                key={contractAmendment.id}
                contractAmendment={contractAmendment}
                setAmendmentToDispatch={setAmendmentToDispatch}
              ></AmendmentCard>
            );
          })}
      </Stack>
    );
  }

  return <></>;
};

const sortByDate = (first: ContractAmendment, second: ContractAmendment) => {
  const firstDate = Date.parse(first.createdAt);
  const secondDate = Date.parse(second.createdAt);
  return secondDate - firstDate;
};
