import { MouseEventHandler } from 'react';

import { EmployeeForPaginationView } from 'common/types';
import sortBy from 'lodash/sortBy';

export interface PaginatedEmployeesPageData extends EmployeeForPaginationView {
  onClick: () => void;
  lsp: {
    id: string;
    name: string;
    onClick: () => MouseEventHandler;
  };
  company: {
    id: string;
    name: string;
    onClick: () => MouseEventHandler;
  };
}

export const transformEmployeesDataForPagination = (
  employees: EmployeeForPaginationView[],
) => {
  return sortBy(employees, ['name']);
};
