import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FormCheckbox } from 'common/components/molecules/FormCheckbox';
import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { CREATE_TERMINATION_REQUEST } from 'paths';
import { X } from 'react-feather';
import { useTrack } from 'utils/mixpanel/tracker';

import { Button, Center, Inline, Stack } from '@omnipresentgroup/design-system';

type TerminationWarningProps = {
  isOpen: boolean;
  closeFn: () => void;
  companyId: string;
  employeeId: string;
};

export const TerminationWarning = ({
  isOpen,
  closeFn,
  companyId,
  employeeId,
}: TerminationWarningProps) => {
  const handleOnClickButton = () => {
    closeFn && closeFn();
    setCheckBoxSelected(false);
  };
  const track = useTrack();
  const [checkBoxSelected, setCheckBoxSelected] = useState(false);
  const history = useHistory();
  return (
    <>
      {isOpen && (
        <ModalWrapper fixedWidth="600">
          <Stack p="32" gap="32" pt="16">
            <Inline justify="end">
              <X
                style={{ cursor: 'pointer' }}
                onClick={handleOnClickButton}
                data-testid="closeModalButton"
              />
            </Inline>
            <Center centerText>
              As Omnipresent is the legal employer, please do NOT inform the
              employee of termination. Instead submit the form on the next page
              and we will support you in a compliant manner.
            </Center>
            <FormCheckbox
              label="By ticking you agree that you have read the above."
              checked={checkBoxSelected}
              name="termination-request-agreement"
              id="termination-request-agreement"
              onChange={() => setCheckBoxSelected(!checkBoxSelected)}
            />
            <Inline justify="space-between">
              <Button
                variant="secondary"
                onClick={() => {
                  track('Cancel On Termination Request Warning Clicked', {
                    employeeId: employeeId,
                  });
                  handleOnClickButton();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  track('Continue On Termination Request Warning Clicked', {
                    employeeId: employeeId,
                  });
                  history.push(
                    CREATE_TERMINATION_REQUEST(companyId, employeeId),
                  );
                }}
                disabled={!checkBoxSelected}
              >
                Continue
              </Button>
            </Inline>
          </Stack>
        </ModalWrapper>
      )}
    </>
  );
};
