import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: ${({ theme: { colors } }) => colors['background-primary']};
  flex-direction: column;
  overflow: scroll;
  max-height: 736px;
  width: 400px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  border-bottom: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  padding: ${({ theme: { space } }) => space[16]};
  justify-content: space-between;
  align-items: center;
`;

export const ContainerClose = styled.div`
  color: ${({ theme: { colors } }) => `${colors['icon-default']}`};
`;

export const FixedHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  overflow: hidden;
`;
