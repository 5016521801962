import { PageContent } from 'app/App.styles';
import Loading from 'common/components/atoms/Loading/Loading';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { transformCompanyForTable } from 'common/store/transformers/company.transformer';
import { Company } from 'common/types';
import { useSelectUserRoles } from 'utils/hooks/redux-selectors/useSelectUserRoles';
import { useCompaniesQuery } from 'utils/queries';
import { sortObjectsByKey } from 'utils/sortObjectsByKey';

import { StyledAdminHomePage } from './CompanyListPage.styles';
import { CompanyListTab } from './CompanyListTab';

export const CompanyListPage = () => {
  const companiesQuery = useCompaniesQuery({ refetchOnWindowFocus: false });
  const companies = sortObjectsByKey<Company>({
    key: 'name',
    list: companiesQuery.data || [],
  }).map(transformCompanyForTable);

  const { isCSAdmin } = useSelectUserRoles();
  if (companiesQuery.isLoading || !companies?.length) {
    return <Loading />;
  }
  const onCTAClicked = () => {
    window.location.href = `${process.env.VITE_BO_APP}/companies/add`;
  };

  return (
    <PageContent>
      <StyledAdminHomePage data-testid="company-list-page" className="bigStack">
        <PageHeader
          title="Clients"
          ctaText={isCSAdmin && 'add new'}
          onCTAClick={onCTAClicked}
          testId="client-list-header"
        />
        <CompanyListTab
          companies={companies}
          onAddCompanyClicked={onCTAClicked}
        />
      </StyledAdminHomePage>
    </PageContent>
  );
};
