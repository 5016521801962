import { Ability, AbilityBuilder } from '@casl/ability';

export const ability = new Ability([]);

export const defineRulesForResources = ({
  resources: { actions, items },
  ownerId,
  subject,
}) => {
  const { can, rules } = new AbilityBuilder();

  if (actions) {
    can(actions, subject, {
      ownerId,
    });

    items.forEach((resource) => {
      can(resource.actions, subject, {
        id: resource.id,
      });
    });
  } else {
    // TODO: to be removed when the feature flag on the BE is unset
    // This will give all rights on a subject
    can('manage', subject);
  }

  // do not override rules for now
  ability.update([
    ...ability.rules.filter((rule) => rule.subject !== subject),
    ...rules,
  ]);
};
