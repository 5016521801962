export enum InitialInvoiceStatusType {
  SUCCESS = 'SUCCESS',
  SUBMITTED = 'SUBMITTED',
  FAILED = 'FAILED',
}

export enum SimpleInvoiceType {
  SETUP_DEPOSIT_FEE = 'op:employee:setup-deposit-fee',
  INITIAL_SALARY_RETAINER = 'op:initial-salary-retainer',
  SETUP_FEE_ONLY = 'op:employee:setup-fee',
  DEPOSIT_ONLY = 'op:employee:deposit',
}

export type SimpleInvoice = {
  id: string;
  currency: string;
  totalAmount: string;
};

export type InvoiceLog = {
  id: string;
  client: string;
  employee: string;
  type: SimpleInvoiceType;
  date: string;
  status: InitialInvoiceStatusType;
  amount?: {
    totalAmount: string;
    currency: string;
  };
};
