import { useEffect, useRef, useState } from 'react';

import Button from 'common/components/atoms/Button';
import EditableInput from 'common/components/molecules/EditableInput/EditableInput';
import { FIELD_TYPE, SowStatus, SOWStatusEnum } from 'common/types';
import { Sliders } from 'react-feather';
import { useOnClickOutside } from 'utils/hooks';

import {
  ActionButtons,
  FilterButton,
  FilterContainer,
  FilterElement,
  FilterHeading,
  FilterIconContainer,
  FilterPopupTitle,
  FilterPopupWrapper,
  FilterText,
  FilterTitle,
  StatusWrapper,
  Wrapper,
} from './SeatTabFilter.styles';

const STATUS_FILTER: { key: SowStatus; label: string }[] = [
  { key: SOWStatusEnum.SOW_AWAITING_PROPOSAL, label: 'Awaiting order form' },
  { key: SOWStatusEnum.SOW_AWAITING_ACCEPTANCE, label: 'View order form' },
  { key: SOWStatusEnum.SOW_ACCEPTED, label: 'Complete profile' },
];
export const INITIAL_FILTER_STATE = { country: '', status: [] };

export type SeatTableFilterType = { country: string; status: SowStatus[] };

export const SeatTabFilter = ({
  filters,
  onFilterUpdate,
}: {
  filters: SeatTableFilterType;
  onFilterUpdate: (filters: SeatTableFilterType) => void;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [showActionPopup, setShowActionPopup] = useState<boolean>(false);
  const [currentFilter, updateFilter] = useState<SeatTableFilterType>(filters);

  useEffect(() => {
    updateFilter(filters);
  }, [filters]);

  useOnClickOutside(elementRef, () => {
    updateFilter(filters);
    setShowActionPopup(false);
  });

  return (
    <Wrapper ref={elementRef} data-testid="filter-action-container">
      <FilterButton
        data-testid="filter-button"
        onClick={() => setShowActionPopup(!showActionPopup)}
      >
        <FilterIconContainer>
          <Sliders />
        </FilterIconContainer>
        <FilterText>
          Filter (
          {
            Object.entries(filters).filter(
              ([key, value]) =>
                (key === 'country' && value) ||
                (key === 'status' && value.length),
            ).length
          }
          )
        </FilterText>
      </FilterButton>
      {showActionPopup && (
        <FilterPopupWrapper data-testid="filter-popup-wrapper">
          <FilterPopupTitle>Filter table by</FilterPopupTitle>
          <FilterContainer>
            <FilterHeading>
              <FilterTitle>Status</FilterTitle>
              <FilterTitle
                clearLink
                onClick={() =>
                  updateFilter({
                    ...currentFilter,
                    status: [],
                  })
                }
              >
                Clear
              </FilterTitle>
            </FilterHeading>
            <FilterElement>
              {STATUS_FILTER.map((status, index) => (
                <StatusWrapper key={index}>
                  <EditableInput
                    name={status.key}
                    noLabel
                    type={FIELD_TYPE.CHECKBOX_BOOLEAN}
                    value={currentFilter.status.indexOf(status.key) > -1}
                    onChange={(
                      name:
                        | 'sow:awaiting-proposal'
                        | 'sow:awaiting-acceptance'
                        | 'sow:accepted',
                      value: any,
                    ) => {
                      const filterStatus = [...currentFilter.status];
                      if (value) {
                        filterStatus.push(name);
                      } else {
                        filterStatus.splice(filterStatus.indexOf(name), 1);
                      }
                      updateFilter({
                        ...currentFilter,
                        status: filterStatus,
                      });
                    }}
                  />
                  <FilterTitle>{status.label}</FilterTitle>
                </StatusWrapper>
              ))}
            </FilterElement>
            <FilterHeading>
              <FilterTitle>Country</FilterTitle>
              <FilterTitle
                clearLink
                onClick={() => {
                  updateFilter({ ...currentFilter, country: '' });
                }}
              >
                Clear
              </FilterTitle>
            </FilterHeading>
            <FilterElement>
              <EditableInput
                name="country"
                value={currentFilter.country}
                type={FIELD_TYPE.COUNTRY}
                onChange={(_: any, val: any) => {
                  updateFilter({ ...currentFilter, country: val });
                }}
                key="country"
              />
            </FilterElement>
            <ActionButtons>
              <Button
                style={{ marginRight: 16 }}
                variant="outlined"
                palette="secondary"
                onClick={() => {
                  setShowActionPopup(false);
                  onFilterUpdate(INITIAL_FILTER_STATE);
                }}
              >
                Clear
              </Button>
              <Button
                palette="primary"
                onClick={() => {
                  setShowActionPopup(false);
                  onFilterUpdate(currentFilter);
                }}
              >
                Apply filters
              </Button>
            </ActionButtons>
          </FilterContainer>
        </FilterPopupWrapper>
      )}
    </Wrapper>
  );
};
