import { LeaveType } from 'common/types';

import { Tag } from '@omnipresentgroup/design-system';

import { LEAVE_TYPES_CONTENT } from './constants';

type TimeOffTypeProps = {
  leaveType: LeaveType;
  center?: boolean;
};

export const TimeOffType = ({ leaveType }: TimeOffTypeProps) => {
  const { label, intent } = LEAVE_TYPES_CONTENT[leaveType];
  return (
    <Tag id="time-off-type" intent={intent}>
      {label}
    </Tag>
  );
};
