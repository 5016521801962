import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { getLoadingSelector } from 'app/store/app.selectors';
import { ReactComponent as ExpenseIcon } from 'assets/icon/PinkReceipt.svg';
import Loading from 'common/components/atoms/Loading/Loading';
import FormSidebar from 'common/components/molecules/FormSidebar/FormSidebar';
import { EditDetailsPage } from 'common/pages/EditDetailsPage/EditDetailsPage';
import { clearDocumentCreatedAction } from 'common/store/actions/documents.actions';
import { uploadDocumentAction } from 'common/store/actions/documentStorage.actions';
import {
  getDocumentFileInfoSelector,
  getDocumentUploadingSelector,
  getUploadedDocumentLocation,
} from 'common/store/selectors/documentStorage.selectors';
import { Expense } from 'common/types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { validateInputs } from 'utils/validators/validator';

import { expenseSchema } from '../../schema/expense.schema';
import {
  clearSelectedExpenseAction,
  getEmployeeExpenseAction,
  updateEmployeeExpenseAction,
} from '../../store/actions/expenses.actions';
import { getEmployeeSelectedExpenseSelector } from '../../store/selectors/expenses.selector';

type ExpenseUpdateReq = {
  expenseId: string;
  fieldsToUpdate: Record<string, any>;
  expenseToUpdate: Expense;
};

type EditExpensePageProps = {
  getExpense: (id: string) => void;
  clearExpense: () => void;
  expense: Expense;
  loading: boolean;
  updateExpense: (req: ExpenseUpdateReq) => void;
};

const EditExpensePage = ({
  getExpense,
  clearExpense,
  expense,
  loading,
  updateExpense,
}: EditExpensePageProps) => {
  const { expenseId } = useParams<{ expenseId: string }>();
  const dispatch = useDispatch();

  const fileInfo = useSelector(getDocumentFileInfoSelector);
  const documentUploading = useSelector((state) =>
    getDocumentUploadingSelector(state),
  );
  const uploadedDocumentLocation = useSelector((state) =>
    getUploadedDocumentLocation(state),
  );

  useEffect(() => {
    const onDocumentAdded = () => {
      if (fileInfo?.file && !documentUploading && !uploadedDocumentLocation) {
        dispatch(uploadDocumentAction(fileInfo));
      }

      if (uploadedDocumentLocation) {
        dispatch(clearDocumentCreatedAction());
      }
    };
    onDocumentAdded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileInfo?.file, uploadedDocumentLocation]);

  const employeeDetailsSections = [
    {
      name: 'expense-details',
      title: 'Expense details',
      details: expense?.expenseDetails,
    },
  ];

  useEffect(() => {
    getExpense(expenseId);
  }, [getExpense, expenseId, clearExpense]);

  const onUpdateMade = (updatedValues: Record<string, any>) =>
    updateExpense({
      expenseId,
      fieldsToUpdate: updatedValues,
      expenseToUpdate: expense,
    });

  return (
    <PageContent>
      {loading || !expense.expenseDetails ? (
        <Loading />
      ) : (
        <EditDetailsPage
          disabled={documentUploading}
          title="Edit expense"
          onSaveEdits={onUpdateMade}
          validator={(fields) => validateInputs(fields, expenseSchema)}
          sections={employeeDetailsSections}
          sideBar={
            <FormSidebar
              copy="Any updates you make will be shared with your manager"
              Icon={ExpenseIcon}
            />
          }
        />
      )}
    </PageContent>
  );
};
const mapStateToProps = (state: any) => ({
  expense: getEmployeeSelectedExpenseSelector(state),
  loading: getLoadingSelector(state),
});

const mapDispatchToProps = {
  getExpense: getEmployeeExpenseAction,
  clearExpense: clearSelectedExpenseAction,
  updateExpense: updateEmployeeExpenseAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditExpensePage);
