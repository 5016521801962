import { useHistory } from 'react-router-dom';

import { StyledFullWidthBlueDivider } from '../../atoms/Divider/Divider.styles';
import { StyledTabTitle } from './TabTitle.styles';

type Props = {
  tabHref: string;
  tabTitle: string;
  isDisabled?: boolean;
  currentTab?: boolean;
  onClick?:
    | (() => void)
    | ((
        event: React.MouseEvent<
          HTMLAnchorElement,
          React.MouseEvent<Element, MouseEvent>
        >,
      ) => void)
    | null;
  isLoading?: boolean;
};

export const TabTitle = ({
  tabHref,
  tabTitle,
  isDisabled = false,
  currentTab = false,
  onClick,
  isLoading = false,
}: Props) => {
  const shouldShowDivider = currentTab && !isDisabled;
  const history = useHistory();
  return (
    <StyledTabTitle
      data-key={tabHref}
      className="stack"
      disabled={isDisabled}
      $loading={isLoading}
      $currentTab={currentTab}
      // Do not allow disabled tabs to be focused via keyboard
      tabIndex={isDisabled ? -1 : undefined}
      to={`${history.location.pathname}${tabHref}`}
      onClick={onClick as React.MouseEventHandler}
      data-testid={
        tabTitle ? tabTitle.toLowerCase().replace(/ /g, '-') : 'tab-title'
      }
      role="link"
    >
      {tabTitle}
      {shouldShowDivider && (
        <StyledFullWidthBlueDivider
          data-testid="current-tab-title-indicator"
          className="currentTabDivider"
        />
      )}
    </StyledTabTitle>
  );
};

export default TabTitle;
