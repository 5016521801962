import { useCallback, useRef } from 'react';

import { ErrorBanner, Loading } from 'common/components';
import { getTenantIdSelector } from 'common/store/selectors/companies.selectors';
import { SimplifiedEmployee } from 'common/types';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { useEmployeesForAdminUsers } from 'utils/hooks/employees/useEmployeesForAdminUsers';

import {
  Box,
  Button,
  Inline,
  Typography,
} from '@omnipresentgroup/design-system';

import { AssociatedEmployee, EmployeesSearchWithList } from '../';
import * as S from './DirectReportsModal.style';

const getEmployeesWithAssociation = (
  employees: SimplifiedEmployee[] | undefined,
  associatedUsers: string[],
): AssociatedEmployee[] =>
  (employees &&
    employees.map((employee) => ({
      userId: employee.userId,
      name: employee.name,
      jobTitle: employee.jobTitle,
      country: employee.country,
      isAssociated: associatedUsers.includes(employee.userId),
    }))) ||
  [];

export const DirectReportsModal = ({
  associatedUsers,
  onCloseClick,
  onSaveClick,
}: {
  associatedUsers: string[];
  onCloseClick: () => void;
  onSaveClick: (selectedEmployees: string[]) => void;
}) => {
  const companyId = useSelector(getTenantIdSelector);
  const companyEmployeesQuery = useEmployeesForAdminUsers(companyId);
  const { isLoading, isError, isSuccess, data } = companyEmployeesQuery;

  const employeesWithAssociation = getEmployeesWithAssociation(
    data,
    associatedUsers,
  );

  const checkedItems = employeesWithAssociation
    .filter((employee) => employee.isAssociated)
    .map((employee) => employee.userId);
  const selectedEmployees = useRef<string[]>(checkedItems);

  const onUpdateSelection = useCallback((employees: string[]) => {
    selectedEmployees.current = employees;
  }, []);

  const handleOnSaveClick = useCallback(() => {
    onSaveClick(selectedEmployees.current);
  }, [onSaveClick]);

  return (
    (isError && <ErrorBanner />) || (
      <S.StyledModal>
        {isLoading && <Loading />}
        {isSuccess && data && (
          <>
            <S.StyledCloseContainer onClick={onCloseClick} role="button">
              <X />
            </S.StyledCloseContainer>
            <Box mb="32" pl="32" pr="32">
              <Typography as="h4" hideParagraphSpacing size="24">
                Edit direct reports
              </Typography>
              <S.StyledSubHeading>
                Add and remove employees from the list of direct reports.
              </S.StyledSubHeading>
            </Box>
            <S.StyledEmployeeListContainer>
              <EmployeesSearchWithList
                employees={employeesWithAssociation}
                hasCheckboxSelection={true}
                onUpdateSelection={onUpdateSelection}
              />
            </S.StyledEmployeeListContainer>
            <Inline justify="space-between" mt="16" p="32">
              <Button variant="secondary" onClick={onCloseClick}>
                Cancel
              </Button>
              <Button onClick={handleOnSaveClick}>Save</Button>
            </Inline>
          </>
        )}
      </S.StyledModal>
    )
  );
};
