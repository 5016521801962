import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import { getTransformedUser } from 'app/store/app.selectors';
import { Loading } from 'common/components';
import { ConfirmationModal } from 'common/components/molecules/ConfirmationModal';
import { FourOhFour } from 'common/pages/404/FourOhFour';
import { TemplateMapping } from 'common/types';
import type { CountryCode } from 'countries-and-timezones';
import { countries } from 'countries-list';
import { BACK_OFFICE_PAGE, COMPANIES_LIST_PAGE } from 'paths';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useTemplateMappingsQuery } from 'utils/queries';
import { isAdmin } from 'utils/user';

import {
  Button,
  Inline,
  notifyError,
  notifySuccess,
  Stack,
  Table,
  Tag,
  Typography,
} from '@omnipresentgroup/design-system';

import { RESOURCE_PATH } from '../../api';
import { useUpdateTemplateMappingMutation } from '../../queries/useUpdateTemplateMappingMutation';

export enum TemplateMappingStatus {
  disabled = 'disabled',
  enabled = 'enabled',
}
export enum TemplateMappingStatusActions {
  disable = 'disable',
  enable = 'enable',
}

export const TABLE_TITLE = 'Template Mappings Table';

export const ContractAutomationMappingList = () => {
  const queryClient = useQueryClient();
  const [selectedMapping, setSelectedMapping] = useState<
    TemplateMapping | undefined
  >();

  const user = useSelector(getTransformedUser);
  const { data: response, isSuccess, isLoading } = useTemplateMappingsQuery({});
  const items: TemplateMapping[] = response?.templateMappings || [];
  const columnHelper = createColumnHelper<any>();
  const { mutate: updateTemplateMapping, isLoading: isUpdating } =
    useUpdateTemplateMappingMutation();

  const columns: ColumnDef<any, any>[] = [
    columnHelper.accessor('countryCode', {
      id: `countryName`,
      cell: (info) => {
        const countryCodeUpperCase = String(
          info.getValue(),
        ).toUpperCase() as CountryCode;

        return countries?.[countryCodeUpperCase]?.name;
      },
      header: () => 'Country Name',
    }),
    columnHelper.accessor('countryCode', {
      cell: (info) => info.getValue(),
      header: () => 'Country Code',
    }),
    columnHelper.accessor('companyName', {
      cell: (info) => {
        return info.getValue() ? (
          <Link
            id={info.row.original.companyId}
            to={`${COMPANIES_LIST_PAGE}/${info.row.original.companyId}`}
          >
            {info.row.original.companyName}
          </Link>
        ) : (
          '-'
        );
      },
      header: () => 'Company Name',
    }),
    columnHelper.accessor('companyId', {
      cell: (info) => {
        const companyId = info?.getValue();
        return companyId || '-';
      },
      header: () => 'Company Id',
    }),

    columnHelper.accessor('avvokaOriginalTemplateId', {
      cell: (info) => info.getValue(),
      header: () => 'Avvoka Original Template Id',
      size: 50,
    }),
    columnHelper.accessor('id', {
      cell: (info) => info.getValue(),
      header: () => 'DynamoDB Template Id',
    }),
    columnHelper.accessor('disabled', {
      cell: (info) => {
        const status = info.getValue()
          ? TemplateMappingStatus.disabled
          : TemplateMappingStatus.enabled;
        return (
          <Tag
            id={info.column.id}
            intent={
              status === TemplateMappingStatus.enabled ? 'success' : undefined
            }
          >{`${status}`}</Tag>
        );
      },
      header: () => 'Status',
      size: 65,
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => info.renderValue(),
      header: () => 'Created At',
    }),
    columnHelper.accessor('updatedAt', {
      cell: (info) => info.renderValue() || '-',
      header: () => 'Updated At',
    }),
    columnHelper.accessor('action', {
      cell: (info) => {
        const actionName = info.row.original.disabled
          ? TemplateMappingStatusActions.enable
          : TemplateMappingStatusActions.disable;
        return (
          <Button
            color={
              actionName === TemplateMappingStatusActions.disable
                ? 'negative'
                : undefined
            }
            size="small"
            id={info.column.id}
            onClick={() => {
              setSelectedMapping(info.row.original as TemplateMapping);
            }}
          >
            {actionName}
          </Button>
        );
      },

      header: () => 'Action',
      size: 65,
    }),
  ];

  const table = useReactTable({
    data: isLoading ? [] : items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  if (!isAdmin(user)) {
    return <FourOhFour />;
  }

  const {
    disabled,
    avvokaOriginalTemplateId,
    countryCode = '',
    companyName = '',
  } = selectedMapping || {};
  const countryName =
    countries?.[countryCode.toUpperCase() as CountryCode]?.name || '';
  const message = `You are about to ${
    disabled
      ? TemplateMappingStatusActions.enable
      : TemplateMappingStatusActions.disable
  } the template ${avvokaOriginalTemplateId} for ${countryName}${
    companyName ? ` on ${companyName}` : ''
  } `;
  const modalLabel = selectedMapping?.disabled
    ? 'Enable template mapping'
    : 'Disable template mapping';
  return (
    <PageContent data-testid="contract-automation-mapping" className="bigStack">
      {selectedMapping && (
        <ConfirmationModal
          cancelHandler={() => {
            setSelectedMapping(undefined);
          }}
          cancelLabel="Cancel"
          confirmHandler={() =>
            updateTemplateMapping(
              {
                templateId: selectedMapping.id,
                disabled: Boolean(!selectedMapping.disabled),
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries(RESOURCE_PATH);
                  setSelectedMapping(undefined);
                  notifySuccess({
                    title: 'Success',
                    description: `Template ${avvokaOriginalTemplateId} was updated successfully`,
                  });
                },
                onError: (error) => {
                  notifyError({
                    title: 'Error',
                    description: `Something went wrong during the mapping update: ${error.response?.data.message}`,
                  });
                },
              },
            )
          }
          confirmLabel={modalLabel}
          loading={isUpdating}
          message={message}
          title={modalLabel}
          id={`${selectedMapping.id}-modal`}
        />
      )}

      <Inline justify="space-between">
        <Typography as="h3" hideParagraphSpacing weight="medium" size="32">
          {TABLE_TITLE}
        </Typography>
        <Button
          disabled={isLoading}
          onClick={() =>
            appHistory.push(`${BACK_OFFICE_PAGE}/${RESOURCE_PATH}/create`)
          }
        >
          Create Template Mapping
        </Button>
      </Inline>
      {isLoading && <Loading />}
      {isSuccess && (
        <Stack maxH="80vh">
          <Table
            showHeader
            table={table}
            usePagination
            paginationProps={{
              pageSize: 100,
            }}
            className="stickyHeaderTable"
          />
        </Stack>
      )}
    </PageContent>
  );
};
