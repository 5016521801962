import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledModalRoot = styled.div`
  z-index: 1000;
  position: fixed;
  inset: 0;
  background: ${({ theme: { colors } }) => colors.overlay};
`;

export const StyledModalContainer = styled.div`
  height: 100%;
  outline: 0;
  overflow: hidden auto;
  text-align: center;

  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
  }
`;

export const StyledModalContents = styled.div<{
  fullScreen?: boolean;
  fixedWidth?: string;
  fixedHeight?: string;
  inLineCss?: Record<string, string>;
}>`
  display: inline-block;
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
  border-radius: ${({ theme: { radii } }) => radii.s};
  margin: 32px;
  text-align: left;
  vertical-align: middle;
  position: relative;
  ${({ fullScreen, fixedWidth, fixedHeight, theme: { space } }) => {
    if (fullScreen) {
      return `
        height: calc(100vh - ${space[8]}*6);
        width: calc(100vw - ${space[8]}*5);
      `;
    }

    const width = fixedWidth
      ? `${fixedWidth}px`
      : `calc(100vw - ${space[8]}*2)`;
    const height = fixedHeight ? `${fixedHeight}px` : 'auto';
    return `
        height: ${height};
        width: ${width};
      `;
  }}
  ${({ inLineCss }) => {
    return inLineCss;
  }}
`;

export const StyledModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseIconButton = styled.button`
  background-color: transparent;
  right: ${tokens.spacing[16]};
  top: ${tokens.spacing[16]};
  color: ${tokens.color.global.gray[500]};
  border: unset;
  width: fit-content;
  position: absolute;
  cursor: pointer;
`;
