import { client } from 'api';
import { transformExpenseFromAPI } from 'omniplatform/manager/store/transformers/employeesExpense.transformer';

import type { IsoDateTime } from '@omnipresentgroup/design-system';

import {
  AddExpensesRequest,
  BulkUpdateExpensesRequest,
  ExpenseFromAPI,
  ExportExpensesRequest,
} from '../types/expenses';

export const getEmployeeExpense = (expenseId: string) =>
  client
    .get<ExpenseFromAPI>(`expenses/${expenseId}`)
    .then((response) => transformExpenseFromAPI(response.data));

/* NB: duplicating for now, the getEmployeeExpense shouldn't transform
the API request result, it's not its concern.
*/
export const getExpense = (id: string) =>
  client.get<ExpenseFromAPI>(`expenses/${id}`);

export const addExpense = (expenseToCreate: AddExpensesRequest) =>
  client.post(`expenses`, expenseToCreate);

export const downloadExpenses = ({
  expenseIds,
  format,
  includeAttachments,
  approverId,
}: ExportExpensesRequest) =>
  client.post(`expenses/export`, {
    expenseIds,
    exportFormat: format,
    includeAttachments,
    approverId,
  });

export const bulkUpdateExpenses = (
  expensesToUpdate: BulkUpdateExpensesRequest,
) =>
  client.patch('expenses', expensesToUpdate).then((response) => response.data);

export const downloadExpenseZipFile = (
  approverId: string,
  timestamp: IsoDateTime,
) =>
  client.get<{ url: string }>(`/expenses/download`, {
    params: {
      approverId,
      timestamp,
    },
  });
