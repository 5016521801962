import flatten from 'lodash/flatten';
import maxBy from 'lodash/maxBy';

import { OnboardingStage } from '../OnboardingProgressTracker/types';

export const isLastStep = (
  stepId: string,
  stages: OnboardingStage[],
): boolean => {
  const hasStages = !!stages;
  if (hasStages) {
    const steps = flatten(stages.map((stage) => stage.steps));
    const lastStep = maxBy(steps, 'id');
    return lastStep?.id === stepId;
  }
  return false;
};

export const getPercentComplete = (
  stages: OnboardingStage[],
  completedSteps: string[],
) => {
  const totalSteps = flatten(stages.map((stage) => stage.steps)).length;
  const totalCompletedSteps = completedSteps.length;
  const percentage = (totalCompletedSteps / totalSteps) * 100;
  return parseFloat(percentage.toFixed(0));
};
