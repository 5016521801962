import { useState } from 'react';

import { ReactComponent as CrossIcon } from 'assets/icon/Cross.svg';
import { Field } from 'common/types';

import { Button } from '@omnipresentgroup/design-system';

import { StyledBoldCopy } from '../../atoms/Text/Copy.styles';
import {
  StyledChangeInput,
  StyledHeader,
  StyledInputWithConfirm,
  StyledValidationText,
} from './InputWithConfirm.styles';

type Props = {
  field: Field;
  onConfirm: (field: Field) => void;
  onCancel: () => void;
  ctaText?: string;
  disabled?: boolean;
};

const InputWithConfirm = ({
  field,
  onConfirm,
  onCancel,
  ctaText = 'Submit change request',
  disabled = false,
}: Props) => {
  const { name, label, value, editable, labelBold } = field;
  const [inputValue, setInputValue] = useState<Field['value']>(value);
  const [showError, setShowError] = useState(false);

  const onSubmit = () => {
    if (!inputValue) {
      return setShowError(true);
    }
    return onConfirm({
      ...field,
      value: inputValue,
    });
  };

  return (
    <StyledInputWithConfirm>
      <StyledHeader>
        <StyledBoldCopy>{label}</StyledBoldCopy>
        <CrossIcon data-testid="cancel-button" onClick={onCancel} />
      </StyledHeader>
      <StyledChangeInput
        name={name}
        labelBold={labelBold}
        type="text"
        value={inputValue || ''}
        onChange={(_: unknown, newValue: Field['value']) =>
          setInputValue(newValue)
        }
        editable={editable}
        onFocus={() => {
          setShowError(false);
        }}
        disabled={disabled}
      />
      {showError && (
        <StyledValidationText>Please enter a value</StyledValidationText>
      )}
      <Button variant="secondary" onClick={onSubmit} disabled={disabled}>
        {ctaText}
      </Button>
    </StyledInputWithConfirm>
  );
};

export default InputWithConfirm;
