/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import { sortByMultipleDates } from '@omnipresentgroup/design-system';

/**
 * Orders a list of items with createdAt field and enables to update order method to desc or asc
 *
 * @param {array} dataList A list of items with createdAt date field
 * @param {string} [initialSortMethod=desc] Defines the initial sort method: 'desc' or 'asc'
 */
export const useSortDataByCreatedAt = (
  dataList: any[],
  initialSortMethod: 'asc' | 'desc' = 'desc',
) => {
  const [sortMethod, setSortMethod] = useState<'asc' | 'desc'>(
    initialSortMethod,
  );

  const orderedData = sortByMultipleDates(
    dataList ?? [],
    ['createdAt'],
    sortMethod,
  );

  return {
    orderedData,
    setSortMethod,
  };
};
