import { AdminBenefitsTab } from 'omniplatform/admin/pages/AdminBenefits/AdminBenefitsTab/AdminBenefitsTab';

import { StyledTab } from '../CompanyDetailPage.styles';

export const BenefitsTab = () => {
  return (
    <StyledTab data-testid="benefits-tab" className="smallStack">
      <AdminBenefitsTab />
    </StyledTab>
  );
};
