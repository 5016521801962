import { joiResolver } from '@hookform/resolvers/joi';
import { employmentType } from 'common/types/employments';
import JoiImport from 'joi';

import DateExtension from '@omnipresentgroup/joi-date';

const Joi = JoiImport.extend(DateExtension) as JoiImport.Root;

export const addEditEmploymentResolver = () =>
  joiResolver(
    Joi.object({
      lspId: Joi.object({
        label: Joi.string().required(),
        value: Joi.string().required(),
      }).required(),
      type: Joi.object({
        label: Joi.string().required(),
        value: Joi.string()
          .valid(...employmentType)
          .required(),
      })
        .required()
        .messages({
          'any.required': 'Please select an employment type',
        }),
      startDate: Joi.date().format('YYYY-MM-DD').raw().required().messages({
        'date.empty': 'Please enter a start date',
        'date.format': 'Please enter a valid start date',
        'any.required': 'Please enter a start date',
      }),
      terminationDate: Joi.date()
        .allow('', null)
        .format('YYYY-MM-DD')
        .raw()
        .when('startDate', {
          not: '',
          then: Joi.date().min(Joi.ref('startDate')).raw().messages({
            'date.min':
              'Please enter a termination date greater or equal to the start date',
          }),
        }),
      firstPayrollDate: Joi.date().allow('', null).format('YYYY-MM-DD').raw(),
      lastPayrollDate: Joi.date()
        .allow('', null)
        .format('YYYY-MM-DD')
        .raw()
        .when('firstPayrollDate', {
          not: '',
          then: Joi.date().min(Joi.ref('firstPayrollDate')).raw().messages({
            'date.min':
              'Please enter a last payroll date greater or equal to the first payroll date',
          }),
        }),
      status: Joi.object({
        label: Joi.string(),
        value: Joi.string(),
      }),
    }),
  );
