import { StyleEnum } from 'omniplatform/admin/pages/PayReportPreviewPage/prepareDataForTreeTable';
import styled from 'styled-components';

import { Table } from '@omnipresentgroup/design-system';

export const TableWrapper = styled(Table)`
  /**
    when rendering headers with a custom element they are wrapped in a span so align properties don't work
  **/
  th:not(:first-child) > div > span {
    width: 100%;
  }

  & > * > tr:has(td) {
    &:has(.${/* sc-prop */ StyleEnum.APPLICATION_TYPE}) {
      background-color: #eaeafc;
    }

    &:has(.${/* sc-prop */ StyleEnum.OMNIPLATFORM}) {
      & > * {
        font-weight: 800;
      }
    }

    &:has(.${/* sc-prop */ StyleEnum.PAYROLL_GLOBAL}) {
      & > * {
        font-weight: 500;
      }

      & > td:first-child {
        padding-left: 50px;
      }
    }

    &:has(.${/* sc-prop */ StyleEnum.PAYROLL_GLOBAL_SUB}) {
      & > td:first-child {
        padding-left: 100px;
      }
    }

    &:has(.${/* sc-prop */ StyleEnum.TOTAL_SUB}) {
      background-color: #b4b5b540;

      & > * {
        font-weight: 500;
      }
    }

    &:has(.${/* sc-prop */ StyleEnum.TOTAL}) {
      background-color: #060707;
      color: white;

      & * {
        font-weight: 800;
        color: white;
      }
    }

    /**
    when rendering headers with a custom element they are wrapped in a span so align properties don't work
  **/
    th:not(:first-child) > div {
      justify-content: flex-end;
    }
  }
`;
