import { useParams } from 'react-router-dom';

import EmployeeDetailsPage from 'common/pages/EmployeeDetailsPage/EmployeeDetailsPage';
import { EMPLOYEES_PATH } from 'paths';

const CompanyEmployeeDetailPage = () => {
  const { employeeId } = useParams();
  return (
    <EmployeeDetailsPage
      addDocumentPath={`${EMPLOYEES_PATH}/${employeeId}/add-document`}
    />
  );
};

export default CompanyEmployeeDetailPage;
