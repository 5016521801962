import { StyledInput } from 'common/components/atoms/Input';
import { StyledSmallCopy } from 'common/components/atoms/Text/Copy.styles';
import DropdownInput from 'common/components/molecules/DropdownInput/DropdownInput';
import styled from 'styled-components';

import { omniConfig } from '@omnipresentgroup/design-system';

export const StyledHolidayAllowanceInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledHolidayTypeDropdown = styled(DropdownInput)`
  width: 58%;
`;

export const StyledHolidayDaysInput = styled(StyledInput)`
  width: 8%;

  :disabled {
    background: ${({ theme: { colors } }) =>
      colors['field-background-default']};
    border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-strong']}`};
  }
`;

export const StyledHolidayAllowanceCopy = styled(StyledSmallCopy)`
  align-self: center;
  margin-right: ${({ theme: { space } }) => space[8]};
  ${(props) =>
    props.disabled
      ? `color: ${omniConfig.theme.colors['text-disabled']};`
      : null};
`;
