import { useEffect, useRef, useState } from 'react';

import {
  getDocumentUploadingSelector,
  getUploadedDocumentLocation,
} from 'app/store/selectors/documentStorage.selectors';
import { ALLOWED_FILE_TYPES, MEGABYTE_BYTES } from 'appConstants';
import { ReactComponent as UploadedDocument } from 'assets/icon/PinkDocument.svg';
import { ReactComponent as UploadIcon } from 'assets/icon/Upload.svg';
import { StyledErrorMessage } from 'common/components/atoms/ErrorMessage';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as fileUtils from 'utils/files';

import { Typography } from '@omnipresentgroup/design-system';

import { setFileInfoAction } from '../../../store/actions/documentStorage.actions';
import Button from '../../atoms/Button/Button';
import Loading from '../../atoms/Loading/Loading';
import { MAXIMUM_UPLOAD_SIZE_BYTES } from './constants';
import {
  StyledLoadingContainer,
  StyledUploadDocument,
  StyledUploadDocumentIcons,
  StyledUploadedDocumentContainer,
} from './UploadDocument.styles';

const UploadDocument = ({
  onDocumentUploaded,
  name,
  type,
  uploading,
  uploadedDocumentLocation,
  editable,
  onFocus,
  buttonLabel,
  buttonStyleVariant,
  buttonPalette,
  setFileInfo,
  value,
  showUploadButtonOnly,
}) => {
  const [fileToUpload, setFileToUpload] = useState(null);
  const [onChangeCalled, setOnChangeCalled] = useState(false);
  const [hasValidFileType, setHasValidFileType] = useState(() => !fileToUpload);
  const [hasValidFileSize, setHasValidFileSize] = useState(() => !fileToUpload);

  const inputRef = useRef(null);
  const onUploadClicked = () => {
    onFocus(name);
    return inputRef.current.click();
  };

  const onDocumentCleared = () => {
    setFileToUpload(null);
    setFileInfo({ documentFile: null, documentType: null });
    onDocumentUploaded(name, null);
    setOnChangeCalled(false);
  };

  const handleInputFileChange = ({ target: { files } }) => {
    const file = files[0];
    if (!file) {
      return;
    }
    const isValidFile = fileUtils.isValidFileType(file, ALLOWED_FILE_TYPES);
    setHasValidFileType(isValidFile);

    const isValidFileSize = fileUtils.isValidFileSize(
      file,
      MAXIMUM_UPLOAD_SIZE_BYTES,
    );
    setHasValidFileSize(isValidFileSize);

    if (!isValidFile || !isValidFileSize) {
      return;
    }

    setFileToUpload(file);
    setFileInfo({ documentFile: file, documentType: type });
  };

  useEffect(() => {
    if (uploadedDocumentLocation && uploadedDocumentLocation !== value) {
      onDocumentUploaded(name, uploadedDocumentLocation);
    } else if (fileToUpload && !onChangeCalled) {
      onDocumentUploaded(name, fileToUpload.name);
      setOnChangeCalled(true);
    }
  }, [
    fileToUpload,
    name,
    value,
    onChangeCalled,
    onDocumentUploaded,
    uploadedDocumentLocation,
  ]);

  const shouldUploadDocument =
    (showUploadButtonOnly || !fileToUpload) && editable;
  const shouldDisplayFileName = fileToUpload || (value && !editable);

  return (
    <StyledUploadDocument>
      {uploading ? (
        <StyledLoadingContainer>
          <Loading />
        </StyledLoadingContainer>
      ) : (
        <>
          <StyledUploadDocumentIcons>
            {shouldUploadDocument && (
              <Button
                testId="upload-icon-wrapper"
                onClick={onUploadClicked}
                icon={<UploadIcon data-testid="upload-icon" />}
                variant={buttonStyleVariant}
                palette={buttonPalette}
              >
                {buttonLabel}
              </Button>
            )}
            {!showUploadButtonOnly && shouldDisplayFileName && (
              <StyledUploadedDocumentContainer>
                <UploadedDocument data-testid="uploaded-document-icon" />
                <Typography
                  as="p"
                  hideParagraphSpacing
                  data-testid="uploaded-document-name"
                  color="helper"
                  size="12"
                >
                  {fileToUpload?.name}
                </Typography>
              </StyledUploadedDocumentContainer>
            )}
          </StyledUploadDocumentIcons>
          {shouldUploadDocument && (
            <input
              type="file"
              name={name}
              ref={inputRef}
              accept={ALLOWED_FILE_TYPES}
              data-testid="upload-input"
              onChange={handleInputFileChange}
            />
          )}
          {!showUploadButtonOnly && fileToUpload && (
            <Button
              onClick={onDocumentCleared}
              noStyle
              testId="clear-document-button"
            >
              Clear
            </Button>
          )}
          {!hasValidFileType && (
            <StyledErrorMessage>
              This file type is not allowed. Please upload an image, pdf or word
              document.
            </StyledErrorMessage>
          )}
          {!hasValidFileSize && (
            <StyledErrorMessage>
              The maximum file size is{' '}
              {MAXIMUM_UPLOAD_SIZE_BYTES / MEGABYTE_BYTES} MB. Please upload a
              smaller file.
            </StyledErrorMessage>
          )}
        </>
      )}
    </StyledUploadDocument>
  );
};

UploadDocument.propTypes = {
  onDocumentUploaded: PropTypes.func.isRequired,
  uploadedDocumentLocation: PropTypes.string,
  uploading: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  editable: PropTypes.bool,
  clearUploadedDocument: PropTypes.func,
  onFocus: PropTypes.func,
  buttonLabel: PropTypes.string,
  buttonStyleVariant: PropTypes.string,
  buttonPalette: PropTypes.string,
  showUploadButtonOnly: PropTypes.bool,
  setFileInfo: PropTypes.func.isRequired,
};

UploadDocument.defaultProps = {
  name: '',
  type: 'document',
  editable: true,
  buttonLabel: 'Upload',
  buttonStyleVariant: 'outlined',
  buttonPalette: 'secondary',
  showUploadButtonOnly: false,
  onFocus: () => {},
};

const mapDispatchToProps = {
  setFileInfo: setFileInfoAction,
};

const mapStateToProps = (state) => ({
  uploading: getDocumentUploadingSelector(state),
  uploadedDocumentLocation: getUploadedDocumentLocation(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocument);
