export const asyncActions = (action, type) => [
  `${action}_${type}_START`,
  `${action}_${type}_END`,
  `${action}_${type}_ERROR`,
];

export const createActionObject = (type, payload) => ({ type, payload });

export const makeActionKeys = (keysList) =>
  keysList.reduce(
    (result, key) => ({
      ...result,
      [key]: key,
    }),
    {},
  );
