/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { PageContent } from 'app/App.styles';
import { queryClient } from 'app/queryClient';
import {
  ExpenseStatusEnum,
  getStatusDetails,
} from 'common/components/molecules/ExpenseStatus/ExpenseStatus';
import MobileCardListHeader from 'common/components/molecules/MobileCardListHeader/MobileCardListHeader';
import { FIVE } from 'common/constants';
import { exportCSVFileWithFs } from 'common/helpers/exportData';
import { getTenantIdSelector } from 'common/store/selectors/companies.selectors';
import { ExpenseFromAPI, FileFormat } from 'common/types';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { expenseCategoriesOptions } from 'omniplatform/employee/pages/AddEmployeeExpensePage/addEmployeeExpenseFormConfig';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { getNextPayrollCutOffDate } from 'utils/getNextPayrollCutOffDate';
import {
  useBulkApproveExpensesMutation,
  useCompanyExpensesQuery,
  useDownloadExpensesToCsvQuery,
  useDownloadExpensesWithAttachmentsQuery,
} from 'utils/queries/expenses';
import { expensesKeys } from 'utils/queries/expenses/keys';

import {
  Breadcrumbs,
  Button,
  Card,
  DateInput,
  Dropdown,
  DropdownButton,
  formatISODate,
  Inline,
  Notification,
  notifyError,
  notifySuccess,
  OptionType,
  SingleValue,
  Stack,
  Table,
  Typography,
  useViewport,
} from '@omnipresentgroup/design-system';

import { ExpenseMobileCard } from './components/ExpenseMobileCard';
import {
  Columns,
  filterByEstimatedPayrollDate,
  filterByStatus,
  LoadingColumns,
  withinDateRange,
} from './managerExpensesListColumns';

export const PAGE_TITLE = 'Employee expenses';
const sorter = (a: OptionType, b: OptionType) => a.label.localeCompare(b.label);

export const getDisplayStatusLabel = (value: ExpenseStatusEnum) => {
  const statusDetails = getStatusDetails(value);
  return statusDetails.text;
};

type FiltersState = {
  employeeName: string;
  dateFrom?: Date;
  dateTo?: Date;
  status?: string;
  category?: string;
  estimatedPayrollMonth?: string | null;
};

const CATEGORIES_OPTIONS = expenseCategoriesOptions.sort((a, b) =>
  a.label.localeCompare(b.label),
);

const EMPTY_FILTERS_STATE: FiltersState = {
  employeeName: '',
  dateFrom: undefined,
  dateTo: undefined,
  status: '',
  category: '',
  estimatedPayrollMonth: null,
};

export const ExpensesListPage = () => {
  const companyId = useSelector(getTenantIdSelector) as string;
  const { data: { data: expensesToDisplay = [] } = {}, isSuccess } =
    useCompanyExpensesQuery(companyId);
  const history = useHistory();
  const location = useLocation();
  const viewport = useViewport();

  useEffect(() => {
    const params = queryString.parse(location.search);
    const initialFilters: FiltersState = {
      employeeName: (params.employeeName as string) || '',
      dateFrom: params.dateFrom
        ? new Date(params.dateFrom as string)
        : undefined,
      dateTo: params.dateTo ? new Date(params.dateTo as string) : undefined,
      status: (params.status as string) || '',
      category: (params.category as string) || '',
      estimatedPayrollMonth: (params.estimatedPayrollMonth as string) || null,
    };

    setFilters(initialFilters);
  }, [location.search]);

  // Function to update the URL based on filter changes
  const updateUrl = (newFilters: FiltersState) => {
    // Convert Date objects to string for URL
    const filtersForUrl = {
      ...newFilters,
      dateFrom: newFilters.dateFrom
        ? newFilters.dateFrom.toISOString().split('T')[0]
        : '',
      dateTo: newFilters.dateTo
        ? newFilters.dateTo.toISOString().split('T')[0]
        : '',
    };

    // Remove falsy or nullish keys
    const filteredFilters = Object.entries(filtersForUrl).reduce(
      (acc, [key, value]) => {
        if (value) {
          // @ts-ignore
          acc[key] = value;
        }
        return acc;
      },
      {},
    );

    const newSearch = queryString.stringify(filteredFilters);
    history.push({ pathname: location.pathname, search: newSearch });
  };

  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<FiltersState>(EMPTY_FILTERS_STATE);

  // Logic to handle column filter changes
  const handleColumnFiltersChange = useCallback(
    (columnFilters) => {
      const newFilters: FiltersState = { ...EMPTY_FILTERS_STATE };

      // Loop through the columnFilters array and update the newFilters state accordingly
      columnFilters.forEach(
        ({
          id,
          value,
        }: {
          id:
            | 'employeeName'
            | 'status'
            | 'category'
            | 'dateSpent'
            | 'estimatedPayrollMonth';
          value: any;
        }) => {
          if (id === 'employeeName') {
            newFilters.employeeName = value || '';
          }
          if (id === 'dateSpent') {
            const [dateFrom, dateTo] = value;
            newFilters.dateFrom = dateFrom || '';
            newFilters.dateTo = dateTo || '';
          }
          if (id === 'status') {
            newFilters.status = value || '';
          }
          if (id === 'category') {
            newFilters.category = value || '';
          }
          if (id === 'estimatedPayrollMonth') {
            newFilters.estimatedPayrollMonth = value || null;
          }
        },
      );

      // Update the filters state with the new values
      setFilters(newFilters);
      updateUrl(newFilters);
    },
    [updateUrl],
  );

  const table = useReactTable({
    // Core Config
    data: expensesToDisplay,
    columns: Columns,
    getCoreRowModel: getCoreRowModel(),
    // Specific filter functions
    filterFns: {
      filterByEstimatedPayrollDate, // needs to return elements with a null value
      filterByStatus, // to include CHANGE_MADE when PENDING is selected
      withinDateRange, // needs to range dates
    },
    state: {
      sorting,
      rowSelection,
      columnFilters: [
        {
          id: 'employeeName',
          value: filters.employeeName,
        },
        {
          id: 'dateSpent',
          value: [filters.dateFrom, filters.dateTo],
        },
        {
          id: 'status',
          value: filters.status,
        },
        {
          id: 'category',
          value: filters.category,
        },
        {
          id: 'estimatedPayrollMonth',
          value: filters.estimatedPayrollMonth,
        },
      ],
    },
    // Sorting Config
    sortDescFirst: true,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    // Filters config
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: handleColumnFiltersChange,
  });

  const loadingTable = useReactTable({
    // Core Config
    data: Array.from({ length: FIVE }, (_value, index) => index),
    columns: LoadingColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const emptySelection = isEmpty(rowSelection);
  const selection = Object.keys(rowSelection).map(
    // @ts-ignore
    (item) => expensesToDisplay[item],
  );
  const disableApprove =
    selection.some(
      (expense: ExpenseFromAPI) =>
        expense.status === ExpenseStatusEnum.APPROVED,
    ) || !selection.length;

  const fieldsForUpdate = selection.map((expense: ExpenseFromAPI) => ({
    expenseId: expense.id,
    approved: true,
  }));

  const expensesToUpdatePayload = { expenses: fieldsForUpdate };

  const { mutate: handleBulkApproveExpenses, isLoading: isLoadingBulkApprove } =
    useBulkApproveExpensesMutation();

  const { refetch: downloadExpensesAsCSV, isLoading: isDownloadingExpenses } =
    useDownloadExpensesToCsvQuery({
      expenseIds: selection.map((expense: ExpenseFromAPI) => expense.id),
      approverId: companyId,
      onSuccess: (data) => {
        exportCSVFileWithFs(data, 'expense.csv');
        setRowSelection({});
      },
    });

  const {
    refetch: downloadExpensesWithAttachments,
    isLoading: isDownloadExpensesWithAttachments,
  } = useDownloadExpensesWithAttachmentsQuery({
    expenseIds: selection.map((expense: ExpenseFromAPI) => expense.id),
    approverId: companyId,
    onSuccess: () => {
      notifySuccess({
        title: 'Preparing your file for download',
        description: `We are preparing your file for download. We will be sending a link
        to your email to download the zipped file. Thank you for your patience.`,
      });
      setRowSelection({});
    },
  });

  const isDownloading =
    isDownloadExpensesWithAttachments || isDownloadingExpenses;
  const tableActions = () => (
    <>
      <DropdownButton
        variant="secondary"
        size="medium"
        icon={isDownloading ? undefined : 'Download'}
        loading={isDownloading}
        disabled={emptySelection}
        options={[
          {
            name: `Download ${FileFormat.CSV}`,
            onClick: () => downloadExpensesAsCSV(),
            icon: 'Download',
          },
          {
            name: 'Download attachments',
            onClick: () => downloadExpensesWithAttachments(),
            icon: 'FileText',
          },
        ]}
      >
        {`Download ${FileFormat.CSV}`}
      </DropdownButton>
      <Button
        icon={disableApprove || isLoadingBulkApprove ? undefined : 'Check'}
        disabled={disableApprove}
        loading={isLoadingBulkApprove}
        onClick={() =>
          handleBulkApproveExpenses(expensesToUpdatePayload, {
            onError: (error) => {
              notifyError({
                title: 'Error',
                description: `Something went wrong with the approval of the expenses: ${error.response?.data.message}`,
              });
            },
            onSuccess: (data) => {
              const failed = data.filter((entry) => !entry.success).length;
              const success = data.filter((entry) => entry.success).length;
              if (failed) {
                notifyError({
                  title: 'Error',
                  description: `${failed} expense(s) could not be approved`,
                });
              }
              if (success) {
                notifySuccess({
                  title: 'Success !',
                  description: `${success} expense(s) approved`,
                });
              }
              queryClient.invalidateQueries(expensesKeys.multiple(companyId));
              setRowSelection({});
            },
          })
        }
      >
        {`Approve ${
          disableApprove || selection.length === 0 ? '' : selection.length
        }`}
      </Button>
    </>
  );

  const employeesOptions = uniqBy(
    expensesToDisplay.map(({ employeeName }) => ({
      value: employeeName,
      label: employeeName,
    })),
    'label',
  ).sort(sorter);

  const sortedPayRollDateOptions = uniqBy(
    expensesToDisplay.map(({ estimatedPayrollMonth }) =>
      estimatedPayrollMonth
        ? {
            value: estimatedPayrollMonth,
            label: formatISODate(estimatedPayrollMonth ?? null, 'MMM yyyy'),
          }
        : null,
    ),
    'label',
  ).filter(Boolean) as OptionType[];

  const statusOptions = uniqBy(
    expensesToDisplay
      /* 
        to prevent having the option { label:"Pending", value:"CHANGES_MADE" }
        (caused by ENGF-342)
        that'd cause ?status=CHANGES_MADE
      */
      .filter(({ status }) => status !== ExpenseStatusEnum.CHANGE_MADE)
      .map(({ status }) => ({
        value: status,
        label: getDisplayStatusLabel(status),
      }))
      .sort(),
    'label',
  ).sort(sorter);

  return (
    <PageContent style={{ position: 'relative' }}>
      <Stack gap="16">
        <Breadcrumbs
          crumbs={[
            { id: 'home', title: 'Home', onClick: () => history.push('/') },
            { id: 'expenses', title: PAGE_TITLE, active: true },
          ]}
        />
        <Typography as="h2" hideParagraphSpacing size="24" weight="medium">
          {PAGE_TITLE}
        </Typography>

        <Stack>
          <Notification
            id="expense-info-alert"
            intent="warning"
            title={`The next cut off date by when you need to approve expenses is ${getNextPayrollCutOffDate()}`}
            description="If expenses are approved after this time your employees will be reimbursed in next month’s payroll instead"
          />
          {(viewport.isLaptop || viewport.isDesktop || viewport.isHighRes) && (
            <Stack>
              <Card p="32">
                <Inline gap="16" align="end" justify="space-around">
                  <Dropdown
                    id="employeeName"
                    onChange={(newValue: SingleValue<OptionType>) => {
                      setFilters((filters) => {
                        const newFilters = {
                          ...filters,
                          employeeName: newValue?.value as string,
                        };
                        updateUrl(newFilters);
                        return newFilters;
                      });
                    }}
                    placeholder="Select employee"
                    options={employeesOptions}
                    value={employeesOptions.filter(
                      (employee) => employee.value === filters.employeeName,
                    )}
                    isSearchable
                    isMulti={false}
                    isClearable={false}
                  />
                  <DateInput
                    id="dateFrom"
                    placeholderText="Select date from"
                    onChange={(date: Date | null) => {
                      setFilters((filters) => {
                        const newFilters = {
                          ...filters,
                          dateFrom: date ?? undefined,
                        };
                        updateUrl(newFilters);
                        return newFilters;
                      });
                    }}
                    maxDate={filters.dateTo}
                    selected={filters.dateFrom}
                  />
                  <DateInput
                    id="dateTo"
                    placeholderText="Select date to"
                    onChange={(date: Date | null) => {
                      setFilters((filters) => {
                        const newFilters = {
                          ...filters,
                          dateTo: date ?? undefined,
                        };
                        updateUrl(newFilters);
                        return newFilters;
                      });
                    }}
                    minDate={filters.dateFrom}
                    selected={filters.dateTo}
                  />
                  <Dropdown
                    id="status"
                    placeholder="Select status"
                    onChange={(newValue: SingleValue<OptionType>) => {
                      setFilters((filters) => {
                        const newFilters = {
                          ...filters,
                          status: newValue?.value as string,
                        };
                        updateUrl(newFilters);
                        return newFilters;
                      });
                    }}
                    value={statusOptions.filter(
                      (status) => status.value === filters.status,
                    )}
                    options={statusOptions}
                    isMulti={false}
                    isSearchable
                    isClearable={false}
                  />
                  <Dropdown
                    id="category"
                    placeholder="Select category"
                    onChange={(newValue: SingleValue<OptionType>) => {
                      setFilters((filters) => {
                        const newFilters = {
                          ...filters,
                          category: newValue?.value as string,
                        };
                        updateUrl(newFilters);
                        return newFilters;
                      });
                    }}
                    value={CATEGORIES_OPTIONS.filter(
                      (category) => category.value === filters.category,
                    )}
                    options={CATEGORIES_OPTIONS}
                    isSearchable
                    isMulti={false}
                    isClearable={false}
                  />

                  <Dropdown
                    id="estimatedPayrollMonth"
                    placeholder="Select pay date"
                    onChange={(newValue: SingleValue<OptionType>) => {
                      setFilters((filters) => {
                        const newFilters = {
                          ...filters,
                          estimatedPayrollMonth: newValue?.value as string,
                        };
                        updateUrl(newFilters);
                        return newFilters;
                      });
                    }}
                    options={sortedPayRollDateOptions}
                    value={sortedPayRollDateOptions.filter(
                      (payDate) =>
                        payDate.value === filters.estimatedPayrollMonth,
                    )}
                    isSearchable
                    isClearable={false}
                    isMulti={false}
                  />
                  <Button
                    variant="secondary"
                    disabled={!Object.values(filters).filter(Boolean).length}
                    icon="FilterOff"
                    onClick={() => {
                      setFilters(EMPTY_FILTERS_STATE);
                      updateUrl(EMPTY_FILTERS_STATE);
                    }}
                  >
                    Clear
                  </Button>
                </Inline>
              </Card>
              <Table
                showHeader
                table={!isSuccess ? loadingTable : table}
                useSorting
                useRowSelection
                tableActions={tableActions()}
              />
            </Stack>
          )}
          {(viewport.isPhone || viewport.isTablet) && (
            <>
              <MobileCardListHeader total={expensesToDisplay?.length} />
              {expensesToDisplay?.map((expense) => (
                <ExpenseMobileCard expense={expense} key={expense.id} />
              ))}
            </>
          )}
        </Stack>
      </Stack>
    </PageContent>
  );
};
