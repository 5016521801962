import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const AddressContainer = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  margin-top: ${({ theme: { space } }) => space[8]};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    flex-basis: 100%;
  }
`;
