import { createEmployer } from 'common/api/employers.api';
import { EmployerCreateApiRequest } from 'common/types';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { lspsKeys } from '../../lsps/keys';

export const useCreateEmployerMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (employer: EmployerCreateApiRequest) => createEmployer(employer),
    {
      onError: (error) => {
        dispatch({ type: 'CREATE_EMPLOYER_ERROR', payload: error });
      },
      onSuccess: () => {
        queryClient.refetchQueries(lspsKeys.default);
      },
    },
  );
};
