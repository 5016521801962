import { client } from 'api';
import { ManagerEmployeesApiResponse } from 'common/types';

export const getManagerPermissionsRequest = (
  companyId: string,
  managerUserId: string,
) => {
  return client.get<ManagerEmployeesApiResponse>(
    `/employees/company/${companyId}/manager/${managerUserId}`,
  );
};
