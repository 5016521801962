import { OnboardingStage, Step } from '../OnboardingProgressTracker/types';
import { OnboardingProgressDrawerStage } from './OnboardingProgressDrawerStage';
import { OnboardingProgressDrawerStep } from './OnboardingProgressDrawerStep';

type Props = {
  stages: OnboardingStage[];
  completedSteps: string[];
};

export const ReadOnlyDrawer = ({ stages, completedSteps }: Props) => {
  return (
    <>
      {stages.map((stage: OnboardingStage, index: number) => (
        <OnboardingProgressDrawerStage
          key={stage.id}
          stage={stage}
          index={index}
          totalStages={stages.length}
          completedSteps={completedSteps}
        >
          {stage.steps.map((step: Step) => (
            <OnboardingProgressDrawerStep
              key={step.id}
              isOpen={true}
              step={step}
              stageId={stage.id}
              completedSteps={completedSteps}
            />
          ))}
        </OnboardingProgressDrawerStage>
      ))}
    </>
  );
};
