import { ComponentProps } from 'react';

import { User } from 'react-feather';

import * as S from '../../ManagerCard/ManagerCard.styles';

type AddManagerCardProps = {
  cardHeader: string;
  headerId: string;
} & ComponentProps<typeof S.ManagerCardContainer>;

export const AddManagerCard = ({
  cardHeader,
  selected = false,
  headerId,
  ...rest
}: AddManagerCardProps) => {
  return (
    <S.ManagerCardContainer
      aria-labelledby={headerId}
      checked={selected}
      aria-checked={selected}
      data-testid="manager-card-container"
      {...rest}
    >
      <S.AddManagerCardContentContainer>
        <S.AddManagerCardContentIcon>
          <User />
        </S.AddManagerCardContentIcon>
        <S.ManagerCardContentText>
          <S.ManagerCardHeader id={headerId}>{cardHeader}</S.ManagerCardHeader>
        </S.ManagerCardContentText>
      </S.AddManagerCardContentContainer>
    </S.ManagerCardContainer>
  );
};
