import React, { useMemo } from 'react';

import { ErrorBanner } from 'common/components';
import Loading from 'common/components/atoms/Loading/Loading';
import { StyledTitleCell } from 'common/components/organisms/TableWithTitle/TableWithTitle.styles';
import { getTenantIdSelector } from 'common/store/selectors/companies.selectors';
import {
  BENEFIT_COUNTRY_SELECTION_LABEL,
  BenefitsClientPreferencesResponse,
  BenefitsCountrySelectionState,
} from 'common/types';
import { useSelector } from 'react-redux';
import { getCountryByCountryCode } from 'utils/countries';
import { useBenefitsClientPreferencesListQuery } from 'utils/queries/benefits/useBenefitsPreferences';

import { BenefitsPreferencesTable } from './AdminBenefitsTab.styles';

const CustomCell = ({ country }: { country: string }) => {
  return <StyledTitleCell>{country}</StyledTitleCell>;
};

type BenefitsClientPreferenceTableData = {
  country: string;
  selection: string;
};

const transformResponse = (
  data: BenefitsClientPreferencesResponse[],
): BenefitsClientPreferenceTableData[] => {
  return data.map(({ countryCode, selectionStatus }) => ({
    country: getCountryByCountryCode(countryCode)?.label || '',
    selection:
      BENEFIT_COUNTRY_SELECTION_LABEL[
        selectionStatus as BenefitsCountrySelectionState
      ],
  }));
};

export const AdminBenefitsTab = () => {
  const companyId = useSelector(getTenantIdSelector);

  const benefitsPreferencesListQuery = useBenefitsClientPreferencesListQuery({
    clientId: companyId,
  });

  const headers = [
    {
      headerTitle: 'Country',
      customCell: CustomCell,
    },
    {
      headerTitle: 'Benefits choice',
      fieldOnObject: 'selection',
    },
  ];

  const tableData = useMemo(() => {
    if (!benefitsPreferencesListQuery.data) {
      return [];
    }
    return transformResponse(benefitsPreferencesListQuery.data);
  }, [benefitsPreferencesListQuery.data]);

  if (benefitsPreferencesListQuery.isLoading) {
    return <Loading />;
  }

  if (benefitsPreferencesListQuery.isError) {
    return (
      <ErrorBanner
        errorMessage={benefitsPreferencesListQuery?.error?.message}
      />
    );
  }

  return (
    <>
      <BenefitsPreferencesTable
        testId="company-benefits"
        itemsToDisplay={tableData}
        onClick={null}
        headers={headers}
      />
    </>
  );
};
