import { StyledSmallCopy } from 'common/components/atoms/Text';
import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledEditDetailsPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-self: center;
`;

export const StyledSectionContainer = styled.div`
  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-left: 0;
    width: 100%;
  }
`;

export const StyledSectionContainerWrapper = styled.div`
  background: ${({ theme: { colors } }) => colors['background-primary']};
  padding: ${({ theme: { space } }) => space[32]};
  display: flex;
  flex-direction: column;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    padding: ${({ theme: { space } }) =>
      `${space[16]} ${space[16]} ${space[16]} ${space[16]}`};
  }
`;

export const EditDetailsStyledSectionContainer = styled.div`
  width: 100%;
  margin-left: ${({ theme: { space } }) => space[32]};
  max-width: 600px;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-left: 0;
  }
`;

export const EditDetailsStyledSectionContainerWrapper = styled.div`
  background: ${({ theme: { colors } }) => colors['background-primary']};
  padding: ${({ theme: { space } }) => space[32]};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  border-radius: ${({ theme: { radii } }) => radii.m};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    padding: ${({ theme: { space } }) => `${space[16]}`};
  }
`;

export const StyledEditPageRequiredFields = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: ${({ theme: { colors } }) => `${colors['text-subtle']}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
  margin-bottom: ${({ theme: { space } }) => space[4]};
`;

export const StyledDetailSection = styled.div``;

export const StyledEditPageContent = styled.div`
  display: flex;
`;

export const StyledEditPageMain = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-self: center;
`;

export const StyledFormHeader = styled.h3`
  margin-left: 0;
  margin-top: 0;
  color: ${({ theme: { colors } }) => colors['text-default']};
`;

export const StyledFormHeaderSubString = styled.div`
  font-weight: 300;
  margin-bottom: ${({ theme: { space } }) => space[4]};
`;

export const StyledSectionFormContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    flex-direction: column;
  }
`;

export const StyledSectionFormContainer = styled(StyledSectionContainerWrapper)`
  padding: ${({ theme: { space } }) =>
    `${space[0]} ${space[64]} ${space[0]} ${space[0]}`};
  width: 100%;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    padding: 0;
  }
`;

export const StyledContextualInfo = styled(StyledSmallCopy)`
  margin-top: ${({ theme: { space } }) => space[16]};
  margin-bottom: ${({ theme: { space } }) => space[16]};
`;
