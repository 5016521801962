import { VariantPayment } from 'common/types/variantPayment';

export const getMockVariantPayment = (
  overrides?: Partial<VariantPayment>,
): VariantPayment => {
  const variantPayment = {
    id: 'mock-id',
    type: 'bonus',
    employeeId: 'mock-employeeId',
    amount: { amount: 10, currency: 'USD' },
    companyId: 'mock-companyId',
    effectiveDate: '2022-10-11',
    requestedAt: '2022-10-10',
    requestedByAuthId: 'mocked-authId',
    reason: 'Internal reason',
  };
  return Object.assign(variantPayment, overrides);
};
