import { GetHRISEmployeesListPayload } from 'common/api/integrations.api';
import { IntegrationProvider } from 'common/types';
import { createQueryKeys } from 'utils/react-query-utils';

export const integrationsKeys = createQueryKeys(
  'integrations',
  (createKey) => ({
    hris: {
      provider: (provider: IntegrationProvider) => createKey('hris', provider),
      employeeList: function ({
        companyId,
        provider,
        integrationId,
      }: GetHRISEmployeesListPayload) {
        const [, ...providerKey] = this.provider(provider);

        return createKey(...providerKey, integrationId, companyId, 'employees');
      },
    },
  }),
);
