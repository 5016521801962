import { client } from 'api';

import type {
  CreateTemplateMappingPayload,
  TemplateMapping,
  UpdateTemplateMappingPayload,
} from '../types';

export const RESOURCE_PATH = `template-mappings`;
export const PATH = `/contracts/${RESOURCE_PATH}`;

export const createTemplateMapping = (data: CreateTemplateMappingPayload) =>
  client.post<TemplateMapping>(PATH, data);

export const updateTemplateMapping = ({
  templateId,
  disabled,
}: { templateId: string } & UpdateTemplateMappingPayload) =>
  client.patch<TemplateMapping>(`${PATH}/${templateId}`, { disabled });
