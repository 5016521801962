import { ReactComponent as WarningBellIcon } from 'assets/icon/ErrorBell.svg';
import { ReactComponent as InfoBellIcon } from 'assets/icon/InfoBell.svg';
import PropTypes from 'prop-types';

import { Viewport } from '@omnipresentgroup/design-system';

import { StyledInfoBannerContainer, StyledInfoCopy } from './InfoBanner.styles';

const InfoBanner = ({ copy, type, statusPill, maxWidth, margin }) => {
  return (
    <StyledInfoBannerContainer
      className="stack"
      type={type}
      data-testid="info-banner"
      maxWidth={maxWidth}
      margin={margin}
    >
      <Viewport devices={['laptop', 'desktop', 'highRes']}>
        {type === 'info' && (
          <InfoBellIcon data-testid="info-banner-info-icon" />
        )}
        {type === 'warning' && (
          <WarningBellIcon data-testid="info-banner-warning-icon" />
        )}
      </Viewport>
      {statusPill}
      <StyledInfoCopy>{copy}</StyledInfoCopy>
    </StyledInfoBannerContainer>
  );
};

InfoBanner.propTypes = {
  copy: PropTypes.string.isRequired,
  type: PropTypes.string,
  statusPill: PropTypes.node,
  maxWidth: PropTypes.string,
  margin: PropTypes.string,
};

InfoBanner.defaultProps = {
  type: 'info',
  statusPill: null,
  maxWidth: '170px',
};

export default InfoBanner;
