import { getResignations } from 'common/api/resignations.api';
import { ONE_MINUTE } from 'common/constants';
import { ResignationModel } from 'common/types/resignations';
import { useQuery, UseQueryOptions } from 'react-query';

import { getDataFromResponse } from '../../../react-query-utils';
import { resignationsKeys } from './keys';

export const useResignationsQuery = (
  employeeId: string,
  { enabled }: { enabled: boolean },
  options?: UseQueryOptions<ResignationModel[], Error>,
) => {
  return useQuery<ResignationModel[], Error>(
    resignationsKeys.allResignations(employeeId),
    () => getResignations(employeeId).then(getDataFromResponse),
    {
      ...options,
      staleTime: options?.staleTime || ONE_MINUTE,
      enabled,
    },
  );
};
