import { Tabs } from 'common/components/organisms/Tabs/Tabs';
import styled from 'styled-components';

export const StyledTabsContainer = styled(Tabs)``;

export const StyledEmptyState = styled.div`
  & > div {
    min-height: 250px;
  }
`;

export const StyledHorizontalButton = styled.button`
  outline: none;
  background: none;
  border: none;
`;
