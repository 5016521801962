import styled from 'styled-components';

/**
 * @deprecated
 * Use the OmniDS Typography component with a medium weight instead
 * https://omnipresentgroup.github.io/OmniDS/?path=/docs/components-typography--default
 * Please reach out to #web-platform-support for questions about using the OmniDS components
 */
export const StyledLabel = styled.label<{ required?: boolean }>`
  color: ${({ theme: { colors } }) => colors['text-default']};
  padding: ${({ theme: { space } }) => `${space[4]} ${space[4]} ${space[4]} 0`};
  margin-bottom: ${({ theme: { space } }) => space[4]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};

  &::after {
    content: ' *';
    display: ${(props) => (props.required ? 'inline' : 'none')};
  }
`;
