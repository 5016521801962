import { useEffect } from 'react';

import { TransformedUser } from 'app/store/app.transformers';
import { getSelectedCompanyForAppSelector } from 'common/store/selectors/companies.selectors';
import { EmployeeForDetailView, SelectedCompany } from 'common/types';
import { getEmployeeSelector } from 'omniplatform/employee/store/selectors/employee.selector';
import { useSelector } from 'react-redux';
import { intercomUpdate, IntercomUpdateObject } from 'utils/intercom/utils';
import { getUserMainRole, isEmployee, isManager } from 'utils/user';

export const useIntercom = (user: TransformedUser) => {
  const selectedEmployee = useSelector<any, EmployeeForDetailView>(
    getEmployeeSelector,
  );
  const selectedCompany = useSelector<any, SelectedCompany>(
    getSelectedCompanyForAppSelector,
  );

  useEffect(() => {
    if (!user?.roles) {
      return;
    }

    let intercomUpdateObject: IntercomUpdateObject = {
      name: user?.name,
      email: user?.email,
      user_id: user?.role.id,
      user_type: getUserMainRole(user?.roles).type,
    };

    if (isEmployee(user)) {
      intercomUpdateObject = {
        ...intercomUpdateObject,
        employee_externalID: selectedEmployee?.externalId,
        country_of_work: selectedEmployee?.country,
        employee_status: selectedEmployee?.onboardingStatus,
        employee_type: selectedEmployee?.employeeType,
        company: {
          id: selectedEmployee.managerId,
          name: selectedEmployee.companyName,
        },
        lsp_id: selectedEmployee?.lspId,
        op_profile: `${window.location.origin}/employees/${selectedEmployee.id}`,
      };
    } else if (isManager(user)) {
      intercomUpdateObject = {
        ...intercomUpdateObject,
        company: {
          id: selectedCompany.id,
          name: selectedCompany.companyName,
          company_externalID: selectedCompany?.administrativeInformation?.find(
            (information) => information.name === 'externalId',
          )?.value,
        },
      };
    }

    intercomUpdate(intercomUpdateObject);
  }, [user, selectedEmployee, selectedCompany]);
};
