import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { subject } from '@casl/ability';
import { useAppAbility } from 'app/store/ability';
import AddDocumentPage from 'common/pages/AddDocumentPage/AddDocumentPage';
import {
  createDocumentAction,
  getDocumentsAction,
} from 'common/store/actions/documents.actions';
import { getSelectedCompanySelector } from 'common/store/selectors/companies.selectors';
import { getDocumentsSelector } from 'common/store/selectors/document.selectors';
import { COMPANIES_LIST_PAGE } from 'paths';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getCompanyAction } from '../../store/actions/companies.actions';

const AddCompanyDocumentPage = ({
  company,
  addDocument,
  getCompany,
  documents,
  getDocuments,
}) => {
  const { companyId } = useParams();
  const ability = useAppAbility();

  const canAddDocument = ability.can(
    'documents:create',
    subject('Documents', { ownerId: companyId }),
  );

  useEffect(() => {
    if (!documents) {
      getDocuments({ companyId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!company?.id) {
      getCompany(companyId);
    }
  }, [companyId, company, getCompany]);

  const onDocumentFormSubmitted = (documentInfo) =>
    addDocument({
      documentInfo,
      tenantId: companyId,
      userId: null, // for now, a null userId is how company documents will be created
      returnPath: `${COMPANIES_LIST_PAGE}/${companyId}#documents`,
    });
  return (
    <AddDocumentPage
      onDocumentFormSubmitted={onDocumentFormSubmitted}
      canAddDocument={canAddDocument}
      copy="Upload a document for this client. Uploads will be visible to all Client Managers."
    />
  );
};

AddCompanyDocumentPage.propTypes = {
  addDocument: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
  company: PropTypes.object,
  getDocuments: PropTypes.func.isRequired,
  documents: PropTypes.array,
};

AddCompanyDocumentPage.defaultProps = {
  company: null,
  documents: undefined,
};

const mapStateToProps = (state) => ({
  company: getSelectedCompanySelector(state),
  documents: getDocumentsSelector(state),
});

const mapDispatchToProps = {
  addDocument: createDocumentAction,
  getDocuments: getDocumentsAction,
  getCompany: getCompanyAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCompanyDocumentPage);
