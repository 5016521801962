import { User, UserRole } from 'common/types';
import {
  getUserMainRole,
  roleTypeToUserFriendlyRoleTypeName,
} from 'utils/user';

export type TransformedUser = {
  authId: string;
  userId: string;
  displayName: string;
  name: string;
  email: string;
  role: UserRole;
  roles: UserRole[];
  userDetails: {
    name: string;
    label: string;
    value: string;
    type: string;
    editable?: boolean;
    showFor?: string;
    required?: boolean;
  }[];
  administrativeInformation: [
    {
      name: 'sentInvitationAt';
      label: 'Platform Invitation';
      value: '' | 'ACCEPTED' | 'SENT';
      type: 'invitation';
      showFor: 'admin';
    },
    {
      name: 'temporaryPassword';
      label: 'Temporary password';
      value: string;
      type: 'text';
      showFor: 'admin';
    },
  ];
};

export const transformUser = ({
  displayName,
  firstName,
  lastName,
  email,
  roles,
  userId,
  authId,
  temporaryPassword,
  sentInvitationAt,
  seenPrivacyPolicy,
}: User): TransformedUser => {
  const role = getUserMainRole(roles) as UserRole;

  return {
    authId,
    userId,
    displayName,
    name: `${firstName} ${lastName}`,
    email,
    role,
    roles,
    userDetails: [
      {
        name: 'firstName',
        label: 'First name',
        value: firstName,
        type: 'string',
        editable: true,
        showFor: 'manager admin lsp',
        required: true,
      },
      {
        name: 'lastName',
        label: 'Last name',
        value: lastName,
        type: 'string',
        editable: true,
        showFor: 'manager admin lsp',
        required: true,
      },
      {
        name: 'displayName',
        label: 'Display name',
        value: displayName,
        type: 'string',
        editable: true,
        showFor: 'employee manager',
        required: true,
      },
      {
        name: 'email',
        label: 'Personal email',
        value: email,
        type: 'string',
        showFor: 'employee admin lsp',
        editable: true,
        required: true,
      },
      {
        name: 'email',
        label: 'Work email',
        value: email,
        type: 'string',
        showFor: 'manager',
        editable: true,
        required: true,
      },
      {
        name: 'roleType',
        label: 'Role',
        value: roleTypeToUserFriendlyRoleTypeName(role.type),
        type: 'string',
      },
    ],
    administrativeInformation: [
      {
        name: 'sentInvitationAt',
        label: 'Platform Invitation',
        value: seenPrivacyPolicy ? 'ACCEPTED' : sentInvitationAt && 'SENT',
        type: 'invitation',
        showFor: 'admin',
      },
      {
        name: 'temporaryPassword',
        label: 'Temporary password',
        value: temporaryPassword,
        type: 'text',
        showFor: 'admin',
      },
    ],
  };
};
