import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledEmployeePage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const StyledEmployeeTableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledEmployeeCardTitle = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 35px;
    height: 35px;
  }
`;

export const StyledManagerEmployeesTableContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    width: 100%;
  }
`;
