import { onboardingServiceClient } from 'api';

import { IconName } from '@omnipresentgroup/design-system';

export enum OnboardingStatusValues {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

type JobTask = {
  id: string;
  status: OnboardingStatusValues;
  title: string;
  type: string;
  actionLink?: string;
  displayName: string;
  displayOrder: number;
};

export type Job = {
  title: string;
  description: string;
  status: OnboardingStatusValues;
  iconName: IconName;
  type: 'job';
  tasks: JobTask[];
};

export type Notice = {
  title: string;
  description: string;
  status: OnboardingStatusValues;
  iconName: IconName;
  type: 'notice';
  noticeLink?: string;
  tasks: []; // FIXME: that's what the API returns
};

export type Onboarding = {
  id: string;
  userId: string;
  status: OnboardingStatusValues;
  territory: string;
  employmentType: string;
  steps: Array<Job | Notice>;
};

export const ONBOARDING_RESOURCE_PATH = 'onboardings';

export type FetchParams = {
  [key: 'userId' | string]: string;
};
export const getOnboardingsRequest = (params: FetchParams) =>
  onboardingServiceClient.get<Onboarding[]>(`/v1/${ONBOARDING_RESOURCE_PATH}`, {
    params,
  });
