import { createQueryKeys } from 'utils/react-query-utils';

export const resignationsKeys = createQueryKeys(
  'resignations',
  (createKey) => ({
    default: createKey('resignations'),
    allResignations: (employeeId?: string) => createKey(employeeId),
    allResignationsByCompany: (companyId?: string) => createKey(companyId),
  }),
);
