import { HTTPStatusCodesEnum } from 'common/constants';
import { rest } from 'msw';

import {
  FetchParams,
  Onboarding,
  ONBOARDING_RESOURCE_PATH,
  OnboardingStatusValues,
} from './onboardings.api';

export const onboardingsHandlers = [
  rest.get<Onboarding[], FetchParams>(
    `${process.env.VITE_ONBOARDING_API_ENDPOINT}/v1/${ONBOARDING_RESOURCE_PATH}`,
    (req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json([]));
    },
  ),
];

const DEFAULT_ONBOARDING: Onboarding = {
  id: '8ef93bf1-e365-49c8-a7e5-590c393e7e98',
  userId: '5fa85611-8866-4d7c-b538-bb33bffc7130',
  status: OnboardingStatusValues.IN_PROGRESS,
  territory: 'GB',
  employmentType: 'EOR',
  steps: [
    {
      title: 'Personal profile',
      description:
        'Add personal, financial and emergency contact details to create your profile',
      iconName: 'User',
      status: OnboardingStatusValues.IN_PROGRESS,
      type: 'job',
      tasks: [
        {
          id: 'e5e2b9ce-fda4-457b-bc5f-f11686c6a715',
          title: 'collect_personal_details',
          type: 'flexible_form',
          status: OnboardingStatusValues.COMPLETED,
          actionLink:
            '/onboarding/specifications/4c3553df-9468-4e9f-b0e7-a4c166210b2e/0',
          displayName: 'Personal details',
          displayOrder: 0,
        },
        {
          id: 'ac81fbd6-d53b-4c33-a368-5ae6f6db8112',
          title: 'collect_home_address',
          type: 'flexible_form',
          status: OnboardingStatusValues.IN_PROGRESS,
          actionLink:
            '/onboarding/specifications/e0c9d896-6a54-4943-aee6-dd1647d7083b/0',
          displayName: 'Address',
          displayOrder: 1,
        },
        {
          id: 'e3d87f69-774d-441f-ae08-f21c835b46f4',
          title: 'collect_bank_details',
          type: 'flexible_form',
          status: OnboardingStatusValues.IN_PROGRESS,
          actionLink:
            '/onboarding/specifications/ca9303c9-b524-47b1-ae89-095fd13eb5dd/0',
          displayName: 'Bank details',
          displayOrder: 2,
        },
        {
          id: '7f5e56d4-8bf0-4aee-a643-a3784b03cb30',
          title: 'collect_administrative_information',
          type: 'flexible_form',
          status: OnboardingStatusValues.COMPLETED,
          actionLink:
            '/onboarding/specifications/a1211be7-28c9-470c-bb60-581ee5d7a032/0',
          displayName: 'Administrative information',
          displayOrder: 3,
        },
        {
          id: '8008ac6b-9036-497b-956e-45875aab7cec',
          title: 'collect_emergency_contact',
          type: 'flexible_form',
          status: OnboardingStatusValues.IN_PROGRESS,
          actionLink:
            '/onboarding/specifications/b31e1bcc-4ed3-4ca2-8da2-b101ffc860dc/0',
          displayName: 'Emergency contact',
          displayOrder: 4,
        },
        {
          id: '38bc45b8-9783-4773-a615-48820c7ab2f5',
          title: 'collect_identification_details',
          type: 'flexible_form',
          status: OnboardingStatusValues.IN_PROGRESS,
          actionLink:
            '/onboarding/specifications/07cdb1c7-56b7-4ed8-a219-042d5f431f33/0',
          displayName: 'Identification details',
          displayOrder: 5,
        },
      ],
    },
    {
      title: 'Employment contract',
      description: 'View and sign your employment contract',
      iconName: 'Briefcase',
      status: OnboardingStatusValues.COMPLETED,
      type: 'notice',
      tasks: [],
    },
    {
      title: 'Benefits package',
      description:
        'You will receive more information on your first working day',
      iconName: 'Award',
      status: OnboardingStatusValues.COMPLETED,
      type: 'notice',
      tasks: [],
    },
    {
      title: 'United Kingdom',
      description: 'Useful information about working and living here',
      iconName: 'World',
      status: OnboardingStatusValues.COMPLETED,
      type: 'notice',
      tasks: [],
      noticeLink:
        'https://www.omnipresent.com/global-employment-solutions/peo-uk',
    },
  ],
};

export const generateOnboarding = (
  override: Partial<typeof DEFAULT_ONBOARDING> = {},
) => ({ ...DEFAULT_ONBOARDING, ...override });

/* When you need to do a local override make use of this function */
