import { EmployersTab } from './Tabs/EmployersTab';
import { PayrollProvidersTab } from './Tabs/PayrollProvidersTab';

const getTabs = () => {
  return [
    {
      id: 1,
      title: 'Employers',
      href: '#employers',
      Content: EmployersTab,
    },
    {
      id: 2,
      title: 'Payroll providers',
      href: '#payroll-providers',
      Content: PayrollProvidersTab,
    },
  ];
};

export { getTabs };
