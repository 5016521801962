import { MouseEventHandler } from 'react';

import { OnboardingStatus } from 'common/components/molecules/OnboardingStatus/OnboardingStatus';
import {
  ClickableCell,
  StyledTitleCell,
} from 'common/components/organisms/TableWithTitle/TableWithTitle.styles';
import { PaginatedEmployeesPageData } from 'common/store/transformers/employeesTransformer';
import { TableHeader } from 'common/types';
import get from 'lodash/get';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';

const NameCustomCell = (employee: {
  name: string;
  onClick: MouseEventHandler;
}) => {
  const name = get(employee, 'name', '');
  const onClick = get(employee, 'onClick');
  return (
    <ClickableCell onClick={onClick}>
      <StyledTitleCell>{name}</StyledTitleCell>
    </ClickableCell>
  );
};

const ClientCustomCell = ({
  company,
}: {
  company: { name: string; onClick: MouseEventHandler };
}) => {
  const name = get(company, 'name', '');
  const onClick = get(company, 'onClick');

  return !!name ? (
    <ClickableCell onClick={onClick}>{name}</ClickableCell>
  ) : null;
};

const LSPCustomCell = ({
  lsp,
}: {
  lsp: { name: string; onClick: MouseEventHandler };
}) => {
  const name = get(lsp, 'name', '');
  const onClick = get(lsp, 'onClick');

  return !!name ? (
    <ClickableCell onClick={onClick}>{name}</ClickableCell>
  ) : null;
};

const StatusCustomCell = ({
  onboardingStatus,
  totalStages,
  latestInProgressStageId,
}: {
  onboardingStatus: string;
  totalStages?: number;
  latestInProgressStageId?: number;
}) => (
  <OnboardingStatus
    status={onboardingStatus}
    totalStages={totalStages}
    latestInProgressStageId={latestInProgressStageId}
  />
);

const startDateCell = ({ startDate }: { startDate: string }) => {
  return <span>{formatISODate(startDate, DateFormats.Date, true, '-')}</span>;
};

export const paginatedTableHeaders: TableHeader<PaginatedEmployeesPageData>[] =
  [
    {
      headerTitle: 'Name',
      customCell: NameCustomCell,
      align: 'left',
      fieldOnObject: 'name',
    },
    {
      headerTitle: 'Country',
      align: 'left',
      fieldOnObject: 'country',
    },
    {
      headerTitle: 'Client',
      align: 'left',
      customCell: ClientCustomCell,
    },
    {
      headerTitle: 'LSP',
      align: 'left',
      customCell: LSPCustomCell,
    },
    {
      headerTitle: 'Start Date',
      align: 'left',
      fieldOnObject: 'startDate',
      customCell: startDateCell,
    },
    {
      headerTitle: 'Status',
      align: 'left',
      fieldOnObject: 'onboardingStatus',
      customCell: StatusCustomCell,
    },
  ];
