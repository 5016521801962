import { useMemo } from 'react';

import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Big from 'big.js';
import { Concept } from 'common/types/payroll';
import isEmpty from 'lodash/isEmpty';
import { Info } from 'luxon';
import { TableWrapper } from 'omniplatform/admin/pages/PayReportPreviewPage/PayReportBreakdownTable.styles';
import {
  formatValueToFixed,
  PayReportCustomCell,
} from 'omniplatform/admin/pages/PayReportPreviewPage/PayReportPreviewTable';
import { PayReportViewFilter } from 'omniplatform/admin/pages/PayReportPreviewPage/PayReportViewFilter';

import {
  BreakdownItem,
  BreakdownItemType,
  groupConceptData,
  mergeNestedBreakdownItemsWithVariance,
} from './prepareDataForTreeTable';

const columnHelper = createColumnHelper<BreakdownItem>();

const columns: ColumnDef<BreakdownItem, string>[] = [
  columnHelper.accessor((row) => row.name, {
    header: () => <PayReportCustomCell fontWeight="bold" value="Pay Details" />,
    id: 'name',
    cell: (info) => {
      return (
        <div className={info.row.original.style}>
          <span>{info.getValue()}</span>
        </div>
      );
    },
  }),
  columnHelper.accessor((row) => row.amount, {
    header: () => <PayReportCustomCell fontWeight="bold" value="Amount" />,
    id: 'amount',
    cell: (info) => {
      return <PayReportCustomCell value={info.getValue()} />;
    },
  }),
];

type PayrollBreakdownTreeTableProps = {
  data: Concept[];
  variance: Concept[];
  payReportViewFilter: PayReportViewFilter;
};

export const PayReportBreakdownTable = ({
  data,
  variance,
  payReportViewFilter,
}: PayrollBreakdownTreeTableProps) => {
  const groupedConceptData = groupConceptData(data);
  const groupedVarianceConceptData = !isEmpty(variance)
    ? groupConceptData(variance)
    : [];
  const mergedData = mergeNestedBreakdownItemsWithVariance(
    groupedConceptData,
    groupedVarianceConceptData,
  );

  const varianceColumns = useMemo<ColumnDef<BreakdownItem, string>[]>(() => {
    return [
      ...columns,
      columnHelper.accessor((row) => row.previous, {
        header: () => (
          <PayReportCustomCell
            fontWeight="bold"
            value={`${Info.months()[Number(payReportViewFilter.month) - 1]} ${
              payReportViewFilter.year
            }: Cycle ${payReportViewFilter.payCycle}`}
          />
        ),
        id: 'previous',
        cell: (info) => {
          return (
            <PayReportCustomCell
              value={
                info.row.original.type === BreakdownItemType.INFORMATIONAL
                  ? ''
                  : info.getValue() || '-'
              }
            />
          );
        },
      }),
      columnHelper.accessor((row) => row.variance, {
        header: () => (
          <PayReportCustomCell fontWeight="bold" value="Variance" />
        ),
        id: 'variance',
        cell: (info) => {
          return (
            <PayReportCustomCell
              value={
                info.row.original.type === BreakdownItemType.INFORMATIONAL
                  ? ''
                  : info.getValue() || '-'
              }
            />
          );
        },
      }),
      columnHelper.accessor((row) => row.percentage, {
        header: () => <PayReportCustomCell fontWeight="bold" value="%" />,
        id: 'percentage',
        cell: (info) => {
          return (
            <PayReportCustomCell
              value={
                info.row.original.type === BreakdownItemType.INFORMATIONAL
                  ? ''
                  : info.getValue()
                    ? `${formatValueToFixed(info.getValue())} %`
                    : '-'
              }
            />
          );
        },
      }),
    ];
  }, [payReportViewFilter]);

  const dataToUse = payReportViewFilter.showVariance
    ? mergedData
    : groupedConceptData;

  const filteredData = dataToUse.filter((item) => {
    if (!item.amount) {
      return true;
    }

    const hasVariance =
      item.variance &&
      !isNaN(parseFloat(item.variance)) &&
      !Big(item.variance).eq(0);

    const hasValue =
      item.amount && !isNaN(parseFloat(item.amount)) && !Big(item.amount).eq(0);

    return hasVariance || hasValue;
  });

  const table = useReactTable({
    columns: payReportViewFilter.showVariance ? varianceColumns : columns,
    data: filteredData,
    getCoreRowModel: getCoreRowModel(),
  });

  return <TableWrapper showHeader={false} table={table} />;
};
