import { ModalWrapper } from 'common/components/molecules/ModalWrapper';

import {
  MessageTextContainer,
  StyledCloseButton,
  StyledCloseButtonContainer,
  StyledErrorIcon,
  StyledMessageModal,
} from './DeleteManagerModal.styles';
import { ErrorModalProps } from './DeleteManagerModal.types';

export const DeleteManagerErrorModal = ({ closeFn }: ErrorModalProps) => {
  return (
    <ModalWrapper fixedWidth="550" data-testid="error-modal">
      <StyledCloseButtonContainer>
        <StyledCloseButton onClick={closeFn} role="button" />
      </StyledCloseButtonContainer>

      <StyledMessageModal>
        <StyledErrorIcon />
        <MessageTextContainer>
          Something went wrong, please try again later.
        </MessageTextContainer>
      </StyledMessageModal>
    </ModalWrapper>
  );
};
