import { Fragment, useCallback, useEffect, useState } from 'react';

import { PageContentWrapper, ProfilePageContent } from 'app/App.styles';
import MobileButton from 'common/components/atoms/MobileButton/MobileButton';
import { StyledBoldCopy } from 'common/components/atoms/Text/Copy.styles';
import EditableInput from 'common/components/molecules/EditableInput/EditableInput';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Typography,
  Viewport,
} from '@omnipresentgroup/design-system';

import { FormFields, FormRequiredFields } from './ProfileWelcomePage.styles';

const ProfileWelcomePage = ({
  sections,
  validator,
  onSaveEdits,
  title,
  submitCTA,
  welcomeMessages,
}) => {
  const [editedValues, setEditedValues] = useState({});
  const [fieldsToValidate, setFieldsToValidate] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [sectionsToEdit, setSectionsToEdit] = useState(sections);

  const inputs = Array.from(document?.getElementsByTagName('input'));
  const inputIds = inputs.map((input) => input.id);

  useEffect(() => {
    const allValues = sections.reduce((values, section) => {
      const sectionDetails = section.details.reduce((acc, { name, value }) => {
        return { ...acc, [name]: value };
      }, {});
      return { ...values, ...sectionDetails };
    }, {});
    setFieldsToValidate(allValues);
  }, [sections]);

  const onFieldChange = useCallback(
    (name, value) => {
      setEditedValues((prevEditedValues) => ({
        ...prevEditedValues,
        [name]: value,
      }));
      const updatedSections = sectionsToEdit.map((section) => {
        return {
          ...section,
          key: section.name,
          details: section.details.map((field) => {
            const updatedField = { ...field };
            if (field.name === name) {
              updatedField.value = value;
            }
            return updatedField;
          }),
        };
      });
      setSectionsToEdit(updatedSections);
    },
    [sectionsToEdit],
  );

  const getErrorField = useCallback(
    (errorKeys) => {
      const field = errorKeys.find((x) => {
        return inputIds.includes(x);
      });
      return field;
    },
    [inputIds],
  );

  const onCTAClick = useCallback(() => {
    const validationObject = { ...fieldsToValidate, ...editedValues };
    const { countryOfWork, ...rest } = validationObject;

    const validationResult = validator(rest);
    if (validationResult) {
      setValidationErrors(validationResult);
      const errorKeys = Object.keys(validationResult);
      if (errorKeys.length > 0) {
        const field = getErrorField(errorKeys);
        const fieldInError = document?.getElementById(`${field}`);
        fieldInError?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }
      return;
    }
    setValidationErrors({});
    onSaveEdits(rest);
  }, [editedValues, fieldsToValidate, onSaveEdits, validator, getErrorField]);

  const getErrorMessage = (name, type) => {
    if (type === 'address') {
      return validationErrors.address;
    }
    if (validationErrors[name]) {
      return validationErrors[name];
    }
    return null;
  };

  return (
    <ProfilePageContent data-testid="edit-welcome-profile-form">
      <PageContentWrapper>
        <Box>
          <Typography as="h1" hideParagraphSpacing size="24" weight="medium">
            {title}
          </Typography>

          {welcomeMessages.map((message, index) => {
            return (
              <Typography as="span" size="16" key={`message-${index}`}>
                {message}
              </Typography>
            );
          })}
        </Box>
        <FormFields>
          <FormRequiredFields>* required field</FormRequiredFields>
          {sectionsToEdit.map((detailSection) => (
            <Fragment key={detailSection.name}>
              <StyledBoldCopy>{detailSection.title}</StyledBoldCopy>
              {detailSection?.details?.map(
                ({
                  name,
                  type,
                  value,
                  label,
                  labelBold,
                  noLabel,
                  options,
                  readOnly,
                  link,
                  contextualInfo,
                  required,
                }) => {
                  return (
                    <EditableInput
                      key={name}
                      noLabel={noLabel}
                      name={name}
                      type={type}
                      link={link}
                      labelBold={labelBold}
                      value={value}
                      label={label}
                      editable={!readOnly}
                      onChange={onFieldChange}
                      contextualInfo={contextualInfo}
                      errorMessage={getErrorMessage(name, type)}
                      required={required}
                      options={
                        name === 'locationOfWork'
                          ? [{ homeAddress: editedValues.address }]
                          : options
                      }
                    />
                  );
                },
              )}
            </Fragment>
          ))}
        </FormFields>

        <Viewport devices={['laptop', 'desktop', 'highRes']}>
          <Button variant="primary" onClick={onCTAClick}>
            {submitCTA}
          </Button>
        </Viewport>
        <Viewport devices={['phone', 'tablet']}>
          <MobileButton onClick={onCTAClick}>{submitCTA}</MobileButton>
        </Viewport>
      </PageContentWrapper>
    </ProfilePageContent>
  );
};

ProfileWelcomePage.propTypes = {
  title: PropTypes.string,
  welcomeMessages: PropTypes.array,
  submitCTA: PropTypes.string,
  sections: PropTypes.array,
  onSaveEdits: PropTypes.func.isRequired,
  validator: PropTypes.func.isRequired,
  isSpainExpressOnboardingFlowActive: PropTypes.bool,
  loadingUpdate: PropTypes.bool,
};

ProfileWelcomePage.defaultProps = {
  sections: [],
  title: '',
  submitCTA: 'Submit',
  welcomeMessages: [],
};

export default ProfileWelcomePage;
