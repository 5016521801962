export const capitaliseFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const toTitleCase = (phrase: string) =>
  phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const removeAccents = (text: string) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const toSentenceCase = (text: string, separator = '_') => {
  return capitaliseFirstLetter(text.replaceAll(separator, ' ').toLowerCase());
};
