import { getCompanyUpsellFees } from 'common/api/seats.api';
import { TWO_MINUTES } from 'common/constants';
import { useQuery } from 'react-query';

import { companiesKeys } from '../keys';

export const useCompanyUpsellFeesQuery = (companyId: string) => {
  return useQuery(
    companiesKeys.upsellFees(companyId),
    () => getCompanyUpsellFees(companyId),
    {
      staleTime: TWO_MINUTES,
    },
  );
};
