import { IS_PROD_ENV } from '../../../constants';

export const BENEFITS_SELECTION_FORM_ID_DEV = 'mit7Cb57';

const TEST_CO_COMPANY_ID = 'TC';
const TEST_2_CO_COMPANY_ID = 'TCO';
const OMNI_GROUP_ID = 'OG';

const companiesNotApplicableForBenefits = [
  TEST_CO_COMPANY_ID,
  TEST_2_CO_COMPANY_ID,
  OMNI_GROUP_ID,
];

export const isCompanyApplicableForBenefits = (seatId: string) => {
  const companyId = seatId.split('-')[0];
  return companiesNotApplicableForBenefits.includes(companyId);
};

export const getBenefitsTypeformId = (seatId: string) => {
  if (IS_PROD_ENV && isCompanyApplicableForBenefits(seatId)) {
    return BENEFITS_SELECTION_FORM_ID_DEV;
  }
  return (
    process.env.VITE_BENEFITS_SELECTION_FORM_ID ||
    BENEFITS_SELECTION_FORM_ID_DEV
  );
};
