import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { getEmployeesUploadStatus } from 'common/api/employees.api';
import Button from 'common/components/atoms/Button';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { AlertTriangle, Loader } from 'react-feather';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useTrack } from 'utils/mixpanel/tracker';

import { omniConfig } from '@omnipresentgroup/design-system';

import { ErrorAccordion } from './ErrorAccordion/ErrorAccordion';
import { EmployeeValidationMessage } from './ReviewErrorPage/EmployeeValidationMessage';

const REFETCH_UPLOAD_INTERVAL = 5000;

const LoaderIcon = styled(Loader)`
  color: ${({ theme: { colors } }) => colors['icon-default']};
`;

const AlertIcon = styled(AlertTriangle)`
  color: ${({ theme: { colors } }) => colors['icon-warning']};
  margin-right: ${({ theme: { space } }) => space[16]};
`;

const AlertBanner = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme: { space } }) => `${space[16]} ${space[16]}`};
  border-radius: ${({ theme: { radii } }) => radii.s};
  margin-top: ${({ theme: { space } }) => space[32]};
  background-color: ${({ theme: { colors } }) => colors['gray-100']};
`;

const LoadingContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;

  font-weight: 500;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[0]};
`;

const AlertTitle = styled.div`
  font-weight: 600;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
`;

const TopContainer = styled.div`
  padding: ${({ theme: { space } }) => space[32]};
  width: 100%;
`;

const BottomBar = styled.div`
  border-top: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  padding: ${({ theme: { space } }) => space[32]};
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
`;

const Heading = styled.h2<{ marginTop?: string }>`
  color: ${({ theme: { colors } }) => colors['text-default']};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  font-weight: 600;
  margin-top: ${({ marginTop = 0 }) => marginTop};
`;

const SupplementaryContent = styled.p`
  color: ${({ theme: { colors } }) => colors['text-subtle']};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  margin-bottom: 0;
`;

const Separator = styled.div`
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  margin: ${({ theme: { space } }) => `${space[32]} ${space[0]}`};
`;

export const BulkUploadStatusPage = () => {
  const history = useHistory();
  const track = useTrack();
  const { bulkUploadId, companyId } = useParams<{
    companyId: string;
    bulkUploadId: string;
  }>();

  const bulkUploadQuery = useQuery(
    `bulkUpload-${bulkUploadId}`,
    () => getEmployeesUploadStatus(bulkUploadId),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (
        !bulkUploadQuery.isLoading &&
        bulkUploadQuery.data?.data?.state === 'pending'
      ) {
        bulkUploadQuery.refetch();
      }
    }, REFETCH_UPLOAD_INTERVAL);

    return () => clearTimeout(intervalId);
  }, [bulkUploadQuery]);

  useEffect(() => {
    if (bulkUploadQuery.data?.data?.state !== 'pending') {
      const data = bulkUploadQuery.data?.data;
      track('Import Employee BulkUpload Completed', {
        clientId: companyId,
        bulkUploadId,
        totalUploadEmployees: data?.total,
        totalSuccessEmployees: data?.total - (data?.errors?.length || 0),
        totalErrorEmployees: data?.errors?.length || 0,
        totalInvalidEmployees: 0,
      });
    }
  }, [bulkUploadId, bulkUploadQuery, companyId, track]);

  const responseData = bulkUploadQuery.data?.data || {};
  const totalFailed = responseData.errors?.length || 0;
  const totalEmployees = responseData.total || 0;
  const totalSuccess = totalEmployees - totalFailed || 0;

  return (
    <PageContent className="bigStack">
      <PageHeader
        title="Import Employees"
        backLink={{
          label: 'Back to upload',
          url: `/companies/${companyId}/employees-upload`,
        }}
      />
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: omniConfig.theme.radii.m,
          minHeight: 'calc(100vh - 15rem)',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {(bulkUploadQuery.isLoading || responseData?.state === 'pending') && (
          <LoadingContentWrapper>
            <LoaderIcon size={64} />
            <AlertTitle>Importing employees...</AlertTitle>
            <div>Please wait, this process might take a few minutes.</div>
            <AlertBanner>
              <AlertIcon />
              <div>
                Make sure you don’t navigate out of this page or close this tab
                whilst we import these employees.
              </div>
            </AlertBanner>
          </LoadingContentWrapper>
        )}
        {!bulkUploadQuery.isLoading && responseData.state !== 'pending' && (
          <>
            <TopContainer>
              <EmployeeValidationMessage
                type="success"
                message={`${totalSuccess} employees imported!`}
                description={`${totalSuccess} employees have been successfully imported to employees list.`}
              />

              {Boolean(totalFailed) && (
                <>
                  <Separator />
                  <EmployeeValidationMessage
                    type="error"
                    message={`${totalFailed} employees have not been imported`}
                    description={`${totalFailed} employees can’t be imported. Check what went wrong below.`}
                  />

                  <div style={{ marginTop: 24 }}>
                    <ErrorAccordion errors={responseData.errors} />
                  </div>
                </>
              )}
            </TopContainer>
            <BottomBar>
              <div>
                <Heading>{totalSuccess} employees imported!</Heading>
                {Boolean(totalFailed) && (
                  <SupplementaryContent>
                    {totalFailed} employees coudn’t be imported. You can try
                    again by uploading a new .csv file
                  </SupplementaryContent>
                )}
              </div>
              <div>
                <Button
                  palette="primary"
                  style={{ marginRight: 10 }}
                  onClick={() =>
                    history.push(`/companies/${companyId}/employees-upload`)
                  }
                >
                  Upload new file
                </Button>
                <Button
                  palette="primary"
                  onClick={() =>
                    history.push(`/companies/${companyId}#employees`)
                  }
                >
                  Finish Import
                </Button>
              </div>
            </BottomBar>
          </>
        )}
      </div>
    </PageContent>
  );
};
