import { AxiosError } from 'axios';
import { updateEmployeeContractStatus } from 'common/api/employees.api';
import { ContractStatus } from 'common/types';
import { useMutation } from 'react-query';

export type UpdatContractStatus = {
  employeeId: string;
  contractId: string;
  status: ContractStatus;
};

export const useUpdateContractStatusMutation = () => {
  return useMutation(
    ({ employeeId, contractId, status }: UpdatContractStatus) =>
      updateEmployeeContractStatus(employeeId, contractId, status),

    {
      onError: (error: AxiosError) => {
        throw new Error(
          `Unable to update contract status. Request failed with message: ${error.response?.data.message}`,
        );
      },
    },
  );
};
