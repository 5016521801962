import actionKeys, {
  CLEAR_COMPANIES,
  CLEAR_COMPANY,
  CLEAR_COMPANY_CREATED,
  CLEAR_CREATED_COMPANY_MANAGER_USER_ID,
} from './reduxConstants';

export const getCompaniesAction = () => ({
  type: actionKeys.GET_COMPANIES_START,
});

export const clearCompaniesAction = () => ({
  type: CLEAR_COMPANIES,
});

export const getCompanyAction = (payload) => ({
  type: actionKeys.GET_COMPANY_START,
  payload,
});

export const clearSelectedCompanyAction = () => ({
  type: CLEAR_COMPANY,
});

export const createCompanyAction = (payload) => ({
  type: actionKeys.CREATE_COMPANY_START,
  payload,
});

export const clearCompanyCreatedAction = () => ({
  type: CLEAR_COMPANY_CREATED,
});

export const clearCreatedCompanyManagerUserIdAction = () => ({
  type: CLEAR_CREATED_COMPANY_MANAGER_USER_ID,
});

export const updateCompanyAction = (payload) => ({
  type: actionKeys.UPDATE_COMPANY_START,
  payload,
});

export const getCompanyManagersAction = (payload) => ({
  type: actionKeys.GET_COMPANY_MANAGERS_START,
  payload,
});

export const getCompanyManagerAction = (payload) => ({
  type: actionKeys.GET_COMPANY_MANAGER_START,
  payload,
});

export const updateCompanyManagerAction = (payload) => ({
  type: actionKeys.UPDATE_COMPANY_MANAGER_START,
  payload,
});

export const addCompanyManagerAction = (payload) => ({
  type: actionKeys.CREATE_COMPANY_MANAGER_START,
  payload,
});
