import { useHistory } from 'react-router-dom';

import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import TableWithTitle from 'common/components/organisms/TableWithTitle/TableWithTitle';
import { PayrollProvider } from 'common/types';
import { getEditPayrollProviderPath, PROVIDERS_PP_ADD } from 'paths';
import { getCountryByCountryCode } from 'utils/countries';
import { useSelectUserRoles } from 'utils/hooks';
import { usePayrollProvidersQuery } from 'utils/queries/payrollProviders/usePayrollProvidersQuery';

import { Tag } from '@omnipresentgroup/design-system';

import { StyledTableCell } from './EmployersTab.styles';

const NameCustomCell = (
  payrollProvider: PayrollProvider,
  onColumnItemClick: (employerId: string) => void,
) => (
  <StyledTableCell onClick={() => onColumnItemClick(payrollProvider.id)}>
    {payrollProvider.name}
  </StyledTableCell>
);

const CountryCustomCell = ({ countryCode }: { countryCode: string }) => {
  const country = getCountryByCountryCode(countryCode)?.label;
  return <StyledTableCell>{country}</StyledTableCell>;
};

const ParentCustomCell = ({ parent }: { parent: PayrollProvider }) => {
  return parent?.name;
};

const IsActiveLabelCell = ({ isActive }: { isActive: string }) => {
  if (isActive) {
    return (
      <StyledTableCell>
        <Tag id="is-active-label-cell-tag" intent="success">
          Active
        </Tag>
      </StyledTableCell>
    );
  }
  return (
    <Tag id="is-active-label-cell-tag" intent="default">
      Inactive
    </Tag>
  );
};

export const tableHeaders = [
  {
    headerTitle: 'Name',
    customCell: NameCustomCell,
  },
  {
    headerTitle: 'Country',
    customCell: CountryCustomCell,
  },
  {
    headerTitle: 'Parent entity',
    customCell: ParentCustomCell,
  },
  {
    headerTitle: 'Active',
    customCell: IsActiveLabelCell,
  },
];

export const PayrollProvidersTab = () => {
  const history = useHistory();
  const { isIEAdmin } = useSelectUserRoles();
  const payrollProvidersQuery = usePayrollProvidersQuery();

  const onAddPayrollProviderClick = () => history.push(PROVIDERS_PP_ADD);
  const onPayrollProviderClick = (ppId: string) => {
    history.push(getEditPayrollProviderPath(ppId));
  };

  if (payrollProvidersQuery.isLoading) {
    return <Loading />;
  }

  if (payrollProvidersQuery.isError) {
    return <ErrorBanner errorMessage={payrollProvidersQuery.error.message} />;
  }

  return (
    <TableWithTitle
      title="Payroll providers"
      itemsToDisplay={payrollProvidersQuery.data || []}
      headers={tableHeaders}
      onCTAClicked={isIEAdmin ? onAddPayrollProviderClick : null}
      CTAText="Add payroll provider"
      showButton={isIEAdmin}
      testId="payroll-providers"
      onColumnItemClick={onPayrollProviderClick}
      onClick={() => payrollProvidersQuery?.data}
    />
  );
};
