import JoiImport from 'joi';

import DateExtension from '@omnipresentgroup/joi-date';

const Joi = JoiImport.extend(DateExtension);

export const schema = Joi.object().keys({
  title: Joi.string()
    .required()
    .messages({ 'string.empty': 'Please enter a title' }),
  description: Joi.string()
    .required()
    .messages({ 'string.empty': 'Please enter a description' }),
  category: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required(),
  })
    .required()
    .messages({
      'object.base': 'Please select a category',
      'any.required': 'Please select a category',
    }),
  amount: Joi.number().required().messages({
    'any.required': 'Please enter an amount',
    'number.base': 'Please enter an amount',
  }),
  currency: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required(),
  })
    .required()
    .messages({
      'object.base': 'Please select a currency',
      'any.required': 'Please select a currency',
    }),
  dateSpent: Joi.date()
    .min('2020-01-01')
    .max(Date.now())
    .format('YYYY-MM-DD')
    .required()
    .messages({
      'date.format': 'Please enter a date in the correct format',
      'date.min': 'Please enter a date that is closer to today.',
      'date.max': 'Please enter a date in the past',
      'date.empty': 'Please enter a date spent',
      'date.base': 'Please enter a date spent',
    }),
  documentLink: Joi.any(),
});
