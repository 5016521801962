import { StyledBoldCopy, StyledContent } from 'common/components/atoms/Text';
import {
  StyledModalButtons,
  StyledModalContainer,
  StyledModalContents,
} from 'common/components/molecules/ConfirmationPopup/ConfirmationPopup.styles';
import styled, { css } from 'styled-components';

export const Container = styled(StyledModalContainer)(() => {
  return css`
    transition: 180ms ease-in-out;
  `;
});

export const Wrapper = styled(StyledModalContents)(({ theme: { space } }) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    padding: ${space[16]};
    min-height: 392px;
  `;
});

export const Header = styled.header(({ theme: { space, radii } }) => {
  return css`
    text-align: center;

    > img {
      width: 160px;
      height: 160px;
      display: block;
      margin: auto;
      border-radius: ${radii.s};
      margin-bottom: ${space[32]};
    }
  `;
});

export const Title = styled(StyledBoldCopy)(() => {
  return css``;
});

export const Copy = styled(StyledContent)(() => {
  return css``;
});

export const ButtonFooter = styled(StyledModalButtons)(() => {
  return css`
    width: 100%;
  `;
});
