import styled from 'styled-components';

export const StyledCheck = styled.div`
  color: ${({ theme: { colors } }) => `${colors['icon-on-color']}`};
  > svg {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    vertical-align: middle;
  }
`;

export const BackGroundDivDrawer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme: { colors } }) => `${colors.overlay}`};
  z-index: 1;
`;

export const StepContainerDrawer = styled.div<{
  isOpen?: boolean;
  isCompleted?: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin: ${({ theme: { space } }) =>
    `${space[0]} ${space[0]} ${space[32]} ${space[32]}`};
  svg {
    margin-right: ${({ theme: { space } }) => space[0]};
  }
  > div {
    &:nth-child(1) {
      margin-top: ${(props) =>
        props.isOpen
          ? ({ theme: { space } }) => space[16]
          : ({ theme: { space } }) => space[0]};
    }

    margin: ${(props) =>
      props.isOpen
        ? ({ theme: { space } }) =>
            `${space[0]} ${space[0]} ${space[16]} ${space[0]}`
        : ({ theme: { space } }) => space[0]};
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    max-height: ${(props) => (props.isOpen ? '100%' : '0')};
    transition: all 0.3s;

    font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
    font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
    font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
    text-align: left;
    font-style: normal;
    color: ${(props) => {
      return props.isCompleted
        ? 'black'
        : ({ theme: { colors } }) => `${colors['text-subtle']}`;
    }};
    line-height: ${({ theme: { lineHeights } }) => lineHeights[0]};
    width: 100%;
  }
`;

export const DrawerContainer = styled.div`
  z-index: 2;
  background-color: ${({ theme: { colors } }) =>
    `${colors['background-primary']}`};
  position: fixed;
  height: 100%;
  top: ${({ theme: { space } }) => space[0]};
  right: ${({ theme: { space } }) => space[0]};
  width: 400px;
  overflow-y: auto;
  animation: showDrawer 0.5s;
  @keyframes showDrawer {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 400px;
    }
  }
`;

export const Title = styled.div`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[2]}`};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.medium}`};
  margin: 0;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`;

export const StyledStageNumberDrawer = styled.div<{
  isPartialCompleted?: boolean;
  isCompleted?: boolean;
}>`
  border-radius: ${({ theme: { radii } }) => radii.full};
  border-style: ${({ theme: { borderStyles } }) => borderStyles.solid};
  border-color: ${(props) => {
    return props.isPartialCompleted || props.isCompleted
      ? ({ theme: { colors } }) => `${colors['border-inverse']}`
      : ({ theme: { colors } }) => `${colors['border-subtle']}`;
  }};
  width: ${({ theme: { space } }) => space[16]};
  height: ${({ theme: { space } }) => space[16]};
  margin-right: ${({ theme: { space } }) => space[8]};
  padding: ${({ theme: { space } }) => space[4]};
  display: inline-block;
  color: ${(props) => {
    return props.isCompleted
      ? ({ theme: { colors } }) => `${colors['text-on-color']}`
      : ({ theme: { colors } }) => `${colors['text-default']}`;
  }};
  background-color: ${(props) => {
    if (props.isCompleted) {
      return ({ theme: { colors } }) => `${colors['background-inverse']}`;
    }
    return props.isPartialCompleted
      ? ({ theme: { colors } }) => `${colors['background-primary']}`
      : ({ theme: { colors } }) => `${colors['gray-200']}`;
  }};
  align-self: center;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
`;

export const StyledCompletedCircleDrawer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 0;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
`;

export const StyledEmployeeStarDate = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  margin-left: ${({ theme: { space } }) => space[32]};
  margin-top: ${({ theme: { space } }) => space[0]};
`;

export const StyledEmployeeName = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  margin-left: ${({ theme: { space } }) => space[32]};
  text-transform: capitalize;
`;

export const StyledStageDrawer = styled.span`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  text-transform: uppercase;
  font-style: normal;
  color: ${({ theme: { colors } }) => colors['text-default']};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  min-width: 200px;
  transition: all 0.2s;
  padding-top: ${({ theme: { space } }) => space[8]};
`;

export const StyledStepDrawer = styled.div<{
  isCompleted?: boolean;
  isOpen?: boolean;
  stageId?: number;
}>`
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  text-align: left;
  font-style: normal;
  color: ${(props) => {
    return props.isCompleted
      ? 'black'
      : ({ theme: { colors } }) => `${colors['text-subtle']}`;
  }};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[0]};
  width: 100%;
`;

export const CloseIconContainer = styled.div`
  color: ${({ theme: { colors } }) => `${colors['icon-default']}`};
`;

export const StyledDivider = styled.div`
  width: 100%;
  align-self: flex-start;
  height: 2px;
  background-color: ${({ theme: { colors } }) => `${colors['border-subtle']}`};
  margin-bottom: ${({ theme: { space } }) => `${space[8]}`};
  margin-top: ${({ theme: { space } }) => `${space[8]}`};
`;

export const LoadingSpinner = styled.div`
  ${({ theme: { colors, space, borderStyles } }) => `
  border: ${space[4]} ${borderStyles.solid} ${colors['border-subtle']};
  border-top: ${space[4]} ${borderStyles.solid} ${colors['gray-400']};
  width: 40px;
  height: 40px;
`}
  border-radius: ${({ theme: { radii } }) => radii.full};
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
