import { getManagersEmployeesRequest } from 'common/api/employees.api';
import { ONE_MINUTE } from 'common/constants';
import { transformSimplifiedEmployee } from 'common/store/transformers/employees.transformer';
import {
  SimplifiedEmployee,
  SimplifiedEmployeesApiResponse,
} from 'common/types';
import { useQuery, UseQueryOptions } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';
import { sortAlphabetically } from 'utils/sort.util';

import { employeesKeys } from '../keys';

export const useCompanyEmployeesQuery = (
  companyId: string,
  options?: Pick<UseQueryOptions, 'staleTime' | 'cacheTime' | 'enabled'>,
) => {
  return useQuery<SimplifiedEmployeesApiResponse, Error, SimplifiedEmployee[]>(
    employeesKeys.managerEmployees(companyId),
    () => getManagersEmployeesRequest(companyId).then(getDataFromResponse),
    {
      staleTime: options?.staleTime || ONE_MINUTE,
      select: (data) =>
        sortAlphabetically(
          'name',
          data.employees.map(transformSimplifiedEmployee),
        ),
      ...options,
    },
  );
};
