import { createCompanyManagerRequest } from 'common/api/companies.api';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useCreateNewManagerMutation = () => {
  return useMutation(
    ({
      companyId,
      managerDetails,
    }: {
      companyId: string;
      managerDetails: Record<string, unknown>;
    }) =>
      createCompanyManagerRequest(companyId, managerDetails).then(
        getDataFromResponse,
      ),
  );
};
