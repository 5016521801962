import { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { client } from 'api';
import { PageContent } from 'app/App.styles';
import { Loading } from 'common/components';
import Button from 'common/components/atoms/Button';
import {
  FileInState,
  FileUploadInput,
} from 'common/components/molecules/FileUploadInput/FileUploadInput';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { HireTypeEnum } from 'common/types/hire';
import { ADD_HIRE_PATH } from 'paths';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { useTrack } from 'utils/mixpanel/tracker';
import { v4 as uuidv4 } from 'uuid';

import { omniConfig } from '@omnipresentgroup/design-system';

import { bulkUploadReducer, emptyFile, State } from './reducer';
import { ReviewErrorPage } from './ReviewErrorPage/ReviewErrorPage';
import { BulkUploadValidationError } from './types';

const UploadContainer = styled.div`
  width: 50%;
  max-width: 600px;
`;

const TopContainer = styled.div`
  padding: ${({ theme: { space } }) => space[32]};
`;

const BottomBar = styled.div`
  border-top: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  padding: ${({ theme: { space } }) => space[32]};
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
`;

const Heading = styled.h2<{ marginTop?: string }>`
  color: ${({ theme: { colors } }) => colors['text-default']};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  font-weight: 600;
  margin-top: ${({ marginTop = 0 }) => marginTop};
`;

const SupplementaryContent = styled.p`
  color: ${({ theme: { colors } }) => colors['text-subtle']};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  margin-bottom: 0;
`;

const Paragraph = styled.p`
  color: ${({ theme: { colors } }) => colors['text-default']};
`;

const isReadyToSubmit = (files: State) =>
  !!Object.values(files).find(({ state }) => state !== 'success');

export const EmployeesBulkUploadPage = () => {
  const history = useHistory();
  const track = useTrack();
  const { companyId } = useParams<{ companyId: string }>();
  const [uploadId, setUploadId] = useState(uuidv4());
  const [state, dispatch] = useReducer(bulkUploadReducer, {});

  const {
    mutate: submitFiles,
    isLoading: reviewingFile,
    isError: hasReviewError,
    error: reviewErrors,
    isSuccess: migrationStarted,
    reset: resetReview,
  } = useMutation((uploadUrls: string[]) =>
    client.post('/employees/bulk-upload', {
      uploadUrls,
      companyId,
      uploadId,
    }),
  );

  useEffect(() => {
    dispatch({
      name: 'CreateAction',
      payload: emptyFile,
    });
  }, []);

  useEffect(() => {
    track('Import Employee BulkUpload Viewed', {
      clientId: companyId,
      bulkUploadId: uploadId,
    });
  }, [uploadId, companyId, track]);

  const uploadFile = ({ name }: File, id: string, numberOfRows: number) => {
    dispatch({
      name: 'UpdateAction',
      payload: {
        id,
        values: {
          fileName: name,
          numberOfEmployees: numberOfRows - 1,
        },
      },
    });
  };

  const updateFile = (id: string, values: Partial<FileInState>) => {
    dispatch({
      name: 'UpdateAction',
      payload: {
        id,
        values,
      },
    });
  };

  const clearFile = (id: string) => {
    dispatch({
      name: 'ClearAction',
      payload: id,
    });
  };

  if (migrationStarted) {
    history.push(`/companies/${companyId}/employees-upload/${uploadId}`);
  }

  const totalUploadEmployees = Object.values(state).reduce(
    (total, { numberOfEmployees }) => total + numberOfEmployees,
    0,
  );

  return (
    <PageContent className="bigStack">
      <PageHeader
        title="Import Employees"
        backLink={{
          label: 'Back to employees',
          url: `/companies/${companyId}#employees`,
        }}
      />
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: omniConfig.theme.radii.m,
          minHeight: 'calc(100vh - 15rem)',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {reviewingFile && <Loading />}
        {hasReviewError && (
          <>
            <ReviewErrorPage
              bulkUploadId={uploadId}
              companyId={companyId}
              goToUploadPage={() => {
                setUploadId(uuidv4());
                resetReview();
              }}
              totalEmployees={totalUploadEmployees}
              invalidEmployees={
                (reviewErrors as any)?.response
                  ?.data as BulkUploadValidationError
              }
            />
          </>
        )}
        {!reviewingFile && !hasReviewError && (
          <>
            <TopContainer>
              <Heading>Upload a .csv file to import employees</Heading>
              <Paragraph>
                You can import employees in bulk by uploading a .csv file below.
                Please ensure the files are correctly formatted as per these
                guidelines.
              </Paragraph>
              <UploadContainer>
                {Object.entries(state).map(([id, file]) => (
                  <FileUploadInput
                    {...file}
                    key={id}
                    fileTypes={['text/csv']}
                    uploadFile={uploadFile}
                    updateFile={updateFile}
                    id={id}
                    clear={() => {
                      clearFile(id);
                    }}
                  />
                ))}
              </UploadContainer>

              <Heading marginTop="8rem">Or add employees manually</Heading>
              <Button
                onClick={() =>
                  history.push(
                    `${ADD_HIRE_PATH(companyId)}?type=${HireTypeEnum.employee}`,
                  )
                }
                palette="primary"
                variant="outlined"
                style={{ marginTop: `${omniConfig.theme.space[16]}` }}
              >
                Add Employee
              </Button>
            </TopContainer>
            <BottomBar>
              <div>
                <Heading>{totalUploadEmployees} employees uploaded</Heading>
                <SupplementaryContent>
                  You might be asked to review some employees’ data in the next
                  step
                </SupplementaryContent>
              </div>
              <div>
                <Button
                  palette="primary"
                  disabled={isReadyToSubmit(state)}
                  onClick={() => {
                    submitFiles(
                      Object.values(state).map(({ url }) => url as string),
                    );
                    track('Import Employee BulkUpload Started', {
                      clientId: companyId,
                      bulkUploadId: uploadId,
                      totalUploadEmployees,
                    });
                  }}
                >
                  Review and add
                </Button>
              </div>
            </BottomBar>
          </>
        )}
      </div>
    </PageContent>
  );
};
