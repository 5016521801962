import styled from 'styled-components';

import { StyledBodyCopy, StyledBoldCopy } from '../../atoms/Text/Copy.styles';

export const StyledLocationOfWorkInput = styled.div`
  margin-top: ${({ theme: { space } }) => `${space[32]}`};
`;

export const StyledHeading = styled(StyledBoldCopy)`
  margin-bottom: ${({ theme: { space } }) => `${space[32]}`};

  &::after {
    content: ' *';
    display: ${(props) => (props.required ? 'inline' : 'none')};
  }
`;

export const StyledTypeLabel = styled(StyledBodyCopy)`
  margin-bottom: ${({ theme: { space } }) => `${space[16]}`};
`;
