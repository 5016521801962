import {
  APPROVED,
  APPROVED_DESCRIPTION,
  ARCHIVED,
  ARCHIVED_DESCRIPTION,
  ContractStatus,
  ContractStatusDescription,
  DRAFT,
  DRAFT_DESCRIPTION,
  PENDING_INTERNAL_REVIEW,
  PENDING_INTERNAL_REVIEW_DESCRIPTION,
  PENDING_REVIEW,
  PENDING_REVIEW_DESCRIPTION,
  PENDING_REVIEW_DESCRIPTION_CM,
  REVIEW_DONE,
  REVIEW_DONE_DESCRIPTION,
  SIGNED_BOTH_PARTIES,
  SIGNED_BOTH_PARTIES_DESCRIPTION,
  SIGNED_EMPLOYEE,
  SIGNED_EMPLOYEE_DESCRIPTION,
} from 'common/types';
import { useSelectUserRoles } from 'utils/hooks/redux-selectors';

export function useStatusDescription(
  status?: ContractStatus,
): ContractStatusDescription | null {
  const isManagerRole = useSelectUserRoles().isManager;

  switch (status) {
    case PENDING_INTERNAL_REVIEW:
      return PENDING_INTERNAL_REVIEW_DESCRIPTION;
    case APPROVED:
      return APPROVED_DESCRIPTION;
    case ARCHIVED:
      return ARCHIVED_DESCRIPTION;
    case DRAFT:
      return DRAFT_DESCRIPTION;
    case SIGNED_BOTH_PARTIES:
      return SIGNED_BOTH_PARTIES_DESCRIPTION;
    case SIGNED_EMPLOYEE:
      return SIGNED_EMPLOYEE_DESCRIPTION;
    case REVIEW_DONE:
      return REVIEW_DONE_DESCRIPTION;
    case PENDING_REVIEW:
      if (isManagerRole) {
        return PENDING_REVIEW_DESCRIPTION_CM;
      }
      return PENDING_REVIEW_DESCRIPTION;
    default:
      return null;
  }
}
