import { asyncActionKeys, SET_FILE_INFO } from './redux.constants';

export const uploadDocumentAction = (documentInfo) => ({
  type: asyncActionKeys.UPLOAD_DOCUMENT_START,
  payload: documentInfo,
});

export const setFileInfoAction = (fileInfo) => ({
  type: SET_FILE_INFO,
  payload: fileInfo,
});
