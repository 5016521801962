import { getUserSelector } from 'app/store/app.selectors';
import { useSelector } from 'react-redux';
import { useCompanyEmployeesQuery } from 'utils/queries/employees/useCompanyEmployeesQuery';
import { isManager } from 'utils/user';

export const useEmployeesForManagerUser = (companyId: string) => {
  const user = useSelector(getUserSelector);
  const canFetchEmployees = isManager(user);
  return useCompanyEmployeesQuery(companyId, {
    enabled: canFetchEmployees && !!companyId,
  });
};
