import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

const activeTab = css`
  color: ${({ theme: { colors } }) => colors['text-default']};
`;

const inactiveTab = css`
  color: ${({ theme: { colors } }) => colors['text-subtle']};
`;

export const StyledTabTitle = styled(Link)<{
  $currentTab: boolean;
  disabled: boolean;
  $loading: boolean;
}>`
  cursor: pointer;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
  text-decoration: none;
  padding-right: ${({ theme: { space } }) => space[8]};
  ${(props) => (props.$currentTab ? activeTab : inactiveTab)};

  div {
    margin-top: ${({ theme: { space } }) => space[4]};
  }

  ${({ disabled: isDisabled, $loading: isLoading }) =>
    (isDisabled || isLoading) &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      position: relative;
      outline: 0;

      /* Using pointer-events: none will disable all mouse interactions of an element.
         pointer-events should be applied only with active pseudoclass, in order to disable click events
         and maintain cursor property which rely on hover event
      */
      &:active {
        pointer-events: none;
      }
    `}
`;
