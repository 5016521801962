import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const AddEmployeeExpensePage = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme: { space } }) => space[32]};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
`;

export const ContentWrapper = styled.div`
  padding: ${({ theme: { space } }) => space[16]};
  background: ${({ theme: { colors } }) => colors['background-primary']};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  border-radius: ${({ theme: { radii } }) => radii.s};

  @media (min-width: ${tokens.breakpoint.laptop}) {
    padding: ${({ theme: { space } }) => space[32]};
  }
`;
