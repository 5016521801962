import { ChangeEvent } from 'react';

import { SimplifiedEmployee } from 'common/types';

import { List, ListColumn } from '../List/List';
import { CheckboxCell } from './CheckBoxCell';
import { CountryWithFlagCell } from './CountryWithFlagCell';

export type AssociatedEmployee = {
  isAssociated: boolean;
} & Pick<SimplifiedEmployee, 'userId' | 'name' | 'jobTitle' | 'country'>;

export type EmployeesListProps = {
  items: AssociatedEmployee[];
  hasCheckboxSelection?: boolean;
  onCheckboxChange?: (
    event: ChangeEvent<HTMLInputElement>,
    model: AssociatedEmployee[],
  ) => void;
};

const defaultEmployeesHeaders: ListColumn[] = [
  {
    headerTitle: 'Name',
    fieldOnObject: 'name',
  },
  {
    headerTitle: 'Role',
    fieldOnObject: 'jobTitle',
  },
  {
    headerTitle: 'Country of work',
    fieldOnObject: 'country',
    customCell: CountryWithFlagCell,
  },
];

const headersWithCheckbox: ListColumn[] = [
  {
    headerTitle: '',
    customCell: CheckboxCell,
  },
  ...defaultEmployeesHeaders,
];

const defaultEmployeesItemKeyExtractor = (
  item: Record<string, unknown>,
): string => item.userId as string;

export const EmployeesList = ({
  items,
  hasCheckboxSelection,
  onCheckboxChange,
}: EmployeesListProps) => {
  return (
    <List
      items={items}
      columns={
        hasCheckboxSelection ? headersWithCheckbox : defaultEmployeesHeaders
      }
      itemKeyExtractor={defaultEmployeesItemKeyExtractor}
      hasCheckboxSelection={hasCheckboxSelection}
      customCellCallback={onCheckboxChange}
    />
  );
};
