import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { joiResolver } from '@hookform/resolvers/joi';
import { PageContent } from 'app/App.styles';
import { queryClient } from 'app/queryClient';
import { AxiosError, AxiosResponse } from 'axios';
import { createTerminationRequest } from 'common/api/terminations.api';
import { ErrorBanner, Loading } from 'common/components';
import {
  FileInState,
  FileUploadInput,
} from 'common/components/molecules/FileUploadInput/FileUploadInput';
import { getEmployeeAction } from 'common/store/actions/employee.actions';
import { getSelectedEmployeeSelector } from 'common/store/selectors/employees.selector';
import { FormData, User } from 'common/types';
import {
  PostTerminationRequestModel,
  TerminationRequestModel,
} from 'common/types/terminations';
import { EMPLOYEES_PATH, getEmployeeListPath } from 'paths';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useSelectUserRoles } from 'utils/hooks';
import { useCompanyManagersQuery } from 'utils/queries';
import { terminationRequestsKeys } from 'utils/queries/employees/terminationRequests/keys';

import {
  Box,
  Button,
  DateFormats,
  DateInput,
  Dropdown,
  formatJSDate,
  Inline,
  Input,
  Link,
  Notification,
  Split,
  Stack,
  TextArea,
  Typography,
} from '@omnipresentgroup/design-system';

import {
  questions,
  removeUndefinedValues,
  terminationFormValidationSchema,
  THIRTY_DAYS_FROM_NOW,
} from './terminationForm.helper';
import { TerminationFormExplainerSection } from './TerminationFormExplainerSection/TerminationFormExplainerSection';
import { TerminationRequestSuccessModal } from './TerminationRequestSuccessModal/TerminationRequestSuccessModal';

const DEFAULT_FILE_INPUT_STATE: FileInState = {
  id: 'terminationSupportDocumentation',
  fileName: '',
  progress: 0,
  state: 'pending',
};

export const TerminationRequestForm = () => {
  const dispatch = useDispatch();
  const employee = useSelector(getSelectedEmployeeSelector);
  const history = useHistory();
  const { isManager } = useSelectUserRoles();
  const { companyId, employeeId } = useParams<{
    companyId: string;
    employeeId: string;
  }>();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    getValues,
  } = useForm<FormData>({
    resolver: joiResolver(terminationFormValidationSchema),
    shouldFocusError: true,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const watchFields = watch();
  const { isEmployeeOPManager, terminationReason, hasDisputesWithEmployee } =
    watchFields;

  const [fileData, setFileData] = useState<FileInState>(
    DEFAULT_FILE_INPUT_STATE,
  );

  useEffect(() => {
    dispatch(getEmployeeAction(employeeId));
  }, [dispatch, employeeId]);

  useEffect(() => {
    if (isEmployeeOPManager?.value === 'no') {
      reset({ ...getValues(), employeeOPManagerProfile: undefined });
    }
    if (terminationReason?.value !== 'other') {
      reset({ ...getValues(), detailsOfTerminationReason: undefined });
    }
    if (hasDisputesWithEmployee?.value === 'no') {
      reset({ ...getValues(), detailsOfDisputesWithEmployee: undefined });
    }
  }, [
    isEmployeeOPManager?.value,
    terminationReason?.value,
    hasDisputesWithEmployee?.value,
    reset,
    getValues,
  ]);

  const {
    data: companyManagersData = [],
    isLoading: isLoadingCompanyManagers,
  } = useCompanyManagersQuery({
    companyId: companyId,
  });

  const {
    isLoading,
    reset: onErrorBannerReset,
    isError,
    isSuccess,
    error,
    mutate: onSubmit,
  } = useMutation<
    AxiosResponse<TerminationRequestModel>,
    AxiosError,
    PostTerminationRequestModel
  >(
    (newTerminationRequest: PostTerminationRequestModel) =>
      createTerminationRequest(newTerminationRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          terminationRequestsKeys.allTerminationRequests(companyId),
        );
      },
    },
  );

  const onClickCancel = () => {
    const redirectUrl = isManager
      ? `${EMPLOYEES_PATH}#employees`
      : getEmployeeListPath(companyId);
    history.push(redirectUrl);
  };

  const formSubmitHandler = handleSubmit((data: Record<string, any>) => {
    const fileUrl = fileData?.url ?? '';

    const terminationRequestPayload: PostTerminationRequestModel = {
      companyId,
      employeeId,
      ...(employee?.currentEmploymentId && {
        employmentId: employee?.currentEmploymentId,
      }),
      ...(data?.employeeOPManagerProfile?.value && {
        employeeManagerId: data?.employeeOPManagerProfile?.value,
      }),
      answers: {
        isEmployeeAwareOfTermination: {
          selectedAnswerKeys: data?.isEmployeeAwareOfTermination?.value,
        },
        terminationReason: {
          selectedAnswerKeys: data?.terminationReason?.value,
        },
        detailsOfTerminationReason: {
          inputAnswers: data?.detailsOfTerminationReason,
        },
        hasDisputesWithEmployee: {
          selectedAnswerKeys: data?.hasDisputesWithEmployee?.value,
        },
        detailsOfDisputesWithEmployee: {
          inputAnswers: data?.detailsOfDisputesWithEmployee,
        },
        lastWorkingDayPreference: {
          inputAnswers: formatJSDate(
            new Date(data?.lastWorkingDayPreference),
            DateFormats.FixedDate,
            true,
          ),
        },
        lastEmploymentDatePreference: {
          inputAnswers: formatJSDate(
            new Date(data?.lastEmploymentDatePreference),
            DateFormats.FixedDate,
            true,
          ),
        },
        employeeNonHolidayLeaves: {
          selectedAnswerKeys: data?.employeeNonHolidayLeaves.map(
            (holidayLeave: { label: string; value: string }) =>
              holidayLeave.value,
          ),
        },
        numberOfVacationDaysBeforeLastEmploymentDate: {
          inputAnswers: data?.numberOfVacationDaysBeforeLastEmploymentDate,
        },
        overtimeOwed: {
          selectedAnswerKeys: data?.overtimeUnits?.value,
          inputAnswers: data?.overtimeOwed,
        },
        numberOfDaysOwed: {
          inputAnswers: data?.numberOfDaysOwed,
        },
        terminationSupportDocumentation: {
          inputAnswers: [fileUrl],
        },
      },
    };

    removeUndefinedValues(terminationRequestPayload);

    onSubmit(terminationRequestPayload);
  });

  if (isLoadingCompanyManagers) {
    return <Loading />;
  }

  const managersEmails = companyManagersData.map((manager: User) => ({
    label: manager.email,
    value: manager.userId,
  }));

  return (
    <>
      {isSuccess && (
        <TerminationRequestSuccessModal
          name={employee?.name}
          companyId={companyId}
        />
      )}

      <PageContent>
        {isLoading && <Loading />}
        {isError && (
          <ErrorBanner
            onErrorClear={() => onErrorBannerReset && onErrorBannerReset()}
            errorMessage={error?.message}
          />
        )}
        <Stack gap="32">
          <Typography as="h1" hideParagraphSpacing size="24" weight="medium">
            Request Termination For: {employee?.name}
          </Typography>
          <Box>
            <Button
              variant="secondary"
              icon="ChevronLeft"
              onClick={onClickCancel}
            >
              Back to employees list
            </Button>
          </Box>
          <Notification
            id="termination-request-info"
            title="Note"
            description="After you submit the form below, Omnipresent will contact you (the manager). We will NOT notify the employee of the termination until all parties agree to do so."
          />
          <TerminationFormExplainerSection />
          <Stack gap="32" depth="m" p="32" bg="primary">
            <Box>
              <Typography
                as="h2"
                hideParagraphSpacing
                size="24"
                weight="medium"
              >
                Fill in the termination request form
              </Typography>
              <Typography as="p" hideParagraphSpacing size="16">
                {`Please check the employee's employment contract for accurate information.`}
              </Typography>
            </Box>
            <Split gap="64" fraction="1/2">
              <Input
                id="employeeName"
                label="Employee Name"
                readOnly
                value={employee?.name || ''}
              />
            </Split>
            <Split gap="64" fraction="1/2">
              <Input
                id="employeeCountry"
                label="Country where employee holds his employment contract"
                readOnly
                value={employee?.country || ''}
              />
            </Split>
            <Split gap="64" fraction="1/2">
              <Controller
                name="isEmployeeOPManager"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    id="isEmployeeOPManager"
                    label="Does the employee have manager permissions or super admin access on the OmniPlatform?"
                    placeholder="Select the answer"
                    required
                    isSearchable={false}
                    options={questions.defaultAnswers.answers}
                    errorMessage={errors['isEmployeeOPManager']?.message}
                    {...field}
                  />
                )}
              />
              <Stack justify="center">
                <Typography as="label" size="14" weight="medium">
                  Why do I need to answer this?
                </Typography>
                <Typography as="span" size="14">
                  {`If the employee has `}
                  <Link
                    to="https://intercom.help/omnipresent/en/articles/6662336-omniplatform-manager-permissions"
                    target="_blank"
                    rel="noreferrer"
                    size="14"
                  >
                    manager permissions
                  </Link>
                  {` or super admin access on OmniPlatform, we must hide this
                termination request from them. If you answer yes, you will be
                asked to select their manager / super admin login email address.`}
                </Typography>
              </Stack>
            </Split>
            <Split gap="64" fraction="1/2">
              {isEmployeeOPManager?.value === 'yes' && (
                <Controller
                  name="employeeOPManagerProfile"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id="employeeOPManagerProfile"
                      label="If yes, which manager or super admin login belongs to the employee?"
                      placeholder="Select the answer"
                      required
                      isSearchable={false}
                      options={managersEmails}
                      errorMessage={errors['employeeOPManagerProfile']?.message}
                      {...field}
                    />
                  )}
                />
              )}
            </Split>
            <Split gap="64" fraction="1/2">
              <Controller
                name="isEmployeeAwareOfTermination"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    id="isEmployeeAwareOfTermination"
                    label="Is the employee aware of the termination?"
                    placeholder="Select the answer"
                    required
                    isSearchable={false}
                    options={questions.defaultAnswers.answers}
                    errorMessage={
                      errors['isEmployeeAwareOfTermination']?.message
                    }
                    {...field}
                  />
                )}
              />
            </Split>
            <Split gap="64" fraction="1/2">
              <Controller
                name="terminationReason"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    id="terminationReason"
                    label="What is the reason for the termination?"
                    placeholder="Select the answer"
                    required
                    isSearchable={false}
                    options={questions.terminationReason.answers}
                    errorMessage={errors['terminationReason']?.message}
                    {...field}
                  />
                )}
              />
              <Stack justify="center">
                <Typography as="label" size="14" weight="medium">
                  Reasons for termination explained
                </Typography>
                <Typography as="span" size="14">
                  <Typography as="span" weight="medium" size="14">
                    {`Poor performance: `}
                  </Typography>
                  Employee shows incompetence in performing job duties as
                  expected and shows no improvement even after feedback and
                  guidance.
                </Typography>
                <Typography as="span" size="14">
                  <Typography as="span" weight="medium" size="14">
                    {`Business reason: `}
                  </Typography>
                  Economic, technical and organizational reasons.
                </Typography>
                <Typography as="span" size="14">
                  <Typography as="span" weight="medium" size="14">
                    {`Did not pass probation: `}
                  </Typography>
                  Performance issues identified during the period of employee
                  probation as mentioned in the employment contract.
                </Typography>
                <Typography as="span" size="14">
                  <Typography as="span" size="14" weight="medium">
                    {`Misconduct: `}
                  </Typography>
                  A willful breach of obligations by an employee or a violation
                  of ethics and code of conduct.
                </Typography>
                <Typography as="span" size="14">
                  <Typography as="span" size="14" weight="medium">
                    {`Changing EOR provider: `}
                  </Typography>
                  Employees are being moved to an alternative EOR provider and
                  therefore need to be terminated.
                </Typography>
                <Typography as="span" size="14">
                  <Typography as="span" size="14" weight="medium">
                    {`Moving to own entity: `}
                  </Typography>
                  Employees are being moved to an entity that belongs to the
                  client.
                </Typography>
                <Typography as="span" size="14">
                  <Typography as="span" size="14" weight="medium">
                    {`Other: `}
                  </Typography>
                  Anything else that cannot be merged with the other categories.
                </Typography>
              </Stack>
            </Split>
            <Split gap="64" fraction="1/2">
              {terminationReason?.value === 'other' && (
                <Controller
                  name="detailsOfTerminationReason"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      id="detailsOfTerminationReason"
                      label="Please provide details"
                      placeholder="Please provide details"
                      required
                      errorMessage={
                        errors['detailsOfTerminationReason']?.message
                      }
                      {...field}
                    />
                  )}
                />
              )}
            </Split>
            <Split gap="64" fraction="1/2">
              <Controller
                name="hasDisputesWithEmployee"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    id="hasDisputesWithEmployee"
                    label="Do you have any current disputes with the employee?"
                    placeholder="Select the answer"
                    required
                    isSearchable={false}
                    options={questions.defaultAnswers.answers}
                    errorMessage={errors['hasDisputesWithEmployee']?.message}
                    {...field}
                  />
                )}
              />
              <Stack justify="center">
                <Typography as="label" size="14" weight="medium">
                  Current disputes
                </Typography>
                <Typography as="span" size="14">
                  Terminating an employee who has an existing grievance, ongoing
                  disciplinary case, unresolved allegations is risky. This can
                  be seen as an act of retaliation against the employee and
                  maybe considered unlawful in court.
                </Typography>
              </Stack>
            </Split>
            <Split gap="64" fraction="1/2">
              {hasDisputesWithEmployee?.value === 'yes' && (
                <Controller
                  name="detailsOfDisputesWithEmployee"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      id="detailsOfDisputesWithEmployee"
                      label="Please provide details"
                      required
                      errorMessage={
                        errors['detailsOfDisputesWithEmployee']?.message
                      }
                      {...field}
                    />
                  )}
                />
              )}
            </Split>
            <Split gap="64" fraction="1/2">
              <Controller
                name="lastWorkingDayPreference"
                control={control}
                render={({ field }) => (
                  <DateInput
                    id="lastWorkingDayPreference"
                    dateFormat={DateFormats.FixedDate}
                    placeholderText={DateFormats.FixedDate.toLocaleLowerCase()}
                    minDate={THIRTY_DAYS_FROM_NOW}
                    label="What is your preference for the employee's last working day?"
                    required
                    errorMessage={errors['lastWorkingDayPreference']?.message}
                    {...field}
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                  />
                )}
              />
              <Stack justify="center">
                <Typography as="label" size="14" weight="medium">
                  Please select a date at least 30 days from the date of
                  submission.
                </Typography>
                <Typography as="span" size="14">
                  {`Our terms require that 'all terminations must be carried out in accordance 
                  with local law.' Rushed terminations put a high degree of risk on both you 
                  and Omnipresent. Because of this risk and to comply with our obligation to 
                  terminate employees in a compliant way, we require 30 days notice before 
                  termination. We always strive to work faster where possible. However, 
                  compliant terminations are complicated and cannot be rushed.`}
                </Typography>
              </Stack>
            </Split>
            <Split gap="64" fraction="1/2">
              <Controller
                name="lastEmploymentDatePreference"
                control={control}
                render={({ field }) => (
                  <DateInput
                    id="lastEmploymentDatePreference"
                    dateFormat={DateFormats.FixedDate}
                    placeholderText={DateFormats.FixedDate.toLocaleLowerCase()}
                    minDate={THIRTY_DAYS_FROM_NOW}
                    label="On what day do you prefer the employee to stop getting paid?"
                    required
                    errorMessage={
                      errors['lastEmploymentDatePreference']?.message
                    }
                    {...field}
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                  />
                )}
              />
              <Stack justify="center">
                <Typography as="label" size="14" weight="medium">
                  We will confirm the effective date of termination on the basis
                  of statutory and/or contractual requirements applicable in the
                  employee&apos;s jurisdiction.
                </Typography>
                <Typography as="span" size="14">
                  Please note that timelines are subject to any mandatory
                  internal processes. You will be able to finalize the timeline
                  with your offboarding coordinator after your submission of
                  this form has been reviewed.
                </Typography>
              </Stack>
            </Split>
            <Split gap="64" fraction="1/2">
              <Controller
                name="employeeNonHolidayLeaves"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    id="employeeNonHolidayLeaves"
                    label="Is the employee currently or scheduled to any time off other than vacation leave?"
                    placeholder="Select the answer"
                    required
                    isMulti
                    isSearchable={false}
                    options={questions.employeeNonHolidayLeaves.answers}
                    errorMessage={errors['employeeNonHolidayLeaves']?.message}
                    {...field}
                  />
                )}
              />
            </Split>
            <Split gap="64" fraction="1/2">
              <Input
                id="numberOfVacationDaysBeforeLastEmploymentDate"
                type="number"
                placeholder="Enter the answer"
                label="How many vacation days has the employee taken or planned to take before the date he/she stops getting paid?"
                required
                errorMessage={
                  errors['numberOfVacationDaysBeforeLastEmploymentDate']
                    ?.message
                }
                {...register('numberOfVacationDaysBeforeLastEmploymentDate')}
              />
            </Split>
            <Split gap="64" fraction="1/2">
              <Input
                id="numberOfDaysOwed"
                type="number"
                placeholder="Enter the answer"
                label="How many vacation days are owed to this employee (if any)?"
                errorMessage={errors['numberOfDaysOwed']?.message}
                {...register('numberOfDaysOwed')}
              />
              <Stack justify="center">
                <Typography as="label" size="14" weight="medium">
                  Vacation days owed
                </Typography>
                <Typography as="span" size="14">
                  To calculate this, please refer back to the employment
                  contract for this employee.
                </Typography>
              </Stack>
            </Split>
            <Box>
              <Typography as="label" size="14" weight="medium">
                How much overtime is owed to this employee (if any)?
              </Typography>
              <Split gap="64" fraction="1/2">
                <Inline stretch="all">
                  <Box maxW={135}>
                    <Controller
                      name="overtimeUnits"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          id="overtimeUnits"
                          label=""
                          placeholder="Select"
                          isSearchable={false}
                          options={questions.overtimeUnits.answers}
                          errorMessage={errors['overtimeUnits']?.message}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Input
                      id="overtimeOwed"
                      type="number"
                      label=""
                      errorMessage={errors['overtimeOwed']?.message}
                      placeholder="Enter the answer"
                      {...register('overtimeOwed')}
                    />
                  </Box>
                </Inline>
              </Split>
            </Box>
            <Split gap="64" fraction="1/2">
              <Stack>
                <Typography as="label" size="14" weight="medium">
                  If you have documentation to support the termination, please
                  upload it here
                </Typography>
                <FileUploadInput
                  id={fileData.id}
                  fileName={fileData.fileName}
                  progress={fileData?.progress}
                  state={fileData?.state}
                  url={fileData}
                  directory={`terminations/${companyId}/${employeeId}`}
                  uploadFile={(file) => {
                    setFileData((fileData) => ({
                      ...fileData,
                      fileName: file.name,
                    }));
                  }}
                  updateFile={(_, data) =>
                    setFileData((fileData) => ({
                      ...fileData,
                      state: data.state || fileData.state,
                      url: data.url,
                      progress: data.progress || fileData?.progress,
                    }))
                  }
                  clear={() => setFileData(DEFAULT_FILE_INPUT_STATE)}
                />
              </Stack>
              <Stack justify="start">
                <Typography as="label" size="14" weight="medium">
                  Examples of supporting documentation
                </Typography>
                <Typography as="span" size="14">
                  Performance review, feedback, relevant email correspondence,
                  PDF documents, data from IT, evidence screenshots (as
                  applicable).
                </Typography>
              </Stack>
            </Split>
            <Typography as="span" size="16">
              You will be contacted by a member of our team after submitting
              this termination request.
            </Typography>
            <Inline justify="space-between">
              <Button variant="secondary" onClick={onClickCancel}>
                Cancel
              </Button>
              <Button
                onClick={formSubmitHandler}
                loading={isLoading}
                disabled={Object.keys(errors).length > 0}
              >
                Submit termination request
              </Button>
            </Inline>
          </Stack>
        </Stack>
      </PageContent>
    </>
  );
};
