import {
  ManualGrossToNetPayload,
  uploadManualGrossToNet,
} from 'common/api/payrollTaxonomy.api';
import { useMutation } from 'react-query';

export const useGrossToNetManualFileMutation = () => {
  return useMutation((data: ManualGrossToNetPayload) =>
    uploadManualGrossToNet(data),
  );
};
