/* eslint-disable no-shadow */

export enum ContractorInvoiceStateEnum {
  CANCELLED = 'CANCELLED',
  IN_PROGRESS = 'IN_PROGRESS',
  OPEN = 'OPEN',
  PAID = 'PAID',
  PENDING = 'PENDING',
}

export enum PermittedInvoiceCurrenciesEnum {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
}

/**
 * API
 */

export type ContractorInvoiceAPI = {
  id: string;
  state: ContractorInvoiceStateEnum;
  contractor: {
    id: string;
    name: string;
  };
  client: {
    id: string;
    name: string;
  };
  amount: number;
  currency: PermittedInvoiceCurrenciesEnum;
  due_date: string;
  description: string;
  raised_at: string;
  paid_at: string;
  overdue_by: string;
};

export type ContractorInvoiceDetailsAPI = ContractorInvoiceAPI & {
  invoice_ref: string;
};

export type GetContractorInvoicesResponseAPI = {
  items: ContractorInvoiceAPI[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

export type GetContractorInvoiceResponseAPI = ContractorInvoiceDetailsAPI;

/**
 * Model
 */

export type ContractorInvoice = {
  id: string;
  state: ContractorInvoiceStateEnum;
  contractor: {
    id: string;
    name: string;
  };
  client: {
    id: string;
    name: string;
  };
  amount: number;
  currency: PermittedInvoiceCurrenciesEnum;
  dueDate: string;
  description: string;
  raisedAt: string;
  paidAt: string;
  overdueBy: string;
};

export type ContractorInvoiceDetails = ContractorInvoice & {
  invoiceRef: string;
};

export type GetContractorInvoicesResponse = {
  items: ContractorInvoice[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

export type GetContractorInvoiceResponse = ContractorInvoiceDetails;

/**
 * Mappers
 */

export const transformContractorInvoiceFromAPI = ({
  id,
  state,
  contractor,
  client,
  amount,
  currency,
  due_date,
  description,
  raised_at,
  paid_at,
  overdue_by,
}: ContractorInvoiceAPI): ContractorInvoice => ({
  id,
  state,
  contractor: { ...contractor },
  client: { ...client },
  amount,
  currency,
  dueDate: due_date,
  description,
  raisedAt: raised_at,
  paidAt: paid_at,
  overdueBy: overdue_by,
});

export const transformContractorInvoiceDetailsFromAPI = ({
  id,
  state,
  contractor,
  client,
  amount,
  currency,
  due_date,
  description,
  raised_at,
  paid_at,
  overdue_by,
  invoice_ref,
}: ContractorInvoiceDetailsAPI): ContractorInvoiceDetails => ({
  ...transformContractorInvoiceFromAPI({
    id,
    state,
    contractor,
    client,
    amount,
    currency,
    due_date,
    description,
    raised_at,
    paid_at,
    overdue_by,
  }),
  invoiceRef: invoice_ref,
});

export const transformGetContractorInvoicesResponseFromAPI = ({
  items,
  total,
  page,
  size,
  pages,
}: GetContractorInvoicesResponseAPI): GetContractorInvoicesResponse => ({
  items: items.map((item) => transformContractorInvoiceFromAPI(item)),
  total,
  page,
  size,
  pages,
});
