import {
  StyledLabel,
  StyledRadioInput,
  StyledWrapper,
} from './RadioInput.styles';

export type RadioInputProps = {
  name: string;
  options: { label: string; value: string; checked?: boolean }[];
  label: string;
  onChange?: (data: string) => void;
} & Partial<HTMLInputElement>;

export const RadioInput = ({
  onChange,
  name,
  options,
  label,
  ...restProps
}: RadioInputProps) => {
  const changeHandler = (event: any) => {
    onChange && onChange(event?.target?.value);
  };

  return (
    <div className="stack">
      <label htmlFor={name}>{label}</label>
      {options?.map((option: any, index: number) => (
        <StyledWrapper key={`${name}-${option.label}`}>
          <StyledRadioInput
            className="radioButton"
            name={name}
            type="radio"
            id={`${name}-${index}-${option.label}`}
            value={option.value}
            onChange={changeHandler}
            checked={Boolean(option.checked)}
            {...restProps}
          />
          <StyledLabel htmlFor={`${name}-${index}-${option.label}`}>
            {option.label}
          </StyledLabel>
        </StyledWrapper>
      ))}
    </div>
  );
};
