import { client } from '../../api';

export const getUserRequest = (userAuthId) =>
  client.get(`/users/${encodeURIComponent(userAuthId)}`);

export const updateUserRequest = (userId, updateObject) =>
  client.patch(`/users/${encodeURIComponent(userId)}`, updateObject);

export const verifyEmailChangeTokenRequest = (userId, token) =>
  client.get(`/users/${encodeURIComponent(userId)}/emails/verify/${token}`);

export const updateUserEmailRequest = (userId, newEmail) =>
  client.post(`/users/${encodeURIComponent(userId)}/emails`, {
    email: newEmail,
  });

export const confirmUserEmailUpdateRequest = (userId, token) =>
  client.post(`/users/${encodeURIComponent(userId)}/emails/verify/${token}`);

export const sendPlatformInvitationRequest = (
  userId,
  isManagerPermissions = false,
) =>
  client.post(`/users/${userId}/invite`, {
    isManagerPermissions,
  });
