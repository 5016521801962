import styled, { css } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledContainer = styled.div(
  (props) => css`
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;

    @media (max-width: ${tokens.breakpoint.tablet}) {
      margin-top: ${({ theme: { space } }) => space[8]};
    }

    &::before {
      content: 'You can scroll vertically and horizontally';
      background: ${({ theme: { colors } }) => colors['gray-400']};
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      opacity: 0;
      animation: ${props['aria-modal'] ? 'dimBg linear 3s 1' : 'unset'};
      animation-delay: 1s;
      text-align: center;
      padding-top: 80px;
      font-size: ${({ theme: { fontSizes } }) => fontSizes[4]};
      color: white;
      font-weight: 500;
      z-index: 0;
    }

    @keyframes dimBg {
      5%,
      95% {
        z-index: 3;
        opacity: 1;
      }
    }
  `,
);

export const CompareTableContainer = styled.div`
  width: calc(100% + 4px);
  height: 100%;
  overflow: auto;
  position: fixed;
  z-index: 4;
`;

export const CompareTable = styled.table(
  ({
    theme: {
      colors,
      borderStyles,
      borderWidths,
      fontSizes,
      shadows,
      space,
      radii,
    },
  }) => css`
    border: unset;
    border-spacing: ${borderWidths.m} 0;
    width: max-content;
    table-layout: fixed;
    margin-bottom: 260px;
    background: ${colors['gray-100']};
    position: relative;
    left: -2px;
    padding-bottom: 3rem;

    @media (min-width: ${tokens.breakpoint.tablet}) {
      padding-right: 14.5rem;
    }

    @media (min-width: ${tokens.breakpoint.laptop}) {
      padding-right: 20rem;
    }

    @media (max-width: ${tokens.breakpoint.tablet}) {
      padding-right: ${space[32]};
    }

    & th,
    td {
      border: ${borderWidths.s} ${colors['border-subtle']};
      border-style: ${borderStyles.none} ${borderStyles.solid}
        ${borderStyles.none} ${borderStyles.solid};
      background: ${colors['background-primary']};
      text-align: left;
      padding: ${`${space[8]} ${space[16]}`};
      box-sizing: border-box;
      height: auto;

      &.empty-field {
        background: ${colors['gray-100']} !important;
        box-shadow: unset;
      }

      .country-dropdown > div > div:first-of-type {
        padding-top: 0;
        padding-bottom: 0;
        height: 40px;
      }

      &.collapse {
        display: none;
      }

      &.more-info {
        cursor: pointer;

        &:hover {
          /* No provision for background opacity without affecting content, hence the 7F. It sets 50% opacity on the bg color */
          background-color: ${tokens.color.global.blue[100] + '7F'} !important;
        }
      }
    }

    & tr {
      &.compare-item-row {
        th {
          background: ${colors['blue-100']};
          cursor: pointer;

          span:last-child {
            display: flex;
            align-items: center;
            float: right;
          }

          svg {
            transition: all 0.2s;
          }
        }

        td {
          background: ${colors['blue-100']};
        }
      }

      &.compare-sub-item-row {
        th {
          color: ${colors['text-subtle']};
        }
      }

      &.compare-data-row {
        &:nth-child(odd) {
          th,
          td {
            background: ${colors['gray-100']};
          }
        }

        th {
          font-size: ${fontSizes[1]};
          font-weight: 600;
          color: ${({ theme: { colors } }) => colors['text-default']};
        }

        td {
          font-weight: 500;
        }
      }

      &.collapsed {
        :nth-last-child(3) {
          th,
          td {
            border-bottom-left-radius: ${radii.m};
            border-bottom-right-radius: ${radii.m};
          }
        }

        th {
          background: ${colors['background-primary']};
          border-top: ${`${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};

          :hover {
            background: ${colors['blue-100']};

            ~ td {
              background: ${colors['blue-100']};
            }
          }

          svg {
            transform: rotate(-180deg);
          }
        }

        td {
          background: ${colors['background-primary']};
          border-top: ${`${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
          padding: 0;
        }

        .empty-field {
          border-top: unset;
        }
      }
    }

    & thead {
      th {
        border-style: ${borderStyles.solid} ${borderStyles.solid}
          ${borderStyles.none} ${borderStyles.solid};
        border-top-left-radius: ${radii.m};
        border-top-right-radius: ${radii.m};
        position: sticky;
        top: 0;
        box-shadow: ${shadows.s};
        padding: ${`${space[32]} ${space[16]}`};
        font-weight: 500;
        text-align: left;
        background: ${colors['background-primary']};
        z-index: 3;
        width: 280px;

        :first-child {
          left: ${space[0]};
          z-index: 4;
          width: 224px !important;
          box-shadow: ${shadows.m};

          button {
            width: 100%;

            div {
              justify-content: center;
            }
          }
        }
      }
    }

    & tbody {
      th {
        position: sticky;
        left: ${space[0]};
        z-index: 3;
        width: 224px;
        box-shadow: ${shadows.s};
      }

      tr:last-child {
        th,
        td {
          border-style: ${borderStyles.none} ${borderStyles.solid}
            ${borderStyles.solid} ${borderStyles.solid};
          border-bottom-left-radius: ${radii.m};
          border-bottom-right-radius: ${radii.m};
        }
      }
    }
  `,
);

export const ActionsCard = styled.div(
  ({
    theme: { colors, borderStyles, borderWidths, shadows, space, radii },
  }) => css`
    display: flex;
    background: ${colors['background-primary']};
    width: fit-content;
    position: absolute;
    top: 75px;
    transform: translateX(-50%);
    left: 50%;
    transition: all 0.2s;
    overflow: hidden;
    padding: 0;
    box-shadow: unset;
    border: unset;
    height: 0;
    opacity: 0;

    & > * {
      padding: ${space[0]} ${space[8]};
      cursor: pointer;
      border: unset;
      background: inherit;

      :disabled {
        color: grey;

        :hover {
          svg {
            color: grey;
            cursor: not-allowed;
          }
        }
      }

      :hover {
        svg {
          color: ${colors['icon-default']};
        }
      }
    }

    & > :nth-child(2) {
      border: ${borderStyles.solid} ${borderWidths.s} ${colors['border-subtle']};
      border-style: ${borderStyles.none} ${borderStyles.solid};
    }

    & > :nth-child(3) {
      border: ${borderStyles.solid} ${borderWidths.s} ${colors['border-subtle']};
      border-style: ${borderStyles.none} ${borderStyles.solid}
        ${borderStyles.none} ${borderStyles.none};
    }

    &.show-actions {
      border: ${borderStyles.solid} ${borderWidths.s} ${colors['border-subtle']} !important;
      box-shadow: ${shadows.s} !important;
      border-radius: ${radii.s};
      padding: ${space[8]} ${space[0]} !important;
      height: fit-content;
      opacity: 1;
    }
  `,
);

export const ScrollElement = styled.div`
  position: fixed;
  z-index: 4;
  bottom: ${({ theme: { space } }) => space[16]};
  overflow: auto;
  height: ${({ theme: { space } }) => space[16]};
  margin-left: ${({ theme: { space } }) => space[4]};
`;
