import { Fragment, useCallback, useEffect, useState } from 'react';

import { ErrorBanner } from 'common/components';
import { Loading } from 'common/components/atoms/Loading/Loading';
import { FormCheckbox } from 'common/components/molecules/FormCheckbox';
import { borderSubtle } from 'common/constants/border';
import { getTenantIdSelector } from 'common/store/selectors/companies.selectors';
import { User } from 'common/types';
import {
  DirectReportsModal,
  EmployeesSearchWithList,
} from 'omniplatform/manager/pages/ManagerPermissions/components';
import { useSelector } from 'react-redux';
import { useSelectUserRoles } from 'utils/hooks';
import {
  useCompanyManagersQuery,
  useManagerEmployeesQuery,
  useUpdateUserAssociationsMutation,
  useUpdateUserScopeExpensesMutation,
  useUpdateUserScopeFinanceMutation,
  useUpdateUserScopePayrollMutation,
} from 'utils/queries';
import { sortAlphabetically } from 'utils/sort.util';
import {
  hasExpensesNotificationsScope,
  hasFinanceScope,
  hasPayrollScope,
  hasSuperManagerScope,
} from 'utils/user';

import {
  Box,
  Button,
  omniConfig,
  Split,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import { AddManagerForm } from '../AddManager/AddManagerForm/AddManagerForm';
import { AssociatedEmployee } from '../EmployeesList';
import { ManagerListSearch } from '../ManagerListSearch/ManagerListSearch';
import { SuperManagerToggle } from '../SuperManagerToggle/SuperManagerToggle';
import * as S from './ManagerPermissionsTab.styles';

export const ManagerPermissionsTab = () => {
  const companyId = useSelector(getTenantIdSelector);

  const [selectedManager, setSelectedManager] = useState<User>();
  const [addManagerForm, setAddManagerForm] = useState(false);
  const [hasExpensesNotification, setHasExpensesNotification] = useState(
    selectedManager && hasExpensesNotificationsScope(selectedManager),
  );
  const [hasPayroll, setHasPayroll] = useState(
    selectedManager && hasPayrollScope(selectedManager),
  );
  const [hasFinance, setHasFinance] = useState(
    selectedManager && hasFinanceScope(selectedManager),
  );
  const [_isEmpty, setIsEmpty] = useState(false);
  const checkboxInfoMessage = 'All changes are saved automatically';
  const changesSavedMessage = 'Changes were saved';
  const [checkboxHelpText, setCheckboxHelpText] = useState(checkboxInfoMessage);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCheckboxHelpText(checkboxInfoMessage);
    }, 1000);
    return () => clearTimeout(timer);
  }, [checkboxHelpText]);

  useEffect(() => {
    setHasExpensesNotification(
      selectedManager && hasExpensesNotificationsScope(selectedManager),
    );
    setHasPayroll(selectedManager && hasPayrollScope(selectedManager));
    setHasFinance(selectedManager && hasFinanceScope(selectedManager));
  }, [selectedManager]);

  const { isCSAdmin, isAdmin, isOPAdmin, isPayrollAdmin } =
    useSelectUserRoles();

  const hasSuperAdminModificationRights: boolean =
    isCSAdmin || isAdmin || isOPAdmin || isPayrollAdmin;

  const updateUserAssociations = useUpdateUserAssociationsMutation({
    companyId,
  });
  const updateUserScopeExpensesMutation = useUpdateUserScopeExpensesMutation({
    companyId,
  });
  const updateUserScopeFinanceMutation = useUpdateUserScopeFinanceMutation({
    companyId,
  });
  const updateUserScopePayrollMutation = useUpdateUserScopePayrollMutation({
    companyId,
  });

  const companyManagersQuery = useCompanyManagersQuery(
    { companyId },
    { select: (data) => sortAlphabetically('name', data as User[]) },
  );
  const {
    isLoading: companyQueryIsLoading,
    isSuccess: companyQueryIsSuccess,
    isError: companyQueryIsError,
    data: companyManagersData,
  } = companyManagersQuery;

  const superAdminModificationDisabled = Boolean(
    companyManagersData &&
      companyManagersData.filter((manager) => hasSuperManagerScope(manager))
        .length === 1,
  );

  const managerEmployeesQuery = useManagerEmployeesQuery({
    companyId,
    managerUserId: selectedManager?.userId || '',
    asAssociatedEmployee: true,
  });
  const {
    isLoading: managerEmployeesQueryIsLoading,
    isSuccess: managerEmployeesQueryIsSuccess,
    isError: managerEmployeesQueryIsError,
    data: managerEmployeesData,
  } = managerEmployeesQuery;

  const employees = managerEmployeesData as AssociatedEmployee[];

  useEffect(() => {
    setIsEmpty(
      managerEmployeesQueryIsSuccess && employees && employees.length === 0,
    );
  }, [employees, managerEmployeesQueryIsSuccess]);

  useEffect(() => {
    if (selectedManager && companyManagersData) {
      const updatedSelectedManager = companyManagersData?.filter(
        (manager) => manager.userId === selectedManager.userId,
      )[0];
      setSelectedManager(updatedSelectedManager);
    } else if (
      companyManagersData &&
      Array.isArray(companyManagersData) &&
      companyManagersData.length > 0
    ) {
      setSelectedManager(companyManagersData[0]);
    }
  }, [companyManagersData]);

  const onChangeExpensesNotification = useCallback(() => {
    if (selectedManager) {
      const hasExpensesScope = !hasExpensesNotification;
      updateUserScopeExpensesMutation.mutate({
        userId: selectedManager.userId,
        hasExpensesScope,
      });
      setHasExpensesNotification(hasExpensesScope);
      setCheckboxHelpText(changesSavedMessage);
    }
  }, [
    hasExpensesNotification,
    selectedManager,
    updateUserScopeExpensesMutation,
  ]);

  const onChangeFinance = useCallback(() => {
    if (selectedManager) {
      const hasFinanceScope = !hasFinance;
      updateUserScopeFinanceMutation.mutate({
        userId: selectedManager.userId,
        hasFinanceScope,
      });
      setHasFinance(hasFinanceScope);
      setCheckboxHelpText(changesSavedMessage);
    }
  }, [hasFinance, selectedManager, updateUserScopeFinanceMutation]);

  const onChangePayroll = useCallback(() => {
    if (selectedManager) {
      const hasPayrollScope = !hasPayroll;
      updateUserScopePayrollMutation.mutate({
        userId: selectedManager.userId,
        hasPayrollScope,
      });
      setHasPayroll(hasPayrollScope);
      setCheckboxHelpText(changesSavedMessage);
    }
  }, [hasPayroll, selectedManager, updateUserScopePayrollMutation]);

  const onOpenModalClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const onCloseModalClick = useCallback(() => {
    setShowModal(false);
  }, []);

  const onSaveClick = useCallback(
    (selectedEmployees: string[]) => {
      if (selectedManager) {
        updateUserAssociations.mutate(
          {
            userId: selectedManager.userId,
            associatedUserIds: selectedEmployees,
          },
          {
            onSuccess: () => setShowModal(false),
            onError: (err) => console.error(err),
          },
        );
      }
    },
    [selectedManager, updateUserAssociations],
  );

  const onClickManager = (manager: User) => {
    setSelectedManager(manager);
    setAddManagerForm(false);
  };

  const onClickAddManager = () => {
    setSelectedManager(undefined);
    setAddManagerForm(true);
  };

  return (
    <Fragment>
      {(companyQueryIsError || managerEmployeesQueryIsError) && <ErrorBanner />}
      {companyQueryIsLoading && <Loading />}
      {managerEmployeesQueryIsSuccess && showModal && selectedManager && (
        <DirectReportsModal
          associatedUsers={employees.map((employee) => employee.userId)}
          onCloseClick={onCloseModalClick}
          onSaveClick={onSaveClick}
        />
      )}
      {(companyQueryIsSuccess || managerEmployeesQueryIsSuccess) && (
        <S.ResponsiveBox bg="primary" minH={608}>
          <S.ResponsiveSplit bg="primary" template="11fr 16fr" gap="32" p="32">
            <S.StyledManagerBox pr="32">
              <Stack bg="primary">
                <Stack gap="0">
                  <Typography as="span" size="16">
                    Managers
                  </Typography>
                  <Typography as="span" size="14" color="helper">
                    Select a manager and see their assigned employees.
                  </Typography>
                </Stack>
              </Stack>
              <ManagerListSearch
                managers={companyManagersData!}
                selectedManager={selectedManager}
                onClickManager={onClickManager}
                onClickAddManager={onClickAddManager}
                addManagerForm={addManagerForm}
              />
            </S.StyledManagerBox>
            {addManagerForm && <AddManagerForm></AddManagerForm>}
            {selectedManager && !addManagerForm && (
              <S.StyledEmployeesBox bg="primary">
                <Stack gap="0">
                  <Split fraction="auto-end">
                    <Typography as="span" size="16" weight="medium">
                      {selectedManager?.name}
                    </Typography>
                    {hasSuperAdminModificationRights && (
                      <SuperManagerToggle
                        manager={selectedManager}
                        isDisabled={superAdminModificationDisabled}
                      />
                    )}
                  </Split>
                  <Typography as="span" size="14" color="helper">
                    Set up permissions and direct reports
                  </Typography>
                  <S.CheckboxWrapper>
                    <FormCheckbox
                      size={16}
                      label="Receive employee expense notifications"
                      name="checkbox-expense-permissions"
                      key="expenses-permissions"
                      id="checkbox-expense-permissions"
                      checked={Boolean(hasExpensesNotification)}
                      onChange={onChangeExpensesNotification}
                    />
                    <Box
                      css={{
                        alignSelf: 'center',
                        paddingTop: omniConfig.theme.space[4],
                      }}
                    >
                      <Typography as="span" size="14">
                        {checkboxHelpText}
                      </Typography>
                    </Box>
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <FormCheckbox
                      size={16}
                      label="Can access company-wide payroll data (including any payroll reports and salary data)"
                      name="checkbox-payroll-permissions"
                      key="payroll-permissions"
                      id="checkbox-payroll-permissions"
                      checked={Boolean(hasPayroll)}
                      onChange={onChangePayroll}
                    />
                    <Box
                      css={{
                        alignSelf: 'center',
                        paddingTop: omniConfig.theme.space[4],
                      }}
                    >
                      <Typography as="span" size="14">
                        <br />
                      </Typography>
                    </Box>
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <FormCheckbox
                      size={16}
                      label="Can access company-wide financial data (including invoices and salary data)"
                      name="checkbox-finance-permissions"
                      key="finance-permissions"
                      id="checkbox-finance-permissions"
                      checked={Boolean(hasFinance)}
                      onChange={onChangeFinance}
                    />
                    <Box
                      css={{
                        alignSelf: 'center',
                        paddingTop: omniConfig.theme.space[4],
                      }}
                    >
                      <Typography as="span" size="14">
                        <br />
                      </Typography>
                    </Box>
                  </S.CheckboxWrapper>
                </Stack>
                <Stack gap="16" css={{ flex: 1 }}>
                  <Stack
                    pb="16"
                    justify="space-between"
                    align="center"
                    css={{
                      borderBottom: borderSubtle,
                      flexDirection: 'row',
                    }}
                  >
                    <Typography as="span" size="16">
                      Direct reports
                    </Typography>
                    {!hasSuperManagerScope(selectedManager) && (
                      <Button
                        icon="Edit"
                        variant="secondary"
                        onClick={onOpenModalClick}
                      >
                        Edit
                      </Button>
                    )}
                  </Stack>
                  <Box
                    css={{
                      alignSelf: 'end',
                    }}
                  >
                    <Typography as="span" size="14">
                      {employees && employees.length} direct reports
                    </Typography>
                  </Box>

                  {managerEmployeesQueryIsSuccess &&
                    employees &&
                    employees.length === 0 && (
                      <S.StyledDirectReportsEmptyBox>
                        <img
                          style={{
                            display: 'block',
                            paddingBottom: omniConfig.theme.space[16],
                          }}
                          width="10%"
                          src="/logo192.png"
                          alt="Omnipresent"
                          data-test-id="omnipresent-png-logo"
                        />
                        <Typography as="span" size="16">
                          You do not have any reports assigned to this manager.
                        </Typography>
                        <S.StyledButton
                          onClick={onOpenModalClick}
                          size="small"
                          variant="primary"
                        >
                          Add direct reports
                        </S.StyledButton>
                      </S.StyledDirectReportsEmptyBox>
                    )}
                  {!managerEmployeesQueryIsLoading &&
                    employees &&
                    employees.length > 0 && (
                      <S.StyledEmployeesList>
                        <EmployeesSearchWithList employees={employees} />
                      </S.StyledEmployeesList>
                    )}
                  {managerEmployeesQueryIsLoading && (
                    <S.StyledEmployeesList>
                      <Loading />
                    </S.StyledEmployeesList>
                  )}
                </Stack>
              </S.StyledEmployeesBox>
            )}
          </S.ResponsiveSplit>
        </S.ResponsiveBox>
      )}
    </Fragment>
  );
};
