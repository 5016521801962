export const getLoadingSelector = (state) => state.appReducer.loading;
export const getAccessTokenSelector = (state) => state.appReducer.accessToken;
export const getErrorSelector = (state) => state.appReducer.error;
export const getErrorMessage = (state) => state.appReducer.errorMessage;
export const getUserSelector = (state) => state.appReducer.user;
export const getTransformedUser = (state) => state.appReducer.transformedUser;
export const getUserTenantId = (state) =>
  getTransformedUser(state)?.role.tenantId;
export const getUserRoleSelector = (state) => state.appReducer.userRole;
export const getUserSeenPrivacyPolicy = (state) =>
  state.appReducer.user.seenPrivacyPolicy;
export const getEmailChangeVerifiedSelector = (state) =>
  state.appReducer.emailChangeVerified;
export const getPlatformInvitationStatus = (state) =>
  state.appReducer.platformInvitationStatus;
