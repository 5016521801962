import { CountriesListRaw, Country } from 'common/types';
import { countries as rawCountries } from 'countries-list';

export const formatCountries = (countriesList: CountriesListRaw) => {
  const countriesKeys = Object.keys(countriesList);

  return countriesKeys.reduce((acc: Array<Country>, countryKey: string) => {
    const currentCountry = countriesList[countryKey];

    return [
      ...acc,
      {
        label: currentCountry.name,
        value: countryKey,
        emoji: currentCountry.emoji,
        currency: currentCountry.currency,
        phone: currentCountry.phone,
      },
    ];
  }, []);
};

const countries = formatCountries(rawCountries);

export const getCountryCodeByCountryName = (countryName: string): string => {
  return (
    countries.find((country: Country) => country.label === countryName)
      ?.value || ''
  );
};

export const getCountryByCountryName = (countryName: string) => {
  return countries.find(
    (country: Country) =>
      country.label.toLowerCase() === countryName.toLowerCase(),
  );
};

export const getCountryByCountryCode = (countryCode: string) => {
  return countries.find(
    (country: Country) =>
      country.value.toLowerCase() === countryCode.toLowerCase(),
  );
};

export const getCountryFlagByCountryName = (countryName: string) => {
  return (
    countries.find((country: Country) => country.label === countryName)
      ?.emoji || ''
  );
};

const countriesNotEligibleForAutomatedUpsells: string | string[] = ['Germany'];

export const isCountryEligibleForAutomatedUpsells = (
  country: string,
): boolean => !countriesNotEligibleForAutomatedUpsells.includes(country);

export default countries;
