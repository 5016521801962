import { getDataProcessingErrors } from 'common/api/payrollTaxonomy.api';
import { DataProcessingErrorApiResponse } from 'common/types';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from 'react-query';
import { payrollKeys } from 'utils/queries/payroll/keys';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useDataProcessingErrorsQuery = (params: {
  dataFrameIdsList: string[];
}) => {
  return useQuery<DataProcessingErrorApiResponse>(
    payrollKeys.dataProcessingErrors(params.dataFrameIdsList),
    () => getDataProcessingErrors(params).then(getDataFromResponse),
    {
      enabled: !isEmpty(params.dataFrameIdsList),
      refetchOnWindowFocus: false,
    },
  );
};
