import { useState } from 'react';
import { useParams } from 'react-router-dom';

import appHistory from 'app/appHistory';
import { ReactComponent as Bonus } from 'assets/icon/Bonus.svg';
import { ReactComponent as Commission } from 'assets/icon/Commission.svg';
import { ReactComponent as SalaryChange } from 'assets/icon/SalaryChange.svg';
import { useSelectUserRoles } from 'utils/hooks';
import { useTrack } from 'utils/mixpanel/tracker';

import {
  Box,
  Button,
  Center,
  Inline,
  Link,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import {
  getBulkCompensationWarning,
  getNextPagePath,
  LEARN_MORE_LINK,
} from '../utils';
import * as S from './BulkCompensationOptionsPage.styles';
import { SelectEmployeesModal } from './components/SelectEmployeesModal';

export type CompensationTypes = 'bonus' | 'commission' | 'salary';

export const BulkCompensationOptionsPage = () => {
  const track = useTrack();
  const { isManager } = useSelectUserRoles();
  const { companyId } = useParams<{ companyId: string }>();
  const [selectedCompensation, setSelectedCompensation] =
    useState<CompensationTypes>();
  const [showSelectEmployeesModal, setShowSelectEmployeesModal] =
    useState(false);

  const onOpenModalClick = () => {
    setShowSelectEmployeesModal(true);
  };
  const onCloseModalClick = () => {
    setShowSelectEmployeesModal(false);
  };

  const onCompensationTypeClicked = (compensationType: CompensationTypes) => {
    track('Select Bulk Compensation Type Clicked', {
      selectedCompensation: compensationType,
    });
    setSelectedCompensation(compensationType);
  };

  const onNextClick = (selectedEmployeesIds: string[]) => {
    track('Add Bulk Compensation Employees Clicked', { selectedCompensation });
    const compensationData = {
      selectedCompensation,
      selectedEmployeesIds,
    };
    const nextPage = getNextPagePath(isManager, 'upload', companyId);
    appHistory.push(nextPage, compensationData);
  };

  return (
    <>
      <Box mr="64">
        {showSelectEmployeesModal && (
          <SelectEmployeesModal
            onCloseClick={onCloseModalClick}
            onNextClick={onNextClick}
            selectedCompensation={selectedCompensation as string}
          />
        )}
        {getBulkCompensationWarning()}

        <Box bg="primary" maxH="90vh" p="32" mt="32" radius="m" depth="s">
          <Typography as="h5" hideParagraphSpacing size="20" weight="medium">
            Submit compensation changes in bulk
          </Typography>
          <Typography as="span" size="14" weight="regular">
            Generate a template and get instant verification to submit bulk
            compensation changes.
          </Typography>

          <S.CompensationTypesCards
            bg="primary"
            py="16"
            radius="s"
            gap="32"
            stretch="all"
            h={450}
            $isSelectedCompensation={selectedCompensation}
          >
            <S.CompensationOptionStack
              align="center"
              bg="primary"
              px="32"
              py="16"
              justify="center"
              stretch={1}
              onClick={() => onCompensationTypeClicked('bonus')}
            >
              <Stack align="center" justify="center">
                <Bonus />
                <Typography
                  as="h6"
                  hideParagraphSpacing
                  size="20"
                  weight="medium"
                >
                  Bonuses
                </Typography>
              </Stack>
              <Typography as="label" size="14">
                An incentive compensation paid to employees when a certain
                business threshold is met. Different amounts can be submitted at
                once.
              </Typography>
            </S.CompensationOptionStack>

            <S.CompensationOptionStack
              align="center"
              bg="primary"
              px="32"
              py="16"
              justify="center"
              stretch={1}
              onClick={() => onCompensationTypeClicked('commission')}
            >
              <Stack align="center" justify="center">
                <Commission />
                <Typography
                  as="h6"
                  hideParagraphSpacing
                  size="20"
                  weight="medium"
                >
                  Commissions
                </Typography>
              </Stack>

              <Typography as="label" size="14">
                A piece of employees&apos;s total compensation paid out when a
                sale is made. Different amounts can be submitted at once.
              </Typography>
            </S.CompensationOptionStack>

            <S.CompensationOptionStack
              align="center"
              justify="center"
              bg="primary"
              px="32"
              py="32"
              stretch={1}
              onClick={() => onCompensationTypeClicked('salary')}
            >
              <Stack align="center" justify="center">
                <SalaryChange />
                <Typography
                  as="h6"
                  hideParagraphSpacing
                  size="20"
                  weight="medium"
                >
                  Salary Changes
                </Typography>
              </Stack>
              <Typography as="label" size="14">
                Different amounts can be submitted at once.
              </Typography>
            </S.CompensationOptionStack>
          </S.CompensationTypesCards>
          <Inline pt="16" h={50} reverse>
            <Box>
              <Button
                size="medium"
                disabled={!Boolean(selectedCompensation)}
                onClick={() => onOpenModalClick()}
              >
                Next
              </Button>
            </Box>
          </Inline>
          <Center axis="horizontal" centerChildren>
            <Inline gap="4">
              <Link
                size="14"
                to={LEARN_MORE_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Link>
              <Typography as="span" size="14">
                about the process of submitting compensation in bulk.
              </Typography>
            </Inline>
          </Center>
        </Box>
      </Box>
    </>
  );
};
