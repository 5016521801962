import { mockLSPsResponse } from '__mocks__/lsps.mock';
import { payrollTaxonomyClient } from 'api';
import Big from 'big.js';
import { HTTPStatusCodesEnum } from 'common/constants';
import {
  BillType,
  GrossToNetFile,
  GrossToNetProcessingStatus,
  GrossToNetSource,
  GrossToNetUploadStatus,
  PayReportBulkApproveApiResponse,
} from 'common/types';
import { cloneDeep, reduce } from 'lodash';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

import {
  APPROVE_PAY_REPORT,
  DATA_PROCESSING_ERRORS_BASE_API_ENDPOINT,
  GROSS_TO_NET_BASE_API_ENDPOINT,
  PAY_REPORT_PREVIEW,
  PUBLISH_PAY_REPORT,
  WORKFLOW_TRANSITION_PAY_REPORT,
} from './payrollTaxonomy.api';

export const DEFAULT_GROSS_TO_NET_STATUS_CHANGE_LOGS: NonNullable<
  GrossToNetFile['grossToNetStatusChangeLogs']
> = [
  {
    id: 'some-log-id-0',
    status: GrossToNetProcessingStatus.COPIED,
    updatedBy: 'auth0|some-user',
    updatedAt: new Date().toISOString(),
  },
  {
    id: 'some-log-id-1',
    status: GrossToNetProcessingStatus.SPECIALIST_APPROVED,
    updatedBy: 'auth0|some-user',
    updatedAt: new Date().toISOString(),
  },
  {
    id: 'some-log-id-2',
    status: GrossToNetProcessingStatus.PUBLISHED,
    updatedBy: 'auth0|some-user',
    updatedAt: new Date().toISOString(),
  },
];

export const DEFAULT_GROSS_TO_NET: GrossToNetFile = {
  id: 'some-id',
  lspId: 'some-lspid',
  payrollCycle: '1',
  total: '0',
  uploadedBy: 'auth0|some-user',
  fileName: 'file.csv',
  cloudStorageFilePath: 'some-path',
  country: 'KE',
  legalEntityId: 'some-legal-entityId',
  payrollPeriod: '202301',
  lspData: {
    lspExchangeRate: 1234,
    lspBillingCurrency: 'EUR',
    lspBillNumber: 'INV001',
    lspBillUrl: 'https://some-url.com',
    invoiceAmount: 1000,
    invoiceDate: new Date(),
    invoiceDueDate: new Date(),
    memo: 'some-memo',
    postingPeriod: '202406',
    billType: BillType.BILL_CREDIT,
  },
  fileType: 'PAY_REGISTER',
  source: GrossToNetSource.NEEYAMO,
  processingStatus: GrossToNetProcessingStatus.DUPLICATES_CHECKED,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
};

const generateGrossToNet = (override = {}) => ({
  ...DEFAULT_GROSS_TO_NET,
  ...override,
});

const DEFAULT_GROSS_TO_NETS = [
  generateGrossToNet(),
  generateGrossToNet({
    id: 'some-id-2',
    fileName: 'file-2.csv',
    country: undefined,
    legalEntityId: undefined,
    processingStatus: GrossToNetProcessingStatus.INGESTED,
  }),
  generateGrossToNet({
    fileName: 'another-file.csv',
    processingStatus: GrossToNetProcessingStatus.ERROR,
  }),
];

const generatePayReportPreview = (override = {}) => ({
  ...DEFAULT_PAY_REPORT_PREVIEW,
  ...override,
});

const generateLegalEntityPayReportPreview = () => {
  const payReportPreviewCopy = cloneDeep(generatePayReportPreview());

  payReportPreviewCopy.employees.items.forEach((employee) => {
    employee.concepts.forEach((concept) => {
      if (concept.value && concept.applicationType !== 'Informational') {
        concept.value = Big(concept.value.replace(',', '')).div(2).toString();
      }
    });

    employee.totals = reduce(
      employee.totals,
      (acc, val, key) => {
        return { ...acc, [key]: Big(val).div(2).toString() };
      },
      {
        salary: '0',
        erContribution: '0',
        eeDeductions: '0',
        expenses: '0',
        allowance: '0',
        variablePay: '0',
      },
    );
  });

  return payReportPreviewCopy;
};

const generateVariancesPayReportPreview = (override = {}) => {
  const payReportPreviewCopy = cloneDeep(generatePayReportPreview(override));

  payReportPreviewCopy.employees.items =
    payReportPreviewCopy.employees.items.map((employee) => {
      return {
        ...employee,
        concepts: employee.concepts.map((concept) => {
          const newValue = Big(concept.value).div(2);
          return {
            ...concept,
            variances: {
              previous: newValue.toFixed(2),
              variance: newValue.toFixed(2),
              variancePercentage: '100.00',
            },
          };
        }),
        totals: reduce(
          employee.totals,
          (acc, val, key) => {
            return { ...acc, [key]: Big(val).div(2).toFixed(2) };
          },
          {
            salary: '0',
            erContribution: '0',
            eeDeductions: '0',
            expenses: '0',
            allowance: '0',
            variablePay: '0',
          },
        ),
        variances: {
          previous: Object.keys(employee.totals).reduce<Record<string, string>>(
            (acc, key) => {
              acc[key] = Big(
                employee.totals[key as keyof typeof employee.totals],
              )
                .div(2)
                .toFixed(2);
              return acc;
            },
            {},
          ),
          variance: Object.keys(employee.totals).reduce<Record<string, string>>(
            (acc, key) => {
              acc[key] = Big(
                employee.totals[key as keyof typeof employee.totals],
              )
                .div(2)
                .toFixed(2);
              return acc;
            },
            {},
          ),
          variancePercentage: Object.keys(employee.totals).reduce<
            Record<string, string>
          >((acc, key) => {
            acc[key] = '100.00';
            return acc;
          }, {}),
        },
      };
    });

  return payReportPreviewCopy;
};

export const DEFAULT_PAY_REPORT_PREVIEW = {
  reportSummary: {
    payrollPeriod: '20235',
    fileName: 'kenya test 2023-04-05.csv',
    status: 'DUPLICATES_CHECKED',
    uploadedBy: 'auth0|620ea0b63e8a2b0069fdcea4',
    employeeCount: 140,
    reportTotalSum: '478648.82',
    country: 'kenya',
    currency: 'KES',
    gtnId: DEFAULT_GROSS_TO_NET.id,
    totals: {
      salary: '20933.34',
      allowances: '0.00',
      expenses: '187.50',
      variablePay: '833.00',
      EEDeductions: '154968.35',
      ERContribution: '3835.45',
    },
  },
  employees: {
    items: [
      {
        concepts: [
          {
            value: '470000',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'N/A',
            taxability: 'Taxable',
            omniplatform: 'Salary',
            payrollGlobal: 'Salary',
            applicationType: 'Income',
            payrollGlobalSubCategory: 'Salary',
          },
          {
            value: '50',
            assignee: 'Employer',
            inputType: 'Input',
            statutory: 'Statutory',
            taxability: 'N/A',
            omniplatform: 'Social Costs',
            payrollGlobal: 'Employer Contributions',
            applicationType: 'Contribution',
            payrollGlobalSubCategory: 'Training Tax ER',
          },
          {
            value: '163340',
            assignee: 'Employee',
            inputType: 'Total',
            statutory: 'N/A',
            taxability: 'N/A',
            omniplatform: 'Informational',
            payrollGlobal: 'Informational',
            applicationType: 'Informational',
            payrollGlobalSubCategory: 'N/A',
          },
          {
            value: '8398',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'N/A',
            taxability: 'Non Taxable',
            omniplatform: 'Expenses',
            payrollGlobal: 'Expenses',
            applicationType: 'Income',
            payrollGlobalSubCategory: 'Expenses',
          },
          {
            value: '1700',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'Statutory',
            taxability: 'N/A',
            omniplatform: 'Tax',
            payrollGlobal: 'Employee Deductions',
            applicationType: 'Deduction',
            payrollGlobalSubCategory: 'Health Fund Tax EE',
          },
          {
            value: '200',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'Statutory',
            taxability: 'N/A',
            omniplatform: 'Salary',
            payrollGlobal: 'Employee Deductions',
            applicationType: 'Deduction',
            payrollGlobalSubCategory: 'Social Security Tax EE',
          },
          {
            value: '200',
            assignee: 'Employer',
            inputType: 'Input',
            statutory: 'Statutory',
            taxability: 'N/A',
            omniplatform: 'Social Costs',
            payrollGlobal: 'Employer Contributions',
            applicationType: 'Contribution',
            payrollGlobalSubCategory: 'Social Security Tax ER',
          },
          {
            value: '133068.35',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'Statutory',
            taxability: 'N/A',
            omniplatform: 'Tax',
            payrollGlobal: 'Employee Deductions',
            applicationType: 'Deduction',
            payrollGlobalSubCategory: 'Income Tax EE',
          },
        ],
        lastName: 'Doe',
        firstName: 'John',
        totals: {
          salary: '470000.23',
          erContribution: '250.00',
          eeDeductions: '134968.35',
          expenses: '8398.59',
          allowance: '0',
          variablePay: '0',
        },
      },
      {
        concepts: [
          {
            value: '6.18',
            assignee: 'Employer',
            inputType: 'Input',
            statutory: 'Non Statutory',
            taxability: 'N/A',
            omniplatform: 'Benefit Contribution',
            payrollGlobal: 'Benefit Contributions ER',
            applicationType: 'Contribution',
            payrollGlobalSubCategory: 'Accident Insurance ER',
          },
          {
            value: '0',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'N/A',
            taxability: 'N/A',
            omniplatform: 'Salary',
            payrollGlobal: 'Salary Deduction to Gross',
            applicationType: 'Deduction',
            payrollGlobalSubCategory: 'Salary Advance Recovery',
          },
          {
            value: '0',
            assignee: 'Employer',
            inputType: 'Input',
            statutory: 'Non Statutory',
            taxability: 'N/A',
            omniplatform: 'Benefit Contribution',
            payrollGlobal: 'Benefit Contributions ER',
            applicationType: 'Contribution',
            payrollGlobalSubCategory: 'Dental Insurance ER',
          },
          {
            value: '0',
            assignee: 'Employer',
            inputType: 'Input',
            statutory: 'Non Statutory',
            taxability: 'N/A',
            omniplatform: 'Benefit Contribution',
            payrollGlobal: 'Benefit Contributions ER',
            applicationType: 'Contribution',
            payrollGlobalSubCategory: 'Medical Insurance ER',
          },
          {
            value: '0',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'N/A',
            taxability: 'Taxable',
            omniplatform: 'Variable Pay',
            payrollGlobal: 'Bonuses',
            applicationType: 'Income',
            payrollGlobalSubCategory: 'Bonus',
          },
          {
            value: '0',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'Non Statutory',
            taxability: 'Taxable',
            omniplatform: 'Allowance',
            payrollGlobal: 'Allowances',
            applicationType: 'Income',
            payrollGlobalSubCategory: 'Phone Allowance',
          },
          {
            value: '0',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'Non Statutory',
            taxability: 'Taxable',
            omniplatform: 'Allowance',
            payrollGlobal: 'Allowances',
            applicationType: 'Income',
            payrollGlobalSubCategory: 'Cost of Living Allowance',
          },
          {
            value: '0',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'N/A',
            taxability: 'Taxable',
            omniplatform: 'Variable Pay',
            payrollGlobal: 'Commissions',
            applicationType: 'Income',
            payrollGlobalSubCategory: 'Commission',
          },
          {
            value: '230.41',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'Statutory',
            taxability: 'N/A',
            omniplatform: 'Tax',
            payrollGlobal: 'Employee Deductions',
            applicationType: 'Deduction',
            payrollGlobalSubCategory: 'Social Security Tax EE',
          },
          {
            value: '230.41',
            assignee: 'Employer',
            inputType: 'Input',
            statutory: 'Statutory',
            taxability: 'N/A',
            omniplatform: 'Social Costs',
            payrollGlobal: 'Employer Contributions',
            applicationType: 'Contribution',
            payrollGlobalSubCategory: 'Social Security Tax ER',
          },
          {
            value: '81.45',
            assignee: 'Employer',
            inputType: 'Input',
            statutory: 'Statutory',
            taxability: 'N/A',
            omniplatform: 'Social Costs',
            payrollGlobal: 'Employer Contributions',
            applicationType: 'Contribution',
            payrollGlobalSubCategory: 'Health Fund Tax ER',
          },
          {
            value: '65.72',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'Statutory',
            taxability: 'N/A',
            omniplatform: 'Salary',
            payrollGlobal: 'Employee Deductions',
            applicationType: 'Deduction',
            payrollGlobalSubCategory: 'Unemployment Insurance EE',
          },
          {
            value: '92.01',
            assignee: 'Employer',
            inputType: 'Input',
            statutory: 'Statutory',
            taxability: 'N/A',
            omniplatform: 'Social Costs',
            payrollGlobal: 'Employer Contributions',
            applicationType: 'Contribution',
            payrollGlobalSubCategory: 'Unemployment Tax ER',
          },
          {
            value: '0',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'Non Statutory',
            taxability: 'N/A',
            omniplatform: 'Salary',
            payrollGlobal: 'Benefits Deduction EE',
            applicationType: 'Deduction',
            payrollGlobalSubCategory: 'Health Insurance EE',
          },
          {
            value: '259',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'N/A',
            taxability: 'Non Taxable',
            omniplatform: 'Expenses',
            payrollGlobal: 'Expenses',
            applicationType: 'Income',
            payrollGlobalSubCategory: 'Expenses',
          },
          {
            value: '801.79',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'Statutory',
            taxability: 'N/A',
            omniplatform: 'Tax',
            payrollGlobal: 'Employee Deductions',
            applicationType: 'Deduction',
            payrollGlobalSubCategory: 'Income Tax EE',
          },
          {
            value: '0',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'Non Statutory',
            taxability: 'N/A',
            omniplatform: 'Salary',
            payrollGlobal: 'Benefits Deduction EE',
            applicationType: 'Deduction',
            payrollGlobalSubCategory: 'Life Insurance EE',
          },
          {
            value: '11.1',
            assignee: 'Employer',
            inputType: 'Input',
            statutory: 'Non Statutory',
            taxability: 'N/A',
            omniplatform: 'Benefit Contribution',
            payrollGlobal: 'Benefit Contributions ER',
            applicationType: 'Contribution',
            payrollGlobalSubCategory: 'Life Insurance ER',
          },
          {
            value: '0',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'N/A',
            taxability: 'Taxable',
            omniplatform: 'Salary',
            payrollGlobal: '',
            applicationType: 'Income',
            payrollGlobalSubCategory: '',
          },
          {
            value: '4038.46',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'N/A',
            taxability: 'Taxable',
            omniplatform: 'Salary',
            payrollGlobal: 'Salary',
            applicationType: 'Income',
            payrollGlobalSubCategory: 'Salary',
          },
          {
            value: '133068.36',
            assignee: 'Employee',
            inputType: 'Input',
            statutory: 'Non Statutory',
            taxability: 'N/A',
            omniplatform: 'Pension Contribution',
            payrollGlobal: 'Pension Deduction EE',
            applicationType: 'Deduction',
            payrollGlobalSubCategory: 'Income Tax EE',
          },
        ],
        lastName: 'Turner',
        firstName: 'Nathan',
        employeeId: 'aef2967b-ccca-48b9-81f0-d156eaa8h800',
        totals: {
          salary: '4038.46',
          erContribution: '542.30',
          eeDeductions: '851.23',
          expenses: '259.00',
          variablePay: '0',
          allowance: '0',
        },
      },
    ],
    pageIndex: 0,
    pageSize: 100,
    pageTotalCount: 1,
  },
};

export const payrollTaxonomyHandlers = [
  rest.post(
    composeEndpoint(GROSS_TO_NET_BASE_API_ENDPOINT, payrollTaxonomyClient),
    (req, res, ctx) => {
      const response = [
        {
          fileName: 'fileName',
          id: 'fileId',
          uploadStatus: GrossToNetUploadStatus.COMPLETED,
        },
      ];
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),
  rest.post(
    composeEndpoint(
      `${GROSS_TO_NET_BASE_API_ENDPOINT}/complete`,
      payrollTaxonomyClient,
    ),
    (req, res, ctx) => {
      const response = [
        {
          fileName: 'fileName',
          id: 'fileId',
          uploadStatus: GrossToNetUploadStatus.COMPLETED,
        },
      ];
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),

  rest.post(
    composeEndpoint(
      `${GROSS_TO_NET_BASE_API_ENDPOINT}/manual-ingestion`,
      payrollTaxonomyClient,
    ),
    (req, res, ctx) => {
      const response = [
        {
          fileName: 'fileName',
          id: 'fileId',
          uploadStatus: GrossToNetUploadStatus.COMPLETED,
        },
      ];
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),

  rest.get(
    composeEndpoint(
      `${GROSS_TO_NET_BASE_API_ENDPOINT}/view/manual`,
      payrollTaxonomyClient,
    ),
    (req, res, ctx) => {
      const response = [
        {
          country: 'canada',
          payrollCycle: '1',
          lspId: mockLSPsResponse[0].id,
        },
        {
          country: 'belgium',
          payrollCycle: '1',
          lspId: mockLSPsResponse[0].id,
        },
      ];

      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),

  rest.get(
    composeEndpoint(
      `${GROSS_TO_NET_BASE_API_ENDPOINT}/view/detail`,
      payrollTaxonomyClient,
    ),
    (req, res, ctx) => {
      const response = {
        grandTotalInBillingCurrency: '2000',
        uploadedRecords: '2',
        uploadedEmployees: '2',
        approvedRecords: '1',
        publishedRecords: '0',
      };

      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),

  rest.get(
    composeEndpoint(
      `${GROSS_TO_NET_BASE_API_ENDPOINT}/export`,
      payrollTaxonomyClient,
    ),
    (req, res, ctx) => {
      const response = {
        data: Buffer.from('foobar').toString('base64url'),
      };

      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),

  rest.get(
    composeEndpoint(
      `${GROSS_TO_NET_BASE_API_ENDPOINT}/:gtnId`,
      payrollTaxonomyClient,
    ),
    (req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          ...DEFAULT_GROSS_TO_NET,
          grossToNetStatusChangeLogs: DEFAULT_GROSS_TO_NET_STATUS_CHANGE_LOGS,
        }),
      );
    },
  ),
  rest.get(
    composeEndpoint(GROSS_TO_NET_BASE_API_ENDPOINT, payrollTaxonomyClient),
    (req, res, ctx) => {
      const query = req.url.searchParams;
      const countryFilter = query.get('country');

      const response = {
        items: countryFilter
          ? DEFAULT_GROSS_TO_NETS.filter(
              ({ country }) => country === countryFilter,
            )
          : DEFAULT_GROSS_TO_NETS,
      };
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),
  rest.delete(
    composeEndpoint(
      `${GROSS_TO_NET_BASE_API_ENDPOINT}/bulk`,
      payrollTaxonomyClient,
    ),
    (req, res, ctx) => {
      const query = req.url.searchParams;
      const grossToNetIds: string[] = query.getAll('grossToNetIds[]');

      const dummyResponse = Array.from(
        grossToNetIds,
      ).reduce<PayReportBulkApproveApiResponse>((acc, dataFrameId) => {
        acc[dataFrameId] = 'DELETED';
        return acc;
      }, {});
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(dummyResponse));
    },
  ),
  rest.delete(
    composeEndpoint(
      `${GROSS_TO_NET_BASE_API_ENDPOINT}/:dataFrameId`,
      payrollTaxonomyClient,
    ),
    (req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({ message: 'Successfully deleted grossToNet file' }),
      );
    },
  ),
  rest.get(
    composeEndpoint(
      DATA_PROCESSING_ERRORS_BASE_API_ENDPOINT,
      payrollTaxonomyClient,
    ),
    (req, res, ctx) => {
      const response = [
        {
          key: 'some-id',
          rawTableId: 'some-raw-table-id',
          failedOn: 'DATA_INGESTION',
          errorType: 'error',
          message: 'error message',
          dataFrameId: 'some-data-frame-id',
          timeStamp: new Date().toISOString(),
        },
      ];
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),
  rest.patch(
    composeEndpoint(GROSS_TO_NET_BASE_API_ENDPOINT, payrollTaxonomyClient),
    async (req, res, ctx) => {
      const response = [
        {
          id: 'some-id',
          uploadedBy: 'auth0|some-user',
          fileName: 'file.csv',
          cloudStorageFilePath: 'some-path',
          country: 'kenya',
          legalEntityId: 'some-legal-entityId',
          payrollPeriod: '202301',
          fileType: 'PAY_REGISTER',
          source: 'NEEYAMO',
          processingStatus: GrossToNetProcessingStatus.TAXONOMIZED,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          uploadStatus: 'COMPLETED',
        },
      ];
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),
  rest.put(
    composeEndpoint(
      `${GROSS_TO_NET_BASE_API_ENDPOINT}/:gtnId/lsp-data`,
      payrollTaxonomyClient,
    ),
    async (req, res, ctx) => {
      const lspData = await req.json();
      const response = { ...DEFAULT_GROSS_TO_NET, lspData };
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),
  rest.get(
    composeEndpoint(PAY_REPORT_PREVIEW, payrollTaxonomyClient),
    (req, res, ctx) => {
      const variancePayrollPeriod = req.url.searchParams.get(
        'variancePayrollPeriod',
      );

      if (variancePayrollPeriod) {
        const varianceResponse = DEFAULT_GROSS_TO_NETS.map(
          (defaultGrossToNet) =>
            generateVariancesPayReportPreview({
              reportSummary: {
                ...DEFAULT_PAY_REPORT_PREVIEW.reportSummary,
                fileName: defaultGrossToNet.fileName,
              },
            }),
        );

        return res(
          ctx.status(HTTPStatusCodesEnum.OK),
          ctx.json(varianceResponse),
        );
      }

      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(
          DEFAULT_GROSS_TO_NETS.map((defaultGrossToNet) =>
            generatePayReportPreview({
              reportSummary: {
                ...DEFAULT_PAY_REPORT_PREVIEW.reportSummary,
                fileName: defaultGrossToNet.fileName,
              },
            }),
          ),
        ),
      );
    },
  ),
  rest.get(
    composeEndpoint(
      `${PAY_REPORT_PREVIEW}/:dataFrameId`,
      payrollTaxonomyClient,
    ),
    (req, res, ctx) => {
      const query = req.url.searchParams;
      const variancePayrollPeriod = query.get('variancePayrollPeriod');

      if (variancePayrollPeriod) {
        const response = generateVariancesPayReportPreview();
        return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
      }

      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(
          generatePayReportPreview(
            req.params.dataFrameId === 'mockDataFrameIdCopied'
              ? {
                  reportSummary: {
                    ...DEFAULT_PAY_REPORT_PREVIEW.reportSummary,
                    status: 'COPIED',
                  },
                }
              : {},
          ),
        ),
      );
    },
  ),
  rest.get(
    composeEndpoint(
      `${PAY_REPORT_PREVIEW}/legal-entity/:legalEntityId`,
      payrollTaxonomyClient,
    ),
    (req, res, ctx) => {
      const legalEntityPayReport = generateLegalEntityPayReportPreview();
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(legalEntityPayReport),
      );
    },
  ),
  rest.post(
    composeEndpoint(APPROVE_PAY_REPORT, payrollTaxonomyClient),
    (req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK));
    },
  ),
  rest.post(
    composeEndpoint(WORKFLOW_TRANSITION_PAY_REPORT, payrollTaxonomyClient),
    (req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK));
    },
  ),
  rest.post(
    composeEndpoint(PUBLISH_PAY_REPORT, payrollTaxonomyClient),
    (req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK));
    },
  ),
  rest.post(
    composeEndpoint(`${PUBLISH_PAY_REPORT}/bulk`, payrollTaxonomyClient),
    (req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK));
    },
  ),
  rest.post(
    composeEndpoint(
      `${WORKFLOW_TRANSITION_PAY_REPORT}/bulk`,
      payrollTaxonomyClient,
    ),
    async (req, res, ctx) => {
      const body: { dataFrameIds?: string[] } = await req.json();
      const dummyResponse = (
        body.dataFrameIds || ['mockDataFrameId']
      ).reduce<PayReportBulkApproveApiResponse>((acc, dataFrameId) => {
        acc[dataFrameId] = 'SUCCESS';
        return acc;
      }, {});

      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(dummyResponse));
    },
  ),
  rest.post(
    composeEndpoint(`${APPROVE_PAY_REPORT}/bulk`, payrollTaxonomyClient),
    async (req, res, ctx) => {
      const body: { dataFrameIds?: string[] } = await req.json();
      const dummyResponse = (
        body.dataFrameIds || ['mockDataFrameId']
      ).reduce<PayReportBulkApproveApiResponse>((acc, dataFrameId) => {
        acc[dataFrameId] = 'SUCCESS';
        return acc;
      }, {});

      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(dummyResponse));
    },
  ),
  rest.post(
    composeEndpoint(
      'orchestration/partial-gtn-processing',
      payrollTaxonomyClient,
    ),
    (req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK));
    },
  ),
  rest.post(
    composeEndpoint('orchestration/gtn-export', payrollTaxonomyClient),
    (req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK));
    },
  ),
  rest.post(
    composeEndpoint('orchestration/gtn-distribute', payrollTaxonomyClient),
    (req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.OK));
    },
  ),
  rest.post(
    composeEndpoint('justification', payrollTaxonomyClient),
    (req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.RESOURCE_CREATED));
    },
  ),
  rest.delete(
    composeEndpoint('justification/:justificationId', payrollTaxonomyClient),
    (req, res, ctx) => {
      return res(ctx.status(HTTPStatusCodesEnum.NO_CONTENT));
    },
  ),
  rest.get(
    composeEndpoint('justification', payrollTaxonomyClient),
    (req, res, ctx) => {
      const query = req.url.searchParams;
      const employeeId = query.get('employeeId');
      const grossToNetId = query.get('grossToNetId');

      if (!grossToNetId && !employeeId) {
        return res(ctx.status(HTTPStatusCodesEnum.BAD_REQUEST));
      }

      const response = [];
      const justification = {
        id: '5d268506-09f8-4096-9989-d15f9ffab759',
        grossToNetId,
        employeeId,
        lineItemId: '',
        note: 'Salary OK',
        createdBy: '',
        archivedBy: null,
        validUntil: null,
        validFrom: '2023-10-22T22:00:00.000Z',
      };

      if (employeeId) {
        response.push(justification);
        return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
      }

      response.push({
        ...justification,
        ...{
          employeeId: DEFAULT_PAY_REPORT_PREVIEW.employees.items[0].employeeId,
          note: 'Salary First OK',
        },
      });
      response.push({
        ...justification,
        ...{ employeeId: 'employeeId-2', note: 'Salary Second OK' },
      });
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),
  rest.get(
    composeEndpoint('justification/by-gtn-ids', payrollTaxonomyClient),
    (req, res, ctx) => {
      const justification = {
        id: '5d268506-09f8-4096-9989-d15f9ffab759',
        grossToNetId: DEFAULT_PAY_REPORT_PREVIEW.reportSummary.gtnId,
        employeeId: DEFAULT_PAY_REPORT_PREVIEW.employees.items[1].employeeId,
        lineItemId: '',
        note: 'Salary OK',
        createdBy: '',
        archivedBy: null,
        validUntil: null,
        validFrom: '2023-10-22T22:00:00.000Z',
      };

      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json([justification]));
    },
  ),
];
