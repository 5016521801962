import { LoadingSpinner } from 'common/components/atoms/LoadingSpinner/LoadingSpinner.styles';
import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import styled from 'styled-components';

export const StyledModal = styled(ModalWrapper)`
  overflow: visible;
  max-width: 669px;
  width: 80%;
`;

export const ModalContainer = styled.div`
  padding: ${({ theme: { space } }) => space[64]};
  margin: 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: ${({ theme: { space } }) => space[32]};
  justify-content: flex-end;

  & button {
    margin-left: ${({ theme: { space } }) => space[16]};
  }
`;

export const SpinnerContainer = styled.div`
  margin-right: ${({ theme: { space } }) => space[4]};
`;

export const SpinnerInButton = styled(LoadingSpinner)`
  height: ${({ theme: { space } }) => space[16]};
  width: ${({ theme: { space } }) => space[16]};
`;

export const ModalTitleIcon = styled.span`
  background-color: ${({ theme: { colors } }) => colors['icon-positive']};
  height: 48px;
  width: 48px;
  min-width: 48px;
  border-radius: ${({ theme: { radii } }) => radii.full};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme: { colors } }) => colors['icon-on-color']};
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { space } }) => space[16]};
`;

export const CountriesList = styled.div`
  margin-top: ${({ theme: { space } }) => space[8]};
  display: inline-grid;
  gap: 6px 40px;
  grid-template-rows: repeat(15, auto);
  grid-auto-flow: column;
`;
