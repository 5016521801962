import { ReactComponent as OmnipresentMark } from 'assets/img/OmnipresentMark.svg';

import * as Styled from './TableEmptyState.styles';

type BaseProps = {
  title?: string;
  description?: string;
};

type EmptyStateProps = BaseProps & {
  buttonLabel?: never;
  buttonClick?: never;
};

type EmptyStateWithButtonProps = BaseProps & {
  buttonLabel: string;
  buttonClick: () => void;
};

export type Props = EmptyStateProps | EmptyStateWithButtonProps;

const TableEmptyState = (props: Props) => {
  const {
    title = "It's a little empty here",
    description = 'Looks like there is no content to display here',
    buttonLabel,
    buttonClick,
  } = props;

  return (
    <Styled.Container>
      <Styled.EmptyHeader>
        <OmnipresentMark />

        <Styled.EmptyHeaderTitle>{title}</Styled.EmptyHeaderTitle>
      </Styled.EmptyHeader>

      <Styled.EmptyDescription wrapContent>
        {description}
      </Styled.EmptyDescription>

      {buttonLabel && (
        <Styled.EmptyActionButton
          variant="outlined"
          palette="primary"
          onClick={buttonClick}
        >
          {buttonLabel}
        </Styled.EmptyActionButton>
      )}
    </Styled.Container>
  );
};

export default TableEmptyState;
