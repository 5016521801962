import { useCallback, useState } from 'react';

import { DateTime } from 'luxon';

const isDateValid = (date: Date) => {
  return DateTime.fromJSDate(date).isValid;
};

const today = DateTime.utc().startOf('day').toJSDate();

const firstDayOfMonth = DateTime.utc()
  .startOf('month')
  .startOf('day')
  .toJSDate();

export const useDateRangeSelection = () => {
  const [startDate, setStartDate] = useState<Date>(firstDayOfMonth);
  const [endDate, setEndDate] = useState<Date>(today);

  const [startErrorMessage, setStartErrorMessage] = useState('');
  const [endErrorMessage, setEndErrorMessage] = useState('');

  const isError = Boolean(startErrorMessage || endErrorMessage);

  const validate = useCallback(() => {
    let startError = '';
    let endError = '';

    if (!startDate || !isDateValid(startDate)) {
      startError = 'Start date is required';
    }
    if (!endDate || !isDateValid(endDate)) {
      endError = 'End date is required';
    }

    if (!startError && !endError) {
      if ((startDate as Date) > (endDate as Date)) {
        startError = 'Start date should be before the end date';
        endError = 'End date should be after the start date';
      } else if ((startDate as Date) > new Date()) {
        startError = 'Start date cannot be in the future';
      }
    }

    setStartErrorMessage(startError);
    setEndErrorMessage(endError);
  }, [endDate, startDate]);

  return {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    startErrorMessage,
    endErrorMessage,
    isError,
    validate,
  };
};
