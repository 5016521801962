import { client } from 'api';
import {
  PayrollProviderApiResponse,
  PayrollProviderCreateApiRequest,
} from 'common/types';
import { removeFalsy } from 'utils/objects';

export const PAYROLL_PROVIDERS_BASE_API_ENDPOINT = '/payroll-providers';

export const getPayrollProviders = (
  params: { isActive?: boolean; lspId?: string } = {},
) => {
  return client.get<PayrollProviderApiResponse[]>(
    PAYROLL_PROVIDERS_BASE_API_ENDPOINT,
    {
      params: removeFalsy(params),
    },
  );
};

export const getPayrollProvider = (id: string) => {
  return client.get<PayrollProviderApiResponse>(
    `${PAYROLL_PROVIDERS_BASE_API_ENDPOINT}/${id}`,
  );
};

export const createPayrollProvider = (
  payrollProvider: PayrollProviderCreateApiRequest,
) => {
  return client.post(
    PAYROLL_PROVIDERS_BASE_API_ENDPOINT,
    payrollProvider,
    removeFalsy(payrollProvider),
  );
};

export const updatePayrollProvider = (
  id: string,
  payrollProviderUpdate: PayrollProviderCreateApiRequest,
) => {
  return client.put(
    `${PAYROLL_PROVIDERS_BASE_API_ENDPOINT}/${id}`,
    payrollProviderUpdate,
    removeFalsy(payrollProviderUpdate),
  );
};
