import { useMemo } from 'react';

import { ReactComponent as OmniAlertTriangleIcon } from 'assets/img/alert-triangle-icon.svg';
import { ReactComponent as OmniCloseIcon } from 'assets/img/x-close-icon.svg';
import { Loading } from 'common/components';
import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { GrossToNetFile } from 'common/types';
import { useDataProcessingErrorsQuery } from 'utils/queries/payroll/useDataProcessingErrors';

import { Button, Typography } from '@omnipresentgroup/design-system';

import * as S from './ViewErrorDetailsModal.styles';

export const ViewErrorDetailsModal = ({
  grossToNetFile,
  doneHandler,
  cancelHandler,
  testId = 'upload-errors-modal',
}: {
  grossToNetFile: GrossToNetFile;
  doneHandler: () => void;
  cancelHandler: () => void;
  testId?: string;
}) => {
  const { data = [], isLoading } = useDataProcessingErrorsQuery({
    dataFrameIdsList: [grossToNetFile.id],
  });

  const errors = useMemo(
    () =>
      data.reduce((acc, { message }) => {
        acc.add(message);
        return acc;
      }, new Set<string>()),
    [data],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ModalWrapper data-testid={testId} fixedWidth="600">
      <S.ModalContainer className="smallStack">
        <Typography as="h4" hideParagraphSpacing size="20" weight="medium">
          <S.TitleWrappper>
            {grossToNetFile.fileName} could not be processed
            <OmniCloseIcon onClick={cancelHandler} />
          </S.TitleWrappper>
        </Typography>
        <S.PayReportsData>
          <S.ErrorTitle>
            <OmniAlertTriangleIcon /> {grossToNetFile.country}{' '}
            <S.ProcessingError>Processing failed</S.ProcessingError>
          </S.ErrorTitle>
          <S.ErrorIntroductionText>
            Below you can find the errors we encountered while processing the
            file.
          </S.ErrorIntroductionText>
          <ul>
            {[...errors].map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </S.PayReportsData>

        <S.ButtonsContainer alignment="right">
          <Button onClick={doneHandler} variant="primary">
            Done
          </Button>
        </S.ButtonsContainer>
      </S.ModalContainer>
    </ModalWrapper>
  );
};
