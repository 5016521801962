import Joi from 'joi';

export const addressSchema = Joi.object()
  .keys({
    addressLine1: Joi.string().messages({
      'string.empty': 'Please enter a valid address',
    }),
    addressLine2: Joi.string().allow(null, ''),
    addressLine3: Joi.string().allow(null, ''),
    city: Joi.string().messages({
      'string.empty': 'Please enter a valid city',
    }),
    country: Joi.string().allow('', null).messages({
      'string.empty': 'Please enter a valid address',
    }),
    postalCode: Joi.string().messages({
      'string.empty': 'Please enter a valid postal code',
    }),
    state: Joi.string().allow('', null),
    hideAddressLines: Joi.boolean(),
    hidePostalCode: Joi.boolean(),
  })
  .messages({});

export const optionalAddressSchema = Joi.object()
  .keys({
    addressLine1: Joi.string().allow(null, ''),
    addressLine2: Joi.string().allow(null, ''),
    addressLine3: Joi.string().allow(null, ''),
    city: Joi.string().allow(null, ''),
    country: Joi.string().allow('', null),
    postalCode: Joi.string().allow(null, ''),
    state: Joi.string().allow('', null),
    hideAddressLines: Joi.boolean(),
    hidePostalCode: Joi.boolean(),
  })
  .messages({});
