import { Departments } from 'appConstants';
import Joi from 'joi';

export const managerSchema = Joi.object().keys({
  jobTitle: Joi.string().messages({
    'string.empty': 'Please enter a job title',
  }),
  department: Joi.string()
    .valid(...Departments)
    .messages({
      'string.empty': 'Please select a valid department',
    }),
});
