import { useEffect, useState } from 'react';

import { StyledToggle } from './Toggle.styles';

type ToggleProps = {
  name: string;
  onToggle: (name: string, newState: boolean) => void;
  toggleOn?: boolean;
  isDisabled?: boolean;
};

export const Toggle = ({
  name,
  onToggle,
  toggleOn = false,
  isDisabled = false,
}: ToggleProps) => {
  const [isToggled, setIsToggled] = useState(toggleOn);

  const handleChange = () => {
    setIsToggled((prev) => {
      const newState = !prev;
      onToggle(name, newState);
      return newState;
    });
  };

  useEffect(() => {
    setIsToggled(toggleOn);
  }, [toggleOn]);

  return (
    <StyledToggle data-testid="toggle" htmlFor={name}>
      <input
        type="checkbox"
        onChange={handleChange}
        checked={isToggled}
        data-testid={`${name}-toggle`}
        id={name}
        disabled={isDisabled}
      />
      <span className="slider round" data-testid={`${name}-toggle-slider`} />
    </StyledToggle>
  );
};
