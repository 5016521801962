import { getAllEmployeesPaginatedRequest } from 'common/api/employees.api';
import { transformEmployeesDataForPagination } from 'common/store/transformers/employeesTransformer';
import {
  EmployeeForPaginationView,
  PaginatedEmployeesApiResponse,
  PaginatedPayload,
} from 'common/types';
import { useInfiniteQuery } from 'react-query';
import { employeesKeys } from 'utils/queries/employees/keys';
import { getDataFromResponse } from 'utils/react-query-utils';

export const usePaginatedEmployeesInfiniteQuery = ({
  size,
  query,
}: {
  size?: number;
  query: PaginatedPayload;
}) => {
  const getQuery = (
    queryObjectKey: keyof PaginatedPayload,
  ): string | undefined => {
    if (query && query[queryObjectKey] !== '') {
      return query[queryObjectKey];
    }
    return undefined;
  };
  return useInfiniteQuery<
    PaginatedEmployeesApiResponse,
    Error,
    EmployeeForPaginationView[]
  >(
    employeesKeys.paginatedEmployees(
      getQuery('employeeName'),
      getQuery('employeeId'),
      getQuery('country'),
      getQuery('personalEmail'),
      getQuery('companyId'),
      getQuery('lspId'),
      getQuery('payrollId'),
      getQuery('externalId'),
      getQuery('onboardingStatus'),
    ),
    async ({ pageParam }) =>
      getAllEmployeesPaginatedRequest({
        nextPageKey: pageParam,
        size: size,
        employeeName: getQuery('employeeName'),
        employeeId: getQuery('employeeId'),
        country: getQuery('country'),
        personalEmail: getQuery('personalEmail'),
        companyId: getQuery('companyId'),
        lspId: getQuery('lspId'),
        payrollId: getQuery('payrollId'),
        externalId: getQuery('externalId'),
        onboardingStatus: getQuery('onboardingStatus'),
      }).then(getDataFromResponse),
    {
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      select: (data) => ({
        pages: data.pages.map((page) =>
          transformEmployeesDataForPagination(page.employees),
        ),
        pageParams: data.pageParams,
      }),
    },
  );
};
