import { AxiosError } from 'axios';
import { postBenefitsClientPreferences } from 'common/api/benefits.api';
import {
  BenefitsClientPreferencesNew,
  BenefitsClientPreferencesResponse,
} from 'common/types';
import { useMutation, useQueryClient } from 'react-query';

import { getDataFromResponse } from '../../../react-query-utils';
import { benefitsKeys } from '../keys';

export const useBenefitsClientPreferencesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    BenefitsClientPreferencesResponse,
    AxiosError,
    BenefitsClientPreferencesNew
  >(
    (data) => {
      return postBenefitsClientPreferences(data).then(getDataFromResponse);
    },
    {
      onSuccess: (data) => {
        const { clientId, countryCode } = data;

        queryClient.setQueryData(
          benefitsKeys.clientPreferences(clientId, countryCode),
          { ...data },
        );
      },
    },
  );
};
