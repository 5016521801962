import { useRef, useState } from 'react';

import { MoreHorizontal } from 'react-feather';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';

import { Spinner } from '@omnipresentgroup/design-system';

import * as S from './ActionsDropDown.styles';

export type ActionItem = {
  id: string;
  label: string;
  onClickHandler: () => Promise<void> | void;
  disabled?: boolean;
  loading?: boolean;
};

export type Props = {
  actions?: ActionItem[];
  disabled?: boolean;
};

export const ActionsDropDown = ({ actions = [], disabled }: Props) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const toggle = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  useOnClickOutside(elementRef, () =>
    setExpanded((prevExpanded) => !prevExpanded),
  );
  return (
    <S.DropDownContainer>
      <button onClick={toggle} aria-label="actions" disabled={disabled}>
        <MoreHorizontal />
      </button>
      {expanded && (
        <S.ActionsContainer className="smallerStack" ref={elementRef}>
          <S.ActionsContainerTitle>Actions</S.ActionsContainerTitle>
          <S.StyledActionDivider />
          <div className="smallerStack">
            {actions.length > 0 &&
              actions.map(
                ({ id, label, onClickHandler, disabled, loading }) => (
                  <S.ActionItem
                    key={id}
                    disabled={disabled || loading}
                    onClick={async () => {
                      await onClickHandler();
                      toggle();
                    }}
                  >
                    {loading && <Spinner size="16" />}
                    {label}
                  </S.ActionItem>
                ),
              )}
          </div>
        </S.ActionsContainer>
      )}
    </S.DropDownContainer>
  );
};
