import styled from 'styled-components';

export const StyledMobileCardListHeader = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme: { space } }) => space[64]};
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors['background-primary']};

  p {
    margin-left: ${({ theme: { space } }) => `${space[8]}`};
  }
`;
