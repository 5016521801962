export const countryResponse = {
  sys: {
    type: 'Array',
  },
  total: 1,
  skip: 0,
  limit: 300,
  items: [
    {
      fields: {
        name: 'Albania',
        flagImage: {
          metadata: {
            tags: [],
          },
          sys: {
            space: {
              sys: {
                type: 'Link',
                linkType: 'Space',
                id: 'r6sltkmz2348',
              },
            },
            id: '5Z9LsKDWC5VR7zcOErPvaT',
            type: 'Asset',
            createdAt: '2021-06-26T15:30:14.211Z',
            updatedAt: '2021-06-26T15:30:14.211Z',
            environment: {
              sys: {
                id: 'master',
                type: 'Link',
                linkType: 'Environment',
              },
            },
            revision: 1,
            locale: 'en-US',
          },
          fields: {
            title: 'AL Flag',
            file: {
              url: '//images.ctfassets.net/r6sltkmz2348/5Z9LsKDWC5VR7zcOErPvaT/aff2b6088b4c3401e04c64114eadde8a/al.svg',
              details: {
                size: 8625,
                image: {
                  width: 980,
                  height: 700,
                },
              },
              fileName: 'al.svg',
              contentType: 'image/svg+xml',
            },
          },
        },
      },
      sys: {
        id: 'albania',
        type: 'Entry',
      },
      metadata: {
        tags: [
          {
            sys: {
              type: 'Link',
              linkType: 'Tag',
              id: 'europe',
            },
          },
          {
            sys: {
              type: 'Link',
              linkType: 'Tag',
              id: 'southern-europe',
            },
          },
        ],
      },
    },
  ],
  includes: {
    Asset: [
      {
        metadata: {
          tags: [],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'r6sltkmz2348',
            },
          },
          id: '5Z9LsKDWC5VR7zcOErPvaT',
          type: 'Asset',
          createdAt: '2021-06-26T15:30:14.211Z',
          updatedAt: '2021-06-26T15:30:14.211Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 1,
          locale: 'en-US',
        },
        fields: {
          title: 'AL Flag',
          file: {
            url: '//images.ctfassets.net/r6sltkmz2348/5Z9LsKDWC5VR7zcOErPvaT/aff2b6088b4c3401e04c64114eadde8a/al.svg',
            details: {
              size: 8625,
              image: {
                width: 980,
                height: 700,
              },
            },
            fileName: 'al.svg',
            contentType: 'image/svg+xml',
          },
        },
      },
    ],
  },
};

export const territoriesResponse = {
  sys: {
    type: 'Array',
  },
  total: 3,
  skip: 0,
  limit: 300,
  items: [
    {
      fields: {
        name: 'Albania',
        flagImage: {
          sys: {
            type: 'Link',
            linkType: 'Asset',
            id: '1onfdDyniq9iCp6UAh2Ehd',
          },
        },
      },
      metadata: {
        tags: [],
      },
      sys: {
        id: 'albania',
        type: 'Entry',
      },
    },
    {
      fields: {
        name: 'Cuba',
        flagImage: {
          sys: {
            type: 'Link',
            linkType: 'Asset',
            id: '1TqqOV58JsVw233NT04b2D',
          },
        },
      },
      metadata: {
        tags: [
          {
            sys: {
              type: 'Link',
              linkType: 'Tag',
              id: 'globalSanctions',
            },
          },
        ],
      },
      sys: {
        id: 'cuba',
        type: 'Entry',
      },
    },
    {
      fields: {
        name: 'United States',
        flagImage: {
          sys: {
            type: 'Link',
            linkType: 'Asset',
            id: '2Ag30kimKdQ7UlaLixd9am',
          },
        },
        keyFact: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  value: 'Social Costs',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'The USA has low/mid employer costs that cover Social Security Tax, Medicare, Federal Unemployment Tax, State Unemployment and Employment Training Tax as well as some state by state taxes. \nThese employer contributions are subject to contribution maximums, so the relative % contribution diminishes as salary increases. This usually results in contributions ranging from ~7-15%.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Each state has their own contribution maximums, so some states are more expensive to hire in than others - some notable ones include New Jersey, Oregon and Washington.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  value: 'Termination Process',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Employment is at will, but typically terminated with 2 weeks notice',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  value: 'Non-Compete',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Please note that US law does not like to enforce non-compete restrictions against employees. This is particularly the case in California where it is virtually impossible. In other states it may be possible but in limited circumstances, and with a very specific and limited scope.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
      },
      metadata: {
        tags: [
          {
            sys: {
              type: 'Link',
              linkType: 'Tag',
              id: 'invisible',
            },
          },
        ],
      },
      sys: {
        id: '6rfraql0CIUvfGp61vmWnO',
        type: 'Entry',
      },
    },
    {
      fields: {
        name: 'Germany',
        flagImage: {
          sys: {
            type: 'Link',
            linkType: 'Asset',
            id: '2Ag30kimKdQ7UlaLixd9am',
          },
        },
        keyFact: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  value: 'Social Costs',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Germany has low/mid employer costs that cover Social Security Tax, Medicare, Federal Unemployment Tax, State Unemployment and Employment Training Tax as well as some state by state taxes. \nThese employer contributions are subject to contribution maximums, so the relative % contribution diminishes as salary increases. This usually results in contributions ranging from ~7-15%.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Each state has their own contribution maximums, so some states are more expensive to hire in than others - some notable ones include New Jersey, Oregon and Washington.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  value: 'Termination Process',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Employment is at will, but typically terminated with 2 weeks notice',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  value: 'Non-Compete',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Please note that US law does not like to enforce non-compete restrictions against employees. This is particularly the case in California where it is virtually impossible. In other states it may be possible but in limited circumstances, and with a very specific and limited scope.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
      },
      metadata: {
        tags: [
          {
            sys: {
              type: 'Link',
              linkType: 'Tag',
              id: 'invisible',
            },
          },
        ],
      },
      sys: {
        id: '6rfraql0CIUvfGp61vmWnO',
        type: 'Entry',
      },
    },
  ],
  includes: {
    Asset: [
      {
        metadata: {
          tags: [],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'ndtlvna7af57',
            },
          },
          id: '1onfdDyniq9iCp6UAh2Ehd',
          type: 'Asset',
          createdAt: '2021-06-28T11:13:25.103Z',
          updatedAt: '2021-06-28T11:13:25.103Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 1,
          locale: 'en-US',
        },
        fields: {
          title: 'AL Flag',
          file: {
            url: '//images.ctfassets.net/ndtlvna7af57/1onfdDyniq9iCp6UAh2Ehd/c296614a6f2b95815020e96cc7a7e667/al.svg',
            details: {
              size: 8625,
              image: {
                width: 980,
                height: 700,
              },
            },
            fileName: 'al.svg',
            contentType: 'image/svg+xml',
          },
        },
      },
      {
        metadata: {
          tags: [],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'ndtlvna7af57',
            },
          },
          id: '1TqqOV58JsVw233NT04b2D',
          type: 'Asset',
          createdAt: '2021-06-28T11:13:47.819Z',
          updatedAt: '2021-06-28T11:13:47.819Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 1,
          locale: 'en-US',
        },
        fields: {
          title: 'CU Flag',
          file: {
            url: '//images.ctfassets.net/ndtlvna7af57/1TqqOV58JsVw233NT04b2D/ab56f3f01ee389aad8d1c733f3196975/cu.svg',
            details: {
              size: 324,
              image: {
                width: 800,
                height: 400,
              },
            },
            fileName: 'cu.svg',
            contentType: 'image/svg+xml',
          },
        },
      },
      {
        metadata: {
          tags: [],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'ndtlvna7af57',
            },
          },
          id: '3lj7NmlIpbmh8S9PXeQBjv',
          type: 'Asset',
          createdAt: '2021-06-28T11:13:30.117Z',
          updatedAt: '2021-06-28T11:13:30.117Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 1,
          locale: 'en-US',
        },
        fields: {
          title: 'US Flag',
          file: {
            url: '//images.ctfassets.net/ndtlvna7af57/3lj7NmlIpbmh8S9PXeQBjv/fb24a43de77999c13e75902945f38afe/us.svg',
            details: {
              size: 741,
              image: {
                width: 7410,
                height: 3900,
              },
            },
            fileName: 'us.svg',
            contentType: 'image/svg+xml',
          },
        },
      },
    ],
  },
};
