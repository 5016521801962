import { BillType, GrossToNetFileLSPData, LSPData } from 'common/types';
import { DateTime } from 'luxon';
import { CURRENCY_CODE_OPTIONS } from 'utils/currencies';

import {
  DateInput,
  Dropdown,
  formatJSDate,
  Inline,
  Input,
  RadioButtonGroup,
  Stack,
} from '@omnipresentgroup/design-system';

export const POSTING_PERIOD_FORMAT = 'yyyyMM';

export const getDefaultLSPData = (): LSPData => ({
  lspBillingCurrency: '',
  lspExchangeRate: 1,
  lspBillNumber: '',
  lspBillUrl: '',
  invoiceDate: new Date(),
  invoiceDueDate: new Date(),
  invoiceAmount: 0,
  memo: '',
  postingPeriod: formatJSDate(new Date(), POSTING_PERIOD_FORMAT),
  billType: BillType.BILL,
});

export const getDefaultLSPDataFromGTNLSPData = (
  gtnLspData: GrossToNetFileLSPData,
): LSPData => {
  const defaultLSPData = getDefaultLSPData();
  return {
    lspBillNumber: gtnLspData.lspBillNumber || defaultLSPData.lspBillNumber,
    lspBillUrl: gtnLspData.lspBillUrl || defaultLSPData.lspBillUrl,
    lspBillingCurrency:
      gtnLspData.lspBillingCurrency || defaultLSPData.lspBillingCurrency,
    lspExchangeRate: gtnLspData.lspExchangeRate
      ? Number(gtnLspData.lspExchangeRate)
      : defaultLSPData.lspExchangeRate,
    invoiceDate: gtnLspData.invoiceDate ?? defaultLSPData.invoiceDate,
    invoiceDueDate: gtnLspData.invoiceDueDate ?? defaultLSPData.invoiceDueDate,
    invoiceAmount: gtnLspData.invoiceAmount
      ? Number(gtnLspData.invoiceAmount)
      : defaultLSPData.invoiceAmount,
    memo: gtnLspData.memo ?? defaultLSPData.memo,
    postingPeriod: gtnLspData.postingPeriod ?? defaultLSPData.postingPeriod,
    billType: gtnLspData.billType || defaultLSPData.billType,
  };
};

export const LSPDataFields = ({
  lspData,
  setLspData,
  readOnly = false,
}: {
  lspData: GrossToNetFileLSPData;
  setLspData: React.Dispatch<React.SetStateAction<LSPData>>;
  readOnly?: boolean;
}) => {
  const billTypeRadioOptions = Object.values(BillType).map((s, i) => ({
    id: `radio-btn-bill-type-${i}`,
    label: s,
    checked: lspData.billType === s,
    value: s,
  }));

  return (
    <Stack>
      {lspData.billType !== null && (
        <RadioButtonGroup
          id="radio-btn-group-bill-type"
          label="Bill Type"
          readOnly={readOnly}
          onChange={(e) => {
            const id = e.target.id;
            const selectedOption = billTypeRadioOptions.find(
              (option) => option.id === id,
            );

            if (!selectedOption) {
              return;
            }
            setLspData((oldData) => ({
              ...oldData,
              billType: selectedOption.value,
            }));
          }}
          options={billTypeRadioOptions}
        />
      )}
      <Inline stretch="all">
        {lspData.invoiceDate !== null && (
          <DateInput
            id="invoice-date-input"
            label="Date of Invoice"
            readOnly={readOnly}
            tooltipProps={{
              description: 'The date the invoice was issued',
              id: 'invoice-date-input-tooltip',
            }}
            selected={lspData.invoiceDate}
            onChange={(date) => {
              if (date !== null) {
                setLspData((oldData) => ({
                  ...oldData,
                  invoiceDate: date,
                }));
              }
            }}
          />
        )}
        {lspData.invoiceDate !== null && (
          <DateInput
            id="invoice-due-date-input"
            label="Due date"
            readOnly={readOnly}
            tooltipProps={{
              description: 'The due date on the invoice',
              id: 'invoice-due-date-input-tooltip',
            }}
            selected={lspData.invoiceDueDate}
            onChange={(date) => {
              if (date !== null) {
                setLspData((oldData) => ({
                  ...oldData,
                  invoiceDueDate: date,
                }));
              }
            }}
          />
        )}
      </Inline>
      <Inline mt="8" stretch="all">
        <Dropdown
          id="currency-dropdown"
          label="Billing Currency"
          isReadOnly={readOnly}
          isMulti={false}
          isSearchable={true}
          options={CURRENCY_CODE_OPTIONS}
          value={CURRENCY_CODE_OPTIONS.find(
            (opt) => opt.value === lspData.lspBillingCurrency,
          )}
          onChange={(e) => {
            if (e) {
              setLspData((oldData) => ({
                ...oldData,
                lspBillingCurrency: e.value.toString(),
              }));
            }
          }}
        />
        {lspData.invoiceAmount !== null && (
          <Input
            id="amount-input"
            label="Invoice amount"
            readOnly={readOnly}
            step="0.01"
            type="number"
            value={lspData.invoiceAmount}
            onChange={(e) => {
              const newValue = parseFloat(e.target.value);
              setLspData((oldData) => ({
                ...oldData,
                invoiceAmount: isNaN(newValue) ? 0 : newValue,
              }));
            }}
          />
        )}
        <Input
          id="exchange-rate-input"
          label="Exchange rate"
          readOnly={readOnly}
          step="0.01"
          type="number"
          min="0"
          value={lspData.lspExchangeRate}
          onChange={(e) => {
            const newValue = parseFloat(e.target.value);
            setLspData((oldData) => ({
              ...oldData,
              lspExchangeRate: isNaN(newValue) ? 1 : newValue,
            }));
          }}
        />
      </Inline>
      <Input
        id="lsp-bill-number-input"
        label="LSP Bill Number / Reference Number"
        placeholder="LSP Bill Number"
        readOnly={readOnly}
        value={lspData.lspBillNumber}
        onChange={(e) => {
          setLspData((oldData) => ({
            ...oldData,
            lspBillNumber: e.target.value,
          }));
        }}
      />
      <Inline stretch="all">
        {lspData.memo !== null && (
          <Input
            id="memo-input"
            label="Invoice memo"
            placeholder="Memo"
            readOnly={readOnly}
            value={lspData.memo}
            onChange={(e) => {
              setLspData((oldData) => ({
                ...oldData,
                memo: e.target.value,
              }));
            }}
          />
        )}
        {lspData.postingPeriod !== null && (
          <DateInput
            id="posting-period-input"
            label="Posting Period"
            readOnly={readOnly}
            showMonthYearPicker
            useUTC={false}
            selected={DateTime.fromFormat(
              lspData.postingPeriod,
              POSTING_PERIOD_FORMAT,
            ).toJSDate()}
            onChange={(date) => {
              if (date !== null) {
                const dateString = formatJSDate(date, POSTING_PERIOD_FORMAT);
                setLspData((oldData) => ({
                  ...oldData,
                  postingPeriod: dateString,
                }));
              }
            }}
          />
        )}
      </Inline>
      <Input
        id="lsp-bill-url-input"
        label="LSP Bill Url"
        placeholder="LSP Bill Url"
        readOnly={readOnly}
        value={lspData.lspBillUrl}
        onChange={(e) => {
          setLspData((oldData) => ({
            ...oldData,
            lspBillUrl: e.target.value,
          }));
        }}
      />
    </Stack>
  );
};
