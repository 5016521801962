import avatarPlaceholderPicture from 'assets/img/avatar-placeholder.png';
import PropTypes from 'prop-types';

import {
  StyledName,
  StyledNameTableCell,
  StyledProfilePicture,
} from './NameTableCell.styles';

const NameTableCell = ({ name }) => (
  <StyledNameTableCell data-testid="name-table-cell">
    <StyledProfilePicture
      src={avatarPlaceholderPicture}
      data-testid="name-cell-profile-pic"
    />
    <div>
      <StyledName>{name}</StyledName>
    </div>
  </StyledNameTableCell>
);

NameTableCell.propTypes = {
  name: PropTypes.string.isRequired,
};

export default NameTableCell;
