import { useRef, useState } from 'react';

import isString from 'lodash/isString';
import { Icon } from 'react-feather';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';

import {
  ActionIcon,
  ActionItem,
  ActionItemHeading,
  ActionItemIcon,
  ActionItemWithIconContainer,
  ActionPopupContainer,
  StyledActionDiver,
  StyledDotsIcon,
  Wrapper,
} from './ActionCell.styles';

type Action = { label: string; disabled?: boolean; icon?: Icon };
type ActionCellProps = {
  actions: (string | Action)[];
  onActionClick: (action: string, e: React.MouseEvent<HTMLDivElement>) => void;
  align?: string;
  title?: string;
  hoverColor?: boolean;
  onDotsClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  showHeading?: boolean;
};

export const ActionCell = ({
  actions,
  align,
  onActionClick,
  title,
  hoverColor,
  onDotsClick,
  showHeading = true,
}: ActionCellProps) => {
  const [showActionPopup, setShowActionPopup] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement>(null);

  //to be backwards compatible, convert all string arrays to object arrays
  const isActionStringArray = actions.every((action: string | Action) =>
    isString(action),
  );
  const modifiedActions: Action[] = !isActionStringArray
    ? (actions as Action[])
    : actions.map((action: any) => ({
        label: action,
      }));

  useOnClickOutside(elementRef, () => setShowActionPopup(false));

  const handleActionClick = (
    action: Action,
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    if (!action.disabled) {
      onActionClick(action.label, e);
      setShowActionPopup(false);
    }
  };

  return (
    <Wrapper ref={elementRef} data-testid="action-cell-container">
      <ActionIcon
        data-testid="dot-action"
        tabIndex={0}
        onClick={(e) => {
          onDotsClick && onDotsClick(e);
          setShowActionPopup(true);
        }}
      >
        <StyledDotsIcon />
      </ActionIcon>
      {showActionPopup && (
        <ActionPopupContainer align={align} $noPadding={!showHeading}>
          {showHeading && (
            <>
              <ActionItemHeading>{title || 'Actions'}</ActionItemHeading>
              <StyledActionDiver />
            </>
          )}
          {modifiedActions.map((action, index) => {
            const { icon: Icon } = action;
            return (
              <ActionItem
                tabIndex={0}
                data-testid={`action-item-${
                  action.label.split(' ').length > 1
                    ? action.label.replace(/\s/g, '-').toLocaleLowerCase()
                    : action.label
                }`}
                key={index}
                disabled={action.disabled}
                hoverColor={hoverColor}
                onClick={(e) => handleActionClick(action, e)}
              >
                {Icon ? (
                  <ActionItemWithIconContainer>
                    <ActionItemIcon>
                      <Icon />
                    </ActionItemIcon>
                    {action.label}
                  </ActionItemWithIconContainer>
                ) : (
                  action.label
                )}
              </ActionItem>
            );
          })}
        </ActionPopupContainer>
      )}
    </Wrapper>
  );
};
