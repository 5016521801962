import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { subject } from '@casl/ability';
import { useAppAbility } from 'app/store/ability';
import { getLoadingSelector } from 'app/store/app.selectors';
import AddDocumentPage from 'common/pages/AddDocumentPage/AddDocumentPage';
import {
  clearDocumentCreatedAction,
  createDocumentAction,
  getDocumentsAction,
} from 'common/store/actions/documents.actions';
import { getEmployeeAction } from 'common/store/actions/employee.actions';
import {
  getDocumentCreatedSelector,
  getDocumentsSelector,
} from 'common/store/selectors/document.selectors';
import { EMPLOYEES_PATH } from 'paths';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getSelectedEmployeeSelector } from '../../store/selectors/employees.selector';

const AddEmployeeDocumentPage = ({
  addDocument,
  selectedEmployee,
  clearDocumentCreated,
  documents,
  getDocuments,
  getEmployee,
}) => {
  const { employeeId } = useParams();
  const ability = useAppAbility();

  useEffect(() => () => clearDocumentCreated(), [clearDocumentCreated]);

  const canAddDocument = ability.can(
    'documents:create',
    subject('Documents', { ownerId: selectedEmployee.userId }),
  );

  useEffect(() => {
    getEmployee(employeeId);
  }, [employeeId, getEmployee]);

  useEffect(() => {
    if (!documents && selectedEmployee.userId) {
      getDocuments({ userId: selectedEmployee.userId });
    }
  }, [selectedEmployee.userId, getDocuments, documents]);

  const onFormSubmit = (formStages) => {
    addDocument({
      documentInfo: formStages,
      tenantId: selectedEmployee.managerId,
      userId: selectedEmployee.userId,
      returnPath: `${EMPLOYEES_PATH}/${selectedEmployee.id}#documents`,
    });
  };

  return (
    <AddDocumentPage
      onDocumentFormSubmitted={onFormSubmit}
      canAddDocument={canAddDocument}
      copy="Uploaded documents will be visible to the employee."
    />
  );
};

AddEmployeeDocumentPage.propTypes = {
  addDocument: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.oneOfType([PropTypes.object]).isRequired,
  clearDocumentCreated: PropTypes.func.isRequired,
  getDocuments: PropTypes.func.isRequired,
  documents: PropTypes.array,
  getEmployee: PropTypes.func.isRequired,
};

AddEmployeeDocumentPage.defaultProps = {
  documents: undefined,
};

const mapStateToProps = (state) => ({
  loading: getLoadingSelector(state),
  selectedEmployee: getSelectedEmployeeSelector(state),
  documents: getDocumentsSelector(state),
  documentCreated: getDocumentCreatedSelector(state),
});

const mapDispatchToProps = {
  addDocument: createDocumentAction,
  getDocuments: getDocumentsAction,
  clearDocumentCreated: clearDocumentCreatedAction,
  getEmployee: getEmployeeAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEmployeeDocumentPage);
