import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { PaginationOptions } from '@tanstack/react-table';
import { PageContent } from 'app/App.styles';
import Big from 'big.js';
import { Loading, PageHeader } from 'common/components';
import {
  getGtnProcessingDisplayStatus,
  GrossToNetSource,
  PayrollJustificationAPIResponse,
  PayrollReportDataCurrency,
} from 'common/types';
import {
  EmployeeReportData,
  GrossToNetFile,
  GrossToNetProcessingStatus,
  PayrollReportPreviewApiResponse,
} from 'common/types/payroll';
import { startCase } from 'lodash';
import { DateTime } from 'luxon';
import { LspDataModal } from 'omniplatform/admin/pages/PayReportPreviewPage/components/LspDataModal';
import {
  PayrollInstanceDetailPageContext,
  VisibleModalEnum,
} from 'omniplatform/admin/pages/PayrollInstanceDetailPage/PayrollInstanceDetailPageContext';
import { ConfirmDeleteLspBillModal } from 'omniplatform/admin/pages/PayrollInstanceLspBillDetailPage/components/ConfirmDeleteLspBillModal';
import { PayrollContextWrapper } from 'omniplatform/admin/pages/PayrollPage/PayrollContextWrapper';
import { PAYROLL_INSTANCE_DETAIL_PAGE } from 'paths';
import { getCountryByCountryCode } from 'utils/countries';
import {
  useLspQuery,
  usePayrollJustificationsByGTNIDsQuery,
} from 'utils/queries';
import { useDataProcessingErrorsQuery } from 'utils/queries/payroll/useDataProcessingErrors';
import { useGrossToNetDetailViewQuery } from 'utils/queries/payroll/useGrossToNetDetaillViewQuery';
import { usePaginatedGrossToNetFilesQuery } from 'utils/queries/payroll/useGrossToNetFilesQuery';
import { usePayReportPreviewBulkQuery } from 'utils/queries/payroll/usePayReportPreviewBulkQuery';

import { Button, Inline, Input, Stack } from '@omnipresentgroup/design-system';

import { StatusHistoryModal } from '../PayReportPreviewPage/components/StatusHistoryModal';
import {
  NEW_STATUSES_CONFIG,
  PROCESSING_STATUS_TRANSITIONS_MAP,
} from '../PayReportPreviewPage/PayReportPreviewPage';
import {
  getPayReportViewFilterPayrollPeriod,
  PAY_CYCLES,
  PayReportViewFilter,
} from '../PayReportPreviewPage/PayReportViewFilter';
import { LspBillDetailTable } from './components/LspBillDetailTable';
import { LSPBillJustificationModal } from './components/LSPBillJustificationModal';
import { LSPBillsDetailHeader } from './components/LSPBillsDetailHeader';
import {
  useApprove,
  useDelete,
  useExport,
  usePostApprovalTransiton,
  usePublish,
} from './hooks';

type Totals = Record<string, string> & { Total: string };

export type RowData = {
  name: string;
  employeeId?: string;
  status: GrossToNetProcessingStatus;
  hasJustification: boolean;
  totals: Totals;
  variancesPreviousTotals?: Totals;
  gtnId: string;
  lspData?: GrossToNetFile['lspData'];
};

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE_INDEX = 0;

const calculateEmployeeTotals = (employee: EmployeeReportData) => {
  return employee.concepts.reduce<{
    totals: Totals;
    variancesPreviousTotals: Totals;
  }>(
    (acc, concept) => {
      if (concept.inputType !== 'Input') {
        return acc;
      }
      const conceptValue = Number(concept.value.replace(',', '') || '0');

      const columnName = concept.omniplatform;

      acc.totals[columnName] = Big(acc.totals[columnName] || '0')
        .add(conceptValue)
        .toString();
      acc.totals.Total = Big(acc.totals.Total).add(conceptValue).toString();

      if (concept.variances) {
        const previousConceptValue = Number(
          concept.variances.previous.replace(',', '') || '0',
        );
        acc.variancesPreviousTotals[columnName] = Big(
          acc.variancesPreviousTotals[columnName] || '0',
        )
          .add(previousConceptValue)
          .toString();
        acc.variancesPreviousTotals.Total = Big(
          acc.variancesPreviousTotals.Total || '0',
        )
          .add(previousConceptValue)
          .toString();
      }

      return acc;
    },
    { totals: { Total: '0' }, variancesPreviousTotals: { Total: '' } },
  );
};

const createTableData = (
  grossToNetFiles: GrossToNetFile[],
  payReports: PayrollReportPreviewApiResponse[],
  employeeSearchValue: string,
  justifications: PayrollJustificationAPIResponse[],
) => {
  const justificatiosnMap = justifications.reduce<
    Map<string, PayrollJustificationAPIResponse>
  >((acc, justification) => {
    acc.set(
      `${justification.grossToNetId}${
        justification.employeeId ? `-${justification.employeeId}` : ''
      }`,
      justification,
    );
    return acc;
  }, new Map());
  return grossToNetFiles.reduce<RowData[]>((acc, grossToNet) => {
    const matchingPayReport = payReports.find(
      (payReport) => payReport.reportSummary.gtnId === grossToNet.id,
    );

    if (!matchingPayReport) {
      const [identifier, index, ...filename] = grossToNet.fileName.split('-');

      let name = grossToNet.fileName;

      // support for older file format
      if (!isNaN(Number(index))) {
        name = `${identifier} ${Big(index).add(1).toString()} ${filename.join(
          '-',
        )}`;
      }

      acc.push({
        name,
        gtnId: grossToNet.id,
        hasJustification: justificatiosnMap.has(grossToNet.id),
        status: grossToNet.processingStatus,
        totals: { Total: '0' },
        lspData: grossToNet.lspData,
      });

      return acc;
    }

    matchingPayReport.employees.items.forEach((employee) => {
      const fullName = `${employee.firstName} ${employee.lastName}`;
      if (
        employeeSearchValue &&
        !fullName.toLowerCase().includes(employeeSearchValue.toLowerCase())
      ) {
        return acc;
      }

      acc.push({
        name: `${employee.firstName} ${employee.lastName}`,
        employeeId: employee.employeeId,
        gtnId: grossToNet.id,
        hasJustification: justificatiosnMap.has(
          `${grossToNet.id}-${employee.employeeId}`,
        ),
        status: grossToNet.processingStatus,
        ...calculateEmployeeTotals(employee),
        lspData: grossToNet.lspData,
      });
    });
    return acc;
  }, []);
};

const LSP_BILLS_PROCESSING_STATUS_TRANSITIONS_MAP = {
  ...PROCESSING_STATUS_TRANSITIONS_MAP,
  // Only allowed here in lsp bills because of direct publish
  DUPLICATES_CHECKED: new Set([
    GrossToNetProcessingStatus.APPROVED,
    GrossToNetProcessingStatus.PUBLISHED,
  ]),
};

export const canBeDeleted = (status: GrossToNetProcessingStatus) =>
  status !== GrossToNetProcessingStatus.APPROVED &&
  status !== GrossToNetProcessingStatus.PUBLISHED;

const getNextStatusesActionsForSelection = (
  selectedGrossToNets: { gtnId: string; status: GrossToNetProcessingStatus }[],
  disable: boolean,
  callbacks: {
    onApprove: () => void;
    onPostApprovalTransition: (newStatus: GrossToNetProcessingStatus) => void;
    onPublish: () => void;
    onDelete: () => void;
    onExport: () => void;
  },
) => {
  const selectedStatuses = new Set<GrossToNetProcessingStatus>();
  selectedGrossToNets.forEach(({ status }) => {
    selectedStatuses.add(status);
  });

  const selectedStatusesArray = [...selectedStatuses];
  if (selectedStatusesArray.length < 1) {
    return [];
  }

  const canDelete = [...selectedStatuses].every(canBeDeleted);
  const initialNextStatusesSet = new Set<GrossToNetProcessingStatus>(
    LSP_BILLS_PROCESSING_STATUS_TRANSITIONS_MAP[selectedStatusesArray[0]],
  );

  const nextStatuses = selectedStatusesArray.reduce((acc, selectedStatus) => {
    if (acc.size < 1) {
      return acc;
    }

    const newStatuses =
      LSP_BILLS_PROCESSING_STATUS_TRANSITIONS_MAP[selectedStatus];
    if (!newStatuses) {
      acc.clear();
      return acc;
    }

    acc.forEach((intersectionStatus) => {
      if (!newStatuses.has(intersectionStatus)) {
        acc.delete(intersectionStatus);
      }
    });

    return acc;
  }, initialNextStatusesSet);

  const actionButtons = [...nextStatuses].map((newStatus) => {
    if (newStatus === GrossToNetProcessingStatus.APPROVED) {
      return (
        <Button
          key="approve"
          data-testid="approve-pay-report-button"
          onClick={callbacks.onApprove}
          variant="primary"
          disabled={disable}
        >
          Start Verification
        </Button>
      );
    }

    const buttonVariant =
      NEW_STATUSES_CONFIG[newStatus]?.action.buttonVariant || 'primary';
    return (
      <Button
        key={newStatus}
        data-testid={`${newStatus.toLowerCase()}-pay-report-button`}
        onClick={() => {
          newStatus === GrossToNetProcessingStatus.PUBLISHED
            ? callbacks.onPublish()
            : callbacks.onPostApprovalTransition(newStatus);
        }}
        variant={buttonVariant}
        disabled={disable}
      >
        {NEW_STATUSES_CONFIG[newStatus]?.action.label ||
          getGtnProcessingDisplayStatus(newStatus)}
      </Button>
    );
  });

  return [
    ...(canDelete
      ? [
          <Button
            key="delete"
            data-testid="delete-pay-report-button"
            onClick={callbacks.onDelete}
            color="negative"
            variant="secondary"
            icon="Trash"
            disabled={disable}
          >
            Delete
          </Button>,
        ]
      : []),
    <Button
      key="export"
      disabled={disable}
      variant="secondary"
      onClick={callbacks.onExport}
    >
      Export as CSV
    </Button>,
    <Inline ml="auto" key="workflow-actions">
      {actionButtons}
    </Inline>,
  ];
};

const Page = () => {
  const { payrollInstanceId } = useParams<{ payrollInstanceId: string }>();
  const location = useLocation();
  const history = useHistory();
  const { setVisibleModal, visibleModal } = useContext(
    PayrollInstanceDetailPageContext,
  );
  const [fileHistoryInfo, setFileHistoryInfo] = useState<{
    gtnId: string;
    fileName: string;
  } | null>(null);
  const [lspDataInfo, setLspDataInfo] = useState<{
    gtnId: string;
    canEdit: boolean;
    lspData: GrossToNetFile['lspData'] | null;
  } | null>(null);
  const [showEmployee, setShowEmployee] = useState<RowData | null>(null);
  const [employeeSearchValue, setEmployeeSearchValue] = useState('');
  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});
  const [{ pageSize, pageIndex }, setPagination] = useState({
    pageIndex: Number(DEFAULT_PAGE_INDEX),
    pageSize: Number(DEFAULT_PAGE_SIZE),
  });
  const [payReportViewFilter, setPayReportViewFilter] =
    useState<PayReportViewFilter>({
      showJustification: false,
      showVariance: false,
      year: DateTime.now().get('year').toString(),
      month: DateTime.now().minus({ months: 1 }).get('month').toString(),
      payCycle: PAY_CYCLES[0],
    });

  const searchParams = new URLSearchParams(location.search);

  const backUrl = `${PAYROLL_INSTANCE_DETAIL_PAGE(
    payrollInstanceId,
  )}#lsp-bills`;

  const country = searchParams.get('country') || undefined;
  const lspId = searchParams.get('lspId') || undefined;
  const payrollCycle = searchParams.get('payrollCycle') || undefined;
  const payrollPeriod = searchParams.get('payrollPeriod') || undefined;

  const {
    data: grossToNetFileData,
    isLoading: isLoadingGtnFiles,
    refetch: reloadGtnFiles,
    isFetching: isFetchingGtnFiles,
  } = usePaginatedGrossToNetFilesQuery({
    payrollPeriod,
    source: GrossToNetSource.MANUAL,
    country,
    lspId,
    payrollCycle,
    pageSize,
    pageIndex,
  });

  const grossToNetFiles = useMemo(() => {
    return grossToNetFileData?.items || [];
  }, [grossToNetFileData]);

  const grossToNetIds = useMemo(() => {
    return grossToNetFiles.map((grossToNetFile) => grossToNetFile.id);
  }, [grossToNetFiles]);

  const payReportViewFilterPayrollPeriod =
    getPayReportViewFilterPayrollPeriod(payReportViewFilter);

  const { data: payReports = [], isFetching: isLoadingPayReports } =
    usePayReportPreviewBulkQuery(
      grossToNetIds,
      PayrollReportDataCurrency.BILLED,
      ...(payReportViewFilter.showVariance
        ? [payReportViewFilterPayrollPeriod, payReportViewFilter.payCycle]
        : []),
    );
  const {
    data: dataProcessingErrors = [],
    isLoading: isLoadingDataProcessingErrors,
  } = useDataProcessingErrorsQuery({ dataFrameIdsList: grossToNetIds });
  const { data: lspData, isLoading: isLoadingLspInfo } = useLspQuery(
    lspId || '',
  );
  const {
    data: gtnDetails,
    refetch: reloadGtnDetails,
    isLoading: isLoadingGtnDetails,
  } = useGrossToNetDetailViewQuery(country, lspId, payrollCycle, payrollPeriod);

  const { data: justifications, refetch: reloadJustifications } =
    usePayrollJustificationsByGTNIDsQuery(grossToNetIds);

  const isLoading = isLoadingLspInfo;

  const isLoadingTable =
    isLoadingPayReports || isLoadingGtnFiles || isLoadingDataProcessingErrors;

  const tableData: RowData[] = useMemo(() => {
    if (isLoadingTable) {
      return [];
    }

    return createTableData(
      grossToNetFiles,
      payReports,
      employeeSearchValue,
      justifications || [],
    );
  }, [
    isLoadingTable,
    grossToNetFiles,
    payReports,
    employeeSearchValue,
    justifications,
  ]);

  const selectedGrossToNets = useMemo(() => {
    const sel = Object.entries(rowSelection).reduce<
      { gtnId: string; status: GrossToNetProcessingStatus }[]
    >((acc, selectedRow) => {
      const [index, selected] = selectedRow;
      if (!selected || !tableData || !tableData[Number(index)]) {
        return acc;
      }
      const { gtnId, status } = tableData[Number(index)];
      return [...acc, { gtnId, status }];
    }, []);

    return sel;
  }, [rowSelection, tableData]);

  const gtnsInfo =
    country && lspId && payrollCycle && payrollPeriod
      ? {
          country,
          lspId,
          payrollCycle,
          payrollPeriod,
        }
      : undefined;

  const actionsParam = {
    setRowSelection,
    selectedGrossToNets,
    gtnsInfo,
    reloadGtnFiles: () => {
      reloadGtnDetails();
      reloadGtnFiles();
    },
    tableData,
  };

  const { isExportingGrossToNets, onExportCsvClick } = useExport(actionsParam);
  const { isDeletingGrossToNets, onDeletePayReportsClick } =
    useDelete(actionsParam);
  const { isApprovingPayReports, onApprovePayReportsClick } =
    useApprove(actionsParam);
  const { isWaitingTransition, onPostApprovalTransitonClick } =
    usePostApprovalTransiton(actionsParam);
  const { isPublishingPayReports, onPublishPayReportsClick } =
    usePublish(actionsParam);

  const isFetching =
    isFetchingGtnFiles ||
    isExportingGrossToNets ||
    isApprovingPayReports ||
    isDeletingGrossToNets ||
    isWaitingTransition ||
    isPublishingPayReports;

  const actionsDisabled = isFetching;

  const paginationProps = useMemo<PaginationOptions>(() => {
    return {
      pageSize,
      pageIndex,
      canNextPage:
        grossToNetFileData?.items.length === grossToNetFileData?.pageSize,
      canPreviousPage: pageIndex > 0,
      pageCount: grossToNetFileData?.totalCount
        ? Math.ceil(Math.max(grossToNetFileData.totalCount / pageSize, 1))
        : 0,
    };
  }, [pageSize, pageIndex, grossToNetFileData]);

  useEffect(() => {
    reloadGtnFiles();
  }, [paginationProps, reloadGtnFiles]);

  // all employees should have same currency
  // TODO get currency from detail endpoint
  const currency = payReports[0]?.reportSummary.currency;

  if (!country || !lspId || !payrollCycle) {
    history.push(backUrl);
    return <></>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {visibleModal === VisibleModalEnum.gtnDelete && (
        <ConfirmDeleteLspBillModal
          onClose={() => setVisibleModal(undefined)}
          onConfirm={() => {
            onDeletePayReportsClick();
            setVisibleModal(undefined);
          }}
        />
      )}
      {visibleModal === VisibleModalEnum.gtnHistory && fileHistoryInfo && (
        <StatusHistoryModal gtnInfo={fileHistoryInfo} />
      )}
      {visibleModal === VisibleModalEnum.gtnLspData && lspDataInfo && (
        <LspDataModal
          gtnId={lspDataInfo.gtnId}
          lspData={lspDataInfo.lspData}
          canEdit={lspDataInfo.canEdit}
          onLspDataUpdated={() => {
            reloadGtnFiles();
          }}
        />
      )}
      {visibleModal === VisibleModalEnum.lspBillJustification &&
        showEmployee && (
          <LSPBillJustificationModal
            employeeData={showEmployee}
            onClose={() => {
              setShowEmployee(null);
              setVisibleModal(undefined);
            }}
            readOnly={
              showEmployee.status === GrossToNetProcessingStatus.PUBLISHED
            }
          ></LSPBillJustificationModal>
        )}
      <PageContent
        data-testid="payroll-instance-lsp-bill-detail-page"
        className="bigStack"
      >
        <PageHeader
          backLink={{
            url: backUrl,
            label: 'Back to lsp bills',
          }}
          title={`${startCase(
            getCountryByCountryCode(country)?.label || country,
          )}: ${lspData?.name || 'unknown LSP'}`}
        />
        <LSPBillsDetailHeader
          gtnDetails={gtnDetails}
          currency={currency}
          payrollCycle={payrollCycle}
          isLoading={isLoadingGtnDetails}
        />
        <Stack bg="primary" radius="m" border="subtle">
          <Inline pt="24" pl="24" pr="24" stretch={2} stackAt={600}>
            <Inline>
              <Input
                id="searchInput"
                placeholder="Search employees"
                type="search"
                value={employeeSearchValue}
                onChange={(e) => {
                  setEmployeeSearchValue(e.target.value);
                }}
                handleClearSearch={() => setEmployeeSearchValue('')}
              />
              <PayReportViewFilter
                payReportViewFilter={payReportViewFilter}
                onFilterUpdate={setPayReportViewFilter}
              />
            </Inline>
            <Inline gap="16" stackAt={600}>
              {selectedGrossToNets.length > 0 ? (
                getNextStatusesActionsForSelection(
                  selectedGrossToNets,
                  actionsDisabled,
                  {
                    onApprove: onApprovePayReportsClick,
                    onPostApprovalTransition: onPostApprovalTransitonClick,
                    onDelete: onDeletePayReportsClick,
                    onPublish: onPublishPayReportsClick,
                    onExport: onExportCsvClick,
                  },
                )
              ) : (
                <>
                  <Button
                    disabled={actionsDisabled}
                    color="negative"
                    variant="secondary"
                    onClick={() => {
                      setVisibleModal(VisibleModalEnum.gtnDelete);
                    }}
                  >
                    Delete all
                  </Button>
                  <Button
                    disabled={actionsDisabled}
                    variant="secondary"
                    onClick={onExportCsvClick}
                  >
                    Export all as CSV
                  </Button>
                  <Button
                    key="refresh"
                    data-testid="refresh-pay-report-button"
                    onClick={() => {
                      reloadGtnDetails();
                      reloadGtnFiles();
                      reloadJustifications();
                    }}
                    variant="secondary"
                    disabled={actionsDisabled}
                  >
                    Refresh
                  </Button>
                  <Inline ml="auto" key="workflow-actions">
                    <Button
                      disabled={actionsDisabled}
                      onClick={onApprovePayReportsClick}
                    >
                      Start Verification for all
                    </Button>
                    <Button
                      disabled={actionsDisabled}
                      onClick={() => {
                        onPostApprovalTransitonClick(
                          GrossToNetProcessingStatus.SPECIALIST_APPROVED,
                        );
                      }}
                    >
                      Approve all
                    </Button>
                    <Button
                      disabled={actionsDisabled}
                      onClick={onPublishPayReportsClick}
                    >
                      Publish all
                    </Button>
                  </Inline>
                </>
              )}
            </Inline>
          </Inline>
          <LspBillDetailTable
            payReportViewFilter={payReportViewFilter}
            tableData={tableData}
            dataProcessingErrors={dataProcessingErrors}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setVisibleModal={setVisibleModal}
            paginationProps={paginationProps}
            setPagination={setPagination}
            isLoading={isLoadingTable}
            onEmployeeClick={(data) => {
              setVisibleModal(VisibleModalEnum.lspBillJustification);
              setShowEmployee(data);
            }}
            onViewStatusHistory={(fileInfo) => {
              setFileHistoryInfo(fileInfo);
              setVisibleModal(VisibleModalEnum.gtnHistory);
            }}
            onViewLspData={(gtnId, canEdit, lspInfo) => {
              setLspDataInfo({
                gtnId,
                canEdit,
                lspData: lspInfo,
              });
              setVisibleModal(VisibleModalEnum.gtnLspData);
            }}
          />
        </Stack>
      </PageContent>
    </>
  );
};

export const PayrollInstanceLspBillDetailPage = () => {
  return (
    <PayrollContextWrapper>
      <Page />
    </PayrollContextWrapper>
  );
};
