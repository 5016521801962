import { AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { awsClient, client } from '../../api';

export const uploadDocumentRequest = (fileToUpload: File, type: string) => {
  const formData = new FormData();
  formData.append('type', type);
  formData.append('file', fileToUpload);

  return client.post('/document-storage', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getPresignedS3UrlRequest = async (fileToUpload: File) => {
  return client('/document-storage/presignedpost', {
    params: {
      filename: fileToUpload.name,
    },
  });
};

export const uploadToPresignedUrlRequest = async (
  presignedUrl: string,
  fileToUpload: File,
  fields: Record<string, any>,
  progressEventHandler: AxiosRequestConfig['onUploadProgress'],
) => {
  const formData = Object.entries(fields).reduce((data, [key, value]) => {
    data.append(key, value);
    return data;
  }, new FormData());

  formData.append('file', fileToUpload);

  return awsClient.post(presignedUrl, formData, {
    onUploadProgress: progressEventHandler,
  });
};

export const uploadDocumentToOmniplatformRequest = async (
  fileToUpload: File,
  directory: string,
  progressEventHandler: AxiosRequestConfig['onUploadProgress'],
) => {
  const fileExtension = fileToUpload.name.split('.').pop();
  const fileName = `${directory}/${uuidv4()}.${fileExtension}`;
  const fileWithNewName = new File([fileToUpload], fileName);
  const {
    data: { url, fields },
  } = await getPresignedS3UrlRequest(fileWithNewName);
  const documentUrl: any = `${url}/${fields.key}`;
  await uploadToPresignedUrlRequest(
    url,
    fileWithNewName,
    fields,
    progressEventHandler,
  );
  return documentUrl;
};
