import { ReactComponent as DownArrow } from 'assets/icon/DownArrow.svg';
import { ReactComponent as UpArrow } from 'assets/icon/UpArrow.svg';
import styled from 'styled-components';

import { StyledBodyCopy } from '../../atoms/Text/Copy.styles';

export const StyledDateSorter = styled(StyledBodyCopy)`
  align-self: flex-end;
  margin-top: ${({ theme: { space } }) => `${space[4]}`};

  svg {
    color: ${({ theme: { colors } }) => colors['icon-default']};
  }
`;

export const StyledUpArrow = styled(UpArrow)`
  cursor: pointer;
`;

export const StyledDownArrow = styled(DownArrow)`
  cursor: pointer;
`;
