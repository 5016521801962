import { User } from '@sentry/react';
import { ActionCell } from 'common/components/molecules/ActionCell/ActionCell';
import { LspUser, LSPUserActions } from 'common/types';

import { StyledTitleCell } from '../TableWithTitle/TableWithTitle.styles';

const ManagerCustomCell = (
  manager: User,
  onColumnItemClick: (column: string, manager: User) => void,
) => {
  const { name } = manager;

  return (
    <span
      role="none"
      style={{ cursor: 'pointer' }}
      onClick={() => onColumnItemClick('name', manager)}
    >
      <StyledTitleCell>{name}</StyledTitleCell>
    </span>
  );
};

const ActionsCustomCell = (
  user: LspUser,
  onColumnItemClick: (column: string, user: LspUser) => void,
) => (
  <ActionCell
    actions={LSPUserActions}
    onActionClick={(action) => onColumnItemClick(action, user)}
  />
);

export const userTableHeaders = [
  {
    id: 'name',
    headerTitle: 'Manager',
    customCell: ManagerCustomCell,
    fieldOnObject: 'name',
  },
  {
    id: 'email',
    headerTitle: 'Email',
    fieldOnObject: 'email',
  },
  {
    id: 'role',
    headerTitle: 'Role',
    fieldOnObject: 'role',
  },
  {
    id: 'action',
    headerTitle: 'Actions',
    fieldOnObject: 'action',
    customCell: ActionsCustomCell,
  },
];
