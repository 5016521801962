import { Stack } from '@omnipresentgroup/design-system';

import { TemplateMapping } from '../../types';
import { CountryOption } from '../Create/Create';

type Props = { countryTemplates: TemplateMapping[]; country: CountryOption };
export const ExistingTemplates = ({ countryTemplates, country }: Props) => (
  <Stack bg="primary" p="32">
    <h2>{`Existing template mappings for ${country.label} ${country.icon}`}</h2>
    <table>
      <thead style={{ textAlign: 'left' }}>
        <tr>
          <th>Country</th>
          <th>Avvoka original template id</th>
          <th>Company id</th>
          <th>Company name</th>
        </tr>
      </thead>
      <tbody>
        {countryTemplates?.map((template) => (
          <tr key={template.avvokaOriginalTemplateId}>
            <td>{template.countryCode}</td>
            <td>{template.avvokaOriginalTemplateId}</td>
            <td>{template.companyId}</td>
            <td>{template.companyName}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </Stack>
);
