import appSaga from 'app/store/sagas/app.saga';
import companiesSaga from 'common/store/sagas/companies.saga';
import documentsSaga from 'common/store/sagas/documents.saga';
import documentStorageSaga from 'common/store/sagas/documentStorage.saga';
import employeesSaga from 'common/store/sagas/employee.saga';
import { all } from 'redux-saga/effects';

import expensesSaga from './expenses.saga';

export default function* employeeRootSaga() {
  yield all([
    ...employeesSaga,
    ...expensesSaga,
    ...appSaga,
    ...documentStorageSaga,
    ...documentsSaga,
    ...companiesSaga,
  ]);
}
