import { LspUsersTable } from 'common/components/organisms/LspUsersTable/LspUsersTable';

const LspUsersTab = ({
  lspId,
  hasVerificationField,
}: {
  lspId: string;
  hasVerificationField: boolean;
}) => {
  return (
    <div data-testid="lsp-users-tab">
      <LspUsersTable
        lspId={lspId}
        hasVerificationField={hasVerificationField}
      />
    </div>
  );
};
export default LspUsersTab;
