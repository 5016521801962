import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { getUserRoleSelector, getUserSelector } from 'app/store/app.selectors';
import { ErrorBanner } from 'common/components';
import { transformCompanyFromApi } from 'common/store/transformers/company.transformer';
import type { AllowedProduct, TransformCompanyFromApi } from 'common/types';
import { updateCompanyAction } from 'omniplatform/admin/store/actions/companies.actions';
import {
  COMPANIES_LIST_PAGE,
  COMPANY_PROFILE_PAGE,
  CONTRACTORS_PATH,
  EMPLOYEES_PATH,
} from 'paths';
import { UseQueryOptions } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useCompanyQuery } from 'utils/queries/companies';
import { isFinanceAdmin, isManager, isPayrollAdmin } from 'utils/user';
import {
  companySchemaForAdmins,
  companySchemaForManagers,
} from 'utils/validators/company.schema';
import { validateInputs } from 'utils/validators/validator';

import Loading from '../../components/atoms/Loading/Loading';
import FormSidebar from '../../components/molecules/FormSidebar/FormSidebar';
import { FourOhFour } from '../404/FourOhFour';
import EditDetailsPage from '../EditDetailsPage/EditDetailsPage';
import SideBar from '../EditEmployeePage/SideBar';
import ProfileWelcomePage from '../ProfileWelcomePage/ProfileWelcomePage';
import { StyledEditCompanyPage } from './EditCompanyPage.styles';

export const EditCompanyPage = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();

  const companyQuery = useCompanyQuery<TransformCompanyFromApi>(companyId, {
    select: (data: any) => transformCompanyFromApi(data),
  } as UseQueryOptions<TransformCompanyFromApi, Error>);

  useEffect(() => {
    companyQuery.remove();
    companyQuery.refetch();
  }, []);

  const user = useSelector(getUserSelector);
  const userRole = useSelector(getUserRoleSelector);
  const isPayrollAdminUser = isPayrollAdmin(user);
  const isFinanceAdminUser = isFinanceAdmin(user);
  const isManagerUser = isManager(user);
  const sections = [
    {
      name: 'company-details',
      title: 'Company details',
      details: companyQuery?.data?.companyDetails,
    },
  ];

  const adminSections = [
    ...sections.map((section) => {
      section?.details?.map((detail) => {
        if (
          (isPayrollAdminUser || isFinanceAdminUser) &&
          detail.name === 'countryOfRegistration'
        ) {
          detail.disabled = false;
        }
        if (
          !(isPayrollAdminUser || isFinanceAdminUser) &&
          [
            'countryOfRegistration',
            'companyName',
            'vatExempt',
            'contactName',
            'contactPhoneNumber',
            'countryOfRegistration',
            'vatNumber',
            'companyNumber',
            'registeredAddress',
            'onboardingStage',
            'invoicingEmail',
          ].includes(detail.name)
        ) {
          detail.disabled = true;
        }
        return detail;
      });

      return section;
    }),
    {
      name: 'admin-details',
      title: 'Administrative information',
      details: companyQuery?.data?.administrativeInformation
        ?.filter((detail) => detail.editable)
        .map((details) => {
          if (details.name !== 'fxConversionPercent') {
            return details;
          }

          if (!isPayrollAdminUser) {
            details.readOnly = true;
            details.contextualInfo =
              'Please contact Finance to change this field in case the FX fee agreed with clients is different from the one listed here.';
          }

          return details;
        }),
    },
  ];
  const showCompanyWelcomePage =
    isManager(user) && companyQuery?.data?.needsManagerReview;
  const pageTitle = isManager(user)
    ? 'Edit company details'
    : 'Edit client details';
  const schema = isManager(user)
    ? companySchemaForManagers
    : companySchemaForAdmins;

  const isContractorOnly = ['Contractor' as AllowedProduct].every(
    (product) => companyQuery?.data?.enabledProducts?.includes(product),
  );

  if (companyQuery.isError) {
    return <ErrorBanner errorMessage={companyQuery.error.message} />;
  }

  return (
    <PageContent data-testid="edit-company-page">
      {companyQuery.isLoading || !companyQuery?.data?.companyDetails ? (
        <Loading />
      ) : (
        <StyledEditCompanyPage>
          {showCompanyWelcomePage ? (
            <ProfileWelcomePage
              title={`Welcome, ${user.name}!`}
              welcomeMessages={[
                `Before we can get you started, you’ll need to fill out the company
              information below so that you can access the Omnipresent platform.`,
              ]}
              onSaveEdits={(editedFields: any) => {
                dispatch(
                  updateCompanyAction({
                    editedFields,
                    companyId,
                    redirectURL: isContractorOnly
                      ? `${process.env.VITE_MANAGER_APP}${CONTRACTORS_PATH}`
                      : EMPLOYEES_PATH,
                    useAppHistory: !isContractorOnly,
                  }),
                );
              }}
              validator={(fields: any) => validateInputs(fields, schema)}
              sections={sections}
            />
          ) : // We cannot simply remove this route from the manager's router as this route is also used for ProfileWelcomePage
          isManagerUser && !showCompanyWelcomePage ? (
            <FourOhFour />
          ) : (
            <EditDetailsPage
              backLink={{
                url:
                  userRole === 'admin'
                    ? `${COMPANIES_LIST_PAGE}/${companyId}#details`
                    : COMPANY_PROFILE_PAGE,
                label:
                  userRole === 'admin'
                    ? "Back to the client's details"
                    : "Back to the company's details",
              }}
              title={pageTitle}
              onSaveEdits={(editedFields) =>
                dispatch(
                  updateCompanyAction({
                    editedFields,
                    companyId,
                  }),
                )
              }
              validator={(fields) => validateInputs(fields, schema)}
              sections={isManager(user) ? sections : (adminSections as any)}
              sideBar={
                <FormSidebar
                  child={
                    <SideBar
                      jobTitle="Client"
                      name={companyQuery?.data?.companyName}
                    />
                  }
                />
              }
            />
          )}
        </StyledEditCompanyPage>
      )}
    </PageContent>
  );
};
