import styled, { css } from 'styled-components';

export const CellContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: ${({ theme: { space } }) => `${space[16]} 0`};
  width: 224px;

  > time {
    display: block;
  }
`;

export const CustomDate = styled.div(
  ({ theme: { colors, fontSizes, space } }) => css`
    > span {
      color: ${colors['text-subtle']};
      font-size: ${fontSizes[1]};
    }

    > time {
      display: block;
      margin-top: ${space[4]};
    }
  `,
);
