import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { AxiosError, AxiosResponse } from 'axios';
import { createEmployment } from 'common/api/employments.api';
import { ErrorBanner, Loading } from 'common/components';
import { getEmployeeAction } from 'common/store/actions/employee.actions';
import { FormData } from 'common/types';
import {
  EmploymentModel,
  employmentType,
  PostEmploymentModel,
} from 'common/types/employments';
import { getEmployeeSelector } from 'omniplatform/employee/store/selectors/employee.selector';
import { EMPLOYEES_PATH } from 'paths';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLspsQuery } from 'utils/queries';
import { employmentKeys } from 'utils/queries/employments/keys';

import {
  Box,
  Button,
  Card,
  DateFormats,
  DateInput,
  Dropdown,
  Inline,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import { getDateForPayload } from './common/employmentHistory.helper';
import { addEditEmploymentResolver } from './common/EmploymentHistoryFormResolver';

export const AddEmploymentPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { employeeId } = useParams<{ employeeId: string }>();

  const { userId, managerId, country } = useSelector(getEmployeeSelector);

  const [employeeCountry, setEmployeeCountry] = useState<undefined | string>(
    '',
  );

  const {
    isLoading: isLspsQueryLoading,
    error: lspsQueryError,
    data: lsps = [],
  } = useLspsQuery();

  useEffect(() => {
    if (!userId || !managerId) {
      dispatch(getEmployeeAction(employeeId));
    }
    if (country !== undefined && country !== employeeCountry) {
      setEmployeeCountry(country);
    }
  }, [dispatch, employeeId, userId, managerId, country, employeeCountry]);

  const {
    isLoading,
    reset: onErrorBannerReset,
    isError,
    error,
    mutate: onSubmit,
  } = useMutation<
    AxiosResponse<EmploymentModel>,
    AxiosError,
    PostEmploymentModel
  >((newEmployment: PostEmploymentModel) => createEmployment(newEmployment), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(employmentKeys.singleUser(userId));
      history.push(`${EMPLOYEES_PATH}/${employeeId}#employmentHistory`);
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: addEditEmploymentResolver(),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const onSubmitClick = handleSubmit((data: Record<string, any>) => {
    const newEmployment: PostEmploymentModel = {
      startDate: getDateForPayload(data?.startDate),
      terminationDate: getDateForPayload(data?.terminationDate),
      firstPayrollDate: getDateForPayload(data?.firstPayrollDate),
      lastPayrollDate: getDateForPayload(data?.lastPayrollDate),
      lspId: data?.lspId?.value,
      type: data?.type?.value,
      userId,
      companyId: managerId,
    };

    onSubmit(newEmployment);
  });

  const options = lsps.map((lsp) => ({
    label: lsp.name,
    value: lsp.id,
  }));

  if (isLspsQueryLoading) {
    return <Loading />;
  }

  return (
    <PageContent>
      {isLoading && <Loading />}
      {(lspsQueryError || isError) && (
        <ErrorBanner
          onErrorClear={() => onErrorBannerReset && onErrorBannerReset()}
          errorMessage={error?.message}
        />
      )}
      {employeeCountry && (
        <Stack gap="32" mb="32">
          <Typography as="h3" hideParagraphSpacing size="24" weight="medium">
            Add an employment
          </Typography>
          <Box>
            <Button
              variant="secondary"
              icon="ChevronLeft"
              onClick={() =>
                history.push(
                  `${EMPLOYEES_PATH}/${employeeId}#employmentHistory`,
                )
              }
            >
              Back to employment history
            </Button>
          </Box>

          <Card p="32" maxW={600}>
            <Stack gap="16">
              <Typography as="span" size="16">
                Please fill out the form below to add an employment
              </Typography>
              <Controller
                name="lspId"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    id="lspId"
                    label="Select a local service provider"
                    placeholder="Select local service provider..."
                    required
                    errorMessage={errors['lspId']?.message}
                    options={options}
                    {...field}
                  />
                )}
              />
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    id="type"
                    label="Select an employment type"
                    placeholder="Select employment type..."
                    isSearchable={false}
                    required
                    errorMessage={errors['type']?.message}
                    options={employmentType.map((e) => ({
                      label: e,
                      value: e,
                    }))}
                    {...field}
                  />
                )}
              />
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DateInput
                    id="startDate"
                    dateFormat={DateFormats.FixedDate}
                    placeholderText={DateFormats.FixedDate.toLocaleLowerCase()}
                    errorMessage={errors.startDate?.message}
                    label="Start date"
                    required
                    {...field}
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                  />
                )}
              />
              <Controller
                name="terminationDate"
                control={control}
                render={({ field }) => (
                  <DateInput
                    id="terminationDate"
                    dateFormat={DateFormats.FixedDate}
                    placeholderText={DateFormats.FixedDate.toLocaleLowerCase()}
                    errorMessage={errors.terminationDate?.message}
                    label="Termination date"
                    {...field}
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                  />
                )}
              />
              <Controller
                name="firstPayrollDate"
                control={control}
                render={({ field }) => (
                  <DateInput
                    id="firstPayrollDate"
                    dateFormat={DateFormats.FixedDate}
                    placeholderText={DateFormats.FixedDate.toLocaleLowerCase()}
                    errorMessage={errors.firstPayrollDate?.message}
                    label="First payroll date"
                    {...field}
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                  />
                )}
              />
              <Controller
                name="lastPayrollDate"
                control={control}
                render={({ field }) => (
                  <DateInput
                    id="lastPayrollDate"
                    dateFormat={DateFormats.FixedDate}
                    placeholderText={DateFormats.FixedDate.toLocaleLowerCase()}
                    errorMessage={errors.lastPayrollDate?.message}
                    label="Last payroll date"
                    {...field}
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                  />
                )}
              />
              <Inline justify="space-between" mt="32">
                <Button
                  variant="secondary"
                  onClick={() =>
                    history.push(
                      `${EMPLOYEES_PATH}/${employeeId}#employmentHistory`,
                    )
                  }
                >
                  Cancel
                </Button>
                <Button onClick={() => onSubmitClick()} disabled={isLoading}>
                  Add employment
                </Button>
              </Inline>
            </Stack>
          </Card>
        </Stack>
      )}
    </PageContent>
  );
};
