import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledManagerExpenseListPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledExpensesTableContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    width: 100%;
  }
`;

export const SuccessNote = styled.span`
  margin-top: 1rem;
`;

export const StyledAlertContainer = styled.div`
  > div {
    width: fit-content;
    padding-right: ${({ theme: { space } }) => `${space[64]}`};
  }
`;
