import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { deleteUser } from 'common/api/users.api';
import { ErrorBanner, PageHeader } from 'common/components';
import Loading from 'common/components/atoms/Loading/Loading';
import { ConfirmationModal } from 'common/components/molecules/ConfirmationModal';
import TableWithTitle from 'common/components/organisms/TableWithTitle/TableWithTitle';
import { User, userRoleTypeToFriendlyName } from 'common/types';
import { AdminActionEnum } from 'common/types/admin';
import { ADD_ADMIN_PAGE } from 'paths';
import { useMutation } from 'react-query';
import { useAdminUsersQuery } from 'utils/queries/users';

import { tableHeaders } from './AdminListPageTableHeaders';

export const modals = {
  DELETE_ADMIN_USER_CONFIRM: 'delete-user-confirm',
  DELETE_ADMIN_USER_SUCCESS: 'delete-user-success',
} as const;

type ModalKeys = keyof typeof modals;
type ModalValues = (typeof modals)[ModalKeys];

export const AdminListPage = () => {
  const adminUsersQuery = useAdminUsersQuery();
  const history = useHistory();
  const [visibleModal, setVisibleModal] = useState<ModalValues | void>();
  const [currentUser, setSelectedUser] = useState<User | void>();

  const handleAdminClick = (column: string, selectedUser: User) => {
    if (column === AdminActionEnum.DELETE) {
      setSelectedUser(selectedUser);
      setVisibleModal(modals.DELETE_ADMIN_USER_CONFIRM);
    }
  };

  const deleteUserMutation = useMutation(
    (userId: string) => deleteUser(userId),
    {
      onSuccess: () => {
        adminUsersQuery.refetch();
      },
      onError: (err) => console.error(err),
    },
  );

  const approveDeleteConfirmationModalProps = {
    title: 'Delete admin user?',
    message: `Are you sure you want to delete this admin user
      ${currentUser?.firstName} ${currentUser?.lastName}
      (${(currentUser?.roles || [])
        .map((role) => userRoleTypeToFriendlyName[role.type])
        .join(', ')})?
      This user will no longer able to access the OmniPlatform.`,
    cancelLabel: 'CANCEL',
    cancelHandler: () => setVisibleModal(),
    confirmLabel: 'DELETE',
    confirmHandler: () => {
      if (currentUser) {
        deleteUserMutation.mutate(currentUser.userId);
        setVisibleModal(modals.DELETE_ADMIN_USER_SUCCESS);
      }
    },
    loading: deleteUserMutation.isLoading,
  };

  const approveDeleteSuccessModalProps = {
    title: 'Admin user deleted',
    message: 'The admin user has been successfully deleted.',
    cancelHandler: () => {
      setSelectedUser();
      setVisibleModal();
    },
    cancelLabel: 'CLOSE',
  };

  const onCTAClick = () => history.push(ADD_ADMIN_PAGE);

  return (
    <PageContent data-testid="admin-list-page" className="bigStack">
      <PageHeader title="Admins" ctaText="add new" onCTAClick={onCTAClick} />
      {adminUsersQuery.isLoading && <Loading />}

      {adminUsersQuery.isError && (
        <ErrorBanner errorMessage={adminUsersQuery.error.message} />
      )}

      {adminUsersQuery.isSuccess && (
        <TableWithTitle
          testId="admins-list"
          itemsToDisplay={adminUsersQuery.data}
          headers={tableHeaders}
          getId={(user: { userId: any }) => user.userId}
          onColumnItemClick={handleAdminClick}
        />
      )}

      {visibleModal === modals.DELETE_ADMIN_USER_CONFIRM && (
        <ConfirmationModal {...approveDeleteConfirmationModalProps} />
      )}

      {visibleModal === modals.DELETE_ADMIN_USER_SUCCESS && (
        <ConfirmationModal {...approveDeleteSuccessModalProps} />
      )}
    </PageContent>
  );
};
