import managerActionKeys from './reduxConstants';

export const getEmployeeExpensesAction = (payload) => ({
  type: managerActionKeys.GET_EMPLOYEE_EXPENSES_START,
  payload,
});

export const getEmployeeExpenseAction = (payload) => ({
  type: managerActionKeys.GET_EMPLOYEE_EXPENSE_START,
  payload,
});

export const updateEmployeeExpenseAction = (payload) => ({
  type: managerActionKeys.UPDATE_EMPLOYEE_EXPENSE_START,
  payload,
});
