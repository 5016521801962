import { Document } from '@contentful/rich-text-types';
import { getCountryFlagByCountryName } from 'utils/countries';

import { Collapsible } from '../../components/atoms/Collapsible';
import { transformKeyFactsToCollapsibleFormat } from '../automatedUpsellSection/keyFactsUtil';
import {
  SmallMediumSpanDescription,
  SmallMediumUnorderedList,
  StyledCollapsibleContent,
  StyledDisclaimerCopy,
  StyledTabTitle,
  StyledTitle,
} from './ConfirmEmployeeSeatPage.styles';

const renderList = (item: any) => {
  const texts = item.text.split('|');
  return texts.map((text: any, index: number) => {
    return (
      <SmallMediumUnorderedList key={index}>
        <li key={index}>{text}</li>
      </SmallMediumUnorderedList>
    );
  });
};

const renderKeyFact = (keyFact: any) => {
  return (
    <>
      {keyFact.items &&
        keyFact.items.map((item: any, index: number) =>
          item.isParagraph ? (
            <SmallMediumSpanDescription key={index}>
              <li>{item.text}</li>
            </SmallMediumSpanDescription>
          ) : (
            renderList(item)
          ),
        )}
    </>
  );
};

export const KeyFactsContent = (
  countryName: string,
  allKeyFacts: Document | undefined,
) => {
  const formattedKeyFacts = transformKeyFactsToCollapsibleFormat(allKeyFacts);
  return (
    <>
      <StyledTitle>
        {getCountryFlagByCountryName(countryName) + ' ' + countryName}: Key
        Facts{' '}
      </StyledTitle>
      <StyledCollapsibleContent>
        {formattedKeyFacts &&
          formattedKeyFacts.map((keyFact, index: number) => {
            return (
              <Collapsible
                key={index}
                title={keyFact.title || ''}
                id={keyFact.title || ''}
              >
                {renderKeyFact(keyFact)}
              </Collapsible>
            );
          })}
      </StyledCollapsibleContent>
    </>
  );
};

export const HowItWorksContent = () => (
  <StyledCollapsibleContent>
    <Collapsible title="Client Terms" id="terms">
      <SmallMediumSpanDescription>
        You contract with Omnipresent by accepting the Client Terms, which
        govern our ongoing relationship, and then for each new employee
        engagement you will complete an Employee Profile setting out the
        individual’s details and your preferred employment terms.
      </SmallMediumSpanDescription>
    </Collapsible>
    <Collapsible title="Employment" id="employment">
      <SmallMediumSpanDescription>
        Our local entity enters into an employment contract with your
        international team member(s) under the terms requested by you in their
        Employee Profile and fully compliant with the local law.
      </SmallMediumSpanDescription>
    </Collapsible>
    <Collapsible title="Flow of payments" id="flow-of-payments">
      <SmallMediumSpanDescription>
        Omnipresent invoices you for all of your international team members in
        your local currency. We then handle all foreign currency exchange,
        payroll calculations and remittances to the employees, pay local taxes
        and social costs, and benefits providers.
      </SmallMediumSpanDescription>
    </Collapsible>
    <Collapsible title="Working relationship" id="working-relationship">
      <SmallMediumSpanDescription>
        To the extent permissible by applicable laws - and unless otherwise
        specified in any communication made to you by Omnipresent (including any
        applicable terms and conditions of service, as well as other
        country-specific key facts communicated to you), you retain operational
        control of your team member(s) on a day to day basis and can manage
        their work and performance as you see fit, within the boundaries of the
        local employment law.
      </SmallMediumSpanDescription>
    </Collapsible>
  </StyledCollapsibleContent>
);

export const InvoiceScheduleContent = () => (
  <StyledCollapsibleContent>
    <Collapsible
      title="Set-up Fee"
      id="fees"
      subtitle="Triggered when employee details are added"
    >
      <SmallMediumSpanDescription>
        Once Terms are signed, Omnipresent begins deploying resources for the
        setup process.
      </SmallMediumSpanDescription>
    </Collapsible>
    <Collapsible
      title="Deposits (if applicable)"
      id="deposits"
      subtitle="Triggered when employee details are added"
    >
      <SmallMediumSpanDescription>
        Payable in full prior to sending the employment contract to the employee
        for signature. The Deposit enables Omnipresent to move forward securely
        to sign the local employment contract with the employee.
      </SmallMediumSpanDescription>
    </Collapsible>
    <Collapsible
      title="Initial Advance Salary Payment"
      id="advance-salary"
      subtitle="Triggered on employee start date"
    >
      <SmallMediumSpanDescription>
        The first month’s estimated labour costs are invoiced on the first day
        of employment.
      </SmallMediumSpanDescription>
    </Collapsible>
    <Collapsible
      title="Ongoing monthly Employment Costs and Fees"
      id="monthly-employment-fees"
      subtitle="Triggered on payroll date "
    >
      <SmallMediumSpanDescription>
        Thereafter, we invoice monthly in arrears for labour costs - once the
        final payroll, taxes, insurance, social security contributions, expenses
        and deductions have been calculated. Each monthly invoice will include
        our monthly service fee, deposit insurance (if applicable), and an FX
        fee (if applicable).
      </SmallMediumSpanDescription>
      <StyledDisclaimerCopy>
        Typically the last working day of the month, but varies by country.
      </StyledDisclaimerCopy>
    </Collapsible>
  </StyledCollapsibleContent>
);

export const OnboardingScheduleContent = () => (
  <>
    <StyledTabTitle>
      Overall expected time frame: 10-15 business days
    </StyledTabTitle>
    <StyledCollapsibleContent>
      <Collapsible
        title="Setup (2 business days)"
        id="setup"
        subtitle="Triggered when terms are signed"
      >
        <SmallMediumSpanDescription>
          You add employees and employment contract details to the OmniPlatform.
          This initiates the onboarding process.
        </SmallMediumSpanDescription>
      </Collapsible>
      <Collapsible
        title="Employment Contract (3 - 5 business days)"
        id="employment-contract"
        subtitle="Triggered when employee details are added"
      >
        <SmallMediumSpanDescription>
          Omnipresent drafts a local employment contract based on the details
          provided and shares it with you for review.
        </SmallMediumSpanDescription>
        <StyledDisclaimerCopy>
          Typically 3-5 business days, but can vary based on country and client
          requirements.
        </StyledDisclaimerCopy>
      </Collapsible>
      <Collapsible
        title="Compliance Check (2 - 3 business days)"
        id="compliance-check"
        subtitle="Triggered when employee contract is reviewed"
      >
        <SmallMediumSpanDescription>
          Employee adds personal details and right to work documentation to the
          OmniPlatform for payroll onboarding.
        </SmallMediumSpanDescription>
      </Collapsible>
      <Collapsible
        title="Invoicing (2 - 3 business days)"
        id="invoicing"
        subtitle="Triggered when employment contract is drafted"
      >
        <SmallMediumSpanDescription>
          Initial invoices to be paid prior to employee added to payroll.
        </SmallMediumSpanDescription>
      </Collapsible>
      <Collapsible
        title="Employee Payroll (2 business days)"
        id="employee-payroll"
        subtitle="Triggered when employment contract is signed"
      >
        <SmallMediumSpanDescription>
          For start dates and employment contract signatures that occur after
          the 15th of the month, payroll is guaranteed for the following month.
          For countries with multiple pay periods per month, contract signatures
          that occur after 10 business days prior to the incoming pay date will
          be guaranteed on the subsequent payroll.
        </SmallMediumSpanDescription>
        <StyledDisclaimerCopy>
          For countries with multiple pay periods per month, contract signatures
          that occur after 10 business days prior to the incoming pay date will
          be guaranteed on the subsequent payroll.
        </StyledDisclaimerCopy>
      </Collapsible>
    </StyledCollapsibleContent>
  </>
);
