import {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useEffect,
} from 'react';

import * as Sentry from '@sentry/react';
import { Loading } from 'common/components';
import { PrivacyPolicyModal } from 'common/components/organisms/PrivacyPolicyModal/PrivacyPolicyModal';
import { UserRoleType } from 'common/types';

import {
  Inline,
  RootLayout,
  Typography,
  useModal,
} from '@omnipresentgroup/design-system';

import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';
import {
  useNavigation,
  useOnboardingStatus,
  usePrivacyPolicy,
  useUser,
} from './hooks';
import { TransformedUser } from './store/app.transformers';

type ContentWrapperProps = {
  user: TransformedUser;
  children: React.ReactNode;
};

const ContentWrapper = ({ children, user }: ContentWrapperProps) => {
  const { modalProps } = useModal({});

  // Handle redirection to the onboarding app, so that it doesn't blink
  // by rendering the whole employee profile
  const {
    hasOnboardingInProgress,
    isSuccessOnboardings,
    isLoadingOnboardings,
    isFetchedOnboardings,
  } = useOnboardingStatus(user);

  // Handle privacy policy here as it's supposed to be blocking any child rendering
  const { showPrivacyPolicy, setShowPrivacyPolicy } = usePrivacyPolicy(user);

  useEffect(() => {
    if (hasOnboardingInProgress) {
      window.location.assign(process.env.VITE_ONBOARDING_APP_URL);
    }
  }, [hasOnboardingInProgress]);

  if (isLoadingOnboardings) {
    return <Loading />;
  }

  if (
    !isLoadingOnboardings &&
    !isSuccessOnboardings &&
    !hasOnboardingInProgress &&
    // Handles the non employee cases as the query is not triggered
    isFetchedOnboardings
  ) {
    throw new Error('Failed to fetch onboardings');
  }

  if (
    !isLoadingOnboardings &&
    isSuccessOnboardings &&
    hasOnboardingInProgress
  ) {
    return (
      <Inline justify="center" my="32">
        <Typography as="h2" size="24">
          You are being redirected to the onboarding app
        </Typography>
      </Inline>
    );
  }

  if (showPrivacyPolicy) {
    return (
      <PrivacyPolicyModal
        modalProps={{
          ...modalProps,
          open: showPrivacyPolicy,
        }}
        handleContinue={() => {
          setShowPrivacyPolicy(false);
        }}
      />
    );
  }

  const enhancedChildren = Children.map(children, (child) => {
    // Only clone and modify if it's a valid React element
    if (isValidElement(child)) {
      return cloneElement(child, { user });
    }
    return child;
  });

  return <>{enhancedChildren}</>;
};

type RootProps = {
  children: ReactNode;
  userRole: UserRoleType;
};

export const Root = ({ children, userRole }: RootProps) => {
  const { isLoading, user } = useUser();

  const { Navigation } = useNavigation({ userRole, user });

  return (
    <RootLayout navigation={<Navigation />}>
      <Sentry.ErrorBoundary
        fallback={(props) => <ErrorBoundaryFallback {...props} />}
      >
        {isLoading || !user ? (
          <Loading />
        ) : (
          <ContentWrapper user={user}>{children}</ContentWrapper>
        )}
      </Sentry.ErrorBoundary>
    </RootLayout>
  );
};
