import appHistory from 'app/appHistory';
import Button from 'common/components/atoms/Button';
import Loading from 'common/components/atoms/Loading/Loading';
import { CardIconsList } from 'common/components/molecules/CardIconsList/CardIconsList';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import {
  getEmployeeSeatListPath,
  getSeatConversionFormPagePath,
  MANAGER_SEATS_LIST_PATH,
} from 'paths';
import {
  Briefcase,
  Clock,
  DollarSign,
  FileText,
  Mail,
  MapPin,
  User,
} from 'react-feather';
import { getCountryCodeByCountryName } from 'utils/countries';
import { useSelectUserRoles } from 'utils/hooks/redux-selectors/useSelectUserRoles';
import { useSingleSeatQuery } from 'utils/queries';

import { Typography } from '@omnipresentgroup/design-system';

import * as S from './EmployeeChecklist.styles';

type Props = {
  companyId: string;
  seatId: string;
};

const EmployeesChecklist = ({ companyId, seatId }: Props) => {
  const { isAdmin } = useSelectUserRoles();

  const pendingEmployeePath = isAdmin
    ? getEmployeeSeatListPath(companyId)
    : MANAGER_SEATS_LIST_PATH;

  const seatQuery = useSingleSeatQuery(seatId);
  const countryName = seatQuery?.data?.countryName;
  const countryCode = countryName
    ? getCountryCodeByCountryName(countryName)
    : '';
  const addEmployeePath = getSeatConversionFormPagePath(
    companyId,
    seatId,
    countryCode,
  );

  const employeePersonalDetails = [
    {
      Icon: User,
      label: 'Full legal name',
    },
    {
      Icon: MapPin,
      label: 'Address',
    },
    {
      Icon: Mail,
      label: 'Personal email address',
    },
  ];

  const employeeContractDetails = [
    {
      Icon: Briefcase,
      label: 'Job title',
    },
    {
      Icon: FileText,
      label: 'Job description',
    },
    {
      Icon: DollarSign,
      label: 'Annual base salary',
    },
    {
      Icon: Clock,
      label: 'Working hours',
    },
  ];

  if (seatQuery.isLoading) {
    return <Loading />;
  }

  if (seatQuery.isError) {
    return <ErrorBanner />;
  }

  return (
    <S.EmployeesChecklistContainer>
      <Typography as="h4" hideParagraphSpacing size="24" weight="medium">
        Complete profile and build contract
      </Typography>
      <S.Divider />
      <S.NameHeader>Employee</S.NameHeader>
      <S.NameContainer>
        {seatQuery.data?.firstName} {seatQuery.data?.lastName}
      </S.NameContainer>
      <S.Divider />
      <S.EmployeeChecklistExplanations
        wrapContent
        data-testid="employee-checklist-content"
      >
        We need some basic details about their employment which will be used to
        fill out their contract. You will also have the opportunity to choose
        the options you want to customise in the contract.
      </S.EmployeeChecklistExplanations>
      <S.EmployeeChecklistSubtitle>
        Please ensure you have the following information at hand:
      </S.EmployeeChecklistSubtitle>
      <S.CheckListContainer
        data-testid="employee-checklist-container"
        className="smallStack"
      >
        <CardIconsList
          header="Employee personal details"
          items={employeePersonalDetails}
        />
        <CardIconsList
          header="Employee contract details"
          items={employeeContractDetails}
        />
      </S.CheckListContainer>
      <S.ButtonContainer>
        <Button
          onClick={() => appHistory.push(pendingEmployeePath)}
          testId="employee-checklist-back-button"
          palette="secondary"
          variant="outlined"
        >
          Later
        </Button>
        <S.RightAlignButton
          onClick={() => appHistory.push(addEmployeePath)}
          testId="employee-checklist-button"
          palette="primary"
        >
          Complete profile
        </S.RightAlignButton>
      </S.ButtonContainer>
    </S.EmployeesChecklistContainer>
  );
};

export default EmployeesChecklist;
