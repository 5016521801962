import { AxiosError } from 'axios';
import { getCompanySeatsRequest } from 'common/api/seats.api';
import { ONE_MINUTE } from 'common/constants';
import { transformSeatApiResponseToSeat } from 'common/store/transformers/seats.transformer';
import { TransformedSeat } from 'common/types';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';
import { sortAlphabetically } from 'utils/sort.util';

import { seatsKeys } from '../keys';

export const useCompanySeatsQuery = (
  companyId: string,
  queryOptions?: Pick<UseQueryOptions, 'staleTime' | 'cacheTime' | 'enabled'>,
): UseQueryResult<TransformedSeat[], AxiosError> => {
  return useQuery(
    seatsKeys.multiple(companyId),
    () => getCompanySeatsRequest(companyId).then(getDataFromResponse),
    {
      staleTime: queryOptions?.staleTime || ONE_MINUTE,
      select: (data) =>
        sortAlphabetically('name', data.map(transformSeatApiResponseToSeat)),
      ...(queryOptions || {}),
    },
  );
};
