import { useCallback } from 'react';

import { AxiosResponse } from 'axios';
import { Form } from 'common/components/molecules/Form/Form';
import { FormProvider } from 'common/components/molecules/Form/FormProvider';
import InfoBanner from 'common/components/molecules/InfoBanner/InfoBanner';
import { ContractAmendmentFormApiRequest } from 'common/types';
import {
  ContractAmendmentFormData,
  transformContractAmendmentFormDataForAPI,
} from 'common/types/contract';
import { HelpCircle, X } from 'react-feather';
import { UseMutateFunction } from 'react-query/types/react/types';

import { Box, Typography } from '@omnipresentgroup/design-system';

import * as S from './ContractAmendment.styles';
import { getContractAmendmentFormConfig } from './ContractAmendmentFormConfig';

type ContractAmendmentFormContainerProp = {
  setShowContractAmendmentForm: (arg: boolean) => void;
  employeeId: string;
  companyId: string;
  contractDocumentId: string;
  create: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    ContractAmendmentFormApiRequest,
    unknown
  >;
  clearFormFields: boolean;
};

type ContractAmendmentFormProp = {
  submitHandler: (arg: ContractAmendmentFormData) => void;
  clearFields: boolean;
};

const noop = () => {
  // This is intentional
};

const ContractAmendmentForm = ({
  submitHandler,
  clearFields,
}: ContractAmendmentFormProp) => {
  return (
    <FormProvider
      clearFields={clearFields}
      config={getContractAmendmentFormConfig()}
    >
      <Form
        onSubmit={submitHandler}
        ctaText="Submit Edit Request"
        showCancel={false}
        onCancel={noop}
        showRequiredText={false}
        reverseButtonOrder={true}
        formWidth="100%"
        borderless={true}
        padding="16px"
      />
    </FormProvider>
  );
};

export const ContractAmendmentFormContainer = ({
  setShowContractAmendmentForm,
  contractDocumentId,
  employeeId,
  companyId,
  create,
  clearFormFields,
}: ContractAmendmentFormContainerProp) => {
  const submitHandler = useCallback(
    (contractAmendmentFormData: ContractAmendmentFormData) => {
      create(
        transformContractAmendmentFormDataForAPI(
          contractAmendmentFormData,
          employeeId,
          companyId,
          contractDocumentId,
        ),
        {
          onSuccess: () => {
            window.location.hash = `#contract-amendment-request-success`;
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [create],
  );

  return (
    <S.Container>
      <S.ContainerHeader>
        <HelpCircle
          data-testid="request-contract-edit-tooltip-icon"
          size={20}
          display="flex"
        />
        <Box w={320}>
          <Typography as="h5" hideParagraphSpacing size="20" weight="medium">
            Request contract edit
          </Typography>
        </Box>

        <S.ContainerClose>
          <X
            data-testid="close-drawer"
            onClick={() => setShowContractAmendmentForm(false)}
            size={16}
          />
        </S.ContainerClose>
      </S.ContainerHeader>
      <InfoBanner
        type=""
        copy="Please note all requests are subject to review by our legal team and that any that any contract edit request can delay the onboarding."
        maxWidth="400px"
        margin="16px"
      />
      <ContractAmendmentForm
        clearFields={clearFormFields}
        submitHandler={submitHandler}
      />
    </S.Container>
  );
};
