import { PayrollInstance } from 'common/types/payroll';
import paginatedPayrollInstances from 'omniplatform/admin/__mocks__/paginatedPayrollInstances.json';
import { v4 as uuidv4 } from 'uuid';

export const getMockedPaginatedPayrolllInstances = () => ({
  nextPageKey: paginatedPayrollInstances.nextPageKey,
  items: paginatedPayrollInstances.items.map(
    (item) =>
      ({
        ...item,
        id: uuidv4(),
      }) as PayrollInstance,
  ),
});
