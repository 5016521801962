import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ResignationRequest } from 'common/types/resignations';

import { Tag, TagIntent } from '@omnipresentgroup/design-system';

const columnHelper = createColumnHelper<ResignationRequest>();

export const resignationTableHeaders: ColumnDef<any, any>[] = [
  columnHelper.accessor('name', {
    cell: (info) => info.getValue(),
    header: () => 'Employee',
  }),
  columnHelper.accessor('jobTitle', {
    cell: (info) => info.getValue(),
    header: () => 'Role',
  }),
  columnHelper.accessor('country', {
    cell: (info) => info.getValue(),
    header: () => 'Country',
  }),
  columnHelper.accessor('status', {
    cell: (info) => (
      <ResignationStatusTag
        id={`${info.row.id}-tag}`}
        status={info.getValue()}
      />
    ),
    header: () => 'Status',
    size: 100,
  }),
];

const ResignationStatusTag = ({
  status,
  id,
}: {
  status: TagIntent;
  id: string;
}) => {
  const statusIntent: { [key: string]: TagIntent } = {
    Requested: 'error',
    Reviewed: 'success',
  };

  return !!status ? (
    <Tag intent={statusIntent[status]} id={id}>
      {status}
    </Tag>
  ) : null;
};
