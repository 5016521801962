import { HTTPStatusCodesEnum } from 'common/constants';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { PathParams, rest } from 'msw';
import {
  adminUsers,
  mockCSAdminUser,
  mockFinanceUser,
} from 'omniplatform/admin/__mocks__/adminUser.mock';

import { User } from '../types';
import {
  CreateUserRequestPayload,
  CreateUserResponse,
  EditUserPayload,
} from './users.api';

export const usersHandlers = [
  rest.get<User[]>(composeEndpoint('/users'), (req, res, ctx) => {
    const roleSearchParam = req.url.searchParams.get('role');

    return res(
      ctx.status(HTTPStatusCodesEnum.OK),
      ctx.json(roleSearchParam === 'admin' ? adminUsers : []),
    );
  }),

  rest.get<User>(composeEndpoint('/users/:userId'), () => {
    // If you've hit this error then you need to use one of the methods in src/utils/test-utils/renderAs.tsx
    // OR as a last resort, provide custom msw handlers within your tests and use the renderWithoutUser function.
    throw new Error(
      'You need to specify a user to mock for MSW, see the comment in src/common/api/users.msw.ts',
    );
  }),

  rest.post<CreateUserRequestPayload, PathParams, CreateUserResponse>(
    composeEndpoint('/users'),
    (req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          id: '12345-67890-98765-54321',
          message: 'User created successfully',
          url: 'https://api.omniplatform.com/users/12345-67890-98765-54321',
        }),
      );
    },
  ),

  rest.patch<EditUserPayload>(
    composeEndpoint('/users/:userId'),
    (req, res, ctx) => {
      return res(ctx.status(204));
    },
  ),

  rest.delete(composeEndpoint('/users/:userId'), (req, res, ctx) => {
    return res(ctx.status(204));
  }),

  rest.post(composeEndpoint(`/users/:userId/invite`), (req, res, ctx) => {
    return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockCSAdminUser));
  }),

  rest.get(composeEndpoint(`/users/:userAuthId`), (req, res, ctx) => {
    return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockFinanceUser));
  }),
];
