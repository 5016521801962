import { AxiosError } from 'axios';
import { workflowTransitionPayReportBulk } from 'common/api/payrollTaxonomy.api';
import {
  BulkRequest,
  GrossToNetProcessingStatus,
  PayReportBulkApproveApiResponse,
} from 'common/types';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const usePayReportWorkflowTransitionBulkMutation = () => {
  return useMutation<
    PayReportBulkApproveApiResponse,
    AxiosError<string>,
    BulkRequest & { newStatus: GrossToNetProcessingStatus }
  >((data: BulkRequest & { newStatus: GrossToNetProcessingStatus }) =>
    workflowTransitionPayReportBulk(data).then(getDataFromResponse),
  );
};
