import { AxiosError } from 'axios';
import { deleteGrossToNetFile } from 'common/api/payrollTaxonomy.api';
import { GrossToNetFilesApiResponse } from 'common/types';
import { InfiniteData, useMutation } from 'react-query';

type Context = {
  previousData: InfiniteData<GrossToNetFilesApiResponse> | undefined;
};

export const useDeleteGrossToNetFileMutation = () => {
  return useMutation<unknown, AxiosError, string, Context>(
    (dataFrameId: string) => deleteGrossToNetFile(dataFrameId),
  );
};
