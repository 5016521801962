import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import { getUserRoleSelector } from 'app/store/app.selectors';
import avatarPlaceholderPicture from 'assets/img/avatar-placeholder.png';
import Loading from 'common/components/atoms/Loading/Loading';
import {
  StyledModalButtons,
  StyledModalContainer,
  StyledModalContents,
} from 'common/components/molecules/ConfirmationPopup/ConfirmationPopup.styles';
import { uploadDocumentAction } from 'common/store/actions/documentStorage.actions';
import {
  getDocumentFileInfoSelector,
  getDocumentUploadingSelector,
  getUploadedDocumentLocation,
} from 'common/store/selectors/documentStorage.selectors';
import { formatNameOrDefaultToTBC } from 'common/store/transformers/seats.transformer';
import { SOWStatusEnum } from 'common/types';
import isNil from 'lodash/isNil';
import {
  getConvertSeatChecklistPagePath,
  getEditSeatPath,
  getEmployeeSeatListPath,
  getSeatConversionFormPagePath,
  MANAGER_SEATS_LIST_PATH,
} from 'paths';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryCodeByCountryName } from 'utils/countries';
import { isUpsellProposalGenerationEnabled } from 'utils/featureFlags';
import { useSingleSeatQuery } from 'utils/queries/seats/useSingleSeatQuery';
import { isAdmin } from 'utils/user';

import { Spinner, Viewport } from '@omnipresentgroup/design-system';

import { updateCompanyEmployeeSeatRequest } from '../../api/seats.api';
import Button from '../../components/atoms/Button/Button';
import FloatingActionButton from '../../components/atoms/FloatingActionButton/FloatingActionButton';
import PageHeader from '../../components/molecules/PageHeader/PageHeader';
import StatementOfWorkStatus from '../../components/molecules/StatementOfWorkStatus/StatementOfWorkStatus';
import UploadDocument from '../../components/molecules/UploadDocument/UploadDocument';
import DetailsCard from '../../components/organisms/DetailsCard/DetailsCard';
import { clearDocumentCreatedAction } from '../../store/actions/documents.actions';
import { updateSeatAction } from '../../store/actions/seats.actions';
import { getSeatSelector } from '../../store/selectors/seats.selector';
import { StyledAccountDetailsPage } from '../AccountDetailsPage/AccountDetailsPage.styles';
import { StyledProfilePicture } from '../EmployeeDetailsPage/EmployeeDetailsPage.styles';
import {
  StyledBoldSeatName,
  StyledModalHeader,
  StyledRightAlignButton,
  StyledStatementUploadContainer,
} from './SeatStatementOfWorkPage.styles';
import { getTableDetailsConfig } from './tableDetailsConfig';

export const SeatStatementOfWorkPage = () => {
  const [sowSignedLoading, setSowSignedLoading] = useState(false);
  const [hasRequiredUpsellData, setHasRequiredUpsellData] = useState(false);
  const { companyId, seatId } = useParams();
  const dispatch = useDispatch();
  const stateSeat = useSelector((state) => getSeatSelector(state, seatId));

  let seatQuery = useSingleSeatQuery(seatId, {
    queryOptions: {
      cacheTime: 0,
    },
  });

  seatQuery = {
    ...seatQuery,
    data: {
      ...seatQuery.data,
      name: formatNameOrDefaultToTBC(
        seatQuery?.data?.firstName,
        seatQuery?.data?.lastName,
      ),
      desiredStartDate: seatQuery?.data?.desiredStartDate ?? '',
    },
  };

  const seat = seatQuery?.data ? seatQuery.data : stateSeat;

  const userRole = useSelector(getUserRoleSelector);
  const fileInfo = useSelector(getDocumentFileInfoSelector);
  const documentUploading = useSelector((state) =>
    getDocumentUploadingSelector(state),
  );
  const uploadedDocumentLocation = useSelector((state) =>
    getUploadedDocumentLocation(state),
  );

  const isAdminUser = isAdmin(userRole);

  const seatFileName = seat?.sowDocUrl?.split('/')?.pop();
  const tableDetails = getTableDetailsConfig(seat);

  const location = useLocation();
  const parsedLocation = queryString.parse(location.search);
  const showModal = parsedLocation.showModal === 'true';
  const [closedModal, setCloseModal] = useState(false);

  const addEmployeePath = isAdminUser
    ? getSeatConversionFormPagePath(
        companyId,
        seatId,
        getCountryCodeByCountryName(seat?.countryName),
      )
    : getConvertSeatChecklistPagePath(companyId, seatId);

  const onEditButtonClick = () =>
    appHistory.push(getEditSeatPath(companyId, seatId));

  useEffect(() => {
    const hasRequiredSeatData = Boolean(
      seat?.countryName &&
        seat.billingCurrency &&
        seat.desiredStartDate &&
        seat.employeeProfile?.employeeType &&
        seat.employeeProfile.depositType &&
        !isNil(seat.employeeProfile.managementFeeAmount) &&
        !isNil(seat.employeeProfile.setupFeeAmount),
    );
    setHasRequiredUpsellData(hasRequiredSeatData);
  }, [seat, seatQuery]);

  useEffect(() => {
    const onDocumentAdded = async () => {
      if (
        isAdminUser &&
        fileInfo?.file &&
        !documentUploading &&
        !uploadedDocumentLocation
      ) {
        dispatch(uploadDocumentAction(fileInfo));
      }

      if (isAdminUser && uploadedDocumentLocation) {
        const updatedSeat = {
          sowDocUrl: uploadedDocumentLocation,
          sowStatus: SOWStatusEnum.SOW_AWAITING_ACCEPTANCE,
        };

        await updateCompanyEmployeeSeatRequest(seatId, updatedSeat);
        await dispatch(updateSeatAction(seatId, updatedSeat));
        dispatch(clearDocumentCreatedAction());
      }
    };
    onDocumentAdded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileInfo?.file, uploadedDocumentLocation]);

  const onDocumentUploaded = () => {};
  const onStatementAlreadySigned = async () => {
    if (!isAdminUser) {
      return;
    }

    setSowSignedLoading(true);
    const updatedSeat = { sowStatus: SOWStatusEnum.SOW_ACCEPTED };
    await updateCompanyEmployeeSeatRequest(seatId, updatedSeat);
    await dispatch(updateSeatAction(seatId, updatedSeat));
    setSowSignedLoading(false);
    appHistory.push(getEmployeeSeatListPath(companyId));
  };

  const getTextForEditCta = () => {
    if (!isUpsellProposalGenerationEnabled()) {
      return 'Edit';
    }
    const ACCEPTED_SOW_STATUS = 'sow:accepted';
    if (isAdminUser && seat?.sowStatus !== ACCEPTED_SOW_STATUS) {
      return 'Edit';
    }
  };

  const SuccessModal = () => {
    const shouldRender = !closedModal && showModal && seat?.name;
    return shouldRender ? (
      <StyledModalContainer data-testid="seat-created-modal">
        <StyledModalContents className="stack">
          <StyledModalHeader>
            Thanks for your new employee request!
          </StyledModalHeader>
          {seatQuery.isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner size="40" />
            </div>
          ) : (
            <div>
              You just requested an order form for
              <StyledBoldSeatName> {seat?.name}</StyledBoldSeatName>. We’ll
              notify you once your order form is ready for approval
            </div>
          )}
          <StyledModalButtons>
            <StyledRightAlignButton
              palette="primary"
              variant="outlined"
              disabled={seatQuery.isLoading}
              onClick={() => setCloseModal(true)}
            >
              Continue
            </StyledRightAlignButton>
          </StyledModalButtons>
        </StyledModalContents>
      </StyledModalContainer>
    ) : null;
  };

  const OrderFormBottons = () => {
    return isAdminUser ? (
      <StyledStatementUploadContainer>
        <div className="button-container">
          {hasRequiredUpsellData ? (
            <UploadDocument
              onDocumentUploaded={onDocumentUploaded}
              name="sowUrl"
              buttonLabel={
                seat.sowDocUrl ? 'Replace order form' : 'Upload order form'
              }
              buttonStyleVariant="outlined"
              buttonPalette="primary"
              value={seat.sowDocUrl || seatFileName}
              showUploadButtonOnly={true}
            />
          ) : (
            <Button
              style={{ marginRight: 150, padding: 20 }}
              onClick={onEditButtonClick}
              testId="complete-order-form"
              variant="outlined"
              palette="primary"
            >
              Complete order form
            </Button>
          )}
          {sowSignedLoading || seatQuery.isLoading ? (
            <Loading />
          ) : (
            <Button
              onClick={onStatementAlreadySigned}
              testId="sow-signed"
              variant="outlined"
              palette="secondary"
              disabled={!hasRequiredUpsellData}
            >
              Order form already signed
            </Button>
          )}
        </div>
      </StyledStatementUploadContainer>
    ) : null;
  };

  const shouldRender = !seatQuery.isLoading && !sowSignedLoading && seat?.name;

  return (
    <PageContent>
      <StyledAccountDetailsPage
        data-testid="seat-statement-upload"
        className="bigStack"
      >
        <SuccessModal />
        {shouldRender ? (
          <>
            <PageHeader
              title={seat?.name || ''}
              ctaText={getTextForEditCta()}
              onCTAClick={onEditButtonClick}
              statusPill={
                <StatementOfWorkStatus
                  status={seat?.sowStatus}
                  testId={seat?.id}
                  center
                  onClick={() => {
                    appHistory.push(addEmployeePath);
                  }}
                />
              }
              image={
                <StyledProfilePicture
                  src={avatarPlaceholderPicture}
                  alt="Employee"
                />
              }
              backLink={{
                url: isAdminUser
                  ? getEmployeeSeatListPath(companyId)
                  : MANAGER_SEATS_LIST_PATH,
                label: 'Back to pending employees',
              }}
            />
            <DetailsCard
              fieldsToDisplay={tableDetails}
              title="Pending Employee Details"
              testId="seat-detailscard"
              userRole={userRole}
            />
            <OrderFormBottons />
          </>
        ) : null}
        <Viewport devices={['phone', 'tablet']}>
          <FloatingActionButton type="edit" onClick={onEditButtonClick} />
        </Viewport>
      </StyledAccountDetailsPage>
    </PageContent>
  );
};
