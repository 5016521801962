import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { Address } from '../Address/Address';
import DropdownInput from '../DropdownInput/DropdownInput';
import FormCheckbox from '../FormCheckbox/FormCheckbox';
import {
  StyledHeading,
  StyledLocationOfWorkInput,
  StyledTypeLabel,
} from './LocationOfWorkInput.styles';

const homeOfficeType = { value: 'Home office', label: 'Home office' };
const typeOptions = {
  name: 'locationOfWorkType',
  options: [
    homeOfficeType,
    { value: 'Corporate office', label: 'Corporate office' },
  ],
};

const LocationOfWorkInput = ({
  name,
  value,
  homeAddress,
  onChange,
  error,
  onFocus,
  required,
}) => {
  const [isSameHomeAddressUsed, setSameHomeAddressUsed] = useState(false);
  const [locationOfWorkAddress, setLocationOfWorkAddress] = useState(
    value.address,
  );
  const [locationOfWorkType, setLocationOfWorkType] = useState(value.type);

  useEffect(() => {
    if (isSameHomeAddressUsed && homeAddress) {
      onChange('locationOfWork', {
        ...value,
        type: homeOfficeType.value,
        address: homeAddress,
      });

      setLocationOfWorkType(homeOfficeType.value);
      setLocationOfWorkAddress(homeAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSameHomeAddressUsed, homeAddress]);

  const onFieldChange = (nameOfFieldChanged, valueOfChangedField) => {
    if (nameOfFieldChanged === 'locationOfWorkType') {
      setLocationOfWorkType(valueOfChangedField);
      onChange('locationOfWork', {
        ...value,
        type: valueOfChangedField,
        address: {
          ...locationOfWorkAddress,
        },
      });
    }

    if (nameOfFieldChanged === 'locationOfWorkAddress') {
      setLocationOfWorkAddress(valueOfChangedField);
      onChange('locationOfWork', {
        ...value,
        type: locationOfWorkType,
        address: valueOfChangedField,
      });
    }
  };

  const onSameHomeAddressChange = (event) => {
    const {
      target: { checked },
    } = event;

    setSameHomeAddressUsed(checked);
  };

  return (
    <StyledLocationOfWorkInput
      data-testid="locationOfWork-input"
      className="stack"
    >
      <StyledHeading>Location of work</StyledHeading>
      <StyledTypeLabel>Type</StyledTypeLabel>
      <DropdownInput
        onSelect={onFieldChange}
        options={typeOptions}
        value={typeOptions.options.find(
          ({ label }) => label === locationOfWorkType,
        )}
        placeholder="Choose type..."
      />
      {homeAddress && (
        <>
          <StyledHeading>Address</StyledHeading>
          <FormCheckbox
            name="same-home-address"
            id="same-home-address-checkbox"
            onChange={onSameHomeAddressChange}
            checked={isSameHomeAddressUsed}
            label="Same as home address"
            data-testid="same-home-address"
          />
        </>
      )}
      {!isSameHomeAddressUsed && (
        <Address
          name={`${name}Address`}
          onChange={onFieldChange}
          addressObject={locationOfWorkAddress}
          hideAddressLines={locationOfWorkAddress.hideAddressLines}
          hidePostalCode={locationOfWorkAddress.hidePostalCode}
          errorObject={error || {}}
          onFocus={() => onFocus(name)}
          required={required}
        />
      )}
    </StyledLocationOfWorkInput>
  );
};

LocationOfWorkInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  homeAddress: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
  }),
  error: PropTypes.oneOfType([PropTypes.object]),
  required: PropTypes.bool,
};

LocationOfWorkInput.defaultProps = {
  error: {},
  onFocus: () => {},
  required: false,
};

export default LocationOfWorkInput;
