import { client } from 'api';
import { EmployerApiResponse, EmployerCreateApiRequest } from 'common/types';
import { removeFalsy } from 'utils/objects';

export const LEGAL_ENTITIES_BASE_API_ENDPOINT = '/legal-entities';

export const getEmployers = (params: {
  isActive?: boolean;
  lspId?: string;
}) => {
  return client.get<EmployerApiResponse[]>(LEGAL_ENTITIES_BASE_API_ENDPOINT, {
    params: removeFalsy(params),
  });
};

export const createEmployer = (employer: EmployerCreateApiRequest) => {
  return client.post(
    LEGAL_ENTITIES_BASE_API_ENDPOINT,
    employer,
    removeFalsy(employer),
  );
};

export const getEmployer = (id: string) => {
  return client.get<EmployerApiResponse>(
    `${LEGAL_ENTITIES_BASE_API_ENDPOINT}/${id}`,
  );
};

export const updateEmployer = (
  id: string,
  employerUpdate: EmployerCreateApiRequest,
) => {
  return client.put(
    `${LEGAL_ENTITIES_BASE_API_ENDPOINT}/${id}`,
    employerUpdate,
    removeFalsy(employerUpdate),
  );
};
