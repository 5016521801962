import { ReactComponent as SuccessIcon } from 'assets/icon/success_mail.svg';
import PlatformInvitationButton from 'common/components/molecules/PlatformInvitationButton/PlatformInvitationButton';
import { getCreatedCompanyManagerUserIdSelector } from 'common/store/selectors/companies.selectors';
import { Manager } from 'common/types';
import styled from 'styled-components';
import { useAdminSelector } from 'utils/hooks/useAppSelector';

import { Typography } from '@omnipresentgroup/design-system';
import tokens from '@omnipresentgroup/design-tokens';

const AddCompanyManagerSuccessContainer = styled.div`
  width: 70%;
  padding: ${({ theme: { space } }) => space[32]};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    padding: ${({ theme: { space } }) => space[64]};
  }
`;

export const AddCompanyManagerSuccess = (payload: {
  data: AddCompanyManagerSuccessInput;
}) => {
  const createdCompanyManagerId =
    useAdminSelector<Manager>(getCreatedCompanyManagerUserIdSelector) ||
    payload?.data?.id;

  return (
    <AddCompanyManagerSuccessContainer className="stack">
      <SuccessIcon />
      <Typography as="h4" hideParagraphSpacing size="20" weight="medium">
        Success!
      </Typography>
      <Typography as="p" hideParagraphSpacing size="16">
        The manager has been successfully created.
      </Typography>
      <PlatformInvitationButton
        userId={createdCompanyManagerId}
        userRole="Manager"
        testId="invite-manager-button"
      />
    </AddCompanyManagerSuccessContainer>
  );
};

interface AddCompanyManagerSuccessInput {
  id: string;
  mesage: string;
  url: string;
}
