import { ReactNode } from 'react';

import { SimplifiedEmployee, UserRoleTypeEnum } from 'common/types';

import NameTableCell from '../../molecules/NameTableCell/NameTableCell';
import { OnboardingStatus } from '../../molecules/OnboardingStatus/OnboardingStatus';

type ColumHeader = {
  headerTitle: string;
  fieldOnObject?: string;
  customCell?: ReactNode;
};

export const LspEmployeeTableHeaders = (userRole: string) => {
  const EmployeeCustomCell = (employee: SimplifiedEmployee) => (
    <NameTableCell {...employee} />
  );

  const StatusCustomCell = ({
    onboardingStatus,
    totalStages,
    latestInProgressStageId,
  }: SimplifiedEmployee) => (
    <OnboardingStatus
      status={onboardingStatus}
      totalStages={totalStages}
      latestInProgressStageId={latestInProgressStageId}
    />
  );

  const employeeColumn = {
    headerTitle: 'Employee',
    customCell: EmployeeCustomCell,
  };

  const commonColumns: ColumHeader[] = [
    {
      headerTitle: 'Role',
      fieldOnObject: 'jobTitle',
    },
    {
      headerTitle: 'Country',
      fieldOnObject: 'country',
    },
    {
      headerTitle: 'Salary',
      fieldOnObject: 'annualBaseSalary',
    },
    {
      headerTitle: 'Start date',
      fieldOnObject: 'startDate',
    },
    {
      headerTitle: 'Status',
      customCell: StatusCustomCell,
    },
  ];

  if (userRole === UserRoleTypeEnum.ADMIN) {
    const clientColumn = {
      headerTitle: 'Client',
      fieldOnObject: 'companyName',
    };
    return [employeeColumn, clientColumn, ...commonColumns];
  }

  return [employeeColumn, ...commonColumns];
};
