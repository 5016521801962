import styled, { css } from 'styled-components';

import { StyledBodyCopy } from '../../../atoms/Text';

export const Container = styled.div(({ theme: { space } }) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 108px;
    padding: ${space[16]} ${space[0]} ${space[32]};

    > button svg {
      margin-right: ${space[16]};
    }
  `;
});

export const Title = styled(StyledBodyCopy)(() => {
  return css``;
});
