import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { sendPlatformInvitation } from 'app/store/app.actions';
import { getPlatformInvitationStatus } from 'app/store/app.selectors';
import Loading from 'common/components/atoms/Loading/Loading';
import { ConfirmationModal } from 'common/components/molecules/ConfirmationModal';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import DetailsCard from 'common/components/organisms/DetailsCard/DetailsCard';
import { RightToWorkAlert } from 'common/pages/EmployeeContractPage/components';
import { EmployeeOrderFormCard } from 'common/pages/EmployeeDetailsPage/EmployeeOrderFormCard/EmployeeOrderFormCard';
import { Contract, Document, Field, Seat, UserRoleType } from 'common/types';
import { useDispatch, useSelector } from 'react-redux';
import { isUpsellProposalGenerationEnabled } from 'utils/featureFlags';
import { useSelectUserRoles } from 'utils/hooks';
import { useVisibleContracts } from 'utils/hooks/useVisibleContracts';
import { useLspsQuery } from 'utils/queries';
import { useArchiveContractMutation } from 'utils/queries/employees/useArchiveContractMutation';

import { getSelectedEmployeeSelector } from '../../../../store/selectors/employees.selector';
import { EmployeeContractCard } from '../../EmployeeContractCard/EmployeeContractCard';
import { OnboardingStageProgressTracker } from '../../ProgressTracker/OnboardingProgressTracker/OnboardingProgressTracker';
import { useEmployeeDetailsSectionByRole } from '../EmployeeInfoTabUtil';
import { shouldShowOnboardingProgressTracker } from './EmployeeInfoTabHelper';

export type Section = {
  key?: string;
  name: string;
  title: string;
  details: Field[];
};

export type Props = {
  userRole?: UserRoleType;
  userId?: string;
  seat: Seat;
  documents: Document[];
};
type PageParams = { employeeId: string };

export const ARCHIVE_CONTRACT_CONFIRM = 'archive-contract-confirm';
export const ARCHIVE_CONTRACT_SUCCESS = 'archive-contract-success';

export const EmployeeInfoTab = ({
  userRole,
  userId,
  seat,
  documents,
}: Props) => {
  const dispatch = useDispatch();
  const platformInvitationProgress = useSelector(getPlatformInvitationStatus);
  const { isAdmin, isManager, isEmployee, isLSP } = useSelectUserRoles();
  const { employeeId } = useParams<PageParams>();
  const employee = useSelector(getSelectedEmployeeSelector);
  const { employeeType } = employee;

  const [visibleModal, setVisibleModal] = useState<string | undefined>(
    undefined,
  );
  const [archiveContractId, setArchiveContractId] = useState<
    string | undefined
  >(undefined);

  const visibleContracts = useVisibleContracts();

  const orderFormDocument = documents
    ? documents.find(
        (document: Document) => document.title.toLowerCase() === 'order form',
      )
    : undefined;

  const employeeDetailsSectionsByRole = useEmployeeDetailsSectionByRole(
    employee,
    userRole,
  );

  const archiveContractMutation = useArchiveContractMutation();

  // NB: This is copy pasted for now because we should factorize after the 3rd copy/paste
  // Also I wasn't sure where to store business logic filled components
  // To me they belong ./pages/components (when re-usable but has logic).
  const archiveContract = (employeeId: string, documentId: string) =>
    archiveContractMutation.mutateAsync({ employeeId, documentId });

  const archiveContractConfirmModalProps = {
    title: 'Archive contract?',
    message:
      "Are you sure you want to archive this contract? It won't be visible to other users anymore",
    cancelHandler: () => setVisibleModal(undefined),
    cancelLabel: 'DO NOT ARCHIVE',
    confirmLabel: 'ARCHIVE',
    confirmHandler: async () => {
      await archiveContract(employeeId, `${archiveContractId}`);
      // FIXME: this is commented and is the prove that we need to address props drilling seriously
      // setVisibleModal(ARCHIVE_CONTRACT_SUCCESS);
    },
    loading: archiveContractMutation.isLoading,
  };

  const archiveContractSuccessModalProps = {
    title: 'Contract has been archived',
    message:
      "You will be able to view the contract but it's no longer visible by other users",
    cancelHandler: () => setVisibleModal(undefined),
    cancelLabel: 'CLOSE',
  };

  const lspsQuery = useLspsQuery();

  if (lspsQuery.isLoading) {
    return <Loading />;
  }

  if (lspsQuery.isError && lspsQuery.error instanceof Error) {
    return <ErrorBanner errorMessage={lspsQuery.error?.message} />;
  }

  const LocalServiceProviders = lspsQuery.data;

  const isAllowedToSeeLocalServiceProviderData =
    lspsQuery.isSuccess &&
    employeeDetailsSectionsByRole &&
    employeeDetailsSectionsByRole.length > 0 &&
    LocalServiceProviders &&
    LocalServiceProviders.length > 0;

  if (isAllowedToSeeLocalServiceProviderData) {
    const lspField = employeeDetailsSectionsByRole
      .flatMap((section: Section) => section.details)
      .find((detailField: Field) => detailField.name === 'lspId');

    if (lspField && lspField.value) {
      lspField.displayValue = LocalServiceProviders.find(
        (lsp) => lsp.id === lspField.value,
      )?.name;
    }
  }

  const handlePlatformInvitation = () => {
    dispatch(sendPlatformInvitation({ userId }));
  };

  const isValidUser = isAdmin || isManager;
  const shouldShowEmployeeOrderFormCard =
    isUpsellProposalGenerationEnabled() && isValidUser && seat;

  const rightToWorkCheckCompleted = employee?.employmentDetails
    ?.find(
      (detail: { name: string }) => detail.name === 'rightToWorkCheckCompleted',
    )
    ?.value?.find((option: { selected: boolean }) => option.selected)?.value;

  const showRightToWorkAlert = isValidUser && !rightToWorkCheckCompleted;
  return (
    <>
      <div className="bigStack">
        <div className="smallStack">
          {shouldShowEmployeeOrderFormCard && (
            <EmployeeOrderFormCard
              employeeId={employeeId}
              seat={seat}
              orderFormDocument={orderFormDocument}
            />
          )}
          {visibleContracts &&
            visibleContracts.map((contract: Contract) => {
              return (
                <EmployeeContractCard
                  key={`${contract.documentId}-${contract.status}-${contract.avvokaDocumentId}-${contract.contractId}
                    }`}
                  contract={contract}
                  employeeId={employeeId}
                  archiveContractLoading={archiveContractMutation.isLoading}
                  archiveContract={(documentId) => {
                    setVisibleModal(ARCHIVE_CONTRACT_CONFIRM);
                    setArchiveContractId(documentId);
                  }}
                />
              );
            })}
        </div>

        {showRightToWorkAlert && <RightToWorkAlert />}
        {shouldShowOnboardingProgressTracker({
          isAdmin,
          isManager,
          isEmployee,
          isLSP,
          employeeType,
          employee,
        }) && <OnboardingStageProgressTracker showHeader />}
        {employeeDetailsSectionsByRole?.map(
          (section: Section) =>
            section && (
              <DetailsCard
                key={section.title}
                testId={`${section.name}-card`}
                title={section.title}
                fieldsToDisplay={section.details}
                userRole={userRole}
                sendPlatformInvitation={handlePlatformInvitation}
                platformInvitationProgress={platformInvitationProgress}
              />
            ),
        )}
      </div>
      {visibleModal === ARCHIVE_CONTRACT_CONFIRM && (
        <ConfirmationModal {...archiveContractConfirmModalProps} />
      )}
      {visibleModal === ARCHIVE_CONTRACT_SUCCESS && (
        <ConfirmationModal {...archiveContractSuccessModalProps} />
      )}
    </>
  );
};
