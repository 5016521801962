import { Bar, Container } from './FileUploadInput.styles';

export type ProgressState = 'success' | 'error' | 'pending';

export const ProgressBar = ({
  progress,
  state = 'pending',
  label,
}: {
  progress: number;
  state?: ProgressState;
  label: string;
}) => {
  const overrideProgress = state === 'pending' ? progress : 100;
  return (
    <Container state={state}>
      <Bar
        role="progressbar"
        aria-label={label}
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
        progress={overrideProgress}
        state={state}
      />
    </Container>
  );
};
