import { TransformedUser } from 'app/store/app.transformers';
import { useOnboardings } from 'utils/queries/onboardings';
import { isEmployee } from 'utils/user';

export const useOnboardingStatus = (user: TransformedUser) => {
  const {
    data: onboardings = [],
    isSuccess: isSuccessOnboardings,
    isLoading: isLoadingOnboardings,
    isFetched: isFetchedOnboardings,
  } = useOnboardings({
    params: {
      userId: user?.userId || '',
    },
    options: { enabled: isEmployee(user) },
  });

  const hasOnboardingInProgress = onboardings.some(
    (onboarding) => onboarding.status === 'in_progress',
  );

  return {
    hasOnboardingInProgress,
    isSuccessOnboardings,
    isFetchedOnboardings,
    isLoadingOnboardings,
  };
};
