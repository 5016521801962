import { updateEmployeeRequest } from 'common/api/employees.api';
import { transformUpdateEmployeePayload } from 'common/store/transformers/employees.transformer';
import { useMutation } from 'react-query';

export type InfoToUpdate = {
  firstName: string;
  lastName: string;
  gender: 'Male | Female | X';
  dateOfBirth: string;
  citizenship: string;
  phoneNumber: string;
  address: {
    addressLine1: string;
    city: string;
    postalCode: string;
    country: string;
    addressLine2: string;
    addressLine3: string;
  };
  iban: string;
  accountNumber: string;
  bicSwift: string;
  routingNumber: string;
  branchName: string;
  socialInsuranceNumber: string;
  workEmail: string;
  locationOfWork: {
    type: 'Home office | Corporate office';
    address: {
      addressLine1: string;
      city: string;
      postalCode: string;
      country: string;
    };
  };
  needsRequiredFieldsFilled: boolean;
};
type PartialInfoToUpdate = Partial<InfoToUpdate>;

export const useUpdateEmployeeMutation = () => {
  return useMutation(
    ({
      infoToUpdate,
      employeeId,
    }: {
      infoToUpdate: PartialInfoToUpdate;
      employeeId: string;
    }) => {
      const payloadTransformed = transformUpdateEmployeePayload(infoToUpdate);
      return updateEmployeeRequest(employeeId, payloadTransformed);
    },
  );
};
