import { useState } from 'react';

import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import {
  ContractAmendmentUpdateBody,
  ContractAmendmentUrgency,
} from 'common/types/contract';
import { X } from 'react-feather';
import { useQueryClient } from 'react-query';
import { SingleValue } from 'react-select';
import { useDispatchContractAmendmentMutation } from 'utils/queries/contract/useContractAmendmentMutation/useContractAmendmentMutation';

import {
  Box,
  Button,
  Center,
  Dropdown,
  Inline,
  Notification,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';
import { OptionType } from '@omnipresentgroup/design-system/dist/types/components/Dropdown/model';

import * as S from './ContractAmendment.styles';

const urgencyOptions = Object.keys(ContractAmendmentUrgency).map(
  (urgencyKey) => {
    return {
      label:
        ContractAmendmentUrgency[
          urgencyKey as keyof typeof ContractAmendmentUrgency
        ],
      value: urgencyKey,
    };
  },
);

export const ContractAmendmentUrgencySelection = ({
  setAmendmentToDispatch,
  amendmentId,
  documentId,
}: {
  setAmendmentToDispatch: (_: string) => void;
  amendmentId: string;
  documentId: string;
}) => {
  const queryClient = useQueryClient();
  const [urgency, setUrgency] = useState<OptionType['value']>('');

  const updateMutation = useDispatchContractAmendmentMutation();

  const {
    mutate: updateUrgency,
    isError: isErrorUpdateUrgency,
    isLoading: isLoadingDispatchAmendment,
  } = updateMutation;

  const handleOnClick = () => {
    const body = { urgency } as ContractAmendmentUpdateBody;
    updateUrgency(
      { body, amendmentId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            'amendments',
            'documentId',
            documentId,
          ]);
          window.location.hash = `#contract-amendment-dispatch-success`;
          setAmendmentToDispatch('');
        },
      },
    );
  };

  return (
    <ModalWrapper fixedWidth="472" onClose={() => setAmendmentToDispatch('')}>
      <div
        role="none"
        data-testid="contractamendment-urgency"
        onClick={(e) => e.stopPropagation()}
      >
        <Inline justify="space-between" mx="32" my="16">
          <Typography as="h1" hideParagraphSpacing size="24" weight="medium">
            Send edit request to Legal
          </Typography>
          <S.ContainerClose>
            <X
              data-testid="close-drawer"
              onClick={() => {
                setAmendmentToDispatch('');
              }}
              size={24}
              cursor="pointer"
            />
          </S.ContainerClose>
        </Inline>
        {isErrorUpdateUrgency && (
          <Notification
            description="Please try again."
            dismissible
            id="updateContractAmendmentUrgencyError"
            intent="error"
            title="We couldn’t save the information you provided."
          />
        )}
        <Stack align="center" mx="32" my="64">
          <Box m="16">Please select an urgency and confirm.</Box>
          <Center h={200} w={300}>
            <Dropdown
              id="urgencyDropdown"
              label="Urgency"
              isMulti={false}
              onChange={(e: SingleValue<OptionType>) =>
                setUrgency((e?.value || '') as string)
              }
              options={[
                {
                  options: urgencyOptions,
                },
              ]}
              placeholder="please select the urgency"
            />
          </Center>
        </Stack>
        <Inline m="16" p="8" justify="space-between">
          <Button
            variant="secondary"
            onClick={() => setAmendmentToDispatch('')}
          >
            Back
          </Button>
          <Button
            variant="secondary"
            onClick={handleOnClick}
            disabled={!urgency}
            loading={isLoadingDispatchAmendment}
          >
            Send to Legal
          </Button>
        </Inline>
      </div>
    </ModalWrapper>
  );
};
