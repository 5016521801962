import { StyledDetailsValue } from 'common/components/molecules/DetailsCardValue/DetailsCardValue.styles';
import DetailsCard from 'common/components/organisms/DetailsCard/DetailsCard';
import {
  StyledDetail,
  StyledDetailLabel,
} from 'common/components/organisms/DetailsCard/DetailsCard.styles';
import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const CQWrapper = styled.div`
  ~ iframe {
    display: none;
  }

  .icon {
    &.text {
      margin-top: ${({ theme: { space } }) => space[4]};
    }

    svg:hover {
      stroke-width: ${({ theme: { borderWidths } }) => borderWidths.m};
    }
  }

  @media (min-width: ${tokens.breakpoint.laptop}) {
    ~ iframe {
      display: block;
    }
  }
`;

export const CQTable = styled(DetailsCard)`
  padding-right: ${({ theme: { space } }) => space[32]};
  width: auto;
  max-width: 1000px;

  ${StyledDetail} {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};

    &.contextual-info {
      background: ${({ theme: { colors } }) => colors['gray-100']};
      font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
    }

    & .details-card-value {
      text-align: center;

      p {
        font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
        font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
      }
    }
  }
  ${StyledDetailLabel} {
    width: 50%;
  }
  ${StyledDetailsValue} {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  }
`;

export const CQTableHead = styled.thead`
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  line-height: ${({ theme: { fontSizes } }) => fontSizes[4]};
`;

export const CQTableHeadCell = styled.th`
  background: ${({ theme: { colors } }) => colors['gray-100']};
  padding: ${({ theme: { space } }) => space[8]};
  border: ${({ theme: { borderWidths, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid}`};
  border-color: ${({ theme: { colors } }) => colors['border-subtle']};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};

  &.title {
    background: ${({ theme: { colors } }) => colors['gray-200']};
    text-transform: uppercase;
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
