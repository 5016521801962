import { useState } from 'react';

import NameTableCell from 'common/components/molecules/NameTableCell/NameTableCell';
import { TerminationRequestModel } from 'common/types/terminations';
import { Copy, Info, X } from 'react-feather';
import { useUserQuery } from 'utils/queries/users';

import { Box, Inline, omniConfig, Tag } from '@omnipresentgroup/design-system';

import { TerminationRequest } from './TerminationRequestsTable';

const NameCustomCell = (
  terminationRequest: TerminationRequest,
  onColumnItemClick: (
    companyId: string,
    employeeId: string,
    terminationRequestId: string,
  ) => void,
) => {
  const { id, name, companyId, employeeId } = terminationRequest;
  return (
    <span
      role="none"
      style={{ cursor: 'pointer' }}
      onClick={() => onColumnItemClick(companyId, employeeId, id)}
    >
      <NameTableCell name={name} />
    </span>
  );
};

const RequestedByCustomCell = (terminationRequest: TerminationRequest) => {
  const { createdBy } = terminationRequest;
  const { data: userData } = useUserQuery(createdBy);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const copyEmailToClipboard = (copyEmail: string) => {
    navigator.clipboard.writeText(copyEmail);
  };

  const email = userData?.email ?? '';

  return (
    <>
      <Inline gap="4">
        {`${userData?.firstName}
        ${userData?.lastName}`}
        <span role="none" style={{ cursor: 'pointer' }}>
          <Info
            size={omniConfig.theme.sizes[16]}
            onClick={() => setShowTooltip(!showTooltip)}
          />
        </span>
      </Inline>
      {showTooltip && (
        <Inline
          bg="inverse"
          p="4"
          mt="4"
          depth="s"
          radius="s"
          gap="8"
          pos="absolute"
          maxW={300}
          overflow="scroll"
        >
          {email}
          <span role="none" style={{ cursor: 'pointer' }}>
            <Copy
              size={omniConfig.theme.sizes[16]}
              onClick={() => {
                copyEmailToClipboard(email);
                setShowTooltip(!showTooltip);
              }}
            />
          </span>
          <span role="none" style={{ cursor: 'pointer' }}>
            <X
              size={omniConfig.theme.sizes[16]}
              onClick={() => {
                setShowTooltip(false);
              }}
            />
          </span>
        </Inline>
      )}
    </>
  );
};

const StatusCustomCell = ({ id, status }: TerminationRequestModel) => (
  <Box pt="16" pb="16">
    <Tag id={id} intent="success">
      {status}
    </Tag>
  </Box>
);

export const tableHeaders = (isCSAdmin: boolean) => {
  return [
    {
      headerTitle: 'Employee',
      customCell: NameCustomCell,
    },
    {
      id: 'jobTitle',
      headerTitle: 'Role',
      fieldOnObject: 'jobTitle',
    },
    {
      id: 'country',
      headerTitle: 'Country',
      fieldOnObject: 'country',
    },
    isCSAdmin
      ? {
          headerTitle: 'Requested by',
          customCell: RequestedByCustomCell,
        }
      : {},
    {
      headerTitle: 'Status',
      customCell: StatusCustomCell,
    },
  ];
};
