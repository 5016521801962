import { Departments } from 'appConstants';
import Joi from 'joi';

export const schema = Joi.object({
  firstName: Joi.string().required().messages({
    'string.base': `Please enter a first name`,
    'string.empty': `Please enter a first name`,
  }),
  lastName: Joi.string().required().messages({
    'string.base': `Please enter a last name`,
    'string.empty': `Please enter a last name`,
  }),
  email: Joi.string().required().messages({
    'string.base': `Please enter an email address`,
    'string.empty': `Please enter an email address`,
  }),
  jobTitle: Joi.string().required().messages({
    'string.empty': 'Please provide a job title',
  }),
  department: Joi.string()
    .required()
    .valid(...Departments)
    .messages({
      'string.empty': 'Please select a department',
      'string.base': 'Please select a department',
    }),
});
