import { useParams } from 'react-router-dom';

import { createCompanyManagerRequest } from 'common/api/companies.api';
import { DuplicateEmailErrorMessage } from 'common/components/molecules/ErrorBanner/ErrorMessages';
import { transformConfigFields } from 'common/components/molecules/Form/FormProvider';
import { HTTPStatusCodesEnum } from 'common/constants';
import { AddManagerFormPage } from 'common/pages/AddManagerFormPage/AddManagerFormPage';
import { addCompanyManagerFormConfig } from 'omniplatform/admin/pages/AddCompanyManagerPage/addCompanyManagerFormConfig';
import { COMPANIES_LIST_PAGE } from 'paths';
import { useMutation } from 'react-query';

import { NewManager } from './type';

export const AddCompanyManagerPage = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const {
    isLoading,
    isSuccess,
    data,
    isError,
    error,
    mutate: addManager,
  }: Record<string, any> = useMutation((newManager: NewManager) =>
    createCompanyManagerRequest(
      companyId,
      transformConfigFields(newManager.managerDetails[0]?.fields),
    ),
  );

  const errorMessage =
    isError &&
    error?.response?.status === HTTPStatusCodesEnum.CONFLICT &&
    DuplicateEmailErrorMessage;

  return (
    <AddManagerFormPage
      formConfig={addCompanyManagerFormConfig}
      isLoading={isLoading}
      addManager={addManager}
      isSuccess={isSuccess}
      companyId={companyId}
      successScreenPayload={data?.data}
      onCompleteUrl={`${COMPANIES_LIST_PAGE}/${companyId}#managers`}
      isError={isError}
      errorMessage={errorMessage || error?.message}
    />
  );
};
