import styled from 'styled-components';

export const StyledDivider = styled.div`
  width: 80%;
  align-self: flex-start;
  height: 2px;
  background-color: ${({ theme: { colors } }) => `${colors['border-subtle']}`};
  margin-bottom: ${({ theme: { space } }) => `${space[8]}`};
  margin-top: ${({ theme: { space } }) => `${space[8]}`};
`;

export const StyledFullWidthDividerHr = styled.hr`
  width: 100%;
  align-self: center;
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
`;

export const StyledFullWidthDivider = styled.div`
  width: 100%;
  align-self: center;
  height: 2px;
  background-color: ${({ theme: { colors } }) => colors['border-subtle']};
`;

export const StyledFullWidthDarkDivider = styled(StyledFullWidthDivider)`
  background-color: ${({ theme: { colors } }) => colors['border-inverse']};
`;

export const StyledGreenDivider = styled(StyledDivider)`
  background-color: ${({ theme: { colors } }) => colors['border-positive']};
`;

export const StyledFullWidthBlueDivider = styled(StyledFullWidthDivider)`
  background-color: ${({ theme: { colors } }) => colors['background-inverse']};
`;

export const StyledBlueDivider = styled(StyledDivider)`
  background-color: ${({ theme: { colors } }) => colors['border-inverse']};
`;

export const NonStyledDivider = styled(StyledDivider)`
  background-color: none;
`;
