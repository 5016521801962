import { BenefitsCountryApiResponse } from 'common/types/benefits';

export const mockBenefitsCountryResponse: Array<BenefitsCountryApiResponse> = [
  {
    id: 'NL-001',
    countryCode: 'NL',
    showBenefitsSelection: false,
    isRequired: false,
    isOffered: false,
    typeFormRef: 'ca416602-18bd-4f55-b1bf-d8f37',
    layoutAdditional:
      'Any fine print might go here for example regarding costs Any fine print might go here for example regarding costs Any fine print might go here for example regarding costs',
    layoutCost: 'EUR 50-90',
    layoutDescription:
      'General description of spain benefits goes here with a brief intro as to why you would consider it. General description of spain benefits goes here with a brief intro as to why you would consider it.',
    layoutInfo:
      'Most competetive employers within Spain offer pension & private health',
    layoutOffered: ['Pension', 'Private healthcare'],
  },
  {
    id: 'UK-002',
    countryCode: 'GB',
    showBenefitsSelection: true,
    isRequired: true,
    isOffered: true,
    typeFormRef: 'ca416602-18bd-4f55-b1bf-d8f37da2077a',
    layoutAdditional:
      'Any fine print might go here for example regarding costs Any fine print might go here for example regarding costs Any fine print might go here for example regarding costs',
    layoutCost: 'EUR 50-90',
    layoutDescription:
      'General description of spain benefits goes here with a brief intro as to why you would consider it. General description of spain benefits goes here with a brief intro as to why you would consider it.',
    layoutInfo:
      'Most competetive employers within Spain offer pension & private health',
    layoutOffered: ['Pension', 'Private healthcare'],
  },
  {
    id: 'PK-003',
    countryCode: 'PK',
    showBenefitsSelection: true,
    isRequired: true,
    isOffered: true,
    typeFormRef: 'ca416902-18bd-4f55-b1bf-d8f37da2077a',
    layoutAdditional:
      'Any fine print might go here for example regarding costs Any fine print might go here for example regarding costs Any fine print might go here for example regarding costs',
    layoutCost: 'EUR 50-90',
    layoutDescription:
      'General description of spain benefits goes here with a brief intro as to why you would consider it. General description of spain benefits goes here with a brief intro as to why you would consider it.',
    layoutInfo:
      'Most competetive employers within Spain offer pension & private health',
    layoutOffered: ['Pension', 'Private healthcare'],
  },
];
