import styled, { css } from 'styled-components';

const error = css`
  margin-bottom: 0;
`;

const errorInput = css`
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-negative']}`};
`;

export const FieldContainer = styled.div<{
  error?: string;
}>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.error ? error : null)}
  margin-bottom: ${({ theme: { space } }) => `${space[8]}`};

  .custom-option-container {
    display: flex;
    align-items: baseline;
    padding: ${({ theme: { space } }) =>
      `${space[8]} ${space[8]} ${space[8]} 0`};
  }

  [class$='-singleValue'] > .custom-option-container {
    padding: 0;
  }

  svg {
    color: ${({ theme: { colors } }) => colors['icon-default']};
  }

  .custom-option-text {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
    color: ${({ theme: { colors } }) => colors['text-default']};
  }

  [class$='-control'] {
    border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-strong']}`};
    min-height: ${({ theme: { space } }) => space[32]};
    /* stylelint-disable selector-type-no-unknown */
    ${(props) => (props.error ? errorInput : null)}
    /* stylelint-enable selector-type-no-unknown */
    :hover {
      border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
        `${borderWidths.s} ${borderStyles.solid} ${colors['border-inverse']}`};
    }

    :focus {
      outline: none;
    }
    ${({
      error,
      theme: {
        colors: { ['red-500']: errorRed },
      },
    }) => {
      if (error && Object.keys(error).length) {
        return `border-color: ${errorRed}`;
      }

      return ``;
    }}
    padding:  ${({ theme: { space } }) => `${space[4]}`};
  }
`;
