import { useHrisIntegrationContext } from 'common/contexts';
import { useSelectCompanySelected } from 'utils/hooks';

import { HRISIntegrationInput } from './HRISIntegrationInput';
import { HRISIntegrationSection } from './HRISIntegrationSection';

type FormHRISIntegrationSectionProps = {
  visible: boolean;
};

export const FormHRISIntegrationSection = ({
  visible: isVisible,
}: FormHRISIntegrationSectionProps) => {
  const { id: companyId } = useSelectCompanySelected();
  const { integrationId } = useHrisIntegrationContext();

  if (companyId && isVisible) {
    const FormHRISIntegration = integrationId
      ? HRISIntegrationInput
      : HRISIntegrationSection;

    return <FormHRISIntegration />;
  }

  return null;
};
