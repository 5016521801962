import { useEffect, useState } from 'react';

import { StyledInput } from 'common/components/atoms/Input';
import PropTypes from 'prop-types';

import { StyledSmallCopy } from '../../atoms/Text/Copy.styles';
import CheckboxInput from '../CheckboxInput/CheckboxInput';
import { StyledConditionalInput } from './ConditionalInput.styles';

const checkBoxOptions = [
  {
    label: 'Yes',
    value: true,
    selected: false,
  },
  {
    label: 'No',
    value: false,
    selected: true,
  },
];

const ConditionalInput = ({ name, value, onChange }) => {
  const initialCheckBoxOptions = checkBoxOptions.map((option) => {
    if (value && option.value) {
      return {
        ...option,
        selected: true,
      };
    }
    if (value && !option.value) {
      return {
        ...option,
        selected: false,
      };
    }
    return option;
  });
  const [checkBoxState, setCheckBoxState] = useState(initialCheckBoxOptions);
  const [showInput, setShowInput] = useState(
    checkBoxState.some((option) => option.value && option.selected),
  );

  useEffect(() => {
    const wasYesSelected = checkBoxState.some(
      (option) => option.value && option.selected,
    );
    if (!wasYesSelected && checkBoxState !== initialCheckBoxOptions) {
      onChange(name, '');
    }
    setShowInput(wasYesSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBoxState]);

  return (
    <StyledConditionalInput
      className="stack"
      data-testid={`${name}-conditionalInput`}
    >
      <CheckboxInput
        onChange={(_, newCheckBoxValue) => setCheckBoxState(newCheckBoxValue)}
        name={name}
        options={checkBoxState}
      />
      {showInput && (
        <>
          <StyledSmallCopy>Please provide details</StyledSmallCopy>
          <StyledInput
            type="text"
            data-testid={`${name}-input`}
            value={value}
            name={name}
            onChange={({ target: { value: newValue } }) =>
              onChange(name, newValue)
            }
          />
        </>
      )}
    </StyledConditionalInput>
  );
};

ConditionalInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ConditionalInput;
