import { AxiosError } from 'axios';
import { getClient } from 'common/api/contractors.api';
import {
  ClientIdTypeEnum,
  transformClientFromAPI,
  type GetClientResponse,
} from 'common/types/contractor';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useClientQuery = (
  id: string,
  idType?: ClientIdTypeEnum,
  queryOptions?: Pick<UseQueryOptions, 'staleTime' | 'cacheTime' | 'enabled'>,
): UseQueryResult<GetClientResponse, AxiosError> => {
  return useQuery(
    [`contractor-clients`, id],
    () => getClient(id, idType).then(getDataFromResponse),
    {
      enabled: !!id && queryOptions?.enabled,
      select: transformClientFromAPI,
      ...queryOptions,
    },
  );
};
