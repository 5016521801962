import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledEmployeeExpenseListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-top: ${({ theme: { space } }) => `${space[8]}`};
  }
`;

export const StyledAlertContainer = styled.div`
  > div {
    width: fit-content;
    padding-right: ${({ theme: { space } }) => `${space[64]}`};
  }
`;
