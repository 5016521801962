import {
  DateFormats,
  formatISODate,
  Typography,
} from '@omnipresentgroup/design-system';

interface DetailsCardDateProps {
  value: string;
  displayValue: string;
}

export const DetailsCardDateType = ({
  value,
  displayValue,
}: DetailsCardDateProps) => {
  /* 
    Should verify value before formatting it since there are some date fields 
    such as dateOfBirth which are optional and later filled by the employee
  */
  const transformingDate = value
    ? formatISODate(value, DateFormats.Date, true, '-')
    : null;
  const transformedDate =
    transformingDate === 'Invalid DateTime' ? value : transformingDate;

  return (
    <Typography as="p" hideParagraphSpacing size="16">
      {displayValue || transformedDate}
    </Typography>
  );
};
