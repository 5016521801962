import { AxiosError } from 'axios';
import {
  getBenefitsClientPreferences,
  getBenefitsClientPreferencesList,
} from 'common/api/benefits.api';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { getDataFromResponse } from 'utils/react-query-utils';

import { HTTPStatusCodesEnum } from '../../../../common/constants';
import {
  BenefitsClientPreferencesResponse,
  BenefitsCountrySelectionState,
} from '../../../../common/types';
import { benefitsKeys } from '../keys';

type Params = {
  countryCode: string;
  clientId: string;
};

const RETRY_TIMES_FOUR = 4;

export const useBenefitsClientPreferencesQuery = ({
  countryCode,
  clientId,
}: Params) => {
  const dispatch = useDispatch();

  return useQuery<Params, AxiosError, BenefitsClientPreferencesResponse>(
    benefitsKeys.clientPreferences(clientId, countryCode),
    () =>
      getBenefitsClientPreferences({ countryCode, clientId }).then(
        getDataFromResponse,
      ),
    {
      onError: (error: AxiosError) => {
        const status = error.response?.status;
        if (status === HTTPStatusCodesEnum.NOT_FOUND) {
          return;
        }
        dispatch({ type: 'GET_CLIENT_PREFERENCES_ERROR', payload: error });
      },

      retry: RETRY_TIMES_FOUR,
      refetchOnWindowFocus: true,
    },
  );
};

export const useBenefitsClientPreferencesListQuery = ({
  clientId,
  selectionStatus,
}: {
  clientId: string;
  selectionStatus?: BenefitsCountrySelectionState;
}) => {
  return useQuery<BenefitsClientPreferencesResponse[], Error>(
    benefitsKeys.clientPreferencesList(clientId, selectionStatus),
    () =>
      getBenefitsClientPreferencesList({ clientId, selectionStatus }).then(
        getDataFromResponse,
      ),
    {
      retry: RETRY_TIMES_FOUR,
      refetchOnWindowFocus: true,
    },
  );
};
