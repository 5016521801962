import { AxiosError } from 'axios';
import { getBenefitsByCountry } from 'common/api/benefits.api';
import { useQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { BenefitsCountryApiResponse } from '../../../../common/types';
import { benefitsKeys } from '../keys';

export const useBenefitsByCountry = (countryCode: string) => {
  return useQuery<
    { countryCode: string },
    AxiosError,
    BenefitsCountryApiResponse
  >(
    benefitsKeys.country(countryCode),
    () => getBenefitsByCountry(countryCode).then(getDataFromResponse),
    {
      retry: 4,
      enabled: !!countryCode,
    },
  );
};
