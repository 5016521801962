import { ReactComponent as HelpIcon } from 'assets/icon/Help.svg';
import { PageHeader } from 'common/components';
import styled from 'styled-components';

export const StyledPageHeader = styled(PageHeader)`
  padding-bottom: ${({ theme: { space } }) => space[32]};
`;

export const StyledSmallCopy = styled.span<{ white?: boolean }>`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[0]}`};
  margin: 0;
`;

export const StyledHelpIcon = styled(HelpIcon)`
  height: 12px;
  width: 13px;
  margin-left: ${({ theme: { space } }) => `${space[8]}`};
  cursor: pointer;
`;

export const ClickableCell = styled.span`
  cursor: pointer;
  margin-left: ${({ theme: { space } }) => `${space[8]}`};
`;
