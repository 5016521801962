import { appReducer } from 'app/store/reducers/app.reducer';
import { companiesReducer } from 'common/store/reducers/companies.reducer';
import { documentsReducer } from 'common/store/reducers/documents.reducer';
import { employeesReducer } from 'common/store/reducers/employee.reducer';
import { combineReducers } from 'redux';

import { expensesReducer } from '../../../employee/store/reducers/expenses.reducer';

const lspRootReducer = combineReducers({
  appReducer,
  employeesReducer,
  documentsReducer,
  expensesReducer,
  companiesReducer,
});

export default lspRootReducer;
