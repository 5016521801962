import styled from 'styled-components';

export const CardContainer = styled.div`
  background-color: ${({ theme: { colors } }) => colors['gray-100']};
  padding: ${({ theme: { space } }) => space[16]};
  border-radius: ${({ theme: { radii } }) => radii.s};
`;

export const ListContainer = styled.ul`
  margin: 0;
  padding: 0;
`;

export const ListRow = styled.li`
  display: flex;
  align-items: center;
  margin-top: ${({ theme: { space } }) => space[8]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};

  svg {
    margin-right: ${({ theme: { space } }) => space[4]};
    height: ${({ theme: { fontSizes } }) => `${fontSizes[2]}`};
    width: ${({ theme: { fontSizes } }) => `${fontSizes[2]}`};
    color: ${({ theme: { colors } }) => colors['text-default']};
  }
`;
