import { ReactComponent as CrossIcon } from 'assets/icon/Cross.svg';
import styled from 'styled-components';

type ModalContainerProps = {
  height?: 'max';
};

export const StyledModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  flex-direction: column;
  place-content: stretch flex-start;
  height: ${(props) => (props.height === 'max' ? '80vh' : '')};
  padding: ${({ theme: { space } }) => space[32]};
`;

export const StyledTitleContainer = styled.div`
  display: flex;
`;

export const StyledCloseIcon = styled(CrossIcon)`
  margin-left: auto;
  cursor: pointer;
`;

export const StyledTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  color: ${({ theme: { colors } }) => colors['text-default']};
`;

export const StyledDescription = styled.p`
  margin: ${({ theme: { space } }) => space[16]} 0;
  padding: 0;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  color: ${({ theme: { colors } }) => colors['text-default']};
  background: none;
`;

export const StyledCloseButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme: { space } }) => space[4]};
`;

export const StyledContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: ${({ theme: { space } }) => space[4]};
  overflow-y: auto;
`;

export const StyledButtonContainer = styled.div`
  border-top: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  padding-top: ${({ theme: { space } }) => space[16]};
  margin: ${({ theme: { space } }) => space[4]};
  display: flex;
  justify-content: space-between;
`;

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme: { colors } }) => colors['orange-300']};

  & > svg {
    padding: ${({ theme: { space } }) =>
      `${space[0]} ${space[4]} ${space[0]} ${space[4]}`};
  }
`;

export const ConfirmButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledAlertContainer = styled.div`
  margin: ${({ theme: { space } }) => `${space[16]} ${space[0]}}`};
`;
