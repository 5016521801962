import { useEffect, useRef } from 'react';

import { Search } from 'react-feather';

import {
  SearchIconContainer,
  StyledSearchInput,
  StyledSearchInputContainer,
} from './SearchInput.styles';

interface Props<DataToSearchType> {
  focus?: boolean;
  searchField: string;
  dataToSearch: DataToSearchType[];
  placeHolderText?: string;
  onFilteredData: (filteredData: DataToSearchType[]) => void;
  name?: string;
}

const SearchInput = <DataToSearchType,>({
  focus,
  searchField,
  dataToSearch,
  placeHolderText,
  onFilteredData,
  name = 'searchInputField',
}: Props<DataToSearchType>) => {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const searchData = (search: string) => {
    if (!search) {
      onFilteredData(dataToSearch);
      return;
    }

    const filteredData = dataToSearch.filter((data: any) =>
      data[searchField].toLowerCase().includes(search.toLowerCase()),
    );

    onFilteredData(filteredData);
  };

  const handleKeyUp = (e: any) => {
    const search = e.target.value;

    searchData(search);
  };

  useEffect(() => {
    if (focus) {
      searchInputRef?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const search = searchInputRef.current?.value || '';

    searchData(search);
    // eslint-disable-next-line
  }, [dataToSearch]);

  return (
    <StyledSearchInputContainer data-testid="search-input-container">
      <SearchIconContainer>
        <Search />
      </SearchIconContainer>
      <StyledSearchInput
        ref={searchInputRef}
        type="text"
        name={name}
        aria-label={name}
        placeholder={placeHolderText ? placeHolderText : 'search'}
        onKeyUp={handleKeyUp}
      />
    </StyledSearchInputContainer>
  );
};

export default SearchInput;
