/**
 * @deprecated Use `sortObjectsByKey` from 'utils/sortObjectsByKey' instead.
 */
export const sortAlphabetically = (key, list = [], order = 'asc') =>
  list.sort((itemOne, itemTwo) => {
    if (order === 'desc') {
      return itemOne[key].toLowerCase() < itemTwo[key].toLowerCase() ? 1 : -1;
    }
    return itemOne[key].toLowerCase() < itemTwo[key].toLowerCase() ? -1 : 1;
  });
