import appHistory from 'app/appHistory';
import logger from 'utils/logger';

const errorMiddleware = () => (next) => (action) => {
  if (action.payload?.response?.status === 401) {
    appHistory.push('/logout');
  }

  try {
    return next(action);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    logger.error(err, { extra: { action: action.type } });
    // re-throw error
    throw err;
  }
};

export default errorMiddleware;
