import {
  GlobalSetup,
  GlobalSetupErrorStatus,
  GlobalSetupStatus,
  GSCountryCode,
} from 'common/types/globalSetup';

import type { OptionType } from '@omnipresentgroup/design-system';

import { omniCreateClient } from '../../api';

export type SendVeInviteRequestParams = {
  userId: string;
  countryCode: string;
};

type SendVeInviteResponse = ErrorResponse | undefined;
export const sendVeInviteRequest = ({
  userId,
  countryCode,
}: SendVeInviteRequestParams) =>
  omniCreateClient.post<SendVeInviteResponse>(
    '/global-setup/virtual-employer/invite',
    {
      userId,
      countryCode,
    },
  );

export const getVeSupportedCountriesRequest = () =>
  omniCreateClient.get<OptionType[]>(
    '/global-setup/virtual-employer/supported-countries',
  );

export const getGlobalSetupRequest = (
  companyId: string,
  countryCode?: GSCountryCode,
) =>
  omniCreateClient.get<GlobalSetup[]>('/global-setup', {
    params: { companyId, countryCode },
  });

export const updateGlobalSetupStatusRequest = ({
  globalSetupId,
  status,
}: {
  globalSetupId: string;
  status: GlobalSetupStatus;
}) =>
  omniCreateClient.patch<GlobalSetup>(`/global-setup/${globalSetupId}/status`, {
    status,
  });

export type AdminUpdateValidStatuses = [
  { name: GlobalSetupStatus; isValid: boolean }[],
  { name: GlobalSetupErrorStatus; isValid: boolean }[],
];
export const getVirtualEmployerAdminUpdateStatuses = (
  currentStatus: GlobalSetupStatus | GlobalSetupErrorStatus,
  countryCode: GSCountryCode,
) =>
  omniCreateClient.get<AdminUpdateValidStatuses>(
    `/global-setup/virtual-employer/${countryCode}/statuses`,
    {
      params: { currentStatus },
    },
  );
