import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledModal = styled(ModalWrapper)`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  @media (min-width: ${tokens.breakpoint.tablet}) {
    height: 70%;
    position: relative;
    width: 60%;
    overflow-y: auto;
  }
`;

export const StyledCloseContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: end;
  padding: ${({ theme: { space } }) => `${space[8]}`};
  & > svg {
    color: ${({ theme: { colors } }) => `${colors['gray-300']}`};
  }
`;

export const StyledSubHeading = styled.span`
  color: ${({ theme: { colors } }) => colors['gray-300']};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
`;

export const StyledEmployeeListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 ${({ theme: { space } }) => `${space[64]}`};
`;
