import { useContext } from 'react';

import {
  Box,
  DateFormats,
  formatJSDate,
  Notification,
  Separator,
  Split,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import {
  EmployeeResignationRequest,
  ResignationRequestContext,
} from '../ResignationRequestForm';

const Item = ({
  field,
  value,
  hasSeparator = true,
}: {
  field: string;
  value: string | number | undefined;
  hasSeparator?: boolean;
}) => {
  return (
    <Stack gap="12">
      <Split>
        <Typography as="span" size="16" weight="medium" color="subtle">
          {field}
        </Typography>
        <Typography as="span" size="16" weight="regular">
          {value}
        </Typography>
      </Split>
      {hasSeparator && <Separator />}
    </Stack>
  );
};

export const ResignationRequestDetailCard = () => {
  const resignationRequest: EmployeeResignationRequest | null = useContext(
    ResignationRequestContext,
  );
  const scheduledTimeOff = resignationRequest?.employeeScheduledLeaves?.label;
  const lastWorkingDay = !!resignationRequest?.employeeLastWorkingDay
    ? formatJSDate(
        resignationRequest.employeeLastWorkingDay,
        DateFormats.Date,
        true,
      )
    : '';
  const vacationDaysSuffix =
    resignationRequest?.employeeNumberOfVacationsDays === '1' ? 'day' : 'days';
  const vacationDays = `${resignationRequest?.employeeNumberOfVacationsDays} ${vacationDaysSuffix}`;

  const isOtherResignationReason =
    resignationRequest?.resignationReason.value === 'other';

  const resignationReasonLabel = isOtherResignationReason
    ? `${resignationRequest?.resignationReason.label} - ${resignationRequest?.otherReason}`
    : resignationRequest?.resignationReason.label;

  return (
    <Stack>
      <Item field="Proposed last working day" value={lastWorkingDay} />
      <Item
        field="Communicated to manager"
        value={resignationRequest?.isManagerAware?.label}
      />
      <Item
        field="Currently on / scheduled for time off"
        value={scheduledTimeOff?.toString()}
      />
      <Item field="Number of time off days taken" value={vacationDays} />
      <Item
        field="Reason for resignation"
        value={resignationReasonLabel}
        hasSeparator={false}
      />
      <Box pt="16">
        <Notification
          id="resignation-alert"
          key="resignation-alert"
          intent="info"
          title="If you notice a mistake in your request after submission, proceed to create a new resignation request."
        />
      </Box>
    </Stack>
  );
};
