import { useMemo } from 'react';

import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { FIVE } from 'common/constants';
import { useGetVariantPaymentsQuery } from 'utils/queries/variantPayments/useGetVariantPaymentsQuery';

import {
  Box,
  Button,
  ContentState,
  Stack,
  Table,
} from '@omnipresentgroup/design-system';

import { CompensationNotice } from '../CompensationNotice/CompensationNotice';
import { Columns, LoadingColumns } from './compensationRequestListColumnConfig';

const DEFAULT_PAGE_SIZE = 10;

export const CompensationRequestsNoticeTitle =
  'The history currently keeps a record of allowances, commissions and bonuses. It does not include any salary changes at this time.';
export const ErrorStateTitle = "Uh-oh, something's gone wrong";
export const ErrorStateDescription =
  'Try reloading the page below, and if this fails get in touch with our support team.';

export const CompensationRequestList = ({
  companyId,
}: {
  companyId: string;
}) => {
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
  } = useGetVariantPaymentsQuery(companyId, {
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const items = useMemo(
    () => (data?.pages ?? []).flatMap((page) => page),
    [data?.pages],
  );

  const table = useReactTable({
    // Core Config
    data: items,
    columns: Columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const loadingTable = useReactTable({
    // Core Config
    data: Array.from({ length: FIVE }, (_value, index) => index),
    columns: LoadingColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Stack gap="24">
      {isError ? (
        <Box bg="primary" radius="m" border="subtle" overflow="hidden">
          <ContentState
            id="compensation-requests-error"
            variant="error"
            title={ErrorStateTitle}
            description={ErrorStateDescription}
            callToAction={
              <Button onClick={() => window.location.reload()}>Retry</Button>
            }
          />
        </Box>
      ) : (
        <Stack gap="32">
          <CompensationNotice />
          <Table
            id="compensation-requests-table"
            table={isLoading ? loadingTable : table}
            showLoadMore={!isLoading && hasNextPage}
            loadMoreProps={{
              loading: isFetchingNextPage,
              onClick: () => fetchNextPage(),
            }}
            showHeader={false}
          />
        </Stack>
      )}
    </Stack>
  );
};
