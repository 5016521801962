import { ChevronDown, ChevronUp } from 'react-feather';
import styled, { css } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

const StyledChevron = css`
  border-left: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  padding-left: calc(
    ${({ theme: { space } }) => `${space[32]} - ${space[16]}`}
  );
  margin-top: ${({ theme: { space } }) => `-${space[4]}`};
  color: ${({ theme: { colors } }) => `${colors['icon-default']}`};
`;

export const StyledDetails = styled.details`
  display: flex;
  font-family: ${({ theme: { fonts } }) => fonts.default};
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[2]}`};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  box-sizing: border-box;
  border-radius: ${({ theme: { radii } }) => `${radii.s}`};
  background: ${({ theme: { colors } }) => `${colors['background-primary']}`};
  padding: ${({ theme: { space } }) => `${space[8]} ${space[8]} 0 ${space[8]}`};

  :hover {
    cursor: pointer;
  }

  @media (max-width: ${tokens.breakpoint.tablet}) {
    flex-direction: column;
  }
`;

export const StyledDetailsTitle = styled.span`
  width: 100%;
  font-style: normal;
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.medium}`};
  user-select: none;
  padding-bottom: ${({ theme: { space } }) => `${space[8]}`};
`;

export const StyledSubtitle = styled.p`
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[1]}`};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.regular}`};
  color: ${({ theme: { colors } }) => `${colors['text-subtle']}`};
  margin-top: 0;
`;

export const StyledDescription = styled.div<{
  noMaxHeigh?: boolean;
  noOverflow?: boolean;
}>`
  cursor: default;
  padding: ${({ theme: { space } }) => `0 0 ${space[8]} 0`};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.regular}`};
  ${({ noOverflow }) => (noOverflow ? '' : 'overflow-y: auto;')}
  ${({ noMaxHeigh }) => (noMaxHeigh ? '' : 'max-height: 200px;')}
`;

export const StyledDetailsDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledSummary = styled.summary`
  list-style: none;
`;

export const StyledChevronUp = styled(ChevronUp)`
  ${StyledChevron}
`;

export const StyledChevronDown = styled(ChevronDown)`
  ${StyledChevron}
`;

export const StyledChevronContainer = styled.div`
  pointer-events: none;
  background: ${({ theme: { colors } }) => `${colors['background-primary']}`};
`;
