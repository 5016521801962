import { client } from 'api';
import { AxiosResponse } from 'axios';
import {
  BenefitReportResponse,
  BenefitsClientEnrollmentResponse,
  BenefitsClientPreferencesNew,
  BenefitsClientPreferencesResponse,
  BenefitsCountryApiResponse,
  BenefitsCountrySelectionState,
} from 'common/types';

export const BENEFITS_BASE_API_ENDPOINT = '/benefits';

export const getBenefitsByCountry = (countryCode: string) =>
  client.get<BenefitsCountryApiResponse>(
    `${BENEFITS_BASE_API_ENDPOINT}/catalog/country/${countryCode.toUpperCase()}`,
  );

export const getBenefitsClientPreferences = ({
  clientId,
  countryCode,
}: {
  clientId: string;
  countryCode: string;
}) =>
  client.get<BenefitsClientPreferencesResponse>(
    `${BENEFITS_BASE_API_ENDPOINT}/client/${clientId}/preferences?countryCode=${countryCode.toUpperCase()}`,
  );

export const getBenefitsEnrollmentByClient = async ({
  clientId,
}: {
  clientId: string;
}): Promise<AxiosResponse<BenefitsClientEnrollmentResponse>> =>
  client.get<BenefitsClientEnrollmentResponse>(
    `${BENEFITS_BASE_API_ENDPOINT}/client/${clientId}?showEnrollment=true`,
  );

export const postBenefitsClientPreferences = ({
  clientId,
  countryCode,
  selectionStatus,
}: BenefitsClientPreferencesNew) => {
  return client.post<BenefitsClientPreferencesResponse>(
    `${BENEFITS_BASE_API_ENDPOINT}/client/${clientId}/preferences?countryCode=${countryCode.toUpperCase()}`,
    { selectionStatus },
  );
};

export const getBenefitsClientPreferencesList = ({
  clientId,
  selectionStatus,
}: {
  clientId: string;
  selectionStatus?: BenefitsCountrySelectionState;
}) => {
  const params = {
    ...(selectionStatus ? { selectionStatus } : {}),
  };
  return client.get<BenefitsClientPreferencesResponse[]>(
    `${BENEFITS_BASE_API_ENDPOINT}/client/${clientId}/preferences`,
    {
      params,
    },
  );
};

export const getBenefitsBillingsSummaryReport = (payrollInstanceId: string) =>
  client.get<BenefitReportResponse>(
    `${BENEFITS_BASE_API_ENDPOINT}/billing/report/billing-summary`,
    {
      params: { payrollInstanceId },
    },
  );

export const getBenefitsPayrollBreakdownReport = (payrollInstanceId: string) =>
  client.get<BenefitReportResponse>(
    `${BENEFITS_BASE_API_ENDPOINT}/billing/report/payroll-summary`,
    {
      params: { payrollInstanceId },
    },
  );

export const getBenefitsGlobalBreakdownReport = (payrollInstanceId: string) =>
  client.get<BenefitReportResponse>(
    `${BENEFITS_BASE_API_ENDPOINT}/billing/report/global-summary`,
    {
      params: { payrollInstanceId },
    },
  );
