import { useHistory } from 'react-router-dom';

import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { EMPLOYMENT_HISTORY } from 'paths';
import { CheckCircle } from 'react-feather';

import {
  Button,
  Center,
  Inline,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import * as S from '../ResignationForm.styles';
import { ResignationRequestDetailCard } from './ResignationRequestDetailCard';

type ResignationSuccessModalProps = {
  companyName: string;
};

export const ResignationSuccessModal = ({
  companyName,
}: ResignationSuccessModalProps) => {
  const history = useHistory();
  return (
    <ModalWrapper
      showCloseIcon
      onClose={() => history.push(EMPLOYMENT_HISTORY)}
      fixedWidth="672"
    >
      <Stack gap="24" p="24">
        <Center p="8" centerChildren>
          <S.StyledCheckIcon>
            <CheckCircle data-testid="success-icon" />
          </S.StyledCheckIcon>
        </Center>
        <Center centerText>
          <Typography as="h1" size="20" weight="medium">
            Resignation request submitted successfully submitted!
          </Typography>
          <Typography as="span" size="16" color="subtle">
            We are sorry to see you intend to leave {companyName}. We have
            received your submission and we will be in touch with you shortly
            about next steps.
          </Typography>
        </Center>
        <ResignationRequestDetailCard />
        <Inline justify="right">
          <Button
            onClick={() => history.push(EMPLOYMENT_HISTORY)}
            data-testid="closeModalButton"
          >
            Back to history
          </Button>
        </Inline>
      </Stack>
    </ModalWrapper>
  );
};
