import {
  mockCompanyTimeOffLogsResponse,
  mockEmployeeTimeOffLogsResponse,
} from '__mocks__/timeOffLogs.mock';
import { employmentTimeClient } from 'api';
import {
  CompanyTimeOffLogsResponse,
  CreateTimeOffLogsPayload,
  EmployeeTimeOffLogsResponse,
  TimeOffLog,
} from 'common/types';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { PathParams, rest } from 'msw';

import { HTTPStatusCodesEnum } from '../constants/statusCode';
import {
  composeTimeOffLogsEndpointByScope,
  TIME_OFF_LOGS_BASE_API_ENDPOINT,
} from './timeOffLogs.api';

export const timeOffLogsHandlers = [
  rest.get<EmployeeTimeOffLogsResponse | TimeOffLog[]>(
    composeEndpoint(
      composeTimeOffLogsEndpointByScope('employee', ':employeeId', true),
      employmentTimeClient,
    ),
    (req, res, ctx) => {
      const query = req.url.searchParams;
      const year = query.get('year');
      const month = query.get('month');
      if (year && month) {
        return res(
          ctx.status(HTTPStatusCodesEnum.OK),
          ctx.json([mockEmployeeTimeOffLogsResponse.taken[0]]),
        );
      }
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockEmployeeTimeOffLogsResponse),
      );
    },
  ),
  rest.get<CompanyTimeOffLogsResponse>(
    composeEndpoint(
      composeTimeOffLogsEndpointByScope('company', ':companyId', true),
      employmentTimeClient,
    ),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockCompanyTimeOffLogsResponse),
      );
    },
  ),
  rest.post<CreateTimeOffLogsPayload, PathParams, TimeOffLog>(
    composeEndpoint(
      `${TIME_OFF_LOGS_BASE_API_ENDPOINT(true)}`,
      employmentTimeClient,
    ),
    (_req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.RESOURCE_CREATED),
        ctx.json(mockEmployeeTimeOffLogsResponse.scheduled[0]),
      );
    },
  ),
  rest.delete<undefined, { timeOffLogId: string }>(
    composeEndpoint(
      `${TIME_OFF_LOGS_BASE_API_ENDPOINT(true)}/requests/:timeOffLogId`,
      employmentTimeClient,
    ),
    (req, res, ctx) => {
      const { timeOffLogId } = req.params;

      if (!timeOffLogId) {
        return res(ctx.status(HTTPStatusCodesEnum.BAD_REQUEST));
      }

      return res(ctx.status(HTTPStatusCodesEnum.NO_CONTENT));
    },
  ),
];
