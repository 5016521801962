import { useCallback, useEffect, useState } from 'react';

import { sendPlatformInvitationRequest } from 'app/api/user.api';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { Manager } from 'common/types';
import { useMutation } from 'react-query';

import { Button, Typography } from '@omnipresentgroup/design-system';

type PlatformInvitationButtonProps = {
  userRole: string;
  userId: string | Manager;
  testId?: string;
};

const PlatformInvitationButton = ({
  userRole,
  userId,
  ...rest
}: PlatformInvitationButtonProps) => {
  const [platformInvitationProgress, setPlatformInvitationProgress] =
    useState('');

  const {
    isLoading,
    isError: isPlatformInviteError,
    error: platformInviteError,
    mutate: sendPlatformInviteToManager,
    data,
  }: Record<string, any> = useMutation(
    (payload: { userId: string | Manager }) =>
      sendPlatformInvitationRequest(payload.userId),
  );

  useEffect(() => {
    const platformInvitationProgressState = isLoading
      ? 'InProgress'
      : data?.data
        ? 'SENT'
        : '';
    setPlatformInvitationProgress(platformInvitationProgressState);
  }, [isLoading, data, setPlatformInvitationProgress]);

  const handleClick = useCallback(() => {
    sendPlatformInviteToManager({ userId });
  }, [userId, sendPlatformInviteToManager]);

  const hasSentInvitation = platformInvitationProgress === 'SENT';
  const isInProgress = platformInvitationProgress === 'InProgress';

  return (
    <>
      {isPlatformInviteError && (
        <ErrorBanner errorMessage={platformInviteError?.message} />
      )}
      {hasSentInvitation ? (
        <Typography as="p" hideParagraphSpacing size="16" weight="bold">
          Invite has been sent
        </Typography>
      ) : (
        <Button
          size="medium"
          variant="primary"
          onClick={handleClick}
          {...rest}
          loading={isInProgress}
        >
          Send platform invite to {userRole}
        </Button>
      )}
    </>
  );
};

export default PlatformInvitationButton;
