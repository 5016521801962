import {
  BillingItem,
  BillingItemApiResponse,
  ClientInvoice,
  ClientInvoiceApiResponse,
  ClientInvoiceEmployee,
  ClientInvoicesExportData,
  EmployeeBillingItem,
  EmployeeBillingItemApiResponse,
  EmployeeCost,
  EmployeeCostsLabels,
  EmployeeCostsName,
  LspBillingItemApiResponse,
  NSCustomersForPaginationView,
  PayrollInstance,
  PayrollInstanceApiResponse,
  SimpleInvoiceLog,
} from 'common/types/payroll';
import toPairs from 'lodash/toPairs';
import { InvoiceLog } from 'omniplatform/admin/pages/FinancePage/types';

const sortBillingItems = (a: BillingItem, b: BillingItem): number => {
  if (a.createdAt !== b.createdAt) {
    // No need to use "localCompare" for date because it's language agnostic
    return b.createdAt > a.createdAt ? 1 : -1;
  }

  if (a.lspName !== b.lspName) {
    return a.lspName.localeCompare(b.lspName);
  }

  if (a.invoiceNumber !== b.invoiceNumber) {
    return a.invoiceNumber?.localeCompare(b.invoiceNumber);
  }

  return 0;
};

// TODO: Resolve the types here
const transformAmountsToBillingBits = (costs: {
  salaryAmount?: string;
  lspFeeAmount?: string;
  expenseAmount?: string;
  variableAmount?: string;
  socialCostsAmount?: string;
  visaFeesAmount?: string;
  pensionContributionAmount?: string;
  onOffAmount?: string;
  depositAmount?: string;
  benefitAmount?: string;
  allowanceAmount?: string;
  otherFees?: string;
  nonStatutoryPensionContributionAmount?: string;
  nonStatutoryBenefitAmount?: string;
}) =>
  toPairs(costs)
    .filter(([, amount]) => amount && parseInt(amount) > 0)
    .map(([name, amount]) => {
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        type: EmployeeCostsLabels[name],
        amount,
      };
    });

export const transformBillingItemsApiResponse = (
  items: BillingItemApiResponse[],
): BillingItem[] => {
  return items
    .map(
      ({
        id,
        invoiceId,
        invoiceNumber,
        currency,
        totalAmount,
        lsp,
        company,
        employee,
        paymentInstructionExportId = 'NOT_EXPORTED',
        invoiceDate,
        clientInvoiceExportId = 'NOT_EXPORTED',
        clientCsvUrl,
        lspCsvUrl,
        createdAt,
        salaryAmount,
        lspFeeAmount,
        expenseAmount,
        variableAmount,
        socialCostsAmount,
        visaFeesAmount,
        pensionContributionAmount,
        onOffAmount,
        depositAmount,
        benefitAmount,
        allowanceAmount,
        otherFees,
        nonStatutoryPensionContributionAmount,
        nonStatutoryBenefitAmount,
        isBenefit,
      }) => ({
        id,
        invoiceId,
        invoiceNumber,
        invoiceDate,
        amount: totalAmount,
        lspName: lsp.name,
        lspCountryName: lsp.country,
        currency,
        companyName: company.name,
        employeeName: employee.name,
        employeeDetails: {
          billingCurrency: employee?.payrollDetails?.billingCurrency,
          managementFee: employee?.payrollDetails?.managementFee?.amount,
        },
        lspPayment:
          paymentInstructionExportId !== 'NOT_EXPORTED'
            ? { id: paymentInstructionExportId }
            : undefined,
        /**@todo Update BE to not return payrollExport (clientInvoice or lspPayment) when not exported and don't include clientInvoiceExportId*/
        clientInvoice:
          clientInvoiceExportId !== 'NOT_EXPORTED'
            ? { id: clientInvoiceExportId }
            : undefined,
        clientCsvUrl,
        lspCsvUrl,
        createdAt,
        billingBits: transformAmountsToBillingBits({
          salaryAmount,
          lspFeeAmount,
          expenseAmount,
          variableAmount,
          socialCostsAmount,
          visaFeesAmount,
          pensionContributionAmount,
          onOffAmount,
          depositAmount,
          benefitAmount,
          allowanceAmount,
          otherFees,
          nonStatutoryPensionContributionAmount,
          nonStatutoryBenefitAmount,
        }),
        isBenefit,
      }),
    )
    .sort(sortBillingItems);
};

export const transformBillingItemsLspApiResponse = (
  items: LspBillingItemApiResponse[],
): BillingItem[] =>
  items
    .map(
      ({
        id,
        invoiceId,
        invoiceNumber,
        currency,
        totalAmount,
        lsp,
        company,
        employee,
        paymentInstructionExportId = 'NOT_EXPORTED',
        invoiceDate,
        clientInvoiceExportId = 'NOT_EXPORTED',
        createdAt,
        salaryAmount,
        lspFeeAmount,
        expenseAmount,
        variableAmount,
        socialCostsAmount,
        visaFeesAmount,
        pensionContributionAmount,
        onOffAmount,
        depositAmount,
        benefitAmount,
        allowanceAmount,
        otherFees,
        nonStatutoryPensionContributionAmount,
        nonStatutoryBenefitAmount,
      }) => ({
        id,
        invoiceId,
        invoiceNumber,
        invoiceDate,
        amount: totalAmount,
        lspName: lsp.name,
        lspCountryName: lsp.country,
        currency,
        companyName: company.name,
        employeeName: employee.name,
        employeeDetails: {
          billingCurrency: employee?.payrollDetails?.billingCurrency,
          managementFee: employee?.payrollDetails?.managementFee?.amount,
        },
        lspPayment:
          paymentInstructionExportId !== 'NOT_EXPORTED'
            ? { id: paymentInstructionExportId }
            : undefined,
        clientInvoice:
          clientInvoiceExportId !== 'NOT_EXPORTED'
            ? { id: clientInvoiceExportId }
            : undefined,
        clientCsvUrl: 'Not Available from API response',
        lspCsvUrl: 'Not Available from API response',
        createdAt,
        billingBits: transformAmountsToBillingBits({
          salaryAmount,
          lspFeeAmount,
          expenseAmount,
          variableAmount,
          socialCostsAmount,
          visaFeesAmount,
          pensionContributionAmount,
          onOffAmount,
          depositAmount,
          benefitAmount,
          allowanceAmount,
          otherFees,
          nonStatutoryPensionContributionAmount,
          nonStatutoryBenefitAmount,
        }),
      }),
    )
    .sort(sortBillingItems);

export const transformEmployeeBillingItemApiResponse = (
  items: EmployeeBillingItemApiResponse[],
  removeZeroCosts?: boolean,
): EmployeeBillingItem[] =>
  items.map((item) => {
    const {
      id,
      employeeId,
      companyId,
      invoiceNumber,
      invoiceId,
      invoiceDate,
      currency,
      createdAt,
      lspId,
      clientInvoiceExportId = 'NOT_EXPORTED',
      paymentInstructionExportId = 'NOT_EXPORTED',
    } = item;

    // All fields ending with Amount, e.g. totalAmount, salaryAmount
    const costFieldsNames = (Object.keys(item) as EmployeeCostsName[]).filter(
      (i) => /Amount$/.test(i),
    );

    const costs = costFieldsNames.reduce<Record<string, EmployeeCost>>(
      (acc, fieldName) => {
        const amount = Number(item[fieldName]);
        if (removeZeroCosts && !amount) {
          return acc;
        }

        acc[fieldName] = {
          amount,
          name: fieldName,
          label:
            EmployeeCostsLabels[fieldName as keyof typeof EmployeeCostsLabels],
        };
        return acc;
      },
      {},
    );

    return {
      id,
      employeeId,
      companyId,
      invoiceNumber,
      invoiceId,
      invoiceDate,
      currency,
      createdAt,
      lspId,
      clientInvoiceExportId,
      paymentInstructionExportId,
      costs,
    } as EmployeeBillingItem;
  });

export const transformPayrollInstancesApiResponse = (
  items: PayrollInstanceApiResponse[] | PayrollInstance[],
): PayrollInstance[] =>
  items.map<PayrollInstance>((item) => {
    const { id, name, month, year, status, createdAt } = item;

    return {
      id,
      name,
      month,
      year,
      status,
      createdAt,
    };
  });

export const transformClientInvoicesApiResponse = (
  items: ClientInvoiceApiResponse[],
): ClientInvoicesExportData =>
  items.reduce(
    (acc, item) => {
      const itemId = item.id;
      if (!acc.companies[item.company.id]) {
        acc.companies[item.company.id] = {
          ...item.company,
          invoiceIds: [itemId],
        };
      }
      acc.companies[item.company.id].invoiceIds.push(itemId);
      acc.companies[item.company.id].invoiceIds = [
        ...new Set(acc.companies[item.company.id].invoiceIds),
      ];
      acc.invoices[itemId] = transformClientInvoicesExportItem(item);
      return acc;
    },
    { companies: {}, invoices: {} } as ClientInvoicesExportData,
  );

const transformClientInvoicesExportItem = (
  item: ClientInvoiceApiResponse,
): ClientInvoice => ({
  ...item,
  clientInvoiceEmployeeIds:
    item.clientInvoiceEmployees?.map(({ id }) => id) ?? [],
  clientInvoiceEmployees: transformClientInvoiceEmployeesApiResponse(
    item.clientInvoiceEmployees ?? [],
  ),
});

export const transformClientInvoiceEmployeesApiResponse = (
  clientInvoiceEmployees: ClientInvoiceEmployee[],
): ClientInvoiceEmployee[] => {
  return clientInvoiceEmployees.map((clientInvoiceEmployee) => {
    const {
      id,
      clientInvoiceId,
      payElementId,
      employeeId,
      employee,
      status,
      errorMessage,
      clientInvoiceEmployeeBillingBits,
      payElements = [],
      fxRate,
      amount,
      reference,
      createdAt,
    } = clientInvoiceEmployee;

    return {
      id,
      clientInvoiceId,
      payElementId,
      employeeId,
      employee,
      status,
      errorMessage,
      clientInvoiceEmployeeBillingBits,
      payElementCurrency: payElements[0]?.currency || '',
      fxRate,
      amount,
      reference,
      createdAt,
    };
  });
};

export interface PaginatedPayrollInstancesPageData extends PayrollInstance {
  onClick: () => void;
}

export const transformInvoicesLogsAPIResponse = (
  items: SimpleInvoiceLog[],
): InvoiceLog[] =>
  items.map((item) => ({
    id: item.id,
    client: item.companyName,
    employee: item.employeeName,
    type: item.invoiceType,
    date: item.createdAt,
    status: item.status,
    ...(item.simpleInvoice?.totalAmount && item.simpleInvoice?.currency
      ? {
          amount: {
            totalAmount: item.simpleInvoice.totalAmount,
            currency: item.simpleInvoice.currency,
          },
        }
      : {}),
  }));

export const transformNSCustomersAPIResponse = (
  items: NSCustomersForPaginationView[],
): NSCustomersForPaginationView[] =>
  items.map((item) => ({
    id: item.id,
    entityId: item.entityId,
    entityType: item.entityType,
    netSuiteId: item.netSuiteId,
    netSuiteType: item.netSuiteType,
    ...(item.company?.externalId && item.company?.name
      ? {
          company: {
            name: item.company.name,
            externalId: item.company.externalId,
          },
        }
      : {}),
  }));
