import { getSelectedCompanyHRISIntegrationsSelector } from 'common/store/selectors/companies.selectors';
import { Integration, IntegrationProvider } from 'common/types';
import { useSelector } from 'react-redux';

type HRISIntegrationFindByProvider = {
  provider: IntegrationProvider;
  integrationId?: never;
};

type HRISIntegrationFindByIntegrationId = {
  provider?: never;
  integrationId: string;
};

type HRISIntegrationFilter =
  | HRISIntegrationFindByProvider
  | HRISIntegrationFindByIntegrationId;

const fallback = {} as Integration;

export const useSelectCompanySelectedHRISIntegration = (
  filter?: HRISIntegrationFilter,
) => {
  const hrisIntegrations = useSelector<unknown, Integration[]>(
    getSelectedCompanyHRISIntegrationsSelector,
  );

  if (filter) {
    const { provider, integrationId } = filter;

    const filteredIntegration = hrisIntegrations.find((hris) => {
      if (provider) {
        return hris.provider === provider;
      }

      if (integrationId) {
        return hris.integrationId === integrationId;
      }

      return undefined;
    });

    return filteredIntegration || fallback;
  }

  return hrisIntegrations[0] || fallback;
};
