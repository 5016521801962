import { OnboardingStatusValues } from 'common/types/employee';

type ProgressTrackerPermissionProps = {
  isAdmin: boolean;
  isManager: boolean;
  isEmployee: boolean;
  isLSP: boolean;
  employeeType: string;
  employee: any;
};

export const shouldShowOnboardingProgressTracker = ({
  isAdmin,
  isManager,
  isEmployee,
  isLSP,
  employeeType,
  employee,
}: ProgressTrackerPermissionProps) => {
  const isEOREmployee = isEmployee && employeeType === 'EOR';
  const isInProgressEmployee =
    employee.onboardingStatus === OnboardingStatusValues.INPROGRESS;
  const hasPermissionsToSeeProgressTracker =
    isLSP || isAdmin || isEOREmployee || isManager;
  return hasPermissionsToSeeProgressTracker && isInProgressEmployee;
};
