import { ChangeEvent, useCallback, useRef, useState } from 'react';

import SearchInput from 'common/components/molecules/SearchInput/SearchInput';
import { sortAlphabetically } from 'utils/sort.util';

import { Box } from '@omnipresentgroup/design-system';

import { AssociatedEmployee, EmployeesList } from '../EmployeesList';

export type EmployeesSearchWithListProps = {
  employees: AssociatedEmployee[];
  hasCheckboxSelection?: boolean;
  onUpdateSelection?: (updatedEmployees: string[]) => void;
};

export const EmployeesSearchWithList = ({
  employees,
  hasCheckboxSelection,
  onUpdateSelection,
}: EmployeesSearchWithListProps) => {
  const [filteredEmployees, setFilteredEmployees] = useState(employees);
  const checkedItems = employees
    .filter((employee) => employee.isAssociated)
    .map((employee) => employee.userId);
  const selectedEmployees = useRef<string[]>(checkedItems);

  const onCheckboxChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      employeeList: AssociatedEmployee[],
    ) => {
      const {
        target: { checked },
      } = event;

      const selectedEmployeesIds = employeeList?.map(
        (employee) => employee.userId,
      );

      if (checked) {
        selectedEmployees.current =
          selectedEmployees.current.concat(selectedEmployeesIds);
      } else {
        selectedEmployees.current = selectedEmployees.current.filter(
          (selectedEmployee) =>
            !selectedEmployeesIds.includes(selectedEmployee),
        );
      }

      selectedEmployees.current = Array.from(
        new Set(selectedEmployees.current),
      );

      if (onUpdateSelection) {
        onUpdateSelection(selectedEmployees.current);
      }
    },
    [onUpdateSelection, selectedEmployees],
  );

  const onFilteredData = useCallback((filteredData: AssociatedEmployee[]) => {
    const filteredDataWithSelectedEmployees = filteredData.map((item) => ({
      ...item,
      isAssociated: selectedEmployees.current.some(
        (selectedEmployee) => selectedEmployee === item.userId,
      ),
    }));
    const sortedEmployees = sortAlphabetically(
      'name',
      filteredDataWithSelectedEmployees,
    );
    setFilteredEmployees(sortedEmployees);
  }, []);

  return (
    <>
      <Box mb="4" pl="8">
        <SearchInput
          searchField="name"
          dataToSearch={employees}
          placeHolderText="Search"
          onFilteredData={onFilteredData}
          name="searchEmployees"
        />
      </Box>
      <EmployeesList
        items={filteredEmployees}
        hasCheckboxSelection={hasCheckboxSelection}
        onCheckboxChange={onCheckboxChange}
      />
    </>
  );
};
