import { useEffect } from 'react';

import { TransformedUser } from 'app/store/app.transformers';
import { isManager } from 'utils/user';

export const usePlanhatIntegration = (user: TransformedUser) => {
  useEffect(() => {
    if (process.env.VITE_PLANHAT_TENANT_ID && isManager(user)) {
      // @ts-ignore as plantrack is globally defined in the index.html
      plantrack.identify(null, { email: user?.email });
    }
  }, [user]);
};
