import {
  APPROVED,
  ARCHIVED,
  ContractStatus,
  DRAFT,
  PENDING_REVIEW,
  SIGNED_BOTH_PARTIES,
  SIGNED_EMPLOYEE,
} from 'common/types';

import type { TagIntent } from '@omnipresentgroup/design-system';

export function useStatusIntent(status?: ContractStatus): TagIntent {
  switch (status) {
    case APPROVED:
      return 'default';
    case ARCHIVED:
      return 'error';
    default:
    case DRAFT:
    case PENDING_REVIEW:
      return 'warning';
    case SIGNED_BOTH_PARTIES:
      return 'success';
    case SIGNED_EMPLOYEE:
      return 'warning';
  }
}
