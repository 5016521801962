import { MergeEmployeeExpandedFieldsDTO } from 'common/types';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';

type TransformedDataFields =
  | 'firstName'
  | 'lastName'
  | 'personalEmail'
  | 'workEmail'
  | 'startDate'
  | 'country'
  | 'city'
  | 'jobTitle'
  | 'jobDescription'
  | 'currency';

export type HRISIntegrationTransformedData = Record<
  TransformedDataFields,
  string
> & {
  amount: number | null;
};

export const hrisIntegrationTransformer = (
  data: MergeEmployeeExpandedFieldsDTO,
): HRISIntegrationTransformedData => {
  const { employments, remote_data: _, work_location, ...usableData } = data;

  const parsedEmployeeData = (
    Object.keys(usableData) as Array<keyof typeof usableData>
  ).reduce(
    (acc, curr) => {
      acc[curr] = data[curr] || '';

      return acc;
    },
    <Record<keyof typeof usableData, string>>{},
  );

  const roleData = employments?.[employments.length - 1];

  return {
    country: work_location?.country || '',
    firstName: parsedEmployeeData.first_name,
    lastName: parsedEmployeeData.last_name,
    personalEmail: parsedEmployeeData.personal_email,
    city: work_location?.city || '',
    jobTitle: roleData?.job_title || '',
    jobDescription: roleData?.job_title || '',
    currency: roleData?.pay_currency || '',
    amount:
      roleData?.pay_period === 'YEAR' ? parseFloat(roleData.pay_rate) : null,
    startDate: formatISODate(
      parsedEmployeeData.start_date.replace(/Z$/, ''),
      DateFormats.FixedDate,
      false,
      '',
    ),
    workEmail: parsedEmployeeData.work_email,
  };
};
