import type { IsoDateTime } from '@omnipresentgroup/design-system';

export type ResignationModel = {
  id: string;
  companyId: string;
  employeeId: string;
  employmentId: string;
  employeeLastWorkingDay: string;
  isManagerAware: boolean;
  employeeScheduledLeaves: string[];
  employeeNumberOfVacationsDays: number;
  resignationReason: string;
  otherReason?: string;
  createdBy: string;
  createdAt: IsoDateTime;
  status?: ResignationStatus;
};

export type PostResignationModel = Omit<
  ResignationModel,
  'id' | 'createdBy' | 'createdAt'
>;

export enum ResignationStatus {
  REQUESTED = 'Requested',
  REVIEWED = 'Reviewed',
}

export type ResignationRequest = {
  id: string;
  employeeId: string;
  name: string;
  jobTitle: string;
  country: string;
  status?: ResignationStatus;
};
