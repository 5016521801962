import { Link } from '@omnipresentgroup/design-system';

type Props = {
  value: string;
  displayValue: string;
};

const DetailsCardLinkType = ({ value, displayValue }: Props) => {
  return (
    <Link size="16" to={value} target="_blank" rel="noopener noreferrer">
      {displayValue || value}
    </Link>
  );
};

export default DetailsCardLinkType;
