import {
  PayrollInstanceApiResponse,
  PayrollInstanceStatus,
} from './../../../common/types/payroll';

export const mockPayrollInstance: PayrollInstanceApiResponse = {
  id: 'mock-id',
  name: 'Payroll instance for June',
  month: 4,
  year: 2022,
  status: PayrollInstanceStatus.IN_PROGRESS,
  createdAt: '2022-04-16T15:00:00.000Z',
};
