import { ReactComponent as OmniContract } from 'assets/icon/OmniContract.svg';

import {
  Box,
  Inline,
  omniConfig,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

type CountryLabel = string | undefined;

const componentMaxWidthCss = { 'max-width': '636px' };

const infoCss = {
  background: omniConfig.theme.colors['red-100'],
  border: `${omniConfig.theme.borderWidths.m} ${omniConfig.theme.borderStyles.solid} ${omniConfig.theme.colors['pink-500']}`,
};

export const ConfigureBenefitsDeprecated = ({
  countryLabel,
}: {
  countryLabel: CountryLabel;
}) => (
  <Stack mt="32">
    <Stack bg="primary" css={componentMaxWidthCss} radius="s">
      <Box css={infoCss} p="16" radius="s">
        <Inline align="center">
          <OmniContract />
          <Typography as="span" size="16">
            We provide supplementary benefits in {countryLabel}
          </Typography>
        </Inline>
      </Box>
      <Box>
        <Typography as="span" size="16">
          Next you&apos;ll be asked to provide some information about the type
          of benefits you want to provide for this employee.
        </Typography>
      </Box>
    </Stack>
  </Stack>
);

ConfigureBenefitsDeprecated.defaultProps = {
  countryLabel: 'United Kingdom',
};
ConfigureBenefitsDeprecated.displayName = 'ConfigureBenefits';
