import {
  mockOngoardingProgressResponse,
  mockPatchOnboardingProgressResponse,
} from '__mocks__/employeeOnboardingProgress';
import { mockEmployeesLspView } from '__mocks__/employees.mock';
import { HTTPStatusCodesEnum } from 'common/constants';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';
import { getMockedPaginatedEployees } from 'omniplatform/admin/__mocks__/paginatedEmployeesMockUtil';
import { mockDepartment } from 'omniplatform/employee/__mocks__/department.mock';
import { mockEmployee } from 'omniplatform/employee/__mocks__/employee.mock';
import { employees } from 'omniplatform/manager/__mocks__/managersEmployees.mock';

import { getISOString } from '@omnipresentgroup/design-system';

export const mockBulkSalaryChangeValidationResult = {
  totSuccess: 2,
  successUrl: 'successfile.csv',
  totErrors: 0,
  errorsUrl: '',
};

export const employeesHandlers = [
  rest.get(composeEndpoint('/employees'), (req, res, ctx) =>
    res(
      ctx.status(HTTPStatusCodesEnum.OK),
      ctx.json({ employees: [mockEmployee] }),
    ),
  ),
  rest.get(composeEndpoint('/employees/paginated/'), (req, res, ctx) =>
    res(
      ctx.status(HTTPStatusCodesEnum.OK),
      ctx.json(getMockedPaginatedEployees()),
    ),
  ),
  rest.get(composeEndpoint('/employees/:employeeId'), (req, res, ctx) => {
    return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockEmployee));
  }),
  rest.options(composeEndpoint('/employees/:employeeId'), (req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json({})),
  ),
  rest.patch(
    composeEndpoint('/employees/:employeeId'),
    async (req, res, ctx) => {
      const body = await req.json();
      const id = req.params.employeeId;
      const employee = generateEmployee();
      const response = {
        ...employee,
        ...body,
        id,
        updatedAt: getISOString(),
      };
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),
  rest.get(
    composeEndpoint('/employees/manager/:managerId'),
    (req, res, ctx) => {
      const hasQPSimplified = req.url.searchParams.get('simplified') === 'true';
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          employees: hasQPSimplified
            ? employees.employees
            : [mockEmployeesLspView[1], mockEmployeesLspView[2]],
        }),
      );
    },
  ),
  rest.patch(
    composeEndpoint('/employees/:employeeId/compensation'),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(DEFAULT_EMPLOYEE)),
  ),
  rest.post(
    composeEndpoint('/employees/:employeeId/netsuite/external-employee'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.RESOURCE_CREATED),
        ctx.json({ netsuiteId: 'mockNetsuiteId' }),
      ),
  ),
  rest.get(
    composeEndpoint('/compensation/reference-data/employee-departments'),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockDepartment)),
  ),
  rest.get(
    composeEndpoint('/onboarding-progress/employee/:employeeId'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockOngoardingProgressResponse),
      ),
  ),
  rest.patch(
    composeEndpoint('/onboarding-progress/employee/:employeeId'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockPatchOnboardingProgressResponse),
      ),
  ),
  rest.post(
    composeEndpoint('/employees/compensation/bulk-template'),
    (req, res, ctx) => res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json('')),
  ),
  rest.post(
    composeEndpoint('/employees/compensation/bulk-validation'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockBulkSalaryChangeValidationResult),
      ),
  ),
  rest.post(composeEndpoint('/employees/compensation/bulk'), (req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.RESOURCE_CREATED), ctx.json('')),
  ),
];

const DEFAULT_EMPLOYEE = {
  name: 'another employee',
  userId: 'a13172b3-acc5-4dde-a4bc-1bb61433fbba',
  managerId: 'F2WXbqrlPaVS',
  profilePicture:
    'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
  needsRequiredFieldsFilled: false,
  requireTypeFormFilled: true,
  onboardingFormResponses: [],
  externalReferences: [{}],
  id: '84d6967a-3429-4ef0-ba86-8573ee4a8b0c',
  externalId: 'SS1-22-6242793',
  country: { id: '', name: 'United Kingdom', currency: '' },
  contactDetails: {
    personalEmail: 'simon.godefroid+anotheremployee@omnipresent.com',
    phoneNumber: '0123123213',
    workEmail: '',
  },
  onboardingStatus: { status: 'Pending' },
  personalDetails: {
    salutation: '',
    firstName: 'Another',
    lastName: 'Employee',
    dateOfBirth: '2000-01-01',
    socialInsuranceNumber: {
      localName: 'Social insurance number',
      number: '1233 677',
    },
    maritalStatus: '',
    noOfChildren: 0,
    privateAddress: {
      addressLine1: '1 rue du test',
      city: 'London',
      postalCode: '1234',
      country: 'United Kingdom',
      addressLine2: 'lol',
      addressLine3: '',
    },
    citizenship: 'United Kingdom',
    bankingDetails: '1312321 123123 1',
    gender: 'Male',
  },
  employmentDetails: {
    locationOfWork: {
      type: 'Home office',
      address: {
        addressLine1: '123',
        city: 'London',
        postalCode: '1231231 ',
        country: '',
      },
    },
    adminstrativeInformation: {
      department: 'Legal',
    },
    jobTitle: 'Maker',
    startDate: '',
    firstPayDate: '',
    jobDescription: 'Makes stuff',
    additionalCompensation: '',
    hoursOfWork: 0,
    rightToWorkCheckCompleted: true,
    probationaryPeriod: { length: 0, timeUnit: 'Months' },
    annualBaseSalary: { amount: 1000000000000000, currency: 'GBP' },
    leave: [{ type: 'holiday:yearly-allowance-generic' }],
  },
  employeeType: 'EOR',
  createdAt: '2022-03-14T06:08:21.982Z',
  updatedAt: '2022-04-01T11:04:30.627Z',
  contractQuestionnaire: {
    templateId: 50534,
    body: {
      probationPeriodYesNo: {
        position: 3,
        title: 'Will there be a probation period?',
        type: 'radio_button',
        value: 'No',
        hint: null,
      },
      noOfHolidays: {
        position: 12,
        title:
          'Please provide the number of days for annual leave excluding public holidays.',
        type: 'select_list',
        value: '25',
        hint: 'Statutory minimum holiday entitlement is 20 days excluding public holidays. Best practice is granting 25 - 28 excluding public holidays.',
      },
      companyName: {
        position: 18,
        title: 'What is the name of your company?',
        type: 'text',
        value: 'Simon Says',
        hint: null,
      },
      restrictiveCovenantsYesNo: {
        position: 19,
        title:
          'Should the employment contract include Restrictive Covenant Clauses (Non-compete, Non-solicit)?',
        type: 'radio_button',
        value: 'No',
        hint: null,
      },
      startDate: {
        position: 2,
        title: 'Please provide the employment starting date.',
        type: 'date',
        value: '2023-03-03',
        hint: 'Please pick a date.',
      },
    },
  },
  updatedByAuthId: 'auth0|60900ca7bf3ad00070cd4634',
  contracts: [
    {
      createdAt: '2022-03-16T11:25:09.018Z',
      documentId: '78febdea-62ad-448d-a1b4-7ff17f2941f2',
      version: 1,
      updatedAt: '2022-03-16T11:25:09.018Z',
      status: 'draft',
    },
    {
      createdAt: '2022-03-14T06:08:30.120Z',
      archivedAt: '2022-03-24T05:02:04.450Z',
      avvokaDocumentId: 70532,
      contractId: '7201c50b-a111-4c3b-8983-a55285bd162f',
      documentId: 'c632411c-4db8-4edd-92c8-1249c5822020',
      version: 1,
      status: 'archived',
      updatedAt: '2022-03-14T06:08:30.120Z',
      avvokaTemplateId: 42053,
    },
  ],
  localOnboardingDetails: [],
  adminNotes: '',
  payrollDetails: {
    managementFee: { currency: 'USD', amount: 1 },
    setupFee: { currency: 'USD', amount: 1 },
    billingCurrency: 'USD',
    depositType: 'None',
  },
  temporaryPassword: 'WHkArbf;peUA14',
  seenPrivacyPolicy: '2022-03-14T06:11:24.313Z',
  companyName: '',
};

export const generateEmployee = (override = {}) => {
  return { ...DEFAULT_EMPLOYEE, ...override };
};

/* When you need to do a local override make use of this function */
