import styled from 'styled-components';

export const ActionWrapper = styled.div`
  display: flex;
  align-items: row;

  & > svg {
    fill: black;
    height: 1.75rem;

    &:hover {
      cursor: pointer;
    }
  }
`;
