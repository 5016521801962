import { getUserRequest } from 'app/api/user.api';
import appHistory from 'app/appHistory';
import { SET_ERROR_MESSAGE } from 'app/store/app.constants';
import { getUserRoleSelector } from 'app/store/app.selectors';
import { getBenefitsClientPreferences } from 'common/api/benefits.api';
import { DuplicateEmailErrorMessage } from 'common/components/molecules/ErrorBanner/ErrorMessages';
import { HTTPStatusCodesEnum } from 'common/constants';
import { getTenantIdSelector } from 'common/store/selectors/companies.selectors';
import { UserRoleTypeEnum } from 'common/types/user';
import { EMPLOYEE_PROFILE_PAGE, EMPLOYEES_PATH } from 'paths';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getCountryCodeByCountryName } from 'utils/countries';
import { createActionObject } from 'utils/redux-utils';
import { sortAlphabetically } from 'utils/sort.util';

import {
  addEmployeeRequest,
  getEmployeeDataExportRequest,
  getEmployeeRequest,
  getManagersEmployeesRequest,
  updateEmployeeRequest,
} from '../../api/employees.api';
import { asyncActionKeys } from '../actions/redux.constants';
import { getSelectedEmployeeSelector } from '../selectors/employees.selector';
import { transformCreateEmployeePayload } from '../transformers/createEmployeePayload.transformers';
import {
  transformEmployeeForDetailsPage,
  transformSimplifiedEmployee,
  transformUpdateEmployeePayload,
} from '../transformers/employees.transformer';

export function* getManagersEmployees({ payload: companyId }) {
  try {
    const {
      data: { employees },
    } = yield call(getManagersEmployeesRequest, companyId);
    return yield put(
      createActionObject(
        asyncActionKeys.GET_MANAGERS_EMPLOYEES_END,
        sortAlphabetically('name', employees.map(transformSimplifiedEmployee)),
      ),
    );
  } catch (error) {
    return yield put(
      createActionObject(asyncActionKeys.GET_MANAGERS_EMPLOYEES_ERROR, error),
    );
  }
}

export function* getEmployee({ payload: employeeId }) {
  try {
    const userRole = yield select(getUserRoleSelector);
    const { data: employee } = yield call(getEmployeeRequest, employeeId);
    let employeeBenefitsClientSelection = '';
    if (
      userRole === UserRoleTypeEnum.ADMIN ||
      userRole === UserRoleTypeEnum.BENEFITS_SPECIALIST
    ) {
      const countryName = employee?.country?.name;
      const countryCode = getCountryCodeByCountryName(countryName);
      const { data: benefitsClientPreference } = yield call(
        getBenefitsClientPreferences,
        {
          clientId: employee.managerId,
          countryCode,
        },
      );
      employeeBenefitsClientSelection =
        benefitsClientPreference?.selectionStatus;
    }

    let employeeTemporaryPassword = '';

    // Temporary password is stored on the user object and so we need to get the employees user object here
    // This will need refactoring when https://github.com/OmnipresentGroup/OmniPlatform/pull/237 goes in
    if (userRole === UserRoleTypeEnum.ADMIN) {
      const { data: employeeUser } = yield call(
        getUserRequest,
        employee.userId,
      );
      employeeTemporaryPassword = employeeUser.temporaryPassword;
    }

    return yield put(
      createActionObject(
        asyncActionKeys.GET_EMPLOYEE_END,
        transformEmployeeForDetailsPage({
          ...employee,
          temporaryPassword: employeeTemporaryPassword,
          benefitsClientSelection: employeeBenefitsClientSelection,
        }),
      ),
    );
  } catch (e) {
    if (e?.response?.status === HTTPStatusCodesEnum.NOT_FOUND) {
      return appHistory.push('/404');
    }
    return yield put(createActionObject(asyncActionKeys.GET_EMPLOYEE_ERROR, e));
  }
}

export function* updateEmployee({
  payload,
  isBankDetailsOrAddressFieldUpdated,
}) {
  try {
    const { id: employeeId } = yield select(getSelectedEmployeeSelector);
    const userRole = yield select(getUserRoleSelector);

    yield call(
      updateEmployeeRequest,
      employeeId,
      transformUpdateEmployeePayload(payload),
    );

    yield call(getEmployee, { payload: employeeId });
    yield put(createActionObject(asyncActionKeys.UPDATE_EMPLOYEE_END));
    if (userRole === 'manager' || userRole === 'admin') {
      appHistory.push(`${EMPLOYEES_PATH}/${employeeId}`);
    }

    if (userRole === 'employee') {
      if (isBankDetailsOrAddressFieldUpdated) {
        return;
      } else {
        appHistory.push(EMPLOYEE_PROFILE_PAGE);
      }
    }
  } catch (e) {
    yield put(createActionObject(asyncActionKeys.UPDATE_EMPLOYEE_ERROR, e));
  }
}

export function* updateEmployeeEmploymentFAQ({ payload }) {
  try {
    const { id: employeeId } = yield select(getSelectedEmployeeSelector);
    yield call(
      updateEmployeeRequest,
      employeeId,
      transformUpdateEmployeePayload(payload),
    );

    yield call(getEmployee, { payload: employeeId });
    yield put(createActionObject(asyncActionKeys.UPDATE_EMPLOYEE_END));
  } catch (e) {
    yield put(
      createActionObject(
        asyncActionKeys.UPDATE_EMPLOYEE_EMPLOYMENT_FAQ_ERROR,
        e,
      ),
    );
  }
}

export function* addEmployee({ payload }) {
  try {
    const tenantId = yield select(getTenantIdSelector);
    yield call(
      addEmployeeRequest,
      transformCreateEmployeePayload(payload.employeeInfo),
      payload.companyId || tenantId,
    );
    yield put(createActionObject(asyncActionKeys.CREATE_EMPLOYEE_END));
  } catch (e) {
    if (e.response.status === HTTPStatusCodesEnum.CONFLICT) {
      yield put(
        createActionObject(SET_ERROR_MESSAGE, DuplicateEmailErrorMessage),
      );
    }
    yield put(createActionObject(asyncActionKeys.CREATE_EMPLOYEE_ERROR, e));
  }
}

export function* getEmployeeDataExport({ payload }) {
  try {
    const {
      data: { url },
    } = yield call(getEmployeeDataExportRequest, payload.employeeId);
    yield put(createActionObject(asyncActionKeys.GET_DATA_EXPORT_END, url));
  } catch (e) {
    yield put(createActionObject(asyncActionKeys.GET_DATA_EXPORT_ERROR, e));
  }
}

export default [
  takeLatest(
    asyncActionKeys.GET_MANAGERS_EMPLOYEES_START,
    getManagersEmployees,
  ),
  takeLatest(asyncActionKeys.GET_EMPLOYEE_START, getEmployee),
  takeLatest(asyncActionKeys.UPDATE_EMPLOYEE_START, updateEmployee),
  takeLatest(
    asyncActionKeys.UPDATE_EMPLOYEE_EMPLOYMENT_FAQ_START,
    updateEmployeeEmploymentFAQ,
  ),
  takeLatest(asyncActionKeys.CREATE_EMPLOYEE_START, addEmployee),
  takeLatest(asyncActionKeys.GET_DATA_EXPORT_START, getEmployeeDataExport),
];
