import { useParams } from 'react-router-dom';

import { getUserRoleSelector } from 'app/store/app.selectors';
import { getEmployeeSelector } from 'omniplatform/employee/store/selectors/employee.selector';
import { useSelector } from 'react-redux';

export const useEmployeeIdByUserRole = () => {
  const userRole = useSelector(getUserRoleSelector);
  const { employeeId } = useParams<{ employeeId: string }>();
  const employee = useSelector(getEmployeeSelector);
  if (userRole !== 'employee') {
    return employeeId;
  }
  return employee.id;
};
