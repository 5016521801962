import appHistory from 'app/appHistory';
import { ADD_EMPLOYEE_EXPENSE_PAGE } from 'paths';

import { Typography } from '@omnipresentgroup/design-system';

import Button from '../../atoms/Button/Button';
import { StyledFullWidthBlueDivider } from '../../atoms/Divider/Divider.styles';
import { StyledBodyCopy } from '../../atoms/Text/Copy.styles';
import { StyledNoExpensesContainer } from './NoExpenses.styles';

const NoExpenses = () => (
  <StyledNoExpensesContainer
    className="bigStack"
    data-testid="no-expenses-container"
  >
    <div className="smallStack">
      <Typography
        as="h3"
        hideParagraphSpacing
        size="24"
        weight="bold"
        css={{ margin: 0 }}
      >
        You haven&apos;t claimed any expenses yet
      </Typography>
      <StyledFullWidthBlueDivider />
    </div>
    <StyledBodyCopy>
      To claim an expense, click the button below.
    </StyledBodyCopy>
    <Button
      palette="secondary"
      onClick={() => appHistory.push(ADD_EMPLOYEE_EXPENSE_PAGE)}
      testId="no-expenses-button"
    >
      Claim expense
    </Button>
  </StyledNoExpensesContainer>
);

export default NoExpenses;
