import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
type ProviderProps = {};

export function createCtx<A>(defaultValue: A) {
  type UpdateType = Dispatch<SetStateAction<typeof defaultValue>>;
  const defaultUpdate: UpdateType = () => defaultValue;

  const ctx = createContext({
    state: defaultValue,
    update: defaultUpdate,
  });

  const Provider = (props: PropsWithChildren<ProviderProps>) => {
    const [state, update] = useState(defaultValue);
    const ctxValue = useMemo(
      () => ({
        state,
        update,
      }),
      [state],
    );
    return <ctx.Provider value={ctxValue} {...props} />;
  };
  return [ctx, Provider] as [typeof ctx, typeof Provider];
}
