import styled, { css } from 'styled-components';

import {
  omniConfig,
  Typography,
  TypographyProps,
} from '@omnipresentgroup/design-system';

interface TextLinkProps {
  $isActive: boolean;
}

const TypographyWrapper = (props: TypographyProps<'span'>) => (
  <Typography {...props} as="span" size="14" />
);

export const TextLink = styled(TypographyWrapper)<TextLinkProps>`
  ${({ $isActive }) => {
    if (!$isActive) {
      return css`
        pointer-events: none;
        color: ${omniConfig.theme.colors['text-default']};
      `;
    }
    return css`
      color: ${omniConfig.theme.colors['link-default']};
      cursor: pointer;
    `;
  }}
`;

export const LoadingContainer = styled.div`
  > div {
    background: ${omniConfig.theme.colors['overlay']};
  }
`;
