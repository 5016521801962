import { User } from 'common/types';

import { Tag } from '@omnipresentgroup/design-system';

import * as S from './ManagerCard.styles';

type ManagerCardProps = {
  item: User;
  cardHeader: string;
  superAdmin?: boolean;
  selected: boolean;
  headerId: string;
} & React.ComponentProps<typeof S.ManagerCardContainer>;

export const ManagerCard = ({
  item,
  cardHeader,
  superAdmin,
  selected = false,
  headerId,
  ...rest
}: ManagerCardProps) => {
  return (
    <S.ManagerCardContainer
      superAdmin={superAdmin}
      aria-labelledby={headerId}
      checked={selected}
      aria-checked={selected}
      data-testid="manager-card-container"
      {...rest}
    >
      <S.ManagerCardContentContainer>
        <S.ManagerCardContentText>
          <S.ManagerCardHeader id={headerId}>{cardHeader}</S.ManagerCardHeader>
        </S.ManagerCardContentText>
      </S.ManagerCardContentContainer>
      {superAdmin && (
        <S.ManagerCardSuperAdminContentContainer>
          <Tag id="super-admin-tag">Super Admin</Tag>
        </S.ManagerCardSuperAdminContentContainer>
      )}
    </S.ManagerCardContainer>
  );
};
