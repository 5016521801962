import { StyledBodyCopy } from 'common/components/atoms/Text/Copy.styles';
import PropTypes from 'prop-types';

import DetailsCardAddressType, {
  detailsCardAddressPropTypes,
} from './DetailsCardAddressType';

const DetailsCardLocationOfWorkType = ({ value }) => {
  const { type, address } = value ?? {};

  return (
    <>
      <StyledBodyCopy>{type}</StyledBodyCopy>
      <DetailsCardAddressType value={address} />
    </>
  );
};

DetailsCardLocationOfWorkType.propTypes = {
  value: PropTypes.shape(detailsCardAddressPropTypes).isRequired,
};

export default DetailsCardLocationOfWorkType;
