import { Reducer } from 'redux';

import {
  asyncActionKeys,
  CLEAR_DOCUMENT_CREATED,
  CLEAR_DOCUMENTS,
} from '../actions/redux.constants';

const initialState = {
  documents: undefined,
  documentCreated: false,
};

// eslint-disable-next-line no-unused-vars
export const documentsReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case asyncActionKeys.GET_DOCUMENTS_END:
      return {
        ...state,
        documents: action.payload,
      };
    case asyncActionKeys.CREATE_DOCUMENT_END:
      return {
        ...state,
        documentCreated: true,
      };
    case CLEAR_DOCUMENT_CREATED:
      return {
        ...state,
        documentCreated: false,
      };
    case CLEAR_DOCUMENTS:
      return {
        ...state,
        documents: undefined,
      };
    default:
      return state;
  }
};
