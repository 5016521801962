import { client } from '../../api';

export const updateUserAssociations = (
  userId: string,
  associatedUserIds: string[],
) => client.put(`/user-associations/${userId}`, { associatedUserIds });

export const updateUserScopeSuperManager = (
  userId: string,
  hasSuperManagerScope: boolean,
) =>
  client.patch(`/user-scopes/users/${userId}/super-manager`, {
    scopeStatus: hasSuperManagerScope,
  });

export const updateExpensesNotificationsScope = (
  userId: string,
  hasExpensesScope: boolean,
) =>
  client.patch(`/user-scopes/users/${userId}/expenses-notifications`, {
    scopeStatus: hasExpensesScope,
  });

export const updateFinanceScope = (userId: string, hasInvoicesScope: boolean) =>
  client.patch(`/user-scopes/users/${userId}/finance`, {
    scopeStatus: hasInvoicesScope,
  });

export const updatePayrollScope = (userId: string, hasPayrollScope: boolean) =>
  client.patch(`/user-scopes/users/${userId}/payroll`, {
    scopeStatus: hasPayrollScope,
  });
