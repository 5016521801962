import appHistory from 'app/appHistory';
import { EmployeeDocumentDetails } from 'common/components/molecules';
import { ActionItem } from 'common/components/molecules/ActionsDropDown/ActionsDropDown';
import { EditMode } from 'common/pages/Contracts/Edit';
import {
  ARCHIVED,
  AUTOMATED,
  Contract,
  MANUAL_UPLOAD,
  PENDING_REVIEW,
} from 'common/types';
import { getContractPath } from 'paths';
import { useSelectUserRoles } from 'utils/hooks';
import {
  useContractLabel,
  useStatusDescription,
  useStatusIntent,
} from 'utils/hooks/contractCard';
import { useAvvokaDocumentDownloadQuery } from 'utils/queries/employmentContractTemplates/useAvvokaTemplateQuery/useAvvokaTemplateQuery';

import {
  DateFormats,
  formatISODate,
  type IsoDateTime,
} from '@omnipresentgroup/design-system';

type Props = {
  contract: Contract;
  employeeId: string;
  archiveContractLoading: boolean;
  archiveContract: (documentId: string) => void;
};

const getUploadType = (
  isCSAdmin: boolean,
  isAvvokaDocumentIdPresent: boolean,
  isStatusPendingReview: boolean,
) => {
  if (isCSAdmin) {
    return isAvvokaDocumentIdPresent || isStatusPendingReview
      ? AUTOMATED
      : MANUAL_UPLOAD;
  }
  return null;
};

export const EmployeeContractCard = ({
  contract: {
    avvokaDocumentId,
    contractId,
    createdAt,
    documentId,
    status,
    version,
    updatedAt,
    archivedAt,
  },
  employeeId,
  archiveContractLoading,
  archiveContract,
}: Props) => {
  const { isCSAdmin, isEmployee, isManager } = useSelectUserRoles();
  const canBeArchived = status !== (ARCHIVED || PENDING_REVIEW) && !!documentId;
  const canDownloadDocX = !!avvokaDocumentId;
  const downloadDocxDocument = useAvvokaDocumentDownloadQuery(avvokaDocumentId);

  const date = formatISODate(updatedAt || createdAt, DateFormats.DateTime);

  const getSubheading = () => {
    if (isManager && status === PENDING_REVIEW) {
      return 'You will receive an email when the contract is ready to review';
    } else if (status === ARCHIVED) {
      return `Archived on ${formatISODate(
        archivedAt as IsoDateTime,
        DateFormats.DateTime,
      )}`;
    } else if (version && !isEmployee) {
      return `Version ${version} - Last updated on ${date}`;
    } else {
      return `Last updated on ${date}`;
    }
  };

  const statusDescription = useStatusDescription(status);
  const intent = useStatusIntent(status);
  const contractLabel = useContractLabel(status);

  const label = documentId || status === PENDING_REVIEW ? contractLabel : '';

  const uploadType = getUploadType(
    isCSAdmin,
    !!avvokaDocumentId,
    status === PENDING_REVIEW,
  );

  const actions =
    isCSAdmin &&
    [
      canBeArchived && {
        id: 'archive-contract',
        label: 'Archive contract',
        disabled: archiveContractLoading,
        loading: archiveContractLoading,
        onClickHandler: () => {
          archiveContract(documentId || '');
        },
      },
      canDownloadDocX && {
        id: 'download-docx',
        label: 'Download docx',
        onClickHandler: () => {
          downloadDocxDocument.refetch();
        },
      },
    ].filter(Boolean);

  function redirectToContract(documentId: string) {
    const route = getContractPath(isEmployee, documentId, employeeId);
    appHistory.push(route);
  }

  const clickHandler = () => {
    switch (true) {
      case !isCSAdmin && status === PENDING_REVIEW:
        return () => {};
      case isCSAdmin && status === PENDING_REVIEW:
        return () =>
          appHistory.push(
            `${getContractPath(isEmployee, contractId!, employeeId)}/edit?${
              EditMode.complete_missing_information
            }`,
          );
      default:
        return () => !!documentId && redirectToContract(documentId || '');
    }
  };

  return (
    <EmployeeDocumentDetails
      heading="Employee contract"
      label={label}
      intent={intent}
      statusDescription={statusDescription}
      uploadType={uploadType}
      onClick={clickHandler()}
      subHeading={getSubheading()}
      actions={actions as ActionItem[]}
    />
  );
};
