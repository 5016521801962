import { Address } from './address';
import { BenefitsCountry } from './benefits';
import { AvvokaQuestion } from './contractTemplate';
import { EmployeeType } from './employee';

export enum SOWStatusEnum {
  SOW_AWAITING_PROPOSAL = 'sow:awaiting-proposal',
  SOW_AWAITING_ACCEPTANCE = 'sow:awaiting-acceptance',
  SOW_ACCEPTED = 'sow:accepted',
}
export enum ArchiveReasonsEnum {
  CHANGE_COUNTRY = 'change country',
  CORRECTION = 'correction',
  DROP_OUT = 'dropout',
}

export enum SeatStatusEnum {
  ARCHIVED = 'archived',
  PENDING = 'pending',
}

export enum SetupFeeStructureEnum {
  PER_EMPLOYEE = 'per employee',
  PER_COUNTRY = 'per country',
}

export type Fee = {
  amount: number;
  currency: string;
};

export type Cost = {
  description: string;
  amount: number;
  currency: string;
};

export type EmployerCostsForSeat = { costs: Cost[]; subtotal: Fee };

export type SowStatus = `${SOWStatusEnum}`;
export type SeatStatus = `${SeatStatusEnum}`;
export type setupFeeStructure = `${SetupFeeStructureEnum}`;

export const depositTypes = [
  'Advanced Payment Deposit (APD)',
  'Zero Deposit Insurance',
  'No Deposit',
  'None',
] as const;

export type DepositType = (typeof depositTypes)[number];
export type ArchiveReason = `${ArchiveReasonsEnum}`;

export type Seat = {
  id: string;
  companyId: string;
  countryName: string;
  desiredStartDate: string;
  sowStatus: SowStatus;
  sowDocUrl?: string;
  createdAt?: string;
  seatStatus: SeatStatus;
  archiveDetails?: {
    archiveReason?: ArchiveReason;
  };
  employeeProfile?: SeatEmployeeProfile;
  billingCurrency?: string;
  firstName?: string;
  lastName?: string;
  annualBaseSalary?: Salary;
  isAutomatedUpsellUsed?: boolean;
  sowSignature?: { signedBy: string; time: string; date: string };
  name?: string;
};

export type TransformedSeat = Seat & {
  name: string;
};

export type locationOfWorkAddress = {
  city?: string;
};

export type SeatEmployeeProfile = {
  firstName?: string;
  lastName?: string;
  pronouns?: string;
  citizenship?: string;
  personalEmail?: string;
  startDate?: string;
  jobTitle?: string;
  jobDescription?: string;
  annualBaseSalaryCurrency?: string;
  annualBaseSalaryAmount?: number;
  managementFeeCurrency?: string;
  managementFeeAmount?: number;
  setupFeeCurrency?: string;
  setupFeeAmount?: number;
  setupFeeStructure?: setupFeeStructure;
  depositFee?: DepositFee;
  additionalCompensation?: string;
  locationOfWorkType?: string;
  locationOfWorkAddress?: locationOfWorkAddress;
  privateAddress?: Address;
  workEmail?: string;
  hoursOfWork?: number;
  probationaryPeriodLength?: number;
  holidayEntitlementDays?: number;
  holidayEntitlementType?: string;
  rightToWorkCheckCompleted?: boolean;
  employeeType?: EmployeeType;
  depositType?: DepositType;
  zeroDepositFee?: number;
  advancedPaymentDepositPercentage?: number;
  contractQuestionnaire?: {
    templateId?: string;
    body: Record<string, AvvokaQuestion>;
  };
  employerCosts?: EmployerCostsForSeat;
  fxFee?: Fee;
};

export interface Salary {
  amount: number;
  currency: string;
}

export type SeatInputData = {
  firstName?: string;
  lastName?: string;
  countryName?: string;
  desiredStartDate?: string;
  sowStatus?: SowStatus;
  annualBaseSalary?: Salary;
  employeeProfile?: SeatEmployeeProfile;
  managementFeeAmount?: number;
  setupFeeAmount?: number;
  billingCurrency?: string;
};

export type CompanyUpsellFees =
  | {
      enableAutomatedProposals?: boolean;
      managementFee?: { currency: string; amount: number | null }[];
      setupFee?: { currency: string; amount: number | null }[];
      depositType?: string;
      advancedPaymentDepositPercent?: number;
      zeroDepositInsuranceFee?: { amount: number; currency: string | null }[];
    }
  | undefined;

export interface DepositFee {
  calculatedAmount?: { currency: string; amount: number | null };
  convertedAmount?: { currency: string; amount: number | null };
}

export interface DepositFeeWithType extends DepositFee {
  depositType?: string;
}

export type FilteredCompanyUpsellFees =
  | {
      managementFee: { currency: string; amount: number };
      setupFee: { currency: string; amount: number };
    }
  | undefined;

export enum SeatActionEnum {
  ARCHIVE = 'Archive',
  CHANGE_COUNTRY = 'Change Country',
  ORDER_FORM_ACCEPTED = 'Order form accepted',
}

export const SeatActions = Object.values(SeatActionEnum);

export type SeatApiRequest = Partial<Omit<Seat, 'id' | 'name' | 'createdAt'>>;

/** For now, supplementaryBenefitsFee is undefined, this will be defined as development progresses */
export interface BenefitsCountryFee extends BenefitsCountry {
  supplementaryBenefitsFee?: undefined;
}
