import styled from 'styled-components';

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme: { space } }) => space[32]};

  button {
    width: 250px;
  }

  button > div {
    justify-content: center;
  }
`;

export const StyledApprovedExpensePage = styled.div`
  display: flex;
  flex-direction: column;
  place-self: center flex-start;
  width: 100%;
  height: 100%;
`;

export const StyledDownloadAllButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0;

  svg {
    margin-right: ${({ theme: { space } }) => space[4]};
  }
`;
