import styled, { css } from 'styled-components';

export const DeleteContainer = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    min-width: 132px;
    color: ${colors['text-default']};

    button {
      padding: 0;
    }
  `,
);
