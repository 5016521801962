import styled from 'styled-components';

export const StyledProgressIndicatorContainer = styled.div`
  height: ${({ theme: { space } }) => space[8]};
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
`;

export const StyledProgressIndicatorBar = styled.div`
  height: ${({ theme: { space } }) => space[8]};
  width: ${(props) => `${props.progress}%`};
  background-color: ${({ theme: { colors } }) =>
    colors['toast-bar-bar-positive']};
`;
