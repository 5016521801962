import { useParams } from 'react-router-dom';

import DataExport from 'common/components/molecules/DataExport/DataExport';
import {
  getDataExportAction,
  updateDataExportAction,
} from 'common/store/actions/employee.actions';
import { getEmployeeDataExportLinkSelector } from 'common/store/selectors/employees.selector';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const DetailsCardDataExportType = ({
  getEmployeeDataExport,
  employeeDataExportLink,
  clearEmployeeDataExport,
}) => {
  const { employeeId } = useParams();

  const handleGetEmployeeDataExport = () => {
    getEmployeeDataExport({ employeeId });
  };

  return (
    <DataExport
      exportLink={employeeDataExportLink}
      getExportLink={handleGetEmployeeDataExport}
      clearExportLink={clearEmployeeDataExport}
    />
  );
};

DetailsCardDataExportType.propTypes = {
  getEmployeeDataExport: PropTypes.func.isRequired,
  employeeDataExportLink: PropTypes.string.isRequired,
  clearEmployeeDataExport: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  employeeDataExportLink: getEmployeeDataExportLinkSelector(state),
});

const mapDispatchToProps = {
  clearEmployeeDataExport: updateDataExportAction,
  getEmployeeDataExport: getDataExportAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailsCardDataExportType);
