import { Field } from 'common/types';

import { Typography } from '@omnipresentgroup/design-system';

import LinkAsButton from '../../atoms/LinkAsButton/LinkAsButton';
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';
import { StyledBackLink, StyledFormHeader } from './FormHeader.styles';

type Stage = {
  name?: string;
  description?: string;
  stage?: number;
  fields?: Field[];
  schema?: any;
};

type Props = {
  title: string;
  stages: Stage[] | number;
  currentStage: number;
  backLink?: { url: string; label: string };
};

// Used for forms in mobile view
const FormHeader = ({ title, stages, currentStage, backLink }: Props) => (
  <StyledFormHeader data-testid="form-header" className="bigStack">
    <Typography as="h4" size="24" weight="medium">
      {title}
    </Typography>
    {backLink && (
      <StyledBackLink data-testid="form-header-backlink">
        <LinkAsButton to={backLink.url}>{backLink.label}</LinkAsButton>
      </StyledBackLink>
    )}

    {Array.isArray(stages) && stages?.length > 1 && (
      <ProgressIndicator
        totalStages={stages.length}
        currentStage={currentStage}
      />
    )}
  </StyledFormHeader>
);

export default FormHeader;
