import { getGlobalSetupRequest } from 'common/api/globalSetup.api';
import { ONE_MINUTE } from 'common/constants';
import { GSCountryCode } from 'common/types/globalSetup';
import { useQuery, UseQueryOptions } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useGetGlobalSetupQuery = (
  companyId: string,
  countryCode?: GSCountryCode,
  options?: Pick<UseQueryOptions, 'staleTime' | 'select'>,
) => {
  return useQuery(
    `get-global-setup-${countryCode}:${companyId}`,
    () =>
      getGlobalSetupRequest(companyId, countryCode).then(getDataFromResponse),
    {
      refetchOnWindowFocus: false,
      staleTime: options?.staleTime ?? ONE_MINUTE * 5,
      enabled: !!companyId,
    },
  );
};
