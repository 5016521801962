import styled from 'styled-components';

export const StyledTerritoriesContainer = styled.div`
  overflow: auto;
  margin-top: ${({ theme: { space } }) => space[16]};
  border-bottom: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.m} ${borderStyles.solid} ${colors['border-subtle']}`};
`;

export const StyledTerritories = styled.div`
  display: flex;
  column-gap: ${({ theme: { space } }) => space[32]};
  width: max-content;
  overflow: auto;
`;

export const StyledTerritory = styled.div`
  cursor: pointer;
  padding: ${({ theme: { space } }) => `${space[16]} ${space[0]}`};
  box-sizing: border-box;
  border-bottom: ${({ theme: { borderWidths, borderStyles } }) =>
    `${borderWidths.m} ${borderStyles.solid} transparent`};

  & > p {
    color: ${({ theme: { colors } }) => colors['text-subtle']};
  }

  &.active {
    border-bottom: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.m} ${borderStyles.solid} ${colors['border-inverse']}`};

    > p {
      color: ${({ theme: { colors } }) => colors['text-default']};
    }
  }
`;

export const StyledEmptyState = styled.div`
  & > div {
    min-height: 250px;
  }
`;
