import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { PageHeader } from 'common/components';
import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { InfiniteScrollTable } from 'common/components/organisms/InfiniteScrollTable/InfiniteScrollTable';
import { ONE_MINUTE, ONE_SECOND } from 'common/constants/timeUnits';
import {
  EmployeeForPaginationView,
  OnboardingStatusValues,
  PaginatedPayload,
} from 'common/types';
import {
  getCompanyProfilePage,
  getEmployeeProfilePage,
  getLspProfilePage,
} from 'paths';
import { SingleValue } from 'react-select';
import countries from 'utils/countries';
import { useDebounce } from 'utils/hooks';
import {
  useCompaniesQuery,
  useLspsQuery,
  usePaginatedEmployeesInfiniteQuery,
} from 'utils/queries';

import {
  Box,
  Dropdown,
  Inline,
  Input,
  OptionType,
  Stack,
} from '@omnipresentgroup/design-system';

import { paginatedTableHeaders } from './AllEmployeesPageTableHeaders';

const EMPLOYEES_PAGE_SIZE = 100;

const SEARCH_BY_OPTIONS = [
  { label: 'Name', value: 'employeeName' },
  { label: 'Personal Email', value: 'personalEmail' },
  { label: 'Employee ID', value: 'employeeId' },
  { label: 'Payroll ID', value: 'payrollId' },
  { label: 'External ID', value: 'externalId' },
];

const EMPTY_ARRAY: [] = [];

const generateSearchParams = ({
  searchValue,
  searchBy,
  lspId,
  companyId,
  country,
  onboardingStatus,
}: {
  searchBy: string;
  searchValue: string;
  lspId: string;
  companyId: string;
  country: string;
  onboardingStatus: string;
}) => {
  const params = new URLSearchParams();
  if (searchValue) {
    params.append('searchBy', searchBy);
    params.append('searchValue', searchValue);
  }
  if (lspId) {
    params.append('lspId', lspId);
  }
  if (companyId) {
    params.append('companyId', companyId);
  }
  if (country) {
    params.append('country', country);
  }
  if (onboardingStatus) {
    params.append('onboardingStatus', onboardingStatus);
  }

  return params;
};

const addClickInteraction = (
  employee: EmployeeForPaginationView,
  history: any,
) => {
  return {
    ...employee,
    onClick: () => history.push(getEmployeeProfilePage(employee.id)),
    company: {
      ...employee.company,
      onClick: () => history.push(getCompanyProfilePage(employee.managerId)),
    },
    lsp: {
      ...employee.lsp,
      onClick: () => history.push(getLspProfilePage(employee?.lsp?.id || '')),
    },
  };
};

export const AllEmployeesPaginatedPage = () => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const [companyId, setCompanyId] = useState<string>(
    urlParams.get('companyId') || '',
  );

  const [lspId, setLspId] = useState<string>(urlParams.get('lspId') || '');

  const [onboardingStatus, setOnboardingStatus] = useState<string>(
    urlParams.get('onboardingStatus') || '',
  );

  const [country, setCountry] = useState<string>(
    urlParams.get('country') || '',
  );

  const [searchBy, setSearchBy] = useState<string>(
    urlParams.get('searchBy') || 'employeeName',
  );

  const [searchValue, setSearchValue] = useState<string>(
    urlParams.get('searchValue') || '',
  );

  const [query, setQuery] = useState<PaginatedPayload>({
    companyId,
    country,
    onboardingStatus,
    lspId,
    [searchBy]: searchValue,
  });

  const { data: companiesData = [], isLoading: isLoadingCompanies } =
    useCompaniesQuery({ staleTime: ONE_MINUTE });

  const companiesDropdown = companiesData.map((company) => ({
    label: company.name,
    value: company.id,
  }));

  const { data: lspsData = [], isLoading: isLoadingLsps } = useLspsQuery();
  const lspsDropdown = lspsData.map((lsp) => ({
    label: lsp.name,
    value: lsp.id,
  }));

  const statusesDropdown = Object.values(OnboardingStatusValues).map(
    (status) => ({
      label: status,
      value: status,
    }),
  );

  useEffect(() => {
    const params = generateSearchParams({
      companyId,
      country,
      onboardingStatus,
      lspId,
      searchBy,
      searchValue,
    });

    history.replace({
      search: params.toString(),
    });

    setQuery({
      companyId,
      country,
      lspId,
      onboardingStatus,
      [searchBy]: searchValue,
    });
  }, [
    history,
    searchBy,
    searchValue,
    lspId,
    country,
    companyId,
    onboardingStatus,
  ]);

  const paginatedEmployeesQuery = usePaginatedEmployeesInfiniteQuery({
    query: useDebounce(query, ONE_SECOND),
    size: EMPLOYEES_PAGE_SIZE,
  });

  const items = useMemo(
    () =>
      paginatedEmployeesQuery.data?.pages.reduce<EmployeeForPaginationView[]>(
        (accumulator, current) => {
          current.forEach((item) => {
            accumulator.push(addClickInteraction(item, history));
          });
          return accumulator;
        },
        [],
      ),
    [history, paginatedEmployeesQuery.data?.pages],
  );

  const handleSearchByChange = (event: SingleValue<OptionType>) => {
    const searchByText = (event?.value as string) || '';
    setSearchBy(searchByText);
    setSearchValue('');
  };

  const { isError, error, isFetchingNextPage, isLoading, hasNextPage } =
    paginatedEmployeesQuery;

  if (isError) {
    return <ErrorBanner errorMessage={error?.message} />;
  }

  const fetchNextPage = () => {
    paginatedEmployeesQuery.fetchNextPage();
  };

  return (
    <PageContent>
      <Stack gap="32">
        <PageHeader title="All employees" />

        <Inline stretch="all">
          <Box mb="4">
            <Input
              id="searchInputField"
              label="Search"
              type="search"
              value={searchValue}
              placeholder="Search"
              onChange={(event) => setSearchValue(event.target.value)}
              handleClearSearch={() => setSearchValue('')}
            />
          </Box>
          <Box mb="4" w={180}>
            <Dropdown
              id="searchBy"
              label="Search by"
              isMulti={false}
              defaultValue={SEARCH_BY_OPTIONS.find(
                ({ value }) => value === searchBy,
              )}
              options={SEARCH_BY_OPTIONS}
              onChange={handleSearchByChange}
            />
          </Box>
          <Box mb="4" w={180}>
            <Dropdown
              id="countries"
              label="Country"
              isClearable
              isSearchable
              isMulti={false}
              placeholder="Select a country..."
              value={countries.find(({ label }) => label === country)}
              options={countries.map((country) => ({
                label: `${country.emoji} ${country.label}`,
                value: country.label,
              }))}
              onChange={(event) => setCountry((event?.value as string) || '')}
            />
          </Box>
          <Box mb="4" w={180}>
            <Dropdown
              id="clients"
              label="Client"
              isClearable
              isSearchable
              isLoading={isLoadingCompanies}
              isMulti={false}
              placeholder="Select a client..."
              value={companiesDropdown.find(({ value }) => value === companyId)}
              options={companiesDropdown}
              onChange={(event) => setCompanyId((event?.value as string) || '')}
            />
          </Box>
          <Box mb="4" w={180}>
            <Dropdown
              id="lsps"
              label="LSP/LPP"
              isLoading={isLoadingLsps}
              isClearable
              isSearchable
              isMulti={false}
              placeholder="Select a LSP/LPP..."
              value={lspsDropdown.find(({ value }) => value === lspId)}
              options={lspsDropdown}
              onChange={(event) => setLspId((event?.value as string) || '')}
            />
          </Box>
          <Box mb="4" w={180}>
            <Dropdown
              id="onboardingStatuses"
              label="Status"
              isClearable
              isSearchable
              isMulti={false}
              placeholder="Select a status..."
              value={statusesDropdown.find(
                ({ label }) => label === onboardingStatus,
              )}
              options={statusesDropdown}
              onChange={(event) => {
                setOnboardingStatus((event?.value as string) || '');
              }}
            />
          </Box>
        </Inline>
        {paginatedEmployeesQuery.isLoading ? (
          <Loading />
        ) : (
          <InfiniteScrollTable
            showButton={false}
            CTAText="Add new employee"
            headers={paginatedTableHeaders}
            itemsToDisplay={items || EMPTY_ARRAY}
            testId="employee-list"
            isInitialLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            onBottomReached={fetchNextPage}
            getId={(item) => item.id}
          />
        )}
      </Stack>
    </PageContent>
  );
};
