import { Seat } from 'common/types';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

export const getUpdatedFields = (updatedSeat: any, seat: Seat): Seat => {
  const employeeProfileKeys = [
    'depositType',
    'employeeType',
    'managementFeeAmount',
    'setupFeeAmount',
  ];
  const hasChangesInEmployeeProfile = Object.keys(updatedSeat).some((key) =>
    employeeProfileKeys.includes(key),
  );

  let newEmployeeProfile: any;
  if (hasChangesInEmployeeProfile) {
    employeeProfileKeys.forEach((key) => {
      const employeeProfile = get(seat, 'employeeProfile');
      newEmployeeProfile = {
        ...newEmployeeProfile,
        [key]: !isNil(updatedSeat[key])
          ? updatedSeat[key]
          : (employeeProfile as any)[key],
      };
    });
  }

  const updatedFields = omit(updatedSeat, [
    ...employeeProfileKeys,
    'sowSigned',
  ]);
  const newSowStatus =
    updatedSeat?.sowSigned && updatedSeat?.sowSigned[0]?.value
      ? 'sow:accepted'
      : seat?.sowStatus;

  const firstName = updatedSeat?.firstName || seat?.firstName;
  const lastName = updatedSeat?.lastName || seat?.lastName;

  return {
    ...seat,
    ...updatedFields,
    employeeProfile: { ...seat?.employeeProfile, ...newEmployeeProfile },
    sowStatus: newSowStatus,
    name: `${firstName} ${lastName}`,
  };
};
