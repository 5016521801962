import { Fragment, useRef } from 'react';

import { ErrorBanner, Loading } from 'common/components';
import { THIRTY_MINUTES } from 'common/constants';
import { getTenantIdSelector } from 'common/store/selectors/companies.selectors';
import { SimplifiedEmployee } from 'common/types';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { useEmployeesForAdminUsers } from 'utils/hooks/employees/useEmployeesForAdminUsers';

import {
  Box,
  Button,
  Inline,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import {
  EmployeesSearchWithList,
  ListEmployeeData,
} from '../EmployeesSearchWithList';
import * as S from './SelectEmployeesModal.style';

const sanitizeEmployeeData = (
  employees: SimplifiedEmployee[] | undefined,
): ListEmployeeData[] =>
  (employees &&
    employees
      .filter((employee) => employee.onboardingStatus === 'Active')
      .map((employee) => ({
        id: employee.id,
        name: employee.name,
        jobTitle: employee.jobTitle,
        country: employee.country,
        startDate: employee.startDate,
        isSelected: false,
      }))) ||
  [];

export type EmployeesListProps = {
  onCloseClick: () => void;
  onNextClick: (selectedEmployees: string[]) => void;
  selectedCompensation: string;
};

export const SelectEmployeesModal = ({
  onCloseClick,
  onNextClick,
  selectedCompensation,
}: EmployeesListProps) => {
  const companyId = useSelector(getTenantIdSelector);
  const companyEmployeesQuery = useEmployeesForAdminUsers(companyId, {
    staleTime: THIRTY_MINUTES,
  });
  const { isLoading, isError, isSuccess, data } = companyEmployeesQuery;
  const selectedEmployees = useRef<string[]>([]);

  const employees = sanitizeEmployeeData(data);
  const compensationType = selectedCompensation.toLowerCase();

  const onUpdateSelection = (updatedEmployees: string[]) => {
    selectedEmployees.current = updatedEmployees;
  };

  const handleOnNextClick = () => {
    onNextClick(selectedEmployees.current);
  };

  return (
    (isError && <ErrorBanner />) || (
      <S.StyledModal>
        {isLoading && <Loading />}
        {isSuccess && data && (
          <>
            <Box mb="32" pl="32" pr="32">
              <Box bg="primary" pt="32" m="0">
                <Inline bg="primary" justify="space-between">
                  <Fragment key=".0">
                    <Typography
                      as="h5"
                      hideParagraphSpacing
                      size="20"
                      weight="medium"
                    >
                      Add employees
                    </Typography>
                    <Stack
                      onClick={onCloseClick}
                      role="button"
                      pt="0"
                      justify="center"
                      css={{
                        cursor: 'pointer',
                      }}
                    >
                      <X />
                    </Stack>
                  </Fragment>
                </Inline>
                <Typography as="span" size="14">
                  Select employees to be added to the {compensationType}{' '}
                  template
                </Typography>
              </Box>
            </Box>
            <Stack overflow="hidden" px="32" py="0">
              <EmployeesSearchWithList
                employees={employees}
                onUpdateSelection={onUpdateSelection}
              />
            </Stack>

            <Inline justify="end" mt="16" p="32">
              <Button variant="secondary" onClick={onCloseClick}>
                Cancel
              </Button>
              <Button onClick={handleOnNextClick}>Next</Button>
            </Inline>
          </>
        )}
      </S.StyledModal>
    )
  );
};
