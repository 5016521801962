import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import avatarPlaceholderPicture from 'assets/img/avatar-placeholder.png';
import Card from 'common/components/molecules/Card/Card';
import MobileCardListHeader from 'common/components/molecules/MobileCardListHeader/MobileCardListHeader';
import TableWithTitle from 'common/components/organisms/TableWithTitle/TableWithTitle';
import { StyledProfilePictureSmall } from 'common/pages/EditEmployeePage/EditEmployeePage.styles';
import {
  EmployeeActionEnum,
  ManagerEmployeeEnum,
  SimplifiedEmployee,
} from 'common/types';
import { HireTypeEnum } from 'common/types/hire';
import { ADD_HIRE_PATH, getEmployeeProfilePage } from 'paths';
import { useSelectUserRoles } from 'utils/hooks';

import { Typography, Viewport } from '@omnipresentgroup/design-system';

import { ReactComponent as ArrowRight } from '../../../../../../assets/icon/ArrowRight.svg';
import { StyledEmployeeCardTitle } from '../../../../../manager/pages/ManagerEmployeePage/ManagerEmployeePage.styles';
import { TerminationWarning } from '../../../../../manager/pages/ManagerEmployeePage/Terminations/TerminationWarning/TerminationWarning';
import { StyledNoEmployees, StyledTab } from '../../CompanyDetailPage.styles';
import { ArchiveEmployeeModal } from '../ArchiveEmployeeModal/ArchiveEmployeeModal';
import { csAdminHeaders } from '../employeeAdminTableHeaders';
import { headers } from '../employeesTableHeaders';
import { EmployeesSearchAndFilter } from './EmployeesSearchAndFilter';

type EmployeesTabProps = {
  employeesToDisplay: SimplifiedEmployee[];
  companyId: string;
  reloadEmployees: () => void;
};

export const EmployeesTab = ({
  employeesToDisplay,
  companyId,
  reloadEmployees,
}: EmployeesTabProps) => {
  const { isAdmin, isCSAdmin } = useSelectUserRoles();

  const history = useHistory();

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const [isTerminationWarningModalOpen, setIsTerminationWarningModalOpen] =
    useState(false);

  const [selectedUser, setSelectedUser] = useState<SimplifiedEmployee>();

  const [selectedUserId, setSelectedUserId] = useState('');

  const [filteredEmployees, setFilteredEmployees] =
    useState<SimplifiedEmployee[]>(employeesToDisplay);

  const onEmployeeClicked = (
    column: string,
    selectedUser: SimplifiedEmployee,
  ) => {
    if (column === EmployeeActionEnum.ARCHIVE) {
      setIsArchiveModalOpen(true);
      setSelectedUser(selectedUser);
    }

    if (column === ManagerEmployeeEnum.REQUEST_TERMINATION) {
      setIsTerminationWarningModalOpen(true);
      setSelectedUserId(selectedUser?.id);
    }

    if (column === 'employee') {
      history.push(getEmployeeProfilePage(selectedUser.id));
    }
  };

  const onCTAClicked = () => {
    history.push(`${ADD_HIRE_PATH(companyId)}?type=${HireTypeEnum.employee}`);
  };

  const tableHeaders = headers.filter(
    (header) => isAdmin || header.id !== 'action',
  );

  const headersForCard = headers.filter(
    (header) => header.id !== 'employee' && header.id !== 'action',
  );

  return (
    <>
      <ArchiveEmployeeModal
        isOpen={isArchiveModalOpen}
        employeeOrSeatDetails={{
          id: selectedUser?.id ?? '',
          name: selectedUser?.name ?? '',
          role: selectedUser?.jobTitle ?? '',
        }}
        closeFn={() => setIsArchiveModalOpen(!isArchiveModalOpen)}
        reloadEmployees={() => reloadEmployees()}
      />
      <TerminationWarning
        isOpen={isTerminationWarningModalOpen}
        closeFn={() =>
          setIsTerminationWarningModalOpen(!isTerminationWarningModalOpen)
        }
        companyId={companyId}
        employeeId={selectedUserId}
      />
      <StyledTab data-testid="employees-tab" className="smallStack">
        {!employeesToDisplay.length ? (
          <StyledNoEmployees
            addEmployeePath={`${ADD_HIRE_PATH(companyId)}?type=${
              HireTypeEnum.employee
            }`}
          />
        ) : (
          <>
            <Viewport devices={['laptop', 'desktop', 'highRes']}>
              <TableWithTitle
                tableTopComponent={
                  <EmployeesSearchAndFilter
                    employeesToFilter={employeesToDisplay}
                    setEmployeesToDisplay={setFilteredEmployees}
                  />
                }
                itemsToDisplay={filteredEmployees}
                headers={isCSAdmin ? csAdminHeaders : tableHeaders}
                onCTAClicked={onCTAClicked}
                CTAText="Add employee"
                showButton
                testId="employees"
                onColumnItemClick={onEmployeeClicked}
              />
            </Viewport>
            <Viewport devices={['phone', 'tablet']}>
              <>
                <MobileCardListHeader total={employeesToDisplay.length} />
                {!!employeesToDisplay?.length &&
                  employeesToDisplay.map((employee) => (
                    <div
                      key={`wrap_${employee.id}`}
                      role="none"
                      onClick={() =>
                        history.push(getEmployeeProfilePage(employee.id))
                      }
                    >
                      <Card
                        key={employee.id}
                        fieldsToShow={headersForCard}
                        onColumnItemClick={onEmployeeClicked}
                        item={employee}
                        icon={<ArrowRight />}
                        title={
                          <StyledEmployeeCardTitle>
                            <StyledProfilePictureSmall
                              src={avatarPlaceholderPicture}
                            />
                            <Typography as="span" size="16" weight="medium">
                              {employee.name}
                            </Typography>
                          </StyledEmployeeCardTitle>
                        }
                      />
                    </div>
                  ))}
              </>
            </Viewport>
          </>
        )}
      </StyledTab>
    </>
  );
};
