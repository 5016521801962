import appHistory from 'app/appHistory';
import { ReactComponent as ChevronLeftIcon } from 'assets/icon/ChevronLeft.svg';

import {
  StyledBackLinkButton,
  StyledLinkAsButton,
  StyledLinkButtonContainer,
} from './LinkAsButton.styles';

type LinkAsButtonProp = {
  children: string;
  testId?: string;
  to?: string;
  onClick?: () => void;
};

/**
 * @deprecated
 * Use the OmniDS Button or Link component instead appropriate to the use case
 * https://omnipresentgroup.github.io/OmniDS/?path=/docs/atoms-button--default
 * https://omnipresentgroup.github.io/OmniDS/?path=/docs/atoms-link--default
 * Please reach out to #web-platform-support for questions about using the OmniDS components
 */
const LinkAsButton = ({
  children,
  testId,
  to,
  onClick: onClickkHandlerFn,
}: LinkAsButtonProp) => {
  const onBackLinkClicked = () => {
    if (onClickkHandlerFn) {
      return onClickkHandlerFn();
    }

    appHistory.goBack();
  };

  const buttonContents = (
    <StyledLinkButtonContainer>
      <ChevronLeftIcon />
      {children}
    </StyledLinkButtonContainer>
  );

  const dataTestId = testId ? testId : 'link-as-button';
  return (
    <>
      {to && (
        <StyledLinkAsButton data-testid={dataTestId} to={to}>
          {buttonContents}
        </StyledLinkAsButton>
      )}
      {!to && (
        <StyledBackLinkButton
          data-testid={dataTestId}
          onClick={onBackLinkClicked}
        >
          {buttonContents}
        </StyledBackLinkButton>
      )}
    </>
  );
};

export default LinkAsButton;
