import { payrollServiceClient } from '../../api';

export const getBulkPayrollIdTemplate = () => {
  return payrollServiceClient.post<string>('/payroll-id/template');
};

type Employee = { id: string; name: string };

export type BulkUploadPayrollIdResponse = {
  errors: {
    employee: Employee;
    error: string;
  }[];
  employeesUpdated: Employee[];
};

export const bulkUpdatePayrollId = (data: BulkPayrollIdUploadPayload) => {
  const formData = new FormData();
  formData.append('type', 'text/csv');
  formData.append('fileContent', data.file);

  return payrollServiceClient.post<BulkUploadPayrollIdResponse>(
    '/payroll-id',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export type BulkPayrollIdUploadPayload = {
  file: File;
};
