import styled, { css } from 'styled-components';

const error = css`
  margin-bottom: 0;
`;

export const FieldContainer = styled.div<{
  error?: string;
}>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.error ? error : null)}
  margin-bottom: ${({ theme: { space } }) => `${space[16]}`};
  width: 100%;
`;
