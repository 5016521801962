import { useCallback, useEffect } from 'react';

import { TransformedUser } from 'app/store/app.transformers';
import { getSelectedCompanyForAppSelector } from 'common/store/selectors/companies.selectors';
import { EmployeeForDetailView, SelectedCompany } from 'common/types';
import { getEmployeeSelector } from 'omniplatform/employee/store/selectors/employee.selector';
import { COMPANY_PROFILE_PAGE, EMPLOYEE_PROFILE_PAGE } from 'paths';
import { useSelector } from 'react-redux';
import { companyNeedsManagerReview } from 'utils/companies';
import {
  getEmployeeTypeFormURL,
  showEmployeeReviewForm,
  showEmployeeTypeForm,
} from 'utils/employees';
import { isEmployee, isManager } from 'utils/user';

import appHistory from '../appHistory';

export const useProfileInputScreen = (user: TransformedUser) => {
  const selectedEmployee = useSelector<any, EmployeeForDetailView>(
    getEmployeeSelector,
  );
  const selectedCompany = useSelector<any, SelectedCompany>(
    getSelectedCompanyForAppSelector,
  );

  const showInputScreen = useCallback(() => {
    if (isManager(user)) {
      return companyNeedsManagerReview(selectedCompany);
    } else if (isEmployee(user)) {
      return (
        showEmployeeReviewForm(selectedEmployee) ||
        showEmployeeTypeForm(selectedEmployee)
      );
    }
    return false;
  }, [selectedCompany, selectedEmployee, user]);

  // Navigation based on the showProfileInputScreen state
  useEffect(() => {
    if (showInputScreen()) {
      if (
        isManager(user) &&
        selectedCompany?.id &&
        appHistory.location.pathname !==
          `${COMPANY_PROFILE_PAGE}/${selectedCompany.id}/edit`
      ) {
        appHistory.push(`${COMPANY_PROFILE_PAGE}/${selectedCompany.id}/edit`);
      } else if (isEmployee(user) && selectedEmployee?.id) {
        const employeeFormURL = showEmployeeTypeForm(selectedEmployee)
          ? getEmployeeTypeFormURL(selectedEmployee)
          : `${EMPLOYEE_PROFILE_PAGE}/${selectedEmployee.id}/edit`;

        appHistory.push(employeeFormURL);
      }
    }
  }, [
    user,
    selectedCompany,
    selectedEmployee,
    showInputScreen,
    appHistory.location.pathname,
  ]);
};
