import { ReactNode } from 'react';

import { StyledContextualInfo } from 'common/components/atoms/ContextualInfo';
import { StyledErrorMessage } from 'common/components/atoms/ErrorMessage';
import { StyledInput } from 'common/components/atoms/Input';
import { PAYROLL_FIELDS } from 'common/helpers/payrollFormFieldLabels.const';
import { DropdownOption, Fee } from 'common/types';
import Select from 'react-select';
import { billingCurrencies, BillingCurrency } from 'utils/currencies';
import { onWheelPreventChangeForNumberInput } from 'utils/input.helper';

import { Box, Split, Typography } from '@omnipresentgroup/design-system';

import { StyledDropdownInput } from '../DropdownInput/DropdownInput.styles';

type InputProps = {
  value: Fee;
  onChange: (name: string, value: Fee) => void;
  onFocus?: (name: string) => void;
  error?: Record<string, any>;
  disabled?: boolean;
  contextualInfo?: ReactNode;
  id?: string;
  label: string;
  validCurrencies?: BillingCurrency[];
  name: string;
};

export const FeeCurrencyInput = ({
  value,
  onChange,
  onFocus,
  error,
  disabled,
  label,
  contextualInfo,
  name,
  validCurrencies = billingCurrencies,
}: InputProps) => {
  const currencyOptions = validCurrencies.map((currency) => ({
    value: currency,
    label: currency,
  }));

  const handleSelect = (option: DropdownOption | null) => {
    onChange(name, {
      currency: option ? option.value : '',
      amount: value.amount,
    });
  };

  const handleInputChange = (input: string) => {
    onChange(name, {
      ...value,
      amount: parseFloat(input),
    });
  };
  const selectValue = value.currency
    ? { label: value.currency, value: value.currency }
    : null;

  return (
    <Box>
      {label && (
        <Typography as="label" size="16" weight="medium" htmlFor={name}>
          {label}
        </Typography>
      )}

      <Split bg="primary" fraction="1/3" radius="s">
        <StyledDropdownInput>
          <Select
            aria-label={`${name} currency`}
            onChange={handleSelect}
            value={selectValue}
            options={currencyOptions}
            isDisabled={disabled}
            placeholder="Currency"
          />
        </StyledDropdownInput>
        <StyledInput
          type="number"
          aria-label={`${name} amount`}
          data-testid={`${name}-amount-input`}
          name={`${name}Amount`}
          step={1}
          min="0"
          decimalScale={2}
          value={value?.amount || value?.amount === 0 ? value.amount : ''}
          disabled={
            name !== PAYROLL_FIELDS.setup_fee_amount.name ? !disabled : disabled
          }
          onChange={(e: any) => handleInputChange(e.target.value)}
          allowDecimals
          disableAbbreviations
          disableGroupSeparators
          placeholder="Amount"
          onFocus={() => onFocus && onFocus(name)}
          onWheel={onWheelPreventChangeForNumberInput}
        />
      </Split>

      {error?.currency?.message && (
        <StyledErrorMessage>{error?.currency?.message}</StyledErrorMessage>
      )}
      {error?.amount?.message && (
        <StyledErrorMessage>{error?.amount?.message}</StyledErrorMessage>
      )}
      {contextualInfo && (
        <StyledContextualInfo id={`${name}-context`}>
          {contextualInfo}
        </StyledContextualInfo>
      )}
    </Box>
  );
};
