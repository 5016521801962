import styled from 'styled-components';

export const CircularProgressbarContainer = styled.div<{
  size: number;
}>`
  width: ${(props) => {
    return `${props.size}px`;
  }};
  height: ${(props) => {
    return `${props.size}px`;
  }};
`;
