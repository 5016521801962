import { useEffect } from 'react';

import { subject } from '@casl/ability';
import { useAppAbility } from 'app/store/ability';
import AddDocumentPage from 'common/pages/AddDocumentPage/AddDocumentPage';
import {
  createDocumentAction,
  getDocumentsAction,
} from 'common/store/actions/documents.actions';
import { getTenantIdSelector } from 'common/store/selectors/companies.selectors';
import { getDocumentsSelector } from 'common/store/selectors/document.selectors';
import { DOCUMENT_LIST_PAGE } from 'paths';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getCompanyAction } from '../../../admin/store/actions/companies.actions';

const AddCompanyDocumentPage = ({
  tenantId,
  addDocument,
  documents,
  getDocuments,
}) => {
  const ability = useAppAbility();
  const canAddDocument = ability.can(
    'documents:create',
    subject('Documents', { ownerId: tenantId }),
  );

  useEffect(() => {
    if (!documents && tenantId) {
      getDocuments({ companyId: tenantId });
    }
  }, [tenantId, getDocuments, documents]);

  const onDocumentFormSubmitted = (documentInfo) =>
    addDocument({
      documentInfo,
      tenantId,
      returnPath: DOCUMENT_LIST_PAGE,
    });
  return (
    <AddDocumentPage
      onDocumentFormSubmitted={onDocumentFormSubmitted}
      canAddDocument={canAddDocument}
      copy="Upload a document for your company. Uploads will only be visible to Managers."
    />
  );
};

AddCompanyDocumentPage.propTypes = {
  addDocument: PropTypes.func.isRequired,
  getDocuments: PropTypes.func.isRequired,
  documents: PropTypes.array,
  tenantId: PropTypes.string.isRequired,
};

AddCompanyDocumentPage.defaultProps = {
  documents: undefined,
};

const mapStateToProps = (state) => ({
  tenantId: getTenantIdSelector(state),
  documents: getDocumentsSelector(state),
});

const mapDispatchToProps = {
  addDocument: createDocumentAction,
  getDocuments: getDocumentsAction,
  getCompany: getCompanyAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCompanyDocumentPage);
