import { getUser } from 'common/api/users.api';
import { TEN_MINUTES } from 'common/constants';
import { useQueries } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { usersKeys } from '../keys';

export const useUsersQuery = (userIds: string[]) => {
  const userIdsSet = new Set(userIds);

  return useQueries(
    [...userIdsSet].map((userId) => ({
      queryKey: usersKeys.singleUser(userId),
      queryFn: () => getUser(userId).then(getDataFromResponse),
      staleTime: TEN_MINUTES,
    })),
  );
};
