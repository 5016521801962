import appHistory from 'app/appHistory';
import { StyledSubtitle } from 'common/components/atoms/Collapsible/Collapsible.styles';
import { StyledTitleCell } from 'common/components/organisms/TableWithTitle/TableWithTitle.styles';
import { getMonthInString } from 'common/helpers/month';
import { PaginatedPayrollInstancesPageData } from 'common/store/transformers/payroll.transformer';
import { TableHeader } from 'common/types';
import { PAYROLL_INSTANCE_DETAIL_PAGE } from 'paths';

import { ClickableCell } from '../../../PayrollInstanceDetailPage/PayrollInstancesTab.styles';

const MonthCustomCell = (payroll: {
  id: string;
  month: number;
  year: number;
  name: string;
}) => {
  const onPayrollInstanceClick = (payrollInstanceId: string) =>
    appHistory.push(PAYROLL_INSTANCE_DETAIL_PAGE(payrollInstanceId));

  const month = getMonthInString(payroll.month);
  const year = payroll.year;

  return (
    <ClickableCell onClick={() => onPayrollInstanceClick(payroll.id)}>
      <StyledTitleCell>
        {month} {year}
      </StyledTitleCell>
      <StyledSubtitle>{payroll.name}</StyledSubtitle>
    </ClickableCell>
  );
};

export const paginatedTableHeaders: TableHeader<PaginatedPayrollInstancesPageData>[] =
  [
    {
      headerTitle: 'Month/Year',
      customCell: MonthCustomCell,
      align: 'left',
    },
  ];
