import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getUserSelector } from 'app/store/app.selectors';
import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { FourOhThree } from 'common/pages/403/FourOhThree';
import { AddEditFormPage } from 'common/pages/AddEditFormPage/AddEditFormPage';
import { updateSeatAction } from 'common/store/actions/seats.actions';
import { Seat } from 'common/types';
import isEmpty from 'lodash/isEmpty';
import { getSeatPath } from 'paths';
import { useDispatch, useSelector } from 'react-redux';
import { isUpsellProposalGenerationEnabled } from 'utils/featureFlags';
import {
  useSingleSeatQuery,
  useUpdateEmployeeSeatMutation,
} from 'utils/queries';
import { isAdmin, isManager } from 'utils/user';

import {
  addCompanyEmployeeFormAdminConfig,
  addCompanyEmployeeFormDefaultConfig,
  addCompanyEmployeeFormManagerConfig,
} from './addCompanyEmployeeSeatFormConfig';
import { getUpdatedFields } from './addEditEmployeeSeatUtil';

type PageParams = { companyId: string; seatId: string };

export const EditEmployeeSeatPage: React.FC = () => {
  const [seatLocalState, setSeatLocalState] = useState<Seat | null>(null);
  const [depositType, setDepositType] = useState<string>('');
  const [employeeType, setEmployeeType] = useState();
  const [depositPercentage, setDepositPercentage] = useState();

  const dispatch = useDispatch();

  const history = useHistory();
  const { seatId, companyId } = useParams<PageParams>();

  const user = useSelector(getUserSelector);
  const isAdminUser = isAdmin(user);
  const isManagerUser = isManager(user);

  const seatQuery = useSingleSeatQuery(seatId, {
    queryOptions: { staleTime: 0, cacheTime: 0 },
  });

  const updateSeatMutation = useUpdateEmployeeSeatMutation();
  const { mutate } = updateSeatMutation;

  const redirectUrl = getSeatPath(companyId, seatId);

  const goBackLink = useMemo(() => {
    const label = 'Back';

    const url = getSeatPath(companyId, seatId);

    return { url, label };
  }, [seatId, companyId]);

  const handleUpdateSeat = useCallback(
    (updatedSeat: Partial<Seat>) => {
      mutate(
        { seatToUpdate: updatedSeat, seatId },
        {
          onSuccess: () => {
            if (!isEmpty(updatedSeat)) {
              const seatWithUpdates = {
                ...seatLocalState,
                ...getUpdatedFields(updatedSeat, seat),
              };
              dispatch(updateSeatAction(seatId, seatWithUpdates));
            }
            history.push(redirectUrl);
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutate, seatId, seatLocalState, dispatch, history, redirectUrl],
  );

  useEffect(() => {
    if (seatQuery.isSuccess) {
      setSeatLocalState(seatQuery?.data || {});
    }
  }, [seatQuery]);

  if (seatQuery.isLoading) {
    return <Loading />;
  }

  if (seatQuery.isError && seatQuery.error instanceof Error) {
    return <ErrorBanner errorMessage={seatQuery.error.message} />;
  }

  if (!seatQuery.isSuccess) {
    return <></>;
  }

  const seat = seatQuery.data;

  const mutationError = updateSeatMutation.error;
  const errorBannerMessage =
    updateSeatMutation.isError && mutationError instanceof Error
      ? mutationError.message
      : '';

  const getCompanyEmployeeFormConfig = () => {
    if (isAdminUser) {
      return addCompanyEmployeeFormAdminConfig({
        seat,
        depositType,
        employeeType,
        depositPercentage,
      });
    }

    if (isManagerUser && isUpsellProposalGenerationEnabled()) {
      return addCompanyEmployeeFormManagerConfig(seat);
    }

    return addCompanyEmployeeFormDefaultConfig(seat);
  };

  const { formFields, schema } = getCompanyEmployeeFormConfig();

  const isForbiddenForManager =
    isManagerUser && isUpsellProposalGenerationEnabled();
  const isForbiddenForAdmin =
    isUpsellProposalGenerationEnabled() &&
    isAdminUser &&
    seat.sowStatus === 'sow:accepted';

  const handleFormChange = (name: string, value: any) => {
    if (!isAdminUser) {
      return;
    }

    switch (name) {
      case 'depositType':
        setDepositType(value);
        break;
      case 'employeeType':
        setEmployeeType(value);
        break;
      case 'depositPercent':
        setDepositPercentage(value);
        break;
    }
  };

  const shouldRender403 = isForbiddenForManager || isForbiddenForAdmin;

  return shouldRender403 ? (
    <FourOhThree />
  ) : (
    <AddEditFormPage
      updateMode
      backLink={goBackLink}
      submitCTA="Save edits"
      formTitle={`${seat.firstName} ${seat.lastName}`}
      schema={schema}
      formFields={formFields}
      onFieldChange={handleFormChange}
      onSubmit={handleUpdateSeat}
      showLoading={updateSeatMutation.isLoading}
      onErrorBannerReset={updateSeatMutation.reset}
      errorBannerMessage={errorBannerMessage}
    />
  );
};
