import { createQueryKeys } from 'utils/react-query-utils';

export const benefitsKeys = createQueryKeys(
  'benefits-country',
  (createKey) => ({
    country: (countryCode: string) => createKey(countryCode, 'country'),
    clientPreferences: (clientId: string, countryCode: string) =>
      createKey(clientId, countryCode, 'client-preferences'),
    clientPreferencesList: (clientId: string, selectionStatus?: string) =>
      createKey(clientId, selectionStatus, 'client-preferences-list'),
    clientEnrollment: (clientId: string) =>
      createKey(clientId, 'client-enrollment'),
  }),
);
