import Joi from 'joi';

import { addressSchema } from './address.schema';

const commonFields = {
  managerFirstName: Joi.string().messages({
    'string.empty': 'Please enter a name',
  }),
  managerLastName: Joi.string().messages({
    'string.empty': 'Please enter a name',
  }),
  managerEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({ 'string.email': 'Please enter a valid email address' }),
  companyName: Joi.string().messages({
    'string.empty': 'Please enter a company name',
  }),
  countryOfRegistration: Joi.string().messages({
    'string.empty': 'Please choose a country',
  }),
  vatNumber: Joi.string().allow('', null),
  companyNumber: Joi.string().allow('', null),
  onboardingStage: Joi.string().messages({
    'string.empty': 'Please enter an onboarding stage',
  }),
  invoicingEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      'string.email': 'Please enter a valid email address',
      'string.empty': 'Please enter an email address',
    }),
  registeredAddress: addressSchema,
};

export const companySchemaForAdmins = Joi.object().keys({
  ...commonFields,
  contactName: Joi.string().allow('', null),
  contactPhoneNumber: Joi.string().allow('', null),
  adminNotes: Joi.string().allow('', null),
  disableContractAutomation: Joi.boolean(),
  isVATExempt: Joi.boolean(),
  fxConversionPercent: Joi.number().min(0),
  salaryPrepayment: {
    terms: Joi.number().min(0),
    multiplier: Joi.number().min(0),
  },
});

export const companySchemaForManagers = Joi.object().keys({
  ...commonFields,
  contactName: Joi.string().messages({
    'string.empty': 'Please enter a name of a primary contact',
  }),
  contactPhoneNumber: Joi.string().messages({
    'string.empty': 'Please enter a phone number of a primary contact',
  }),
});
