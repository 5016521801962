import {
  asyncActionKeys,
  CLEAR_ERROR,
  CLEAR_PLATFORM_INVITATION_STATUS,
  SET_ACCESS_TOKEN,
  SET_USER_ROLE,
} from './app.constants';

export const setAccessTokenAction = (token) => ({
  type: SET_ACCESS_TOKEN,
  payload: token,
});

export const setUserRoleAction = (userRole) => ({
  type: SET_USER_ROLE,
  payload: userRole,
});

export const clearErrorAction = () => ({
  type: CLEAR_ERROR,
});

export const getUserAction = (userFromAuth0) => ({
  type: asyncActionKeys.GET_USER_START,
  payload: userFromAuth0,
});

export const updateUserAction = (payload) => ({
  type: asyncActionKeys.UPDATE_USER_START,
  payload,
});

export const verifyEmailChangeAction = (payload) => ({
  type: asyncActionKeys.VERIFY_EMAIL_CHANGE_START,
  payload,
});

export const confirmEmailChangeAction = (payload) => ({
  type: asyncActionKeys.CONFIRM_EMAIL_CHANGE_START,
  payload,
});

export const startEmailChangeAction = (payload) => ({
  type: asyncActionKeys.CREATE_EMAIL_CHANGE_START,
  payload,
});

export const sendPlatformInvitation = (payload) => ({
  type: asyncActionKeys.SEND_PLATFORM_INVITATION_START,
  payload,
});

export const clearPlatformInvitationStatus = () => ({
  type: CLEAR_PLATFORM_INVITATION_STATUS,
});
