import { Collapsible } from 'common/components/atoms/Collapsible/Collapsible';
import { AlertTriangle, Clipboard } from 'react-feather';
import styled from 'styled-components';
import { copyToClipboard } from 'utils/copyToClipboard';

import { BulkUploadValidationError } from '../types';

const IconContainer = styled.div`
  width: 24px;
  margin-right: ${({ theme: { space } }) => space[16]};
`;

const ClipBoarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: ${({ theme: { space } }) => `${space[0]} ${space[16]}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  color: ${({ theme: { colors } }) => colors['text-default']};
  cursor: pointer;
`;

const AccordionSummaryTitle = styled.div`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const ClipboardIcon = styled(Clipboard)`
  color: ${({ theme: { colors } }) => colors['icon-default']};
  margin-right: ${({ theme: { space } }) => space[8]};
`;

const AlertIcon = styled(AlertTriangle)`
  color: ${({ theme: { colors } }) => colors['icon-warning']};
`;

const ErrorContainer = styled.div`
  padding: ${({ theme: { space } }) => `${space[32]} ${space[0]}`};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  border-left: 0;
  border-right: 0;
`;

const ErrorList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-left: ${({ theme: { space } }) => `-${space[32]}`};
  margin-top: ${({ theme: { space } }) => `-${space[32]}`};
`;

const ErrorItem = styled.div`
  margin-left: ${({ theme: { space } }) => space[32]};
  margin-top: ${({ theme: { space } }) => space[32]};
  flex-basis: 45%;
`;

const ErrorItemLabel = styled.div`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  margin-bottom: ${({ theme: { space } }) => space[16]};
`;

const EmployeeName = styled.span`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
`;

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
`;

const convertCamelCaseToSpaces = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

type ValidationAccordionProps = {
  invalidEmployees: BulkUploadValidationError;
};

export const ValidationAccordion = ({
  invalidEmployees,
}: ValidationAccordionProps) => {
  const copyInvalidEmployeesToClipboard = (
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.preventDefault();
    const emails = invalidEmployees.map((employee) => employee.email).join(',');
    copyToClipboard(emails);
  };
  return (
    <>
      <Collapsible
        noMaxHeigh
        id="validationAccordion"
        title={
          <AccordionSummaryTitle>
            <div>
              {invalidEmployees.length} employee profiles present formatting
              errors
            </div>
            <ClipBoarContainer onClick={copyInvalidEmployeesToClipboard}>
              <ClipboardIcon size={12} />
              <div>Copy all to clipboard</div>
            </ClipBoarContainer>
          </AccordionSummaryTitle>
        }
      >
        <div>
          {invalidEmployees.map((employee, index) => {
            return (
              <ErrorContainer key={index}>
                <div style={{ marginBottom: 22, fontSize: 16 }}>
                  <EmployeeName>{employee.name}</EmployeeName>{' '}
                  <>{employee.email}</>
                </div>
                <ErrorList>
                  {employee.errors.map((error, errIndex) => {
                    return (
                      <ErrorItem key={errIndex}>
                        <ErrorItemLabel>
                          {convertCamelCaseToSpaces(error.context.label)}
                        </ErrorItemLabel>
                        <ErrorMessage>
                          <IconContainer>
                            <AlertIcon size={24} />
                          </IconContainer>
                          <div>
                            {error.message.replaceAll(
                              `"${error.context.key}"`,
                              error.context.value,
                            )}
                          </div>
                        </ErrorMessage>
                      </ErrorItem>
                    );
                  })}
                </ErrorList>
              </ErrorContainer>
            );
          })}
        </div>
      </Collapsible>
    </>
  );
};
