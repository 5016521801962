import {
  getOnboardingsRequest,
  ONBOARDING_RESOURCE_PATH,
} from 'common/api/onboardings.api';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

type Onboarding = { [key: string]: string };

export const useOnboardings = <T = Onboarding[]>({
  params,
  options,
}: {
  params: { [key: string]: string };
  options?: UseQueryOptions<T, Error, T, QueryKey>;
}) =>
  useQuery<T, Error, T, QueryKey>(
    [ONBOARDING_RESOURCE_PATH, params],
    () => getOnboardingsRequest(params).then(getDataFromResponse) as Promise<T>,
    {
      ...options,
      enabled: options?.enabled,
    },
  );
