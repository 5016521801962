import { useCallback, useEffect, useMemo, useState } from 'react';

import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { DropdownOption, OnSelect } from 'common/types';
import { UseQueryResult } from 'react-query';
import { findSoftOptionMatch } from 'utils/findSoftOptionMatch';

import DropdownInput from '../DropdownInput/DropdownInput';

type FetchingDropdownInputProps = {
  value: Record<string, string> | string;
  name: string;
  placeholder: string;
  disabled: boolean;
  onSelect: (name: string, option: DropdownOption, payload: any) => void;
  onFocus: () => void;
  query: (params?: any) => UseQueryResult<any[]>;
  params?: any;
  dbErrorMessage: string;
  dataIdField?: string;
  transform?: (queryResult: any) => DropdownOption;
  label?: string;
};

const transformDataToOptions = ({
  id,
  name,
}: {
  id: string;
  name: string;
}): DropdownOption => ({
  label: name,
  value: id,
});

const FetchingDropdownInput = ({
  name,
  value,
  placeholder,
  disabled,
  onSelect,
  onFocus,
  query: useDropdownQuery,
  params,
  dbErrorMessage,
  dataIdField = 'id',
  transform = transformDataToOptions,
  label,
}: FetchingDropdownInputProps) => {
  const query = useDropdownQuery(params);

  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [payloadObject, setPayloadObject] = useState<Record<string, unknown>>(
    {},
  );

  const currentOption = useMemo(() => {
    const softMatchingOption = findSoftOptionMatch(options, value);
    return softMatchingOption || value;
  }, [options, value]);

  const handleSelect: OnSelect = useCallback(
    (fieldName, label, { value: id }) => {
      onSelect(fieldName, { value: id, label }, payloadObject[id]);
    },
    [payloadObject, onSelect],
  );

  useEffect(() => {
    if (query.isSuccess) {
      const result = query.data;

      setOptions(result.map(transform));
      setPayloadObject(
        result.reduce((acc, item) => {
          const id = item[dataIdField];
          acc[id] = item;
          return acc;
        }, {}),
      );
    }
  }, [dataIdField, query.isSuccess, query.data, transform]);

  if (query.isError) {
    return <ErrorBanner errorMessage={dbErrorMessage} />;
  }

  return (
    <DropdownInput
      options={{
        name,
        options,
      }}
      label={label}
      value={currentOption}
      placeholder={placeholder}
      isLoading={query.isLoading}
      onSelect={handleSelect}
      onFocus={onFocus}
      isDisabled={disabled}
    />
  );
};

export default FetchingDropdownInput;
