import PropTypes from 'prop-types';
import { useSortDataByCreatedAt } from 'utils/hooks/useSortDataByCreatedAt/useSortDataByCreatedAt';

import TableWithTitle from '../../../components/organisms/TableWithTitle/TableWithTitle';
import { employeeExpenseTableHeaders } from './expensesTableHeaders';

const byTableHeaderId = ({ id }) => id !== 'set-to-paid-button';

const getEmployeeExpenseTableHeaders = (canSetExpensesToPaidStatus) =>
  !canSetExpensesToPaidStatus
    ? employeeExpenseTableHeaders.filter(byTableHeaderId)
    : employeeExpenseTableHeaders;

const EmployeeExpensesTab = ({
  expenses: { paid = [], outstanding = [] } = {},
  canSetExpensesToPaidStatus,
}) => {
  const { orderedData: expensesToDisplay } = useSortDataByCreatedAt([
    ...paid,
    ...outstanding,
  ]);

  const filteredEmployeeExpenseTableHeaders = getEmployeeExpenseTableHeaders(
    canSetExpensesToPaidStatus,
  );

  return (
    <div data-testid="expenses-tab">
      <TableWithTitle
        headers={filteredEmployeeExpenseTableHeaders}
        itemsToDisplay={expensesToDisplay}
        maxWidth={false}
      />
    </div>
  );
};

EmployeeExpensesTab.defaultProps = {
  canSetExpensesToPaidStatus: true,
};

EmployeeExpensesTab.propTypes = {
  expenses: PropTypes.oneOfType([PropTypes.array]).isRequired,
  canSetExpensesToPaidStatus: PropTypes.bool,
};

export default EmployeeExpensesTab;
