import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { PaginationState } from '@tanstack/react-table';
import { PageContent } from 'app/App.styles';
import { ErrorBanner, Loading, PageHeader } from 'common/components';
import {
  EmployeeReportData,
  getGtnProcessingDisplayStatus,
  getIntentByStatus,
  GrossToNetFileLSPData,
  GrossToNetProcessingStatus,
  PAYROLL_CYCLE_LABELS,
  PayrollCycle,
  PayrollReportDataCurrency,
  PayrollReportPreviewApiResponse,
} from 'common/types/payroll';
import { startCase } from 'lodash';
import { DateTime } from 'luxon';
import { LspDataModal } from 'omniplatform/admin/pages/PayReportPreviewPage/components/LspDataModal';
import {
  formatValueToFixed,
  PayReportPreviewTable,
} from 'omniplatform/admin/pages/PayReportPreviewPage/PayReportPreviewTable';
import {
  getPayReportViewFilterPayrollPeriod,
  PAY_CYCLES,
  PayReportViewFilter,
} from 'omniplatform/admin/pages/PayReportPreviewPage/PayReportViewFilter';
import {
  PayrollInstanceDetailPageContext,
  VisibleModalEnum,
} from 'omniplatform/admin/pages/PayrollInstanceDetailPage/PayrollInstanceDetailPageContext';
import { PayrollContextWrapper } from 'omniplatform/admin/pages/PayrollPage/PayrollContextWrapper';
import { PAYROLL_INSTANCE_DETAIL_PAGE } from 'paths';
import { getCountryByCountryCode } from 'utils/countries';
import { useTrack } from 'utils/mixpanel/tracker';
import { useLspQuery } from 'utils/queries';
import { useApprovePayReportMutation } from 'utils/queries/payroll/useApprovePayReportMutation';
import { useDeleteGrossToNetFileMutation } from 'utils/queries/payroll/useDeleteGrossToNetFileMutation';
import { useGrossToNetQuery } from 'utils/queries/payroll/useGrossToNetQuery';
import { usePayReportPreviewQuery } from 'utils/queries/payroll/usePayReportPreviewQuery';
import { usePayReportWorkflowTransitionMutation } from 'utils/queries/payroll/usePayReportWorkflowTransitionMutation';
import { usePayrollJustificationsQuery } from 'utils/queries/payroll/usePayrollJustificationQuery';

import {
  Box,
  Button,
  Card,
  Inline,
  Input,
  List,
  Modal,
  Notification,
  notifyError,
  notifySuccess,
  Stack,
  Tag,
  Typography,
} from '@omnipresentgroup/design-system';

import { StatusHistoryModal } from './components/StatusHistoryModal';
import { PayReportBreakdownModal } from './PayReportBreakdownModal';

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_PAGE_INDEX = 0;

const generateURLParams = ({
  pageIndex = DEFAULT_PAGE_INDEX,
  pageSize = DEFAULT_PAGE_SIZE,
  selectedRow,
}: {
  pageIndex: number;
  pageSize: number;
  selectedRow?: number | null;
}) => {
  const params = new URLSearchParams();
  params.append('pageIndex', pageIndex.toString());
  params.append('pageSize', pageSize.toString());

  if (selectedRow !== null && selectedRow !== undefined) {
    params.append('selectedRow', selectedRow.toString());
  }

  return params;
};

export const NEW_STATUSES_CONFIG: Partial<
  Record<
    GrossToNetProcessingStatus,
    {
      action: {
        label: string;
        buttonVariant?: 'primary' | 'secondary' | 'tertiary';
      };
      modal?: {
        title?: string;
        content?: React.ReactNode;
      };
      notification?: {
        message: string;
      };
    }
  >
> = {
  PRE_APPROVAL_QUERY: {
    action: {
      label: 'Submit Pre-Approval Query',
      buttonVariant: 'secondary',
    },
    modal: {
      title: 'Submit Pre-Approval Query?',
      content: (
        <Stack gap="24">
          <Typography as="span" size="16">
            Are you sure you want to raise a pre-approval query?
          </Typography>
          <Typography as="span" size="16">
            This will mark the upload as requiring clarification.
          </Typography>
        </Stack>
      ),
    },
    notification: {
      message: 'Pay report query successfully submitted',
    },
  },
  SPECIALIST_APPROVED: {
    action: {
      label: 'Approve',
    },
    modal: {
      title: 'Approve payroll data?',
      content: (
        <Stack gap="24">
          <Typography as="span" size="16">
            Are you sure you want to approve this payroll report data?
          </Typography>
          <Typography as="span" size="16">
            Continuing with this action means that you have:
          </Typography>
          <List
            showDisc
            gap="0"
            items={[
              {
                id: '1',
                name: 'Verified all variances, and included justifications where necessary',
              },
              {
                id: '2',
                name: 'Confirmed that the LSP Billing Data, where applicable, has been entered correctly',
              },
              {
                id: '3',
                name: 'Confirmed that the upload has the correct employees',
              },
              {
                id: '4',
                name: 'Confirmed that all associated costs have been accounted for',
              },
            ]}
            keyExtractor="id"
            render={(item) => (
              <Typography as="span" size="16">
                {item.name}
              </Typography>
            )}
          />
        </Stack>
      ),
    },
    notification: {
      message: 'Pay report has been successfully approved',
    },
  },
  PRE_PUBLISH_QUERY: {
    action: {
      label: 'Submit Pre-Publish Query',
      buttonVariant: 'secondary',
    },
    modal: {
      title: 'Submit Pre-Publish Query?',
      content: (
        <Stack gap="24">
          <Typography as="span" size="16">
            Are you sure you want to raise a pre-publish query?
          </Typography>
          <Typography as="span" size="16">
            This will mark the upload as requiring clarification.
          </Typography>
        </Stack>
      ),
    },
    notification: {
      message: 'Pay report query successfully submitted',
    },
  },
  PUBLISHED: {
    action: {
      label: 'Publish',
    },
    modal: {
      title: 'Publish payroll report?',
      content: (
        <Stack gap="24">
          <Box>
            <Notification
              id="publish-warning"
              intent="warning"
              title="This process cannot be undone. Please ensure that you have completed all required verification checks before proceeding."
            ></Notification>
          </Box>
          <Typography as="span" size="16">
            Are you sure you want to publish this payroll report?
          </Typography>
          <Typography as="span" size="16">
            Continuing with this action will send this data into subsequent
            processing. Including:
          </Typography>

          <List
            showDisc
            gap="0"
            items={[
              {
                id: '1',
                name: ' Providing the client with their Payroll Report and Invoice Breakdowns',
              },
              {
                id: '2',
                name: 'Generating the data for Client Billing',
              },
              {
                id: '3',
                name: 'Generating the data for Vendor Bills, where applicable',
              },
              {
                id: '4',
                name: 'Generating the data for Journals across the P&L and Balance Sheet',
              },
            ]}
            keyExtractor="id"
            render={(item) => (
              <Typography as="span" size="16">
                {item.name}
              </Typography>
            )}
          />
        </Stack>
      ),
    },
    notification: {
      message: 'Pay report has been successfully published',
    },
  },
};

export const PROCESSING_STATUS_TRANSITIONS_MAP: Partial<
  Record<GrossToNetProcessingStatus, Set<GrossToNetProcessingStatus>>
> = {
  COPIED: new Set([
    GrossToNetProcessingStatus.PRE_APPROVAL_QUERY,
    GrossToNetProcessingStatus.SPECIALIST_APPROVED,
  ]),
  PRE_APPROVAL_QUERY: new Set([GrossToNetProcessingStatus.SPECIALIST_APPROVED]),
  SPECIALIST_APPROVED: new Set([
    GrossToNetProcessingStatus.PRE_PUBLISH_QUERY,
    GrossToNetProcessingStatus.PUBLISHED,
  ]),
  PRE_PUBLISH_QUERY: new Set([GrossToNetProcessingStatus.PUBLISHED]),
};

const getNextStatusesActions = (
  currentStatus: GrossToNetProcessingStatus | undefined,
  callbacks: {
    onApprove: () => void;
    onPostApprovalTransition: (newStatus: GrossToNetProcessingStatus) => void;
    onRefresh: () => void;
  },
  disable: boolean,
) => {
  if (currentStatus === GrossToNetProcessingStatus.DUPLICATES_CHECKED) {
    return [
      <Button
        key="approve"
        data-testid="approve-pay-report-button"
        onClick={callbacks.onApprove}
        variant="primary"
        disabled={disable}
      >
        Start Verification
      </Button>,
    ];
  }

  if (currentStatus === GrossToNetProcessingStatus.APPROVED) {
    return [
      <Button
        key="refresh"
        data-testid="refresh-pay-report-button"
        onClick={callbacks.onRefresh}
        variant="primary"
        disabled={disable}
      >
        Refresh
      </Button>,
    ];
  }

  const postApprovalTransitions =
    (currentStatus && PROCESSING_STATUS_TRANSITIONS_MAP[currentStatus]) || [];

  return [...postApprovalTransitions].map((newStatus) => {
    const buttonVariant =
      NEW_STATUSES_CONFIG[newStatus]?.action.buttonVariant || 'primary';
    return (
      <Button
        key={newStatus}
        data-testid={`${newStatus.toLowerCase()}-pay-report-button`}
        onClick={() => {
          callbacks.onPostApprovalTransition(newStatus);
        }}
        variant={buttonVariant}
        disabled={disable}
      >
        {NEW_STATUSES_CONFIG[newStatus]?.action.label ||
          getGtnProcessingDisplayStatus(newStatus)}
      </Button>
    );
  });
};

const ApprovedStates = [GrossToNetProcessingStatus.PUBLISHED];

const NextStatusConfirmModal = ({
  open,
  onOpenChange,
  newStatus,
  loading,
  onConfirm,
}: {
  open: boolean;
  onOpenChange: (_open: boolean) => void;
  newStatus: GrossToNetProcessingStatus | null;
  reportData?: PayrollReportPreviewApiResponse;
  loading: boolean;
  onConfirm: () => void;
}) => {
  const newStatusConfig = newStatus && NEW_STATUSES_CONFIG[newStatus];

  const actionLabel = newStatusConfig?.action.label || '';
  const modalTitle = newStatusConfig?.modal?.title;
  const modalContent = newStatusConfig?.modal?.content;

  return (
    <Modal span={5} open={open} onOpenChange={onOpenChange}>
      <Modal.Header title={modalTitle || actionLabel} />
      <Modal.Body>{modalContent}</Modal.Body>
      <Modal.Footer>
        <Inline gap="8">
          <Button variant="secondary" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button loading={loading} onClick={onConfirm}>
            {actionLabel}
          </Button>
        </Inline>
      </Modal.Footer>
    </Modal>
  );
};

const StartVerificationModal = ({
  showStartVerificationPayReportModal,
  setShowStartVerificationPayReportModal,
  approvePayReportClick,
  isApproving,
}: {
  showStartVerificationPayReportModal: boolean;
  setShowStartVerificationPayReportModal: (_open: boolean) => void;
  approvePayReportClick: () => void;
  isApproving: boolean;
}) => {
  return (
    <Modal
      span={5}
      open={showStartVerificationPayReportModal}
      onOpenChange={setShowStartVerificationPayReportModal}
    >
      <Modal.Header title="Start Verification?" />
      <Modal.Body>
        <Stack gap="24">
          <Typography as="span" size="16">
            Are you sure you want to start the data verification process for
            this data?
          </Typography>
          <Typography as="span" size="16">
            This will make it available in all reporting dashboards
          </Typography>
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Inline gap="8">
          <Button
            variant="secondary"
            onClick={() => setShowStartVerificationPayReportModal(false)}
          >
            Cancel
          </Button>
          <Button loading={isApproving} onClick={() => approvePayReportClick()}>
            Start Verification
          </Button>
        </Inline>
      </Modal.Footer>
    </Modal>
  );
};

const InfoHeader = ({
  reportData,
  lspData,
}: {
  reportData?: PayrollReportPreviewApiResponse;
  lspData?: GrossToNetFileLSPData | null;
}) => {
  const { setVisibleModal } = useContext(PayrollInstanceDetailPageContext);
  const totalSumDisplay = `${reportData?.reportSummary?.currency || ''} ${
    reportData?.reportSummary?.reportTotalSum
      ? formatValueToFixed(reportData?.reportSummary?.reportTotalSum)
      : ''
  }`;

  const displayStatus = reportData
    ? getGtnProcessingDisplayStatus(reportData.reportSummary.status)
    : 'UNAVAILABLE';
  const intent = getIntentByStatus(reportData?.reportSummary?.status);

  return (
    <Card p="24">
      <Inline stretch={1}>
        <Inline justify="space-between" stretch="all">
          <Stack bg="primary" color="default">
            <Typography as="span" size="12" color="subtle" weight="medium">
              Total
            </Typography>
            <Typography as="span" size="16" weight="medium">
              {totalSumDisplay}
            </Typography>
          </Stack>
          <Stack
            bg="primary"
            color="default"
            borderLeft
            border="subtle"
            pl="16"
          >
            <Typography as="span" size="12" color="subtle" weight="medium">
              Employees
            </Typography>
            <Typography as="span" size="16" weight="medium">
              {reportData?.reportSummary?.employeeCount || ''}
            </Typography>
          </Stack>
          <Stack
            bg="primary"
            color="default"
            borderLeft
            border="subtle"
            pl="16"
          >
            <Typography as="span" size="12" color="subtle" weight="medium">
              Payroll Cycle
            </Typography>
            <Typography as="span" size="16" weight="medium">
              {reportData?.reportSummary?.payrollCycle ===
              PayrollCycle.OFF_CYCLE
                ? PAYROLL_CYCLE_LABELS[reportData?.reportSummary?.payrollCycle]
                : reportData?.reportSummary?.payrollCycle || ''}
            </Typography>
          </Stack>
          <Stack
            bg="primary"
            color="default"
            borderLeft
            border="subtle"
            pl="16"
          >
            <Inline align="center" justify="space-between">
              <Typography as="span" size="12" color="subtle" weight="medium">
                Status
              </Typography>
            </Inline>
            <Tag id="tag-id-medium" size="small" intent={intent}>
              {displayStatus}
            </Tag>
          </Stack>
        </Inline>
        {(reportData || lspData) && (
          <Stack>
            {reportData && (
              <Button
                icon="Clock"
                variant="tertiary"
                size="small"
                title="View status history"
                onClick={() => setVisibleModal(VisibleModalEnum.gtnHistory)}
              />
            )}
            {(lspData ||
              reportData?.reportSummary.status !==
                GrossToNetProcessingStatus.PUBLISHED) && (
              <Button
                variant="secondary"
                size="small"
                title="View LSP data"
                onClick={() => setVisibleModal(VisibleModalEnum.gtnLspData)}
              >
                LSP data
              </Button>
            )}
          </Stack>
        )}
      </Inline>
    </Card>
  );
};

const Page = () => {
  const track = useTrack();
  const { dataFrameId, payrollInstanceId } = useParams<{
    dataFrameId: string;
    payrollInstanceId: string;
  }>();
  const history = useHistory();
  const location = useLocation();
  const { visibleModal } = useContext(PayrollInstanceDetailPageContext);
  const searchParams = new URLSearchParams(location.search);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: Number(searchParams.get('pageIndex') || DEFAULT_PAGE_INDEX),
    pageSize: Number(searchParams.get('pageSize') || DEFAULT_PAGE_SIZE),
  });

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [employeeSearchValue, setEmployeeSearchValue] = useState('');

  const [payReportViewFilter, setPayReportViewFilter] =
    useState<PayReportViewFilter>({
      showJustification: false,
      showVariance: false,
      year: DateTime.now().get('year').toString(),
      month: DateTime.now().minus({ months: 1 }).get('month').toString(),
      payCycle: PAY_CYCLES[0],
    });

  const payReportViewFilterPayrollPeriod =
    getPayReportViewFilterPayrollPeriod(payReportViewFilter);

  const {
    data: reportData,
    isLoading,
    isError,
    isFetching,
    refetch: refetchReportData,
  } = usePayReportPreviewQuery(dataFrameId, {
    dataCurrency: PayrollReportDataCurrency.LOCAL,
    pageIndex,
    pageSize,
    ...(payReportViewFilter.showVariance
      ? {
          variancePayrollPeriod: payReportViewFilterPayrollPeriod,
          variancePayrollCycle: payReportViewFilter.payCycle,
        }
      : {}),
  });

  const { data: justifications } = usePayrollJustificationsQuery({
    grossToNetId: dataFrameId,
  });

  const { data: lspData, isLoading: isLoadingLspInfo } = useLspQuery(
    reportData?.reportSummary.lspId || '',
  );

  const {
    data: gtnData,
    isLoading: isLoadingGtnData,
    refetch: refetchGtnData,
  } = useGrossToNetQuery(dataFrameId);

  const reportDataEmployees = useMemo(() => {
    const justificationEmployeeIds =
      justifications?.map(({ employeeId }) => employeeId) || [];
    const employees = reportData?.employees?.items || [];

    return employees
      .map((employee) => ({
        ...employee,
        hasJustificationNote: justificationEmployeeIds.includes(
          employee.employeeId,
        ),
      }))
      .filter((employee) => {
        const fullName = `${employee.firstName} ${employee.lastName}`;
        return !employeeSearchValue
          ? true
          : fullName.toLowerCase().includes(employeeSearchValue.toLowerCase());
      });
  }, [reportData, justifications, employeeSearchValue]);

  const currentEmployee =
    selectedRow !== null ? reportDataEmployees[selectedRow] : undefined;

  const totalPageCount: number = reportData?.employees?.pageTotalCount || -1;

  const isReportApproved =
    reportData && ApprovedStates.includes(reportData.reportSummary.status);

  const [
    showStartVerificationPayReportModal,
    setShowStartVerificationPayReportModal,
  ] = useState<boolean>(false);
  const [
    showWorkflowTransitionPayReportModal,
    setShowWorkflowTransitionPayReportModal,
  ] = useState<boolean>(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    useState<boolean>(false);

  const { mutate: approvePayReport, isLoading: isApproving } =
    useApprovePayReportMutation();

  const { mutate: workflowTransition, isLoading: isWaitingTransition } =
    usePayReportWorkflowTransitionMutation();

  const [selectedNewStatus, setSelectedNewStatus] =
    useState<GrossToNetProcessingStatus | null>(null);

  const { mutate: deleteGrossToNetFile, isLoading: isDeleting } =
    useDeleteGrossToNetFileMutation();

  useEffect(() => {
    const params = generateURLParams({
      pageIndex,
      pageSize,
      selectedRow,
    });

    history.push({
      search: params.toString(),
    });
  }, [pageIndex, pageSize, selectedRow, history]);

  const handleEmployeeNameClick = (id: string) => {
    track('View employee pay report clicked');
    setSelectedRow(Number(id));
  };

  const handleCancelPayReportModal = () => {
    setSelectedRow(null);
  };

  const fileName = useMemo(
    () => reportData?.reportSummary?.fileName || dataFrameId,
    [dataFrameId, reportData?.reportSummary?.fileName],
  );

  if (isLoading || isLoadingLspInfo || isLoadingGtnData) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorBanner errorMessage="A problem occurred" />;
  }

  const canDelete =
    reportData?.reportSummary?.status !== GrossToNetProcessingStatus.APPROVED &&
    reportData?.reportSummary?.status !== GrossToNetProcessingStatus.PUBLISHED;

  return (
    <PageContent data-testid="pay-report-preview-page" className="bigStack">
      <PageHeader
        backLink={{
          url: `${PAYROLL_INSTANCE_DETAIL_PAGE(payrollInstanceId)}#payreports`,
          label: 'Back to all pay reports',
        }}
        title={`${startCase(
          getCountryByCountryCode(reportData?.reportSummary?.country || '')
            ?.label,
        )}: ${lspData?.name}`}
        testId="pay-report-preview"
        copy={reportData?.reportSummary?.fileName ?? ''}
        customCTA={
          <Inline gap="32">
            {canDelete && (
              <Button
                color="negative"
                variant="secondary"
                icon="Trash"
                onClick={() => {
                  setShowConfirmDeleteModal(true);
                }}
              >
                Delete
              </Button>
            )}
            {getNextStatusesActions(
              reportData?.reportSummary?.status,
              {
                onApprove: () => {
                  setShowStartVerificationPayReportModal(true);
                },
                onPostApprovalTransition: (
                  newStatus: GrossToNetProcessingStatus,
                ) => {
                  setSelectedNewStatus(newStatus);
                  setShowWorkflowTransitionPayReportModal(true);
                },
                onRefresh: () => {
                  refetchReportData();
                },
              },
              isLoading || isError,
            )}
          </Inline>
        }
      />
      <StartVerificationModal
        showStartVerificationPayReportModal={
          showStartVerificationPayReportModal
        }
        setShowStartVerificationPayReportModal={
          setShowStartVerificationPayReportModal
        }
        isApproving={isApproving}
        approvePayReportClick={() => {
          if (dataFrameId) {
            track('Start Verification Pay Report file button clicked');
            approvePayReport(
              { dataFrameId },
              {
                onSuccess: () => {
                  setShowStartVerificationPayReportModal(false);
                  notifySuccess({
                    title: 'Success',
                    description:
                      'Pay report status has been changed successfully',
                  });
                  refetchReportData();
                },
                onError: (error) => {
                  setShowStartVerificationPayReportModal(false);
                  notifyError({
                    title: 'Error',
                    description: `Something went wrong starting verification of the pay report: ${error.response?.data.message}`,
                  });
                },
              },
            );
          }
        }}
      />
      <NextStatusConfirmModal
        reportData={reportData}
        loading={isWaitingTransition}
        newStatus={selectedNewStatus}
        open={showWorkflowTransitionPayReportModal}
        onOpenChange={setShowWorkflowTransitionPayReportModal}
        onConfirm={() => {
          if (dataFrameId && selectedNewStatus) {
            const newStatusConfig = NEW_STATUSES_CONFIG[selectedNewStatus];
            track(`${newStatusConfig?.action.label} pay report file clicked`);
            workflowTransition(
              { dataFrameId, newStatus: selectedNewStatus },
              {
                onSuccess: () => {
                  setShowWorkflowTransitionPayReportModal(false);
                  notifySuccess({
                    title: 'Success',
                    description:
                      newStatusConfig?.notification?.message ||
                      'Pay report status has been changed successfully',
                  });
                  refetchReportData();
                  refetchGtnData();
                },
                onError: (error) => {
                  setShowWorkflowTransitionPayReportModal(false);
                  notifyError({
                    title: 'Error',
                    description: `Something went wrong changing pay report state: ${error.response?.data}`,
                  });
                },
              },
            );
          }
        }}
      />
      <Modal
        span={5}
        open={showConfirmDeleteModal}
        onOpenChange={setShowConfirmDeleteModal}
      >
        <Modal.Header title={`Delete ${fileName}`} />
        <Modal.Body>
          <Stack gap="24">
            <Typography as="span" size="16">
              Are you sure you wish to delete the {fileName} pay report file?
            </Typography>
            <Typography as="span" size="16">
              This process can&apos;t be undone.
            </Typography>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Inline gap="8">
            <Button
              variant="secondary"
              onClick={() => setShowConfirmDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              loading={isDeleting}
              onClick={() => {
                if (dataFrameId) {
                  reportData?.reportSummary.status ===
                  GrossToNetProcessingStatus.APPROVED
                    ? track('Delete approved pay report file clicked')
                    : track('Delete pay report file clicked');

                  deleteGrossToNetFile(dataFrameId, {
                    onSuccess: () => {
                      notifySuccess({
                        title: `${fileName} successfully deleted`,
                      });
                      history.replace(
                        `${PAYROLL_INSTANCE_DETAIL_PAGE(
                          payrollInstanceId,
                        )}#payreports`,
                      );
                    },
                    onError: () => {
                      notifyError({
                        title: `Something went wrong while deleting file ${fileName}`,
                      });
                    },
                    onSettled: () => {
                      setShowConfirmDeleteModal(false);
                    },
                  });
                }
              }}
            >
              Delete
            </Button>
          </Inline>
        </Modal.Footer>
      </Modal>
      {reportData && <StatusHistoryModal gtnInfo={reportData.reportSummary} />}
      {visibleModal === VisibleModalEnum.gtnLspData && gtnData && (
        <LspDataModal
          gtnId={gtnData.id}
          lspData={gtnData.lspData}
          canEdit={
            gtnData.processingStatus !== GrossToNetProcessingStatus.PUBLISHED
          }
          onLspDataUpdated={() => {
            refetchGtnData();
          }}
        />
      )}
      <InfoHeader reportData={reportData} lspData={gtnData?.lspData} />
      <Stack bg="primary" radius="m" border="subtle">
        <Inline pt="24" pl="24" pr="24">
          <Input
            id="searchInput"
            placeholder="Search employees"
            type="search"
            value={employeeSearchValue}
            onChange={(e) => {
              setEmployeeSearchValue(e.target.value);
            }}
            handleClearSearch={() => setEmployeeSearchValue('')}
          />
          <PayReportViewFilter
            payReportViewFilter={payReportViewFilter}
            onFilterUpdate={setPayReportViewFilter}
          />
        </Inline>
        <PayReportPreviewTable
          handleEmployeeNameClick={handleEmployeeNameClick}
          reportDataEmployees={reportDataEmployees}
          payReportViewFilter={payReportViewFilter}
          totalPageCount={totalPageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={setPagination}
          setPayReportViewFilter={setPayReportViewFilter}
        />
      </Stack>

      {currentEmployee && (
        <PayReportBreakdownModal
          title={currentEmployee?.firstName || 'It is a little empty here'}
          employeeReportData={currentEmployee || ({} as EmployeeReportData)}
          employeeTotalCount={reportData?.reportSummary?.employeeCount ?? 0}
          grossToNetId={dataFrameId}
          selectedRow={selectedRow}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={setPagination}
          setSelectedRow={setSelectedRow}
          cancelHandler={handleCancelPayReportModal}
          isLoading={isFetching}
          payReportViewFilter={payReportViewFilter}
          approved={!!isReportApproved}
        />
      )}
    </PageContent>
  );
};

export const PayReportPreviewPage = () => {
  return (
    <PayrollContextWrapper>
      <Page />
    </PayrollContextWrapper>
  );
};
