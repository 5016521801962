import styled from 'styled-components';

export const StyledCheckIcon = styled.span`
  background-color: ${({ theme: { colors } }) => colors['icon-positive']};
  height: 48px;
  width: 48px;
  min-width: 48px;
  border-radius: ${({ theme: { radii } }) => radii.full};
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: ${({ theme: { colors } }) => colors['icon-on-color']};
`;
