import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledEmployeeExpenseDetailPage = styled.div`
  display: flex;
  flex-direction: column;
  place-self: center flex-start;
  width: 100%;
  overflow: visible;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  margin-top: ${({ theme: { space } }) => `${space[32]}`};

  button {
    margin-right: ${({ theme: { space } }) => space[8]};

    @media (max-width: ${tokens.breakpoint.tablet}) {
      margin-right: 0;
      margin-left: 0;
      width: 100%;
      margin-top: ${({ theme: { space } }) => `${space[16]}`};
    }
  }

  @media (max-width: ${tokens.breakpoint.tablet}) {
    width: 100%;
    margin-top: ${({ theme: { space } }) => `${space[16]}`};
    flex-direction: column;
  }
`;

export const StyledExpenseDetails = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    flex-direction: column;
  }
`;
