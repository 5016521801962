import { AxiosError } from 'axios';
import { getTerminationRequest } from 'common/api/terminations.api';
import { THIRTY_SECONDS } from 'common/constants';
import { TerminationRequestModel } from 'common/types/terminations';
import { useQuery } from 'react-query';

import { getDataFromResponse } from '../../../../react-query-utils';
import { terminationRequestsKeys } from '../keys';

export const useTerminationRequestQuery = (
  terminationRequestId: string,
  { enabled }: { enabled: boolean },
) => {
  return useQuery<TerminationRequestModel, AxiosError>(
    terminationRequestsKeys.single(terminationRequestId),
    () => getTerminationRequest(terminationRequestId).then(getDataFromResponse),
    {
      enabled,
      staleTime: THIRTY_SECONDS,
    },
  );
};
