import { StyledLabel } from 'common/components/atoms/Label';
import styled, { css } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

import { StyledDropdownInput } from '../../DropdownInput/DropdownInput.styles';

export const Container = styled.div(
  ({ theme: { space, colors, borderWidths, borderStyles } }) => {
    return css`
      margin: ${space[0]};
      padding: ${space[16]} ${space[0]} ${space[32]};
      border: ${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']};
      border-left: 0;
      border-right: 0;
      transition: 180ms ease-in-out;
    `;
  },
);

export const InputLabel = styled(StyledLabel)(({
  theme: { space, fontWeights },
}) => {
  return css`
    padding: 0;
    margin: ${space[4]} ${space[0]} ${space[16]};
    display: block;
    font-weight: ${fontWeights.regular};
  `;
});

export const IntegrationWrapper = styled.div(
  ({ theme: { space, fontSizes } }) => {
    return css`
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: ${space[16]};

      > button {
        flex-shrink: 0;
        max-width: 192px;
        font-size: ${fontSizes[0]};
      }

      @media (max-width: ${tokens.breakpoint.tablet}) {
        flex-direction: column;
      }
    `;
  },
);

export const InputContainer = styled(StyledDropdownInput)(() => {
  return css`
    width: 100%;
  `;
});

export const DropdownOptionWrapper = styled.div(
  ({ theme: { space, fontWeights, colors } }) => {
    return css`
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      gap: ${space[16]};
      width: 100%;
      font-weight: ${fontWeights.regular};
      white-space: nowrap;
      overflow: hidden;

      strong {
        flex: 1;
        display: block;
        font-weight: ${fontWeights.regular};
      }

      span {
        flex-shrink: 1;
        color: ${colors['text-subtle']};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `;
  },
);

export const CopyContainer = styled.div(({ theme: { space } }) => {
  return css`
    margin-top: ${space[16]};
  `;
});
