import {
  EMPLOYMENT_STATUS,
  EMPLOYMENT_STATUS_MAP,
  EmploymentStatus,
} from 'common/types/employments';

export const getEmploymentStatusOptions = (
  employmentStatus: EmploymentStatus,
): { label: string; value: EmploymentStatus }[] => {
  switch (employmentStatus) {
    case EMPLOYMENT_STATUS.ACTIVE:
      return [
        employmentStatusToLabelValueMap[EMPLOYMENT_STATUS.ACTIVE],
        employmentStatusToLabelValueMap[EMPLOYMENT_STATUS.INACTIVE],
      ];
    case EMPLOYMENT_STATUS.ONBOARDED:
      return [
        employmentStatusToLabelValueMap[EMPLOYMENT_STATUS.ONBOARDED],
        employmentStatusToLabelValueMap[EMPLOYMENT_STATUS.INACTIVE],
      ];
    case EMPLOYMENT_STATUS.INACTIVE:
    case EMPLOYMENT_STATUS.CREATED:
      return [
        employmentStatusToLabelValueMap[EMPLOYMENT_STATUS.CREATED],
        employmentStatusToLabelValueMap[EMPLOYMENT_STATUS.INACTIVE],
      ];
  }
};

const employmentStatusToLabelValueMap = {
  [EMPLOYMENT_STATUS.ACTIVE]: {
    label: EMPLOYMENT_STATUS_MAP[EMPLOYMENT_STATUS.ACTIVE],
    value: EMPLOYMENT_STATUS.ACTIVE,
  },
  [EMPLOYMENT_STATUS.ONBOARDED]: {
    label: EMPLOYMENT_STATUS_MAP[EMPLOYMENT_STATUS.ONBOARDED],
    value: EMPLOYMENT_STATUS.ONBOARDED,
  },
  [EMPLOYMENT_STATUS.CREATED]: {
    label: EMPLOYMENT_STATUS_MAP[EMPLOYMENT_STATUS.CREATED],
    value: EMPLOYMENT_STATUS.CREATED,
  },
  [EMPLOYMENT_STATUS.INACTIVE]: {
    label: EMPLOYMENT_STATUS_MAP[EMPLOYMENT_STATUS.INACTIVE],
    value: EMPLOYMENT_STATUS.INACTIVE,
  },
};
