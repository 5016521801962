import { getPayrollReportPreview } from 'common/api/payrollTaxonomy.api';
import {
  PayrollReportDataCurrency,
  PayrollReportPreviewApiResponse,
} from 'common/types/payroll';
import { useQuery } from 'react-query';

import { getDataFromResponse } from '../../../react-query-utils';
import { payrollKeys } from '../keys';

export const usePayReportPreviewQuery = (
  dataFrameId: string,
  {
    pageIndex,
    pageSize,
    dataCurrency,
    variancePayrollPeriod,
    variancePayrollCycle,
  }: {
    pageSize?: number;
    pageIndex?: number;
    dataCurrency: PayrollReportDataCurrency;
    variancePayrollPeriod?: string;
    variancePayrollCycle?: string;
  },
) => {
  return useQuery<PayrollReportPreviewApiResponse, Error>(
    payrollKeys.payReportPreview({
      dataFrameId,
      pageSize,
      pageIndex,
      variancePayrollPeriod,
      variancePayrollCycle,
    }),
    () =>
      getPayrollReportPreview(dataFrameId, {
        dataCurrency,
        pageSize,
        pageIndex,
        variancePayrollPeriod,
        variancePayrollCycle,
      }).then(getDataFromResponse),
  );
};
