import { useRef, useState } from 'react';

import { DateTime, Info } from 'luxon';
import styled from 'styled-components';
import { useOnClickOutside } from 'utils/hooks';
import { useTrack } from 'utils/mixpanel/tracker';

import {
  Box,
  Button,
  Dropdown,
  RadioButtonGroup,
  Stack,
  ToggleSwitch,
} from '@omnipresentgroup/design-system';

export const FilterPopupWrapper = styled.div`
  position: absolute;
  top: 52px;
  right: 0;
  z-index: 3;
  width: 317px;
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
  box-shadow: ${({ theme: { shadows } }) => shadows.m};
  border-radius: ${({ theme: { radii } }) => radii.m};
  padding: ${({ theme: { space } }) => `${space[32]} ${space[16]}`};
`;

const PAY_YEAR = Array.from({ length: 2 }, (_, i) =>
  String(DateTime.now().get('year') - i),
);

export type PayReportViewFilter = {
  showJustification: boolean;
  showVariance: boolean;
  year: string;
  month: string;
  payCycle: string;
};

export const PAY_CYCLES = ['1', '2', '3', '4', '5'] as const;

export const getPayReportViewFilterPayrollPeriod = (
  payReportViewFilter: PayReportViewFilter,
) => `${payReportViewFilter.year}${payReportViewFilter.month.padStart(2, '0')}`;

export const PayReportViewFilter = ({
  payReportViewFilter,
  onFilterUpdate,
}: {
  payReportViewFilter: PayReportViewFilter;
  onFilterUpdate: (filters: PayReportViewFilter) => void;
}) => {
  const track = useTrack();
  const elementRef = useRef<HTMLDivElement>(null);
  const [showActionPopup, setShowActionPopup] = useState<boolean>(false);
  const [currentFilter, updateFilter] =
    useState<PayReportViewFilter>(payReportViewFilter);

  useOnClickOutside(elementRef, () => {
    onFilterUpdate(currentFilter);
    setShowActionPopup(false);
  });

  const payCycleOptions = PAY_CYCLES.map((val) => ({
    id: `pay-cycle-${val}`,
    label: `Cycle ${val}`,
    name: `Cycle ${val}`,
    value: val,
    checked: val === currentFilter.payCycle,
  }));

  const selectorsAreDisabled =
    !currentFilter.showJustification && !currentFilter.showVariance;

  return (
    <Box css={{ position: 'relative' }} ref={elementRef}>
      <Button
        icon="Adjustments"
        variant="secondary"
        onClick={() => {
          setShowActionPopup(!showActionPopup);
          onFilterUpdate(currentFilter);
        }}
      >
        View
      </Button>
      {showActionPopup && (
        <FilterPopupWrapper data-testid="filter-popup-wrapper">
          <Stack bg="primary" p="8">
            <ToggleSwitch
              checked={currentFilter.showVariance}
              onCheckedChange={(checked) => {
                track('Show variance button toggled');
                updateFilter((prevFilter) => ({
                  ...prevFilter,
                  showJustification: false,
                  showVariance: checked,
                }));
              }}
              label="Show variance"
              size="small"
            />
            <Dropdown
              id="filter-year-dropdown"
              label="Year"
              isDisabled={selectorsAreDisabled}
              value={{
                label: currentFilter.year,
                value: currentFilter.year,
              }}
              onChange={(option) => {
                if (!option || !('value' in option)) {
                  return;
                }

                updateFilter((prevFilter) => ({
                  ...prevFilter,
                  year: option.value.toString(),
                }));
              }}
              options={PAY_YEAR.map((year) => ({
                label: year,
                value: year,
              }))}
            />

            <Dropdown
              id="filter-month-dropdown"
              label="Month"
              isDisabled={selectorsAreDisabled}
              value={{
                label: Info.months()[Number(currentFilter.month) - 1],
                value: currentFilter.month,
              }}
              onChange={(option) => {
                if (!option || !('value' in option)) {
                  return;
                }

                updateFilter((prevFilter) => ({
                  ...prevFilter,
                  month: option.value.toString(),
                }));
              }}
              options={Info.months().map((month, index) => ({
                label: month,
                value: String(index + 1),
              }))}
            />

            <RadioButtonGroup
              id="filter-paycyle-radio-group"
              label="Pay cycle"
              disabled={selectorsAreDisabled}
              onChange={(e) => {
                const id = e.target.id;

                const selectedOption = payCycleOptions.find(
                  (option) => option.id === id,
                );

                if (!selectedOption) {
                  return;
                }

                updateFilter((prevFilter) => ({
                  ...prevFilter,
                  payCycle: selectedOption.value.toString(),
                }));
              }}
              options={payCycleOptions}
              required
            />
          </Stack>
        </FilterPopupWrapper>
      )}
    </Box>
  );
};
