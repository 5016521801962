import { useHistory } from 'react-router-dom';

import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import TableWithTitle from 'common/components/organisms/TableWithTitle/TableWithTitle';
import {
  Employer,
  EMPLOYER_TYPES_LABELS,
  EmployerType,
  PayrollProvider,
} from 'common/types';
import { getEditEmployerPath, PROVIDERS_EMPLOYER_ADD } from 'paths';
import { getCountryByCountryCode } from 'utils/countries';
import { useSelectUserRoles } from 'utils/hooks';
import { useEmployersQuery } from 'utils/queries/employers/useEmployerQuery';

import { Tag } from '@omnipresentgroup/design-system';

import { StyledTableCell } from './EmployersTab.styles';

const NameCustomCell = (
  employer: Employer,
  onColumnItemClick: (employerId: string) => void,
) => (
  <StyledTableCell onClick={() => onColumnItemClick(employer.id)}>
    {employer.name}
  </StyledTableCell>
);

const CountryCustomCell = ({ countryCode }: { countryCode: string }) => {
  const country = getCountryByCountryCode(countryCode)?.label;
  return <StyledTableCell>{country}</StyledTableCell>;
};

const TypeCustomCell = ({ type }: { type: EmployerType }) => {
  return EMPLOYER_TYPES_LABELS[type];
};

const ParentCustomCell = ({ parent }: { parent: Employer }) => {
  return parent ? `${parent.name} (${parent.countryCode})` : '';
};

const PPCustomCell = ({
  payrollProvider,
}: {
  payrollProvider: PayrollProvider;
}) => {
  return <StyledTableCell>{payrollProvider?.name}</StyledTableCell>;
};

const IsOmniEntityCustomCell = ({
  isOmniEntity,
}: {
  isOmniEntity: boolean;
}) => {
  if (isOmniEntity) {
    return (
      <StyledTableCell>
        <Tag id="is-omni-entity-tag" intent="success">
          Yes
        </Tag>
      </StyledTableCell>
    );
  }
};

const IsActiveLabelCell = ({ isActive }: { isActive: string }) => {
  if (isActive) {
    return (
      <StyledTableCell>
        <Tag id="is-active-label-cell-tag" intent="success">
          Active
        </Tag>
      </StyledTableCell>
    );
  }
  return (
    <Tag id="is-active-label-cell-tag" intent="default">
      Inactive
    </Tag>
  );
};

export const userTableHeaders = [
  {
    headerTitle: 'Name',
    customCell: NameCustomCell,
  },
  {
    headerTitle: 'Entity type',
    customCell: TypeCustomCell,
  },
  {
    headerTitle: 'Payroll provider',
    customCell: PPCustomCell,
  },
  {
    headerTitle: 'Country',
    customCell: CountryCustomCell,
  },
  {
    headerTitle: 'Parent entity',
    customCell: ParentCustomCell,
  },
  {
    headerTitle: 'Is OmniEntity',
    customCell: IsOmniEntityCustomCell,
  },
  {
    headerTitle: 'Active',
    customCell: IsActiveLabelCell,
  },
];

export const EmployersTab = () => {
  const history = useHistory();
  const { isIEAdmin } = useSelectUserRoles();
  const employersQuery = useEmployersQuery();

  const onAddEmployerClicked = () => history.push(PROVIDERS_EMPLOYER_ADD);
  const onEmployerClick = (employerId: string) =>
    history.push(getEditEmployerPath(employerId));

  if (employersQuery.isLoading) {
    return <Loading />;
  }

  if (employersQuery.isError) {
    return <ErrorBanner errorMessage={employersQuery.error.message} />;
  }

  return (
    <>
      <TableWithTitle
        title="Employers"
        itemsToDisplay={employersQuery.data || []}
        headers={userTableHeaders}
        onCTAClicked={isIEAdmin ? onAddEmployerClicked : null}
        CTAText="Add employer"
        showButton={isIEAdmin}
        testId="employers"
        onColumnItemClick={onEmployerClick}
        onClick={() => employersQuery?.data}
      />
    </>
  );
};
