import styled from 'styled-components';

import { omniConfig, Table } from '@omnipresentgroup/design-system';

export const StickyLastColumnTable = styled(Table)`
  thead {
    z-index: 2;
  }

  th:nth-child(1),
  td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: inherit;
  }

  th:nth-child(2),
  td:nth-child(2) {
    position: sticky;
    left: 64px;
    background-color: inherit;
  }

  tr:hover {
    background-color: #eaeafc;
  }

  th:last-child,
  td:last-child {
    position: sticky;
    right: 0;
    background-color: inherit;
  }

  th:not(:first-child) > div > span {
    width: 100%;
  }

  th:has(.end-cell),
  td:has(> .end-cell) {
    border-right-style: ${omniConfig.theme.borderStyles.solid};
    border-right-width: ${omniConfig.theme.borderWidths.s};
    border-right-color: ${omniConfig.theme.colors['border-subtle']};
  }
`;
