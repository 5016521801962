import { createQueryKeys } from 'utils/react-query-utils';

import type { IsoDateTime } from '@omnipresentgroup/design-system';

export const expensesKeys = createQueryKeys('expenses', (createKey) => ({
  multiple: (companyId: string) => createKey(companyId),
}));

export const expenseCsvKeys = createQueryKeys('expenses-csv', (createKey) => ({
  multiple: (companyId: string, expenseIds: string[]) =>
    createKey(companyId, expenseIds),
}));

export const expenseIdsKeys = createQueryKeys('expensesIds', (createKey) => ({
  multiple: (expenseIds: string[], approverId: string) =>
    createKey(expenseIds, approverId),
}));

export const expenseZipDownloadKeys = createQueryKeys(
  'expenseZipDownload',
  (createKey) => ({
    multiple: (approverId: string, timestamp: IsoDateTime) =>
      createKey(approverId, timestamp),
  }),
);
