import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const isTitle = (node) => {
  const { content: mainContent } = node;
  const text = mainContent[0];
  if (text?.marks) {
    const [mark] = text ? text?.marks : [];
    return mark?.type === 'bold';
  }
  return false;
};

const isParagraph = (node) => {
  return node?.nodeType?.trim() === 'paragraph';
};

const isUnorderedList = (node) => {
  return node.nodeType === 'unordered-list';
};

const formatParagraphWithMultipleTexts = (paragraph) => {
  return paragraph.content.map((text) => {
    const newText = {
      nodeType: 'paragraph',
      content: [text],
      data: {},
    };
    return newText;
  });
};

const hasDifferentFormat = (keyFacts) => {
  const firstParagraph = keyFacts.content[0];
  return firstParagraph?.content.length > 1;
};

export const transformKeyFactsToCollapsibleFormat = (keyFacts) => {
  if (keyFacts && keyFacts.content) {
    const facts = [];
    let newFact = { items: [] };
    if (hasDifferentFormat(keyFacts)) {
      const firstParagraph = keyFacts.content[0];

      const correctedParagrapsh =
        formatParagraphWithMultipleTexts(firstParagraph);
      keyFacts.content.shift();
      keyFacts.content = [...correctedParagrapsh, ...keyFacts.content];
    }
    const lastIndex = keyFacts.content.length - 1;

    keyFacts.content.forEach((node, index) => {
      if (isParagraph(node) && isTitle(node)) {
        if (newFact !== { items: [] }) {
          facts.push(newFact);
        }
        newFact = { items: [] };
        const title = documentToPlainTextString(node);
        newFact.title = title;
      } else {
        const text = isParagraph(node)
          ? documentToPlainTextString(node)
          : documentToPlainTextString(node, '|');
        const item = {
          text,
          isParagraph: !isUnorderedList(node),
        };
        newFact.items.push(item);
      }
      if (index === lastIndex) {
        facts.push(newFact);
      }
    });
    facts.shift();
    return facts;
  }
  return [];
};
