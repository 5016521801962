import styled from 'styled-components';

export const StyledToggle = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background-color: ${({ theme: { colors } }) =>
      colors['background-inverse']};
    transition: 0.4s;
  }

  .slider::before {
    position: absolute;
    content: '';
    height: 21px;
    width: 21px;
    left: 2px;
    bottom: 2px;
    background-color: ${({ theme: { colors } }) =>
      colors['control-background-default']};
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${({ theme: { colors } }) =>
      colors['control-background-interactive']};
  }

  input:focus + .slider {
    box-shadow: ${({ theme: { shadows } }) => shadows.s};
  }

  input:checked + .slider::before {
    transform: translateX(25px);
  }

  .slider.round {
    border-radius: ${({ theme: { radii } }) => radii.full};
  }

  .slider.round::before {
    border-radius: ${({ theme: { radii } }) => radii.full};
  }
`;
