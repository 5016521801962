import styled from 'styled-components';

export const StyledCardContainer = styled.div`
  box-shadow: ${({ theme: { shadows } }) => shadows.s};
  border-radius: ${({ theme: { radii } }) => radii.m};
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
  padding: ${({ theme: { space } }) => space[8]};
  cursor: pointer;
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  padding: ${({ theme: { space } }) => space[4]};
  justify-content: space-between;
  align-items: center;
`;

export const StyledFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const StyledFullWidthFieldContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  div {
    justify-self: flex-end;
    margin: 0;
  }
`;

export const StyledFieldContainer = styled.div`
  width: 45%;
  padding: ${({ theme: { space } }) => space[4]};
`;
