import { useEffect } from 'react';

import { TransformedUser } from 'app/store/app.transformers';
import { getSelectedCompanyForAppSelector } from 'common/store/selectors/companies.selectors';
import { EmployeeForDetailView, SelectedCompany } from 'common/types';
import { getEmployeeSelector } from 'omniplatform/employee/store/selectors/employee.selector';
import { useSelector } from 'react-redux';
import { getTracker } from 'utils/mixpanel/tracker';
import { hasSuperManagerScope, isEmployee, isManager } from 'utils/user';

export const useMixpanelTracking = (user: TransformedUser) => {
  const selectedEmployee = useSelector<any, EmployeeForDetailView>(
    getEmployeeSelector,
  );
  const selectedCompany = useSelector<any, SelectedCompany>(
    getSelectedCompanyForAppSelector,
  );

  useEffect(() => {
    if (!user?.roles) {
      return;
    }

    const tracker = getTracker();

    if (isEmployee(user)) {
      const employeeData = {
        employeeExternalId: selectedEmployee?.externalId,
        employeeJobTitle: selectedEmployee?.jobTitle,
      };
      tracker.register(employeeData);
    } else if (isManager(user)) {
      const managerData = {
        companyExternalId: selectedCompany?.administrativeInformation?.find(
          (info) => info.name === 'externalId',
        )?.value,
        companyName: selectedCompany?.companyName,
        permissionsTier: hasSuperManagerScope(user)
          ? 'superManager'
          : 'regularManager',
        jobTitle: selectedCompany?.managers?.find(
          (manager) => manager.id === user.userId,
        )?.jobTitle,
        department: selectedCompany?.managers?.find(
          (manager) => manager.id === user.userId,
        )?.department,
      };

      tracker.register(managerData);
    }
  }, [user, selectedEmployee, selectedCompany]);
};
