import { useEffect } from 'react';

import { subject } from '@casl/ability';
import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import { useAppAbility } from 'app/store/ability';
import { getLoadingSelector } from 'app/store/app.selectors';
import FloatingActionButton from 'common/components/atoms/FloatingActionButton/FloatingActionButton';
import Loading from 'common/components/atoms/Loading/Loading';
import DateSort from 'common/components/molecules/DateSort/DateSort';
import DocumentCard from 'common/components/molecules/DocumentCard/DocumentCard';
import MobileCardListHeader from 'common/components/molecules/MobileCardListHeader/MobileCardListHeader';
import NoDocuments from 'common/components/molecules/NoDocuments/NoDocuments';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import StyledTableContainer from 'common/components/molecules/StyledTableContainer/StyledTableContainer.styles';
import {
  deleteDocumentAction,
  getDocumentsAction,
} from 'common/store/actions/documents.actions';
import { getDocumentsSelector } from 'common/store/selectors/document.selectors';
import { ADD_DOCUMENT_PAGE } from 'paths';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSortDataByCreatedAt } from 'utils/hooks/useSortDataByCreatedAt/useSortDataByCreatedAt';

import { Viewport } from '@omnipresentgroup/design-system';

import {
  StyledDocumentListTableContainer,
  StyledDocumentsHeader,
  StyledShowingText,
} from '../../../manager/pages/DocumentListPage/DocumentListPage.styles';
import { getEmployeeSelector } from '../../store/selectors/employee.selector';
import { StyledDocumentListPageContainer } from './DocumentListPage.styles';

// TODO: Combine this with manager document page if possible
const DocumentListPage = ({
  getDocuments,
  employee,
  documents,
  loading,
  deleteDocument,
}) => {
  const onCTAClick = () => appHistory.push(ADD_DOCUMENT_PAGE);
  const ability = useAppAbility();

  const canAddDocument = ability.can(
    'documents:create',
    subject('Documents', { ownerId: employee.userId }),
  );

  const { orderedData: documentsToDisplay, setSortMethod } =
    useSortDataByCreatedAt(documents);

  useEffect(() => {
    if (employee?.userId) {
      getDocuments({ userId: employee.userId });
    }
  }, [employee, getDocuments]);

  return (
    <PageContent>
      {loading ? (
        <Loading />
      ) : (
        <StyledDocumentListPageContainer>
          {!documents?.length ? (
            <NoDocuments canAddDocument={canAddDocument} />
          ) : (
            <>
              <StyledTableContainer className="bigStack">
                <PageHeader
                  title="Personal documents"
                  ctaText="Add document"
                  secondaryCta
                  onCTAClick={onCTAClick}
                  hasDisabledCTA={!canAddDocument}
                />
                <StyledDocumentListTableContainer className="smallStack">
                  <Viewport devices={['phone', 'tablet']}>
                    <MobileCardListHeader total={documentsToDisplay.length} />
                  </Viewport>
                  <Viewport devices={['laptop', 'desktop', 'highRes']}>
                    <StyledDocumentsHeader>
                      <StyledShowingText>
                        Showing 1 - {documentsToDisplay.length} of{' '}
                        {documentsToDisplay.length}
                      </StyledShowingText>
                      <DateSort
                        onUpArrowClicked={() => setSortMethod('asc')}
                        onDownArrowClicked={() => setSortMethod('desc')}
                      />
                    </StyledDocumentsHeader>
                  </Viewport>
                  {documentsToDisplay.map((document) => (
                    <DocumentCard
                      key={document.id}
                      document={document}
                      canDeleteDocument={ability.can(
                        'documents:delete',
                        subject('Documents', { id: document.id }),
                      )}
                      canDownloadDocument={ability.can(
                        'documents:download',
                        subject('Documents', { id: document.id }),
                      )}
                      onDeleteDocument={() =>
                        deleteDocument({
                          documentId: document.id,
                          userId: employee.userId,
                        })
                      }
                    />
                  ))}
                </StyledDocumentListTableContainer>
              </StyledTableContainer>
            </>
          )}
        </StyledDocumentListPageContainer>
      )}
      <Viewport devices={['phone', 'tablet']}>
        <FloatingActionButton
          onClick={onCTAClick}
          type="add"
          disabled={!canAddDocument}
        />
      </Viewport>
    </PageContent>
  );
};

DocumentListPage.propTypes = {
  getDocuments: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  deleteDocument: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  employee: getEmployeeSelector(state),
  documents: getDocumentsSelector(state),
  loading: getLoadingSelector(state),
});

const mapDispatchToProps = {
  getDocuments: getDocumentsAction,
  deleteDocument: deleteDocumentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentListPage);
