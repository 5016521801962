import { getUserSelector } from 'app/store/app.selectors';
import { listCutoffReportFilters } from 'common/api/cutoffReports.api';
import { ListCutoffReportsFilter } from 'common/types';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { isPayrollAdmin } from 'utils/user';

import { notifyError } from '@omnipresentgroup/design-system';

import { getDataFromResponse } from '../../../react-query-utils';
import { payrollKeys } from '../keys';

export const useListCutoffReportsFilterQuery = () => {
  const user = useSelector(getUserSelector);

  return useQuery<ListCutoffReportsFilter, Error>(
    payrollKeys.cutoffReportFilterList(),
    () => listCutoffReportFilters().then(getDataFromResponse),
    {
      enabled: isPayrollAdmin(user),
      onError: () => {
        notifyError({
          title: 'Error',
          description: 'Failed to get cutoff report filters.',
        });
      },
    },
  );
};
