import { ActionCell } from 'common/components/molecules/ActionCell/ActionCell';
import NameTableCell from 'common/components/molecules/NameTableCell/NameTableCell';
import { OnboardingStatus } from 'common/components/molecules/OnboardingStatus/OnboardingStatus';
import { EmployeeActions } from 'common/types';

const NameCustomCell = (currentUser, onColumnItemClick) => {
  const { name } = currentUser;
  return (
    <span
      role="none"
      style={{ cursor: 'pointer' }}
      onClick={() => onColumnItemClick('employee', currentUser)}
    >
      <NameTableCell name={name} />
    </span>
  );
};

const ActionsCustomCell = (currentUser, onColumnItemClick) => (
  <>
    {currentUser?.onboardingStatus !== 'Active' ? (
      <ActionCell
        actions={EmployeeActions}
        onActionClick={(action) => onColumnItemClick(action, currentUser)}
      />
    ) : (
      ''
    )}
  </>
);

const StatusCustomCell = (employee) => (
  <OnboardingStatus
    status={employee.onboardingStatus}
    totalStages={employee?.totalStages}
    latestInProgressStageId={employee?.latestInProgressStageId}
  />
);

export const headers = [
  {
    id: 'employee',
    headerTitle: 'Employee',
    customCell: NameCustomCell,
  },
  {
    id: 'role',
    headerTitle: 'Role',
    fieldOnObject: 'jobTitle',
  },
  {
    id: 'country',
    headerTitle: 'Country',
    fieldOnObject: 'country',
  },
  {
    id: 'salary',
    headerTitle: 'Salary',
    fieldOnObject: 'annualBaseSalary',
  },
  {
    id: 'start-date',
    headerTitle: 'Start date',
    fieldOnObject: 'startDate',
  },
  {
    id: 'status',
    headerTitle: 'Status',
    customCell: StatusCustomCell,
  },
  {
    id: 'action',
    headerTitle: 'Actions',
    customCell: ActionsCustomCell,
  },
];
