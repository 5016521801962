import { AxiosError } from 'axios';
import { downloadDocumentRequest } from 'common/api/documents.api';
import { THIRTY_SECONDS } from 'common/constants';
import { useQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useDocumentDownloadQuery = (
  documentId: string,
  disposition?: string,
) => {
  return useQuery<{ url: string }, AxiosError>(
    `download-document-${documentId}`,
    () =>
      documentId
        ? downloadDocumentRequest(documentId, disposition).then(
            getDataFromResponse,
          )
        : Promise.reject(new Error('No document id provided')),
    {
      enabled: !!documentId,
      staleTime: THIRTY_SECONDS,
    },
  );
};
