import { queryClient } from 'app/queryClient';
import { deleteLSPRequest } from 'common/api/localServiceProviders.api';
import { useMutation } from 'react-query';

import { lspsKeys } from '../keys';

export const useDeleteLSPMutation = ({ lspId }: { lspId: string }) => {
  return useMutation(
    () => {
      return deleteLSPRequest(lspId);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(lspsKeys.single(lspId), {
          refetchInactive: true,
        });
        queryClient.invalidateQueries(lspsKeys.default, {
          refetchInactive: true,
        });
      },
    },
  );
};
