import { Seat } from 'common/types';
import { Reducer } from 'redux';

import {
  ADD_SEAT,
  asyncActionKeys,
  UPDATE_SEAT,
} from '../actions/redux.constants';

type State = {
  seats: Seat[];
};

const initialState = {
  seats: [],
};

export const seatsReducer: Reducer = (state: State = initialState, action) => {
  switch (action.type) {
    case asyncActionKeys.GET_SEATS_END:
      return {
        ...state,
        seats: action.payload,
      };
    case ADD_SEAT:
      return {
        seats: [...state.seats, action.payload],
      };
    case UPDATE_SEAT:
      return {
        seats: state.seats.map((seatInfo: Seat) => {
          let seat = { ...seatInfo };

          if (seat.id === action.payload.id) {
            seat = { ...seatInfo, ...action.payload };
          }

          return seat;
        }),
      };
    default:
      return state;
  }
};
