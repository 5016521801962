import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

const resourceLink = css`
  color: ${({ theme: { colors } }) => colors['text-default']};
  text-decoration: unset;
  &:hover {
    text-decoration: underline;
    cursor: pointer;

    button {
      background: ${tokens.color.button['background-secondary-focused']};
    }
  }

  button {
    & span {
      display: flex;
      align-items: center;
    }
  }
`;

export const ExternalLink = styled.a`
  ${resourceLink}
`;

export const InternalLink = styled(Link)`
  ${resourceLink}
`;
