import { useEffect, useRef, useState } from 'react';

import { ReactComponent as CopyIcon } from 'assets/icon/Copy.svg';
import { ReactComponent as DownloadIcon } from 'assets/icon/Download.svg';
import { LoadingSpinner } from 'common/components/atoms/LoadingSpinner/LoadingSpinner.styles';
import { copyToClipboard } from 'utils/copyToClipboard';

import { Button } from '@omnipresentgroup/design-system';

import { IconButton, Wrapper } from './DataExport.styles';

export interface Props {
  exportLink: string;
  getExportLink: () => void;
  clearExportLink: (data: string) => void;
}

const DataExport = ({ exportLink, getExportLink, clearExportLink }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    return () => clearExportLink('');
  }, [clearExportLink]);

  return (
    <Wrapper data-testid="data-export-component">
      {!isLoading && !exportLink && (
        <Button
          variant="secondary"
          size="small"
          data-testid="data-export-action"
          onClick={() => {
            setIsLoading(true);
            getExportLink();
          }}
        >
          Generate Link
        </Button>
      )}
      {isLoading && !exportLink && (
        <LoadingSpinner
          data-testid="data-export-loading-icon"
          style={{ marginLeft: 5 }}
        />
      )}
      {exportLink && (
        <>
          <input ref={inputRef} value={exportLink} readOnly />
          <IconButton
            data-testid="data-export-copy-button"
            onClick={() => {
              copyToClipboard(exportLink);
              inputRef?.current?.select();
            }}
          >
            <CopyIcon />
          </IconButton>
          <IconButton
            data-testid="data-export-download-button"
            onClick={() => window.open(exportLink)}
          >
            <DownloadIcon />
          </IconButton>
        </>
      )}
    </Wrapper>
  );
};

export default DataExport;
