import { useState } from 'react';

import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { X } from 'react-feather';

import { Inline, Typography } from '@omnipresentgroup/design-system';

import * as S from './ContractAmendment.styles';
import { ContractAmendmentsHistory } from './ContractAmendmentsHistory';
import { ContractAmendmentUrgencySelection } from './ContractAmendmentUrgencySelection';

export const ContractAmendments = ({
  contractDocumentId,
  setShowContractAmendments,
  setShowContractAmendmentForm,
}: {
  contractDocumentId: string;
  setShowContractAmendments: (arg: boolean) => void;
  setShowContractAmendmentForm: (arg: boolean) => void;
}) => {
  const [amendmentToDispatch, setAmendmentToDispatch] = useState('');

  const closeListAndOpenForm = () => {
    setShowContractAmendments(false);
    setShowContractAmendmentForm(true);
  };

  return (
    <div>
      <ModalWrapper
        fixedWidth="672"
        fixedHeight="400"
        onClose={() =>
          amendmentToDispatch ? null : setShowContractAmendments(false)
        }
        inLineCss={{ overflow: 'auto' }}
      >
        <div
          data-testid="contractamendments"
          role="none"
          onClick={(e) => e.stopPropagation()}
        >
          <S.FixedHeader>
            <Inline justify="space-between" mx="32" my="16">
              <Typography
                as="h1"
                hideParagraphSpacing
                size="24"
                weight="medium"
              >
                Contract edit request history
              </Typography>
              <S.ContainerClose>
                <X
                  data-testid="close-drawer"
                  onClick={() => {
                    setShowContractAmendments(false);
                  }}
                  size={24}
                  cursor="pointer"
                />
              </S.ContainerClose>
            </Inline>
          </S.FixedHeader>
          <ContractAmendmentsHistory
            setAmendmentToDispatch={setAmendmentToDispatch}
            closeListAndOpenForm={closeListAndOpenForm}
            contractDocumentId={contractDocumentId}
          />
          {amendmentToDispatch && (
            <ContractAmendmentUrgencySelection
              setAmendmentToDispatch={setAmendmentToDispatch}
              amendmentId={amendmentToDispatch}
              documentId={contractDocumentId}
            />
          )}
        </div>
      </ModalWrapper>
    </div>
  );
};
