/* eslint-disable react/forbid-prop-types */
import { StyledBodyCopy } from 'common/components/atoms/Text/Copy.styles';
import PropTypes from 'prop-types';
import { getIsRedactedValue } from 'utils/rbac.utils';

import { StyledDetailsValue } from './DetailsCardValue.styles';
import DetailsCardAddressType from './valuesByType/DetailsCardAddressType';
import DetailsCardDataExportType from './valuesByType/DetailsCardDataExportType';
import { DetailsCardDateType } from './valuesByType/DetailsCardDateType';
import DetailsCardInvitationType from './valuesByType/DetailsCardInvitationType';
import DetailsCardLinkType from './valuesByType/DetailsCardLinkType';
import DetailsCardLocationOfWorkType from './valuesByType/DetailsCardLocationOfWorkType';
import DetailsCardReceiptType from './valuesByType/DetailsCardReceiptType';
import DetailsCardRedactedType from './valuesByType/DetailsCardRedactedType';

const MAP_VALUE_COMPONENT_BY_TYPE = {
  date: DetailsCardDateType,
  month: DetailsCardDateType,
  receipt: DetailsCardReceiptType,
  address: DetailsCardAddressType,
  redacted: DetailsCardRedactedType,
  dataExport: DetailsCardDataExportType,
  invitation: DetailsCardInvitationType,
  locationOfWork: DetailsCardLocationOfWorkType,
  link: DetailsCardLinkType,
};

const chooseComponentTypeFromValue = ({ value, type }) => {
  return MAP_VALUE_COMPONENT_BY_TYPE[
    getIsRedactedValue(value) ? 'redacted' : type
  ];
};

const DetailsCardValue = ({
  type,
  value,
  label,
  displayValue,
  className,
  testId,
  ...rest
}) => {
  const ValueComponentByType = chooseComponentTypeFromValue({ value, type });
  return (
    <StyledDetailsValue className={className}>
      {ValueComponentByType ? (
        <ValueComponentByType
          value={value}
          displayValue={displayValue}
          {...rest}
        />
      ) : (
        <StyledBodyCopy data-testid={testId}>
          {displayValue || value}
        </StyledBodyCopy>
      )}
    </StyledDetailsValue>
  );
};

DetailsCardValue.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  displayValue: PropTypes.any,
  className: PropTypes.string.isRequired,
  testId: PropTypes.string,
};

DetailsCardValue.defaultProps = {
  value: null,
  displayValue: null,
  className: '',
};

export default DetailsCardValue;
