import { useHistory } from 'react-router-dom';

import TableWithTitle from 'common/components/organisms/TableWithTitle/TableWithTitle';
import { SimplifiedEmployee } from 'common/types';
import { TerminationRequestModel } from 'common/types/terminations';
import { VIEW_TERMINATION_REQUEST } from 'paths';
import { getCountryFlagByCountryName } from 'utils/countries';
import { useSelectUserRoles } from 'utils/hooks';

import { tableHeaders } from './terminationRequestsTableHeaders';

export type TerminationRequest = {
  id: string;
  name: string;
  jobTitle: string;
  companyId: string;
  employeeId: string;
  country: string;
  createdBy: string;
  status: string;
  documentIds: string[];
};

type TerminationRequestsTableProps = {
  employees: SimplifiedEmployee[];
  terminationRequestsData: TerminationRequestModel[];
};

export const TerminationRequestsTable = ({
  employees,
  terminationRequestsData,
}: TerminationRequestsTableProps) => {
  const terminationRequests: TerminationRequest[] = [];

  const history = useHistory();

  const { isCSAdmin } = useSelectUserRoles();

  const onTerminationRequestClicked = (
    companyId: string,
    employeeId: string,
    terminationRequestId: string,
  ) => {
    history.push(
      VIEW_TERMINATION_REQUEST(companyId, employeeId, terminationRequestId),
    );
  };

  employees.forEach((employee: SimplifiedEmployee) => {
    terminationRequestsData.some((termination: TerminationRequestModel) => {
      if (employee.id === termination.employeeId) {
        terminationRequests.push({
          id: termination.id,
          name: employee.name,
          jobTitle: employee.jobTitle,
          companyId: employee.managerId,
          employeeId: termination.employeeId,
          country: `${getCountryFlagByCountryName(employee.country)} ${
            employee.country
          }`,
          createdBy: termination.createdBy,
          status: termination.status,
          documentIds:
            termination.answers['terminationSupportDocumentation'] &&
            (termination.answers['terminationSupportDocumentation']
              .inputAnswers as string[]),
        });
      }
    });
  });

  return (
    <TableWithTitle
      testId="termination-requests"
      itemsToDisplay={terminationRequests}
      headers={tableHeaders(isCSAdmin)}
      getId={(item: { id: any }) => item.id}
      onColumnItemClick={onTerminationRequestClicked}
    />
  );
};
