import { FIELD_TYPE } from 'common/types';

export const reduceInputField = (result, field, addName) => {
  const addNameValue = addName ? addName : true;
  const fieldValue = field.value;

  switch (true) {
    case [FIELD_TYPE.CONDITIONAL_INPUT, FIELD_TYPE.NUMBER].includes(
      field.type,
    ): {
      return {
        ...result,
        [field.name]: fieldValue,
      };
    }

    case field.type === FIELD_TYPE.EMPLOYEE_CURRENCY: {
      if (addNameValue) {
        return {
          ...result,
          [`${field.name}Currency`]: field.value.currency || '',
          [`${field.name}Amount`]: field.value.amount || 0,
        };
      }
      return {
        ...result,
        currency: field.value.currency || '',
        amount: field.value.amount || 0,
      };
    }

    case field.type === FIELD_TYPE.FEE_CURRENCY: {
      return {
        ...result,
        [field.name]: field.value.amount || 0,
      };
    }

    case field.type === FIELD_TYPE.LOCATION_OF_WORK: {
      const address = field.value.address;
      const { hideAddressLines, hidePostalCode, ...addressValueWithoutFlags } =
        address;
      return {
        ...result,
        [`${field.name}Type`]: field.value.type,
        [`${field.name}Address`]: addressValueWithoutFlags,
      };
    }

    case field.type === FIELD_TYPE.TIME_PERIOD: {
      return {
        ...result,
        [`${field.name}Unit`]: field.value.timePeriod,
        [`${field.name}Length`]: field.value.length,
      };
    }
    case field.type === FIELD_TYPE.CURRENCY_TIME_PERIOD: {
      return {
        ...result,
        [`${field.name}Currency`]: field.value.currency,
        [`${field.name}Amount`]: field.value.amount,
        [`${field.name}Regularity`]: field.value.timePeriod,
      };
    }
    case field.type === FIELD_TYPE.CHECKBOX: {
      return {
        ...result,
        [field.name]: reduceFormCheckboxValue(field.value),
      };
    }
    case field.type === FIELD_TYPE.HOLIDAY_ALLOWANCE: {
      return {
        ...result,
        [`${field.name}Days`]: field.value.value,
        [`${field.name}Type`]: field.value.type,
      };
    }
    case [FIELD_TYPE.DROPDOWN, FIELD_TYPE.DEPENDENT_DROPDOWN].includes(
      field.type,
    ): {
      return {
        ...result,
        [field.name]: transformDropdownValue(field),
      };
    }

    case !fieldValue: {
      return {
        ...result,
      };
    }

    default:
      return {
        ...result,
        [field.name]: fieldValue,
      };
  }
};

export const reduceFormCheckboxValue = (value) =>
  value.find((option) => option.selected)?.value;

export const transformFormCheckBoxValue = (value) => [
  {
    label: 'Yes',
    value: true,
    selected: value,
  },
  {
    label: 'No',
    value: false,
    selected: !value,
  },
];
export const transformDropdownValue = (field) => {
  if (!field.value) {
    return field.value;
  }
  const optionList = field.options.reduce((acc, option) => {
    if (option.options) {
      return [...acc, ...option.options];
    }
    return [...acc, option];
  }, []);

  const response = optionList?.find((option) => {
    const valueAsString = new String(field?.value);
    return valueAsString.toLowerCase() === option.label.toLowerCase();
  });
  return response?.value;
};
