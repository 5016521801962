import { clientInvoicesClient } from 'api';
import { HTTPStatusCodesEnum } from 'common/constants';
import {
  InvoicesLogsQueryOptions,
  PaymentEntity,
  PaymentEntityResponse,
  PaymentVendorsResponse,
  PaymentVendorUpdate,
  PaymentVendorUpdateResponse,
} from 'common/types';
import { castArray } from 'lodash';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { DefaultBodyType, PathParams, rest } from 'msw';
import billingItemsClientView from 'omniplatform/admin/__mocks__/billingItemsClientView.json';
import billingItemsLspView from 'omniplatform/admin/__mocks__/billingItemsLspView.json';
import exportClientInvoices from 'omniplatform/admin/__mocks__/exportClientInvoices.json';
import { getMockedPaginatedBillingItems } from 'omniplatform/admin/__mocks__/getMockedPaginatedBillingItems';
import { getMockedPaginatedPayrolllInstances } from 'omniplatform/admin/__mocks__/getMockedPaginatedPayrolllInstances';
import importedBenefitsBillingsByClient from 'omniplatform/admin/__mocks__/importedBenefitsBillingsByClient.json';
import importedBenefitsBillings from 'omniplatform/admin/__mocks__/importedBenefitsBillingsstats.json';
import { filterMockInvoiceLogs } from 'omniplatform/admin/__mocks__/invoiceLogs.mock';
import mockEmployeeBillingItems from 'omniplatform/admin/__mocks__/mockEmployeeBillingItems.json';
import parseBenefitsBillings from 'omniplatform/admin/__mocks__/parseBenefitsBillings.json';
import parseEmployeeCostsResponse from 'omniplatform/admin/__mocks__/parseEmployeesCosts.json';
import parseInvoiceSpreadsheetErrors from 'omniplatform/admin/__mocks__/parseInvoiceSpreadsheetErrors.json';
import {
  mockPaymentEntity,
  mockPaymentVendorResponse1,
  mockPaymentVendorResponse2,
} from 'omniplatform/admin/__mocks__/paymentVendor.mock';

import { getMockedPaginatedNetsuiteCustomers } from './../../omniplatform/admin/__mocks__/paginatedNetsuiteCustomersMock';
import { mockPayrollInstance } from './../../omniplatform/admin/__mocks__/payrollInstance.mock';

const billingItemsViewMap: { [key: string]: DefaultBodyType } = {
  client: billingItemsClientView,
  lsp: billingItemsLspView,
};

export const payrollHandlers = [
  rest.post(composeEndpoint('payroll/invoices'), (req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.RESOURCE_CREATED), ctx.json({})),
  ),
  rest.post(composeEndpoint('/payroll/benefits-invoice'), (req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.RESOURCE_CREATED), ctx.json({})),
  ),

  rest.post(
    composeEndpoint('/payroll/payroll-instances', clientInvoicesClient),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockPayrollInstance)),
  ),

  rest.get(composeEndpoint('/payroll/billing-items'), (req, res, ctx) => {
    const view = req.url.searchParams.get('view') || '';
    return res(
      ctx.status(HTTPStatusCodesEnum.OK),
      ctx.json(billingItemsViewMap[view] || []),
    );
  }),
  rest.post(composeEndpoint('/payroll/billing-items/export'), (req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(exportClientInvoices)),
  ),
  rest.delete(composeEndpoint('/payroll/invoices/*'), (req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json({})),
  ),
  rest.get(
    composeEndpoint('/payroll/billing-items/paginated'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(getMockedPaginatedBillingItems()),
      ),
  ),
  rest.get(
    composeEndpoint('/payroll/billing-items/employee/:employeeId'),
    (req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({ data: mockEmployeeBillingItems }),
      );
    },
  ),
  rest.get(composeEndpoint('/payroll/invoices/fx-rate'), (req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.OK)),
  ),
  rest.get(
    composeEndpoint('/payroll/ifx-exchange-rates/export-to-csv'),
    (req, res, ctx) => res(ctx.status(HTTPStatusCodesEnum.OK)),
  ),
  rest.post(
    composeEndpoint('/payroll/invoices/parse-employees-costs'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(parseEmployeeCostsResponse),
      ),
  ),
  rest.post(
    composeEndpoint('/payroll/invoices/parse-employees-costs'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.BAD_REQUEST),
        ctx.json(parseInvoiceSpreadsheetErrors),
      ),
  ),
  rest.post(composeEndpoint('/payroll/workbook/import'), (req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.OK)),
  ),
  rest.post(composeEndpoint('/payroll/workbook/import'), (req, res, ctx) =>
    res(
      ctx.status(HTTPStatusCodesEnum.BAD_REQUEST),
      ctx.json({ message: 'no valid records found to parse' }),
    ),
  ),
  rest.get(
    composeEndpoint('/payroll/payroll-instances', clientInvoicesClient),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(getMockedPaginatedPayrolllInstances()),
      ),
  ),
  rest.get(
    composeEndpoint(
      '/payroll/payroll-instances/:payrollInstanceId',
      clientInvoicesClient,
    ),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockPayrollInstance)),
  ),
  rest.post(
    composeEndpoint(
      '/payroll/payroll-instances/:payrollInstanceId/validate',
      clientInvoicesClient,
    ),
    (req, res, ctx) => res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json({})),
  ),
  rest.post(
    composeEndpoint(
      '/payroll/payroll-instances/:payrollInstanceId/generate',
      clientInvoicesClient,
    ),
    (req, res, ctx) => res(ctx.status(HTTPStatusCodesEnum.OK)),
  ),
  rest.post(
    composeEndpoint(
      '/payroll/payroll-instances/:payrollInstanceId/export',
      clientInvoicesClient,
    ),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockPayrollInstance)),
  ),

  rest.post(composeEndpoint('/payments/export'), (req, res, ctx) => {
    return res(
      ctx.status(HTTPStatusCodesEnum.OK),
      ctx.json({ url: 'mockExportUrl' }),
    );
  }),
  rest.post<InvoicesLogsQueryOptions>(
    composeEndpoint('/payroll/invoice-logs'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(filterMockInvoiceLogs(req.body || {})),
      ),
  ),

  rest.post<{ fileName: string }>(
    composeEndpoint('/payroll/benefits/import'),
    (req, res, ctx) => {
      const { fileName } = req.body;

      if (fileName.includes('bad_data')) {
        return res(
          ctx.status(HTTPStatusCodesEnum.BAD_REQUEST),
          ctx.json({
            errors: [
              'The employee in row 1 is not found in the database',
              'There is a name mismatch in row 1',
              'The employee client in row 1 is not found in the database',
            ],
          }),
        );
      }

      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(parseBenefitsBillings),
      );
    },
  ),

  rest.get<{ payrollInstanceId: string }>(
    composeEndpoint('/payroll/benefits/import'),
    (req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(importedBenefitsBillings),
      );
    },
  ),

  rest.get<{ payrollInstanceId: string }>(
    composeEndpoint('/payroll/benefits/import/client/:clientId'),
    (req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(importedBenefitsBillingsByClient),
      );
    },
  ),

  rest.get(composeEndpoint('/invoices/customers'), (req, res, ctx) =>
    res(
      ctx.status(HTTPStatusCodesEnum.OK),
      ctx.json(getMockedPaginatedNetsuiteCustomers()),
    ),
  ),
  rest.get<PaymentVendorsResponse>(
    composeEndpoint('/payments/vendors'),
    (req, res, ctx) => {
      const query = req.url.searchParams;
      const nextPageKey = query.get('nextPageKey');
      if (nextPageKey) {
        return res(
          ctx.status(HTTPStatusCodesEnum.OK),
          ctx.json(mockPaymentVendorResponse2),
        );
      } else {
        return res(
          ctx.status(HTTPStatusCodesEnum.OK),
          ctx.json(mockPaymentVendorResponse1),
        );
      }
    },
  ),
  rest.patch<PaymentVendorUpdate, PathParams, PaymentVendorUpdateResponse>(
    composeEndpoint('/payments/vendors/:id'),
    (req, res, ctx) => {
      const { body } = req;
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(body));
    },
  ),
  rest.get<PaymentEntityResponse>(
    composeEndpoint('/payments/vendors/entities/lsp'),
    (req, res, ctx) => {
      const query = req.url.searchParams;
      const entityIds = castArray(query.get('entity-id-list[]') || []);
      // the api will return null in case such an entity is not found, this will mock that behaviour
      // by only returning entities for which there is a mock
      const response = entityIds.reduce<PaymentEntity[]>((acc, id) => {
        return id === mockPaymentEntity?.id
          ? [...acc, mockPaymentEntity]
          : [...acc, null];
      }, []);
      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),
];
