import { asyncActions, makeActionKeys } from 'utils/redux-utils';

const adminActionKeys = makeActionKeys([
  ...asyncActions('GET', 'COMPANIES'),
  ...asyncActions('GET', 'COMPANY'),
  ...asyncActions('GET', 'COMPANY_EMPLOYEES'),
  ...asyncActions('GET', 'COMPANY_EMPLOYEE'),
  ...asyncActions('CREATE', 'COMPANY'),
  ...asyncActions('UPDATE', 'COMPANY'),
  ...asyncActions('GET', 'COMPANY_MANAGERS'),
  ...asyncActions('GET', 'COMPANY_MANAGER'),
  ...asyncActions('UPDATE', 'COMPANY_MANAGER'),
  ...asyncActions('CREATE', 'COMPANY_MANAGER'),
]);

export const CLEAR_COMPANIES = 'CLEAR_COMPANIES';
export const CLEAR_COMPANY = 'CLEAR_COMPANY';
export const CLEAR_COMPANY_CREATED = 'CLEAR_COMPANY_CREATED';
export const CLEAR_CREATED_COMPANY_MANAGER_USER_ID =
  'CLEAR_CREATED_COMPANY_MANAGER_USER_ID';

export const CLEAR_COMPANY_EMPLOYEES = 'CLEAR_COMPANY_EMPLOYEES';
export const CLEAR_COMPANY_EMPLOYEE = 'CLEAR_COMPANY_EMPLOYEE';

export default adminActionKeys;
