import {
  asyncActionKeys,
  CLEAR_EMPLOYEE_CREATED,
  CLEAR_SELECTED_EMPLOYEE,
  UPDATE_COMPENSATION,
  UPDATE_DEPARTMENT,
  UPDATE_EMPLOYEE_PATCH,
} from './redux.constants';

export const getManagersEmployeesAction = (payload) => ({
  type: asyncActionKeys.GET_MANAGERS_EMPLOYEES_START,
  payload,
});

export const getEmployeeAction = (payload) => ({
  type: asyncActionKeys.GET_EMPLOYEE_START,
  payload,
});

export const clearSelectedEmployeeAction = () => ({
  type: CLEAR_SELECTED_EMPLOYEE,
});

export const updateEmployeeAction = (
  payload,
  isBankDetailsOrAddressFieldUpdated,
  isSpainExpressOnboardingFlowActive,
) => {
  return {
    type: asyncActionKeys.UPDATE_EMPLOYEE_START,
    payload,
    isBankDetailsOrAddressFieldUpdated,
    isSpainExpressOnboardingFlowActive,
  };
};

export const addEmployeeAction = (payload) => ({
  type: asyncActionKeys.CREATE_EMPLOYEE_START,
  payload,
});

export const clearEmployeeCreatedAction = () => ({
  type: CLEAR_EMPLOYEE_CREATED,
});

export const getDataExportAction = (payload) => ({
  type: asyncActionKeys.GET_DATA_EXPORT_START,
  payload,
});

export const updateDataExportAction = (payload) => ({
  type: asyncActionKeys.GET_DATA_EXPORT_END,
  payload,
});

export const updateEmployeeEmploymentFAQAction = (payload) => ({
  type: asyncActionKeys.UPDATE_EMPLOYEE_EMPLOYMENT_FAQ_START,
  payload,
});

export const updateCompesationAction = (infoToUpdate) => ({
  type: UPDATE_COMPENSATION,
  payload: { ...infoToUpdate },
});

export const updateDepartmentAction = (infoToUpdate) => ({
  type: UPDATE_DEPARTMENT,
  payload: { ...infoToUpdate },
});

export const updateEmployeePatchAction = (payload) => ({
  type: UPDATE_EMPLOYEE_PATCH,
  payload,
});
