import { useCallback, useEffect } from 'react';

import { PopupButton } from '@typeform/embed-react';
import { useBenefitsClientPreferencesMutation } from 'utils/queries/benefits/useBenefitsPreferences';

import { Button, omniConfig } from '@omnipresentgroup/design-system';

import { useSelectUser } from '../../../../utils/hooks';
import { useBenefitsSelectionContext } from './BenefitsSelectionContext';
import { ButtonsRow } from './BenefitsStep.styles';
import { getBenefitsTypeformId } from './getTypeformId';
import { changeTypeformUrl } from './TypeFormPopupUtil';

export const TYPEFORM_MODAL_SIZE = 80;

export const BenefitsSelectionButtons = ({
  onSubmit,
  text,
  testId,
}: {
  onSubmit: () => void;
  text: string;
  testId: string;
}) => {
  const {
    countryCode,
    companyId,
    companyName,
    clientPreferences,
    countryData,
    seat,
  } = useBenefitsSelectionContext();
  const user = useSelectUser();
  const { mutate: createClientPreferences } =
    useBenefitsClientPreferencesMutation();

  useEffect(() => {
    countryData.isRequired && clientPreferences.setSelectedOptionToSave('YES');
  }, [clientPreferences, countryData.isRequired]);

  const submitAndSavePreferences = useCallback(() => {
    if (!clientPreferences.selectedOptionToSave) {
      return;
    }
    onSubmit();

    createClientPreferences({
      countryCode,
      clientId: companyId,
      selectionStatus: clientPreferences.selectedOptionToSave,
    });
  }, [
    clientPreferences.selectedOptionToSave,
    companyId,
    countryCode,
    createClientPreferences,
    onSubmit,
  ]);

  const { firstName, lastName, id: seatId } = seat;

  const typeFormId = getBenefitsTypeformId(seatId);

  const hiddenFields = {
    company_name: companyName || 'company name not found',
    employee_name: `${firstName} ${lastName}`,
    manager_name: `${user?.firstName} ${user?.lastName}`,
    manager_email: user?.email,
    external_id: seatId,
  };

  const SetupBenefitsButton = () => (
    <PopupButton
      buttonProps={{ 'data-testid': testId }}
      onSubmit={submitAndSavePreferences}
      id={typeFormId}
      size={TYPEFORM_MODAL_SIZE}
      hidden={hiddenFields}
      style={{
        border: 0,
        cursor: 'pointer',
        minHeight: omniConfig.theme.space[16],
        borderRadius: omniConfig.theme.radii.s,
        backgroundColor: omniConfig.theme.colors['gray-500'],
        color: omniConfig.theme.colors['white-base'],
        padding: `${omniConfig.theme.space[8]} ${omniConfig.theme.space[16]}`,
        fontFamily: omniConfig.theme.fonts.default,
        fontSize: omniConfig.theme.fontSizes[1],
      }}
      onReady={() => changeTypeformUrl(countryData.typeFormRef || '')}
    >
      {text}
    </PopupButton>
  );
  const showTypeformButton =
    !clientPreferences.populated &&
    clientPreferences.selectedOptionToSave === 'YES';

  if (showTypeformButton) {
    return (
      <ButtonsRow>
        <SetupBenefitsButton />
      </ButtonsRow>
    );
  }

  const nextStepIsEnabled =
    clientPreferences.populated || clientPreferences.selectedOptionToSave;

  return (
    <Button onClick={submitAndSavePreferences} disabled={!nextStepIsEnabled}>
      Next
    </Button>
  );
};
