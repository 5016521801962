import { StyledTitleCell } from 'common/components/organisms/TableWithTitle/TableWithTitle.styles';

const CompanyCustomCell = (manager) => {
  const { companyName } = manager;
  return <StyledTitleCell>{companyName}</StyledTitleCell>;
};

export const headers = [
  {
    headerTitle: 'Company',
    customCell: CompanyCustomCell,
    fieldOnObject: 'companyName',
  },
  {
    headerTitle: 'Country of registration',
    fieldOnObject: 'countryOfRegistration',
  },
  {
    headerTitle: 'Number of employees',
    fieldOnObject: 'noOfEmployeesTable',
  },
];
