import { Camera } from 'react-feather';
import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledConfirmEmployeeSeatPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;

  @media (max-width: ${tokens.breakpoint.laptop}) {
    margin-left: 0;
    width: 100%;
  }
`;

export const StyledSectionContainer = styled.div`
  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-left: 0;
    width: 100vw;
  }
`;

export const StyledReviewContainerWrapper = styled.div`
  background: ${({ theme: { colors } }) => `${colors['background-primary']}`};
  padding: ${({ theme: { space } }) => `${space[32]}`};
  display: flex;
  flex-grow: 1;

  @media (max-width: ${tokens.breakpoint.laptop}) {
    width: auto;
    margin: ${({ theme: { space } }) => `${space[8]} 0`};
    padding: ${({ theme: { space } }) =>
      `${space[16]} ${space[16]} ${space[16]} ${space[16]}`};
    flex-direction: column;
  }
`;

export const StyledReviewContainer = styled.div`
  box-sizing: border-box;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-left: 0;
  }
`;

export const StyledOrderFormContainer = styled.div`
  box-sizing: border-box;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-left: 0;
  }
`;

export const StyledTabsContainer = styled.div`
  flex-basis: 130%;
  padding-right: ${({ theme: { space } }) => `${space[32]}`};
  border-right: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin-left: 0;
    flex-basis: 0;
  }
`;

export const StyledConfirmationSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: baseline;
  padding: ${({ theme: { space } }) =>
    `${space[16]} 0 ${space[16]} ${space[32]}`};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    border: ${({ theme: { borderStyles } }) => borderStyles.none};
    padding: 0;
  }
`;

export const StyledAcceptedDetails = styled.div``;

export const StyledAcceptedText = styled.p`
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.regular}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  color: ${({ theme: { colors } }) => `${colors['text-subtle']}`};
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ScrollableTabTextContainer = styled.div`
  overflow-y: auto;
  max-height: 990px;
  align-self: baseline;
`;

export const StyledTabTitle = styled.h3`
  color: ${({ theme: { colors } }) => `${colors['text-default']}`};
  margin: 0;
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[1]}`};
`;

export const StyledCollapsibleContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme: { space } }) => `${space[16]}`};
  gap: ${({ theme: { space } }) => `${space[16]}`};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    padding: ${({ theme: { space } }) => `${space[16]} 0`};
  }
`;

export const StyledDisclaimerCopy = styled.p`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  color: ${({ theme: { colors } }) => `${colors['text-default']}`};
  margin-top: ${({ theme: { space } }) => `${space[8]}`};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.regular}`};
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[1]}`};
  margin-bottom: 0;
`;

export const StyledSubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme: { space } }) => `${space[16]}`};

  > * {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme: { space } }) => `${space[16]}`};

  @media (max-width: ${tokens.breakpoint.tablet}) {
    gap: ${({ theme: { space } }) => `${space[8]}`};
  }

  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
`;

export const StyledTitle = styled.p`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.bold}`};
  color: ${({ theme: { colors } }) => `${colors['text-default']}`};
  margin: 0;
`;

export const SmallMediumSpanDescription = styled.p`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[1]}`};
  color: ${({ theme: { colors } }) => `${colors['text-default']}`};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.regular}`};
  list-style: none;
  margin-top: ${({ theme: { space } }) => `${space[4]}`};
  margin-bottom: ${({ theme: { space } }) => `${space[4]}`};
`;

export const SmallMediumUnorderedList = styled.span`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[1]}`};
  color: ${({ theme: { colors } }) => `${colors['text-default']}`};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.regular}`};
`;

export const StyledTableApprovalKey = styled.td`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.regular}`};
  border-bottom: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  width: 40%;
  margin: 0;
  color: ${({ theme: { colors } }) => `${colors['text-subtle']}`};
  padding: ${({ theme: { space } }) => `${space[4]}`}
    ${({ theme: { space } }) => `${space[4]}`}
    ${({ theme: { space } }) => `${space[4]}`}
    ${({ theme: { space } }) => `${space[4]}`};
`;

export const StyledTableApprovalValue = styled(StyledTableApprovalKey)`
  margin-right: 0;
  text-align: right;
  width: 30%;
  color: black;
`;

export const StyledCameraIcon = styled(Camera)`
  padding: ${({ theme: { space } }) =>
    `${space[8]} 0 ${space[8]} ${space[32]}`};
`;
