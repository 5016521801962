import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { subject } from '@casl/ability';
import { useAppAbility } from 'app/store/ability';
import AddDocumentPage from 'common/pages/AddDocumentPage/AddDocumentPage';
import {
  createDocumentAction,
  getDocumentsAction,
} from 'common/store/actions/documents.actions';
import {
  clearSelectedEmployeeAction,
  getEmployeeAction,
} from 'common/store/actions/employee.actions';
import { getDocumentsSelector } from 'common/store/selectors/document.selectors';
import { getSelectedEmployeeSelector } from 'common/store/selectors/employees.selector';
import { EMPLOYEES_PATH } from 'paths';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AddEmployeeDocumentPage = ({
  employee,
  addDocument,
  getEmployee,
  documents,
  getDocuments,
  clearEmployee,
}) => {
  const { employeeId } = useParams();
  const ability = useAppAbility();

  const canAddDocument = ability.can(
    'documents:create',
    subject('Documents', { ownerId: employee.userId }),
  );

  useEffect(() => {
    if (!employee?.userId) {
      getEmployee(employeeId);
    }
  }, [employeeId, employee, getEmployee, clearEmployee]);

  useEffect(() => {
    if (!documents && employee.userId) {
      getDocuments({ userId: employee.userId });
    }
  }, [documents, employee, getDocuments]);

  const onDocumentFormSubmitted = (documentInfo) =>
    addDocument({
      documentInfo,
      tenantId: employee.managerId,
      userId: employee.userId,
      returnPath: `${EMPLOYEES_PATH}/${employeeId}#documents`,
    });
  return (
    <AddDocumentPage
      onDocumentFormSubmitted={onDocumentFormSubmitted}
      canAddDocument={canAddDocument}
    />
  );
};

AddEmployeeDocumentPage.propTypes = {
  employee: PropTypes.oneOfType([PropTypes.object]).isRequired,
  addDocument: PropTypes.func.isRequired,
  getEmployee: PropTypes.func.isRequired,
  clearEmployee: PropTypes.func.isRequired,
  getDocuments: PropTypes.func.isRequired,
  documents: PropTypes.array,
};

AddEmployeeDocumentPage.defaultProps = {
  documents: undefined,
};

const mapStateToProps = (state) => ({
  employee: getSelectedEmployeeSelector(state),
  documents: getDocumentsSelector(state),
});

const mapDispatchToProps = {
  addDocument: createDocumentAction,
  getDocuments: getDocumentsAction,
  getEmployee: getEmployeeAction,
  clearEmployee: clearSelectedEmployeeAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEmployeeDocumentPage);
