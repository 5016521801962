import PropTypes from 'prop-types';

import { StyledHeaderRow, StyledTableHeader } from './Table.styles';

const TableHeader = ({ headers }) => (
  <thead>
    <StyledHeaderRow data-testid="table-header">
      {headers.map(({ headerTitle, align, id }) => (
        <StyledTableHeader key={headerTitle || id} align={align}>
          {headerTitle}
        </StyledTableHeader>
      ))}
    </StyledHeaderRow>
  </thead>
);

TableHeader.propTypes = {
  headers: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default TableHeader;
