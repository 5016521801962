import { queryClient } from 'app/queryClient';
import { updateLSPRequest } from 'common/api/localServiceProviders.api';
import { transformLspToApiRequest } from 'common/store/transformers/lsps.transformer';
import { LSP } from 'common/types';
import { useMutation } from 'react-query';

import { lspsKeys } from '../keys';

export const useUpdateLSPMutation = (lspId: string) => {
  return useMutation(
    (lsp: Partial<LSP>) => {
      return updateLSPRequest(lspId, transformLspToApiRequest(lsp));
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(lspsKeys.single(lspId), {
          refetchInactive: true,
        });
        queryClient.invalidateQueries(lspsKeys.default, {
          refetchInactive: true,
        });
      },
    },
  );
};
