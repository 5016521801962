import { PaginatedNetSuiteCustomersApiResponse } from 'common/types';

const paginatedNetsuiteCustomersResponse: PaginatedNetSuiteCustomersApiResponse =
  {
    customers: [
      {
        id: '13cdc3d8-d605-4b3e-b334-5166dc939045',
        entityId: '5WMzmW7nXS7V',
        entityType: 'client',
        netSuiteId: '30',
        netSuiteType: 'ns_customer',
        company: {
          name: 'Ana Company',
          externalId: 'AC',
        },
      },
      {
        id: '63423f94-b014-4653-a38a-8aaf25943592',
        entityId: 'jzgO9IZX6aFo',
        entityType: 'client',
        netSuiteId: '4068',
        netSuiteType: 'ns_customer',
        company: {
          name: 'Roman Test Company',
          externalId: 'RTC',
        },
      },
    ],
    nextPageKey: 'eyJpZCI6IjYzNDIzZjk0LWIwMTQtNDY1My1hMzhhLThhYWYyNTk0MzU5MiJ9',
  };

export const getMockedPaginatedNetsuiteCustomers = () => {
  return {
    nextPageKey: paginatedNetsuiteCustomersResponse.nextPageKey,
    customers: paginatedNetsuiteCustomersResponse.customers.map((item) => ({
      ...item,
    })),
  };
};
