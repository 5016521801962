import { useState } from 'react';

import { Typography } from '@omnipresentgroup/design-system';

import { ConfirmationPopup } from '../../../components/molecules/ConfirmationPopup/ConfirmationPopup';
import { BenefitsCountrySelectionState } from '../../../types';
import { StyledCheckboxInput } from './TypeFormPopup.styles';

export const CONFIRMATION_MAP: Record<BenefitsCountrySelectionState, string> = {
  YES: 'Are you sure? Your choice will be saved and applied for the future employments.',
  NO: 'Are you sure? Your choice will be saved and will apply to all your future hires in this country. You can always contact us if you change your mind later.',
  REMIND_LATER:
    'You will be able to configure supplementary benefits later and get a cost estimate before agreeing to them. The cost of these benefits will not be included in your initial cost proposal.',
};

export const CONSENT_MAP: { [key: string]: string } = {
  REMIND_LATER:
    'I understand I can configure supplementary benefits at a later stage and that these come at an extra cost.',
};

export const SelectionConfirmationPopup = ({
  onConfirm,
  close,
  selectedOption,
}: {
  onConfirm: () => void;
  close: () => void;
  selectedOption: BenefitsCountrySelectionState | null;
}) => {
  const [checkBoxSelected, setCheckBoxSelected] = useState(false);

  const confirmationPopupHasConsent = !!CONSENT_MAP[selectedOption as string];

  if (!selectedOption) {
    return null;
  }

  return (
    <ConfirmationPopup
      title="Confirm your choice"
      onConfirm={() => {
        onConfirm();
        close();
      }}
      onCancel={close}
      copy={
        <div className="stack">
          <Typography as="span" size="16">
            {CONFIRMATION_MAP[selectedOption]}
          </Typography>
          {confirmationPopupHasConsent && (
            <StyledCheckboxInput
              label={CONSENT_MAP[selectedOption]}
              checked={checkBoxSelected}
              name="typeform-consent-checkbox"
              id="typeform-consent-checkbox-id"
              onChange={() => setCheckBoxSelected(!checkBoxSelected)}
            />
          )}
        </div>
      }
      confirmationDisabled={!checkBoxSelected && confirmationPopupHasConsent}
    />
  );
};
