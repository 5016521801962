import { StyledInput } from 'common/components/atoms/Input';
import { LoadingSpinner } from 'common/components/atoms/LoadingSpinner/LoadingSpinner.styles';
import styled, { css } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

type ColumnProps = {
  width?: string;
};

type CardProps = {
  padding?: string;
  marginTop?: string;
};

type PaginationButtonProps = {
  disabled?: boolean;
};

export const StyledContainer = styled.div(
  ({ theme: { space } }) => css`
    width: 100%;
    height: 100%;

    @media (max-width: ${tokens.breakpoint.tablet}) {
      margin-top: ${space[8]};
    }
  `,
);

export const StyledColumnsContainer = styled.div(
  () => css`
    display: flex;
    width: 100%;
    gap: 24px;

    @media (max-width: ${tokens.breakpoint.laptop}) {
      flex-flow: column-reverse;
    }
  `,
);

export const StyledColumn = styled.div<ColumnProps>`
  width: calc(${(props) => props.width || '50%'} - 1%);
  margin-top: ${({ theme: { space } }) => space[32]};
  flex-shrink: 0;
  flex-grow: 0;

  @media (max-width: ${tokens.breakpoint.laptop}) {
    width: 100%;
  }
`;

export const StyledCard = styled.div<CardProps>`
  background: ${({ theme: { colors } }) => colors['background-primary']};
  border-radius: ${({ theme: { radii } }) => radii.s};
  box-shadow: ${({ theme: { shadows } }) => shadows.s};
  padding: ${(props) => props.padding || (({ theme: { space } }) => space[64])};
  word-break: break-all;
  margin-top: ${({ theme: { space } }) => space[32]};

  :hover {
    box-shadow: ${({ theme: { shadows } }) => shadows.m};
  }
`;

export const ResourcesContainer = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    gap: ${({ theme: { space } }) => space[16]};
    width: 100%;

    @media (max-width: ${tokens.breakpoint.laptop}) {
      max-width: 100%;
    }
  `,
);

export const StyledSearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    gap: ${({ theme: { space } }) => space[32]};

    & > input {
      width: 100%;
    }

    & > button {
      min-width: fit-content;

      div {
        justify-content: center;
      }
    }
  }

  & svg {
    position: absolute;
    left: ${({ theme: { space } }) => space[16]};
    top: 43px;
  }

  @media (max-width: ${tokens.breakpoint.laptop}) {
    & > div {
      flex-direction: column;

      & > input {
        width: auto;
      }
    }
  }
`;

export const StyledSearchInput = styled(StyledInput)`
  width: auto;
  padding: ${({ theme: { space } }) =>
    `${space[8]} ${space[16]} ${space[8]} ${space[64]}`};
  border-radius: ${({ theme: { radii } }) => radii.s};
`;

export const StyledlocationSubItem = styled.div`
  max-width: 80%;
  display: flex;
  align-items: center;

  & span[role='img'] {
    padding-right: ${({ theme: { space } }) => space[8]};

    & > svg {
      height: ${({ theme: { space } }) => space[16]};
      width: ${({ theme: { space } }) => space[16]};
    }
  }
`;

export const StyledBodyText = styled.p`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[2]}`};
  color: ${({ theme: { colors } }) => `${colors['text-default']}`};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.medium}`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

export const StyledLabel = styled.label<{ required?: boolean }>`
  color: ${({ theme: { colors } }) => colors['text-subtle']};
  padding: ${({ theme: { space } }) => `${space[4]} ${space[4]} ${space[4]} 0`};
  margin-bottom: ${({ theme: { space } }) => space[8]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::after {
    content: ' *';
    display: ${(props) => (props.required ? 'inline' : 'none')};
  }
`;

export const StyledEmployeesCountItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme: { space } }) => space[16]};
  margin-bottom: ${({ theme: { space } }) => space[16]};
  border-bottom: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};

  &:last-child {
    border: unset;
    padding: 0;
    margin: 0;
  }
`;

export const StyledEmployeesCountContainer = styled.div`
  padding: ${({ theme: { space } }) => `${space[32]} ${space[0]}`};
`;

export const StyledPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  * {
    margin-bottom: 0;
  }
`;

export const StyledPaginationButton = styled.button<PaginationButtonProps>`
  height: 41px;
  width: 41px;
  border-radius: ${({ theme: { radii } }) => radii.s};
  border: ${({ theme: { borderWidths, borderStyles, colors } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-strong']}`};
  color: ${({ theme: { colors } }) => colors['gray-300']};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  margin: ${({ theme: { space } }) => `${space[0]} ${space[8]}`};
  background: ${({ theme: { colors } }) => colors['white-base']};

  &:last-child {
    margin: 0;
  }

  &:not([disabled]) {
    cursor: pointer;
    color: ${({ theme: { colors } }) => colors['text-default']};
    border-color: ${({ theme: { colors } }) => colors['border-inverse']};
  }
`;

export const EmployeesLoader = styled(LoadingSpinner)`
  margin: ${({ theme: { space } }) => space[32]} auto;
`;

export const FiltersContainer = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    gap: ${({ theme: { space } }) => space[16]};
    width: 100%;
    margin-bottom: ${({ theme: { space } }) => space[32]};

    @media (max-width: ${tokens.breakpoint.laptop}) {
      max-width: 100%;
    }
  `,
);

export const CountryCardContainer = styled.div(
  () => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @media (max-width: ${tokens.breakpoint.desktop}) {
      grid-template-columns: 1fr;
    }
  `,
);

export const ItemFilterContainer = styled.div`
  display: flex;
  min-width: 100%;
  max-width: 100%;

  > div {
    flex: 1;
    width: 50%;
  }
`;

export const filterInputLabel = styled.div(
  ({ theme: { fontSizes, colors, space } }) => css`
    font-size: ${fontSizes[1]};
    color: ${colors['text-subtle']};
    margin-bottom: ${space[8]};
    padding: 1px ${space[8]} 0 1px;
    display: flex;
    justify-content: space-between;

    @media (min-width: ${tokens.breakpoint.laptop}) and (max-width: ${tokens
        .breakpoint.desktop}) {
      display: block;
    }
  `,
);

export const filterInputDescription = styled.span(
  ({ theme: { fontSizes, space, fontWeights, colors } }) => css`
    font-size: ${fontSizes[1]};
    color: ${colors['text-subtle']};
    font-weight: ${fontWeights.regular};
    margin-bottom: ${space[8]};
    padding-right: ${space[8]};
    display: flex;
    justify-content: space-between;
  `,
);

export const resetButton = styled.button(
  ({ theme: { fontSizes, colors, borderStyles } }) => css`
    font-size: ${fontSizes[1]};
    color: ${colors['blue-500']};
    background: ${colors['white-base']};
    border: ${borderStyles.none};
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
  `,
);

export const StyledTerritoriesWrapper = styled.div`
  display: grid;
`;

export const CountriesLoader = styled(LoadingSpinner)`
  margin: ${({ theme: { space } }) => space[64]} auto;
`;

export const EmptyStateContainer = styled.div`
  > div {
    padding: 0;
  }

  & p {
    margin: 0;
  }
`;
