import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { downloadDocumentAction } from '../../../store/actions/documents.actions';
import Button from '../../atoms/Button/Button';

const DownloadDocumentButton = ({ documentId }) => {
  const dispatch = useDispatch();
  return (
    <Button
      variant="link"
      palette="secondary"
      onClick={() => dispatch(downloadDocumentAction(documentId))}
    >
      Download
    </Button>
  );
};

DownloadDocumentButton.propTypes = {
  documentId: PropTypes.string.isRequired,
};

export default DownloadDocumentButton;
