import { forwardRef } from 'react';

import PropTypes from 'prop-types';

import { convertToRem } from '@omnipresentgroup/design-system';

import {
  StyledCheckbox,
  StyledCheckboxWrapper,
  StyledIcon,
  StyledInput,
  StyledMinusIcon,
} from './Checkbox.styles';

const CheckboxPropTypes = {
  id: PropTypes.string,
  size: PropTypes.number,
};

const CheckboxDefaultProps = {
  id: 'checkbox-input',
  size: 16,
};

export const Checkbox = forwardRef((props, ref) => {
  const convertedSize = props.size ? convertToRem(props.size) : undefined;
  const mergedProps = {
    ...props,
    size: convertedSize,
  };
  return (
    <StyledCheckboxWrapper>
      <StyledInput ref={ref} data-testid={props.id} {...mergedProps} />
      <StyledCheckbox size={convertedSize}>
        <StyledIcon />
        <StyledMinusIcon />
      </StyledCheckbox>
    </StyledCheckboxWrapper>
  );
});

Checkbox.propTypes = CheckboxPropTypes;
Checkbox.defaultProps = CheckboxDefaultProps;
Checkbox.displayName = 'Checkbox';
