import { AxiosError } from 'axios';
import { getContractAmendments } from 'common/api/contractAmendment.api';
import { ContractAmendment } from 'common/types';
import { useQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useContractAmendmentQuery = (documentId: string) => {
  return useQuery<ContractAmendment[], AxiosError>(
    ['amendments', 'documentId', documentId],
    () => getContractAmendments(documentId).then(getDataFromResponse),
    {
      enabled: !!documentId,
    },
  );
};
