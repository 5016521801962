import styled from 'styled-components';

import { StyledCard } from '../ManagerAtlasPage.styles';

export const StyledCountryCard = styled(StyledCard)`
  padding: ${({ theme: { space } }) => space[16]};
  position: relative;
`;

export const StyledCountryImage = styled.img`
  height: 151px;
  width: 100%;
  object-fit: cover;
`;

export const StyledCountryEmployees = styled.span`
  background: ${({ theme: { colors } }) => colors['blue-100']};
  color: #${({ theme: { colors } }) => colors['blue-500']};
  position: absolute;
  border-radius: ${({ theme: { radii } }) => radii.s};
  padding: ${({ theme: { space } }) => `${space[4]} ${space[8]}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  top: 24px;
  left: 24px;
`;

export const StyledCountryFlag = styled.span`
  background: ${({ theme: { colors } }) => colors['background-primary']};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[6]};
  object-fit: cover;
  padding: ${({ theme: { space } }) => `${space[4]} ${space[8]}`};
  position: absolute;
  border-radius: ${({ theme: { radii } }) => radii.full};
  top: 131px;
  right: ${({ theme: { space } }) => space[16]};
  box-shadow: ${({ theme: { shadows } }) => shadows.s};
`;

export const StyledCountryName = styled.div`
  margin-top: ${({ theme: { space } }) => space[32]};

  & > p {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
    font-weight: 500;
  }

  & > h4 {
    font-weight: 600;
    line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  }
`;

export const StyledCountryDetails = styled.div`
  margin-top: ${({ theme: { space } }) => space[16]};
`;

export const StyledDetail = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme: { space } }) => space[4]};

  & > p {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
    font-weight: 400;
    line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};

    &:first-child {
      white-space: nowrap;
      padding-right: ${({ theme: { space } }) => space[16]};
    }

    &:last-child {
      word-break: break-word;
      text-align: end;
    }
  }
`;

export const StyledButtonBlock = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${({ theme: { space } }) => space[16]};
  gap: ${({ theme: { space } }) => space[16]};
`;

export const StyledViewButton = styled.button`
  font-weight: 700;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[0]};
  padding: ${({ theme: { space } }) => `${space[8]} ${space[16]}`};
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors['gray-500']};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-inverse']}`};
  border-radius: ${({ theme: { radii } }) => radii.s};
  background: ${({ theme: { colors } }) => colors['white-base']};
  transition: all 0.4s;

  &:hover {
    background: ${({ theme: { colors } }) => colors['gray-500']};
    color: ${({ theme: { colors } }) => colors['white-base']};
    cursor: pointer;
  }
`;

export const StyledActionButton = styled.button`
  font-weight: 700;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[0]};
  padding: ${({ theme: { space } }) => `${space[8]} ${space[16]}`};
  text-transform: uppercase;
  background: none;
  color: ${({ theme: { colors } }) => colors['gray-500']};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-inverse']}`};
  border-radius: ${({ theme: { radii } }) => radii.s};
  transition: all 0.4s;

  &:hover {
    background: ${({ theme: { colors } }) => colors['gray-500']};
    color: ${({ theme: { colors } }) => colors['white-base']};
    cursor: pointer;
  }
`;
