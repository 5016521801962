import { forwardRef } from 'react';

import PropTypes from 'prop-types';

import { Box, convertToRem, Split } from '@omnipresentgroup/design-system';

import { Checkbox } from '../../atoms/Checkbox';
import { StyledLabel } from './FormCheckbox.styles';

const FormCheckboxPropTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
};

const FormCheckboxDefaultProps = {};

/* @todo replace any with actual types */
const FormCheckbox = forwardRef(function FormCheckbox(
  { label, id, name, size, ...restProps }: any,
  ref,
) {
  const convertedSize = size ? convertToRem(size - (size / 16) * 2) : undefined;
  return (
    <>
      <Split
        gap="0"
        fraction="auto-start"
        css={{ 'align-items': 'center', display: 'flex' }}
      >
        <Box>
          <Checkbox id={id} size={size} name={name} ref={ref} {...restProps} />
        </Box>
        <Box pr="16" ml="8">
          <StyledLabel htmlFor={id} size={convertedSize}>
            {label}
          </StyledLabel>
        </Box>
      </Split>
    </>
  );
});

FormCheckbox.propTypes = FormCheckboxPropTypes;
FormCheckbox.defaultProps = FormCheckboxDefaultProps;

export default FormCheckbox;
