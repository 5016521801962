/**
 * @description
 * Verifies if a given value is redacted.
 *
 * @example
 * getIsRedactedValue({ amount: { redacted: true } }) -> true
 * getIsRedactedValue({ redacted: true }) -> true
 * getIsRedactedValue({ country: 'Brasil' }) -> false
 */
export const getIsRedactedValue = (value) => {
  if (!value || typeof value !== 'object') {
    return false;
  }

  const redactedValue =
    value.redacted ||
    Object.values(value).find(
      (child) =>
        child && Object.prototype.hasOwnProperty.call(child, 'redacted'),
    )?.redacted;

  return Boolean(redactedValue);
};

/**
 * @description
 * Verifies if a given redacted value is missing.
 */
export const getIsRedactedValueMissing = (value) =>
  getIsRedactedValue(value) && value?.missing;

/**
 * @description
 * Filters redacted values from a form section details
 *
 * @param {Object[]} sectionsToEdit - The sections to edit visible on a form
 * @param {Object[]} sectionsToEdit[].details - The sections details which may contain redacted values
 */
export const filterRedactedDetails = (sectionsToEdit) =>
  sectionsToEdit.map(({ details, ...rest }) => ({
    ...rest,
    details: details.filter(({ value }) => !getIsRedactedValue(value)),
  }));
