import avatarPlaceholderPicture from 'assets/img/avatar-placeholder.png';
import PropTypes from 'prop-types';

import { Typography } from '@omnipresentgroup/design-system';

import {
  StyledProfilePictureSmall,
  StyledSideBar,
} from './EditEmployeePage.styles';

const SideBar = ({ name, jobTitle, onboardingStatus }) => (
  <StyledSideBar className="stack">
    <StyledProfilePictureSmall
      src={avatarPlaceholderPicture}
      alt="Employee profile picture"
    />
    <Typography as="h4" hideParagraphSpacing size="24" weight="bold">
      {name}
    </Typography>
    <Typography as="p" hideParagraphSpacing size="16">
      {jobTitle}
    </Typography>
    {onboardingStatus && (
      <Typography as="p" hideParagraphSpacing size="16">
        {onboardingStatus}
      </Typography>
    )}
  </StyledSideBar>
);

SideBar.propTypes = {
  name: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  onboardingStatus: PropTypes.string,
};

SideBar.defaultProps = {
  onboardingStatus: '',
};

export default SideBar;
