import { ModalWrapper } from 'common/components/molecules/ModalWrapper';

import {
  Button,
  Inline,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

type ResignationCancelModalProps = {
  closeFn: () => void;
  onSubmit: () => void;
};

export const ResignationCancelModal = ({
  closeFn,
  onSubmit,
}: ResignationCancelModalProps) => {
  const handleCloseModal = () => {
    closeFn && closeFn();
  };

  const handleOnSubmitButton = () => {
    onSubmit && onSubmit();
  };

  return (
    <ModalWrapper onClose={handleCloseModal} showCloseIcon fixedWidth="400">
      <Stack p="32" pt="24">
        <Typography as="span" size="16" weight="medium">
          Are you sure you want to cancel?
        </Typography>
        <Typography as="span" size="16">
          All information will be lost.
        </Typography>
        <Inline justify="space-between">
          <Button
            variant="secondary"
            onClick={() => {
              handleOnSubmitButton();
              handleCloseModal();
            }}
          >
            Yes, cancel
          </Button>
          <Button onClick={handleCloseModal}>Stay on this page</Button>
        </Inline>
      </Stack>
    </ModalWrapper>
  );
};
