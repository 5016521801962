import { useEffect, useState } from 'react';

import { PAYROLL_FIELDS } from 'common/helpers/payrollFormFieldLabels.const';
import { Field, FIELD_TYPE } from 'common/types';
import { useContractValues } from 'utils/hooks';
import { arePreconditionsMet } from 'utils/preconditions';
import { filterRedactedDetails } from 'utils/rbac.utils';
import { togglePayrollDepositFields } from 'utils/togglePayrollDepositFields';
import { reduceInputField } from 'utils/transformer-utils/input.transformer';

import { Notification, Viewport } from '@omnipresentgroup/design-system';

import MobileButton from '../../components/atoms/MobileButton/MobileButton';
import { StyledBoldCopy } from '../../components/atoms/Text/Copy.styles';
import EditableInput from '../../components/molecules/EditableInput/EditableInput';
import FormSubmitButton from '../../components/molecules/FormSubmitButton/FormSubmitButton';
import PageHeader from '../../components/molecules/PageHeader/PageHeader';
import { Section } from '../EmployeeDetailsPage/Tabs/EmployeeInfoTab/EmployeeInfoTab';
import {
  EditDetailsStyledSectionContainer,
  EditDetailsStyledSectionContainerWrapper,
  StyledContextualInfo,
  StyledEditDetailsPage,
  StyledEditPageContent,
  StyledEditPageMain,
  StyledEditPageRequiredFields,
} from './EditDetailsPage.styles';

interface Props {
  sections: Section[];
  title: string;
  onSaveEdits: (data: any) => void;
  sideBar: React.ReactNode;
  validator: (data: any) => any;
  country?: string;
  backLink?: {
    url: string;
    label: string;
  };
  disabled?: boolean;
}

export const EditDetailsPage: React.FC<Props> = ({
  sections,
  title,
  onSaveEdits,
  sideBar,
  validator,
  country,
  backLink,
  disabled,
}) => {
  const [editedValues, setEditedValues] = useState<{ [key: string]: any }>({});
  const [formValues, setFormValues] = useState({});
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [sectionsToEdit, setSectionsToEdit] = useState<Section[]>(sections);
  const filteredSectionsToEdit = filterRedactedDetails(
    sectionsToEdit,
  ) as Section[];

  useEffect(() => {
    setFormValues(
      filteredSectionsToEdit.reduce(
        (acc, section) => ({
          ...acc,
          ...section.details.reduce(reduceInputField, {}),
        }),
        {},
      ),
    );
  }, []);
  const contractValues = useContractValues(sectionsToEdit, formValues);
  const managementFeeWaiverValues = (editedValues: any) => {
    const waiverEntries = Object.entries(editedValues).filter(
      ([key]) =>
        key === PAYROLL_FIELDS.managementFeeWaiver.waiver_percentage.name ||
        key === PAYROLL_FIELDS.managementFeeWaiver.waiver_period_start.name ||
        key === PAYROLL_FIELDS.managementFeeWaiver.waiver_billing_cycles.name,
    );
    const managementFeeWaiver = Object.fromEntries(waiverEntries);

    if (Object.keys(managementFeeWaiver).length) {
      const formWaiverValues: Record<string, string> = formValues;
      return {
        managementFeeWaiver: {
          ...managementFeeWaiver,
          billingCycles:
            managementFeeWaiver.billingCycles ??
            formWaiverValues[
              PAYROLL_FIELDS.managementFeeWaiver.waiver_billing_cycles.name
            ],
          percentage:
            managementFeeWaiver.percentage ??
            formWaiverValues[
              PAYROLL_FIELDS.managementFeeWaiver.waiver_percentage.name
            ],
          periodStart:
            managementFeeWaiver.periodStart ??
            formWaiverValues[
              PAYROLL_FIELDS.managementFeeWaiver.waiver_period_start.name
            ],
        },
      };
    } else {
      return;
    }
  };

  const testWaiver = managementFeeWaiverValues(editedValues);
  const onFieldChange = (name: string, value: string) => {
    if (name === 'depositType') {
      togglePayrollDepositFields(sectionsToEdit, value);
    }

    const currentSectionsToEdit = sectionsToEdit.map((section) => {
      return {
        ...section,
        details: section.details.map((field: Field) => {
          if (field.name !== name) {
            return field;
          }

          setEditedValues((currentEditedValues) => ({
            ...currentEditedValues,
            ...(field.type === FIELD_TYPE.CHECKBOX ||
            field.type === FIELD_TYPE.DROPDOWN ||
            field.type === FIELD_TYPE.FEE_CURRENCY
              ? [{ ...field, value }].reduce(reduceInputField, {})
              : { [name]: value }),
          }));

          setFormValues((currentFormValues) => ({
            ...currentFormValues,
            ...(field.type === FIELD_TYPE.CHECKBOX ||
            field.type === FIELD_TYPE.DROPDOWN ||
            field.type === FIELD_TYPE.FEE_CURRENCY
              ? [{ ...field, value }].reduce(reduceInputField, {})
              : { [name]: value }),
          }));
          return {
            ...field,
            value,
          };
        }),
      };
    });
    setSectionsToEdit(currentSectionsToEdit);
  };

  const onCTAClick = () => {
    const editedValuesTransformed = Object.keys(editedValues)
      .filter(
        (key) =>
          key !==
            PAYROLL_FIELDS.managementFeeWaiver.waiver_billing_cycles.name &&
          key !== PAYROLL_FIELDS.managementFeeWaiver.waiver_percentage.name &&
          key !== PAYROLL_FIELDS.managementFeeWaiver.waiver_period_start.name,
      )
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: editedValues[key] });
      }, {});

    const data = {
      ...editedValuesTransformed,
      ...contractValues,
      ...testWaiver,
    };

    const validationResult = validator(data);
    if (validationResult) {
      setValidationErrors(validationResult);

      return;
    }

    setValidationErrors({});
    onSaveEdits(data);
  };

  const getErrorMessage = (name: string, type: string) => {
    if (type === 'address') {
      return validationErrors.address;
    }
    if (validationErrors[name]) {
      return validationErrors[name];
    }
    return null;
  };

  return (
    <>
      <StyledEditDetailsPage>
        <StyledEditPageMain className="bigStack">
          <PageHeader title={title} backLink={backLink} />
          <StyledEditPageContent>
            {sideBar && sideBar}
            <EditDetailsStyledSectionContainer data-testid="edit-page-form">
              <EditDetailsStyledSectionContainerWrapper>
                <StyledEditPageRequiredFields>
                  * required field
                </StyledEditPageRequiredFields>
                {filteredSectionsToEdit.map((detailSection: any) => (
                  <div key={detailSection.title}>
                    <StyledBoldCopy>{detailSection.title}</StyledBoldCopy>
                    {detailSection.contextualInfo && (
                      <StyledContextualInfo>
                        {detailSection.contextualInfo}
                      </StyledContextualInfo>
                    )}
                    {detailSection.notification && (
                      <Notification
                        id="edit-details-notification"
                        intent={detailSection.notification.intent}
                        title={detailSection.notification.title}
                        description={detailSection.notification.description}
                      />
                    )}

                    {detailSection.details
                      .filter(
                        (field: Field) =>
                          !field.condition ||
                          arePreconditionsMet(field.condition, formValues),
                      )
                      .map(
                        ({
                          name,
                          type,
                          value,
                          label,
                          labelBold,
                          noLabel,
                          contextualInfo,
                          readOnly,
                          required,
                          options,
                          disabled,
                          link,
                          placeholder,
                          params,
                        }: Field) => {
                          return (
                            <EditableInput
                              key={name}
                              options={options}
                              placeholder={placeholder}
                              country={country}
                              noLabel={noLabel}
                              name={name}
                              type={type}
                              value={value}
                              label={label}
                              editable={!readOnly}
                              disabled={disabled}
                              onChange={onFieldChange}
                              link={link}
                              labelBold={labelBold}
                              contextualInfo={contextualInfo}
                              errorMessage={getErrorMessage(name, type)}
                              required={required}
                              params={params}
                            />
                          );
                        },
                      )}
                  </div>
                ))}
              </EditDetailsStyledSectionContainerWrapper>
              <FormSubmitButton
                ctaText="Save edits"
                onCTAClick={onCTAClick}
                disabled={disabled}
                showCancel
              />
            </EditDetailsStyledSectionContainer>
          </StyledEditPageContent>
        </StyledEditPageMain>
      </StyledEditDetailsPage>
      <Viewport devices={['phone', 'tablet']}>
        <MobileButton onClick={onCTAClick} disabled={disabled}>
          Save edits
        </MobileButton>
      </Viewport>
    </>
  );
};

export default EditDetailsPage;
