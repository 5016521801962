import { useHistory } from 'react-router-dom';

import { ActionCell } from 'common/components/molecules/ActionCell/ActionCell';
import {
  ClickableCell,
  StyledTitleCell,
} from 'common/components/organisms/TableWithTitle/TableWithTitle.styles';
import { User, UserRole, userRoleTypeToFriendlyName } from 'common/types';
import { AdminActions } from 'common/types/admin';
import { ADMIN_LIST_PAGE } from 'paths';

const UserDisplayNameCell = ({
  userId,
  firstName,
  lastName,
}: {
  userId: string;
  firstName: string;
  lastName: string;
}) => {
  const history = useHistory();
  return (
    <ClickableCell onClick={() => history.push(`${ADMIN_LIST_PAGE}/${userId}`)}>
      <StyledTitleCell>
        {firstName} {lastName}
      </StyledTitleCell>
    </ClickableCell>
  );
};

const ActionsCustomCell = (
  user: User,
  onColumnItemClick: (column: string, user: User) => void,
) => (
  <ActionCell
    actions={AdminActions}
    onActionClick={(action) => onColumnItemClick(action, user)}
  />
);

export const tableHeaders = [
  {
    headerTitle: 'Name',
    align: 'left',
    customCell: UserDisplayNameCell,
  },
  {
    headerTitle: 'Additional Admin Roles',
    align: 'left',
    customCell: ({ roles }: { roles: UserRole[] }) => {
      const additionalAdminRoles = roles.filter(
        (role) => role.type !== 'admin',
      );
      return additionalAdminRoles
        .map((role) => userRoleTypeToFriendlyName[role.type])
        .join(', ');
    },
  },
  {
    id: 'action',
    headerTitle: '',
    customCell: ActionsCustomCell,
  },
];
