import { useCallback } from 'react';

import { AxiosError } from 'axios';
import { getManagerPermissionsRequest } from 'common/api/managerPermissions.api';
import { ONE_MINUTE } from 'common/constants';
import { ManagerEmployeesApiResponse } from 'common/types';
import { AssociatedEmployee } from 'omniplatform/manager/pages/ManagerPermissions/components';
import { useQuery, UseQueryResult } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { managerPermissionsKeys } from '../keys';

const selectAsAssociatedEmployees = (
  data: ManagerEmployeesApiResponse,
): AssociatedEmployee[] =>
  data.employees.map((employee) => ({
    isAssociated: true,
    userId: employee.userId,
    name: employee.name,
    jobTitle: employee.employmentDetails.jobTitle,
    country: employee.country.name,
  }));

export const useManagerEmployeesQuery = ({
  companyId,
  managerUserId,
  asAssociatedEmployee,
}: {
  companyId: string;
  managerUserId: string;
  asAssociatedEmployee: boolean;
}): UseQueryResult<
  ManagerEmployeesApiResponse | AssociatedEmployee[],
  AxiosError
> => {
  return useQuery(
    managerPermissionsKeys.getEmployees(companyId, managerUserId),
    () =>
      getManagerPermissionsRequest(companyId, managerUserId).then(
        getDataFromResponse,
      ),
    {
      staleTime: ONE_MINUTE,
      enabled: !!companyId && !!managerUserId,
      refetchOnWindowFocus: false,
      select: useCallback(
        (data: ManagerEmployeesApiResponse) =>
          asAssociatedEmployee ? selectAsAssociatedEmployees(data) : data,
        [asAssociatedEmployee],
      ),
    },
  );
};
