import { call, put, takeLatest } from 'redux-saga/effects';
import { createActionObject } from 'utils/redux-utils';
import { v4 as uuidv4 } from 'uuid';

import {
  getPresignedS3UrlRequest,
  uploadToPresignedUrlRequest,
} from '../../api/documentStorage.api';
import { asyncActionKeys } from '../actions/redux.constants';

function* uploadDocument({ payload: { file, documentType } }) {
  try {
    const fileExtension = file.name.split('.').pop();

    //TODO: delete old(originalFileName) file from s3

    const fileName = `${documentType}/${uuidv4()}.${fileExtension}`;
    const fileWithNewName = new File([file], fileName);
    const {
      data: { url, fields },
    } = yield call(getPresignedS3UrlRequest, fileWithNewName);
    const documentUrl = `${url}/${fields.key}`;
    yield call(uploadToPresignedUrlRequest, url, fileWithNewName, fields);
    yield put(
      createActionObject(asyncActionKeys.UPLOAD_DOCUMENT_END, documentUrl),
    );
  } catch (e) {
    yield put(createActionObject(asyncActionKeys.UPLOAD_DOCUMENT_ERROR, e));
  }
}

export default [
  takeLatest(asyncActionKeys.UPLOAD_DOCUMENT_START, uploadDocument),
];
