export const employerCostsResponse = {
  country: {
    code: 'GB',
    name: 'United Kingdom',
    flag: 'https://uploads-ssl.webflow.com/6070bb98d3880477794a7128/609046fb9c8705e7f2232545_gb.svg',
    verified: true,
    vat: {
      rate: null,
      amount: null,
      base: null,
      costs: null,
      fee: null,
    },
  },
  localCurrency: {
    code: 'GBP',
    name: 'British Pound Sterling',
  },
  targetCurrency: {
    code: 'EUR',
    name: 'Euro',
  },
  grossSalary: {
    EUR: 1000,
    USD: 1134.9810231172935,
    GBP: 836.5059836199538,
  },
  employerCosts: {
    subtotal: {
      GBP: 39.51700524815224,
      USD: 53.6171311686113,
      EUR: 47.2405529929907,
      CAD: 47.24,
    },
    costs: [
      {
        name: 'Workplace Pension',
        GBP: 25.095179508598612,
        USD: 34.0494306935188,
        EUR: 29.999999999999996,
        CAD: 34.04,
        notes: 'Uncapped',
      },
      {
        name: 'National Insurance',
        GBP: 14.421825739553624,
        USD: 19.5677004750925,
        CAD: 19.56,
        EUR: 17.2405529929907,
        notes:
          'Taxable Income Band is the amount to apply the Employer Tax to. Minimum Exemption as per Suzie Calculations. ',
      },
    ],
  },
  additionalCosts: {
    EUR: 0,
    USD: 0,
    GBP: 0,
  },
  managementFee: {
    EUR: 0,
    USD: 0,
    GBP: 0,
  },
  fxFee: {
    GBP: 17.52045977736212,
    USD: 23.771963085718095,
    EUR: 20.944811059859813,
    CAD: 20.94,
    rate: 0.02,
  },
  vat: {
    amount: {
      GBP: 0,
      USD: 0,
      EUR: 0,
    },
    note: '',
  },
  totalCost: {
    GBP: 893.5434486454682,
    USD: 1212.3701173716229,
    EUR: 1068.1853640528504,
  },
  description:
    'The employment cost includes a 3% pension contribution from the employer. This is an option that the employee chooses whether to opt into, and may not be applicable.',
  calculation:
    'For NI, an employer Tax of 13.8% is applied to all income above the minimum exemption amount. The Taxable Income Band calculated monthly salary minus the minimum exemption, giving the amount to apply the employer tax to. Pension is applied at a 3% rate to monthly income with no minimum or maximum.',
  sources:
    'https://home.kpmg/xx/en/home/insights/2011/12/united-kingdom-other-taxes-levies.html',
};
