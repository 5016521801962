/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';

import appHistory from 'app/appHistory';
import {
  getLoadingSelector,
  getTransformedUser,
} from 'app/store/app.selectors';
import { AxiosError } from 'axios';
import { downloadDocumentRequest } from 'common/api/documents.api';
import { createDocusignEnvelope } from 'common/api/docusign.api';
import { updateEmployeeRequest } from 'common/api/employees.api';
import Loading from 'common/components/atoms/Loading/Loading';
import { ActionsDropDown } from 'common/components/molecules/ActionsDropDown/ActionsDropDown';
import { ConfirmationModal } from 'common/components/molecules/ConfirmationModal';
import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { FourOhFour } from 'common/pages/404/FourOhFour';
import { OnboardingStageProgressTracker as EmployeeOnboardingProgressTracker } from 'common/pages/EmployeeDetailsPage/ProgressTracker/OnboardingProgressTracker/OnboardingProgressTracker';
import { getEmployeeAction } from 'common/store/actions/employee.actions.js';
import { getSelectedEmployeeSelector } from 'common/store/selectors/employees.selector';
import { transformEmployeeForDetailsPage } from 'common/store/transformers/employees.transformer';
import {
  APPROVED,
  ARCHIVED,
  Contract,
  ContractStatus,
  DRAFT,
  Employee,
  PENDING_INTERNAL_REVIEW,
  PENDING_REVIEW,
  REVIEW_DONE,
  SIGNED_BOTH_PARTIES,
  SIGNED_EMPLOYEE,
  User,
} from 'common/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EMPLOYEE_PROFILE_PAGE, EMPLOYEES_PATH } from 'paths';
import queryString from 'query-string';
import { X } from 'react-feather';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryFlagByCountryName } from 'utils/countries';
import { getEmployeeName } from 'utils/employees';
import {
  useStatusDescription,
  useStatusIntent,
} from 'utils/hooks/contractCard';
import { mapCountryNameToCountryCode } from 'utils/mapCountryNameToCountryCode';
import { useTrack } from 'utils/mixpanel/tracker';
import { useUpdateContractStatusMutation } from 'utils/queries/contract';
import { useCreateContractAmendmentMutation } from 'utils/queries/contract/useContractAmendmentMutation';
import { useArchiveContractMutation } from 'utils/queries/employees/useArchiveContractMutation';
import { useAvvokaDocumentDownloadQuery } from 'utils/queries/employmentContractTemplates/useAvvokaTemplateQuery/useAvvokaTemplateQuery';
import { isCSAdmin, isEmployee, isManager, isOPAdmin } from 'utils/user';

import {
  Button,
  Center,
  DateFormats,
  formatISODate,
  getISOString,
  Inline,
  Notification,
  Stack,
  Tag,
  Typography,
  type IsoDateTime,
} from '@omnipresentgroup/design-system';

import { EditMode } from '../Contracts/Edit';
import {
  ARCHIVE_CONTRACT_CONFIRM,
  ARCHIVE_CONTRACT_SUCCESS,
} from '../EmployeeDetailsPage/Tabs/EmployeeInfoTab/EmployeeInfoTab';
import { ContractQuestionnaire, RightToWorkAlert } from './components';
import * as S from './components/ContractAmendment.styles';
import { ContractAmendmentFormContainer } from './components/ContractAmendmentForm';
import { ContractAmendments } from './components/ContractAmendments';
import {
  ContractContainer,
  ContractHeading,
  ContractHeadingText,
  ContractPageContent,
  CTAContainer,
} from './EmployeeContractPage.styles';

type ParamTypes = {
  documentId: string;
  employeeId: string;
};

const REFETCH_EMPLOYEE_INTERVAL_ON_MISSING_DOCUMENT_ID = 5000;

const getContractHeading = (
  user: User,
  contractStatus: ContractStatus,
  employee: Employee,
) => {
  switch (true) {
    case isManager(user) && contractStatus === APPROVED:
      return 'Awaiting Employee Signature';
    case isEmployee(user) && contractStatus === APPROVED:
      return 'Awaiting Your Signature';
    default:
      return `${employee.name}'s Employee Contract`;
  }
};

const useAdminContractActions = (contract?: Contract) => {
  const employee = useSelector(getSelectedEmployeeSelector);
  const isAutomatedFlow = Boolean(
    Object.keys(employee?.contractQuestionnaire?.body || {})?.length > 0,
  );
  const showEditAnswers =
    isAutomatedFlow &&
    [
      DRAFT,
      SIGNED_EMPLOYEE,
      PENDING_INTERNAL_REVIEW,
      APPROVED,
      ARCHIVED,
      PENDING_REVIEW,
      SIGNED_BOTH_PARTIES,
    ].includes(contract?.status as Exclude<ContractStatus, typeof REVIEW_DONE>);
  const showGenerateContract =
    isAutomatedFlow &&
    [
      DRAFT,
      SIGNED_EMPLOYEE,
      PENDING_INTERNAL_REVIEW,
      APPROVED,
      ARCHIVED,
      PENDING_REVIEW,
      REVIEW_DONE,
    ].includes(
      contract?.status as Exclude<ContractStatus, typeof SIGNED_BOTH_PARTIES>,
    ) &&
    !!contract?.avvokaDocumentId;
  const showSendToManager = isAutomatedFlow && contract?.status === REVIEW_DONE;
  return { showEditAnswers, showGenerateContract, showSendToManager };
};

const createAnalyticsEvent = (contract: Contract, employee: Employee) => ({
  clientId: employee.managerId,
  countryCode: mapCountryNameToCountryCode(employee.country),
  employeeId: employee.id,
  contractStatus: contract.status,
  contractVersion: contract.version,
});

const COMPLETE_DRAFT_REVIEW_CONFIRMATION = 'complete-draft-review-confirmation';
const COMPLETE_DRAFT_REVIEW_SUCCESS = 'complete-draft-review-success';
const EMPLOYEE_SIGNED = 'employee-signed';
const COMPLETE_INTERNAL_REVIEW_CONFIRMATION =
  'complete-internal-review-confirmation';

export const EmploymentContractPage = () => {
  const { documentId, employeeId } = useParams<ParamTypes>();
  const employee = useSelector(getSelectedEmployeeSelector);
  const user = useSelector(getTransformedUser);
  const dispatch = useDispatch();
  const isLoading = useSelector(getLoadingSelector);
  const location = useLocation();

  const parsedLocation = queryString.parse(location.search);
  const [showLoading, setShowLoading] = useState(false);
  const [contract, setContract] = useState<Contract | undefined>();
  const [showContractAmendmentForm, setShowContractAmendmentForm] =
    useState(false);
  const [showContractAmendments, setShowContractAmendments] = useState(false);
  const [
    clearContractAmendmentFormFields,
    setClearContractAmendmentFormFields,
  ] = useState(false);

  const [
    showContractAmendmentRequestSuccessModal,
    setShowContractAmendmentRequestSuccessModal,
  ] = useState(false);

  const { showEditAnswers, showGenerateContract, showSendToManager } =
    useAdminContractActions(contract);

  const createAmendmentMutation = useCreateContractAmendmentMutation(
    setShowContractAmendmentRequestSuccessModal,
  );
  const { mutate: createAmendment, isError: isErrorCreateAmendment } =
    createAmendmentMutation;

  const track = useTrack();

  const { enableContractAmendmentsForPilotCountry } = useFlags();

  const intent = useStatusIntent(contract?.status);
  const statusDescription = useStatusDescription(contract?.status);

  const isPilotCountryForContractAmendments =
    enableContractAmendmentsForPilotCountry?.countries?.includes(
      employee?.country,
    );
  const rightToWorkCheckCompleted = employee?.employmentDetails
    ?.find(
      (detail: { name: string }) => detail.name === 'rightToWorkCheckCompleted',
    )
    ?.value?.find((option: { selected: boolean }) => option.selected)?.value;

  const showRightToWorkAlert =
    // this prevents the alert from blinking
    Object.keys(employee).length > 0 &&
    rightToWorkCheckCompleted === false &&
    contract?.status !== SIGNED_BOTH_PARTIES;

  const documentDownloadDocx = useAvvokaDocumentDownloadQuery(
    contract?.avvokaDocumentId,
  );

  const defaultModal =
    parsedLocation.employeeSigned === 'true' ? EMPLOYEE_SIGNED : undefined;
  const [visibleModal, setVisibleModal] = useState<string | undefined>(
    defaultModal,
  );
  const { fullName: employeeName } = employee && getEmployeeName(employee);
  const canBeArchived =
    isCSAdmin(user) &&
    contract?.status !== (ARCHIVED || PENDING_REVIEW) &&
    !!contract?.documentId;

  const canDownloadDocX = isCSAdmin(user) && !!contract?.avvokaDocumentId;

  const isContractAmendementAvailableForRoleType =
    isManager(user) || isCSAdmin(user);

  const canRequestContractAmendment =
    isPilotCountryForContractAmendments &&
    isContractAmendementAvailableForRoleType;

  const showAdminButtons = isCSAdmin(user) || isOPAdmin(user);

  const actions = [
    canBeArchived && {
      id: 'archive-contract',
      label: 'Archive contract',
      onClickHandler: () => setVisibleModal(ARCHIVE_CONTRACT_CONFIRM),
    },
    canDownloadDocX && {
      id: 'download-contract-docx',
      label: 'Download docx',
      onClickHandler: () => {
        documentDownloadDocx.refetch();
      },
    },
    canRequestContractAmendment && {
      id: 'request-contract-edit',
      label: 'Request contract edit',
      onClickHandler: () => setShowContractAmendmentForm(true),
    },
    canRequestContractAmendment && {
      id: 'view-contract-edit-request-history',
      label: 'Contract edit request history',
      onClickHandler: () => setShowContractAmendments(true),
    },
  ].filter(Boolean);

  useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeAction(employeeId));
    }
  }, [dispatch, employeeId]);

  const isMatchingDoc = useCallback(
    (c: Contract) => c.documentId === documentId || c.contractId === documentId,
    [documentId],
  );

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (employeeId && !contract?.documentId) {
        dispatch(getEmployeeAction(employeeId));
      }
    }, REFETCH_EMPLOYEE_INTERVAL_ON_MISSING_DOCUMENT_ID);

    return () => clearTimeout(intervalId);
  }, [contract, dispatch, employeeId]);

  useEffect(() => {
    const { contracts } = employee;
    if (Array.isArray(contracts)) {
      setContract(contracts.find(isMatchingDoc));
    }
  }, [employee, isMatchingDoc]);

  const {
    data: documentData,
    error: documentError,
    isSuccess,
  } = useQuery(
    `employeeContract-${contract?.documentId}`,
    () => downloadDocumentRequest(contract?.documentId as string, 'inline'),
    {
      enabled: !!contract?.documentId,
      refetchOnWindowFocus: false,
    },
  );

  const isGeneratingContract = !documentData?.data.url;
  const mutation = useMutation(
    (updatedContracts: Contract[]) =>
      updateEmployeeRequest(employee.id, { contracts: updatedContracts }),
    {
      onSuccess: ({ data }) => {
        dispatch({
          type: 'GET_EMPLOYEE_END',
          payload: transformEmployeeForDetailsPage({
            ...employee,
            ...data,
          }),
        });
      },
    },
  );

  const docusignMutation = useMutation(
    (documentId: string) => createDocusignEnvelope(documentId, employee.id),
    {
      onSuccess: ({ data }) => {
        window.location.assign(data.docuSignUrl);
        setShowLoading(true);
      },
    },
  );

  const { mutateAsync: updateContractState } =
    useUpdateContractStatusMutation();

  const updateContractStatus = useCallback(
    (status: ContractStatus) => {
      if (!Array.isArray(employee.contracts) || !contract) {
        return;
      }
      const updatedContracts: Contract[] = [...employee.contracts];
      const index = updatedContracts.findIndex(isMatchingDoc);
      updatedContracts[index] = {
        ...contract,
        status,
        updatedAt: getISOString(),
      };
      mutation.mutate(updatedContracts, {
        onError: (err) => console.error(err),
      });
    },
    [contract, employee, isMatchingDoc, mutation],
  );

  const archiveContractMutation = useArchiveContractMutation();

  const archiveContract = (employeeId: string, documentId: string) =>
    archiveContractMutation.mutate({ employeeId, documentId });

  useEffect(() => {
    if (mutation.isLoading || parsedLocation.event !== 'signing_complete') {
      return;
    }

    if (
      contract?.status === APPROVED &&
      parsedLocation.employeeSigned === 'true'
    ) {
      updateContractStatus(SIGNED_EMPLOYEE);
    }

    if (
      contract?.status === SIGNED_EMPLOYEE &&
      parsedLocation.employerSigned === 'true'
    ) {
      updateContractStatus(SIGNED_BOTH_PARTIES);
    }
  }, [parsedLocation, employee, contract, updateContractStatus, mutation]);

  if (!contract) {
    return <Loading />;
  }

  if ((documentError as AxiosError)?.response?.status === 404) {
    return <Redirect to="/404" />;
  }

  if (contract.status === DRAFT && isEmployee(user)) {
    return <Redirect to="/404" />;
  }

  if (
    contract.status === REVIEW_DONE &&
    (isManager(user) || isEmployee(user))
  ) {
    return <Redirect to="/404" />;
  }

  if (
    rightToWorkCheckCompleted === false &&
    isManager(user) &&
    contract.status === DRAFT
  ) {
    return (
      <FourOhFour message="Contract is waiting for right to work verification." />
    );
  }

  if (contract.status === ARCHIVED && (isEmployee(user) || isManager(user))) {
    return (
      <FourOhFour message="This link doesn't exist anymore, please visit employee page for existing contract." />
    );
  }

  const CTAs = (user: User, contract: Contract) => {
    if (isManager(user) && contract.status === DRAFT) {
      return (
        <Button
          onClick={() => setVisibleModal(COMPLETE_DRAFT_REVIEW_CONFIRMATION)}
          variant="primary"
          disabled={contract.status !== DRAFT || !contract?.documentId}
        >
          Send to employee
        </Button>
      );
    }

    if (isEmployee(user) && contract.status === APPROVED) {
      return (
        <Button
          onClick={() => {
            track(
              'Sign Employment Contract Clicked',
              createAnalyticsEvent(contract, employee),
            );
            docusignMutation.mutate(contract.documentId || '');
          }}
          variant="primary"
        >
          Sign With Docusign
        </Button>
      );
    }

    if (
      (isCSAdmin(user) || isOPAdmin(user)) &&
      contract.status === SIGNED_EMPLOYEE
    ) {
      return (
        <Button
          onClick={() => {
            track(
              'Counter-Sign Employment Contract Clicked',
              createAnalyticsEvent(contract, employee),
            );
            docusignMutation.mutate(contract.documentId || '');
          }}
          variant="primary"
        >
          Sign With Docusign
        </Button>
      );
    }

    if (
      (isCSAdmin(user) || isOPAdmin(user)) &&
      contract.status === PENDING_INTERNAL_REVIEW
    ) {
      return (
        <Button
          onClick={() => {
            setVisibleModal(COMPLETE_INTERNAL_REVIEW_CONFIRMATION);
          }}
          variant="primary"
        >
          Complete internal review
        </Button>
      );
    }
    return null;
  };

  if (docusignMutation.isLoading || showLoading) {
    return <Loading />;
  }

  const completeDraftReviewConfirmationModalProps = {
    title: 'Send to employee?',
    message: `The contract will only be released to the employee to sign upon confirmation of receipt of funds related to the deposit invoice.
       \nTo avoid delays in onboarding, please ensure settlement.
      `,
    cancelHandler: () => setVisibleModal(undefined),
    cancelLabel: 'CANCEL',
    confirmLabel: 'SEND TO EMPLOYEE',
    confirmHandler: () => {
      track(
        'Approve Employment Contract Clicked',
        createAnalyticsEvent(contract, employee),
      );
      updateContractStatus(PENDING_INTERNAL_REVIEW);
      setVisibleModal(COMPLETE_DRAFT_REVIEW_SUCCESS);
    },
    loading: mutation.isLoading,
  };

  const completeDraftReviewSuccessModalProps = {
    title: `Contract pending confirmation of funds`,
    message: `The contract will be sent to ${employeeName} to sign upon confirmation of receipt of funds related to the deposit invoice.
    \nTo avoid delays in onboarding, please ensure settlement. You will be notified when the employee has signed.`,
    cancelHandler: () => setVisibleModal(undefined),
    cancelLabel: 'CLOSE',
    confirmLabel: 'GO TO EMPLOYEE PAGE',
    confirmHandler: () => appHistory.push(`${EMPLOYEES_PATH}/${employeeId}`),
    loading: isLoading,
  };

  const archiveContractConfirmModalProps = {
    title: 'Archive contract?',
    message:
      "Are you sure you want to archive this contract? It won't be visible to other users anymore",
    cancelHandler: () => setVisibleModal(undefined),
    cancelLabel: 'DO NOT ARCHIVE',
    confirmLabel: 'ARCHIVE',
    confirmHandler: () => {
      archiveContract(employeeId, documentId);
      setVisibleModal(ARCHIVE_CONTRACT_SUCCESS);
    },
    loading: archiveContractMutation.isLoading,
  };

  const archiveContractSuccessModalProps = {
    title: 'Contract has been archived',
    message:
      "You will be able to view the contract but it's no longer visible by other users",
    cancelHandler: () => setVisibleModal(undefined),
    cancelLabel: 'CLOSE',
  };

  const completeInternalReviewConfirmationModalProps = {
    title: 'Complete Internal Review?',
    message: `Have you confirmed that the related deposit invoice has been paid?
     \nCompleting this step will issue the offer of employment (the contract) to the client employee for signature.
     \nThereby confirming that Omnipresent can take on credit risk associated to this employment.
      `,
    cancelHandler: () => setVisibleModal(undefined),
    cancelLabel: 'CANCEL',
    confirmLabel: 'CONFIRM',
    confirmHandler: () => {
      track(
        'Complete Employment Contract Internal Review Clicked',
        createAnalyticsEvent(contract, employee),
      );
      updateContractStatus(APPROVED);
      setVisibleModal(undefined);
    },
    loading: mutation.isLoading,
  };

  return (
    <ContractPageContent className="largerStack">
      {visibleModal === COMPLETE_DRAFT_REVIEW_CONFIRMATION && (
        <ConfirmationModal {...completeDraftReviewConfirmationModalProps} />
      )}

      {visibleModal === COMPLETE_DRAFT_REVIEW_SUCCESS && (
        <ConfirmationModal {...completeDraftReviewSuccessModalProps} />
      )}

      {visibleModal === EMPLOYEE_SIGNED && (
        <ConfirmationModal
          title="Contract signed"
          message="This contract will now be countersigned by Omnipresent. You will be notified when the contract is finalised."
          cancelHandler={() => setVisibleModal(undefined)}
          cancelLabel="CLOSE"
        />
      )}

      {visibleModal === ARCHIVE_CONTRACT_CONFIRM && (
        <ConfirmationModal {...archiveContractConfirmModalProps} />
      )}

      {visibleModal === ARCHIVE_CONTRACT_SUCCESS && (
        <ConfirmationModal {...archiveContractSuccessModalProps} />
      )}

      {visibleModal === COMPLETE_INTERNAL_REVIEW_CONFIRMATION && (
        <ConfirmationModal {...completeInternalReviewConfirmationModalProps} />
      )}

      {isErrorCreateAmendment && (
        <Notification
          description="Please try again."
          dismissible
          id="createContractAmendmentFormError"
          intent="error"
          title="We couldn’t save the information you provided."
        />
      )}
      <EmployeeOnboardingProgressTracker />
      {showRightToWorkAlert && <RightToWorkAlert />}
      {showContractAmendmentRequestSuccessModal && (
        <ModalWrapper
          fixedWidth="480"
          fixedHeight="400"
          onClose={() => setClearContractAmendmentFormFields(true)}
          inLineCss={{ overflow: 'auto' }}
        >
          <Stack mx="32" my="16" h={360} justify="space-between">
            <Inline justify="space-between">
              <Typography
                as="h1"
                hideParagraphSpacing
                size="24"
                weight="medium"
              >
                Edit Request
              </Typography>
              <S.ContainerClose>
                <X
                  data-testid="close-drawer"
                  onClick={() => {
                    setShowContractAmendmentRequestSuccessModal(false);
                    setClearContractAmendmentFormFields(true);
                  }}
                  size={24}
                  cursor="pointer"
                />
              </S.ContainerClose>
            </Inline>
            <Stack>
              <Center axis="horizontal" centerText={true}>
                Thank you for submiting your edit request.
              </Center>
              <Center axis="horizontal" centerText={true}>
                We will come back to you with a response as soon as possible.
              </Center>
              <Center axis="horizontal" centerText={true}>
                Previously submitted requests can be seen from the Contract Edit
                History option.
              </Center>
            </Stack>

            <Inline justify="flex-end">
              <Button
                variant="primary"
                onClick={() => {
                  setShowContractAmendmentRequestSuccessModal(false);
                  setClearContractAmendmentFormFields(true);
                }}
              >
                Close
              </Button>
            </Inline>
          </Stack>
        </ModalWrapper>
      )}

      <Inline h="100%">
        <ContractContainer
          showContractAmendmentForm={showContractAmendmentForm}
        >
          <ContractHeading>
            {contract && Object.keys(employee).length > 1 && (
              <>
                <ContractHeadingText>
                  <Typography as="h1" hideParagraphSpacing size="24">
                    {getContractHeading(user, contract.status, employee)}
                    &nbsp;|&nbsp;
                    <Tag id="status_badge" intent={intent} outlined={false}>
                      {statusDescription}
                    </Tag>
                  </Typography>
                  <Typography as="h2" hideParagraphSpacing size="24">
                    {`${getCountryFlagByCountryName(employee.country)} ${
                      employee.country
                    } | `}
                    {['archived'].includes(contract.status)
                      ? `Archived on ${formatISODate(
                          contract.archivedAt as IsoDateTime,
                          DateFormats.Date,
                        )}`
                      : `Updated at ${formatISODate(
                          contract.updatedAt || contract.createdAt,
                          DateFormats.Date,
                        )}`}
                  </Typography>
                </ContractHeadingText>
                <CTAContainer>
                  <Button
                    variant="secondary"
                    disabled={isGeneratingContract}
                    onClick={() =>
                      isEmployee(user)
                        ? appHistory.push(`${EMPLOYEE_PROFILE_PAGE}`)
                        : appHistory.push(`${EMPLOYEES_PATH}/${employeeId}`)
                    }
                  >
                    Back
                  </Button>
                  {showAdminButtons && (
                    <>
                      {showEditAnswers && (
                        <Button
                          onClick={() => {
                            track(
                              'Edit Contract Responses Clicked',
                              createAnalyticsEvent(contract, employee),
                            );
                            appHistory.push(
                              `${appHistory.location.pathname}/edit?${EditMode.update_contract_answers}`,
                            );
                          }}
                          variant="secondary"
                          disabled={isGeneratingContract}
                        >
                          Edit Contract Answers
                        </Button>
                      )}
                      {showGenerateContract && (
                        <Button
                          onClick={() => {
                            // FIXME: do we update this event's name?
                            track(
                              'Edit Contract Responses Clicked',
                              createAnalyticsEvent(contract, employee),
                            );
                            if (contract.status === REVIEW_DONE) {
                              appHistory.push(
                                `${appHistory.location.pathname}/edit?${EditMode.complete_missing_information}`,
                              );
                            } else {
                              appHistory.push(
                                `${appHistory.location.pathname}/edit?${EditMode.generate_new_contract}`,
                              );
                            }
                          }}
                          variant="secondary"
                          disabled={isGeneratingContract}
                        >
                          Generate New Contract
                        </Button>
                      )}
                      {showSendToManager && (
                        <Button
                          onClick={async () => {
                            track(
                              'Send to Manager Clicked',
                              createAnalyticsEvent(contract, employee),
                            );
                            if (contract.contractId) {
                              await updateContractState({
                                employeeId,
                                contractId: contract.contractId,
                                status: DRAFT,
                              });
                              appHistory.push(
                                `${EMPLOYEES_PATH}/${employee.id}`,
                              );
                            }
                          }}
                          variant="secondary"
                          disabled={isGeneratingContract}
                        >
                          Send To Manager
                        </Button>
                      )}
                    </>
                  )}
                  {CTAs(user, contract)}
                </CTAContainer>
                {actions.length > 0 && (
                  <ActionsDropDown
                    actions={actions}
                    disabled={isGeneratingContract}
                  />
                )}
              </>
            )}
          </ContractHeading>
          {isGeneratingContract && (
            <div style={{ margin: 'auto', fontSize: 20 }}>
              <div
                style={{
                  position: 'relative',
                  paddingTop: 200,
                  marginBottom: 40,
                }}
              >
                <Loading />
              </div>
              We are generating your custom and locally compliant employment
              contract
            </div>
          )}
          {isSuccess && (
            <div className="flex-row">
              {isCSAdmin(user) && <ContractQuestionnaire employee={employee} />}
              <iframe
                src={documentData?.data.url}
                title="Employee Contract"
                width="100%"
              ></iframe>
            </div>
          )}
        </ContractContainer>
        {showContractAmendmentForm && (
          <ContractAmendmentFormContainer
            setShowContractAmendmentForm={setShowContractAmendmentForm}
            contractDocumentId={contract.documentId || documentId}
            employeeId={employeeId}
            companyId={employee.managerId}
            create={createAmendment}
            clearFormFields={clearContractAmendmentFormFields}
          />
        )}
        {showContractAmendments && (
          <ContractAmendments
            setShowContractAmendments={setShowContractAmendments}
            setShowContractAmendmentForm={setShowContractAmendmentForm}
            contractDocumentId={contract.documentId || documentId}
          />
        )}
      </Inline>
    </ContractPageContent>
  );
};
