/* eslint-disable no-magic-numbers */
import { ReactElement } from 'react';

import { ReactComponent as EmployeeCardImg } from 'assets/img/duotone-game-changer-3.svg';
import { ReactComponent as ContractorCardImg } from 'assets/img/duotone-local.svg';
import { HireTypeEnum } from 'common/types/hire';
import { capitaliseFirstLetter } from 'utils/string-utils';

import {
  Box,
  Card,
  Inline,
  Stack,
  Typography,
  Viewport,
} from '@omnipresentgroup/design-system';

type HireCardDetails = {
  description: string;
  getImage: (width: number) => ReactElement;
};

const PAGE_TEXT_MAP: Record<HireTypeEnum, HireCardDetails> = {
  [HireTypeEnum.employee]: {
    description:
      'Hired to work a set number of hours per week, and entitled to benefits and job security provided by the employer.',
    getImage: (width: number) => <EmployeeCardImg width={width} />,
  },
  [HireTypeEnum.contractor]: {
    description:
      'Hired to work temporarily to complete a specific job or project, and is responsible for their own taxes and benefits.',
    getImage: (width: number) => <ContractorCardImg width={width} />,
  },
};

export type HireCardProps = {
  type: HireTypeEnum;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  tag?: ReactElement;
};

export const HireCard = ({
  type,
  selected,
  disabled,
  onClick,
  tag,
}: HireCardProps) => {
  return (
    <Card
      role="radio"
      interactive="true"
      aria-labelledby={type}
      w="stretch"
      p="16"
      selected={selected}
      disabled={disabled}
      onClick={onClick}
    >
      <Viewport devices={['phone']}>
        <Inline
          px="8"
          gap="16"
          w="stretch"
          align="center"
          css={{ position: 'relative' }}
        >
          <Box>{PAGE_TEXT_MAP[type].getImage(40)}</Box>
          <Stack gap="0">
            <Inline justify="space-between" align="center">
              <Typography
                as="h4"
                hideParagraphSpacing
                size="20"
                weight="medium"
              >
                {capitaliseFirstLetter(type)}
              </Typography>
              {tag}
            </Inline>
            <Typography as="span" size="14" color="subtle">
              {PAGE_TEXT_MAP[type].description}
            </Typography>
          </Stack>
        </Inline>
      </Viewport>
      <Viewport devices={['highRes', 'desktop', 'laptop', 'tablet']}>
        <Stack pt="24" gap="32" align="center" css={{ position: 'relative' }}>
          {tag && (
            <Box css={{ position: 'absolute', top: 0, right: 0 }}>{tag}</Box>
          )}
          <Box>{PAGE_TEXT_MAP[type].getImage(120)}</Box>
          <Stack
            gap="0"
            align="center"
            css={{
              textAlign: 'center',
            }}
          >
            <Typography as="h4" hideParagraphSpacing size="20" weight="medium">
              {capitaliseFirstLetter(type)}
            </Typography>
            <Typography as="span" size="14" color="subtle">
              {PAGE_TEXT_MAP[type].description}
            </Typography>
          </Stack>
        </Stack>
      </Viewport>
    </Card>
  );
};
