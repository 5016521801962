import { Tag } from '@omnipresentgroup/design-system';

import { InitialInvoiceStatusType } from '../types';

const getStatusTagData = (
  statusType: InitialInvoiceStatusType,
): { intent: 'error' | 'success' | 'warning'; label: string } => {
  switch (statusType) {
    case InitialInvoiceStatusType.SUCCESS:
      return { intent: 'success', label: 'Processed' };
    case InitialInvoiceStatusType.SUBMITTED:
      return { intent: 'warning', label: 'Submitted' };
    case InitialInvoiceStatusType.FAILED:
    default:
      return { intent: 'error', label: 'Failed' };
  }
};

export const ProcessingStatusBadge = ({
  status,
  id,
}: {
  status: InitialInvoiceStatusType;
  id: string;
}) => {
  const { intent, label } = getStatusTagData(status);

  return (
    <Tag id={id} intent={intent}>
      {label}
    </Tag>
  );
};
