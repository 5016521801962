import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

const borderWidth = '1px'; // TODO: Leaving the hardcoded value for now until new tables are ready from OmniDS

export const StyledDetailsValue = styled.td`
  border: ${({ theme: { colors, borderStyles } }) =>
    `${borderWidth} ${borderStyles.solid} ${colors['border-subtle']}`};
  padding: ${({ theme: { space } }) => space[16]};
  word-break: break-word;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    width: 50%;
    border-left: none;
    border-right: none;
  }
`;
