import { StyledErrorMessage } from 'common/components/atoms/ErrorMessage';
import styled from 'styled-components';

import EditableInput from '../EditableInput/EditableInput';

export const StyledInputWithConfirm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme: { space } }) => `${space[16]} 0`};
  border: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-inverse']}`};
  background: ${({ theme: { colors } }) => colors['gray-100']};
  margin-top: ${({ theme: { space } }) => `${space[16]}`};

  p {
    align-self: flex-start;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  width: 90%;
  margin: ${({ theme: { space } }) => `${space[8]}`};
  justify-content: space-between;
`;

export const StyledChangeInput = styled(EditableInput)`
  width: 90%;
  margin: ${({ theme: { space } }) => `${space[8]}`};
`;

export const StyledValidationText = styled(StyledErrorMessage)`
  margin-left: ${({ theme: { space } }) => `${space[8]}`};
`;
