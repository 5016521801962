import { StyledInput } from 'common/components/atoms/Input';
import styled from 'styled-components';

import { omniConfig } from '@omnipresentgroup/design-system';

const checkboxSize = '20px';
const checkboxBorderWidth = omniConfig.theme.borderWidths.s;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  user-select: none;
`;

export const StyledRadioInput = styled(StyledInput)`
  height: calc(${checkboxSize} + calc(${checkboxBorderWidth} * 2));
  width: calc(${checkboxSize} + calc(${checkboxBorderWidth} * 2));
`;

export const StyledLabel = styled.label`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  margin-left: ${({ theme: { space } }) => space[4]};
  margin-top: ${({ theme: { space } }) => space[4]};
`;
