import { Condition, Operators } from 'common/types';

export const arePreconditionsMet = (
  conditions: Condition,
  state: Record<string, any>,
): boolean => {
  const conditionKey = Object.keys(conditions)[0];

  if (conditionKey === 'Or') {
    return (conditions[conditionKey] as Condition[]).some((condition) =>
      arePreconditionsMet(condition, state),
    );
  } else if (conditionKey === 'And') {
    return (conditions[conditionKey] as Condition[]).every((condition) =>
      arePreconditionsMet(condition, state),
    );
  }

  const operator = conditionKey as Exclude<Operators, 'And' | 'Or'>;
  const attribute = conditions[operator]?.[0].Att || '';
  const attributeValue = conditions[operator]?.[1].String || '';
  switch (operator) {
    case 'Equals':
      return state[attribute] === attributeValue;
    case 'NotEquals':
      return state[attribute] !== attributeValue;
    case 'Greater':
      return state[attribute] > attributeValue;
    case 'GreaterEqual':
      return state[attribute] >= attributeValue;
    case 'Less':
      return state[attribute] < attributeValue;
    case 'LessEqual':
      return state[attribute] <= attributeValue;
    default:
      return false;
  }
};
