import type { ResourceItem } from 'omniplatform/manager/pages/ManagerDashboardPage/components/Resources';
import { useTrack } from 'utils/mixpanel/tracker';

import {
  Button,
  Icon,
  Inline,
  Typography,
} from '@omnipresentgroup/design-system';

import * as S from './Resource.styles';

export const Resource = ({ title, url, icon }: ResourceItem) => {
  const track = useTrack();
  const isExternal = url.startsWith('https://');

  const handleResourceClick = (externalURL: string, linkTitle: string) => {
    window.open(externalURL, '_blank', 'noopener,noreferrer');
    track('View Resource Clicked', { resource: linkTitle });
  };

  const content = (
    <Inline
      bg="primary"
      border="subtle"
      justify="space-between"
      px="32"
      py="16"
      align="center"
      data-testid={`${title.toLowerCase()}-url`}
      radius="m"
    >
      <Inline align="center">
        {icon}
        <Typography as="label" size="16" weight="medium">
          {title}
        </Typography>
      </Inline>
      <Inline bg="page" radius="s" minH={40} minW={40}>
        <Button variant="tertiary" fullWidth>
          {isExternal ? (
            <Icon
              size="24"
              icon="ChevronRight"
              data-testid="external-link-icon"
            />
          ) : (
            <Icon
              size="24"
              icon="ExternalLink"
              data-testid="internal-link-icon"
            />
          )}
        </Button>
      </Inline>
    </Inline>
  );

  if (isExternal) {
    return (
      <S.ExternalLink
        onClick={() => handleResourceClick(url, title)}
        data-testid={`${title.toLowerCase()}-url`}
      >
        {content}
      </S.ExternalLink>
    );
  }

  return (
    <S.InternalLink data-testid={`${title.toLowerCase()}-url`} to={url}>
      {content}
    </S.InternalLink>
  );
};
