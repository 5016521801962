import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import { omniConfig } from '@omnipresentgroup/design-system';

import { CircularProgressbarContainer } from './PercentageProgressCircular.style';

export const PercentageComponent = ({
  percentage,
  text,
  size,
}: {
  percentage: number;
  text: string;
  size: number;
}) => {
  return (
    <CircularProgressbarContainer
      data-testid="percentage-component"
      size={size}
    >
      <CircularProgressbar
        value={percentage}
        text={text}
        styles={buildStyles({
          textColor: omniConfig.theme.colors['text-default'],
          pathColor: omniConfig.theme.colors['icon-default'],
          trailColor: omniConfig.theme.colors['gray-200'],
        })}
      />
    </CircularProgressbarContainer>
  );
};
