import styled, { css } from 'styled-components';

import { omniConfig } from '@omnipresentgroup/design-system';

const whiteText = css`
  color: ${({ theme: { colors } }) => `${colors['text-on-color']}`};
`;

const normalText = css`
  color: ${({ theme: { colors } }) => `${colors['text-default']}`};
`;

const regularText = css`
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.regular}`};
`;

const boldText = css`
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.bold}`};
`;

/**
 * @deprecated
 * The use of the following styles in discouraged in favor of using Typography from the OmniDS components library
 * https://omnipresentgroup.github.io/OmniDS/?path=/docs/components-typography--default
 * Please reach out to #web-platform-support for questions about using the OmniDS components
 */
export const StyledContent = styled.p<{ wrapContent?: boolean }>`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  ${(props) => (props.wrapContent ? `max-width: 480px;` : ``)}
`;

/**
 * @deprecated
 * The use of the following styles in discouraged in favor of using Typography from the OmniDS components library
 * https://omnipresentgroup.github.io/OmniDS/?path=/docs/components-typography--default
 * Please reach out to #web-platform-support for questions about using the OmniDS components
 */
export const StyledSubtitle = styled.h4<{
  colour?: keyof typeof omniConfig.theme.colors;
  bold?: boolean;
}>`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[4]}`};
  color: ${({ theme: { colors: themeColours }, colour }) =>
    colour ? themeColours[colour] : themeColours['gray-500']};
  ${(props) => (props.bold ? boldText : regularText)};
  margin: 0;
  text-overflow: ellipsis;
`;

/**
 * @deprecated
 * The use of the following styles in discouraged in favor of using Typography from the OmniDS components library
 * https://omnipresentgroup.github.io/OmniDS/?path=/docs/components-typography--default
 * Please reach out to #web-platform-support for questions about using the OmniDS components
 */
export const StyledSmallCopy = styled.p<{ white?: boolean }>`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[0]}`};
  ${(props) => (props.white ? whiteText : normalText)};
  margin: 0;
`;

/**
 * @deprecated
 * The use of the following styles in discouraged in favor of using Typography from the OmniDS components library
 * https://omnipresentgroup.github.io/OmniDS/?path=/docs/components-typography--default
 * Please take this PR https://github.com/OmnipresentGroup/OmniPlatform/pull/2386 //TODO: Replace with current PR
 * as reference to proceed with the replacement
 * Reach out to #web-platform-support for questions about using the OmniDS components
 */
export const StyledBodyCopy = styled.p<{
  white?: boolean;
  bold?: boolean;
}>`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  white-space: pre-line;
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[2]}`};
  ${(props) => (props.white ? whiteText : normalText)};
  ${(props) => (props.bold ? boldText : regularText)};
  margin: 0;
`;

/**
 * @deprecated
 * The use of the following styles in discouraged in favor of using Typography from the OmniDS components library
 * https://omnipresentgroup.github.io/OmniDS/?path=/docs/components-typography--default
 * Please take this PR https://github.com/OmnipresentGroup/OmniPlatform/pull/2384 //TODO: Replace with current PR
 * as a reference to proceed with the replacement
 * Reach out to #web-platform-support for questions about using the OmniDS components
 */
export const StyledBoldCopy = styled.h4<{ white?: boolean }>`
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => `${fontSizes[2]}`};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.medium}`};
  ${(props) => (props.white ? whiteText : normalText)};
  margin: 0;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`;
