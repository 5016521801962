import { useLSPEmployeesQuery, useLspUsersQuery } from 'utils/queries';
import { useLspEmploymentsQuery } from 'utils/queries/employees/useEmploymentHistoryQuery/useEmploymentHistoryQuery';
import { useEmployersQuery } from 'utils/queries/employers/useEmployerQuery';
import { usePayrollProvidersQuery } from 'utils/queries/payrollProviders/usePayrollProvidersQuery';

const useLSPRelations = ({ lspId }: { lspId: string }) => {
  const lspEmployeesQuery = useLSPEmployeesQuery({ lspId });
  const lspUsersQuery = useLspUsersQuery(lspId);
  const lspEmploymentsQuery = useLspEmploymentsQuery({ lspId });
  const lspPayrollProviderQuery = usePayrollProvidersQuery({ lspId });
  const lspEmployersQuery = useEmployersQuery({ lspId });

  const isLoading =
    lspEmployeesQuery.isLoading ||
    lspEmploymentsQuery.isLoading ||
    lspUsersQuery.isLoading ||
    lspPayrollProviderQuery.isLoading ||
    lspEmployersQuery.isLoading;

  const isError =
    lspEmployeesQuery.isError ||
    lspUsersQuery.isError ||
    lspEmploymentsQuery.isError ||
    lspPayrollProviderQuery.isError ||
    lspEmployersQuery.isError;

  const isSuccess =
    lspEmployeesQuery.isSuccess &&
    lspUsersQuery.isSuccess &&
    lspEmploymentsQuery.isSuccess &&
    lspPayrollProviderQuery.isSuccess &&
    lspEmployersQuery.isSuccess;

  const hasConnectedEntities =
    isSuccess &&
    (lspEmployeesQuery.data?.length ||
      lspEmploymentsQuery.data?.length ||
      lspUsersQuery.data?.length ||
      lspPayrollProviderQuery.data?.length ||
      lspEmployersQuery.data?.length);

  return { isLoading, isError, isSuccess, hasConnectedEntities };
};

export { useLSPRelations };
