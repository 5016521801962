import { useContext, useReducer, useState } from 'react';

import { queryClient } from 'app/queryClient';
import axios from 'axios';
import { HTTPStatusCodesEnum } from 'common/constants';
import { PAYROLL_CYCLE_LABELS, PayrollCycle } from 'common/types';
import { PayrollInstanceDetailPageContext } from 'omniplatform/admin/pages/PayrollInstanceDetailPage/PayrollInstanceDetailPageContext';
import { payrollKeys } from 'utils/queries/payroll/keys';
import { useGrossToNetManualFileMutation } from 'utils/queries/payroll/useGrossToNetManualFileMutation';

import {
  Button,
  FileUploader,
  fileUploadReducer,
  FileUploadState,
  Modal,
  RadioButtonGroup,
  Stack,
  useModal,
} from '@omnipresentgroup/design-system';

export const LspBillUploadModal = () => {
  const { modalProps } = useModal({
    trigger: <Button onClick={() => {}}>Upload</Button>,
  });

  const [payrollCycle, setPayrollCycle] = useState<string>('1');

  const [uploadedFiles, dispatchFileUpload] = useReducer(fileUploadReducer, []);
  const [uploadError, setUploadError] = useState<string | undefined>();

  const { mutate: uploadFileMutate } = useGrossToNetManualFileMutation();

  const { payrollPeriod } = useContext(PayrollInstanceDetailPageContext);

  const uploadFile = (fileState: FileUploadState) => {
    if (!fileState.props) {
      return;
    }

    // delete previous file state
    dispatchFileUpload({
      type: 'deleted',
      id: fileState.id,
      data: fileState,
    });

    uploadFileMutate(
      {
        file: fileState.props,
        payrollPeriod,
        payrollCycle,
        updateUploadProgress: (progress: ProgressEvent) => {
          dispatchFileUpload({
            type: 'updated',
            data: { ...fileState, uploadProgress: progress.loaded },
          });
        },
      },
      {
        onSuccess: () => {
          setUploadError(undefined);
          dispatchFileUpload({
            type: 'succeeded',
            data: { ...fileState, uploadProgress: 100 },
          });
          queryClient.invalidateQueries(
            payrollKeys.grossToNetManualView(payrollPeriod),
          );
        },
        onError: (err) => {
          if (
            axios.isAxiosError(err) &&
            typeof err?.response?.data === 'string' &&
            err.response.status === HTTPStatusCodesEnum.BAD_REQUEST
          ) {
            setUploadError(err.response.data);
          }

          dispatchFileUpload({
            type: 'failed',
            data: { ...fileState, uploadProgress: 100 },
          });
        },
      },
    );

    dispatchFileUpload({
      type: 'started',
      data: { ...fileState, uploadProgress: 0 },
    });
  };

  const radioOptions = Object.values(PayrollCycle).map((s) => ({
    id: `radio-btn-payroll-cycle-${s}`,
    label: PAYROLL_CYCLE_LABELS[s],
    checked: payrollCycle === s,
    value: s,
    name: 'radio-btn-default',
  }));

  return (
    <Modal {...modalProps} span={4}>
      <Modal.Header title="Upload LSP Bill" />
      <Modal.Body>
        <Stack>
          <FileUploader
            id="lsp-bill-uploader"
            files={uploadedFiles}
            handleFileUpload={(file) => {
              uploadFile(file);
            }}
            handleFileDelete={(file) => {
              dispatchFileUpload({
                type: 'deleted',
                id: file.id,
                data: file,
              });
            }}
            handleFileReUpload={(file) => {
              uploadFile(file);
            }}
            fileDropInputProps={{
              id: 'lsp-bill-uploader-file-input',
              errorMessage: uploadError,
            }}
          />
          <RadioButtonGroup
            id="radio-btn-group-payroll-cycle"
            onChange={(e) => {
              const id = e.target.id;
              const selectedOption = radioOptions.find(
                (option) => option.id === id,
              );

              if (!selectedOption) {
                return;
              }
              setPayrollCycle(selectedOption.value);
            }}
            options={radioOptions}
          />
        </Stack>
      </Modal.Body>
    </Modal>
  );
};
