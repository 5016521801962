import { transformUser } from 'app/store/app.transformers';
import { asyncActionKeys as employeeActionKeys } from 'common/store/actions/redux.constants';
import { transformEmployeeFromEmailInvitation } from 'common/store/transformers/employees.transformer';
import adminActionKeys from 'omniplatform/admin/store/actions/reduxConstants';
import { getEmployeeSelector } from 'omniplatform/employee/store/selectors/employee.selector';
import { ACCOUNT_DETAILS_PAGE, VERIFY_EMAIL_PAGE } from 'paths';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { getTracker } from 'utils/mixpanel/tracker';
import { createActionObject } from 'utils/redux-utils';
import { getUserMainRole } from 'utils/user';

import {
  confirmUserEmailUpdateRequest,
  getUserRequest,
  sendPlatformInvitationRequest,
  updateUserEmailRequest,
  updateUserRequest,
  verifyEmailChangeTokenRequest,
} from '../../api/user.api';
import appHistory from '../../appHistory';
import {
  asyncActionKeys as appActionKeys,
  SET_ERROR_MESSAGE,
  SET_USER_ROLE,
} from '../app.constants';
import { getUserSelector } from '../app.selectors';

function* getUser({ payload: { sub } }) {
  try {
    const tracker = getTracker();
    const { data: user } = yield call(getUserRequest, sub);
    const userRole = getUserMainRole(user.roles);

    /** @todo Should really the company here or should a UI component request it? */
    if (userRole.type === 'manager') {
      yield put(
        createActionObject(
          adminActionKeys.GET_COMPANY_START,
          userRole.tenantId,
        ),
      );
      yield take(adminActionKeys.GET_COMPANY_END);
    }

    /** @todo Do we really need to load employment data from an employee user at this moment? */
    if (userRole.type === 'employee') {
      yield put(
        createActionObject(employeeActionKeys.GET_EMPLOYEE_START, userRole.id),
      );
      yield take(employeeActionKeys.GET_EMPLOYEE_END);
    }

    yield put(createActionObject(SET_USER_ROLE, userRole.type));
    yield put(createActionObject(appActionKeys.GET_USER_END, user));

    tracker.identify(sub);
    tracker.register({ userRole: userRole.type });
    tracker.people.set_once({ mainRole: userRole.type });
  } catch (e) {
    yield put(createActionObject(appActionKeys.GET_USER_ERROR, e));
  }
}

function* startEmailChange({ payload }) {
  try {
    const user = yield select(getUserSelector);
    yield call(updateUserEmailRequest, user.authId, payload);
    yield put(createActionObject(appActionKeys.CREATE_EMAIL_CHANGE_END));
  } catch (e) {
    yield put(createActionObject(appActionKeys.CREATE_EMAIL_CHANGE_ERROR, e));
  }
}

function* updateUser({ payload }) {
  try {
    const updatedUserFields = { ...payload };
    const user = yield select(getUserSelector);
    // Removing email from updated fields as it is dealt with in the start email change saga
    // TODO make API ignore email field and remove the line below
    delete updatedUserFields.email;
    yield call(updateUserRequest, user.authId, updatedUserFields);
    const { data: updatedUser } = yield call(getUserRequest, user.authId);
    yield put(createActionObject(appActionKeys.GET_USER_END, updatedUser));
    if (!payload.seenPrivacyPolicy) {
      appHistory.push(ACCOUNT_DETAILS_PAGE);
    }
    yield put(createActionObject(appActionKeys.UPDATE_USER_END));
  } catch (e) {
    yield put(createActionObject(appActionKeys.UPDATE_USER_ERROR, e));
  }
}

function* verifyEmailChange({ payload }) {
  try {
    const { authId } = yield select(getUserSelector);
    yield call(verifyEmailChangeTokenRequest, authId, payload);
    yield put(createActionObject(appActionKeys.VERIFY_EMAIL_CHANGE_END, true));
    window.history.pushState({}, null, VERIFY_EMAIL_PAGE);
  } catch (e) {
    if (e?.response?.status === 410) {
      yield put(
        createActionObject(
          SET_ERROR_MESSAGE,
          'Your email change request has expired, please try again.',
        ),
      );
      appHistory.push('/account-details');
    }
    yield put(createActionObject(appActionKeys.VERIFY_EMAIL_CHANGE_ERROR, e));
  }
}

function* confirmEmailChange({ payload }) {
  try {
    const { authId } = yield select(getUserSelector);
    yield call(confirmUserEmailUpdateRequest, authId, payload.token);
    payload.logout({ returnTo: window.location.origin });
    yield put(createActionObject(appActionKeys.CONFIRM_EMAIL_CHANGE_END));
  } catch (e) {
    yield put(createActionObject(appActionKeys.CONFIRM_EMAIL_CHANGE_ERROR, e));
  }
}

function* sendPlatformInvitation({ payload: { userId } }) {
  try {
    const { data: user } = yield call(sendPlatformInvitationRequest, userId);
    const userRole = getUserMainRole(user.roles);

    if (userRole.type === 'employee') {
      const currentSelectedEmployee = yield select(getEmployeeSelector);

      const userEmployee = transformUser(user);

      yield put(
        createActionObject(
          employeeActionKeys.GET_EMPLOYEE_END,
          transformEmployeeFromEmailInvitation({
            userEmployee,
            currentSelectedEmployee,
          }),
        ),
      );
    } else if (userRole.type === 'manager') {
      yield put(
        createActionObject(
          adminActionKeys.GET_COMPANY_MANAGER_END,
          transformUser(user),
        ),
      );
    }

    yield put(createActionObject(appActionKeys.SEND_PLATFORM_INVITATION_END));
  } catch (e) {
    yield put(
      createActionObject(appActionKeys.SEND_PLATFORM_INVITATION_ERROR, e),
    );
  }
}

export default [
  takeLatest(appActionKeys.GET_USER_START, getUser),
  takeLatest(appActionKeys.UPDATE_USER_START, updateUser),
  takeLatest(appActionKeys.VERIFY_EMAIL_CHANGE_START, verifyEmailChange),
  takeLatest(appActionKeys.CREATE_EMAIL_CHANGE_START, startEmailChange),
  takeLatest(appActionKeys.CONFIRM_EMAIL_CHANGE_START, confirmEmailChange),
  takeLatest(
    appActionKeys.SEND_PLATFORM_INVITATION_START,
    sendPlatformInvitation,
  ),
];
