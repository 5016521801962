import { AxiosRequestConfig } from 'axios';
import { IS_TEST_ENV } from 'common/constants';
import {
  ApprovePayReportPayload,
  BulkRequest,
  DataProcessingErrorApiResponse,
  GrossToNetBulkDeleteApiResponse,
  GrossToNetDetailView,
  GrossToNetExportApiResponse,
  GrossToNetFile,
  GrossToNetFilesApiResponse,
  GrossToNetFileUpload,
  GrossToNetManualView,
  GrossToNetProcessingStatus,
  LSPData,
  PayReportBulkApproveApiResponse,
  PayrollJustificationAPIPayload,
  PayrollJustificationAPIQuery,
  PayrollReportDataCurrency,
  PayrollReportPreviewApiResponse,
  PublishPayReportPayload,
  UpdateGrossToNetPayload,
  WorkflowTransitionPayReportPayload,
} from 'common/types';

import { payrollTaxonomyClient } from '../../api';

type GrossToNetPayload = {
  formData: FormData;
  onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
};

export const GROSS_TO_NET_BASE_API_ENDPOINT = '/gross-to-net';
export const DATA_PROCESSING_ERRORS_BASE_API_ENDPOINT =
  'data-processing-errors';
export const PAY_REPORT_PREVIEW = 'report/payroll/preview';
export const APPROVE_PAY_REPORT = 'approval';
export const PUBLISH_PAY_REPORT = 'publish';
export const WORKFLOW_TRANSITION_PAY_REPORT = 'approval-workflow';

export const MAX_GROSS_TO_NET_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const sendGrossNetPayroll = ({
  formData,
  onUploadProgress,
}: GrossToNetPayload) => {
  return payrollTaxonomyClient.post<GrossToNetFileUpload[]>(
    GROSS_TO_NET_BASE_API_ENDPOINT,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    },
  );
};

export const sendGrossNetPayrollComplete = ({
  formData,
  onUploadProgress,
}: GrossToNetPayload) => {
  return payrollTaxonomyClient.post<GrossToNetFileUpload[]>(
    `${GROSS_TO_NET_BASE_API_ENDPOINT}/complete`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    },
  );
};

export type ManualGrossToNetPayload = {
  file: File;
  payrollPeriod: string;
  payrollCycle: string;
  updateUploadProgress?: (progress: ProgressEvent) => void;
};

export const uploadManualGrossToNet = ({
  file,
  payrollPeriod,
  payrollCycle,
  updateUploadProgress,
}: ManualGrossToNetPayload) => {
  const formData = new FormData();

  formData.append('fileContent', file);
  formData.append('source', 'MANUAL');
  formData.append('payrollPeriod', payrollPeriod);
  formData.append('fileType', 'PAY_REGISTER');
  formData.append('payrollCycle', payrollCycle);

  return payrollTaxonomyClient.post<GrossToNetFileUpload[]>(
    `${GROSS_TO_NET_BASE_API_ENDPOINT}/manual-ingestion`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // onUploadProgress not supported https://github.com/mswjs/msw/issues/1131
      ...(IS_TEST_ENV
        ? {}
        : {
            onUploadProgress: (progressEvent: ProgressEvent) =>
              updateUploadProgress && updateUploadProgress(progressEvent),
          }),
    },
  );
};

export const getGrossToNetFiles = (params: {
  payrollPeriod?: string;
  lspId?: string;
  country?: string;
  pageSize?: number;
  source?: string;
  payrollCycle?: string;
  pageIndex?: number;
}) => {
  return payrollTaxonomyClient.get<GrossToNetFilesApiResponse>(
    GROSS_TO_NET_BASE_API_ENDPOINT,
    { params },
  );
};

export const getDataProcessingErrors = (params: {
  dataFrameIdsList: string[];
}) => {
  return payrollTaxonomyClient.get<DataProcessingErrorApiResponse>(
    DATA_PROCESSING_ERRORS_BASE_API_ENDPOINT,
    {
      params,
    },
  );
};

export const updateGrossToNetFile = (files: UpdateGrossToNetPayload) =>
  payrollTaxonomyClient.patch<GrossToNetFileUpload[]>(
    GROSS_TO_NET_BASE_API_ENDPOINT,
    files,
  );

export const updateGrossToNetLSPData = (gtnId: string, lspData: LSPData) =>
  payrollTaxonomyClient.put<GrossToNetFile>(
    `${GROSS_TO_NET_BASE_API_ENDPOINT}/${gtnId}/lsp-data`,
    lspData,
  );

export const getGrossToNet = (dataFrameId: string) =>
  payrollTaxonomyClient.get<GrossToNetFile>(
    `${GROSS_TO_NET_BASE_API_ENDPOINT}/${dataFrameId}`,
  );

export const deleteGrossToNetFile = (dataFrameId: string) =>
  payrollTaxonomyClient.delete<GrossToNetExportApiResponse>(
    `${GROSS_TO_NET_BASE_API_ENDPOINT}/${dataFrameId}`,
  );

export const deleteGrossToNetFileBulk = (request: BulkRequest) => {
  const { type: requestType, ...requestData } = request;
  return payrollTaxonomyClient.delete<GrossToNetBulkDeleteApiResponse>(
    `${GROSS_TO_NET_BASE_API_ENDPOINT}/bulk`,
    {
      params:
        request.type === 'dataFrameIds'
          ? { grossToNetIds: request.dataFrameIds }
          : { ...requestData },
    },
  );
};

export const getPayrollReportPreview = (
  dataFrameId: string,
  params: {
    pageSize?: number;
    pageIndex?: number;
    dataCurrency: PayrollReportDataCurrency;
    variancePayrollPeriod?: string;
    variancePayrollCycle?: string;
  },
) => {
  return payrollTaxonomyClient.get<PayrollReportPreviewApiResponse>(
    `${PAY_REPORT_PREVIEW}/${dataFrameId}`,
    { params },
  );
};

export const getPayrollReportPreviewBulk = (
  dataFrameIds: string[],
  dataCurrency: PayrollReportDataCurrency,
  variancePayrollPeriod?: string,
  variancePayrollCycle?: string,
) => {
  return payrollTaxonomyClient.get<PayrollReportPreviewApiResponse[]>(
    `${PAY_REPORT_PREVIEW}`,
    {
      params: {
        dataFrameIds,
        dataCurrency,
        variancePayrollPeriod,
        variancePayrollCycle,
      },
    },
  );
};

export const approvePayReport = ({ dataFrameId }: ApprovePayReportPayload) => {
  return payrollTaxonomyClient.post(APPROVE_PAY_REPORT, { dataFrameId });
};

export const publishPayReport = ({ dataFrameId }: PublishPayReportPayload) => {
  return payrollTaxonomyClient.post(PUBLISH_PAY_REPORT, { dataFrameId });
};

export const workflowTransitionPayReport = ({
  dataFrameId,
  newStatus,
}: WorkflowTransitionPayReportPayload) => {
  return payrollTaxonomyClient.post(WORKFLOW_TRANSITION_PAY_REPORT, {
    dataFrameId,
    newStatus,
  });
};

export const publishPayReportBulk = (request: BulkRequest) => {
  const { type: _requestType, ...requestData } = request;
  return payrollTaxonomyClient.post(`${PUBLISH_PAY_REPORT}/bulk`, {
    ...requestData,
  });
};

export const approvePayReportBulk = (request: BulkRequest) => {
  const { type: _requestType, ...requestData } = request;
  return payrollTaxonomyClient.post<PayReportBulkApproveApiResponse>(
    `${APPROVE_PAY_REPORT}/bulk`,
    { ...requestData },
  );
};

export const workflowTransitionPayReportBulk = (
  request: BulkRequest & { newStatus: GrossToNetProcessingStatus },
) => {
  const { type: _requestType, ...requestData } = request;
  return payrollTaxonomyClient.post<PayReportBulkApproveApiResponse>(
    `${WORKFLOW_TRANSITION_PAY_REPORT}/bulk`,
    {
      ...requestData,
    },
  );
};

export const executePartialGTNProcessing = (data: {
  lspId: string;
  country: string;
  payrollPeriod: string;
}) => {
  return payrollTaxonomyClient.post(
    'orchestration/partial-gtn-processing',
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const executeGTNExport = (data: { payrollPeriod: string }) => {
  return payrollTaxonomyClient.post('orchestration/gtn-export', data);
};

export const executeGTNDistribute = (data: { payrollPeriod: string }) => {
  return payrollTaxonomyClient.post('orchestration/gtn-distribute', data);
};

export const getGrossToNetManualView = (data: { payrollPeriod: string }) => {
  return payrollTaxonomyClient.get<GrossToNetManualView[]>(
    `${GROSS_TO_NET_BASE_API_ENDPOINT}/view/manual`,
    {
      params: { payrollPeriod: data.payrollPeriod },
    },
  );
};

export const getGrossToNetDetailView = (data: {
  country: string;
  payrollCycle: string;
  lspId: string;
  payrollPeriod: string;
}) => {
  return payrollTaxonomyClient.get<GrossToNetDetailView>(
    `${GROSS_TO_NET_BASE_API_ENDPOINT}/view/detail`,
    {
      params: {
        country: data.country,
        payrollCycle: data.payrollCycle,
        lspId: data.lspId,
        payrollPeriod: data.payrollPeriod,
      },
    },
  );
};

export const exportGrossToNets = (request: BulkRequest) => {
  const { type: requestType, ...requestData } = request;
  return payrollTaxonomyClient.get<GrossToNetExportApiResponse>(
    `${GROSS_TO_NET_BASE_API_ENDPOINT}/export`,
    {
      params:
        request.type === 'dataFrameIds'
          ? { grossToNetIds: request.dataFrameIds }
          : { ...requestData },
    },
  );
};

export const savePayrollJustification = (
  data: PayrollJustificationAPIPayload,
) => payrollTaxonomyClient.post(`justification`, data);

export const getPayrollJustifications = (
  params: PayrollJustificationAPIQuery,
) => payrollTaxonomyClient.get(`justification`, { params });

export const getPayrollJustificationsByGTNIDs = (grossToNetIds: string[]) =>
  payrollTaxonomyClient.get('justification/by-gtn-ids', {
    params: { grossToNetIds },
  });

export const deletePayrollJustification = (justificationId: string) =>
  payrollTaxonomyClient.delete<GrossToNetExportApiResponse>(
    `justification/${justificationId}`,
  );
