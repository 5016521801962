import { borderSubtle } from 'common/constants/border';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { omniConfig } from '@omnipresentgroup/design-system';

import { StyledDropdownInput } from './DropdownInput.styles';

export const customDropdownStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: borderSubtle,
    '&:hover': {
      backgroundColor: omniConfig.theme.colors['background-hover'],
    },
    '&:focus': {
      backgroundColor: omniConfig.theme.colors['gray-200'],
    },
    backgroundColor: state.isSelected
      ? omniConfig.theme.colors['gray-200']
      : omniConfig.theme.colors['field-background-default'],
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    padding: omniConfig.theme.space[4],
    border: state.isFocused
      ? `${omniConfig.theme.borderWidths.s} ${omniConfig.theme.borderStyles.solid} ${omniConfig.theme.colors['border-inverse']}`
      : `${omniConfig.theme.borderWidths.s} ${omniConfig.theme.borderStyles.solid} ${omniConfig.theme.colors['border-strong']}`,
    borderRadius: omniConfig.theme.radii.s,
  }),
  container: (provided) => ({
    ...provided,
    border: 'none',
  }),
};

// eslint-disable-next-line react/prop-types
const formatOptionLabel = ({ label, emoji }) => (
  <div className={`custom-option-container ${emoji && 'padded'}`}>
    {emoji && <span className="emoji">{emoji}</span>}
    <div className="custom-option-text">{label}</div>
  </div>
);

const DropdownInput = ({
  options,
  onSelect,
  placeholder,
  value,
  className,
  onFocus,
  error,
  isDisabled,
  label,
  isLoading,
  formatGroupLabel,
}) => {
  return (
    <StyledDropdownInput
      error={error}
      className={className}
      data-testid={`${options.name}-dropdown-input`}
    >
      <Select
        aria-label={label}
        onFocus={() => onFocus(options.name)}
        styles={customDropdownStyles}
        value={value}
        inputId={options.name}
        options={options.options}
        formatOptionLabel={formatOptionLabel}
        placeholder={placeholder}
        isDisabled={isDisabled}
        // TODO: Change this to not pass event.label and just use event
        onChange={(event) => onSelect(options.name, event.label, event)}
        formatGroupLabel={formatGroupLabel}
        isLoading={isLoading}
      />
    </StyledDropdownInput>
  );
};

DropdownInput.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onFocus: PropTypes.func,
  error: PropTypes.string,
  label: PropTypes.string,
  formatGroupLabel: PropTypes.func,
};

DropdownInput.defaultProps = {
  placeholder: 'Select...',
  value: null,
  className: '',
  onFocus: () => {},
  error: '',
  isLoading: false,
  isDisabled: false,
  formatGroupLabel: () => {},
};

export default DropdownInput;
