import { useEffect } from 'react';

import { getUserTenantId } from 'app/store/app.selectors';
import { TransformedUser } from 'app/store/app.transformers';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

export const useLaunchDarkly = (user: TransformedUser) => {
  const ldClient = useLDClient();

  const userId = user?.userId;
  const userEmail = user?.email;

  const companyId = useSelector(getUserTenantId);

  useEffect(() => {
    if (userId && userEmail && ldClient?.identify) {
      ldClient.identify({
        kind: 'multi',
        user: {
          key: userId,
          anonymous: true,
          email: userEmail,
        },
        organization: {
          key: companyId,
          anonymous: true,
        },
      });
    }
  }, [ldClient, userId, userEmail, companyId]);
};
