import { ManagersEmployeeExpensesReducerState } from 'common/types/reduxStore';
import { AnyAction } from 'redux';

import actionKeys from '../actions/reduxConstants';

const initialState: ManagersEmployeeExpensesReducerState = {
  employeeExpenses: {},
  selectedExpense: {},
};

export const managersEmployeeExpensesReducer = (
  state = initialState,
  action: AnyAction,
): ManagersEmployeeExpensesReducerState => {
  switch (action.type) {
    case actionKeys.GET_EMPLOYEE_EXPENSES_END:
      return {
        ...state,
        employeeExpenses: action.payload,
      };
    case actionKeys.GET_EMPLOYEE_EXPENSE_END:
      return {
        ...state,
        selectedExpense: action.payload,
      };
    default:
      return state;
  }
};
