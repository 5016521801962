/**
 * Given a country that has multiple currencies defined in `countries-list`
 * we return the specific one. i.e. USD,USN,USS -> USN
 * Or
 * to override currency and not consider the one defined in `countries-list`
 * we return the override currency. i.e. For Croatia EUR instead of HRK
 */

export const defaultCountryCurrency: { [key: string]: string } = {
  Croatia: 'EUR',
  Switzerland: 'CHF',
  'El Salvador': 'USD',
  Uruguay: 'UYU',
  Chile: 'CLP',
} as const;

export const currencyCodesMinorUnitsExceptions: { [key: string]: number } = {
  BHD: 3,
  BIF: 0,
  BYR: 0,
  CLF: 0,
  CLP: 0,
  CVE: 0,
  DJF: 0,
  GNF: 0,
  IDR: 0,
  IQD: 0,
  IRR: 0,
  ISK: 0,
  JOD: 3,
  JPY: 0,
  KMF: 0,
  KPW: 0,
  KRW: 0,
  KWD: 0,
  LAK: 0,
  LBP: 0,
  LYD: 3,
  MGA: 1,
  MOP: 1,
  MRO: 1,
  OMR: 3,
  PYG: 0,
  RWF: 0,
  SLL: 0,
  STD: 0,
  TND: 3,
  UYI: 0,
  VND: 0,
  VUV: 0,
  XAF: 0,
  XOF: 0,
  XPF: 0,
} as const;
