import { HTTPStatusCodesEnum } from 'common/constants';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

export const documentStorageHandlers = [
  rest.get(
    composeEndpoint('/document-storage/presignedpost'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({
          url: '/upload-document-url',
          fields: {
            key: 'filename',
          },
        }),
      ),
  ),
  rest.post(composeEndpoint('/upload-document-url'), (req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.OK)),
  ),
];
