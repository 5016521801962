import { useHistory } from 'react-router-dom';

import { ModalWrapper } from 'common/components/molecules/ModalWrapper';
import { EMPLOYEES_PATH, getEmployeeTerminationRequestsPath } from 'paths';
import { Check } from 'react-feather';
import { useSelectUserRoles } from 'utils/hooks';
import { useTrack } from 'utils/mixpanel/tracker';

import { Center, Stack, Typography } from '@omnipresentgroup/design-system';

import { StyledCheckIcon } from './TerminationRequestSuccessModal.styles';

type TerminationRequestSuccessModalProps = {
  name: string;
  companyId: string;
};

export const TerminationRequestSuccessModal = ({
  name,
  companyId,
}: TerminationRequestSuccessModalProps) => {
  const history = useHistory();
  const { isManager } = useSelectUserRoles();
  const track = useTrack();

  const onClickClose = () => {
    track('Close On Termination Request Success Modal Clicked');
    history.push(
      isManager
        ? `${EMPLOYEES_PATH}#termination-requests`
        : getEmployeeTerminationRequestsPath(companyId),
    );
  };

  return (
    <ModalWrapper
      fixedWidth="550"
      data-testid="termination-request-success-modal"
      onClose={onClickClose}
      showCloseIcon
    >
      <Stack p="32" pt="16">
        <Center centerChildren>
          <StyledCheckIcon>
            <Check />
          </StyledCheckIcon>
        </Center>
        <Center centerText>
          <Typography as="span" size="16">
            The termination request for{' '}
            <Typography as="strong" size="16" weight="bold">
              {name}
            </Typography>{' '}
            has been submitted.
          </Typography>
        </Center>
        <Center centerText>
          <Typography as="span" size="16">
            We will contact you shortly.
          </Typography>
        </Center>
      </Stack>
    </ModalWrapper>
  );
};
