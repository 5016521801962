import { mockBenefitsCountryResponse } from '__mocks__/benefits/benefitsCountry.mock';
import { HTTPStatusCodesEnum } from 'common/constants';
import { BenefitsCountryFee } from 'common/types';
import {
  BenefitReportResponse,
  BenefitsClientEnrollmentResponse,
  BenefitsClientPreferencesResponse,
  BenefitsCountryApiResponse,
} from 'common/types/benefits';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

import { mockBenefitsClientEnrollmentResponse } from '../../__mocks__/benefits/benefitsEnrollment.mock';
import {
  mockBenefitsClientPreferencesResponses,
  mockCompanyBenefitsPreferencesResponses,
} from '../../__mocks__/benefits/benefitsPreferences.mock';

type PathParamsForBenefits = { clientId: string; countryCode: string };
type PathParamsForReports = { payrollInstanceId: string };

export const benefitsHandlers = [
  rest.get<undefined, PathParamsForBenefits, BenefitsClientEnrollmentResponse>(
    composeEndpoint('/benefits/client/:clientId'),
    (req, res, ctx) => {
      const clientId = req.params.clientId;
      const enrollment = req.url.searchParams.get('showEnrollment');

      if (!clientId || !enrollment) {
        return res(ctx.status(400));
      }

      const response = mockBenefitsClientEnrollmentResponse(clientId);

      return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
    },
  ),
  rest.get<undefined, PathParamsForBenefits, BenefitsCountryApiResponse>(
    composeEndpoint('/benefits/catalog/country/:countryCode'),
    (req, res, ctx) => {
      const countryCode = req.params.countryCode;
      const benefitsCountry = mockBenefitsCountryResponse.find(
        (item) => item.countryCode === countryCode,
      );
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(benefitsCountry as BenefitsCountryApiResponse),
      );
    },
  ),
  rest.get<
    undefined,
    PathParamsForBenefits,
    BenefitsClientPreferencesResponse | BenefitsClientPreferencesResponse[]
  >(
    composeEndpoint('/benefits/client/:clientId/preferences'),
    (req, res, ctx) => {
      const clientId = req.params.clientId;
      const countryCode = req.url.searchParams.get('countryCode');
      if (countryCode) {
        const response =
          mockBenefitsClientPreferencesResponses[countryCode.toUpperCase()];
        if (!response) {
          return res(ctx.status(404));
        }
        return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
      }

      const clientPreferences = mockCompanyBenefitsPreferencesResponses.filter(
        (item) => item.clientId === clientId,
      );
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(clientPreferences),
      );
    },
  ),
  rest.get<undefined, PathParamsForReports, BenefitReportResponse>(
    composeEndpoint('/benefits/billing/report/billing-summary'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({ url: '__BILLING_URL__' }),
      ),
  ),
  rest.get<undefined, PathParamsForReports, BenefitReportResponse>(
    composeEndpoint('/benefits/billing/report/payroll-summary'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({ url: '__PAYROLL_URL__' }),
      ),
  ),
  rest.get<undefined, PathParamsForReports, BenefitReportResponse>(
    composeEndpoint('/benefits/billing/report/global-summary'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json({ url: '__GLOBAL_URL__' }),
      ),
  ),
];

export const benefitsCountryErrorHandler = rest.get(
  composeEndpoint('/benefits/catalog/country'),
  (_req, res, ctx) => {
    return res.once(
      ctx.status(500),
      ctx.json({ error: 'Something went wrong' }),
    );
  },
);

export const benefitsCountryWithFeeHandler = rest.get<BenefitsCountryFee>(
  composeEndpoint('/benefits/catalog/country/:countryCode'),
  (_req, res, ctx) => {
    return res(
      ctx.status(HTTPStatusCodesEnum.OK),
      ctx.json({
        ...mockBenefitsCountryResponse[1],
        supplementaryBenefitsFee: undefined,
      }),
    );
  },
);
