import { TransformedSeat } from 'common/types';
import { formatCurrency } from 'utils/currencies';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';

export const getTableDetailsConfig = (seat: TransformedSeat) => [
  {
    name: 'name',
    label: 'Employee Name',
    value: seat?.name,
    type: 'string',
  },
  {
    name: 'country',
    label: 'Country of Work',
    value: seat?.countryName,
    type: 'string',
  },
  {
    name: 'desiredStartDate',
    label: 'Desired Start Date',
    value: formatISODate(seat?.desiredStartDate, DateFormats.Date, true, '-'),
    type: 'string',
  },
  {
    name: 'employeeId',
    label: 'Employee ID',
    value: seat?.id,
    type: 'string',
  },
  ...(seat?.billingCurrency
    ? [
        {
          name: 'annualBaseSalary',
          label: 'Annual Base Salary',
          type: 'string',
          value: seat?.employeeProfile?.annualBaseSalaryCurrency
            ? (formatCurrency(
                seat?.employeeProfile?.annualBaseSalaryAmount as number,
                seat?.employeeProfile?.annualBaseSalaryCurrency,
              ) as string)
            : '',
        },
        {
          name: 'billingCurrency',
          label: 'Billing Currency',
          value: seat?.billingCurrency,
          type: 'string',
        },
      ]
    : []),
];
