import { useContext, useState } from 'react';

import { GrossToNetFileLSPData, LSPData } from 'common/types';
import {
  PayrollInstanceDetailPageContext,
  VisibleModalEnum,
} from 'omniplatform/admin/pages/PayrollInstanceDetailPage/PayrollInstanceDetailPageContext';
import { useUpdateGrossToNetLSPDataMutation } from 'utils/queries/payroll/useUpdateGrossToNetLSPDataMutation';

import {
  Button,
  Inline,
  Modal,
  notifyError,
  notifySuccess,
} from '@omnipresentgroup/design-system';

import {
  getDefaultLSPData,
  getDefaultLSPDataFromGTNLSPData,
  LSPDataFields,
} from '../../PayrollInstanceDetailPage/components/LSPDataFields';

export const LspDataModal = ({
  gtnId,
  lspData,
  onLspDataUpdated,
  canEdit,
}: {
  gtnId: string;
  lspData: GrossToNetFileLSPData | null;
  onLspDataUpdated?: () => void;
  canEdit: boolean;
}) => {
  const { visibleModal, setVisibleModal } = useContext(
    PayrollInstanceDetailPageContext,
  );

  const [initialLspData, setInitialLspData] =
    useState<GrossToNetFileLSPData | null>(lspData);

  const isCreating = !initialLspData;

  const [editingLSPData, setEditingLSPData] = useState<LSPData>(
    initialLspData
      ? getDefaultLSPDataFromGTNLSPData(initialLspData)
      : getDefaultLSPData(),
  );
  const [isEditing, setIsEditing] = useState<boolean>(!lspData);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { mutate: updateGrossToNetLSPData } =
    useUpdateGrossToNetLSPDataMutation();

  return (
    <Modal
      {...{
        span: 10,
        open: visibleModal === VisibleModalEnum.gtnLspData,
        onOpenChange: (open) =>
          setVisibleModal(open ? VisibleModalEnum.gtnLspData : undefined),
      }}
    >
      <Modal.Header title="LSP data" />
      <Modal.Body>
        <LSPDataFields
          lspData={isEditing || isCreating ? editingLSPData : initialLspData}
          setLspData={setEditingLSPData}
          readOnly={!isEditing || isUpdating}
        />
      </Modal.Body>
      <Modal.Footer>
        <Inline gap="8">
          {isCreating && (
            <Button
              variant="secondary"
              onClick={() => setVisibleModal(undefined)}
            >
              Close
            </Button>
          )}
          {initialLspData && canEdit && (
            <Button
              variant="secondary"
              disabled={isUpdating}
              onClick={() => {
                if (isEditing) {
                  setIsEditing(false);
                } else {
                  setEditingLSPData({
                    ...getDefaultLSPDataFromGTNLSPData(initialLspData),
                  });
                  setIsEditing(true);
                }
              }}
            >
              {isEditing ? 'Reset' : 'Edit'}
            </Button>
          )}
          <Button
            loading={isUpdating}
            disabled={
              isEditing && !Object.values(editingLSPData).every(Boolean)
            }
            onClick={() => {
              if (isEditing) {
                setIsUpdating(true);
                updateGrossToNetLSPData(
                  { gtnId, lspData: editingLSPData },
                  {
                    onSuccess: (response) => {
                      notifySuccess({
                        title: `LSP Data successfully ${
                          isCreating ? 'added' : 'updated'
                        }`,
                      });
                      onLspDataUpdated && onLspDataUpdated();
                      setInitialLspData(response.lspData ?? editingLSPData);
                      setIsEditing(false);
                    },
                    onError: (error) => {
                      console.error(error);
                      notifyError({
                        title: `Error while ${
                          isCreating ? 'addding' : 'updating'
                        } LSP Data`,
                      });
                    },
                    onSettled: () => {
                      setIsUpdating(false);
                    },
                  },
                );
              } else {
                setVisibleModal(undefined);
              }
            }}
          >
            {isEditing ? 'Save' : 'Close'}
          </Button>
        </Inline>
      </Modal.Footer>
    </Modal>
  );
};
