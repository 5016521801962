export const companyOnboardingStages = [
  {
    label: 'T&C and Proposal signed',
    value: 'T&C and Proposal signed',
  },
  {
    label: 'Next steps and client KYB',
    value: 'Next steps and client KYB',
  },
  {
    label: 'Setup Fee Invoice and OmniManager Invite',
    value: 'Setup Fee Invoice and OmniManager Invite',
  },
  {
    label: 'Client Employee Onboarding',
    value: 'Client Employee Onboarding',
  },
  {
    label: 'Employee Contract review',
    value: 'Employee Contract review',
  },
  {
    label: 'Email introduction to LSP',
    value: 'Email introduction to LSP',
  },
  {
    label: 'Employment contract signed',
    value: 'Employment contract signed',
  },
  {
    label: 'Payroll onboarding',
    value: 'Payroll onboarding',
  },
  {
    label: 'Onboarding complete',
    value: 'Onboarding complete',
  },
];
