import { ExpenseStatus } from 'common/components/molecules/ExpenseStatus/ExpenseStatus';
import { StyledTitleCell } from 'common/components/organisms/TableWithTitle/TableWithTitle.styles';

// eslint-disable-next-line react/prop-types
const TitleCustomCell = ({ title }) => (
  <StyledTitleCell>{title}</StyledTitleCell>
);

const StatusCustomCell = (expense) => <ExpenseStatus {...expense} />;

export const employeeExpenseTableHeaders = [
  {
    headerTitle: 'Spent at',
    customCell: TitleCustomCell,
    maxWidth: true,
  },
  {
    headerTitle: 'Amount',
    fieldOnObject: 'amount',
  },
  {
    headerTitle: 'Date spent',
    fieldOnObject: 'dateSpent',
  },
  {
    headerTitle: 'Status',
    align: 'center',
    customCell: StatusCustomCell,
  },
];
