import { StyledModalContainer } from 'common/components/organisms/TooltipModal/TooltipModal.styles';
import { CheckCircle } from 'react-feather';
import styled, { css } from 'styled-components';

import { Button } from '@omnipresentgroup/design-system';
import tokens from '@omnipresentgroup/design-tokens';

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin-top: ${({ theme: { space } }) => `${space[8]}`};

  @media (min-width: ${tokens.breakpoint.tablet}) {
    flex-direction: row;
  }
`;

export const CtaButton = styled(Button)`
  width: 100%;

  div {
    justify-content: center;
  }

  margin-bottom: ${({ theme: { space } }) => space[4]};

  @media (min-width: ${tokens.breakpoint.tablet}) {
    width: fit-content;
    align-self: flex-end;
  }
`;

export const StyledForm = styled.form`
  height: 100%;
`;

export const CloseButtonWrapper = styled.div`
  align-self: end;
  cursor: pointer;
  margin-top: ${({ theme: { space } }) => space[8]};
  padding-right: ${({ theme: { space } }) => space[8]};
  z-index: 1;
`;

export const StyledSuccessModalContainer = styled(StyledModalContainer)`
  align-items: center;
  flex-grow: 1;
  height: inherit;
  justify-content: center;
  padding-bottom: ${({ theme: { space } }) => space[32]};
`;

const PageWrapper = css`
  background: ${({ theme: { colors } }) => colors['background-primary']};
  border-radius: ${({ theme: { radii } }) => radii.s};
  margin-left: 0;
  width: 100%;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    width: 80%;
    padding: ${({ theme: { space } }) => space[32]};
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${PageWrapper}
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${({ theme: { space } }) => `${space[16]}`};
  margin-right: ${({ theme: { space } }) => `${space[16]}`};
  margin-left: ${({ theme: { space } }) => `${space[16]}`};
`;

export const StyledHeader = styled.p`
  margin: 0;
  text-align: center;
  font-family: ${({ theme: { fonts } }) => `${fonts.default}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  font-weight: ${({ theme: { fontWeights } }) => `${fontWeights.medium}`};
  padding-top: ${({ theme: { space } }) => `${space[16]}`};
`;

export const StyledIcon = css`
  display: flex;
  align-self: center;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[6]};
  padding: ${({ theme: { space } }) => `${space[8]}`};
`;

export const StyledSuccessIcon = styled(CheckCircle)`
  ${StyledIcon}
  color: ${({ theme: { colors } }) => colors['icon-positive']};
  width: ${({ theme: { space } }) => space[64]};
  height: ${({ theme: { space } }) => space[64]};
`;

export const AddManagerFormTitleContainer = styled.div`
  display: flex;
`;

export const RequiredFields = styled.div`
  margin-left: auto;
  color: ${({ theme: { colors } }) => `${colors['text-subtle']}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
  margin-bottom: ${({ theme: { space } }) => space[4]};
`;
