import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { queryClient } from 'app/queryClient';
import { updateEmployment } from 'common/api/employments.api';
import { Loading } from 'common/components';
import DetailsCard from 'common/components/organisms/DetailsCard/DetailsCard';
import { TEN_MINUTES } from 'common/constants';
import { Employee } from 'common/types';
import {
  EMPLOYMENT_STATUS,
  EMPLOYMENT_STATUS_MAP,
  EmploymentModel,
} from 'common/types/employments';
import { ResignationModel } from 'common/types/resignations';
import { DateTime } from 'luxon';
import { getEmployeeSelector } from 'omniplatform/employee/store/selectors/employee.selector';
import {
  CREATE_RESIGNATION,
  EDIT_EMPLOYMENT_PAGE,
  RESIGNATION_SUMMARY,
} from 'paths';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useSelectUserRoles } from 'utils/hooks';
import { useTrack } from 'utils/mixpanel/tracker';
import { useEmploymentHistoryQuery } from 'utils/queries';
import { employeesKeys } from 'utils/queries/employees/keys';
import { useResignationsQuery } from 'utils/queries/employees/resignations';
import { employmentKeys } from 'utils/queries/employments/keys';

import {
  Button,
  DateFormats,
  formatISODate,
  Inline,
  Notification,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import * as S from './EmploymentHistory.styles';

type EmploymentHistoryProps = {
  setIsOnboardingInProgress?: (
    isOnboardingInProgress: boolean | undefined,
  ) => boolean;
};

export const EmploymentHistory = ({
  setIsOnboardingInProgress,
}: EmploymentHistoryProps) => {
  const history = useHistory();

  const track = useTrack();

  const { isAdmin, isEmployee } = useSelectUserRoles();

  const employee: Employee = useSelector(getEmployeeSelector);

  const employmentHistoryQuery = useEmploymentHistoryQuery(
    employee.userId,
    TEN_MINUTES,
    'lspName',
  );

  const { data: resignationsData = [], isLoading } = useResignationsQuery(
    employee.id,
    {
      enabled: Boolean(employee.id) && isEmployee,
    },
  );

  const isOnboardingInProgress =
    employmentHistoryQuery.data &&
    employmentHistoryQuery?.data.some(
      (employment) =>
        isEmploymentStatusCreated(employment) ||
        isEmploymentStatusOnboarded(employment),
    );

  useEffect(() => {
    setIsOnboardingInProgress &&
      setIsOnboardingInProgress(isOnboardingInProgress);
  }, [setIsOnboardingInProgress, isOnboardingInProgress]);

  const employmentsToDeactivate = employmentHistoryQuery.data?.filter(
    (employments) => employments.status === EMPLOYMENT_STATUS.ACTIVE,
  );

  const { isLoading: isUpdatingEmployment, mutate: activateEmployment } =
    useMutation(
      async (employmentId: string) => {
        if (employmentsToDeactivate && employmentsToDeactivate.length > 0) {
          await Promise.allSettled(
            employmentsToDeactivate.map((activeEmployments) =>
              updateEmployment(activeEmployments.id, {
                status: EMPLOYMENT_STATUS.INACTIVE,
              }),
            ),
          );
        }
        return await updateEmployment(employmentId, {
          status: EMPLOYMENT_STATUS.ACTIVE,
        });
      },
      {
        onSuccess: async ({ data: { id: employmentId } }) => {
          const invalidate = [
            queryClient.invalidateQueries(employmentKeys.single(employmentId)),
            queryClient.invalidateQueries(
              employmentKeys.singleUser(employee.userId),
            ),
            queryClient.invalidateQueries(
              employeesKeys.singleEmployee(employee.id),
            ),
          ];
          if (employmentsToDeactivate) {
            invalidate.push(
              ...employmentsToDeactivate.flatMap((employment) =>
                queryClient.invalidateQueries(
                  employmentKeys.single(employment.id),
                ),
              ),
            );
          }
          return await Promise.allSettled(invalidate);
        },
        onError: (err) => console.error(err),
      },
    );

  const {
    isLoading: isLoadingSetToOnboarded,
    mutate: setEmploymentToOnboarded,
  } = useMutation(
    async (employmentId: string) =>
      await updateEmployment(employmentId, {
        status: EMPLOYMENT_STATUS.ONBOARDED,
      }),
    {
      onSuccess: async ({ data: { id: employmentId } }) =>
        await Promise.allSettled([
          queryClient.invalidateQueries(employmentKeys.single(employmentId)),
          queryClient.invalidateQueries(
            employmentKeys.singleUser(employee.userId),
          ),
        ]),
      onError: (err) => console.error(err),
    },
  );
  const currentDate = DateTime.now();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {employmentHistoryQuery.isSuccess && (
        <Stack gap="32" mt="32">
          {resignationsData?.length > 0 &&
            isCurrentDateLessThanThirtyDaysOfResignationCreatedDate(
              resignationsData,
            ) &&
            isEmployee && (
              <Stack>
                <Notification
                  id="resignation-confirmation-info"
                  title={`Your intent to resign from ${employee.companyName} has been submitted - we’re sorry to see you go.`}
                  description={`We will be in touch with you to complete the offboarding process. 
                A copy of your submission has also been emailed to you. If you wish to edit the details 
                of your submission, proceed to submit a new request. We will always store your latest submission; 
                last submission on  ${formatISODate(
                  currentDate.toString(),
                  DateFormats.Date,
                  true,
                  '-',
                )}`}
                  intent="success"
                  link={
                    <Link to={RESIGNATION_SUMMARY(employee.id)}>
                      View submission
                    </Link>
                  }
                />
              </Stack>
            )}
          {isOnboardingInProgress && isAdmin && (
            <Notification
              id="employment-onboarding-info"
              title="Please activate or deactivate the upcoming employment below, to add another"
            />
          )}
          {employmentHistoryQuery?.data
            .sort(sortEmployment)
            .map((employment) => {
              const historyDisplay = mapEmploymentHistoryToDisplay(employment);

              const statusButton = (employment: EmploymentModel) => {
                if (isEmploymentStatusCreated(employment)) {
                  return (
                    <Button
                      variant="primary"
                      onClick={() => setEmploymentToOnboarded(employment.id)}
                      size="medium"
                      loading={isUpdatingEmployment || isLoadingSetToOnboarded}
                    >
                      Onboarded
                    </Button>
                  );
                }
                if (isEmploymentStatusOnboarded(employment)) {
                  return (
                    <Button
                      variant="primary"
                      onClick={() => activateEmployment(employment.id)}
                      size="medium"
                      loading={isUpdatingEmployment || isLoadingSetToOnboarded}
                    >
                      Activate
                    </Button>
                  );
                }
              };

              const submitResignationButton = (
                employment: EmploymentModel,
                employee: Employee,
              ) => {
                return (
                  isEmploymentStatusActive(employment) && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        track('Initiate Resignation Button Clicked', {
                          employeeId: employee.id,
                        });
                        history.push(CREATE_RESIGNATION(employee.id));
                      }}
                      size="medium"
                    >
                      Initiate resignation
                    </Button>
                  )
                );
              };

              const cardHeadContent = (
                <thead>
                  <tr>
                    <td>
                      <Inline p="16">
                        <Typography as="label" size="16" weight="medium">
                          Employment
                        </Typography>
                      </Inline>
                    </td>
                    <td>
                      <Inline p="16" justify="end">
                        {isAdmin && (
                          <Button
                            variant="secondary"
                            onClick={() =>
                              history.push(
                                EDIT_EMPLOYMENT_PAGE(
                                  employee.id,
                                  employment.id,
                                ),
                              )
                            }
                            icon="Edit"
                            size="medium"
                          >
                            Edit
                          </Button>
                        )}
                        {isAdmin && statusButton(employment)}
                        {isEmployee &&
                          submitResignationButton(employment, employee)}
                      </Inline>
                    </td>
                  </tr>
                </thead>
              );

              return (
                <DetailsCard
                  key={employment?.id}
                  head={cardHeadContent}
                  fieldsToDisplay={historyDisplay.details}
                />
              );
            })}
        </Stack>
      )}
    </>
  );
};

const mapEmploymentHistoryToDisplay = (history: EmploymentModel) => ({
  id: history?.id,
  details: [
    {
      label: 'Status of the employment',
      name: 'statusOfEmployment',
      type: 'string',
      value: (
        <S.StyledEmploymentStatus
          as="span"
          status={history?.status && EMPLOYMENT_STATUS_MAP[history?.status]}
        >
          {history?.status && EMPLOYMENT_STATUS_MAP[history?.status]}
        </S.StyledEmploymentStatus>
      ),
    },
    {
      label: 'Employment start date',
      name: 'employmentStartDate',
      type: 'string',
      value: history?.startDate
        ? formatISODate(history?.startDate, DateFormats.Date)
        : '-',
    },
    {
      label: 'Employment termination date',
      name: 'employmentTerminationDate',
      type: 'string',
      value: history?.terminationDate
        ? formatISODate(history?.terminationDate, DateFormats.Date)
        : '-',
    },
    {
      label: 'Payroll/Service provider',
      name: 'payrollServiceProvider',
      type: 'string',
      value: history?.lspName,
    },
    {
      label: 'First payroll date',
      name: 'firstPayrollDate',
      type: 'string',
      value: history?.firstPayrollDate
        ? formatISODate(history?.firstPayrollDate, DateFormats.Date)
        : '-',
    },
    {
      label: 'Last payroll date',
      name: 'lastPayrollDate',
      type: 'string',
      value: history?.lastPayrollDate
        ? formatISODate(history?.lastPayrollDate, DateFormats.Date)
        : '-',
    },
  ],
});

const sortEmployment = (a: EmploymentModel, b: EmploymentModel): number =>
  new Date(b.startDate || 0).getTime() - new Date(a.startDate || 0).getTime();

const isEmploymentStatusCreated = (employment: EmploymentModel) =>
  employment.status === EMPLOYMENT_STATUS.CREATED;

const isEmploymentStatusOnboarded = (employment: EmploymentModel) =>
  employment.status === EMPLOYMENT_STATUS.ONBOARDED;

const isEmploymentStatusActive = (employment: EmploymentModel) =>
  employment.status === EMPLOYMENT_STATUS.ACTIVE;

const sortResignationsByCreatedAt = (
  a: ResignationModel,
  b: ResignationModel,
) => {
  return (
    DateTime.fromISO(b.createdAt).toMillis() -
    DateTime.fromISO(a.createdAt).toMillis()
  );
};

const isCurrentDateLessThanThirtyDaysOfResignationCreatedDate = (
  resignations: ResignationModel[],
): boolean => {
  resignations.sort(sortResignationsByCreatedAt);

  const thirtyDaysFromCreatedAtDate = DateTime.fromISO(
    resignations[0].createdAt,
  ).plus({
    days: 30,
  });

  return (
    DateTime.now().startOf('day') < thirtyDaysFromCreatedAtDate.startOf('day')
  );
};
