import JoiImport from 'joi';
import { DateTime } from 'luxon';

import DateExtension from '@omnipresentgroup/joi-date';

const Joi = JoiImport.extend(DateExtension) as JoiImport.Root;

export const THREE_DAYS_FROM_NOW = DateTime.local()
  .plus({ days: 3 })
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .toJSDate();

export const resignationFormValidationSchema = Joi.object({
  employeeLastWorkingDay: Joi.date()
    .format('YYYY-MM-DD')
    .raw()
    .required()
    .greater(THREE_DAYS_FROM_NOW)
    .messages({
      'any.required': 'Please enter a date',
      'date.empty': 'Please enter a date',
      'date.format': 'Please enter a valid date',
      'date.greater':
        'Please select a date at least 72 hours from the date of submission',
    }),
  isManagerAware: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required(),
  })
    .default('No')
    .messages({
      'any.required': 'Please select the answer',
    }),
  employeeScheduledLeaves: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required(),
  })
    .required()
    .messages({
      'any.required': 'Please select the answer',
    }),
  employeeNumberOfVacationsDays: Joi.number().min(0).required().messages({
    'any.required': 'Please enter a number',
    'number.base': 'Please enter a number',
    'number.min': 'Value must be greater than or equal to 0',
  }),
  resignationReason: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required(),
  })
    .required()
    .messages({
      'any.required': 'Please select the answer',
    }),
  otherReason: Joi.when('resignationReason', {
    is: {
      value: 'other',
      label: 'Other',
    },
    then: Joi.string().required().messages({
      'any.required': 'Please enter other reason',
    }),
  }),
});

export const questions = {
  isManagerAware: {
    answers: [
      {
        defaultChecked: true,
        id: 'isManagerAware-no',
        label: 'No',
        name: 'isManagerAware',
      },
      {
        id: 'isManagerAware-yes',
        label: 'Yes',
        name: 'isManagerAware',
      },
    ],
  },
  defaultAnswers: {
    answers: [
      {
        value: 'true',
        label: 'Yes',
      },
      {
        value: 'false',
        label: 'No',
      },
    ],
  },
  employeeScheduledLeaves: {
    answers: [
      {
        value: 'notApplicable',
        label: 'Not applicable',
      },
      {
        value: 'sickLeave',
        label: 'Sick leave',
      },
      {
        value: 'parentalLeave',
        label: 'Maternity/Paternity leave',
      },
      {
        value: 'compassionateLeave',
        label: 'Compassionate leave',
      },
      {
        value: 'vacationLeave',
        label: 'Paid/Unpaid vacation leave',
      },
      {
        value: 'studyLeave',
        label: 'Study leave',
      },
      {
        value: 'gardeningLeave',
        label: 'Gardening leave',
      },
      {
        value: 'daysOwed',
        label: 'Days owed',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  resignationReasons: {
    answers: [
      {
        value: 'flexibleWorkOptions',
        label: 'Seeking more flexible work options',
      },
      {
        value: 'movingToDirectEmployer',
        label: 'Moving to a direct employer',
      },
      {
        value: 'nonRemoteOptions',
        label: 'Seeking non-remote options',
      },
      {
        value: 'contractualCompletion',
        label: 'Contractual change/completion',
      },
      {
        value: 'jobSatisfaction',
        label: 'Job satisfaction',
      },
      {
        value: 'betterBenefits',
        label: 'Better remuneration/benefits',
      },
      {
        value: 'returnToStudy',
        label: 'Return to study/retraining',
      },
      {
        value: 'betterCareerOpportunities',
        label: 'Better career opportunities',
      },
      {
        value: 'personalReasons',
        label: 'Personal reasons unrelated to the job',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
};
