import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import { getLoadingSelector } from 'app/store/app.selectors';
import FloatingActionButton from 'common/components/atoms/FloatingActionButton/FloatingActionButton';
import Loading from 'common/components/atoms/Loading/Loading';
import { StyledBoldCopy } from 'common/components/atoms/Text/Copy.styles';
import Card from 'common/components/molecules/Card/Card';
import MobileCardListHeader from 'common/components/molecules/MobileCardListHeader/MobileCardListHeader';
import NoExpenses from 'common/components/molecules/NoExpenses/NoExpenses';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import StyledTableContainer from 'common/components/molecules/StyledTableContainer/StyledTableContainer.styles';
import TableWithTitle from 'common/components/organisms/TableWithTitle/TableWithTitle';
import { Tabs } from 'common/components/organisms/Tabs/Tabs';
import { ADD_EMPLOYEE_EXPENSE_PAGE, EMPLOYEE_EXPENSE_LIST_PAGE } from 'paths';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getNextPayrollCutOffDate } from 'utils/getNextPayrollCutOffDate';

import { Notification, Viewport } from '@omnipresentgroup/design-system';

import { StyledShowingText } from '../../../manager/pages/DocumentListPage/DocumentListPage.styles';
import { StyledExpensesTableContainer } from '../../../manager/pages/Expenses/ExpensesListPage/ExpensesListPage.styles';
import { managerEmployeesExpenseTableHeaders } from '../../../manager/pages/Expenses/ExpensesListPage/managerExpensesTableHeaders';
import { getEmployeeExpensesAction } from '../../store/actions/expenses.actions';
import {
  getEmployeeIdSelector,
  getEmployeeSelector,
} from '../../store/selectors/employee.selector';
import { getEmployeeExpensesSelector } from '../../store/selectors/expenses.selector';
import {
  StyledAlertContainer,
  StyledEmployeeExpenseListContainer,
} from './EmployeeExpenseListPage.styles';
import { employeeExpenseTableHeaders } from './expenseTableHeaders';

const EmployeeExpenseListPage = ({
  employee,
  employeesExpenses,
  getEmployeesExpenses,
  loading,
  employeeId,
}) => {
  const location = useLocation();
  const [currentHash, setCurrentHash] = useState(location.hash || null);
  useEffect(() => {
    if (employeeId) {
      getEmployeesExpenses();
    }
  }, [getEmployeesExpenses, employeeId]);

  const { outstanding, paid } = employeesExpenses;

  useEffect(() => {
    setCurrentHash(location.hash);
  }, [location]);

  const onRowClicked = (expenseId) => {
    appHistory.push(`${EMPLOYEE_EXPENSE_LIST_PAGE}/${expenseId}`);
  };

  const onCTAClicked = () => appHistory.push(ADD_EMPLOYEE_EXPENSE_PAGE);

  const headersForCard = employeeExpenseTableHeaders.filter(
    (header) => header.headerTitle !== 'Spent at',
  );

  const OutstandingContent = () => (
    <StyledExpensesTableContainer className="smallStack">
      <Viewport devices={['laptop', 'desktop', 'highRes']}>
        <>
          <StyledShowingText>
            Showing 1 - {outstanding.length} of {outstanding.length}
          </StyledShowingText>
          <TableWithTitle
            itemsToDisplay={outstanding}
            headers={employeeExpenseTableHeaders}
            onClick={onRowClicked}
            testId="outstanding-expenses"
          />
        </>
      </Viewport>
      <Viewport devices={['phone', 'tablet']}>
        <>
          <MobileCardListHeader total={outstanding.length} />
          {outstanding.map((expense, index) => (
            <Card
              key={String(`outstanding-expense-${index}`)}
              title={<StyledBoldCopy>{expense.title}</StyledBoldCopy>}
              item={expense}
              fieldsToShow={headersForCard}
              onClick={onRowClicked}
            />
          ))}
        </>
      </Viewport>
    </StyledExpensesTableContainer>
  );

  const ArchivedContent = () => (
    <StyledExpensesTableContainer className="smallStack">
      <Viewport devices={['laptop', 'desktop', 'highRes']}>
        <>
          <StyledShowingText>
            Showing 1 - {paid.length} of {paid.length}
          </StyledShowingText>
          <TableWithTitle
            itemsToDisplay={paid}
            headers={managerEmployeesExpenseTableHeaders}
            testId="archived-expenses"
          />
        </>
      </Viewport>
      <Viewport devices={['phone', 'tablet']}>
        <>
          <MobileCardListHeader total={paid.length} />
          {paid.map((expense, index) => (
            <Card
              key={String(`paid-expense-${index}`)}
              title={<StyledBoldCopy>{expense.title}</StyledBoldCopy>}
              item={expense}
              fieldsToShow={headersForCard}
              onClick={onRowClicked}
            />
          ))}
        </>
      </Viewport>
    </StyledExpensesTableContainer>
  );

  const tabConfig = [
    {
      id: 1,
      title: 'Outstanding',
      href: '#outstanding',
      Content: OutstandingContent,
    },
    {
      id: 2,
      title: 'Archived',
      href: '#archived',
      Content: ArchivedContent,
    },
  ];

  const isEmployeeInactive =
    employee?.onboardingStatus?.toLowerCase() === 'inactive';

  return (
    <PageContent>
      <StyledEmployeeExpenseListContainer data-testid="employee-expenses-list-page">
        {loading ? (
          <Loading />
        ) : (
          <>
            {!outstanding?.length && !paid?.length && <NoExpenses />}
            {(!!outstanding?.length || !!paid?.length) && (
              <StyledTableContainer className="bigStack">
                <PageHeader
                  title="Expenses"
                  onCTAClick={onCTAClicked}
                  secondaryCta
                  ctaText="Add expense"
                  hasDisabledCTA={isEmployeeInactive}
                />
                <StyledAlertContainer>
                  <Notification
                    id="expense-info-alert"
                    intent="warning"
                    title={`Expenses need to be submitted to and approved by your manager by ${getNextPayrollCutOffDate()} to be reimbursed in the upcoming payroll`}
                    description="If your submitted expenses are approved after this cut off date, then you will be reimbursed in next month’s payroll"
                  />
                </StyledAlertContainer>
                <Tabs
                  tabsToDisplay={tabConfig}
                  currentTab={
                    currentHash
                      ? tabConfig.filter((tab) => tab.href === currentHash)[0]
                      : tabConfig[0]
                  }
                />
                <Viewport devices={['phone', 'tablet']}>
                  <FloatingActionButton
                    type="add"
                    onClick={onCTAClicked}
                    disabled={isEmployeeInactive}
                  />
                </Viewport>
              </StyledTableContainer>
            )}
          </>
        )}
      </StyledEmployeeExpenseListContainer>
    </PageContent>
  );
};

EmployeeExpenseListPage.propTypes = {
  employeesExpenses: PropTypes.oneOfType([PropTypes.object]).isRequired,
  getEmployeesExpenses: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  employeeId: PropTypes.string,
  employee: PropTypes.object.isRequired,
};

EmployeeExpenseListPage.defaultProps = {
  employeeId: null,
};

const mapStateToProps = (state) => ({
  employee: getEmployeeSelector(state),
  employeesExpenses: getEmployeeExpensesSelector(state),
  loading: getLoadingSelector(state),
  employeeId: getEmployeeIdSelector(state),
});

const mapDispatchToProps = {
  getEmployeesExpenses: getEmployeeExpensesAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeExpenseListPage);
