import { getResignationsByCompanyId } from 'common/api/resignations.api';
import { ONE_MINUTE } from 'common/constants';
import { ResignationModel } from 'common/types/resignations';
import { useQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { resignationsKeys } from './keys';

export const useResignationsByCompanyQuery = (
  companyId: string,
  { enabled }: { enabled: boolean },
) => {
  return useQuery<ResignationModel[], Error>(
    resignationsKeys.allResignationsByCompany(companyId),
    () => getResignationsByCompanyId(companyId).then(getDataFromResponse),
    {
      staleTime: ONE_MINUTE,
      enabled,
    },
  );
};
