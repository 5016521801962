import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  background: ${({ theme: { colors } }) => colors['background-primary']};
  border-radius: ${({ theme: { radii } }) => radii.m};
  box-shadow: ${({ theme: { shadows } }) => shadows.s};
  padding: ${({ theme: { space } }) => space[32]};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
