import styled, { css } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

import Button from '../../atoms/Button';
import { StyledBoldCopy, StyledContent } from '../../atoms/Text';

export const Container = styled.div(
  ({ theme: { space, colors, radii, borderWidths, borderStyles } }) => css`
    display: grid;
    place-content: center;
    min-height: 39.5vw;
    position: relative;
    padding: ${space[64]};

    ::before {
      z-index: -1;
      content: '';
      position: absolute;
      inset: 0;
      background: ${colors['gray-200']};
      border: ${`${borderWidths.s} ${borderStyles.solid} ${colors['border-strong']}`};
      border-radius: ${radii.m};
      opacity: ${tokens.opacity[2]};
    }
  `,
);

export const EmptyHeader = styled.header(
  ({ theme: { space } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    > svg {
      width: ${space[64]};
      height: ${space[64]};
      margin-bottom: ${space[16]};
      opacity: 1;
    }
  `,
);

export const EmptyHeaderTitle = styled(StyledBoldCopy)(
  ({ theme: { space } }) => css`
    text-align: center;
    font-size: ${space[32]};
  `,
);

export const EmptyDescription = styled(StyledContent)(
  () => css`
    text-align: center;
    white-space: pre-wrap;
  `,
);

export const EmptyActionButton = styled(Button)(
  ({ theme: { space } }) => css`
    margin: ${space[32]} auto;
  `,
);
