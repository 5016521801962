import styled, { css } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const StyledContainer = styled.div(
  ({ theme: { space } }) => css`
    width: 100%;
    height: 100%;

    @media (max-width: ${tokens.breakpoint.tablet}) {
      margin-top: ${space[8]};
    }
  `,
);
