import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import { ChevronDown } from 'react-feather';
import { useTrack } from 'utils/mixpanel/tracker';

import {
  Box,
  Button,
  Inline,
  Modal,
  omniConfig,
  Typography,
} from '@omnipresentgroup/design-system';

const accordionItemStyle = {
  border: `1px solid ${omniConfig.theme.colors['border-subtle']}`,
  borderRadius: omniConfig.theme.radii['m'],
  padding: omniConfig.theme.space['16'],
  marginBottom: omniConfig.theme.space['16'],
};

const accordionTriggerStyle = {
  width: '100%',
  height: '40px',
  backgroundColor: omniConfig.theme.colors['white-base'],
  border: 'none',
  padding: 0,
};

export const InvoiceInfoModal = ({
  isModalOpen,
  onAgreeClick,
  closeModal,
}: {
  isModalOpen: boolean;
  onAgreeClick: () => void;
  closeModal: () => void;
}) => {
  const track = useTrack();

  return (
    <Modal open={isModalOpen} onOpenChange={(openState) => !openState}>
      <Modal.Header
        title="Agree and continue?"
        description="Continuing will issue the following invoices"
      />
      <Modal.Body>
        <Box w={600}>
          <Accordion defaultValue="null" type="single" collapsible>
            <AccordionItem value="item-1" style={accordionItemStyle}>
              <AccordionTrigger
                style={accordionTriggerStyle}
                onClick={() => track('Set Up Fee Invoice Accordion Opened')}
              >
                <Inline justify="space-between">
                  <Typography as="span" size="14" weight="medium">
                    Set-up fee
                  </Typography>
                  <ChevronDown aria-hidden />
                </Inline>
              </AccordionTrigger>
              <AccordionContent>
                <Typography as="p" size="14" color="helper">
                  The admin cost for setting up a contract with your employee.
                  It includes things such as registration with local tax
                  authorities, social security services, payroll setup,
                  provision of compliant employment contracts, etc. This is
                  non-refundable.
                </Typography>
                <Typography as="p" size="14" color="helper">
                  Note: If no set-up fee has been arranged, this invoice will be
                  skipped.
                </Typography>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem
              value="item-2"
              style={accordionItemStyle}
              defaultValue="closed"
            >
              <AccordionTrigger
                style={accordionTriggerStyle}
                onClick={() => track('Deposit Invoice Accordion Opened')}
              >
                <Inline justify="space-between">
                  <Typography as="p" size="14" weight="medium">
                    Deposit invoice
                  </Typography>
                  <ChevronDown aria-hidden />
                </Inline>
              </AccordionTrigger>
              <AccordionContent>
                <Typography as="p" size="14" color="helper">
                  This is an amount we hold to protect Omnipresent from customer
                  insolvency, and is only sent for EOR employees.
                </Typography>
                <Typography as="p" size="14" color="helper">
                  It ensures that we have sufficient funds to cover notice
                  periods and statutory severance payments for an employee (or
                  employees) and associated local tax authorities in the event
                  of default.
                </Typography>
                <Typography as="p" size="14" color="helper">
                  If all outstanding invoices are settled, the deposit will be
                  credited and refunded in full at the end of each
                  employee&apos;s tenure.
                </Typography>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
          <Typography as="p" size="14" color="helper">
            Depending on working arrangements, in some rare circumstances a work
            visa sponsorship fee may also be issued - but this will have already
            been discussed.
          </Typography>
          <Typography as="p" size="14">
            <b>Please note:</b> Your deposit needs to be paid for your employee
            to receive their contract – the offer of employment. Invoices will
            issued upon the employee profile being created, and will be
            distributed to the invoicing recipient(s) we currently have on file
            for your company.
          </Typography>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Inline gap="8" justify="space-between" w="100%">
          <Button
            variant="secondary"
            onClick={() => {
              track('Invoice Info Modal Cancelled');
              closeModal();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              track('Invoice Info Modal Continue Clicked');
              onAgreeClick();
            }}
          >
            Agree and continue
          </Button>
        </Inline>
      </Modal.Footer>
    </Modal>
  );
};
