import { getEmployeeOnboardingProgressRequest } from 'common/api/employees.api';
import { useQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

import { useEmployeeIdByUserRole } from './useOnboardingProgressQueryUtil';

export const useOnboardingProgressQuery = () => {
  const employeeId = useEmployeeIdByUserRole();

  return useQuery(
    ['onboarding-progress', employeeId],
    () =>
      getEmployeeOnboardingProgressRequest(employeeId).then(
        getDataFromResponse,
      ),
    { enabled: !!employeeId },
  );
};
