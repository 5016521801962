import { downloadCutoffReport } from 'common/api/cutoffReports.api';
import { useMutation } from 'react-query';

export function useDownloadCutoffReportMutation() {
  return useMutation(
    ({
      reportId,
      setProgress,
    }: {
      reportId: string;
      setProgress: (progress: number) => void;
    }) => {
      return downloadCutoffReport(reportId, setProgress);
    },
  );
}
