import { AxiosError } from 'axios';
import {
  downloadDocumentDocx,
  getTemplateMappingsRequest,
  getTemplateRequest,
} from 'common/api/contractTemplates.api';
import {
  ContractTemplateApiResponse,
  ContractTemplateMappingsApiResponse,
} from 'common/types';
import { useQuery } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useTemplateMappingsQuery = ({
  enabled = true,
}: {
  enabled?: boolean;
}) => {
  return useQuery<ContractTemplateMappingsApiResponse, AxiosError>(
    [`template-mappings`],
    () => getTemplateMappingsRequest().then(getDataFromResponse),
    { enabled },
  );
};

export const useAvvokaTemplateQuery = ({
  countryCode,
  enabled = true,
  companyId,
}: {
  countryCode?: string;
  enabled: boolean;
  companyId: string;
}) => {
  return useQuery<ContractTemplateApiResponse, AxiosError>(
    [`contractTemplate`, countryCode, companyId],
    () =>
      getTemplateRequest({ countryCode, companyId }).then(getDataFromResponse),
    { enabled },
  );
};

export const useAvvokaDocumentDownloadQuery = (
  avvokaDocumentId: string | undefined,
) =>
  useQuery(
    `contract-download-${avvokaDocumentId}`,
    () => downloadDocumentDocx(avvokaDocumentId || ''),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        window.open(data.linkToDocument, '_blank');
      },
    },
  );
