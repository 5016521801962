import { useState } from 'react';

import { LoadingSpinner } from 'common/components/atoms/LoadingSpinner/LoadingSpinner.styles';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { OnboardingStatusValues } from 'common/types/employee';
import intersection from 'lodash/intersection';
import map from 'lodash/map';
import { CONTRACTUAL_COMPENSATION_PATH } from 'paths';
import { ArrowUpRight, Check } from 'react-feather';
import { getCountryFlagByCountryName } from 'utils/countries';
import { useSelectUserRoles } from 'utils/hooks/redux-selectors/useSelectUserRoles';
import { useTrack } from 'utils/mixpanel/tracker';
import { useOnboardingProgressQuery } from 'utils/queries/employeeOnboardingProgress';
import { useEmployeeIdByUserRole } from 'utils/queries/employeeOnboardingProgress/useOnboardingProgressQuery/useOnboardingProgressQueryUtil';
import { useEmployeeQuery } from 'utils/queries/employees/useEmployeeQuery';

import { Notification } from '@omnipresentgroup/design-system';

import { OnboardingProgressDrawer } from '../OnboardingProgressDrawer/OnboardingProgressDrawer';
import { getPercentComplete } from '../OnboardingProgressDrawer/OnboardingProgressDrawerUtil';
import {
  StepBar,
  StyledCheck,
  StyledCircle,
  StyledCircleContainer,
  StyledCompletedCircle,
  StyledCountryFlag,
  StyledDrawerClick,
  StyledMainContainer,
  StyledOnboardingText,
  StyledOnboardingTitle,
  StyledProgressStep,
  StyledSection,
  StyledStage,
  StyledStageContainer,
  StyledStageNumber,
} from './OnboardingProgressTracker.style';
import { getEmployeeStartDate } from './OnboardingProgressTrackerUtil';
import { OnboardingStage, ProgressLineProps, StageProps } from './types';

const COMP_DETAILS_STEP_ID = '4AfCOdtepyH5tOwloWBG1T';

const Stage = ({
  onboardingStage,
  completedSteps,
  totalStages,
  percentComplete,
}: StageProps) => {
  const { id: stageId, name: stageName, steps: stageSteps } = onboardingStage;
  const stageStepsId = map(stageSteps, 'id');
  const stageCompletedSteps = intersection(completedSteps, stageStepsId);
  const isCompleted = stageStepsId.length === stageCompletedSteps?.length;
  const hasCompletedSteps = stageCompletedSteps?.length >= 1;
  const isPartiallyCompleted = hasCompletedSteps && !isCompleted;
  const isLastStage = stageId === totalStages;

  return (
    <StyledStageContainer key={stageId}>
      <StyledStageNumber
        isCompleted={isCompleted}
        isPartiallyCompleted={isPartiallyCompleted}
      >
        {isCompleted ? (
          <StyledCheck data-testid={`completed-stage-${stageId}`}>
            <Check size={14} />
          </StyledCheck>
        ) : (
          <StyledCompletedCircle>{stageId} </StyledCompletedCircle>
        )}
      </StyledStageNumber>
      <StyledStage>{`${stageName}`}</StyledStage>
      <ProgressLine
        isCompletedStage={isCompleted}
        isLastStage={isLastStage}
        stageId={stageId}
        percentComplete={percentComplete}
      />
    </StyledStageContainer>
  );
};

const ProgressLine = ({
  isCompletedStage,
  isLastStage,
  stageId,
  percentComplete,
}: ProgressLineProps) => {
  return (
    <StyledProgressStep>
      <StyledCircleContainer>
        <StyledCircle
          isCompletedStage={isCompletedStage}
          percentComplete={percentComplete}
        />
      </StyledCircleContainer>
      {!isLastStage && (
        <StepBar
          percentComplete={percentComplete}
          isCompleted={isCompletedStage}
          isLastStage={isLastStage}
          data-testid={`${stageId}-bar`}
        />
      )}
    </StyledProgressStep>
  );
};

export const OnboardingStageProgressTracker = ({
  showHeader,
}: {
  showHeader?: boolean;
}) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const employeeId = useEmployeeIdByUserRole();
  const track = useTrack();
  const fetchForLsp = true;
  const options = undefined;
  const employeeQuery = useEmployeeQuery(employeeId, options, fetchForLsp);
  const onboardingProgressData = useOnboardingProgressQuery();
  const notHasCompletedSteps =
    onboardingProgressData?.data &&
    !onboardingProgressData?.data?.completedSteps;
  const isActiveEmployee =
    employeeQuery?.data &&
    employeeQuery?.data?.onboardingStatus?.status ===
      OnboardingStatusValues.ACTIVE;

  const { isManager } = useSelectUserRoles();

  if (notHasCompletedSteps || isActiveEmployee) {
    return null;
  }

  if (employeeQuery.isError || onboardingProgressData.isError) {
    return <ErrorBanner />;
  }

  if (employeeQuery.isLoading || onboardingProgressData.isLoading) {
    return <LoadingSpinner role="alert" />;
  }

  if (!employeeQuery.isSuccess || !onboardingProgressData.isSuccess) {
    return <></>;
  }

  const startDate = getEmployeeStartDate(employeeQuery.data as any);
  const employeeName = employeeQuery.data.name;
  const lspId = employeeQuery.data?.lspId;

  const {
    completedSteps,
    territory: country,
    totalStages,
    onboardingStages,
  }: {
    completedSteps: string[];
    territory: string;
    totalStages: number;
    onboardingStages: OnboardingStage[];
  } = onboardingProgressData.data;

  const showCompDetailsStepNotice =
    isManager &&
    !!onboardingStages.find((stage) =>
      stage.steps.find((step) => step.id === COMP_DETAILS_STEP_ID),
    ) &&
    !completedSteps.includes(COMP_DETAILS_STEP_ID);

  const percentage = getPercentComplete(onboardingStages, completedSteps);

  const countryFlag = getCountryFlagByCountryName(country);

  const handleClickSeeMore = () => {
    track('Onboarding Tracker See More Clicked');
    setOpenDrawer(true);
  };

  return (
    <>
      {showHeader && (
        <StyledOnboardingTitle>
          {countryFlag && (
            <StyledCountryFlag size="small" className="emoji">
              {countryFlag}
            </StyledCountryFlag>
          )}
          <StyledOnboardingText>Onboarding Progress</StyledOnboardingText>
        </StyledOnboardingTitle>
      )}
      {showCompDetailsStepNotice && (
        <Notification
          id="compensation-details-step-notice"
          intent="warning"
          title="Please add and/or review any additional compensation"
          description="Please review all additional compensation to be granted to this employee. Please ensure you 'submit' the details at the end of the form, to begin contract generation."
          link={{
            text: 'Complete additional compensation',
            to: `${process.env.VITE_MANAGER_APP}${CONTRACTUAL_COMPENSATION_PATH(
              employeeId,
            )}?mode=edit`,
          }}
        />
      )}
      <StyledSection data-testid="onboarding-progress-tracker">
        <StyledMainContainer>
          {onboardingStages.map((stage) => (
            <Stage
              key={stage.id}
              onboardingStage={stage}
              totalStages={totalStages || onboardingStages.length}
              completedSteps={completedSteps}
              percentComplete={percentage}
            />
          ))}
        </StyledMainContainer>
        <StyledDrawerClick onClick={handleClickSeeMore}>
          See more
          <ArrowUpRight size={16} />
        </StyledDrawerClick>

        {openDrawer && (
          <>
            <OnboardingProgressDrawer
              setOpenDrawer={setOpenDrawer}
              stages={onboardingStages}
              territoryFlag={countryFlag}
              startDate={startDate}
              employeeName={employeeName}
              employeeId={employeeId}
              lspId={lspId}
              completedSteps={completedSteps}
            />
          </>
        )}
      </StyledSection>
    </>
  );
};
