import PropTypes from 'prop-types';

import { Stack, Typography, Viewport } from '@omnipresentgroup/design-system';

import Button from '../../atoms/Button/Button';
import LinkAsButton from '../../atoms/LinkAsButton/LinkAsButton';
import { StyledBodyCopy } from '../../atoms/Text/Copy.styles';
import {
  StyledBackLink,
  StyledButtonContainer,
  StyledPageHeader,
  StyledPageHeaderTitle,
  StyledTitleStatusContainer,
} from './PageHeader.styles';

const PageHeader = ({
  title,
  ctaText,
  onCTAClick,
  className,
  copy,
  image,
  statusPill,
  testId,
  backLink,
  hasDisabledCTA,
  showImportEmployeesButton,
  importEmployeesButtonClicked,
  customCTA,
}) => {
  return (
    <Stack minH={40} w="stretch" justify="center">
      <StyledPageHeader className={className} data-testid={testId}>
        <StyledPageHeaderTitle>
          {image && image}
          <div>
            <StyledTitleStatusContainer>
              <Typography
                as="h3"
                hideParagraphSpacing
                size="24"
                weight="medium"
              >
                {title}
              </Typography>
              {statusPill}
            </StyledTitleStatusContainer>
            <StyledBodyCopy>{copy}</StyledBodyCopy>
          </div>
        </StyledPageHeaderTitle>
        <StyledButtonContainer>
          <Viewport devices={['laptop', 'desktop', 'highRes']}>
            {customCTA}
            {showImportEmployeesButton && importEmployeesButtonClicked && (
              <Button
                palette="primary"
                style={{ marginRight: 24 }}
                onClick={importEmployeesButtonClicked}
              >
                Import Employees
              </Button>
            )}
            {ctaText && onCTAClick && (
              <Button
                onClick={onCTAClick}
                testId="header-cta"
                palette="primary"
                disabled={hasDisabledCTA}
              >
                {ctaText}
              </Button>
            )}
          </Viewport>
        </StyledButtonContainer>
      </StyledPageHeader>

      {backLink && (
        <StyledBackLink data-testid="page-header-backlink">
          <LinkAsButton to={backLink.url}>{backLink.label}</LinkAsButton>
        </StyledBackLink>
      )}
    </Stack>
  );
};

PageHeader.propTypes = {
  title: PropTypes.node.isRequired,
  copy: PropTypes.string,
  ctaText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onCTAClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  customCTA: PropTypes.element,
  className: PropTypes.string,
  image: PropTypes.node,
  testId: PropTypes.string,
  statusPill: PropTypes.node,
  backLink: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string.isRequired,
  }),
  hasDisabledCTA: PropTypes.bool,
  showImportEmployeesButton: PropTypes.bool,
  importEmployeesButtonClicked: PropTypes.func,
};

PageHeader.defaultProps = {
  showImportEmployeesButton: false,
  ctaText: null,
  onCTAClick: null,
  customCTA: null,
  className: '',
  copy: null,
  image: null,
  testId: 'page-header',
  statusPill: null,
  backLink: undefined,
  hasDisabledCTA: false,
  importEmployeesButtonClicked: null,
};

export default PageHeader;
