import { createContext } from 'react';

import { PayrollInstanceApiResponse } from 'common/types';

export enum VisibleModalEnum {
  lspBillJustification = 'lsp-bill-justification',
  gtnUpload = 'multi-file-upload',
  gtnDelete = 'delete-gtn',
  gtnConfirm = 'confirm-gtn',
  gtnHistory = 'history-gtn',
  gtnLspData = 'lsp-data',
}

export type PayrollInstanceDetailPageContextType = {
  visibleModal: VisibleModalEnum | undefined;
  setVisibleModal: (modal?: VisibleModalEnum) => void;
  month: number;
  year: number;
  payrollPeriod: string;
  payrollInstance?: PayrollInstanceApiResponse;
};

export const PayrollInstanceDetailPageContext =
  createContext<PayrollInstanceDetailPageContextType>({
    visibleModal: undefined,
    setVisibleModal: () => {},
    month: 0,
    year: 0,
    payrollPeriod: '',
    payrollInstance: undefined,
  });
