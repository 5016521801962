import { HTTPStatusCodesEnum } from 'common/constants';
import {
  ContractAmendment,
  ContractAmendmentFormApiRequest,
} from 'common/types';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { PathParams, rest } from 'msw';

export const DEFAULT_AMENDMENT: ContractAmendment = {
  id: 'd03ef219-a4ee-4166-95bc-ec1697d3e464',
  employeeId: 'a1e89d20-61bb-4887-8b8b-f2782710dc75',
  companyId: 'F2WXbqrlPaVS',
  owner: 'auth0|5f06da3e920eff001999e07',
  documentId: 'documentId-123',
  reviewType: 'reword',
  reviewClause: 'start_date',
  reviewClauseNumber: '1.12',
  reviewStatus: 'pending',
  details: 'edit the starting date',
  createdAt: '2023-01-11T19:26:38.642Z',
};

export const generateAmendment = (override = {}) => ({
  ...DEFAULT_AMENDMENT,
  ...override,
});

export const contractAmendmentHandlers = [
  rest.post<ContractAmendmentFormApiRequest, PathParams, ContractAmendment>(
    composeEndpoint('/amendments'),
    (req, res, ctx) => {
      return res(ctx.status(201), ctx.json(DEFAULT_AMENDMENT));
    },
  ),

  rest.get<ContractAmendment[]>(
    composeEndpoint('/amendments'),
    (req, res, ctx) => {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json([DEFAULT_AMENDMENT]),
      );
    },
  ),

  rest.post<
    ContractAmendmentFormApiRequest,
    PathParams<ContractAmendment['id']>,
    ContractAmendment
  >(composeEndpoint('/amendments/:amendmentId/dispatch'), (req, res, ctx) => {
    const reviewStatus = 'dispatched';
    return res(
      ctx.json({
        ...DEFAULT_AMENDMENT,
        reviewStatus,
      }),
    );
  }),
];
