export enum VariantPaymentTypesEnum {
  bonus = 'bonus',
  commission = 'commission',
  allowance = 'allowance',
}

export type VariantPaymentTypes = `${VariantPaymentTypesEnum}`;

export enum VariantPaymentStatusEnum {
  requested = 'requested',
  deleted = 'deleted',
  ended = 'ended',
}

export type VariantPaymentStatus = `${VariantPaymentStatusEnum}`;

export enum VariantPaymentFrequencyEnum {
  ONE_OFF = 'one_off',
  MONTHLY = 'monthly',
}

export type VariantPaymentFrequency = `${VariantPaymentFrequencyEnum}`;

export enum AllowanceTypesEnum {
  CAR_ALLOWANCE = 'car_allowance',
  COST_OF_LIVING_ALLOWANCE = 'cost_of_living_allowance',
  EDUCATION_ALLOWANCE = 'education_allowance',
  END_OF_YEAR_ALLOWANCE = 'end_of_year_allowance',
  HEALTH_ALLOWANCE = 'health_allowance',
  HOLIDAY_ALLOWANCE = 'holiday_allowance',
  HOUSING_ALLOWANCE = 'housing_allowance',
  MEAL_ALLOWANCE = 'meal_allowance',
  PENSION_ALLOWANCE = 'pension_allowance',
  PHONE_ALLOWANCE = 'phone_allowance',
  REMOTE_ALLOWANCE = 'remote_allowance',
  TRANSPORTATION_ALLOWANCE = 'transportation_allowance',
  TRAVEL_ALLOWANCE = 'travel_allowance',
  WELLNESS_ALLOWANCE = 'wellness_allowance',
}

export type AllowanceTypes = `${AllowanceTypesEnum}`;

export type CompensationOptions = 1 | 2 | 3;

type Amount = {
  amount: number;
  currency: string;
};

export type QueryParams = {
  pageSize?: number;
  nextPageKey?: string;
};

export type VariantPayment = {
  id: string;
  type: VariantPaymentTypes;
  employeeId: string;
  amount: Amount;
  companyId: string;
  effectiveDate: string;
  requestedAt: string;
  requestedByAuthId: string;
  reason?: string;
  additionalNotes?: string;
};

export type CreateVariantPaymentRequest = {
  employeeId: string;
  amount: Amount;
  type: VariantPaymentTypes;
  effectiveDate: string;
  reason: string;
  additionalNotes?: string;
  allowanceType?: AllowanceTypes;
  frequency: VariantPaymentFrequency;
  endDate?: string;
};

export type ValidateBulkVariantPaymentRequest = {
  companyId: string;
  type: string;
  csvFileUrl: string;
};

export type SubmitBulkVariantPaymentRequest = {
  companyId: string;
  type: string;
  csvFileUrl: string;
};

export type SubmitBulkVariantPaymentResponse = VariantPayment[];

export type GetVariantPaymentsResponseItem = {
  id: string;
  employeeId: string;
  amount: Amount;
  employeeName?: string;
  type: VariantPaymentTypes;
  allowanceType?: AllowanceTypes;
  reason?: string;
  effectiveDate: string;
  requestedAt: string;
  status?: VariantPaymentStatus;
  frequency: VariantPaymentFrequency;
  endDate?: string;
};

export type GetVariantPaymentsResponse = {
  variantPayments: GetVariantPaymentsResponseItem[];
  nextPageKey?: string;
};
