import { getEmployerCostsRequest } from 'common/api/calculator.api';
import { EmployerCostApiResponse } from 'common/types/employerCost';
import { useQuery } from 'react-query';
import { getCountryCodeByCountryName } from 'utils/countries';
import { getDataFromResponse } from 'utils/react-query-utils';

import { employeerCostsKeys } from '../keys';

export const useEmployerCostsQuery = (
  countryName: string,
  currencyCode: string,
  annualBaseSalary: number,
  hasFxRate: boolean,
  { queryOptions }: { queryOptions?: any },
) => {
  const countryCode = getCountryCodeByCountryName(countryName) || '';
  return useQuery<EmployerCostApiResponse, Error>(
    employeerCostsKeys.costs(
      countryName,
      currencyCode,
      annualBaseSalary,
      hasFxRate,
    ),
    () =>
      getEmployerCostsRequest({
        countryCode,
        currencyCode,
        annualBaseSalary,
        hasFxRate,
      }).then(getDataFromResponse),
    {
      staleTime: 1000,
      cacheTime: 0,
      ...(queryOptions || {}),
    },
  );
};
