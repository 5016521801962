import type { IsoDateTime } from '@omnipresentgroup/design-system';

export const [AUTOMATED, MANUAL_UPLOAD] = [
  'Automated',
  'Manual upload',
] as const;

export type ContractOrigin = typeof AUTOMATED | typeof MANUAL_UPLOAD;

/* REFACTOR: move this to a const file */
/* REFACTOR: create CTA labels const */
export const [
  ARCHIVED,
  PENDING_REVIEW,
  REVIEW_DONE,
  DRAFT,
  PENDING_INTERNAL_REVIEW,
  APPROVED,
  SIGNED_EMPLOYEE,
  SIGNED_BOTH_PARTIES,
] = [
  'archived',
  'pending-review',
  'review-done',
  'draft',
  'pending-internal-review',
  'approved',
  'signed:employee',
  'signed:both-parties',
] as const;

export type ContractStatus =
  | typeof ARCHIVED
  | typeof PENDING_REVIEW
  | typeof REVIEW_DONE
  | typeof DRAFT
  | typeof PENDING_INTERNAL_REVIEW
  | typeof APPROVED
  | typeof SIGNED_EMPLOYEE
  | typeof SIGNED_BOTH_PARTIES;

/* REFACTOR: move this to a const file */
export const [
  ARCHIVED_DESCRIPTION,
  REVIEW_DONE_DESCRIPTION,
  PENDING_REVIEW_DESCRIPTION,
  PENDING_REVIEW_DESCRIPTION_CM,
  DRAFT_DESCRIPTION,
  APPROVED_DESCRIPTION,
  SIGNED_EMPLOYEE_DESCRIPTION,
  SIGNED_BOTH_PARTIES_DESCRIPTION,
  PENDING_INTERNAL_REVIEW_DESCRIPTION,
] = [
  'Archived',
  'Review document',
  'Missing information',
  'Pending',
  'Draft',
  'Approved',
  'Signed by employee',
  'Signed by both parties',
  'Pending internal review',
] as const;

export type ContractStatusDescription =
  | typeof ARCHIVED_DESCRIPTION
  | typeof PENDING_REVIEW_DESCRIPTION
  | typeof PENDING_REVIEW_DESCRIPTION_CM
  | typeof REVIEW_DONE_DESCRIPTION
  | typeof DRAFT_DESCRIPTION
  | typeof APPROVED_DESCRIPTION
  | typeof SIGNED_EMPLOYEE_DESCRIPTION
  | typeof SIGNED_BOTH_PARTIES_DESCRIPTION
  | typeof PENDING_INTERNAL_REVIEW_DESCRIPTION;

export type Contract = {
  avvokaDocumentId?: string;
  avvokaTemplateId?: string;
  contractId?: string;
  createdAt: IsoDateTime;
  documentId?: string;
  status: ContractStatus;
  updatedAt?: IsoDateTime;
  archivedAt?: IsoDateTime;
  version: number;
};

export type ContractAmendmentReviewType = 'reword' | 'add' | 'remove';

export const CONTRACT_AMENDMENT_REVIEW_TYPE_LABELS: Record<
  ContractAmendmentReviewType,
  any
> = {
  reword: 'Reword clause',
  add: 'Add clause',
  remove: 'Remove clause',
};

export const CONTRACT_AMENDMENT_REVIEW_TYPE_LABELS_TRANSFORMED: Record<
  string,
  ContractAmendmentReviewType
> = {
  'Reword clause': 'reword',
  'Add clause': 'add',
  'Remove clause': 'remove',
};

export type ContractAmendmentReviewClause =
  | 'start_date'
  | 'duties'
  | 'place_and_hours_of_work'
  | 'salary_and_benefits'
  | 'equipment'
  | 'holidays'
  | 'illness_or_injury'
  | 'intellectual_property'
  | 'confidentiality'
  | 'termination'
  | 'disciplinary_and_grievance_procedures'
  | 'data_protection'
  | 'employment_structure'
  | 'collective_agreements'
  | 'warranties_and_liability'
  | 'miscellaneous'
  | 'governing_law_and_dispute_resolution'
  | 'other';

export const CONTRACT_AMENDMENT_REVIEW_CLAUSE_LABELS: Record<
  ContractAmendmentReviewClause,
  any
> = {
  start_date: 'Start date',
  duties: 'Duties',
  place_and_hours_of_work: 'Place and hours of work',
  salary_and_benefits: 'Salary & benefits',
  equipment: 'Equipment',
  holidays: 'Holidays',
  illness_or_injury: 'Illness or injury',
  intellectual_property: 'Intellectual property',
  confidentiality: 'Confidentiality',
  termination: 'Termination',
  disciplinary_and_grievance_procedures:
    'Disciplinary and grievance procedures',
  data_protection: 'Data protection',
  employment_structure: 'Employment structure',
  collective_agreements: 'Collective agreements',
  warranties_and_liability: 'Warranties & liability',
  miscellaneous: 'Miscellaneous',
  governing_law_and_dispute_resolution: 'Governing law and dispute resolution',
  other: 'Other',
};

export const CONTRACT_AMENDMENT_REVIEW_CLAUSE_LABELS_TRANSFORMED: Record<
  string,
  ContractAmendmentReviewClause
> = {
  'Start date': 'start_date',
  Duties: 'duties',
  'Place and hours of work': 'place_and_hours_of_work',
  'Salary & benefits': 'salary_and_benefits',
  Equipment: 'equipment',
  Holidays: 'holidays',
  'Illness or injury': 'illness_or_injury',
  'Intellectual property': 'intellectual_property',
  Confidentiality: 'confidentiality',
  Termination: 'termination',
  'Disciplinary and grievance procedures':
    'disciplinary_and_grievance_procedures',
  'Data protection': 'data_protection',
  'Employment structure': 'employment_structure',
  'Collective agreements': 'collective_agreements',
  'Warranties & liability': 'warranties_and_liability',
  Miscellaneous: 'miscellaneous',
  'Governing law and dispute resolution':
    'governing_law_and_dispute_resolution',
  Other: 'other',
};

export type ContractAmendmentReviewStatus =
  | 'pending'
  | 'claimed'
  | 'rejected'
  | 'solved'
  | 'canceled'
  | 'dispatched';

export enum ContractAmendmentUrgency {
  urgent = 'Urgent',
  medium_urgency = 'Medium urgency',
  not_urgent = 'Not urgent',
}

export type ContractAmendmentFormData = {
  reviewType: ContractAmendmentReviewType;
  reviewClause: ContractAmendmentReviewClause;
  reviewClauseNumber: string;
  details: string;
};

export const transformContractAmendmentFormDataForAPI = (
  {
    reviewType,
    reviewClauseNumber,
    reviewClause,
    details,
  }: ContractAmendmentFormData,
  employeeId: string,
  companyId: string,
  contractDocumentId: string,
): ContractAmendmentFormApiRequest => {
  return {
    employeeId,
    companyId,
    documentId: contractDocumentId,
    reviewType: CONTRACT_AMENDMENT_REVIEW_TYPE_LABELS_TRANSFORMED[reviewType],
    reviewClause:
      CONTRACT_AMENDMENT_REVIEW_CLAUSE_LABELS_TRANSFORMED[reviewClause],
    reviewClauseNumber,
    details,
  };
};

export type ContractAmendmentFormApiRequest = {
  employeeId: string;
  companyId: string;
  documentId: string;
  reviewType: ContractAmendmentReviewType;
  reviewClause: ContractAmendmentReviewClause;
  reviewClauseNumber: string;
  details: string;
  urgency?: ContractAmendmentUrgency;
};

export type ContractAmendment = {
  id: string;
  employeeId: string;
  companyId: string;
  documentId: string;
  reviewType: ContractAmendmentReviewType;
  reviewClause: ContractAmendmentReviewClause;
  reviewClauseNumber: string;
  details: string;
  createdAt: string;
  owner: string;
  reviewStatus: ContractAmendmentReviewStatus;
  urgency?: ContractAmendmentUrgency;
};

export type ContractAmendmentUpdateBody = {
  urgency: ContractAmendmentUrgency;
};
