import { PostTerminationRequestModel } from 'common/types/terminations';
import JoiImport from 'joi';
import { DateTime } from 'luxon';

import DateExtension from '@omnipresentgroup/joi-date';

const Joi = JoiImport.extend(DateExtension) as JoiImport.Root;

export const THIRTY_DAYS_FROM_NOW = DateTime.local()
  .plus({ days: 30 })
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .toJSDate();

export const terminationFormValidationSchema = Joi.object({
  isEmployeeOPManager: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required(),
  })
    .required()
    .messages({
      'any.required': 'Please select the answer',
    }),
  employeeOPManagerProfile: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required(),
  })
    .when('isEmployeeOPManager', {
      is: { value: 'yes', label: 'Yes' },
      then: Joi.required(),
    })
    .messages({
      'any.required': 'Please select manager email',
    }),
  isEmployeeAwareOfTermination: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required(),
  })
    .required()
    .messages({
      'any.required': 'Please select the answer',
    }),
  terminationReason: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required(),
  })
    .required()
    .messages({
      'any.required': 'Please select the answer',
    }),
  detailsOfTerminationReason: Joi.string()
    .when('terminationReason', {
      is: {
        value: 'other',
        text: 'Other',
      },
      then: Joi.required(),
    })
    .messages({
      'string.empty': 'Please enter the details',
      'any.required': 'Please enter the details',
    }),
  hasDisputesWithEmployee: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required(),
  })
    .required()
    .messages({
      'any.required': 'Please select the answer',
    }),
  detailsOfDisputesWithEmployee: Joi.string()
    .when('hasDisputesWithEmployee', {
      is: {
        value: 'yes',
        label: 'Yes',
      },
      then: Joi.required(),
    })
    .messages({
      'string.empty': 'Please enter the details',
      'any.required': 'Please enter the details',
    }),
  lastWorkingDayPreference: Joi.date()
    .format('YYYY-MM-DD')
    .raw()
    .required()
    .greater(THIRTY_DAYS_FROM_NOW)
    .messages({
      'date.empty': 'Please enter a date',
      'any.required': 'Please enter a date',
      'date.format': 'Please enter a valid date',
      'date.greater':
        'Please select a date at least 30 days from the date of submission',
    }),
  lastEmploymentDatePreference: Joi.date()
    .format('YYYY-MM-DD')
    .raw()
    .required()
    .greater(THIRTY_DAYS_FROM_NOW)
    .messages({
      'date.empty': 'Please enter a date',
      'any.required': 'Please enter a date',
      'date.format': 'Please enter a valid date',
      'date.greater':
        'Please select a date at least 30 days from the date of submission',
    }),
  employeeNonHolidayLeaves: Joi.array()
    .items(
      Joi.object({
        label: Joi.string().required(),
        value: Joi.string().required(),
      }),
    )
    .min(1)
    .label('Employee Non Holiday Leaves')
    .required()
    .messages({
      'array.min': 'Please pick at least one option or "Not Applicable"',
      'any.required': 'Please select the answer',
    }),
  numberOfVacationDaysBeforeLastEmploymentDate: Joi.number()
    .min(0)
    .required()
    .messages({
      'any.required': 'Please enter a number',
      'number.base': 'Please enter a number',
      'number.min': 'Value must be greater than or equal to 0',
    }),
  overtimeOwed: Joi.number().min(0).allow('').optional().messages({
    'number.min': 'Value must be greater than or equal to 0',
  }),
  overtimeUnits: Joi.object({
    label: Joi.string().allow('').optional(),
    value: Joi.string().allow('').optional(),
  })
    .when('overtimeOwed', {
      is: Joi.number().min(0),
      then: Joi.required(),
    })
    .messages({
      'any.required': 'Please select a value',
    }),
  numberOfDaysOwed: Joi.number()
    .min(0)
    .allow('')
    .optional()
    .messages({ 'number.min': 'Value must be greater than or equal to 0' }),
});

export const questions = {
  defaultAnswers: {
    answers: [
      {
        value: 'yes',
        label: 'Yes',
      },
      {
        value: 'no',
        label: 'No',
      },
    ],
  },
  terminationReason: {
    answers: [
      {
        value: 'poorPerformance',
        label: 'Poor performance',
      },
      {
        value: 'businessReason',
        label: 'Business reason',
      },
      {
        value: 'failedProbation',
        label: "Didn't pass probation",
      },
      {
        value: 'misconduct',
        label: 'Misconduct',
      },
      {
        value: 'restructureOrTakeover',
        label: 'Organizational restructure/Takeover',
      },
      {
        value: 'eorSwitch',
        label: 'Changing EOR Provider',
      },
      {
        value: 'moveToOwnEntity',
        label: 'Moving to own entity',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  employeeNonHolidayLeaves: {
    answers: [
      {
        value: 'notApplicable',
        label: 'Not applicable',
      },
      {
        value: 'sickLeave',
        label: 'Sick leave',
      },
      {
        value: 'parentalLeave',
        label: 'Maternity/Paternity leave',
      },
      {
        value: 'compassionateLeave',
        label: 'Compassionate leave',
      },
      {
        value: 'vacationLeave',
        label: 'Paid/Unpaid vacation leave',
      },
      {
        value: 'studyLeave',
        label: 'Study leave',
      },
      {
        value: 'gardeningLeave',
        label: 'Gardening leave',
      },
      {
        value: 'daysOwed',
        label: 'Days owed',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  overtimeUnits: {
    answers: [
      {
        value: 'days',
        label: 'Day(s)',
      },
      {
        value: 'hours',
        label: 'Hour(s)',
      },
    ],
  },
};

export const removeUndefinedValues = (payload: PostTerminationRequestModel) => {
  Object.keys(payload).forEach((key) => {
    if (!payload[key as keyof PostTerminationRequestModel]) {
      delete payload[key as keyof PostTerminationRequestModel];
    }
  });

  Object.keys(payload.answers).forEach((questionKey) => {
    if (payload.answers[questionKey].selectedAnswerKeys) {
      return;
    } else if (
      !payload?.answers[questionKey]?.inputAnswers &&
      payload?.answers[questionKey]?.inputAnswers !== 0
    ) {
      delete payload.answers[questionKey];
    } else if (
      Array.isArray(payload?.answers[questionKey]?.inputAnswers) &&
      !(payload?.answers[questionKey]?.inputAnswers as unknown as string[])?.[0]
    ) {
      delete payload.answers[questionKey];
    }
  });
};
