import Button from 'common/components/atoms/Button';
import { StyledBoldCopy, StyledContent } from 'common/components/atoms/Text';
import styled, { css } from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const Container = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    place-content: center;
    padding: ${space[64]};

    @media (min-width: ${tokens.breakpoint.tablet}) {
      padding: ${space[64]};
    }
  `,
);

export const Header = styled.header(
  ({ theme: { space, radii } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
      width: 160px;
      height: 160px;
      display: block;
      border-radius: ${radii.s};
    }

    > svg,
    img {
      margin-bottom: ${space[64]};
    }
  `,
);

export const HeaderTitle = styled(StyledBoldCopy)(
  ({ theme: { fontSizes } }) => css`
    text-align: center;
    font-size: ${fontSizes[5]};
  `,
);

export const Description = styled(StyledContent)(
  ({ theme: { fontSizes } }) => css`
    text-align: center;
    white-space: pre-wrap;
    font-size: ${fontSizes[2]};
  `,
);

export const ActionButton = styled(Button)(
  ({ theme: { space } }) => css`
    margin: ${space[32]} auto;
  `,
);
