import { getFxRateForFees } from 'common/api/payroll.api';
import { TWO_MINUTES } from 'common/constants';
import { useQuery } from 'react-query';

import { payrollKeys } from '../keys';

export const useFxRateQuery = (
  fromCurrency: string,
  toCurrency: string,
  { queryOptions }: { queryOptions?: any },
) => {
  return useQuery(
    payrollKeys.fxRate(fromCurrency, toCurrency),
    () => getFxRateForFees(fromCurrency, toCurrency),
    {
      staleTime: TWO_MINUTES,
      ...(queryOptions || {}),
    },
  );
};
