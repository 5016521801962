import { PageContent } from 'app/App.styles';
import styled from 'styled-components';

import tokens from '@omnipresentgroup/design-tokens';

export const ProfilePageContent = styled(PageContent)`
  width: 100%;
  margin: 0 auto;
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme: { space } }) => space[16]};
  justify-content: space-between;
  position: relative;
  align-items: center;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    border-bottom: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
    padding: ${({ theme: { space } }) => `${space[8]} ${space[16]}`};
    margin-bottom: ${({ theme: { space } }) => space[64]};
  }
`;

export const PageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 560px;
  margin: ${({ theme: { space } }) => space[64]} auto;

  @media (max-width: ${tokens.breakpoint.tablet}) {
    margin: 0 auto;
  }
`;

export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ theme: { space } }) => space[16]} 0;
  padding: 34px 50px 42px;
  background: ${({ theme: { colors } }) => `${colors['white-base']}`};
  box-shadow: ${({ theme: { shadows } }) => shadows.s};
  border-radius: ${({ theme: { radii } }) => radii.m};
  width: calc(100% - 100px);
`;

export const FormRequiredFields = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: ${({ theme: { colors } }) => `${colors['text-subtle']}`};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[0]};
  margin-bottom: ${({ theme: { space } }) => space[4]};
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[5]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[3]};
  text-align: center;
  margin-bottom: ${({ theme: { space } }) => space[16]};
`;

export const WelcomeMessage = styled.div`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[1]};
  text-align: center;
`;

export const LogoutItem = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const LogoIcon = styled.div`
  height: 60px;
  width: 60px;
  position: absolute;
  left: 50%;
  transform: translateX(-30px);
`;
