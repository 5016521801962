import { PageContent } from 'app/App.styles';
import styled, { css } from 'styled-components';

const activeStage = css`
  padding-right: ${({ theme: { space } }) => space[64]};

  h2 {
    color: inherit;
  }

  h3 {
    color: ${({ theme: { colors } }) => colors['text-default']};
  }

  p {
    color: ${({ theme: { colors } }) => colors['text-default']};
  }

  ::before,
  ::after {
    content: none;
  }
`;

export const OnboardingList = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
`;

export const OnboardingStage = styled.li<{ active?: boolean }>`
  position: relative;
  padding: 0 6em 0 ${({ theme: { space } }) => space[64]};
  color: ${({ theme: { colors } }) => colors['gray-300']};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};

  :first-of-type {
    padding-left: 0;
  }

  & + li {
    border-left: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  }

  h2,
  h3,
  p {
    margin: 0;
  }

  h2 {
    color: ${({ theme: { colors } }) => colors['text-default']};
    font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
    margin-bottom: ${({ theme: { space } }) => space[4]};
  }

  h3 {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
    color: ${({ theme: { colors } }) => colors['text-default']};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
    margin-bottom: ${({ theme: { space } }) => space[4]};
  }

  p {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
  }

  ::before {
    content: '';
    position: absolute;
    height: ${({ theme: { space } }) => space[32]};
    width: ${({ theme: { space } }) => space[32]};
    top: 50%;
    right: ${({ theme: { space } }) => space[64]};
    transform: translateY(-50%);
    background: ${({ theme: { colors } }) => colors['icon-default']};
    border-radius: ${({ theme: { radii } }) => radii.full};
  }

  ::after {
    content: '';
    position: absolute;
    height: 12px;
    width: ${({ theme: { space } }) => space[8]};
    top: 49%;
    right: calc(${({ theme: { space } }) => space[64]} + 2px);
    transform: rotate(45deg) translateY(-70%);
    border-right: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.m} ${borderStyles.solid} ${colors['white-base']}`};
    border-bottom: ${({ theme: { borderWidths, colors, borderStyles } }) =>
      `${borderWidths.m} ${borderStyles.solid} ${colors['white-base']}`};
  }

  ${(props) => (props.active ? activeStage : '')}
`;

export const ContractHeading = styled.div`
  display: flex;

  h1 {
    color: ${({ theme: { colors } }) => colors['text-default']};
    margin: 0 0 ${({ theme: { space } }) => space[4]};
    font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  }

  h2 {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[1]};
    color: ${({ theme: { colors } }) => colors['gray-300']};
    margin-bottom: ${({ theme: { space } }) => space[16]};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  }

  button {
    align-self: flex-start;
  }
`;

export const ContractHeadingText = styled.div`
  flex-grow: 1;
`;

export const ContractContainer = styled.div.attrs(
  (props: { showContractAmendmentForm: boolean }) => props,
)`
  display: flex;
  flex-direction: column;
  flex-grow: ${(props) => (props.showContractAmendmentForm ? 2 : 1)};
  background: ${({ theme: { colors } }) => colors['background-primary']};
  padding: ${({ theme: { space } }) => space[32]};

  iframe {
    flex-grow: 1;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  margin-right: ${(props) => (props.showContractAmendmentForm ? `1rem` : 0)};
`;

export const ContractPageContent = styled(PageContent)`
  height: 100%;
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  button {
    margin-left: ${({ theme: { space } }) => space[16]};
  }
`;
