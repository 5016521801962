import styled from 'styled-components';

export const LoadingSpinner = styled.div`
  ${({ theme: { space, borderStyles, colors } }) => `
  border: ${space[4]} ${borderStyles.solid} ${colors['border-subtle']};
  border-top: ${space[4]} ${borderStyles.solid} ${colors['border-strong']};
  width: ${space[32]};
  height: ${space[32]};
`}
  border-radius: ${({ theme: { radii } }) => radii.full};
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
