import { createContext, useContext, useEffect, useState } from 'react';

import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { useSelector } from 'react-redux';
import { useBenefitsByCountry } from 'utils/queries/benefits';
import { useBenefitsClientPreferencesQuery } from 'utils/queries/benefits/useBenefitsPreferences';

import { getSelectedCompanySelector } from '../../../store/selectors/companies.selectors';
import {
  BenefitsCountry,
  BenefitsCountrySelectionState,
  Seat,
} from '../../../types';

export type BenefitsSelectionContextType = {
  companyId: string;
  companyName: string;
  countryCode: string;
  isFetching: boolean;
  clientPreferences: {
    populated: boolean;
    value?: BenefitsCountrySelectionState;
    selectedOptionToSave: BenefitsCountrySelectionState | null;
    setSelectedOptionToSave: (
      value: BenefitsCountrySelectionState | null,
    ) => void;
  };
  countryData: BenefitsCountry | Partial<BenefitsCountry>;
  seat: Seat;
};

export const BenefitsSelectionContext =
  createContext<BenefitsSelectionContextType>({
    companyId: '',
    countryCode: '',
    companyName: '',
    isFetching: true,
    clientPreferences: {
      populated: false,
      selectedOptionToSave: null,
      setSelectedOptionToSave: () => {},
    },
    countryData: {},
    seat: {} as Seat,
  });

export function useBenefitsSelectionContext() {
  return useContext<BenefitsSelectionContextType>(BenefitsSelectionContext);
}

export type Props = {
  children: React.ReactNode;
  companyId: string;
  countryCode: string;
  seat: Seat;
};

export const BenefitsSelectionProvider = ({
  children,
  companyId,
  countryCode,
  seat,
}: Props) => {
  const company = useSelector(getSelectedCompanySelector);
  const [selectedOptionToSave, setSelectedOptionToSave] =
    useState<BenefitsCountrySelectionState | null>(null);

  const benefitsCountryQuery = useBenefitsByCountry(countryCode);

  const benefitsPreferencesQuery = useBenefitsClientPreferencesQuery({
    countryCode,
    clientId: companyId,
  });

  useEffect(() => {
    benefitsCountryQuery?.data?.isRequired && setSelectedOptionToSave('YES');
  }, [benefitsCountryQuery?.data?.isRequired]);

  const isLoading =
    benefitsPreferencesQuery.isLoading || benefitsCountryQuery.isLoading;
  if (isLoading) {
    return <Loading />;
  }

  if (benefitsCountryQuery.isError) {
    const errorMessage =
      benefitsPreferencesQuery.error?.message ||
      benefitsCountryQuery.error?.message;

    return <ErrorBanner errorMessage={errorMessage} />;
  }

  return (
    <BenefitsSelectionContext.Provider
      value={{
        companyId,
        companyName: company?.companyName || '',
        countryCode,
        isFetching:
          benefitsPreferencesQuery.isFetching ||
          benefitsCountryQuery.isFetching,
        clientPreferences: {
          populated: !!benefitsPreferencesQuery.data,
          value: benefitsPreferencesQuery.data?.selectionStatus,
          selectedOptionToSave,
          setSelectedOptionToSave,
        },
        countryData: benefitsCountryQuery.data || {},
        seat,
      }}
    >
      {children}
    </BenefitsSelectionContext.Provider>
  );
};
