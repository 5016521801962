import { StyledTitleCell } from 'common/components/organisms/TableWithTitle/TableWithTitle.styles';
import { transformLspType } from 'common/store/transformers/lsps.transformer';
import { CountryName, LSPApiResponse, LSPTypes } from 'common/types';

import { Tag } from '@omnipresentgroup/design-system';

import { StyledTableCell } from '../ProviderListPage/Tabs/EmployersTab.styles';

const NameCustomCell = ({ name }: { name: string }) => {
  return <StyledTitleCell>{name}</StyledTitleCell>;
};

const TypeCustomCell = ({ type }: { type: LSPTypes }) => {
  return <StyledTitleCell>{transformLspType(type)}</StyledTitleCell>;
};

const IsActiveCustomCell = ({ isActive }: { isActive: string }) => (
  <StyledTableCell>
    <Tag
      id="is-active-label-cell-tag"
      intent={isActive ? 'success' : 'default'}
    >
      {isActive ? 'Active' : 'Inactive'}
    </Tag>
  </StyledTableCell>
);

const countryDisplayLimit = 3;
export const tableHeaders = [
  {
    headerTitle: 'Name',
    customCell: NameCustomCell,
    align: 'left',
    fiedOnObject: 'name',
  },
  {
    headerTitle: 'Type',
    customCell: TypeCustomCell,
    align: 'left',
    fiedOnObject: 'type',
  },
  {
    headerTitle: 'Countries',
    customCell: ({ countries }: { countries?: CountryName[] }) => {
      if (!countries || !countries.length) {
        return '';
      }
      const countriesToDisplay = [...countries]
        .sort((a, b) => (a < b ? -1 : 1))
        .slice(0, countryDisplayLimit)
        .join(', ');

      const additionalCountriesIndicator =
        countries.length > countryDisplayLimit
          ? ` ... (${countries.length - countryDisplayLimit} more)`
          : '';

      return `${countriesToDisplay}${additionalCountriesIndicator}`;
    },
    fiedOnObject: 'countries',
  },
  {
    headerTitle: 'Number of Employees',
    customCell: ({ noOfEmployees }: { noOfEmployees: number }) =>
      `${noOfEmployees}`,
    fiedOnObject: 'noOfEmployees',
  },
  {
    headerTitle: 'Active',
    customCell: IsActiveCustomCell,
  },
];

export const cardHeaders = tableHeaders
  .filter((header) => header.headerTitle !== 'Name')
  .map((header) => {
    if (header.fiedOnObject === 'noOfEmployees') {
      return {
        ...header,
        customCell: (field: LSPApiResponse) =>
          `Number of employees: ${header.customCell(field)}`,
      };
    }
    if (header.fiedOnObject === 'countries') {
      return {
        ...header,
        customCell: (field: LSPApiResponse) =>
          `Countries: ${header.customCell(field)}`,
      };
    }
    return header;
  });
