import { client } from 'api';

import { MergeEmployeesListResponse } from '../types';
import { Integration, IntegrationProvider } from '../types/integration';

export const INTEGRATIONS_BASE_API_ENDPOINT = '/integrations';

export type BaseIntegrationPayload = Record<
  'companyId' | 'integrationId',
  string
>;

export type MergeIntegrationStartResponse = {
  integrationId: string;
  linkToken: string;
};

export type CreateMergeIntegrationStartPayload = {
  companyId: string;
  companyName: string;
};

export const createMergeIntegrationStartRequest = (
  payload: CreateMergeIntegrationStartPayload,
) =>
  client.post<MergeIntegrationStartResponse>(
    `${INTEGRATIONS_BASE_API_ENDPOINT}/merge/start`,
    payload,
  );

export type CreateMergeIntegrationInstallPayload = {
  companyId: string;
  integrationId: string;
  publicToken: string;
};

export const createMergeIntegrationsInstallRequest = (
  payload: CreateMergeIntegrationInstallPayload,
) =>
  client.post<Integration>(
    `${INTEGRATIONS_BASE_API_ENDPOINT}/merge/install`,
    payload,
  );

export type DeleteMergeIntegrationPayload = BaseIntegrationPayload;

export const deleteMergeIntegrationRequest = (
  payload: DeleteMergeIntegrationPayload,
) =>
  client.delete(`${INTEGRATIONS_BASE_API_ENDPOINT}/merge/install`, {
    data: payload,
  });

export type GetHRISEmployeesListPayload = BaseIntegrationPayload & {
  provider: IntegrationProvider;
};

export const getHRISEmployeesList = ({
  companyId,
  provider,
  integrationId,
}: GetHRISEmployeesListPayload) =>
  client.get<MergeEmployeesListResponse>(
    `${INTEGRATIONS_BASE_API_ENDPOINT}/${provider}/${integrationId}/employees?companyId=${companyId}&includes=work_location,employments`,
  );
