import { useParams } from 'react-router-dom';

import { EMPLOYEES_PATH } from 'paths';

import { Notification } from '@omnipresentgroup/design-system';

import { StyledLink } from './RightToWorkAlert.styles';

export const RightToWorkAlert = () => {
  return (
    <Notification
      id="right-of-work-alert"
      intent="warning"
      title="The employee's right to work has not been confirmed."
      description={<RightToWorkAlertMessage />}
    />
  );
};

export const RightToWorkAlertMessage = () => {
  const { employeeId } = useParams<{ employeeId: string }>();
  return (
    <>
      Click{' '}
      <StyledLink
        to={{
          pathname: `${EMPLOYEES_PATH}/${employeeId}/edit`,
          hash: 'rightToWorkCheckCompleted',
        }}
      >
        here
      </StyledLink>{' '}
      to update it.
    </>
  );
};
