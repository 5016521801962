import { ExpenseStatusEnum } from 'common/components/molecules/ExpenseStatus/ExpenseStatus';
import { ExpenseFromAPI } from 'common/types/expenses';

export const mockEmployeesExpenses: ExpenseFromAPI[] = [
  {
    id: '7a634f20-74ee-4d0a-94b9-037420dc872f',
    employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
    employeeName: 'Roy Testerton',
    approverId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    approverName: 'Testman Ellis',
    title: 'New laptop',
    description: 'Bought a new macbook for work',
    documentId: '11324234234234',
    category: 'PRINTING',
    amount: {
      amount: 1200.4,
      currency: 'USD',
    },
    createdAt: '2020-01-03T00:00:00.000Z',
    dateSpent: '2019-12-30',
    status: ExpenseStatusEnum.PENDING,
    statusHistory: [
      {
        status: ExpenseStatusEnum.PENDING,
        statusChangedAt: '2020-01-03T00:00:00.000Z',
      },
    ],
    paid: false,
    requestedChange: undefined,
    rejectionReason: undefined,
    approverSeen: true,
  },
  {
    id: '7a634f20-74ee-4d0a-94b9-037420dc854d',
    employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
    employeeName: 'Wisdom Adzorgenu',
    approverId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    approverName: 'Testman Ellis',
    title: 'Lunch',
    description: 'Bought lunch for a client',
    documentId: '11324234234234',
    category: 'PRINTING',
    amount: { amount: 100, currency: 'GBP' },
    createdAt: '2020-02-10T00:00:00.000Z',
    dateSpent: '2020-02-10',
    status: ExpenseStatusEnum.INFO_NEEDED,
    statusHistory: [
      {
        status: ExpenseStatusEnum.INFO_NEEDED,
        statusChangedAt: '2020-02-11T00:00:00.000Z',
      },
      {
        status: ExpenseStatusEnum.INFO_NEEDED,
        statusChangedAt: '2020-02-10T00:00:00.000Z',
      },
    ],
    paid: false,
    requestedChange: 'Please include more information about the lunch',
    rejectionReason: undefined,
    approverSeen: false,
  },
  {
    id: '24b4f539-c948-4e7a-beeb-9f873312b7fe',
    employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
    employeeName: 'Roy Testerton',
    approverId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    approverName: 'Testman Ellis',
    title: 'Software license',
    description: 'Bought a new IDE license',
    category: 'SUBSISTENCE',
    documentId: '11324234234234',
    amount: { amount: 86, currency: 'CAD' },
    createdAt: '2020-02-11T00:00:00.000Z',
    dateSpent: '2020-02-11',
    status: ExpenseStatusEnum.PAID,
    statusHistory: [
      {
        status: ExpenseStatusEnum.APPROVED,
        statusChangedAt: '2022-02-11T00:00:00.000Z',
      },
      {
        status: ExpenseStatusEnum.INFO_NEEDED,
        requestedChanges: 'Please include more information about the software',
        statusChangedAt: '2020-02-11T00:00:00.000Z',
      },
      {
        status: ExpenseStatusEnum.PENDING,
        statusChangedAt: '2020-02-10T00:00:00.000Z',
      },
    ],
    estimatedPayrollMonth: '2022-03-01',
    paid: true,
    requestedChange: undefined,
    rejectionReason: undefined,
    approverSeen: true,
  },
  {
    id: '7a634f20-74ee-4d0a-94b9-037420dc243g',
    employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8b9y3',
    employeeName: 'Simone Testarona',
    approverId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    approverName: 'Testman Ellis',
    title: 'New mouse',
    description: 'New ergonomic mouse for home office',
    documentId: '11324234234234',
    category: 'SUBSISTENCE',
    amount: { amount: 50, currency: 'CHF' },
    createdAt: '2020-03-10T00:00:00.000Z',
    dateSpent: '2020-03-10',
    status: ExpenseStatusEnum.REJECTED,
    statusHistory: [
      {
        status: ExpenseStatusEnum.REJECTED,
        statusChangedAt: '2020-03-06T00:00:00.000Z',
      },
      {
        status: ExpenseStatusEnum.PENDING,
        statusChangedAt: '2020-03-05T00:00:00.000Z',
      },
    ],
    paid: false,
    requestedChange: undefined,
    rejectionReason: "We don't pay for accessories, sorry",
    approverSeen: true,
  },
  {
    id: '28d5dc5f-0fdd-475a-bc2e-e1574edeace1',
    employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8h8t5',
    employeeName: 'James Maddison',
    approverId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    approverName: 'Testman Ellis',
    title: 'Office chair',
    description: 'New ergonomic chair for home office',
    documentId: '11324234234234',
    amount: { amount: 70, currency: 'EUR' },
    category: 'SUBSISTENCE',
    createdAt: '2020-03-05T00:00:00.000Z',
    dateSpent: '2020-03-05',
    status: ExpenseStatusEnum.PAID,
    statusHistory: [
      {
        status: ExpenseStatusEnum.APPROVED,
        statusChangedAt: '2020-03-06T00:00:00.000Z',
      },
      {
        status: ExpenseStatusEnum.PENDING,
        statusChangedAt: '2020-03-05T00:00:00.000Z',
      },
    ],
    paid: true,
    estimatedPayrollMonth: '2020-03-01',
    requestedChange: undefined,
    rejectionReason: undefined,
    approverSeen: true,
  },
  {
    id: '33ea1690-e5f3-4758-9326-ec5266d12da8',
    employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8h8t5',
    employeeName: 'Egbert Simpson',
    approverId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    approverName: 'Testman Ellis',
    title: 'Office chair',
    description: 'New ergonomic chair for home office',
    documentId: '11324234234234',
    amount: { amount: 70, currency: 'EUR' },
    category: 'SUBSISTENCE',
    createdAt: '2020-03-05T00:00:00.000Z',
    dateSpent: '2020-03-05',
    status: ExpenseStatusEnum.APPROVED,
    statusHistory: [
      {
        status: ExpenseStatusEnum.APPROVED,
        statusChangedAt: '2020-03-06T00:00:00.000Z',
      },
      {
        status: ExpenseStatusEnum.PENDING,
        statusChangedAt: '2020-03-05T00:00:00.000Z',
      },
    ],
    paid: false,
    estimatedPayrollMonth: '2020-03-01',
    requestedChange: undefined,
    rejectionReason: undefined,
    approverSeen: true,
  },
  {
    id: '7a634f20-74ee-4d0a-94b9-037420dc872g',
    employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8h8t5',
    employeeName: 'James Maddison',
    approverId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    approverName: 'Testman Ellis',
    title: 'New desktop',
    description: 'Bought a new desktop',
    category: 'PRINTING',
    amount: {
      amount: 1200.4,
      currency: 'USD',
    },
    createdAt: '2020-01-03T00:00:00.000Z',
    dateSpent: '2019-12-30',
    status: ExpenseStatusEnum.PENDING,
    statusHistory: [
      {
        status: ExpenseStatusEnum.PENDING,
        statusChangedAt: '2020-01-03T00:00:00.000Z',
      },
    ],
    paid: false,
    requestedChange: '',
    rejectionReason: '',
    approverSeen: true,
  },
  {
    id: 'f0956dc6-cdd2-40ff-b783-776a6e865cf6',
    employeeId: 'cab6805c-0e62-4db3-9026-6ecdc5b85d76',
    employeeName: 'Roy Testerton Bis',
    approverId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
    approverName: 'Testman Ellis',
    title: 'New desk',
    description: 'Bought a new desk for work',
    documentId: '11324234234234',
    category: 'OFFICE_EQUIPMENT',
    amount: {
      amount: 400.5,
      currency: 'EUR',
    },
    createdAt: '2022-01-03T00:00:00.000Z',
    dateSpent: '2021-12-30',
    status: ExpenseStatusEnum.PENDING,
    statusHistory: [
      {
        status: ExpenseStatusEnum.PENDING,
        statusChangedAt: '2022-01-03T00:00:00.000Z',
      },
    ],
    paid: false,
    requestedChange: undefined,
    rejectionReason: undefined,
    approverSeen: true,
  },
];
export const generateEmployeeExpenses = (
  override: Partial<ExpenseFromAPI>,
) => ({
  ...mockEmployeesExpenses,
  ...override,
});
export const mockManagerExpenseCsv =
  'Employee,Amount,Currency,Spent At,Date,Status\nTesto McTestore,2000,GBP,New laptop,2020-12-12T00:00:00.000Z,PENDING\n';
