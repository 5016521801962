import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import { getEmployeeExpense } from 'common/api/expense.api';
import { ErrorBanner } from 'common/components';
import { ConfirmationModal } from 'common/components/molecules/ConfirmationModal';
import { EXPENSE_LIST_PAGE, getEmployeeProfilePage } from 'paths';
import { useQuery } from 'react-query';
import { useSelectUserRoles } from 'utils/hooks';
import { useLegacyApproveExpenseMutation } from 'utils/queries/expenses/useApproveExpenseMutation';
import { useDeleteExpenseMutation } from 'utils/queries/expenses/useDeleteExpenseMutation';
import { useLegacyUpdateExpenseMutation } from 'utils/queries/expenses/useUpdateExpenseMutation';

import { Viewport } from '@omnipresentgroup/design-system';

import Button from '../../components/atoms/Button/Button';
import LinkAsButton from '../../components/atoms/LinkAsButton/LinkAsButton';
import Loading from '../../components/atoms/Loading/Loading';
import { ExpenseStatus } from '../../components/molecules/ExpenseStatus/ExpenseStatus';
import InfoBanner from '../../components/molecules/InfoBanner/InfoBanner';
import InputWithConfirm from '../../components/molecules/InputWIthConfirm/InputWithConfirm';
import PageHeader from '../../components/molecules/PageHeader/PageHeader';
import DetailsCard from '../../components/organisms/DetailsCard/DetailsCard';
import {
  StyledButtonContainer,
  StyledEmployeeExpenseDetailPage,
  StyledExpenseDetails,
} from './EmployeeExpenseDetailPage.styles';

function useSearchQuery() {
  return new URLSearchParams(useLocation().search);
}

export enum ExpenseModals {
  DELETE_EXPENSE_CONFIRM = 'delete-expense-confirm',
  DELETE_EXPENSE_ERROR = 'delete-expense-error',
}

const EmployeeExpenseDetailPage = () => {
  const { expenseId } = useParams<{ expenseId: string }>();
  const [requestingChange, setRequestingChange] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [visibleModal, setVisibleModal] = useState<ExpenseModals | void>();
  const { isManager, isAdmin, isEmployee } = useSelectUserRoles();

  const location = useLocation();

  const query = useSearchQuery();
  const referrer = query.get('referrer');

  const expenseQuery = useQuery(`expense-${expenseId}`, () =>
    getEmployeeExpense(expenseId),
  );

  const approveExpenseMutation = useLegacyApproveExpenseMutation(expenseId);
  const updateExpenseMutation = useLegacyUpdateExpenseMutation(expenseId);
  const deleteExpenseMutation = useDeleteExpenseMutation(expenseId);

  const discardDeleteModals = useCallback(() => {
    deleteExpenseMutation.reset();
    setVisibleModal();
  }, [deleteExpenseMutation]);

  useEffect(() => {
    if (deleteExpenseMutation.isError) {
      setVisibleModal(ExpenseModals.DELETE_EXPENSE_ERROR);
    }
  }, [expenseId, deleteExpenseMutation.isError]);

  useEffect(() => {
    if (deleteExpenseMutation.isSuccess && expenseQuery.isSuccess) {
      appHistory.push(
        `${getEmployeeProfilePage(expenseQuery.data.employeeId)}#expenses`,
      );
    }
  }, [expenseId, deleteExpenseMutation.isSuccess, expenseQuery]);

  const approveDeleteConfirmationModalProps = {
    title: 'Delete expense?',
    message: `Are you sure you want to delete this expense?`,
    cancelLabel: 'CANCEL',
    cancelHandler: discardDeleteModals,
    confirmLabel: 'DELETE',
    confirmHandler: () => {
      if (expenseId) {
        deleteExpenseMutation.mutate();
      }
    },
    loading: deleteExpenseMutation.isLoading,
  };

  const approveDeleteErrorModalProps = {
    title: 'Failed to delete',
    message: 'Failed to delete expense. Please try again later',
    cancelHandler: discardDeleteModals,
    cancelLabel: 'CLOSE',
  };

  if (expenseQuery.isError) {
    return (
      <ErrorBanner
        onErrorClear={() => {}}
        errorMessage={
          (expenseQuery.error instanceof Error && expenseQuery.error.message) ||
          ''
        }
      />
    );
  }

  if (expenseQuery.isSuccess) {
    const {
      title,
      status,
      description,
      expenseDetails,
      requestedChange,
      rejectionReason,
      employeeId,
      paid,
    } = expenseQuery.data;

    const onApprove = () => approveExpenseMutation.mutate();

    const isRejected = status === 'REJECTED';
    const isApproved = status === 'APPROVED';
    const isPaid = status === 'PAID' ? true : paid;
    const isPending = !isApproved && !isRejected;
    const canManageExpense =
      !requestingChange &&
      !rejecting &&
      !isRejected &&
      !isApproved &&
      !isPaid &&
      isManager;

    const canDeleteExpense = (!isPaid && isEmployee) || (!isPaid && isAdmin);

    return (
      <PageContent>
        {expenseQuery.isLoading && <Loading />}
        {!expenseQuery.isLoading && expenseDetails?.length && (
          <StyledEmployeeExpenseDetailPage
            data-testid="employee-expense-detail-page"
            className="bigStack"
          >
            {isAdmin && (
              <PageHeader
                title={title}
                copy={description}
                ctaText={isPending && 'Approve'}
                onCTAClick={onApprove}
                hasDisabledCTA={approveExpenseMutation.isLoading}
                statusPill={<ExpenseStatus status={status} />}
              />
            )}

            {referrer === 'employee' ? (
              <LinkAsButton
                to={`${getEmployeeProfilePage(employeeId)}#expenses`}
              >
                Back to employee
              </LinkAsButton>
            ) : (
              <LinkAsButton to={`${EXPENSE_LIST_PAGE}${location.search}`}>
                Back to all expenses
              </LinkAsButton>
            )}

            <StyledExpenseDetails>
              {requestedChange.value && !isApproved && (
                <InfoBanner
                  type="info"
                  copy={`You have requested a change to the expense: "${requestedChange.value}"`}
                />
              )}
              {rejectionReason.value && (
                <InfoBanner
                  type="warning"
                  copy={`You have rejected the expense: "${rejectionReason.value}"`}
                />
              )}
              <DetailsCard
                title="Details"
                fieldsToDisplay={expenseDetails}
                testId="employee-expense-details-manager-page"
              />
            </StyledExpenseDetails>
            {canManageExpense && (
              <StyledButtonContainer>
                <Viewport devices={['phone', 'tablet']}>
                  <Button onClick={onApprove}>Approve</Button>
                </Viewport>
                <Button
                  onClick={() => setRequestingChange(true)}
                  palette="secondary"
                >
                  Request change
                </Button>
                <Button
                  variant="link"
                  palette="secondary"
                  onClick={() => setRejecting(true)}
                >
                  Reject
                </Button>
              </StyledButtonContainer>
            )}
            {canDeleteExpense && (
              <>
                <StyledButtonContainer>
                  <Button
                    onClick={() => {
                      setVisibleModal(ExpenseModals.DELETE_EXPENSE_CONFIRM);
                    }}
                  >
                    Delete expense
                  </Button>
                </StyledButtonContainer>
                {visibleModal === ExpenseModals.DELETE_EXPENSE_CONFIRM && (
                  <ConfirmationModal {...approveDeleteConfirmationModalProps} />
                )}

                {visibleModal === ExpenseModals.DELETE_EXPENSE_ERROR && (
                  <ConfirmationModal {...approveDeleteErrorModalProps} />
                )}
              </>
            )}
            {requestingChange && (
              <InputWithConfirm
                field={requestedChange}
                onConfirm={(updatedExpense) => {
                  updateExpenseMutation.mutate(updatedExpense, {
                    onSuccess: () => setRequestingChange(false),
                  });
                }}
                onCancel={() => setRequestingChange(false)}
                disabled={updateExpenseMutation.status === 'loading'}
              />
            )}
            {rejecting && (
              <InputWithConfirm
                field={rejectionReason}
                ctaText="Reject expense"
                onConfirm={(updatedExpense) => {
                  updateExpenseMutation.mutate(updatedExpense, {
                    onSuccess: () => setRejecting(false),
                  });
                }}
                onCancel={() => setRejecting(false)}
                disabled={updateExpenseMutation.status === 'loading'}
              />
            )}
          </StyledEmployeeExpenseDetailPage>
        )}
      </PageContent>
    );
  } else {
    return <Loading />;
  }
};

export default EmployeeExpenseDetailPage;
