import styled from 'styled-components';

export const StyledFormProgressContainer = styled.div`
  display: flex;
  height: ${(props) => `${props.stages * 70}px`};
  max-width: 230px;

  p {
    margin-left: ${({ theme: { space } }) => `${space[16]}`};
  }
`;

export const StyledProgressIndicatorContainer = styled.div`
  width: 10px;
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
  border-radius: ${({ theme: { radii } }) => radii.s};
`;

export const StyledProgressIndicator = styled.div`
  height: ${(props) => `${props.progress}%`};
  background-color: ${({ theme: { colors } }) => colors['background-inverse']};
  border-radius: ${({ theme: { radii } }) => radii.s}
    ${({ theme: { radii } }) => radii.s} 0 0;
`;

export const StyledStagesCopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledStageCopyContainer = styled.div``;
