import appHistory from 'app/appHistory';
import Button from 'common/components/atoms/Button/Button';
import { StyledFullWidthBlueDivider } from 'common/components/atoms/Divider/Divider.styles';
import { StyledBodyCopy } from 'common/components/atoms/Text/Copy.styles';
import { StyledModalContainer } from 'common/components/molecules/ConfirmationPopup/ConfirmationPopup.styles';
import { EMPLOYEES_PATH } from 'paths';

import { Typography } from '@omnipresentgroup/design-system';

import {
  StyledButtonContainer,
  StyledContainer,
} from './EmployerFieldMissingModal.style';

type EmployerFieldMissingModalProps = {
  employeeName: string;
  employeeId: string;
  stateChanger: (value: boolean) => void;
  setOpenDrawer?: (value: boolean) => void;
};

export const EmployerFieldMissingModal = ({
  employeeName,
  employeeId,
  stateChanger,
  setOpenDrawer,
}: EmployerFieldMissingModalProps) => {
  const addEmployeePath = `${EMPLOYEES_PATH}/${employeeId}/edit`;

  return (
    <StyledModalContainer>
      <StyledContainer className="stack">
        <Typography as="h4" hideParagraphSpacing size="24" weight="medium">
          Employer missing
        </Typography>
        <StyledFullWidthBlueDivider />
        <StyledBodyCopy>
          {`Please fill out ${employeeName}'s Employer before completing the onboarding`}
        </StyledBodyCopy>

        <StyledButtonContainer>
          <Button
            variant="outlined"
            palette="secondary"
            onClick={() => {
              if (!!setOpenDrawer) {
                setOpenDrawer(false);
              }
              stateChanger(false);
            }}
          >
            Cancel
          </Button>
          <Button
            palette="primary"
            onClick={() => {
              if (!!setOpenDrawer) {
                setOpenDrawer(false);
              }
              stateChanger(false);
              appHistory.push(addEmployeePath);
            }}
          >
            Update Employer
          </Button>
        </StyledButtonContainer>
      </StyledContainer>
    </StyledModalContainer>
  );
};
