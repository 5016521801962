import {
  Component,
  ErrorInfo,
  ReactNode,
  Suspense,
  useEffect,
  useRef,
} from 'react';

import type { Props } from 'rich-markdown-editor';
import { lazyWithRetry } from 'utils/lazyWithRetry';

import { Spinner } from '@omnipresentgroup/design-system';

const SUPPRESSED_ERRORS = ['ResizeObserver'];

class EditorErrorBoundary extends Component<{ children: ReactNode }> {
  private mounted = false;

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    if (!this.mounted) {
      return;
    }

    if (SUPPRESSED_ERRORS.some((errMsg) => error.message.includes(errMsg))) {
      return;
    }
    throw error;
  }

  render() {
    return this.props.children;
  }
}

const EditorWithBoundary = lazyWithRetry(() => {
  const Editor = import('rich-markdown-editor');
  return Editor;
});

export const SafeRichMarkdownEditor = (props: Props) => {
  const mountedRef = useRef(true);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const safeProps: Props = {
    ...props,
    onChange: (...args) => {
      if (mountedRef.current && props.onChange) {
        try {
          props.onChange(...args);
        } catch (error) {
          if (mountedRef.current) {
            throw error;
          }
        }
      }
    },
  };

  return (
    <Suspense fallback={<Spinner size="40" />}>
      <EditorErrorBoundary>
        <EditorWithBoundary {...safeProps} />
      </EditorErrorBoundary>
    </Suspense>
  );
};
