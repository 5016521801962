import { createClient as createContentfulClient } from 'contentful';

const space: string = process.env.VITE_CONTENTFUL_SPACE || 'fake-space';
const accessToken: string =
  process.env.VITE_CONTENTFUL_TOKEN || 'fake-access-token';

const contentfulClient = createContentfulClient({
  space,
  accessToken,
});

export const getContentfulClient = () => contentfulClient;
