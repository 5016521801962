import { Flag } from 'react-feather';
import { getCountryFlagByCountryName } from 'utils/countries';

import { ListEmployeeData } from '.';

export const CountryWithFlagCell = (employee: ListEmployeeData) => {
  return (
    employee &&
    employee.country && (
      <>
        <span
          role="img"
          aria-label={`${employee.country
            .toLowerCase()
            .replace(/ /g, '-')}-flag`}
        >
          {getCountryFlagByCountryName(employee.country) || (
            <Flag height="20px" width="20px" />
          )}
        </span>
        {`  ${employee.country}`}
      </>
    )
  );
};
