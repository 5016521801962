import { TransformedUser } from 'app/store/app.transformers';
import { User } from 'common/types';
import {
  isAdmin,
  isBenefitsAdmin,
  isEmployee,
  isManager,
  isPayrollAdmin,
} from 'utils/user';

export const getEditableDetailsByUserType = (
  details: any[],
  user: User | TransformedUser,
) => {
  if (!details.length) {
    return [];
  }
  return details
    .map((section) => {
      if (user && isManager(user)) {
        return {
          ...section,
          details: section.details.filter(
            (field: { managerEditable: any }) => field.managerEditable,
          ),
        };
      }
      if (user && isEmployee(user)) {
        return {
          ...section,
          details: section.details.filter(
            (field: { employeeEditable: any }) => field.employeeEditable,
          ),
        };
      }

      if (user && isBenefitsAdmin(user)) {
        return {
          ...section,
          details: section.details.filter(
            (field: { benefitsSpecialistEditable: any }) =>
              fieldAdminEditable(field, user) ||
              field.benefitsSpecialistEditable,
          ),
        };
      }

      if (user && isAdmin(user)) {
        return {
          ...section,
          details: section.details.filter((field: any) =>
            fieldAdminEditable(field, user),
          ),
        };
      }

      return null;
    })
    .filter((section) => !!section.details.length);
};

const hasPayrollFields = (user: any, field: any) => {
  const isPayrollEditable =
    typeof (field.payrollEditable === Boolean) &&
    field.payrollEditable !== false;

  return user && isPayrollAdmin(user) && isPayrollEditable;
};

const fieldAdminEditable = (field: any, user: User | TransformedUser) => {
  const isAdminEditable =
    typeof (field.adminEditable === Boolean) && field.adminEditable !== false;
  return (
    ![
      'temporaryPassword',
      'personalEmail',
      'sentInvitationAt',
      'externalId',
    ].includes(field.name) &&
    (hasPayrollFields(user, field) || isAdminEditable)
  );
};
