import styled from 'styled-components';

export const StyledFloatingActionButton = styled.button`
  width: 48px;
  height: 48px;
  background-color: ${({ theme: { colors } }) => colors['gray-500']};
  box-shadow: ${({ theme: { shadows } }) => shadows.s};
  right: 20px;
  border-radius: ${({ theme: { radii } }) => radii.full};
  position: fixed;
  bottom: 80px;
  margin: 0;
  cursor: pointer;
  border: ${({ theme: { borderStyles } }) => borderStyles.none};

  :hover {
    box-shadow: ${({ theme: { shadows } }) => shadows.s};
    background-color: ${({ theme: { colors } }) => colors['blue-300']};
  }

  :focus {
    outline: 0;
  }
`;
