import { useMemo, useState } from 'react';

import { joiResolver } from '@hookform/resolvers/joi';
import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import { DropdownOption, TemplateMapping } from 'common/types';
import JoiImport from 'joi';
import { BACK_OFFICE_PAGE } from 'paths';
import { Controller, useForm } from 'react-hook-form';
import countries from 'utils/countries';
import { useCompaniesQuery, useTemplateMappingsQuery } from 'utils/queries';

import {
  Button,
  Checkbox,
  Dropdown,
  Inline,
  Input,
  notifyError,
  notifySuccess,
  Split,
  Stack,
} from '@omnipresentgroup/design-system';
import DateExtension from '@omnipresentgroup/joi-date';

import { useCreateTemplateMappingMutation } from '../../queries';
import {
  CreateTemplateMappingAPIResponse,
  CreateTemplateMappingPayload,
} from '../../types';
import { ExistingTemplates } from '../ExistingTemplates/ExistingTemplates';

const Joi = JoiImport.extend(DateExtension) as JoiImport.Root;

export type CountryOption = DropdownOption & { icon: string };
export type FormValues = CreateTemplateMappingPayload;
export const CreateTemplateMapping = () => {
  const { data: response } = useTemplateMappingsQuery({});
  const { templateMappings = [] as TemplateMapping[] } = response || {};
  const { data: companies, isLoading } = useCompaniesQuery();
  const { mutate: createTemplateMapping, isLoading: isCreatingTemplate } =
    useCreateTemplateMappingMutation();
  const countryOptions = useMemo(
    () =>
      countries.map(({ label, value, emoji }) => ({
        label,
        value,
        icon: emoji,
      })),
    [],
  );
  const [isClientSpecific, setIsClientSpecific] = useState(false);

  const schema = Joi.object().keys({
    countryCode: Joi.string().label('Country').required(),
    companyId: isClientSpecific
      ? Joi.string().label('Company').required()
      : Joi.string().optional(),
    avvokaOriginalTemplateId: Joi.string()
      .label('Avvoka Template Id')
      .required(),
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    shouldUnregister: false,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
  });

  const FORM_ID = 'create-template';
  const formValues = watch();
  const { countryCode } = formValues;

  const countryTemplates = templateMappings.filter(
    (template) =>
      template?.countryCode === formValues?.countryCode?.toLowerCase(),
  );

  const handleMapTemplate = (formValues: FormValues) => {
    createTemplateMapping(formValues, {
      onSuccess: (response: CreateTemplateMappingAPIResponse) => {
        const { data } = response;
        const { companyId, avvokaOriginalTemplateId } = data;

        const country = countryOptions.find(
          (countryOption) => countryOption.value === countryCode,
        );

        const company = companiesOptions?.find(
          (companyOption) => companyOption.value === companyId,
        );

        notifySuccess({
          title: 'Success',
          description: `Template ${avvokaOriginalTemplateId} was mapped successfully to country ${country?.label} ${
            !!companyId ? `for ${company?.label}` : ''
          }`,
        });
        appHistory.push(`${BACK_OFFICE_PAGE}/template-mappings`);
      },
      onError: (error) => {
        notifyError({
          title: 'Error',
          description: `Something went wrong during the mapping creation: ${error.response?.data.message}`,
        });
      },
    });
  };

  const companiesOptions = companies?.map((company) => ({
    label: company.name,
    value: company.id,
  }));

  const findOption = (
    options: (DropdownOption | CountryOption)[],
    selectedOption: DropdownOption,
  ) => options?.find(({ value }) => value === selectedOption?.value);

  const country = countryOptions.find(
    (countryOption) => countryOption.value === countryCode,
  );

  return (
    <PageContent key="create-template-mapping-page" className="stack">
      <PageHeader title="Create template mapping" />
      <Split fraction="1/2" stackAt={1200}>
        <Stack p="32" bg="primary" gap="16">
          <form id={FORM_ID} onSubmit={handleSubmit(handleMapTemplate)}>
            <Stack bg="primary" gap="16">
              <Controller
                name="countryCode"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    id="countryCode"
                    label="Country"
                    placeholder="Select the country for the template"
                    required
                    isSearchable
                    errorMessage={errors['countryCode']?.message}
                    onChange={(option) => {
                      field.onChange((option as DropdownOption).value);
                    }}
                    value={findOption(
                      countryOptions,
                      field.value as DropdownOption,
                    )}
                    options={countryOptions}
                  />
                )}
              />
              <Checkbox
                checked={isClientSpecific}
                disabled={!countryCode}
                id="clientSpecific"
                label="Is this template client specific?"
                onCheckedChange={() => setIsClientSpecific((prev) => !prev)}
                style={{ appearance: 'checkbox', WebkitAppearance: 'checkbox' }}
              />
              {isClientSpecific && (
                <Controller
                  name="companyId"
                  control={control}
                  shouldUnregister
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      id="companyId"
                      label="Company"
                      placeholder="Select a company..."
                      isSearchable
                      required
                      isLoading={isLoading}
                      isDisabled={!countryCode}
                      options={companiesOptions}
                      errorMessage={errors['companyId']?.message}
                      onChange={(option) => {
                        field.onChange((option as DropdownOption).value);
                      }}
                      value={findOption(
                        companiesOptions as DropdownOption[],
                        field.value as DropdownOption,
                      )}
                    />
                  )}
                />
              )}
              <Controller
                name="avvokaOriginalTemplateId"
                control={control}
                render={({ field }) => (
                  <Input
                    id="avvokaOriginalTemplateId"
                    type="text"
                    label="Avvoka Template Id"
                    placeholder="Enter the Avvoka Template id"
                    maxLength={5}
                    errorMessage={errors['avvokaOriginalTemplateId']?.message}
                    disabled={!countryCode}
                    required
                    {...field}
                  />
                )}
              />
            </Stack>
          </form>

          <Inline justify="space-between">
            <Button
              size="medium"
              variant="secondary"
              type="button"
              onClick={() =>
                appHistory.push(`${BACK_OFFICE_PAGE}/template-mappings`)
              }
            >
              Cancel
            </Button>
            <Button
              disabled={Object.keys(errors).length > 0 || !countryCode}
              size="medium"
              type="submit"
              form={FORM_ID}
              loading={isCreatingTemplate}
            >
              Create template mapping
            </Button>
          </Inline>
        </Stack>
        {country && countryTemplates.length > 0 && (
          <ExistingTemplates
            countryTemplates={countryTemplates}
            country={country}
          />
        )}
      </Split>
    </PageContent>
  );
};
