import { ALLOWED_FILE_TYPES, AllowedFileType } from 'appConstants';
import { uploadDocumentToOmniplatformRequest } from 'common/api/documentStorage.api';
import { MAXIMUM_UPLOAD_SIZE_BYTES } from 'common/components/molecules/UploadDocument/constants';
import { useMutation } from 'react-query';
import * as fileUtils from 'utils/files.js';

export const useUploadMutation = (
  allowedFileTypes?: AllowedFileType[],
  directory = 'document',
  progressEventHandler?: (progress: number) => void,
) => {
  const { mutate: saveDocument, ...results } = useMutation((file: File) =>
    uploadDocumentToOmniplatformRequest(file, directory, updateUploadProgress),
  );
  const updateUploadProgress = (progressEvent: {
    loaded: number;
    total: number;
  }) => {
    progressEventHandler &&
      progressEventHandler(
        Math.round((progressEvent.loaded * 100) / progressEvent.total),
      );
  };

  const handleFileUpload = ({ files }: HTMLInputElement) => {
    const file = files && files[0];
    if (!file) {
      return;
    }
    const isValidFile = fileUtils.isValidFileType(
      file,
      allowedFileTypes || ALLOWED_FILE_TYPES,
    );
    const isValidFileSize = fileUtils.isValidFileSize(
      file,
      MAXIMUM_UPLOAD_SIZE_BYTES,
    );
    if (!isValidFile || !isValidFileSize) {
      return;
    }
    return saveDocument(file);
  };

  return {
    handleFileUpload,
    ...results,
  };
};
