import { useEffect, useMemo, useState } from 'react';

import { PaginationState, Updater } from '@tanstack/react-table';
import { Loading } from 'common/components';
import { EmployeeReportData } from 'common/types/payroll';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { flatten } from 'lodash';
import { PayReportViewFilter } from 'omniplatform/admin/pages/PayReportPreviewPage/PayReportViewFilter';
import { usePaginatedEmployeesInfiniteQuery } from 'utils/queries';

import {
  Button,
  Modal,
  Tabs,
  TextArea,
  Typography,
} from '@omnipresentgroup/design-system';

import { PayReportBreakdownTable } from './PayReportBreakdownTable';
import { usePayrollEmployeeJustifications } from './usePayrollEmployeeJustifications';

export type PayReportBreakdownModalProps = {
  title: string;
  cancelHandler: () => void;
  employeeReportData: EmployeeReportData;
  employeeTotalCount: number;
  selectedRow: number | null;
  pageSize: number;
  pageIndex: number;
  setPagination: (updaterOrValue: Updater<PaginationState>) => void;
  setSelectedRow: (selectedRow: number | null) => void;
  isLoading?: boolean;
  payReportViewFilter: PayReportViewFilter;
  grossToNetId: string;
  approved: boolean;
};

enum TabsNames {
  PAY_REPORT_TAB = 'pay-report',
  JUSTIFICATION_TAB = 'justification',
}

const DefaultTab = TabsNames.PAY_REPORT_TAB;

export const PayReportBreakdownModal = ({
  cancelHandler,
  employeeReportData,
  employeeTotalCount,
  selectedRow,
  pageSize,
  pageIndex,
  setPagination,
  setSelectedRow,
  isLoading,
  payReportViewFilter,
  grossToNetId,
  approved,
}: PayReportBreakdownModalProps) => {
  const { enablePayrollJustifications } = useFlags();

  const [currentTab, setCurrentTab] = useState<TabsNames>(
    TabsNames.PAY_REPORT_TAB,
  );

  const currentlyViewing =
    selectedRow !== null ? selectedRow + 1 + pageIndex * 10 : 0;

  const employeeDisplayName = `${employeeReportData.firstName} ${employeeReportData.lastName}`;

  const { data: employeesData = { pages: [] } } =
    usePaginatedEmployeesInfiniteQuery({
      query: { employeeId: employeeReportData.employeeId },
    });

  const fullEmployee = flatten(employeesData.pages).find(
    (employee) =>
      employee.name ===
      `${employeeReportData.firstName} ${employeeReportData.lastName}`,
  );

  const { note, handleUpdateNote, handleChangeNote, isLoadingJustifications } =
    usePayrollEmployeeJustifications({
      grossToNetId,
      employeeId: fullEmployee?.id,
      readOnly: approved,
    });

  const nextButtonHandler = () => {
    if (selectedRow !== null && selectedRow === pageSize - 1) {
      setPagination({ pageIndex: pageIndex + 1, pageSize });
      setSelectedRow(0);
      return;
    }
    setSelectedRow(selectedRow !== null ? selectedRow + 1 : null);
  };

  const prevButtonHandler = () => {
    if (selectedRow !== null && selectedRow === 0) {
      setPagination({ pageIndex: pageIndex - 1, pageSize });
      setSelectedRow(pageSize - 1);
      return;
    }
    setSelectedRow(selectedRow !== null ? selectedRow - 1 : null);
  };

  const nextButtonDisabled =
    isLoading || currentlyViewing === employeeTotalCount;
  const prevButtonDisabled =
    isLoading || (pageIndex === 0 && selectedRow === 0);

  const displayTitle = isLoading ? 'Employee Loading' : employeeDisplayName;
  const footerDisplayCount = `Employee ${
    isLoading ? 'Loading' : currentlyViewing
  }/${employeeTotalCount}`;

  useEffect(() => {
    if (selectedRow !== null) {
      setCurrentTab(DefaultTab);
    }
  }, [selectedRow]);

  const employeeVarianceDataToDisplay = useMemo(
    () =>
      employeeReportData?.concepts
        .filter((concept) => concept.variances)
        .map((concept) => ({
          ...concept,
          value: concept.variances!.previous,
        })),
    [employeeReportData?.concepts],
  );

  return (
    <Modal open span={payReportViewFilter.showVariance ? 6 : 4}>
      <Modal.Header
        onClose={cancelHandler}
        title={displayTitle}
        description={fullEmployee && fullEmployee.company.name}
      />

      {enablePayrollJustifications && (
        <Tabs
          controlledTabId={currentTab}
          // To make the Tabs component rerender on every `currentTab` external change
          // (e.g. `currentTab` is set into default when moving on to the next employee)
          // This is due to the controlledTabId property behaviour which value is controlled on the first render only
          key={`${selectedRow}-${currentTab}`}
          onTabClicked={(tab) => {
            setCurrentTab(tab.id as TabsNames);
          }}
          tabsToDisplay={[
            {
              disabled: false,
              id: TabsNames.PAY_REPORT_TAB,
              title: 'Pay Report',
            },
            {
              disabled: false,
              id: TabsNames.JUSTIFICATION_TAB,
              title: 'Justifications',
            },
          ]}
        />
      )}

      <Modal.Body>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {currentTab === TabsNames.PAY_REPORT_TAB && (
              <PayReportBreakdownTable
                data={employeeReportData?.concepts}
                variance={employeeVarianceDataToDisplay}
                payReportViewFilter={payReportViewFilter}
              />
            )}
            {currentTab === TabsNames.JUSTIFICATION_TAB &&
              (isLoadingJustifications ? (
                <Loading />
              ) : (
                <TextArea
                  id="payrollJustificationText"
                  readOnly={approved || isLoadingJustifications}
                  minRows={10}
                  value={note}
                  onChange={handleChangeNote}
                  onBlur={handleUpdateNote}
                />
              ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={prevButtonHandler} disabled={prevButtonDisabled}>
          Prev
        </Button>
        <Typography as="span" hideParagraphSpacing size="14" weight="regular">
          {footerDisplayCount}
        </Typography>
        <Button onClick={nextButtonHandler} disabled={nextButtonDisabled}>
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
