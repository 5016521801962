import { useEffect, useState } from 'react';

import { clearErrorAction } from 'app/store/app.actions';
import { getErrorMessage, getErrorSelector } from 'app/store/app.selectors';
import { useDispatch, useSelector } from 'react-redux';

export const useErrorBanner = () => {
  const dispatch = useDispatch();
  const error = useSelector(getErrorSelector);
  const errorMessage = useSelector(getErrorMessage);

  const [showErrorBanner, setShowErrorBanner] = useState(false);

  useEffect(() => {
    if (errorMessage || (error && error.response?.status !== 403)) {
      setShowErrorBanner(true);
    } else {
      setShowErrorBanner(false);
    }
  }, [errorMessage, error]);

  const clearError = () => {
    dispatch(clearErrorAction());
    setShowErrorBanner(false);
  };

  return { showErrorBanner, errorMessage, clearError };
};
