import actionKeys from '../actions/reduxConstants';

const initialState = {
  employeesExpenses: {},
  selectedExpense: {},
  expenseCreated: false,
};

export const expensesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionKeys.GET_EXPENSES_END:
      return {
        ...state,
        employeesExpenses: action.payload,
      };
    case actionKeys.GET_EXPENSE_END:
      return {
        ...state,
        selectedExpense: action.payload,
      };
    case actionKeys.CLEAR_SELECTED_EXPENSE:
      return {
        ...state,
        selectedExpense: {},
      };
    case actionKeys.DELETE_EXPENSE_END:
      return {
        ...state,
        employeesExpenses: {
          outstanding: state.employeesExpenses.outstanding?.filter(
            (expense) => expense.id !== action.payload,
          ),
          paid: [...state.employeesExpenses.paid],
        },
      };
    default:
      return state;
  }
};
