import { countries } from 'countries-list';

export const mapCountryNameToCountryCode = (
  countryName: string | undefined,
) => {
  if (!countryName) {
    return undefined;
  }
  const matchingCountryCodeMapping = Object.entries(countries).find(
    ([_, country]) => country.name.toLowerCase() === countryName.toLowerCase(),
  );
  return (
    matchingCountryCodeMapping && matchingCountryCodeMapping[0].toLowerCase()
  );
};
