import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageContent } from 'app/App.styles';
import { queryClient } from 'app/queryClient';
import { AxiosError, AxiosResponse } from 'axios';
import { updateEmployment } from 'common/api/employments.api';
import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import { FormData } from 'common/types';
import {
  EMPLOYMENT_STATUS_MAP,
  EmploymentModel,
  employmentType,
  PatchEmploymentModel,
} from 'common/types/employments';
import { EMPLOYEES_PATH } from 'paths';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLspsQuery } from 'utils/queries';
import { useEmployeeQuery } from 'utils/queries/employees/useEmployeeQuery';
import { employmentKeys } from 'utils/queries/employments/keys';
import { useEmploymentQuery } from 'utils/queries/employments/useEmploymentQuery/useEmploymentQuery';

import {
  Box,
  Button,
  Card,
  DateFormats,
  DateInput,
  Dropdown,
  Inline,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

import { getDateForPayload } from './common/employmentHistory.helper';
import { addEditEmploymentResolver } from './common/EmploymentHistoryFormResolver';
import { getEmploymentStatusOptions } from './common/GetEmploymentStatusOptions';

export const EditEmploymentPage = () => {
  const history = useHistory();

  const { employmentId, employeeId } = useParams<{
    employmentId: string;
    employeeId: string;
  }>();

  const employeeEmploymentHistory = `${EMPLOYEES_PATH}/${employeeId}#employmentHistory`;

  const {
    isLoading: isEmploymentQueryLoading,
    error: employmentQueryError,
    data: employment,
  } = useEmploymentQuery(employmentId);

  const {
    isLoading: isEmployeeQuerLoading,
    error: employeeQueryError,
    data: employee,
  } = useEmployeeQuery(employeeId, undefined, true);

  const {
    isLoading: isLspsQueryLoading,
    error: lspsQueryError,
    data: lsps = [],
  } = useLspsQuery();

  const {
    isLoading,
    isError: isUpdate,
    isSuccess,
    error: updateError,
    reset: onErrorBannerReset,
    mutate: onSubmit,
  } = useMutation<
    AxiosResponse<EmploymentModel>,
    AxiosError,
    PatchEmploymentModel
  >(
    (employment: PatchEmploymentModel) =>
      updateEmployment(employmentId, employment),
    {
      onSuccess: async () =>
        await Promise.allSettled([
          queryClient.invalidateQueries(
            employmentKeys.singleUser(employee?.userId),
          ),
          queryClient.invalidateQueries(employmentKeys.single(employment?.id)),
        ]),
    },
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    resolver: addEditEmploymentResolver(),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const onSubmitClick = handleSubmit((data: Record<string, any>) => {
    const patchEmployment: PatchEmploymentModel = {
      startDate: getDateForPayload(data?.startDate),
      terminationDate: getDateForPayload(data?.terminationDate),
      firstPayrollDate: getDateForPayload(data?.firstPayrollDate),
      lastPayrollDate: getDateForPayload(data?.lastPayrollDate),
      lspId: data?.lspId?.value,
      status: data?.status?.value,
      type: data?.type?.value,
    };
    onSubmit(patchEmployment);
  });

  useEffect(() => {
    if (isSuccess) {
      history.push(employeeEmploymentHistory);
    }
  }, [history, isSuccess, employeeEmploymentHistory]);

  if (isEmploymentQueryLoading || isEmployeeQuerLoading || isLspsQueryLoading) {
    return <Loading />;
  }

  if (employmentQueryError instanceof Error) {
    return <ErrorBanner errorMessage={employmentQueryError.message} />;
  }

  if (!employment) {
    return <ErrorBanner errorMessage="No employment data" />;
  }

  if (employeeQueryError instanceof Error) {
    return <ErrorBanner errorMessage={employeeQueryError.message} />;
  }

  if (lspsQueryError instanceof Error) {
    return <ErrorBanner errorMessage={lspsQueryError.message} />;
  }

  const isEditingCurrentEmployment =
    employmentId === employee?.currentEmploymentId;

  const lspOptions = lsps.map((lsp) => ({
    label: lsp.name,
    value: lsp.id,
  }));

  return (
    <PageContent>
      {isLoading && <Loading />}
      {(lspsQueryError || isUpdate) && (
        <ErrorBanner
          onErrorClear={() => onErrorBannerReset && onErrorBannerReset()}
          errorMessage={updateError?.message}
        />
      )}
      <Stack gap="32" mb="32">
        <Typography as="h3" hideParagraphSpacing size="24" weight="medium">
          Edit employment
        </Typography>
        <Box>
          <Button
            variant="secondary"
            icon="ChevronLeft"
            onClick={() =>
              history.push(`${EMPLOYEES_PATH}/${employeeId}#employmentHistory`)
            }
          >
            Back to employment history
          </Button>
        </Box>
        <Card p="32" maxW={600}>
          <Stack gap="16">
            <Typography as="span" size="16">
              Please fill out the form below to update employment
            </Typography>
            <Controller
              key="lspId"
              name="lspId"
              control={control}
              defaultValue={lspOptions.find(
                (lspOption) => lspOption.value === employment?.lspId,
              )}
              render={({ field }) => (
                <Dropdown
                  id="lspId"
                  label="Select a local service provider"
                  placeholder="Select local service provider..."
                  required
                  isReadOnly={isEditingCurrentEmployment}
                  errorMessage={errors['lspId']?.message}
                  options={lspOptions}
                  {...field}
                />
              )}
            />
            <Controller
              key="type"
              name="type"
              control={control}
              defaultValue={{
                label: employment?.type,
                value: employment?.type,
              }}
              render={({ field }) => (
                <Dropdown
                  id="type"
                  label="Select an employment type"
                  placeholder="Select employment type..."
                  isSearchable={false}
                  required
                  isReadOnly={isEditingCurrentEmployment}
                  isClearable={false}
                  errorMessage={errors['type']?.message}
                  options={employmentType.map((e) => ({
                    label: e,
                    value: e,
                  }))}
                  {...field}
                />
              )}
            />
            <Controller
              name="startDate"
              control={control}
              defaultValue={employment?.startDate}
              render={({ field }) => (
                <DateInput
                  id="startDate"
                  dateFormat={DateFormats.FixedDate}
                  placeholderText={DateFormats.FixedDate.toLocaleLowerCase()}
                  errorMessage={errors.startDate?.message}
                  label="Start date"
                  required
                  readOnly={isEditingCurrentEmployment}
                  {...field}
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                />
              )}
            />
            <Controller
              name="terminationDate"
              control={control}
              defaultValue={employment?.terminationDate}
              render={({ field }) => (
                <DateInput
                  id="terminationDate"
                  dateFormat={DateFormats.FixedDate}
                  placeholderText={DateFormats.FixedDate.toLocaleLowerCase()}
                  errorMessage={errors.terminationDate?.message}
                  label="Termination date"
                  {...field}
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                />
              )}
            />
            <Controller
              name="firstPayrollDate"
              control={control}
              defaultValue={employment?.firstPayrollDate}
              render={({ field }) => (
                <DateInput
                  id="firstPayrollDate"
                  dateFormat={DateFormats.FixedDate}
                  placeholderText={DateFormats.FixedDate.toLocaleLowerCase()}
                  errorMessage={errors.firstPayrollDate?.message}
                  label="First payroll date"
                  readOnly={isEditingCurrentEmployment}
                  {...field}
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                />
              )}
            />
            <Controller
              name="lastPayrollDate"
              control={control}
              defaultValue={employment?.lastPayrollDate}
              render={({ field }) => (
                <DateInput
                  id="lastPayrollDate"
                  dateFormat={DateFormats.FixedDate}
                  placeholderText={DateFormats.FixedDate.toLocaleLowerCase()}
                  errorMessage={errors.lastPayrollDate?.message}
                  label="Last payroll date"
                  {...field}
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                />
              )}
            />
            <Controller
              name="status"
              control={control}
              defaultValue={{
                label: EMPLOYMENT_STATUS_MAP[employment?.status],
                value: employment?.status,
              }}
              render={({ field }) => (
                <Dropdown
                  id="status"
                  label="Select status"
                  placeholder="Select status..."
                  isSearchable={false}
                  required
                  isReadOnly={isEditingCurrentEmployment}
                  isClearable={false}
                  errorMessage={errors['type']?.message}
                  options={getEmploymentStatusOptions(employment?.status || '')}
                  {...field}
                />
              )}
            />
            <Inline justify="space-between" mt="32">
              <Button
                variant="secondary"
                onClick={() =>
                  history.push(
                    `${EMPLOYEES_PATH}/${employeeId}#employmentHistory`,
                  )
                }
              >
                Cancel
              </Button>
              <Button
                onClick={() => onSubmitClick()}
                disabled={isLoading || !isDirty}
              >
                Save
              </Button>
            </Inline>
          </Stack>
        </Card>
      </Stack>
    </PageContent>
  );
};
