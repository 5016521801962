import { FormStage } from 'common/types';

import { BenefitsSelectionButtons } from '../Benefits/BenefitsSelectionButtons';
import { BenefitsSelectionStep } from '../Benefits/BenefitsSelectionStep';
import { BenefitsSelectionStepDeprecated } from '../Benefits/BenefitsSelectionStepDeprecated';
import { TypeFormPopupButtonDeprecated } from '../Benefits/TypeFormPopupButtonDeprecated';

export const getBenefitsSelectionStageDeprecated = (
  seatId: string,
): FormStage => {
  return {
    name: 'Benefits Selection',
    stage: 3,
    pageComponent: BenefitsSelectionStepDeprecated,
    ctaText: 'Setup Employee Benefits',
    ctaComponent: TypeFormPopupButtonDeprecated,
    fields: [],
    benefitSelectionFormId: `benefitsFormId-${seatId}`,
  };
};

export const getBenefitsSelectionStage = (): FormStage => {
  return {
    name: 'Benefits Selection',
    stage: 3,
    pageComponent: BenefitsSelectionStep,
    ctaText: 'Setup Employee Benefits',
    ctaComponent: BenefitsSelectionButtons,
    fields: [],
  };
};
