import { useEffect, useState } from 'react';

import { LSPApiResponse, LSPTypesEnum } from '../../../../common/types';
import { useLspsQuery } from '../../lsps';
import { useEmployersQuery } from '../useEmployerQuery';

export type EmployerLSPSelectionQueryParams = {
  employerCountryCode: string;
  employerLspId?: string;
};

export const useEmployerLSPSelectionQuery = ({
  employerCountryCode,
  employerLspId,
}: EmployerLSPSelectionQueryParams) => {
  const [lspSelection, setLspSelection] = useState<LSPApiResponse[]>([]);

  const employersQuery = useEmployersQuery({
    countryCode: employerCountryCode,
  });

  const lspQuery = useLspsQuery({ type: LSPTypesEnum.EMPLOYER_OF_RECORD });

  useEffect(() => {
    if (!lspQuery.isSuccess || !employersQuery.isSuccess) {
      return;
    }

    const lspIdsInTheSameCountry = employersQuery.data.map(
      (employer) => employer.lspId,
    );

    const availableLspsForEmployee = lspQuery.data.filter(
      (lsp) =>
        lsp.id === employerLspId || !lspIdsInTheSameCountry.includes(lsp.id),
    );

    setLspSelection(availableLspsForEmployee);
  }, [
    employerLspId,
    employersQuery.data,
    employersQuery.isSuccess,
    lspQuery.data,
    lspQuery.isSuccess,
  ]);

  return {
    isLoading: lspQuery.isLoading || employersQuery.isLoading,
    isError: lspQuery.isError || employersQuery.isError,
    isSuccess: lspQuery.isSuccess || employersQuery.isSuccess,
    isFetching: lspQuery.isFetching || employersQuery.isFetching,
    data: lspSelection,
  };
};
