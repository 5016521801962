import { HTTPStatusCodesEnum } from 'common/constants';
import {
  CountryTimesheetFormat,
  EmployeeRegularWorkingHoursResponse,
  EmployeeTimesheetResponse,
  PaginatedCompanyTimesheetsApiResponse,
} from 'common/types';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';
import {
  getMockCountryTimesheetFormat,
  mockEmployeeSpecificTimesheet,
  mockEmployeeTimesheets,
  mockRegularWorkingHours,
} from 'omniplatform/employee/__mocks__/employeeTimesheets.mock';

import { mockPaginatedCompanyTimesheetsApiResponse } from '../../__mocks__/companyTimesheets.mock';

export const timesheetsHandlers = [
  rest.get<CountryTimesheetFormat, { countryName: string }>(
    composeEndpoint('/timesheets/countries-format/:countryName'),
    (req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(getMockCountryTimesheetFormat(req.params.countryName)),
      ),
  ),
  rest.get(
    composeEndpoint('/timesheets/:employeeId/is-allowed'),
    (_req, res, ctx) => res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(true)),
  ),
  rest.get<EmployeeTimesheetResponse>(
    composeEndpoint('/timesheets/:employeeId/:year/:month'),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockEmployeeSpecificTimesheet),
      ),
  ),
  rest.get<EmployeeRegularWorkingHoursResponse>(
    composeEndpoint('/timesheets/regular-working-hours/:employeeId'),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockRegularWorkingHours),
      ),
  ),
  rest.post<EmployeeRegularWorkingHoursResponse>(
    composeEndpoint('/timesheets/regular-working-hours/:employeeId'),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockRegularWorkingHours),
      ),
  ),
  rest.patch<EmployeeRegularWorkingHoursResponse>(
    composeEndpoint('/timesheets/regular-working-hours/:employeeId'),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockRegularWorkingHours),
      ),
  ),
  rest.get<EmployeeTimesheetResponse[]>(
    composeEndpoint('/timesheets/:employeeId'),
    (_req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockEmployeeTimesheets)),
  ),
  rest.patch<EmployeeTimesheetResponse>(
    composeEndpoint('/timesheets/:employeeId/:year/:month'),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockEmployeeTimesheets[0]),
      ),
  ),
  rest.patch<undefined>(
    composeEndpoint('/timesheets/:employeeId/:year/:month/submit'),
    (_req, res, ctx) => res(ctx.status(HTTPStatusCodesEnum.OK)),
  ),
  rest.post<string>(
    composeEndpoint('/timesheets/download/:employeeId/:year/:month'),
    (req, res, ctx) => {
      const { employeeId, month, year } = req.params;
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(
          `some,csv,data,employee,${employeeId},year,${year},month,${month}`,
        ),
      );
    },
  ),
  rest.get<PaginatedCompanyTimesheetsApiResponse>(
    composeEndpoint('/timesheets/company/:companyId'),
    (_req, res, ctx) =>
      res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json(mockPaginatedCompanyTimesheetsApiResponse),
      ),
  ),
];
