import Big from 'big.js';
import {
  DepositFeeWithType,
  FilteredCompanyUpsellFees,
  Salary,
} from 'common/types';
import { EmployerCostFee } from 'common/types/employerCost';

export type Fee = {
  amount: number;
  currency: string;
};

export const isZeroDepositInsurance = (depositType?: string) =>
  depositType === 'Zero Deposit Insurance';

export const isAdvancedPaymentDeposit = (depositType?: string) =>
  depositType === 'Advanced Payment Deposit (APD)';

export const getDepositFee = (
  depositFee?: DepositFeeWithType,
  upsellFee?: FilteredCompanyUpsellFees,
  billingCurrency?: string,
  currencyExchangeRate: Big | number = 1,
): Fee => {
  if (depositFee?.convertedAmount) {
    return depositFee?.convertedAmount as Fee;
  }
  const amount =
    (depositFee?.calculatedAmount?.amount || 0) *
    Big(currencyExchangeRate || 1).toNumber();
  const currency = billingCurrency || upsellFee?.managementFee?.currency || '';
  return { amount, currency };
};

export const getMonthlyTotalAmount = (
  depositFee?: DepositFeeWithType,
  upsellFee?: FilteredCompanyUpsellFees,
  billingCurrency?: string,
  monthlySalaryFeeInBillingCurrency?: Salary,
  employerCostsFees?: EmployerCostFee[],
): number => {
  const depositAmount = isZeroDepositInsurance(depositFee?.depositType)
    ? getDepositFee(depositFee, upsellFee, billingCurrency)?.amount || 0
    : 0;
  const employerCostsTotal = getEmployerCostFeeAmountByDescription(
    'Employer cost estimate',
    employerCostsFees,
  );
  const fxFeeAmount = getEmployerCostFeeAmountByDescription(
    'Foreign exchange fee',
    employerCostsFees,
  );

  return (
    (upsellFee?.managementFee?.amount || 0) +
    depositAmount +
    (monthlySalaryFeeInBillingCurrency?.amount || 0) +
    fxFeeAmount +
    employerCostsTotal
  );
};

export const getOneTimeTotalAmount = (
  depositFee?: DepositFeeWithType,
  upsellFee?: FilteredCompanyUpsellFees,
  currencyExchangeRate?: Big | number,
  billingCurrency?: string,
): number => {
  const advancedPaymentDepositFee = getDepositFee(
    depositFee,
    upsellFee,
    billingCurrency,
    currencyExchangeRate,
  );
  const depositValue = isAdvancedPaymentDeposit(depositFee?.depositType)
    ? advancedPaymentDepositFee?.amount || 0
    : 0;
  return (upsellFee?.setupFee?.amount || 0) + depositValue;
};

const getEmployerCostFeeAmountByDescription = (
  description: string,
  employerCostsFees?: EmployerCostFee[],
) => {
  return (
    employerCostsFees?.find((fee) => fee.description === description)?.fee
      ?.amount || 0
  );
};
