import appHistory from 'app/appHistory';
import { Loading } from 'common/components';
import PropTypes from 'prop-types';

import { Viewport } from '@omnipresentgroup/design-system';

import Button from '../../atoms/Button/Button';
import { StyledButtonContainer } from './FormSubmitButton.styles';

const FormSubmitButton = ({
  ctaText,
  onCTAClick,
  showCancel,
  onCancelClick,
  disabled,
  isLoading,
  formId,
  reverseOrder,
}) => {
  return (
    <StyledButtonContainer reverseOrder={reverseOrder}>
      <Viewport devices={['laptop', 'desktop', 'highRes']}>
        {showCancel && (
          <Button
            onClick={onCancelClick || appHistory.goBack}
            palette="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
        )}
        {ctaText && (
          <Button
            onClick={onCTAClick}
            testId="form-button-submit"
            palette="primary"
            type="submit"
            disabled={disabled}
            form={formId}
          >
            {isLoading ? <Loading /> : ctaText}
          </Button>
        )}
      </Viewport>
    </StyledButtonContainer>
  );
};

FormSubmitButton.propTypes = {
  ctaText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onCTAClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onCancelClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  showCancel: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  formId: PropTypes.string,
  reverseOrder: PropTypes.bool,
};

FormSubmitButton.defaultProps = {
  ctaText: null,
  onCTAClick: null,
  showCancel: null,
  onCancelClick: null,
  disabled: false,
  isLoading: false,
  reverseOrder: false,
};

export default FormSubmitButton;
