import { getTransformedUser } from 'app/store/app.selectors';
import {
  CONTRACT_AMENDMENT_REVIEW_CLAUSE_LABELS,
  CONTRACT_AMENDMENT_REVIEW_TYPE_LABELS,
  ContractAmendment,
} from 'common/types/contract';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { isCSAdmin } from 'utils/user';

import {
  Box,
  Button,
  Inline,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

type AmendmentCardProps = {
  setAmendmentToDispatch: (_: string) => void;
  contractAmendment: ContractAmendment;
};

export const AmendmentCard = ({
  setAmendmentToDispatch,
  contractAmendment,
}: AmendmentCardProps) => {
  const { enableDispatchAmendmentToLegal: isEnabledDispatchAmendmentToLegal } =
    useFlags();

  const user = useSelector(getTransformedUser);
  const reviewType =
    CONTRACT_AMENDMENT_REVIEW_TYPE_LABELS[contractAmendment.reviewType];
  const reviewClause =
    CONTRACT_AMENDMENT_REVIEW_CLAUSE_LABELS[contractAmendment.reviewClause];

  return (
    <Box bg="primary" depth="s" p="16" radius="s" key={contractAmendment.id}>
      <Stack gap="8">
        <Typography as="label" size="16" weight="bold">
          {reviewType}
        </Typography>
        <Typography as="label" size="16" weight="medium">
          {reviewClause}
        </Typography>
        <Typography
          as="label"
          size="16"
          weight="medium"
        >{`Clause ${contractAmendment.reviewClauseNumber}`}</Typography>
        <Box mb="16" pb="32" borderBottom border="subtle">
          <Typography as="span" size="16">
            {contractAmendment.details}
          </Typography>
        </Box>
      </Stack>
      {isCSAdmin(user) && isEnabledDispatchAmendmentToLegal && (
        <Inline reverse={true}>
          {contractAmendment.reviewStatus === 'dispatched' ? (
            <Button disabled variant="secondary">
              Sent
            </Button>
          ) : (
            <Button
              variant="secondary"
              onClick={() => {
                setAmendmentToDispatch(contractAmendment.id);
              }}
            >
              Send to Legal
            </Button>
          )}
        </Inline>
      )}
    </Box>
  );
};
