import { useParams } from 'react-router-dom';

import { AxiosError } from 'axios';
import { updatePayrollProvider } from 'common/api/payrollProviders.api';
import { PayrollProvider, PayrollProviderCreateApiRequest } from 'common/types';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useUpdatePayrollProviderMutation = () => {
  const { id } = useParams<{ id: string }>();
  return useMutation<
    PayrollProvider,
    AxiosError,
    PayrollProviderCreateApiRequest
  >((payrollProviderUpdate) => {
    return updatePayrollProvider(id, payrollProviderUpdate).then(
      getDataFromResponse,
    );
  });
};
