import { useEffect, useMemo, useState } from 'react';

import { sendPlatformInvitationRequest } from 'app/api/user.api';
import { PageContent } from 'app/App.styles';
import appHistory from 'app/appHistory';
import { getUserSelector } from 'app/store/app.selectors';
import { Department } from 'appConstants';
import FloatingActionButton from 'common/components/atoms/FloatingActionButton/FloatingActionButton';
import Loading from 'common/components/atoms/Loading/Loading';
import ErrorBanner from 'common/components/molecules/ErrorBanner/ErrorBanner';
import PageHeader from 'common/components/molecules/PageHeader/PageHeader';
import DetailsCard from 'common/components/organisms/DetailsCard/DetailsCard';
import { StyledAccountDetailsPage } from 'common/pages/AccountDetailsPage/AccountDetailsPage.styles';
import { ManagerDetail, Managers } from 'common/types/manager';
import { COMPANIES_LIST_PAGE } from 'paths';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { isCSAdmin } from 'utils/user';

import { Button, Viewport } from '@omnipresentgroup/design-system';

import { DeleteManagerModal } from '../CompanyDetailPage/Tabs/DeleteManagerModal/DeleteManagerModal';

export type ManagerDetailButtonConfig = {
  isClient: boolean;
  buttonText: string;
  onClickHandler: (...args: any[]) => any | void;
  isDisabled: boolean;
};

export type CompanyManager = {
  id: string;
  name: string;
  scopes: string[];
  jobTitle?: string;
  department?: Department;
};

type ManagerDetailPageProps = {
  manager: Managers;
  loading: boolean;
  companyManagers?: CompanyManager[];
  managerId: string;
  canEdit: boolean;
  onEditURL: string;
  buttonConfig?: ManagerDetailButtonConfig;
  companyId: string;
  canDelete?: boolean;
};

const DeleteCTA = ({
  ctaHandler,
}: {
  ctaHandler: (event: React.MouseEvent) => void;
}) => (
  <Viewport devices={['laptop', 'desktop', 'highRes']}>
    <Button onClick={ctaHandler} variant="secondary">
      Delete Manager
    </Button>
  </Viewport>
);

const ManagerDetailPage = ({
  manager,
  companyManagers,
  loading,
  managerId,
  onEditURL,
  canEdit,
  companyId,
  canDelete = true,
}: ManagerDetailPageProps) => {
  const [platformInvitationProgress, setPlatformInvitationProgress] =
    useState('');

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const selectedManager: CompanyManager | null =
    companyManagers?.find((manager) => manager.id === managerId) || null;

  const user = useSelector(getUserSelector);

  const accountDetailsSections = useMemo(() => {
    const details =
      manager?.userDetails?.concat([
        {
          editable: true,
          required: true,
          label: 'Job Title',
          name: 'jobTitle',
          showFor: 'admin',
          type: 'string',
          value: selectedManager?.jobTitle || '-',
        },
        {
          editable: true,
          required: true,
          label: 'Department',
          name: 'department',
          showFor: 'admin',
          type: 'string',
          value: selectedManager?.department || '-',
        },
      ]) || [];

    details.forEach((detail: ManagerDetail) =>
      detail.name === 'email'
        ? {
            ...detail,
            label: 'Work email',
          }
        : detail,
    );

    return [
      {
        name: 'manager-details',
        title: 'Manager details',
        details: details,
      },
      {
        name: 'administrative-details',
        title: 'Administrative information',
        details: manager?.administrativeInformation,
      },
    ];
  }, [manager, selectedManager]);

  const {
    isLoading,
    isError: isPlatformInviteError,
    error: platformInviteError,
    mutate: sendPlatformInviteToManager,
    data,
  }: Record<string, any> = useMutation((managerId: string) =>
    sendPlatformInvitationRequest(managerId),
  );

  useEffect(() => {
    const platformInvitationProgressState = isLoading
      ? 'InProgress'
      : data?.data
        ? 'SENT'
        : '';
    setPlatformInvitationProgress(platformInvitationProgressState);
  }, [isLoading, data, setPlatformInvitationProgress]);

  const onEditClicked = () => {
    appHistory.push(onEditURL);
  };

  const capitalizeFirstLetter = (word: string) => {
    return word?.charAt(0).toUpperCase() + word?.slice(1);
  };

  const deleteHandler = () => {
    setIsDeleteModalOpen(true);
  };

  return (
    <PageContent>
      {isPlatformInviteError && (
        <ErrorBanner errorMessage={platformInviteError?.message} />
      )}
      <StyledAccountDetailsPage
        data-testid="manager-details-page"
        className="bigStack"
      >
        <DeleteManagerModal
          isOpen={isDeleteModalOpen}
          companyId={companyId}
          managerDetails={{
            id: managerId,
            name: manager?.name,
            role: capitalizeFirstLetter(manager?.role?.type),
          }}
          closeFn={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          successFn={() =>
            appHistory.push(`${COMPANIES_LIST_PAGE}/${companyId}#managers`)
          }
          hidden={false}
        />

        {loading || !manager ? (
          <Loading />
        ) : (
          <>
            <PageHeader
              title={manager.name}
              ctaText={canEdit && 'Edit details'}
              onCTAClick={onEditClicked}
            />
            {accountDetailsSections.map(
              (section) =>
                section && (
                  <DetailsCard
                    key={section.title}
                    fieldsToDisplay={section.details}
                    title={section.title}
                    testId={`${section.name}-card`}
                    userRole="admin"
                    platformInvitationProgress={platformInvitationProgress}
                    sendPlatformInvitation={() =>
                      sendPlatformInviteToManager(managerId)
                    }
                  />
                ),
            )}
          </>
        )}
        <Viewport devices={['phone', 'tablet']}>
          <FloatingActionButton type="edit" onClick={onEditClicked} />
        </Viewport>
        {!loading && canDelete && isCSAdmin(user) && (
          <DeleteCTA ctaHandler={deleteHandler} />
        )}
      </StyledAccountDetailsPage>
    </PageContent>
  );
};

export default ManagerDetailPage;
