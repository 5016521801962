import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorBanner, Loading } from 'common/components';
import {
  PayrollInstanceDetailPageContext,
  VisibleModalEnum,
} from 'omniplatform/admin/pages/PayrollInstanceDetailPage/PayrollInstanceDetailPageContext';
import { useWorkbookQuery } from 'utils/queries/payroll/useWorkbookQuery';

const MONTH_PADDING = 2;

export const PayrollContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [visibleModal, setVisibleModal] = useState<
    VisibleModalEnum | undefined
  >();

  const { payrollInstanceId } = useParams<{ payrollInstanceId: string }>();

  const payrollInstanceQuery = useWorkbookQuery(payrollInstanceId);

  const month = payrollInstanceQuery.data?.month;
  const year = payrollInstanceQuery.data?.year;

  const payrollPeriod = `${year}${month
    ?.toString()
    .padStart(MONTH_PADDING, '0')}`;

  const contextValue = useMemo(
    () => ({
      visibleModal,
      setVisibleModal,
      month: month || 0,
      year: year || 0,
      payrollPeriod,
      payrollInstance: payrollInstanceQuery.data,
    }),
    [
      visibleModal,
      setVisibleModal,
      month,
      year,
      payrollPeriod,
      payrollInstanceQuery,
    ],
  );

  if (payrollInstanceQuery.isLoading) {
    return <Loading />;
  }

  if (payrollInstanceQuery.isError) {
    return <ErrorBanner errorMessage={payrollInstanceQuery.error.message} />;
  }

  return (
    <PayrollInstanceDetailPageContext.Provider value={contextValue}>
      {children}
    </PayrollInstanceDetailPageContext.Provider>
  );
};
