import { HTTPStatusCodesEnum } from 'common/constants';
import {
  PostTerminationRequestModel,
  TerminationRequestModel,
} from 'common/types/terminations';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';

export const terminationRequestHandlers = [
  rest.get<TerminationRequestModel[]>(
    composeEndpoint('/terminations/requests'),
    (req, res, ctx) => {
      const query = req.url.searchParams;
      const companyId = query.get('companyId');

      if (companyId) {
        res(
          ctx.status(HTTPStatusCodesEnum.OK),
          ctx.json(mockTerminationRequests),
        );
      }
    },
  ),

  rest.get<TerminationRequestModel>(
    composeEndpoint('/terminations/requests/:id'),
    (req, res, ctx) =>
      res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockTerminationRequest)),
  ),

  rest.post<PostTerminationRequestModel>(
    composeEndpoint('/terminations/requests'),
    (req, res, ctx) => res(ctx.status(201), ctx.json(mockTerminationRequest)),
  ),
];

export const mockTerminationRequest: TerminationRequestModel = {
  id: '45a95078-19d3-4fa1-b73d-d9d8b7a524f2',
  questionnaireId: 'b150ead5-b55f-45a1-b4bc-c3fc0ae960f2',
  companyId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
  employeeId: 'def2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
  employmentId: 'abc2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
  employeeManagerId: 'def2967b-f6fa-48a7-82f0-cf82ecd8b5b3',
  status: 'Requested',
  answers: {
    isEmployeeAwareOfTermination: { selectedAnswerKeys: 'yes' },
    terminationReason: { selectedAnswerKeys: 'other' },
    detailsOfTerminationReason: {
      inputAnswers: 'Other termination reason',
    },
    hasDisputesWithEmployee: { selectedAnswerKeys: 'yes' },
    detailsOfDisputesWithEmployee: { inputAnswers: 'Dispute details' },
    lastWorkingDayPreference: { inputAnswers: '2050-12-31' },
    lastEmploymentDatePreference: { inputAnswers: '2050-12-31' },
    employeeNonHolidayLeaves: {
      selectedAnswerKeys: ['sickLeave', 'parentalLeave'],
    },
    numberOfVacationDaysBeforeLastEmploymentDate: { inputAnswers: 4 },
    overtimeOwed: { inputAnswers: 5, selectedAnswerKeys: 'hours' },
    numberOfDaysOwed: { inputAnswers: 2 },
    terminationSupportDocumentation: {
      inputAnswers: ['1b349c3a-c8ed-4b19-82b5-b94159dea00c'],
    },
  },
  createdBy: 'def2967b-f6fa-48a7-82f0-cf82ecd8b5b3',
  createdAt: '2022-07-10T09:30:00.114Z',
};

export const mockTerminationRequests: TerminationRequestModel[] = [
  mockTerminationRequest,
];
