import { HTTPStatusCodesEnum } from 'common/constants';
import { TemplateMapping } from 'common/types';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';
import { PATH } from 'omniplatform/admin/pages/BackOffice/TemplatesMapping/api';

import { getISOString } from '@omnipresentgroup/design-system';

export const contractTemplateHandlers = [
  rest.get(composeEndpoint(PATH), (req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(DEFAULT_AVVOKA_LIST)),
  ),
  rest.post(composeEndpoint(PATH), async (req, res, ctx) => {
    const body = await req.json();
    const response = {
      id: 'c437c2d7-c283-499e-82a8-d5d62717e3ef',
      ...body,
      createdAt: new Date(Date.now()).toISOString(),
    };
    return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
  }),
  rest.patch(composeEndpoint(`${PATH}/:templateId`), async (req, res, ctx) => {
    const templateId = req.params.templateId;
    const template = DEFAULT_AVVOKA_LIST.templateMappings.find(
      (template) => template.id === templateId,
    ) as TemplateMapping;

    const response = {
      ...template,
      disabled: !template.disabled,
      updatedAt: getISOString(),
    };

    return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(response));
  }),
];

export const DEFAULT_AVVOKA_LIST = {
  templateMappings: [
    {
      id: 'eeabb743-6d27-49e8-b7d8-9ed867a3f72b',
      countryCode: 'in',
      companyId: 'bZoOVEPow8Ws',
      companyName: 'Davey Diamondz',
      avvokaOriginalTemplateId: 48066,
      createdAt: '2022-06-29T07:30:29.460Z',
    },
    {
      id: 'e61c2efb-e1de-4d4c-97d7-1ae973615964',
      countryCode: 'ke',
      avvokaOriginalTemplateId: 48495,
      createdAt: '2022-06-28T12:08:29.005Z',
    },
    {
      id: 'cbfa8a44-b6b9-4814-90d5-3cbbe2dd4e39',
      countryCode: 'fr',
      companyId: 'F2WXbqrlPaVS',
      companyName: 'Simon Says',
      avvokaOriginalTemplateId: 48487,
      createdAt: '2022-06-24T11:07:53.843Z',
    },
    {
      id: 'fa29c9a8-5d6a-4c51-a694-2105f4ee993a',
      countryCode: 'dk',
      avvokaOriginalTemplateId: 40720,
      createdAt: '2021-12-02T12:56:24.018Z',
    },
    {
      id: 'b0215b64-5e93-41ea-b24f-ae8da6691f4a',
      countryCode: 'gb',
      avvokaOriginalTemplateId: 47336,
      createdAt: '2021-10-22T09:26:58.570Z',
      updatedAt: '2022-03-24T15:38:44.294Z',
    },
    {
      id: 'c9b4d24a-068e-4b51-ad46-472580392c0c',
      countryCode: 'nl',
      avvokaOriginalTemplateId: 42148,
      createdAt: '2021-12-22T13:10:20.823Z',
    },
    {
      id: '44856d35-07cf-4c42-b534-775368c5c7f3',
      countryCode: 'dk',
      companyId: 'bZoOVEPow8Ws',
      companyName: 'Davey Diamondz',
      avvokaOriginalTemplateId: 48068,
      createdAt: '2022-06-29T07:29:31.931Z',
    },
    {
      id: 'ee320e46-6e9f-4628-9a32-ed420bff5c14',
      countryCode: 'sg',
      avvokaOriginalTemplateId: 48064,
      createdAt: '2022-05-11T14:30:55.321Z',
    },
  ] as TemplateMapping[],
};
