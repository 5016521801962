import PropTypes from 'prop-types';

import { Box, Typography } from '@omnipresentgroup/design-system';

import FormProgressIndicator from '../FormProgress/FormProgress';
import { StyledFormSidebar } from './FormSidebar.styles';

const FormSidebar = ({ copy, stages, title, currentStage, Icon, child }) => {
  const showSidebarContent = Icon || !!stages?.length || copy || child;
  return (
    <StyledFormSidebar data-testid="form-sidebar-component">
      {title && (
        <Typography as="h3" size="24" weight="medium" hideParagraphSpacing>
          {title}
        </Typography>
      )}
      {copy && (
        <Typography
          as="p"
          hideParagraphSpacing
          size="16"
          weight="regular"
          whiteSpace="pre-line"
        >
          {copy}
        </Typography>
      )}
      {showSidebarContent && (
        <Box>
          {Icon && <Icon data-testid="form-sidebar-icon" />}
          {!!stages?.length && (
            <FormProgressIndicator
              stages={stages}
              currentStage={currentStage}
            />
          )}
          {child}
        </Box>
      )}
    </StyledFormSidebar>
  );
};

FormSidebar.propTypes = {
  copy: PropTypes.string,
  stages: PropTypes.oneOfType([PropTypes.array]),
  title: PropTypes.string,
  currentStage: PropTypes.number,
  Icon: PropTypes.oneOfType([PropTypes.object]),
  child: PropTypes.node,
};

FormSidebar.defaultProps = {
  title: '',
  currentStage: 0,
  stages: [],
  Icon: null,
  copy: null,
  child: null,
};

export default FormSidebar;
