import PropTypes from 'prop-types';
import { onWheelPreventChangeForNumberInput } from 'utils/input.helper';

import {
  StyledLengthInput,
  StyledTimePeriod,
  StyledTimePeriodInputContainer,
} from './TimePeriodInput.styles';

const TimePeriodInput = ({ name, value, onChange, editable, errorMessage }) => {
  return (
    <StyledTimePeriodInputContainer>
      <StyledLengthInput
        type="number"
        data-testid={`${name}-length-input`}
        value={value.length || ''}
        name={`${name}Amount`}
        disabled={!editable}
        editable={editable}
        onChange={({ target: { value: newValue, type } }) =>
          onChange(name, {
            ...value,
            length: type === 'number' ? parseInt(newValue, 10) : newValue,
          })
        }
        error={errorMessage}
        placeholder="Length"
        onWheel={onWheelPreventChangeForNumberInput}
      />

      <StyledTimePeriod
        data-testid={`${name}-timePeriod-input`}
        value={value.timePeriod}
        name={`${name}TimePeriod`}
        disabled={!editable}
        editable={editable}
        onChange={({ target: { value: newValue } }) =>
          onChange(name, {
            ...value,
            timePeriod: newValue,
          })
        }
        error={errorMessage}
        placeholder="Time period"
      />
    </StyledTimePeriodInputContainer>
  );
};

TimePeriodInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  errorMessage: PropTypes.string,
};

TimePeriodInput.defaultProps = {
  onChange: () => {},
  editable: false,
  errorMessage: null,
};

export default TimePeriodInput;
