import { AxiosError } from 'axios';
import { updateGrossToNetLSPData } from 'common/api/payrollTaxonomy.api';
import { GrossToNetFile, LSPData } from 'common/types';
import { useMutation } from 'react-query';
import { getDataFromResponse } from 'utils/react-query-utils';

export const useUpdateGrossToNetLSPDataMutation = () => {
  return useMutation<
    GrossToNetFile,
    AxiosError,
    { gtnId: string; lspData: LSPData }
  >(({ gtnId, lspData }: { gtnId: string; lspData: LSPData }) =>
    updateGrossToNetLSPData(gtnId, lspData).then(getDataFromResponse),
  );
};
