import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  FilterFn,
} from '@tanstack/react-table';
import appHistory from 'app/appHistory';
import {
  ExpenseStatus,
  ExpenseStatusEnum,
} from 'common/components/molecules/ExpenseStatus/ExpenseStatus';
import { ReviewedExpenseStatuses } from 'common/helpers/reviewedExpensesStatuses';
import { SimplifiedExpense } from 'common/types';
import { expenseCategoriesOptions } from 'omniplatform/employee/pages/AddEmployeeExpensePage/addEmployeeExpenseFormConfig';
import { EXPENSE_LIST_PAGE } from 'paths';
import Skeleton from 'react-loading-skeleton';

import {
  Button,
  DateFormats,
  formatISODate,
  Stack,
  Typography,
} from '@omnipresentgroup/design-system';

export const managerEmployeesExpenseTableHeaders = [];

const columnHelper = createColumnHelper<SimplifiedExpense>();

export enum ColumnHeaders {
  employeeName = 'Employee',
  currency = 'Currency',
  amount = 'Amount',
  dateSpent = 'Date spent',
  status = 'Status',
}
export enum StackedColumnHeaders {
  spentAt = 'Spent at',
  category = 'Category',
  estimatedPayrollMonth = 'Pay date',
  approver = 'Approver',
}

const StackedHeaders = {
  estimatedPayrollMonth: () => (
    <Stack gap="0">
      <Typography size="14" as="span" weight="bold">
        {StackedColumnHeaders.estimatedPayrollMonth}
      </Typography>
      <Typography size="14" as="span" weight="medium" color="subtle">
        {StackedColumnHeaders.approver}
      </Typography>
    </Stack>
  ),
  category: () => (
    <Stack gap="0">
      <Typography size="14" weight="bold" as="span">
        {StackedColumnHeaders.spentAt}
      </Typography>
      <Typography size="14" as="span" weight="medium" color="subtle">
        {StackedColumnHeaders.category}
      </Typography>
    </Stack>
  ),
};

const DateCell = (info: CellContext<SimplifiedExpense, string>) => (
  <Typography
    size="14"
    as="span"
    // @ts-ignore
    style={{ textWrap: 'nowrap' }}
  >
    {formatISODate(info?.getValue(), DateFormats.Date)}
  </Typography>
);

const AmountCell = (
  info: CellContext<SimplifiedExpense, SimplifiedExpense['amount']>,
) => {
  const { amount } = info?.getValue();
  return `${amount?.toFixed(2) ?? ''}`;
};

const StatusCell = (
  info: CellContext<SimplifiedExpense, ExpenseStatusEnum>,
) => <ExpenseStatus {...info?.row.original} />;

const defaultRenderer = (info: CellContext<SimplifiedExpense, string>) =>
  info?.getValue() || '-';

const SpentAtCategoryCell = (
  info: CellContext<SimplifiedExpense, SimplifiedExpense['category']>,
) => {
  const { category, title } = info.row.original;
  const categoryName = expenseCategoriesOptions.find(
    ({ value }) => value === category,
  )?.label;
  return (
    <Stack gap="0">
      <Typography size="14" as="span" weight="bold">
        {title}
      </Typography>
      <Typography
        size="14"
        as="span"
        // @ts-ignore
        style={{ textWrap: 'nowrap' }}
      >
        {categoryName}
      </Typography>
    </Stack>
  );
};

const PayDateApproverCell = (
  info: CellContext<SimplifiedExpense, SimplifiedExpense['approverName']>,
) => {
  const approverName = ReviewedExpenseStatuses.includes(
    info.row.original.status,
  )
    ? info.row.original.approverName
    : null;
  const estimatedPayrollMonth = info.row.original.estimatedPayrollMonth;
  return estimatedPayrollMonth ? (
    <Stack gap="0">
      <Typography size="14" as="span" weight="bold">
        {formatISODate(estimatedPayrollMonth, 'MMM yyyy')}
      </Typography>
      <Typography size="14" as="span">
        {approverName}
      </Typography>
    </Stack>
  ) : (
    ''
  );
};

const FAR_FUTURE_DATE = new Date(8640000000000000);
const FAR_PAST_DATE = new Date('1900-01-01');

export const withinDateRange: FilterFn<SimplifiedExpense> = (
  row,
  columnId,
  filterValue,
) => {
  const rowDate = new Date(row.getValue(columnId));
  const [start, end] = filterValue;
  const startDate = start ? new Date(start) : FAR_PAST_DATE; // far past date if not defined
  const endDate = end ? new Date(end) : FAR_FUTURE_DATE; // far future date if not defined
  return rowDate >= startDate && rowDate <= endDate;
};

export const filterByEstimatedPayrollDate: FilterFn<SimplifiedExpense> = (
  row,
  columnId,
  filterValue,
) => {
  const rowValue = row.getValue(columnId);
  if (!filterValue) {
    return true; // Include all rows if filter is not set
  }
  return rowValue === filterValue; // Only include rows that match the filter value
};

export const filterByStatus: FilterFn<SimplifiedExpense> = (
  row,
  columnId,
  filterValue,
) => {
  const rowValue: ExpenseStatusEnum = row.getValue(columnId);

  const pendingStatuses = [
    ExpenseStatusEnum.CHANGE_MADE,
    ExpenseStatusEnum.PENDING,
  ];

  if (!filterValue) {
    return true;
  }
  if (pendingStatuses.includes(filterValue)) {
    // Return true if rowValue is one of the pending statuses
    return pendingStatuses.includes(rowValue);
  } else {
    // Handle other statuses normally
    return rowValue === filterValue;
  }
};

export const Columns = [
  columnHelper.accessor('employeeName', {
    header: ColumnHeaders.employeeName,
    cell: defaultRenderer,
  }),

  columnHelper.accessor('category', {
    cell: SpentAtCategoryCell,
    header: StackedHeaders.category,
  }),
  columnHelper.accessor('amount.currency', {
    header: ColumnHeaders.currency,
    cell: defaultRenderer,
    size: 50,
  }),
  columnHelper.accessor('amount', {
    header: ColumnHeaders.amount,
    cell: AmountCell,
  }),
  columnHelper.accessor('dateSpent', {
    header: ColumnHeaders.dateSpent,
    cell: DateCell,
    filterFn: withinDateRange,
  }),
  columnHelper.accessor('estimatedPayrollMonth', {
    header: StackedHeaders.estimatedPayrollMonth,
    cell: PayDateApproverCell,
    filterFn: filterByEstimatedPayrollDate,
  }),

  columnHelper.accessor('status', {
    header: ColumnHeaders.status,
    cell: StatusCell,
    filterFn: filterByStatus,
  }),
  columnHelper.display({
    id: 'action',
    cell: (info) => (
      <Button
        variant="secondary"
        size="small"
        onClick={() => {
          appHistory.push(`${EXPENSE_LIST_PAGE}/${info.row.original.id}`);
        }}
      >
        View details
      </Button>
    ),
    header: '',
  }),
];

export const LoadingColumns: ColumnDef<any, any>[] = [
  columnHelper.display({
    id: 'employeeName',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.employeeName,
    size: 200,
  }),
  columnHelper.display({
    id: 'category',
    cell: () => <Skeleton height={16} />,
    header: () => StackedColumnHeaders.spentAt,
    size: 100,
  }),
  columnHelper.display({
    id: 'amount.currency',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.currency,
    size: 100,
  }),
  columnHelper.display({
    id: 'amount.amount',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.amount,
    size: 100,
  }),
  columnHelper.display({
    id: 'dateSpent',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.dateSpent,
    size: 100,
  }),
  columnHelper.display({
    id: 'estimatedPayrollMonth',
    cell: () => <Skeleton height={16} />,
    header: () => StackedColumnHeaders.estimatedPayrollMonth,
    size: 100,
  }),
  columnHelper.display({
    id: 'status',
    cell: () => <Skeleton height={16} />,
    header: () => ColumnHeaders.status,
    size: 100,
  }),
];
