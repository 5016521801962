import { useCallback, useEffect, useState } from 'react';

import { DailyWorkingHours as DailyWorkingHoursType } from 'common/types';
import { DayOfWeek } from 'common/types/utilities';
import { Duration, Interval } from 'luxon';
import { AlertCircle } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import {
  Box,
  Checkbox,
  DateFormats,
  DateInput,
  Inline,
  Typography,
} from '@omnipresentgroup/design-system';
import { CheckboxProps } from '@omnipresentgroup/design-system/dist/types/components/Checkbox/Checkbox';

import {
  formatToHumanReadableTime,
  getEndOfDay,
  getStartOfDay,
  transformJSDatesToDateTime,
  transformToJSDate,
} from '../../../../../utils';
import { FormDailyWorkingHours } from '../../../../SelectWorkingHoursModal';

export type CalculatedDailyWorkingHours = FormDailyWorkingHours & {
  total?: {
    value: number;
    display: string;
  };
  isSelected: CheckboxProps['checked'];
};

interface DailyWorkingHoursProps {
  dayOfWeek: DayOfWeek;
  defaultValues?: Omit<DailyWorkingHoursType, 'id'>;
  onTotalHoursChange: (dailyWorkingHours: CalculatedDailyWorkingHours) => void;
  onCheckboxChange: (checked: boolean) => void;
}

const ErrorIcon = styled(AlertCircle)`
  color: ${({ theme: { colors } }) => colors['icon-negative']};
  margin-right: ${({ theme: { space } }) => space[16]};
`;

export const DailyWorkingHours = ({
  dayOfWeek,
  defaultValues,
  onTotalHoursChange,
  onCheckboxChange,
}: DailyWorkingHoursProps) => {
  const [totalDailyHours, setTotalDailyHours] = useState<string>();
  const [checked, setChecked] =
    useState<CheckboxProps['checked']>(!!defaultValues);
  const [invalidHours, setInvalidHours] = useState<boolean>(false);

  const { control: controlDailyWorkingHours, watch } = useForm({
    mode: 'onSubmit',
  });

  const watchAllFields = watch();
  const rowBackground = checked ? 'page' : 'primary';

  const calculateDailyTotalHours = (
    dateStartTime: Date,
    dateEndTime: Date,
    dateBreakStartTime: Date,
    dateBreakEndTime: Date,
  ) => {
    const { startTime, endTime, breakStartTime, breakEndTime } =
      transformJSDatesToDateTime(
        dateStartTime,
        dateEndTime,
        dateBreakStartTime,
        dateBreakEndTime,
      );
    const workingTime = Interval.fromDateTimes(startTime, endTime);
    const breakTime = Interval.fromDateTimes(breakStartTime, breakEndTime);

    const workingTimeDuration = workingTime.toDuration();
    const breakTimeDuration = breakTime.toDuration();
    const totalWorkingTimeInMinutes = Math.round(
      workingTimeDuration.minus(breakTimeDuration).as('minutes'),
    );

    const totalWorkingHoursDisplayValue = formatToHumanReadableTime(
      Duration.fromObject({ minutes: totalWorkingTimeInMinutes }),
    );

    return {
      value: totalWorkingTimeInMinutes,
      display: totalWorkingHoursDisplayValue,
    };
  };

  const handleFormSubmit = useCallback(() => {
    const { startTime, endTime, breakStartTime, breakEndTime } = watchAllFields;
    const totalDailyWorkedHours = calculateDailyTotalHours(
      startTime,
      endTime,
      breakStartTime,
      breakEndTime,
    );
    if (totalDailyWorkedHours.display !== totalDailyHours) {
      const dailyWorkingHours = {
        dayOfWeek,
        startTime,
        breakStartTime,
        breakEndTime,
        endTime,
        total: {
          value: totalDailyWorkedHours.value,
          display: totalDailyWorkedHours.display,
        },
        isSelected: checked as boolean,
      };
      setChecked(true);
      setTotalDailyHours(totalDailyWorkedHours.display);
      onTotalHoursChange(dailyWorkingHours);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, onTotalHoursChange, totalDailyHours, watchAllFields]);

  const validateFields = useCallback((fields: typeof watchAllFields) => {
    const { startTime, endTime, breakStartTime, breakEndTime } = fields;
    const areAllFieldsValid =
      startTime && endTime && breakStartTime && breakEndTime;
    const isWorkingIntervalValid = endTime > startTime;
    const isBreakingIntervalValid = breakEndTime > breakStartTime;
    if (areAllFieldsValid) {
      if (isWorkingIntervalValid && isBreakingIntervalValid) {
        setInvalidHours(false);
      } else {
        setInvalidHours(true);
        setChecked(false);
      }
    }

    return (
      areAllFieldsValid && isWorkingIntervalValid && isBreakingIntervalValid
    );
  }, []);

  useEffect(() => {
    const validateFormFields = validateFields(watchAllFields);
    if (validateFormFields) {
      handleFormSubmit();
    }
  }, [handleFormSubmit, validateFields, watchAllFields]);

  useEffect(() => {
    const validateFormFields = validateFields(watchAllFields);
    if (typeof checked === 'boolean' && validateFormFields) {
      onCheckboxChange(checked);
    }
    if (typeof checked === 'boolean' && invalidHours) {
      onCheckboxChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <form id={dayOfWeek}>
      <Inline
        bg={rowBackground}
        color="default"
        py="4"
        px="16"
        radius="s"
        align="center"
        title="Daily Hours"
      >
        <Box w={20}>
          <Checkbox
            id={`${dayOfWeek}-checkbox`}
            label=""
            checked={checked}
            disabled={invalidHours}
            onCheckedChange={(updatedChecked: CheckboxProps['checked']) =>
              setChecked(updatedChecked)
            }
          />
        </Box>
        <Box minW={90}>
          <Typography as="span" size="14">
            {dayOfWeek}
          </Typography>
        </Box>
        <Box minW={120} w={120}>
          <Controller
            defaultValue={transformToJSDate(defaultValues?.startTime)}
            name="startTime"
            control={controlDailyWorkingHours}
            render={({ field }) => (
              <DateInput
                showTimeSelect
                showTimeSelectOnly
                id="startTime"
                timeFormat={DateFormats.Time}
                timeIntervals={15}
                dateFormat={DateFormats.Time}
                placeholderText="00:00"
                required
                {...field}
                selected={field.value}
              />
            )}
          />
        </Box>
        <Box minW={120} w={120}>
          <Controller
            defaultValue={transformToJSDate(defaultValues?.breakStartTime)}
            name="breakStartTime"
            control={controlDailyWorkingHours}
            render={({ field }) => (
              <DateInput
                showTimeSelect
                showTimeSelectOnly
                id="breakStartTime"
                timeFormat={DateFormats.Time}
                timeIntervals={15}
                dateFormat={DateFormats.Time}
                minTime={watchAllFields.startTime || getStartOfDay()}
                maxTime={watchAllFields.endTime || getEndOfDay()}
                placeholderText="00:00"
                required
                disabled={!watchAllFields.startTime}
                {...field}
                selected={field.value}
              />
            )}
          />
        </Box>
        <Box minW={120} w={120}>
          <Controller
            defaultValue={transformToJSDate(defaultValues?.breakEndTime)}
            name="breakEndTime"
            control={controlDailyWorkingHours}
            render={({ field }) => (
              <DateInput
                showTimeSelect
                showTimeSelectOnly
                id="breakEndTime"
                timeFormat={DateFormats.Time}
                timeIntervals={15}
                dateFormat={DateFormats.Time}
                minTime={watchAllFields.breakStartTime || getEndOfDay()}
                maxTime={watchAllFields.endTime || getEndOfDay()}
                disabled={!watchAllFields.breakStartTime}
                placeholderText="00:00"
                required
                {...field}
                selected={field.value}
              />
            )}
          />
        </Box>
        <Box minW={120} w={120}>
          <Controller
            defaultValue={transformToJSDate(defaultValues?.endTime)}
            name="endTime"
            control={controlDailyWorkingHours}
            render={({ field }) => (
              <DateInput
                showTimeSelect
                showTimeSelectOnly
                id="endTime"
                timeFormat={DateFormats.Time}
                timeIntervals={15}
                dateFormat={DateFormats.Time}
                minTime={watchAllFields.breakEndTime || getStartOfDay()}
                disabled={!watchAllFields.startTime}
                maxTime={getEndOfDay()}
                placeholderText="00:00"
                required
                {...field}
                selected={field.value}
              />
            )}
          />
        </Box>
        <Inline align="center" ml="8" minW={80} w={80}>
          {invalidHours && <ErrorIcon role="tooltip" />}
          {!invalidHours && (
            <Typography as="span" size="14" color="disabled">
              {totalDailyHours && totalDailyHours}
            </Typography>
          )}
        </Inline>
      </Inline>
    </form>
  );
};
