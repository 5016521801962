import { client } from 'api';
import { AxiosResponse } from 'axios';
import {
  EmploymentModel,
  PatchEmploymentModel,
  PostEmploymentModel,
} from 'common/types/employments';

import { removeFalsy } from '../../utils/objects';

export const getEmployments = (params: {
  display?: string;
  userId?: string;
  lspId?: string;
}): Promise<AxiosResponse<EmploymentModel[]>> => {
  return client.get<EmploymentModel[]>(`/employments`, {
    params: removeFalsy(params),
  });
};

export const getEmployment = (
  employmentId: string,
): Promise<AxiosResponse<EmploymentModel>> =>
  client.get<EmploymentModel>(`/employments/${employmentId}`);

export const createEmployment = (
  employment: PostEmploymentModel,
): Promise<AxiosResponse<EmploymentModel>> =>
  client.post<EmploymentModel>(`/employments`, employment);

export const updateEmployment = (
  employmentId: string,
  employment: PatchEmploymentModel,
): Promise<AxiosResponse<EmploymentModel>> =>
  client.patch<EmploymentModel>(`/employments/${employmentId}`, employment);
