import { asyncActions, makeActionKeys } from 'utils/redux-utils';

const employeeActionKeys = makeActionKeys([
  ...asyncActions('GET', 'EMPLOYEE'),
  ...asyncActions('UPDATE', 'EMPLOYEE'),
  ...asyncActions('GET', 'EXPENSES'),
  ...asyncActions('GET', 'EXPENSE'),
  ...asyncActions('UPDATE', 'EXPENSE'),
  ...asyncActions('DELETE', 'EXPENSE'),
  ...asyncActions('SET_PAID', 'EXPENSE'),
  ...asyncActions('GET', 'DOCUMENTS'),
  'CLEAR_SELECTED_EXPENSE',
]);

export default employeeActionKeys;
