import styled from 'styled-components';

import { Typography, TypographyProps } from '@omnipresentgroup/design-system';

const TypographyWrapper = (props: TypographyProps<'span'>) => (
  <Typography {...props} as="span" size="16" />
);

export const StyledEmploymentStatus = styled(TypographyWrapper)<{
  status?: string;
}>`
  color: ${({ theme: { colors }, status }): string => {
    let statusColor;
    switch (status) {
      case 'Onboarding':
        statusColor = colors['orange-500'];
        break;
      case 'Onboarded':
        statusColor = colors['blue-500'];
        break;
      case 'Active':
        statusColor = colors['green-700'];
        break;
      case 'Inactive':
        statusColor = colors['red-500'];
        break;
      default:
        statusColor = colors['gray-500'];
    }
    return statusColor;
  }};
`;
