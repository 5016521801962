import PropTypes from 'prop-types';
import { Trash } from 'react-feather';
import { useDispatch } from 'react-redux';

import {
  downloadDocumentAction,
  viewDocumentAction,
} from '../../../store/actions/documents.actions';
import Button from '../../atoms/Button/Button';
import { StyledBodyCopy, StyledSmallCopy } from '../../atoms/Text/Copy.styles';
import {
  StyledDeleteSection,
  StyledDocumentCard,
  StyledDownloadSection,
  StyledRightSection,
  StyledTitleDescription,
  StyledUploadDate,
} from './DocumentCard.styles';

const DocumentCard = ({
  document,
  onDeleteDocument,
  canDeleteDocument,
  canDownloadDocument,
}) => {
  const dispatch = useDispatch();
  const downloadDocument = (documentId) => () =>
    dispatch(downloadDocumentAction(documentId));
  const viewDocument = (documentId) => () =>
    dispatch(viewDocumentAction({ documentId }));

  return (
    <StyledDocumentCard data-testid="document-card">
      <StyledTitleDescription>
        <div className="stack">
          <StyledBodyCopy>{document.title}</StyledBodyCopy>
          <StyledSmallCopy>{document.description}</StyledSmallCopy>
        </div>
        <StyledUploadDate>{document.formattedDate}</StyledUploadDate>
      </StyledTitleDescription>
      <StyledRightSection>
        <StyledDownloadSection>
          <Button
            variant="link"
            palette="secondary"
            testId={`${document.id}-view-button`}
            onClick={viewDocument(document.id)}
            disabled={!canDownloadDocument}
          >
            View
          </Button>
        </StyledDownloadSection>
        <StyledDownloadSection>
          <Button
            variant="link"
            palette="secondary"
            testId={`${document.id}-download-button`}
            onClick={downloadDocument(document.id)}
            disabled={!canDownloadDocument}
          >
            Download
          </Button>
        </StyledDownloadSection>
        {onDeleteDocument && (
          <StyledDeleteSection
            disabled={!canDeleteDocument}
            onClick={() => {
              // TODO implement custom prompt
              // eslint-disable-next-line no-alert
              const deleteConfirmed = window.confirm(
                'Are you sure you want to delete this document?',
              );
              if (deleteConfirmed) {
                onDeleteDocument(document.id);
              }
            }}
            data-testid={`${document.id}-delete-document`}
          >
            <Trash strokeWidth={1.5} size={16} />
          </StyledDeleteSection>
        )}
      </StyledRightSection>
    </StyledDocumentCard>
  );
};

DocumentCard.propTypes = {
  document: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onDeleteDocument: PropTypes.func,
  canDeleteDocument: PropTypes.bool,
  canDownloadDocument: PropTypes.bool,
};

DocumentCard.defaultProps = {
  onDeleteDocument: null,
  canDeleteDocument: false,
  canDownloadDocument: false,
};

export default DocumentCard;
