import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  background-color: ${({ theme: { colors } }) => colors['background-primary']};
  height: 100%;
  overflow-y: auto;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  position: relative;
  width: 100%;
`;

export const StyledTableHeader = styled.thead`
  color: ${({ theme: { colors } }) => colors['text-default']};
  height: ${({ theme: { space } }) => space[64]};
`;

export const StyledHeaderRow = styled.tr``;

export const StyledTableHeaderCell = styled.th`
  background-color: ${({ theme: { colors } }) => colors['gray-100']};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  position: sticky;
  text-align: left;
  top: 0;
  z-index: 1;
  border-bottom: ${({ theme: { borderWidths, colors, borderStyles } }) =>
    `${borderWidths.s} ${borderStyles.solid} ${colors['border-subtle']}`};
  border-radius: ${({ theme: { radii } }) => `${radii.s}`};

  &:last-child {
    padding: ${({ theme: { space } }) => `${space[4]} ${space[32]}`};
  }

  &:not(:last-child) {
    padding: ${({ theme: { space } }) =>
      `${space[4]} 0 ${space[4]} ${space[16]}`};
  }
`;

const withCheckbox = css`
  td:nth-child(1) {
    width: ${({ theme: { space } }) => space[64]};
  }
`;

export const StyledTableBody = styled.tbody<{ hasCheckbox?: boolean }>`
  td {
    height: ${({ theme: { space } }) => space[64]};
    font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
  }

  ${(props) => props.hasCheckbox && withCheckbox}
  tr {
    :hover {
      box-shadow: none;
    }
  }
`;
