import { useEffect, useState } from 'react';

import { TransformedUser } from 'app/store/app.transformers';

export const usePrivacyPolicy = (transformedUser: TransformedUser) => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  useEffect(() => {
    if (transformedUser) {
      const userSeenPrivacyPolicy =
        transformedUser.administrativeInformation?.find(
          (user) => user.name === 'sentInvitationAt',
        )?.value === 'ACCEPTED';

      setShowPrivacyPolicy(!userSeenPrivacyPolicy);
    }
  }, [transformedUser]);

  return { showPrivacyPolicy, setShowPrivacyPolicy };
};
