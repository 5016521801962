import styled, { css } from 'styled-components';

import { Box } from '@omnipresentgroup/design-system';
import tokens from '@omnipresentgroup/design-tokens';

export const StyledContainer = styled.div(
  ({ theme: { space } }) => css`
    width: 100%;
    height: 100%;

    @media (max-width: ${tokens.breakpoint.tablet}) {
      margin-top: ${space[8]};
    }

    div.loading svg {
      animation-name: spin;
      animation-duration: 800ms;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        20% {
          transform: rotate(0deg);
        }

        80% {
          transform: rotate(360deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
  `,
);

export const StyledAmount = styled(Box)`
  display: flex;
  gap: ${({ theme: { space } }) => space[8]};

  p:first-child {
    text-decoration: underline;
  }
`;
