import {
  LSP,
  LSPApiResponse,
  LSPTypes,
  LSPTypesEnum,
  LSPUpdateApiRequest,
  User,
} from 'common/types';
import {
  getUserMainRole,
  roleTypeToUserFriendlyRoleTypeName,
} from 'utils/user';

export const transformLspType = (type: LSPTypes) => {
  switch (type) {
    case 'lsp:employer-of-record':
      return 'Employer of Record';
    case 'lsp:payroll-provider':
      return 'Payroll Provider';
    default:
      return 'Unknown';
  }
};
export const transformLSPApiResponseToLSP = ({
  countries,
  type,
  inactive,
  ...rest
}: LSPApiResponse): LSP => ({
  ...rest,
  countries,
  type,
  isActive: !inactive,
  displayTypes: transformLspType(type),
  displayCountries:
    countries && countries.length > 0 ? countries.join(', ') : '',
});

const createLSPGroupOptionList = (lsps: Array<LSP>, type: LSPTypes) => {
  return {
    label: transformLspType(type),
    options: lsps
      .map((lsp) => ({
        label: lsp.isActive ? lsp.name : `[INACTIVE] ${lsp.name}`,
        value: lsp.id,
        emoji: lsp.isActive ? '' : '❗',
      }))
      .sort((lsp1, lsp2) => lsp1.label.localeCompare(lsp2.label)),
  };
};

export const transformLSPListOptions = (lsps: Array<LSP>) => {
  return [
    {
      label: 'Not Applicable',
      value: '',
    },
    createLSPGroupOptionList(
      lsps.filter((lsp) => lsp.type === LSPTypesEnum.EMPLOYER_OF_RECORD),
      LSPTypesEnum.EMPLOYER_OF_RECORD,
    ),
    createLSPGroupOptionList(
      lsps.filter((lsp) => lsp.type === LSPTypesEnum.PAYROLL_PROVIDER),
      LSPTypesEnum.PAYROLL_PROVIDER,
    ),
  ];
};

export const transformDepartmentListOptions = (departments: Array<any>) => {
  return departments.map((department) => ({
    label: department.name,
    value: department.id,
  }));
};

export const transformLspUsers = (lspUsers: User[]) => {
  return lspUsers.map((lspUser: User) => {
    const roleType = getUserMainRole(lspUser.roles);
    const role = roleTypeToUserFriendlyRoleTypeName(roleType.type);
    const id: string = lspUser.userId;
    return Object.assign(lspUser, { id, role });
  });
};

/**
 * Adapting the output to be compliant with Update Lsp detail api
 *
 * @param  {LSPUpdateApiRequest} lsp - input object from form submit
 *
 * @returns {object} - if contactEmails is available, returns an object with contactEmails value
 *                     converted to an array in order to be compliant with api.
 *                     Returns the original input if contactEmails is not available.
 */
export const transformLspToApiRequest = (
  lsp: Partial<LSP>,
): LSPUpdateApiRequest => {
  const { name, isActive } = lsp;
  return {
    name,
    inactive: !isActive,
  };
};
