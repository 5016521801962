import PropTypes from 'prop-types';

import { StyledMobileButton } from './MobileButton.styles';

const MobileButton = ({ children, onClick, type, testId, disabled }) => (
  <StyledMobileButton
    type={type}
    onClick={onClick}
    data-testid={testId}
    disabled={disabled}
  >
    {children}
  </StyledMobileButton>
);

MobileButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
};

MobileButton.defaultProps = {
  type: 'button',
  testId: 'omniplatform-button-testid',
  disabled: false,
};

export default MobileButton;
