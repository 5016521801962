import { ACCOUNT_DETAILS_PAGE, VERIFY_EMAIL_PAGE } from 'paths';

import { AccountDetailsPage } from './pages/AccountDetailsPage/AccountDetailsPage';
import EditAccountDetailsPage from './pages/EditAccountDetailsPage/EditAccountDetailsPage';
import { VerifyEmailPage } from './pages/VerifyEmailPage/VerifyEmailPage';

export const commonRoutes = [
  {
    path: `${VERIFY_EMAIL_PAGE}/:token`,
    component: VerifyEmailPage,
    exact: true,
  },
  {
    path: ACCOUNT_DETAILS_PAGE,
    exact: true,
    component: AccountDetailsPage,
  },
  {
    path: `${ACCOUNT_DETAILS_PAGE}/edit`,
    exact: true,
    component: EditAccountDetailsPage,
  },
];
