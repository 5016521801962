import { ChangeEvent, useCallback, useRef, useState } from 'react';

import SearchInput from 'common/components/molecules/SearchInput/SearchInput';
import { SimplifiedEmployee } from 'common/types';
import {
  List,
  ListColumn,
} from 'omniplatform/manager/pages/ManagerPermissions/components/List';
import { sortAlphabetically } from 'utils/sort.util';

import { Box } from '@omnipresentgroup/design-system';

import { CheckboxCell } from './CheckBoxCell';
import { CountryWithFlagCell } from './CountryWithFlagCell';

export type ListEmployeeData = {
  isSelected: boolean;
} & Pick<
  SimplifiedEmployee,
  'id' | 'name' | 'jobTitle' | 'country' | 'startDate'
>;

export type Employee = Pick<
  SimplifiedEmployee,
  'id' | 'name' | 'jobTitle' | 'country' | 'startDate'
>;

export type EmployeesSearchWithListProps = {
  employees: ListEmployeeData[];
  onUpdateSelection?: (updatedEmployees: string[]) => void;
};

export const EmployeesSearchWithList = ({
  employees,
  onUpdateSelection,
}: EmployeesSearchWithListProps) => {
  const [filteredEmployees, setFilteredEmployees] =
    useState<ListEmployeeData[]>();

  const selectedEmployees = useRef<string[]>([]);
  const selectAllRef = useRef<HTMLInputElement>();

  const onCheckboxChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      selectedEmployeesList: ListEmployeeData[],
    ) => {
      const {
        target: { checked },
      } = event;

      const selectedEmployeesIds = selectedEmployeesList.map(
        (employee) => employee.id,
      );

      if (checked) {
        selectedEmployees.current =
          selectedEmployees.current.concat(selectedEmployeesIds);
      } else {
        selectedEmployees.current = selectedEmployees.current.filter(
          (selectedEmployee) =>
            !selectedEmployeesIds.includes(selectedEmployee),
        );
      }

      selectedEmployees.current = Array.from(
        new Set(selectedEmployees.current),
      );

      onFilteredData(employees);

      if (onUpdateSelection) {
        onUpdateSelection(selectedEmployees.current);
      }

      if (selectAllRef.current) {
        const allSelected =
          selectedEmployees.current.length === employees.length;
        const noneSelected = selectedEmployees.current.length === 0;
        selectAllRef.current.indeterminate = !noneSelected && !allSelected;
        selectAllRef.current.checked = allSelected;
      }
    },
    [onUpdateSelection, selectedEmployees],
  );

  const onFilteredData = useCallback((filteredData: Employee[]) => {
    const filteredDataWithSelectedEmployees = filteredData.map((item) => ({
      ...item,
      isSelected: selectedEmployees.current.some(
        (selectedEmployee) => selectedEmployee === item.id,
      ),
    }));
    const sortedEmployees = sortAlphabetically(
      'name',
      filteredDataWithSelectedEmployees,
    );
    setFilteredEmployees(sortedEmployees);
  }, []);

  const employeeListHeaders: ListColumn[] = [
    {
      headerTitle: '',
      customCell: CheckboxCell,
    },
    {
      headerTitle: 'Employee',
      fieldOnObject: 'name',
    },
    {
      headerTitle: 'Role',
      fieldOnObject: 'jobTitle',
    },
    {
      headerTitle: 'Country of work',
      fieldOnObject: 'country',
      customCell: CountryWithFlagCell,
    },

    {
      headerTitle: 'Start Date',
      fieldOnObject: 'startDate',
    },
  ];

  const defaultEmployeesItemKeyExtractor = (
    item: Record<string, unknown>,
  ): string => item.id as string;

  return (
    <>
      <Box mb="4" pl="8">
        <SearchInput
          searchField="name"
          dataToSearch={employees}
          placeHolderText="Search"
          onFilteredData={onFilteredData}
          name="searchEmployees"
        />
      </Box>
      <List
        items={filteredEmployees}
        columns={employeeListHeaders}
        hasCheckboxSelection={true}
        enableSelectAllCheckbox={true}
        itemKeyExtractor={defaultEmployeesItemKeyExtractor}
        customCellCallback={onCheckboxChange}
        selectAllRef={selectAllRef}
      />
    </>
  );
};
