export const validateInputs = (fieldsToValidate, schema) => {
  let errorObject = {};
  const validationResult = schema.validate(fieldsToValidate, {
    abortEarly: false,
  });
  const errorDetails = validationResult?.error?.details;
  if (!errorDetails) {
    return null;
  }
  errorDetails.forEach(({ context, message, path }) => {
    if (
      path.length > 1 &&
      ['annualBaseSalary', 'holidayAllowance'].includes(path[0])
    ) {
      errorObject = {
        ...errorObject,
        [path[0]]: message,
      };
      return;
    }

    if (
      context.key === 'addressLine1' ||
      context.key === 'city' ||
      context.key === 'postalCode'
    ) {
      if (context.label.includes('locationOfWork')) {
        errorObject = {
          ...errorObject,
          locationOfWork: {
            ...errorObject.locationOfWork,
            [context.key]: message,
          },
        };
        return;
      }
      errorObject = {
        ...errorObject,
        address: {
          ...errorObject.address,
          [context.key]: message,
        },
      };
      return;
    }
    errorObject = {
      ...errorObject,
      [context.key]: message,
    };
  });
  return errorObject;
};
