/**
 * Returns matching option that equals value or has property that equals value.
 * Support for sub options.
 * Returns undefined if no match was found
 */
export const findSoftOptionMatch = (
  options: any[],
  value: any,
  accumulator: any = undefined,
): any => {
  return options.reduce((prevOption: any, option: any) => {
    // if option has sub options
    if (option.options) {
      return findSoftOptionMatch(option.options, value, prevOption);
    } else if (
      option === value ||
      (Object.values(option).includes(value) && value)
    ) {
      return option;
    }
    return prevOption;
  }, accumulator);
};
