import { User } from 'common/types';

export const mockCompanyManagers: User[] = [
  {
    userId: '82e11408-5cd4-4ce1-8d54-73622f4930b5',
    authId: 'auth0|5f06dabb6b2561001913cc64',
    firstName: 'Roy',
    lastName: 'Testerton',
    name: 'Roy Testerton',
    displayName: 'Roy Testerton',
    email: 'e2e-employee@omnie2etesting.com',
    roles: [
      {
        type: 'manager',
        tenantId: 'OuanZbYPCFsSQaQpJ96gn',
        id: 'def2967b-f6fa-48a7-82f0-cf56ecd8b5a7',
      },
    ],
    seenWelcomeScreen: false,
    seenPrivacyPolicy: '56565656',
    temporaryPassword: 'sdfv34rerfvdfv',
    createdAt: '2020-08-20T15:00:00.000Z',
    sentInvitationAt: '2020-08-20T15:00:00.000Z',
  },
  {
    userId: '82e11408-5cd4-4ce1-8d54-73622f4938b2',
    authId: 'auth0|5f06dabb6b2561001913g63',
    firstName: 'Test',
    lastName: 'Testeroni',
    name: 'Test Testeroni',
    displayName: 'Test Testeroni',
    email: 'test@omnie2etesting.com',
    roles: [
      {
        type: 'manager',
        tenantId: 'OuanZbYPCFsSQaQpJ96gn',
        id: 'def2967b-f6fa-48a7-82f0-cf56ecd8b7h2',
      },
    ],
    seenWelcomeScreen: false,
    seenPrivacyPolicy: '56565656',
    temporaryPassword: 'sdfv34rersdcsdc',
    createdAt: '2020-08-20T15:00:00.000Z',
    sentInvitationAt: '2020-08-20T15:00:00.000Z',
  },
  {
    userId: 'def2967b-f6fa-48a7-82f0-cf82ecd8b5b3',
    authId: 'auth0|5f06da3e920eff001999e075',
    firstName: 'Tina',
    lastName: 'Testington',
    displayName: 'Tina Testington',
    name: 'Tina Testington',
    email: 'tina.testington@omnie2etesting.com',
    temporaryPassword: 'TWFuYWdlck9tbmk=',
    roles: [
      {
        tenantId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
        id: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
        scopes: ['employees:*', 'expenses:notifications'],
        type: 'manager',
      },
    ],
    seenWelcomeScreen: false,
    seenPrivacyPolicy: '54565656',
    createdAt: '2020-08-20T15:00:00.000Z',
    sentInvitationAt: '2020-08-20T15:00:00.000Z',
  },
  {
    userId: 'def2967b-f6fa-48a7-82f0-cf82ecd8b5b4',
    authId: 'auth0|5f06da3e920eff001999e076',
    firstName: 'Mocky',
    lastName: 'Mouse',
    displayName: 'Mocky Mouse',
    name: 'Mocky Mouse',
    email: 'mocky.mouse@omnie2etesting.com',
    temporaryPassword: 'TWFuYWdlck9tbmk=',
    roles: [
      {
        tenantId: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
        id: 'adc8745b-f6fa-48a7-82f0-cf82ecd8b7t4',
        scopes: ['employees:*', 'expenses:notifications'],
        type: 'manager',
      },
    ],
    seenWelcomeScreen: false,
    seenPrivacyPolicy: '54565656',
    createdAt: '2020-08-20T15:00:00.000Z',
    sentInvitationAt: '2020-08-20T15:00:00.000Z',
  },
];
