import { useEffect, useState } from 'react';

import { FormCheckbox } from 'common/components/molecules/FormCheckbox';
import PropTypes from 'prop-types';

const DEFAULT_INITIAL_VALUE = false;

export const CheckboxBoolean = ({
  name,
  title,
  onChange,
  label,
  initialValue = DEFAULT_INITIAL_VALUE,
  id,
}) => {
  const [isChecked, setIsChecked] = useState(initialValue);

  useEffect(() => setIsChecked(initialValue), [initialValue]);

  const handleClick = () => {
    setIsChecked((prev) => {
      const newState = !prev;
      onChange(name, newState);
      return newState;
    });
  };

  return (
    <FormCheckbox
      name={name}
      title={title}
      checked={isChecked}
      onClick={handleClick}
      label={label}
      id={id}
    />
  );
};

CheckboxBoolean.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.bool,
  id: PropTypes.string,
};
