import { HTTPStatusCodesEnum } from 'common/constants';
import {
  GlobalSetup,
  GlobalSetupErrorStatus,
  GlobalSetupStatus,
} from 'common/types/globalSetup';
import { composeEndpoint } from 'mocks/utils/composeEndpoint';
import { rest } from 'msw';
import {
  mockGlobalSetups,
  mockVeAdminUpdateStatusesDE,
  mockVeAdminUpdateStatusesES,
  mockVeSupportedCountries,
} from 'omniplatform/admin/__mocks__/globalSetup.mock';

import type { OptionType } from '@omnipresentgroup/design-system';

import { omniCreateClient } from '../../api';
import { SendVeInviteRequestParams } from './globalSetup.api';

const sendVeInviteRequestHandler = rest.post<
  undefined,
  SendVeInviteRequestParams
>(
  composeEndpoint('/global-setup/virtual-employer/invite', omniCreateClient),
  (_req, res, ctx) => res(ctx.status(HTTPStatusCodesEnum.OK)),
);

const getVeSupportedCountriesRequestHandler = rest.get<OptionType[]>(
  composeEndpoint(
    '/global-setup/virtual-employer/supported-countries',
    omniCreateClient,
  ),
  (_req, res, ctx) =>
    res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockVeSupportedCountries)),
);

export const getGlobalSetupRequestHandler = rest.get<GlobalSetup[] | null>(
  composeEndpoint('/global-setup', omniCreateClient),
  (_req, res, ctx) => {
    if (_req.url.searchParams.get('countryCode') === 'es') {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json([mockGlobalSetups[1]]),
      );
    }
    if (_req.url.searchParams.get('countryCode') === 'de') {
      return res(
        ctx.status(HTTPStatusCodesEnum.OK),
        ctx.json([mockGlobalSetups[0]]),
      );
    }

    return res(ctx.status(HTTPStatusCodesEnum.OK), ctx.json(mockGlobalSetups));
  },
);

export const updateGlobalSetupStatusRequestHandler = rest.patch(
  composeEndpoint('/global-setup/:globalSetupId/status', omniCreateClient),
  (_req, res, ctx) => {
    return res(ctx.status(HTTPStatusCodesEnum.OK));
  },
);

export type AdminUpdateValidStatuses = [
  { name: GlobalSetupStatus; isValid: boolean }[],
  { name: GlobalSetupErrorStatus; isValid: boolean }[],
];
export const getVirtualEmployerAdminUpdateStatusesRequestHandler =
  rest.get<AdminUpdateValidStatuses>(
    composeEndpoint(
      '/global-setup/virtual-employer/:countryCode/statuses',
      omniCreateClient,
    ),
    (req, res, ctx) => {
      const { countryCode } = req.params;
      if ((countryCode as string).toLowerCase() === 'de') {
        return res(
          ctx.status(HTTPStatusCodesEnum.OK),
          ctx.json(mockVeAdminUpdateStatusesDE),
        );
      }

      if ((countryCode as string).toLowerCase() === 'es') {
        return res(
          ctx.status(HTTPStatusCodesEnum.OK),
          ctx.json(mockVeAdminUpdateStatusesES),
        );
      }

      return res(ctx.status(400));
    },
  );

export const globalSetupHandlers = [
  sendVeInviteRequestHandler,
  getVeSupportedCountriesRequestHandler,
  getGlobalSetupRequestHandler,
  updateGlobalSetupStatusRequestHandler,
  getVirtualEmployerAdminUpdateStatusesRequestHandler,
];
