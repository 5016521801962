import { DropdownOption, Overtime, TimeOff } from 'common/types';
import { DateTime, Duration } from 'luxon';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';

export const DROPDOWN_OVERTIME_OPTIONS = [
  {
    options: [
      {
        label: 'Overtime',
        value: 'overtime',
      },
      {
        label: 'Nightwork',
        value: 'nightwork',
      },
      {
        label: 'Oncall',
        value: 'oncall',
      },
      {
        label: 'Standby',
        value: 'standby',
      },
    ],
  },
];

export type FormTimeOff = {
  type: DropdownOption;
  startDate: Date;
  endDate: Date;
};

export type FormOvertime = {
  type: DropdownOption;
  date: Date;
  startTime: Date;
  endTime: Date;
  note: string;
};

export const getStartOfDay = () => {
  const startDate = new Date();
  startDate.setHours(0, 0);
  return startDate;
};

export const getEndOfDay = () => {
  const endDate = new Date();
  endDate.setHours(23, 45);
  return endDate;
};

export const formattedOvertime = (overtime: Overtime) => {
  return `${overtime.type}: ${formatISODate(
    overtime.date,
    DateFormats.Date,
    false,
    '-',
  )} - ${formatISODate(
    overtime.startTime,
    DateFormats.Time,
  )} to ${formatISODate(overtime.endTime, DateFormats.Time)}`;
};

export const overtimeArrayToInformationDetailItems = (overtimes: Overtime[]) =>
  overtimes.map((overtime) => formattedOvertime(overtime));

export const formattedTimeOffInformation = (timeOff: TimeOff) => {
  return `${timeOff.type}: ${formatISODate(
    timeOff.startDate,
    DateFormats.Date,
    false,
    '-',
  )} - ${formatISODate(timeOff.endDate, DateFormats.Date, false, '-')}`;
};

export const timeOffArrayToInformationDetailItems = (timeOffs: TimeOff[]) =>
  timeOffs.map((timeOff) => formattedTimeOffInformation(timeOff));

export function transformJSDatesToDateTime(
  startTime: Date,
  endTime: Date,
  breakStartTime: Date,
  breakEndTime: Date,
) {
  return {
    startTime: DateTime.fromJSDate(startTime),
    endTime: DateTime.fromJSDate(endTime),
    breakStartTime: DateTime.fromJSDate(breakStartTime),
    breakEndTime: DateTime.fromJSDate(breakEndTime),
  };
}

export const formatToHumanReadableTime = (duration: Duration) => {
  return duration
    .shiftTo('hours')
    .toHuman({ unitDisplay: 'short', maximumFractionDigits: 2 });
};

export const transformToJSDate = (time?: string) => {
  if (!time) {
    return undefined;
  }
  const timeDate = DateTime.fromISO(time);
  return timeDate.toJSDate();
};
