import { Tag, TagIntent } from '@omnipresentgroup/design-system';

export const ExpenseStatus = (status: any) => {
  const { text, intent }: { text: string; intent: TagIntent } =
    typeof status === 'object'
      ? getStatusDetails(status.status)
      : getStatusDetails(status);
  return (
    <Tag id={text} intent={intent}>
      {text}
    </Tag>
  );
};

export enum ExpenseStatusEnum {
  APPROVED = 'APPROVED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  CHANGE_MADE = 'CHANGE_MADE',
  INFO_NEEDED = 'INFO_NEEDED',
  REJECTED = 'REJECTED',
}

export const getStatusDetails = (
  status: ExpenseStatusEnum,
): { text: string; intent: TagIntent } => {
  switch (status) {
    case ExpenseStatusEnum.APPROVED:
      return { text: 'Approved', intent: 'success' };
    case ExpenseStatusEnum.PAID:
      return { text: 'Paid', intent: 'default' };
    case ExpenseStatusEnum.PENDING:
    case ExpenseStatusEnum.CHANGE_MADE:
      return { text: 'Pending', intent: 'warning' };
    case ExpenseStatusEnum.INFO_NEEDED:
      return { text: 'Info needed', intent: 'error' };
    case ExpenseStatusEnum.REJECTED:
      return { text: 'Rejected', intent: 'error' };
    default:
      return { text: '-', intent: 'default' };
  }
};
