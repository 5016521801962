import { getWorkbooksDetails } from 'common/api/payroll.api';
import { THIRTY_SECONDS } from 'common/constants';
import { PayrollInstanceApiResponse } from 'common/types/payroll';
import { useQuery } from 'react-query';

import { getDataFromResponse } from '../../../react-query-utils';
import { payrollKeys } from '../keys';

export const useWorkbookQuery = (workbookId: string) =>
  useQuery<PayrollInstanceApiResponse, Error>(
    payrollKeys.single(workbookId),
    () => getWorkbooksDetails(workbookId).then(getDataFromResponse),
    {
      staleTime: THIRTY_SECONDS,
    },
  );
