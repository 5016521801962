import { ArchiveReasonsEnum } from 'common/types';
import Joi from 'joi';

const reasonsForArchivingEmployee = [
  ArchiveReasonsEnum.DROP_OUT,
  ArchiveReasonsEnum.CORRECTION,
];

export const schema = Joi.object({
  reasonForArchiving: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().valid(...reasonsForArchivingEmployee),
  })
    .required()
    .messages({
      'object.base': 'Please select the reason for archiving',
      'any.required': 'Please select the reason for archiving',
    }),
  archiveVerificationField: Joi.string().required().messages({
    'string.empty': 'Please type word "ARCHIVE"',
    'any.required': 'Please type word "ARCHIVE"',
  }),
  countryChangeVerificationField: Joi.string().required().messages({
    'string.empty': 'Please type word "CHANGE COUNTRY"',
    'any.required': 'Please type word "CHANGE COUNTRY"',
  }),
});
