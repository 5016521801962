import { ExpenseStatusEnum } from 'common/components/molecules/ExpenseStatus/ExpenseStatus';
import { Field, FIELD_TYPE } from 'common/types';
import { Expense, ExpenseFromAPI } from 'common/types/expenses';
import { expenseCategoriesOptions } from 'omniplatform/employee/pages/AddEmployeeExpensePage/addEmployeeExpenseFormConfig';
import { formatCurrency } from 'utils/currencies';

import { DateFormats, formatISODate } from '@omnipresentgroup/design-system';

export const getApprovedAndRejectedDates = (
  statusHistory: ExpenseFromAPI['statusHistory'],
) => {
  const approvalUpdate = statusHistory?.find(
    (update) => update.status === ExpenseStatusEnum.APPROVED,
  );

  const rejectionUpdate = statusHistory?.find(
    (update) => update.status === ExpenseStatusEnum.REJECTED,
  );

  return {
    approvedDate: approvalUpdate?.statusChangedAt,
    rejectDate: rejectionUpdate?.statusChangedAt,
  };
};

export const transformExpenseFromAPI = ({
  id,
  title,
  description,
  status,
  amount,
  dateSpent,
  documentId,
  rejectionReason,
  requestedChange,
  category,
  approverName,
  statusHistory,
  employeeId,
  paid,
}: ExpenseFromAPI): Expense => {
  const { approvedDate, rejectDate } =
    getApprovedAndRejectedDates(statusHistory);

  const expense: Expense = {
    title,
    status,
    description,
    id,
    approverName,
    category,
    employeeId,
    paid,
    rejectionReason: {
      name: 'rejectionReason',
      label: 'Reason for rejection',
      value: rejectionReason,
      type: 'string',
      editable: true,
    },
    requestedChange: {
      name: 'requestedChange',
      label: 'Requested change',
      value: requestedChange,
      type: 'string',
      editable: true,
    },
    expenseDetails: [
      {
        name: 'title',
        label: 'Spent at',
        value: title,
        type: 'string',
        editable: false,
      },
      {
        name: 'description',
        label: 'Description',
        value: description,
        type: 'string',
        editable: false,
      },
      {
        name: 'category',
        label: 'Category',
        value: category,
        type: FIELD_TYPE.DROPDOWN,
        editable: false,
        options: expenseCategoriesOptions,
      },
      {
        name: 'amount',
        label: 'Amount spent',
        value: amount,
        type: 'employeeCurrency',
        editable: false,
        displayValue: formatCurrency(amount.amount, amount.currency),
      },
      {
        name: 'dateSpent',
        label: 'Date spent',
        value: formatISODate(dateSpent, DateFormats.FixedDate),
        type: FIELD_TYPE.DATE,
        editable: false,
      },
      {
        name: 'documentId',
        label: 'Proof of purchase',
        value: documentId,
        type: 'receipt',
        editable: false,
      },
    ],
  };

  if (['APPROVED', 'PAID'].includes(status)) {
    expense.expenseDetails.push(
      {
        name: 'approvedDate',
        label: 'Approved Date',
        value: formatISODate(
          approvedDate as string,
          DateFormats.Date,
          true,
          '-',
        ),
        type: 'date',
        editable: false,
      } as Field,
      {
        name: 'approvedBy',
        label: 'Approved By',
        value: approverName,
        type: 'string',
        editable: false,
      } as Field,
    );
  }

  if (status === 'REJECTED') {
    expense.expenseDetails.push({
      name: 'rejectedDate',
      label: 'Rejected Date',
      value: formatISODate(rejectDate as string, DateFormats.Date, true, '-'),
      type: 'date',
      editable: false,
    } as Field);
  }

  return expense;
};

export const transformUpdateExpenseRequest = ({
  name,
  value,
}: {
  name: string;
  value: unknown;
}) => ({
  [name]: value,
});
