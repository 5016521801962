export const expenseCategoriesOptions = [
  { label: 'Client Entertainment', value: 'CLIENT_ENTERTAINMENT' },
  { label: 'Staff Entertainment', value: 'STAFF_ENTERTAINMENT' },
  { label: 'Postage, Freight & Courier', value: 'POSTAGE_FREIGHT_COURIER' },
  { label: 'Rent (shared office space)', value: 'OFFICE_RENT' },
  { label: 'Home Office Equipment', value: 'OFFICE_EQUIPMENT' },
  { label: 'Repairs & Maintenance', value: 'REPAIR_MAINTANANCE' },
  { label: 'Telephone & Internet', value: 'TELEPHONE_INTERNET' },
  { label: 'Domestic Travel', value: 'DOMESTIC_TRAVEL' },
  { label: 'International Travel', value: 'INTERNATIONAL_TRAVEL' },
  { label: 'Mileage', value: 'MILEAGE' },
  { label: 'Printing', value: 'PRINTING' },
  { label: 'Subsistence', value: 'SUBSISTENCE' },
  { label: 'Subscriptions / memberships', value: 'SUBSCRIPTIONS' },
  { label: 'Staff Training / Courses', value: 'TRAINING' },
  { label: 'Client Billable', value: 'CLIENT_BILLABLE' },
];
