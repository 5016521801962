import {
  EmployerCostApiResponse,
  EmployerCostFee,
  EmployerCosts,
} from 'common/types/employerCost';
import { Cost, EmployerCostsForSeat, Fee } from 'common/types/seat';
import get from 'lodash/get';

const EMPLOYER_COSTS_LABEL = 'Employer cost estimate';
const FX_FEE_LABEL = 'Foreign exchange fee';

const buildParentFeeFromCost = (
  cost: any,
  description: string,
): EmployerCostFee => {
  return {
    description,
    fee: { currency: cost?.currency, amount: cost?.amount },
    isSubitem: false,
  };
};

export const buildFeesDataFromEmployerCostApiResponse = (
  employerCostsApiResponse: EmployerCostApiResponse | undefined,
  currency: string,
): EmployerCostFee[] => {
  if (!employerCostsApiResponse) {
    return [];
  }
  const subtotalCost = get(employerCostsApiResponse, 'employerCosts.subtotal');
  const subtotalValue = subtotalCost[currency];
  const subtotalFee = buildParentFeeFromCost(
    { currency, amount: subtotalValue },
    EMPLOYER_COSTS_LABEL,
  );

  const costs = get(employerCostsApiResponse, 'employerCosts.costs');
  let feesCosts = [];
  if (costs) {
    feesCosts = costs.map((cost: any) => ({
      description: cost.name,
      fee: { currency, amount: cost[currency] },
      isSubitem: true,
    }));
  }
  const fxFeeCost = get(employerCostsApiResponse, 'fxFee');

  const fxFeeCostValue = fxFeeCost[currency];
  const fxFee = buildParentFeeFromCost(
    { currency, amount: fxFeeCostValue },
    FX_FEE_LABEL,
  );

  return [subtotalFee, ...feesCosts, fxFee];
};

export const buildEmployerCostsFeesModelFromApiResponse = (
  employerCostsApiResponse: EmployerCostApiResponse | undefined,
  currency: string,
): EmployerCosts => {
  if (!employerCostsApiResponse) {
    return {};
  }

  const subtotalCost = get(employerCostsApiResponse, 'employerCosts.subtotal');
  const subtotal = { currency, amount: subtotalCost[currency] };

  const costs = get(employerCostsApiResponse, 'employerCosts.costs');
  let feesCosts = [];
  if (costs) {
    feesCosts = costs?.map((cost: any) => ({
      currency,
      description: cost.name,
      amount: cost[currency],
    }));
  }

  const fxFeeCost = get(employerCostsApiResponse, 'fxFee');
  const fxFee = { currency, amount: fxFeeCost[currency] };

  return {
    employerCosts: {
      subtotal,
      costs: feesCosts,
    },
    fxFee,
  };
};

export const buildFeesDataFromSeatApiResponse = (
  employerCosts: EmployerCostsForSeat | undefined,
  fxFeeFromSeat: Fee | undefined,
): EmployerCostFee[] => {
  if (!employerCosts) {
    return [];
  }
  const employerCostFees = employerCosts?.costs.map((cost: Cost) => ({
    description: cost.description,
    fee: { currency: cost.currency, amount: cost.amount },
    isSubitem: true,
  }));

  const subtotalCost = get(employerCosts, 'subtotal');
  const subtotalFee = buildParentFeeFromCost(
    subtotalCost,
    EMPLOYER_COSTS_LABEL,
  );

  const fxFee: EmployerCostFee = buildParentFeeFromCost(
    fxFeeFromSeat,
    FX_FEE_LABEL,
  );

  return [subtotalFee, ...employerCostFees, fxFee];
};
