import { PayrollProviderApiResponse } from '../common/types';

export const mockPayrollProvidersResponse: Array<PayrollProviderApiResponse> = [
  {
    id: 'PP-0023-01',
    lspId: 'LSP-0005',
    countryCode: 'NL',
    name: 'Green LLC',
    hasHistory: true,
  },
  {
    id: 'PP-0023-02',
    lspId: 'LSP-0006',
    countryCode: 'FR',
    name: 'Leannon Inc',
    hasHistory: true,
  },
  {
    id: 'PP-0023-03',
    lspId: 'LSP-0007',
    countryCode: 'PL',
    name: 'Hegmann, Haley and Jaskolski',
    hasHistory: true,
  },
];

export const mockActivePayrollProvidersResponse: Array<PayrollProviderApiResponse> =
  [
    {
      id: 'PP-0023-01',
      lspId: 'LSP-0005',
      countryCode: 'NL',
      name: 'Active Green LLC',
      isActive: true,
      hasHistory: true,
    },
    {
      id: 'PP-0023-02',
      lspId: 'LSP-0006',
      countryCode: 'FR',
      name: 'Active Leannon Inc',
      isActive: true,
      hasHistory: true,
    },
    {
      id: 'PP-0023-03',
      lspId: 'LSP-0007',
      countryCode: 'PL',
      name: 'Active Hegmann, Haley and Jaskolski',
      isActive: true,
      hasHistory: true,
    },
  ];
