import { useEffect } from 'react';

import { PageContent } from 'app/App.styles';
import { getLoadingSelector, getUserSelector } from 'app/store/app.selectors';
import { ReactComponent as DocumentIcon } from 'assets/icon/PinkDocument.svg';
import { uploadDocumentAction } from 'common/store/actions/documentStorage.actions';
import { getDocumentCreatedSelector } from 'common/store/selectors/document.selectors';
import { DOCUMENT_LIST_PAGE } from 'paths';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserMainRole } from 'utils/user';

import { Typography, Viewport } from '@omnipresentgroup/design-system';

import FormSidebar from '../../components/molecules/FormSidebar/FormSidebar';
import StagedForm from '../../components/organisms/StagedForm/StagedForm';
import {
  clearDocumentCreatedAction,
  createDocumentAction,
} from '../../store/actions/documents.actions';
import { getFormConfig } from './addDocumentFormConfig';
import { StyledAddDocumentPage } from './AddDocumentPage.styles';

const AddDocumentPage = ({
  addDocument,
  loading,
  user,
  documentCreated,
  clearDocumentCreated,
  canAddDocument,
  onDocumentFormSubmitted,
  copy,
  icon,
}) => {
  useEffect(() => () => clearDocumentCreated(), [clearDocumentCreated]);

  const userRole = getUserMainRole(user.roles);
  const defaultFormSubmitForEmployeeDocument = (formStages) => {
    if (userRole?.type !== 'employee') {
      throw new Error(
        'AddDocumentPage with default onDocumentFormSubmitted is meant for employees only',
      );
    }
    addDocument({
      documentInfo: formStages,
      tenantId: userRole?.tenantId,
      userId: user.userId,
      returnPath: DOCUMENT_LIST_PAGE,
    });
  };
  const onFormSubmit =
    onDocumentFormSubmitted || defaultFormSubmitForEmployeeDocument;

  const formConfig = getFormConfig(user);

  return (
    <PageContent>
      <StyledAddDocumentPage data-testid="add-document-form">
        <Viewport devices={['phone', 'tablet']}>
          <Typography as="h4" hideParagraphSpacing size="24" weight="medium">
            Add document
          </Typography>
        </Viewport>
        <Viewport devices={['laptop', 'desktop', 'highRes']}>
          <FormSidebar
            title="Add document"
            copy={copy || formConfig.formCopy}
            Icon={icon}
          />
        </Viewport>
        <StagedForm
          formConfig={formConfig}
          onFormSubmit={onFormSubmit}
          loading={loading && user.userId}
          submissionSuccess={documentCreated}
          disableCTA={!canAddDocument}
        />
      </StyledAddDocumentPage>
    </PageContent>
  );
};

AddDocumentPage.propTypes = {
  addDocument: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  documentCreated: PropTypes.bool.isRequired,
  canAddDocument: PropTypes.bool,
  clearDocumentCreated: PropTypes.func.isRequired,
  onDocumentFormSubmitted: PropTypes.func,
  copy: PropTypes.string,
  icon: PropTypes.object,
};

AddDocumentPage.defaultProps = {
  // TODO: need to set `false` by default
  canAddDocument: true,
  onDocumentFormSubmitted: null,
  copy: null,
  icon: DocumentIcon,
};

const mapStateToProps = (state) => ({
  loading: getLoadingSelector(state),
  user: getUserSelector(state),
  documentCreated: getDocumentCreatedSelector(state),
});

const mapDispatchToProps = {
  addDocument: createDocumentAction,
  clearDocumentCreated: clearDocumentCreatedAction,
  uploadDocument: uploadDocumentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentPage);
