import { exportFXRatesDataToCsv } from 'common/api/payroll.api';
import { TWO_MINUTES } from 'common/constants';
import { useQuery } from 'react-query';

import { payrollKeys } from '../keys';

export const useFxRatesQuery = (
  fromCurrency?: string,
  toCurrency?: string,
  queryOptions?: { queryOptions?: any },
) => {
  return useQuery(
    payrollKeys.fxRates(fromCurrency, toCurrency),
    () => exportFXRatesDataToCsv(fromCurrency, toCurrency),
    {
      staleTime: TWO_MINUTES,
      refetchOnWindowFocus: false,
      enabled: false,
      ...(queryOptions || {}),
    },
  );
};
