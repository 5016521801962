import { getLspDetails } from 'common/api/localServiceProviders.api';
import { THIRTY_SECONDS } from 'common/constants';
import { LSPApiResponse } from 'common/types';
import { useQuery } from 'react-query';

import { useSelectUserRoles } from '../../../hooks';
import { getDataFromResponse } from '../../../react-query-utils';
import { lspsKeys } from '../keys';

export const useLspQuery = (lspId: string) => {
  const { isAdmin, isLSP } = useSelectUserRoles();
  const hasPermissionToSeeLSP = isAdmin || isLSP;

  return useQuery<LSPApiResponse, Error>(
    lspsKeys.single(lspId),
    () => getLspDetails(lspId).then(getDataFromResponse),
    {
      enabled: hasPermissionToSeeLSP && !!lspId,
      staleTime: THIRTY_SECONDS,
    },
  );
};
