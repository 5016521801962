import { useParams } from 'react-router-dom';

import { AxiosError } from 'axios';
import { updateContractQuestionnaireRequest } from 'common/api/contractTemplates.api';
import { ContractStatus, QuestionnaireAPIPayload } from 'common/types';
import { useMutation } from 'react-query';

export type UpdatContractQuestionnaire = {
  questionnaire: QuestionnaireAPIPayload;
  status: ContractStatus;
  companyId: string;
};

export const useUpdateContractQuestionnaireMutation = () => {
  const { employeeId } = useParams<{ employeeId: string }>();

  return useMutation(
    ({ questionnaire, status, companyId }: UpdatContractQuestionnaire) =>
      updateContractQuestionnaireRequest({
        status,
        employeeId,
        companyId,
        questionnaire,
      }),
    {
      onError: (error: AxiosError) => {
        throw new Error(
          `Unable to update contract. Request failed with message: ${error.response?.data.message}`,
        );
      },
    },
  );
};
