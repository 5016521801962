import appHistory from 'app/appHistory';
import { getUserTenantId } from 'app/store/app.selectors';
import { createDocumentRequest } from 'common/api/documents.api';
import { EMPLOYEE_EXPENSE_LIST_PAGE } from 'paths';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createActionObject } from 'utils/redux-utils';

import {
  getEmployeeExpenseRequest,
  getEmployeeExpensesRequest,
  updateEmployeeExpenseRequest,
} from '../../api/expenses.api';
import employeeActionKeys from '../actions/reduxConstants';
import {
  getEmployeeIdSelector,
  getEmployeeSelector,
} from '../selectors/employee.selector';
import {
  transformExpenseFromApi,
  transformExpenseToApi,
} from '../transformers/expense.transformer';
import { transformExpenseList } from '../transformers/expensesList.transformer';

function* getEmployeesExpenses({ payload: employeeIdFromPayload }) {
  try {
    const employeeId = yield select(getEmployeeIdSelector);
    const { data: employeesExpenses } = yield call(
      getEmployeeExpensesRequest,
      employeeIdFromPayload || employeeId,
    );
    yield put(
      createActionObject(
        employeeActionKeys.GET_EXPENSES_END,
        transformExpenseList(employeesExpenses),
      ),
    );
  } catch (e) {
    yield put(createActionObject(employeeActionKeys.GET_EXPENSES_ERROR, e));
  }
}

function* getEmployeesExpense({ payload: expenseId }) {
  try {
    const { data: expense } = yield call(getEmployeeExpenseRequest, expenseId);
    yield put(
      createActionObject(
        employeeActionKeys.GET_EXPENSE_END,
        transformExpenseFromApi(expense),
      ),
    );
  } catch (e) {
    yield put(createActionObject(employeeActionKeys.GET_EXPENSE_ERROR, e));
  }
}

function* updateEmployeesExpense({
  payload: { expenseId, fieldsToUpdate, expenseToUpdate },
}) {
  try {
    const tenantId = yield select(getUserTenantId);
    const transformedExpense = transformExpenseToApi(fieldsToUpdate);
    if (transformedExpense.documentId) {
      const employee = yield select(getEmployeeSelector);
      const { data: createdDocument } = yield call(createDocumentRequest, {
        type: 'expense',
        linkToDocument: transformedExpense.documentId,
        userId: employee.userId,
        title: expenseToUpdate.title,
        tenantId,
      });
      transformedExpense.documentId = createdDocument.id;
      delete transformedExpense.documentLink;
    }
    yield call(updateEmployeeExpenseRequest, transformedExpense, expenseId);
    const { data: expense } = yield call(getEmployeeExpenseRequest, expenseId);
    yield put(
      createActionObject(
        employeeActionKeys.GET_EXPENSE_END,
        transformExpenseFromApi(expense),
      ),
    );
    yield put(createActionObject(employeeActionKeys.UPDATE_EXPENSE_END));
    appHistory.push(`${EMPLOYEE_EXPENSE_LIST_PAGE}/${expenseId}`);
  } catch (e) {
    yield put(createActionObject(employeeActionKeys.GET_EXPENSE_ERROR, e));
  }
}

function* setExpenseToPaid({ payload: { expenseId, employeeId } }) {
  try {
    yield call(updateEmployeeExpenseRequest, { paid: true }, expenseId);
    yield call(getEmployeesExpenses, { payload: employeeId });
    yield put(createActionObject(employeeActionKeys.SET_PAID_EXPENSE_END));
  } catch (e) {
    yield put(createActionObject(employeeActionKeys.SET_PAID_EXPENSE_ERROR, e));
  }
}

export default [
  takeLatest(employeeActionKeys.GET_EXPENSES_START, getEmployeesExpenses),
  takeLatest(employeeActionKeys.GET_EXPENSE_START, getEmployeesExpense),
  takeLatest(employeeActionKeys.UPDATE_EXPENSE_START, updateEmployeesExpense),
  takeLatest(employeeActionKeys.SET_PAID_EXPENSE_START, setExpenseToPaid),
];
